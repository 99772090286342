exports.LAS_VEGAS_SCHOOLS = [
		{
			"id": 1333,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.18969,
			"lon": -115.305267,
			"name": "24 Hour Fitness Kid's Club Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2090 Village Center Circle",
				"street2": null,
				"zip": "89134",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89134",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1333-24-Hour-Fitness-Kids-Club-Dc/",
				"reviews": "/nevada/las-vegas/1333-24-Hour-Fitness-Kids-Club-Dc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1333-24-Hour-Fitness-Kids-Club-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1334,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.165813,
			"lon": -115.245468,
			"name": "24 Hour Fitness Kid's Club Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "601 South Rainbow Boulevard",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1334-24-Hour-Fitness-Kids-Club-Dc/",
				"reviews": "/nevada/las-vegas/1334-24-Hour-Fitness-Kids-Club-Dc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1334-24-Hour-Fitness-Kids-Club-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1335,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.010391,
			"lon": -115.138252,
			"name": "24 Hour Fitness Kid's Club Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "9875 South Maryland Parkway",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1335-24-Hour-Fitness-Kids-Club-Dc/",
				"reviews": "/nevada/las-vegas/1335-24-Hour-Fitness-Kids-Club-Dc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1335-24-Hour-Fitness-Kids-Club-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1336,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.142017,
			"lon": -115.120132,
			"name": "24 Hour Fitness Kid's Club Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2605 South Eastern Avenue",
				"street2": null,
				"zip": "89169",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89169",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1336-24-Hour-Fitness-Kids-Club-Dc/",
				"reviews": "/nevada/las-vegas/1336-24-Hour-Fitness-Kids-Club-Dc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1336-24-Hour-Fitness-Kids-Club-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1337,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.265148,
			"lon": -115.259529,
			"name": "24 Hour Fitness Kid's Club Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5651 Centennial Center Boulevard",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1337-24-Hour-Fitness-Kids-Club-Dc/",
				"reviews": "/nevada/las-vegas/1337-24-Hour-Fitness-Kids-Club-Dc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1337-24-Hour-Fitness-Kids-Club-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1239,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.164555,
			"lon": -115.138283,
			"name": "9th Bridge School",
			"gradeLevels": "PK-4",
			"assigned": null,
			"address": {
				"street1": "310 S. 9th Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1239-9th-Bridge-School/",
				"reviews": "/nevada/las-vegas/1239-9th-Bridge-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1239-9th-Bridge-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1024,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.166275,
			"lon": -115.210648,
			"name": "A Small Wonder Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5100 Alta Dr",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1024-A-Small-Wonder-Day-Care-Center/",
				"reviews": "/nevada/las-vegas/1024-A-Small-Wonder-Day-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1024-A-Small-Wonder-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1030,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.087212,
			"lon": -115.106552,
			"name": "A To Z Child Care & Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5653 S Mojave Rd",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1030-A-To-Z-Child-Care--Learning-Center/",
				"reviews": "/nevada/las-vegas/1030-A-To-Z-Child-Care--Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1030-A-To-Z-Child-Care--Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 758,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.190407,
			"lon": -115.097404,
			"name": "ABC Preschool of Redeemer Luthe",
			"gradeLevels": "PK-2",
			"assigned": null,
			"address": {
				"street1": "1730 North Pecos Road",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/758-ABC-Preschool-Of-Redeemer-Luthe/",
				"reviews": "/nevada/las-vegas/758-ABC-Preschool-Of-Redeemer-Luthe/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/758-ABC-Preschool-Of-Redeemer-Luthe/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1553,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.104828,
			"lon": -115.309593,
			"name": "Abston  Sandra B Elementary School",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "9950 West Tompkins Avenue",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1553-Abston--Sandra-B-Elementary-School/",
				"reviews": "/nevada/las-vegas/1553-Abston--Sandra-B-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1553-Abston--Sandra-B-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 206,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.176167,
			"lon": -115.205315,
			"name": "Academy for Individualized Study High School",
			"gradeLevels": "7-12",
			"assigned": null,
			"address": {
				"street1": "4601 West Bonanza Road",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/206-Academy-For-Individualized-Study-High-School/",
				"reviews": "/nevada/las-vegas/206-Academy-For-Individualized-Study-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/206-Academy-For-Individualized-Study-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "1%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 266,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 38,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 1
				},
				{
					"label": "White",
					"percentage": 51
				},
				{
					"label": "Hispanic",
					"percentage": 32
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 520,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 36.145653,
			"lon": -115.140663,
			"name": "Academy For Learning",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "1018 E Sahara Ave",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/520-Academy-For-Learning/",
				"reviews": "/nevada/las-vegas/520-Academy-For-Learning/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/520-Academy-For-Learning/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 18,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 61
				},
				{
					"label": "Two or more races",
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 11
				}
			],
			"remediationData": {}
		},
		{
			"id": 1332,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.182644,
			"lon": -115.146339,
			"name": "Acelero Learning Center-Jefferson",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1050 D Street",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1332-Acelero-Learning-Center-Jefferson/",
				"reviews": "/nevada/las-vegas/1332-Acelero-Learning-Center-Jefferson/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1332-Acelero-Learning-Center-Jefferson/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1410,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.166782,
			"lon": -115.082626,
			"name": "Acelero Learning Clark Child Care Center-Stewart",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4301 Stewart Ave",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1410-Acelero-Learning-Clark-Child-Care-Center-Stewart/",
				"reviews": "/nevada/las-vegas/1410-Acelero-Learning-Clark-Child-Care-Center-Stewart/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1410-Acelero-Learning-Clark-Child-Care-Center-Stewart/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1404,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.178066,
			"lon": -115.109116,
			"name": "Acelero Learning Clark Dc-Martinez",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2901 East Harris Avenue",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1404-Acelero-Learning-Clark-Dc-Martinez/",
				"reviews": "/nevada/las-vegas/1404-Acelero-Learning-Clark-Dc-Martinez/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1404-Acelero-Learning-Clark-Dc-Martinez/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 976,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.16452,
			"lon": -115.162621,
			"name": "Acelero Learning Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1522 Pinto Lane",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/976-Acelero-Learning-Head-Start/",
				"reviews": "/nevada/las-vegas/976-Acelero-Learning-Head-Start/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/976-Acelero-Learning-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 204,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.186268,
			"lon": -115.176735,
			"name": "Advanced Technologies Academy",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "2501 Vegas Drive",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/204-Advanced-Technologies-Academy/",
				"reviews": "/nevada/las-vegas/204-Advanced-Technologies-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/204-Advanced-Technologies-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "48%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1165,
			"parentRating": 4,
			"numReviews": 31,
			"studentsPerTeacher": 19,
			"subratings": {
				"College Readiness Rating": 10,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 48
				},
				{
					"label": "Hispanic",
					"percentage": 42
				},
				{
					"label": "White",
					"percentage": 24
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 17
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 784,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.011829,
			"lon": -115.217911,
			"name": "Aldeane Comito Ries Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9805 South Lindell Road",
				"street2": null,
				"zip": "89141",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89141",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/784-Aldeane-Comito-Ries-Elementary-School/",
				"reviews": "/nevada/las-vegas/784-Aldeane-Comito-Ries-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/784-Aldeane-Comito-Ries-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "43%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 856,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 43
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 18
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 672,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.127548,
			"lon": -115.331192,
			"name": "Alexander Dawson School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "10845 West Desert Inn Road",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/672-Alexander-Dawson-School/",
				"reviews": "/nevada/las-vegas/672-Alexander-Dawson-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/672-Alexander-Dawson-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 588,
			"parentRating": 4,
			"numReviews": 33,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 79
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 707,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.180489,
			"lon": -115.198441,
			"name": "All Saints Day School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "4201 West Washington Avenue",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/707-All-Saints-Day-School/",
				"reviews": "/nevada/las-vegas/707-All-Saints-Day-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/707-All-Saints-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 50,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 80
				},
				{
					"label": "Pacific Islander",
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"percentage": 10
				}
			],
			"remediationData": {}
		},
		{
			"id": 902,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 36.078163,
			"lon": -115.090988,
			"name": "American Heritage Academy",
			"gradeLevels": "PK-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "6126 South Sandhill Road",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/902-American-Heritage-Academy/",
				"reviews": "/nevada/las-vegas/902-American-Heritage-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/902-American-Heritage-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 129,
			"parentRating": 5,
			"numReviews": 30,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 75
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1448,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m,h",
			"lat": 36.077,
			"lon": -115.274406,
			"name": "American Preparatory Academy LV",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "8377 West Patrick Lane",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1448-American-Preparatory-Academy/",
				"reviews": "/nevada/las-vegas/1448-American-Preparatory-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1448-American-Preparatory-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "14%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1657,
			"parentRating": 4,
			"numReviews": 21,
			"studentsPerTeacher": 23,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 14
				},
				{
					"label": "White",
					"percentage": 40
				},
				{
					"label": "Hispanic",
					"percentage": 19
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 16
				},
				{
					"label": "African American",
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1447,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 36.122971,
			"lon": -115.217216,
			"name": "Anderson Academy of Mathematics and Science",
			"gradeLevels": "1-8",
			"assigned": null,
			"address": {
				"street1": "3675 South Lindell Road",
				"street2": "Suite 111/114",
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1447-Anderson-Academy-Of-Mathematics-And-Science/",
				"reviews": "/nevada/las-vegas/1447-Anderson-Academy-Of-Mathematics-And-Science/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1447-Anderson-Academy-Of-Mathematics-And-Science/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 617,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.195312,
			"lon": -115.157578,
			"name": "Andre Agassi College Preparatory Academy",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "1201 West Lake Mead Boulevard",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/617-Andre-Agassi-College-Preparatory-Academy/",
				"reviews": "/nevada/las-vegas/617-Andre-Agassi-College-Preparatory-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/617-Andre-Agassi-College-Preparatory-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "90%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 466,
			"parentRating": 3,
			"numReviews": 7,
			"studentsPerTeacher": 21,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 90
				},
				{
					"label": "African American",
					"percentage": 68
				},
				{
					"label": "Hispanic",
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "White",
					"percentage": 2
				},
				{
					"label": "Asian",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 845,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.195255,
			"lon": -115.157242,
			"name": "Andre Agassi College Preparatory Academy",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "1201 West Lake Mead Boulevard",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/845-Andre-Agassi-College-Preparatory-Academy/",
				"reviews": "/nevada/las-vegas/845-Andre-Agassi-College-Preparatory-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/845-Andre-Agassi-College-Preparatory-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "66%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 663,
			"parentRating": 4,
			"numReviews": 3,
			"studentsPerTeacher": 16,
			"subratings": {
				"College Readiness Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 66
				},
				{
					"label": "African American",
					"percentage": 72
				},
				{
					"label": "Hispanic",
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "White",
					"percentage": 1
				},
				{
					"label": "Asian",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 175,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.193817,
			"lon": -115.067245,
			"name": "Ann Lynch Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4850 Kell Lane",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/175-Ann-Lynch-Elementary-School/",
				"reviews": "/nevada/las-vegas/175-Ann-Lynch-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/175-Ann-Lynch-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 643,
			"parentRating": 2,
			"numReviews": 3,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 70
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 774,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.307018,
			"lon": -115.234329,
			"name": "Anthony Saville Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "8101 North Torrey Pines Drive",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/774-Anthony-Saville-Middle-School/",
				"reviews": "/nevada/las-vegas/774-Anthony-Saville-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/774-Anthony-Saville-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "37%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1638,
			"parentRating": 3,
			"numReviews": 25,
			"studentsPerTeacher": 27,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 37
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 26
				},
				{
					"label": "Pacific Islander",
					"rating": 3,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 45
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 1463,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.159317,
			"lon": -115.367676,
			"name": "Aprende Academy Preschool at Doral Academy Red Rock",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "626 Crossbridge Drive",
				"street2": null,
				"zip": "89138",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89138",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1463-Aprende-Academy-Preschool-At-Doral-Academy-Red-Rock/",
				"reviews": "/nevada/las-vegas/1463-Aprende-Academy-Preschool-At-Doral-Academy-Red-Rock/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1463-Aprende-Academy-Preschool-At-Doral-Academy-Red-Rock/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1460,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 35.999104,
			"lon": -115.136932,
			"name": "Aprende Academy Preschool at Pinecrest Academy St Rose",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1385 East Cactus Avenue",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1460-Aprende-Academy-Preschool-At-Pinecrest-Academy-St-Rose/",
				"reviews": "/nevada/las-vegas/1460-Aprende-Academy-Preschool-At-Pinecrest-Academy-St-Rose/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1460-Aprende-Academy-Preschool-At-Pinecrest-Academy-St-Rose/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 764,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.304111,
			"lon": -115.25975,
			"name": "Arbor View High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "7500 Whispering Sands Drive",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/764-Arbor-View-High-School/",
				"reviews": "/nevada/las-vegas/764-Arbor-View-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/764-Arbor-View-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "31%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 3306,
			"parentRating": 3,
			"numReviews": 15,
			"studentsPerTeacher": 26,
			"subratings": {
				"College Readiness Rating": 8,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 31
				},
				{
					"label": "White",
					"percentage": 47
				},
				{
					"label": "Hispanic",
					"percentage": 24
				},
				{
					"label": "African American",
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 214,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.17783,
			"lon": -115.11097,
			"name": "Arturo Cambeiro Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2851 East Harris Avenue",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/214-Arturo-Cambeiro-Elementary-School/",
				"reviews": "/nevada/las-vegas/214-Arturo-Cambeiro-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/214-Arturo-Cambeiro-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 641,
			"parentRating": 4,
			"numReviews": 3,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 79
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1013,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.113659,
			"lon": -115.07341,
			"name": "Baby Land Infant Center Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4610 Monterrey Avenue",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1013-Baby-Land-Infant-Center-Pre-School/",
				"reviews": "/nevada/las-vegas/1013-Baby-Land-Infant-Center-Pre-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1013-Baby-Land-Infant-Center-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1006,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.127968,
			"lon": -115.091377,
			"name": "Baby Love And Care Inf Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3825 Raymert Drive",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1006-Baby-Love-And-Care-Inf-Center/",
				"reviews": "/nevada/las-vegas/1006-Baby-Love-And-Care-Inf-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1006-Baby-Love-And-Care-Inf-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 882,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.189018,
			"lon": -115.084694,
			"name": "Back To Basics Preschool & Day Care",
			"gradeLevels": "PK-2",
			"assigned": null,
			"address": {
				"street1": "4090 E Owens Avenue",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/882-Back-To-Basics-Preschool--Day-Care/",
				"reviews": "/nevada/las-vegas/882-Back-To-Basics-Preschool--Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/882-Back-To-Basics-Preschool--Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1173,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "h",
			"lat": 36.11541,
			"lon": -115.252319,
			"name": "Beacon Academy of Nevada",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "7360 West Flamingo Road",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1173-Beacon-Academy-Of-Nevada/",
				"reviews": "/nevada/las-vegas/1173-Beacon-Academy-Of-Nevada/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1173-Beacon-Academy-Of-Nevada/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "66%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 405,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 46,
			"subratings": {
				"College Readiness Rating": 1,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 66
				},
				{
					"label": "Hispanic",
					"percentage": 43
				},
				{
					"label": "White",
					"percentage": 28
				},
				{
					"label": "African American",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1331,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.003429,
			"lon": -115.155304,
			"name": "Beautiful Savior Lutheran Ps",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "10265 Bermuda Road",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1331-Beautiful-Savior-Lutheran-Ps/",
				"reviews": "/nevada/las-vegas/1331-Beautiful-Savior-Lutheran-Ps/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1331-Beautiful-Savior-Lutheran-Ps/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 230,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.211273,
			"lon": -115.233078,
			"name": "Berkeley L Bunker Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6350 Peak Drive",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/230-Berkeley-L-Bunker-Elementary-School/",
				"reviews": "/nevada/las-vegas/230-Berkeley-L-Bunker-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/230-Berkeley-L-Bunker-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 783,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 53
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 11,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.196354,
			"lon": -115.218323,
			"name": "Bertha Ronzone Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5701 Stacey Avenue",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/11-Bertha-Ronzone-Elementary-School/",
				"reviews": "/nevada/las-vegas/11-Bertha-Ronzone-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/11-Bertha-Ronzone-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 905,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 65
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 223,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.29472,
			"lon": -115.261902,
			"name": "Betsy A Rhodes Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7350 Tealwood Street",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/223-Betsey-A-Rhodes-Elementary-School/",
				"reviews": "/nevada/las-vegas/223-Betsey-A-Rhodes-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/223-Betsey-A-Rhodes-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "42%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 632,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 42
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 25
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 47
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 12,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.089783,
			"lon": -115.087395,
			"name": "Bill Y Tomiyasu Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5445 Annie Oakley Drive",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/12-Bill-Y-Tomiyasu-Elementary-School/",
				"reviews": "/nevada/las-vegas/12-Bill-Y-Tomiyasu-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/12-Bill-Y-Tomiyasu-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "64%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 531,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 64
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 57
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1495,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.176914,
			"lon": -115.360107,
			"name": "Billy and Rosemary Vassiliadis Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "215 Antelope Ridge Drive",
				"street2": null,
				"zip": "89138",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89138",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1495-Billy-And-Rosemary-Vassiliadis-Elementary-School/",
				"reviews": "/nevada/las-vegas/1495-Billy-And-Rosemary-Vassiliadis-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1495-Billy-And-Rosemary-Vassiliadis-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "11%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 836,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 61
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 14
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 584,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.180111,
			"lon": -115.137199,
			"name": "Biltmore Continuation High School",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "801 North Veterans Memorial Drive",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/584-Biltmore-Continuation-High-School/",
				"reviews": "/nevada/las-vegas/584-Biltmore-Continuation-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/584-Biltmore-Continuation-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 38,
			"parentRating": 3,
			"numReviews": 2,
			"studentsPerTeacher": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "Hispanic",
					"percentage": 50
				},
				{
					"label": "African American",
					"percentage": 34
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 482,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 36.081333,
			"lon": -115.316162,
			"name": "Bishop Gorman High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "5959 South Hualapai Way",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/482-Bishop-Gorman-High-School/",
				"reviews": "/nevada/las-vegas/482-Bishop-Gorman-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/482-Bishop-Gorman-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1264,
			"parentRating": 3,
			"numReviews": 14,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 64
				},
				{
					"label": "Hispanic",
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 14,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.154549,
			"lon": -115.237175,
			"name": "Bonanza High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "6665 Del Rey Avenue",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/14-Bonanza-High-School/",
				"reviews": "/nevada/las-vegas/14-Bonanza-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/14-Bonanza-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "66%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2085,
			"parentRating": 3,
			"numReviews": 12,
			"studentsPerTeacher": 25,
			"subratings": {
				"College Readiness Rating": 3,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 66
				},
				{
					"label": "Hispanic",
					"percentage": 55
				},
				{
					"label": "White",
					"percentage": 20
				},
				{
					"label": "African American",
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1361,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.129536,
			"lon": -115.319481,
			"name": "Bottlegenie Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3317 Chesterbrook Court",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1361-Bottlegenie-Day-Care/",
				"reviews": "/nevada/las-vegas/1361-Bottlegenie-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1361-Bottlegenie-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1329,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.079983,
			"lon": -115.296677,
			"name": "Bright Child Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5970 South Fort Apache Road",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1329-Bright-Child-Learning-Center/",
				"reviews": "/nevada/las-vegas/1329-Bright-Child-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1329-Bright-Child-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1330,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.020248,
			"lon": -115.268211,
			"name": "Bright Child Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "8050 Blue Diamond Road",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1330-Bright-Child-Learning-Center/",
				"reviews": "/nevada/las-vegas/1330-Bright-Child-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1330-Bright-Child-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1403,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.188232,
			"lon": -115.063156,
			"name": "Bright Child Learning Center-Nellis",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1567 North Nellis Boulevard",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1403-Bright-Child-Learning-Center-Nellis/",
				"reviews": "/nevada/las-vegas/1403-Bright-Child-Learning-Center-Nellis/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1403-Bright-Child-Learning-Center-Nellis/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1128,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.182961,
			"lon": -115.314545,
			"name": "Bright Horizons at Summerlin",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "10100 Banburry Cross Drive",
				"street2": null,
				"zip": "89114",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89114",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1128-Bright-Horizons-At-Summerlin/",
				"reviews": "/nevada/las-vegas/1128-Bright-Horizons-At-Summerlin/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1128-Bright-Horizons-At-Summerlin/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 41,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "African American",
					"percentage": 45
				},
				{
					"label": "White",
					"percentage": 36
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 1038,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.105072,
			"lon": -115.254738,
			"name": "Building Blocks Child Care",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "7570 Peace Way",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1038-Building-Blocks-Child-Care/",
				"reviews": "/nevada/las-vegas/1038-Building-Blocks-Child-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1038-Building-Blocks-Child-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 153,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.108814,
			"lon": -115.203178,
			"name": "Burk Horizon Southwest Sunset High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "4560 West Harmon Avenue",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/153-Burk-Horizon-Southwest-Sunset-High-School/",
				"reviews": "/nevada/las-vegas/153-Burk-Horizon-Southwest-Sunset-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/153-Burk-Horizon-Southwest-Sunset-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "51%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 43,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 51
				},
				{
					"label": "Hispanic",
					"percentage": 58
				},
				{
					"label": "White",
					"percentage": 19
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 17,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.183331,
			"lon": -115.100807,
			"name": "C C Ronnow Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1100 Lena Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/17-CC-Ronnow-Elementary-School/",
				"reviews": "/nevada/las-vegas/17-CC-Ronnow-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/17-CC-Ronnow-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 761,
			"parentRating": 3,
			"numReviews": 3,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 85
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 24,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.11832,
			"lon": -115.236778,
			"name": "C H Decker Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3850 Redwood Street",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/24-C-H-Decker-Elementary-School/",
				"reviews": "/nevada/las-vegas/24-C-H-Decker-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/24-C-H-Decker-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 734,
			"parentRating": 3,
			"numReviews": 17,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 62
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 20,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.106037,
			"lon": -115.091019,
			"name": "C W Woodbury Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "3875 East Harmon Avenue",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/20-C-W-Woodbury-Middle-School/",
				"reviews": "/nevada/las-vegas/20-C-W-Woodbury-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/20-C-W-Woodbury-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "83%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 904,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 83
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 72
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"rating": 2,
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 518,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 36.08073,
			"lon": -115.248421,
			"name": "Calvary Chapel Christian School",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "7175 West Oquendo Road",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/518-Calvary-Chapel-Christian-School/",
				"reviews": "/nevada/las-vegas/518-Calvary-Chapel-Christian-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/518-Calvary-Chapel-Christian-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 571,
			"parentRating": 4,
			"numReviews": 24,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 61
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1328,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.080841,
			"lon": -115.247932,
			"name": "Calvary Chapel Ps-Spring Vly",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "7175 West Oquendo Road",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1328-Calvary-Chapel-Ps-Spring-Vly/",
				"reviews": "/nevada/las-vegas/1328-Calvary-Chapel-Ps-Spring-Vly/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1328-Calvary-Chapel-Ps-Spring-Vly/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 659,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.213711,
			"lon": -115.233383,
			"name": "Calvary Christian Learning Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "2900 North Torrey Pines Drive",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/659-Calvary-Christian-Learning-Academy/",
				"reviews": "/nevada/las-vegas/659-Calvary-Christian-Learning-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/659-Calvary-Christian-Learning-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 159,
			"parentRating": 4,
			"numReviews": 34,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 68
				},
				{
					"label": "African American",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 887,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.258308,
			"lon": -115.241516,
			"name": "Candil Hall Academy",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "5348 North Rainbow Boulevard",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/887-Candil-Hall-Academy/",
				"reviews": "/nevada/las-vegas/887-Candil-Hall-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/887-Candil-Hall-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 18,
			"parentRating": 5,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 61
				},
				{
					"label": "Hispanic",
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 828,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.007206,
			"lon": -115.269142,
			"name": "Carolyn S Reedom Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "10025 Rumrill Street",
				"street2": null,
				"zip": "89178",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89178",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/828-Carolyn-S-Reedom-Elementary-School/",
				"reviews": "/nevada/las-vegas/828-Carolyn-S-Reedom-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/828-Carolyn-S-Reedom-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "35%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 612,
			"parentRating": 3,
			"numReviews": 27,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 35
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 27
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 15
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1377,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.153236,
			"lon": -115.085228,
			"name": "Cascos Family Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1668 Gateway Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1377-Cascos-Family-Day-Care/",
				"reviews": "/nevada/las-vegas/1377-Cascos-Family-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1377-Cascos-Family-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 766,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.115871,
			"lon": -115.108856,
			"name": "Ccsd Virtual High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "3050 East Flamingo Road",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/766-Ccsd-Virtual-High-School/",
				"reviews": "/nevada/las-vegas/766-Ccsd-Virtual-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/766-Ccsd-Virtual-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 149,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 26
				},
				{
					"label": "White",
					"percentage": 49
				},
				{
					"label": "Hispanic",
					"percentage": 24
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1303,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.21471,
			"lon": -115.091103,
			"name": "Cecile Walnut Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3820 Cecile Avenue",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1303-Cecile-Walnut-Head-Start/",
				"reviews": "/nevada/las-vegas/1303-Cecile-Walnut-Head-Start/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1303-Cecile-Walnut-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 893,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.28056,
			"lon": -115.279442,
			"name": "Centennial Academy",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6610 Grand Montecito Pkwy",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/893-Centennial-Academy/",
				"reviews": "/nevada/las-vegas/893-Centennial-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/893-Centennial-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 96,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 576,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.27903,
			"lon": -115.318481,
			"name": "Centennial High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "10200 West Centennial Parkway",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/576-Centennial-High-School/",
				"reviews": "/nevada/las-vegas/576-Centennial-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/576-Centennial-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 3050,
			"parentRating": 3,
			"numReviews": 10,
			"studentsPerTeacher": 24,
			"subratings": {
				"College Readiness Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "White",
					"percentage": 40
				},
				{
					"label": "Hispanic",
					"percentage": 27
				},
				{
					"label": "African American",
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1327,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.250057,
			"lon": -115.225655,
			"name": "Challenger Pre-Sch-Los Prados",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5151 North Jones Boulevard",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1327-Challenger-Pre-Sch-Los-Prados/",
				"reviews": "/nevada/las-vegas/1327-Challenger-Pre-Sch-Los-Prados/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1327-Challenger-Pre-Sch-Los-Prados/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1422,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.253212,
			"lon": -115.224762,
			"name": "Challenger School - Los Prados",
			"gradeLevels": "PK-1",
			"assigned": null,
			"address": {
				"street1": "5150 North Jones Boulevard",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1422-Challenger-School---Los-Prados/",
				"reviews": "/nevada/las-vegas/1422-Challenger-School---Los-Prados/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1422-Challenger-School---Los-Prados/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 115,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 743,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.019695,
			"lon": -115.128708,
			"name": "Challenger School - Silverado",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1725 E Serene Ave",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/743-Challenger-School---Silverado/",
				"reviews": "/nevada/las-vegas/743-Challenger-School---Silverado/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/743-Challenger-School---Silverado/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 46,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 742,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.223198,
			"lon": -115.31208,
			"name": "Challenger School - Summerlin",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "9900 W Isaac Newton Way",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/742-Challenger-School---Summerlin/",
				"reviews": "/nevada/las-vegas/742-Challenger-School---Summerlin/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/742-Challenger-School---Summerlin/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 36,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.120182,
			"lon": -115.086815,
			"name": "Chaparral High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "3850 Annie Oakley Drive",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/21-Chaparral-High-School/",
				"reviews": "/nevada/las-vegas/21-Chaparral-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/21-Chaparral-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "76%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2384,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 23,
			"subratings": {
				"College Readiness Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 76
				},
				{
					"label": "Hispanic",
					"percentage": 61
				},
				{
					"label": "African American",
					"percentage": 17
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 719,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 35.989105,
			"lon": -115.221756,
			"name": "Charles & Phyllis Frias Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5800 West Broken Top Avenue",
				"street2": null,
				"zip": "89141",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89141",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/719-Charles--Phyllis-Frias-Elementary-School/",
				"reviews": "/nevada/las-vegas/719-Charles--Phyllis-Frias-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/719-Charles--Phyllis-Frias-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "33%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 697,
			"parentRating": 4,
			"numReviews": 21,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 33
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 8,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 22
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 42
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 14
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 236,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.013039,
			"lon": -115.139374,
			"name": "Charles Silvestri Junior High School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1055 East Silverado Ranch Boulevard",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/236-Charles-Silvestri-Junior-High-School/",
				"reviews": "/nevada/las-vegas/236-Charles-Silvestri-Junior-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/236-Charles-Silvestri-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "55%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1640,
			"parentRating": 3,
			"numReviews": 18,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 55
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 32
				},
				{
					"label": "Pacific Islander",
					"rating": 5,
					"percentage": 6
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				}
			],
			"remediationData": {}
		},
		{
			"id": 979,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.153156,
			"lon": -115.217407,
			"name": "Charleston Heights Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1665 Lindell Rd",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/979-Charleston-Heights-Day-Care/",
				"reviews": "/nevada/las-vegas/979-Charleston-Heights-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/979-Charleston-Heights-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1205,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.169369,
			"lon": -115.054802,
			"name": "Charlotte & Jerry Keller Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5445 Cedar Avenue",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1205-Charlotte--Jerry-Keller-Elementary-School/",
				"reviews": "/nevada/las-vegas/1205-Charlotte--Jerry-Keller-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1205-Charlotte--Jerry-Keller-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 679,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 75
				},
				{
					"label": "African American",
					"percentage": 11
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 181,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.053986,
			"lon": -115.151215,
			"name": "Charlotte Hill Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "560 East Eldorado Lane",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/181-Charlotte-Hill-Elementary-School/",
				"reviews": "/nevada/las-vegas/181-Charlotte-Hill-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/181-Charlotte-Hill-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "99%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 571,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 99
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 37
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 790,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.174725,
			"lon": -115.099365,
			"name": "Child Haven",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "601 N. Pecos Rd #70",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/790-Child-Haven-Elementary-School/",
				"reviews": "/nevada/las-vegas/790-Child-Haven-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/790-Child-Haven-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 851,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.175388,
			"lon": -115.099121,
			"name": "Child Haven 6-12",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "601 North Pecos Road",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/851-Child-Haven-6-12/",
				"reviews": "/nevada/las-vegas/851-Child-Haven-6-12/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/851-Child-Haven-6-12/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1405,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.169708,
			"lon": -115.123695,
			"name": "Child Haven Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "701 N Pecos Rdbldg K",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1405-Child-Haven-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1405-Child-Haven-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1405-Child-Haven-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 983,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.150814,
			"lon": -115.087448,
			"name": "Child Time",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1934 S Walnut Rd",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/983-Child-Time/",
				"reviews": "/nevada/las-vegas/983-Child-Time/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/983-Child-Time/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1039,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.044296,
			"lon": -115.117531,
			"name": "Child Time Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "7980 South Eastern Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1039-Child-Time-Learning-Center/",
				"reviews": "/nevada/las-vegas/1039-Child-Time-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1039-Child-Time-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1385,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.136944,
			"lon": -115.083374,
			"name": "Children's Choice Lc-Boulder",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4185 East Vegas Valley Drive",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1385-Childrens-Choice-Lc-Boulder/",
				"reviews": "/nevada/las-vegas/1385-Childrens-Choice-Lc-Boulder/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1385-Childrens-Choice-Lc-Boulder/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1384,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.156189,
			"lon": -115.320702,
			"name": "Children's Learning Adv Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "10405 Griffith Peak Drive",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1384-Childrens-Learning-Adv-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1384-Childrens-Learning-Adv-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1384-Childrens-Learning-Adv-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1391,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.048813,
			"lon": -115.244965,
			"name": "Children's Learning Adv Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6980 West Robindale Road",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1391-Childrens-Learning-Adv-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1391-Childrens-Learning-Adv-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1391-Childrens-Learning-Adv-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1392,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.086639,
			"lon": -115.116875,
			"name": "Children's Learning Adv Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2488 East Russell Road",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1392-Childrens-Learning-Adv-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1392-Childrens-Learning-Adv-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1392-Childrens-Learning-Adv-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1001,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.127045,
			"lon": -115.198761,
			"name": "Children's Oasis",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3460 Arville St",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1001-Childrens-Oasis/",
				"reviews": "/nevada/las-vegas/1001-Childrens-Oasis/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1001-Childrens-Oasis/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1028,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.126324,
			"lon": -115.217293,
			"name": "Children's World",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5540 Spring Mountain Rd",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1028-Childrens-World/",
				"reviews": "/nevada/las-vegas/1028-Childrens-World/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1028-Childrens-World/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 880,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.101688,
			"lon": -115.172112,
			"name": "Childrens Choice Kindergarten",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "3799 Las Vegas Blvd S",
				"street2": null,
				"zip": "89109",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89109",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/880-Childrens-Choice-Kindergarten/",
				"reviews": "/nevada/las-vegas/880-Childrens-Choice-Kindergarten/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/880-Childrens-Choice-Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1386,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.140591,
			"lon": -115.175484,
			"name": "Childrens Choice Lc-Plce Sta",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2750 South Rancho Drive",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1386-Childrens-Choice-Lc-Plce-Sta/",
				"reviews": "/nevada/las-vegas/1386-Childrens-Choice-Lc-Plce-Sta/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1386-Childrens-Choice-Lc-Plce-Sta/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1292,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.092754,
			"lon": -115.075485,
			"name": "Childrens Inn Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4502 Palm Mesa Drive",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1292-Childrens-Inn-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1292-Childrens-Inn-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1292-Childrens-Inn-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1326,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.044296,
			"lon": -115.117531,
			"name": "Childtime Child Care Center 1803",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "7980 South Eastern Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1326-Childtime-Child-Care-Center-1803/",
				"reviews": "/nevada/las-vegas/1326-Childtime-Child-Care-Center-1803/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1326-Childtime-Child-Care-Center-1803/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 972,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.174637,
			"lon": -115.233505,
			"name": "Christ Luth Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "111 North Torrey Pines Drive",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/972-Christ-Luth-Childrens-Center/",
				"reviews": "/nevada/las-vegas/972-Christ-Luth-Childrens-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/972-Christ-Luth-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1224,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.088249,
			"lon": -115.10051,
			"name": "Christian Montessori Academy",
			"gradeLevels": "PK-4",
			"assigned": null,
			"address": {
				"street1": "5580 South Pecos Road",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1224-Christian-Montessori-Academy/",
				"reviews": "/nevada/las-vegas/1224-Christian-Montessori-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1224-Christian-Montessori-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 69,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 85
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 199,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.20105,
			"lon": -115.25338,
			"name": "Cimarron Memorial High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "2301 North Tenaya Way",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/199-Cimarron-Memorial-High-School/",
				"reviews": "/nevada/las-vegas/199-Cimarron-Memorial-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/199-Cimarron-Memorial-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "73%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2525,
			"parentRating": 3,
			"numReviews": 14,
			"studentsPerTeacher": 25,
			"subratings": {
				"College Readiness Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 73
				},
				{
					"label": "Hispanic",
					"percentage": 50
				},
				{
					"label": "African American",
					"percentage": 20
				},
				{
					"label": "White",
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1025,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.096153,
			"lon": -115.118271,
			"name": "Cinderella Careskool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5120 South Eastern Avenue",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1025-Cinderella-Careskool/",
				"reviews": "/nevada/las-vegas/1025-Cinderella-Careskool/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1025-Cinderella-Careskool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1325,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.141487,
			"lon": -115.287231,
			"name": "Citibank Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2720 Crystal Water Way",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1325-Citibank-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1325-Citibank-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1325-Citibank-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 656,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.141487,
			"lon": -115.287231,
			"name": "Citibank Family Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2720 Crystal Water Way",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/656-Citibank-Family-Center/",
				"reviews": "/nevada/las-vegas/656-Citibank-Family-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/656-Citibank-Family-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 44,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 1043,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.144302,
			"lon": -115.141655,
			"name": "City Impact Early Ed Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "928 East Sahara Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1043-City-Impact-Early-Ed-Center/",
				"reviews": "/nevada/las-vegas/1043-City-Impact-Early-Ed-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1043-City-Impact-Early-Ed-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1555,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.148251,
			"lon": -115.11171,
			"name": "Clark Adult Program",
			"gradeLevels": "12",
			"assigned": null,
			"address": {
				"street1": "2701 East Saint Louis Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1555-Clark-Adult-Program/",
				"reviews": "/nevada/las-vegas/1555-Clark-Adult-Program/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1555-Clark-Adult-Program/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 5108,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 169,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.23505,
			"lon": -115.211914,
			"name": "Claude & Stella Parson Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4100 Thom Boulevard",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/169-Claude--Stella-Parson-Elementary-School/",
				"reviews": "/nevada/las-vegas/169-Claude--Stella-Parson-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/169-Claude--Stella-Parson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "99%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 389,
			"parentRating": 3,
			"numReviews": 8,
			"studentsPerTeacher": 14,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 99
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 28
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 45
				},
				{
					"label": "White",
					"rating": 2,
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 573,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.10923,
			"lon": -115.284592,
			"name": "Clifford J Lawrence Junior High School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "4410 South Juliano Road",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/573-Clifford-J-Lawrence-Junior-High-School/",
				"reviews": "/nevada/las-vegas/573-Clifford-J-Lawrence-Junior-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/573-Clifford-J-Lawrence-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "67%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1348,
			"parentRating": 3,
			"numReviews": 17,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 67
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 38
				},
				{
					"label": "Pacific Islander",
					"rating": 7,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"rating": 3,
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 15
				}
			],
			"remediationData": {}
		},
		{
			"id": 158,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.230007,
			"lon": -115.094696,
			"name": "Clyde C Cox Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3855 North Timberlake Drive",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/158-Clyde-C-Cox-Elementary-School/",
				"reviews": "/nevada/las-vegas/158-Clyde-C-Cox-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/158-Clyde-C-Cox-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 802,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 73
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 217,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.155621,
			"lon": -115.23259,
			"name": "College of So. NV High School West",
			"gradeLevels": "11-12",
			"assigned": null,
			"address": {
				"street1": "6375 West Charleston Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/217-College-Of-So.-Nv-High-School-West/",
				"reviews": "/nevada/las-vegas/217-College-Of-So.-Nv-High-School-West/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/217-College-Of-So.-Nv-High-School-West/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "14%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 187,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 36,
			"subratings": {
				"College Readiness Rating": 10,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 14
				},
				{
					"label": "White",
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"percentage": 34
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 18
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1497,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.283493,
			"lon": -115.268623,
			"name": "Coral Academy of Science Las Vegas Centennial Hills",
			"gradeLevels": "K-7",
			"assigned": null,
			"address": {
				"street1": "7951 Deer Springs Way",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1497-Coral-Academy-Of-Science-Las-Vegas-Centennial-Hills/",
				"reviews": "/nevada/las-vegas/1497-Coral-Academy-Of-Science-Las-Vegas-Centennial-Hills/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1497-Coral-Academy-Of-Science-Las-Vegas-Centennial-Hills/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "14%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 633,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 14
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 22
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 44
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1499,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "p,e,m",
			"lat": 36.235439,
			"lon": -115.057976,
			"name": "Coral Academy of Science Las Vegas Nellis AFB",
			"gradeLevels": "PK-7",
			"assigned": null,
			"address": {
				"street1": "42 Baer Drive",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1499-Coral-Academy-Of-Science-Las-Vegas-Nellis-AFB/",
				"reviews": "/nevada/las-vegas/1499-Coral-Academy-Of-Science-Las-Vegas-Nellis-AFB/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1499-Coral-Academy-Of-Science-Las-Vegas-Nellis-AFB/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "18%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 791,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 29,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 18
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 45
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1501,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e",
			"lat": 36.041264,
			"lon": -115.132271,
			"name": "Coral Academy of Science Las Vegas Tamarus",
			"gradeLevels": "K-3",
			"assigned": null,
			"address": {
				"street1": "8185 Tamarus Street",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1501-Coral-Academy-Of-Science-Las-Vegas-Tamarus/",
				"reviews": "/nevada/las-vegas/1501-Coral-Academy-Of-Science-Las-Vegas-Tamarus/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1501-Coral-Academy-Of-Science-Las-Vegas-Tamarus/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "11%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 394,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 41
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 28
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 744,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.13974,
			"lon": -115.221817,
			"name": "Cornerstone Christian Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "5825 W. Eldora Avenue Suite B",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/744-Cornerstone-Christian-Academy/",
				"reviews": "/nevada/las-vegas/744-Cornerstone-Christian-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/744-Cornerstone-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 425,
			"parentRating": 4,
			"numReviews": 43,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 59
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 730,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.083874,
			"lon": -115.055916,
			"name": "Cowan Academic Center",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "5300 East Russell Road",
				"street2": null,
				"zip": "89122",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89122",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/730-Cowan-Behavior-J-Shs/",
				"reviews": "/nevada/las-vegas/730-Cowan-Behavior-J-Shs/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/730-Cowan-Behavior-J-Shs/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 98,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "Hispanic",
					"percentage": 48
				},
				{
					"label": "African American",
					"percentage": 31
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 156,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.083874,
			"lon": -115.055916,
			"name": "Cowan Sunset Southeast High School",
			"gradeLevels": "8-12",
			"assigned": null,
			"address": {
				"street1": "5300 East Russell Road",
				"street2": null,
				"zip": "89122",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89122",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/156-Cowan-Sunset-Southeast-High-School/",
				"reviews": "/nevada/las-vegas/156-Cowan-Sunset-Southeast-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/156-Cowan-Sunset-Southeast-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "14%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 29,
			"parentRating": 3,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"percentage": 55
				},
				{
					"label": "White",
					"percentage": 28
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1035,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.1203,
			"lon": -115.236992,
			"name": "Creative Kids",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6620 W Katie Ave",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1035-Creative-Kids/",
				"reviews": "/nevada/las-vegas/1035-Creative-Kids/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1035-Creative-Kids/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 2,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 974,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.174675,
			"lon": -115.250259,
			"name": "Creative Kids",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "124 N Tenaya Way",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/974-Creative-Kids/",
				"reviews": "/nevada/las-vegas/974-Creative-Kids/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/974-Creative-Kids/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1023,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.238304,
			"lon": -115.210976,
			"name": "Creative Kids Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5001 West Craig Road",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1023-Creative-Kids-Learning-Center/",
				"reviews": "/nevada/las-vegas/1023-Creative-Kids-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1023-Creative-Kids-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1026,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.095707,
			"lon": -115.234482,
			"name": "Creative Kids Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5125 South Torrey Pines Drive",
				"street2": null,
				"zip": "89118",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89118",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1026-Creative-Kids-Learning-Center/",
				"reviews": "/nevada/las-vegas/1026-Creative-Kids-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1026-Creative-Kids-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1034,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.203037,
			"lon": -115.238136,
			"name": "Creative Kids Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6565 Smoke Ranch",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1034-Creative-Kids-Learning-Center/",
				"reviews": "/nevada/las-vegas/1034-Creative-Kids-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1034-Creative-Kids-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1323,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.298607,
			"lon": -115.277992,
			"name": "Creative Kids Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "8355 Farm Road",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1323-Creative-Kids-Learning-Center/",
				"reviews": "/nevada/las-vegas/1323-Creative-Kids-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1323-Creative-Kids-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1324,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.168266,
			"lon": -115.327835,
			"name": "Creative Kids Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "10650 Alta Drive",
				"street2": null,
				"zip": "89144",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89144",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1324-Creative-Kids-Learning-Center/",
				"reviews": "/nevada/las-vegas/1324-Creative-Kids-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1324-Creative-Kids-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1380,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.035473,
			"lon": -115.11972,
			"name": "Creative Kids Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2320 East Wigwam Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1380-Creative-Kids-Learning-Center/",
				"reviews": "/nevada/las-vegas/1380-Creative-Kids-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1380-Creative-Kids-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 990,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.142998,
			"lon": -115.298279,
			"name": "Creative Kids Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2575 South Fort Apache Road",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/990-Creative-Kids-Learning-Center/",
				"reviews": "/nevada/las-vegas/990-Creative-Kids-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/990-Creative-Kids-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 998,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.2192,
			"lon": -115.241844,
			"name": "Creative Kids Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3220 North Rainbow Boulevard",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/998-Creative-Kids-Learning-Center/",
				"reviews": "/nevada/las-vegas/998-Creative-Kids-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/998-Creative-Kids-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 25,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.154716,
			"lon": -115.127258,
			"name": "Crestwood Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1300 Pauline Way",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/25-Crestwood-Elementary-School/",
				"reviews": "/nevada/las-vegas/25-Crestwood-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/25-Crestwood-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "83%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 706,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 83
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 85
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1322,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.151264,
			"lon": -115.156403,
			"name": "Culinary Workers Child Care Center-Local 226",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1630 South Commerce Street",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1322-Culinary-Workers-Child-Care-Center-Local-226/",
				"reviews": "/nevada/las-vegas/1322-Culinary-Workers-Child-Care-Center-Local-226/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1322-Culinary-Workers-Child-Care-Center-Local-226/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1225,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.152744,
			"lon": -115.224014,
			"name": "Cumorah Academy Of Las Vegas",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "6000 West Oakey Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1225-Cumorah-Academy-Of-Las-Vegas/",
				"reviews": "/nevada/las-vegas/1225-Cumorah-Academy-Of-Las-Vegas/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1225-Cumorah-Academy-Of-Las-Vegas/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 99,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 75
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 184,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.113632,
			"lon": -115.052254,
			"name": "Cynthia Cunningham Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4145 Jimmy Durante Boulevard",
				"street2": null,
				"zip": "89122",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89122",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/184-Cynthia-Cunningham-Elementary-School/",
				"reviews": "/nevada/las-vegas/184-Cynthia-Cunningham-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/184-Cynthia-Cunningham-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 776,
			"parentRating": 1,
			"numReviews": 9,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 48
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 15
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 26,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.148922,
			"lon": -115.058182,
			"name": "Cyril Wengert Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2001 Winterwood Boulevard",
				"street2": null,
				"zip": "89142",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89142",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/26-Cyril-Wengert-Elementary-School/",
				"reviews": "/nevada/las-vegas/26-Cyril-Wengert-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/26-Cyril-Wengert-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 659,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 72
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 592,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.136272,
			"lon": -115.321259,
			"name": "D'Vorre & Hall Ober Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3035 Desert Marigold Lane",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/592-Dvorre--Hall-Ober-Elementary-School/",
				"reviews": "/nevada/las-vegas/592-Dvorre--Hall-Ober-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/592-Dvorre--Hall-Ober-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "43%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 766,
			"parentRating": 3,
			"numReviews": 30,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 43
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 30
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 212,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.15506,
			"lon": -115.03643,
			"name": "Dan Goldfarb Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1651 Orchard Valley Drive",
				"street2": null,
				"zip": "89142",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89142",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/212-Dan-Goldfarb-Elementary-School/",
				"reviews": "/nevada/las-vegas/212-Dan-Goldfarb-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/212-Dan-Goldfarb-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 822,
			"parentRating": 4,
			"numReviews": 4,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 64
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 210,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.260143,
			"lon": -115.283295,
			"name": "Dean LaMar Allen Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8680 West Hammer Lane",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/210-Dean-Lamar-Allen-Elementary-School/",
				"reviews": "/nevada/las-vegas/210-Dean-Lamar-Allen-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/210-Dean-Lamar-Allen-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "36%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 517,
			"parentRating": 3,
			"numReviews": 20,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 36
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 59
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 722,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.123436,
			"lon": -115.140511,
			"name": "Dean Petersen Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3650 Cambridge Street",
				"street2": null,
				"zip": "89169",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89169",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/722-Dean-Petersen-Elementary-School/",
				"reviews": "/nevada/las-vegas/722-Dean-Petersen-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/722-Dean-Petersen-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 976,
			"parentRating": 1,
			"numReviews": 1,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 48
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 767,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.081211,
			"lon": -115.106644,
			"name": "Del Sol Academy of the Performing Arts",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "3100 East Patrick Lane",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/767-Del-Sol-High-School/",
				"reviews": "/nevada/las-vegas/767-Del-Sol-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/767-Del-Sol-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2379,
			"parentRating": 3,
			"numReviews": 8,
			"studentsPerTeacher": 22,
			"subratings": {
				"College Readiness Rating": 2,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 71
				},
				{
					"label": "Hispanic",
					"percentage": 62
				},
				{
					"label": "White",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 27,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.177971,
			"lon": -115.069649,
			"name": "Dell H Robison Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "825 Marion Drive",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/27-Dell-H-Robison-Middle-School/",
				"reviews": "/nevada/las-vegas/27-Dell-H-Robison-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/27-Dell-H-Robison-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "85%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1288,
			"parentRating": 3,
			"numReviews": 5,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 85
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 83
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1503,
			"districtId": 23,
			"districtName": "Achievement School District",
			"districtCity": "Las Vegas",
			"levelCode": "e",
			"lat": 36.195312,
			"lon": -115.157578,
			"name": "Democracy Prep at Agassi Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1201 West Lake Mead Boulevard",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1503-Democracy-Prep-At-Agassi-Elementary/",
				"reviews": "/nevada/las-vegas/1503-Democracy-Prep-At-Agassi-Elementary/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1503-Democracy-Prep-At-Agassi-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 479,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 12,
			"subratings": {
				"Test Scores Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 62
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 31
				},
				{
					"label": "White",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1551,
			"districtId": 23,
			"districtName": "Achievement School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.195312,
			"lon": -115.157578,
			"name": "Democracy Prep at Agassi High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1201 West Lake Mead Boulevard",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1551-Democracy-Prep-At-Agassi-High/",
				"reviews": "/nevada/las-vegas/1551-Democracy-Prep-At-Agassi-High/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1551-Democracy-Prep-At-Agassi-High/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 237,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "African American",
					"percentage": 65
				},
				{
					"label": "Hispanic",
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "White",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1504,
			"districtId": 23,
			"districtName": "Achievement School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.195312,
			"lon": -115.157578,
			"name": "Democracy Prep at Agassi Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1201 West Lake Mead Boulevard",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1504-Democracy-Prep-At-Agassi-Middle/",
				"reviews": "/nevada/las-vegas/1504-Democracy-Prep-At-Agassi-Middle/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1504-Democracy-Prep-At-Agassi-Middle/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 307,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 59
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 36
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "White",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1505,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 35.991283,
			"lon": -115.182373,
			"name": "Dennis Ortwein Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "10926 Dean Martin Drive",
				"street2": null,
				"zip": "89141",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89141",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1505-Dennis-Ortwein-Elementary/",
				"reviews": "/nevada/las-vegas/1505-Dennis-Ortwein-Elementary/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1505-Dennis-Ortwein-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "41%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 680,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {
				"Test Scores Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 41
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 27
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 16
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 14
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 849,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 35.992378,
			"lon": -115.237175,
			"name": "Desert Oasis High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "6600 West Erie Avenue",
				"street2": null,
				"zip": "89141",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89141",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/849-Desert-Oasis-High-School/",
				"reviews": "/nevada/las-vegas/849-Desert-Oasis-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/849-Desert-Oasis-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "37%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 3169,
			"parentRating": 3,
			"numReviews": 14,
			"studentsPerTeacher": 26,
			"subratings": {
				"College Readiness Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 37
				},
				{
					"label": "White",
					"percentage": 31
				},
				{
					"label": "Hispanic",
					"percentage": 27
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 18
				},
				{
					"label": "African American",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 578,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.178284,
			"lon": -115.092239,
			"name": "Desert Pines High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "3800 East Harris Avenue",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 1,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/578-Desert-Pines-High-School/",
				"reviews": "/nevada/las-vegas/578-Desert-Pines-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/578-Desert-Pines-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "77%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2878,
			"parentRating": 2,
			"numReviews": 11,
			"studentsPerTeacher": 22,
			"subratings": {
				"College Readiness Rating": 1,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 77
				},
				{
					"label": "Hispanic",
					"percentage": 76
				},
				{
					"label": "African American",
					"percentage": 15
				},
				{
					"label": "White",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 680,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.156956,
			"lon": -115.198128,
			"name": "Desert Torah Academy Ohel Menachem",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1312 Vista Drive",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/680-Desert-Torah-Academy-Ohel-Menachem/",
				"reviews": "/nevada/las-vegas/680-Desert-Torah-Academy-Ohel-Menachem/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/680-Desert-Torah-Academy-Ohel-Menachem/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 157,
			"parentRating": 4,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 98
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 580,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.164581,
			"lon": -115.099464,
			"name": "Desert Willow Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "245 North Pecos Road",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/580-Desert-Willow-Elementary/",
				"reviews": "/nevada/las-vegas/580-Desert-Willow-Elementary/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/580-Desert-Willow-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "African American",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 1251,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.197987,
			"lon": -115.290474,
			"name": "Discovery Charter School HillPointe",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "8941 Hillpointe Road",
				"street2": null,
				"zip": "89134",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89134",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1251-Discovery-Charter-School/",
				"reviews": "/nevada/las-vegas/1251-Discovery-Charter-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1251-Discovery-Charter-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 253,
			"parentRating": 3,
			"numReviews": 12,
			"studentsPerTeacher": 17,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 26
				},
				{
					"label": "White",
					"percentage": 54
				},
				{
					"label": "Hispanic",
					"percentage": 24
				},
				{
					"label": "African American",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1507,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.090935,
			"lon": -115.089272,
			"name": "Discovery Charter School Mesa Vista",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "3883 East Mesa Vista Way",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1507-Discovery-Charter-School-Mesa-Vista/",
				"reviews": "/nevada/las-vegas/1507-Discovery-Charter-School-Mesa-Vista/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1507-Discovery-Charter-School-Mesa-Vista/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "43%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 119,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 12,
			"subratings": {
				"Test Scores Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 43
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "White",
					"percentage": 34
				},
				{
					"label": "Hispanic",
					"percentage": 23
				},
				{
					"label": "African American",
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1508,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.029823,
			"lon": -115.286591,
			"name": "Don and Dee Snyder Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8951 West Ford Avenue",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1508-Don-And-Dee-Snyder-Elementary-School/",
				"reviews": "/nevada/las-vegas/1508-Don-And-Dee-Snyder-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1508-Don-And-Dee-Snyder-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "48%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 834,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 48
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 25
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 28
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 15
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1453,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 35.997169,
			"lon": -115.289825,
			"name": "Doral Academy Cactus",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "9025 West Cactus Avenue",
				"street2": null,
				"zip": "89178",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89178",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1453-Doral-Academy-Of-Nevada---Cactus-Campus/",
				"reviews": "/nevada/las-vegas/1453-Doral-Academy-Of-Nevada---Cactus-Campus/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1453-Doral-Academy-Of-Nevada---Cactus-Campus/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1002,
			"parentRating": 3,
			"numReviews": 4,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 17
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 52
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 14
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1509,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.207108,
			"lon": -115.25721,
			"name": "Doral Academy Fire Mesa",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2568 Fire Mesa Street",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1509-Doral-Academy-Fire-Mesa/",
				"reviews": "/nevada/las-vegas/1509-Doral-Academy-Fire-Mesa/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1509-Doral-Academy-Fire-Mesa/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "28%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 981,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 28
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 33
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 42
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1459,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m,h",
			"lat": 36.167114,
			"lon": -115.369774,
			"name": "Doral Academy Red Rock",
			"gradeLevels": "K-11",
			"assigned": null,
			"address": {
				"street1": "626 Cross Bridge Road",
				"street2": null,
				"zip": "89138",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89138",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1459-Aprende-Academy-Preschool-At-Doral-Academy-Fire-Mesa/",
				"reviews": "/nevada/las-vegas/1459-Aprende-Academy-Preschool-At-Doral-Academy-Fire-Mesa/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1459-Aprende-Academy-Preschool-At-Doral-Academy-Fire-Mesa/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "15%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1637,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 22,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 15
				},
				{
					"label": "White",
					"percentage": 55
				},
				{
					"label": "Hispanic",
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1244,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.116135,
			"lon": -115.302139,
			"name": "Doral Academy Saddle",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "9625 West Saddle Avenue",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1244-Doral-Academy/",
				"reviews": "/nevada/las-vegas/1244-Doral-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1244-Doral-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "16%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 995,
			"parentRating": 4,
			"numReviews": 64,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 16
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 43
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1512,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.027058,
			"lon": -115.232529,
			"name": "Doral Academy West Pebble",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "6435 West Pebble Road",
				"street2": null,
				"zip": "89139",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89139",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1512-Doral-Academy-West-Pebble/",
				"reviews": "/nevada/las-vegas/1512-Doral-Academy-West-Pebble/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1512-Doral-Academy-West-Pebble/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "23%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 920,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 23
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 23
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 41
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 17
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 28,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.092297,
			"lon": -115.10405,
			"name": "Doris French Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3235 East Hacienda Avenue",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/28-Doris-French-Elementary-School/",
				"reviews": "/nevada/las-vegas/28-Doris-French-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/28-Doris-French-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "99%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 530,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 99
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 52
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 22
				},
				{
					"label": "African American",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.152771,
			"lon": -115.216057,
			"name": "Doris Hancock Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1661 South Lindell Road",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/29-Doris-Hancock-Elementary-School/",
				"reviews": "/nevada/las-vegas/29-Doris-Hancock-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/29-Doris-Hancock-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 606,
			"parentRating": 2,
			"numReviews": 11,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 53
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 160,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.205318,
			"lon": -115.217583,
			"name": "Doris M Reed Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2501 Winwood Street",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/160-Doris-M-Reed-Elementary-School/",
				"reviews": "/nevada/las-vegas/160-Doris-M-Reed-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/160-Doris-M-Reed-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 754,
			"parentRating": 3,
			"numReviews": 7,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 25
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 54
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"rating": 1,
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 124,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.224834,
			"lon": -115.263977,
			"name": "Dorothy Eisenberg Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7770 West Delhi Avenue",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/124-Dorothy-Eisenberg-Elementary-School/",
				"reviews": "/nevada/las-vegas/124-Dorothy-Eisenberg-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/124-Dorothy-Eisenberg-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 552,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 39
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 489,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 36.20079,
			"lon": -115.305908,
			"name": "Dr Miriam & Sheldon G Adelson Educational Campus",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "9700 Hillpointe Road",
				"street2": null,
				"zip": "89134",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89134",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/489-Dr-Miriam--Sheldon-G-Adelson-Educational-Campus/",
				"reviews": "/nevada/las-vegas/489-Dr-Miriam--Sheldon-G-Adelson-Educational-Campus/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/489-Dr-Miriam--Sheldon-G-Adelson-Educational-Campus/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 492,
			"parentRating": 3,
			"numReviews": 30,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 89
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1513,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.045532,
			"lon": -115.197784,
			"name": "Dr. Beverly S. Mathis Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7950 Arville Street",
				"street2": null,
				"zip": "89139",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89139",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1513-Dr.-Beverly-S.-Mathis-Elementary-School/",
				"reviews": "/nevada/las-vegas/1513-Dr.-Beverly-S.-Mathis-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1513-Dr.-Beverly-S.-Mathis-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "54%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 955,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 54
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 838,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.139832,
			"lon": -115.215965,
			"name": "Dr. C Owen Roundy Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2755 Mohawk Street",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/838-Dr.-C-Owen-Roundy-Elementary-School/",
				"reviews": "/nevada/las-vegas/838-Dr.-C-Owen-Roundy-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/838-Dr.-C-Owen-Roundy-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 835,
			"parentRating": 3,
			"numReviews": 7,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 73
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 770,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.20499,
			"lon": -115.025749,
			"name": "Dr. William (Bob) H  Bailey Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "2500 North Hollywood Boulevard",
				"street2": null,
				"zip": "89156",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89156",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/770-Dr.-William-Bob-H--Bailey-Middle-School/",
				"reviews": "/nevada/las-vegas/770-Dr.-William-Bob-H--Bailey-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/770-Dr.-William-Bob-H--Bailey-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1348,
			"parentRating": 2,
			"numReviews": 11,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 69
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"rating": 1,
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 228,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.166008,
			"lon": -115.036331,
			"name": "Duane D Keller Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "301 North Fogg Street",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/228-Duane-D-Keller-Middle-School/",
				"reviews": "/nevada/las-vegas/228-Duane-D-Keller-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/228-Duane-D-Keller-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "81%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1300,
			"parentRating": 3,
			"numReviews": 14,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 81
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 73
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"rating": 3,
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1355,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.127354,
			"lon": -115.119019,
			"name": "Duck Duck Goose Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3427 South Eastern Avenue",
				"street2": null,
				"zip": "89169",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89169",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1355-Duck-Duck-Goose-Day-Care/",
				"reviews": "/nevada/las-vegas/1355-Duck-Duck-Goose-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1355-Duck-Duck-Goose-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 136,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.089653,
			"lon": -115.246185,
			"name": "Durango High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "7100 West Dewey Drive",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/136-Durango-High-School/",
				"reviews": "/nevada/las-vegas/136-Durango-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/136-Durango-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "58%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2390,
			"parentRating": 3,
			"numReviews": 12,
			"studentsPerTeacher": 25,
			"subratings": {
				"College Readiness Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 58
				},
				{
					"label": "Hispanic",
					"percentage": 41
				},
				{
					"label": "White",
					"percentage": 20
				},
				{
					"label": "African American",
					"percentage": 16
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 30,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.169262,
			"lon": -115.199936,
			"name": "E W Griffith Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "324 Essex Drive",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/30-E-W-Griffith-Elementary-School/",
				"reviews": "/nevada/las-vegas/30-E-W-Griffith-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/30-E-W-Griffith-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 628,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 75
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 850,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.136196,
			"lon": -115.026672,
			"name": "East Career Technical Academy",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "6705 Vegas Valley Drive",
				"street2": null,
				"zip": "89142",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89142",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/850-East-Career-Technical-Academy/",
				"reviews": "/nevada/las-vegas/850-East-Career-Technical-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/850-East-Career-Technical-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "63%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1912,
			"parentRating": 5,
			"numReviews": 12,
			"studentsPerTeacher": 23,
			"subratings": {
				"College Readiness Rating": 9,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 63
				},
				{
					"label": "Hispanic",
					"percentage": 75
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1032,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.152473,
			"lon": -115.228233,
			"name": "Easter Seals Southern Nevada D",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6200 West Oakey Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1032-Easter-Seals-Southern-Nevada-D/",
				"reviews": "/nevada/las-vegas/1032-Easter-Seals-Southern-Nevada-D/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1032-Easter-Seals-Southern-Nevada-D/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.204796,
			"lon": -115.095795,
			"name": "Ed Von Tobel Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "2436 North Pecos Road",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/31-Ed-Von-Tobel-Middle-School/",
				"reviews": "/nevada/las-vegas/31-Ed-Von-Tobel-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/31-Ed-Von-Tobel-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1166,
			"parentRating": 3,
			"numReviews": 4,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 84
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 2
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.136116,
			"lon": -115.197067,
			"name": "Ed W Clark High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "4291 Pennwood Avenue",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/32-Ed-W-Clark-High-School/",
				"reviews": "/nevada/las-vegas/32-Ed-W-Clark-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/32-Ed-W-Clark-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "62%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 3267,
			"parentRating": 3,
			"numReviews": 10,
			"studentsPerTeacher": 24,
			"subratings": {
				"College Readiness Rating": 6,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 62
				},
				{
					"label": "Hispanic",
					"percentage": 55
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 18
				},
				{
					"label": "African American",
					"percentage": 11
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 234,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.229397,
			"lon": -115.292686,
			"name": "Edith Garehime Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3850 Campbell Road",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/234-Edith-Garehime-Elementary-School/",
				"reviews": "/nevada/las-vegas/234-Edith-Garehime-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/234-Edith-Garehime-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "50%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 765,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 50
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 29
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 39
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 841,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.28157,
			"lon": -115.304405,
			"name": "Edmundo Eddie Escobedo SR Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "9501 Echelon Point Drive",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/841-Edmundo-Eddie-Escobedo-Sr-Middle-School/",
				"reviews": "/nevada/las-vegas/841-Edmundo-Eddie-Escobedo-Sr-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/841-Edmundo-Eddie-Escobedo-Sr-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "46%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1195,
			"parentRating": 2,
			"numReviews": 19,
			"studentsPerTeacher": 28,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 46
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 28
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 34
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 126,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.191395,
			"lon": -115.247917,
			"name": "Edythe & Lloyd Katz Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1800 Rock Springs Drive",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/126-Edythe--Lloyd-Katz-Elementary-School/",
				"reviews": "/nevada/las-vegas/126-Edythe--Lloyd-Katz-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/126-Edythe--Lloyd-Katz-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 678,
			"parentRating": 3,
			"numReviews": 18,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 24
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 44
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"rating": 3,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 633,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.18045,
			"lon": -115.035942,
			"name": "Eileen Brookman Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6225 East Washington Avenue",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/633-Eileen-Brookman-Elementary-School/",
				"reviews": "/nevada/las-vegas/633-Eileen-Brookman-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/633-Eileen-Brookman-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "63%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 738,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 63
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 64
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 777,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.229889,
			"lon": -115.321213,
			"name": "Eileen Conners Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3810 Shadow Peak Street",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/777-Eileen-Conners-Elementary-School/",
				"reviews": "/nevada/las-vegas/777-Eileen-Conners-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/777-Eileen-Conners-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "52%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 674,
			"parentRating": 4,
			"numReviews": 22,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 52
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 31
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 35
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 180,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.135986,
			"lon": -115.218925,
			"name": "Elaine Wynn Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5655 Edna Avenue",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/180-Elaine-Wynn-Elementary-School/",
				"reviews": "/nevada/las-vegas/180-Elaine-Wynn-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/180-Elaine-Wynn-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 919,
			"parentRating": 1,
			"numReviews": 1,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 72
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 33,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.170902,
			"lon": -115.075233,
			"name": "Elbert Edwards Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4551 Diamond Head Drive",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/33-Elbert-Edwards-Elementary-School/",
				"reviews": "/nevada/las-vegas/33-Elbert-Edwards-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/33-Elbert-Edwards-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 674,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 85
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 34,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.179646,
			"lon": -115.050507,
			"name": "Eldorado High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "1139 North Linn Lane",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/34-Eldorado-High-School/",
				"reviews": "/nevada/las-vegas/34-Eldorado-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/34-Eldorado-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "67%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2043,
			"parentRating": 3,
			"numReviews": 10,
			"studentsPerTeacher": 23,
			"subratings": {
				"College Readiness Rating": 2,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 67
				},
				{
					"label": "Hispanic",
					"percentage": 74
				},
				{
					"label": "African American",
					"percentage": 11
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1475,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.179646,
			"lon": -115.050507,
			"name": "Eldorado Prep",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "1139 North Linn Lane",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1475-Eldorado-Prep/",
				"reviews": "/nevada/las-vegas/1475-Eldorado-Prep/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1475-Eldorado-Prep/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "90%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 181,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 90
				},
				{
					"label": "Hispanic",
					"percentage": 89
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Asian",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 980,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.191307,
			"lon": -115.069717,
			"name": "Eob Head Start-Las Vegas",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1750 Marion Dr",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/980-Eob-Head-Start-Las-Vegas/",
				"reviews": "/nevada/las-vegas/980-Eob-Head-Start-Las-Vegas/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/980-Eob-Head-Start-Las-Vegas/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1484,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "m,h",
			"lat": 36.172794,
			"lon": -115.086792,
			"name": "Equipo Academy",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "4131 East Bonanza Road",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1484-Equipo-Academy-School---18433/",
				"reviews": "/nevada/las-vegas/1484-Equipo-Academy-School---18433/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1484-Equipo-Academy-School---18433/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 753,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 21,
			"subratings": {
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "Hispanic",
					"percentage": 96
				},
				{
					"label": "White",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 133,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.200974,
			"lon": -115.29409,
			"name": "Ernest Becker Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "9151 Pinewood Hills Drive",
				"street2": null,
				"zip": "89134",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89134",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/133-Ernest-Becker-Middle-School/",
				"reviews": "/nevada/las-vegas/133-Ernest-Becker-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/133-Ernest-Becker-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "59%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1420,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 59
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 35
				},
				{
					"label": "Pacific Islander",
					"rating": 9,
					"percentage": 3
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 193,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.25507,
			"lon": -115.232819,
			"name": "Ernest May Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6350 West Washburn Road",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/193-Ernest-J.-May-Elementary-School/",
				"reviews": "/nevada/las-vegas/193-Ernest-J.-May-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/193-Ernest-J.-May-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 558,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 53
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 33
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 38
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 595,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.190247,
			"lon": -115.335381,
			"name": "Ethel W. Staton Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1700 Sageberry Drive",
				"street2": null,
				"zip": "89144",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89144",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/595-Ethel-W.-Staton-Elementary-School/",
				"reviews": "/nevada/las-vegas/595-Ethel-W.-Staton-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/595-Ethel-W.-Staton-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "22%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 785,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 22
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 18
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 53
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1211,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 35.986893,
			"lon": -115.208138,
			"name": "Evelyn Stuckey Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4905 Chartan Avenue",
				"street2": null,
				"zip": "89141",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89141",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1211-Evelyn-Stuckey-Elementary-School/",
				"reviews": "/nevada/las-vegas/1211-Evelyn-Stuckey-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1211-Evelyn-Stuckey-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "28%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 682,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 9,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 28
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 10,
					"percentage": 23
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 43
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 681,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 36.159401,
			"lon": -115.1278,
			"name": "Exodus Christian Academy",
			"gradeLevels": "K-9",
			"assigned": null,
			"address": {
				"street1": "407 Spencer Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/681-Exodus-Christian-Academy/",
				"reviews": "/nevada/las-vegas/681-Exodus-Christian-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/681-Exodus-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 729,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.082211,
			"lon": -115.073936,
			"name": "Explore Knowledge Academy Charter Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5871 South Mountain Vista Street",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/729-Explore-Knowledge-Academy/",
				"reviews": "/nevada/las-vegas/729-Explore-Knowledge-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/729-Explore-Knowledge-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 462,
			"parentRating": 3,
			"numReviews": 42,
			"studentsPerTeacher": 28,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 41
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 34
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 844,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.082211,
			"lon": -115.073936,
			"name": "Explore Knowledge Academy Charter Secondary",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "5871 South Mountain Vista Street",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/844-Explore-Knowledge-Academy-Charter-Secondary/",
				"reviews": "/nevada/las-vegas/844-Explore-Knowledge-Academy-Charter-Secondary/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/844-Explore-Knowledge-Academy-Charter-Secondary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "6%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 288,
			"parentRating": 3,
			"numReviews": 5,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"College Readiness Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 6
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 37
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 745,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.141079,
			"lon": -115.325653,
			"name": "Faith Lutheran Academy",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2700 South Town Center Drive",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/745-Faith-Lutheran-Academy/",
				"reviews": "/nevada/las-vegas/745-Faith-Lutheran-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/745-Faith-Lutheran-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 289,
			"parentRating": 4,
			"numReviews": 28,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 79
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 493,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 36.148506,
			"lon": -115.315567,
			"name": "Faith Lutheran Middle School & High School",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "2015 S Hualapai Way",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/493-Faith-Lutheran-Middle-School--High-School/",
				"reviews": "/nevada/las-vegas/493-Faith-Lutheran-Middle-School--High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/493-Faith-Lutheran-Middle-School--High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1366,
			"parentRating": 3,
			"numReviews": 39,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 76
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1457,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.156124,
			"lon": -115.320694,
			"name": "Faith Lutheran Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "10405 Griffith Peak",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1457-Faith-Lutheran-Preschool/",
				"reviews": "/nevada/las-vegas/1457-Faith-Lutheran-Preschool/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1457-Faith-Lutheran-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1033,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.21822,
			"lon": -115.230446,
			"name": "Fellowship Christian Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6210 W Cheyenne Ave",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1033-Fellowship-Christian-Day-Care/",
				"reviews": "/nevada/las-vegas/1033-Fellowship-Christian-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1033-Fellowship-Christian-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1136,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.225155,
			"lon": -115.259895,
			"name": "Fielday School KinderCare",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3570 N. Buffalo Dr.",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1136-Fielday-School-KinderCare/",
				"reviews": "/nevada/las-vegas/1136-Fielday-School-KinderCare/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1136-Fielday-School-KinderCare/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 706,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.172955,
			"lon": -115.17131,
			"name": "First Christian Montessori Childrens World",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "101 South Rancho Drive",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/706-First-Christian-Montessori-Childrens-World/",
				"reviews": "/nevada/las-vegas/706-First-Christian-Montessori-Childrens-World/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/706-First-Christian-Montessori-Childrens-World/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 30,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 40
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 20
				},
				{
					"label": "White",
					"percentage": 20
				},
				{
					"label": "Pacific Islander",
					"percentage": 20
				}
			],
			"remediationData": {}
		},
		{
			"id": 486,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.162933,
			"lon": -115.134094,
			"name": "First Good Shepherd Lutheran School",
			"gradeLevels": "PK-6",
			"assigned": null,
			"address": {
				"street1": "301 South Maryland Parkway",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/486-First-Good-Shepherd-Lutheran-School/",
				"reviews": "/nevada/las-vegas/486-First-Good-Shepherd-Lutheran-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/486-First-Good-Shepherd-Lutheran-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 217,
			"parentRating": 5,
			"numReviews": 16,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 70
				},
				{
					"label": "African American",
					"percentage": 15
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1015,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.188145,
			"lon": -115.070564,
			"name": "First Step Kids Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4701 East Owens Avenue",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1015-First-Step-Kids-Care/",
				"reviews": "/nevada/las-vegas/1015-First-Step-Kids-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1015-First-Step-Kids-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1558,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.148251,
			"lon": -115.11171,
			"name": "Florence McClure Correctional Center",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "2701 East Saint Louis Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1558-Florence-McClure-Correctional-Center/",
				"reviews": "/nevada/las-vegas/1558-Florence-McClure-Correctional-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1558-Florence-McClure-Correctional-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1466,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m,h",
			"lat": 36.232162,
			"lon": -115.222984,
			"name": "Founders Academy of Nevada",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "5730 West Alexander Road",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1466-Founders-Academy-Las-Vegas/",
				"reviews": "/nevada/las-vegas/1466-Founders-Academy-Las-Vegas/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1466-Founders-Academy-Las-Vegas/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "17%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 714,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 17,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 17
				},
				{
					"label": "White",
					"percentage": 50
				},
				{
					"label": "Hispanic",
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 237,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.091927,
			"lon": -115.058182,
			"name": "Francis H Cortney Junior High School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "5301 East Hacienda Avenue",
				"street2": null,
				"zip": "89122",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89122",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/237-Francis-H-Cortney-Junior-High-School/",
				"reviews": "/nevada/las-vegas/237-Francis-H-Cortney-Junior-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/237-Francis-H-Cortney-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "82%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1236,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 82
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 55
				},
				{
					"label": "Pacific Islander",
					"rating": 3,
					"percentage": 3
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"rating": 3,
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 36,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.169224,
			"lon": -115.234207,
			"name": "Frank F Garside Junior High School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "300 South Torrey Pines Drive",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/36-Frank-F-Garside-Junior-High-School/",
				"reviews": "/nevada/las-vegas/36-Frank-F-Garside-Junior-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/36-Frank-F-Garside-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "86%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1266,
			"parentRating": 2,
			"numReviews": 11,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 86
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Asian",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 68
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"rating": 4,
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 166,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.105785,
			"lon": -115.255325,
			"name": "Frank Kim Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7600 Peace Way",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/166-Frank-Kim-Elementary-School/",
				"reviews": "/nevada/las-vegas/166-Frank-Kim-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/166-Frank-Kim-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "69%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 528,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 69
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 42
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"rating": 1,
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 988,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.180676,
			"lon": -115.173851,
			"name": "Friendship Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2301 W Washington Ave",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/988-Friendship-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/988-Friendship-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/988-Friendship-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1559,
			"districtId": 23,
			"districtName": "Achievement School District",
			"districtCity": "Las Vegas",
			"levelCode": "e",
			"lat": 36.182041,
			"lon": -115.081306,
			"name": "Futuro Academy Elementary",
			"gradeLevels": "K-2",
			"assigned": null,
			"address": {
				"street1": "920 North Lamb Boulevard",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1559-Futuro-Academy-Elementary/",
				"reviews": "/nevada/las-vegas/1559-Futuro-Academy-Elementary/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1559-Futuro-Academy-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "87%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 259,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 87
				},
				{
					"label": "Hispanic",
					"percentage": 78
				},
				{
					"label": "African American",
					"percentage": 10
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 37,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.092724,
			"lon": -115.13176,
			"name": "Gene Ward Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1555 East Hacienda Avenue",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/37-Gene-Ward-Elementary-School/",
				"reviews": "/nevada/las-vegas/37-Gene-Ward-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/37-Gene-Ward-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 711,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 68
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 38,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.123474,
			"lon": -115.090927,
			"name": "George E Harris Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3620 South Sandhill Road",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/38-George-E-Harris-Elementary-School/",
				"reviews": "/nevada/las-vegas/38-George-E-Harris-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/38-George-E-Harris-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 676,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 62
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 686,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 36.134899,
			"lon": -115.279602,
			"name": "Glad Tidings Christian School",
			"gradeLevels": "K-11",
			"assigned": null,
			"address": {
				"street1": "8612 Paddle Wheel Way",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/686-Glad-Tidings-Christian-School/",
				"reviews": "/nevada/las-vegas/686-Glad-Tidings-Christian-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/686-Glad-Tidings-Christian-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 155,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.180084,
			"lon": -115.093071,
			"name": "Global Community High School at Morris Hall",
			"gradeLevels": "8-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "3801 East Washington Avenue",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/155-Global-Community-High-School-At-Morris-Hall/",
				"reviews": "/nevada/las-vegas/155-Global-Community-High-School-At-Morris-Hall/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/155-Global-Community-High-School-At-Morris-Hall/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "98%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 157,
			"parentRating": 5,
			"numReviews": 1,
			"studentsPerTeacher": 10,
			"subratings": {
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 98
				},
				{
					"label": "Hispanic",
					"percentage": 92
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "White",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 970,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.183029,
			"lon": -115.170212,
			"name": "Godsend Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1001 N Tonopah Dr",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/970-Godsend-Learning-Center/",
				"reviews": "/nevada/las-vegas/970-Godsend-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/970-Godsend-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1021,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.17485,
			"lon": -115.064743,
			"name": "Grandma's House",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4995 Irene Ave",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1021-Grandmas-House/",
				"reviews": "/nevada/las-vegas/1021-Grandmas-House/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1021-Grandmas-House/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 134,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.088821,
			"lon": -115.237358,
			"name": "Grant Sawyer Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "5450 Redwood Street",
				"street2": null,
				"zip": "89118",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89118",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/134-Grant-Sawyer-Middle-School/",
				"reviews": "/nevada/las-vegas/134-Grant-Sawyer-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/134-Grant-Sawyer-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1202,
			"parentRating": 4,
			"numReviews": 16,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 46
				},
				{
					"label": "Pacific Islander",
					"rating": 8,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				}
			],
			"remediationData": {}
		},
		{
			"id": 704,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.152355,
			"lon": -115.128357,
			"name": "Griffith United Methodist Preschool",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1701 East Oakey Boulevard",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/704-Griffith-United-Methodist-Preschool/",
				"reviews": "/nevada/las-vegas/704-Griffith-United-Methodist-Preschool/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/704-Griffith-United-Methodist-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 68,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 50
				},
				{
					"label": "White",
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 176,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.232006,
			"lon": -115.094833,
			"name": "Gwendolyn Woolley Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3955 North Timberlake Drive",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/176-Gwendolyn-Woolley-Elementary-School/",
				"reviews": "/nevada/las-vegas/176-Gwendolyn-Woolley-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/176-Gwendolyn-Woolley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 754,
			"parentRating": 3,
			"numReviews": 4,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 72
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 593,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.130512,
			"lon": -115.062263,
			"name": "Hal Smith Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5150 East Desert Inn Road",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/593-Hal-Smith-Elementary-School/",
				"reviews": "/nevada/las-vegas/593-Hal-Smith-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/593-Hal-Smith-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 990,
			"parentRating": 2,
			"numReviews": 12,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 37
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 42
				},
				{
					"label": "White",
					"rating": 2,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"rating": 1,
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 41,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.17717,
			"lon": -115.122604,
			"name": "Halle Hewetson Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "701 North 20th Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/41-Halle-Hewetson-Elementary-School/",
				"reviews": "/nevada/las-vegas/41-Halle-Hewetson-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/41-Halle-Hewetson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 937,
			"parentRating": 4,
			"numReviews": 3,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 85
				},
				{
					"label": "White",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1379,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 35.994087,
			"lon": -115.26162,
			"name": "Hand In Hand Pre-Sch-Buffalo",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "South Buffalo Drive",
				"street2": null,
				"zip": "89179",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89179",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1379-Hand-In-Hand-Pre-Sch-Buffalo/",
				"reviews": "/nevada/las-vegas/1379-Hand-In-Hand-Pre-Sch-Buffalo/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1379-Hand-In-Hand-Pre-Sch-Buffalo/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1398,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.021061,
			"lon": -115.131256,
			"name": "Hand In Hand Pre-School & Cdc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1550 East Serene Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1398-Hand-In-Hand-Pre-School--Cdc/",
				"reviews": "/nevada/las-vegas/1398-Hand-In-Hand-Pre-School--Cdc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1398-Hand-In-Hand-Pre-School--Cdc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1245,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.021084,
			"lon": -115.131142,
			"name": "Hand in Hand Preschool & EDC",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1550 East Serene Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1245-Hand-In-Hand-Preschool--EDC/",
				"reviews": "/nevada/las-vegas/1245-Hand-In-Hand-Preschool--EDC/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1245-Hand-In-Hand-Preschool--EDC/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 688,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.146206,
			"lon": -115.193642,
			"name": "Happy Days Kindergarten",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "2301 Las Verdes Street",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/688-Happy-Days-Kindergarten/",
				"reviews": "/nevada/las-vegas/688-Happy-Days-Kindergarten/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/688-Happy-Days-Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 10,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 60
				},
				{
					"label": "Hispanic",
					"percentage": 20
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 20
				}
			],
			"remediationData": {}
		},
		{
			"id": 42,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.091309,
			"lon": -115.06678,
			"name": "Harley Harmon Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5351 Hillsboro Lane",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/42-Harley-Harmon-Elementary-School/",
				"reviews": "/nevada/las-vegas/42-Harley-Harmon-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/42-Harley-Harmon-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "85%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 720,
			"parentRating": 3,
			"numReviews": 11,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 85
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 75
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 43,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.108665,
			"lon": -115.228203,
			"name": "Harvey N  Dondero Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4450 Ridgeville Street",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/43-Harvey-N--Dondero-Elementary-School/",
				"reviews": "/nevada/las-vegas/43-Harvey-N--Dondero-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/43-Harvey-N--Dondero-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 763,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "African American",
					"rating": 9,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 71
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 44,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.084141,
			"lon": -115.115005,
			"name": "Helen C Cannon Junior High School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "5850 Euclid Street",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/44-Helen-C-Cannon-Junior-High-School/",
				"reviews": "/nevada/las-vegas/44-Helen-C-Cannon-Junior-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/44-Helen-C-Cannon-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "84%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 993,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 84
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 69
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"rating": 3,
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 190,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.200787,
			"lon": -115.031403,
			"name": "Helen Herr Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6475 Eagle Creek Lane",
				"street2": null,
				"zip": "89156",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89156",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/190-Helen-Herr-Elementary-School/",
				"reviews": "/nevada/las-vegas/190-Helen-Herr-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/190-Helen-Herr-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "82%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 718,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 82
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 70
				},
				{
					"label": "White",
					"rating": 2,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 191,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.095718,
			"lon": -115.220337,
			"name": "Helen Jydstrup Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5150 Duneville Street",
				"street2": null,
				"zip": "89118",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89118",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/191-Helen-Jydstrup-Elementary-School/",
				"reviews": "/nevada/las-vegas/191-Helen-Jydstrup-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/191-Helen-Jydstrup-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 686,
			"parentRating": 3,
			"numReviews": 13,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 37
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 30
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 10
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 46,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.169243,
			"lon": -115.253845,
			"name": "Helen M Smith Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7101 Pinedale Avenue",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/46-Helen-M-Smith-Elementary-School/",
				"reviews": "/nevada/las-vegas/46-Helen-M-Smith-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/46-Helen-M-Smith-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "99%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 542,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 99
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 48
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1354,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.107445,
			"lon": -115.253838,
			"name": "Helen Meyer Community Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4525 New Forest Drive",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1354-Helen-Meyer-Community-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1354-Helen-Meyer-Community-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1354-Helen-Meyer-Community-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1203,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.296207,
			"lon": -115.320938,
			"name": "Henry & Evelyn Bozarth Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7431 Egan Crest Drive",
				"street2": null,
				"zip": "89166",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89166",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1203-Henry--Evelyn-Bozarth-Elementary-School/",
				"reviews": "/nevada/las-vegas/1203-Henry--Evelyn-Bozarth-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1203-Henry--Evelyn-Bozarth-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "19%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 847,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 19
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1008,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.115875,
			"lon": -115.062813,
			"name": "Herb Kaufman Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4020 Perry Street",
				"street2": null,
				"zip": "89122",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89122",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1008-Herb-Kaufman-Head-Start/",
				"reviews": "/nevada/las-vegas/1008-Herb-Kaufman-Head-Start/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1008-Herb-Kaufman-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 183,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.150333,
			"lon": -115.276443,
			"name": "Herbert A Derfelt Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1900 South Lisa Lane",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/183-Herbert-A-Derfelt-Elementary-School/",
				"reviews": "/nevada/las-vegas/183-Herbert-A-Derfelt-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/183-Herbert-A-Derfelt-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "65%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 612,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 65
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 36
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 25
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 655,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.100544,
			"lon": -115.093765,
			"name": "Hill And Dale Child Development Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3720 East Tropicana Avenue",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/655-Hill-And-Dale-Child-Development-Center/",
				"reviews": "/nevada/las-vegas/655-Hill-And-Dale-Child-Development-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/655-Hill-And-Dale-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 85,
			"parentRating": 5,
			"numReviews": 14,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 50
				},
				{
					"label": "Hispanic",
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 10
				}
			],
			"remediationData": {}
		},
		{
			"id": 1419,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.152187,
			"lon": -115.022476,
			"name": "Hollywood Park & Rec Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1650 South Hollywood Boulevard",
				"street2": null,
				"zip": "89142",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89142",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1419-Hollywood-Park--Rec-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1419-Hollywood-Park--Rec-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1419-Hollywood-Park--Rec-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 746,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 36.18,
			"lon": -115.150002,
			"name": "House Of Knowledge Christian Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "Po Box 270097",
				"street2": null,
				"zip": "89127",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89127",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/746-House-Of-Knowledge-Christian-Academy/",
				"reviews": "/nevada/las-vegas/746-House-Of-Knowledge-Christian-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/746-House-Of-Knowledge-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 23,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "African American",
					"percentage": 70
				},
				{
					"label": "White",
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 612,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.30291,
			"lon": -115.2127,
			"name": "Howard E Heckethorn Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5150 Whispering Sands Drive",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/612-Howard-E-Heckethorn-Elementary-School/",
				"reviews": "/nevada/las-vegas/612-Howard-E-Heckethorn-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/612-Howard-E-Heckethorn-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "34%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 642,
			"parentRating": 4,
			"numReviews": 4,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 34
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 47
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 720,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.164036,
			"lon": -115.124527,
			"name": "Howard E Hollingsworth Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1776 East Ogden Avenue",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/720-Howard-E-Hollingsworth-Elementary-School/",
				"reviews": "/nevada/las-vegas/720-Howard-E-Hollingsworth-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/720-Howard-E-Hollingsworth-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 625,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 14,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 71
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 48,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.162148,
			"lon": -115.180069,
			"name": "Howard Wasden Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2831 Palomino Lane",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/48-Howard-Wasden-Elementary-School/",
				"reviews": "/nevada/las-vegas/48-Howard-Wasden-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/48-Howard-Wasden-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 581,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 47
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1020,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.189655,
			"lon": -115.06488,
			"name": "Hullum Homes Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4980 E Owens Ave",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1020-Hullum-Homes-Head-Start/",
				"reviews": "/nevada/las-vegas/1020-Hullum-Homes-Head-Start/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1020-Hullum-Homes-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 49,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.161823,
			"lon": -115.194473,
			"name": "Hyde Park Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "900 Hinson Street",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/49-Hyde-Park-Middle-School/",
				"reviews": "/nevada/las-vegas/49-Hyde-Park-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/49-Hyde-Park-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "57%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1606,
			"parentRating": 4,
			"numReviews": 34,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 57
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 48
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 17
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 696,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.165928,
			"lon": -115.162704,
			"name": "Imagination Plus Child Care Center",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "1617 Alta Drive",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/696-Imagination-Plus-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/696-Imagination-Plus-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/696-Imagination-Plus-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 18,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "African American",
					"percentage": 67
				},
				{
					"label": "Hispanic",
					"percentage": 17
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 1254,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e",
			"lat": 36.281219,
			"lon": -115.279221,
			"name": "Imagine School Mountain View",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "6610 Grand Montecito Parkway",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1254-Imagine-School-Mountain-View/",
				"reviews": "/nevada/las-vegas/1254-Imagine-School-Mountain-View/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1254-Imagine-School-Mountain-View/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "29%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 712,
			"parentRating": 4,
			"numReviews": 26,
			"studentsPerTeacher": 21,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 29
				},
				{
					"label": "Hispanic",
					"percentage": 32
				},
				{
					"label": "White",
					"percentage": 29
				},
				{
					"label": "African American",
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 747,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.041218,
			"lon": -115.132271,
			"name": "Imprints Day School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8185 Tamarus Street",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/747-Imprints-Day-School/",
				"reviews": "/nevada/las-vegas/747-Imprints-Day-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/747-Imprints-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 815,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.144478,
			"lon": -115.141579,
			"name": "Innovations International Charter School of NV",
			"gradeLevels": "6-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "950 East Sahara Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/815-Innovations-International-Charter-School-Of-NV/",
				"reviews": "/nevada/las-vegas/815-Innovations-International-Charter-School-Of-NV/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/815-Innovations-International-Charter-School-Of-NV/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "64%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 325,
			"parentRating": 3,
			"numReviews": 11,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 6,
				"College Readiness Rating": 1,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 64
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 65
				},
				{
					"label": "White",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 835,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.152245,
			"lon": -115.129257,
			"name": "Innovations International Charter School of NV",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1600 East Oakey Boulevard",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/835-Innovations-International-Charter-School-Of-Nv/",
				"reviews": "/nevada/las-vegas/835-Innovations-International-Charter-School-Of-Nv/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/835-Innovations-International-Charter-School-Of-Nv/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 542,
			"parentRating": 3,
			"numReviews": 12,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 71
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 65
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1040,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.175411,
			"lon": -115.269951,
			"name": "International Christian Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "8100 Westcliff Drive",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1040-International-Christian-Academy/",
				"reviews": "/nevada/las-vegas/1040-International-Christian-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1040-International-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 400,
			"parentRating": 4,
			"numReviews": 36,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 52,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.185978,
			"lon": -115.072174,
			"name": "Ira J Earl Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1463 Marion Drive",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/52-Ira-J-Earl-Elementary-School/",
				"reviews": "/nevada/las-vegas/52-Ira-J-Earl-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/52-Ira-J-Earl-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 791,
			"parentRating": 3,
			"numReviews": 3,
			"studentsPerTeacher": 14,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 83
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 235,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.228817,
			"lon": -115.26413,
			"name": "Irwin & Susan Molasky Junior High School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "7801 West Gilmore Avenue",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/235-Irwin--Susan-Molasky-Junior-High-School/",
				"reviews": "/nevada/las-vegas/235-Irwin--Susan-Molasky-Junior-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/235-Irwin--Susan-Molasky-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "79%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1066,
			"parentRating": 2,
			"numReviews": 17,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 79
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 24
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 47
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"rating": 4,
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 55,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.204872,
			"lon": -115.227539,
			"name": "J  Harold Brinley Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "2480 Maverick Street",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/55-J--Harold-Brinley-Middle-School/",
				"reviews": "/nevada/las-vegas/55-J--Harold-Brinley-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/55-J--Harold-Brinley-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1014,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 64
				},
				{
					"label": "White",
					"rating": 2,
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"rating": 2,
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 56,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.104221,
			"lon": -115.067741,
			"name": "J M Ullom Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4869 East Sun Valley Drive",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/56-J-M-Ullom-Elementary-School/",
				"reviews": "/nevada/las-vegas/56-J-M-Ullom-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/56-J-M-Ullom-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 662,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 69
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 57,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.1856,
			"lon": -115.210915,
			"name": "J T McWilliams Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1315 Hiawatha Road",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/57-J-T-Mcwilliams-Elementary-School/",
				"reviews": "/nevada/las-vegas/57-J-T-Mcwilliams-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/57-J-T-Mcwilliams-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 846,
			"parentRating": 4,
			"numReviews": 3,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 78
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 54,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.239185,
			"lon": -115.067818,
			"name": "J. E. Manch Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4351 North Lamont Street",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/54-J-E-Manch-Elementary-School/",
				"reviews": "/nevada/las-vegas/54-J-E-Manch-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/54-J-E-Manch-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 898,
			"parentRating": 3,
			"numReviews": 7,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 43
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 41
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 189,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.095947,
			"lon": -115.124809,
			"name": "Jack Dailey Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2001 East Reno Avenue",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/189-Jack-Dailey-Elementary-School/",
				"reviews": "/nevada/las-vegas/189-Jack-Dailey-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/189-Jack-Dailey-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 795,
			"parentRating": 3,
			"numReviews": 11,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 67
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 621,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.032169,
			"lon": -115.1287,
			"name": "Jack Lund Schofield Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "8625 South Spencer Street",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/621-Jack-Lund-Schofield-Middle-School/",
				"reviews": "/nevada/las-vegas/621-Jack-Lund-Schofield-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/621-Jack-Lund-Schofield-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "55%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1212,
			"parentRating": 3,
			"numReviews": 7,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 55
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 38
				},
				{
					"label": "Pacific Islander",
					"rating": 5,
					"percentage": 4
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 123,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.193577,
			"lon": -115.2481,
			"name": "James B McMillan Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7000 Walt Lott Drive",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/123-James-B-Mcmillan-Elementary-School/",
				"reviews": "/nevada/las-vegas/123-James-B-Mcmillan-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/123-James-B-Mcmillan-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 621,
			"parentRating": 2,
			"numReviews": 18,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 29
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 41
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 718,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.319511,
			"lon": -115.299583,
			"name": "James Bilbray Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9370 Brent Lane",
				"street2": null,
				"zip": "89143",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89143",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/718-James-Bilbray-Elementary-School/",
				"reviews": "/nevada/las-vegas/718-James-Bilbray-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/718-James-Bilbray-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 723,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 52
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 58,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.130302,
			"lon": -115.204308,
			"name": "James Cashman Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "4622 West Desert Inn Road",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/58-James-Cashman-Middle-School/",
				"reviews": "/nevada/las-vegas/58-James-Cashman-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/58-James-Cashman-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "78%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1548,
			"parentRating": 3,
			"numReviews": 20,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 78
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 67
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1519,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 35.987152,
			"lon": -115.257889,
			"name": "Jan Jones Blackhurst Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "11141 South Pioneer Way",
				"street2": null,
				"zip": "89179",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89179",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1519-Jan-Jones-Blackhurst-Elementary-School/",
				"reviews": "/nevada/las-vegas/1519-Jan-Jones-Blackhurst-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1519-Jan-Jones-Blackhurst-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "27%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 793,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 27
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 28
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 783,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.201946,
			"lon": -115.095062,
			"name": "Jay W. Jeffers Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2320 Clifford Street",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/783-Jay-W.-Jeffers-Elementary-School/",
				"reviews": "/nevada/las-vegas/783-Jay-W.-Jeffers-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/783-Jay-W.-Jeffers-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 809,
			"parentRating": 5,
			"numReviews": 2,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 85
				},
				{
					"label": "White",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1562,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.137142,
			"lon": -115.025398,
			"name": "Jenkins  Earl N Elementary School",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "6950 Vegas Valley Drive",
				"street2": null,
				"zip": "89142",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89142",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1562-Jenkins--Earl-N-Elementary-School/",
				"reviews": "/nevada/las-vegas/1562-Jenkins--Earl-N-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1562-Jenkins--Earl-N-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 772,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.141369,
			"lon": -115.082092,
			"name": "Jerome Mack Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "4250 Karen Avenue",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/772-Jerome-Mack-Middle-School/",
				"reviews": "/nevada/las-vegas/772-Jerome-Mack-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/772-Jerome-Mack-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1301,
			"parentRating": 2,
			"numReviews": 14,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Asian",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 73
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"rating": 3,
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 527,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 36.126099,
			"lon": -115.112396,
			"name": "Jewish Community Day School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2761 Emerson Avenue",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/527-Jewish-Community-Day-School/",
				"reviews": "/nevada/las-vegas/527-Jewish-Community-Day-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/527-Jewish-Community-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 61,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.146744,
			"lon": -115.139633,
			"name": "John C  Fremont Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1100 East Saint Louis Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/61-John-C--Fremont-Middle-School/",
				"reviews": "/nevada/las-vegas/61-John-C--Fremont-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/61-John-C--Fremont-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 847,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 68
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 589,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.001369,
			"lon": -115.166443,
			"name": "John C. Bass Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "10377 Rancho Destino Road",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/589-John-C.-Bass-Elementary-School/",
				"reviews": "/nevada/las-vegas/589-John-C.-Bass-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/589-John-C.-Bass-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 664,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 29
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 14
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 171,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.149555,
			"lon": -115.046417,
			"name": "John F Mendoza Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2000 South Sloan Lane",
				"street2": null,
				"zip": "89142",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89142",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/171-John-F-Mendoza-Elementary-School/",
				"reviews": "/nevada/las-vegas/171-John-F-Mendoza-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/171-John-F-Mendoza-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "79%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 815,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 79
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 77
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 167,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.030998,
			"lon": -115.151886,
			"name": "John R  Beatty Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8685 Hidden Palms Parkway",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/167-John-R--Beatty-Elementary-School/",
				"reviews": "/nevada/las-vegas/167-John-R--Beatty-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/167-John-R--Beatty-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 634,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 31
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 38
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 782,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.011284,
			"lon": -115.158203,
			"name": "John R Hummel Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9800 Placid Street",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/782-John-R-Hummel-Elementary-School/",
				"reviews": "/nevada/las-vegas/782-John-R-Hummel-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/782-John-R-Hummel-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 711,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 32
				},
				{
					"label": "Pacific Islander",
					"rating": 8,
					"percentage": 7
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 14
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 10
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 63,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.154682,
			"lon": -115.141113,
			"name": "John S Park Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "931 Franklin Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/63-John-S-Park-Elementary-School/",
				"reviews": "/nevada/las-vegas/63-John-S-Park-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/63-John-S-Park-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 741,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 63
				},
				{
					"label": "White",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 220,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.180622,
			"lon": -115.325058,
			"name": "John W Bonner Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "765 Crestdale Lane",
				"street2": null,
				"zip": "89144",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89144",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/220-John-W-Bonner-Elementary-School/",
				"reviews": "/nevada/las-vegas/220-John-W-Bonner-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/220-John-W-Bonner-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "23%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 869,
			"parentRating": 4,
			"numReviews": 23,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 23
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 22
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 47
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 14
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 789,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.108093,
			"lon": -115.218903,
			"name": "Joseph E Thiriot Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5700 West Harmon Avenue",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/789-Joseph-E-Thiriot-Elementary-School/",
				"reviews": "/nevada/las-vegas/789-Joseph-E-Thiriot-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/789-Joseph-E-Thiriot-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 724,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 30
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 45
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 570,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.271812,
			"lon": -115.239212,
			"name": "Joseph M Neal Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6651 West Azure Drive",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/570-Joseph-M-Neal-Elementary-School/",
				"reviews": "/nevada/las-vegas/570-Joseph-M-Neal-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/570-Joseph-M-Neal-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 634,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 29
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 45
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1242,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 36.139896,
			"lon": -115.242165,
			"name": "Journey Education",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2710 South Rainbow Blvd",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1242-Journey-Education/",
				"reviews": "/nevada/las-vegas/1242-Journey-Education/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1242-Journey-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 16,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 806,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.051659,
			"lon": -115.245506,
			"name": "Judith D Steele Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6995 West Eldorado Lane",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/806-Judith-D-Steele-Elementary-School/",
				"reviews": "/nevada/las-vegas/806-Judith-D-Steele-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/806-Judith-D-Steele-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "41%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 777,
			"parentRating": 4,
			"numReviews": 17,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 41
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 7,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 20
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 29
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 26
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 779,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.128025,
			"lon": -115.336861,
			"name": "Judy & John L Goolsby Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "11175 West Desert Inn Road",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/779-Judy--John-L-Goolsby-Elementary-School/",
				"reviews": "/nevada/las-vegas/779-Judy--John-L-Goolsby-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/779-Judy--John-L-Goolsby-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "27%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 824,
			"parentRating": 4,
			"numReviews": 26,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 27
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 7,
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 51
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 618,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.245731,
			"lon": -115.263756,
			"name": "Justice Myron E Leavitt Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "4701 Quadrel Street",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/618-Justice-Myron-E-Leavitt-Middle-School/",
				"reviews": "/nevada/las-vegas/618-Justice-Myron-E-Leavitt-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/618-Justice-Myron-E-Leavitt-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "45%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1641,
			"parentRating": 3,
			"numReviews": 23,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 45
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 31
				},
				{
					"label": "Pacific Islander",
					"rating": 10,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 42
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 64,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.145077,
			"lon": -115.115219,
			"name": "K O Knudson Junior High School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "2400 Atlantic Street",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/64-K-O-Knudson-Junior-High-School/",
				"reviews": "/nevada/las-vegas/64-K-O-Knudson-Junior-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/64-K-O-Knudson-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1320,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 70
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 638,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.153748,
			"lon": -115.022232,
			"name": "Kathleen & Tim Harney Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1580 South Hollywood Boulevard",
				"street2": null,
				"zip": "89142",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89142",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/638-Kathleen--Tim-Harney-Middle-School/",
				"reviews": "/nevada/las-vegas/638-Kathleen--Tim-Harney-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/638-Kathleen--Tim-Harney-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1818,
			"parentRating": 4,
			"numReviews": 22,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 57
				},
				{
					"label": "Pacific Islander",
					"rating": 10,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				}
			],
			"remediationData": {}
		},
		{
			"id": 776,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.082199,
			"lon": -115.313713,
			"name": "Kathy L. Batterman Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "10135 West Quail Avenue",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/776-Kathy-L.-Batterman-Elementary-School/",
				"reviews": "/nevada/las-vegas/776-Kathy-L.-Batterman-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/776-Kathy-L.-Batterman-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "38%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 790,
			"parentRating": 4,
			"numReviews": 18,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 38
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 22
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 35
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 15
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 17
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 610,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.265488,
			"lon": -115.219666,
			"name": "Kay Carl Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5625 Corbett Street",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/610-Kay-Carl-Elementary-School/",
				"reviews": "/nevada/las-vegas/610-Kay-Carl-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/610-Kay-Carl-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 788,
			"parentRating": 4,
			"numReviews": 17,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 33
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 35
				},
				{
					"label": "Two or more races",
					"rating": 4,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 568,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.12289,
			"lon": -115.301888,
			"name": "Keith C & Karen W Hayes Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9620 West Twain Avenue",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/568-Keith-C--Karen-W-Hayes-Elementary-School/",
				"reviews": "/nevada/las-vegas/568-Keith-C--Karen-W-Hayes-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/568-Keith-C--Karen-W-Hayes-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 711,
			"parentRating": 5,
			"numReviews": 9,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 30
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1521,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.300003,
			"lon": -115.264763,
			"name": "Kenneth Divich Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9851 Donald Nelson Avenue",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1521-Kenneth-Divich-Elementary/",
				"reviews": "/nevada/las-vegas/1521-Kenneth-Divich-Elementary/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1521-Kenneth-Divich-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "32%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 766,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {
				"Test Scores Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 32
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 46
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 139,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.11327,
			"lon": -115.232262,
			"name": "Kenny C Guinn Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "4150 South Torrey Pines Drive",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/139-Kenny-C-Guinn-Middle-School/",
				"reviews": "/nevada/las-vegas/139-Kenny-C-Guinn-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/139-Kenny-C-Guinn-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1217,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 71
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 51
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 65,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.200176,
			"lon": -115.162231,
			"name": "Kermit R Booker Sr Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2277 North Martin Luther King Boulevard",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/65-Kermit-R-Booker-Sr-Elementary-School/",
				"reviews": "/nevada/las-vegas/65-Kermit-R-Booker-Sr-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/65-Kermit-R-Booker-Sr-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 485,
			"parentRating": 3,
			"numReviews": 4,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 55
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1019,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.148914,
			"lon": -115.06601,
			"name": "Kid's Cove Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4975 East Saint Louis Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1019-Kids-Cove-Pre-School/",
				"reviews": "/nevada/las-vegas/1019-Kids-Cove-Pre-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1019-Kids-Cove-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1009,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.13295,
			"lon": -115.084908,
			"name": "Kid's Quest Child Care Center-Boulder Stn",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4111 Boulder Highway",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1009-Kids-Quest-Child-Care-Center-Boulder-Stn/",
				"reviews": "/nevada/las-vegas/1009-Kids-Quest-Child-Care-Center-Boulder-Stn/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1009-Kids-Quest-Child-Care-Center-Boulder-Stn/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1228,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.100597,
			"lon": -115.301102,
			"name": "Kids Campus Learning Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "9572 West Tropicana Avenue",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1228-Kids-Campus-Learning-Center/",
				"reviews": "/nevada/las-vegas/1228-Kids-Campus-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1228-Kids-Campus-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 76,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 69
				},
				{
					"label": "African American",
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 884,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.237328,
			"lon": -115.207054,
			"name": "Kids Co-Op",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "4316 N Decatur Blvd",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/884-Kids-Co-Op/",
				"reviews": "/nevada/las-vegas/884-Kids-Co-Op/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/884-Kids-Co-Op/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 81,
			"parentRating": 5,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 91
				},
				{
					"label": "Hispanic",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 1027,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.173656,
			"lon": -115.059608,
			"name": "Kids First",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5245 East Bonanza Road",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1027-Kids-First/",
				"reviews": "/nevada/las-vegas/1027-Kids-First/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1027-Kids-First/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1288,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.26244,
			"lon": -115.270325,
			"name": "Kids Kamp At Cimarronrose",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5591 North Cimarron Road",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1288-Kids-Kamp-At-Cimarronrose/",
				"reviews": "/nevada/las-vegas/1288-Kids-Kamp-At-Cimarronrose/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1288-Kids-Kamp-At-Cimarronrose/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1014,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.129986,
			"lon": -115.073296,
			"name": "Kids Learning Path",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4640 East Desert Inn Road",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1014-Kids-Learning-Path/",
				"reviews": "/nevada/las-vegas/1014-Kids-Learning-Path/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1014-Kids-Learning-Path/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1374,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.115379,
			"lon": -115.198143,
			"name": "Kids Quest Child Care Center-Palms",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4321 West Flamingo Road",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1374-Kids-Quest-Child-Care-Center-Palms/",
				"reviews": "/nevada/las-vegas/1374-Kids-Quest-Child-Care-Center-Palms/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1374-Kids-Quest-Child-Care-Center-Palms/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1022,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.097576,
			"lon": -115.224411,
			"name": "Kids R Kids #2 Child Care Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "5000 South Jones Boulevard",
				"street2": null,
				"zip": "89118",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89118",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1022-Kids-R-Kids-2-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1022-Kids-R-Kids-2-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1022-Kids-R-Kids-2-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 900,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.020058,
			"lon": -115.141495,
			"name": "Kids R Kids Of Silverado Ranch",
			"gradeLevels": "PK-1",
			"assigned": null,
			"address": {
				"street1": "985 East Serene Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/900-Kids-R-Kids-Of-Silverado-Ranch/",
				"reviews": "/nevada/las-vegas/900-Kids-R-Kids-Of-Silverado-Ranch/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/900-Kids-R-Kids-Of-Silverado-Ranch/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 63,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 70
				},
				{
					"label": "Hispanic",
					"percentage": 26
				},
				{
					"label": "African American",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 1229,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.097576,
			"lon": -115.224411,
			"name": "Kids R Kids Of Sw Las Vegas",
			"gradeLevels": "K-1",
			"assigned": null,
			"address": {
				"street1": "5000 South Jones Boulevard",
				"street2": null,
				"zip": "89118",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89118",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1229-Kids-R-Kids-Of-Sw-Las-Vegas/",
				"reviews": "/nevada/las-vegas/1229-Kids-R-Kids-Of-Sw-Las-Vegas/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1229-Kids-R-Kids-Of-Sw-Las-Vegas/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 29,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 41
				},
				{
					"label": "Two or more races",
					"percentage": 34
				},
				{
					"label": "African American",
					"percentage": 14
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 698,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.110462,
			"lon": -115.26255,
			"name": "Kids Turf Academy Center",
			"gradeLevels": "PK-1",
			"assigned": null,
			"address": {
				"street1": "7885 W Rochelle Avenue",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/698-Kids-Turf-Academy-Center/",
				"reviews": "/nevada/las-vegas/698-Kids-Turf-Academy-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/698-Kids-Turf-Academy-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 2,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1390,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.014687,
			"lon": -115.13382,
			"name": "Kids Turf Academy Ii",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1380 East Silverado Ranch Boulevard",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1390-Kids-Turf-Academy-Ii/",
				"reviews": "/nevada/las-vegas/1390-Kids-Turf-Academy-Ii/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1390-Kids-Turf-Academy-Ii/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1394,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.102551,
			"lon": -115.201691,
			"name": "Kids Tyme Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4500 W Tropicana Ave",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1394-Kids-Tyme-Day-Care/",
				"reviews": "/nevada/las-vegas/1394-Kids-Tyme-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1394-Kids-Tyme-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1281,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.101017,
			"lon": -115.223412,
			"name": "Kidz Kidz Kidz",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6018 West Tropicana Avenue",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1281-Kidz-Kidz-Kidz/",
				"reviews": "/nevada/las-vegas/1281-Kidz-Kidz-Kidz/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1281-Kidz-Kidz-Kidz/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1261,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.057053,
			"lon": -115.129387,
			"name": "Kinder Care Learning Center #1306",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1655 East Warm Springs Road",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1261-Kinder-Care-Learning-Center-1306/",
				"reviews": "/nevada/las-vegas/1261-Kinder-Care-Learning-Center-1306/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1261-Kinder-Care-Learning-Center-1306/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1258,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.115273,
			"lon": -115.233368,
			"name": "Kinder Care Learning Center #791",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4050 South Torrey Pines Drive",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1258-Kinder-Care-Learning-Center-791/",
				"reviews": "/nevada/las-vegas/1258-Kinder-Care-Learning-Center-791/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1258-Kinder-Care-Learning-Center-791/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 889,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.265053,
			"lon": -115.242836,
			"name": "Kinder Prep Academy",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "5695 North Rainbow Boulevard",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/889-Kinder-Prep-Academy/",
				"reviews": "/nevada/las-vegas/889-Kinder-Prep-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/889-Kinder-Prep-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 54,
			"parentRating": 3,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 58
				},
				{
					"label": "Hispanic",
					"percentage": 21
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 670,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.134766,
			"lon": -115.320602,
			"name": "Kindercare Learning Centers",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3085 Desert Marigold Lane",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/670-Kindercare-Learning-Centers/",
				"reviews": "/nevada/las-vegas/670-Kindercare-Learning-Centers/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/670-Kindercare-Learning-Centers/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 125,
			"parentRating": 4,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 75
				},
				{
					"label": "African American",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 13
				}
			],
			"remediationData": {}
		},
		{
			"id": 188,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.169945,
			"lon": -115.032768,
			"name": "Kirk Adams Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "580 North Fogg Street",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/188-Kirk-Adams-Elementary-School/",
				"reviews": "/nevada/las-vegas/188-Kirk-Adams-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/188-Kirk-Adams-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "65%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 485,
			"parentRating": 5,
			"numReviews": 6,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 65
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 67
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 20
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 66,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.193108,
			"lon": -115.146095,
			"name": "Kit Carson Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1735 D Street",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/66-Kit-Carson-Elementary-School/",
				"reviews": "/nevada/las-vegas/66-Kit-Carson-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/66-Kit-Carson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 405,
			"parentRating": 5,
			"numReviews": 2,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 45
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 46
				},
				{
					"label": "White",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 808,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.311935,
			"lon": -115.217819,
			"name": "Kitty McDonough Ward Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5555 Horse Drive",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/808-Kitty-Mcdonough-Ward-Elementary-School/",
				"reviews": "/nevada/las-vegas/808-Kitty-Mcdonough-Ward-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/808-Kitty-Mcdonough-Ward-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "33%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 793,
			"parentRating": 3,
			"numReviews": 15,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 33
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 20
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 56
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 538,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 36.162201,
			"lon": -115.278297,
			"name": "La Madre Mountain School",
			"gradeLevels": "7-12",
			"assigned": null,
			"address": {
				"street1": "850 S. Durango Dr #106",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/538-La-Madre-Mountain-School/",
				"reviews": "/nevada/las-vegas/538-La-Madre-Mountain-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/538-La-Madre-Mountain-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1041,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.162308,
			"lon": -115.277069,
			"name": "La Petite Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "8451 Boseck Drive",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1041-La-Petite-Academy/",
				"reviews": "/nevada/las-vegas/1041-La-Petite-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1041-La-Petite-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 874,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.199772,
			"lon": -115.27813,
			"name": "La Petite Academy",
			"gradeLevels": "PK-2",
			"assigned": null,
			"address": {
				"street1": "2121 Harbor Island Drive",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/874-La-Petite-Academy/",
				"reviews": "/nevada/las-vegas/874-La-Petite-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/874-La-Petite-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 52,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 748,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.2729,
			"lon": -115.224861,
			"name": "Lamb Of God Lutheran School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6232 North Jones Boulevard",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/748-Lamb-Of-God-Lutheran-School/",
				"reviews": "/nevada/las-vegas/748-Lamb-Of-God-Lutheran-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/748-Lamb-Of-God-Lutheran-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 328,
			"parentRating": 4,
			"numReviews": 59,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 74
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 137,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.165195,
			"lon": -115.139633,
			"name": "Las Vegas Academy of Arts",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "315 South 7th Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/137-Las-Vegas-Academy-Of-Intl-Studies--Performing-And/",
				"reviews": "/nevada/las-vegas/137-Las-Vegas-Academy-Of-Intl-Studies--Performing-And/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/137-Las-Vegas-Academy-Of-Intl-Studies--Performing-And/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "29%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1722,
			"parentRating": 5,
			"numReviews": 23,
			"studentsPerTeacher": 21,
			"subratings": {
				"College Readiness Rating": 10,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 29
				},
				{
					"label": "White",
					"percentage": 44
				},
				{
					"label": "Hispanic",
					"percentage": 29
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1359,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.112377,
			"lon": -115.301636,
			"name": "Las Vegas Athletic Club Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "9615 West Flamingo Road",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1359-Las-Vegas-Athletic-Club-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1359-Las-Vegas-Athletic-Club-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1359-Las-Vegas-Athletic-Club-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1362,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.141354,
			"lon": -115.138397,
			"name": "Las Vegas Athletic Club Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2655 South Maryland Parkway",
				"street2": null,
				"zip": "89109",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89109",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1362-Las-Vegas-Athletic-Club-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1362-Las-Vegas-Athletic-Club-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1362-Las-Vegas-Athletic-Club-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 485,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.1297,
			"lon": -115.222801,
			"name": "Las Vegas Day School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "3275 Red Rock St.",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/485-Las-Vegas-Day-School/",
				"reviews": "/nevada/las-vegas/485-Las-Vegas-Day-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/485-Las-Vegas-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 926,
			"parentRating": 4,
			"numReviews": 25,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 83
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 67,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.146557,
			"lon": -115.0327,
			"name": "Las Vegas High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "6500 East Sahara Avenue",
				"street2": null,
				"zip": "89142",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89142",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/67-Las-Vegas-High-School/",
				"reviews": "/nevada/las-vegas/67-Las-Vegas-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/67-Las-Vegas-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "63%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2815,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 24,
			"subratings": {
				"College Readiness Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 63
				},
				{
					"label": "Hispanic",
					"percentage": 72
				},
				{
					"label": "White",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 496,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.151333,
			"lon": -115.225021,
			"name": "Las Vegas Junior Academy",
			"gradeLevels": "PK-9",
			"assigned": null,
			"address": {
				"street1": "6059 West Oakey Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/496-Las-Vegas-Junior-Academy/",
				"reviews": "/nevada/las-vegas/496-Las-Vegas-Junior-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/496-Las-Vegas-Junior-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 109,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 44
				},
				{
					"label": "White",
					"percentage": 26
				},
				{
					"label": "African American",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 994,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.137913,
			"lon": -115.26931,
			"name": "Las Vegas Kids R Kids-Lakes",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2830 South Cimarron Road",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/994-Las-Vegas-Kids-R-Kids-Lakes/",
				"reviews": "/nevada/las-vegas/994-Las-Vegas-Kids-R-Kids-Lakes/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/994-Las-Vegas-Kids-R-Kids-Lakes/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 68,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.134689,
			"lon": -115.079475,
			"name": "Laura Dearing Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3046 Ferndale Street",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/68-Laura-Dearing-Elementary-School/",
				"reviews": "/nevada/las-vegas/68-Laura-Dearing-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/68-Laura-Dearing-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 856,
			"parentRating": 3,
			"numReviews": 13,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 24
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 59
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 724,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.046368,
			"lon": -115.232918,
			"name": "Lawrence & Heidi Canarelli Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "7808 South Torrey Pines Drive",
				"street2": null,
				"zip": "89139",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89139",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/724-Lawrence--Heidi-Canarelli-Middle-School/",
				"reviews": "/nevada/las-vegas/724-Lawrence--Heidi-Canarelli-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/724-Lawrence--Heidi-Canarelli-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "50%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1773,
			"parentRating": 3,
			"numReviews": 22,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 50
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 25
				},
				{
					"label": "Pacific Islander",
					"rating": 6,
					"percentage": 5
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 24
				}
			],
			"remediationData": {}
		},
		{
			"id": 1522,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "m,h",
			"lat": 36.274513,
			"lon": -115.259468,
			"name": "Leadership Academy of Nevada",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "7495 W. Azure Drive #209",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1522-Leadership-Academy-Of-Nevada/",
				"reviews": "/nevada/las-vegas/1522-Leadership-Academy-Of-Nevada/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1522-Leadership-Academy-Of-Nevada/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "18%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 280,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 18
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 13
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 76
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1397,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.087559,
			"lon": -115.004921,
			"name": "Leap Into Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6395 East Russell Road",
				"street2": null,
				"zip": "89122",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89122",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1397-Leap-Into-Learning-Center/",
				"reviews": "/nevada/las-vegas/1397-Leap-Into-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1397-Leap-Into-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1552,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.03326,
			"lon": -115.246399,
			"name": "Legacy Traditional School Southwest Las Vegas",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "7077 West Wigwam Avenue",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1552-Legacy-Traditional-School---SW-Las-Vegas/",
				"reviews": "/nevada/las-vegas/1552-Legacy-Traditional-School---SW-Las-Vegas/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1552-Legacy-Traditional-School---SW-Las-Vegas/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 69,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.110115,
			"lon": -115.12487,
			"name": "Lewis E Rowe Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4338 South Bruce Street",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/69-Lewis-E-Rowe-Elementary-School/",
				"reviews": "/nevada/las-vegas/69-Lewis-E-Rowe-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/69-Lewis-E-Rowe-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 698,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 57
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 700,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 36.194923,
			"lon": -115.236671,
			"name": "Liberty Baptist Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "6501 West Lake Mead Boulevard",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/700-Liberty-Baptist-Academy/",
				"reviews": "/nevada/las-vegas/700-Liberty-Baptist-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/700-Liberty-Baptist-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 156,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 42
				},
				{
					"label": "Hispanic",
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 19
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 227,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.269562,
			"lon": -115.215317,
			"name": "Lied STEM Academy",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "5350 West Tropical Parkway",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/227-Lied-Middle-School/",
				"reviews": "/nevada/las-vegas/227-Lied-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/227-Lied-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "48%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1127,
			"parentRating": 2,
			"numReviews": 16,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 48
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 33
				},
				{
					"label": "Pacific Islander",
					"rating": 9,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 897,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.198269,
			"lon": -115.290962,
			"name": "Lil Scholar Academy-Summerlin",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "8961 Hillpointe Road",
				"street2": null,
				"zip": "89134",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89134",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/897-Lil-Scholar-Academy-Summerlin/",
				"reviews": "/nevada/las-vegas/897-Lil-Scholar-Academy-Summerlin/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/897-Lil-Scholar-Academy-Summerlin/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 80,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 781,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.2034,
			"lon": -115.025749,
			"name": "Liliam Lujan Hickey Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2450 North Hollywood Boulevard",
				"street2": null,
				"zip": "89156",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89156",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/781-Liliam-Lujan-Hickey-Elementary-School/",
				"reviews": "/nevada/las-vegas/781-Liliam-Lujan-Hickey-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/781-Liliam-Lujan-Hickey-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 765,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 64
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 178,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.20137,
			"lon": -115.236504,
			"name": "Lilly & Wing Fong Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2200 James Bilbray Drive",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/178-Lilly--Wing-Fong-Elementary-School/",
				"reviews": "/nevada/las-vegas/178-Lilly--Wing-Fong-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/178-Lilly--Wing-Fong-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 839,
			"parentRating": 3,
			"numReviews": 10,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 34
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 45
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 778,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.167324,
			"lon": -115.344223,
			"name": "Linda Rankin Givens Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "655 Park Vista Drive",
				"street2": null,
				"zip": "89138",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89138",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/778-Linda-Rankin-Givens-Elementary-School/",
				"reviews": "/nevada/las-vegas/778-Linda-Rankin-Givens-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/778-Linda-Rankin-Givens-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "16%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 748,
			"parentRating": 4,
			"numReviews": 29,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 16
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 14
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 59
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 702,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.149994,
			"lon": -115.243881,
			"name": "Lit'l Scholar Academy & Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1951 South Rainbow Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/702-Litl-Scholar-Academy--Dc/",
				"reviews": "/nevada/las-vegas/702-Litl-Scholar-Academy--Dc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/702-Litl-Scholar-Academy--Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1413,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.178867,
			"lon": -115.164078,
			"name": "Little Annies Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1720 West Bonanza Road",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1413-Little-Annies-Day-Care/",
				"reviews": "/nevada/las-vegas/1413-Little-Annies-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1413-Little-Annies-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1360,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.11441,
			"lon": -115.287155,
			"name": "Little Paradise Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "8985 West Flamingo Road",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1360-Little-Paradise-Day-Care/",
				"reviews": "/nevada/las-vegas/1360-Little-Paradise-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1360-Little-Paradise-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 691,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.1297,
			"lon": -115.104599,
			"name": "Little Scholar",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3233 E Desert Inn Rd",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/691-Little-Scholar/",
				"reviews": "/nevada/las-vegas/691-Little-Scholar/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/691-Little-Scholar/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 813,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.006748,
			"lon": -115.220688,
			"name": "Lois & Jerry Tarkanian Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "5800 West Pyle Avenue",
				"street2": null,
				"zip": "89141",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89141",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/813-Lois--Jerry-Tarkanian-Middle-School/",
				"reviews": "/nevada/las-vegas/813-Lois--Jerry-Tarkanian-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/813-Lois--Jerry-Tarkanian-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "41%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2030,
			"parentRating": 3,
			"numReviews": 18,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 41
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "Pacific Islander",
					"rating": 8,
					"percentage": 4
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 16
				}
			],
			"remediationData": {}
		},
		{
			"id": 72,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.235439,
			"lon": -115.057976,
			"name": "Lomie G Heard Elementary School",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "42 Baer Drive",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/72-Lomie-G-Heard-Elementary-School/",
				"reviews": "/nevada/las-vegas/72-Lomie-G-Heard-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/72-Lomie-G-Heard-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "38%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 580,
			"parentRating": 3,
			"numReviews": 11,
			"studentsPerTeacher": 15,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 38
				},
				{
					"label": "White",
					"percentage": 54
				},
				{
					"label": "Hispanic",
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 10
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1524,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.190968,
			"lon": -115.077271,
			"name": "Lomie G. Heard Elementary School  A Marzano Academy",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4497 Kell Lane",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1524-Lomie-G.-Heard-Elementary-School--A-Marzano-Academy/",
				"reviews": "/nevada/las-vegas/1524-Lomie-G.-Heard-Elementary-School--A-Marzano-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1524-Lomie-G.-Heard-Elementary-School--A-Marzano-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 718,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 72
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1352,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.247414,
			"lon": -115.243507,
			"name": "Lone Mountain Creative Lc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6863 West Lone Mountain Road",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1352-Lone-Mountain-Creative-Lc/",
				"reviews": "/nevada/las-vegas/1352-Lone-Mountain-Creative-Lc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1352-Lone-Mountain-Creative-Lc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1429,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.247414,
			"lon": -115.243507,
			"name": "Lone Mountain Learning Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "6863 West Lone Mountain Road",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1429-Lone-Mountain-Learning-Center/",
				"reviews": "/nevada/las-vegas/1429-Lone-Mountain-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1429-Lone-Mountain-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 147,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 54
				},
				{
					"label": "Hispanic",
					"percentage": 31
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 128,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.054008,
			"lon": -115.153252,
			"name": "Louis Wiener Jr Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "450 East Eldorado Lane",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/128-Louis-Wiener-Jr-Elementary-School/",
				"reviews": "/nevada/las-vegas/128-Louis-Wiener-Jr-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/128-Louis-Wiener-Jr-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "99%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 556,
			"parentRating": 4,
			"numReviews": 14,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 99
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 39
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 36
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1036,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.180542,
			"lon": -115.210701,
			"name": "Lucas Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "712 Donner Street",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1036-Lucas-Day-Care-Center/",
				"reviews": "/nevada/las-vegas/1036-Lucas-Day-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1036-Lucas-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 613,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.088161,
			"lon": -115.285103,
			"name": "Lucille S Rogers Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5535 South Riley Street",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/613-Lucille-S-Rogers-Elementary-School/",
				"reviews": "/nevada/las-vegas/613-Lucille-S-Rogers-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/613-Lucille-S-Rogers-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "46%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 964,
			"parentRating": 5,
			"numReviews": 16,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 46
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 7,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 21
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1037,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.180138,
			"lon": -115.213844,
			"name": "Lustig's Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "713 Huntington Street",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1037-Lustigs-Day-Care-Center/",
				"reviews": "/nevada/las-vegas/1037-Lustigs-Day-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1037-Lustigs-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1353,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.160969,
			"lon": -115.192482,
			"name": "Lvvwd-School's Out Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "853 South Valley View Boulevard",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1353-Lvvwd-Schools-Out-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1353-Lvvwd-Schools-Out-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1353-Lvvwd-Schools-Out-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 168,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.137321,
			"lon": -115.294235,
			"name": "M  J  Christensen Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9001 Mariner Cove Drive",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/168-M--J--Christensen-Elementary-School/",
				"reviews": "/nevada/las-vegas/168-M--J--Christensen-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/168-M--J--Christensen-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "57%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 545,
			"parentRating": 3,
			"numReviews": 13,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 57
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 34
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 34
				},
				{
					"label": "African American",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 74,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.182171,
			"lon": -115.16877,
			"name": "Mabel Hoggard Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "950 North Tonopah Drive",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/74-Mabel-Hoggard-Elementary-School/",
				"reviews": "/nevada/las-vegas/74-Mabel-Hoggard-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/74-Mabel-Hoggard-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "99%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 451,
			"parentRating": 5,
			"numReviews": 8,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 99
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 7,
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 55
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 232,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.191246,
			"lon": -115.081718,
			"name": "Manuel J Cortez Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4245 East Tonopah Avenue",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/232-Manuel-J-Cortez-Elementary-School/",
				"reviews": "/nevada/las-vegas/232-Manuel-J-Cortez-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/232-Manuel-J-Cortez-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 750,
			"parentRating": 3,
			"numReviews": 8,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 74
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 125,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.224937,
			"lon": -115.266533,
			"name": "Marc Kahre Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7887 West Gowan Road",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/125-Marc-Kahre-Elementary-School/",
				"reviews": "/nevada/las-vegas/125-Marc-Kahre-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/125-Marc-Kahre-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 487,
			"parentRating": 3,
			"numReviews": 14,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 42
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 23
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1050,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.203156,
			"lon": -115.119637,
			"name": "Marcia's Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2016 East Carey Avenue",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1050-Marcias-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1050-Marcias-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1050-Marcias-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 620,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.193371,
			"lon": -115.065781,
			"name": "Mario C & Joanne Monaco Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1870 North Lamont Street",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/620-Mario-C--Joanne-Monaco-Middle-School/",
				"reviews": "/nevada/las-vegas/620-Mario-C--Joanne-Monaco-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/620-Mario-C--Joanne-Monaco-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1311,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 82
				},
				{
					"label": "White",
					"rating": 2,
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 161,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.097374,
			"lon": -115.237495,
			"name": "Marion B Earl Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6650 West Reno Avenue",
				"street2": null,
				"zip": "89118",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89118",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/161-Marion-B-Earl-Elementary-School/",
				"reviews": "/nevada/las-vegas/161-Marion-B-Earl-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/161-Marion-B-Earl-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "65%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 708,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 65
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 37
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 20
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1199,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.031513,
			"lon": -115.236847,
			"name": "Mark L Fine Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6635 West Cougar Avenue",
				"street2": null,
				"zip": "89139",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89139",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1199-Mark-L-Fine-Elementary-School/",
				"reviews": "/nevada/las-vegas/1199-Mark-L-Fine-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1199-Mark-L-Fine-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "48%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 860,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 48
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 22
				},
				{
					"label": "Pacific Islander",
					"rating": 4,
					"percentage": 5
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 27
				}
			],
			"remediationData": {}
		},
		{
			"id": 611,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.270908,
			"lon": -115.302971,
			"name": "Marshall C Darnell Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9480 West Tropical Parkway",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/611-Marshall-C-Darnell-Elementary-School/",
				"reviews": "/nevada/las-vegas/611-Marshall-C-Darnell-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/611-Marshall-C-Darnell-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 606,
			"parentRating": 3,
			"numReviews": 13,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 22
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 43
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 164,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.200901,
			"lon": -115.05661,
			"name": "Martin Luther King Jr Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2260 Betty Lane",
				"street2": null,
				"zip": "89156",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89156",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/164-Martin-Luther-King-Jr-Elementary-School/",
				"reviews": "/nevada/las-vegas/164-Martin-Luther-King-Jr-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/164-Martin-Luther-King-Jr-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 514,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 65
				},
				{
					"label": "White",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1525,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "p,e,m",
			"lat": 36.174816,
			"lon": -115.070557,
			"name": "Mater Academy Bonanza",
			"gradeLevels": "PK-7",
			"assigned": null,
			"address": {
				"street1": "4760 East Bonanza Road",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1525-Mater-Academy-Bonanza/",
				"reviews": "/nevada/las-vegas/1525-Mater-Academy-Bonanza/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1525-Mater-Academy-Bonanza/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "86%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 906,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 86
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 79
				},
				{
					"label": "White",
					"rating": 1,
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1470,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "p,e,m",
			"lat": 36.127182,
			"lon": -115.07505,
			"name": "Mater Academy Mountain Vista",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "3445 South Mountain Vista Street",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1470-Mater-Academy-Of-Nevada/",
				"reviews": "/nevada/las-vegas/1470-Mater-Academy-Of-Nevada/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1470-Mater-Academy-Of-Nevada/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1056,
			"parentRating": 5,
			"numReviews": 6,
			"studentsPerTeacher": 23,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "Hispanic",
					"percentage": 79
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 77,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.192444,
			"lon": -115.155006,
			"name": "Matt Kelly Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1900 J Street",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/77-Matt-Kelly-Elementary-School/",
				"reviews": "/nevada/las-vegas/77-Matt-Kelly-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/77-Matt-Kelly-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 347,
			"parentRating": 3,
			"numReviews": 3,
			"studentsPerTeacher": 12,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 73
				},
				{
					"label": "Hispanic",
					"percentage": 16
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1351,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.086918,
			"lon": -115.128212,
			"name": "Mccarran Bright Horizons Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1788 Landing Strip Avenue",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1351-Mccarran-Bright-Horizons-Dc/",
				"reviews": "/nevada/las-vegas/1351-Mccarran-Bright-Horizons-Dc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1351-Mccarran-Bright-Horizons-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 870,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.086918,
			"lon": -115.128212,
			"name": "Mccarran Child Development Center",
			"gradeLevels": "PK-1",
			"assigned": null,
			"address": {
				"street1": "1788 Landing Strip Avenue",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/870-Mccarran-Child-Development-Center/",
				"reviews": "/nevada/las-vegas/870-Mccarran-Child-Development-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/870-Mccarran-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 96,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 75
				},
				{
					"label": "African American",
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 1003,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.169003,
			"lon": -115.218895,
			"name": "Melvin Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "360 Upland Blvd",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1003-Melvin-Day-Care-Center/",
				"reviews": "/nevada/las-vegas/1003-Melvin-Day-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1003-Melvin-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 536,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.199333,
			"lon": -115.317001,
			"name": "Merryhill Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2160 Snow Trail",
				"street2": null,
				"zip": "89134",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89134",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/536-Merryhill-Elementary-School/",
				"reviews": "/nevada/las-vegas/536-Merryhill-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/536-Merryhill-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 171,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 541,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.09724,
			"lon": -115.279907,
			"name": "Merryhill Elementary School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "5055 S Durango Dr",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/541-Merryhill-Elementary-School/",
				"reviews": "/nevada/las-vegas/541-Merryhill-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/541-Merryhill-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 137,
			"parentRating": 4,
			"numReviews": 23,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 701,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.169201,
			"lon": -115.31321,
			"name": "Merryhill School Alta",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "10050 Alta Drive",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/701-Merryhill-School-Alta/",
				"reviews": "/nevada/las-vegas/701-Merryhill-School-Alta/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/701-Merryhill-School-Alta/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 35,
			"parentRating": 3,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 64
				},
				{
					"label": "African American",
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 1181,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.09724,
			"lon": -115.279907,
			"name": "Merryhill School Durango ",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5055 S Durango Dr",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1181-Merryhill-School-Durango-/",
				"reviews": "/nevada/las-vegas/1181-Merryhill-School-Durango-/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1181-Merryhill-School-Durango-/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1182,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.304516,
			"lon": -115.260994,
			"name": "Merryhill School Grand Teton",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "7605 Grand Teton Drive",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1182-Merryhill-School-Grand-Teton/",
				"reviews": "/nevada/las-vegas/1182-Merryhill-School-Grand-Teton/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1182-Merryhill-School-Grand-Teton/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 13,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 984,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.199329,
			"lon": -115.317604,
			"name": "Merryhill School Summerlin Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2150 Snow Trail",
				"street2": null,
				"zip": "89134",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89134",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/984-Merryhill-School-Summerlin-Preschool/",
				"reviews": "/nevada/las-vegas/984-Merryhill-School-Summerlin-Preschool/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/984-Merryhill-School-Summerlin-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 636,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.154667,
			"lon": -115.027184,
			"name": "Mervin Iverson Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1575 South Hollywood Boulevard",
				"street2": null,
				"zip": "89142",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89142",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/636-Mervin-Iverson-Elementary-School/",
				"reviews": "/nevada/las-vegas/636-Mervin-Iverson-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/636-Mervin-Iverson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 985,
			"parentRating": 3,
			"numReviews": 5,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 57
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 197,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.184673,
			"lon": -115.019814,
			"name": "Mike OCallaghan Middle School i3 Learn Academy",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1450 Radwick Drive",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/197-Mike-Ocallaghan-Middle-School/",
				"reviews": "/nevada/las-vegas/197-Mike-Ocallaghan-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/197-Mike-Ocallaghan-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "76%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1322,
			"parentRating": 4,
			"numReviews": 4,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 76
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 73
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"rating": 4,
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 991,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.140518,
			"lon": -115.282715,
			"name": "Mini Skool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2750 Lake Sahara Dr",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/991-Mini-Skool/",
				"reviews": "/nevada/las-vegas/991-Mini-Skool/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/991-Mini-Skool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1018,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.174313,
			"lon": -115.065285,
			"name": "Mini-Skool Early Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4930 East Bonanza Road",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1018-Mini-Skool-Early-Learning-Center/",
				"reviews": "/nevada/las-vegas/1018-Mini-Skool-Early-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1018-Mini-Skool-Early-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1350,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.173183,
			"lon": -115.226921,
			"name": "Mirabelli Community Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "6200 Hargrove Avenue",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1350-Mirabelli-Community-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1350-Mirabelli-Community-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1350-Mirabelli-Community-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1568,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.180111,
			"lon": -115.137199,
			"name": "Mission High School",
			"gradeLevels": "6-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "801 North Veterans Memorial Drive",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1568-Mission-High-School/",
				"reviews": "/nevada/las-vegas/1568-Mission-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1568-Mission-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 38,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "Hispanic",
					"percentage": 42
				},
				{
					"label": "White",
					"percentage": 24
				},
				{
					"label": "African American",
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 709,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.070927,
			"lon": -115.097488,
			"name": "Montessori Visions Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "3551 East Sunset Road",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/709-Montessori-Visions-Academy/",
				"reviews": "/nevada/las-vegas/709-Montessori-Visions-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/709-Montessori-Visions-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 53,
			"parentRating": 4,
			"numReviews": 22,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 71
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 732,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.180084,
			"lon": -115.093071,
			"name": "Morris Behavior Junior/Senior High School",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "3801 East Washington Avenue",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/732-Morris-Behavior-Jr-Sr-High-School/",
				"reviews": "/nevada/las-vegas/732-Morris-Behavior-Jr-Sr-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/732-Morris-Behavior-Jr-Sr-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "93%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 40,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 93
				},
				{
					"label": "Hispanic",
					"percentage": 53
				},
				{
					"label": "African American",
					"percentage": 28
				},
				{
					"label": "White",
					"percentage": 15
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 105,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.148743,
			"lon": -115.113228,
			"name": "Morris Sunset East High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1905 Atlantic Street",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/105-Morris-Sunset-East-High-School/",
				"reviews": "/nevada/las-vegas/105-Morris-Sunset-East-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/105-Morris-Sunset-East-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "35%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 66,
			"parentRating": 3,
			"numReviews": 2,
			"studentsPerTeacher": 61,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"percentage": 68
				},
				{
					"label": "African American",
					"percentage": 18
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1029,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.201275,
			"lon": -115.217712,
			"name": "Mott's Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5608 W Bartlett Ave",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1029-Motts-Day-Care-Center/",
				"reviews": "/nevada/las-vegas/1029-Motts-Day-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1029-Motts-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1406,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.158527,
			"lon": -115.173126,
			"name": "Mountain Heights Mont School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2301 West Charleston Boulevard",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1406-Mountain-Heights-Mont-School/",
				"reviews": "/nevada/las-vegas/1406-Mountain-Heights-Mont-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1406-Mountain-Heights-Mont-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 693,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.127861,
			"lon": -115.207603,
			"name": "Mountain Heights Montessori",
			"gradeLevels": "PK-3",
			"assigned": null,
			"address": {
				"street1": "3412 S. Decatur Blvd",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/693-Mountain-Heights-Montessori/",
				"reviews": "/nevada/las-vegas/693-Mountain-Heights-Montessori/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/693-Mountain-Heights-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 59,
			"parentRating": 5,
			"numReviews": 16,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 53
				},
				{
					"label": "Hispanic",
					"percentage": 16
				},
				{
					"label": "African American",
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				}
			],
			"remediationData": {}
		},
		{
			"id": 1231,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 36.26403,
			"lon": -115.265999,
			"name": "Mountain View Baptist Academy",
			"gradeLevels": "PK-10",
			"assigned": null,
			"address": {
				"street1": "7830 West Ann Road",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1231-Mountain-View-Baptist-Academy/",
				"reviews": "/nevada/las-vegas/1231-Mountain-View-Baptist-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1231-Mountain-View-Baptist-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 28,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 78
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 512,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 36.173443,
			"lon": -115.092819,
			"name": "Mountain View Christian Schools",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "3900 E Bonanza Rd",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/512-Mountain-View-Christian-Schools/",
				"reviews": "/nevada/las-vegas/512-Mountain-View-Christian-Schools/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/512-Mountain-View-Christian-Schools/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 523,
			"parentRating": 3,
			"numReviews": 39,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 81,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.192074,
			"lon": -115.019852,
			"name": "Mountain View Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5436 East Kell Lane",
				"street2": null,
				"zip": "89156",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89156",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/81-Mountain-View-Elementary-School/",
				"reviews": "/nevada/las-vegas/81-Mountain-View-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/81-Mountain-View-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "85%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 619,
			"parentRating": 3,
			"numReviews": 5,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 85
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 63
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 757,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.219334,
			"lon": -115.302345,
			"name": "Mountain View Lutheran School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9550 West Cheyenne Avenue",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/757-Mountain-View-Lutheran-School/",
				"reviews": "/nevada/las-vegas/757-Mountain-View-Lutheran-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/757-Mountain-View-Lutheran-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 229,
			"parentRating": 5,
			"numReviews": 30,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 86
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 710,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 36.117718,
			"lon": -115.092545,
			"name": "Mt Olive Luteran School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "3975 South Sandhill Road",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/710-Mt-Olive-Luteran-School/",
				"reviews": "/nevada/las-vegas/710-Mt-Olive-Luteran-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/710-Mt-Olive-Luteran-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 59,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 80
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1004,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.188244,
			"lon": -115.188599,
			"name": "My Little Margies Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3707 Vegas Drive",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1004-My-Little-Margies-Day-Care/",
				"reviews": "/nevada/las-vegas/1004-My-Little-Margies-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1004-My-Little-Margies-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1005,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.120327,
			"lon": -115.23806,
			"name": "My Little School House",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3790 Redwood St",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1005-My-Little-School-House/",
				"reviews": "/nevada/las-vegas/1005-My-Little-School-House/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1005-My-Little-School-House/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 83,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.204052,
			"lon": -115.083527,
			"name": "Myrtle Tate Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2450 Lincoln Road",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/83-Myrtle-Tate-Elementary-School/",
				"reviews": "/nevada/las-vegas/83-Myrtle-Tate-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/83-Myrtle-Tate-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 814,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 65
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 999,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.169182,
			"lon": -115.130043,
			"name": "Nala Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "323 North Maryland Parkway",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/999-Nala-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/999-Nala-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/999-Nala-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1415,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.126892,
			"lon": -115.216858,
			"name": "Nca Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3435 Lindell Road",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1415-Nca-Learning-Center/",
				"reviews": "/nevada/las-vegas/1415-Nca-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1415-Nca-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1477,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.115997,
			"lon": -115.10878,
			"name": "Nevada Learning Academy at CCSD",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "3050 East Flamingo Road",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1477-Nevada-Learning-Academy-At-CCSD/",
				"reviews": "/nevada/las-vegas/1477-Nevada-Learning-Academy-At-CCSD/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1477-Nevada-Learning-Academy-At-CCSD/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "31%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 231,
			"parentRating": 5,
			"numReviews": 1,
			"studentsPerTeacher": 5,
			"subratings": {
				"Test Scores Rating": 9,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 31
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "White",
					"percentage": 46
				},
				{
					"label": "Hispanic",
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1569,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "h",
			"lat": 36.167912,
			"lon": -115.129509,
			"name": "Nevada State High School Downtown",
			"gradeLevels": "11-12",
			"assigned": null,
			"address": {
				"street1": "300 North 13th Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1569-Nevada-State-High-School-Downtown/",
				"reviews": "/nevada/las-vegas/1569-Nevada-State-High-School-Downtown/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1569-Nevada-State-High-School-Downtown/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 80,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 71
				},
				{
					"label": "Hispanic",
					"percentage": 66
				},
				{
					"label": "African American",
					"percentage": 19
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1570,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m,h",
			"lat": 36.069664,
			"lon": -115.262619,
			"name": "Nevada State High School Southwest",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "7885 West Sunset Road",
				"street2": "170",
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1570-NEVADA-STATE-HIGH-SCHOOL-SOUTHWEST/",
				"reviews": "/nevada/las-vegas/1570-NEVADA-STATE-HIGH-SCHOOL-SOUTHWEST/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1570-NEVADA-STATE-HIGH-SCHOOL-SOUTHWEST/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1571,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "h",
			"lat": 36.162289,
			"lon": -115.277641,
			"name": "Nevada State High School Summerlin",
			"gradeLevels": "11-12",
			"assigned": null,
			"address": {
				"street1": "850 South Durango Drive",
				"street2": "100",
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1571-Nevada-State-High-School-Summerlin/",
				"reviews": "/nevada/las-vegas/1571-Nevada-State-High-School-Summerlin/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1571-Nevada-State-High-School-Summerlin/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "46%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 242,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 46
				},
				{
					"label": "Hispanic",
					"percentage": 36
				},
				{
					"label": "White",
					"percentage": 29
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 859,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m,h",
			"lat": 36.101372,
			"lon": -115.092285,
			"name": "Nevada Virtual Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "4801 South Sandhill Road",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/859-Nevada-Virtual-Academy/",
				"reviews": "/nevada/las-vegas/859-Nevada-Virtual-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/859-Nevada-Virtual-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "51%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1840,
			"parentRating": 3,
			"numReviews": 40,
			"studentsPerTeacher": 27,
			"subratings": {
				"College Readiness Rating": 1,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 51
				},
				{
					"label": "White",
					"percentage": 46
				},
				{
					"label": "Hispanic",
					"percentage": 28
				},
				{
					"label": "African American",
					"percentage": 17
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1430,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.039986,
			"lon": -115.275139,
			"name": "New Hope Christian Academy",
			"gradeLevels": "PK-2",
			"assigned": null,
			"address": {
				"street1": "8425 West Windmill Lane",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1430-New-Hope-Christian-Academy/",
				"reviews": "/nevada/las-vegas/1430-New-Hope-Christian-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1430-New-Hope-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 137,
			"parentRating": 2,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 738,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 36.158161,
			"lon": -115.239777,
			"name": "New Horizons Academy",
			"gradeLevels": "2-12",
			"assigned": null,
			"address": {
				"street1": "6701 West Charleston Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/738-New-Horizons-Academy/",
				"reviews": "/nevada/las-vegas/738-New-Horizons-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/738-New-Horizons-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 58,
			"parentRating": 4,
			"numReviews": 15,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 78
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 529,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 36.279499,
			"lon": -115.226997,
			"name": "New Life Christian Academy",
			"gradeLevels": "3-9",
			"assigned": null,
			"address": {
				"street1": "1229 E Carson",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/529-New-Life-Christian-Academy/",
				"reviews": "/nevada/las-vegas/529-New-Life-Christian-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/529-New-Life-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 973,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.16394,
			"lon": -115.13282,
			"name": "New Live Christian Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1229 Carson Avenue",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/973-New-Live-Christian-Pre-School/",
				"reviews": "/nevada/las-vegas/973-New-Live-Christian-Pre-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/973-New-Live-Christian-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1210,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e",
			"lat": 36.272163,
			"lon": -115.273804,
			"name": "Northwest Career & Techincal Academy Elementary School",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "8200 West Tropical Parkway",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1210-Northwest-Career--Techincal-Academy-Elementary-School/",
				"reviews": "/nevada/las-vegas/1210-Northwest-Career--Techincal-Academy-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1210-Northwest-Career--Techincal-Academy-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "55%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 20,
			"parentRating": 5,
			"numReviews": 1,
			"studentsPerTeacher": 19,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 55
				},
				{
					"label": "White",
					"percentage": 30
				},
				{
					"label": "African American",
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 10
				}
			],
			"remediationData": {}
		},
		{
			"id": 846,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.272163,
			"lon": -115.273804,
			"name": "Northwest Career and Technical Academy",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "8200 West Tropical Parkway",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/846-Northwest-Career--Technical-Academy/",
				"reviews": "/nevada/las-vegas/846-Northwest-Career--Technical-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/846-Northwest-Career--Technical-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "36%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1858,
			"parentRating": 4,
			"numReviews": 26,
			"studentsPerTeacher": 22,
			"subratings": {
				"College Readiness Rating": 10,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 36
				},
				{
					"label": "Hispanic",
					"percentage": 37
				},
				{
					"label": "White",
					"percentage": 32
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1573,
			"districtId": 23,
			"districtName": "Achievement School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.125366,
			"lon": -115.116005,
			"name": "NV Prep",
			"gradeLevels": "5-12",
			"assigned": null,
			"address": {
				"street1": "2525 Emerson Avenue",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1573-NV-Prep/",
				"reviews": "/nevada/las-vegas/1573-NV-Prep/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1573-NV-Prep/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "90%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 113,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 90
				},
				{
					"label": "Hispanic",
					"percentage": 71
				},
				{
					"label": "African American",
					"percentage": 18
				},
				{
					"label": "White",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1574,
			"districtId": 23,
			"districtName": "Achievement School District",
			"districtCity": "Las Vegas",
			"levelCode": "e",
			"lat": 36.125366,
			"lon": -115.116005,
			"name": "NV Rise Elementary School",
			"gradeLevels": "K-1",
			"assigned": null,
			"address": {
				"street1": "2525 Emerson Avenue",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1574-NV-Rise-Elementary-School/",
				"reviews": "/nevada/las-vegas/1574-NV-Rise-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1574-NV-Rise-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "89%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 117,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 89
				},
				{
					"label": "Hispanic",
					"percentage": 44
				},
				{
					"label": "African American",
					"percentage": 32
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1576,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "h",
			"lat": 36.167912,
			"lon": -115.129509,
			"name": "NV State High School Sunrise",
			"gradeLevels": "11-12",
			"assigned": null,
			"address": {
				"street1": "300 North 13th Street",
				"street2": "2nd Floor",
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1576-NV-State-High-School-Sunrise/",
				"reviews": "/nevada/las-vegas/1576-NV-State-High-School-Sunrise/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1576-NV-State-High-School-Sunrise/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 38,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 71
				},
				{
					"label": "Hispanic",
					"percentage": 58
				},
				{
					"label": "African American",
					"percentage": 26
				},
				{
					"label": "White",
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 84,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.17234,
			"lon": -115.235207,
			"name": "O K Adcock Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6350 Hyde Avenue",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/84-O-K-Adcock-Elementary-School/",
				"reviews": "/nevada/las-vegas/84-O-K-Adcock-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/84-O-K-Adcock-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 639,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 67
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1232,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 36.086338,
			"lon": -115.058998,
			"name": "Oasis Baptist Church",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "5220 East Russell Road",
				"street2": null,
				"zip": "89122",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89122",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1232-Oasis-Baptist-Church/",
				"reviews": "/nevada/las-vegas/1232-Oasis-Baptist-Church/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1232-Oasis-Baptist-Church/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 161,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 51
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 10
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 625,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.146912,
			"lon": -115.225876,
			"name": "Odyssey Charter High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "2251 South Jones Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/625-Odyssey-Charter-High-School/",
				"reviews": "/nevada/las-vegas/625-Odyssey-Charter-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/625-Odyssey-Charter-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "87%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1365,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 27,
			"subratings": {
				"College Readiness Rating": 2,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 87
				},
				{
					"label": "Hispanic",
					"percentage": 40
				},
				{
					"label": "White",
					"percentage": 38
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 840,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.146912,
			"lon": -115.225876,
			"name": "Odyssey Charter School 6-8",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "2251 South Jones Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/840-Odyssey-Charter-School-6-8/",
				"reviews": "/nevada/las-vegas/840-Odyssey-Charter-School-6-8/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/840-Odyssey-Charter-School-6-8/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "77%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 590,
			"parentRating": 5,
			"numReviews": 8,
			"studentsPerTeacher": 32,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 77
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 36
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 39
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 571,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.146912,
			"lon": -115.225876,
			"name": "Odyssey Charter School K-5",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2251 South Jones Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/571-Odyssey-Charter-School-K-5/",
				"reviews": "/nevada/las-vegas/571-Odyssey-Charter-School-K-5/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/571-Odyssey-Charter-School-K-5/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "84%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 267,
			"parentRating": 4,
			"numReviews": 9,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 84
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 39
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 35
				},
				{
					"label": "African American",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 572,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.19487,
			"lon": -115.182152,
			"name": "Ollie Detwiler Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1960 Ferrell Street",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/572-Ollie-Detwiler-Elementary-School/",
				"reviews": "/nevada/las-vegas/572-Ollie-Detwiler-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/572-Ollie-Detwiler-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 697,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 33
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 52
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 651,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.052376,
			"lon": -115.152733,
			"name": "Omar Haikal Islamic Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "485 East Eldorado Lane",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/651-Omar-Haikal-Islamic-Academy/",
				"reviews": "/nevada/las-vegas/651-Omar-Haikal-Islamic-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/651-Omar-Haikal-Islamic-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 178,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 75
				},
				{
					"label": "African American",
					"percentage": 25
				}
			],
			"remediationData": {}
		},
		{
			"id": 142,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.171593,
			"lon": -115.092102,
			"name": "Oran K Gragson Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "555 North Honolulu Street",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/142-Oran-K-Gragson-Elementary-School/",
				"reviews": "/nevada/las-vegas/142-Oran-K-Gragson-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/142-Oran-K-Gragson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 802,
			"parentRating": 2,
			"numReviews": 2,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 80
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 481,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.166386,
			"lon": -115.183304,
			"name": "Our Lady Of Las Vegas School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "3046 Alta Drive",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/481-Our-Lady-Of-Las-Vegas-School/",
				"reviews": "/nevada/las-vegas/481-Our-Lady-Of-Las-Vegas-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/481-Our-Lady-Of-Las-Vegas-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 651,
			"parentRating": 3,
			"numReviews": 19,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 88,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.112324,
			"lon": -115.247826,
			"name": "P A  Diskin Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4220 Ravenwood Drive",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/88-P-A--Diskin-Elementary-School/",
				"reviews": "/nevada/las-vegas/88-P-A--Diskin-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/88-P-A--Diskin-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 675,
			"parentRating": 3,
			"numReviews": 5,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 58
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 219,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.171227,
			"lon": -115.332558,
			"name": "Palo Verde High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "333 South Pavilion Center Drive",
				"street2": null,
				"zip": "89144",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89144",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/219-Palo-Verde-High-School/",
				"reviews": "/nevada/las-vegas/219-Palo-Verde-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/219-Palo-Verde-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 3198,
			"parentRating": 3,
			"numReviews": 41,
			"studentsPerTeacher": 27,
			"subratings": {
				"College Readiness Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 26
				},
				{
					"label": "White",
					"percentage": 51
				},
				{
					"label": "Hispanic",
					"percentage": 23
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 87,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.11171,
			"lon": -115.145096,
			"name": "Paradise Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "900 Cottage Grove Avenue",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/87-Paradise-Elementary-School/",
				"reviews": "/nevada/las-vegas/87-Paradise-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/87-Paradise-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 522,
			"parentRating": 3,
			"numReviews": 3,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 53
				},
				{
					"label": "Two or more races",
					"rating": 2,
					"percentage": 7
				},
				{
					"label": "White",
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1016,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.102341,
			"lon": -115.110107,
			"name": "Paradise Park Community Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "4775 McLeod Drive",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1016-Paradise-Park-Community-Center/",
				"reviews": "/nevada/las-vegas/1016-Paradise-Park-Community-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1016-Paradise-Park-Community-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 62,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.125134,
			"lon": -115.28936,
			"name": "Patricia A Bendorf Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3550 South Kevin Way",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/62-Patricia-A-Bendorf-Elementary-School/",
				"reviews": "/nevada/las-vegas/62-Patricia-A-Bendorf-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/62-Patricia-A-Bendorf-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "60%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 706,
			"parentRating": 2,
			"numReviews": 27,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 60
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 33
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 14
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 89,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.183819,
			"lon": -115.221153,
			"name": "Paul E Culley Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1200 North Mallard Street",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/89-Paul-E-Culley-Elementary-School/",
				"reviews": "/nevada/las-vegas/89-Paul-E-Culley-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/89-Paul-E-Culley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 837,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 73
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1279,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.177525,
			"lon": -115.177094,
			"name": "Pdc Professional Development Center Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "701 North Rancho Drive",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1279-Pdc-Professional-Development-Center-Head-Start/",
				"reviews": "/nevada/las-vegas/1279-Pdc-Professional-Development-Center-Head-Start/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1279-Pdc-Professional-Development-Center-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1409,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.089947,
			"lon": -115.243851,
			"name": "Peace Garden Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5447 South Rainbow Boulevard",
				"street2": null,
				"zip": "89118",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89118",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1409-Peace-Garden-Day-Care/",
				"reviews": "/nevada/las-vegas/1409-Peace-Garden-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1409-Peace-Garden-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1321,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.18314,
			"lon": -115.150063,
			"name": "Pentecostal Temple Cdc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "614 Jefferson Avenue",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1321-Pentecostal-Temple-Cdc/",
				"reviews": "/nevada/las-vegas/1321-Pentecostal-Temple-Cdc/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1321-Pentecostal-Temple-Cdc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 731,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.27882,
			"lon": -115.321793,
			"name": "Peterson Academic Center",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "10250 West Centennial Parkway",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/731-Peterson-Behavior-Jr-Sr-High-School/",
				"reviews": "/nevada/las-vegas/731-Peterson-Behavior-Jr-Sr-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/731-Peterson-Behavior-Jr-Sr-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 86,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "African American",
					"percentage": 45
				},
				{
					"label": "Hispanic",
					"percentage": 40
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 131,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.153286,
			"lon": -115.304459,
			"name": "Piggott Academy of International Studies",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9601 Red Hills Road",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/131-Clarence-Piggott-Elementary-School/",
				"reviews": "/nevada/las-vegas/131-Clarence-Piggott-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/131-Clarence-Piggott-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "35%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 678,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 35
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 40
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1534,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 35.998615,
			"lon": -115.133934,
			"name": "Pinecrest Academy St Rose",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1385 East Cactus Avenue",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1534-Pinecrest-Academy-St-Rose/",
				"reviews": "/nevada/las-vegas/1534-Pinecrest-Academy-St-Rose/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1534-Pinecrest-Academy-St-Rose/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "20%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 992,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 20
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 42
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 703,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.1702,
			"lon": -115.2015,
			"name": "Primetime Preschool - Child Care Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "4514 Meadows Lane",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/703-Primetime-Preschool---Child-Care-Center/",
				"reviews": "/nevada/las-vegas/703-Primetime-Preschool---Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/703-Primetime-Preschool---Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1171,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.233128,
			"lon": -115.228516,
			"name": "Quest Academy Northwest",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "4025 North Rancho Drive",
				"street2": null,
				"zip": "89130",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89130",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1171-Quest-Academy-Preparatory-Education/",
				"reviews": "/nevada/las-vegas/1171-Quest-Academy-Preparatory-Education/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1171-Quest-Academy-Preparatory-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "70%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 463,
			"parentRating": 3,
			"numReviews": 41,
			"studentsPerTeacher": 19,
			"subratings": {
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 70
				},
				{
					"label": "African American",
					"percentage": 47
				},
				{
					"label": "Hispanic",
					"percentage": 30
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1578,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e",
			"lat": 36.232765,
			"lon": -115.259888,
			"name": "Quest Academy West Alexander",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "7550 West Alexander Road",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1578-Quest-Academy-West-Alexander/",
				"reviews": "/nevada/las-vegas/1578-Quest-Academy-West-Alexander/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1578-Quest-Academy-West-Alexander/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "64%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 42,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 64
				},
				{
					"label": "African American",
					"percentage": 43
				},
				{
					"label": "Hispanic",
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 1579,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.175323,
			"lon": -115.099113,
			"name": "Quest Program",
			"gradeLevels": "1-12",
			"assigned": null,
			"address": {
				"street1": "601 North Pecos Road",
				"street2": "70",
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1579-Quest-Program/",
				"reviews": "/nevada/las-vegas/1579-Quest-Program/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1579-Quest-Program/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 997,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.16943,
			"lon": -115.252098,
			"name": "Quinones Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "304 Pinecliff Drive",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/997-Quinones-Day-Care/",
				"reviews": "/nevada/las-vegas/997-Quinones-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/997-Quinones-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 149,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.221996,
			"lon": -115.234764,
			"name": "R E Tobler Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6510 West Buckskin Avenue",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/149-R-E-Tobler-Elementary-School/",
				"reviews": "/nevada/las-vegas/149-R-E-Tobler-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/149-R-E-Tobler-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 521,
			"parentRating": 2,
			"numReviews": 9,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 50
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 146,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.138195,
			"lon": -115.234947,
			"name": "R Guild Gray Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2825 South Torrey Pines Drive",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/146-R-Guild-Gray-Elementary-School/",
				"reviews": "/nevada/las-vegas/146-R-Guild-Gray-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/146-R-Guild-Gray-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 472,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 55
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 19
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 831,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.196194,
			"lon": -115.154411,
			"name": "Rainbow Dreams Academy",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "950 West Lake Mead Boulevard",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/831-Rainbow-Dreams-Academy/",
				"reviews": "/nevada/las-vegas/831-Rainbow-Dreams-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/831-Rainbow-Dreams-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 227,
			"parentRating": 3,
			"numReviews": 12,
			"studentsPerTeacher": 10,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 80
				},
				{
					"label": "Hispanic",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "White",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1489,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.196194,
			"lon": -115.154411,
			"name": "Rainbow Dreams Academy Secondary",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "950 West Lake Mead Boulevard",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1489-Rainbow-Dreams-Academy-Secondary/",
				"reviews": "/nevada/las-vegas/1489-Rainbow-Dreams-Academy-Secondary/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1489-Rainbow-Dreams-Academy-Secondary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 86,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 79
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 723,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.288906,
			"lon": -115.263756,
			"name": "Ralph Cadwallader Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "7775 West Elkhorn Road",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/723-Ralph-Cadwallader-Middle-School/",
				"reviews": "/nevada/las-vegas/723-Ralph-Cadwallader-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/723-Ralph-Cadwallader-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "35%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1507,
			"parentRating": 3,
			"numReviews": 29,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 35
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 22
				},
				{
					"label": "Pacific Islander",
					"rating": 7,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 53
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 91,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.186485,
			"lon": -115.124069,
			"name": "Rancho High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "1900 Searles Avenue",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/91-Rancho-High-School/",
				"reviews": "/nevada/las-vegas/91-Rancho-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/91-Rancho-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "66%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 3292,
			"parentRating": 4,
			"numReviews": 29,
			"studentsPerTeacher": 24,
			"subratings": {
				"College Readiness Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 66
				},
				{
					"label": "Hispanic",
					"percentage": 76
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1469,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.265263,
			"lon": -115.054901,
			"name": "Red Rock Academy",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "5760 Range Road",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1469-Red-Rock-Academy/",
				"reviews": "/nevada/las-vegas/1469-Red-Rock-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1469-Red-Rock-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "24%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 45,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 24
				},
				{
					"label": "African American",
					"percentage": 42
				},
				{
					"label": "Hispanic",
					"percentage": 38
				},
				{
					"label": "White",
					"percentage": 13
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1371,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.148067,
			"lon": -115.347641,
			"name": "Red Rock Country Club Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2250 Red Springs Drive",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1371-Red-Rock-Country-Club-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1371-Red-Rock-Country-Club-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1371-Red-Rock-Country-Club-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 92,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.167862,
			"lon": -115.216911,
			"name": "Red Rock Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "408 Upland Boulevard",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/92-Red-Rock-Elementary-School/",
				"reviews": "/nevada/las-vegas/92-Red-Rock-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/92-Red-Rock-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 769,
			"parentRating": 3,
			"numReviews": 8,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 67
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 498,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.190407,
			"lon": -115.097404,
			"name": "Redeemer Lutheran Elementary School & Preschool",
			"gradeLevels": "PK-2",
			"assigned": null,
			"address": {
				"street1": "1730 North Pecos Road",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/498-Redeemer-Lutheran-Elementary-School--Preschool/",
				"reviews": "/nevada/las-vegas/498-Redeemer-Lutheran-Elementary-School--Preschool/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/498-Redeemer-Lutheran-Elementary-School--Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 11,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Two or more races",
					"percentage": 43
				},
				{
					"label": "African American",
					"percentage": 29
				},
				{
					"label": "Hispanic",
					"percentage": 14
				},
				{
					"label": "White",
					"percentage": 14
				}
			],
			"remediationData": {}
		},
		{
			"id": 93,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.140884,
			"lon": -115.179581,
			"name": "Rex Bell Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2900 Wilmington Way",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/93-Rex-Bell-Elementary-School/",
				"reviews": "/nevada/las-vegas/93-Rex-Bell-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/93-Rex-Bell-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 896,
			"parentRating": 5,
			"numReviews": 10,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 58
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 207,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.193932,
			"lon": -115.269058,
			"name": "Richard H Bryan Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8050 Cielo Vista Avenue",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/207-Richard-H-Bryan-Elementary-School/",
				"reviews": "/nevada/las-vegas/207-Richard-H-Bryan-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/207-Richard-H-Bryan-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 554,
			"parentRating": 5,
			"numReviews": 10,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 53
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 33
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 39
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 194,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.167671,
			"lon": -115.054382,
			"name": "Richard J Rundle Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "425 North Christy Lane",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/194-Richard-J-Rundle-Elementary-School/",
				"reviews": "/nevada/las-vegas/194-Richard-J-Rundle-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/194-Richard-J-Rundle-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 740,
			"parentRating": 5,
			"numReviews": 2,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 24
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 65
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 94,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.138844,
			"lon": -115.1287,
			"name": "Robert E Lake Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2904 Meteoro Street",
				"street2": null,
				"zip": "89169",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89169",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/94-Robert-E-Lake-Elementary-School/",
				"reviews": "/nevada/las-vegas/94-Robert-E-Lake-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/94-Robert-E-Lake-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 820,
			"parentRating": 2,
			"numReviews": 11,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 64
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 981,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.191307,
			"lon": -115.069717,
			"name": "Robert Jones Garden Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1750 Marion Dr",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/981-Robert-Jones-Garden-Head-Start/",
				"reviews": "/nevada/las-vegas/981-Robert-Jones-Garden-Head-Start/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/981-Robert-Jones-Garden-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 839,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.031216,
			"lon": -115.308342,
			"name": "Robert L Forbuss Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8601 South Grand Canyon Drive",
				"street2": null,
				"zip": "89178",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89178",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/839-Robert-L-Forbuss-Elementary-School/",
				"reviews": "/nevada/las-vegas/839-Robert-L-Forbuss-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/839-Robert-L-Forbuss-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "47%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 705,
			"parentRating": 4,
			"numReviews": 21,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 47
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 27
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 15
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 14
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 177,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.177883,
			"lon": -115.113899,
			"name": "Robert Lunt Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2701 East Harris Avenue",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/177-Robert-Lunt-Elementary-School/",
				"reviews": "/nevada/las-vegas/177-Robert-Lunt-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/177-Robert-Lunt-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 640,
			"parentRating": 3,
			"numReviews": 6,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 85
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 96,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.182266,
			"lon": -115.196564,
			"name": "Robert O Gibson Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "3900 West Washington Avenue",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/96-Robert-O-Gibson-Middle-School/",
				"reviews": "/nevada/las-vegas/96-Robert-O-Gibson-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/96-Robert-O-Gibson-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "87%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1266,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 87
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 79
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 221,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.016125,
			"lon": -115.140282,
			"name": "Roberta C Cartwright Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1050 East Gary Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/221-Roberta-C-Cartwright-Elementary-School/",
				"reviews": "/nevada/las-vegas/221-Roberta-C-Cartwright-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/221-Roberta-C-Cartwright-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "58%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 552,
			"parentRating": 3,
			"numReviews": 11,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 58
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 33
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 29
				},
				{
					"label": "Two or more races",
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 996,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.17086,
			"lon": -115.107918,
			"name": "Rock Christian Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2929 Cedar Ave",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/996-Rock-Christian-Learning-Center/",
				"reviews": "/nevada/las-vegas/996-Rock-Christian-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/996-Rock-Christian-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 635,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.015957,
			"lon": -115.137642,
			"name": "Roger D Gehring Academy of Science and Technology",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1155 East Richmar Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/635-Roger-D-Gehring-Elementary-School/",
				"reviews": "/nevada/las-vegas/635-Roger-D-Gehring-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/635-Roger-D-Gehring-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "47%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 583,
			"parentRating": 3,
			"numReviews": 23,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 47
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 32
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 16
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 215,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.11937,
			"lon": -115.270981,
			"name": "Roger M Bryan Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8255 West Katie Avenue",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/215-Roger-M-Bryan-Elementary-School/",
				"reviews": "/nevada/las-vegas/215-Roger-M-Bryan-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/215-Roger-M-Bryan-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "63%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 720,
			"parentRating": 4,
			"numReviews": 14,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 63
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 28
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 19
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 20
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1000,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.127865,
			"lon": -115.207619,
			"name": "Romp 'N Play",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3412 S Decatur Blvd",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1000-Romp-N-Play/",
				"reviews": "/nevada/las-vegas/1000-Romp-N-Play/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1000-Romp-N-Play/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 97,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.164207,
			"lon": -115.234352,
			"name": "Rose Warren Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6451 Brandywine Way",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/97-Rose-Warren-Elementary-School/",
				"reviews": "/nevada/las-vegas/97-Rose-Warren-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/97-Rose-Warren-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 716,
			"parentRating": 3,
			"numReviews": 3,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 74
				},
				{
					"label": "White",
					"rating": 2,
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 98,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.164017,
			"lon": -115.110725,
			"name": "Roy West Martin Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "200 North 28th Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/98-Roy-West-Martin-Middle-School/",
				"reviews": "/nevada/las-vegas/98-Roy-West-Martin-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/98-Roy-West-Martin-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "83%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1563,
			"parentRating": 3,
			"numReviews": 8,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 83
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 80
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 829,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.189301,
			"lon": -115.076904,
			"name": "Ruben P Diaz Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4450 East Owens Avenue",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/829-Ruben-P-Diaz-Elementary-School/",
				"reviews": "/nevada/las-vegas/829-Ruben-P-Diaz-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/829-Ruben-P-Diaz-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 707,
			"parentRating": 4,
			"numReviews": 4,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 74
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1207,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.281158,
			"lon": -115.143112,
			"name": "Ruby Duncan Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "250 W Rome Blvd",
				"street2": null,
				"zip": "89084",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89084",
			"type": "school",
			"links": {
				"profile": "/nevada/north-las-vegas/1207-Ruby-Duncan-Elementary-School/",
				"reviews": "/nevada/north-las-vegas/1207-Ruby-Duncan-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/north-las-vegas/1207-Ruby-Duncan-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 643,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 37
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 32
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 99,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.125351,
			"lon": -115.130531,
			"name": "Ruby S Thomas Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1560 Cherokee Lane",
				"street2": null,
				"zip": "89169",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89169",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/99-Ruby-S-Thomas-Elementary-School/",
				"reviews": "/nevada/las-vegas/99-Ruby-S-Thomas-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/99-Ruby-S-Thomas-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 906,
			"parentRating": 1,
			"numReviews": 1,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 24
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 51
				},
				{
					"label": "White",
					"rating": 2,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 100,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.176846,
			"lon": -115.195724,
			"name": "Ruth Fyfe Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4101 West Bonanza Road",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/100-Ruth-Fyfe-Elementary-School/",
				"reviews": "/nevada/las-vegas/100-Ruth-Fyfe-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/100-Ruth-Fyfe-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 14,
			"subratings": {
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "Hispanic",
					"percentage": 68
				},
				{
					"label": "African American",
					"percentage": 16
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 165,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.243462,
			"lon": -115.255142,
			"name": "Ruthe Deskin Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4550 North Pioneer Way",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/165-Ruthe-Deskin-Elementary-School/",
				"reviews": "/nevada/las-vegas/165-Ruthe-Deskin-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/165-Ruthe-Deskin-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 607,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 43
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 4,
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 809,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.294754,
			"lon": -115.293617,
			"name": "Sandra L Thompson Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7351 North Campbell Road",
				"street2": null,
				"zip": "89149",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89149",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/809-Sandra-L-Thompson-Elementary-School/",
				"reviews": "/nevada/las-vegas/809-Sandra-L-Thompson-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/809-Sandra-L-Thompson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 636,
			"parentRating": 4,
			"numReviews": 17,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 53
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 26
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 721,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.195103,
			"lon": -115.068077,
			"name": "Sandy Searles Miller Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4851 East Lake Mead Boulevard",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/721-Sandy-Searles-Miller-Elementary-School/",
				"reviews": "/nevada/las-vegas/721-Sandy-Searles-Miller-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/721-Sandy-Searles-Miller-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 652,
			"parentRating": 4,
			"numReviews": 21,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 71
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 7,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 73
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 977,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.008663,
			"lon": -115.159714,
			"name": "Seton Academy Pre-School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "9975 Placid Street",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/977-Seton-Academy-Pre-School/",
				"reviews": "/nevada/las-vegas/977-Seton-Academy-Pre-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/977-Seton-Academy-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1130,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.230419,
			"lon": -115.293541,
			"name": "Seton Academy West",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3801 North Campbell Road",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1130-Seton-Academy-West/",
				"reviews": "/nevada/las-vegas/1130-Seton-Academy-West/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1130-Seton-Academy-West/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 198,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 79
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 663,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.188721,
			"lon": -115.26474,
			"name": "Shadow Hills Baptist Preschool",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "7811 Vegas Drive",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/663-Shadow-Hills-Baptist-Preschool/",
				"reviews": "/nevada/las-vegas/663-Shadow-Hills-Baptist-Preschool/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/663-Shadow-Hills-Baptist-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 188,
			"parentRating": 4,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 87
				},
				{
					"label": "Two or more races",
					"percentage": 13
				}
			],
			"remediationData": {}
		},
		{
			"id": 728,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.31881,
			"lon": -115.211243,
			"name": "Shadow Ridge High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "5050 Brent Lane",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/728-Shadow-Ridge-High-School/",
				"reviews": "/nevada/las-vegas/728-Shadow-Ridge-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/728-Shadow-Ridge-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "35%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 3187,
			"parentRating": 3,
			"numReviews": 30,
			"studentsPerTeacher": 24,
			"subratings": {
				"College Readiness Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 35
				},
				{
					"label": "White",
					"percentage": 42
				},
				{
					"label": "Hispanic",
					"percentage": 27
				},
				{
					"label": "African American",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 594,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.23061,
			"lon": -115.301125,
			"name": "Sheila R. Tarr  Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9400 West Gilmore Avenue",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/594-Sheila-R.-Tarr--Elementary-School/",
				"reviews": "/nevada/las-vegas/594-Sheila-R.-Tarr--Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/594-Sheila-R.-Tarr--Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "43%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 739,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 43
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 32
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 42
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1538,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.06152,
			"lon": -115.307693,
			"name": "Shelley Berkley Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9850 Copper Edge Road",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1538-Shelley-Berkley-Elementary-School/",
				"reviews": "/nevada/las-vegas/1538-Shelley-Berkley-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1538-Shelley-Berkley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 746,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 53
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 29
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 17
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 660,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.199047,
			"lon": -115.292549,
			"name": "Shenker Academy",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "9001 Hillpointe Road",
				"street2": null,
				"zip": "89134",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89134",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/660-Shenker-Academy/",
				"reviews": "/nevada/las-vegas/660-Shenker-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/660-Shenker-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 51,
			"parentRating": 3,
			"numReviews": 23,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1539,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.005272,
			"lon": -115.12561,
			"name": "Shirley A Barber Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2002 Dave Street",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1539-Shirley-A-Barber-Elementary/",
				"reviews": "/nevada/las-vegas/1539-Shirley-A-Barber-Elementary/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1539-Shirley-A-Barber-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "63%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 690,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {
				"Test Scores Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 63
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 34
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 14
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 623,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.049908,
			"lon": -115.268227,
			"name": "Sierra Vista High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "8100 West Robindale Road",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/623-Sierra-Vista-High-School/",
				"reviews": "/nevada/las-vegas/623-Sierra-Vista-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/623-Sierra-Vista-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "52%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2612,
			"parentRating": 3,
			"numReviews": 28,
			"studentsPerTeacher": 27,
			"subratings": {
				"College Readiness Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 52
				},
				{
					"label": "Hispanic",
					"percentage": 27
				},
				{
					"label": "White",
					"percentage": 23
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 19
				},
				{
					"label": "African American",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 598,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.175312,
			"lon": -115.336189,
			"name": "Sig Rogich Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "235 Pavilion Center Drive",
				"street2": null,
				"zip": "89144",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89144",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/598-Sig-Rogich-Middle-School/",
				"reviews": "/nevada/las-vegas/598-Sig-Rogich-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/598-Sig-Rogich-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "20%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1877,
			"parentRating": 3,
			"numReviews": 25,
			"studentsPerTeacher": 29,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 20
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 5
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 16
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 56
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 9
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 205,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.024372,
			"lon": -115.13031,
			"name": "Silverado High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "1650 Silver Hawk Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/205-Silverado-High-School/",
				"reviews": "/nevada/las-vegas/205-Silverado-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/205-Silverado-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2299,
			"parentRating": 3,
			"numReviews": 12,
			"studentsPerTeacher": 25,
			"subratings": {
				"College Readiness Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "White",
					"percentage": 34
				},
				{
					"label": "Hispanic",
					"percentage": 33
				},
				{
					"label": "African American",
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 837,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.107494,
			"lon": -115.051117,
			"name": "Sister Robert Joseph Bailey Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4525 Jimmy Durante Boulevard",
				"street2": null,
				"zip": "89122",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89122",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/837-Sister-Robert-Joseph-Bailey-Elementary-School/",
				"reviews": "/nevada/las-vegas/837-Sister-Robert-Joseph-Bailey-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/837-Sister-Robert-Joseph-Bailey-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 955,
			"parentRating": 5,
			"numReviews": 11,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 50
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"rating": 3,
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 975,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.188522,
			"lon": -115.157845,
			"name": "Smart Start Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1260 W Owens Ave",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/975-Smart-Start-Day-Care/",
				"reviews": "/nevada/las-vegas/975-Smart-Start-Day-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/975-Smart-Start-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 863,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.13382,
			"lon": -115.326157,
			"name": "Solomon Schechter Day School Of Las Vegas",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "10100 Banburry Cross Drive",
				"street2": "Suite 1",
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/863-Solomon-Schechter-Day-School-Of-Las-Vegas/",
				"reviews": "/nevada/las-vegas/863-Solomon-Schechter-Day-School-Of-Las-Vegas/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/863-Solomon-Schechter-Day-School-Of-Las-Vegas/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 65,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 60
				},
				{
					"label": "Two or more races",
					"percentage": 18
				},
				{
					"label": "Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 1541,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.242702,
			"lon": -115.243523,
			"name": "Somerset Academy Lone Mountain",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "4491 North Rainbow Boulevard",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1541-Somerset-Academy-Lone-Mountain/",
				"reviews": "/nevada/las-vegas/1541-Somerset-Academy-Lone-Mountain/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1541-Somerset-Academy-Lone-Mountain/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "24%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 958,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 24
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 31
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 51
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1252,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m,h",
			"lat": 36.288486,
			"lon": -115.275146,
			"name": "Somerset Academy Sky Pointe",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "7038 Sky Pointe Drive",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/north-las-vegas/1252-Somerset-Academy-Of-Las-Vegas/",
				"reviews": "/nevada/north-las-vegas/1252-Somerset-Academy-Of-Las-Vegas/#Reviews",
				"collegeSuccess": "/nevada/north-las-vegas/1252-Somerset-Academy-Of-Las-Vegas/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "13%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1908,
			"parentRating": 3,
			"numReviews": 13,
			"studentsPerTeacher": 25,
			"subratings": {
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 13
				},
				{
					"label": "White",
					"percentage": 62
				},
				{
					"label": "Hispanic",
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1546,
			"districtId": 13,
			"districtName": "State-Sponsored Charter Schools",
			"districtCity": "Carson City",
			"levelCode": "e,m",
			"lat": 36.301731,
			"lon": -115.329094,
			"name": "Somerset Skye Canyon",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "8151 North Shaumber Road",
				"street2": null,
				"zip": "89166",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "NV",
			"zipcode": "89166",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1546-Somerset-Skye-Canyon/",
				"reviews": "/nevada/las-vegas/1546-Somerset-Skye-Canyon/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1546-Somerset-Skye-Canyon/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "11%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 855,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {
				"Test Scores Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 55
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 968,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.159405,
			"lon": -115.111923,
			"name": "Source Academy & Early Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "10 North 28th Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/968-Source-Academy--Early-Learning-Center/",
				"reviews": "/nevada/las-vegas/968-Source-Academy--Early-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/968-Source-Academy--Early-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 582,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.148743,
			"lon": -115.113228,
			"name": "South Academic Center",
			"gradeLevels": "6-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "1905 Atlantic Street",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/582-South-Continuation-Junior-Senior-High-School/",
				"reviews": "/nevada/las-vegas/582-South-Continuation-Junior-Senior-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/582-South-Continuation-Junior-Senior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 83,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "African American",
					"percentage": 53
				},
				{
					"label": "Hispanic",
					"percentage": 29
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 102,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.081295,
			"lon": -115.068748,
			"name": "Southeast Career Technical Academy",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "5710 South Mountain Vista Street",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/102-Southeast-Career-Technical-Academy/",
				"reviews": "/nevada/las-vegas/102-Southeast-Career-Technical-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/102-Southeast-Career-Technical-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1837,
			"parentRating": 3,
			"numReviews": 2,
			"studentsPerTeacher": 22,
			"subratings": {
				"College Readiness Rating": 9,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 53
				},
				{
					"label": "Hispanic",
					"percentage": 58
				},
				{
					"label": "White",
					"percentage": 22
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 848,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.085361,
			"lon": -115.072975,
			"name": "Southeast Career Technical Academy",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "5710 Mountain Vista Drive",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/848-Southeast-Career-Technical-Academy/",
				"reviews": "/nevada/las-vegas/848-Southeast-Career-Technical-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/848-Southeast-Career-Technical-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 2,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 865,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 35.980385,
			"lon": -115.186882,
			"name": "Southern Highlands Preparatory School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "11500 Southern Highlands Parkway",
				"street2": null,
				"zip": "89141",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89141",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/865-Southern-Highlands-Preparatory-School/",
				"reviews": "/nevada/las-vegas/865-Southern-Highlands-Preparatory-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/865-Southern-Highlands-Preparatory-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 244,
			"parentRating": 4,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 66
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 791,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "e,m,h",
			"lat": 36.112625,
			"lon": -115.233566,
			"name": "Southwest Behavior Junior-Senior High School",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "6480 Fairbanks Road",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/791-Southwest-Behavior-J-Shs/",
				"reviews": "/nevada/las-vegas/791-Southwest-Behavior-J-Shs/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/791-Southwest-Behavior-J-Shs/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 32,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 100
				},
				{
					"label": "Hispanic",
					"percentage": 47
				},
				{
					"label": "White",
					"percentage": 22
				},
				{
					"label": "African American",
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 1202,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.038151,
			"lon": -115.247551,
			"name": "Southwest Career and Technical Academy",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "7050 West Shelbourne Avenue",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1202-Southwest-Career-And-Technical-Academy/",
				"reviews": "/nevada/las-vegas/1202-Southwest-Career-And-Technical-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1202-Southwest-Career-And-Technical-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "35%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1460,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 21,
			"subratings": {
				"College Readiness Rating": 9,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 35
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 36
				},
				{
					"label": "White",
					"percentage": 25
				},
				{
					"label": "Hispanic",
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 667,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.1516,
			"lon": -115.1306,
			"name": "Spring Meadows Preschool & Kindergarten",
			"gradeLevels": "PK-1",
			"assigned": null,
			"address": {
				"street1": "1600 East Oakey Boulevard",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/667-Spring-Meadows-Preschool--Kindergarten/",
				"reviews": "/nevada/las-vegas/667-Spring-Meadows-Preschool--Kindergarten/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/667-Spring-Meadows-Preschool--Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 522,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 36.105072,
			"lon": -115.254738,
			"name": "Spring Valley Christian Academy",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "7570 Peace Way",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/522-Spring-Valley-Christian-Academy/",
				"reviews": "/nevada/las-vegas/522-Spring-Valley-Christian-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/522-Spring-Valley-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 52,
			"parentRating": 4,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 59
				},
				{
					"label": "African American",
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"percentage": 14
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 769,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.119606,
			"lon": -115.259308,
			"name": "Spring Valley High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "3750 South Buffalo Drive",
				"street2": null,
				"zip": "89147",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89147",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/769-Spring-Valley-High-School/",
				"reviews": "/nevada/las-vegas/769-Spring-Valley-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/769-Spring-Valley-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "48%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2540,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 24,
			"subratings": {
				"College Readiness Rating": 4,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 48
				},
				{
					"label": "Hispanic",
					"percentage": 36
				},
				{
					"label": "White",
					"percentage": 25
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 16
				},
				{
					"label": "African American",
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 1185,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.137146,
			"lon": -115.243607,
			"name": "Spring Valley Montessori School",
			"gradeLevels": "PK-6",
			"assigned": null,
			"address": {
				"street1": "6940 Edna Avenue",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1185-Spring-Valley-Montessori-School/",
				"reviews": "/nevada/las-vegas/1185-Spring-Valley-Montessori-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1185-Spring-Valley-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 80,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 44
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"percentage": 7
				}
			],
			"remediationData": {}
		},
		{
			"id": 1194,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.05439,
			"lon": -115.247894,
			"name": "Springstone Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "7373 Montessouri Street",
				"street2": null,
				"zip": "89113",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89113",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1194-Springstone-Montessori-School/",
				"reviews": "/nevada/las-vegas/1194-Springstone-Montessori-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1194-Springstone-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 472,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.149281,
			"lon": -115.136192,
			"name": "St Anne Catholic School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1813 South Maryland Parkway",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/472-St-Anne-Catholic-School/",
				"reviews": "/nevada/las-vegas/472-St-Anne-Catholic-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/472-St-Anne-Catholic-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 282,
			"parentRating": 4,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 35
				},
				{
					"label": "White",
					"percentage": 28
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 669,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 36.193192,
			"lon": -115.271957,
			"name": "St Elizabeth Ann Seton Catholic School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1807 Pueblo Vista Drive",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/669-St-Elizabeth-Ann-Seton-Catholic-School/",
				"reviews": "/nevada/las-vegas/669-St-Elizabeth-Ann-Seton-Catholic-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/669-St-Elizabeth-Ann-Seton-Catholic-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 481,
			"parentRating": 4,
			"numReviews": 43,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 74
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 480,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 36.182827,
			"lon": -115.215912,
			"name": "St Francis De Sales School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1111 North Michael Way",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/480-St-Francis-De-Sales-School/",
				"reviews": "/nevada/las-vegas/480-St-Francis-De-Sales-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/480-St-Francis-De-Sales-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 296,
			"parentRating": 4,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 47
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 16
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 479,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 36.16222,
			"lon": -115.13308,
			"name": "St Joseph School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1300 Bridger Avenue",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/479-St-Joseph-School/",
				"reviews": "/nevada/las-vegas/479-St-Joseph-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/479-St-Joseph-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 123,
			"parentRating": 4,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 36
				},
				{
					"label": "Hispanic",
					"percentage": 32
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 478,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.11195,
			"lon": -115.118477,
			"name": "St Viator Catholic School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "4246 South Eastern Avenue",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/478-St-Viator-Catholic-School/",
				"reviews": "/nevada/las-vegas/478-St-Viator-Catholic-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/478-St-Viator-Catholic-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 674,
			"parentRating": 2,
			"numReviews": 18,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 62
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 885,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.110477,
			"lon": -115.120308,
			"name": "St. Viator Prekindergarten & Kindergarten",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "4246 S. Eastern Avenue",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/885-St.-Viator-Prekindergarten--Kindergarten/",
				"reviews": "/nevada/las-vegas/885-St.-Viator-Prekindergarten--Kindergarten/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/885-St.-Viator-Prekindergarten--Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 159,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.178684,
			"lon": -115.056351,
			"name": "Stanford Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5350 East Harris Avenue",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/159-Stanford-Elementary-School/",
				"reviews": "/nevada/las-vegas/159-Stanford-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/159-Stanford-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 638,
			"parentRating": 5,
			"numReviews": 6,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 77
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 807,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 35.982899,
			"lon": -115.157928,
			"name": "Steve Schorr Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "11420 Placid Street",
				"street2": null,
				"zip": "89183",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89183",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/807-Steve-Schorr-Elementary-School/",
				"reviews": "/nevada/las-vegas/807-Steve-Schorr-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/807-Steve-Schorr-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "49%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 746,
			"parentRating": 4,
			"numReviews": 21,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 49
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 29
				},
				{
					"label": "Pacific Islander",
					"rating": 8,
					"percentage": 6
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 29
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 14
				}
			],
			"remediationData": {}
		},
		{
			"id": 600,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.156666,
			"lon": -115.229012,
			"name": "Summit School",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "6171 West Charleston Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/600-Summit-School/",
				"reviews": "/nevada/las-vegas/600-Summit-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/600-Summit-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "50%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 50
				},
				{
					"label": "White",
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"percentage": 25
				},
				{
					"label": "African American",
					"percentage": 25
				}
			],
			"remediationData": {}
		},
		{
			"id": 1052,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.188538,
			"lon": -115.15892,
			"name": "Sunflower Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1310 West Owens Avenue",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1052-Sunflower-Head-Start/",
				"reviews": "/nevada/las-vegas/1052-Sunflower-Head-Start/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1052-Sunflower-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 104,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.165009,
			"lon": -115.112602,
			"name": "Sunrise Acres Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "211 North 28th Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/104-Sunrise-Acres-Elementary-School/",
				"reviews": "/nevada/las-vegas/104-Sunrise-Acres-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/104-Sunrise-Acres-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 773,
			"parentRating": 3,
			"numReviews": 6,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 66
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1320,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.151058,
			"lon": -115.213264,
			"name": "Sunrise Montessori Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5355 West Oakey Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1320-Sunrise-Montessori-Academy/",
				"reviews": "/nevada/las-vegas/1320-Sunrise-Montessori-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1320-Sunrise-Montessori-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1200,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.205555,
			"lon": -115.019814,
			"name": "Sunrise Mountain High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "2575 Los Feliz Street",
				"street2": null,
				"zip": "89156",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89156",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1200-Sunrise-Mountain-High-School/",
				"reviews": "/nevada/las-vegas/1200-Sunrise-Mountain-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1200-Sunrise-Mountain-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "77%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2562,
			"parentRating": 4,
			"numReviews": 3,
			"studentsPerTeacher": 24,
			"subratings": {
				"College Readiness Rating": 2,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 77
				},
				{
					"label": "Hispanic",
					"percentage": 79
				},
				{
					"label": "African American",
					"percentage": 11
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 985,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.202068,
			"lon": -115.047859,
			"name": "Sunrise Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2240 Linn Ln",
				"street2": null,
				"zip": "89156",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89156",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/985-Sunrise-Preschool/",
				"reviews": "/nevada/las-vegas/985-Sunrise-Preschool/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/985-Sunrise-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 697,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.070953,
			"lon": -115.09819,
			"name": "Sunset Montessori Community School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3525 East Sunset Road",
				"street2": null,
				"zip": "89120",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89120",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/697-Sunset-Montessori-Community-School/",
				"reviews": "/nevada/las-vegas/697-Sunset-Montessori-Community-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/697-Sunset-Montessori-Community-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 48,
			"parentRating": 5,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1007,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.116734,
			"lon": -115.242569,
			"name": "Susan Marvosh Group Care Home",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3977 Avila St",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1007-Susan-Marvosh-Group-Care-Home/",
				"reviews": "/nevada/las-vegas/1007-Susan-Marvosh-Group-Care-Home/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1007-Susan-Marvosh-Group-Care-Home/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 992,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.139301,
			"lon": -115.224548,
			"name": "Sweet Peas Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2760 S Jones Blvd",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/992-Sweet-Peas-Learning-Center/",
				"reviews": "/nevada/las-vegas/992-Sweet-Peas-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/992-Sweet-Peas-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 986,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.129536,
			"lon": -115.120636,
			"name": "Teacher's Apple Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2253 East Desert Inn Road",
				"street2": null,
				"zip": "89169",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89169",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/986-Teachers-Apple-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/986-Teachers-Apple-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/986-Teachers-Apple-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1319,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.133739,
			"lon": -115.326591,
			"name": "Temple Beth Shalom Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "10700 Havenwood Lane",
				"street2": null,
				"zip": "89135",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89135",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1319-Temple-Beth-Shalom-Pre-School/",
				"reviews": "/nevada/las-vegas/1319-Temple-Beth-Shalom-Pre-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1319-Temple-Beth-Shalom-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 661,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.141819,
			"lon": -115.282356,
			"name": "The Lakes KinderCare",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2600 Lake Sahara Dr",
				"street2": null,
				"zip": "89117",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89117",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/661-The-Lakes-KinderCare/",
				"reviews": "/nevada/las-vegas/661-The-Lakes-KinderCare/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/661-The-Lakes-KinderCare/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 503,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 36.19521,
			"lon": -115.282204,
			"name": "The Meadows School",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "8601 Scholar Lane",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/503-The-Meadows-School/",
				"reviews": "/nevada/las-vegas/503-The-Meadows-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/503-The-Meadows-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 896,
			"parentRating": 4,
			"numReviews": 24,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 61
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 20
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 754,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.199799,
			"lon": -115.277298,
			"name": "The Summit School",
			"gradeLevels": "PK-1",
			"assigned": null,
			"address": {
				"street1": "2121 Harbor Island Dr",
				"street2": null,
				"zip": "89128",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89128",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/754-The-Summit-School/",
				"reviews": "/nevada/las-vegas/754-The-Summit-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/754-The-Summit-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 827,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.32497,
			"lon": -115.366714,
			"name": "Thomas O'Roarke Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8455 Ohare Road",
				"street2": null,
				"zip": "89166",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89166",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/827-Thomas-Oroarke-Elementary-School/",
				"reviews": "/nevada/las-vegas/827-Thomas-Oroarke-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/827-Thomas-Oroarke-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "27%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 816,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 9,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 27
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 10,
					"percentage": 18
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 59
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1393,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.173042,
			"lon": -115.081474,
			"name": "Tinker Town Learn & Play Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4339 East Bonanza Road",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1393-Tinker-Town-Learn--Play-Child-Care-Center/",
				"reviews": "/nevada/las-vegas/1393-Tinker-Town-Learn--Play-Child-Care-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1393-Tinker-Town-Learn--Play-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 632,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.053135,
			"lon": -115.231667,
			"name": "Tony Alamo Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "7455 El Camino Road",
				"street2": null,
				"zip": "89139",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89139",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/632-Tony-Alamo-Elementary-School/",
				"reviews": "/nevada/las-vegas/632-Tony-Alamo-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/632-Tony-Alamo-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1032,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 53
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 23
				},
				{
					"label": "Pacific Islander",
					"rating": 5,
					"percentage": 5
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 20
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 24
				}
			],
			"remediationData": {}
		},
		{
			"id": 901,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 36.231277,
			"lon": -115.259659,
			"name": "Top Christian Learning Academy",
			"gradeLevels": "K-3",
			"assigned": null,
			"address": {
				"street1": "7550 Constantinople Ave",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/901-Top-Christian-Learning-Academy/",
				"reviews": "/nevada/las-vegas/901-Top-Christian-Learning-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/901-Top-Christian-Learning-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 30,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1318,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.231712,
			"lon": -115.259514,
			"name": "Top Kids Learning Enrich Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "7550 Constantinople Avenue",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1318-Top-Kids-Learning-Enrich-Center/",
				"reviews": "/nevada/las-vegas/1318-Top-Kids-Learning-Enrich-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1318-Top-Kids-Learning-Enrich-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1408,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.027988,
			"lon": -115.119392,
			"name": "Top Of The Class Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "8889 South Eastern Avenue",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1408-Top-Of-The-Class-Learning-Center/",
				"reviews": "/nevada/las-vegas/1408-Top-Of-The-Class-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1408-Top-Of-The-Class-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 881,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.115234,
			"lon": -115.233421,
			"name": "Torrey Pines KinderCare",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4050 South Torrey Pines Drive",
				"street2": null,
				"zip": "89103",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89103",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/881-Torrey-Pines-KinderCare/",
				"reviews": "/nevada/las-vegas/881-Torrey-Pines-KinderCare/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/881-Torrey-Pines-KinderCare/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 673,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.203033,
			"lon": -115.238182,
			"name": "Tree of Life Christian School",
			"gradeLevels": "PK-2",
			"assigned": null,
			"address": {
				"street1": "6565 Smoke Ranch Road",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/673-Tree-Of-Life-Christian-School/",
				"reviews": "/nevada/las-vegas/673-Tree-Of-Life-Christian-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/673-Tree-Of-Life-Christian-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 487,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.147808,
			"lon": -115.142418,
			"name": "Trinity Christian Schools",
			"gradeLevels": "PK-6",
			"assigned": null,
			"address": {
				"street1": "950 E St. Louis",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/487-Trinity-Christian-Schools/",
				"reviews": "/nevada/las-vegas/487-Trinity-Christian-Schools/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/487-Trinity-Christian-Schools/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1234,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 36.147141,
			"lon": -115.145859,
			"name": "Trinity International School",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "700 East Saint Louis Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1234-Trinity-International-School/",
				"reviews": "/nevada/las-vegas/1234-Trinity-International-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1234-Trinity-International-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 70,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 41
				},
				{
					"label": "White",
					"percentage": 27
				},
				{
					"label": "African American",
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 903,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 36.169003,
			"lon": -115.196335,
			"name": "Trinity International Schools",
			"gradeLevels": "5-12",
			"assigned": null,
			"address": {
				"street1": "4141 Meadows Lane",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/903-Trinity-International-Schools/",
				"reviews": "/nevada/las-vegas/903-Trinity-International-Schools/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/903-Trinity-International-Schools/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 695,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.158245,
			"lon": -115.225281,
			"name": "Trinity United Metodist Church School/Child Care",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "6151 West Charleston Boulevard",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/695-Trinity-United-Metodist-Church-School-Child-Care/",
				"reviews": "/nevada/las-vegas/695-Trinity-United-Metodist-Church-School-Child-Care/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/695-Trinity-United-Metodist-Church-School-Child-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 83,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 55
				},
				{
					"label": "African American",
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"percentage": 18
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 107,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.184635,
			"lon": -115.186325,
			"name": "Twin Lakes Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3300 Riverside Drive",
				"street2": null,
				"zip": "89108",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89108",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/107-Twin-Lakes-Elementary-School/",
				"reviews": "/nevada/las-vegas/107-Twin-Lakes-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/107-Twin-Lakes-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "99%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 552,
			"parentRating": 1,
			"numReviews": 1,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 99
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 81
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1011,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.110138,
			"lon": -115.137093,
			"name": "Univ Methodist Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4412 South Maryland Parkway",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1011-Univ-Methodist-Child-Development-Center/",
				"reviews": "/nevada/las-vegas/1011-Univ-Methodist-Child-Development-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1011-Univ-Methodist-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 524,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 36.109402,
			"lon": -115.133003,
			"name": "University Baptist Academy",
			"gradeLevels": "1-11",
			"assigned": null,
			"address": {
				"street1": "1490 E University Av",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/524-University-Baptist-Academy/",
				"reviews": "/nevada/las-vegas/524-University-Baptist-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/524-University-Baptist-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 1304,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.109512,
			"lon": -115.141937,
			"name": "Unlv Csun Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4505 S Maryland Pkwy",
				"street2": null,
				"zip": "89154",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89154",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1304-Unlv-Csun-Pre-School/",
				"reviews": "/nevada/las-vegas/1304-Unlv-Csun-Pre-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1304-Unlv-Csun-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 108,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.179447,
			"lon": -115.231064,
			"name": "Vail Pittman Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "6333 Fargo Avenue",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/108-Vail-Pittman-Elementary-School/",
				"reviews": "/nevada/las-vegas/108-Vail-Pittman-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/108-Vail-Pittman-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 624,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 63
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 109,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.139027,
			"lon": -115.121468,
			"name": "Valley High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "2839 Burnham Avenue",
				"street2": null,
				"zip": "89169",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89169",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/109-Valley-High-School/",
				"reviews": "/nevada/las-vegas/109-Valley-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/109-Valley-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "76%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2782,
			"parentRating": 5,
			"numReviews": 9,
			"studentsPerTeacher": 22,
			"subratings": {
				"College Readiness Rating": 2,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 76
				},
				{
					"label": "Hispanic",
					"percentage": 68
				},
				{
					"label": "African American",
					"percentage": 14
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 756,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 36.182167,
			"lon": -115.146317,
			"name": "Variety Early Learning Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "700 Twin Lakes Drive",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/756-Variety-Early-Learning-Center/",
				"reviews": "/nevada/las-vegas/756-Variety-Early-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/756-Variety-Early-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 111,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.149742,
			"lon": -115.195419,
			"name": "Vegas Verdes Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4000 West El Parque Avenue",
				"street2": null,
				"zip": "89102",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89102",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/111-Vegas-Verdes-Elementary-School/",
				"reviews": "/nevada/las-vegas/111-Vegas-Verdes-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/111-Vegas-Verdes-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 712,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 73
				},
				{
					"label": "White",
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1201,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.188091,
			"lon": -115.177109,
			"name": "Veterans Tribute Career Techincal Academy",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "2531 Vegas Drive",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1201-Veterans-Tribute-Career-Techincal-Academy/",
				"reviews": "/nevada/las-vegas/1201-Veterans-Tribute-Career-Techincal-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1201-Veterans-Tribute-Career-Techincal-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "62%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 811,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 24,
			"subratings": {
				"College Readiness Rating": 9,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 62
				},
				{
					"label": "Hispanic",
					"percentage": 68
				},
				{
					"label": "White",
					"percentage": 18
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 639,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.089481,
			"lon": -115.31015,
			"name": "Victoria Fertitta Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "9905 West Mesa Vista Avenue",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/639-Victoria-Fertitta-Middle-School/",
				"reviews": "/nevada/las-vegas/639-Victoria-Fertitta-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/639-Victoria-Fertitta-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "44%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1395,
			"parentRating": 3,
			"numReviews": 38,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 44
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 25
				},
				{
					"label": "Pacific Islander",
					"rating": 9,
					"percentage": 3
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 16
				}
			],
			"remediationData": {}
		},
		{
			"id": 114,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.18399,
			"lon": -115.112625,
			"name": "Walter Bracken Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1200 North 27th Street",
				"street2": null,
				"zip": "89101",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89101",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/114-Walter-Bracken-Elementary-School/",
				"reviews": "/nevada/las-vegas/114-Walter-Bracken-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/114-Walter-Bracken-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "67%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 511,
			"parentRating": 5,
			"numReviews": 16,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 67
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 10,
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 65
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 182,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.163177,
			"lon": -115.275444,
			"name": "Walter Jacobson Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8400 Boseck Drive",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/182-Walter-Jacobson-Elementary-School/",
				"reviews": "/nevada/las-vegas/182-Walter-Jacobson-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/182-Walter-Jacobson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "65%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 534,
			"parentRating": 5,
			"numReviews": 12,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 65
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 16
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 43
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 10
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 196,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.168377,
			"lon": -115.262054,
			"name": "Walter Johnson Junior High School Academy of International S",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "7701 Ducharme Avenue",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/196-Walter-Johnson-Junior-High-School/",
				"reviews": "/nevada/las-vegas/196-Walter-Johnson-Junior-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/196-Walter-Johnson-Junior-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "65%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1508,
			"parentRating": 3,
			"numReviews": 18,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 65
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 46
				},
				{
					"label": "Pacific Islander",
					"rating": 9,
					"percentage": 2
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 115,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.150124,
			"lon": -115.08886,
			"name": "Walter V Long Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2000 South Walnut Road",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/115-Walter-V-Long-Elementary-School/",
				"reviews": "/nevada/las-vegas/115-Walter-V-Long-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/115-Walter-V-Long-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 820,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 71
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 546,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.137489,
			"lon": -115.209038,
			"name": "Wassell Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "5000 Edna Avenue",
				"street2": null,
				"zip": "89146",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89146",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/546-Wassell-Academy/",
				"reviews": "/nevada/las-vegas/546-Wassell-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/546-Wassell-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 31,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 64
				},
				{
					"label": "Hispanic",
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 1445,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.090481,
			"lon": -115.306183,
			"name": "Water of Life Lutheran School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5430 S. Grand Canyon Dr.",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1445-Water-Of-Life-Lutheran-School/",
				"reviews": "/nevada/las-vegas/1445-Water-Of-Life-Lutheran-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1445-Water-Of-Life-Lutheran-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 787,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.061874,
			"lon": -115.291443,
			"name": "Wayne N Tanaka Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9135 West Maule Avenue",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/787-Wayne-N-Tanaka-Elementary-School/",
				"reviews": "/nevada/las-vegas/787-Wayne-N-Tanaka-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/787-Wayne-N-Tanaka-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "55%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 840,
			"parentRating": 3,
			"numReviews": 14,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 55
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 27
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 27
				},
				{
					"label": "Two or more races",
					"rating": 6,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 75,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.182701,
			"lon": -115.155197,
			"name": "Wendell P. Williams Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "1030 J Street",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/75-Wendell-P.-Williams-Elementary-School/",
				"reviews": "/nevada/las-vegas/75-Wendell-P.-Williams-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/75-Wendell-P.-Williams-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 358,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 12,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 68
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 23
				},
				{
					"label": "White",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1209,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.156406,
			"lon": -115.354156,
			"name": "West Career & Technical Academy",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "11945 West Charleston Boulevard",
				"street2": null,
				"zip": "89145",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89145",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1209-West-Career--Technical-Academy/",
				"reviews": "/nevada/las-vegas/1209-West-Career--Technical-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1209-West-Career--Technical-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "21%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1402,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 21,
			"subratings": {
				"College Readiness Rating": 10,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 21
				},
				{
					"label": "White",
					"percentage": 45
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 25
				},
				{
					"label": "Hispanic",
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 1246,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 36.159409,
			"lon": -115.185616,
			"name": "West Charleston Enrichment Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "3216 W. Charleston Blvd. Ste. B",
				"street2": null,
				"zip": "89131",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89131",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1246-West-Charleston-Enrichment-Academy/",
				"reviews": "/nevada/las-vegas/1246-West-Charleston-Enrichment-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1246-West-Charleston-Enrichment-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 16,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 832,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.194466,
			"lon": -115.167099,
			"name": "West Prepatory Academy at Charles I West Hall Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2050 Sapphire Stone Avenue",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/832-West-Prepatory-Academy-At-Charles-I-West-Hall-Elementary/",
				"reviews": "/nevada/las-vegas/832-West-Prepatory-Academy-At-Charles-I-West-Hall-Elementary/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/832-West-Prepatory-Academy-At-Charles-I-West-Hall-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "88%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 467,
			"parentRating": 4,
			"numReviews": 2,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 88
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 70
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 226,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m,h",
			"lat": 36.194466,
			"lon": -115.167099,
			"name": "West Prepatory Academy at Charlles I West Hall Sec",
			"gradeLevels": "6-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "2050 Sapphire Stone Avenue",
				"street2": null,
				"zip": "89106",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89106",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/226-West-Prepatory-Academy-At-Charlles-I-West-Hall-Sec/",
				"reviews": "/nevada/las-vegas/226-West-Prepatory-Academy-At-Charlles-I-West-Hall-Sec/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/226-West-Prepatory-Academy-At-Charlles-I-West-Hall-Sec/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "88%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1586,
			"parentRating": 3,
			"numReviews": 2,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 5,
				"College Readiness Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 88
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 30
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 63
				},
				{
					"label": "White",
					"rating": 1,
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"rating": 2,
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 116,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "h",
			"lat": 36.176834,
			"lon": -115.203041,
			"name": "Western High School",
			"gradeLevels": "9-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "4601 West Bonanza Road",
				"street2": null,
				"zip": "89107",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89107",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/116-Western-High-School/",
				"reviews": "/nevada/las-vegas/116-Western-High-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/116-Western-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "74%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2828,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 24,
			"subratings": {
				"College Readiness Rating": 2,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 74
				},
				{
					"label": "Hispanic",
					"percentage": 72
				},
				{
					"label": "African American",
					"percentage": 16
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 117,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.099766,
			"lon": -115.044609,
			"name": "Whitney Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "5005 Keenan Avenue",
				"street2": null,
				"zip": "89122",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89122",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/117-Whitney-Elementary-School/",
				"reviews": "/nevada/las-vegas/117-Whitney-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/117-Whitney-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 492,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 25
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 47
				},
				{
					"label": "White",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 842,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.061848,
			"lon": -115.299911,
			"name": "Wilbur & Theresa Faiss Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "9525 West Maule Avenue",
				"street2": null,
				"zip": "89148",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89148",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/842-Wilbur--Theresa-Faiss-Middle-School/",
				"reviews": "/nevada/las-vegas/842-Wilbur--Theresa-Faiss-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/842-Wilbur--Theresa-Faiss-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "54%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1728,
			"parentRating": 3,
			"numReviews": 28,
			"studentsPerTeacher": 27,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 54
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 28
				},
				{
					"label": "Pacific Islander",
					"rating": 4,
					"percentage": 3
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 28
				},
				{
					"label": "Two or more races",
					"rating": 7,
					"percentage": 13
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 14
				}
			],
			"remediationData": {}
		},
		{
			"id": 118,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.131329,
			"lon": -115.100349,
			"name": "Will Beckley Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "3223 Glenhurst Drive",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/118-Will-Beckley-Elementary-School/",
				"reviews": "/nevada/las-vegas/118-Will-Beckley-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/118-Will-Beckley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 932,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 74
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 785,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.320396,
			"lon": -115.300194,
			"name": "William & Mary Scherkenbach Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9371 Iron Mountain Road",
				"street2": null,
				"zip": "89143",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89143",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/785-William--Mary-Scherkenbach-Elementary-School/",
				"reviews": "/nevada/las-vegas/785-William--Mary-Scherkenbach-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/785-William--Mary-Scherkenbach-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 576,
			"parentRating": 3,
			"numReviews": 14,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 28
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 44
				},
				{
					"label": "Two or more races",
					"rating": 5,
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 120,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.112846,
			"lon": -115.072037,
			"name": "WIlliam E  Ferron Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4200 South Mountain Vista Street",
				"street2": null,
				"zip": "89121",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89121",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/120-William-E--Ferron-Elementary-School/",
				"reviews": "/nevada/las-vegas/120-William-E--Ferron-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/120-William-E--Ferron-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 647,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 66
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 119,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "m",
			"lat": 36.121361,
			"lon": -115.130737,
			"name": "William E Orr Middle School",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1562 East Katie Avenue",
				"street2": null,
				"zip": "89119",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89119",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/119-William-E-Orr-Middle-School/",
				"reviews": "/nevada/las-vegas/119-William-E-Orr-Middle-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/119-William-E-Orr-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1049,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Asian",
					"rating": 6
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 24
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 58
				},
				{
					"label": "White",
					"rating": 2,
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"rating": 2,
					"percentage": 5
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 614,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.155064,
			"lon": -115.080353,
			"name": "William E Snyder Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4317 East Colorado Avenue",
				"street2": null,
				"zip": "89104",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89104",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/614-William-E-Snyder-Elementary-School/",
				"reviews": "/nevada/las-vegas/614-William-E-Snyder-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/614-William-E-Snyder-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 850,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 64
				},
				{
					"label": "White",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 591,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.168846,
			"lon": -115.083015,
			"name": "William K. Moore Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "491 North Lamb Boulevard",
				"street2": null,
				"zip": "89110",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89110",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/591-William-K.-Moore-Elementary-School/",
				"reviews": "/nevada/las-vegas/591-William-K.-Moore-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/591-William-K.-Moore-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 668,
			"parentRating": 3,
			"numReviews": 2,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "African American",
					"rating": 2,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 73
				},
				{
					"label": "White",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 127,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.199661,
			"lon": -115.2901,
			"name": "William Lummis Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "9000 Hillpointe Road",
				"street2": null,
				"zip": "89134",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89134",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/127-William-Lummis-Elementary-School/",
				"reviews": "/nevada/las-vegas/127-William-Lummis-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/127-William-Lummis-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 510,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 26
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 18
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 59
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 811,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.014072,
			"lon": -115.275818,
			"name": "William V Wright Elementary School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "8425 Bob Fisk Avenue",
				"street2": null,
				"zip": "89178",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89178",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/811-William-V-Wright-Elementary-School/",
				"reviews": "/nevada/las-vegas/811-William-V-Wright-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/811-William-V-Wright-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "36%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1261,
			"parentRating": 4,
			"numReviews": 23,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 36
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 8
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 28
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 15
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 683,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 36.224319,
			"lon": -115.259972,
			"name": "Word Of Life Christian Academy",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "3520 North Buffalo Drive",
				"street2": null,
				"zip": "89129",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89129",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/683-Word-Of-Life-Christian-Academy/",
				"reviews": "/nevada/las-vegas/683-Word-Of-Life-Christian-Academy/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/683-Word-Of-Life-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 252,
			"parentRating": 5,
			"numReviews": 133,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "African American",
					"percentage": 45
				},
				{
					"label": "White",
					"percentage": 35
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 1416,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 36.041794,
			"lon": -115.158257,
			"name": "Young Disciples Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "325 East Windmill Lane",
				"street2": null,
				"zip": "89123",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "NV",
			"zipcode": "89123",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/1416-Young-Disciples-Learning-Center/",
				"reviews": "/nevada/las-vegas/1416-Young-Disciples-Learning-Center/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/1416-Young-Disciples-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 130,
			"districtId": 2,
			"districtName": "Clark County School District",
			"districtCity": "Las Vegas",
			"levelCode": "p,e",
			"lat": 36.237492,
			"lon": -115.067406,
			"name": "Zel & Mary Lowman Elementary",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "4225 North Lamont Street",
				"street2": null,
				"zip": "89115",
				"city": "Las Vegas"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "NV",
			"zipcode": "89115",
			"type": "school",
			"links": {
				"profile": "/nevada/las-vegas/130-Zel--Mary-Lowman-Elementary-School/",
				"reviews": "/nevada/las-vegas/130-Zel--Mary-Lowman-Elementary-School/#Reviews",
				"collegeSuccess": "/nevada/las-vegas/130-Zel--Mary-Lowman-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "100%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 952,
			"parentRating": 2,
			"numReviews": 13,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 100
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 48
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 37
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		}
	]