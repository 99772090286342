const mountain_view = {
    "geography": {
       "census_release": "ACS 2019 1-year",
       "parents": {
          "county": {
             "full_name": "Santa Clara County, CA",
             "short_name": "Santa Clara County",
             "sumlevel": "050",
             "land_area": 3343890934,
             "full_geoid": "05000US06085",
             "total_population": 1927852
          },
          "CBSA": {
             "full_name": "San Jose-Sunnyvale-Santa Clara, CA Metro Area",
             "short_name": "San Jose-Sunnyvale-Santa Clara, CA",
             "sumlevel": "310",
             "land_area": 6940482016,
             "full_geoid": "31000US41940",
             "total_population": 1990660
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39512223
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 328239523
          }
       },
       "this": {
          "full_name": "Mountain View, CA",
          "short_name": "Mountain View",
          "sumlevel": "160",
          "land_area": 31055404,
          "full_geoid": "16000US0649670",
          "total_population": 82726,
          "sumlevel_name": "Place",
          "short_geoid": "0649670"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "1"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 18.23,
                   "CBSA": 21.67,
                   "state": 22.5
                },
                "error": {
                   "this": 2.93,
                   "CBSA": 0.3,
                   "state": 0.08
                },
                "numerators": {
                   "this": 15078,
                   "CBSA": 431307,
                   "state": 8891064
                },
                "numerator_errors": {
                   "this": 2427.3,
                   "CBSA": 5887.9,
                   "state": 32008.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 84,
                   "state": 81
                },
                "error_ratio": {
                   "this": 16.1,
                   "CBSA": 1.4,
                   "state": 0.4
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 70.35,
                   "CBSA": 64.48,
                   "state": 62.73
                },
                "error": {
                   "this": 5.05,
                   "CBSA": 0.51,
                   "state": 0.13
                },
                "numerators": {
                   "this": 58197,
                   "CBSA": 1283572,
                   "state": 2.478616E7
                },
                "numerator_errors": {
                   "this": 4174.6,
                   "CBSA": 10170.5,
                   "state": 51870.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 112
                },
                "error_ratio": {
                   "this": 7.2,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 11.42,
                   "CBSA": 13.85,
                   "state": 14.77
                },
                "error": {
                   "this": 1.9,
                   "CBSA": 0.33,
                   "state": 0.08
                },
                "numerators": {
                   "this": 9451,
                   "CBSA": 275781,
                   "state": 5834998
                },
                "numerator_errors": {
                   "this": 1568.7,
                   "CBSA": 6572.8,
                   "state": 32031.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 82,
                   "state": 77
                },
                "error_ratio": {
                   "this": 16.6,
                   "CBSA": 2.4,
                   "state": 0.5
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 9.86,
                      "CBSA": 11.4,
                      "state": 12.04
                   },
                   "error": {
                      "this": 2.1,
                      "CBSA": 0.21,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 8154,
                      "CBSA": 226908,
                      "state": 4758601
                   },
                   "numerator_errors": {
                      "this": 1737.2,
                      "CBSA": 4128.5,
                      "state": 23052.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 86,
                      "state": 82
                   },
                   "error_ratio": {
                      "this": 21.3,
                      "CBSA": 1.8,
                      "state": 0.5
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 9.75,
                      "CBSA": 12.62,
                      "state": 13.1
                   },
                   "error": {
                      "this": 2.15,
                      "CBSA": 0.22,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 8068,
                      "CBSA": 251248,
                      "state": 5176959
                   },
                   "numerator_errors": {
                      "this": 1779.8,
                      "CBSA": 4326,
                      "state": 23034.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 77,
                      "state": 74
                   },
                   "error_ratio": {
                      "this": 22.1,
                      "CBSA": 1.7,
                      "state": 0.5
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 19.07,
                      "CBSA": 14.2,
                      "state": 14.49
                   },
                   "error": {
                      "this": 3.2,
                      "CBSA": 0.25,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 15773,
                      "CBSA": 282582,
                      "state": 5723923
                   },
                   "numerator_errors": {
                      "this": 2647.3,
                      "CBSA": 4901.7,
                      "state": 24219.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 134,
                      "state": 132
                   },
                   "error_ratio": {
                      "this": 16.8,
                      "CBSA": 1.8,
                      "state": 0.4
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 20.05,
                      "CBSA": 15.73,
                      "state": 14.47
                   },
                   "error": {
                      "this": 2.52,
                      "CBSA": 0.23,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 16587,
                      "CBSA": 313158,
                      "state": 5715837
                   },
                   "numerator_errors": {
                      "this": 2086.6,
                      "CBSA": 4601.1,
                      "state": 22852.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 127,
                      "state": 139
                   },
                   "error_ratio": {
                      "this": 12.6,
                      "CBSA": 1.5,
                      "state": 0.4
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.16,
                      "CBSA": 13.7,
                      "state": 12.77
                   },
                   "error": {
                      "this": 2,
                      "CBSA": 0.22,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 11713,
                      "CBSA": 272672,
                      "state": 5046965
                   },
                   "numerator_errors": {
                      "this": 1651.4,
                      "CBSA": 4361.4,
                      "state": 22530.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 103,
                      "state": 111
                   },
                   "error_ratio": {
                      "this": 14.1,
                      "CBSA": 1.6,
                      "state": 0.5
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 11.3,
                      "CBSA": 12.82,
                      "state": 12.46
                   },
                   "error": {
                      "this": 1.73,
                      "CBSA": 0.21,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 9348,
                      "CBSA": 255250,
                      "state": 4923782
                   },
                   "numerator_errors": {
                      "this": 1427.4,
                      "CBSA": 4138.3,
                      "state": 20800
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 88,
                      "state": 91
                   },
                   "error_ratio": {
                      "this": 15.3,
                      "CBSA": 1.6,
                      "state": 0.4
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 7.43,
                      "CBSA": 10.03,
                      "state": 10.63
                   },
                   "error": {
                      "this": 1.58,
                      "CBSA": 0.3,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 6149,
                      "CBSA": 199720,
                      "state": 4200080
                   },
                   "numerator_errors": {
                      "this": 1303.6,
                      "CBSA": 6027.7,
                      "state": 31191.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 74,
                      "state": 70
                   },
                   "error_ratio": {
                      "this": 21.3,
                      "CBSA": 3,
                      "state": 0.8
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 5.66,
                      "CBSA": 5.91,
                      "state": 6.44
                   },
                   "error": {
                      "this": 1.38,
                      "CBSA": 0.19,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 4679,
                      "CBSA": 117680,
                      "state": 2545061
                   },
                   "numerator_errors": {
                      "this": 1144.2,
                      "CBSA": 3875.8,
                      "state": 19797.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 96,
                      "state": 88
                   },
                   "error_ratio": {
                      "this": 24.4,
                      "CBSA": 3.2,
                      "state": 0.8
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 2.73,
                      "CBSA": 3.59,
                      "state": 3.6
                   },
                   "error": {
                      "this": 0.82,
                      "CBSA": 0.18,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 2255,
                      "CBSA": 71442,
                      "state": 1421015
                   },
                   "numerator_errors": {
                      "this": 677.1,
                      "CBSA": 3584.6,
                      "state": 16282.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 76,
                      "state": 76
                   },
                   "error_ratio": {
                      "this": 30,
                      "CBSA": 5,
                      "state": 1.1
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 9.71,
                      "CBSA": 11.36,
                      "state": 12.35
                   },
                   "error": {
                      "this": 2.82,
                      "CBSA": 0.27,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 4099,
                      "CBSA": 114531,
                      "state": 2425066
                   },
                   "numerator_errors": {
                      "this": 1217.4,
                      "CBSA": 2720.7,
                      "state": 17537.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 85,
                      "state": 79
                   },
                   "error_ratio": {
                      "this": 29,
                      "CBSA": 2.4,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 10.34,
                      "CBSA": 12.91,
                      "state": 13.47
                   },
                   "error": {
                      "this": 3.33,
                      "CBSA": 0.29,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 4362,
                      "CBSA": 130176,
                      "state": 2646132
                   },
                   "numerator_errors": {
                      "this": 1432.1,
                      "CBSA": 2892.4,
                      "state": 17211.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 80,
                      "state": 77
                   },
                   "error_ratio": {
                      "this": 32.2,
                      "CBSA": 2.2,
                      "state": 0.7
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 19.31,
                      "CBSA": 15.04,
                      "state": 15.06
                   },
                   "error": {
                      "this": 4.76,
                      "CBSA": 0.33,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 8148,
                      "CBSA": 151632,
                      "state": 2956969
                   },
                   "numerator_errors": {
                      "this": 2071.2,
                      "CBSA": 3378.7,
                      "state": 16123.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 128,
                      "state": 128
                   },
                   "error_ratio": {
                      "this": 24.7,
                      "CBSA": 2.2,
                      "state": 0.5
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 21.76,
                      "CBSA": 16.55,
                      "state": 14.93
                   },
                   "error": {
                      "this": 3.74,
                      "CBSA": 0.38,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 9183,
                      "CBSA": 166869,
                      "state": 2932306
                   },
                   "numerator_errors": {
                      "this": 1676.8,
                      "CBSA": 3850.8,
                      "state": 17684.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 131,
                      "state": 146
                   },
                   "error_ratio": {
                      "this": 17.2,
                      "CBSA": 2.3,
                      "state": 0.6
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14,
                      "CBSA": 13.52,
                      "state": 12.82
                   },
                   "error": {
                      "this": 2.85,
                      "CBSA": 0.34,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 5906,
                      "CBSA": 136309,
                      "state": 2518785
                   },
                   "numerator_errors": {
                      "this": 1257.7,
                      "CBSA": 3398.8,
                      "state": 17201
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 104,
                      "state": 109
                   },
                   "error_ratio": {
                      "this": 20.4,
                      "CBSA": 2.5,
                      "state": 0.7
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 11.69,
                      "CBSA": 12.66,
                      "state": 12.42
                   },
                   "error": {
                      "this": 2.37,
                      "CBSA": 0.3,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 4935,
                      "CBSA": 127634,
                      "state": 2439055
                   },
                   "numerator_errors": {
                      "this": 1045.4,
                      "CBSA": 3031.9,
                      "state": 15253.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 92,
                      "state": 94
                   },
                   "error_ratio": {
                      "this": 20.3,
                      "CBSA": 2.4,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 6.88,
                      "CBSA": 9.84,
                      "state": 10.2
                   },
                   "error": {
                      "this": 2.29,
                      "CBSA": 0.45,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 2904,
                      "CBSA": 99211,
                      "state": 2004301
                   },
                   "numerator_errors": {
                      "this": 981.3,
                      "CBSA": 4513.4,
                      "state": 21480.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 70,
                      "state": 67
                   },
                   "error_ratio": {
                      "this": 33.3,
                      "CBSA": 4.6,
                      "state": 1.1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 4.47,
                      "CBSA": 5.31,
                      "state": 5.89
                   },
                   "error": {
                      "this": 1.81,
                      "CBSA": 0.27,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 1888,
                      "CBSA": 53516,
                      "state": 1156395
                   },
                   "numerator_errors": {
                      "this": 772,
                      "CBSA": 2722.6,
                      "state": 13944.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 84,
                      "state": 76
                   },
                   "error_ratio": {
                      "this": 40.5,
                      "CBSA": 5.1,
                      "state": 1.2
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 1.84,
                      "CBSA": 2.8,
                      "state": 2.86
                   },
                   "error": {
                      "this": 0.72,
                      "CBSA": 0.2,
                      "state": 0.05
                   },
                   "numerators": {
                      "this": 775,
                      "CBSA": 28278,
                      "state": 561785
                   },
                   "numerator_errors": {
                      "this": 308.8,
                      "CBSA": 2064.4,
                      "state": 10571.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 66,
                      "state": 64
                   },
                   "error_ratio": {
                      "this": 39.1,
                      "CBSA": 7.1,
                      "state": 1.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 10.01,
                      "CBSA": 11.44,
                      "state": 11.74
                   },
                   "error": {
                      "this": 2.99,
                      "CBSA": 0.32,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 4055,
                      "CBSA": 112377,
                      "state": 2333535
                   },
                   "numerator_errors": {
                      "this": 1239.3,
                      "CBSA": 3105.2,
                      "state": 14962.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 88,
                      "state": 85
                   },
                   "error_ratio": {
                      "this": 29.9,
                      "CBSA": 2.8,
                      "state": 0.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 9.14,
                      "CBSA": 12.32,
                      "state": 12.74
                   },
                   "error": {
                      "this": 2.54,
                      "CBSA": 0.33,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 3706,
                      "CBSA": 121072,
                      "state": 2530827
                   },
                   "numerator_errors": {
                      "this": 1056.7,
                      "CBSA": 3216.8,
                      "state": 15307.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 74,
                      "state": 72
                   },
                   "error_ratio": {
                      "this": 27.8,
                      "CBSA": 2.7,
                      "state": 0.6
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 18.82,
                      "CBSA": 13.33,
                      "state": 13.92
                   },
                   "error": {
                      "this": 3.89,
                      "CBSA": 0.36,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 7625,
                      "CBSA": 130950,
                      "state": 2766954
                   },
                   "numerator_errors": {
                      "this": 1648.7,
                      "CBSA": 3551.2,
                      "state": 18072.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 141,
                      "state": 135
                   },
                   "error_ratio": {
                      "this": 20.7,
                      "CBSA": 2.7,
                      "state": 0.6
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 18.27,
                      "CBSA": 14.89,
                      "state": 14.01
                   },
                   "error": {
                      "this": 2.83,
                      "CBSA": 0.26,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 7404,
                      "CBSA": 146289,
                      "state": 2783531
                   },
                   "numerator_errors": {
                      "this": 1241.9,
                      "CBSA": 2518.1,
                      "state": 14474.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 123,
                      "state": 130
                   },
                   "error_ratio": {
                      "this": 15.5,
                      "CBSA": 1.7,
                      "state": 0.5
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.33,
                      "CBSA": 13.88,
                      "state": 12.72
                   },
                   "error": {
                      "this": 2.48,
                      "CBSA": 0.28,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 5807,
                      "CBSA": 136363,
                      "state": 2528180
                   },
                   "numerator_errors": {
                      "this": 1070.2,
                      "CBSA": 2733.1,
                      "state": 14551.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 103,
                      "state": 113
                   },
                   "error_ratio": {
                      "this": 17.3,
                      "CBSA": 2,
                      "state": 0.6
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 10.89,
                      "CBSA": 12.99,
                      "state": 12.5
                   },
                   "error": {
                      "this": 2.29,
                      "CBSA": 0.29,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 4413,
                      "CBSA": 127616,
                      "state": 2484727
                   },
                   "numerator_errors": {
                      "this": 972,
                      "CBSA": 2816.6,
                      "state": 14140.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 84,
                      "state": 87
                   },
                   "error_ratio": {
                      "this": 21,
                      "CBSA": 2.2,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 8.01,
                      "CBSA": 10.23,
                      "state": 11.05
                   },
                   "error": {
                      "this": 2.05,
                      "CBSA": 0.41,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 3245,
                      "CBSA": 100509,
                      "state": 2195779
                   },
                   "numerator_errors": {
                      "this": 858.2,
                      "CBSA": 3995.4,
                      "state": 22615.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 78,
                      "state": 72
                   },
                   "error_ratio": {
                      "this": 25.6,
                      "CBSA": 4,
                      "state": 1
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 6.89,
                      "CBSA": 6.53,
                      "state": 6.99
                   },
                   "error": {
                      "this": 2.04,
                      "CBSA": 0.28,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 2791,
                      "CBSA": 64164,
                      "state": 1388666
                   },
                   "numerator_errors": {
                      "this": 844.6,
                      "CBSA": 2758.5,
                      "state": 14053.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 106,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 29.6,
                      "CBSA": 4.3,
                      "state": 1
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 3.65,
                      "CBSA": 4.39,
                      "state": 4.32
                   },
                   "error": {
                      "this": 1.47,
                      "CBSA": 0.3,
                      "state": 0.06
                   },
                   "numerators": {
                      "this": 1480,
                      "CBSA": 43164,
                      "state": 859230
                   },
                   "numerator_errors": {
                      "this": 602.6,
                      "CBSA": 2930.5,
                      "state": 12383.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 83,
                      "state": 84
                   },
                   "error_ratio": {
                      "this": 40.3,
                      "CBSA": 6.8,
                      "state": 1.4
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 34.9,
                   "CBSA": 37.4,
                   "state": 37
                },
                "error": {
                   "this": 2.2,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 94
                },
                "error_ratio": {
                   "this": 6.3,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 34.3,
                   "CBSA": 36.3,
                   "state": 35.9
                },
                "error": {
                   "this": 2.6,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 94,
                   "state": 96
                },
                "error_ratio": {
                   "this": 7.6,
                   "CBSA": 0.6,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 35.5,
                   "CBSA": 38.5,
                   "state": 38.2
                },
                "error": {
                   "this": 2.6,
                   "CBSA": 0.2,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 93
                },
                "error_ratio": {
                   "this": 7.3,
                   "CBSA": 0.5,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 51.01,
                "CBSA": 50.64,
                "state": 49.71
             },
             "error": {
                "this": 3.14,
                "CBSA": 0.05,
                "state": 0.02
             },
             "numerators": {
                "this": 42200,
                "CBSA": 1008156,
                "state": 1.9640794E7
             },
             "numerator_errors": {
                "this": 2599,
                "CBSA": 1022,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 103
             },
             "error_ratio": {
                "this": 6.2,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 48.99,
                "CBSA": 49.36,
                "state": 50.29
             },
             "error": {
                "this": 3.14,
                "CBSA": 0.05,
                "state": 0.02
             },
             "numerators": {
                "this": 40526,
                "CBSA": 982504,
                "state": 1.9871428E7
             },
             "numerator_errors": {
                "this": 2596,
                "CBSA": 1022,
                "state": 6786
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 97
             },
             "error_ratio": {
                "this": 6.4,
                "CBSA": 0.1,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 43.94,
                "CBSA": 31.62,
                "state": 37.18
             },
             "error": {
                "this": 1.54,
                "CBSA": 0.04,
                "state": 0.01
             },
             "numerators": {
                "this": 35877,
                "CBSA": 628606,
                "state": 1.4605312E7
             },
             "numerator_errors": {
                "this": 1259,
                "CBSA": 844,
                "state": 3168
             },
             "index": {
                "this": 100,
                "CBSA": 139,
                "state": 118
             },
             "error_ratio": {
                "this": 3.5,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 1.41,
                "CBSA": 2.3,
                "state": 5.52
             },
             "error": {
                "this": 0.39,
                "CBSA": 0.05,
                "state": 0.02
             },
             "numerators": {
                "this": 1155,
                "CBSA": 45713,
                "state": 2169155
             },
             "numerator_errors": {
                "this": 315,
                "CBSA": 926,
                "state": 6936
             },
             "index": {
                "this": 100,
                "CBSA": 61,
                "state": 26
             },
             "error_ratio": {
                "this": 27.7,
                "CBSA": 2.2,
                "state": 0.4
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.17,
                "CBSA": 0.18,
                "state": 0.36
             },
             "error": {
                "this": 0.13,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 135,
                "CBSA": 3552,
                "state": 140831
             },
             "numerator_errors": {
                "this": 104,
                "CBSA": 409,
                "state": 2516
             },
             "index": {
                "this": 100,
                "CBSA": 94,
                "state": 47
             },
             "error_ratio": {
                "this": 76.5,
                "CBSA": 11.1,
                "state": 2.8
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 31.71,
                "CBSA": 35.27,
                "state": 14.28
             },
             "error": {
                "this": 1.48,
                "CBSA": 0.1,
                "state": 0.02
             },
             "numerators": {
                "this": 25896,
                "CBSA": 701044,
                "state": 5610931
             },
             "numerator_errors": {
                "this": 1208,
                "CBSA": 2065,
                "state": 9199
             },
             "index": {
                "this": 100,
                "CBSA": 90,
                "state": 222
             },
             "error_ratio": {
                "this": 4.7,
                "CBSA": 0.3,
                "state": 0.1
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.27,
                "CBSA": 0.32,
                "state": 0.36
             },
             "error": {
                "this": 0.27,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 224,
                "CBSA": 6265,
                "state": 140788
             },
             "numerator_errors": {
                "this": 224,
                "CBSA": 499,
                "state": 2224
             },
             "index": {
                "this": 100,
                "CBSA": 84,
                "state": 75
             },
             "error_ratio": {
                "this": 100,
                "CBSA": 9.4,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.39,
                "CBSA": 0.28,
                "state": 0.25
             },
             "error": {
                "this": 0.2,
                "CBSA": 0.05,
                "state": 0.01
             },
             "numerators": {
                "this": 318,
                "CBSA": 5606,
                "state": 100119
             },
             "numerator_errors": {
                "this": 163,
                "CBSA": 920,
                "state": 3715
             },
             "index": {
                "this": 100,
                "CBSA": 139,
                "state": 156
             },
             "error_ratio": {
                "this": 51.3,
                "CBSA": 17.9,
                "state": 4
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 3.79,
                "CBSA": 3.52,
                "state": 3.03
             },
             "error": {
                "this": 0.59,
                "CBSA": 0.11,
                "state": 0.04
             },
             "numerators": {
                "this": 3096,
                "CBSA": 70001,
                "state": 1188673
             },
             "numerator_errors": {
                "this": 485,
                "CBSA": 2135,
                "state": 14463
             },
             "index": {
                "this": 100,
                "CBSA": 108,
                "state": 125
             },
             "error_ratio": {
                "this": 15.6,
                "CBSA": 3.1,
                "state": 1.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 18.31,
                "CBSA": 26.51,
                "state": 39.02
             },
             "error": {
                "this": 1.59,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 14955,
                "CBSA": 527059,
                "state": 1.5327688E7
             },
             "numerator_errors": {
                "this": 1296,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 69,
                "state": 47
             },
             "error_ratio": {
                "this": 8.7,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 88904,
                "CBSA": 61400,
                "state": 39393
             },
             "error": {
                "this": 7235,
                "CBSA": 878,
                "state": 177
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 145,
                "state": 226
             },
             "error_ratio": {
                "this": 8.1,
                "CBSA": 1.4,
                "state": 0.4
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 147915,
                "CBSA": 130865,
                "state": 80440
             },
             "error": {
                "this": 15480,
                "CBSA": 2664,
                "state": 313
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 113,
                "state": 184
             },
             "error_ratio": {
                "this": 10.5,
                "CBSA": 2,
                "state": 0.4
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 16.69,
                   "CBSA": 18.7,
                   "state": 31.68
                },
                "error": {
                   "this": 3.94,
                   "CBSA": 0.93,
                   "state": 0.26
                },
                "numerators": {
                   "this": 5916,
                   "CBSA": 123983,
                   "state": 4167825
                },
                "numerator_errors": {
                   "this": 1423.3,
                   "CBSA": 6232,
                   "state": 34680.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 89,
                   "state": 53
                },
                "error_ratio": {
                   "this": 23.6,
                   "CBSA": 5,
                   "state": 0.8
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 17.6,
                   "CBSA": 20.64,
                   "state": 27.83
                },
                "error": {
                   "this": 3.9,
                   "CBSA": 0.88,
                   "state": 0.24
                },
                "numerators": {
                   "this": 6240,
                   "CBSA": 136795,
                   "state": 3661397
                },
                "numerator_errors": {
                   "this": 1414.6,
                   "CBSA": 5940.2,
                   "state": 32290.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 85,
                   "state": 63
                },
                "error_ratio": {
                   "this": 22.2,
                   "CBSA": 4.3,
                   "state": 0.9
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 29.04,
                   "CBSA": 29.6,
                   "state": 26.8
                },
                "error": {
                   "this": 4.59,
                   "CBSA": 0.97,
                   "state": 0.24
                },
                "numerators": {
                   "this": 10297,
                   "CBSA": 196234,
                   "state": 3525910
                },
                "numerator_errors": {
                   "this": 1696.1,
                   "CBSA": 6546.3,
                   "state": 31856.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 108
                },
                "error_ratio": {
                   "this": 15.8,
                   "CBSA": 3.3,
                   "state": 0.9
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 36.67,
                   "CBSA": 31.05,
                   "state": 13.7
                },
                "error": {
                   "this": 3.9,
                   "CBSA": 0.78,
                   "state": 0.14
                },
                "numerators": {
                   "this": 13003,
                   "CBSA": 205830,
                   "state": 1802741
                },
                "numerator_errors": {
                   "this": 1510,
                   "CBSA": 5376,
                   "state": 18330
                },
                "index": {
                   "this": 100,
                   "CBSA": 118,
                   "state": 268
                },
                "error_ratio": {
                   "this": 10.6,
                   "CBSA": 2.5,
                   "state": 1
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 4.46,
                "CBSA": 6.32,
                "state": 11.75
             },
             "error": {
                "this": 1.55,
                "CBSA": 0.46,
                "state": 0.18
             },
             "numerators": {
                "this": 3663,
                "CBSA": 123477,
                "state": 4552837
             },
             "numerator_errors": {
                "this": 1271,
                "CBSA": 9048,
                "state": 67995
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 71,
                "state": 38
             },
             "error_ratio": {
                "this": 34.8,
                "CBSA": 7.3,
                "state": 1.5
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 0,
                   "CBSA": 5.83,
                   "state": 15.62
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.73,
                   "state": 0.27
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 24628,
                   "state": 1363574
                },
                "numerator_errors": {
                   "this": 748.2,
                   "CBSA": 3110.3,
                   "state": 24624.2
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 12.5,
                   "state": 1.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 100,
                   "CBSA": 94.17,
                   "state": 84.38
                },
                "error": {
                   "this": 22.4,
                   "CBSA": 2.51,
                   "state": 0.61
                },
                "numerators": {
                   "this": 14548,
                   "CBSA": 398137,
                   "state": 7365259
                },
                "numerator_errors": {
                   "this": 2242.9,
                   "CBSA": 7421.4,
                   "state": 37404.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 119
                },
                "error_ratio": {
                   "this": 22.4,
                   "CBSA": 2.7,
                   "state": 0.7
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 9.08,
                   "CBSA": 7.93,
                   "state": 10.45
                },
                "error": {
                   "this": 4.56,
                   "CBSA": 0.86,
                   "state": 0.23
                },
                "numerators": {
                   "this": 849,
                   "CBSA": 21528,
                   "state": 599877
                },
                "numerator_errors": {
                   "this": 447.7,
                   "CBSA": 2362.9,
                   "state": 13070.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 115,
                   "state": 87
                },
                "error_ratio": {
                   "this": 50.2,
                   "CBSA": 10.8,
                   "state": 2.2
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 90.92,
                   "CBSA": 92.07,
                   "state": 89.55
                },
                "error": {
                   "this": 4.71,
                   "CBSA": 1.55,
                   "state": 0.39
                },
                "numerators": {
                   "this": 8498,
                   "CBSA": 249964,
                   "state": 5138754
                },
                "numerator_errors": {
                   "this": 1440.3,
                   "CBSA": 2657.4,
                   "state": 14255.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 102
                },
                "error_ratio": {
                   "this": 5.2,
                   "CBSA": 1.7,
                   "state": 0.4
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 26.21,
                "CBSA": 30.26,
                "state": 30.68
             },
             "error": {
                "this": 2.5,
                "CBSA": 0.43,
                "state": 0.1
             },
             "numerators": {
                "this": 1233100,
                "CBSA": 2.9507464E7,
                "state": 5.3813811E8
             },
             "numerator_errors": {
                "this": 139456,
                "CBSA": 532392,
                "state": 2278526
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 87,
                "state": 85
             },
             "error_ratio": {
                "this": 9.5,
                "CBSA": 1.4,
                "state": 0.3
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 63.87,
                   "CBSA": 74.68,
                   "state": 73.51
                },
                "error": {
                   "this": 3.34,
                   "CBSA": 1.01,
                   "state": 0.23
                },
                "numerators": {
                   "this": 31349,
                   "CBSA": 764930,
                   "state": 1.3767903E7
                },
                "numerator_errors": {
                   "this": 2391,
                   "CBSA": 12803,
                   "state": 52769
                },
                "index": {
                   "this": 100,
                   "CBSA": 86,
                   "state": 87
                },
                "error_ratio": {
                   "this": 5.2,
                   "CBSA": 1.4,
                   "state": 0.3
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 8.83,
                   "CBSA": 10.52,
                   "state": 9.83
                },
                "error": {
                   "this": 2.21,
                   "CBSA": 0.63,
                   "state": 0.15
                },
                "numerators": {
                   "this": 4336,
                   "CBSA": 107743,
                   "state": 1841273
                },
                "numerator_errors": {
                   "this": 1112,
                   "CBSA": 6587,
                   "state": 29274
                },
                "index": {
                   "this": 100,
                   "CBSA": 84,
                   "state": 90
                },
                "error_ratio": {
                   "this": 25,
                   "CBSA": 6,
                   "state": 1.5
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 8.65,
                   "CBSA": 4.66,
                   "state": 5.18
                },
                "error": {
                   "this": 2.43,
                   "CBSA": 0.38,
                   "state": 0.08
                },
                "numerators": {
                   "this": 4245,
                   "CBSA": 47745,
                   "state": 970901
                },
                "numerator_errors": {
                   "this": 1215,
                   "CBSA": 3896,
                   "state": 15634
                },
                "index": {
                   "this": 100,
                   "CBSA": 186,
                   "state": 167
                },
                "error_ratio": {
                   "this": 28.1,
                   "CBSA": 8.2,
                   "state": 1.5
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 7.92,
                   "CBSA": 1.69,
                   "state": 0.86
                },
                "error": {
                   "this": 2.36,
                   "CBSA": 0.23,
                   "state": 0.04
                },
                "numerators": {
                   "this": 3886,
                   "CBSA": 17336,
                   "state": 160661
                },
                "numerator_errors": {
                   "this": 1176,
                   "CBSA": 2347,
                   "state": 7943
                },
                "index": {
                   "this": 100,
                   "CBSA": 469,
                   "state": 921
                },
                "error_ratio": {
                   "this": 29.8,
                   "CBSA": 13.6,
                   "state": 4.7
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 4.51,
                   "CBSA": 2.29,
                   "state": 2.56
                },
                "error": {
                   "this": 1.84,
                   "CBSA": 0.29,
                   "state": 0.08
                },
                "numerators": {
                   "this": 2213,
                   "CBSA": 23453,
                   "state": 479751
                },
                "numerator_errors": {
                   "this": 911,
                   "CBSA": 2957,
                   "state": 15458
                },
                "index": {
                   "this": 100,
                   "CBSA": 197,
                   "state": 176
                },
                "error_ratio": {
                   "this": 40.8,
                   "CBSA": 12.7,
                   "state": 3.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 2.08,
                   "CBSA": 1.36,
                   "state": 1.72
                },
                "error": {
                   "this": 0.98,
                   "CBSA": 0.19,
                   "state": 0.06
                },
                "numerators": {
                   "this": 1023,
                   "CBSA": 13892,
                   "state": 321375
                },
                "numerator_errors": {
                   "this": 483,
                   "CBSA": 1961,
                   "state": 11717
                },
                "index": {
                   "this": 100,
                   "CBSA": 153,
                   "state": 121
                },
                "error_ratio": {
                   "this": 47.1,
                   "CBSA": 14,
                   "state": 3.5
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 4.14,
                   "CBSA": 4.8,
                   "state": 6.34
                },
                "error": {
                   "this": 1.78,
                   "CBSA": 0.38,
                   "state": 0.12
                },
                "numerators": {
                   "this": 2032,
                   "CBSA": 49187,
                   "state": 1188387
                },
                "numerator_errors": {
                   "this": 879,
                   "CBSA": 3909,
                   "state": 22217
                },
                "index": {
                   "this": 100,
                   "CBSA": 86,
                   "state": 65
                },
                "error_ratio": {
                   "this": 43,
                   "CBSA": 7.9,
                   "state": 1.9
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 46.49,
                "CBSA": 53.35,
                "state": 48.44
             },
             "error": {
                "this": 2.23,
                "CBSA": 0.66,
                "state": 0.14
             },
             "numerators": {
                "this": 32763,
                "CBSA": 871204,
                "state": 1.5561514E7
             },
             "numerator_errors": {
                "this": 1936.3,
                "CBSA": 10731.2,
                "state": 46249.3
             },
             "index": {
                "this": 100,
                "CBSA": 87,
                "state": 96
             },
             "error_ratio": {
                "this": 4.8,
                "CBSA": 1.2,
                "state": 0.3
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 53.51,
                "CBSA": 46.65,
                "state": 51.56
             },
             "error": {
                "this": 4.88,
                "CBSA": 0.65,
                "state": 0.16
             },
             "numerators": {
                "this": 37708,
                "CBSA": 761698,
                "state": 1.6562598E7
             },
             "numerator_errors": {
                "this": 3675.1,
                "CBSA": 10660.5,
                "state": 51752.6
             },
             "index": {
                "this": 100,
                "CBSA": 115,
                "state": 104
             },
             "error_ratio": {
                "this": 9.1,
                "CBSA": 1.4,
                "state": 0.3
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 47.46,
                   "CBSA": 38.63,
                   "state": 40.83
                },
                "error": {
                   "this": 6.84,
                   "CBSA": 0.73,
                   "state": 0.17
                },
                "numerators": {
                   "this": 16988,
                   "CBSA": 318683,
                   "state": 6478460
                },
                "numerator_errors": {
                   "this": 2752,
                   "CBSA": 5996,
                   "state": 27866
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 116
                },
                "error_ratio": {
                   "this": 14.4,
                   "CBSA": 1.9,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 38.51,
                   "CBSA": 31.2,
                   "state": 33.96
                },
                "error": {
                   "this": 5.21,
                   "CBSA": 0.68,
                   "state": 0.15
                },
                "numerators": {
                   "this": 13356,
                   "CBSA": 252117,
                   "state": 5521285
                },
                "numerator_errors": {
                   "this": 1982,
                   "CBSA": 5538,
                   "state": 24532
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 113
                },
                "error_ratio": {
                   "this": 13.5,
                   "CBSA": 2.2,
                   "state": 0.4
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 46.17,
                   "CBSA": 53.67,
                   "state": 49.37
                },
                "error": {
                   "this": 2.03,
                   "CBSA": 0.91,
                   "state": 0.2
                },
                "numerators": {
                   "this": 16525,
                   "CBSA": 442711,
                   "state": 7832740
                },
                "numerator_errors": {
                   "this": 1425,
                   "CBSA": 7542,
                   "state": 32087
                },
                "index": {
                   "this": 100,
                   "CBSA": 86,
                   "state": 94
                },
                "error_ratio": {
                   "this": 4.4,
                   "CBSA": 1.7,
                   "state": 0.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 46.83,
                   "CBSA": 53.03,
                   "state": 47.54
                },
                "error": {
                   "this": 2.46,
                   "CBSA": 0.94,
                   "state": 0.2
                },
                "numerators": {
                   "this": 16238,
                   "CBSA": 428493,
                   "state": 7728774
                },
                "numerator_errors": {
                   "this": 1311,
                   "CBSA": 7634,
                   "state": 33308
                },
                "index": {
                   "this": 100,
                   "CBSA": 88,
                   "state": 99
                },
                "error_ratio": {
                   "this": 5.3,
                   "CBSA": 1.8,
                   "state": 0.4
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 4.68,
                   "CBSA": 5.98,
                   "state": 7.69
                },
                "error": {
                   "this": 1.97,
                   "CBSA": 0.44,
                   "state": 0.11
                },
                "numerators": {
                   "this": 1675,
                   "CBSA": 49331,
                   "state": 1219434
                },
                "numerator_errors": {
                   "this": 716,
                   "CBSA": 3616,
                   "state": 17626
                },
                "index": {
                   "this": 100,
                   "CBSA": 78,
                   "state": 61
                },
                "error_ratio": {
                   "this": 42.1,
                   "CBSA": 7.4,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 8.65,
                   "CBSA": 9.09,
                   "state": 10.83
                },
                "error": {
                   "this": 2.25,
                   "CBSA": 0.52,
                   "state": 0.16
                },
                "numerators": {
                   "this": 3001,
                   "CBSA": 73422,
                   "state": 1760791
                },
                "numerator_errors": {
                   "this": 803,
                   "CBSA": 4173,
                   "state": 25259
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 80
                },
                "error_ratio": {
                   "this": 26,
                   "CBSA": 5.7,
                   "state": 1.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 1.69,
                   "CBSA": 1.72,
                   "state": 2.11
                },
                "error": {
                   "this": 1.43,
                   "CBSA": 0.23,
                   "state": 0.05
                },
                "numerators": {
                   "this": 605,
                   "CBSA": 14188,
                   "state": 334453
                },
                "numerator_errors": {
                   "this": 513,
                   "CBSA": 1932,
                   "state": 8393
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 80
                },
                "error_ratio": {
                   "this": 84.6,
                   "CBSA": 13.4,
                   "state": 2.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 6.01,
                   "CBSA": 6.68,
                   "state": 7.68
                },
                "error": {
                   "this": 2.17,
                   "CBSA": 0.44,
                   "state": 0.1
                },
                "numerators": {
                   "this": 2083,
                   "CBSA": 53957,
                   "state": 1248175
                },
                "numerator_errors": {
                   "this": 764,
                   "CBSA": 3578,
                   "state": 16759
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 78
                },
                "error_ratio": {
                   "this": 36.1,
                   "CBSA": 6.6,
                   "state": 1.3
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 81.72,
                   "CBSA": 81.4,
                   "state": 72.23
                },
                "error": {
                   "this": 2.6,
                   "CBSA": 0.63,
                   "state": 0.27
                },
                "numerators": {
                   "this": 12143,
                   "CBSA": 319520,
                   "state": 5567122
                },
                "numerator_errors": {
                   "this": 694,
                   "CBSA": 2844,
                   "state": 22183
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 113
                },
                "error_ratio": {
                   "this": 3.2,
                   "CBSA": 0.8,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 5.28,
                   "CBSA": 5.63,
                   "state": 7.67
                },
                "error": {
                   "this": 2.01,
                   "CBSA": 0.34,
                   "state": 0.11
                },
                "numerators": {
                   "this": 785,
                   "CBSA": 22081,
                   "state": 591232
                },
                "numerator_errors": {
                   "this": 301,
                   "CBSA": 1348,
                   "state": 8769
                },
                "index": {
                   "this": 100,
                   "CBSA": 94,
                   "state": 69
                },
                "error_ratio": {
                   "this": 38.1,
                   "CBSA": 6,
                   "state": 1.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 13,
                   "CBSA": 12.98,
                   "state": 20.1
                },
                "error": {
                   "this": 2.79,
                   "CBSA": 0.54,
                   "state": 0.21
                },
                "numerators": {
                   "this": 1931,
                   "CBSA": 50947,
                   "state": 1549424
                },
                "numerator_errors": {
                   "this": 425,
                   "CBSA": 2129,
                   "state": 16177
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 65
                },
                "error_ratio": {
                   "this": 21.5,
                   "CBSA": 4.2,
                   "state": 1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 3.22,
                "CBSA": 4.43,
                "state": 4.69
             },
             "error": {
                "this": 1.47,
                "CBSA": 0.46,
                "state": 0.11
             },
             "numerators": {
                "this": 737,
                "CBSA": 21601,
                "state": 450971
             },
             "numerator_errors": {
                "this": 342,
                "CBSA": 2240,
                "state": 10955
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 73,
                "state": 69
             },
             "error_ratio": {
                "this": 45.7,
                "CBSA": 10.4,
                "state": 2.3
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0,
                   "CBSA": 0.08,
                   "state": 0.87
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.11,
                   "state": 0.16
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 45,
                   "state": 10882
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 67,
                   "state": 2046
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 137.5,
                   "state": 18.4
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 0,
                   "CBSA": 2.02,
                   "state": 4.44
                },
                "error": {
                   "this": 0,
                   "CBSA": 1.31,
                   "state": 0.41
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1156,
                   "state": 56709
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 753,
                   "state": 5217
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 64.9,
                   "state": 9.2
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 0,
                   "CBSA": 4.43,
                   "state": 6.85
                },
                "error": {
                   "this": 0,
                   "CBSA": 1.31,
                   "state": 0.37
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 3265,
                   "state": 101991
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 965,
                   "state": 5591
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 29.6,
                   "state": 5.4
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 12.78,
                   "CBSA": 11.69,
                   "state": 9.73
                },
                "error": {
                   "this": 5.73,
                   "CBSA": 1.9,
                   "state": 0.51
                },
                "numerators": {
                   "this": 587,
                   "CBSA": 8763,
                   "state": 139246
                },
                "numerator_errors": {
                   "this": 289,
                   "CBSA": 1442,
                   "state": 7329
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 131
                },
                "error_ratio": {
                   "this": 44.8,
                   "CBSA": 16.3,
                   "state": 5.2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 4.41,
                   "CBSA": 8.49,
                   "state": 6.95
                },
                "error": {
                   "this": 5.16,
                   "CBSA": 1.33,
                   "state": 0.33
                },
                "numerators": {
                   "this": 124,
                   "CBSA": 6052,
                   "state": 93949
                },
                "numerator_errors": {
                   "this": 149,
                   "CBSA": 976,
                   "state": 4611
                },
                "index": {
                   "this": 100,
                   "CBSA": 52,
                   "state": 63
                },
                "error_ratio": {
                   "this": 117,
                   "CBSA": 15.7,
                   "state": 4.7
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 0,
                   "CBSA": 2.09,
                   "state": 2.64
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.9,
                   "state": 0.21
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1431,
                   "state": 33377
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 618,
                   "state": 2743
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 43.1,
                   "state": 8
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0.94,
                   "CBSA": 1.08,
                   "state": 0.96
                },
                "error": {
                   "this": 1.53,
                   "CBSA": 0.44,
                   "state": 0.14
                },
                "numerators": {
                   "this": 26,
                   "CBSA": 889,
                   "state": 14817
                },
                "numerator_errors": {
                   "this": 43,
                   "CBSA": 364,
                   "state": 2211
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 98
                },
                "error_ratio": {
                   "this": 162.8,
                   "CBSA": 40.7,
                   "state": 14.6
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 35456,
                "CBSA": 662842,
                "state": 1.3157873E7
             },
             "error": {
                "this": 1656,
                "CBSA": 4591,
                "state": 23844
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 5,
                "state": null
             },
             "error_ratio": {
                "this": 4.7,
                "CBSA": 0.7,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 2.32,
                "CBSA": 2.95,
                "state": 2.94
             },
             "error": {
                "this": 0.11,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 82435,
                "CBSA": 1953094,
                "state": 3.8685704E7
             },
             "numerator_errors": {
                "this": 226,
                "CBSA": 2672,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 79,
                "state": 79
             },
             "error_ratio": {
                "this": 4.7,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 55.78,
                   "CBSA": 65.4,
                   "state": 60.69
                },
                "error": {
                   "this": 4.97,
                   "CBSA": 1.15,
                   "state": 0.22
                },
                "numerators": {
                   "this": 45983,
                   "CBSA": 1277354,
                   "state": 2.3477092E7
                },
                "numerator_errors": {
                   "this": 4095,
                   "CBSA": 22550,
                   "state": 84642
                },
                "index": {
                   "this": 100,
                   "CBSA": 85,
                   "state": 92
                },
                "error_ratio": {
                   "this": 8.9,
                   "CBSA": 1.8,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 4.88,
                   "CBSA": 6.79,
                   "state": 7.74
                },
                "error": {
                   "this": 3.17,
                   "CBSA": 0.64,
                   "state": 0.16
                },
                "numerators": {
                   "this": 4024,
                   "CBSA": 132540,
                   "state": 2996042
                },
                "numerator_errors": {
                   "this": 2611,
                   "CBSA": 12462,
                   "state": 63406
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 63
                },
                "error_ratio": {
                   "this": 65,
                   "CBSA": 9.4,
                   "state": 2.1
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 8.29,
                   "CBSA": 12.85,
                   "state": 16.47
                },
                "error": {
                   "this": 3.48,
                   "CBSA": 0.91,
                   "state": 0.23
                },
                "numerators": {
                   "this": 6835,
                   "CBSA": 251009,
                   "state": 6371949
                },
                "numerator_errors": {
                   "this": 2866,
                   "CBSA": 17743,
                   "state": 87738
                },
                "index": {
                   "this": 100,
                   "CBSA": 65,
                   "state": 50
                },
                "error_ratio": {
                   "this": 42,
                   "CBSA": 7.1,
                   "state": 1.4
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 31.05,
                   "CBSA": 14.96,
                   "state": 15.1
                },
                "error": {
                   "this": 3.82,
                   "CBSA": 0.63,
                   "state": 0.1
                },
                "numerators": {
                   "this": 25593,
                   "CBSA": 292191,
                   "state": 5840619
                },
                "numerator_errors": {
                   "this": 3151,
                   "CBSA": 12288,
                   "state": 37146
                },
                "index": {
                   "this": 100,
                   "CBSA": 208,
                   "state": 206
                },
                "error_ratio": {
                   "this": 12.3,
                   "CBSA": 4.2,
                   "state": 0.7
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 39795,
                "CBSA": 706285,
                "state": 1.4367012E7
             },
             "error": {
                "this": 2263,
                "CBSA": 923,
                "state": 1286
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 6,
                "state": null
             },
             "error_ratio": {
                "this": 5.7,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 89.1,
                   "CBSA": 93.85,
                   "state": 91.58
                },
                "error": {
                   "this": 6.56,
                   "CBSA": 0.64,
                   "state": 0.17
                },
                "numerators": {
                   "this": 35456,
                   "CBSA": 662842,
                   "state": 1.3157873E7
                },
                "numerator_errors": {
                   "this": 1656,
                   "CBSA": 4591,
                   "state": 23844
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 97
                },
                "error_ratio": {
                   "this": 7.4,
                   "CBSA": 0.7,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 10.9,
                   "CBSA": 6.15,
                   "state": 8.42
                },
                "error": {
                   "this": 3.51,
                   "CBSA": 0.62,
                   "state": 0.17
                },
                "numerators": {
                   "this": 4339,
                   "CBSA": 43443,
                   "state": 1209139
                },
                "numerator_errors": {
                   "this": 1418,
                   "CBSA": 4388,
                   "state": 24297
                },
                "index": {
                   "this": 100,
                   "CBSA": 177,
                   "state": 129
                },
                "error_ratio": {
                   "this": 32.2,
                   "CBSA": 10.1,
                   "state": 2
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 38.92,
                   "CBSA": 61.51,
                   "state": 63.96
                },
                "error": {
                   "this": 3.66,
                   "CBSA": 0.98,
                   "state": 0.22
                },
                "numerators": {
                   "this": 15490,
                   "CBSA": 434409,
                   "state": 9189272
                },
                "numerator_errors": {
                   "this": 1702.9,
                   "CBSA": 6930.2,
                   "state": 31423.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 63,
                   "state": 61
                },
                "error_ratio": {
                   "this": 9.4,
                   "CBSA": 1.6,
                   "state": 0.3
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 58,
                   "CBSA": 35.54,
                   "state": 32.18
                },
                "error": {
                   "this": 5.52,
                   "CBSA": 1.19,
                   "state": 0.27
                },
                "numerators": {
                   "this": 23082,
                   "CBSA": 251035,
                   "state": 4622614
                },
                "numerator_errors": {
                   "this": 2559.4,
                   "CBSA": 8419.5,
                   "state": 38673.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 163,
                   "state": 180
                },
                "error_ratio": {
                   "this": 9.5,
                   "CBSA": 3.3,
                   "state": 0.8
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 3.07,
                   "CBSA": 2.89,
                   "state": 3.74
                },
                "error": {
                   "this": 1.1,
                   "CBSA": 0.25,
                   "state": 0.08
                },
                "numerators": {
                   "this": 1223,
                   "CBSA": 20422,
                   "state": 537931
                },
                "numerator_errors": {
                   "this": 445,
                   "CBSA": 1750,
                   "state": 11591
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 82
                },
                "error_ratio": {
                   "this": 35.8,
                   "CBSA": 8.7,
                   "state": 2.1
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0,
                   "CBSA": 0.06,
                   "state": 0.12
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.06,
                   "state": 0.01
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 419,
                   "state": 17195
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 400,
                   "state": 2102
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 100,
                   "state": 8.3
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 39.89,
                   "CBSA": 55.21,
                   "state": 54.86
                },
                "error": {
                   "this": 3.95,
                   "CBSA": 0.77,
                   "state": 0.22
                },
                "numerators": {
                   "this": 14145,
                   "CBSA": 365949,
                   "state": 7218742
                },
                "numerator_errors": {
                   "this": 1549,
                   "CBSA": 5710,
                   "state": 32100
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 73
                },
                "error_ratio": {
                   "this": 9.9,
                   "CBSA": 1.4,
                   "state": 0.4
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 60.11,
                   "CBSA": 44.79,
                   "state": 45.14
                },
                "error": {
                   "this": 3.54,
                   "CBSA": 0.84,
                   "state": 0.24
                },
                "numerators": {
                   "this": 21311,
                   "CBSA": 296893,
                   "state": 5939131
                },
                "numerator_errors": {
                   "this": 1602,
                   "CBSA": 5964,
                   "state": 32807
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 133
                },
                "error_ratio": {
                   "this": 5.9,
                   "CBSA": 1.9,
                   "state": 0.5
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 1645800,
                "CBSA": 1116400,
                "state": 568500
             },
             "error": {
                "this": 82976,
                "CBSA": 12380,
                "state": 2502
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 147,
                "state": 289
             },
             "error_ratio": {
                "this": 5,
                "CBSA": 1.1,
                "state": 0.4
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 2.36,
                   "CBSA": 2.37,
                   "state": 5.03
                },
                "error": {
                   "this": 0.81,
                   "CBSA": 0.15,
                   "state": 0.05
                },
                "numerators": {
                   "this": 332,
                   "CBSA": 8828,
                   "state": 359961
                },
                "numerator_errors": {
                   "this": 114.5,
                   "CBSA": 548.4,
                   "state": 3940.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 47
                },
                "error_ratio": {
                   "this": 34.3,
                   "CBSA": 6.3,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 2.8,
                   "CBSA": 2.07,
                   "state": 6.63
                },
                "error": {
                   "this": 0.81,
                   "CBSA": 0.15,
                   "state": 0.06
                },
                "numerators": {
                   "this": 393,
                   "CBSA": 7732,
                   "state": 474527
                },
                "numerator_errors": {
                   "this": 114.6,
                   "CBSA": 568.6,
                   "state": 4768.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 135,
                   "state": 42
                },
                "error_ratio": {
                   "this": 28.9,
                   "CBSA": 7.2,
                   "state": 0.9
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 1.75,
                   "CBSA": 1.76,
                   "state": 11.06
                },
                "error": {
                   "this": 0.76,
                   "CBSA": 0.13,
                   "state": 0.06
                },
                "numerators": {
                   "this": 246,
                   "CBSA": 6570,
                   "state": 791435
                },
                "numerator_errors": {
                   "this": 106.9,
                   "CBSA": 504.3,
                   "state": 6253.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 16
                },
                "error_ratio": {
                   "this": 43.4,
                   "CBSA": 7.4,
                   "state": 0.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 2.02,
                   "CBSA": 2.37,
                   "state": 13.6
                },
                "error": {
                   "this": 1.01,
                   "CBSA": 0.16,
                   "state": 0.09
                },
                "numerators": {
                   "this": 284,
                   "CBSA": 8838,
                   "state": 973196
                },
                "numerator_errors": {
                   "this": 143,
                   "CBSA": 599,
                   "state": 7992
                },
                "index": {
                   "this": 100,
                   "CBSA": 85,
                   "state": 15
                },
                "error_ratio": {
                   "this": 50,
                   "CBSA": 6.8,
                   "state": 0.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 1.08,
                   "CBSA": 3.99,
                   "state": 13.21
                },
                "error": {
                   "this": 0.56,
                   "CBSA": 0.22,
                   "state": 0.08
                },
                "numerators": {
                   "this": 152,
                   "CBSA": 14882,
                   "state": 945276
                },
                "numerator_errors": {
                   "this": 79,
                   "CBSA": 831,
                   "state": 7694
                },
                "index": {
                   "this": 100,
                   "CBSA": 27,
                   "state": 8
                },
                "error_ratio": {
                   "this": 51.9,
                   "CBSA": 5.5,
                   "state": 0.6
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 19.24,
                   "CBSA": 40.13,
                   "state": 35.28
                },
                "error": {
                   "this": 1.95,
                   "CBSA": 0.54,
                   "state": 0.26
                },
                "numerators": {
                   "this": 2705,
                   "CBSA": 149566,
                   "state": 2523951
                },
                "numerator_errors": {
                   "this": 290.1,
                   "CBSA": 2309.5,
                   "state": 12891.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 48,
                   "state": 55
                },
                "error_ratio": {
                   "this": 10.1,
                   "CBSA": 1.3,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 23.21,
                   "CBSA": 22.29,
                   "state": 7.93
                },
                "error": {
                   "this": 2.11,
                   "CBSA": 0.41,
                   "state": 0.04
                },
                "numerators": {
                   "this": 3263,
                   "CBSA": 83053,
                   "state": 567462
                },
                "numerator_errors": {
                   "this": 318,
                   "CBSA": 1652,
                   "state": 4302
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 293
                },
                "error_ratio": {
                   "this": 9.1,
                   "CBSA": 1.8,
                   "state": 0.5
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 14056,
                "CBSA": 372659,
                "state": 7154580
             },
             "error": {
                "this": 490,
                "CBSA": 2785,
                "state": 37872
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 4,
                "state": null
             },
             "error_ratio": {
                "this": 3.5,
                "CBSA": 0.7,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 1-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 6.78,
                "CBSA": 9.06,
                "state": 8.86
             },
             "error": {
                "this": 2.5,
                "CBSA": 0.47,
                "state": 0.13
             },
             "numerators": {
                "this": 5586,
                "CBSA": 176854,
                "state": 3427072
             },
             "numerator_errors": {
                "this": 2056.8,
                "CBSA": 9233.8,
                "state": 50836.5
             },
             "index": {
                "this": 100,
                "CBSA": 75,
                "state": 77
             },
             "error_ratio": {
                "this": 36.9,
                "CBSA": 5.2,
                "state": 1.5
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 7.63,
                "CBSA": 11.24,
                "state": 11.17
             },
             "error": {
                "this": 2.34,
                "CBSA": 0.63,
                "state": 0.16
             },
             "numerators": {
                "this": 6287,
                "CBSA": 219614,
                "state": 4320604
             },
             "numerator_errors": {
                "this": 1930,
                "CBSA": 12268.4,
                "state": 63360.7
             },
             "index": {
                "this": 100,
                "CBSA": 68,
                "state": 68
             },
             "error_ratio": {
                "this": 30.7,
                "CBSA": 5.6,
                "state": 1.4
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 21.22,
                "CBSA": 23.18,
                "state": 21.9
             },
             "error": {
                "this": 4.76,
                "CBSA": 0.98,
                "state": 0.25
             },
             "numerators": {
                "this": 17494,
                "CBSA": 452689,
                "state": 8473932
             },
             "numerator_errors": {
                "this": 3922.2,
                "CBSA": 19244.3,
                "state": 98624.3
             },
             "index": {
                "this": 100,
                "CBSA": 92,
                "state": 97
             },
             "error_ratio": {
                "this": 22.4,
                "CBSA": 4.2,
                "state": 1.1
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 14.98,
                "CBSA": 19.06,
                "state": 20.05
             },
             "error": {
                "this": 3.52,
                "CBSA": 0.99,
                "state": 0.24
             },
             "numerators": {
                "this": 12351,
                "CBSA": 372274,
                "state": 7754580
             },
             "numerator_errors": {
                "this": 2899.3,
                "CBSA": 19357.5,
                "state": 91602.6
             },
             "index": {
                "this": 100,
                "CBSA": 79,
                "state": 75
             },
             "error_ratio": {
                "this": 23.5,
                "CBSA": 5.2,
                "state": 1.2
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 13.09,
                "CBSA": 11.32,
                "state": 13.11
             },
             "error": {
                "this": 3.55,
                "CBSA": 0.76,
                "state": 0.21
             },
             "numerators": {
                "this": 10793,
                "CBSA": 221176,
                "state": 5072685
             },
             "numerator_errors": {
                "this": 2923.3,
                "CBSA": 14873.9,
                "state": 79541.6
             },
             "index": {
                "this": 100,
                "CBSA": 116,
                "state": 100
             },
             "error_ratio": {
                "this": 27.1,
                "CBSA": 6.7,
                "state": 1.6
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 36.3,
                "CBSA": 26.14,
                "state": 24.91
             },
             "error": {
                "this": 5.02,
                "CBSA": 1.01,
                "state": 0.26
             },
             "numerators": {
                "this": 29924,
                "CBSA": 510487,
                "state": 9636829
             },
             "numerator_errors": {
                "this": 4137,
                "CBSA": 19807.6,
                "state": 101788.8
             },
             "index": {
                "this": 100,
                "CBSA": 139,
                "state": 146
             },
             "error_ratio": {
                "this": 13.8,
                "CBSA": 3.9,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 19.1,
                "CBSA": 13.82,
                "state": 12
             },
             "error": {
                "this": 3.92,
                "CBSA": 0.67,
                "state": 0.16
             },
             "numerators": {
                "this": 15654,
                "CBSA": 272064,
                "state": 4689828
             },
             "numerator_errors": {
                "this": 3213.3,
                "CBSA": 13126.5,
                "state": 63097.7
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 138,
                "state": 159
             },
             "error_ratio": {
                "this": 20.5,
                "CBSA": 4.8,
                "state": 1.3
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 1-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 80.9,
                "CBSA": 86.18,
                "state": 88
             },
             "error": {
                "this": 3.78,
                "CBSA": 0.64,
                "state": 0.17
             },
             "numerators": {
                "this": 66302,
                "CBSA": 1696672,
                "state": 3.439422E7
             },
             "numerator_errors": {
                "this": 3115,
                "CBSA": 12845,
                "state": 68574
             },
             "index": {
                "this": 100,
                "CBSA": 94,
                "state": 92
             },
             "error_ratio": {
                "this": 4.7,
                "CBSA": 0.7,
                "state": 0.2
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 7.91,
                "CBSA": 8.29,
                "state": 7.37
             },
             "error": {
                "this": 2.66,
                "CBSA": 0.55,
                "state": 0.13
             },
             "numerators": {
                "this": 6480,
                "CBSA": 163270,
                "state": 2881883
             },
             "numerator_errors": {
                "this": 2179,
                "CBSA": 10772,
                "state": 52031
             },
             "index": {
                "this": 100,
                "CBSA": 95,
                "state": 107
             },
             "error_ratio": {
                "this": 33.6,
                "CBSA": 6.6,
                "state": 1.8
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 4.35,
                "CBSA": 2.44,
                "state": 2.72
             },
             "error": {
                "this": 1.84,
                "CBSA": 0.23,
                "state": 0.07
             },
             "numerators": {
                "this": 3568,
                "CBSA": 48036,
                "state": 1061256
             },
             "numerator_errors": {
                "this": 1508,
                "CBSA": 4546,
                "state": 27761
             },
             "index": {
                "this": 100,
                "CBSA": 178,
                "state": 160
             },
             "error_ratio": {
                "this": 42.3,
                "CBSA": 9.4,
                "state": 2.6
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 4.34,
                "CBSA": 1.67,
                "state": 1.23
             },
             "error": {
                "this": 1.92,
                "CBSA": 0.24,
                "state": 0.05
             },
             "numerators": {
                "this": 3556,
                "CBSA": 32975,
                "state": 480204
             },
             "numerator_errors": {
                "this": 1571,
                "CBSA": 4629,
                "state": 18764
             },
             "index": {
                "this": 100,
                "CBSA": 260,
                "state": 353
             },
             "error_ratio": {
                "this": 44.2,
                "CBSA": 14.4,
                "state": 4.1
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 2.5,
                "CBSA": 1.41,
                "state": 0.68
             },
             "error": {
                "this": 1.12,
                "CBSA": 0.19,
                "state": 0.03
             },
             "numerators": {
                "this": 2050,
                "CBSA": 27783,
                "state": 266485
             },
             "numerator_errors": {
                "this": 914,
                "CBSA": 3765,
                "state": 12302
             },
             "index": {
                "this": 100,
                "CBSA": 177,
                "state": 368
             },
             "error_ratio": {
                "this": 44.8,
                "CBSA": 13.5,
                "state": 4.4
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 93.56,
                "CBSA": 88.65,
                "state": 84.03
             },
             "error": {
                "this": 5.57,
                "CBSA": 1.3,
                "state": 0.29
             },
             "numerators": {
                "this": 58032,
                "CBSA": 1234572,
                "state": 2.263636E7
             },
             "numerator_errors": {
                "this": 4129.5,
                "CBSA": 18089.6,
                "state": 78234.5
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 106,
                "state": 111
             },
             "error_ratio": {
                "this": 6,
                "CBSA": 1.5,
                "state": 0.3
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 71.91,
                "CBSA": 52.66,
                "state": 35
             },
             "error": {
                "this": 5.01,
                "CBSA": 0.92,
                "state": 0.17
             },
             "numerators": {
                "this": 44601,
                "CBSA": 733354,
                "state": 9428484
             },
             "numerator_errors": {
                "this": 3562.8,
                "CBSA": 12834.6,
                "state": 44753.9
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 137,
                "state": 205
             },
             "error_ratio": {
                "this": 7,
                "CBSA": 1.7,
                "state": 0.5
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 6.44,
                "CBSA": 11.35,
                "state": 15.97
             },
             "error": {
                "this": 2.23,
                "CBSA": 0.52,
                "state": 0.14
             },
             "numerators": {
                "this": 3994,
                "CBSA": 158083,
                "state": 4301513
             },
             "numerator_errors": {
                "this": 1393.2,
                "CBSA": 7253,
                "state": 37669.1
             },
             "index": {
                "this": 100,
                "CBSA": 57,
                "state": 40
             },
             "error_ratio": {
                "this": 34.6,
                "CBSA": 4.6,
                "state": 0.9
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 8.39,
                "CBSA": 14.07,
                "state": 20.59
             },
             "error": {
                "this": 1.92,
                "CBSA": 0.6,
                "state": 0.16
             },
             "numerators": {
                "this": 5202,
                "CBSA": 195956,
                "state": 5546711
             },
             "numerator_errors": {
                "this": 1207.8,
                "CBSA": 8397.9,
                "state": 42454
             },
             "index": {
                "this": 100,
                "CBSA": 60,
                "state": 41
             },
             "error_ratio": {
                "this": 22.9,
                "CBSA": 4.3,
                "state": 0.8
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 13.27,
                "CBSA": 21.92,
                "state": 28.44
             },
             "error": {
                "this": 2.7,
                "CBSA": 0.69,
                "state": 0.18
             },
             "numerators": {
                "this": 8229,
                "CBSA": 305262,
                "state": 7661164
             },
             "numerator_errors": {
                "this": 1703.1,
                "CBSA": 9590.8,
                "state": 48118.5
             },
             "index": {
                "this": 100,
                "CBSA": 61,
                "state": 47
             },
             "error_ratio": {
                "this": 20.3,
                "CBSA": 3.1,
                "state": 0.6
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 32.16,
                "CBSA": 27.6,
                "state": 21.86
             },
             "error": {
                "this": 3.82,
                "CBSA": 0.7,
                "state": 0.13
             },
             "numerators": {
                "this": 19949,
                "CBSA": 384399,
                "state": 5889724
             },
             "numerator_errors": {
                "this": 2492.2,
                "CBSA": 9703.6,
                "state": 34784.9
             },
             "index": {
                "this": 100,
                "CBSA": 117,
                "state": 147
             },
             "error_ratio": {
                "this": 11.9,
                "CBSA": 2.5,
                "state": 0.6
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 39.74,
                "CBSA": 25.06,
                "state": 13.14
             },
             "error": {
                "this": 3.8,
                "CBSA": 0.6,
                "state": 0.1
             },
             "numerators": {
                "this": 24652,
                "CBSA": 348955,
                "state": 3538760
             },
             "numerator_errors": {
                "this": 2546.1,
                "CBSA": 8400.4,
                "state": 28159
             },
             "index": {
                "this": 100,
                "CBSA": 159,
                "state": 302
             },
             "error_ratio": {
                "this": 9.6,
                "CBSA": 2.4,
                "state": 0.8
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 41.85,
                "CBSA": 39.69,
                "state": 26.74
             },
             "error": {
                "this": 3.45,
                "CBSA": 0.69,
                "state": 0.14
             },
             "numerators": {
                "this": 34618,
                "CBSA": 790026,
                "state": 1.056422E7
             },
             "numerator_errors": {
                "this": 2852,
                "CBSA": 13650,
                "state": 55357
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 157
             },
             "error_ratio": {
                "this": 8.2,
                "CBSA": 1.7,
                "state": 0.5
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 18.01,
                   "CBSA": 7.91,
                   "state": 6.42
                },
                "error": {
                   "this": 1.68,
                   "CBSA": 0.3,
                   "state": 0.07
                },
                "numerators": {
                   "this": 6204,
                   "CBSA": 60639,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 619,
                   "CBSA": 2354,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 228,
                   "state": 281
                },
                "error_ratio": {
                   "this": 9.3,
                   "CBSA": 3.8,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 53.99,
                   "CBSA": 65.91,
                   "state": 39.42
                },
                "error": {
                   "this": 2.4,
                   "CBSA": 0.62,
                   "state": 0.09
                },
                "numerators": {
                   "this": 18599,
                   "CBSA": 505503,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 1064,
                   "CBSA": 3277,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 82,
                   "state": 137
                },
                "error_ratio": {
                   "this": 4.4,
                   "CBSA": 0.9,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 1.22,
                   "CBSA": 1.7,
                   "state": 1.84
                },
                "error": {
                   "this": 0.57,
                   "CBSA": 0.14,
                   "state": 0.05
                },
                "numerators": {
                   "this": 420,
                   "CBSA": 13034,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 196,
                   "CBSA": 1055,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 66
                },
                "error_ratio": {
                   "this": 46.7,
                   "CBSA": 8.2,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 0.71,
                   "CBSA": 0.53,
                   "state": 0.82
                },
                "error": {
                   "this": 0.3,
                   "CBSA": 0.09,
                   "state": 0.03
                },
                "numerators": {
                   "this": 245,
                   "CBSA": 4060,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 105,
                   "CBSA": 662,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 87
                },
                "error_ratio": {
                   "this": 42.3,
                   "CBSA": 17,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 23.18,
                   "CBSA": 22.67,
                   "state": 50.28
                },
                "error": {
                   "this": 2.8,
                   "CBSA": 0.42,
                   "state": 0.15
                },
                "numerators": {
                   "this": 7984,
                   "CBSA": 173901,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 1008,
                   "CBSA": 3417,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 46
                },
                "error_ratio": {
                   "this": 12.1,
                   "CBSA": 1.9,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 2.9,
                   "CBSA": 1.29,
                   "state": 1.23
                },
                "error": {
                   "this": 0.74,
                   "CBSA": 0.09,
                   "state": 0.03
                },
                "numerators": {
                   "this": 998,
                   "CBSA": 9858,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 258,
                   "CBSA": 722,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 225,
                   "state": 236
                },
                "error_ratio": {
                   "this": 25.5,
                   "CBSA": 7,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 44.23
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.14
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 1.6292017E7
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 52781.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 42.11,
                   "CBSA": 51.8,
                   "state": 56.97
                },
                "error": {
                   "this": 8.19,
                   "CBSA": 2.05,
                   "state": 0.51
                },
                "numerators": {
                   "this": 4552,
                   "CBSA": 163621,
                   "state": 3710631
                },
                "numerator_errors": {
                   "this": 1137,
                   "CBSA": 6463,
                   "state": 33084
                },
                "index": {
                   "this": 100,
                   "CBSA": 81,
                   "state": 74
                },
                "error_ratio": {
                   "this": 19.4,
                   "CBSA": 4,
                   "state": 0.9
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 35.69,
                   "CBSA": 22.79,
                   "state": 33.31
                },
                "error": {
                   "this": 14.69,
                   "CBSA": 1.47,
                   "state": 0.42
                },
                "numerators": {
                   "this": 3858,
                   "CBSA": 71974,
                   "state": 2169717
                },
                "numerator_errors": {
                   "this": 1699,
                   "CBSA": 4659,
                   "state": 27349
                },
                "index": {
                   "this": 100,
                   "CBSA": 157,
                   "state": 107
                },
                "error_ratio": {
                   "this": 41.2,
                   "CBSA": 6.5,
                   "state": 1.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 10.28,
                   "CBSA": 5.87,
                   "state": 3.07
                },
                "error": {
                   "this": 5.03,
                   "CBSA": 0.76,
                   "state": 0.15
                },
                "numerators": {
                   "this": 1111,
                   "CBSA": 18550,
                   "state": 200078
                },
                "numerator_errors": {
                   "this": 571,
                   "CBSA": 2397,
                   "state": 9887
                },
                "index": {
                   "this": 100,
                   "CBSA": 175,
                   "state": 335
                },
                "error_ratio": {
                   "this": 48.9,
                   "CBSA": 12.9,
                   "state": 4.9
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 11.92,
                   "CBSA": 18.01,
                   "state": 5.66
                },
                "error": {
                   "this": 6.42,
                   "CBSA": 1.04,
                   "state": 0.17
                },
                "numerators": {
                   "this": 1288,
                   "CBSA": 56897,
                   "state": 368935
                },
                "numerator_errors": {
                   "this": 723,
                   "CBSA": 3297,
                   "state": 11144
                },
                "index": {
                   "this": 100,
                   "CBSA": 66,
                   "state": 211
                },
                "error_ratio": {
                   "this": 53.9,
                   "CBSA": 5.8,
                   "state": 3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0,
                   "CBSA": 1.53,
                   "state": 0.98
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.5,
                   "state": 0.11
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 4839,
                   "state": 63864
                },
                "numerator_errors": {
                   "this": 216,
                   "CBSA": 1573,
                   "state": 6999
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 32.7,
                   "state": 11.2
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 52.83,
                   "CBSA": 45.1,
                   "state": 55.21
                },
                "error": {
                   "this": 4.63,
                   "CBSA": 0.87,
                   "state": 0.18
                },
                "numerators": {
                   "this": 35741,
                   "CBSA": 703298,
                   "state": 1.6905948E7
                },
                "numerator_errors": {
                   "this": 3543.7,
                   "CBSA": 13577.1,
                   "state": 55036
                },
                "index": {
                   "this": 100,
                   "CBSA": 117,
                   "state": 96
                },
                "error_ratio": {
                   "this": 8.8,
                   "CBSA": 1.9,
                   "state": 0.3
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 15.48,
                   "CBSA": 17.23,
                   "state": 27.8
                },
                "error": {
                   "this": 4.08,
                   "CBSA": 0.46,
                   "state": 0.12
                },
                "numerators": {
                   "this": 10475,
                   "CBSA": 268660,
                   "state": 8513944
                },
                "numerator_errors": {
                   "this": 2802.9,
                   "CBSA": 7123.5,
                   "state": 37016.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 56
                },
                "error_ratio": {
                   "this": 26.4,
                   "CBSA": 2.7,
                   "state": 0.4
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 11.19,
                   "CBSA": 9.75,
                   "state": 4.88
                },
                "error": {
                   "this": 2.74,
                   "CBSA": 0.58,
                   "state": 0.09
                },
                "numerators": {
                   "this": 7569,
                   "CBSA": 152112,
                   "state": 1494004
                },
                "numerator_errors": {
                   "this": 1886.3,
                   "CBSA": 8991,
                   "state": 28577.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 115,
                   "state": 229
                },
                "error_ratio": {
                   "this": 24.5,
                   "CBSA": 5.9,
                   "state": 1.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 20.09,
                   "CBSA": 26.48,
                   "state": 10.99
                },
                "error": {
                   "this": 3.07,
                   "CBSA": 0.55,
                   "state": 0.08
                },
                "numerators": {
                   "this": 13592,
                   "CBSA": 412940,
                   "state": 3364089
                },
                "numerator_errors": {
                   "this": 2172.2,
                   "CBSA": 8583,
                   "state": 24688.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 76,
                   "state": 183
                },
                "error_ratio": {
                   "this": 15.3,
                   "CBSA": 2.1,
                   "state": 0.7
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.4,
                   "CBSA": 1.43,
                   "state": 1.12
                },
                "error": {
                   "this": 0.49,
                   "CBSA": 0.22,
                   "state": 0.05
                },
                "numerators": {
                   "this": 271,
                   "CBSA": 22343,
                   "state": 343173
                },
                "numerator_errors": {
                   "this": 332.7,
                   "CBSA": 3488.1,
                   "state": 14221.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 28,
                   "state": 36
                },
                "error_ratio": {
                   "this": 122.5,
                   "CBSA": 15.4,
                   "state": 4.5
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 151,
                   "CBSA": 2442,
                   "state": 66574
                },
                "error": {
                   "this": 63.13,
                   "CBSA": 319.93,
                   "state": 1628.6
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 6,
                   "state": null
                },
                "error_ratio": {
                   "this": 41.8,
                   "CBSA": 13.1,
                   "state": 2.4
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 206,
                   "CBSA": 6382,
                   "state": 145536
                },
                "error": {
                   "this": 86.57,
                   "CBSA": 405.23,
                   "state": 2399.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 42,
                   "CBSA": 6.3,
                   "state": 1.6
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 582,
                   "CBSA": 19651,
                   "state": 561083
                },
                "error": {
                   "this": 133.81,
                   "CBSA": 863.14,
                   "state": 4350.77
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 23,
                   "CBSA": 4.4,
                   "state": 0.8
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 324,
                   "CBSA": 7981,
                   "state": 284290
                },
                "error": {
                   "this": 117.04,
                   "CBSA": 586.46,
                   "state": 3865.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 4,
                   "state": null
                },
                "error_ratio": {
                   "this": 36.1,
                   "CBSA": 7.3,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 274,
                   "CBSA": 7720,
                   "state": 309021
                },
                "error": {
                   "this": 125.59,
                   "CBSA": 544.05,
                   "state": 4425.46
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 4,
                   "state": null
                },
                "error_ratio": {
                   "this": 45.8,
                   "CBSA": 7,
                   "state": 1.4
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 1239,
                   "CBSA": 47264,
                   "state": 1351006
                },
                "error": {
                   "this": 688,
                   "CBSA": 3067,
                   "state": 19299
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 55.5,
                   "CBSA": 6.5,
                   "state": 1.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 73,
                   "CBSA": 3888,
                   "state": 120461
                },
                "error": {
                   "this": 97,
                   "CBSA": 937,
                   "state": 5618
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 132.9,
                   "CBSA": 24.1,
                   "state": 4.7
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 1312,
                "CBSA": 51152,
                "state": 1471467
             },
             "error": {
                "this": 698,
                "CBSA": 3444,
                "state": 19838
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 3,
                "state": null
             },
             "error_ratio": {
                "this": 53.2,
                "CBSA": 6.7,
                "state": 1.3
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 1.95,
                "CBSA": 3.28,
                "state": 4.83
             },
             "error": {
                "this": 1.03,
                "CBSA": 0.22,
                "state": 0.07
             },
             "numerators": {
                "this": 1312,
                "CBSA": 51152,
                "state": 1471467
             },
             "numerator_errors": {
                "this": 698,
                "CBSA": 3444,
                "state": 19838
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 59,
                "state": 40
             },
             "error_ratio": {
                "this": 52.8,
                "CBSA": 6.7,
                "state": 1.4
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 31055404,
       "awater": 719720,
       "display_name": "Mountain View, CA",
       "full_geoid": "16000US0649670",
       "population": 80993,
       "simple_name": "Mountain View",
       "sumlevel": "160",
       "square_miles": 12,
       "population_density": 6899.3
    }
 }

 export default mountain_view