exports.DAN_VILLE_SCHOOLS = [{'address': {'city': 'Danville',
                                 'street1': '741 Brookside Drive',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 515,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 4},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 10, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 11,
                                        'rating': 10},
                                       {'label': 'White', 'percentage': 65, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 12,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 535,
                     'lat': 37.80769,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/danville/535-John-Baldwin-Elementary-School/#College_success',
                         'profile': '/california/danville/535-John-Baldwin-Elementary-School/',
                         'reviews': '/california/danville/535-John-Baldwin-Elementary-School/#Reviews'},
                     'lon': -121.984451,
                     'name': 'John Baldwin Elementary School',
                     'numReviews': 17,
                     'parentRating': 4,
                     'percentLowIncome': '4%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 26,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 10,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '189 Del Amigo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '50%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 97,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 20},
                                       {'label': 'White', 'percentage': 47},
                                       {'label': 'Asian', 'percentage': 22},
                                       {'label': 'Hispanic', 'percentage': 11},
                                       {'label': 'Two or more races', 'percentage': 9},
                                       {'label': 'African American', 'percentage': 6},
                                       {'label': 'Filipino', 'percentage': 4}],
                     'gradeLevels': '7-12',
                     'highlighted': false,
                     'id': 539,
                     'lat': 37.826927,
                     'levelCode': 'm,h',
                     'links': {
                         'collegeSuccess': '/california/danville/539-Del-Amigo-High-Continuation-School/#College_success',
                         'profile': '/california/danville/539-Del-Amigo-High-Continuation-School/',
                         'reviews': '/california/danville/539-Del-Amigo-High-Continuation-School/#Reviews'},
                     'lon': -122.010857,
                     'name': 'Del Amigo High (Continuation) School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '20%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 8,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '1001 Diablo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 490,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 2},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'White', 'percentage': 70, 'rating': 10},
                                       {'label': 'Two or more races', 'percentage': 11},
                                       {'label': 'Asian', 'percentage': 8},
                                       {'label': 'Hispanic', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 542,
                     'lat': 37.833687,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/danville/542-Green-Valley-Elementary-School/#College_success',
                         'profile': '/california/danville/542-Green-Valley-Elementary-School/',
                         'reviews': '/california/danville/542-Green-Valley-Elementary-School/#Reviews'},
                     'lon': -121.980499,
                     'name': 'Green Valley Elementary School',
                     'numReviews': 25,
                     'parentRating': 4,
                     'percentLowIncome': '2%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 7, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '1475 Harlan Drive',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 630,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 4},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 11, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 9,
                                        'rating': 9},
                                       {'label': 'White', 'percentage': 62, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 13,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 543,
                     'lat': 37.792137,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/danville/543-Greenbrook-Elementary-School/#College_success',
                         'profile': '/california/danville/543-Greenbrook-Elementary-School/',
                         'reviews': '/california/danville/543-Greenbrook-Elementary-School/#Reviews'},
                     'lon': -121.973778,
                     'name': 'Greenbrook Elementary School',
                     'numReviews': 31,
                     'parentRating': 4,
                     'percentLowIncome': '4%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 10,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '968 Blemer Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 645,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 4,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 8, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 8,
                                        'rating': 9},
                                       {'label': 'White', 'percentage': 73, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 10,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 545,
                     'lat': 37.837696,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/danville/545-Los-Cerros-Middle-School/#College_success',
                               'profile': '/california/danville/545-Los-Cerros-Middle-School/',
                               'reviews': '/california/danville/545-Los-Cerros-Middle-School/#Reviews'},
                     'lon': -121.981087,
                     'name': 'Los Cerros Middle School',
                     'numReviews': 19,
                     'parentRating': 4,
                     'percentLowIncome': '4%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 9,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '300 Quinterra Lane',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 556,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 6},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 15, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 11,
                                        'rating': 10},
                                       {'label': 'White', 'percentage': 62, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 9,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 546,
                     'lat': 37.818733,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/danville/546-Montair-Elementary-School/#College_success',
                               'profile': '/california/danville/546-Montair-Elementary-School/',
                               'reviews': '/california/danville/546-Montair-Elementary-School/#Reviews'},
                     'lon': -122.003937,
                     'name': 'Montair Elementary School',
                     'numReviews': 32,
                     'parentRating': 4,
                     'percentLowIncome': '6%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 10,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '3131 Stone Valley Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '88%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 2448,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 4,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 20, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 9,
                                        'rating': 9},
                                       {'label': 'White', 'percentage': 59, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 9,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 547,
                     'lat': 37.844788,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/danville/547-Monte-Vista-High-School/#College_success',
                               'profile': '/california/danville/547-Monte-Vista-High-School/',
                               'reviews': '/california/danville/547-Monte-Vista-High-School/#Reviews'},
                     'lon': -121.982307,
                     'name': 'Monte Vista High School',
                     'numReviews': 40,
                     'parentRating': 3,
                     'percentLowIncome': '4%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'College Readiness Rating': 10,
                                    'Equity Overview Rating': 10,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '501 Danville Boulevard',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '86%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 2094,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 7, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 10,
                                        'rating': 10},
                                       {'label': 'White', 'percentage': 74, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 7,
                                        'rating': 9},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 551,
                     'lat': 37.826714,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/danville/551-San-Ramon-Valley-High-School/#College_success',
                         'profile': '/california/danville/551-San-Ramon-Valley-High-School/',
                         'reviews': '/california/danville/551-San-Ramon-Valley-High-School/#Reviews'},
                     'lon': -122.005318,
                     'name': 'San Ramon Valley High School',
                     'numReviews': 25,
                     'parentRating': 4,
                     'percentLowIncome': '4%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'College Readiness Rating': 10,
                                    'Equity Overview Rating': 10,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '2200 Holbrook Drive',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 573,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 2},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 21, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 9,
                                        'rating': 9},
                                       {'label': 'White', 'percentage': 56, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 10,
                                        'rating': 9},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 553,
                     'lat': 37.805824,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/danville/553-Sycamore-Valley-Elementary-School/#College_success',
                         'profile': '/california/danville/553-Sycamore-Valley-Elementary-School/',
                         'reviews': '/california/danville/553-Sycamore-Valley-Elementary-School/#Reviews'},
                     'lon': -121.944939,
                     'name': 'Sycamore Valley Elementary School',
                     'numReviews': 37,
                     'parentRating': 4,
                     'percentLowIncome': '2%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 10,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '4675 Camino Tassajara Road',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 492,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 5},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 30, 'rating': 10},
                                       {'label': 'White', 'percentage': 46, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 11,
                                        'rating': 10},
                                       {'label': 'Hispanic', 'percentage': 9},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 554,
                     'lat': 37.799404,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/danville/554-Tassajara-Hills-Elementary-School/#College_success',
                         'profile': '/california/danville/554-Tassajara-Hills-Elementary-School/',
                         'reviews': '/california/danville/554-Tassajara-Hills-Elementary-School/#Reviews'},
                     'lon': -121.878868,
                     'name': 'Tassajara Hills Elementary School',
                     'numReviews': 20,
                     'parentRating': 5,
                     'percentLowIncome': '5%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 28,
                     'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '667 Diablo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 623,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 3},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 11, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 10,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 69, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 9,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 556,
                     'lat': 37.825459,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/danville/556-Vista-Grande-Elementary-School/#College_success',
                         'profile': '/california/danville/556-Vista-Grande-Elementary-School/',
                         'reviews': '/california/danville/556-Vista-Grande-Elementary-School/#Reviews'},
                     'lon': -121.987251,
                     'name': 'Vista Grande Elementary School',
                     'numReviews': 24,
                     'parentRating': 4,
                     'percentLowIncome': '3%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 7,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '600 El Capitan Drive',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 978,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 5,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 10, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 8,
                                        'rating': 9},
                                       {'label': 'White', 'percentage': 71, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 8,
                                        'rating': 9},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 557,
                     'lat': 37.800816,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/danville/557-Charlotte-Wood-Middle-School/#College_success',
                         'profile': '/california/danville/557-Charlotte-Wood-Middle-School/',
                         'reviews': '/california/danville/557-Charlotte-Wood-Middle-School/#Reviews'},
                     'lon': -121.976898,
                     'name': 'Charlotte Wood Middle School',
                     'numReviews': 17,
                     'parentRating': 4,
                     'percentLowIncome': '5%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 26,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 9,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '920 Diablo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 29160,
                     'lat': 37.832432,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/29160-The-Growing-Room/#College_success',
                               'profile': '/california/danville/29160-The-Growing-Room/',
                               'reviews': '/california/danville/29160-The-Growing-Room/#Reviews'},
                     'lon': -121.982803,
                     'name': 'The Growing Room',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '920 Diablo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 29161,
                     'lat': 37.832432,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/29161-Growing-Room-Twin-Creeks/#College_success',
                               'profile': '/california/danville/29161-Growing-Room-Twin-Creeks/',
                               'reviews': '/california/danville/29161-Growing-Room-Twin-Creeks/#Reviews'},
                     'lon': -121.982803,
                     'name': 'Growing Room Twin Creeks',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '121 Starlight Place',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 29722,
                     'lat': 37.800144,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/29722-Starlight-School-Day-Care/#College_success',
                         'profile': '/california/danville/29722-Starlight-School-Day-Care/',
                         'reviews': '/california/danville/29722-Starlight-School-Day-Care/#Reviews'},
                     'lon': -121.973106,
                     'name': 'Starlight School Day Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '616 Bourne Court',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29827,
                     'lat': 37.809219,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/29827-Kiddie-Academy--Ps/#College_success',
                               'profile': '/california/danville/29827-Kiddie-Academy--Ps/',
                               'reviews': '/california/danville/29827-Kiddie-Academy--Ps/#Reviews'},
                     'lon': -121.900902,
                     'name': 'Kiddie Academy & Ps',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '1800 Holbrook Drive',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30217,
                     'lat': 37.805809,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/30217-Tree-Of-Life-Learning-Center/#College_success',
                         'profile': '/california/danville/30217-Tree-Of-Life-Learning-Center/',
                         'reviews': '/california/danville/30217-Tree-Of-Life-Learning-Center/#Reviews'},
                     'lon': -121.944443,
                     'name': 'Tree Of Life Learning Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '989 San Ramon Valley Boulevard',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30251,
                     'lat': 37.806858,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/30251-Dayspring-Pre-School/#College_success',
                               'profile': '/california/danville/30251-Dayspring-Pre-School/',
                               'reviews': '/california/danville/30251-Dayspring-Pre-School/#Reviews'},
                     'lon': -121.991852,
                     'name': 'Dayspring Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '1550 Diablo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30436,
                     'lat': 37.836643,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/30436-St-Timothy-Episc-Noah-Ark-Ps/#College_success',
                         'profile': '/california/danville/30436-St-Timothy-Episc-Noah-Ark-Ps/',
                         'reviews': '/california/danville/30436-St-Timothy-Episc-Noah-Ark-Ps/#Reviews'},
                     'lon': -121.970825,
                     'name': 'St Timothy Episc Noah Ark Ps',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '2425 Camino Tassajara',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 31638,
                     'lat': 37.809429,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/31638-Peekadoodle-Academy-Of-Danville/#College_success',
                         'profile': '/california/danville/31638-Peekadoodle-Academy-Of-Danville/',
                         'reviews': '/california/danville/31638-Peekadoodle-Academy-Of-Danville/#Reviews'},
                     'lon': -121.951637,
                     'name': 'Peekadoodle Academy of Danville',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '2100 Mount Diablo Scenic Boulevard',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 490,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 49},
                                       {'label': 'Asian', 'percentage': 17},
                                       {'label': 'Hispanic', 'percentage': 13},
                                       {'label': 'Two or more races', 'percentage': 10},
                                       {'label': 'African American', 'percentage': 9},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': '6-12',
                     'highlighted': false,
                     'id': 8693,
                     'lat': 37.833244,
                     'levelCode': 'm,h',
                     'links': {'collegeSuccess': '/california/danville/8693-The-Athenian-School/#College_success',
                               'profile': '/california/danville/8693-The-Athenian-School/',
                               'reviews': '/california/danville/8693-The-Athenian-School/#Reviews'},
                     'lon': -121.950645,
                     'name': 'The Athenian School',
                     'numReviews': 8,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '919 Camino Ramon',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 12,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-3',
                     'highlighted': false,
                     'id': 15861,
                     'lat': 37.801708,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/danville/15861-Montessori-School-Of-San-Ramon/#College_success',
                         'profile': '/california/danville/15861-Montessori-School-Of-San-Ramon/',
                         'reviews': '/california/danville/15861-Montessori-School-Of-San-Ramon/#Reviews'},
                     'lon': -121.987839,
                     'name': 'Montessori School Of San Ramon',
                     'numReviews': 6,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '220 El Pintado Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 342,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 85},
                                       {'label': 'Asian', 'percentage': 8},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'Hispanic', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 3}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 9168,
                     'lat': 37.830547,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/danville/9168-San-Ramon-Valley-Christian-Academy/#College_success',
                         'profile': '/california/danville/9168-San-Ramon-Valley-Christian-Academy/',
                         'reviews': '/california/danville/9168-San-Ramon-Valley-Christian-Academy/#Reviews'},
                     'lon': -121.99958,
                     'name': 'San Ramon Valley Christian Academy',
                     'numReviews': 63,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '435 La Gonda Way',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 643,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 89},
                                       {'label': 'Hispanic', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 11244,
                     'lat': 37.82663,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/danville/11244-St.-Isidore/#College_success',
                               'profile': '/california/danville/11244-St.-Isidore/',
                               'reviews': '/california/danville/11244-St.-Isidore/#Reviews'},
                     'lon': -122.003494,
                     'name': 'St. Isidore',
                     'numReviews': 72,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '4100 Camino Tassajara',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 986,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 35, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 8,
                                        'rating': 9},
                                       {'label': 'White', 'percentage': 41, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 10,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 11696,
                     'lat': 37.799664,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/danville/11696-Diablo-Vista-Middle-School/#College_success',
                         'profile': '/california/danville/11696-Diablo-Vista-Middle-School/',
                         'reviews': '/california/danville/11696-Diablo-Vista-Middle-School/#Reviews'},
                     'lon': -121.897423,
                     'name': 'Diablo Vista Middle School',
                     'numReviews': 23,
                     'parentRating': 4,
                     'percentLowIncome': '4%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 10,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '421 Montcrest Place',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 15791,
                     'lat': 37.820641,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/15791-Garden-Montessori-School/#College_success',
                               'profile': '/california/danville/15791-Garden-Montessori-School/',
                               'reviews': '/california/danville/15791-Garden-Montessori-School/#Reviews'},
                     'lon': -122.012024,
                     'name': 'Garden Montessori School',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '699 Old Orchard Drive',
                                 'street2': '',
                                 'zip': '93526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 300,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 17678,
                     'lat': 37.813354,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/17678-Infant--Pre/#College_success',
                               'profile': '/california/danville/17678-Infant--Pre/',
                               'reviews': '/california/danville/17678-Infant--Pre/#Reviews'},
                     'lon': -121.97718,
                     'name': 'Infant- Pre',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '14 Osborn Way',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18738,
                     'lat': 37.81451,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/18738-Autumn-Creek-Learning-Center/#College_success',
                         'profile': '/california/danville/18738-Autumn-Creek-Learning-Center/',
                         'reviews': '/california/danville/18738-Autumn-Creek-Learning-Center/#Reviews'},
                     'lon': -121.962967,
                     'name': 'Autumn Creek Learning Center',
                     'numReviews': 3,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '1453 San Ramon Valley Boulevard',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 18739,
                     'lat': 37.796619,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/18739-Diablo-Hills-Country-Pre-School/#College_success',
                         'profile': '/california/danville/18739-Diablo-Hills-Country-Pre-School/',
                         'reviews': '/california/danville/18739-Diablo-Hills-Country-Pre-School/#Reviews'},
                     'lon': -121.986382,
                     'name': "Diablo Hill's Country Pre-School",
                     'numReviews': 4,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': 'Sherburne Hills Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18740,
                     'lat': 37.804276,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/18740-Sycamore-Valley-Day-School/#College_success',
                         'profile': '/california/danville/18740-Sycamore-Valley-Day-School/',
                         'reviews': '/california/danville/18740-Sycamore-Valley-Day-School/#Reviews'},
                     'lon': -121.952362,
                     'name': 'Sycamore Valley Day School',
                     'numReviews': 15,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '1565 Green Valley Rd',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18741,
                     'lat': 37.837086,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/18741-Rolling-Hills-Pre-School/#College_success',
                               'profile': '/california/danville/18741-Rolling-Hills-Pre-School/',
                               'reviews': '/california/danville/18741-Rolling-Hills-Pre-School/#Reviews'},
                     'lon': -121.979164,
                     'name': 'Rolling Hills Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '120 Town and Country Drive',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 18742,
                     'lat': 37.814308,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/18742-Greenbrook-Kids-Country/#College_success',
                               'profile': '/california/danville/18742-Greenbrook-Kids-Country/',
                               'reviews': '/california/danville/18742-Greenbrook-Kids-Country/#Reviews'},
                     'lon': -121.998993,
                     'name': 'Greenbrook Kids Country',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '1899 Casablanca Street',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18743,
                     'lat': 37.797554,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/18743-Tassajara-Learning-Center/#College_success',
                         'profile': '/california/danville/18743-Tassajara-Learning-Center/',
                         'reviews': '/california/danville/18743-Tassajara-Learning-Center/#Reviews'},
                     'lon': -121.889107,
                     'name': 'Tassajara Learning Center',
                     'numReviews': 14,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '120 Town and Country Drive',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 18744,
                     'lat': 37.814308,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/18744-Kids-Country-Sycamore/#College_success',
                               'profile': '/california/danville/18744-Kids-Country-Sycamore/',
                               'reviews': '/california/danville/18744-Kids-Country-Sycamore/#Reviews'},
                     'lon': -121.998993,
                     'name': 'Kids Country-Sycamore',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '222 West El Pintado',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18745,
                     'lat': 37.825184,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/18745-Community-Presbyterian-Pre-School-I/#College_success',
                         'profile': '/california/danville/18745-Community-Presbyterian-Pre-School-I/',
                         'reviews': '/california/danville/18745-Community-Presbyterian-Pre-School-I/#Reviews'},
                     'lon': -121.998192,
                     'name': 'Community Presbyterian Pre-School I',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '2425 Camino Tassajara',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18746,
                     'lat': 37.809338,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/18746-Childrens-Academy-Danville/#College_success',
                         'profile': '/california/danville/18746-Childrens-Academy-Danville/',
                         'reviews': '/california/danville/18746-Childrens-Academy-Danville/#Reviews'},
                     'lon': -121.951645,
                     'name': "Children's Academy-Danville",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '3201 Camino Tassajara Rd',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18747,
                     'lat': 37.815964,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/18747-Stratford-School---Danville/#College_success',
                         'profile': '/california/danville/18747-Stratford-School---Danville/',
                         'reviews': '/california/danville/18747-Stratford-School---Danville/#Reviews'},
                     'lon': -121.968292,
                     'name': 'Stratford School - Danville',
                     'numReviews': 14,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '816 Diablo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18748,
                     'lat': 37.829521,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/18748-Acorn-Learning-Center/#College_success',
                               'profile': '/california/danville/18748-Acorn-Learning-Center/',
                               'reviews': '/california/danville/18748-Acorn-Learning-Center/#Reviews'},
                     'lon': -121.98777,
                     'name': 'Acorn Learning Center',
                     'numReviews': 2,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '940 Diablo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18750,
                     'lat': 37.833336,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/18750-Larsons-Childrens-Center/#College_success',
                               'profile': '/california/danville/18750-Larsons-Childrens-Center/',
                               'reviews': '/california/danville/18750-Larsons-Childrens-Center/#Reviews'},
                     'lon': -121.981598,
                     'name': "Larson's Childrens Center",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '935 Camino Ramon',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18751,
                     'lat': 37.801136,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/18751-Valley-Parents-Pre-School/#College_success',
                         'profile': '/california/danville/18751-Valley-Parents-Pre-School/',
                         'reviews': '/california/danville/18751-Valley-Parents-Pre-School/#Reviews'},
                     'lon': -121.98764,
                     'name': "Valley Parent's Pre-School",
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '105 Hickory Court',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 21274,
                     'lat': 37.812805,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/21274-Sunnybrook-Learning-Center/#College_success',
                         'profile': '/california/danville/21274-Sunnybrook-Learning-Center/',
                         'reviews': '/california/danville/21274-Sunnybrook-Learning-Center/#Reviews'},
                     'lon': -121.910385,
                     'name': 'Sunnybrook Learning Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '920 Diablo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22621,
                     'lat': 37.832432,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/22621-Growing-Room-Day-Care/#College_success',
                               'profile': '/california/danville/22621-Growing-Room-Day-Care/',
                               'reviews': '/california/danville/22621-Growing-Room-Day-Care/#Reviews'},
                     'lon': -121.982803,
                     'name': 'Growing Room Day Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '120 Town and Country Drive',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22629,
                     'lat': 37.814308,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/22629-Kids-Country-Child-Care-Center-Quail-Run/#College_success',
                         'profile': '/california/danville/22629-Kids-Country-Child-Care-Center-Quail-Run/',
                         'reviews': '/california/danville/22629-Kids-Country-Child-Care-Center-Quail-Run/#Reviews'},
                     'lon': -121.998993,
                     'name': 'Kids Country Child Care Center-Quail Run',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '730 San Ramon Valley Blvd',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 24330,
                     'lat': 37.81181,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/24330-Danville-Kindercare/#College_success',
                               'profile': '/california/danville/24330-Danville-Kindercare/',
                               'reviews': '/california/danville/24330-Danville-Kindercare/#Reviews'},
                     'lon': -121.994209,
                     'name': 'Danville Kindercare',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '2425 Camino Tassajara',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 24920,
                     'lat': 37.809338,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/24920-Childrens-Academy-Of-Danville/#College_success',
                         'profile': '/california/danville/24920-Childrens-Academy-Of-Danville/',
                         'reviews': '/california/danville/24920-Childrens-Academy-Of-Danville/#Reviews'},
                     'lon': -121.951645,
                     'name': "Children's Academy of Danville",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '6011 Massara Street',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Danville',
                     'districtId': 55,
                     'districtName': 'San Ramon Valley Unified School District',
                     'enrollment': 638,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 4},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 45, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 6,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 30, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 13,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 25032,
                     'lat': 37.79285,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/danville/25032-Creekside-Elementary-School/#College_success',
                         'profile': '/california/danville/25032-Creekside-Elementary-School/',
                         'reviews': '/california/danville/25032-Creekside-Elementary-School/#Reviews'},
                     'lon': -121.881508,
                     'name': 'Creekside Elementary School',
                     'numReviews': 30,
                     'parentRating': 4,
                     'percentLowIncome': '4%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '125 St. Patricks Dr.',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 26450,
                     'lat': 37.796181,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/26450-Casa-Dei-Nonni-Montessori/#College_success',
                         'profile': '/california/danville/26450-Casa-Dei-Nonni-Montessori/',
                         'reviews': '/california/danville/26450-Casa-Dei-Nonni-Montessori/#Reviews'},
                     'lon': -121.983162,
                     'name': 'Casa dei Nonni Montessori',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '2615 Camino Tassajara',
                                 'street2': '',
                                 'zip': '94506'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27305,
                     'lat': 37.807781,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/27305-Stratford-School---Danville-Sycamore-Valley/#College_success',
                         'profile': '/california/danville/27305-Stratford-School---Danville-Sycamore-Valley/',
                         'reviews': '/california/danville/27305-Stratford-School---Danville-Sycamore-Valley/#Reviews'},
                     'lon': -121.948929,
                     'name': 'Stratford School - Danville Sycamore Valley',
                     'numReviews': 11,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '741 Brookside Drive',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27832,
                     'lat': 37.807293,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/27832-Srvsacca-Kids-Co-John-Baldwin/#College_success',
                         'profile': '/california/danville/27832-Srvsacca-Kids-Co-John-Baldwin/',
                         'reviews': '/california/danville/27832-Srvsacca-Kids-Co-John-Baldwin/#Reviews'},
                     'lon': -121.984192,
                     'name': 'Srvsacca-Kids Co-John Baldwin',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '667 Diablo Road',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27944,
                     'lat': 37.825272,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/27944-Srvsacca-Kids-Cntry-Vst-Grand/#College_success',
                         'profile': '/california/danville/27944-Srvsacca-Kids-Cntry-Vst-Grand/',
                         'reviews': '/california/danville/27944-Srvsacca-Kids-Cntry-Vst-Grand/#Reviews'},
                     'lon': -121.986954,
                     'name': 'Srvsacca-Kids Cntry-Vst Grand',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '120 Town and Country Drive',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27973,
                     'lat': 37.814308,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/danville/27973-Srvsacca-Cca-Green-Valley-Center/#College_success',
                         'profile': '/california/danville/27973-Srvsacca-Cca-Green-Valley-Center/',
                         'reviews': '/california/danville/27973-Srvsacca-Cca-Green-Valley-Center/#Reviews'},
                     'lon': -121.998993,
                     'name': 'Srvsacca Cca-Green Valley Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Danville',
                                 'street1': '300 Quinnterra Lane',
                                 'street2': '',
                                 'zip': '94526'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27974,
                     'lat': 37.81916,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/danville/27974-Kids-Country-Montair/#College_success',
                               'profile': '/california/danville/27974-Kids-Country-Montair/',
                               'reviews': '/california/danville/27974-Kids-Country-Montair/#Reviews'},
                     'lon': -122.005142,
                     'name': 'Kids Country-Montair',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'}]
