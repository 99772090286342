var danville 	= require('./danville');
var dublin 	= require('./dublin');
var milpitas 	= require('./milpitas');
var pleasanton 	= require('./pleasanton');
var san_francisco 	= require('./san_francisco');
var san_jose 	= require('./san_jose');
var san_ramon 	= require('./san_ramon');
var fremont 	= require('./fremont');
var union_city 	= require('./union_city');
var mountain_view 	= require('./mountain_view');
var cupertino 	= require('./cupertino');
var campbell 	= require('./campbell');
var castro_valley 	= require('./castro_valley');
var daly_city 	= require('./daly_city');
var foster_city 	= require('./foster_city');
var hayward 	= require('./hayward');
var livermore 	= require('./livermore');
var millbrae 	= require('./millbrae');
var newark 	= require('./newark');
var palo_alto 	= require('./palo_alto');
var redwood_city 	= require('./redwood_city');
var san_bruno 	= require('./san_bruno');
var san_leandro 	= require('./san_leandro');
var san_mateo 	= require('./san_mateo');
var santa_clara 	= require('./santa_clara');
var saratoga 	= require('./saratoga');
var sunnyvale 	= require('./sunnyvale');
var mountain_house 	= require('./mountain_house');
var tracy 	= require('./tracy');
var las_vegas 	= require('./las_vegas');
var alameda 	= require('./alameda');
var menlo_park 	= require('./menlo_park');
var los_gatos 	= require('./los_gatos');
var los_altos 	= require('./los_altos');
var atherton 	= require('./atherton');
var walnut_creek 	= require('./walnut_creek');
var burlingame 	= require('./burlingame');
var hillsborough 	= require('./hillsborough');
var portola_valley 	= require('./portola_valley');
var woodside 	= require('./woodside');

const listingMap = {
    "San Francisco": san_francisco.SAN_FRANCISCO_LISTINGS,
    "San Ramon": san_ramon.SAN_RAMON_LISTINGS,
    "Pleasanton": pleasanton.PLEASANTON_LISTINGS,
    "Dublin": dublin.DUBLIN_LISTINGS,
    "Danville": danville.DANVILLE_LISTINGS,
    "Milpitas": milpitas.MILPITAS_LISTINGS,
    "San Jose": san_jose.SAN_JOSE_LISTINGS,
    "Fremont": fremont.FREMONT_LISTINGS,
    "Union City": union_city.UNION_CITY_LISTINGS,
    "Mountain View": mountain_view.MOUNTAIN_VIEW_LISTINGS,
    "Cupertino": cupertino.CUPERTINO_LISTINGS,
    "Campbell": campbell.CAMPBELL_LISTINGS,
    "Castro Valley": castro_valley.CASTRO_VALLEY_LISTINGS,
    "Daly City": daly_city.DALY_CITY_LISTINGS,
    "Foster City": foster_city.FOSTER_CITY_LISTINGS,
    "Hayward": hayward.HAYWARD_LISTINGS,
    "Livermore": livermore.LIVERMORE_LISTINGS,
    "Millbrae": millbrae.MILLBRAE_LISTINGS,
    "Newark": newark.NEWARK_LISTINGS,
    "Palo Alto": palo_alto.PALO_ALTO_LISTINGS,
    "Redwood City": redwood_city.REDWOOD_CITY_LISTINGS,
    "San Bruno": san_bruno.SAN_BRUNO_LISTINGS,
    "San Leandro": san_leandro.SAN_LEANDRO_LISTINGS,
    "San Mateo": san_mateo.SAN_MATEO_LISTINGS,
    "Santa Clara": santa_clara.SANTA_CLARA_LISTINGS,
    "Saratoga": saratoga.SARATOGA_LISTINGS,
    "Sunnyvale": sunnyvale.SUNNYVALE_LISTINGS,
    "Mountain House": mountain_house.MOUNTAIN_HOUSE_LISTINGS,
    "Tracy": tracy.TRACY_LISTINGS,
    "Las Vegas": las_vegas.LAS_VEGAS_LISTINGS,
    "Alameda": alameda.ALAMEDA_LISTINGS,
    "Menlo Park": menlo_park.MENLO_PARK_LISTINGS,
    "Los Gatos": los_gatos.LOS_GATOS_LISTINGS,
    "Los Altos": los_altos.LOS_ALTOS_LISTINGS,
    "Atherton": atherton.ANTHERTON_LISTINGS,
    "Walnut Creek": walnut_creek.WALNUT_CREEK_LISTINGS,
    "Burlingame": burlingame.BURLINGAME_LISTINGS,
    "Hillsborough": hillsborough.HILLSBOROUGH_LISTINGS,
    "Portola Valley": portola_valley.PORTOLA_VALLEY_LISTINGS,
    "Woodside": woodside.WOODSIDE_LISTINGS,
}

export default listingMap