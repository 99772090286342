exports.LOS_GATOS_SCHOOLS = [
		{
			"id": 5710,
			"districtId": 657,
			"districtName": "Union Elementary",
			"districtCity": "San Jose",
			"levelCode": "e",
			"lat": 37.239452,
			"lon": -121.941536,
			"name": "Alta Vista Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "200 Blossom Valley Drive",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5710-Alta-Vista-Elementary-School/",
				"reviews": "/california/los-gatos/5710-Alta-Vista-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5710-Alta-Vista-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "6%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 649,
			"parentRating": 5,
			"numReviews": 12,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 6
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 27
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 10
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 11
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 20322,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.252838,
			"lon": -121.961884,
			"name": "APJCC Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "14855 Oka Rd",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20322-APJCC-Preschool/",
				"reviews": "/california/los-gatos/20322-APJCC-Preschool/#Reviews",
				"collegeSuccess": "/california/los-gatos/20322-APJCC-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5525,
			"districtId": 640,
			"districtName": "Los Gatos Union Elementary",
			"districtCity": "Los Gatos",
			"levelCode": "e",
			"lat": 37.233955,
			"lon": -121.956688,
			"name": "Blossom Hill Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "16400 Blossom Hill Road",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5525-Blossom-Hill-Elementary-School/",
				"reviews": "/california/los-gatos/5525-Blossom-Hill-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5525-Blossom-Hill-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 584,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 27
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 58
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5530,
			"districtId": 641,
			"districtName": "Loma Prieta Joint Union Elementary",
			"districtCity": "Los Gatos",
			"levelCode": "m",
			"lat": 37.125309,
			"lon": -121.944077,
			"name": "C. T. English Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "23800 Summit Road",
				"street2": null,
				"zip": "95033",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95033",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5530-C.-T.-English-Middle-School/",
				"reviews": "/california/los-gatos/5530-C.-T.-English-Middle-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5530-C.-T.-English-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "7%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 232,
			"parentRating": 3,
			"numReviews": 8,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 7
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 71
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Asian",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 20327,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.231274,
			"lon": -121.964104,
			"name": "Calvary Infant Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "16330 Los Gatos Blvd",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20327-Calvary-Infant-Care-Center/",
				"reviews": "/california/los-gatos/20327-Calvary-Infant-Care-Center/#Reviews",
				"collegeSuccess": "/california/los-gatos/20327-Calvary-Infant-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5526,
			"districtId": 640,
			"districtName": "Los Gatos Union Elementary",
			"districtCity": "Los Gatos",
			"levelCode": "e",
			"lat": 37.240204,
			"lon": -121.98127,
			"name": "Daves Avenue Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "17770 Daves Ave.",
				"street2": null,
				"zip": "95030",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95030",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5526-Daves-Avenue-Elementary-School/",
				"reviews": "/california/los-gatos/5526-Daves-Avenue-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5526-Daves-Avenue-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "5%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 491,
			"parentRating": 4,
			"numReviews": 30,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 5
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 66
				},
				{
					"label": "Hispanic",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 10003,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.307598,
			"lon": -121.925301,
			"name": "Eitz Chaim Academy",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1934 Dandini Circle",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/10003-Eitz-Chaim-Academy/",
				"reviews": "/california/los-gatos/10003-Eitz-Chaim-Academy/#Reviews",
				"collegeSuccess": "/california/los-gatos/10003-Eitz-Chaim-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31580,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.223377,
			"lon": -121.981125,
			"name": "Fusion Academy Los Gatos",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "50 University Avenue",
				"street2": "Suite A300",
				"zip": "95030",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95030",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/31580-Fusion-Academy-Los-Gatos/",
				"reviews": "/california/los-gatos/31580-Fusion-Academy-Los-Gatos/#Reviews",
				"collegeSuccess": "/california/los-gatos/31580-Fusion-Academy-Los-Gatos/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 45,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20321,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.22197,
			"lon": -121.97776,
			"name": "Grace Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "111 Church Street",
				"street2": null,
				"zip": "95030",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95030",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20321-Grace-Pre-School/",
				"reviews": "/california/los-gatos/20321-Grace-Pre-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/20321-Grace-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20324,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.23344,
			"lon": -121.965706,
			"name": "Green Hills Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "16195 George Street",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20324-Green-Hills-Pre-School/",
				"reviews": "/california/los-gatos/20324-Green-Hills-Pre-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/20324-Green-Hills-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20330,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.232853,
			"lon": -121.959312,
			"name": "Growing Footprints Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "16575 Shannon Road",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20330-Growing-Footprints-Pre-School/",
				"reviews": "/california/los-gatos/20330-Growing-Footprints-Pre-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/20330-Growing-Footprints-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20325,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.233093,
			"lon": -121.912437,
			"name": "Harwood Hills Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "16220 Harwood Road",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20325-Harwood-Hills-Pre-School/",
				"reviews": "/california/los-gatos/20325-Harwood-Hills-Pre-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/20325-Harwood-Hills-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24019,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.23196,
			"lon": -121.913704,
			"name": "Highland Institute for Better Education",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "109 Belvue Drive",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/24019-Highland-Institute-For-Better-Education/",
				"reviews": "/california/los-gatos/24019-Highland-Institute-For-Better-Education/#Reviews",
				"collegeSuccess": "/california/los-gatos/24019-Highland-Institute-For-Better-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8664,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.227379,
			"lon": -121.954163,
			"name": "Hillbrook School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "300 Marchmont Dr",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/8664-Hillbrook-School/",
				"reviews": "/california/los-gatos/8664-Hillbrook-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/8664-Hillbrook-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 315,
			"parentRating": 4,
			"numReviews": 33,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 71
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "Asian",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Hispanic",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29157,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.244488,
			"lon": -121.946716,
			"name": "Holy Cross Lutheran Childrens",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "15885 Los Gatos Almaden Road",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/29157-Holy-Cross-Lutheran-Childrens/",
				"reviews": "/california/los-gatos/29157-Holy-Cross-Lutheran-Childrens/#Reviews",
				"collegeSuccess": "/california/los-gatos/29157-Holy-Cross-Lutheran-Childrens/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22398,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.251732,
			"lon": -121.962425,
			"name": "Kid's Corner",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "16735 Lark Avenue",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/22398-Kids-Corner/",
				"reviews": "/california/los-gatos/22398-Kids-Corner/#Reviews",
				"collegeSuccess": "/california/los-gatos/22398-Kids-Corner/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20326,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.231453,
			"lon": -121.964378,
			"name": "Kiddie Kampus Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "16330 Los Gatos Boulevard",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20326-Kiddie-Kampus-Day-Care-Center/",
				"reviews": "/california/los-gatos/20326-Kiddie-Kampus-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/los-gatos/20326-Kiddie-Kampus-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5522,
			"districtId": 638,
			"districtName": "Lakeside Joint",
			"districtCity": "Los Gatos",
			"levelCode": "e",
			"lat": 37.193432,
			"lon": -122.018883,
			"name": "Lakeside Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "19621 Black Road",
				"street2": null,
				"zip": "95033",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95033",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5522-Lakeside-Elementary-School/",
				"reviews": "/california/los-gatos/5522-Lakeside-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5522-Lakeside-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "6%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 71,
			"parentRating": 4,
			"numReviews": 18,
			"studentsPerTeacher": 12,
			"subratings": {
				"Test Scores Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 6
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "White",
					"percentage": 61
				},
				{
					"label": "Hispanic",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Asian",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 24021,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.232784,
			"lon": -121.957588,
			"name": "Lead Center",
			"gradeLevels": "K-9",
			"assigned": null,
			"address": {
				"street1": "20 Cherry Blossom Lane",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/24021-Lead-Center/",
				"reviews": "/california/los-gatos/24021-Lead-Center/#Reviews",
				"collegeSuccess": "/california/los-gatos/24021-Lead-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5528,
			"districtId": 640,
			"districtName": "Los Gatos Union Elementary",
			"districtCity": "Los Gatos",
			"levelCode": "e",
			"lat": 37.179985,
			"lon": -121.990273,
			"name": "Lexington Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "19700 Old Santa Cruz Highway",
				"street2": null,
				"zip": "95033",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95033",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5528-Lexington-Elementary-School/",
				"reviews": "/california/los-gatos/5528-Lexington-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5528-Lexington-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "5%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 144,
			"parentRating": 4,
			"numReviews": 5,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 5
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 70
				},
				{
					"label": "Hispanic",
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 24972,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 37.232059,
			"lon": -121.959251,
			"name": "Liber Community School",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "16555 Shannon Rd.",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/24972-Liber-Community-School/",
				"reviews": "/california/los-gatos/24972-Liber-Community-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/24972-Liber-Community-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5531,
			"districtId": 641,
			"districtName": "Loma Prieta Joint Union Elementary",
			"districtCity": "Los Gatos",
			"levelCode": "e",
			"lat": 37.125309,
			"lon": -121.944077,
			"name": "Loma Prieta Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "23800 Summit Road",
				"street2": null,
				"zip": "95033",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95033",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5531-Loma-Prieta-Elementary-School/",
				"reviews": "/california/los-gatos/5531-Loma-Prieta-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5531-Loma-Prieta-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "5%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 265,
			"parentRating": 3,
			"numReviews": 8,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 5
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 75
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 9278,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.223476,
			"lon": -121.907349,
			"name": "Los Gatos Christian School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "16845 Hicks Road",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/9278-Los-Gatos-Christian-School/",
				"reviews": "/california/los-gatos/9278-Los-Gatos-Christian-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/9278-Los-Gatos-Christian-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 353,
			"parentRating": 4,
			"numReviews": 29,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 63
				},
				{
					"label": "Two or more races",
					"percentage": 19
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5523,
			"districtId": 639,
			"districtName": "Los Gatos-Saratoga Joint Union High",
			"districtCity": "Los Gatos",
			"levelCode": "h",
			"lat": 37.222404,
			"lon": -121.976562,
			"name": "Los Gatos High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "20 High School Court",
				"street2": null,
				"zip": "95030",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95030",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5523-Los-Gatos-High-School/",
				"reviews": "/california/los-gatos/5523-Los-Gatos-High-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5523-Los-Gatos-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "5%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "86%",
				"state_average": "64%"
			},
			"enrollment": 2138,
			"parentRating": 4,
			"numReviews": 23,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 63
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 27251,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.223656,
			"lon": -121.985268,
			"name": "Los Gatos Parent Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "15 Lyndon Avenue",
				"street2": null,
				"zip": "95030",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95030",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/27251-Los-Gatos-Parent-Nursery-School/",
				"reviews": "/california/los-gatos/27251-Los-Gatos-Parent-Nursery-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/27251-Los-Gatos-Parent-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5529,
			"districtId": 640,
			"districtName": "Los Gatos Union Elementary",
			"districtCity": "Los Gatos",
			"levelCode": "e",
			"lat": 37.230377,
			"lon": -121.96611,
			"name": "Louise Van Meter Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "16445 Los Gatos Boulevard",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5529-Louise-Van-Meter-Elementary-School/",
				"reviews": "/california/los-gatos/5529-Louise-Van-Meter-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5529-Louise-Van-Meter-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "3%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 536,
			"parentRating": 4,
			"numReviews": 14,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 3
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 61
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 20328,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.227993,
			"lon": -121.965347,
			"name": "Mariposa Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "16548 Ferris Avenue",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20328-Mariposa-Montessori-School/",
				"reviews": "/california/los-gatos/20328-Mariposa-Montessori-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/20328-Mariposa-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9799,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.232944,
			"lon": -121.918427,
			"name": "Mulberry School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "220 Belgatos Road",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/9799-Mulberry-School/",
				"reviews": "/california/los-gatos/9799-Mulberry-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/9799-Mulberry-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 150,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 64
				},
				{
					"label": "African American",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 13
				},
				{
					"label": "Asian",
					"percentage": 5
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 20323,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.24358,
			"lon": -121.949966,
			"name": "Peppertree Pre-School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "16035 Los Gatos Almaden Road",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20323-Peppertree-Pre-School/",
				"reviews": "/california/los-gatos/20323-Peppertree-Pre-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/20323-Peppertree-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26787,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.249374,
			"lon": -121.954842,
			"name": "Rainbow Of Knowledge Elementary School",
			"gradeLevels": "PK-6",
			"assigned": null,
			"address": {
				"street1": "15080 Los Gatos Boulevard",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/26787-Rainbow-Of-Knowledge-Elementary-School/",
				"reviews": "/california/los-gatos/26787-Rainbow-Of-Knowledge-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/26787-Rainbow-Of-Knowledge-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 20,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 94
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 6
				}
			],
			"remediationData": {}
		},
		{
			"id": 5527,
			"districtId": 640,
			"districtName": "Los Gatos Union Elementary",
			"districtCity": "Los Gatos",
			"levelCode": "m",
			"lat": 37.233837,
			"lon": -121.967331,
			"name": "Raymond J. Fisher Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "19195 Fisher Avenue",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5527-Raymond-J.-Fisher-Middle-School/",
				"reviews": "/california/los-gatos/5527-Raymond-J.-Fisher-Middle-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5527-Raymond-J.-Fisher-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "4%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1269,
			"parentRating": 3,
			"numReviews": 12,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 4
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 9
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 67
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5419,
			"districtId": 629,
			"districtName": "Campbell Union",
			"districtCity": "Campbell",
			"levelCode": "e,m",
			"lat": 37.266037,
			"lon": -121.98671,
			"name": "Rolling Hills Middle",
			"gradeLevels": "5-8",
			"assigned": null,
			"address": {
				"street1": "1585 More Avenue",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/5419-Rolling-Hills-Middle-School/",
				"reviews": "/california/los-gatos/5419-Rolling-Hills-Middle-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/5419-Rolling-Hills-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "24%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1062,
			"parentRating": 3,
			"numReviews": 16,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 24
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 26
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 23
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 35
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 20329,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.232853,
			"lon": -121.959312,
			"name": "Shannon Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "16575 Shannon Road",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/20329-Shannon-Nursery-School/",
				"reviews": "/california/los-gatos/20329-Shannon-Nursery-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/20329-Shannon-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29749,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.232727,
			"lon": -121.95755,
			"name": "Shir Hadash Early Child Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "20 Cherry Blossom Lane",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/29749-Shir-Hadash-Early-Child-Center/",
				"reviews": "/california/los-gatos/29749-Shir-Hadash-Early-Child-Center/#Reviews",
				"collegeSuccess": "/california/los-gatos/29749-Shir-Hadash-Early-Child-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9087,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.223839,
			"lon": -121.984383,
			"name": "St. Mary Elementary School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "30 Lyndon Avenue",
				"street2": null,
				"zip": "95030",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95030",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/9087-St.-Mary-Elementary-School/",
				"reviews": "/california/los-gatos/9087-St.-Mary-Elementary-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/9087-St.-Mary-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 282,
			"parentRating": 5,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 57
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Asian",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 16697,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.240509,
			"lon": -121.926468,
			"name": "Stratford School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "220 Kensington Way",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/16697-Stratford-School/",
				"reviews": "/california/los-gatos/16697-Stratford-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/16697-Stratford-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 308,
			"parentRating": 4,
			"numReviews": 18,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10615,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.252838,
			"lon": -121.961884,
			"name": "Yavneh Day School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "14855 Oka Road",
				"street2": null,
				"zip": "95032",
				"city": "Los Gatos"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95032",
			"type": "school",
			"links": {
				"profile": "/california/los-gatos/10615-Yavneh-Day-School/",
				"reviews": "/california/los-gatos/10615-Yavneh-Day-School/#Reviews",
				"collegeSuccess": "/california/los-gatos/10615-Yavneh-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 151,
			"parentRating": 4,
			"numReviews": 34,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 99
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "Hispanic",
					"percentage": 1
				}
			],
			"remediationData": {}
		}
	]