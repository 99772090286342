exports.MENLO_PARK_SCHOOLS = [
		{
			"id": 9696,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 37.461685,
			"lon": -122.157204,
			"name": "Alto International School",
			"gradeLevels": "PK-10",
			"assigned": null,
			"address": {
				"street1": "475 Pope Street",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/9696-Alto-International-School/",
				"reviews": "/california/menlo-park/9696-Alto-International-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/9696-Alto-International-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 243,
			"parentRating": 4,
			"numReviews": 24,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 90
				},
				{
					"label": "Asian",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 2
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 20452,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.454906,
			"lon": -122.162865,
			"name": "Applebee Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "107 Clover Lane",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20452-Applebee-Pre-School/",
				"reviews": "/california/menlo-park/20452-Applebee-Pre-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/20452-Applebee-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12859,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.478699,
			"lon": -122.167603,
			"name": "Beechwood School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "50 Terminal Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/12859-Beechwood-School/",
				"reviews": "/california/menlo-park/12859-Beechwood-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/12859-Beechwood-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9593,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.478683,
			"lon": -122.167694,
			"name": "Beechwood School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "50 Terminal Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/9593-Beechwood-School/",
				"reviews": "/california/menlo-park/9593-Beechwood-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/9593-Beechwood-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 174,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 80
				},
				{
					"label": "African American",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Asian",
					"percentage": 2
				},
				{
					"label": "White",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 20458,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.475456,
			"lon": -122.160728,
			"name": "Belle Haven Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "410 Ivy Dr",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20458-Belle-Haven-Child-Development-Center/",
				"reviews": "/california/menlo-park/20458-Belle-Haven-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/menlo-park/20458-Belle-Haven-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6911,
			"districtId": 794,
			"districtName": "Ravenswood City Elementary",
			"districtCity": "East Palo Alto",
			"levelCode": "e,m",
			"lat": 37.476593,
			"lon": -122.16098,
			"name": "Belle Haven Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "415 Ivy Drive",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/6911-Belle-Haven-Elementary-School/",
				"reviews": "/california/menlo-park/6911-Belle-Haven-Elementary-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/6911-Belle-Haven-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "86%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 368,
			"parentRating": 2,
			"numReviews": 5,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 1,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 86
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 80
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "White",
					"percentage": 1
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 27141,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.470104,
			"lon": -122.160774,
			"name": "Bright Angel Montessori Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "695 Bay Rd",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/27141-Bright-Angel-Montessori-Academy/",
				"reviews": "/california/menlo-park/27141-Bright-Angel-Montessori-Academy/#Reviews",
				"collegeSuccess": "/california/menlo-park/27141-Bright-Angel-Montessori-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30212,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.475903,
			"lon": -122.147507,
			"name": "Casa Dei Bambini Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1215 O'Brien Drive",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/30212-Casa-Dei-Bambini-Pre-School/",
				"reviews": "/california/menlo-park/30212-Casa-Dei-Bambini-Pre-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/30212-Casa-Dei-Bambini-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26658,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.475903,
			"lon": -122.147507,
			"name": "Casa Dei Bambini School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1215 O'Brien Drive",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/26658-Casa-Dei-Bambini-School/",
				"reviews": "/california/menlo-park/26658-Casa-Dei-Bambini-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/26658-Casa-Dei-Bambini-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 108,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"percentage": 25
				},
				{
					"label": "Hispanic",
					"percentage": 17
				},
				{
					"label": "Asian",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 31557,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.475903,
			"lon": -122.147507,
			"name": "Casa Dei Bambini School",
			"gradeLevels": "K-1",
			"assigned": null,
			"address": {
				"street1": "1215 O'Brien Drive",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/31557-Casa-Dei-Bambini-School/",
				"reviews": "/california/menlo-park/31557-Casa-Dei-Bambini-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/31557-Casa-Dei-Bambini-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 7,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20463,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.434914,
			"lon": -122.208336,
			"name": "Circle Of Friends' Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3214 Alameda De Las Pulgas",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20463-Circle-Of-Friends-Pre-School/",
				"reviews": "/california/menlo-park/20463-Circle-Of-Friends-Pre-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/20463-Circle-Of-Friends-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28840,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.470253,
			"lon": -122.200737,
			"name": "Fair Oaks Full Day Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3502 Middlefield Road",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/28840-Fair-Oaks-Full-Day-Head-Start/",
				"reviews": "/california/menlo-park/28840-Fair-Oaks-Full-Day-Head-Start/#Reviews",
				"collegeSuccess": "/california/menlo-park/28840-Fair-Oaks-Full-Day-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20460,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.461281,
			"lon": -122.160004,
			"name": "Footsteps Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "490 Willow Road",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20460-Footsteps-Pre-School/",
				"reviews": "/california/menlo-park/20460-Footsteps-Pre-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/20460-Footsteps-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6927,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.469868,
			"lon": -122.202232,
			"name": "Garfield Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "3600 Middlefield Road",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/6927-Garfield-Elementary-School/",
				"reviews": "/california/menlo-park/6927-Garfield-Elementary-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/6927-Garfield-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "92%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 533,
			"parentRating": 3,
			"numReviews": 5,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 92
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 98
				},
				{
					"label": "White",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6901,
			"districtId": 791,
			"districtName": "Menlo Park City Elementary",
			"districtCity": "Atherton",
			"levelCode": "m",
			"lat": 37.43996,
			"lon": -122.195534,
			"name": "Hillview Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1100 Elder Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/6901-Hillview-Middle-School/",
				"reviews": "/california/menlo-park/6901-Hillview-Middle-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/6901-Hillview-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 968,
			"parentRating": 3,
			"numReviews": 18,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 15
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 59
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 20455,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.475948,
			"lon": -122.146446,
			"name": "Job Train",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1200 O'Brien Drive",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20455-Job-Train/",
				"reviews": "/california/menlo-park/20455-Job-Train/#Reviews",
				"collegeSuccess": "/california/menlo-park/20455-Job-Train/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26311,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.42717,
			"lon": -122.203369,
			"name": "Jubilee Academy",
			"gradeLevels": "2-11",
			"assigned": null,
			"address": {
				"street1": "810 Monte Rosa Drive",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/26311-Jubilee-Academy/",
				"reviews": "/california/menlo-park/26311-Jubilee-Academy/#Reviews",
				"collegeSuccess": "/california/menlo-park/26311-Jubilee-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20454,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.449089,
			"lon": -122.188042,
			"name": "Kirk House Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1148 Johnson Street",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20454-Kirk-House-Pre-School/",
				"reviews": "/california/menlo-park/20454-Kirk-House-Pre-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/20454-Kirk-House-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6898,
			"districtId": 790,
			"districtName": "Las Lomitas Elementary",
			"districtCity": "Menlo Park",
			"levelCode": "e,m",
			"lat": 37.42823,
			"lon": -122.200806,
			"name": "La Entrada Middle",
			"gradeLevels": "4-8",
			"assigned": null,
			"address": {
				"street1": "2200 Sharon Road",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/6898-La-Entrada-Middle-School/",
				"reviews": "/california/menlo-park/6898-La-Entrada-Middle-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/6898-La-Entrada-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "7%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 745,
			"parentRating": 4,
			"numReviews": 20,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 7
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 17
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 11
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 58
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 20457,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.397629,
			"lon": -122.193146,
			"name": "Ladera Community Church Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3300 Alpine Road",
				"street2": null,
				"zip": "94028",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94028",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20457-Ladera-Community-Church-Pre-School/",
				"reviews": "/california/menlo-park/20457-Ladera-Community-Church-Pre-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/20457-Ladera-Community-Church-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24010,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.451889,
			"lon": -122.180168,
			"name": "Lydian Academy",
			"gradeLevels": "7-12",
			"assigned": null,
			"address": {
				"street1": "815 El Camino Real",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/24010-Lydian-Academy/",
				"reviews": "/california/menlo-park/24010-Lydian-Academy/#Reviews",
				"collegeSuccess": "/california/menlo-park/24010-Lydian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 44,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20462,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.450001,
			"lon": -122.18,
			"name": "Menlo Atherton Co-Op Nursery",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 693",
				"street2": null,
				"zip": "94026",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94026",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20462-Menlo-Atherton-Co-Op-Nursery/",
				"reviews": "/california/menlo-park/20462-Menlo-Atherton-Co-Op-Nursery/#Reviews",
				"collegeSuccess": "/california/menlo-park/20462-Menlo-Atherton-Co-Op-Nursery/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20461,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.454803,
			"lon": -122.178612,
			"name": "Menlo Children's Center Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "801 Laurel Street",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20461-Menlo-Childrens-Center-Pre-School/",
				"reviews": "/california/menlo-park/20461-Menlo-Childrens-Center-Pre-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/20461-Menlo-Childrens-Center-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29330,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.472534,
			"lon": -122.203438,
			"name": "Menlo Park Head Start",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "419 Sixth Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/29330-Menlo-Park-Head-Start/",
				"reviews": "/california/menlo-park/29330-Menlo-Park-Head-Start/#Reviews",
				"collegeSuccess": "/california/menlo-park/29330-Menlo-Park-Head-Start/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20451,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.478397,
			"lon": -122.167557,
			"name": "Menlo Park-Belle Haven Child Care Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "100 Terminal Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20451-Menlo-Park-Belle-Haven-Child-Care-Center/",
				"reviews": "/california/menlo-park/20451-Menlo-Park-Belle-Haven-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/menlo-park/20451-Menlo-Park-Belle-Haven-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 20459,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.475456,
			"lon": -122.160728,
			"name": "Menlo Park-Belle Haven Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "410 Ivy Dr",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20459-Menlo-Park-Belle-Haven-Child-Development-Center/",
				"reviews": "/california/menlo-park/20459-Menlo-Park-Belle-Haven-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/menlo-park/20459-Menlo-Park-Belle-Haven-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9279,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.476372,
			"lon": -122.151672,
			"name": "Mid-Peninsula High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1340 Willow Road",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/9279-Mid-Peninsula-High-School/",
				"reviews": "/california/menlo-park/9279-Mid-Peninsula-High-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/9279-Mid-Peninsula-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 107,
			"parentRating": 4,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 61
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 8144,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.460926,
			"lon": -122.180374,
			"name": "Nativity Catholic School K-8",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1250 Laurel Street",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/8144-Nativity-Catholic-School-K-8/",
				"reviews": "/california/menlo-park/8144-Nativity-Catholic-School-K-8/#Reviews",
				"collegeSuccess": "/california/menlo-park/8144-Nativity-Catholic-School-K-8/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 301,
			"parentRating": 4,
			"numReviews": 27,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 50
				},
				{
					"label": "White",
					"percentage": 36
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 20453,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.443497,
			"lon": -122.18364,
			"name": "New Beginnings Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1100 Middle Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/20453-New-Beginnings-Pre-School/",
				"reviews": "/california/menlo-park/20453-New-Beginnings-Pre-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/20453-New-Beginnings-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6903,
			"districtId": 791,
			"districtName": "Menlo Park City Elementary",
			"districtCity": "Atherton",
			"levelCode": "e",
			"lat": 37.430008,
			"lon": -122.192886,
			"name": "Oak Knoll Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1895 Oak Knoll Lane",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/6903-Oak-Knoll-Elementary-School/",
				"reviews": "/california/menlo-park/6903-Oak-Knoll-Elementary-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/6903-Oak-Knoll-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "4%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 651,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 4
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 10
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 65
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 8672,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.466324,
			"lon": -122.166298,
			"name": "Peninsula School, Ltd",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "920 Peninsula Way",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/8672-Peninsula-School-Ltd/",
				"reviews": "/california/menlo-park/8672-Peninsula-School-Ltd/#Reviews",
				"collegeSuccess": "/california/menlo-park/8672-Peninsula-School-Ltd/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 218,
			"parentRating": 5,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9270,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.429173,
			"lon": -122.203751,
			"name": "Phillips Brooks School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "2245 Avy Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/9270-Phillips-Brooks-School/",
				"reviews": "/california/menlo-park/9270-Phillips-Brooks-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/9270-Phillips-Brooks-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 278,
			"parentRating": 4,
			"numReviews": 21,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 54
				},
				{
					"label": "Asian",
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"percentage": 19
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 17953,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.432133,
			"lon": -122.198761,
			"name": "Playschool",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "919 Cloud Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/17953-Playschool/",
				"reviews": "/california/menlo-park/17953-Playschool/#Reviews",
				"collegeSuccess": "/california/menlo-park/17953-Playschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28572,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.461456,
			"lon": -122.160881,
			"name": "Roberts Pre-School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "641 Coleman Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/28572-Roberts-Pre-School/",
				"reviews": "/california/menlo-park/28572-Roberts-Pre-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/28572-Roberts-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15479,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.461456,
			"lon": -122.160881,
			"name": "Roberts School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "641 Coleman Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/15479-Roberts-School/",
				"reviews": "/california/menlo-park/15479-Roberts-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/15479-Roberts-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 14875,
			"districtId": 798,
			"districtName": "Sequoia Union High",
			"districtCity": "Redwood City",
			"levelCode": "e,m,h",
			"lat": 37.471497,
			"lon": -122.203117,
			"name": "Sequoia District Adult Education",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "3247 Middlefield Road",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/14875-Sequoia-District-Adult-Education/",
				"reviews": "/california/menlo-park/14875-Sequoia-District-Adult-Education/#Reviews",
				"collegeSuccess": "/california/menlo-park/14875-Sequoia-District-Adult-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 2,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8143,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.447975,
			"lon": -122.19001,
			"name": "St. Raymond School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1211 Arbor Road",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/8143-St.-Raymond-School/",
				"reviews": "/california/menlo-park/8143-St.-Raymond-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/8143-St.-Raymond-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 305,
			"parentRating": 4,
			"numReviews": 18,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 73
				},
				{
					"label": "Two or more races",
					"percentage": 17
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "Asian",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 17746,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.476391,
			"lon": -122.199654,
			"name": "Synapse School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "3375 Edison Way",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/17746-Synapse-School/",
				"reviews": "/california/menlo-park/17746-Synapse-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/17746-Synapse-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 282,
			"parentRating": 4,
			"numReviews": 35,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 43
				},
				{
					"label": "Asian",
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"percentage": 26
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Hispanic",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32887,
			"districtId": 798,
			"districtName": "Sequoia Union High",
			"districtCity": "Redwood City",
			"levelCode": "e,m,h",
			"lat": 37.482193,
			"lon": -122.173935,
			"name": "Tide Academy",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "150 Jefferson Drive",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/32887-Tide-Academy/",
				"reviews": "/california/menlo-park/32887-Tide-Academy/#Reviews",
				"collegeSuccess": "/california/menlo-park/32887-Tide-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8442,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.421803,
			"lon": -122.207542,
			"name": "Trinity School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2650 Sand Hill Road",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/8442-Trinity-School/",
				"reviews": "/california/menlo-park/8442-Trinity-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/8442-Trinity-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 149,
			"parentRating": 4,
			"numReviews": 29,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 61
				},
				{
					"label": "Asian",
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 13477,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.432358,
			"lon": -122.201302,
			"name": "University Heights Montessori Childrens Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2066 Avy Avenue",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/13477-University-Heights-Montessori-Childrens-Center/",
				"reviews": "/california/menlo-park/13477-University-Heights-Montessori-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/menlo-park/13477-University-Heights-Montessori-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 68,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 6921,
			"districtId": 794,
			"districtName": "Ravenswood City Elementary",
			"districtCity": "East Palo Alto",
			"levelCode": "e,m",
			"lat": 37.462547,
			"lon": -122.15773,
			"name": "Willow Oaks Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "620 Willow Road",
				"street2": null,
				"zip": "94025",
				"city": "Menlo Park"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94025",
			"type": "school",
			"links": {
				"profile": "/california/menlo-park/6921-Willow-Oaks-Elementary-School/",
				"reviews": "/california/menlo-park/6921-Willow-Oaks-Elementary-School/#Reviews",
				"collegeSuccess": "/california/menlo-park/6921-Willow-Oaks-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "88%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 416,
			"parentRating": 3,
			"numReviews": 6,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 88
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 86
				},
				{
					"label": "Pacific Islander",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Asian",
					"percentage": 1
				}
			],
			"remediationData": {}
		}
	]