exports.SANTA_CLARA_SCHOOLS = [
		{
			"id": 5636,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.332977,
			"lon": -121.978127,
			"name": "Millikin Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "615 Hobart Terrace",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5636-Millikin-Elementary-School/",
				"reviews": "/california/santa-clara/5636-Millikin-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5636-Millikin-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "2%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 558,
			"parentRating": 4,
			"numReviews": 17,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 2
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 85
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Hispanic",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32733,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "h",
			"lat": 37.391216,
			"lon": -121.982048,
			"name": "Mission Early College High",
			"gradeLevels": "10-12",
			"assigned": null,
			"address": {
				"street1": "3000 Mission College Boulevard",
				"street2": null,
				"zip": "95054",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95054",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/32733-Mission-Early-College-High/",
				"reviews": "/california/santa-clara/32733-Mission-Early-College-High/#Reviews",
				"collegeSuccess": "/california/santa-clara/32733-Mission-Early-College-High/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "44%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "76%",
				"state_average": "64%"
			},
			"enrollment": 101,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 51,
			"subratings": {
				"Test Scores Rating": 9,
				"College Readiness Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 44
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 33
				},
				{
					"label": "White",
					"percentage": 21
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 5634,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.342182,
			"lon": -122.00174,
			"name": "Laurelwood Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "955 Teal Drive",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5634-Laurelwood-Elementary-School/",
				"reviews": "/california/santa-clara/5634-Laurelwood-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5634-Laurelwood-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "11%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 644,
			"parentRating": 3,
			"numReviews": 20,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 11
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 59
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5645,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.34193,
			"lon": -121.940689,
			"name": "Washington Open Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "270 Washington Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5645-Washington-Elementary-School/",
				"reviews": "/california/santa-clara/5645-Washington-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5645-Washington-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 331,
			"parentRating": 3,
			"numReviews": 25,
			"studentsPerTeacher": 27,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 22
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 48
				},
				{
					"label": "Hispanic",
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 32405,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.344727,
			"lon": -121.976181,
			"name": "Central Park Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2720 Sonoma Place",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/32405-Central-Park-Elementary/",
				"reviews": "/california/santa-clara/32405-Central-Park-Elementary/#Reviews",
				"collegeSuccess": "/california/santa-clara/32405-Central-Park-Elementary/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "14%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 399,
			"parentRating": 5,
			"numReviews": 15,
			"studentsPerTeacher": 33,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 14
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 43
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 5434,
			"districtId": 631,
			"districtName": "Cupertino Union",
			"districtCity": "Sunnyvale",
			"levelCode": "e",
			"lat": 37.328743,
			"lon": -121.987946,
			"name": "Dwight D. Eisenhower Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "277 Rodonovan Drive",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5434-Dwight-D.-Eisenhower-Elementary-School/",
				"reviews": "/california/santa-clara/5434-Dwight-D.-Eisenhower-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5434-Dwight-D.-Eisenhower-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 574,
			"parentRating": 4,
			"numReviews": 21,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 68
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5629,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.357693,
			"lon": -121.987976,
			"name": "Briarwood Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1930 Townsend Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5629-Briarwood-Elementary-School/",
				"reviews": "/california/santa-clara/5629-Briarwood-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5629-Briarwood-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "53%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 319,
			"parentRating": 3,
			"numReviews": 8,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 53
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 49
				},
				{
					"label": "Asian",
					"percentage": 22
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Filipino",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5646,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.333076,
			"lon": -121.961128,
			"name": "Westwood Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "435 Saratoga Avenue",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5646-Westwood-Elementary-School/",
				"reviews": "/california/santa-clara/5646-Westwood-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5646-Westwood-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "39%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 392,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 8,
					"percentage": 39
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 42
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 29
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5647,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "h",
			"lat": 37.365692,
			"lon": -121.984215,
			"name": "Adrian Wilcox High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "3250 Monroe Street",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5647-Adrian-Wilcox-High-School/",
				"reviews": "/california/santa-clara/5647-Adrian-Wilcox-High-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5647-Adrian-Wilcox-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "38%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "77%",
				"state_average": "64%"
			},
			"enrollment": 1961,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 8,
				"College Readiness Rating": 8,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 38
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 30
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 38
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 19
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 6,
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 16956,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e,m",
			"lat": 37.3983,
			"lon": -121.946556,
			"name": "Don Callejon",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "4176 Lick Mill Boulevard",
				"street2": null,
				"zip": "95054",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95054",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/16956-Don-Callejon-School/",
				"reviews": "/california/santa-clara/16956-Don-Callejon-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/16956-Don-Callejon-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "33%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 912,
			"parentRating": 3,
			"numReviews": 40,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 33
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 44
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 28
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5627,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.366756,
			"lon": -121.975395,
			"name": "Bracher Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2700 Chromite Drive",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5627-Bracher-Elementary-School/",
				"reviews": "/california/santa-clara/5627-Bracher-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5627-Bracher-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "47%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 344,
			"parentRating": 4,
			"numReviews": 16,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 47
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 19
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 48
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 5631,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "m",
			"lat": 37.358379,
			"lon": -121.971817,
			"name": "Juan Cabrillo Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "2550 Cabrillo Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5631-Juan-Cabrillo-Middle-School/",
				"reviews": "/california/santa-clara/5631-Juan-Cabrillo-Middle-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5631-Juan-Cabrillo-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "48%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 908,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 48
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 45
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5632,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.343479,
			"lon": -121.964508,
			"name": "C. W. Haman Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "865 Los Padres Boulevard",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5632-C.-W.-Haman-Elementary-School/",
				"reviews": "/california/santa-clara/5632-C.-W.-Haman-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5632-C.-W.-Haman-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "36%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 381,
			"parentRating": 5,
			"numReviews": 9,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 36
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 41
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 28
				},
				{
					"label": "Asian",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5642,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "h",
			"lat": 37.346062,
			"lon": -121.983391,
			"name": "Santa Clara High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "3000 Benton Street",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5642-Santa-Clara-High-School/",
				"reviews": "/california/santa-clara/5642-Santa-Clara-High-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5642-Santa-Clara-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "40%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "80%",
				"state_average": "64%"
			},
			"enrollment": 1967,
			"parentRating": 4,
			"numReviews": 16,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 8,
				"College Readiness Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 40
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 20
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 35
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 5
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 13
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5644,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.333965,
			"lon": -121.986633,
			"name": "Sutter Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "3200 Forbes Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5644-Sutter-Elementary-School/",
				"reviews": "/california/santa-clara/5644-Sutter-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5644-Sutter-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "20%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 456,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 20
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 34
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 20
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5626,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.358685,
			"lon": -121.975471,
			"name": "Bowers Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "2755 Barkley Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5626-Bowers-Elementary-School/",
				"reviews": "/california/santa-clara/5626-Bowers-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5626-Bowers-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "65%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 282,
			"parentRating": 5,
			"numReviews": 17,
			"studentsPerTeacher": 14,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 65
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 55
				},
				{
					"label": "White",
					"percentage": 16
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5630,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "m",
			"lat": 37.345119,
			"lon": -121.944199,
			"name": "Buchser Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1111 Bellomy Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5630-Buchser-Middle-School/",
				"reviews": "/california/santa-clara/5630-Buchser-Middle-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5630-Buchser-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "42%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1011,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 42
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 13
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 41
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 6
				},
				{
					"label": "Filipino",
					"rating": 7,
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 5633,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.405144,
			"lon": -121.963905,
			"name": "Kathryn Hughes Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "4949 Calle De Escuela",
				"street2": null,
				"zip": "95054",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95054",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5633-Kathryn-Hughes-Elementary-School/",
				"reviews": "/california/santa-clara/5633-Kathryn-Hughes-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5633-Kathryn-Hughes-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "47%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 407,
			"parentRating": 4,
			"numReviews": 32,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 47
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 7,
					"percentage": 20
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 38
				},
				{
					"label": "Filipino",
					"rating": 6,
					"percentage": 17
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5637,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.389343,
			"lon": -121.946243,
			"name": "Montague Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "750 Laurie Avenue",
				"street2": null,
				"zip": "95054",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95054",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5637-Montague-Elementary-School/",
				"reviews": "/california/santa-clara/5637-Montague-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5637-Montague-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "38%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 426,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 38
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 30
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 29
				},
				{
					"label": "Filipino",
					"rating": 5,
					"percentage": 15
				},
				{
					"label": "White",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5640,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.347881,
			"lon": -121.98819,
			"name": "Pomeroy Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1250 Pomeroy Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5640-Pomeroy-Elementary-School/",
				"reviews": "/california/santa-clara/5640-Pomeroy-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5640-Pomeroy-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "42%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 421,
			"parentRating": 3,
			"numReviews": 9,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 42
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Asian",
					"rating": 7,
					"percentage": 28
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 36
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 20
				},
				{
					"label": "Filipino",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 5643,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e",
			"lat": 37.357822,
			"lon": -121.959442,
			"name": "Scott Lane Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1925 Scott Boulevard",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5643-Scott-Lane-Elementary-School/",
				"reviews": "/california/santa-clara/5643-Scott-Lane-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5643-Scott-Lane-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "77%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 368,
			"parentRating": 5,
			"numReviews": 5,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 77
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 76
				},
				{
					"label": "Asian",
					"percentage": 13
				},
				{
					"label": "White",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 11158,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "m,h",
			"lat": 37.345085,
			"lon": -121.956551,
			"name": "Wilson Alternative",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "1840 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/11158-Wilson-Alternative-School/",
				"reviews": "/california/santa-clara/11158-Wilson-Alternative-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/11158-Wilson-Alternative-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "54%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "40%",
				"state_average": "64%"
			},
			"enrollment": 254,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 3,
				"College Readiness Rating": 2,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 54
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 50
				},
				{
					"label": "White",
					"percentage": 24
				},
				{
					"label": "Asian",
					"percentage": 8
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 5638,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "h",
			"lat": 37.356503,
			"lon": -121.995338,
			"name": "New Valley Continuation High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1875 Lawrence Road",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/5638-New-Valley-Continuation-High-School/",
				"reviews": "/california/santa-clara/5638-New-Valley-Continuation-High-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/5638-New-Valley-Continuation-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "76%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "41%",
				"state_average": "64%"
			},
			"enrollment": 127,
			"parentRating": 5,
			"numReviews": 1,
			"studentsPerTeacher": 11,
			"subratings": {
				"Test Scores Rating": 1,
				"College Readiness Rating": 2,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 76
				},
				{
					"label": "All students",
					"rating": 1
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 76
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "Asian",
					"percentage": 2
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 15550,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.367794,
			"lon": -121.991379,
			"name": "Santa Clara Christian",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "3421 Monroe Street",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/15550-Santa-Clara-Christian/",
				"reviews": "/california/santa-clara/15550-Santa-Clara-Christian/#Reviews",
				"collegeSuccess": "/california/santa-clara/15550-Santa-Clara-Christian/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 171,
			"parentRating": 5,
			"numReviews": 15,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 37
				},
				{
					"label": "Asian",
					"percentage": 34
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 16675,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.341175,
			"lon": -121.968803,
			"name": "Live Oak Academy",
			"gradeLevels": "1-12",
			"assigned": null,
			"address": {
				"street1": "2499 Homestead Road",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/16675-Live-Oak-Academy/",
				"reviews": "/california/santa-clara/16675-Live-Oak-Academy/#Reviews",
				"collegeSuccess": "/california/santa-clara/16675-Live-Oak-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 120,
			"parentRating": 4,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 16678,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.390541,
			"lon": -121.95134,
			"name": "North Valley Baptist School",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "941 Clyde Avenue",
				"street2": null,
				"zip": "95054",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95054",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/16678-North-Valley-Baptist-School/",
				"reviews": "/california/santa-clara/16678-North-Valley-Baptist-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/16678-North-Valley-Baptist-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 233,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Pacific Islander",
					"percentage": 43
				},
				{
					"label": "White",
					"percentage": 33
				},
				{
					"label": "Hispanic",
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 22461,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.354126,
			"lon": -121.942825,
			"name": "Pacific Montessori Academy I",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "745 Harrison Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22461-Pacific-Montessori-Academy-I/",
				"reviews": "/california/santa-clara/22461-Pacific-Montessori-Academy-I/#Reviews",
				"collegeSuccess": "/california/santa-clara/22461-Pacific-Montessori-Academy-I/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22707,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.349865,
			"lon": -121.948097,
			"name": "Kidsville Pre-School & Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1247 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22707-Kidsville-Pre-School--Day-Care/",
				"reviews": "/california/santa-clara/22707-Kidsville-Pre-School--Day-Care/#Reviews",
				"collegeSuccess": "/california/santa-clara/22707-Kidsville-Pre-School--Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22708,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.347954,
			"lon": -121.987,
			"name": "Pomeroy District Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1250 Pomeroy Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22708-Pomeroy-District-Pre-School/",
				"reviews": "/california/santa-clara/22708-Pomeroy-District-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/22708-Pomeroy-District-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22710,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.354931,
			"lon": -121.956261,
			"name": "Joyful Noise Pre-School & Kindergarten",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1700 Lincoln St",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22710-Joyful-Noise-Pre-School--Kindergarten/",
				"reviews": "/california/santa-clara/22710-Joyful-Noise-Pre-School--Kindergarten/#Reviews",
				"collegeSuccess": "/california/santa-clara/22710-Joyful-Noise-Pre-School--Kindergarten/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22711,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.347088,
			"lon": -121.955299,
			"name": "Haman District Preschool-Westwood",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1840 Benton St",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22711-Haman-District-Preschool-Westwood/",
				"reviews": "/california/santa-clara/22711-Haman-District-Preschool-Westwood/#Reviews",
				"collegeSuccess": "/california/santa-clara/22711-Haman-District-Preschool-Westwood/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22712,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.347954,
			"lon": -121.987,
			"name": "Pomeroy District Pre-School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "1250 Pomeroy Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22712-Pomeroy-District-Pre-School/",
				"reviews": "/california/santa-clara/22712-Pomeroy-District-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/22712-Pomeroy-District-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22713,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.340984,
			"lon": -121.987633,
			"name": "Neighborhood Christian Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "887 Pomeroy Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22713-Neighborhood-Christian-Center/",
				"reviews": "/california/santa-clara/22713-Neighborhood-Christian-Center/#Reviews",
				"collegeSuccess": "/california/santa-clara/22713-Neighborhood-Christian-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22714,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.358311,
			"lon": -121.986992,
			"name": "Briarwood Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1940 Townsend Ave",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22714-Briarwood-Childrens-Center/",
				"reviews": "/california/santa-clara/22714-Briarwood-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/santa-clara/22714-Briarwood-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22715,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.357655,
			"lon": -121.995293,
			"name": "St Lawrence Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1977 Saint Lawrence Drive",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22715-St-Lawrence-Pre-School/",
				"reviews": "/california/santa-clara/22715-St-Lawrence-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/22715-St-Lawrence-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22716,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.330215,
			"lon": -121.954697,
			"name": "Action Day Nursery On Pruneridge",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2001 Pruneridge Avenue",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22716-Action-Day-Nursery-On-Pruneridge/",
				"reviews": "/california/santa-clara/22716-Action-Day-Nursery-On-Pruneridge/#Reviews",
				"collegeSuccess": "/california/santa-clara/22716-Action-Day-Nursery-On-Pruneridge/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22717,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.327839,
			"lon": -121.979912,
			"name": "Morningstar Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "220 Blake Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22717-Morningstar-Pre-School/",
				"reviews": "/california/santa-clara/22717-Morningstar-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/22717-Morningstar-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22719,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.345352,
			"lon": -121.956024,
			"name": "Bracher Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1840 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22719-Bracher-Childrens-Center/",
				"reviews": "/california/santa-clara/22719-Bracher-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/santa-clara/22719-Bracher-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22722,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.356133,
			"lon": -121.971741,
			"name": "Noah's Ark Pre-School & Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2545 Warburton Ave",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22722-Noahs-Ark-Pre-School--Day-Care/",
				"reviews": "/california/santa-clara/22722-Noahs-Ark-Pre-School--Day-Care/#Reviews",
				"collegeSuccess": "/california/santa-clara/22722-Noahs-Ark-Pre-School--Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22723,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.345352,
			"lon": -121.956024,
			"name": "Bowers State Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1840 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22723-Bowers-State-Pre-School/",
				"reviews": "/california/santa-clara/22723-Bowers-State-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/22723-Bowers-State-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22724,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.398605,
			"lon": -121.964378,
			"name": "Mission College Campus Chldrn's Center",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3000 Mission College Blvd Ms 6",
				"street2": null,
				"zip": "95054",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95054",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22724-Mission-College-Campus-Chldrns-Center/",
				"reviews": "/california/santa-clara/22724-Mission-College-Campus-Chldrns-Center/#Reviews",
				"collegeSuccess": "/california/santa-clara/22724-Mission-College-Campus-Chldrns-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22725,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.327839,
			"lon": -121.979912,
			"name": "Wahaha School Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "220 Blake Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22725-Wahaha-School-Day-Care/",
				"reviews": "/california/santa-clara/22725-Wahaha-School-Day-Care/#Reviews",
				"collegeSuccess": "/california/santa-clara/22725-Wahaha-School-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22726,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.376751,
			"lon": -121.986282,
			"name": "Decathlon Club Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3250 Central Expressway",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22726-Decathlon-Club-Day-Care/",
				"reviews": "/california/santa-clara/22726-Decathlon-Club-Day-Care/#Reviews",
				"collegeSuccess": "/california/santa-clara/22726-Decathlon-Club-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22727,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.368008,
			"lon": -121.991844,
			"name": "Monticello Cdc I",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3401 Monroe Street",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22727-Monticello-Cdc-I/",
				"reviews": "/california/santa-clara/22727-Monticello-Cdc-I/#Reviews",
				"collegeSuccess": "/california/santa-clara/22727-Monticello-Cdc-I/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22728,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.389252,
			"lon": -121.945976,
			"name": "Montagne District Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "750 Laurie Avenue #27",
				"street2": null,
				"zip": "95054",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95054",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22728-Montagne-District-Pre-School/",
				"reviews": "/california/santa-clara/22728-Montagne-District-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/22728-Montagne-District-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22729,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.345352,
			"lon": -121.956024,
			"name": "Westwood District Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1840 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22729-Westwood-District-Pre-School/",
				"reviews": "/california/santa-clara/22729-Westwood-District-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/22729-Westwood-District-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22730,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.343533,
			"lon": -121.945038,
			"name": "Santa Clara Parents Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "471 Monroe Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22730-Santa-Clara-Parents-Nursery-School/",
				"reviews": "/california/santa-clara/22730-Santa-Clara-Parents-Nursery-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/22730-Santa-Clara-Parents-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 10,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22731,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.345352,
			"lon": -121.956024,
			"name": "Hughes State Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1840 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22731-Hughes-State-Pre-School/",
				"reviews": "/california/santa-clara/22731-Hughes-State-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/22731-Hughes-State-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22732,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.323364,
			"lon": -121.990974,
			"name": "A Special Place-Stevens Creek",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "5041 Stevens Creek Boulevard",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22732-A-Special-Place-Stevens-Creek/",
				"reviews": "/california/santa-clara/22732-A-Special-Place-Stevens-Creek/#Reviews",
				"collegeSuccess": "/california/santa-clara/22732-A-Special-Place-Stevens-Creek/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22734,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.349945,
			"lon": -121.940643,
			"name": "Kids On Campus",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "500 El Camino Real",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/22734-Kids-On-Campus/",
				"reviews": "/california/santa-clara/22734-Kids-On-Campus/#Reviews",
				"collegeSuccess": "/california/santa-clara/22734-Kids-On-Campus/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23171,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.345352,
			"lon": -121.956024,
			"name": "Ponderosa District Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1840 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/23171-Ponderosa-District-Pre-School/",
				"reviews": "/california/santa-clara/23171-Ponderosa-District-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/23171-Ponderosa-District-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 1,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24022,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.351498,
			"lon": -121.984818,
			"name": "Llatino High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "3208 El Camino Real Boulevard",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/24022-Llatino-High-School/",
				"reviews": "/california/santa-clara/24022-Llatino-High-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/24022-Llatino-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24024,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.359612,
			"lon": -121.970444,
			"name": "Cabrillo Montessori School",
			"gradeLevels": "K-3",
			"assigned": null,
			"address": {
				"street1": "2495 Cabrillo Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/24024-Cabrillo-Montessori-School/",
				"reviews": "/california/santa-clara/24024-Cabrillo-Montessori-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/24024-Cabrillo-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 94,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 85
				},
				{
					"label": "Two or more races",
					"percentage": 15
				}
			],
			"remediationData": {}
		},
		{
			"id": 24026,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.389778,
			"lon": -121.944107,
			"name": "The Redwood School",
			"gradeLevels": "3-12",
			"assigned": null,
			"address": {
				"street1": "3605 Eastwood Circle",
				"street2": null,
				"zip": "95054",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95054",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/24026-The-Redwood-School/",
				"reviews": "/california/santa-clara/24026-The-Redwood-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/24026-The-Redwood-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12908,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,h",
			"lat": 37.356133,
			"lon": -121.971741,
			"name": "Cedarwood Sudbury School",
			"gradeLevels": "1-2, 9-12",
			"assigned": null,
			"address": {
				"street1": "2545 Warburton Ave",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/12908-Cedarwood-Sudbury-School/",
				"reviews": "/california/santa-clara/12908-Cedarwood-Sudbury-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/12908-Cedarwood-Sudbury-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13074,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.341301,
			"lon": -121.987701,
			"name": "Challenger",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "890 Pomeroy Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/13074-Challenger/",
				"reviews": "/california/santa-clara/13074-Challenger/#Reviews",
				"collegeSuccess": "/california/santa-clara/13074-Challenger/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 3,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13173,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.339664,
			"lon": -121.990837,
			"name": "Monticello Academy",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "3345 Lochinvar Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/13173-Monticello-Academy/",
				"reviews": "/california/santa-clara/13173-Monticello-Academy/#Reviews",
				"collegeSuccess": "/california/santa-clara/13173-Monticello-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 498,
			"parentRating": 4,
			"numReviews": 29,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 66
				},
				{
					"label": "White",
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Hispanic",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13397,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.327007,
			"lon": -121.980156,
			"name": "Happy Days CDC",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "220 Blake Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/13397-Happy-Days-CDC/",
				"reviews": "/california/santa-clara/13397-Happy-Days-CDC/#Reviews",
				"collegeSuccess": "/california/santa-clara/13397-Happy-Days-CDC/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13600,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.339436,
			"lon": -121.993523,
			"name": "Kindercare Learning Centers",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "840 Bing Drive",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/13600-Kindercare-Learning-Centers/",
				"reviews": "/california/santa-clara/13600-Kindercare-Learning-Centers/#Reviews",
				"collegeSuccess": "/california/santa-clara/13600-Kindercare-Learning-Centers/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 38,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 77
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 13694,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "e,m,h",
			"lat": 37.345352,
			"lon": -121.956017,
			"name": "Santa Clara Adult",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "1840 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/13694-Santa-Clara-Adult/",
				"reviews": "/california/santa-clara/13694-Santa-Clara-Adult/#Reviews",
				"collegeSuccess": "/california/santa-clara/13694-Santa-Clara-Adult/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24976,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.333363,
			"lon": -121.951241,
			"name": "Stratford School",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "400 North Winchester Boulevard",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/24976-Stratford-School/",
				"reviews": "/california/santa-clara/24976-Stratford-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/24976-Stratford-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 128,
			"parentRating": 5,
			"numReviews": 14,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24977,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.341072,
			"lon": -121.988274,
			"name": "Stratford School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "890 Pomeroy Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/24977-Stratford-School/",
				"reviews": "/california/santa-clara/24977-Stratford-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/24977-Stratford-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 624,
			"parentRating": 4,
			"numReviews": 37,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 25610,
			"districtId": 654,
			"districtName": "Santa Clara Unified",
			"districtCity": "Santa Clara",
			"levelCode": "m,h",
			"lat": 37.34203,
			"lon": -121.991493,
			"name": "Santa Clara Community Day",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "3450 Brookdale Drive",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/25610-Santa-Clara-Community-Day/",
				"reviews": "/california/santa-clara/25610-Santa-Clara-Community-Day/#Reviews",
				"collegeSuccess": "/california/santa-clara/25610-Santa-Clara-Community-Day/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "82%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 11,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 82
				},
				{
					"label": "Hispanic",
					"percentage": 91
				},
				{
					"label": "Asian",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 26357,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.368008,
			"lon": -121.991844,
			"name": "Monticello Academy",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "3401 Monroe Street",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/26357-Monticello-Academy/",
				"reviews": "/california/santa-clara/26357-Monticello-Academy/#Reviews",
				"collegeSuccess": "/california/santa-clara/26357-Monticello-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 12,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26361,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.329395,
			"lon": -121.951744,
			"name": "Pacific Autism Center For Education",
			"gradeLevels": "1-12",
			"assigned": null,
			"address": {
				"street1": "1880 Pruneridge Avenue",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/26361-Pacific-Autism-Center-For-Education/",
				"reviews": "/california/santa-clara/26361-Pacific-Autism-Center-For-Education/#Reviews",
				"collegeSuccess": "/california/santa-clara/26361-Pacific-Autism-Center-For-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 42,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26439,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.343105,
			"lon": -122.002274,
			"name": "Laurelwood Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "955 Teal Drive",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/26439-Laurelwood-Preschool/",
				"reviews": "/california/santa-clara/26439-Laurelwood-Preschool/#Reviews",
				"collegeSuccess": "/california/santa-clara/26439-Laurelwood-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26447,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.32346,
			"lon": -121.955116,
			"name": "Scuttlebugs Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3291 Stevens Creek Blvd",
				"street2": null,
				"zip": "95117",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95117",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/26447-Scuttlebugs-Child-Development-Center/",
				"reviews": "/california/santa-clara/26447-Scuttlebugs-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/santa-clara/26447-Scuttlebugs-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26481,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.369415,
			"lon": -121.969269,
			"name": "Institute for Business Technology",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "2400 Walsh Ave",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/26481-Institute-For-Business-Technology/",
				"reviews": "/california/santa-clara/26481-Institute-For-Business-Technology/#Reviews",
				"collegeSuccess": "/california/santa-clara/26481-Institute-For-Business-Technology/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27604,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.349873,
			"lon": -121.984917,
			"name": "Parkside Chinese Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1350 Vía Dondera",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/27604-Parkside-Chinese-Pre-School/",
				"reviews": "/california/santa-clara/27604-Parkside-Chinese-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/27604-Parkside-Chinese-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28199,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.345352,
			"lon": -121.956024,
			"name": "Braly Extended Day",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1840 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/28199-Braly-Extended-Day/",
				"reviews": "/california/santa-clara/28199-Braly-Extended-Day/#Reviews",
				"collegeSuccess": "/california/santa-clara/28199-Braly-Extended-Day/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28523,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.345352,
			"lon": -121.956024,
			"name": "Pomeroy Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1840 Benton Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/28523-Pomeroy-Pre-School/",
				"reviews": "/california/santa-clara/28523-Pomeroy-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/28523-Pomeroy-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 28938,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.36063,
			"lon": -121.969345,
			"name": "Westwood Oaks Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2121 Hoover Court",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/28938-Westwood-Oaks-Pre-School/",
				"reviews": "/california/santa-clara/28938-Westwood-Oaks-Pre-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/28938-Westwood-Oaks-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29053,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.335476,
			"lon": -121.949913,
			"name": "Kidz Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1224 North Winchester Boulevard",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/29053-Kidz-Academy/",
				"reviews": "/california/santa-clara/29053-Kidz-Academy/#Reviews",
				"collegeSuccess": "/california/santa-clara/29053-Kidz-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30077,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.361229,
			"lon": -121.940811,
			"name": "Hearts & Hands Christian Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2005 De La Cruz Boulevard #120",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/30077-Hearts--Hands-Christian-Child-Care-Center/",
				"reviews": "/california/santa-clara/30077-Hearts--Hands-Christian-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/santa-clara/30077-Hearts--Hands-Christian-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30179,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.339924,
			"lon": -121.969978,
			"name": "Cham Sarang Academy Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2526 Homestead Road",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/30179-Cham-Sarang-Academy-Child-Care-Center/",
				"reviews": "/california/santa-clara/30179-Cham-Sarang-Academy-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/santa-clara/30179-Cham-Sarang-Academy-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30707,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.368008,
			"lon": -121.991844,
			"name": "Monticello Academy - Monroe Campus",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3401 Monroe Street",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/30707-Monticello-Academy---Monroe-Campus/",
				"reviews": "/california/santa-clara/30707-Monticello-Academy---Monroe-Campus/#Reviews",
				"collegeSuccess": "/california/santa-clara/30707-Monticello-Academy---Monroe-Campus/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 43,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 62
				},
				{
					"label": "White",
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"percentage": 8
				}
			],
			"remediationData": {}
		},
		{
			"id": 32314,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.359612,
			"lon": -121.970444,
			"name": "Cabrillo Montessori School of Silicon Valley",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2495 Cabrillo Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/32314-Cabrillo-Montessori-School-Of-Silicon-Valley/",
				"reviews": "/california/santa-clara/32314-Cabrillo-Montessori-School-Of-Silicon-Valley/#Reviews",
				"collegeSuccess": "/california/santa-clara/32314-Cabrillo-Montessori-School-Of-Silicon-Valley/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9092,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.359455,
			"lon": -121.995361,
			"name": "St. Lawrence Elementary and Middle School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1977 St. Lawrence Drive",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/9092-St.-Lawrence-Elementary-And-Middle-School/",
				"reviews": "/california/santa-clara/9092-St.-Lawrence-Elementary-And-Middle-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/9092-St.-Lawrence-Elementary-And-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 360,
			"parentRating": 4,
			"numReviews": 17,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 37
				},
				{
					"label": "White",
					"percentage": 31
				},
				{
					"label": "Hispanic",
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 9093,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.339943,
			"lon": -121.974609,
			"name": "St. Justin",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2655 Homestead Road",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/9093-St.-Justin/",
				"reviews": "/california/santa-clara/9093-St.-Justin/#Reviews",
				"collegeSuccess": "/california/santa-clara/9093-St.-Justin/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 315,
			"parentRating": 4,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 38
				},
				{
					"label": "Asian",
					"percentage": 29
				},
				{
					"label": "Hispanic",
					"percentage": 16
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 9101,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.347385,
			"lon": -121.943535,
			"name": "St. Clare Elementary School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "750 Washington Street",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/9101-St.-Clare-Elementary-School/",
				"reviews": "/california/santa-clara/9101-St.-Clare-Elementary-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/9101-St.-Clare-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 278,
			"parentRating": 5,
			"numReviews": 21,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 56
				},
				{
					"label": "Hispanic",
					"percentage": 22
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 17
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 9110,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.359283,
			"lon": -121.995453,
			"name": "St. Lawrence Academy",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "2000 Lawrence Court",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/9110-St.-Lawrence-Academy/",
				"reviews": "/california/santa-clara/9110-St.-Lawrence-Academy/#Reviews",
				"collegeSuccess": "/california/santa-clara/9110-St.-Lawrence-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 228,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 92
				},
				{
					"label": "Asian",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 9661,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.341072,
			"lon": -121.988274,
			"name": "Delphi Academy San Francisco Bay",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "890 Pomeroy Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/9661-Delphi-Academy-San-Francisco-Bay/",
				"reviews": "/california/santa-clara/9661-Delphi-Academy-San-Francisco-Bay/#Reviews",
				"collegeSuccess": "/california/santa-clara/9661-Delphi-Academy-San-Francisco-Bay/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 135,
			"parentRating": 5,
			"numReviews": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 78
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 9801,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.340984,
			"lon": -121.987633,
			"name": "Neighborhood Christian Center",
			"gradeLevels": "PK-1",
			"assigned": null,
			"address": {
				"street1": "887 Pomeroy Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/9801-Neighborhood-Christian-Center/",
				"reviews": "/california/santa-clara/9801-Neighborhood-Christian-Center/#Reviews",
				"collegeSuccess": "/california/santa-clara/9801-Neighborhood-Christian-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 194,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 67
				},
				{
					"label": "Two or more races",
					"percentage": 22
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 9836,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.329395,
			"lon": -121.951744,
			"name": "Pacific Autism Center For Education",
			"gradeLevels": "K-12 & Ungraded",
			"assigned": null,
			"address": {
				"street1": "1880 Pruneridge Avenue",
				"street2": null,
				"zip": "95050",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95050",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/9836-Pacific-Autism-Center-For-Education/",
				"reviews": "/california/santa-clara/9836-Pacific-Autism-Center-For-Education/#Reviews",
				"collegeSuccess": "/california/santa-clara/9836-Pacific-Autism-Center-For-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 52,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 42
				},
				{
					"label": "Asian",
					"percentage": 29
				},
				{
					"label": "Hispanic",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Pacific Islander",
					"percentage": 4
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 9882,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.327839,
			"lon": -121.979912,
			"name": "Sierra Elementary And High School",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "220 Blake Avenue",
				"street2": null,
				"zip": "95051",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95051",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/9882-Sierra-Elementary-And-High-School/",
				"reviews": "/california/santa-clara/9882-Sierra-Elementary-And-High-School/#Reviews",
				"collegeSuccess": "/california/santa-clara/9882-Sierra-Elementary-And-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 87,
			"parentRating": 4,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 62
				},
				{
					"label": "White",
					"percentage": 33
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 9978,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.376957,
			"lon": -121.959244,
			"name": "Granada Islamic",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "3003 Scott Boulevard",
				"street2": null,
				"zip": "95054",
				"city": "Santa Clara"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95054",
			"type": "school",
			"links": {
				"profile": "/california/santa-clara/9978-Granada-Islamic/",
				"reviews": "/california/santa-clara/9978-Granada-Islamic/#Reviews",
				"collegeSuccess": "/california/santa-clara/9978-Granada-Islamic/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 477,
			"parentRating": 2,
			"numReviews": 18,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 56
				},
				{
					"label": "White",
					"percentage": 29
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Two or more races",
					"percentage": 6
				}
			],
			"remediationData": {}
		}
	]