const ELEMENTARY_SCHOOL_RANKS = [
    {
        "guid": "7c52cada-aaf9-40de-8d7d-3dda0a2d2bba",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 1,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.450135,
                "lon": -122.134564
            },
            "entity": {
                "abbreviation": "Duveneck Elementary School",
                "alternates": {
                    "nces": "062961004586"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "7c52cada-aaf9-40de-8d7d-3dda0a2d2bba",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Duveneck Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "23a2763b-1695-46aa-a71d-ab1cbe922645",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "e91bfc0c-bca1-45d4-909a-25b104b8c881"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Duveneck Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "duveneck-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 374
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 16
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.134,37.4497,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.134,37.4497,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94303"
            }
        ]
    },
    {
        "guid": "4a5f7a56-1a4d-45ba-9a7d-f51e73053bb5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 2,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.408557,
                "lon": -122.132056
            },
            "entity": {
                "abbreviation": "Juana Briones Elementary School",
                "alternates": {
                    "nces": "062961004591"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "4a5f7a56-1a4d-45ba-9a7d-f51e73053bb5",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Juana Briones Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "84d3d988-b95a-494b-9118-bbd4b7a119e5",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Juana Briones Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "juana-briones-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 307
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.129,37.4072,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.129,37.4072,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94306"
            }
        ]
    },
    {
        "guid": "73263c01-8ae5-48e0-80e2-adb2ab8571dd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 3,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.417789,
                "lon": -122.15741
            },
            "entity": {
                "abbreviation": "Lucille M. Nixon Elementary School",
                "alternates": {
                    "nces": "062961004594"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "73263c01-8ae5-48e0-80e2-adb2ab8571dd",
                "isClaimed": true,
                "isPremium": false,
                "location": "Stanford, CA",
                "name": "Lucille M. Nixon Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6d60ab2e-fe21-448b-966b-a0e7a2ad07fb",
                    "zipCode": "ac2ecc25-49dd-47f1-a9d6-b82f9fdbe676"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lucille M. Nixon Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lucille-m-nixon-elementary-school-stanford-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 445
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Nixon was an amazing school. The teachers were extremely patient and assigned creative and meaningful assignments. I remember writing a journal as someone in the gold rush in 4th grade and doing book reports opened me to fascinating books and genres I wouldn't have initially read.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-07T06:58:49.360623Z",
                "guid": "7d4cf1f9-09d4-43a3-b0e2-39c3163fc98a",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.156,37.4139,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.156,37.4139,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94305"
            }
        ]
    },
    {
        "guid": "0f81cb73-b2fb-4807-9fdd-888ade39986c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 4,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.43072,
                "lon": -122.11289
            },
            "entity": {
                "abbreviation": "Palo Verde Elementary School",
                "alternates": {
                    "nces": "062961004597"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "0f81cb73-b2fb-4807-9fdd-888ade39986c",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Palo Verde Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "d7389232-9e89-4250-8b8d-287a9af568a5",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "e91bfc0c-bca1-45d4-909a-25b104b8c881"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Palo Verde Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "palo-verde-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 393
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "I have a 3rd grader at this school.  The teachers are caring, well-prepared, and dedicated.  The students are eager to learn.  The parents are friendly and involved.  The staff is dedicated and helpful.  The students learn a lot, with, it seems to me, particular focus on reading and writing, particularly on reading comprehension and writing structure.  There is also a lot of focus on being good citizens being caring towards others, and anti-bullying messages.  There is a lot of art and other extras, as well as a lot of after-school activities.  I feel like my daughter is getting a well-rounded school experience.  She is above grade in her work, and there is a group of similar kids which the teacher works hard to keep engaged and learning.  I have been very happy with every grade of her education at Palo Verde.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-11-19T00:25:33.09893Z",
                "guid": "5f5ac2e6-64d0-49b6-bb7f-c79f552984ae",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.114,37.4308,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.114,37.4308,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94303"
            }
        ]
    },
    {
        "guid": "469e29bd-bb17-4a72-8db2-10d047290025",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 5,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.425769,
                "lon": -122.130449
            },
            "entity": {
                "abbreviation": "El Carmelo Elementary School",
                "alternates": {
                    "nces": "062961004583"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "469e29bd-bb17-4a72-8db2-10d047290025",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "El Carmelo Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b613338b-7cb8-48e9-bdad-b313cfe4eb22",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "El Carmelo Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "el-carmelo-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 360
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Both of my children attend El Carmelo, and we absolutely love it. The rigorous academics, the social/emotional awareness with Project Cornerstone, the caring teachers and staff, the engaged parents and PTA. Truly a gem in South Palo Alto!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-09-05T14:40:35.098121Z",
                "guid": "11c03aa3-9701-4af7-a604-92371be4bdef",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.13,37.4252,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.13,37.4252,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94306"
            }
        ]
    },
    {
        "guid": "dbb02eef-0c09-44d7-9433-265d2f2998e0",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 6,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.847159,
                "lon": -122.283151
            },
            "entity": {
                "abbreviation": "Yu Ming Charter School",
                "alternates": {
                    "nces": "060201312901"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "dbb02eef-0c09-44d7-9433-265d2f2998e0",
                "isClaimed": true,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Yu Ming Charter School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "9b0e24bf-e5f4-4fab-becb-f9156b7b0d68"
                },
                "premium": false,
                "shortName": "Yu Ming Charter School",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "yu-ming-charter-school-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 445
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Great school, I go there and I love it so much! There are a lot of different things you can do but I love how it also teaches me mandarin and it also is super strong on math and English even though its half Chinese!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-03-03T04:28:24.131612Z",
                "guid": "b5a86495-e0eb-483f-8d1e-bec1651e0051",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.284,37.8474,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.284,37.8474,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94608"
            }
        ]
    },
    {
        "guid": "82c31d57-a1af-4be2-bc2f-ec0733fce742",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 7,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.443,
                "lon": -122.143
            },
            "entity": {
                "abbreviation": "Walter Hays Elementary School",
                "alternates": {
                    "nces": "062961004588"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "82c31d57-a1af-4be2-bc2f-ec0733fce742",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Walter Hays Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "799804e8-941c-4380-8c7c-10f3b32eccca",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "149b342b-a069-494e-b28e-26a86c518a0a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Walter Hays Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "walter-hays-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 371
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 16
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.143,37.443,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.143,37.443,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94301"
            }
        ]
    },
    {
        "guid": "abae148b-bd17-4927-983e-2eab83aafa68",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 8,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.428036,
                "lon": -122.258475
            },
            "entity": {
                "abbreviation": "Woodside Elementary School",
                "alternates": {
                    "nces": "064314007013"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "abae148b-bd17-4927-983e-2eab83aafa68",
                "isClaimed": false,
                "isPremium": false,
                "location": "Woodside, CA",
                "name": "Woodside Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3fd9c449-6e9c-4b39-af8c-45c1109e0846",
                    "zipCode": "7d773137-7242-4cc9-91c9-d3770c1b9f33"
                },
                "premium": false,
                "shortName": "Woodside Elementary School",
                "tagline": [
                    "Public School",
                    "Woodside, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "woodside-elementary-school-woodside-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 408
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 11
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Great Teachers! Smart and competitive students. My main critique is about diversity, the school works hard on it but it is really not possible. Also if you are not very left-wing you will be destroyed by unsupportive peers and some teachers. There is literally borderline propaganda on the walls that includes fighting against those who think differently, defunding government organizations, and fighting the government. Some are supportive but if you don't think like others don't say anything.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-02-14T06:28:53.396883Z",
                "guid": "8d6c19b4-ef2c-4ea5-97f5-743d27625bd1",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.259,37.4281,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.259,37.4281,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.2,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94062"
            }
        ]
    },
    {
        "guid": "a91f71a2-b9ed-41dc-80ec-177e375413b2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 9,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.422156,
                "lon": -122.118398
            },
            "entity": {
                "abbreviation": "Fairmeadow Elementary School",
                "alternates": {
                    "nces": "062961004585"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "a91f71a2-b9ed-41dc-80ec-177e375413b2",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Fairmeadow Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "19f5aca2-1171-4d7e-b74a-93c3e4d99c32",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Fairmeadow Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "fairmeadow-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 445
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We love Fairmeadow Elementary school at all levels, ecademic balance, diversity, social and emotional growth of our child. Our son is in grade 1 and he loves reading, math, science, soccer and tops it all he loves to play and socialize. Thanks to the administration, teachers & parents to make this school so precious where childrens can thrive. I will end my review with saying “it takes a village to raise a child”",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-02-08T19:41:11.074583Z",
                "guid": "3b87d3b2-3bf3-479a-a0be-b36043b21d5e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.118,37.4222,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.118,37.4222,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.333333333333333,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94306"
            }
        ]
    },
    {
        "guid": "a244d6ea-064c-4e1d-a56c-6686c830ec43",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 10,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.439725,
                "lon": -122.123611
            },
            "entity": {
                "abbreviation": "Ohlone Elementary School",
                "alternates": {
                    "nces": "062961004592"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "a244d6ea-064c-4e1d-a56c-6686c830ec43",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Ohlone Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b613338b-7cb8-48e9-bdad-b313cfe4eb22",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "e91bfc0c-bca1-45d4-909a-25b104b8c881"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Ohlone Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ohlone-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 560
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "A different experience from other schools, Ohlone is a very unique place. From combining grades to numerous trips, Ohlone is a very hands on school that has improved my social learning Although the academics could be more strict this is a good school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-10-25T00:31:49.476667Z",
                "guid": "e1f159bc-600d-45dc-9850-b55186d0586c",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.123,37.439,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.123,37.439,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94303"
            }
        ]
    },
    {
        "guid": "a6b46681-2822-4995-a80d-93b6289c8da8",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 11,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.446143,
                "lon": -122.151153
            },
            "entity": {
                "abbreviation": "Addison Elementary School",
                "alternates": {
                    "nces": "062961004579"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "a6b46681-2822-4995-a80d-93b6289c8da8",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Addison Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "311bf9b0-c70b-46b2-b7e9-182f70af1a36",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "149b342b-a069-494e-b28e-26a86c518a0a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Addison Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "addison-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 402
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.151,37.4458,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.151,37.4458,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94301"
            }
        ]
    },
    {
        "guid": "42868afe-1344-45c3-bc68-2e2da94782ec",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 12,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.41835,
                "lon": -122.113428
            },
            "entity": {
                "abbreviation": "Herbert Hoover Elementary School",
                "alternates": {
                    "nces": "062961004581"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "42868afe-1344-45c3-bc68-2e2da94782ec",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Herbert Hoover Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "19f5aca2-1171-4d7e-b74a-93c3e4d99c32",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Herbert Hoover Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "herbert-hoover-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 395
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.114,37.4187,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.114,37.4187,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94306"
            }
        ]
    },
    {
        "guid": "daa0323a-3f93-4e6e-97e9-e4836ca534f1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 13,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.824211,
                "lon": -122.247223
            },
            "entity": {
                "abbreviation": "Beach Elementary School",
                "alternates": {
                    "nces": "063033004729"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "daa0323a-3f93-4e6e-97e9-e4836ca534f1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Piedmont, CA",
                "name": "Beach Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                    "schoolDistrict": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "56749142-fea1-470f-b296-912a61cb1169",
                    "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                        "location": "Piedmont, CA",
                        "name": "Piedmont City Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "56749142-fea1-470f-b296-912a61cb1169",
                            "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                        },
                        "premium": false,
                        "shortName": "Piedmont City Unified School District",
                        "tagline": [
                            "School District",
                            "Piedmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "piedmont-city-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Beach Elementary School",
                "tagline": [
                    "Piedmont City Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "beach-elementary-school-piedmont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 276
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 16
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.247,37.8242,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.247,37.8242,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94611"
            }
        ]
    },
    {
        "guid": "ccdde699-70e4-47c6-9c7d-093e35e3b4b5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 14,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.193411,
                "lon": -122.020547
            },
            "entity": {
                "abbreviation": "Lakeside Elementary School",
                "alternates": {
                    "nces": "062070002488"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ccdde699-70e4-47c6-9c7d-093e35e3b4b5",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Gatos, CA",
                "name": "Lakeside Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "98637fb0-4b0f-4318-8807-b2944c7d92a8"
                },
                "premium": false,
                "shortName": "Lakeside Elementary School",
                "tagline": [
                    "Public School",
                    "Los Gatos, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lakeside-elementary-school-los-gatos-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 71
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Lakeside Elementary is truly unique, I can't say enough good things about it. It has brought out the best in our children, educating them towards self-confident curious learners and creative problem-solvers.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-11-15T19:13:04.064494Z",
                "guid": "59f863d8-2dd3-421f-aa72-de069680ace2",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.018,37.1938,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.018,37.1938,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95033"
            }
        ]
    },
    {
        "guid": "2b119753-deff-442b-b43c-e5768498fcf8",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 15,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.819541,
                "lon": -122.235123
            },
            "entity": {
                "abbreviation": "Wildwood Elementary School",
                "alternates": {
                    "nces": "063033004733"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2b119753-deff-442b-b43c-e5768498fcf8",
                "isClaimed": false,
                "isPremium": false,
                "location": "Piedmont, CA",
                "name": "Wildwood Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                    "schoolDistrict": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "56749142-fea1-470f-b296-912a61cb1169",
                    "zipCode": "151103a6-ab35-4483-a39b-6a34c254b9bd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                        "location": "Piedmont, CA",
                        "name": "Piedmont City Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "56749142-fea1-470f-b296-912a61cb1169",
                            "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                        },
                        "premium": false,
                        "shortName": "Piedmont City Unified School District",
                        "tagline": [
                            "School District",
                            "Piedmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "piedmont-city-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Wildwood Elementary School",
                "tagline": [
                    "Piedmont City Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "wildwood-elementary-school-piedmont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 296
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.8198,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.8198,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94611"
            }
        ]
    },
    {
        "guid": "7afb89df-4791-476c-a5c9-f1efa70a68b4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 16,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.593009,
                "lon": -121.884927
            },
            "entity": {
                "abbreviation": "Sunol Glen Elementary School",
                "alternates": {
                    "nces": "060002109291"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7afb89df-4791-476c-a5c9-f1efa70a68b4",
                "isClaimed": false,
                "isPremium": false,
                "location": "Sunol, CA",
                "name": "Sunol Glen Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3ce775b9-7775-4383-8562-f4da43b60186",
                    "zipCode": "c683e79d-d4b8-4e7f-91eb-6dc9de40b44b"
                },
                "premium": false,
                "shortName": "Sunol Glen Elementary School",
                "tagline": [
                    "Public School",
                    "Sunol, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "sunol-glen-elementary-school-sunol-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 297
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Works Here",
                "body": "Academics will puxh your kids to new levels. Each teacher is different in their style but all are good in their unique way.  There is no obvious drama and eveyone their genuinely cares for each and every one of those kids.  The quint and warm vibe is supported by our parent community.   The worst part of the school are the spring time allergies.  Guaranteed every year.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-03-06T06:32:29.123749Z",
                "guid": "6ed1e6f0-5d21-4218-83bc-ebdab7b16a66",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.885,37.5926,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.885,37.5926,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94586"
            }
        ]
    },
    {
        "guid": "f075beb0-a10d-434c-a41e-0fcf41a61e1a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 17,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.422809,
                "lon": -122.15663
            },
            "entity": {
                "abbreviation": "Escondido Elementary School",
                "alternates": {
                    "nces": "062961004584"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "f075beb0-a10d-434c-a41e-0fcf41a61e1a",
                "isClaimed": true,
                "isPremium": false,
                "location": "Stanford, CA",
                "name": "Escondido Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6d60ab2e-fe21-448b-966b-a0e7a2ad07fb",
                    "zipCode": "ac2ecc25-49dd-47f1-a9d6-b82f9fdbe676"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Escondido Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "escondido-elementary-school-stanford-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 535
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.156,37.421,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.156,37.421,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94305"
            }
        ]
    },
    {
        "guid": "86e0fe7e-849e-473c-ad74-706f92ae6c8a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 18,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.302757,
                "lon": -121.973539
            },
            "entity": {
                "abbreviation": "Discovery Charter School",
                "alternates": {
                    "nces": "060159111792"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "86e0fe7e-849e-473c-ad74-706f92ae6c8a",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Discovery Charter School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "schoolNetwork": "11465407-60e3-4192-8083-e8f010b6f06f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "326a03e0-bc81-42e5-87ef-3cd92b018bc4"
                },
                "premium": false,
                "shortName": "Discovery Charter School",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "discovery-charter-school-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 566
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I got the benefit of going to this school because all my older brothers were already going there. I went to DCS all the way from kindergarten to eighth grade. There are so many things that this school offers which you will never find anywhere else. One of the things that might either seem like a pro or a con is that DCS is very reliant on parent participation. This participation often makes school a lot easier as a young child. Though if you worry that as a parent you wouldn't be able to participate whether it be due to a job or something, don't worry about it! DCS is very understanding and only encourages parent participation if possible. Another great thing about DCS is that they really focus on building the skills you will need to succeed late in life. They might not give you as much homework and so it might seem that it is easier, but realistically they are just trying to get away from the idea of working for the grade rather than the gaining of knowledge and understanding.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-28T05:31:04.87704Z",
                "guid": "3d4940b9-6bd2-4cc9-95c2-2881c01c8e62",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.303,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.303,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 12
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95117"
            }
        ]
    },
    {
        "guid": "bd123530-efdc-4bfa-b928-edd7cf473123",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 19,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.652147,
                "lon": -121.884221
            },
            "entity": {
                "abbreviation": "Phoebe Apperson Hearst Elementary School",
                "alternates": {
                    "nces": "060002008445"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "bd123530-efdc-4bfa-b928-edd7cf473123",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Phoebe Apperson Hearst Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Phoebe Apperson Hearst Elementary School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "phoebe-apperson-hearst-elementary-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 705
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Best elementary school. Teachers are kind and great place to learn. Teachers teach kids in fun and interesting way",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-08-28T20:14:06.121631Z",
                "guid": "5d66ffcc-04bc-4285-bfed-d0cc251e6341",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.884,37.6523,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.884,37.6523,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94566"
            }
        ]
    },
    {
        "guid": "8f6ece5b-5b71-44e4-9524-1bf8e7444140",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 20,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.68099,
                "lon": -121.921584
            },
            "entity": {
                "abbreviation": "Lydiksen Elementary School",
                "alternates": {
                    "nces": "060002009284"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "8f6ece5b-5b71-44e4-9524-1bf8e7444140",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Lydiksen Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "e44c5ce5-ac67-4add-ba71-3640f25c0ef0",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "4000a419-f29e-4c0c-b086-d53791b6f956"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lydiksen Elementary School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lydiksen-elementary-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 666
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.92,37.6804,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.92,37.6804,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94588"
            }
        ]
    },
    {
        "guid": "dc295091-7a80-4370-b6dc-309230ced6f0",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 21,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.655867,
                "lon": -121.855106
            },
            "entity": {
                "abbreviation": "Vintage Hills Elementary School",
                "alternates": {
                    "nces": "060002009290"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "dc295091-7a80-4370-b6dc-309230ced6f0",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Vintage Hills Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "caf441ef-50b4-4540-8694-f7566f68b9e6",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Vintage Hills Elementary School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "vintage-hills-elementary-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 629
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.856,37.6551,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.856,37.6551,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94566"
            }
        ]
    },
    {
        "guid": "78021215-0d0e-4ced-b119-fd4f7206bd21",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 22,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.691887,
                "lon": -121.873927
            },
            "entity": {
                "abbreviation": "Fairlands Elementary School",
                "alternates": {
                    "nces": "060002009286"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "78021215-0d0e-4ced-b119-fd4f7206bd21",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Fairlands Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "55dabaf5-c72a-4000-b234-2d0904c7179e",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "4000a419-f29e-4c0c-b086-d53791b6f956"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Fairlands Elementary School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "fairlands-elementary-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 767
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Works Here",
                "body": "The Fairlands Community and School are top notch.  Such a desirable place to work and learn. The administration does a wonderful job connecting staff and parents that benefits the students greatly.  The rigor in academics is impressive while they find many extra curricular events to build connections and community.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-27T22:58:47.085216Z",
                "guid": "64f72c67-fcc6-4d32-a2f5-d57983181613",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.874,37.6926,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.874,37.6926,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.333333333333333,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94588"
            }
        ]
    },
    {
        "guid": "61094a3c-d3d0-45d8-aa77-f8e176297d8a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 23,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.673903,
                "lon": -121.886306
            },
            "entity": {
                "abbreviation": "Walnut Grove Elementary School",
                "alternates": {
                    "nces": "060002009288"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "61094a3c-d3d0-45d8-aa77-f8e176297d8a",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Walnut Grove Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "1bae5f83-6724-4fb2-b9cd-88002e8e2add",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Walnut Grove Elementary School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "walnut-grove-elementary-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 749
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Great school! Went there for a few years. Awesome, life-changing teachers, friendly administrators, great activities for kids to do. All in all recommend.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-05-28T15:28:06.330879Z",
                "guid": "a89b5736-bd9c-4083-8b1e-0e000e3a9540",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.885,37.6732,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.885,37.6732,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94566"
            }
        ]
    },
    {
        "guid": "68a839fb-d04e-4301-ac88-8a7398a97f75",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 24,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.396738,
                "lon": -122.117957
            },
            "entity": {
                "abbreviation": "BCS",
                "alternates": {
                    "nces": "060241511442"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "68a839fb-d04e-4301-ac88-8a7398a97f75",
                "isClaimed": true,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Bullis Charter School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                    "zipCode": "518d850a-d6a6-4bdc-9dfb-0f338f1c7264"
                },
                "premium": false,
                "shortName": "Bullis Charter",
                "tagline": [
                    "Public School",
                    "Los Altos, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "bullis-charter-school-los-altos-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 915
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "BCS has very strong emphasis on both STEM and the arts which meant that I felt very well rounded when I left after 6th grade. They certainly were a little untraditional at times, especially with such a heavy arts presence (which I loved) and a focus on project based learning (\"PBL\"), but I think this lack of traditional learning was what made BCS so fun as an elementary student. I experienced a few iffy teachers, but the other teachers made up for it by far.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-01-11T05:23:57.266696Z",
                "guid": "7e3c2bda-d520-4633-b429-ec926c2f1812",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.116471,37.39581,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.116471,37.39581,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.9166666666666665,
                "count": 12
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94022"
            }
        ]
    },
    {
        "guid": "ac3bbf55-c178-46f2-96d6-e2a56e16b79c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 25,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.549371,
                "lon": -122.367514
            },
            "entity": {
                "abbreviation": "West Hillsborough",
                "alternates": {
                    "nces": "061719002175"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ac3bbf55-c178-46f2-96d6-e2a56e16b79c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Hillsborough, CA",
                "name": "West Hillsborough",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "dc25cc08-bb54-4104-88fe-58456acabf26",
                    "schoolDistrict": "dc25cc08-bb54-4104-88fe-58456acabf26",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "440fbb77-c65a-4641-8451-1689dfa5c0ae",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "HiIlsborough City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc25cc08-bb54-4104-88fe-58456acabf26",
                        "location": "Hillsborough, CA",
                        "name": "HiIlsborough City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "440fbb77-c65a-4641-8451-1689dfa5c0ae",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "HiIlsborough City School District",
                        "tagline": [
                            "School District",
                            "Hillsborough, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "hiilsborough-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "West Hillsborough",
                "tagline": [
                    "HiIlsborough City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "west-hillsborough-hillsborough-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 363
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I personally loved west. The teachers were amazing and truly paid attention to you. I know everyone else felt the same. West was such a close-knit and welcoming community, and i wouldn't trade my experience there.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-29T03:11:14.119126Z",
                "guid": "9786ac6f-ac19-448e-966c-dde211099fc7",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.367,37.55,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.367,37.55,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "7574f3a4-19a0-4c54-8ed7-69a4b950a2ae",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 26,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.825623,
                "lon": -122.232487
            },
            "entity": {
                "abbreviation": "Havens Elementary School",
                "alternates": {
                    "nces": "063033004730"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7574f3a4-19a0-4c54-8ed7-69a4b950a2ae",
                "isClaimed": false,
                "isPremium": false,
                "location": "Piedmont, CA",
                "name": "Havens Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                    "schoolDistrict": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "56749142-fea1-470f-b296-912a61cb1169",
                    "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "ffc793dc-426b-4df9-8bd2-7250f0309363",
                        "location": "Piedmont, CA",
                        "name": "Piedmont City Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "56749142-fea1-470f-b296-912a61cb1169",
                            "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                        },
                        "premium": false,
                        "shortName": "Piedmont City Unified School District",
                        "tagline": [
                            "School District",
                            "Piedmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "piedmont-city-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Havens Elementary School",
                "tagline": [
                    "Piedmont City Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "havens-elementary-school-piedmont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 439
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Havens is the best school I have ever seen. It has lots of nice kids, a big campus, and a balance of sports and academics.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-09-24T15:00:19.190534Z",
                "guid": "67439bcc-1e0b-4137-bc9f-33c701d77653",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.233,37.8254,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.233,37.8254,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94611"
            }
        ]
    },
    {
        "guid": "f7b59955-3169-4e2b-a615-dba2cd2b5135",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 27,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.671894,
                "lon": -121.872765
            },
            "entity": {
                "abbreviation": "Alisal Elementary School",
                "alternates": {
                    "nces": "060002009285"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "f7b59955-3169-4e2b-a615-dba2cd2b5135",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Alisal Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "1bae5f83-6724-4fb2-b9cd-88002e8e2add",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alisal Elementary School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "alisal-elementary-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 644
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.871,37.6722,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.871,37.6722,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94566"
            }
        ]
    },
    {
        "guid": "4363a195-d4f1-4f75-a494-c3a513ea1cd2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 28,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.684218,
                "lon": -121.906765
            },
            "entity": {
                "abbreviation": "Donlon Elementary School",
                "alternates": {
                    "nces": "060002009283"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "4363a195-d4f1-4f75-a494-c3a513ea1cd2",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Donlon Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "cc6229ba-c0f6-4723-bba6-019b823a6659",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "4000a419-f29e-4c0c-b086-d53791b6f956"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Donlon Elementary School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "donlon-elementary-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 758
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.909,37.6841,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.909,37.6841,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94588"
            }
        ]
    },
    {
        "guid": "0ce8c563-6bd9-47a7-b0af-eadadd0184fc",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 29,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.692346,
                "lon": -121.862829
            },
            "entity": {
                "abbreviation": "Henry P. Mohr Elementary School",
                "alternates": {
                    "nces": "060002006951"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "0ce8c563-6bd9-47a7-b0af-eadadd0184fc",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Henry P. Mohr Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "e8f7cb26-2505-4dfb-b3fc-e27c0c852005",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "4000a419-f29e-4c0c-b086-d53791b6f956"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Henry P. Mohr Elementary School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "henry-p-mohr-elementary-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 683
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.861,37.692,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.861,37.692,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94588"
            }
        ]
    },
    {
        "guid": "5177d15d-aa28-496f-83c0-b86738dc0620",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 30,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.962187,
                "lon": -122.556897
            },
            "entity": {
                "abbreviation": "Ross Elementary School",
                "alternates": {
                    "nces": "063366005192"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5177d15d-aa28-496f-83c0-b86738dc0620",
                "isClaimed": false,
                "isPremium": false,
                "location": "Ross, CA",
                "name": "Ross Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e7373d6f-098a-470c-b7c5-fea4c43fe457",
                    "zipCode": "af1937e2-b1ea-48c6-b5cc-ff1e59c2c43e"
                },
                "premium": false,
                "shortName": "Ross Elementary School",
                "tagline": [
                    "Public School",
                    "Ross, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "ross-elementary-school-ross-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 394
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 15
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.558,37.9613,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.558,37.9613,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94957"
            }
        ]
    },
    {
        "guid": "2a221158-0942-457c-ae02-8eaf5efd4b05",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 31,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.568812,
                "lon": -122.361174
            },
            "entity": {
                "abbreviation": "North Hillsborough",
                "alternates": {
                    "nces": "061719009853"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2a221158-0942-457c-ae02-8eaf5efd4b05",
                "isClaimed": false,
                "isPremium": false,
                "location": "Hillsborough, CA",
                "name": "North Hillsborough",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "dc25cc08-bb54-4104-88fe-58456acabf26",
                    "schoolDistrict": "dc25cc08-bb54-4104-88fe-58456acabf26",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "440fbb77-c65a-4641-8451-1689dfa5c0ae",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "HiIlsborough City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc25cc08-bb54-4104-88fe-58456acabf26",
                        "location": "Hillsborough, CA",
                        "name": "HiIlsborough City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "440fbb77-c65a-4641-8451-1689dfa5c0ae",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "HiIlsborough City School District",
                        "tagline": [
                            "School District",
                            "Hillsborough, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "hiilsborough-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "North Hillsborough",
                "tagline": [
                    "HiIlsborough City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "north-hillsborough-hillsborough-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 300
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "North was a great place for me, in both academics and sports. I always felt supported by my teachers, and it was a great fit for me. I felt challenged and if I needed help, my teachers were there for me. All in all, a wonderful school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-07-06T14:38:50.011484Z",
                "guid": "9ed77a0f-0226-4132-83a5-fe65a3fe722c",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.362,37.5682,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.362,37.5682,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "d6b3b0a2-054a-4483-ad9e-69bb7a3cce37",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 32,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.381843,
                "lon": -122.218007
            },
            "entity": {
                "abbreviation": "Ormondale Elementary School",
                "alternates": {
                    "nces": "063147004882"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d6b3b0a2-054a-4483-ad9e-69bb7a3cce37",
                "isClaimed": false,
                "isPremium": false,
                "location": "Portola Valley, CA",
                "name": "Ormondale Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "2a38bec5-e570-4b44-a915-cb47120424cd",
                    "schoolDistrict": "2a38bec5-e570-4b44-a915-cb47120424cd",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cb45ddc0-9411-42ef-a6cd-bce00ea8d969",
                    "zipCode": "4cc8743e-d0d7-450b-805a-b54edc5bdbf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Portola Valley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "2a38bec5-e570-4b44-a915-cb47120424cd",
                        "location": "Portola Valley, CA",
                        "name": "Portola Valley Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cb45ddc0-9411-42ef-a6cd-bce00ea8d969",
                            "zipCode": "4cc8743e-d0d7-450b-805a-b54edc5bdbf5"
                        },
                        "premium": false,
                        "shortName": "Portola Valley Elementary School District",
                        "tagline": [
                            "School District",
                            "Portola Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "portola-valley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ormondale Elementary School",
                "tagline": [
                    "Portola Valley Elementary School District, CA",
                    "K-3"
                ],
                "type": "School",
                "url": "ormondale-elementary-school-portola-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 266
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.218,37.3811,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.218,37.3811,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94028"
            }
        ]
    },
    {
        "guid": "39d4606a-5582-4d2c-a02d-b45676bc4e03",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 33,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.786881,
                "lon": -121.941133
            },
            "entity": {
                "abbreviation": "Golden View Elementary School",
                "alternates": {
                    "nces": "063513009631"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "39d4606a-5582-4d2c-a02d-b45676bc4e03",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Golden View Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "3c6606ca-3ee2-4c1b-abd7-7093cc8f9255",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "5efca495-caa8-45de-bcaf-bb99171be0a7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Golden View Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "golden-view-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 668
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.943,37.7864,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.943,37.7864,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "d58189e5-803e-4e09-87c9-466a549f08c6",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 34,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.807797,
                "lon": -121.984355
            },
            "entity": {
                "abbreviation": "John Baldwin Elementary School",
                "alternates": {
                    "nces": "063513005949"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d58189e5-803e-4e09-87c9-466a549f08c6",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "John Baldwin Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                    "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John Baldwin Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "john-baldwin-elementary-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 515
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.8077,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.8077,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94526"
            }
        ]
    },
    {
        "guid": "069bfcc0-e80e-4ade-b273-d1bddf109b0b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 35,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.562968,
                "lon": -122.337418
            },
            "entity": {
                "abbreviation": "South Hillsborough",
                "alternates": {
                    "nces": "061719002174"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "069bfcc0-e80e-4ade-b273-d1bddf109b0b",
                "isClaimed": false,
                "isPremium": false,
                "location": "Hillsborough, CA",
                "name": "South Hillsborough",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "dc25cc08-bb54-4104-88fe-58456acabf26",
                    "schoolDistrict": "dc25cc08-bb54-4104-88fe-58456acabf26",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "440fbb77-c65a-4641-8451-1689dfa5c0ae",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "HiIlsborough City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc25cc08-bb54-4104-88fe-58456acabf26",
                        "location": "Hillsborough, CA",
                        "name": "HiIlsborough City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "440fbb77-c65a-4641-8451-1689dfa5c0ae",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "HiIlsborough City School District",
                        "tagline": [
                            "School District",
                            "Hillsborough, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "hiilsborough-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "South Hillsborough",
                "tagline": [
                    "HiIlsborough City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "south-hillsborough-hillsborough-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 223
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Good school. \n\nThe principal is awful. She doesn't care about the kids. Yes, really. \n\nHopefully she will leave soon and then the schools ratings will increase.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-03-25T08:16:16.88905Z",
                "guid": "76e74edf-8ee0-46c8-916e-5cfb3ed338a8",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.338,37.5618,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.338,37.5618,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "3e668792-aa55-4d43-94c3-545817b95636",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 36,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.712355,
                "lon": -121.863671
            },
            "entity": {
                "abbreviation": "Harold William Kolb",
                "alternates": {
                    "nces": "060001912927"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "3e668792-aa55-4d43-94c3-545817b95636",
                "isClaimed": true,
                "isPremium": false,
                "location": "Dublin, CA",
                "name": "Harold William Kolb",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "schoolDistrict": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                    "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "DUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                        "location": "Dublin, CA",
                        "name": "Dublin Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                            "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                        },
                        "premium": false,
                        "shortName": "Dublin Unified School District",
                        "tagline": [
                            "School District",
                            "Dublin, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dublin-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Harold William Kolb",
                "tagline": [
                    "Dublin Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "harold-william-kolb-dublin-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 735
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "I am honored to be the first parent to review this school! We transferred to the school two years ago from a great school in Palo Alto. This school holds students to the highest standards. One of our  sons was considered 'advanced' at his previous school and struggled when we first transferred to this school. He's moved onto middle school and not only has caught up but is advanced again. Kolb has a great community of parents - diverse and invested parents. The classes are getting larger but the teachers seem to handle it well (even for my second grade daughter who does well in smaller classes but still thrives at Kolb). I think this group of kids, those passing through now, are going to be leaders in our world. The school just has something about. No drama, just a straight forward learning environment, supported by great teachers and parents who keep it running well.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-09-15T02:49:25.223734Z",
                "guid": "28b66bfc-7936-4c18-a408-c920e4196839",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.86,37.7125,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.86,37.7125,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.666666666666667,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94568"
            }
        ]
    },
    {
        "guid": "94dfa192-d1d9-4d28-bbe5-2d793bd24a12",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 37,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.769075,
                "lon": -121.931042
            },
            "entity": {
                "abbreviation": "Coyote Creek Elementary School",
                "alternates": {
                    "nces": "063513008461"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "94dfa192-d1d9-4d28-bbe5-2d793bd24a12",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Coyote Creek Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "5efca495-caa8-45de-bcaf-bb99171be0a7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Coyote Creek Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "coyote-creek-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 920
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.927,37.7776,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.927,37.7776,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "58cbdb0c-7e6e-45e3-b2b6-a8b08d9213bb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 38,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.248156,
                "lon": -122.049822
            },
            "entity": {
                "abbreviation": "Creekside Elementary School",
                "alternates": {
                    "nces": "063513012242"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "58cbdb0c-7e6e-45e3-b2b6-a8b08d9213bb",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Creekside Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7f03f1ed-f752-43e6-b5aa-a09ae097dd25",
                    "zipCode": "cf08ff54-6c10-49c2-a964-4e1c94d0ce7b"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Creekside Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "creekside-elementary-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 638
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.882,37.7927,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.882,37.7927,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94506"
            }
        ]
    },
    {
        "guid": "ba5c7d4b-06b3-4040-9b33-277456b2adbc",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 39,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.7617201,
                "lon": -122.4006227
            },
            "entity": {
                "abbreviation": "The New School of San Francisco",
                "alternates": {
                    "nces": "060243913939"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "ba5c7d4b-06b3-4040-9b33-277456b2adbc",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "The New School of San Francisco",
                "parentGUIDs": {
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0e7feade-1234-4a78-b9a6-f0a08b901e7c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "781604ff-cae4-4ff7-8bc5-30889e25ac1f"
                },
                "premium": false,
                "shortName": "The New School of San Francisco",
                "tagline": [
                    "Public School",
                    "San Francisco, CA",
                    "K-4"
                ],
                "type": "School",
                "url": "the-new-school-of-san-francisco-san-francisco-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 235
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 13
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Amazing curriculum, teachers, parent involvement, communication, and culture. I haven’t seen anything like it at other schools, public or private.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-08-06T16:20:31.057739Z",
                "guid": "41f32fe1-cc39-4683-89ad-9b80a4835b48",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4006227,37.7617201,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4006227,37.7617201,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94107"
            }
        ]
    },
    {
        "guid": "8d0e175d-29b0-4ec1-8e10-0cdb3389a185",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 40,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.88856,
                "lon": -122.288284
            },
            "entity": {
                "abbreviation": "Marin Elementary School",
                "alternates": {
                    "nces": "060186000062"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8d0e175d-29b0-4ec1-8e10-0cdb3389a185",
                "isClaimed": false,
                "isPremium": false,
                "location": "Albany, CA",
                "name": "Marin Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                    "schoolDistrict": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "f3a34422-94be-42dd-b22b-9ec647ebed62",
                    "zipCode": "b0469f0b-b037-4ab0-9a8e-d888278d5110"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Albany City Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                        "location": "Berkeley, CA",
                        "name": "Albany City Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "39b2df86-684a-467f-9c95-5540b32110f5"
                        },
                        "premium": false,
                        "shortName": "Albany City Unified School District",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "albany-city-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Marin Elementary School",
                "tagline": [
                    "Albany City Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "marin-elementary-school-albany-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 504
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "i love all the teachers there and all of the aids makes you feel like your welcome  and have you enjoy your 6 years there its perfect for all student [even with ADHD]",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-06-25T21:39:07.682768Z",
                "guid": "bc554d57-137a-4ac3-8fd2-960d7d924475",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.289,37.8886,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.289,37.8886,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94706"
            }
        ]
    },
    {
        "guid": "a5ceea61-c18c-4409-94e3-92f420d1c846",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 41,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.356692,
                "lon": -122.071121
            },
            "entity": {
                "abbreviation": "Oak Avenue Elementary School",
                "alternates": {
                    "nces": "062265002788"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a5ceea61-c18c-4409-94e3-92f420d1c846",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Oak Avenue Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "schoolDistrict": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                    "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Altos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                        "location": "Los Altos, CA",
                        "name": "Los Altos Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                            "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                        },
                        "premium": false,
                        "shortName": "Los Altos Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Altos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-altos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Oak Avenue Elementary School",
                "tagline": [
                    "Los Altos Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "oak-avenue-elementary-school-los-altos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 387
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.072,37.358,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.072,37.358,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94024"
            }
        ]
    },
    {
        "guid": "00d371c1-15ff-4d5b-a9d3-5548cb8d3a3f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 42,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.750779,
                "lon": -121.899926
            },
            "entity": {
                "abbreviation": "Live Oak Elementary School",
                "alternates": {
                    "nces": "063513011880"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "00d371c1-15ff-4d5b-a9d3-5548cb8d3a3f",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Live Oak Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "5efca495-caa8-45de-bcaf-bb99171be0a7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Live Oak Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "live-oak-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 819
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Grade levels: TK-5\nEstablished: 2007\nEnrollment: 923\n\n5151 Sherwood Way\nSan Ramon, CA 94582\n\n803-3100 (phone)\n803-3Grade levels: TK-5\nEstablished: 2007\nEnrollment: 923\n\n5151 Sherwood Way\nSan Ramon, CA 94582\n\n803-3100 (phone)\n803-3197 (fax)\nSITE INFORMATION\nLive Oak Elementary School opened fall of 2007 as the newest San Ramon Valley Elementary School. It is located in the Dougherty Valley community of east San Ramon.\n5151",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-07-13T23:01:49.643887Z",
                "guid": "9026b1a5-632a-4815-8408-4a9a68f46cff",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.894,37.7538,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.894,37.7538,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "90a81abb-137b-4dd5-8ff0-643a512592e9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 43,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.436911,
                "lon": -122.212115
            },
            "entity": {
                "abbreviation": "Las Lomitas Elementary School",
                "alternates": {
                    "nces": "062238002675"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "90a81abb-137b-4dd5-8ff0-643a512592e9",
                "isClaimed": false,
                "isPremium": false,
                "location": "Atherton, CA",
                "name": "Las Lomitas Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "7b0de5ef-e84c-42d7-b2b1-c1fe3f6e8861",
                    "schoolDistrict": "7b0de5ef-e84c-42d7-b2b1-c1fe3f6e8861",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "b1ffcba5-34ff-41c4-8dee-13d6f1b49d01",
                    "zipCode": "190d1411-e5a3-4f6d-a604-70b9e1fe71a3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Las Lomitas Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7b0de5ef-e84c-42d7-b2b1-c1fe3f6e8861",
                        "location": "Menlo Park, CA",
                        "name": "Las Lomitas Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "22bb5284-8004-4b40-8e2f-7523a5c756cf",
                            "zipCode": "190d1411-e5a3-4f6d-a604-70b9e1fe71a3"
                        },
                        "premium": false,
                        "shortName": "Las Lomitas Elementary School District",
                        "tagline": [
                            "School District",
                            "Menlo Park, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "las-lomitas-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Las Lomitas Elementary School",
                "tagline": [
                    "Las Lomitas Elementary School District, CA",
                    "K-3"
                ],
                "type": "School",
                "url": "las-lomitas-elementary-school-atherton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 501
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.212,37.438,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.212,37.438,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94027"
            }
        ]
    },
    {
        "guid": "6d252787-0476-4506-acb7-31158816fa89",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 44,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.7551729,
                "lon": -122.4087206
            },
            "entity": {
                "abbreviation": "Mission Preparatory",
                "alternates": {
                    "nces": "060223612768"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "6d252787-0476-4506-acb7-31158816fa89",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Mission Preparatory",
                "parentGUIDs": {
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0cac67cc-9c02-4a4c-8cd6-5714b7fda8b7",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "c568e3a2-4c27-4503-a9f7-85276a6ec461"
                },
                "premium": false,
                "shortName": "Mission Preparatory",
                "tagline": [
                    "Public School",
                    "San Francisco, CA",
                    "PK, K-8"
                ],
                "type": "School",
                "url": "mission-preparatory-san-francisco-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 385
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I'm a current student in Mission Preparatory and it's great. Since it's a new school, I think it should have more clubs and sports, but academics are great. Teachers and staff are very supportive and encouraging.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-22T20:13:49.288019Z",
                "guid": "8e14ea06-bd76-4724-a975-02fc4b09c0b9",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4087206,37.7551729,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4087206,37.7551729,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94112"
            }
        ]
    },
    {
        "guid": "f20c83df-f531-43ec-a4f9-90f216c25412",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 45,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.740646,
                "lon": -121.94842
            },
            "entity": {
                "abbreviation": "Neil A. Armstrong Elementary School",
                "alternates": {
                    "nces": "063513005954"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f20c83df-f531-43ec-a4f9-90f216c25412",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Neil A. Armstrong Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "54c77220-1c02-48f7-a584-28d7571918f4",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "d01e8b5d-d2aa-452e-a5e1-1f9fac6d6870"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Neil A. Armstrong Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "neil-a-armstrong-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 544
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.948,37.7411,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.948,37.7411,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94583"
            }
        ]
    },
    {
        "guid": "5dbb81e7-e590-47a2-a48b-fe5032305dac",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 46,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.792317,
                "lon": -121.974169
            },
            "entity": {
                "abbreviation": "Greenbrook Elementary School",
                "alternates": {
                    "nces": "063513005948"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5dbb81e7-e590-47a2-a48b-fe5032305dac",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Greenbrook Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                    "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Greenbrook Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "greenbrook-elementary-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 630
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.975,37.7913,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.975,37.7913,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94526"
            }
        ]
    },
    {
        "guid": "82053851-1fd4-48c5-b893-fe31ec2b346a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 47,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.761669,
                "lon": -121.970573
            },
            "entity": {
                "abbreviation": "Bollinger Canyon Elementary School",
                "alternates": {
                    "nces": "063513007356"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "82053851-1fd4-48c5-b893-fe31ec2b346a",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Bollinger Canyon Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0510ef45-ed63-4629-8254-ca0dd9dda9ee",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "d01e8b5d-d2aa-452e-a5e1-1f9fac6d6870"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Bollinger Canyon Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "bollinger-canyon-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 518
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We have lived in some of the areas that have been noted has the top 3 school districts in the country.  To my wife Bollinger Canyon Elementary School ranks above them all.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-08-11T19:48:57.001796Z",
                "guid": "6e385afa-d396-4532-a356-23172546ae94",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.971,37.7612,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.971,37.7612,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94583"
            }
        ]
    },
    {
        "guid": "0aa5c306-f261-44aa-9500-a369cc47d361",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 48,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.244727,
                "lon": -122.007128
            },
            "entity": {
                "abbreviation": "Bella Vista Elementary School",
                "alternates": {
                    "nces": "063513013980"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0aa5c306-f261-44aa-9500-a369cc47d361",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Bella Vista Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "888f8d10-1840-4fe0-bfd5-a02c72b57731",
                    "zipCode": "8071d52d-fe81-44cd-a2cf-6acfb351f358"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Bella Vista Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "bella-vista-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 493
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {},
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "4ad790eb-ab4e-4e38-8510-a62f74b7a9a9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 49,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.7184085,
                "lon": -121.8624431
            },
            "entity": {
                "abbreviation": "John Green Elementary School",
                "alternates": {
                    "nces": "060001912078"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "4ad790eb-ab4e-4e38-8510-a62f74b7a9a9",
                "isClaimed": true,
                "isPremium": false,
                "location": "Dublin, CA",
                "name": "John Green Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "schoolDistrict": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                    "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "DUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                        "location": "Dublin, CA",
                        "name": "Dublin Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                            "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                        },
                        "premium": false,
                        "shortName": "Dublin Unified School District",
                        "tagline": [
                            "School District",
                            "Dublin, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dublin-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John Green Elementary School",
                "tagline": [
                    "Dublin Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "john-green-elementary-school-dublin-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 859
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8624431,37.7184085,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8624431,37.7184085,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94568"
            }
        ]
    },
    {
        "guid": "d5aa9d13-cc79-44a7-a6dc-449abdfdfb7c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 50,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.806355,
                "lon": -121.944959
            },
            "entity": {
                "abbreviation": "Sycamore Valley Elementary School",
                "alternates": {
                    "nces": "063513000289"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d5aa9d13-cc79-44a7-a6dc-449abdfdfb7c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Sycamore Valley Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                    "zipCode": "3e1ec33e-a148-4e8a-9b40-fb03abc83c61"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sycamore Valley Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sycamore-valley-elementary-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 573
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I loved Sycamore! The campus is great, kid friendly, clean, and a wonderful place to study. The teachers here care about their students and provide interactive activities as projects. The school is run well, and the staff are warm and hardworking. Sycamore is home, and I think everyone, from parents to staff to students will feel welcome here and receive a fantastic education.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-11-16T16:34:45.367611Z",
                "guid": "cd505550-40ce-46cb-bc91-ed4145c1f956",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.943,37.8071,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.943,37.8071,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94506"
            }
        ]
    },
    {
        "guid": "8bffaf17-3350-4140-9135-d08aa8fefc78",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 51,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.819361,
                "lon": -122.003172
            },
            "entity": {
                "abbreviation": "Montair Elementary School",
                "alternates": {
                    "nces": "063513005951"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8bffaf17-3350-4140-9135-d08aa8fefc78",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Montair Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                    "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Montair Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "montair-elementary-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 556
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "This school is amazing! Nice teachers, friendly students, and great facilities. Kids get to go on many educational and fun field trips. The campus is safe and the school has a nice playground and a big field. I recommend sending your child here.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-16T02:41:55.85567Z",
                "guid": "45a816c5-2f46-4904-a84a-8d91dd154070",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.004,37.8194,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.004,37.8194,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94526"
            }
        ]
    },
    {
        "guid": "5445889b-4eca-4b95-9409-fd4865be20fa",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 52,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.976329,
                "lon": -121.769233
            },
            "entity": {
                "abbreviation": "Quail Run Elementary School",
                "alternates": {
                    "nces": "063513010760"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5445889b-4eca-4b95-9409-fd4865be20fa",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Quail Run Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "a67a738f-0765-4797-b0e3-4c395ccd760b"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Quail Run Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "quail-run-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 949
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Junior",
                "body": "I think students would like to learn here beacause you can make lots of friends and at the same time learn new things every day. Take it from me, your kids will have a great time learning here",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-03-27T18:12:36.220821Z",
                "guid": "db251af9-da79-498c-be67-66ea4681f008",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.914,37.7624,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.914,37.7624,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "b3aadc66-7728-4b74-9251-1a8f92d7f0a1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 53,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.413009,
                "lon": -122.13602
            },
            "entity": {
                "abbreviation": "Barron Park Elementary School",
                "alternates": {
                    "nces": "062961007802"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "b3aadc66-7728-4b74-9251-1a8f92d7f0a1",
                "isClaimed": true,
                "isPremium": false,
                "location": "Palo Alto, CA",
                "name": "Barron Park Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "84d3d988-b95a-494b-9118-bbd4b7a119e5",
                    "parent": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "schoolDistrict": "5ebd9221-9432-4680-b498-f32d7ec76533",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                    "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PAUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5ebd9221-9432-4680-b498-f32d7ec76533",
                        "location": "Palo Alto, CA",
                        "name": "Palo Alto Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1b26fe96-6bbf-45ea-b1bf-9c682db0b352",
                            "zipCode": "c46624af-208c-421d-8b97-5338480b17d3"
                        },
                        "premium": false,
                        "shortName": "Palo Alto Unified School District",
                        "tagline": [
                            "School District",
                            "Palo Alto, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "palo-alto-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Barron Park Elementary School",
                "tagline": [
                    "Palo Alto Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "barron-park-elementary-school-palo-alto-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 244
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 15
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We've had a great experience at Barron Park, grades weren't a problem and there are lots of different experiences, for example, The Maker's Studio, it's a great way to teach things in a creative way. My daughter really likes coding and earning badges there. Two great teacher experiences with Ms. Lorber and Nick Foote.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-05-21T23:20:33.501941Z",
                "guid": "624b6bc4-532f-4eab-8658-d5bb11ed9b1a",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.137,37.4131,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.137,37.4131,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94306"
            }
        ]
    },
    {
        "guid": "78bdcb84-c4f4-4875-8937-13cf16e4a4b4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 54,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.370731,
                "lon": -122.108044
            },
            "entity": {
                "abbreviation": "Covington Elementary School",
                "alternates": {
                    "nces": "062265011202"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "78bdcb84-c4f4-4875-8937-13cf16e4a4b4",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Covington Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "schoolDistrict": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                    "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Altos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                        "location": "Los Altos, CA",
                        "name": "Los Altos Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                            "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                        },
                        "premium": false,
                        "shortName": "Los Altos Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Altos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-altos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Covington Elementary School",
                "tagline": [
                    "Los Altos Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "covington-elementary-school-los-altos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 585
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Covington provides an excellent education that meets the needs of each individual student. The staff create a positive and challenging learning environment.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-08-19T14:00:19.602656Z",
                "guid": "75990f4e-71b1-4cc7-b70e-1f5efd2331fa",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.108,37.3721,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.108,37.3721,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94024"
            }
        ]
    },
    {
        "guid": "a0820c57-18bb-4537-a1f7-3de638c1e3fd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 55,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.889861,
                "lon": -122.295312
            },
            "entity": {
                "abbreviation": "Cornell Elementary School",
                "alternates": {
                    "nces": "060186000061"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a0820c57-18bb-4537-a1f7-3de638c1e3fd",
                "isClaimed": false,
                "isPremium": false,
                "location": "Albany, CA",
                "name": "Cornell Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                    "schoolDistrict": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "f3a34422-94be-42dd-b22b-9ec647ebed62",
                    "zipCode": "b0469f0b-b037-4ab0-9a8e-d888278d5110"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Albany City Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                        "location": "Berkeley, CA",
                        "name": "Albany City Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "39b2df86-684a-467f-9c95-5540b32110f5"
                        },
                        "premium": false,
                        "shortName": "Albany City Unified School District",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "albany-city-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Cornell Elementary School",
                "tagline": [
                    "Albany City Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cornell-elementary-school-albany-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 561
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.296,37.8896,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.296,37.8896,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94706"
            }
        ]
    },
    {
        "guid": "e88f83ec-ce5c-4e08-9a72-9d778b8c518a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 56,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.361515,
                "lon": -122.093242
            },
            "entity": {
                "abbreviation": "Loyola Elementary School",
                "alternates": {
                    "nces": "062265002787"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e88f83ec-ce5c-4e08-9a72-9d778b8c518a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Loyola Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "schoolDistrict": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                    "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Altos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                        "location": "Los Altos, CA",
                        "name": "Los Altos Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                            "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                        },
                        "premium": false,
                        "shortName": "Los Altos Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Altos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-altos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Loyola Elementary School",
                "tagline": [
                    "Los Altos Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "loyola-elementary-school-los-altos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 404
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.093,37.3605,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.093,37.3605,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94024"
            }
        ]
    },
    {
        "guid": "182e414e-7c9a-4167-8661-940dea2b3f7b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 57,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.394619,
                "lon": -122.12129
            },
            "entity": {
                "abbreviation": "Santa Rita Elementary School",
                "alternates": {
                    "nces": "062265002789"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "182e414e-7c9a-4167-8661-940dea2b3f7b",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Santa Rita Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "schoolDistrict": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                    "zipCode": "518d850a-d6a6-4bdc-9dfb-0f338f1c7264"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Altos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                        "location": "Los Altos, CA",
                        "name": "Los Altos Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                            "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                        },
                        "premium": false,
                        "shortName": "Los Altos Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Altos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-altos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Santa Rita Elementary School",
                "tagline": [
                    "Los Altos Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "santa-rita-elementary-school-los-altos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 524
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.123,37.3939,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.123,37.3939,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94022"
            }
        ]
    },
    {
        "guid": "afb6f9da-7a3e-420a-9367-1ab5ee41ca92",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 58,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.796979,
                "lon": -121.868831
            },
            "entity": {
                "abbreviation": "Tassajara Hills Elementary School",
                "alternates": {
                    "nces": "063513007082"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "afb6f9da-7a3e-420a-9367-1ab5ee41ca92",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Tassajara Hills Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "3e1ec33e-a148-4e8a-9b40-fb03abc83c61"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Tassajara Hills Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "tassajara-hills-elementary-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 492
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.879,37.8005,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.879,37.8005,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94506"
            }
        ]
    },
    {
        "guid": "9883c297-428c-4ce8-8beb-0fb0ab088f6d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 59,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.712555,
                "lon": -121.934507
            },
            "entity": {
                "abbreviation": "J. M. Amador Elementary School",
                "alternates": {
                    "nces": "060001913862"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "9883c297-428c-4ce8-8beb-0fb0ab088f6d",
                "isClaimed": true,
                "isPremium": false,
                "location": "Dublin, CA",
                "name": "J.M. Amador Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "schoolDistrict": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                    "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "DUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                        "location": "Dublin, CA",
                        "name": "Dublin Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                            "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                        },
                        "premium": false,
                        "shortName": "Dublin Unified School District",
                        "tagline": [
                            "School District",
                            "Dublin, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dublin-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "J. M. Amador Elementary School",
                "tagline": [
                    "Dublin Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "jm-amador-elementary-school-dublin-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 839
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "The Best School in Dublin. In fact it is one of the newest in the District. The Teachers give good lessons and make them ready for the next grade. \nShoutouts to:\n@Benge\n@Proctor\n@Jawed",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-25T03:22:47.920614Z",
                "guid": "5ce954b9-7b54-4718-917d-999c8fd257a2",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.844,37.7187,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.844,37.7187,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94568"
            }
        ]
    },
    {
        "guid": "112beb86-52d6-40c0-acae-8c7816571bcf",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 60,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.710873,
                "lon": -121.885182
            },
            "entity": {
                "abbreviation": "James Dougherty Elementary School",
                "alternates": {
                    "nces": "060001908443"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "112beb86-52d6-40c0-acae-8c7816571bcf",
                "isClaimed": true,
                "isPremium": false,
                "location": "Dublin, CA",
                "name": "James Dougherty Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "schoolDistrict": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                    "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "DUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                        "location": "Dublin, CA",
                        "name": "Dublin Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                            "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                        },
                        "premium": false,
                        "shortName": "Dublin Unified School District",
                        "tagline": [
                            "School District",
                            "Dublin, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dublin-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "James Dougherty Elementary School",
                "tagline": [
                    "Dublin Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "james-dougherty-elementary-school-dublin-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 890
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.884,37.7099,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.884,37.7099,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94568"
            }
        ]
    },
    {
        "guid": "767f8898-396d-4780-98e4-890bf66432e3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 61,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.866289,
                "lon": -122.030831
            },
            "entity": {
                "abbreviation": "Alamo Elementary School",
                "alternates": {
                    "nces": "063513005942"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "767f8898-396d-4780-98e4-890bf66432e3",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alamo, CA",
                "name": "Alamo Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6cd2db24-61d9-4c4b-9dba-1b8592c736a6",
                    "zipCode": "70fa1630-e849-423c-9be9-dcec4af8d465"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alamo Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "alamo-elementary-school-alamo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 359
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.03,37.8672,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.03,37.8672,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94507"
            }
        ]
    },
    {
        "guid": "40e78a49-f8d1-4e38-8bc0-edd692a4fe93",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 62,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.507837,
                "lon": -122.285014
            },
            "entity": {
                "abbreviation": "San Carlos Charter Learning Center",
                "alternates": {
                    "nces": "060184204029"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "40e78a49-f8d1-4e38-8bc0-edd692a4fe93",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Carlos, CA",
                "name": "San Carlos Charter Learning Center",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "premium": false,
                "shortName": "San Carlos Charter Learning Center",
                "tagline": [
                    "Public School",
                    "San Carlos, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "san-carlos-charter-learning-center-san-carlos-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 385
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Charter is a very good school with lots of group projects and involvement.  They have lots of special activities and programs for children with learning difficulties. Parents are super involved and teachers are committed and ready to provide 1:1 guidance.  For children who need advanced studies, the teachers will provide differential learning. The students are super friendly and ready to learn. There is a lot of focus on social emotional learning and teamwork.  The  administration is kind and helpful.  The school applies Project Based Learning  and hand-on activities. Students get lots of choice in what they do.\n\nMy one problem though, is that in the new campus made of shipping containers, whenever there is motion on the top floor,  it makes a really loud sound on the bottom floor. This becomes a real difficulty for students k-2 and in the  library. But  they  are  working it out in different ways including doing a fun art project with egg cartons.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-09-30T02:36:52.536777Z",
                "guid": "f45a6bfe-6b14-496e-93d4-de754867fb85",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.286,37.5089,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.286,37.5089,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94070"
            }
        ]
    },
    {
        "guid": "2b5ee18b-4792-458c-ae95-2f00a8f1e656",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 63,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.857648,
                "lon": -122.133008
            },
            "entity": {
                "abbreviation": "Donald L. Rheem Elementary School",
                "alternates": {
                    "nces": "062574003850"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2b5ee18b-4792-458c-ae95-2f00a8f1e656",
                "isClaimed": false,
                "isPremium": false,
                "location": "Moraga, CA",
                "name": "Donald L. Rheem Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "9992c13f-d382-4666-b608-06b81ea52d9e",
                    "schoolDistrict": "9992c13f-d382-4666-b608-06b81ea52d9e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5352c423-bafe-4a84-a5f2-d30a97406aed",
                    "zipCode": "162d00a8-92bc-4205-9037-3a00dcac6d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moraga Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "9992c13f-d382-4666-b608-06b81ea52d9e",
                        "location": "Moraga, CA",
                        "name": "Moraga Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5352c423-bafe-4a84-a5f2-d30a97406aed",
                            "zipCode": "162d00a8-92bc-4205-9037-3a00dcac6d50"
                        },
                        "premium": false,
                        "shortName": "Moraga Elementary School District",
                        "tagline": [
                            "School District",
                            "Moraga, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moraga-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Donald L. Rheem Elementary School",
                "tagline": [
                    "Moraga Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "donald-l-rheem-elementary-school-moraga-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 410
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.133,37.8581,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.133,37.8581,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94556"
            }
        ]
    },
    {
        "guid": "60b47db9-8f79-4d03-9ff2-02c27523f40b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 64,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.384999,
                "lon": -122.101468
            },
            "entity": {
                "abbreviation": "Almond Elementary School",
                "alternates": {
                    "nces": "062265002782"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "60b47db9-8f79-4d03-9ff2-02c27523f40b",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Almond Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "schoolDistrict": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                    "zipCode": "518d850a-d6a6-4bdc-9dfb-0f338f1c7264"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Altos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                        "location": "Los Altos, CA",
                        "name": "Los Altos Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                            "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                        },
                        "premium": false,
                        "shortName": "Los Altos Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Altos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-altos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Almond Elementary School",
                "tagline": [
                    "Los Altos Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "almond-elementary-school-los-altos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 488
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.101,37.3832,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.101,37.3832,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94022"
            }
        ]
    },
    {
        "guid": "6ac634c3-ef89-4501-a8ea-972022ebfaf3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 65,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.663764,
                "lon": -122.425638
            },
            "entity": {
                "abbreviation": "Montclaire Elementary School",
                "alternates": {
                    "nces": "061029001143"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6ac634c3-ef89-4501-a8ea-972022ebfaf3",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Altos, CA",
                "name": "Montclaire Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                    "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Montclaire Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "montclaire-elementary-school-los-altos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 428
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.083,37.3431,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.083,37.3431,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94024"
            }
        ]
    },
    {
        "guid": "0b15e148-ac7f-419f-8910-a347e6f4970d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 66,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.746248,
                "lon": -122.23861
            },
            "entity": {
                "abbreviation": "Amelia Earhart Elementary School",
                "alternates": {
                    "nces": "060177000043"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0b15e148-ac7f-419f-8910-a347e6f4970d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Amelia Earhart Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "7195f3ef-44f9-4ce1-8865-b2ce025097c8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Amelia Earhart Elementary School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "amelia-earhart-elementary-school-alameda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 651
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.238,37.7445,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.238,37.7445,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94502"
            }
        ]
    },
    {
        "guid": "8c10ba5a-c73f-4765-94d7-56aada64b891",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 67,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.727925,
                "lon": -121.940506
            },
            "entity": {
                "abbreviation": "Country Club Elementary School",
                "alternates": {
                    "nces": "063513005945"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8c10ba5a-c73f-4765-94d7-56aada64b891",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Country Club Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "54c77220-1c02-48f7-a584-28d7571918f4",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "d01e8b5d-d2aa-452e-a5e1-1f9fac6d6870"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Country Club Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "country-club-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 552
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Amazing helpful teachers and a lot of activities. Everyone gets along. The thing I want to change is how they changed how many swings there are by 6 to 3.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-04-04T04:06:29.925183Z",
                "guid": "82c04f36-08e6-4ecc-8b2e-380da89b7f8e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.94,37.7285,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.94,37.7285,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94583"
            }
        ]
    },
    {
        "guid": "26d839df-5c4b-49d0-bd17-17e35e3cdb17",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 68,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.068041,
                "lon": -122.703112
            },
            "entity": {
                "abbreviation": "Nicasio",
                "alternates": {
                    "nces": "062730004135"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "26d839df-5c4b-49d0-bd17-17e35e3cdb17",
                "isClaimed": false,
                "isPremium": false,
                "location": "Nicasio, CA",
                "name": "Nicasio",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "8ca68bc1-015b-4525-9626-5f2e2f9421f3"
                },
                "premium": false,
                "shortName": "Nicasio",
                "tagline": [
                    "Public School",
                    "Nicasio, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "nicasio-nicasio-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 39
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 13
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.704,38.0684,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.704,38.0684,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94946"
            }
        ]
    },
    {
        "guid": "42880dc2-bce3-4b0c-abf9-af03fee988f0",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 69,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.370855,
                "lon": -122.089789
            },
            "entity": {
                "abbreviation": "Springer Elementary School",
                "alternates": {
                    "nces": "062265002790"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "42880dc2-bce3-4b0c-abf9-af03fee988f0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Springer Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "schoolDistrict": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "e8a55ab4-9bff-4942-821c-369c0c98966c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Altos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                        "location": "Los Altos, CA",
                        "name": "Los Altos Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                            "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                        },
                        "premium": false,
                        "shortName": "Los Altos Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Altos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-altos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Springer Elementary School",
                "tagline": [
                    "Los Altos Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "springer-elementary-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 468
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.094,37.372,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.094,37.372,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94040"
            }
        ]
    },
    {
        "guid": "b19217b3-134d-44b3-a5cd-f75f34bef12a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 70,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.878917,
                "lon": -122.285333
            },
            "entity": {
                "abbreviation": "Jefferson Elementary School",
                "alternates": {
                    "nces": "060474000441"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b19217b3-134d-44b3-a5cd-f75f34bef12a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Jefferson Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5a88f45f-a437-4833-9c01-60bde5403d41",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Jefferson Elementary School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "jefferson-elementary-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 401
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.285,37.8789,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.285,37.8789,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94702"
            }
        ]
    },
    {
        "guid": "54885df1-33e9-4e41-877f-564783beda23",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 71,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.432491,
                "lon": -122.195287
            },
            "entity": {
                "abbreviation": "Oak Knoll Elementary School",
                "alternates": {
                    "nces": "062457003685"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "54885df1-33e9-4e41-877f-564783beda23",
                "isClaimed": true,
                "isPremium": false,
                "location": "Menlo Park, CA",
                "name": "Oak Knoll Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                    "schoolDistrict": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "22bb5284-8004-4b40-8e2f-7523a5c756cf",
                    "zipCode": "190d1411-e5a3-4f6d-a604-70b9e1fe71a3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Menlo Park City Elementary School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                        "location": "Atherton, CA",
                        "name": "Menlo Park City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4443e3e1-84f5-48f0-a699-fe081d5e632a",
                            "zipCode": "76bdf085-4e2e-442f-87dd-d98dbedc0d18"
                        },
                        "premium": false,
                        "shortName": "Menlo Park City Elementary School District",
                        "tagline": [
                            "School District",
                            "Atherton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "menlo-park-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Oak Knoll Elementary School",
                "tagline": [
                    "Menlo Park City Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "oak-knoll-elementary-school-menlo-park-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 651
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.192,37.4304,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.192,37.4304,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94025"
            }
        ]
    },
    {
        "guid": "f6b2d85c-dfeb-462a-b633-0928c6acab7b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 72,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.99172,
                "lon": -121.81968
            },
            "entity": {
                "abbreviation": "Hidden Hills Elementary School",
                "alternates": {
                    "nces": "063513010758"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "f6b2d85c-dfeb-462a-b633-0928c6acab7b",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Hidden Hills Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "775a411e-1de8-46d4-aa54-28cb004cae6f",
                    "zipCode": "2026ed1b-602c-43a5-859e-4d5b3ed7410b"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Hidden Hills Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "hidden-hills-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 708
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "This is the best elementary school in SRVUSD. Niche has gone terribly wrong on its data. There are only about 805 students at the school and not 1060. The test scores are the best in the district, don’t know what way does niche work. Totally wrong ratings.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-03-02T05:45:42.082908Z",
                "guid": "9eb2e34a-53ab-4232-8351-e65a1d10f43a",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.893,37.7646,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.893,37.7646,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "319ab510-47fe-4cdd-8935-9d4b14588ddf",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 73,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.660589,
                "lon": -121.863715
            },
            "entity": {
                "abbreviation": "Valley View Elementary School",
                "alternates": {
                    "nces": "060002009287"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "319ab510-47fe-4cdd-8935-9d4b14588ddf",
                "isClaimed": true,
                "isPremium": false,
                "location": "Pleasanton, CA",
                "name": "Valley View Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2b233cce-20bf-4bd8-b9f6-1289598cc7d4",
                    "parent": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "schoolDistrict": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                    "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "PUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "c831a26c-bfd8-400a-9df2-b2bcdbc75eb3",
                        "location": "Pleasanton, CA",
                        "name": "Pleasanton Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "29f69694-5146-4bfb-b2b3-aa52956e3114",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6a69cf51-74d9-4871-a020-7a26cc1e03a8",
                            "zipCode": "e7654ec4-7c93-4d2e-a586-048286d02972"
                        },
                        "premium": false,
                        "shortName": "Pleasanton Unified School District",
                        "tagline": [
                            "School District",
                            "Pleasanton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pleasanton-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Valley View Elementary School",
                "tagline": [
                    "Pleasanton Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "valley-view-elementary-school-pleasanton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 651
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.862,37.6602,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.862,37.6602,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94566"
            }
        ]
    },
    {
        "guid": "9b1548d2-ec22-4e0d-9c4c-687518543148",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 74,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.756602,
                "lon": -121.953237
            },
            "entity": {
                "abbreviation": "Montevideo Elementary School",
                "alternates": {
                    "nces": "063513005953"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9b1548d2-ec22-4e0d-9c4c-687518543148",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Montevideo Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "54c77220-1c02-48f7-a584-28d7571918f4",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "d01e8b5d-d2aa-452e-a5e1-1f9fac6d6870"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Montevideo Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "montevideo-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 658
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.951,37.7557,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.951,37.7557,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94583"
            }
        ]
    },
    {
        "guid": "3d62ec98-7693-4437-ace0-c1e58adc8739",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 75,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.25602,
                "lon": -122.033588
            },
            "entity": {
                "abbreviation": "Saratoga Elementary School",
                "alternates": {
                    "nces": "063591006151"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3d62ec98-7693-4437-ace0-c1e58adc8739",
                "isClaimed": false,
                "isPremium": false,
                "location": "Saratoga, CA",
                "name": "Saratoga Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "47e72105-df42-4803-af88-d3e2e6e78efd",
                    "schoolDistrict": "47e72105-df42-4803-af88-d3e2e6e78efd",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                    "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Saratoga Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "47e72105-df42-4803-af88-d3e2e6e78efd",
                        "location": "Saratoga, CA",
                        "name": "Saratoga Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                            "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                        },
                        "premium": false,
                        "shortName": "Saratoga Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Saratoga, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "saratoga-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Saratoga Elementary School",
                "tagline": [
                    "Saratoga Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "saratoga-elementary-school-saratoga-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 330
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.034,37.2557,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.034,37.2557,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95070"
            }
        ]
    },
    {
        "guid": "f2dcdafa-539f-4977-8d68-83c050828414",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 76,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.88765,
                "lon": -122.300951
            },
            "entity": {
                "abbreviation": "Ocean View Elementary School",
                "alternates": {
                    "nces": "060186007937"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f2dcdafa-539f-4977-8d68-83c050828414",
                "isClaimed": false,
                "isPremium": false,
                "location": "Albany, CA",
                "name": "Ocean View Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                    "schoolDistrict": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "f3a34422-94be-42dd-b22b-9ec647ebed62",
                    "zipCode": "b0469f0b-b037-4ab0-9a8e-d888278d5110"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Albany City Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "9b6d044b-dcd0-4c7e-a3c5-43c7aca51d99",
                        "location": "Berkeley, CA",
                        "name": "Albany City Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "39b2df86-684a-467f-9c95-5540b32110f5"
                        },
                        "premium": false,
                        "shortName": "Albany City Unified School District",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "albany-city-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Ocean View Elementary School",
                "tagline": [
                    "Albany City Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ocean-view-elementary-school-albany-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 573
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.301,37.8877,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.301,37.8877,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94706"
            }
        ]
    },
    {
        "guid": "a8102c09-9f54-4206-ace7-6d23e17a5a8e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 77,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.825075,
                "lon": -121.98724
            },
            "entity": {
                "abbreviation": "Vista Grande Elementary School",
                "alternates": {
                    "nces": "063513005960"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a8102c09-9f54-4206-ace7-6d23e17a5a8e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Vista Grande Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                    "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Vista Grande Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "vista-grande-elementary-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 623
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.8255,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.8255,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94526"
            }
        ]
    },
    {
        "guid": "f79ab902-af33-4a99-97de-afb04bdccd74",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 78,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.46281,
                "lon": -122.186581
            },
            "entity": {
                "abbreviation": "Encinal Elementary School",
                "alternates": {
                    "nces": "062457003682"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "f79ab902-af33-4a99-97de-afb04bdccd74",
                "isClaimed": true,
                "isPremium": false,
                "location": "Atherton, CA",
                "name": "Encinal Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                    "schoolDistrict": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4443e3e1-84f5-48f0-a699-fe081d5e632a",
                    "zipCode": "76bdf085-4e2e-442f-87dd-d98dbedc0d18"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Menlo Park City Elementary School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                        "location": "Atherton, CA",
                        "name": "Menlo Park City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4443e3e1-84f5-48f0-a699-fe081d5e632a",
                            "zipCode": "76bdf085-4e2e-442f-87dd-d98dbedc0d18"
                        },
                        "premium": false,
                        "shortName": "Menlo Park City Elementary School District",
                        "tagline": [
                            "School District",
                            "Atherton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "menlo-park-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Encinal Elementary School",
                "tagline": [
                    "Menlo Park City Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "encinal-elementary-school-atherton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 601
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 16
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.186,37.4645,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.186,37.4645,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94027"
            }
        ]
    },
    {
        "guid": "871ec4b8-70a2-44ce-ab12-62fd7b55bcc7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 79,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.767094,
                "lon": -121.98633
            },
            "entity": {
                "abbreviation": "Twin Creeks Elementary School",
                "alternates": {
                    "nces": "063513005959"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "871ec4b8-70a2-44ce-ab12-62fd7b55bcc7",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Twin Creeks Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0510ef45-ed63-4629-8254-ca0dd9dda9ee",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "d01e8b5d-d2aa-452e-a5e1-1f9fac6d6870"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Twin Creeks Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "twin-creeks-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 557
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.7683,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.7683,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94583"
            }
        ]
    },
    {
        "guid": "0994e2aa-0c5c-4dc4-926f-d5e76d235bca",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 80,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.716079,
                "lon": -121.937628
            },
            "entity": {
                "abbreviation": "Dublin Elementary School",
                "alternates": {
                    "nces": "060001906929"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "0994e2aa-0c5c-4dc4-926f-d5e76d235bca",
                "isClaimed": true,
                "isPremium": false,
                "location": "Dublin, CA",
                "name": "Dublin Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "schoolDistrict": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                    "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "DUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                        "location": "Dublin, CA",
                        "name": "Dublin Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                            "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                        },
                        "premium": false,
                        "shortName": "Dublin Unified School District",
                        "tagline": [
                            "School District",
                            "Dublin, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dublin-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Dublin Elementary School",
                "tagline": [
                    "Dublin Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "dublin-elementary-school-dublin-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 878
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.937,37.7143,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.937,37.7143,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94568"
            }
        ]
    },
    {
        "guid": "a2d874f6-9ce5-413c-9128-d58a88cbc8ce",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 81,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.49301,
                "lon": -122.275098
            },
            "entity": {
                "abbreviation": "Heather Elementary School",
                "alternates": {
                    "nces": "063429005403"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "a2d874f6-9ce5-413c-9128-d58a88cbc8ce",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Carlos, CA",
                "name": "Heather Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "schoolDistrict": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Carlos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                        "location": "San Carlos, CA",
                        "name": "San Carlos Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                            "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                        },
                        "premium": false,
                        "shortName": "San Carlos Elementary School District",
                        "tagline": [
                            "School District",
                            "San Carlos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-carlos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Heather Elementary School",
                "tagline": [
                    "San Carlos Elementary School District, CA",
                    "K-4"
                ],
                "type": "School",
                "url": "heather-elementary-school-san-carlos-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 400
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.274,37.4924,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.274,37.4924,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94070"
            }
        ]
    },
    {
        "guid": "a7201d1d-acfa-4fe7-bd00-06de5ccae52e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 82,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.846257,
                "lon": -122.154316
            },
            "entity": {
                "abbreviation": "Del Rey Elementary School",
                "alternates": {
                    "nces": "062886004470"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a7201d1d-acfa-4fe7-bd00-06de5ccae52e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Orinda, CA",
                "name": "Del Rey Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                    "schoolDistrict": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                    "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Orinda Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                        "location": "Orinda, CA",
                        "name": "Orinda Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                            "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                        },
                        "premium": false,
                        "shortName": "Orinda Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Orinda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "orinda-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Del Rey Elementary School",
                "tagline": [
                    "Orinda Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "del-rey-elementary-school-orinda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 424
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.153,37.8456,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.153,37.8456,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94563"
            }
        ]
    },
    {
        "guid": "8db314e4-35fd-4de6-8629-41f3022d910e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 83,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.467208,
                "lon": -122.173742
            },
            "entity": {
                "abbreviation": "Laurel Elementary School",
                "alternates": {
                    "nces": "062457003684"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "8db314e4-35fd-4de6-8629-41f3022d910e",
                "isClaimed": true,
                "isPremium": false,
                "location": "Atherton, CA",
                "name": "Laurel Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                    "schoolDistrict": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4443e3e1-84f5-48f0-a699-fe081d5e632a",
                    "zipCode": "76bdf085-4e2e-442f-87dd-d98dbedc0d18"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Menlo Park City Elementary School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "678a6b56-b32b-469b-a2ad-9f4a47811229",
                        "location": "Atherton, CA",
                        "name": "Menlo Park City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4443e3e1-84f5-48f0-a699-fe081d5e632a",
                            "zipCode": "76bdf085-4e2e-442f-87dd-d98dbedc0d18"
                        },
                        "premium": false,
                        "shortName": "Menlo Park City Elementary School District",
                        "tagline": [
                            "School District",
                            "Atherton, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "menlo-park-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Laurel Elementary School",
                "tagline": [
                    "Menlo Park City Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "laurel-elementary-school-atherton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 709
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.172,37.4669,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.172,37.4669,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94027"
            }
        ]
    },
    {
        "guid": "cd9b4e5a-46b6-4307-8c74-4b82c99aa7e4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 84,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.977429,
                "lon": -122.563194
            },
            "entity": {
                "abbreviation": "Anthony G. Bacich Elementary School",
                "alternates": {
                    "nces": "061938002327"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "cd9b4e5a-46b6-4307-8c74-4b82c99aa7e4",
                "isClaimed": false,
                "isPremium": false,
                "location": "Kentfield, CA",
                "name": "Anthony G. Bacich Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "8de7e676-eed5-4a72-95d9-91bf80c34b7c",
                    "schoolDistrict": "8de7e676-eed5-4a72-95d9-91bf80c34b7c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "0db5806d-7d26-41a5-8300-2d00fc0dfd2c",
                    "zipCode": "0455b370-2ddd-4cd1-ae84-32c046dba7fb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Kentfield Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "8de7e676-eed5-4a72-95d9-91bf80c34b7c",
                        "location": "Kentfield, CA",
                        "name": "Kentfield School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "f147eb0c-7f22-44ef-8ab8-f959183ec46c",
                            "zipCode": "3e3caa89-6ba8-4a2f-b748-8dc0f34bf648"
                        },
                        "premium": false,
                        "shortName": "Kentfield Elementary School District",
                        "tagline": [
                            "School District",
                            "Kentfield, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "kentfield-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Anthony G. Bacich Elementary School",
                "tagline": [
                    "Kentfield School District, CA",
                    "K-4"
                ],
                "type": "School",
                "url": "anthony-g-bacich-elementary-school-kentfield-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 640
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "I transferred my older son here from private school in Kindergarten and couldn't be happier.  The teachers have been engaged&  warm, the community has been heavily involved, and the administration has been receptive to feedback.  My older son is in the fourth grade and his younger sibling is in the second grade.  They love going to school and look forward to learning, new activities every day.  It's such a wonderful community to be a part of.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-09-11T16:28:46.393081Z",
                "guid": "0570c6ee-6e2e-4e4c-9a98-f7235ca668dd",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.538,37.9528,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.538,37.9528,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94904"
            }
        ]
    },
    {
        "guid": "807b7f9f-085f-44cb-8fca-9b3fde99e121",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 85,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.265209,
                "lon": -122.03322
            },
            "entity": {
                "abbreviation": "Foothill Elementary School",
                "alternates": {
                    "nces": "063591006149"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "807b7f9f-085f-44cb-8fca-9b3fde99e121",
                "isClaimed": false,
                "isPremium": false,
                "location": "Saratoga, CA",
                "name": "Foothill Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "47e72105-df42-4803-af88-d3e2e6e78efd",
                    "schoolDistrict": "47e72105-df42-4803-af88-d3e2e6e78efd",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                    "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Saratoga Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "47e72105-df42-4803-af88-d3e2e6e78efd",
                        "location": "Saratoga, CA",
                        "name": "Saratoga Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                            "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                        },
                        "premium": false,
                        "shortName": "Saratoga Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Saratoga, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "saratoga-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Foothill Elementary School",
                "tagline": [
                    "Saratoga Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "foothill-elementary-school-saratoga-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 330
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.035,37.2658,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.035,37.2658,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95070"
            }
        ]
    },
    {
        "guid": "5999a760-4267-473e-938f-b9a44a70f48b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 86,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.492182,
                "lon": -122.2533
            },
            "entity": {
                "abbreviation": "White Oaks Elementary School",
                "alternates": {
                    "nces": "063429005405"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "5999a760-4267-473e-938f-b9a44a70f48b",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Carlos, CA",
                "name": "White Oaks Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "schoolDistrict": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Carlos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                        "location": "San Carlos, CA",
                        "name": "San Carlos Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                            "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                        },
                        "premium": false,
                        "shortName": "San Carlos Elementary School District",
                        "tagline": [
                            "School District",
                            "San Carlos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-carlos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "White Oaks Elementary School",
                "tagline": [
                    "San Carlos Elementary School District, CA",
                    "K-3"
                ],
                "type": "School",
                "url": "white-oaks-elementary-school-san-carlos-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 306
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.254,37.4913,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.254,37.4913,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94070"
            }
        ]
    },
    {
        "guid": "a5399f8e-d09a-46ab-a867-2d426b5715dd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 87,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.839641,
                "lon": -122.028197
            },
            "entity": {
                "abbreviation": "Rancho Romero Elementary School",
                "alternates": {
                    "nces": "063513005956"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a5399f8e-d09a-46ab-a867-2d426b5715dd",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alamo, CA",
                "name": "Rancho Romero Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6cd2db24-61d9-4c4b-9dba-1b8592c736a6",
                    "zipCode": "70fa1630-e849-423c-9be9-dcec4af8d465"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Rancho Romero Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rancho-romero-elementary-school-alamo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 478
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.028,37.8413,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.028,37.8413,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94507"
            }
        ]
    },
    {
        "guid": "e678f0f6-be97-4b2f-9b74-f60916e67c41",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 88,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.503996,
                "lon": -122.273481
            },
            "entity": {
                "abbreviation": "Arundel Elementary School",
                "alternates": {
                    "nces": "063429005400"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "e678f0f6-be97-4b2f-9b74-f60916e67c41",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Carlos, CA",
                "name": "Arundel Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "schoolDistrict": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Carlos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                        "location": "San Carlos, CA",
                        "name": "San Carlos Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                            "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                        },
                        "premium": false,
                        "shortName": "San Carlos Elementary School District",
                        "tagline": [
                            "School District",
                            "San Carlos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-carlos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Arundel Elementary School",
                "tagline": [
                    "San Carlos Elementary School District, CA",
                    "K-4"
                ],
                "type": "School",
                "url": "arundel-elementary-school-san-carlos-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 470
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.275,37.5039,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.275,37.5039,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94070"
            }
        ]
    },
    {
        "guid": "78da75a6-adad-4eed-98a7-d7fb95f8a4ff",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 89,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.382421,
                "lon": -122.130234
            },
            "entity": {
                "abbreviation": "Gardner Bullis Elementary School",
                "alternates": {
                    "nces": "062265011796"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "78da75a6-adad-4eed-98a7-d7fb95f8a4ff",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Altos Hills, CA",
                "name": "Gardner Bullis Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "schoolDistrict": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4c92d151-0663-44f5-b6fe-f6beae4bde74",
                    "zipCode": "518d850a-d6a6-4bdc-9dfb-0f338f1c7264"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Altos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6ca5a5c5-0168-4a14-bf57-3a392ce8933e",
                        "location": "Los Altos, CA",
                        "name": "Los Altos Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "146c32c3-5786-4267-a136-7014ebeea05d",
                            "zipCode": "c9e67f6e-8065-4936-963b-a927dec3259f"
                        },
                        "premium": false,
                        "shortName": "Los Altos Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Altos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-altos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Gardner Bullis Elementary School",
                "tagline": [
                    "Los Altos Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "gardner-bullis-elementary-school-los-altos-hills-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 302
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Gardner Bullis is a small, community school that we have had a delightful experience having our son attend for the past two years.  The teachers do a nice job of balancing academic excellence with social-emotional development.\n  \nClasses are small (our son's classes that last two years have 18 and 19 students, respectively).  There are a variety of specialty teachers on staff (eg, literacy, ESL, library, PE, STEM, computer science) and docent programs that support additional areas of learning (eg, visual arts, \"living classroom\" which focuses on the outdoors, and the YMCA's Project Cornerstone which is focused on social-emotional development).\n\nRecently, I was especially impressed with how quickly the school got a robust remote learning curriculum in place when the SIP order went into effect.  Staff also adjusted quickly to feedback when it was received on what wasn't working.\n\nIf you live in the Los Altos Hills area and are looking at schools, I would definitely schedule a tour.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-27T16:30:54.194322Z",
                "guid": "2abd3cf2-2771-4383-b65a-b5cbf9599981",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.13,37.3812,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.13,37.3812,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94022"
            }
        ]
    },
    {
        "guid": "bed4f0bb-ad65-49d1-85a6-df7a72414c5c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 90,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.834512,
                "lon": -121.979492
            },
            "entity": {
                "abbreviation": "Green Valley Elementary School",
                "alternates": {
                    "nces": "063513005947"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bed4f0bb-ad65-49d1-85a6-df7a72414c5c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Danville, CA",
                "name": "Green Valley Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                    "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Green Valley Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "green-valley-elementary-school-danville-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 490
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "As a parent of two currently enrolled students, a PTA chairperson and a volunteer at the school I can confidentially say that this school has our children’s best interest at heart. While it may not be perfect, in the same breath I can say that nothing is. The school is dedicated to making sure that the students are academically engaged; as well as, emotionally supported. I would like to comment on the review bashing the stay at home moms. I believe her experience to be unique to herself. I don’t think that every working mom or stay at home mom has such a divisive view of the school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-08-22T15:32:38.886327Z",
                "guid": "e8b7d99b-30cc-4f61-a82f-11c4741574b0",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.979,37.8332,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.979,37.8332,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94526"
            }
        ]
    },
    {
        "guid": "0027493f-20ce-4845-8ae5-f55f773aeabc",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 91,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.588154,
                "lon": -122.378985
            },
            "entity": {
                "abbreviation": "Lincoln Elementary School",
                "alternates": {
                    "nces": "060648000583"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0027493f-20ce-4845-8ae5-f55f773aeabc",
                "isClaimed": false,
                "isPremium": false,
                "location": "Burlingame, CA",
                "name": "Lincoln Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "schoolDistrict": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Burlingame Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                        "location": "Burlingame, CA",
                        "name": "Burlingame Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "Burlingame Elementary School District",
                        "tagline": [
                            "School District",
                            "Burlingame, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "burlingame-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Lincoln Elementary School",
                "tagline": [
                    "Burlingame Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lincoln-elementary-school-burlingame-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 457
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.378,37.5881,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.378,37.5881,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "a929f97c-1088-4b96-bc6e-2caf5f1aca10",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 92,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.746372,
                "lon": -122.251594
            },
            "entity": {
                "abbreviation": "Bay Farm School",
                "alternates": {
                    "nces": "060177000006"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a929f97c-1088-4b96-bc6e-2caf5f1aca10",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Bay Farm School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "7195f3ef-44f9-4ce1-8865-b2ce025097c8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Bay Farm School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "bay-farm-school-alameda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 610
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.251,37.7441,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.251,37.7441,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94502"
            }
        ]
    },
    {
        "guid": "b9fbfdd2-2035-4d43-acdd-d7efedbf7524",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 93,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.537956,
                "lon": -121.991933
            },
            "entity": {
                "abbreviation": "Circle of Independent Learning Charter School",
                "alternates": {
                    "ceeb": "051061",
                    "nces": "061440007479"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "b9fbfdd2-2035-4d43-acdd-d7efedbf7524",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Circle of Independent Learning Charter School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Circle of Independent Learning Charter School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "circle-of-independent-learning-charter-school-fremont-ca",
                "variation": 52
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 368
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "The pandemic year was a perfect time for us to try  COIL. My son had begged me to homeschool him for over a year, and being in the tradition public school classroom was not working for him. I wish I had listened to him earlier. Within one semester at COIL, my son was able to rehabilitate his relationship with learning while building the confidence and the skills to do independent learning. It is amazing thing to see your child thrive again after years of a downward trend. He is actually getting A’s B’s and have favorite subjects that are not recess again. COIL has been a wonderful resource for kids who don’t fit well in a traditional public school setting for various reasons, from being able to advance at one’s own pace at math, extracurricular pursuits, athletics, to medical health reasons. It has helped me to understand my learners and work with them and try different things that would not be possible in a traditional public school setting.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-06-04T05:25:15.63439Z",
                "guid": "26ace910-1314-4eca-8f2d-d7a77f13d327",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.991,37.5383,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.991,37.5383,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.275862068965517,
                "count": 29
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "b9a94b74-ff9f-4687-90db-847db1f69c38",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 94,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.496307,
                "lon": -122.263641
            },
            "entity": {
                "abbreviation": "Brittan Acres Elementary School",
                "alternates": {
                    "nces": "063429005401"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b9a94b74-ff9f-4687-90db-847db1f69c38",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Carlos, CA",
                "name": "Brittan Acres Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "schoolDistrict": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Carlos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                        "location": "San Carlos, CA",
                        "name": "San Carlos Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                            "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                        },
                        "premium": false,
                        "shortName": "San Carlos Elementary School District",
                        "tagline": [
                            "School District",
                            "San Carlos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-carlos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Brittan Acres Elementary School",
                "tagline": [
                    "San Carlos Elementary School District, CA",
                    "K-3"
                ],
                "type": "School",
                "url": "brittan-acres-elementary-school-san-carlos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 373
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.266,37.4962,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.266,37.4962,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94070"
            }
        ]
    },
    {
        "guid": "2ef221e9-bc59-4043-a5d9-b64b080eb756",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 95,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.581001,
                "lon": -122.340303
            },
            "entity": {
                "abbreviation": "Washington Elementary School",
                "alternates": {
                    "nces": "060648000586"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2ef221e9-bc59-4043-a5d9-b64b080eb756",
                "isClaimed": false,
                "isPremium": false,
                "location": "Burlingame, CA",
                "name": "Washington Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "schoolDistrict": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Burlingame Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                        "location": "Burlingame, CA",
                        "name": "Burlingame Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "Burlingame Elementary School District",
                        "tagline": [
                            "School District",
                            "Burlingame, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "burlingame-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Washington Elementary",
                "tagline": [
                    "Burlingame Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "washington-elementary-school-burlingame-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 382
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.34,37.5803,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.34,37.5803,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "863df9dc-0a1e-4d55-b8e8-5bd439450774",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 96,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.712961,
                "lon": -122.033923
            },
            "entity": {
                "abbreviation": "Jensen Ranch Elementary School",
                "alternates": {
                    "nces": "060780004339"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "863df9dc-0a1e-4d55-b8e8-5bd439450774",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Jensen Ranch Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "5a343bc9-dc79-4997-8588-69d2feb04764"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Jensen Ranch Elementary School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "jensen-ranch-elementary-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 424
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "super good amazing best school best teachers best education best diversity what else can you ask for. Jensen ranch elementry is the best school in castro valley. Their is a lot of activities and stuff its amazing safter js a big part of the school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-05T02:38:33.590454Z",
                "guid": "2a93a8b7-19f5-4f4e-9cf9-1d3e9517d0ac",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.031,37.7125,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.031,37.7125,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94552"
            }
        ]
    },
    {
        "guid": "a29c2c8f-b027-4509-96fc-53d719202579",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 97,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.872432,
                "lon": -122.162612
            },
            "entity": {
                "abbreviation": "Glorietta Elementary School",
                "alternates": {
                    "nces": "062886004471"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a29c2c8f-b027-4509-96fc-53d719202579",
                "isClaimed": false,
                "isPremium": false,
                "location": "Orinda, CA",
                "name": "Glorietta Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                    "schoolDistrict": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                    "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Orinda Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                        "location": "Orinda, CA",
                        "name": "Orinda Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                            "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                        },
                        "premium": false,
                        "shortName": "Orinda Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Orinda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "orinda-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Glorietta Elementary School",
                "tagline": [
                    "Orinda Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "glorietta-elementary-school-orinda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 462
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.163,37.8718,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.163,37.8718,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94563"
            }
        ]
    },
    {
        "guid": "4d0fb952-0cf0-47d9-b02b-c1a848f13c59",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 98,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.907362,
                "lon": -122.523517
            },
            "entity": {
                "abbreviation": "Edna Maguire Elementary School",
                "alternates": {
                    "nces": "062487009926"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4d0fb952-0cf0-47d9-b02b-c1a848f13c59",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mill Valley, CA",
                "name": "Edna Maguire Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                    "schoolDistrict": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mill Valley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                        "location": "Mill Valley, CA",
                        "name": "Mill Valley Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "de53e6f6-0d45-4f4d-b256-501d43b52c81",
                            "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                        },
                        "premium": false,
                        "shortName": "Mill Valley Elementary School District",
                        "tagline": [
                            "School District",
                            "Mill Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mill-valley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Edna Maguire Elementary School",
                "tagline": [
                    "Mill Valley Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "edna-maguire-elementary-school-mill-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 536
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "Very good. The teachers there are very nice, especially if you get the good ones. It’s very easy to connect with classmates, a very important part of an elementary school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-17T04:03:49.638749Z",
                "guid": "c653cbb9-6c06-46fc-827a-4c8f7281ed85",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.524,37.908,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.524,37.908,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94941"
            }
        ]
    },
    {
        "guid": "ce12006b-950d-4220-a28b-b217e6f5041c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 99,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.905279,
                "lon": -122.1423
            },
            "entity": {
                "abbreviation": "Happy Valley Elementary School",
                "alternates": {
                    "nces": "062031002447"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ce12006b-950d-4220-a28b-b217e6f5041c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Lafayette, CA",
                "name": "Happy Valley Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                    "schoolDistrict": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                    "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Lafayette Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                        "location": "Lafayette, CA",
                        "name": "Lafayette Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                            "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                        },
                        "premium": false,
                        "shortName": "Lafayette Elementary School District",
                        "tagline": [
                            "School District",
                            "Lafayette, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "lafayette-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Happy Valley Elementary School",
                "tagline": [
                    "Lafayette Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "happy-valley-elementary-school-lafayette-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 556
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.142,37.9044,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.142,37.9044,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94549"
            }
        ]
    },
    {
        "guid": "c300fa72-da1e-4643-8488-992424238536",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 100,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.742269,
                "lon": -121.93394
            },
            "entity": {
                "abbreviation": "Walt Disney Elementary School",
                "alternates": {
                    "nces": "063513005961"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c300fa72-da1e-4643-8488-992424238536",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Walt Disney Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "54c77220-1c02-48f7-a584-28d7571918f4",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a7847108-2b53-4853-a24b-81c86d5c49b0",
                    "zipCode": "d01e8b5d-d2aa-452e-a5e1-1f9fac6d6870"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Walt Disney Elementary School",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "walt-disney-elementary-school-san-ramon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 525
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.935,37.7407,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.935,37.7407,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94583"
            }
        ]
    },
    {
        "entities": [
            {
                "guid": "306b5d8e-4c6b-40c3-9960-a8a1f581be8b",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 101,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.889416,
                        "lon": -122.118252
                    },
                    "entity": {
                        "abbreviation": "Lafayette Elementary School",
                        "alternates": {
                            "nces": "062031002448"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "306b5d8e-4c6b-40c3-9960-a8a1f581be8b",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Lafayette, CA",
                        "name": "Lafayette Elementary School",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                            "schoolDistrict": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                            "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Lafayette Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                                "location": "Lafayette, CA",
                                "name": "Lafayette Elementary School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                                    "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                                },
                                "premium": false,
                                "shortName": "Lafayette Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Lafayette, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "lafayette-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Lafayette Elementary School",
                        "tagline": [
                            "Lafayette Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "lafayette-elementary-school-lafayette-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 538
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 27
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.117,37.8888,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.117,37.8888,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94549"
                    }
                ]
            },
            {
                "guid": "ff7c7993-839b-43d4-8c8e-165e276317fc",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 102,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.847594,
                        "lon": -122.138275
                    },
                    "entity": {
                        "abbreviation": "Los Perales Elementary School",
                        "alternates": {
                            "nces": "062574007075"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "ff7c7993-839b-43d4-8c8e-165e276317fc",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Moraga, CA",
                        "name": "Los Perales Elementary School",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "9992c13f-d382-4666-b608-06b81ea52d9e",
                            "schoolDistrict": "9992c13f-d382-4666-b608-06b81ea52d9e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5352c423-bafe-4a84-a5f2-d30a97406aed",
                            "zipCode": "162d00a8-92bc-4205-9037-3a00dcac6d50"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Moraga Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "9992c13f-d382-4666-b608-06b81ea52d9e",
                                "location": "Moraga, CA",
                                "name": "Moraga Elementary School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "5352c423-bafe-4a84-a5f2-d30a97406aed",
                                    "zipCode": "162d00a8-92bc-4205-9037-3a00dcac6d50"
                                },
                                "premium": false,
                                "shortName": "Moraga Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Moraga, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "moraga-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Los Perales Elementary School",
                        "tagline": [
                            "Moraga Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "los-perales-elementary-school-moraga-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 417
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.136,37.8467,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.136,37.8467,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94556"
                    }
                ]
            },
            {
                "guid": "84de3641-1edd-4d21-a036-380e9a8a7568",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 103,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.303379,
                        "lon": -122.04806
                    },
                    "entity": {
                        "abbreviation": "William Regnart Elementary School",
                        "alternates": {
                            "nces": "061029001150"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "84de3641-1edd-4d21-a036-380e9a8a7568",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Cupertino, CA",
                        "name": "William Regnart Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Cupertino Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                                "location": "Sunnyvale, CA",
                                "name": "Cupertino Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                                },
                                "premium": false,
                                "shortName": "Cupertino Union School District",
                                "tagline": [
                                    "School District",
                                    "Sunnyvale, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "cupertino-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "William Regnart Elementary School",
                        "tagline": [
                            "Cupertino Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "william-regnart-elementary-school-cupertino-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 452
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.047,37.3035,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.047,37.3035,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95014"
                    }
                ]
            },
            {
                "guid": "2a291287-1a12-4bd3-8f25-a741d11fa6bc",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 104,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.721515,
                        "lon": -121.931491
                    },
                    "entity": {
                        "abbreviation": "Murray Elementary School",
                        "alternates": {
                            "nces": "060001909276"
                        },
                        "character": "Public",
                        "claimed": true,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "2a291287-1a12-4bd3-8f25-a741d11fa6bc",
                        "isClaimed": true,
                        "isPremium": false,
                        "location": "Dublin, CA",
                        "name": "Murray Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                            "schoolDistrict": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                            "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "DUSD",
                                "claimed": true,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                                "location": "Dublin, CA",
                                "name": "Dublin Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                                    "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                                },
                                "premium": false,
                                "shortName": "Dublin Unified School District",
                                "tagline": [
                                    "School District",
                                    "Dublin, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "dublin-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Murray Elementary School",
                        "tagline": [
                            "Dublin Unified School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "murray-elementary-school-dublin-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 615
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.933,37.7224,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.933,37.7224,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94568"
                    }
                ]
            },
            {
                "guid": "8eb337b3-a708-4401-b363-1c696ffdb936",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 105,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.897735,
                        "lon": -122.504746
                    },
                    "entity": {
                        "abbreviation": "Strawberry Point Elementary School",
                        "alternates": {
                            "nces": "062487008518"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "8eb337b3-a708-4401-b363-1c696ffdb936",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Mill Valley, CA",
                        "name": "Strawberry Point Elementary School",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                            "schoolDistrict": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fe35a8a3-ce1d-48c1-a71f-a3334bc67a35",
                            "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Mill Valley Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                                "location": "Mill Valley, CA",
                                "name": "Mill Valley Elementary School District",
                                "parentGUIDs": {
                                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "de53e6f6-0d45-4f4d-b256-501d43b52c81",
                                    "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                                },
                                "premium": false,
                                "shortName": "Mill Valley Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Mill Valley, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "mill-valley-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Strawberry Point Elementary School",
                        "tagline": [
                            "Mill Valley Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "strawberry-point-elementary-school-mill-valley-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 327
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.505,37.8976,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.505,37.8976,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94941"
                    }
                ]
            },
            {
                "guid": "0a8d1a62-8c42-48d4-9714-c0365aa048bc",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 106,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.751599,
                        "lon": -122.157405
                    },
                    "entity": {
                        "abbreviation": "Francophone Charter School of Oakland",
                        "alternates": {
                            "nces": "060183913942"
                        },
                        "character": "Public, Charter",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Charter School",
                        "guid": "0a8d1a62-8c42-48d4-9714-c0365aa048bc",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Oakland, CA",
                        "name": "Francophone Charter School of Oakland",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "3816271d-bf9c-431c-9e92-8f63ddf53940"
                        },
                        "premium": false,
                        "shortName": "Francophone Charter School of Oakland",
                        "tagline": [
                            "Public School",
                            "Oakland, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "francophone-charter-school-of-oakland-oakland-ca",
                        "variation": 57
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 226
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 21
                        }
                    ],
                    "featuredReview": {
                        "author": "Parent",
                        "body": "We've been here for a few years now.  I have been extremely impressed with the teaching.  We attended a private French immersion school before this, and I think that academically speaking, this is very similar in terms of the teaching and curriculum, only it's free!  I'd also say the student/family community is very warm and diverse in every sense of the word.  So we're very glad we made the switch.  It is still a growing school and sometimes the systems and processes are a bit messy and disorganized, that I would say is the main downside.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2021-05-06T21:40:31.87193Z",
                        "guid": "ecd4813a-ee29-44d0-ad17-7813011a8b17",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.157,37.7514,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.157,37.7514,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94605"
                    }
                ]
            },
            {
                "guid": "46cc8726-8583-466f-af5e-d64952b654f1",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 107,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.339863,
                        "lon": -122.06061
                    },
                    "entity": {
                        "abbreviation": "West Valley Elementary School",
                        "alternates": {
                            "nces": "061029001154"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "46cc8726-8583-466f-af5e-d64952b654f1",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Sunnyvale, CA",
                        "name": "West Valley Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "12ede258-9fa8-4ba5-9e1e-4d80c11aa22e"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Cupertino Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                                "location": "Sunnyvale, CA",
                                "name": "Cupertino Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                                },
                                "premium": false,
                                "shortName": "Cupertino Union School District",
                                "tagline": [
                                    "School District",
                                    "Sunnyvale, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "cupertino-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "West Valley Elementary School",
                        "tagline": [
                            "Cupertino Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "west-valley-elementary-school-sunnyvale-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 554
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 24
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.063,37.341,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.063,37.341,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94087"
                    }
                ]
            },
            {
                "guid": "54c862c0-76ad-4cdf-a69d-29add2d46d16",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 108,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.292858,
                        "lon": -122.009658
                    },
                    "entity": {
                        "abbreviation": "Christa McAuliffe Elementary School",
                        "alternates": {
                            "nces": "061029009356"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "54c862c0-76ad-4cdf-a69d-29add2d46d16",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Saratoga, CA",
                        "name": "Christa McAuliffe Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                            "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Cupertino Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                                "location": "Sunnyvale, CA",
                                "name": "Cupertino Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                                },
                                "premium": false,
                                "shortName": "Cupertino Union School District",
                                "tagline": [
                                    "School District",
                                    "Sunnyvale, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "cupertino-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Christa McAuliffe Elementary School",
                        "tagline": [
                            "Cupertino Union School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "christa-mcauliffe-elementary-school-saratoga-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 493
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 25
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.01,37.2921,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.01,37.2921,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95070"
                    }
                ]
            },
            {
                "guid": "52c02306-d511-4119-b8c5-3f5c7a8a7d3c",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 109,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.823022,
                        "lon": -122.124673
                    },
                    "entity": {
                        "abbreviation": "Camino Pablo Elementary School",
                        "alternates": {
                            "nces": "062574003849"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "52c02306-d511-4119-b8c5-3f5c7a8a7d3c",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Moraga, CA",
                        "name": "Camino Pablo Elementary School",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "9992c13f-d382-4666-b608-06b81ea52d9e",
                            "schoolDistrict": "9992c13f-d382-4666-b608-06b81ea52d9e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5352c423-bafe-4a84-a5f2-d30a97406aed",
                            "zipCode": "162d00a8-92bc-4205-9037-3a00dcac6d50"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Moraga Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "9992c13f-d382-4666-b608-06b81ea52d9e",
                                "location": "Moraga, CA",
                                "name": "Moraga Elementary School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "5352c423-bafe-4a84-a5f2-d30a97406aed",
                                    "zipCode": "162d00a8-92bc-4205-9037-3a00dcac6d50"
                                },
                                "premium": false,
                                "shortName": "Moraga Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Moraga, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "moraga-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Camino Pablo Elementary School",
                        "tagline": [
                            "Moraga Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "camino-pablo-elementary-school-moraga-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 367
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 26
                        }
                    ],
                    "featuredReview": {
                        "author": "Niche User",
                        "body": "Great parent support and great leadership at the school which make for an outstanding community",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2016-08-30T03:11:51Z",
                        "guid": "9624e0b5-a548-4883-83cc-df6effd4cc8b",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.122,37.8219,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.122,37.8219,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 4.666666666666667,
                        "count": 3
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94556"
                    }
                ]
            },
            {
                "guid": "294d9564-b570-4ba8-8443-14ecad7147db",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 110,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.897112,
                        "lon": -122.207671
                    },
                    "entity": {
                        "abbreviation": "Wagner Ranch Elementary School",
                        "alternates": {
                            "nces": "062886007077"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "294d9564-b570-4ba8-8443-14ecad7147db",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Orinda, CA",
                        "name": "Wagner Ranch Elementary School",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                            "schoolDistrict": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                            "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Orinda Union Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                                "location": "Orinda, CA",
                                "name": "Orinda Union Elementary School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                                    "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                                },
                                "premium": false,
                                "shortName": "Orinda Union Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Orinda, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "orinda-union-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Wagner Ranch Elementary School",
                        "tagline": [
                            "Orinda Union Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "wagner-ranch-elementary-school-orinda-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 416
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 21
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.207,37.8976,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.207,37.8976,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94563"
                    }
                ]
            },
            {
                "guid": "8817e581-dc75-4ef0-932c-d8f88c870992",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 111,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.298463,
                        "lon": -121.991947
                    },
                    "entity": {
                        "abbreviation": "Country Lane Elementary School",
                        "alternates": {
                            "nces": "062577003853"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "8817e581-dc75-4ef0-932c-d8f88c870992",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "Country Lane Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "parent": "a8938731-bf81-4725-9991-fd2727841f28",
                            "schoolDistrict": "a8938731-bf81-4725-9991-fd2727841f28",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "ca7b746e-cd3c-474a-a264-ac5c6e4ff339"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Moreland Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "a8938731-bf81-4725-9991-fd2727841f28",
                                "location": "San Jose, CA",
                                "name": "Moreland",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                                    "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                                },
                                "premium": false,
                                "shortName": "Moreland Elementary School District",
                                "tagline": [
                                    "School District",
                                    "San Jose, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "moreland-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Country Lane Elementary School",
                        "tagline": [
                            "Moreland, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "country-lane-elementary-school-san-jose-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 613
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 24
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.991,37.2976,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.991,37.2976,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95129"
                    }
                ]
            },
            {
                "guid": "fcb7f659-f7d4-4636-8443-aa9692c3a4c1",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 112,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.910033,
                        "lon": -122.104568
                    },
                    "entity": {
                        "abbreviation": "Springhill Elementary School",
                        "alternates": {
                            "nces": "062031002450"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "fcb7f659-f7d4-4636-8443-aa9692c3a4c1",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Lafayette, CA",
                        "name": "Springhill Elementary School",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                            "schoolDistrict": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                            "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Lafayette Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                                "location": "Lafayette, CA",
                                "name": "Lafayette Elementary School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                                    "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                                },
                                "premium": false,
                                "shortName": "Lafayette Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Lafayette, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "lafayette-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Springhill Elementary School",
                        "tagline": [
                            "Lafayette Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "springhill-elementary-school-lafayette-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 454
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.102,37.9077,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.102,37.9077,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94549"
                    }
                ]
            },
            {
                "guid": "3dfdbcf2-1a01-4880-b0c0-21fade307eb4",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 113,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.903692,
                        "lon": -122.538172
                    },
                    "entity": {
                        "abbreviation": "Park Elementary School",
                        "alternates": {
                            "nces": "062487003714"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "3dfdbcf2-1a01-4880-b0c0-21fade307eb4",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Mill Valley, CA",
                        "name": "Park Elementary School",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                            "schoolDistrict": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "de53e6f6-0d45-4f4d-b256-501d43b52c81",
                            "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Mill Valley Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                                "location": "Mill Valley, CA",
                                "name": "Mill Valley Elementary School District",
                                "parentGUIDs": {
                                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "de53e6f6-0d45-4f4d-b256-501d43b52c81",
                                    "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                                },
                                "premium": false,
                                "shortName": "Mill Valley Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Mill Valley, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "mill-valley-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Park Elementary School",
                        "tagline": [
                            "Mill Valley Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "park-elementary-school-mill-valley-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 304
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "featuredReview": {
                        "author": "Senior",
                        "body": "Awesome teachers, great experience. Spent every day having fun and learning. If there is room at this school, definitely consider putting your child in here.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2019-04-04T17:50:16.378939Z",
                        "guid": "98486341-049e-40f2-a825-ac6340fead17",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.537,37.9033,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.537,37.9033,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 2
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94941"
                    }
                ]
            },
            {
                "guid": "ad7937ca-3663-472e-a149-5c15dd35ca2c",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 114,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.300499,
                        "lon": -122.018809
                    },
                    "entity": {
                        "abbreviation": "John Muir Elementary School",
                        "alternates": {
                            "nces": "061029001144"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "ad7937ca-3663-472e-a149-5c15dd35ca2c",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "John Muir Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "ca7b746e-cd3c-474a-a264-ac5c6e4ff339"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Cupertino Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                                "location": "Sunnyvale, CA",
                                "name": "Cupertino Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                                },
                                "premium": false,
                                "shortName": "Cupertino Union School District",
                                "tagline": [
                                    "School District",
                                    "Sunnyvale, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "cupertino-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "John Muir Elementary School",
                        "tagline": [
                            "Cupertino Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "john-muir-elementary-school-san-jose-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 354
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 24
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.02,37.2998,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.02,37.2998,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95129"
                    }
                ]
            },
            {
                "guid": "ffd57ef2-2017-432f-8719-dd7644be536b",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 115,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.277259,
                        "lon": -122.02605
                    },
                    "entity": {
                        "abbreviation": "Argonaut Elementary School",
                        "alternates": {
                            "nces": "063591006147"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "ffd57ef2-2017-432f-8719-dd7644be536b",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Saratoga, CA",
                        "name": "Argonaut Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "47e72105-df42-4803-af88-d3e2e6e78efd",
                            "schoolDistrict": "47e72105-df42-4803-af88-d3e2e6e78efd",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                            "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Saratoga Union Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "47e72105-df42-4803-af88-d3e2e6e78efd",
                                "location": "Saratoga, CA",
                                "name": "Saratoga Union Elementary School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                                    "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                                },
                                "premium": false,
                                "shortName": "Saratoga Union Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Saratoga, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "saratoga-union-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Argonaut Elementary School",
                        "tagline": [
                            "Saratoga Union Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "argonaut-elementary-school-saratoga-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 344
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.025,37.2768,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.025,37.2768,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95070"
                    }
                ]
            },
            {
                "guid": "f17713bf-b7ef-44d0-993d-2ea1e7eb4bac",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 116,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.34483,
                        "lon": -122.02819
                    },
                    "entity": {
                        "abbreviation": "Louis E. Stocklmeir Elementary School",
                        "alternates": {
                            "nces": "061029001153"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "f17713bf-b7ef-44d0-993d-2ea1e7eb4bac",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Sunnyvale, CA",
                        "name": "Louis E. Stocklmeir Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "12ede258-9fa8-4ba5-9e1e-4d80c11aa22e"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Cupertino Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                                "location": "Sunnyvale, CA",
                                "name": "Cupertino Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                                },
                                "premium": false,
                                "shortName": "Cupertino Union School District",
                                "tagline": [
                                    "School District",
                                    "Sunnyvale, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "cupertino-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Louis E. Stocklmeir Elementary School",
                        "tagline": [
                            "Cupertino Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "louis-e-stocklmeir-elementary-school-sunnyvale-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 1106
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 25
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.027,37.3441,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.027,37.3441,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94087"
                    }
                ]
            },
            {
                "guid": "66f1e0bb-49fd-4288-af5e-d6c741a1d786",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 117,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.762185,
                        "lon": -122.259038
                    },
                    "entity": {
                        "abbreviation": "Donald D. Lum Elementary School",
                        "alternates": {
                            "nces": "060177000051"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "66f1e0bb-49fd-4288-af5e-d6c741a1d786",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Alameda, CA",
                        "name": "Donald D. Lum Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                            "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "AUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                                "location": "Alameda, CA",
                                "name": "Alameda Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                                },
                                "premium": false,
                                "shortName": "Alameda City Unified School District",
                                "tagline": [
                                    "School District",
                                    "Alameda, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "alameda-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Donald D. Lum Elementary School",
                        "tagline": [
                            "Alameda Unified School District, CA",
                            "5"
                        ],
                        "type": "School",
                        "url": "donald-d-lum-elementary-school-alameda-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 30
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 30
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.26,37.7617,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.26,37.7617,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94501"
                    }
                ]
            },
            {
                "guid": "f09baa1c-6e6d-47a7-924f-d766f7dd57c5",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 118,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.906093,
                        "lon": -122.55403
                    },
                    "entity": {
                        "abbreviation": "Old Mill Elementary School",
                        "alternates": {
                            "nces": "062487003713"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "f09baa1c-6e6d-47a7-924f-d766f7dd57c5",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Mill Valley, CA",
                        "name": "Old Mill Elementary School",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                            "schoolDistrict": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "de53e6f6-0d45-4f4d-b256-501d43b52c81",
                            "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Mill Valley Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                                "location": "Mill Valley, CA",
                                "name": "Mill Valley Elementary School District",
                                "parentGUIDs": {
                                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "de53e6f6-0d45-4f4d-b256-501d43b52c81",
                                    "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                                },
                                "premium": false,
                                "shortName": "Mill Valley Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Mill Valley, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "mill-valley-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Old Mill Elementary School",
                        "tagline": [
                            "Mill Valley Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "old-mill-elementary-school-mill-valley-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 287
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.553,37.9061,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.553,37.9061,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94941"
                    }
                ]
            },
            {
                "guid": "65df395f-9660-49ab-bf7e-811417d24ecd",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 119,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.588606,
                        "lon": -122.388997
                    },
                    "entity": {
                        "abbreviation": "Franklin Elementary School",
                        "alternates": {
                            "nces": "060648000582"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "65df395f-9660-49ab-bf7e-811417d24ecd",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Burlingame, CA",
                        "name": "Franklin Elementary School",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                            "schoolDistrict": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Burlingame Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                                "location": "Burlingame, CA",
                                "name": "Burlingame Elementary School District",
                                "parentGUIDs": {
                                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                                },
                                "premium": false,
                                "shortName": "Burlingame Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Burlingame, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "burlingame-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Franklin Elementary School",
                        "tagline": [
                            "Burlingame Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "franklin-elementary-school-burlingame-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 466
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 28
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.388,37.5883,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.388,37.5883,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94010"
                    }
                ]
            },
            {
                "guid": "2632ad76-cadd-4a59-93c5-fecba9f8b693",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 120,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.550209,
                        "lon": -121.947845
                    },
                    "entity": {
                        "abbreviation": "John Gomes Elementary School",
                        "alternates": {
                            "nces": "061440001664"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "2632ad76-cadd-4a59-93c5-fecba9f8b693",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Fremont, CA",
                        "name": "John Gomes Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "f8323aa0-74a4-4cb1-84e3-cfe331752916"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "FUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                                "location": "Fremont, CA",
                                "name": "Fremont Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                                },
                                "premium": false,
                                "shortName": "Fremont Unified School District",
                                "tagline": [
                                    "School District",
                                    "Fremont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "fremont-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "John Gomes Elementary School",
                        "tagline": [
                            "Fremont Unified School District, CA",
                            "K-6"
                        ],
                        "type": "School",
                        "url": "john-gomes-elementary-school-fremont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 746
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.949,37.5511,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.949,37.5511,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94539"
                    }
                ]
            },
            {
                "guid": "fd6a1c76-bbeb-43fc-b1d9-b04b1da303a7",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 121,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.90783,
                        "lon": -122.197378
                    },
                    "entity": {
                        "abbreviation": "Sleepy Hollow Elementary School",
                        "alternates": {
                            "nces": "062886004473"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "fd6a1c76-bbeb-43fc-b1d9-b04b1da303a7",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Orinda, CA",
                        "name": "Sleepy Hollow Elementary School",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                            "schoolDistrict": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                            "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Orinda Union Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "9c66fb6e-dc65-4ed7-9175-c6f2ba9a2638",
                                "location": "Orinda, CA",
                                "name": "Orinda Union Elementary School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "4c4dba65-4ae5-4d4e-916e-4bcaac578f8b",
                                    "zipCode": "9056b24a-6431-4b5e-aede-11f6b12c170e"
                                },
                                "premium": false,
                                "shortName": "Orinda Union Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Orinda, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "orinda-union-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Sleepy Hollow Elementary School",
                        "tagline": [
                            "Orinda Union Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "sleepy-hollow-elementary-school-orinda-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 339
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 20
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.201,37.9091,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.201,37.9091,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 1,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94563"
                    }
                ]
            },
            {
                "guid": "74b25a27-305e-41fd-93e9-052ac52c983c",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 122,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.507097,
                        "lon": -121.927401
                    },
                    "entity": {
                        "abbreviation": "Fred E. Weibel Elementary School",
                        "alternates": {
                            "nces": "061440009139"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "74b25a27-305e-41fd-93e9-052ac52c983c",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Fremont, CA",
                        "name": "Fred E. Weibel Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "f8323aa0-74a4-4cb1-84e3-cfe331752916"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "FUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                                "location": "Fremont, CA",
                                "name": "Fremont Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                                },
                                "premium": false,
                                "shortName": "Fremont Unified School District",
                                "tagline": [
                                    "School District",
                                    "Fremont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "fremont-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Fred E. Weibel Elementary School",
                        "tagline": [
                            "Fremont Unified School District, CA",
                            "K-6"
                        ],
                        "type": "School",
                        "url": "fred-e-weibel-elementary-school-fremont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 796
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "featuredReview": {
                        "author": "Alum",
                        "body": "I couldn't have asked for a better experience at Weibel. The staff treated everyone as equals, and my fellow class and schoolmates made everyday at school special for me. From Kindergarten through 6th grade, my teachers made sure I got a good education, as well as good manners. All the curriculum I learned in elementary school carried on throughout what I learn till this day. If I had all the money in the world I'd most definitely give back to the school. Help repair the school field, make the playground more clean and modern so the kids attending the school now can enjoy recess on another level, and lastly, give the school money to spend on what their needs may be.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2019-10-10T21:08:15.176217Z",
                        "guid": "e4973af9-8d44-470d-96bb-3591c3f42444",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.927,37.5062,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.927,37.5062,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 4.5,
                        "count": 2
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94539"
                    }
                ]
            },
            {
                "guid": "541c7efd-b8bb-42f9-bdaf-d2809891bfb4",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 123,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.307176,
                        "lon": -122.026643
                    },
                    "entity": {
                        "abbreviation": "R. I. Meyerholz Elementary School",
                        "alternates": {
                            "nces": "061029001141"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "541c7efd-b8bb-42f9-bdaf-d2809891bfb4",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "R.I. Meyerholz Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "ca7b746e-cd3c-474a-a264-ac5c6e4ff339"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Cupertino Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                                "location": "Sunnyvale, CA",
                                "name": "Cupertino Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                                },
                                "premium": false,
                                "shortName": "Cupertino Union School District",
                                "tagline": [
                                    "School District",
                                    "Sunnyvale, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "cupertino-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "R. I. Meyerholz Elementary School",
                        "tagline": [
                            "Cupertino Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "ri-meyerholz-elementary-school-san-jose-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 771
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 25
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.026,37.3062,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.026,37.3062,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95129"
                    }
                ]
            },
            {
                "guid": "ee8fe74a-0b6e-4026-82b4-0cadcfcec9f4",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 124,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.227994,
                        "lon": -121.897395
                    },
                    "entity": {
                        "abbreviation": "Guadalupe Elementary School",
                        "alternates": {
                            "nces": "064032006672"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "ee8fe74a-0b6e-4026-82b4-0cadcfcec9f4",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "Guadalupe Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "76f228c2-291e-4ea6-839a-447faaa4a145",
                            "parent": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                            "schoolDistrict": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "67602db5-a16e-4627-8133-9f3d9f61c732"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Union Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                                "location": "San Jose, CA",
                                "name": "Union Elementary School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                                },
                                "premium": false,
                                "shortName": "Union Elementary School District",
                                "tagline": [
                                    "School District",
                                    "San Jose, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "union-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Guadalupe Elementary School",
                        "tagline": [
                            "Union Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "guadalupe-elementary-school-san-jose-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 595
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.897,37.2289,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.897,37.2289,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95120"
                    }
                ]
            },
            {
                "guid": "31798771-622e-440b-a38f-d830188c1a9e",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 125,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.875179,
                        "lon": -122.528084
                    },
                    "entity": {
                        "abbreviation": "Tamalpais Valley Elementary School",
                        "alternates": {
                            "nces": "062487003716"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "31798771-622e-440b-a38f-d830188c1a9e",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Mill Valley, CA",
                        "name": "Tamalpais Valley Elementary School",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                            "schoolDistrict": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Mill Valley Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "0bc1b786-c4e0-4ed1-8387-13dbe7b5ac2d",
                                "location": "Mill Valley, CA",
                                "name": "Mill Valley Elementary School District",
                                "parentGUIDs": {
                                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "de53e6f6-0d45-4f4d-b256-501d43b52c81",
                                    "zipCode": "637c14ef-772f-434e-a25d-6ef7f24e7cd3"
                                },
                                "premium": false,
                                "shortName": "Mill Valley Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Mill Valley, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "mill-valley-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Tamalpais Valley Elementary School",
                        "tagline": [
                            "Mill Valley Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "tamalpais-valley-elementary-school-mill-valley-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 452
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "featuredReview": {
                        "author": "Alum",
                        "body": "Going to Tam Valley Elementary was great. It has an absolutely amazing location and blacktop for kids to play in. It's right by a few trails which is nice. It always has fun activities for students like the art room and library. There's a garden, Music classes, science/nature walks, and so much more. It's such an amazing school.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2020-03-25T15:42:34.702838Z",
                        "guid": "8282e873-ff92-43c8-97e9-f10c6b6f74a9",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.528,37.8741,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.528,37.8741,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94941"
                    }
                ]
            }
        ]
    },
    {
        "guid": "cb53e319-c6f8-4a7f-8095-a6fd9d61c7d6",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 126,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.255569,
                "lon": -121.918722
            },
            "entity": {
                "abbreviation": "Oster Elementary School",
                "alternates": {
                    "nces": "064032006678"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "cb53e319-c6f8-4a7f-8095-a6fd9d61c7d6",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Oster Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "schoolDistrict": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                        "location": "San Jose, CA",
                        "name": "Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Oster Elementary School",
                "tagline": [
                    "Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "oster-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 657
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Me as a parent I want my child to be successful",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2015-12-20T14:31:30Z",
                "guid": "09bdb348-3bd4-4859-94bb-370b2dd21f48",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.92,37.2566,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.92,37.2566,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "68d84b20-b380-445c-8e1d-7d1992668efb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 127,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.530988,
                "lon": -121.994235
            },
            "entity": {
                "abbreviation": "Joseph Azevada Elementary School",
                "alternates": {
                    "nces": "061440001655"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "68d84b20-b380-445c-8e1d-7d1992668efb",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Joseph Azevada Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Joseph Azevada Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "joseph-azevada-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 650
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I attended Azevada Elementary School from 1-3rd grade. The teachers and staff were amazing they treated me very well even with my disabilities. The teachers I had could never compare to the teachers I had from 4-5th grade. For that reason I ended up transferring back to Azevada for my 6th grade.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-03-18T18:31:54.618646Z",
                "guid": "b7b8325a-e0e2-463d-9402-aeea03d6c172",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.994,37.5315,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.994,37.5315,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "13bc7106-61d4-4e6b-ad66-be8bbe3b4098",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 128,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.9897877,
                "lon": -122.5903385
            },
            "entity": {
                "abbreviation": "Ross Valley Charter",
                "alternates": {
                    "nces": "060223414064"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "13bc7106-61d4-4e6b-ad66-be8bbe3b4098",
                "isClaimed": true,
                "isPremium": false,
                "location": "Fairfax, CA",
                "name": "Ross Valley Charter",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "d8aac2d4-6266-4e7a-a585-8758fbf27804",
                    "zipCode": "98575946-123b-48d5-8cc4-beebd45ad068"
                },
                "premium": false,
                "shortName": "Ross Valley Charter",
                "tagline": [
                    "Public School",
                    "Fairfax, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ross-valley-charter-fairfax-ca-060223414064",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 161
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "New location in downtown Fairfax. Huge site. Multi age program. Teachers engage students with childs interests. Parent involvement, staff very in touch with school. Wecoming to all families. Great support for children that do not connect in traditional classrooms.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-08-09T18:50:21.907577Z",
                "guid": "a204a273-a5c6-404a-b899-b1cd39c4ddb8",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.5903385,37.9897877,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.5903385,37.9897877,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94930"
            }
        ]
    },
    {
        "guid": "18be844d-3e49-4bff-90f7-eac8eea1f695",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 129,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.579335,
                "lon": -122.368432
            },
            "entity": {
                "abbreviation": "Roosevelt Elementary School",
                "alternates": {
                    "nces": "060648007418"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "18be844d-3e49-4bff-90f7-eac8eea1f695",
                "isClaimed": false,
                "isPremium": false,
                "location": "Burlingame, CA",
                "name": "Roosevelt Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "schoolDistrict": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Burlingame Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                        "location": "Burlingame, CA",
                        "name": "Burlingame Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "Burlingame Elementary School District",
                        "tagline": [
                            "School District",
                            "Burlingame, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "burlingame-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Roosevelt Elementary School",
                "tagline": [
                    "Burlingame Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "roosevelt-elementary-school-burlingame-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 359
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great school.  My kids have loved every minute. Innovative teachers and administrator, welcoming community and great kids.  A very diverse student body that welcomes all.  Great academics with a heavy emphasis on STEAM and the Arts!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-06-16T15:31:12.936485Z",
                "guid": "ea687702-54d1-4ea8-9ca1-7609a74a09bf",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.37,37.5797,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.37,37.5797,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "5a98b17d-6abb-46b1-ad29-c61d55ae53f9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 130,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.327113,
                "lon": -122.064408
            },
            "entity": {
                "abbreviation": "Stevens Creek Elementary School",
                "alternates": {
                    "nces": "061029001152"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5a98b17d-6abb-46b1-ad29-c61d55ae53f9",
                "isClaimed": false,
                "isPremium": false,
                "location": "Cupertino, CA",
                "name": "Stevens Creek Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Stevens Creek Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "stevens-creek-elementary-school-cupertino-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 582
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Amazing school. Suitable for children all over the globe. Kind and friendly teachers. Loved the celebrations and the extra activities. Had many fun and cherish able moments. The field trips were amazing and we had learned a lot from them. They were the best way to teach us. ",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-10-30T14:28:44.119146Z",
                "guid": "bc0838ae-160f-46e0-9dcc-d09e06db4e82",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.063,37.3279,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.063,37.3279,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95014"
            }
        ]
    },
    {
        "guid": "d33d7861-4394-4e58-9d35-12f80668ec3d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 131,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.234769,
                "lon": -121.95723
            },
            "entity": {
                "abbreviation": "Blossom Hill Elementary School",
                "alternates": {
                    "nces": "062283003489"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d33d7861-4394-4e58-9d35-12f80668ec3d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Gatos, CA",
                "name": "Blossom Hill Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "2657e046-c2e2-4cea-822c-45bceb563d45",
                    "schoolDistrict": "2657e046-c2e2-4cea-822c-45bceb563d45",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                    "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Gatos Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "2657e046-c2e2-4cea-822c-45bceb563d45",
                        "location": "Los Gatos, CA",
                        "name": "Los Gatos Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                            "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                        },
                        "premium": false,
                        "shortName": "Los Gatos Union School District",
                        "tagline": [
                            "School District",
                            "Los Gatos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-gatos-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Blossom Hill Elementary School",
                "tagline": [
                    "Los Gatos Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "blossom-hill-elementary-school-los-gatos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 584
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.957,37.234,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.957,37.234,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95032"
            }
        ]
    },
    {
        "guid": "e8da1e7b-b589-4eed-a4a9-4a30f92d85a2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 132,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.315228,
                "lon": -122.025835
            },
            "entity": {
                "abbreviation": "C. B. Eaton Elementary School",
                "alternates": {
                    "nces": "061029007787"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e8da1e7b-b589-4eed-a4a9-4a30f92d85a2",
                "isClaimed": false,
                "isPremium": false,
                "location": "Cupertino, CA",
                "name": "C.B. Eaton Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "C. B. Eaton Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cb-eaton-elementary-school-cupertino-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 497
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Eaton Elementary is a wonderful, safe place for kids to grow. Teachers are upstanding and there is a lot of fun events promoted by PTO. Highly recommended!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-06-04T05:16:18.997016Z",
                "guid": "6c127dc0-1c4f-46f6-bf4b-f32c360e48f1",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.026,37.3144,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.026,37.3144,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95014"
            }
        ]
    },
    {
        "guid": "17a5abf3-8f14-4e9b-b3a4-98e31afa7a52",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 133,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.375484,
                "lon": -122.074732
            },
            "entity": {
                "abbreviation": "Frank L. Huff Elementary School",
                "alternates": {
                    "nces": "062628007791"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "17a5abf3-8f14-4e9b-b3a4-98e31afa7a52",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Frank L. Huff Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "schoolDistrict": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "e8a55ab4-9bff-4942-821c-369c0c98966c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mountain View Whisman School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "55669afd-2c14-4350-8f57-42ebb04aa320",
                        "location": "Mountain View, CA",
                        "name": "Mountain View Whisman School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                            "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                        },
                        "premium": false,
                        "shortName": "Mountain View Whisman School District",
                        "tagline": [
                            "School District",
                            "Mountain View, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mountain-view-whisman-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Frank L. Huff Elementary School",
                "tagline": [
                    "Mountain View Whisman School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "frank-l-huff-elementary-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 610
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.075,37.3751,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.075,37.3751,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94040"
            }
        ]
    },
    {
        "guid": "547b4518-f3cb-4039-9413-8819c9cc6e28",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 134,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.699151,
                "lon": -122.049751
            },
            "entity": {
                "abbreviation": "Independent Elementary School",
                "alternates": {
                    "nces": "060780009342"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "547b4518-f3cb-4039-9413-8819c9cc6e28",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Independent Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "5a343bc9-dc79-4997-8588-69d2feb04764"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Independent Elementary School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "independent-elementary-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 626
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Independent Elementary School was a solid foundation to the start of my education. Granted, I did have a stay-at-home mom that provided a lot of support for my precious public school education, but I also truly place my academic success in the dedication and care that a majority of the teachers had. Let's just say that to this day (keep in mind that I'm a high school senior now), my mom still randomly spurts about how wonderful my kindergarten teacher was, and how important her teaching was to planting that darling seed of education into my life. \n\nI debated giving the school four stars or five stars, and in the end, I still chose five because I believe all the teachers did care to at least 90% capacity.\n\n(And if you're also looking at Jensen Ranch Elementary School, just know that the education is really almost the same. I know an equal amount of highly intelligent (and unintelligent) kids from both Independent and Jensen.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-07-15T01:13:08.524368Z",
                "guid": "016a50b6-e15f-45e3-92a2-5230a9874c82",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.051,37.6993,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.051,37.6993,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94552"
            }
        ]
    },
    {
        "guid": "8e721b27-0478-4a9e-9aff-abfae2271878",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 135,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.499094,
                "lon": -122.260452
            },
            "entity": {
                "abbreviation": "Arroyo",
                "alternates": {
                    "nces": "063429013998"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8e721b27-0478-4a9e-9aff-abfae2271878",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Carlos, CA",
                "name": "Arroyo",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "schoolDistrict": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Carlos Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a0c9ed90-b1a5-433a-b19f-5f52a2f701f3",
                        "location": "San Carlos, CA",
                        "name": "San Carlos Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                            "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                        },
                        "premium": false,
                        "shortName": "San Carlos Elementary School District",
                        "tagline": [
                            "School District",
                            "San Carlos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-carlos-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Arroyo",
                "tagline": [
                    "San Carlos Elementary School District, CA",
                    "4-5"
                ],
                "type": "School",
                "url": "arroyo-san-carlos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 288
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 18
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {},
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94070"
            }
        ]
    },
    {
        "guid": "09a62f90-d0b7-4000-a94b-43c9601e8eab",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 136,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.237724,
                "lon": -121.913892
            },
            "entity": {
                "abbreviation": "Noddin Elementary School",
                "alternates": {
                    "nces": "064032006677"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "09a62f90-d0b7-4000-a94b-43c9601e8eab",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Noddin Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "schoolDistrict": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                        "location": "San Jose, CA",
                        "name": "Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Noddin Elementary School",
                "tagline": [
                    "Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "noddin-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 625
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Wonderful school that tries to cater to each student’s academic and social needs. State funding is low for this district so parents help provide art and PE.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-03-29T13:42:23.846331Z",
                "guid": "89f45231-2bce-4120-b3bb-a4ea64827c24",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.914,37.2386,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.914,37.2386,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "7f44413f-fbfd-4d6b-b963-7351a33b7825",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 137,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.404664,
                "lon": -122.082781
            },
            "entity": {
                "abbreviation": "Stevenson Elementary School",
                "alternates": {
                    "nces": "062628012540"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7f44413f-fbfd-4d6b-b963-7351a33b7825",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Stevenson Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "schoolDistrict": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mountain View Whisman School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "55669afd-2c14-4350-8f57-42ebb04aa320",
                        "location": "Mountain View, CA",
                        "name": "Mountain View Whisman School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                            "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                        },
                        "premium": false,
                        "shortName": "Mountain View Whisman School District",
                        "tagline": [
                            "School District",
                            "Mountain View, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mountain-view-whisman-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Stevenson Elementary School",
                "tagline": [
                    "Mountain View Whisman School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "stevenson-elementary-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 427
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "A public school that one could easily pay private school prices for. We love it - have been here since Kinder & are very happy. Every week, all kids have gardening, cooking, tinkering, music, PE, STEAM, and for 2 semesters, every Thurs, all they do, all day long is Arts Focus - ceramics, woodwork, videography, kinetic energy, printing... the list is exhaustive. The play they put up every year beats any Broadway production (ok, that's a bit of a stretch...). Parents get to participate and volunteer - in the classroom as a teacher's assistant; organizing field trips; appreciating teachers; parent volunteers teach all the ArtsFocus subjects, gardening, cooking, tinkering. When parents are involved in their students' education, partnering with the teachers, the results are spectacular. These kids are involved in their learning, have a love for learning, are respectful, empathetic, kind and know how to speak with grownups confidently. Love this school!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-06-02T01:20:38.121839Z",
                "guid": "fae09473-192d-4b0a-9694-0ec7e51da2e2",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.083,37.4048,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.083,37.4048,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94043"
            }
        ]
    },
    {
        "guid": "4de5783c-0c9c-4f2e-bd29-20a7b6f1eb69",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 138,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.57299,
                "lon": -122.052189
            },
            "entity": {
                "abbreviation": "Ardenwood Elementary School",
                "alternates": {
                    "nces": "061440008138"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4de5783c-0c9c-4f2e-bd29-20a7b6f1eb69",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Ardenwood Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "59bd583b-4aa7-49e1-8439-175d9bb43663"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Ardenwood Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "ardenwood-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 963
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I love this school especially all the teachers I've had here. the students have a great bond and it's honestly the best school I've gone to. From what I know all of my past peers love this school. The curriculum is updated based on students' advancements and support towards it. Although the food isn't great it's improved from day one. I love the diversity of families and students, each having their preferences, but we all come together as one.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-09T05:34:48.124451Z",
                "guid": "77a723cc-695c-427d-8281-9e2656db5d9f",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.053,37.5727,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.053,37.5727,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94555"
            }
        ]
    },
    {
        "guid": "1c50dc65-fba0-4ff5-819f-a1a18f981d48",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 139,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.576727,
                "lon": -122.38045
            },
            "entity": {
                "abbreviation": "Hoover Elementary School",
                "alternates": {
                    "nces": "060648013999"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1c50dc65-fba0-4ff5-819f-a1a18f981d48",
                "isClaimed": false,
                "isPremium": false,
                "location": "Burlingame, CA",
                "name": "Hoover Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "schoolDistrict": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Burlingame Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                        "location": "Burlingame, CA",
                        "name": "Burlingame Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "Burlingame Elementary School District",
                        "tagline": [
                            "School District",
                            "Burlingame, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "burlingame-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Hoover Elementary School",
                "tagline": [
                    "Burlingame Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "hoover-elementary-school-burlingame-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 224
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {},
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "85557cbc-2aa4-4a92-b72e-5bb7765086e1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 140,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.318646,
                "lon": -122.02315
            },
            "entity": {
                "abbreviation": "L. P. Collins Elementary School",
                "alternates": {
                    "nces": "061029008815"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "85557cbc-2aa4-4a92-b72e-5bb7765086e1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Cupertino, CA",
                "name": "L.P. Collins Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "L. P. Collins Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lp-collins-elementary-school-cupertino-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 702
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.028,37.3289,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.028,37.3289,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95014"
            }
        ]
    },
    {
        "guid": "d3f0588a-a423-4165-8754-8f984a160d70",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 141,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.863431,
                "lon": -122.092266
            },
            "entity": {
                "abbreviation": "Burton Valley Elementary School",
                "alternates": {
                    "nces": "062031002446"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d3f0588a-a423-4165-8754-8f984a160d70",
                "isClaimed": false,
                "isPremium": false,
                "location": "Lafayette, CA",
                "name": "Burton Valley Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                    "schoolDistrict": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                    "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Lafayette Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "265d7a5c-75c5-4d25-9025-e234bf71c5c4",
                        "location": "Lafayette, CA",
                        "name": "Lafayette Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e4e259e3-74e6-485c-8a9b-9d48c8298442",
                            "zipCode": "3bbdf3eb-e3f7-45ec-a476-cd13a4bb1d7f"
                        },
                        "premium": false,
                        "shortName": "Lafayette Elementary School District",
                        "tagline": [
                            "School District",
                            "Lafayette, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "lafayette-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Burton Valley Elementary School",
                "tagline": [
                    "Lafayette Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "burton-valley-elementary-school-lafayette-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 798
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.093,37.8618,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.093,37.8618,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94549"
            }
        ]
    },
    {
        "guid": "2c81892d-b29b-491e-a4e0-ba8bcbf83569",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 142,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.579014,
                "lon": -122.355803
            },
            "entity": {
                "abbreviation": "McKinley Elementary School",
                "alternates": {
                    "nces": "060648000584"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2c81892d-b29b-491e-a4e0-ba8bcbf83569",
                "isClaimed": false,
                "isPremium": false,
                "location": "Burlingame, CA",
                "name": "McKinley Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "schoolDistrict": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                    "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Burlingame Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "961e1665-774c-4f0f-ba78-56fa3ff1977f",
                        "location": "Burlingame, CA",
                        "name": "Burlingame Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1a87cc05-15c2-4905-a5e0-7218bf42bae4",
                            "zipCode": "1772eca3-601d-475d-ba5b-465907251d74"
                        },
                        "premium": false,
                        "shortName": "Burlingame Elementary School District",
                        "tagline": [
                            "School District",
                            "Burlingame, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "burlingame-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "McKinley Elementary School",
                "tagline": [
                    "Burlingame Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "mckinley-elementary-school-burlingame-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 537
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.357,37.5789,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.357,37.5789,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94010"
            }
        ]
    },
    {
        "guid": "14522d45-59a5-4fc7-b75c-0c76e7129256",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 143,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.577018,
                "lon": -121.984519
            },
            "entity": {
                "abbreviation": "Niles Elementary School",
                "alternates": {
                    "nces": "061440001680"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "14522d45-59a5-4fc7-b75c-0c76e7129256",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Niles Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Niles Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "niles-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 588
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We are parents of a 3rd grader and a Kindergartner - completely satisfied with the school, teachers, and community. Teachers have been great, and our kids are building a solid foundation.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-05-31T05:26:52.813959Z",
                "guid": "565b4b02-9a17-4399-902b-3ba4124e77b4",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.985,37.5761,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.985,37.5761,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "438e3d6e-ba60-45d4-9e4e-883a172670d8",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 144,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.029683,
                "lon": -122.573564
            },
            "entity": {
                "abbreviation": "Dixie Elementary School",
                "alternates": {
                    "nces": "061122001244"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "438e3d6e-ba60-45d4-9e4e-883a172670d8",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Rafael, CA",
                "name": "Dixie Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0386848a-dbb0-4765-be3e-653f41d96543",
                    "parent": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                    "schoolDistrict": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Dixie Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                        "location": "San Rafael, CA",
                        "name": "Dixie Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                            "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                        },
                        "premium": false,
                        "shortName": "Dixie Elementary School District",
                        "tagline": [
                            "School District",
                            "San Rafael, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dixie-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Dixie Elementary School",
                "tagline": [
                    "Dixie Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "dixie-elementary-school-san-rafael-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 419
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.574,38.0291,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.574,38.0291,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94903"
            }
        ]
    },
    {
        "guid": "5f12b8c8-f4fd-4191-8d9e-bc1113303ebd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 145,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.717067,
                "lon": -122.073649
            },
            "entity": {
                "abbreviation": "Proctor Elementary School",
                "alternates": {
                    "nces": "060780000749"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5f12b8c8-f4fd-4191-8d9e-bc1113303ebd",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Proctor Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Proctor Elementary School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "proctor-elementary-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 511
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Proctor Elementary SchoolProctor Elementary SchoolProctor Elementary SchoolProctor Elementary SchoolProctor Elementary SchoolProctor Elementary SchoolProctor Elementary SchoolProctor Elementary SchoolProctor Elementary SchoolProctor Elementary SchoolProctor Elementary SchoolProctor Elementary School",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-05-30T13:16:04.064287Z",
                "guid": "fd874a38-ba77-4540-a51b-30ee988c56ce",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.072,37.7158,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.072,37.7158,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94546"
            }
        ]
    },
    {
        "guid": "603518b4-e7ce-4212-ac7d-9741b2701655",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 146,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.902109,
                "lon": -122.498188
            },
            "entity": {
                "abbreviation": "Bel Aire Elementary School",
                "alternates": {
                    "nces": "063216004975"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "603518b4-e7ce-4212-ac7d-9741b2701655",
                "isClaimed": false,
                "isPremium": false,
                "location": "Tiburon, CA",
                "name": "Bel Aire Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6c40224d-6d11-4b9a-8d5b-18325326110d",
                    "schoolDistrict": "6c40224d-6d11-4b9a-8d5b-18325326110d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "90907f23-d6a7-4db0-ba2b-2e8f545b1d88",
                    "zipCode": "1d3dcee8-86cc-475e-976d-9cb9a69814a7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Reed Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6c40224d-6d11-4b9a-8d5b-18325326110d",
                        "location": "Tiburon, CA",
                        "name": "Reed Union Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "90907f23-d6a7-4db0-ba2b-2e8f545b1d88",
                            "zipCode": "1d3dcee8-86cc-475e-976d-9cb9a69814a7"
                        },
                        "premium": false,
                        "shortName": "Reed Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Tiburon, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "reed-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Bel Aire Elementary School",
                "tagline": [
                    "Reed Union Elementary School District, CA",
                    "3-5"
                ],
                "type": "School",
                "url": "bel-aire-elementary-school-tiburon-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 459
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We moved from a San Francisco private school with 2 kids. We were nervous at first transferring during COVID. My kids opted for in person learning and the teachers have done a phenomenal job-responsive, organized, kind. My fifth grader  had a differentiated learning experience in math when we asked more of a challenge.  The community has been kind  welcoming us during COVID. Enrichment such as Spanish, music, art, PE and library are outstanding. There are clubs at no cost such as math, band, chorus, C4C, a TV prod. club and more. They provide help for kids that need more in breakouts after school. The academics are on par with our former San Francisco private school. Our kids do not feel stressed out  and are very happy with their new friends. I am impressed by the COVID in person protocols (e.g. PPE, plexiglass, tents, etc.). The Foundation for Reed Schools spent $1mm+ in COVID safety. Highly recommend coming to Bel Aire - an intimate community with entire town in the same school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-02-11T04:50:27.02218Z",
                "guid": "af813946-f43d-44e2-9ca2-60fcca31b7bf",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.497,37.9032,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.497,37.9032,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94920"
            }
        ]
    },
    {
        "guid": "b36422af-862a-496e-8bb3-e47914a232fe",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 147,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.542185,
                "lon": -121.939837
            },
            "entity": {
                "abbreviation": "Joshua Chadbourne Elementary School",
                "alternates": {
                    "nces": "061440001661"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b36422af-862a-496e-8bb3-e47914a232fe",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Joshua Chadbourne Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "f8323aa0-74a4-4cb1-84e3-cfe331752916"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Joshua Chadbourne Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "joshua-chadbourne-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 734
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Everything here is excellent! My little boy attends Chadbourne, and he absolutely loves it. he tells me he wants to go to school every day, He became a student who is eager to learn.  The teachers are caring, well-prepared, and dedicated. the engaged parents and PTA. The school also managed to create a very safe and welcoming environment while still keeping the academics competitive.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-26T08:05:49.213412Z",
                "guid": "a980782c-7e7a-45c2-838a-2339506c54f2",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.94,37.5433,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.94,37.5433,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94539"
            }
        ]
    },
    {
        "guid": "7c874399-0d1d-417b-92f3-5ee3b8784d73",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 148,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.861508,
                "lon": -122.251185
            },
            "entity": {
                "abbreviation": "Emerson Elementary School",
                "alternates": {
                    "nces": "060474000436"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7c874399-0d1d-417b-92f3-5ee3b8784d73",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Emerson Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "6f1cd314-8bca-4f9f-b431-9c4c14bb56a8",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "b9dd7946-c2a7-4ae6-8e94-d5fea0a9aa3d"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Emerson Elementary School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "emerson-elementary-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 320
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.252,37.8616,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.252,37.8616,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94705"
            }
        ]
    },
    {
        "guid": "b2350f7a-e294-4b0d-981b-ca144c26d346",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 149,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.29007,
                "lon": -122.02593
            },
            "entity": {
                "abbreviation": "Blue Hills Elementary School",
                "alternates": {
                    "nces": "061029001125"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b2350f7a-e294-4b0d-981b-ca144c26d346",
                "isClaimed": false,
                "isPremium": false,
                "location": "Saratoga, CA",
                "name": "Blue Hills Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                    "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Blue Hills Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "blue-hills-elementary-school-saratoga-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 339
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.025,37.2891,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.025,37.2891,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95070"
            }
        ]
    },
    {
        "guid": "5dd1fe08-73c3-4f32-892e-a97e6275f4e4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 150,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.56116,
                "lon": -122.051461
            },
            "entity": {
                "abbreviation": "Forest Park Elementary School",
                "alternates": {
                    "nces": "061440000488"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5dd1fe08-73c3-4f32-892e-a97e6275f4e4",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Forest Park Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "59bd583b-4aa7-49e1-8439-175d9bb43663"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Forest Park Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "forest-park-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 1011
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I would like to see more encouragement towards students that are not mentally gifted. Also, there should be a good program for the arts. However, this school is very good for academics, better than most private schools in the area.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-23T10:34:11.395157Z",
                "guid": "b19f4e42-8125-42dc-aa6a-b5de7731b92c",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.052,37.5621,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.052,37.5621,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94555"
            }
        ]
    },
    {
        "entities": [
            {
                "guid": "3294333a-4a10-4264-a9ed-d1e6fe726e42",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 151,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.329088,
                        "lon": -121.989399
                    },
                    "entity": {
                        "abbreviation": "Dwight D. Eisenhower Elementary School",
                        "alternates": {
                            "nces": "061029001132"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "3294333a-4a10-4264-a9ed-d1e6fe726e42",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Santa Clara, CA",
                        "name": "Dwight D. Eisenhower Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Cupertino Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                                "location": "Sunnyvale, CA",
                                "name": "Cupertino Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                                },
                                "premium": false,
                                "shortName": "Cupertino Union School District",
                                "tagline": [
                                    "School District",
                                    "Sunnyvale, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "cupertino-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Dwight D. Eisenhower Elementary School",
                        "tagline": [
                            "Cupertino Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "dwight-d-eisenhower-elementary-school-santa-clara-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 574
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.989,37.329,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.989,37.329,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95051"
                    }
                ]
            },
            {
                "guid": "60e7966b-35aa-44c7-9d1e-73cb67009618",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 152,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.236119,
                        "lon": -121.84849
                    },
                    "entity": {
                        "abbreviation": "Discovery Charter II",
                        "alternates": {
                            "nces": "060241013640"
                        },
                        "character": "Public, Charter",
                        "claimed": true,
                        "displayable": true,
                        "genus": "Charter School",
                        "guid": "60e7966b-35aa-44c7-9d1e-73cb67009618",
                        "isClaimed": true,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "Discovery Charter II",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                            "schoolNetwork": "11465407-60e3-4192-8083-e8f010b6f06f",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                        },
                        "premium": false,
                        "shortName": "Discovery Charter II",
                        "tagline": [
                            "Public School",
                            "San Jose, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "discovery-charter-ii-san-jose-ca",
                        "variation": 57
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 584
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 25
                        }
                    ],
                    "featuredReview": {
                        "author": "Parent",
                        "body": "My family has attended Discovery Charter School 2 Falcon Campus for the last 5 years.\n\nTeachers are able to follow the childrens’ lead for curriculum, and present opportunities to engage in hands-on activities including many field trips. One concern I had about specific academic needs not being met was swiflty addressed and adaptions were made, with the support of teachers and the director.\n\nSocial and emotional needs are supported at the school, and everyone is on the same page, responding to the children using Positive Discipline techniques. \n\nDiscovery provides the opportunity to stay with teachers for multiple years (looping). \n\nMost of the parents regularly volunteer, and you can really create a village here.\n\nMy children are excited to go to school. Learning is fun and curiosity is encouraged. I am excited my children are enjoying their education, as their experiences there will likely result in life-long, engaged learners. I highly recommend you check D2 out!",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2020-01-29T23:16:36.137922Z",
                        "guid": "16fc8468-e1cd-43d2-96b6-146c324e3c60",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.303,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.303,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 3.6666666666666665,
                        "count": 3
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95123"
                    }
                ]
            },
            {
                "guid": "2ff60a84-9678-422e-8063-cfad24e7a255",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 153,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.560037,
                        "lon": -121.985216
                    },
                    "entity": {
                        "abbreviation": "Parkmont Elementary School",
                        "alternates": {
                            "nces": "061440001684"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "2ff60a84-9678-422e-8063-cfad24e7a255",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Fremont, CA",
                        "name": "Parkmont Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "FUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                                "location": "Fremont, CA",
                                "name": "Fremont Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                                },
                                "premium": false,
                                "shortName": "Fremont Unified School District",
                                "tagline": [
                                    "School District",
                                    "Fremont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "fremont-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Parkmont Elementary School",
                        "tagline": [
                            "Fremont Unified School District, CA",
                            "K-6"
                        ],
                        "type": "School",
                        "url": "parkmont-elementary-school-fremont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 885
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 27
                        }
                    ],
                    "featuredReview": {
                        "author": "Middle School Student",
                        "body": "These were one of the best years of my life, spent at this school. I recommend it 110%. One of the things though is that the inside of the cafeteria should be cleaned better before older grades come to eat lunch. While learning at this school, I found myself many times forced to eat outside even when it was raining, because of the poor quality of the cafeteria.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2019-03-12T22:40:53.070058Z",
                        "guid": "2748705d-f5c1-4e0e-a488-0dab3a0ce7d7",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.984,37.5613,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.984,37.5613,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94536"
                    }
                ]
            },
            {
                "guid": "83044971-989c-41cb-8f8f-28d51ff55440",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 154,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.535083,
                        "lon": -122.237566
                    },
                    "entity": {
                        "abbreviation": "Sandpiper Elementary School",
                        "alternates": {
                            "nces": "060453007417"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "83044971-989c-41cb-8f8f-28d51ff55440",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Redwood City, CA",
                        "name": "Sandpiper Elementary School",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "d9fcdec8-decf-4863-b3f9-67748b4dc339",
                            "parent": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                            "schoolDistrict": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "80b04c41-59c1-4587-8f7c-24efc88eaf9a"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Belmont-Redwood Shores Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                                "location": "Belmont, CA",
                                "name": "Belmont-Redwood Shores Elementary School District",
                                "parentGUIDs": {
                                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                                    "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                                },
                                "premium": false,
                                "shortName": "Belmont-Redwood Shores Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Belmont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "belmont-redwood-shores-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Sandpiper Elementary School",
                        "tagline": [
                            "Belmont-Redwood Shores Elementary School District, CA",
                            "K-7"
                        ],
                        "type": "School",
                        "url": "sandpiper-elementary-school-redwood-city-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 617
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 27
                        }
                    ],
                    "featuredReview": {
                        "author": "Middle School Student",
                        "body": "The teachers and staff are amazing, they care about their students and all the students like their teachers.  Sandpiper does an amazing job of making everyone feel welcome and they have renovating the school recently making it even nicer than it was before.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2020-03-10T04:13:31.825829Z",
                        "guid": "e244de61-0074-43f4-ad6b-9ab9fe0a9df6",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.5378,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.5378,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94065"
                    }
                ]
            },
            {
                "guid": "631fe13f-3bbb-4719-bf16-a3e610fcb46c",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 155,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.487001,
                        "lon": -121.923405
                    },
                    "entity": {
                        "abbreviation": "James Leitch Elementary School",
                        "alternates": {
                            "nces": "061440001673"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "631fe13f-3bbb-4719-bf16-a3e610fcb46c",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Fremont, CA",
                        "name": "James Leitch Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "f8323aa0-74a4-4cb1-84e3-cfe331752916"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "FUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                                "location": "Fremont, CA",
                                "name": "Fremont Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                                },
                                "premium": false,
                                "shortName": "Fremont Unified School District",
                                "tagline": [
                                    "School District",
                                    "Fremont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "fremont-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "James Leitch Elementary School",
                        "tagline": [
                            "Fremont Unified School District, CA",
                            "K-3"
                        ],
                        "type": "School",
                        "url": "james-leitch-elementary-school-fremont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 857
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 20
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.922,37.488,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.922,37.488,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94539"
                    }
                ]
            },
            {
                "guid": "4e2bf24d-b237-498b-9327-ba37ada6d556",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 156,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.519231,
                        "lon": -121.967446
                    },
                    "entity": {
                        "abbreviation": "Steven Millard Elementary School",
                        "alternates": {
                            "nces": "061440001676"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "4e2bf24d-b237-498b-9327-ba37ada6d556",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Fremont, CA",
                        "name": "Steven Millard Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "FUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                                "location": "Fremont, CA",
                                "name": "Fremont Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                                },
                                "premium": false,
                                "shortName": "Fremont Unified School District",
                                "tagline": [
                                    "School District",
                                    "Fremont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "fremont-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Steven Millard Elementary School",
                        "tagline": [
                            "Fremont Unified School District, CA",
                            "K-6"
                        ],
                        "type": "School",
                        "url": "steven-millard-elementary-school-fremont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 577
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "featuredReview": {
                        "author": "Parent",
                        "body": "Great school with a great new Principal. Dedicated and active PTA. There are only 2 teachers out of a total of 26 that I have heard negative things about.  I would rate them a 5 if they would have field trips.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2017-10-13T04:47:43.824274Z",
                        "guid": "4c692250-d3b5-4057-9008-505710a2e6af",
                        "rating": 4
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.974,37.519,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.974,37.519,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 4,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94538"
                    }
                ]
            },
            {
                "guid": "ae37abea-ca01-48f9-8990-f80369f2faae",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 157,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.7692,
                        "lon": -122.261959
                    },
                    "entity": {
                        "abbreviation": "Franklin Elementary School",
                        "alternates": {
                            "nces": "060177004337"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "ae37abea-ca01-48f9-8990-f80369f2faae",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Alameda, CA",
                        "name": "Franklin Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                            "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "AUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                                "location": "Alameda, CA",
                                "name": "Alameda Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                                },
                                "premium": false,
                                "shortName": "Alameda City Unified School District",
                                "tagline": [
                                    "School District",
                                    "Alameda, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "alameda-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Franklin Elementary School",
                        "tagline": [
                            "Alameda Unified School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "franklin-elementary-school-alameda-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 335
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 26
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.263,37.7693,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.263,37.7693,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94501"
                    }
                ]
            },
            {
                "guid": "4b8e15fb-3f7c-4231-870b-ef41e52f0104",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 158,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.714676,
                        "lon": -121.922059
                    },
                    "entity": {
                        "abbreviation": "Frederiksen Elementary School",
                        "alternates": {
                            "nces": "060001909275"
                        },
                        "character": "Public",
                        "claimed": true,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "4b8e15fb-3f7c-4231-870b-ef41e52f0104",
                        "isClaimed": true,
                        "isPremium": false,
                        "location": "Dublin, CA",
                        "name": "Frederiksen Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                            "schoolDistrict": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                            "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "DUSD",
                                "claimed": true,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "1eb7d1e3-3645-4686-b9c1-1f8cde48db34",
                                "location": "Dublin, CA",
                                "name": "Dublin Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "9b40368d-b46b-4aea-bc65-0b503ad7997e",
                                    "zipCode": "bdff8a37-efb0-409f-87de-4220f60652e9"
                                },
                                "premium": false,
                                "shortName": "Dublin Unified School District",
                                "tagline": [
                                    "School District",
                                    "Dublin, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "dublin-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Frederiksen Elementary School",
                        "tagline": [
                            "Dublin Unified School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "frederiksen-elementary-school-dublin-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 800
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 28
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.923,37.7152,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.923,37.7152,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94568"
                    }
                ]
            },
            {
                "guid": "3eead52c-96a1-49cb-aec9-79073b8ba76e",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 159,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.519302,
                        "lon": -121.958588
                    },
                    "entity": {
                        "abbreviation": "Harvey Green Elementary School",
                        "alternates": {
                            "nces": "061440001665"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "3eead52c-96a1-49cb-aec9-79073b8ba76e",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Fremont, CA",
                        "name": "Harvey Green Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "FUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                                "location": "Fremont, CA",
                                "name": "Fremont Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                                },
                                "premium": false,
                                "shortName": "Fremont Unified School District",
                                "tagline": [
                                    "School District",
                                    "Fremont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "fremont-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Harvey Green Elementary School",
                        "tagline": [
                            "Fremont Unified School District, CA",
                            "K-6"
                        ],
                        "type": "School",
                        "url": "harvey-green-elementary-school-fremont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 517
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 26
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.96,37.5191,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.96,37.5191,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94538"
                    }
                ]
            },
            {
                "guid": "63524106-14a5-449c-83ca-d6a2d0dde16b",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 160,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.240757,
                        "lon": -121.981127
                    },
                    "entity": {
                        "abbreviation": "Daves Avenue Elementary School",
                        "alternates": {
                            "nces": "062283003490"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "63524106-14a5-449c-83ca-d6a2d0dde16b",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Los Gatos, CA",
                        "name": "Daves Avenue Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "2657e046-c2e2-4cea-822c-45bceb563d45",
                            "schoolDistrict": "2657e046-c2e2-4cea-822c-45bceb563d45",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "00e76fa9-4e6a-44a3-a238-6c95fbd5e104",
                            "zipCode": "c738c179-3382-4e23-acb0-8960870c64d9"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Los Gatos Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "2657e046-c2e2-4cea-822c-45bceb563d45",
                                "location": "Los Gatos, CA",
                                "name": "Los Gatos Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                                    "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                                },
                                "premium": false,
                                "shortName": "Los Gatos Union School District",
                                "tagline": [
                                    "School District",
                                    "Los Gatos, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "los-gatos-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Daves Avenue Elementary School",
                        "tagline": [
                            "Los Gatos Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "daves-avenue-elementary-school-los-gatos-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 491
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.981,37.2402,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.981,37.2402,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95030"
                    }
                ]
            },
            {
                "guid": "644cf05a-7d52-4d59-974c-59103877637d",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 161,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.357979,
                        "lon": -122.166323
                    },
                    "entity": {
                        "abbreviation": "Central Elementary School",
                        "alternates": {
                            "nces": "060453000414"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "644cf05a-7d52-4d59-974c-59103877637d",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Belmont, CA",
                        "name": "Central Elementary School",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                            "schoolDistrict": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4c92d151-0663-44f5-b6fe-f6beae4bde74",
                            "zipCode": "518d850a-d6a6-4bdc-9dfb-0f338f1c7264"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Belmont-Redwood Shores Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                                "location": "Belmont, CA",
                                "name": "Belmont-Redwood Shores Elementary School District",
                                "parentGUIDs": {
                                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                                    "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                                },
                                "premium": false,
                                "shortName": "Belmont-Redwood Shores Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Belmont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "belmont-redwood-shores-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Central Elementary School",
                        "tagline": [
                            "Belmont-Redwood Shores Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "central-elementary-school-belmont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 440
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 26
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.283,37.5241,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.283,37.5241,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94002"
                    }
                ]
            },
            {
                "guid": "4bf3275d-d1cf-4a5e-a16f-7d058ac5d118",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 162,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.31615,
                        "lon": -122.01042
                    },
                    "entity": {
                        "abbreviation": "D. J. Sedgwick Elementary School",
                        "alternates": {
                            "nces": "061029004034"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "4bf3275d-d1cf-4a5e-a16f-7d058ac5d118",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Cupertino, CA",
                        "name": "D.J. Sedgwick Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Cupertino Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                                "location": "Sunnyvale, CA",
                                "name": "Cupertino Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                                },
                                "premium": false,
                                "shortName": "Cupertino Union School District",
                                "tagline": [
                                    "School District",
                                    "Sunnyvale, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "cupertino-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "D. J. Sedgwick Elementary School",
                        "tagline": [
                            "Cupertino Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "dj-sedgwick-elementary-school-cupertino-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 617
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 26
                        }
                    ],
                    "featuredReview": {
                        "author": "Alum",
                        "body": "I had a very positive experience in elementary school; I loved my teachers and they loved me back! I transferred to Sedgwick when I started 3rd grade and the transition was easy for me because the teachers were so welcoming.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2017-04-28T03:51:47.22009Z",
                        "guid": "c5817651-4a03-4648-9977-39b9b6393c99",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.009,37.3153,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.009,37.3153,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95014"
                    }
                ]
            },
            {
                "guid": "4f3dec12-e2b2-444d-89f8-149e068f8fb4",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 163,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.228753,
                        "lon": -121.966643
                    },
                    "entity": {
                        "abbreviation": "Louise Van Meter Elementary School",
                        "alternates": {
                            "nces": "062283003492"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "4f3dec12-e2b2-444d-89f8-149e068f8fb4",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Los Gatos, CA",
                        "name": "Louise Van Meter Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "2657e046-c2e2-4cea-822c-45bceb563d45",
                            "schoolDistrict": "2657e046-c2e2-4cea-822c-45bceb563d45",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                            "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Los Gatos Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "2657e046-c2e2-4cea-822c-45bceb563d45",
                                "location": "Los Gatos, CA",
                                "name": "Los Gatos Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                                    "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                                },
                                "premium": false,
                                "shortName": "Los Gatos Union School District",
                                "tagline": [
                                    "School District",
                                    "Los Gatos, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "los-gatos-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Louise Van Meter Elementary School",
                        "tagline": [
                            "Los Gatos Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "louise-van-meter-elementary-school-los-gatos-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 536
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.966,37.2304,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.966,37.2304,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95032"
                    }
                ]
            },
            {
                "guid": "04edbdab-7337-4f5e-8074-a206085b9354",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 164,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.528714,
                        "lon": -121.921586
                    },
                    "entity": {
                        "abbreviation": "Mission San Jose Elementary School",
                        "alternates": {
                            "nces": "061440001677"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "04edbdab-7337-4f5e-8074-a206085b9354",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Fremont, CA",
                        "name": "Mission San Jose Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "f8323aa0-74a4-4cb1-84e3-cfe331752916"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "FUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                                "location": "Fremont, CA",
                                "name": "Fremont Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                                },
                                "premium": false,
                                "shortName": "Fremont Unified School District",
                                "tagline": [
                                    "School District",
                                    "Fremont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "fremont-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Mission San Jose Elementary School",
                        "tagline": [
                            "Fremont Unified School District, CA",
                            "K-6"
                        ],
                        "type": "School",
                        "url": "mission-san-jose-elementary-school-fremont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 535
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 24
                        }
                    ],
                    "featuredReview": {
                        "author": "Parent",
                        "body": "I really like their academics methods. They teach children not only academics knowledge but also social and emotion. Students would have more confidence in learning and be more comfortable to attend school. However, some teachers are focused on the textbook and tests too much, which leads students to study for the exam but not the interests. I would recommend teaching in a joyful and informative way would help.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2019-11-16T09:06:27.029383Z",
                        "guid": "6d655833-a69a-4c02-8db3-f015909664b9",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.922,37.5287,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.922,37.5287,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 2
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94539"
                    }
                ]
            },
            {
                "guid": "b2b97917-8e09-4437-bc32-4005b0a3d0b2",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 165,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.483429,
                        "lon": -121.925947
                    },
                    "entity": {
                        "abbreviation": "Warm Springs Elementary School",
                        "alternates": {
                            "nces": "061440001690"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "b2b97917-8e09-4437-bc32-4005b0a3d0b2",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Fremont, CA",
                        "name": "Warm Springs Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "f8323aa0-74a4-4cb1-84e3-cfe331752916"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "FUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                                "location": "Fremont, CA",
                                "name": "Fremont Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                                },
                                "premium": false,
                                "shortName": "Fremont Unified School District",
                                "tagline": [
                                    "School District",
                                    "Fremont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "fremont-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Warm Springs Elementary School",
                        "tagline": [
                            "Fremont Unified School District, CA",
                            "3-6"
                        ],
                        "type": "School",
                        "url": "warm-springs-elementary-school-fremont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 1054
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 25
                        }
                    ],
                    "featuredReview": {
                        "author": "Alum",
                        "body": "it was pretty good. Some teachers were really nice but I heard it’s more compacted now due to the population growth",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2021-03-14T15:39:59.95577Z",
                        "guid": "91fbaa15-531c-4054-adf8-d8c6d2f61422",
                        "rating": 4
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.923,37.4828,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.923,37.4828,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 4,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94539"
                    }
                ]
            },
            {
                "guid": "59d92ebe-5d21-416d-a3fb-bf77100f36d7",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 166,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.888899,
                        "lon": -122.066783
                    },
                    "entity": {
                        "abbreviation": "Tice Creek",
                        "alternates": {
                            "nces": "064125013866"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "59d92ebe-5d21-416d-a3fb-bf77100f36d7",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Walnut Creek, CA",
                        "name": "Tice Creek",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                            "schoolDistrict": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "549b68b5-65f5-4e14-8c39-f3e25edc69a0",
                            "zipCode": "0f5bfe3e-c4e2-4b41-afd1-7f0c717be19f"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Walnut Creek Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                                "location": "Walnut Creek, CA",
                                "name": "Walnut Creek Elementary School District",
                                "parentGUIDs": {
                                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                                    "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                                },
                                "premium": false,
                                "shortName": "Walnut Creek Elementary School District",
                                "tagline": [
                                    "School District",
                                    "Walnut Creek, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "walnut-creek-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Tice Creek",
                        "tagline": [
                            "Walnut Creek Elementary School District, CA",
                            "K-8"
                        ],
                        "type": "School",
                        "url": "tice-creek-walnut-creek-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 427
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 22
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.8891,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.8891,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94595"
                    }
                ]
            },
            {
                "guid": "92192969-7790-4902-8746-ee965036dd7e",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 167,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.306297,
                        "lon": -122.006943
                    },
                    "entity": {
                        "abbreviation": "Nelson S. Dilworth Elementary School",
                        "alternates": {
                            "nces": "061029001129"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "92192969-7790-4902-8746-ee965036dd7e",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "Nelson S. Dilworth Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "ca7b746e-cd3c-474a-a264-ac5c6e4ff339"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Cupertino Union School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                                "location": "Sunnyvale, CA",
                                "name": "Cupertino Union School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                                },
                                "premium": false,
                                "shortName": "Cupertino Union School District",
                                "tagline": [
                                    "School District",
                                    "Sunnyvale, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "cupertino-union-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Nelson S. Dilworth Elementary School",
                        "tagline": [
                            "Cupertino Union School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "nelson-s-dilworth-elementary-school-san-jose-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 435
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 24
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.007,37.3065,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.007,37.3065,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95129"
                    }
                ]
            },
            {
                "guid": "0fe4a4f0-5738-478f-aa37-c591455b8d66",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 168,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.707117,
                        "lon": -122.090194
                    },
                    "entity": {
                        "abbreviation": "Chabot Elementary School",
                        "alternates": {
                            "nces": "060780007661"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "0fe4a4f0-5738-478f-aa37-c591455b8d66",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Castro Valley, CA",
                        "name": "Chabot Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                            "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Castro Valley Unified School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                                "location": "Castro Valley, CA",
                                "name": "Castro Valley Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                                    "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                                },
                                "premium": false,
                                "shortName": "Castro Valley Unified School District",
                                "tagline": [
                                    "School District",
                                    "Castro Valley, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "castro-valley-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Chabot Elementary School",
                        "tagline": [
                            "Castro Valley Unified School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "chabot-elementary-school-castro-valley-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 464
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 26
                        }
                    ],
                    "featuredReview": {
                        "author": "Niche User",
                        "body": "The teacher was very understanding and flexible. Especially with COVID and everything still being 100% online this was a major relief. Thank you for providing all the necessary teaching staff, including paraeducators/teaching assistants!",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2021-01-11T03:56:33.831165Z",
                        "guid": "b6ab5654-800f-42c3-a93c-dc38df9c774a",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.089,37.7072,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.089,37.7072,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 1
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94546"
                    }
                ]
            },
            {
                "guid": "205a096d-5647-4162-9c80-fcb83b42edf0",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 169,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.248299,
                        "lon": -121.945658
                    },
                    "entity": {
                        "abbreviation": "Carlton Elementary School",
                        "alternates": {
                            "nces": "064032006669"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "205a096d-5647-4162-9c80-fcb83b42edf0",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Jose, CA",
                        "name": "Carlton Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "parent": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                            "schoolDistrict": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Union Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                                "location": "San Jose, CA",
                                "name": "Union Elementary School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                                },
                                "premium": false,
                                "shortName": "Union Elementary School District",
                                "tagline": [
                                    "School District",
                                    "San Jose, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "union-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Carlton Elementary School",
                        "tagline": [
                            "Union Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "carlton-elementary-school-san-jose-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 710
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 26
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.946,37.2474,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.946,37.2474,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95124"
                    }
                ]
            },
            {
                "guid": "533f7c6a-da27-415f-af26-5bf21dd1c892",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 170,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.240044,
                        "lon": -121.940996
                    },
                    "entity": {
                        "abbreviation": "Alta Vista Elementary School",
                        "alternates": {
                            "nces": "064032006667"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "533f7c6a-da27-415f-af26-5bf21dd1c892",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Los Gatos, CA",
                        "name": "Alta Vista Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "parent": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                            "schoolDistrict": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                            "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Union Elementary School District",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                                "location": "San Jose, CA",
                                "name": "Union Elementary School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                                },
                                "premium": false,
                                "shortName": "Union Elementary School District",
                                "tagline": [
                                    "School District",
                                    "San Jose, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "union-elementary-school-district-ca",
                                "variation": 63
                            }
                        },
                        "premium": false,
                        "shortName": "Alta Vista Elementary School",
                        "tagline": [
                            "Union Elementary School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "alta-vista-elementary-school-los-gatos-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 649
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 24
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.941,37.239,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.941,37.239,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95032"
                    }
                ]
            },
            {
                "guid": "6ad09294-17ec-4a4d-a3d2-efd5b238c1a2",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 171,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.535975,
                        "lon": -121.947756
                    },
                    "entity": {
                        "abbreviation": "Mission Valley Elementary School",
                        "alternates": {
                            "nces": "061440001679"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "6ad09294-17ec-4a4d-a3d2-efd5b238c1a2",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Fremont, CA",
                        "name": "Mission Valley Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "f8323aa0-74a4-4cb1-84e3-cfe331752916"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "FUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                                "location": "Fremont, CA",
                                "name": "Fremont Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                                },
                                "premium": false,
                                "shortName": "Fremont Unified School District",
                                "tagline": [
                                    "School District",
                                    "Fremont, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "fremont-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Mission Valley Elementary School",
                        "tagline": [
                            "Fremont Unified School District, CA",
                            "K-6"
                        ],
                        "type": "School",
                        "url": "mission-valley-elementary-school-fremont-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 655
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 23
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.946,37.5366,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.946,37.5366,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94539"
                    }
                ]
            },
            {
                "guid": "aa49f3fe-8dbd-41c4-931c-bdc28a3f4c8c",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 172,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.783843,
                        "lon": -122.464575
                    },
                    "entity": {
                        "abbreviation": "George Peabody Elementary",
                        "alternates": {
                            "nces": "063441005614"
                        },
                        "character": "Public",
                        "claimed": true,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "aa49f3fe-8dbd-41c4-931c-bdc28a3f4c8c",
                        "isClaimed": true,
                        "isPremium": false,
                        "location": "San Francisco, CA",
                        "name": "George Peabody Elementary",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "4c5b2024-bead-4cc8-b4bc-873b3b375d10",
                            "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "1b2bdc40-30b1-4227-bccb-fd8c4930354e"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "SFUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                                "location": "San Francisco, CA",
                                "name": "San Francisco Unified School District",
                                "parentGUIDs": {
                                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                                    "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                                },
                                "premium": false,
                                "shortName": "San Francisco Unified School District",
                                "tagline": [
                                    "School District",
                                    "San Francisco, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "san-francisco-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "George Peabody Elementary",
                        "tagline": [
                            "San Francisco Unified School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "george-peabody-elementary-san-francisco-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 271
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 25
                        }
                    ],
                    "featuredReview": {
                        "author": "Parent",
                        "body": "My 2 kids are in Peabody. Teachers and all staffs are very friendly, knowledgeable. Especially we are thankful to Ms. Krey and Ms. Holway they are amazing, very helpful.",
                        "categories": [
                            "Overall Experience"
                        ],
                        "created": "2019-10-27T17:22:00.535619Z",
                        "guid": "ff8ddb2d-ea2b-4524-b3e3-eea9da7742a4",
                        "rating": 5
                    },
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.465,37.7839,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.465,37.7839,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 5,
                        "count": 2
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94118"
                    }
                ]
            },
            {
                "guid": "bc68ce77-1315-40f8-98b1-1e7e942ca38b",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 173,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.342706,
                        "lon": -121.940711
                    },
                    "entity": {
                        "abbreviation": "Washington Elementary School",
                        "alternates": {
                            "nces": "063543006650"
                        },
                        "character": "Public",
                        "claimed": true,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "bc68ce77-1315-40f8-98b1-1e7e942ca38b",
                        "isClaimed": true,
                        "isPremium": false,
                        "location": "Santa Clara, CA",
                        "name": "Washington Elementary School",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                            "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "91f8ca89-1d96-436a-8a3f-5e45a8940ad1"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "Santa Clara Unified School District",
                                "claimed": true,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                                "location": "Santa Clara, CA",
                                "name": "Santa Clara Unified School District",
                                "parentGUIDs": {
                                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                                },
                                "premium": false,
                                "shortName": "Santa Clara Unified School District",
                                "tagline": [
                                    "School District",
                                    "Santa Clara, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "santa-clara-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Washington Elementary School",
                        "tagline": [
                            "Santa Clara Unified School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "washington-elementary-school-santa-clara-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 331
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 27
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.941,37.3426,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.941,37.3426,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/95050"
                    }
                ]
            },
            {
                "guid": "eb933cb1-8325-44da-92f8-3fa7c341d240",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 174,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.765349,
                        "lon": -122.234786
                    },
                    "entity": {
                        "abbreviation": "Edison Elementary School",
                        "alternates": {
                            "nces": "060177000044"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "eb933cb1-8325-44da-92f8-3fa7c341d240",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "Alameda, CA",
                        "name": "Edison Elementary School",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                            "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "AUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                                "location": "Alameda, CA",
                                "name": "Alameda Unified School District",
                                "parentGUIDs": {
                                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                                },
                                "premium": false,
                                "shortName": "Alameda City Unified School District",
                                "tagline": [
                                    "School District",
                                    "Alameda, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "alameda-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Edison Elementary School",
                        "tagline": [
                            "Alameda Unified School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "edison-elementary-school-alameda-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 469
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 26
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.7644,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.7644,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94501"
                    }
                ]
            },
            {
                "guid": "e950194b-bb9b-41f7-988f-8b972d51eb18",
                "badge": {
                    "display": "Best Public Elementary Schools in San Francisco Bay Area",
                    "ordinal": 175,
                    "total": 872,
                    "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
                    "photoURLs": {
                        "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                        "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
                    }
                },
                "content": {
                    "centroid": {
                        "lat": 37.739259,
                        "lon": -122.450166
                    },
                    "entity": {
                        "abbreviation": "Miraloma Elementary School",
                        "alternates": {
                            "nces": "063441005648"
                        },
                        "character": "Public",
                        "claimed": false,
                        "displayable": true,
                        "genus": "Public School",
                        "guid": "e950194b-bb9b-41f7-988f-8b972d51eb18",
                        "isClaimed": false,
                        "isPremium": false,
                        "location": "San Francisco, CA",
                        "name": "Miraloma Elementary School",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "cdbf611b-c1ba-4344-bbbe-5ae2fcc53ff5",
                            "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "50a82c65-04cd-4b00-bb26-c5f015b369e3"
                        },
                        "parents": {
                            "parent": {
                                "abbreviation": "SFUSD",
                                "claimed": false,
                                "displayable": true,
                                "genus": "School District",
                                "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                                "location": "San Francisco, CA",
                                "name": "San Francisco Unified School District",
                                "parentGUIDs": {
                                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                                    "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                                    "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                                },
                                "premium": false,
                                "shortName": "San Francisco Unified School District",
                                "tagline": [
                                    "School District",
                                    "San Francisco, CA"
                                ],
                                "type": "SchoolDistrict",
                                "url": "san-francisco-unified-school-district-ca",
                                "variation": 61
                            }
                        },
                        "premium": false,
                        "shortName": "Miraloma Elementary School",
                        "tagline": [
                            "San Francisco Unified School District, CA",
                            "K-5"
                        ],
                        "type": "School",
                        "url": "miraloma-elementary-school-san-francisco-ca",
                        "variation": 45
                    },
                    "facts": [
                        {
                            "config": {
                                "format": [
                                    "comma"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Students",
                            "value": 391
                        },
                        {
                            "config": {
                                "format": [
                                    "ratio"
                                ],
                                "rounding": [
                                    "whole"
                                ]
                            },
                            "label": "Student-Teacher Ratio",
                            "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                            "value": 21
                        }
                    ],
                    "grades": [
                        {
                            "description": "Based on quality of academics, teachers, student life, and outcomes.",
                            "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                            "label": "Overall Niche Grade",
                            "value": 4
                        }
                    ],
                    "photos": {
                        "mapbox_header": {
                            "author": "© Mapbox",
                            "crops": {
                                "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.45,37.739,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                                "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.45,37.739,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                            },
                            "licenseName": "© OpenStreetMap",
                            "licenseUrl": "http://www.openstreetmap.org/copyright",
                            "sourceUrl": "https://www.mapbox.com/about/maps/"
                        }
                    },
                    "reviewAverage": {
                        "average": 0,
                        "count": 0
                    },
                    "virtualTour": [
                        {
                            "label": "Virtual Tour"
                        }
                    ]
                },
                "ctas": [
                    {
                        "label": "View Nearby Homes",
                        "type": "realEstateListings",
                        "url": "https://www.realtor.com/realestateandhomes-search/94127"
                    }
                ]
            }
        ]
    },
    {
        "guid": "a452ee93-0275-4c76-aef9-00365fc954a4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 176,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.276347,
                "lon": -121.98233
            },
            "entity": {
                "abbreviation": "Forest Hill Elementary School",
                "alternates": {
                    "nces": "060720000667"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "a452ee93-0275-4c76-aef9-00365fc954a4",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Forest Hill Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "schoolDistrict": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                    "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Campbell Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                        "location": "Campbell, CA",
                        "name": "Campbell Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                            "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                        },
                        "premium": false,
                        "shortName": "Campbell Union School District",
                        "tagline": [
                            "School District",
                            "Campbell, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "campbell-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Forest Hill Elementary School",
                "tagline": [
                    "Campbell Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "forest-hill-elementary-school-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 657
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.982,37.2753,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.982,37.2753,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95130"
            }
        ]
    },
    {
        "guid": "6091cf0e-d313-498a-adeb-5ebdc802564b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 177,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.706711,
                "lon": -122.060434
            },
            "entity": {
                "abbreviation": "Vannoy Elementary School",
                "alternates": {
                    "nces": "060780000752"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6091cf0e-d313-498a-adeb-5ebdc802564b",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Vannoy Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Vannoy Elementary School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "vannoy-elementary-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 436
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "My sister went to Vannoy for 4 years. The principal, the office ladies and most of her teachers were super super nice. The only thing was her fifth grade teacher was not that nice.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-08-10T15:36:45.920746Z",
                "guid": "41c3a18b-3143-4f68-b94f-b0a01a654218",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.059,37.7074,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.059,37.7074,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94546"
            }
        ]
    },
    {
        "guid": "c5e79df4-d1b8-4054-a1e2-9b1f2ee48558",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 178,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.803071,
                "lon": -122.443386
            },
            "entity": {
                "abbreviation": "Claire Lilienthal Elementary",
                "alternates": {
                    "nces": "063441007841"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c5e79df4-d1b8-4054-a1e2-9b1f2ee48558",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Claire Lilienthal Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "fd7d4388-deaa-4a97-b3a1-ea5c44d25269",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b1d5d1b3-1a17-4487-9231-69eb48dcc12a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Claire Lilienthal Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "claire-lilienthal-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 697
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We have three children at Claire Lilienthal. We count our blessings every day - the school is amazing. Our kids have very different personalities and learning styles, and two are African American and one is White, and all have found tremendous academic enrichment, fabulous arts and sports programs and truly excellent academics. I have come to appreciate that teachers have “feet” and chose to work in schools where they have ample family and administrative support. Our teachers have been hands- down phenomenal. To top it off, the school has terrific community and many very involved families. I am proud to have my kids be in such a diverse and academically and socially successful school. We can do this, America! Urban public schools can be amazing places of academic achievement and cultural and racial integration. Thank you Claire Lilienthal.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-05T02:52:08.050741Z",
                "guid": "1f375a50-4f35-4198-ac1e-bf0cf72109d0",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.443,37.8033,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.443,37.8033,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94123"
            }
        ]
    },
    {
        "guid": "476d2a3b-2afc-45ba-b84e-dc0d7b0a009a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 179,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.320755,
                "lon": -122.038795
            },
            "entity": {
                "abbreviation": "William Faria Elementary School",
                "alternates": {
                    "nces": "061029001133"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "476d2a3b-2afc-45ba-b84e-dc0d7b0a009a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Cupertino, CA",
                "name": "William Faria Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "William Faria Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "william-faria-elementary-school-cupertino-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 695
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.039,37.321,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.039,37.321,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95014"
            }
        ]
    },
    {
        "guid": "14241ddf-8d9b-4069-bd28-ee4cde387484",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 180,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.425102,
                "lon": -121.871616
            },
            "entity": {
                "abbreviation": "John Sinnott Elementary School",
                "alternates": {
                    "nces": "062450003674"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "14241ddf-8d9b-4069-bd28-ee4cde387484",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "John Sinnott Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John Sinnott Elementary School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "john-sinnott-elementary-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 738
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.871,37.4223,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.871,37.4223,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "770867a8-e5da-45f7-b167-a417e153e60e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 181,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.267313,
                "lon": -121.919286
            },
            "entity": {
                "abbreviation": "Steindorf Steam K-8 Magnet",
                "alternates": {
                    "nces": "060714013993"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "770867a8-e5da-45f7-b167-a417e153e60e",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Steindorf Steam K-8 Magnet",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "schoolDistrict": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cambrian School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "aec99b83-c70f-442f-b30c-4c79582989d1",
                        "location": "San Jose, CA",
                        "name": "Cambrian School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Cambrian School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cambrian-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Steindorf Steam K-8 Magnet",
                "tagline": [
                    "Cambrian School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "steindorf-steam-k-8-magnet-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 502
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {},
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "3c9b754b-df29-47c8-b495-e9ba5bf1ad05",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 182,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.750756,
                "lon": -122.499807
            },
            "entity": {
                "abbreviation": "Sunset Elementary School",
                "alternates": {
                    "nces": "063441006556"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3c9b754b-df29-47c8-b495-e9ba5bf1ad05",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Sunset Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "47575dee-023f-4a2f-8c52-61e4471bda57",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "dfff3cae-3a03-46a7-b060-62db0e2af199"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sunset Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sunset-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 407
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.5,37.7507,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.5,37.7507,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94116"
            }
        ]
    },
    {
        "guid": "fdee8710-4ee3-45d4-9ed3-00a4fb25a7f6",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 183,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.865719,
                "lon": -122.294678
            },
            "entity": {
                "abbreviation": "Rosa Parks Environmental Science Magnet",
                "alternates": {
                    "nces": "060474006865"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "fdee8710-4ee3-45d4-9ed3-00a4fb25a7f6",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Rosa Parks Environmental Science Magnet",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8c486372-b77d-4fd6-b272-01f7715363a3",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "39b2df86-684a-467f-9c95-5540b32110f5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Rosa Parks Environmental Science Magnet",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rosa-parks-environmental-science-magnet-berkeley-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 449
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Works Here",
                "body": "I am a proud after school teacher at Rosa Parks. This is a school community that works together to support students and families in every way possible. Each student is tracked carefully and provided math and reading intervention when needed. Equity and inclusion are a number one priority at Rosa. Gender neutral restrooms, a yearly PRIDE parade, and daily morning school gatherings where we all recite a pledge by Rosa Parks and practice our social/emotional \"tool box\" make this school an affirmative environment for all. The after school program called Kid's Village offers amazing enrichment classes-from the yoga class with Papa Da to slime making. Kids just don't want to leave at pick up time!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-03-27T12:39:43.419836Z",
                "guid": "a0ddc518-d264-4e82-840e-027b07a2fdb8",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.298,37.865,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.298,37.865,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94710"
            }
        ]
    },
    {
        "guid": "b1036d2d-5491-48a5-aa0b-be8054e07b23",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 184,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.912337,
                "lon": -122.068756
            },
            "entity": {
                "abbreviation": "Buena Vista Elementary School",
                "alternates": {
                    "nces": "064125006827"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b1036d2d-5491-48a5-aa0b-be8054e07b23",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Buena Vista Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "schoolDistrict": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Walnut Creek Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                        "location": "Walnut Creek, CA",
                        "name": "Walnut Creek Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                            "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                        },
                        "premium": false,
                        "shortName": "Walnut Creek Elementary School District",
                        "tagline": [
                            "School District",
                            "Walnut Creek, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "walnut-creek-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Buena Vista Elementary School",
                "tagline": [
                    "Walnut Creek Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "buena-vista-elementary-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 462
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.07,37.9136,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.07,37.9136,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94597"
            }
        ]
    },
    {
        "guid": "e1f25f0c-db69-4e01-95a2-5defed728115",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 185,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.902067,
                "lon": -122.033641
            },
            "entity": {
                "abbreviation": "Indian Valley Elementary School",
                "alternates": {
                    "nces": "064125006828"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e1f25f0c-db69-4e01-95a2-5defed728115",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Indian Valley Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "schoolDistrict": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Walnut Creek Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                        "location": "Walnut Creek, CA",
                        "name": "Walnut Creek Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                            "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                        },
                        "premium": false,
                        "shortName": "Walnut Creek Elementary School District",
                        "tagline": [
                            "School District",
                            "Walnut Creek, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "walnut-creek-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Indian Valley Elementary School",
                "tagline": [
                    "Walnut Creek Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "indian-valley-elementary-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 395
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.032,37.9009,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.032,37.9009,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94598"
            }
        ]
    },
    {
        "guid": "b43a651f-bf45-47bb-a352-a0c8c0865717",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 186,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.329581,
                "lon": -122.045315
            },
            "entity": {
                "abbreviation": "Garden Gate Elementary School",
                "alternates": {
                    "nces": "061029001134"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b43a651f-bf45-47bb-a352-a0c8c0865717",
                "isClaimed": false,
                "isPremium": false,
                "location": "Cupertino, CA",
                "name": "Garden Gate Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Garden Gate Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "garden-gate-elementary-school-cupertino-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 576
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.045,37.3313,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.045,37.3313,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95014"
            }
        ]
    },
    {
        "guid": "f7931060-dbc8-4364-a71d-4b0ff412bcb8",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 187,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.508385,
                "lon": -122.322253
            },
            "entity": {
                "abbreviation": "Fox Elementary School",
                "alternates": {
                    "nces": "060453000416"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f7931060-dbc8-4364-a71d-4b0ff412bcb8",
                "isClaimed": false,
                "isPremium": false,
                "location": "Belmont, CA",
                "name": "Fox Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "schoolDistrict": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                    "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Belmont-Redwood Shores Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                        "location": "Belmont, CA",
                        "name": "Belmont-Redwood Shores Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                            "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                        },
                        "premium": false,
                        "shortName": "Belmont-Redwood Shores Elementary School District",
                        "tagline": [
                            "School District",
                            "Belmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "belmont-redwood-shores-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Fox Elementary School",
                "tagline": [
                    "Belmont-Redwood Shores Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "fox-elementary-school-belmont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 491
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.323,37.5094,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.323,37.5094,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94002"
            }
        ]
    },
    {
        "guid": "5cfd8691-ad0f-4d25-a6e2-bdc93bc62547",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 188,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.304644,
                "lon": -121.999277
            },
            "entity": {
                "abbreviation": "Murdock-Portal Elementary School",
                "alternates": {
                    "nces": "061029005406"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5cfd8691-ad0f-4d25-a6e2-bdc93bc62547",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Murdock-Portal Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ca7b746e-cd3c-474a-a264-ac5c6e4ff339"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Murdock-Portal Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "murdock-portal-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 570
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.999,37.304,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.999,37.304,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95129"
            }
        ]
    },
    {
        "guid": "0b68a192-ffc8-4036-bf68-5026a0f7f44a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 189,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.754453,
                "lon": -122.238718
            },
            "entity": {
                "abbreviation": "Frank Otis Elementary School",
                "alternates": {
                    "nces": "060177000054"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0b68a192-ffc8-4036-bf68-5026a0f7f44a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Frank Otis Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Frank Otis Elementary School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "frank-otis-elementary-school-alameda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 640
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Not the greatest environment for children who aren't white or Asian. Decent parental involvement, but faculty is not responsive to the very real racial issues at the school. Great LGBT support, however.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-11T22:49:05.793332Z",
                "guid": "01ec4fa2-d53e-49c3-831b-3e07640f8c4d",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.238,37.7538,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.238,37.7538,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "ad6ab2ce-f5cc-4a4d-a30a-95a0d7b0cf61",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 190,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.283312,
                "lon": -121.986849
            },
            "entity": {
                "abbreviation": "Gussie M. Baker Elementary School",
                "alternates": {
                    "nces": "062577003857"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ad6ab2ce-f5cc-4a4d-a30a-95a0d7b0cf61",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Gussie M. Baker Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "a8938731-bf81-4725-9991-fd2727841f28",
                    "schoolDistrict": "a8938731-bf81-4725-9991-fd2727841f28",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moreland Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a8938731-bf81-4725-9991-fd2727841f28",
                        "location": "San Jose, CA",
                        "name": "Moreland",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                        },
                        "premium": false,
                        "shortName": "Moreland Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moreland-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Gussie M. Baker Elementary School",
                "tagline": [
                    "Moreland, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "gussie-m-baker-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 666
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.2842,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.2842,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95130"
            }
        ]
    },
    {
        "guid": "f518ecc0-9964-4922-8558-08927e0f0159",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 191,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.303426,
                "lon": -121.986455
            },
            "entity": {
                "abbreviation": "Easterbrook Discovery",
                "alternates": {
                    "nces": "062577003854"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f518ecc0-9964-4922-8558-08927e0f0159",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Easterbrook Discovery",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "a8938731-bf81-4725-9991-fd2727841f28",
                    "schoolDistrict": "a8938731-bf81-4725-9991-fd2727841f28",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ca7b746e-cd3c-474a-a264-ac5c6e4ff339"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moreland Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a8938731-bf81-4725-9991-fd2727841f28",
                        "location": "San Jose, CA",
                        "name": "Moreland",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                        },
                        "premium": false,
                        "shortName": "Moreland Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moreland-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Easterbrook Discovery",
                "tagline": [
                    "Moreland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "easterbrook-discovery-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 950
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Enthusiastic parents make this school thrive, exposing kids from very broad backgrounds to technology, arts, music in a collaboration with a wonderful staff.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-01-31T12:06:01Z",
                "guid": "645a2c70-2539-4339-bf47-8e67f7afcf6e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.985,37.3062,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.985,37.3062,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95129"
            }
        ]
    },
    {
        "guid": "d4db400d-7235-4ef4-99f5-7ecc290fd093",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 192,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.315463,
                "lon": -122.053872
            },
            "entity": {
                "abbreviation": "Abraham Lincoln Elementary School",
                "alternates": {
                    "nces": "061029001139"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d4db400d-7235-4ef4-99f5-7ecc290fd093",
                "isClaimed": false,
                "isPremium": false,
                "location": "Cupertino, CA",
                "name": "Abraham Lincoln Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                    "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Abraham Lincoln Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "abraham-lincoln-elementary-school-cupertino-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 576
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.054,37.3152,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.054,37.3152,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95014"
            }
        ]
    },
    {
        "guid": "19159d7a-3958-4511-9140-620c3dc96fdd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 193,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.893951,
                "lon": -122.037661
            },
            "entity": {
                "abbreviation": "Walnut Heights Elementary School",
                "alternates": {
                    "nces": "064125006832"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "19159d7a-3958-4511-9140-620c3dc96fdd",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Walnut Heights Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "schoolDistrict": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "f9bfff9c-5a50-47e2-923e-5a7528a271bf"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Walnut Creek Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                        "location": "Walnut Creek, CA",
                        "name": "Walnut Creek Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                            "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                        },
                        "premium": false,
                        "shortName": "Walnut Creek Elementary School District",
                        "tagline": [
                            "School District",
                            "Walnut Creek, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "walnut-creek-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Walnut Heights Elementary School",
                "tagline": [
                    "Walnut Creek Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "walnut-heights-elementary-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 387
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "The teachers are very nice. The cafeteria food hardly meets health standards in my opinion. I am gifted so I did not have trouble in the classroom. No clubs at all. In the first grade classroom, however, the work is too hard. Shout out to Ms. Rohan",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-02-07T15:08:47.008969Z",
                "guid": "e6cebe7d-4914-4d9b-a696-9d68e11afafc",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.037,37.895,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.037,37.895,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94596"
            }
        ]
    },
    {
        "guid": "101cb0cf-920f-4775-aa96-a8bf91252ce1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 194,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.5434,
                "lon": -122.246
            },
            "entity": {
                "abbreviation": "Redwood Shores Elementary School",
                "alternates": {
                    "nces": "060453012574"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "101cb0cf-920f-4775-aa96-a8bf91252ce1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "Redwood Shores Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "795424e8-5d32-4eb7-9926-c7b6e33321a2",
                    "parent": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "schoolDistrict": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "80b04c41-59c1-4587-8f7c-24efc88eaf9a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Belmont-Redwood Shores Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                        "location": "Belmont, CA",
                        "name": "Belmont-Redwood Shores Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                            "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                        },
                        "premium": false,
                        "shortName": "Belmont-Redwood Shores Elementary School District",
                        "tagline": [
                            "School District",
                            "Belmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "belmont-redwood-shores-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Redwood Shores Elementary School",
                "tagline": [
                    "Belmont-Redwood Shores Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "redwood-shores-elementary-school-redwood-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 486
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.246,37.5434,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.246,37.5434,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94065"
            }
        ]
    },
    {
        "guid": "71379d47-01a9-4364-b347-12be2f88d443",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 195,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.379234,
                "lon": -122.081242
            },
            "entity": {
                "abbreviation": "Benjamin Bubb Elementary School",
                "alternates": {
                    "nces": "062628003922"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "71379d47-01a9-4364-b347-12be2f88d443",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Benjamin Bubb Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "schoolDistrict": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "e8a55ab4-9bff-4942-821c-369c0c98966c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mountain View Whisman School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "55669afd-2c14-4350-8f57-42ebb04aa320",
                        "location": "Mountain View, CA",
                        "name": "Mountain View Whisman School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                            "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                        },
                        "premium": false,
                        "shortName": "Mountain View Whisman School District",
                        "tagline": [
                            "School District",
                            "Mountain View, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mountain-view-whisman-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Benjamin Bubb Elementary School",
                "tagline": [
                    "Mountain View Whisman School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "benjamin-bubb-elementary-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 575
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.082,37.3782,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.082,37.3782,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94040"
            }
        ]
    },
    {
        "guid": "20d65485-f237-40a1-ba04-bf9f4299148f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 196,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.530036,
                "lon": -121.963235
            },
            "entity": {
                "abbreviation": "O. N. Hirsch Elementary School",
                "alternates": {
                    "nces": "061440001668"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "20d65485-f237-40a1-ba04-bf9f4299148f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "O.N. Hirsch Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "O. N. Hirsch Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "on-hirsch-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 570
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.964,37.5281,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.964,37.5281,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "6bf86ff1-5a00-4eeb-a3c3-a18e19e2a75f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 197,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.454007,
                "lon": -121.902052
            },
            "entity": {
                "abbreviation": "Marshall Pomeroy Elementary School",
                "alternates": {
                    "nces": "062450003669"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6bf86ff1-5a00-4eeb-a3c3-a18e19e2a75f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "Marshall Pomeroy Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Marshall Pomeroy Elementary School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "marshall-pomeroy-elementary-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 722
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "High School Freshman",
                "body": "My experience with Marshall Pomeroy was great. I definitely have many memories of how much I had fun. The teachers there really made things great for me. I loved to learn back then and always looked forward to school, the main reason probably being the support from teachers and students. I would definitely choose this school again as I haven't encountered any teacher that didn't want to help you.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-02-25T00:57:18Z",
                "guid": "d71e3689-5ee3-431b-908e-27d47d9c4a29",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.903,37.4538,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.903,37.4538,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "f008f0e6-62f5-4c4c-aea5-2ddfe33846e3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 198,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.569015,
                "lon": -122.014669
            },
            "entity": {
                "abbreviation": "Brookvale Elementary School",
                "alternates": {
                    "nces": "061440001658"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f008f0e6-62f5-4c4c-aea5-2ddfe33846e3",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Brookvale Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Brookvale Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "brookvale-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 708
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I went to Brookvale from Kindergarten up till 6th grade. I highly recommend this school to anyone for many reasons. Although I'll share one particular reason why any child should go to Brookvale due to my personal experience, this school was able to give the resources. If it were not for the teachers caring for my education, I would not receive the resources I am still currently using.  Teachers showed my family how much they care about me by updating my parents on possible resources I can use. I am especially thankful for teachers from Brookvale, guiding me in the right direction to be successful in Elementary school and years after.\n\tHalf of my years spent Brookvale Elementary School,  I also attend the after school program, Adventure Time. Staff members from Adventure Time were always welcoming and always showed how they care for every student. I truly believe if it were not for attending Brookvale Elementary, I wouldn't be the person I am today.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-10T18:24:07.620691Z",
                "guid": "13276676-84b8-4353-831c-4cbe79d32fb9",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.015,37.568,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.015,37.568,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "bd65c050-8343-4ac0-bb7f-01f3a62a4a82",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 199,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.576853,
                "lon": -122.030333
            },
            "entity": {
                "abbreviation": "Warwick Elementary School",
                "alternates": {
                    "nces": "061440001691"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bd65c050-8343-4ac0-bb7f-01f3a62a4a82",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Warwick Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "59bd583b-4aa7-49e1-8439-175d9bb43663"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Warwick Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "warwick-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 912
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.032,37.577,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.032,37.577,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94555"
            }
        ]
    },
    {
        "guid": "0521dedc-2ac1-44e0-a290-b20441d9101f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 200,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.333447,
                "lon": -121.97844
            },
            "entity": {
                "abbreviation": "Millikin Elementary School",
                "alternates": {
                    "nces": "063543006044"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "0521dedc-2ac1-44e0-a290-b20441d9101f",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Millikin Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Millikin Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "millikin-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 558
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 34
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.978,37.3335,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.978,37.3335,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95051"
            }
        ]
    },
    {
        "guid": "f2dcf60b-172c-4d7e-bc50-861976988dcd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 201,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.560283,
                "lon": -122.017754
            },
            "entity": {
                "abbreviation": "Oliveira Elementary School",
                "alternates": {
                    "nces": "061440001683"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f2dcf60b-172c-4d7e-bc50-861976988dcd",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Oliveira Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Oliveira Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "oliveira-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 885
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.017,37.5601,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.017,37.5601,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "666976cb-d82b-40f6-b8fe-69daf089927c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 202,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.367268,
                "lon": -122.042911
            },
            "entity": {
                "abbreviation": "Cumberland Elementary School",
                "alternates": {
                    "nces": "063846006459"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "666976cb-d82b-40f6-b8fe-69daf089927c",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Cumberland Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "schoolDistrict": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "12ede258-9fa8-4ba5-9e1e-4d80c11aa22e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Sunnyvale School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                        "location": "Sunnyvale, CA",
                        "name": "Sunnyvale School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                        },
                        "premium": false,
                        "shortName": "Sunnyvale School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "sunnyvale-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Cumberland Elementary School",
                "tagline": [
                    "Sunnyvale School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cumberland-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 806
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Cumberland is a great school, as far as elementary schools go. There are a lot of events and activities during school hours as well as after school. There are also art, music, drama, dance, language, and fitness classes or programs. The teachers care about their students.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-06-02T22:08:56.871971Z",
                "guid": "2b8a32f2-bdfa-47a4-b5ff-5b047633528b",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.044,37.3666,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.044,37.3666,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94087"
            }
        ]
    },
    {
        "guid": "9b92ddae-5b67-43a8-95c3-3acda91480b9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 203,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.769462,
                "lon": -122.444137
            },
            "entity": {
                "abbreviation": "Chinese Immersion School At Deavila",
                "alternates": {
                    "nces": "063441012487"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9b92ddae-5b67-43a8-95c3-3acda91480b9",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Chinese Immersion School At Deavila",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "4be617a2-47f0-47fe-928e-445981b37379",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "7db16a60-8255-4b6d-ac82-4ba69c978bcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Chinese Immersion School At Deavila",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "chinese-immersion-school-at-deavila-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 387
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.444,37.7698,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.444,37.7698,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94117"
            }
        ]
    },
    {
        "guid": "f359ea93-3c36-40ad-a3c8-d5a5babae222",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 204,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.87695,
                "lon": -122.27125
            },
            "entity": {
                "abbreviation": "Berkeley Arts Magnet at Whittier",
                "alternates": {
                    "nces": "060474000431"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f359ea93-3c36-40ad-a3c8-d5a5babae222",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Berkeley Arts Magnet at Whittier",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "982ff635-e0a6-4092-b8f7-45cadf528051",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "ea161974-3034-4eb6-bc6f-aae77a45f617"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Berkeley Arts Magnet at Whittier",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "berkeley-arts-magnet-at-whittier-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 425
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.271,37.8769,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.271,37.8769,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94709"
            }
        ]
    },
    {
        "guid": "92909deb-3606-48d1-b2ca-eb0b55a35f4a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 205,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.69902,
                "lon": -122.065715
            },
            "entity": {
                "abbreviation": "Marshall Elementary School",
                "alternates": {
                    "nces": "060780000746"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "92909deb-3606-48d1-b2ca-eb0b55a35f4a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Marshall Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Marshall Elementary School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "marshall-elementary-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 490
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.065,37.6989,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.065,37.6989,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94546"
            }
        ]
    },
    {
        "guid": "85150fc7-d954-4fab-9247-451a7d29a446",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 206,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.334596,
                "lon": -121.986584
            },
            "entity": {
                "abbreviation": "Sutter Elementary School",
                "alternates": {
                    "nces": "063543006052"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "85150fc7-d954-4fab-9247-451a7d29a446",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Sutter Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sutter Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sutter-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 456
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 4
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.987,37.3338,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.987,37.3338,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95051"
            }
        ]
    },
    {
        "guid": "afcb1302-cac4-418e-8974-8b6694cc0c45",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 207,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.971069,
                "lon": -122.566174
            },
            "entity": {
                "abbreviation": "Wade Thomas Elementary School",
                "alternates": {
                    "nces": "060000608776"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "afcb1302-cac4-418e-8974-8b6694cc0c45",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Anselmo, CA",
                "name": "Wade Thomas Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "schoolDistrict": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "0db5806d-7d26-41a5-8300-2d00fc0dfd2c",
                    "zipCode": "0455b370-2ddd-4cd1-ae84-32c046dba7fb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Ross Valley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e787e270-2623-42bc-917f-95d6d7ff2026",
                        "location": "San Anselmo, CA",
                        "name": "Ross Valley Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "0db5806d-7d26-41a5-8300-2d00fc0dfd2c",
                            "zipCode": "0455b370-2ddd-4cd1-ae84-32c046dba7fb"
                        },
                        "premium": false,
                        "shortName": "Ross Valley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Anselmo, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "ross-valley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Wade Thomas Elementary School",
                "tagline": [
                    "Ross Valley Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "wade-thomas-elementary-school-san-anselmo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 370
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.568,37.9709,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.568,37.9709,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94960"
            }
        ]
    },
    {
        "guid": "0063bbc0-dea7-4d42-be00-67ded344f7f4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 208,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.575687,
                "lon": -122.327489
            },
            "entity": {
                "abbreviation": "College Park Elementary School",
                "alternates": {
                    "nces": "063492005912"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "0063bbc0-dea7-4d42-be00-67ded344f7f4",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "College Park Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "3435a379-835e-44ad-a67d-683bc7ef6fc8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "College Park Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "college-park-elementary-school-san-mateo-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 452
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.327,37.5761,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.327,37.5761,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 1,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94401"
            }
        ]
    },
    {
        "guid": "f5f9e4cc-5178-4cd8-8288-6c3ce53a47b5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 209,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.007757,
                "lon": -122.549376
            },
            "entity": {
                "abbreviation": "Vallecito Elementary School",
                "alternates": {
                    "nces": "061122008822"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f5f9e4cc-5178-4cd8-8288-6c3ce53a47b5",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Rafael, CA",
                "name": "Vallecito Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                    "parent": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                    "schoolDistrict": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                    "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Dixie Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                        "location": "San Rafael, CA",
                        "name": "Dixie Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                            "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                        },
                        "premium": false,
                        "shortName": "Dixie Elementary School District",
                        "tagline": [
                            "School District",
                            "San Rafael, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dixie-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Vallecito Elementary School",
                "tagline": [
                    "Dixie Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "vallecito-elementary-school-san-rafael-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 476
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.552,38.0058,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.552,38.0058,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94903"
            }
        ]
    },
    {
        "guid": "a5841e8c-5ed1-4780-85d9-c6e3c7dd8853",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 210,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.5533,
                "lon": -122.332549
            },
            "entity": {
                "abbreviation": "Baywood Elementary School",
                "alternates": {
                    "nces": "063492005899"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a5841e8c-5ed1-4780-85d9-c6e3c7dd8853",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Baywood Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "062f4806-af97-4d46-8d31-2a26304d0e2f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Baywood Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "baywood-elementary-school-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 712
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Baywood is an amazing school with caring students and parents who go above and beyond for our children.  All three of my boys have attended Baywood and I couldn’t have asked for a better elementary school experience for them.   My kids agree!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-21T05:54:13.452379Z",
                "guid": "eb71c8c8-2e14-4179-8c84-34a0f385214b",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.331,37.5523,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.331,37.5523,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.7142857142857144,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94402"
            }
        ]
    },
    {
        "guid": "87ebe948-084b-4519-a44b-c1aa2bce5e97",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 211,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.343386,
                "lon": -122.002412
            },
            "entity": {
                "abbreviation": "Laurelwood Elementary School",
                "alternates": {
                    "nces": "063543006042"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "87ebe948-084b-4519-a44b-c1aa2bce5e97",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Laurelwood Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Laurelwood Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "laurelwood-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 644
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.003,37.3431,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.003,37.3431,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95051"
            }
        ]
    },
    {
        "guid": "51a25c71-ec38-4e15-a89c-3fcffebb443c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 212,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.208369,
                "lon": -121.850625
            },
            "entity": {
                "abbreviation": "Williams Elementary School",
                "alternates": {
                    "nces": "063459001538"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "51a25c71-ec38-4e15-a89c-3fcffebb443c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Williams Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "76f228c2-291e-4ea6-839a-447faaa4a145",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "67602db5-a16e-4627-8133-9f3d9f61c732"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Williams Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "williams-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 682
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 29
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Very highly educated community with a diverse ethnic mix. Mostly highly educated, extremely hard-working teachers who care a lot about their students. The district seems to stay up on new education trends. The parent organization is extremely involved and helps fund a lot of extras, like two full computer labs, music, art, and extra physical education options. In addition, parents volunteer regularly in the classrooms and lead multiple school community events each year.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-02-18T23:51:18.92813Z",
                "guid": "da9789e7-f5f3-4c38-877e-63dd1ac8a8c7",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.852,37.2076,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.852,37.2076,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95120"
            }
        ]
    },
    {
        "guid": "224c8526-2c51-41bd-92b5-78f42f61867a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 213,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.362984,
                "lon": -122.009658
            },
            "entity": {
                "abbreviation": "Ponderosa Elementary School",
                "alternates": {
                    "nces": "063543006049"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "224c8526-2c51-41bd-92b5-78f42f61867a",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Ponderosa Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Ponderosa Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ponderosa-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 590
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.008,37.3625,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.008,37.3625,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94086"
            }
        ]
    },
    {
        "guid": "2a0be6cf-c22b-4f62-bf1d-13879b4fdd26",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 214,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.12639,
                "lon": -121.94196
            },
            "entity": {
                "abbreviation": "Loma Prieta Elementary School",
                "alternates": {
                    "nces": "062235002673"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2a0be6cf-c22b-4f62-bf1d-13879b4fdd26",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Gatos, CA",
                "name": "Loma Prieta Elementary School",
                "parentGUIDs": {
                    "county": "49997ec1-1e60-4274-84cb-b2c7ecfe9f49",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "91f9426e-72bf-44ae-ac51-69c7c5c0ae5c",
                    "schoolDistrict": "91f9426e-72bf-44ae-ac51-69c7c5c0ae5c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "98637fb0-4b0f-4318-8807-b2944c7d92a8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Loma Prieta Joint Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "91f9426e-72bf-44ae-ac51-69c7c5c0ae5c",
                        "location": "Los Gatos, CA",
                        "name": "Loma Prieta Joint Union Elementary School District",
                        "parentGUIDs": {
                            "county": "49997ec1-1e60-4274-84cb-b2c7ecfe9f49",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "zipCode": "98637fb0-4b0f-4318-8807-b2944c7d92a8"
                        },
                        "premium": false,
                        "shortName": "Loma Prieta Joint Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Los Gatos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "loma-prieta-joint-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Loma Prieta Elementary School",
                "tagline": [
                    "Loma Prieta Joint Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "loma-prieta-elementary-school-los-gatos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 265
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.944,37.1256,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.944,37.1256,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95033"
            }
        ]
    },
    {
        "guid": "243693b2-48b1-43c2-a2e2-ad81b77adcec",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 215,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.538126,
                "lon": -122.011787
            },
            "entity": {
                "abbreviation": "John G. Mattos Elementary School",
                "alternates": {
                    "nces": "061440001675"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "243693b2-48b1-43c2-a2e2-ad81b77adcec",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "John G. Mattos Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John G. Mattos Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "john-g-mattos-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 607
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "I have nothing but praise for Mattos educators and faculty. With a ration of 26:1, it is amazing the miracles they produce each year.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-16T02:21:30.587281Z",
                "guid": "030b873d-c3e0-49fe-a09e-3484612f2aa9",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.012,37.5388,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.012,37.5388,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "ab2ef80e-9ce0-46fe-a58f-11711392a3db",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 216,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.468508,
                "lon": -122.254454
            },
            "entity": {
                "abbreviation": "Cipriani Elementary School",
                "alternates": {
                    "nces": "060453004019"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ab2ef80e-9ce0-46fe-a58f-11711392a3db",
                "isClaimed": false,
                "isPremium": false,
                "location": "Belmont, CA",
                "name": "Cipriani Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bb7ac56d-5f91-4fd0-91c9-a875c7b0034c",
                    "parent": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "schoolDistrict": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "7d773137-7242-4cc9-91c9-d3770c1b9f33"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Belmont-Redwood Shores Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                        "location": "Belmont, CA",
                        "name": "Belmont-Redwood Shores Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                            "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                        },
                        "premium": false,
                        "shortName": "Belmont-Redwood Shores Elementary School District",
                        "tagline": [
                            "School District",
                            "Belmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "belmont-redwood-shores-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Cipriani Elementary School",
                "tagline": [
                    "Belmont-Redwood Shores Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cipriani-elementary-school-belmont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 441
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.306,37.516,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.306,37.516,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94002"
            }
        ]
    },
    {
        "guid": "3fbf264a-a42b-4655-8cec-a562a6e53778",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 217,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.346715,
                "lon": -122.033604
            },
            "entity": {
                "abbreviation": "Chester W. Nimitz Elementary School",
                "alternates": {
                    "nces": "061029001146"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3fbf264a-a42b-4655-8cec-a562a6e53778",
                "isClaimed": false,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Chester W. Nimitz Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "12ede258-9fa8-4ba5-9e1e-4d80c11aa22e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Chester W. Nimitz Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "chester-w-nimitz-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 628
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.037,37.3472,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.037,37.3472,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94087"
            }
        ]
    },
    {
        "guid": "b334db28-af68-46b5-a8d2-606b908f0eae",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 218,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.212173,
                "lon": -121.838225
            },
            "entity": {
                "abbreviation": "Graystone Elementary School",
                "alternates": {
                    "nces": "063459005715"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b334db28-af68-46b5-a8d2-606b908f0eae",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Graystone Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "76f228c2-291e-4ea6-839a-447faaa4a145",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "67602db5-a16e-4627-8133-9f3d9f61c732"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Graystone Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "graystone-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 571
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.837,37.2114,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.837,37.2114,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95120"
            }
        ]
    },
    {
        "guid": "42d7e0f2-214b-4ea9-905f-7ed4c069c361",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 219,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.607622,
                "lon": -122.095416
            },
            "entity": {
                "abbreviation": "Venture (Alternative)",
                "alternates": {
                    "ceeb": "053224",
                    "nces": "063513008976"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "42d7e0f2-214b-4ea9-905f-7ed4c069c361",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Ramon, CA",
                "name": "Venture (Alternative)",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "schoolDistrict": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                    "zipCode": "e8c32215-6d14-4b33-82bd-2d41b6b32206"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SRVUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0eb0d278-7f40-4d95-af46-a98f9faf3402",
                        "location": "Danville, CA",
                        "name": "San Ramon Valley Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fcc57eee-ebde-443a-b77d-e10bbde2f779",
                            "zipCode": "bd3e7c23-836c-470d-ab71-7f4963ad582c"
                        },
                        "premium": false,
                        "shortName": "San Ramon Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Danville, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-ramon-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Venture (Alternative)",
                "tagline": [
                    "San Ramon Valley Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "venture-alternative-san-ramon-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 154
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 17
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "I have had a very good experience with Venture. The independent study works very well for me. At the same time, teachers are always there with support and helpful answers whenever I need it.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-02-21T20:14:55.996067Z",
                "guid": "db820dc2-3471-4b80-be61-c191153dca8d",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.905,37.7674,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.905,37.7674,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.1875,
                "count": 16
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94582"
            }
        ]
    },
    {
        "guid": "f6ca5fc9-def4-41c6-af13-968657db4c76",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 220,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.777531,
                "lon": -122.496756
            },
            "entity": {
                "abbreviation": "Lafayette Elementary School",
                "alternates": {
                    "nces": "063441005637"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f6ca5fc9-def4-41c6-af13-968657db4c76",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Lafayette Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "17498cb5-11d4-482c-8bb3-a9dad794c27e",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "095d8179-e190-4e1d-a747-e79c35d38d3c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lafayette Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lafayette-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 540
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.497,37.7772,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.497,37.7772,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94121"
            }
        ]
    },
    {
        "guid": "32a408cc-f952-460e-b8cb-c333d7f88573",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 221,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.666079,
                "lon": -121.796416
            },
            "entity": {
                "abbreviation": "Emma C. Smith Elementary School",
                "alternates": {
                    "nces": "062211002624"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "32a408cc-f952-460e-b8cb-c333d7f88573",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Emma C. Smith Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "0a020eaf-ab20-4a50-8308-d60ca74bd07f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Emma C. Smith Elementary School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "emma-c-smith-elementary-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 719
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.797,37.6661,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.797,37.6661,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94550"
            }
        ]
    },
    {
        "guid": "7d4dbfb0-eb0a-4389-a983-36d1eafbf3b1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 222,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.284106,
                "lon": -121.773749
            },
            "entity": {
                "abbreviation": "Silver Oak Elementary School",
                "alternates": {
                    "nces": "061314004054"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7d4dbfb0-eb0a-4389-a983-36d1eafbf3b1",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Silver Oak Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "4bac95e0-8512-4574-9a03-52643a7af8b3",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "b8379ca1-951f-4d73-b5a4-e02ae128490a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Silver Oak Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "silver-oak-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 607
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "I had 3 children graduate from Silver Oak.  The staff and teachers are top notch.  The curriculum is well above average and the school is in the top bracket in the state of California.  Your children come out well prepared for middle school and beyond.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-04-04T21:59:27.383018Z",
                "guid": "3cf5f6ff-56f8-44da-aae7-53aa56a382d9",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.76,37.2798,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.76,37.2798,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95138"
            }
        ]
    },
    {
        "guid": "f5887910-b8d3-4a87-84b3-1f52b45bc1e9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 223,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.592154,
                "lon": -122.390393
            },
            "entity": {
                "abbreviation": "Spring Valley Elementary School",
                "alternates": {
                    "nces": "062490003721"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f5887910-b8d3-4a87-84b3-1f52b45bc1e9",
                "isClaimed": false,
                "isPremium": false,
                "location": "Millbrae, CA",
                "name": "Spring Valley Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "schoolDistrict": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a22149ca-7510-485b-b4ab-2f8db3bd4a65",
                    "zipCode": "4fba468a-9eb7-49d5-b87f-036348d35412"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Millbrae Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                        "location": "Millbrae, CA",
                        "name": "Millbrae Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a22149ca-7510-485b-b4ab-2f8db3bd4a65",
                            "zipCode": "4fba468a-9eb7-49d5-b87f-036348d35412"
                        },
                        "premium": false,
                        "shortName": "Millbrae Elementary School District",
                        "tagline": [
                            "School District",
                            "Millbrae, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "millbrae-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Spring Valley Elementary School",
                "tagline": [
                    "Millbrae Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "spring-valley-elementary-school-millbrae-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 425
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.391,37.5908,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.391,37.5908,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94030"
            }
        ]
    },
    {
        "guid": "5335c7cb-7804-4a85-bfd9-3a4f8ea37f8a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 224,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.852204,
                "lon": -122.273788
            },
            "entity": {
                "abbreviation": "Malcolm X Elementary School",
                "alternates": {
                    "nces": "060474000447"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5335c7cb-7804-4a85-bfd9-3a4f8ea37f8a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Malcolm X Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "4870ba36-d978-42b8-9459-d6834bf05892",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "ea9f890c-9568-4762-a2f4-771a4f369e28"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Malcolm X Elementary School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "malcolm-x-elementary-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 557
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.274,37.8531,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.274,37.8531,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94703"
            }
        ]
    },
    {
        "guid": "51c21496-0cda-4d9f-b567-486a301ab57d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 225,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.54551,
                "lon": -122.273201
            },
            "entity": {
                "abbreviation": "Foster City Elementary School",
                "alternates": {
                    "nces": "063492005905"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "51c21496-0cda-4d9f-b567-486a301ab57d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Foster City, CA",
                "name": "Foster City Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                    "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Foster City Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "foster-city-elementary-school-foster-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 866
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "This is an astonishing school with fabulous teachers who are very interested in teaching kids. Their dedication is amazing and the staff is extremely polite with the parents and students. There are many ways for the students to get involved at the school and the teachers have created a powerful learning environment at the school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-01-18T08:56:14.537614Z",
                "guid": "7f602eb1-1948-4090-a5eb-93c4846605d8",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.274,37.5463,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.274,37.5463,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94404"
            }
        ]
    },
    {
        "guid": "f59b1391-3b8d-427d-b7be-c4899ff3fe61",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 226,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.242083,
                "lon": -121.902329
            },
            "entity": {
                "abbreviation": "Lietz Elementary School",
                "alternates": {
                    "nces": "064032006674"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f59b1391-3b8d-427d-b7be-c4899ff3fe61",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Lietz Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "schoolDistrict": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "cfe32ecb-646c-4ac4-8146-97a3f348c00e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "8d1d588f-fdd3-4780-9dee-41bc233763ff",
                        "location": "San Jose, CA",
                        "name": "Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Lietz Elementary School",
                "tagline": [
                    "Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lietz-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 596
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.902,37.2437,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.902,37.2437,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95118"
            }
        ]
    },
    {
        "guid": "7491f1de-9c1a-4ff9-8f02-278b05781b09",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 227,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.098507,
                "lon": -122.576736
            },
            "entity": {
                "abbreviation": "Nova Education Center",
                "alternates": {
                    "ceeb": "052171",
                    "nces": "062772004199"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7491f1de-9c1a-4ff9-8f02-278b05781b09",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Nova Education Center",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "7cf6b53c-a89f-46d8-a63f-d6f897c0dd65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Nova Education Center",
                "tagline": [
                    "Novato Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "nova-education-center-novato-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 39
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 13
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Attending this school made my final two years of high school considerably more worthwhile.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-07-12T23:15:17Z",
                "guid": "33730697-0de2-49b1-ae8e-a5c497b6d815",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.576,38.0983,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.576,38.0983,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.714285714285714,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94947"
            }
        ]
    },
    {
        "guid": "73710f25-0bee-49c8-bbdb-ac738043833c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 228,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.663278,
                "lon": -121.773425
            },
            "entity": {
                "abbreviation": "Sunset Elementary School",
                "alternates": {
                    "nces": "062211002635"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "73710f25-0bee-49c8-bbdb-ac738043833c",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Sunset Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "0a020eaf-ab20-4a50-8308-d60ca74bd07f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sunset Elementary School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sunset-elementary-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 771
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.775,37.6626,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.775,37.6626,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94550"
            }
        ]
    },
    {
        "guid": "d2340040-d085-4974-895f-01ce156de688",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 229,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.921158,
                "lon": -122.498253
            },
            "entity": {
                "abbreviation": "The Cove School",
                "alternates": {
                    "nces": "062091013739"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d2340040-d085-4974-895f-01ce156de688",
                "isClaimed": false,
                "isPremium": false,
                "location": "Corte Madera, CA",
                "name": "Cove",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "038742ad-94b6-4220-83f7-0a1b32f99cfd",
                    "schoolDistrict": "038742ad-94b6-4220-83f7-0a1b32f99cfd",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "80ece29c-b2d0-4129-8615-c2bf68e5f264",
                    "zipCode": "c8008f23-0b15-4931-a168-e9d7bb79f0b4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Larkspur-Corta Madera School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "038742ad-94b6-4220-83f7-0a1b32f99cfd",
                        "location": "Larkspur, CA",
                        "name": "Larkspur-Corta Madera School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "db131da3-37dc-42cf-aad2-15024d364e4d",
                            "zipCode": "f981dbb9-3c01-40c1-97a3-f476d8291c9a"
                        },
                        "premium": false,
                        "shortName": "Larkspur-Corta Madera School District",
                        "tagline": [
                            "School District",
                            "Larkspur, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "larkspur-corta-madera-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "The Cove School",
                "tagline": [
                    "Larkspur-Corta Madera School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cove-corte-madera-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 424
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.499,37.9213,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.499,37.9213,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94925"
            }
        ]
    },
    {
        "guid": "9db9b6fb-154c-480e-bf3f-32af9fef987c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 230,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.753624,
                "lon": -122.455702
            },
            "entity": {
                "abbreviation": "Clarendon Alternative Elementary School",
                "alternates": {
                    "nces": "063441005666"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9db9b6fb-154c-480e-bf3f-32af9fef987c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Clarendon Alternative Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a8c91243-947c-4d94-af4b-ef40266ac80c",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "ee205ed4-1f3f-448a-8f17-065ffce55d35"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Clarendon Alternative Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "clarendon-alternative-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 555
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.456,37.7537,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.456,37.7537,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94131"
            }
        ]
    },
    {
        "guid": "7529c23c-17c5-4498-acc0-6f6f646ad39c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 231,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.8668,
                "lon": -122.273
            },
            "entity": {
                "abbreviation": "Washington Elementary School",
                "alternates": {
                    "nces": "060474000452"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7529c23c-17c5-4498-acc0-6f6f646ad39c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Washington Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "ea9f890c-9568-4762-a2f4-771a4f369e28"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Washington Elementary",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "washington-elementary-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 501
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.273,37.8668,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.273,37.8668,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94704"
            }
        ]
    },
    {
        "guid": "52fd4feb-52de-4612-9d1a-21b3f9663029",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 232,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.519978,
                "lon": -121.951808
            },
            "entity": {
                "abbreviation": "E. M. Grimmer Elementary School",
                "alternates": {
                    "nces": "061440001666"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "52fd4feb-52de-4612-9d1a-21b3f9663029",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "E.M. Grimmer Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "E. M. Grimmer Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "em-grimmer-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 481
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great teachers and staff. The teachers are amazing and work with the parents to help the kids learn. The staff is amazing really cares about the school & kids well being. We love our school because the PTA is amazing with events to bring together the kids, parents and teachers. It's a great school for kids to learn and grow. They have a great librarian and library. The kids take field trips to learn about Physics, theater, science, etc. Parents are giving abs help teachers with supplies for the classroom. Events are held to fundraise by the PTA and kids and parents alike come together for the good of the school. It's an great school for my kids and family.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-09-17T04:43:57.193548Z",
                "guid": "98b87032-18c8-4226-b767-4b3d5c87b157",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.951,37.5201,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.951,37.5201,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "3dddf0da-bd82-405b-81a8-0831a711b479",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 233,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.310566,
                "lon": -121.991235
            },
            "entity": {
                "abbreviation": "Manuel de Vargas Elementary School",
                "alternates": {
                    "nces": "061029001128"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3dddf0da-bd82-405b-81a8-0831a711b479",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Manuel De Vargas Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "schoolDistrict": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ca7b746e-cd3c-474a-a264-ac5c6e4ff339"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cupertino Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f9c2f3e1-9d47-401a-8d63-d2dc4cb109d2",
                        "location": "Sunnyvale, CA",
                        "name": "Cupertino Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a367781a-1e4e-4262-aa1f-0d6e438d54b8",
                            "zipCode": "57188a78-9608-47e7-ad49-c14bdbdb567f"
                        },
                        "premium": false,
                        "shortName": "Cupertino Union School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cupertino-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Manuel de Vargas Elementary School",
                "tagline": [
                    "Cupertino Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "manuel-de-vargas-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 519
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "This is one of 2 tital 2 schools in CSUD. The school achieved a successful outcome with project based learning program and my daughter loves it. So many good teachers who willingly help out kids and support parents. I love the diversity in this school as well.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-10-14T20:16:39.947679Z",
                "guid": "8e9602c0-1f16-4cc0-87d7-04cfa84ac6de",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.99,37.3107,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.99,37.3107,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95129"
            }
        ]
    },
    {
        "guid": "7eaaed62-68e3-4a6c-a7d1-1e2a0b6e2c92",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 234,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.757937,
                "lon": -122.489573
            },
            "entity": {
                "abbreviation": "Lawton Alternative Elementary School",
                "alternates": {
                    "nces": "063441005639"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7eaaed62-68e3-4a6c-a7d1-1e2a0b6e2c92",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Lawton Alternative Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "47575dee-023f-4a2f-8c52-61e4471bda57",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b4e89c98-14e0-415a-81a3-67446f01feef"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lawton Alternative Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "lawton-alternative-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 593
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.489,37.758,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.489,37.758,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94122"
            }
        ]
    },
    {
        "guid": "c0641920-c8b2-45df-ac33-3130db2301d1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 235,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.227581,
                "lon": -121.881178
            },
            "entity": {
                "abbreviation": "Los Alamitos Elementary School",
                "alternates": {
                    "nces": "063459005727"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c0641920-c8b2-45df-ac33-3130db2301d1",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Los Alamitos Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "76f228c2-291e-4ea6-839a-447faaa4a145",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "67602db5-a16e-4627-8133-9f3d9f61c732"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Los Alamitos Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "los-alamitos-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 731
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.88,37.2284,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.88,37.2284,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95120"
            }
        ]
    },
    {
        "guid": "c7ead868-f57d-4476-998d-806f8d9380c7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 236,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.440082,
                "lon": -121.912656
            },
            "entity": {
                "abbreviation": "Curtner Elementary School",
                "alternates": {
                    "nces": "062450003665"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c7ead868-f57d-4476-998d-806f8d9380c7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "Curtner Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Curtner Elementary School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "curtner-elementary-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 730
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.913,37.4411,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.913,37.4411,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "bbaf72fb-44c1-419c-82f9-481a5cd174d9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 237,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.798163,
                "lon": -122.426276
            },
            "entity": {
                "abbreviation": "Sherman Elementary School",
                "alternates": {
                    "nces": "063441005668"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bbaf72fb-44c1-419c-82f9-481a5cd174d9",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Sherman Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "31b80d97-8d04-40b3-a0c8-5b67e2ccf6ba",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b1d5d1b3-1a17-4487-9231-69eb48dcc12a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sherman Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sherman-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 387
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I is the best there I wish I can go back to every memory I had there. They have the best teachers and every thing.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-11T02:15:26.269782Z",
                "guid": "3c0134cb-bbc8-4e3f-8ce9-9045fe15a4a5",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.426,37.7978,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.426,37.7978,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94123"
            }
        ]
    },
    {
        "guid": "d9cf2ac7-8c14-4e47-81b2-508a826a97bb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 238,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.005674,
                "lon": -121.903127
            },
            "entity": {
                "abbreviation": "Delaine Eastin Elementary School",
                "alternates": {
                    "nces": "062691008675"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "d9cf2ac7-8c14-4e47-81b2-508a826a97bb",
                "isClaimed": true,
                "isPremium": false,
                "location": "Union City, CA",
                "name": "Delaine Eastin Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "schoolDistrict": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "0f3ccf9b-3550-4b3b-bc4e-0553b58c6d69",
                    "zipCode": "3da768f4-2aba-4f43-8fa3-a9f7e92a82d1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "New Haven Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "305b3896-e539-4976-95d2-a90396c3f1cf",
                        "location": "Union City, CA",
                        "name": "New Haven Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                            "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                        },
                        "premium": false,
                        "shortName": "New Haven Unified School District",
                        "tagline": [
                            "School District",
                            "Union City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "new-haven-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Delaine Eastin Elementary School",
                "tagline": [
                    "New Haven Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "delaine-eastin-elementary-school-union-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 765
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Brilliant school that teaches children good habits and how to respect diversity.  Once broke the world record of square dancing.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-02-20T18:01:08.136036Z",
                "guid": "c786de09-a224-4f69-a50d-e5e4c6392a10",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.074,37.5674,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.074,37.5674,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94587"
            }
        ]
    },
    {
        "guid": "47a77ab0-511b-4686-9944-cb853d73aee9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 239,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.990888,
                "lon": -122.576843
            },
            "entity": {
                "abbreviation": "Brookside Elementary School",
                "alternates": {
                    "nces": "060000608775"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "47a77ab0-511b-4686-9944-cb853d73aee9",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Anselmo, CA",
                "name": "Brookside Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "schoolDistrict": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "0db5806d-7d26-41a5-8300-2d00fc0dfd2c",
                    "zipCode": "0455b370-2ddd-4cd1-ae84-32c046dba7fb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Ross Valley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e787e270-2623-42bc-917f-95d6d7ff2026",
                        "location": "San Anselmo, CA",
                        "name": "Ross Valley Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "0db5806d-7d26-41a5-8300-2d00fc0dfd2c",
                            "zipCode": "0455b370-2ddd-4cd1-ae84-32c046dba7fb"
                        },
                        "premium": false,
                        "shortName": "Ross Valley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Anselmo, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "ross-valley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Brookside Elementary School",
                "tagline": [
                    "Ross Valley Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "brookside-elementary-school-san-anselmo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 361
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.575,37.9908,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.575,37.9908,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94960"
            }
        ]
    },
    {
        "guid": "4c239d15-1427-4daa-8265-2982c244abf7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 240,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.05762,
                "lon": -122.525978
            },
            "entity": {
                "abbreviation": "Novato Charter School",
                "alternates": {
                    "nces": "060193606035"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "4c239d15-1427-4daa-8265-2982c244abf7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Novato Charter School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "890ee59a-23bb-434c-820a-d0eb9aa9b666"
                },
                "premium": false,
                "shortName": "Novato Charter School",
                "tagline": [
                    "Public School",
                    "Novato, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "novato-charter-school-novato-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 271
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.526,38.0563,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.526,38.0563,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94949"
            }
        ]
    },
    {
        "guid": "2fec6c04-151f-4a06-8a0e-1958e48b0e3c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 241,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.324635,
                "lon": -121.8522
            },
            "entity": {
                "abbreviation": "CAPS",
                "alternates": {
                    "nces": "060167112639"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "2fec6c04-151f-4a06-8a0e-1958e48b0e3c",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Alpha: Cornerstone Academy Preparatory",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "schoolNetwork": "32061b3b-37e5-4624-a58b-532086d3e22f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "premium": false,
                "shortName": "Cornerstone Academy",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "alpha--cornerstone-academy-preparatory-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 543
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 29
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.852,37.3248,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.852,37.3248,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "e83811bb-865c-4638-a1c4-f5021dfe04e5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 242,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.525729,
                "lon": -122.274405
            },
            "entity": {
                "abbreviation": "Nesbit Elementary School",
                "alternates": {
                    "nces": "060453000418"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e83811bb-865c-4638-a1c4-f5021dfe04e5",
                "isClaimed": false,
                "isPremium": false,
                "location": "Belmont, CA",
                "name": "Nesbit Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "schoolDistrict": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                    "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Belmont-Redwood Shores Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "feffbbb3-06c8-4a03-86d0-b4bfb615800d",
                        "location": "Belmont, CA",
                        "name": "Belmont-Redwood Shores Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9d0d3391-77b3-4daf-98b4-a4ec0b501c56",
                            "zipCode": "2da9315f-286f-4929-a588-2ca31ff945e2"
                        },
                        "premium": false,
                        "shortName": "Belmont-Redwood Shores Elementary School District",
                        "tagline": [
                            "School District",
                            "Belmont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "belmont-redwood-shores-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Nesbit Elementary School",
                "tagline": [
                    "Belmont-Redwood Shores Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "nesbit-elementary-school-belmont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 670
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.276,37.5251,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.276,37.5251,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94002"
            }
        ]
    },
    {
        "guid": "9c5cb27c-8377-4688-ae8e-8f10fdd7d72c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 243,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.545723,
                "lon": -122.004833
            },
            "entity": {
                "abbreviation": "Glenmoor Elementary School",
                "alternates": {
                    "nces": "061440001663"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9c5cb27c-8377-4688-ae8e-8f10fdd7d72c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Glenmoor Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Glenmoor Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "glenmoor-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 663
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.004,37.5444,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.004,37.5444,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "8b3454eb-e72d-4e6b-9b7d-3a65ddc17b7f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 244,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.297351,
                "lon": -121.968014
            },
            "entity": {
                "abbreviation": "George C. Payne Elementary School",
                "alternates": {
                    "nces": "062577003856"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8b3454eb-e72d-4e6b-9b7d-3a65ddc17b7f",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "George C. Payne Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "a8938731-bf81-4725-9991-fd2727841f28",
                    "schoolDistrict": "a8938731-bf81-4725-9991-fd2727841f28",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moreland Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a8938731-bf81-4725-9991-fd2727841f28",
                        "location": "San Jose, CA",
                        "name": "Moreland",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                        },
                        "premium": false,
                        "shortName": "Moreland Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moreland-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "George C. Payne Elementary School",
                "tagline": [
                    "Moreland, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "george-c-payne-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 581
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.967,37.2968,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.967,37.2968,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95130"
            }
        ]
    },
    {
        "guid": "2b9cac10-fd0b-439e-8a40-77bd633b4d27",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 245,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.73729,
                "lon": -122.499935
            },
            "entity": {
                "abbreviation": "Ulloa Elementary School",
                "alternates": {
                    "nces": "063441005676"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2b9cac10-fd0b-439e-8a40-77bd633b4d27",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Ulloa Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "45ae15e7-7755-498f-b7ee-f6f7ee80674c",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "dfff3cae-3a03-46a7-b060-62db0e2af199"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Ulloa Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ulloa-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 528
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.499,37.7374,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.499,37.7374,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94116"
            }
        ]
    },
    {
        "guid": "5882b1f6-b88c-4c09-97e9-ce340edbf059",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 246,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.780077,
                "lon": -122.435425
            },
            "entity": {
                "abbreviation": "Creative Arts Charter School",
                "alternates": {
                    "nces": "060215503982"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "5882b1f6-b88c-4c09-97e9-ce340edbf059",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Creative Arts Charter School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9ae6d1c0-ee34-4266-84ab-3a6632674608",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "9fbdd391-c59a-4ee3-a774-a928ff2f0905"
                },
                "premium": false,
                "shortName": "Creative Arts Charter School",
                "tagline": [
                    "Public School",
                    "San Francisco, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "creative-arts-charter-school-san-francisco-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 431
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "CACS has a robust but manageable workload that prepares students for the curriculum ahead in high school. It offers a sense of community and diversity that simply isn't present at most other schools.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-09-05T19:16:18.138018Z",
                "guid": "3e462fd8-e8ce-4fdd-b88e-cf975bf47830",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.7798,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.7798,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.25,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94115"
            }
        ]
    },
    {
        "guid": "6bf5e407-7891-46b0-9431-40afaa7bb48f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 247,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.695786,
                "lon": -122.025189
            },
            "entity": {
                "abbreviation": "Palomares Elementary School",
                "alternates": {
                    "nces": "060780000747"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6bf5e407-7891-46b0-9431-40afaa7bb48f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Palomares Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "5a343bc9-dc79-4997-8588-69d2feb04764"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Palomares Elementary School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "palomares-elementary-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 143
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.025,37.6944,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.025,37.6944,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94552"
            }
        ]
    },
    {
        "guid": "6388b6a9-3547-4c5a-903c-247509b319c3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 248,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.928854,
                "lon": -122.521642
            },
            "entity": {
                "abbreviation": "Neil Cummins Elementary School",
                "alternates": {
                    "nces": "062091002516"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6388b6a9-3547-4c5a-903c-247509b319c3",
                "isClaimed": false,
                "isPremium": false,
                "location": "Corte Madera, CA",
                "name": "Neil Cummins Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "038742ad-94b6-4220-83f7-0a1b32f99cfd",
                    "schoolDistrict": "038742ad-94b6-4220-83f7-0a1b32f99cfd",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "80ece29c-b2d0-4129-8615-c2bf68e5f264",
                    "zipCode": "c8008f23-0b15-4931-a168-e9d7bb79f0b4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Larkspur-Corta Madera School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "038742ad-94b6-4220-83f7-0a1b32f99cfd",
                        "location": "Larkspur, CA",
                        "name": "Larkspur-Corta Madera School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "db131da3-37dc-42cf-aad2-15024d364e4d",
                            "zipCode": "f981dbb9-3c01-40c1-97a3-f476d8291c9a"
                        },
                        "premium": false,
                        "shortName": "Larkspur-Corta Madera School District",
                        "tagline": [
                            "School District",
                            "Larkspur, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "larkspur-corta-madera-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Neil Cummins Elementary School",
                "tagline": [
                    "Larkspur-Corta Madera School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "neil-cummins-elementary-school-corte-madera-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 599
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.523,37.9287,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.523,37.9287,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94925"
            }
        ]
    },
    {
        "guid": "a970213e-d93b-4738-a0ab-5dab64eb963f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 249,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.887842,
                "lon": -122.268195
            },
            "entity": {
                "abbreviation": "Oxford Elementary School",
                "alternates": {
                    "nces": "060474000448"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a970213e-d93b-4738-a0ab-5dab64eb963f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Oxford Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8c2da8b7-c537-425f-bd36-4353a1e615d0",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "59111173-6ea4-44ab-b974-b2b1bf1af8fb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Oxford Elementary School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "oxford-elementary-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 281
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.269,37.8881,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.269,37.8881,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94707"
            }
        ]
    },
    {
        "guid": "d1fcbbbe-c923-4ce4-8312-22d2ff51276f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 250,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.783035,
                "lon": -122.48274
            },
            "entity": {
                "abbreviation": "Alamo Elementary School",
                "alternates": {
                    "nces": "063441005582"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d1fcbbbe-c923-4ce4-8312-22d2ff51276f",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Alamo Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "17498cb5-11d4-482c-8bb3-a9dad794c27e",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "095d8179-e190-4e1d-a747-e79c35d38d3c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alamo Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "alamo-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 529
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.482,37.7827,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.482,37.7827,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94121"
            }
        ]
    },
    {
        "guid": "1136623e-2639-4754-aa9c-c266e1c1440a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 251,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.180529,
                "lon": -121.99109
            },
            "entity": {
                "abbreviation": "Lexington Elementary School",
                "alternates": {
                    "nces": "062283003491"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1136623e-2639-4754-aa9c-c266e1c1440a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Los Gatos, CA",
                "name": "Lexington Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "2657e046-c2e2-4cea-822c-45bceb563d45",
                    "schoolDistrict": "2657e046-c2e2-4cea-822c-45bceb563d45",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "98637fb0-4b0f-4318-8807-b2944c7d92a8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Los Gatos Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "2657e046-c2e2-4cea-822c-45bceb563d45",
                        "location": "Los Gatos, CA",
                        "name": "Los Gatos Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                            "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                        },
                        "premium": false,
                        "shortName": "Los Gatos Union School District",
                        "tagline": [
                            "School District",
                            "Los Gatos, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "los-gatos-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Lexington Elementary School",
                "tagline": [
                    "Los Gatos Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lexington-elementary-school-los-gatos-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 144
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.993,37.1804,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.993,37.1804,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95033"
            }
        ]
    },
    {
        "guid": "2549e289-b576-48be-9a38-097274f46f91",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 252,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.881942,
                "lon": -122.046433
            },
            "entity": {
                "abbreviation": "Murwood Elementary School",
                "alternates": {
                    "nces": "064125006829"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2549e289-b576-48be-9a38-097274f46f91",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Murwood Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "schoolDistrict": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "f9bfff9c-5a50-47e2-923e-5a7528a271bf"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Walnut Creek Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                        "location": "Walnut Creek, CA",
                        "name": "Walnut Creek Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                            "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                        },
                        "premium": false,
                        "shortName": "Walnut Creek Elementary School District",
                        "tagline": [
                            "School District",
                            "Walnut Creek, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "walnut-creek-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Murwood Elementary School",
                "tagline": [
                    "Walnut Creek Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "murwood-elementary-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 366
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.048,37.8838,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.048,37.8838,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94596"
            }
        ]
    },
    {
        "guid": "a9e56a67-c994-4645-b6ed-0671ea75cfcd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 253,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.038106,
                "lon": -122.544351
            },
            "entity": {
                "abbreviation": "Mary E. Silveira Elementary School",
                "alternates": {
                    "nces": "061122010255"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a9e56a67-c994-4645-b6ed-0671ea75cfcd",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Rafael, CA",
                "name": "Mary E. Silveira Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "617c6d11-21a1-4f7e-bc3b-139235de285c",
                    "parent": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                    "schoolDistrict": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Dixie Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "ae4d168b-dbac-4c07-aa91-8211cee4678e",
                        "location": "San Rafael, CA",
                        "name": "Dixie Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                            "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                        },
                        "premium": false,
                        "shortName": "Dixie Elementary School District",
                        "tagline": [
                            "School District",
                            "San Rafael, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "dixie-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Mary E. Silveira Elementary School",
                "tagline": [
                    "Dixie Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "mary-e-silveira-elementary-school-san-rafael-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 440
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.544,38.0372,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.544,38.0372,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94903"
            }
        ]
    },
    {
        "guid": "4261e32a-4b5f-4c1b-ab78-d541abd7a738",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 254,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.264069,
                "lon": -121.99659
            },
            "entity": {
                "abbreviation": "Marshall Lane Elementary School",
                "alternates": {
                    "nces": "060720000671"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "4261e32a-4b5f-4c1b-ab78-d541abd7a738",
                "isClaimed": false,
                "isPremium": false,
                "location": "Saratoga, CA",
                "name": "Marshall Lane Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "schoolDistrict": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e0be8fef-526c-4910-9500-3b4b953963dd",
                    "zipCode": "d862ebf1-2d26-4072-bc4b-528805062a62"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Campbell Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                        "location": "Campbell, CA",
                        "name": "Campbell Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                            "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                        },
                        "premium": false,
                        "shortName": "Campbell Union School District",
                        "tagline": [
                            "School District",
                            "Campbell, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "campbell-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Marshall Lane Elementary School",
                "tagline": [
                    "Campbell Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "marshall-lane-elementary-school-saratoga-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 541
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "The teachers were great and very supportive. Everyone was nice to each other and very friendly. The school really helped me become more open and confident.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-04-22T15:34:02.954068Z",
                "guid": "48a13415-9f92-4d17-9e8c-98699bc5e8c7",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.996,37.2626,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.996,37.2626,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95070"
            }
        ]
    },
    {
        "guid": "e055c474-e03f-43ac-851e-0c87e42c5dbb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 255,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.763521,
                "lon": -122.476266
            },
            "entity": {
                "abbreviation": "Jefferson Elementary School",
                "alternates": {
                    "nces": "063441005631"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e055c474-e03f-43ac-851e-0c87e42c5dbb",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Jefferson Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a050bfe7-0851-4a3d-8418-018310efa8a2",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b4e89c98-14e0-415a-81a3-67446f01feef"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Jefferson Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "jefferson-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 490
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.477,37.763,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.477,37.763,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94122"
            }
        ]
    },
    {
        "guid": "333699f6-5da5-4617-9c3e-7aedb591bc20",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 256,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.083807,
                "lon": -122.164928
            },
            "entity": {
                "abbreviation": "Matthew Turner Elementary School",
                "alternates": {
                    "nces": "060462002832"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "333699f6-5da5-4617-9c3e-7aedb591bc20",
                "isClaimed": true,
                "isPremium": false,
                "location": "Benicia, CA",
                "name": "Matthew Turner Elementary School",
                "parentGUIDs": {
                    "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "schoolDistrict": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                    "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Benicia Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7c21191c-eac4-441c-86d9-aee0b22405db",
                        "location": "Benicia, CA",
                        "name": "Benicia Unified School District",
                        "parentGUIDs": {
                            "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                            "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                        },
                        "premium": false,
                        "shortName": "Benicia Unified School District",
                        "tagline": [
                            "School District",
                            "Benicia, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "benicia-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Matthew Turner Elementary School",
                "tagline": [
                    "Benicia Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "matthew-turner-elementary-school-benicia-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 498
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.16,38.0854,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.16,38.0854,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94510"
            }
        ]
    },
    {
        "guid": "28f57cf5-4367-4074-8f4b-d8fb14ae6d81",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 257,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.759404,
                "lon": -122.469295
            },
            "entity": {
                "abbreviation": "Alice Fong Yu Elementary",
                "alternates": {
                    "nces": "063441005360"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "28f57cf5-4367-4074-8f4b-d8fb14ae6d81",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Alice Fong Yu Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a050bfe7-0851-4a3d-8418-018310efa8a2",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b4e89c98-14e0-415a-81a3-67446f01feef"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alice Fong Yu Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "alice-fong-yu-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 590
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 30
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "As a Chinese Immersion school, AFY is not for every family.  My kids thrived in the small school environment but for others, having the same classmates for 9 years can be stifling and awkward.  The majority of teachers are great - caring yet having high expectations and able to get the best out of their students.  Unfortunately, parent involvement has declined over the years.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-04-21T02:17:24.14673Z",
                "guid": "e1fe4fd5-a6ca-4cbc-8319-55cc64f4300e",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.47,37.7592,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.47,37.7592,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94122"
            }
        ]
    },
    {
        "guid": "cf75018f-f283-41e8-849f-d9bc8a5121a7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 258,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.697952,
                "lon": -122.080936
            },
            "entity": {
                "abbreviation": "Castro Valley Elementary School",
                "alternates": {
                    "nces": "060780000741"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "cf75018f-f283-41e8-849f-d9bc8a5121a7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Castro Valley Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Castro Valley Elementary School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "castro-valley-elementary-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 506
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.082,37.6983,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.082,37.6983,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94546"
            }
        ]
    },
    {
        "guid": "ccf2fb45-e2e7-464a-aa8e-4c391c243125",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 259,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.430898,
                "lon": -121.914786
            },
            "entity": {
                "abbreviation": "Anthony Spangler Elementary School",
                "alternates": {
                    "nces": "062450003675"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ccf2fb45-e2e7-464a-aa8e-4c391c243125",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "Anthony Spangler Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Anthony Spangler Elementary School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "anthony-spangler-elementary-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 589
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.914,37.4305,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.914,37.4305,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "79262237-f608-46d4-9172-e980021ccb7f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 260,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.887216,
                "lon": -122.068709
            },
            "entity": {
                "abbreviation": "Parkmead Elementary School",
                "alternates": {
                    "nces": "064125009461"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "79262237-f608-46d4-9172-e980021ccb7f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Parkmead Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "schoolDistrict": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "549b68b5-65f5-4e14-8c39-f3e25edc69a0",
                    "zipCode": "0f5bfe3e-c4e2-4b41-afd1-7f0c717be19f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Walnut Creek Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "fae8d5cd-4693-4aa6-ab37-1c214424e412",
                        "location": "Walnut Creek, CA",
                        "name": "Walnut Creek Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                            "zipCode": "fc527efc-132f-406e-bea8-66aaa5a1450e"
                        },
                        "premium": false,
                        "shortName": "Walnut Creek Elementary School District",
                        "tagline": [
                            "School District",
                            "Walnut Creek, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "walnut-creek-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Parkmead Elementary School",
                "tagline": [
                    "Walnut Creek Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "parkmead-elementary-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 450
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.887,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.067,37.887,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94595"
            }
        ]
    },
    {
        "guid": "08bbd60a-a232-4564-8b89-7fc6c39d8a7f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 261,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.541954,
                "lon": -121.967013
            },
            "entity": {
                "abbreviation": "J. Haley Durham Elementary School",
                "alternates": {
                    "nces": "061440001662"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "08bbd60a-a232-4564-8b89-7fc6c39d8a7f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "J. Haley Durham Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "J. Haley Durham Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "j-haley-durham-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 707
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.966,37.5429,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.966,37.5429,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "b4a53f17-456d-4e55-86b6-7362af8195dd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 262,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.288645,
                "lon": -121.907162
            },
            "entity": {
                "abbreviation": "Booksin Elementary School",
                "alternates": {
                    "nces": "063459005702"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b4a53f17-456d-4e55-86b6-7362af8195dd",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Booksin Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9bed7cde-76c6-4ed3-9076-f48cade709d3",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "0f5f9ca7-0382-4b2d-8697-36994f2fddf7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Booksin Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "booksin-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 839
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.906,37.288,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.906,37.288,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95125"
            }
        ]
    },
    {
        "guid": "9adbd4b8-d527-417e-8571-3749d32dc1ce",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 263,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.775125,
                "lon": -122.476803
            },
            "entity": {
                "abbreviation": "Argonne Elementary School",
                "alternates": {
                    "nces": "063441005586"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9adbd4b8-d527-417e-8571-3749d32dc1ce",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Argonne Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "4c5b2024-bead-4cc8-b4bc-873b3b375d10",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "095d8179-e190-4e1d-a747-e79c35d38d3c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Argonne Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "argonne-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 433
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.476,37.7753,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.476,37.7753,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94121"
            }
        ]
    },
    {
        "guid": "b1ab3f9a-9b82-4f55-8f2d-606554a741e5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 264,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.288036,
                "lon": -121.82216
            },
            "entity": {
                "abbreviation": "Rocketship Spark Academy",
                "alternates": {
                    "nces": "060208713582"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "b1ab3f9a-9b82-4f55-8f2d-606554a741e5",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Rocketship Spark Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8db627d4-9ef7-4051-b4f7-cc15a0037356",
                    "schoolNetwork": "159c1d35-78c2-4e2f-8837-1d24cf94af66",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "22009871-64c9-41cb-9a56-3fd200b76810"
                },
                "premium": false,
                "shortName": "Rocketship Spark Academy",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rocketship-spark-academy-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 608
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 36
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.824,37.2879,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.824,37.2879,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95111"
            }
        ]
    },
    {
        "guid": "6e71e941-3383-490b-a575-6430f8a8237b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 265,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.574379,
                "lon": -122.316427
            },
            "entity": {
                "abbreviation": "North Shoreview Montessori Elementary",
                "alternates": {
                    "nces": "063492005914"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "6e71e941-3383-490b-a575-6430f8a8237b",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "North Shoreview Montessori Elementary",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "3435a379-835e-44ad-a67d-683bc7ef6fc8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "North Shoreview Montessori Elementary",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "north-shoreview-montessori-elementary-san-mateo-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 328
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.316,37.5753,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.316,37.5753,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94401"
            }
        ]
    },
    {
        "guid": "6680966b-37cf-49cd-9681-160b6121c3d9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 266,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.395506,
                "lon": -122.094923
            },
            "entity": {
                "abbreviation": "Gabriela Mistral Elementary School",
                "alternates": {
                    "nces": "062628013928"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6680966b-37cf-49cd-9681-160b6121c3d9",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Gabriela Mistral Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "schoolDistrict": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "e8a55ab4-9bff-4942-821c-369c0c98966c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mountain View Whisman School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "55669afd-2c14-4350-8f57-42ebb04aa320",
                        "location": "Mountain View, CA",
                        "name": "Mountain View Whisman School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                            "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                        },
                        "premium": false,
                        "shortName": "Mountain View Whisman School District",
                        "tagline": [
                            "School District",
                            "Mountain View, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mountain-view-whisman-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Gabriela Mistral Elementary School",
                "tagline": [
                    "Mountain View Whisman School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "gabriela-mistral-elementary-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 373
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.095,37.3954,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.095,37.3954,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94040"
            }
        ]
    },
    {
        "guid": "ae1764f7-43c7-43c9-9ee7-0d5ab5b9702e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 267,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.718648,
                "lon": -121.717474
            },
            "entity": {
                "abbreviation": "Altamont Creek Elementary School",
                "alternates": {
                    "nces": "062211008438"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "ae1764f7-43c7-43c9-9ee7-0d5ab5b9702e",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Altamont Creek Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Altamont Creek Elementary School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "altamont-creek-elementary-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 585
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.718,37.7226,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.718,37.7226,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94551"
            }
        ]
    },
    {
        "guid": "cbb0b12f-96e1-4595-bf26-0e8b3bf08dc4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 268,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.388989,
                "lon": -122.07107
            },
            "entity": {
                "abbreviation": "Edith Landels Elementary School",
                "alternates": {
                    "nces": "062628003926"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "cbb0b12f-96e1-4595-bf26-0e8b3bf08dc4",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Edith Landels Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "schoolDistrict": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "75615aec-3332-4e66-b553-a3d95e4ce31b"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mountain View Whisman School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "55669afd-2c14-4350-8f57-42ebb04aa320",
                        "location": "Mountain View, CA",
                        "name": "Mountain View Whisman School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                            "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                        },
                        "premium": false,
                        "shortName": "Mountain View Whisman School District",
                        "tagline": [
                            "School District",
                            "Mountain View, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mountain-view-whisman-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Edith Landels Elementary School",
                "tagline": [
                    "Mountain View Whisman School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "edith-landels-elementary-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 491
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great school and activities at Landels. \nEarly focus on STEM seems to be working great. The administration and teachers are very attentive to feedback and a bunch of really dedicated teachers!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-12-22T18:45:44.078231Z",
                "guid": "1b6ba59b-c1ea-46cf-88ad-3a7b4d08cc65",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.071,37.3884,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.071,37.3884,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94041"
            }
        ]
    },
    {
        "guid": "6e41c8f6-5e21-4d93-b17a-0af61aae2964",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 269,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.731847,
                "lon": -122.471051
            },
            "entity": {
                "abbreviation": "Commodore Sloat Elementary",
                "alternates": {
                    "nces": "063441005598"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6e41c8f6-5e21-4d93-b17a-0af61aae2964",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Commodore Sloat Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "3541273e-c42b-45f7-9a8f-3f1a2d367781",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "50a82c65-04cd-4b00-bb26-c5f015b369e3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Commodore Sloat Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "commodore-sloat-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 390
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.471,37.7313,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.471,37.7313,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94127"
            }
        ]
    },
    {
        "guid": "ba917938-68a8-4e32-a658-a7abddd58ff7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 270,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.763833,
                "lon": -122.450333
            },
            "entity": {
                "abbreviation": "Grattan Elementary School",
                "alternates": {
                    "nces": "063441005620"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ba917938-68a8-4e32-a658-a7abddd58ff7",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Grattan Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "c0d81ab1-1c29-4ee3-84da-76a4d5071375",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "7db16a60-8255-4b6d-ac82-4ba69c978bcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Grattan Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "grattan-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 387
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.451,37.7635,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.451,37.7635,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94117"
            }
        ]
    },
    {
        "guid": "97bbdf65-0db9-41e9-967a-ad074244082b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 271,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.220554,
                "lon": -121.868317
            },
            "entity": {
                "abbreviation": "Simonds Elementary School",
                "alternates": {
                    "nces": "063459005738"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "97bbdf65-0db9-41e9-967a-ad074244082b",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Simonds Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "76f228c2-291e-4ea6-839a-447faaa4a145",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "67602db5-a16e-4627-8133-9f3d9f61c732"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Simonds Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "simonds-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 651
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.87,37.2205,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.87,37.2205,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95120"
            }
        ]
    },
    {
        "guid": "a061b577-3ad9-482d-9106-7a9cddd77ab0",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 272,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.399987,
                "lon": -121.855821
            },
            "entity": {
                "abbreviation": "Ruskin Elementary School",
                "alternates": {
                    "nces": "060480000465"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a061b577-3ad9-482d-9106-7a9cddd77ab0",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Ruskin Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ruskin Elementary School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ruskin-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 568
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "I must say this school made me, who didn't care about academics, become better in academics. I transferred in with horrible grades and only excelled when I came out of that school.  The teachers there care! That makes a difference in my experience there.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-06-14T07:54:52.530755Z",
                "guid": "1b22b52a-52f7-4792-949d-d797e9d190ff",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.857,37.3999,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.857,37.3999,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "c57b1a33-df63-4d62-a2aa-84b63022c6f2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 273,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.567047,
                "lon": -122.259064
            },
            "entity": {
                "abbreviation": "Audubon Elementary School",
                "alternates": {
                    "nces": "063492005897"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c57b1a33-df63-4d62-a2aa-84b63022c6f2",
                "isClaimed": false,
                "isPremium": false,
                "location": "Foster City, CA",
                "name": "Audubon Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                    "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Audubon Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "audubon-elementary-school-foster-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 787
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.258,37.5678,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.258,37.5678,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94404"
            }
        ]
    },
    {
        "guid": "bf83a5b1-43ff-4617-9e51-33079a9be738",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 274,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.606023,
                "lon": -122.404594
            },
            "entity": {
                "abbreviation": "Green Hills Elementary School",
                "alternates": {
                    "nces": "062490003717"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bf83a5b1-43ff-4617-9e51-33079a9be738",
                "isClaimed": false,
                "isPremium": false,
                "location": "Millbrae, CA",
                "name": "Green Hills Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "schoolDistrict": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a22149ca-7510-485b-b4ab-2f8db3bd4a65",
                    "zipCode": "4fba468a-9eb7-49d5-b87f-036348d35412"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Millbrae Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                        "location": "Millbrae, CA",
                        "name": "Millbrae Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a22149ca-7510-485b-b4ab-2f8db3bd4a65",
                            "zipCode": "4fba468a-9eb7-49d5-b87f-036348d35412"
                        },
                        "premium": false,
                        "shortName": "Millbrae Elementary School District",
                        "tagline": [
                            "School District",
                            "Millbrae, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "millbrae-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Green Hills Elementary School",
                "tagline": [
                    "Millbrae Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "green-hills-elementary-school-millbrae-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 385
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.405,37.605,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.405,37.605,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94030"
            }
        ]
    },
    {
        "guid": "6350a527-7f8e-4a3f-88fe-f49c302a7156",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 275,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.800265,
                "lon": -122.2654
            },
            "entity": {
                "abbreviation": "American Indian Public Charter School II",
                "alternates": {
                    "nces": "060188012013"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "6350a527-7f8e-4a3f-88fe-f49c302a7156",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "American Indian Public Charter School II",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                },
                "premium": false,
                "shortName": "American Indian Public Charter School II",
                "tagline": [
                    "Public School",
                    "Oakland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "american-indian-public-charter-school-ii-oakland-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 794
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "American Indian Public High School prepared me for college readiness. The school has high test scores and has a lot of AP courses that students are required to take.  The school needs to improve resources for students because I needed a lot of help in the college process. I was confused and didn't have anyone to guide me. I had to find out things by myself. My school is very small and we only have one counselor. Our senior class is big and for just one counselor to handle us was hard. We need more teachers and counselors to be hired so we could have more resources from them. We are a low-income school and we need a lot of help financially. Although we don't have a lot of money in my school, they still managed to keep the sports team in good shape. We constantly win championships every year with at least one sport. Overall, American Indian is a good school but needs to improve in resources for students.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-18T22:16:31.846648Z",
                "guid": "d0580779-b908-4fd6-8b77-94e31a607ce2",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.265,37.8001,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.265,37.8001,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.4,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94607"
            }
        ]
    },
    {
        "guid": "bb4eae60-a9e7-43cd-8d4d-3238933ea460",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 276,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.263931,
                "lon": -121.970379
            },
            "entity": {
                "abbreviation": "Village",
                "alternates": {
                    "nces": "060720011447"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "bb4eae60-a9e7-43cd-8d4d-3238933ea460",
                "isClaimed": false,
                "isPremium": false,
                "location": "Campbell, CA",
                "name": "Village",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                    "parent": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "schoolDistrict": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3c3feaf1-4124-4b7a-acde-a86df9bd11e7",
                    "zipCode": "8d00fb60-14ac-43b4-a329-feca61c7ad31"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Campbell Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                        "location": "Campbell, CA",
                        "name": "Campbell Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                            "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                        },
                        "premium": false,
                        "shortName": "Campbell Union School District",
                        "tagline": [
                            "School District",
                            "Campbell, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "campbell-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Village",
                "tagline": [
                    "Campbell Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "village-campbell-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 263
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Village is an excellent school. The parent involvement is what makes Village School great. The teachers and staff are also very experienced. \nThis is my child's third year at this school and she has learned so much, not only academically, but she also has developed social and life skills.\n The school set is small so we feel like we are a big family. \n If you like parent participation, it is up to the commitment this school requires and want your child that attend a school that is not focus on state tests. This is the right school for you.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-03-27T04:17:34.024538Z",
                "guid": "6e366f70-d91d-4263-91ab-6eac79a015c6",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.965,37.2644,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.965,37.2644,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95008"
            }
        ]
    },
    {
        "guid": "00592f01-d59c-4d9c-8a5b-9da7b2150223",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 277,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.769809,
                "lon": -122.252095
            },
            "entity": {
                "abbreviation": "Henry Haight Elementary School",
                "alternates": {
                    "nces": "060177000047"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "00592f01-d59c-4d9c-8a5b-9da7b2150223",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Henry Haight Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Henry Haight Elementary School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "henry-haight-elementary-school-alameda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 544
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.251,37.7692,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.251,37.7692,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "4fe4edd4-0c7c-4250-a268-76ff68800ed0",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 278,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.825162,
                "lon": -122.159339
            },
            "entity": {
                "abbreviation": "Canyon Elementary School",
                "alternates": {
                    "nces": "060735000680"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4fe4edd4-0c7c-4250-a268-76ff68800ed0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Canyon, CA",
                "name": "Canyon Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "5061a77e-e472-4a2b-ac66-f74645bd3499"
                },
                "premium": false,
                "shortName": "Canyon Elementary School",
                "tagline": [
                    "Public School",
                    "Canyon, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "canyon-elementary-school-canyon-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 67
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 34
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.167,37.8308,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.167,37.8308,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94516"
            }
        ]
    },
    {
        "guid": "37a3d83e-eea4-4a60-a854-33a3b64e9214",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 279,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.894202,
                "lon": -122.267934
            },
            "entity": {
                "abbreviation": "Cragmont Elementary School",
                "alternates": {
                    "nces": "060474006895"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "37a3d83e-eea4-4a60-a854-33a3b64e9214",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Cragmont Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "f5c0f12b-ab0f-4712-8aa3-d11164adea5b",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "0ce57911-0bf8-45b6-a9aa-72972eb07e24"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Cragmont Elementary School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cragmont-elementary-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 377
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.268,37.8939,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.268,37.8939,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94708"
            }
        ]
    },
    {
        "guid": "18166cd0-2c54-4cf0-89e2-7962cafd270e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 280,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.31043,
                "lon": -121.764654
            },
            "entity": {
                "abbreviation": "Tom Matsumoto Elementary School",
                "alternates": {
                    "nces": "061314007788"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "18166cd0-2c54-4cf0-89e2-7962cafd270e",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Tom Matsumoto Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "7b9128e4-a557-4f99-aa1e-3d1bcd32a36a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Tom Matsumoto Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "tom-matsumoto-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 657
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.766,37.3117,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.766,37.3117,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95135"
            }
        ]
    },
    {
        "guid": "24a7faac-015f-408c-a791-fa09cbb0f18c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 281,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.00548,
                "lon": -122.575292
            },
            "entity": {
                "abbreviation": "Hidden Valley Elementary School",
                "alternates": {
                    "nces": "060000612911"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "24a7faac-015f-408c-a791-fa09cbb0f18c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Anselmo, CA",
                "name": "Hidden Valley Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "schoolDistrict": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6d543989-2061-482c-a738-faa7d4da2479",
                    "zipCode": "0455b370-2ddd-4cd1-ae84-32c046dba7fb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Ross Valley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e787e270-2623-42bc-917f-95d6d7ff2026",
                        "location": "San Anselmo, CA",
                        "name": "Ross Valley Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "0db5806d-7d26-41a5-8300-2d00fc0dfd2c",
                            "zipCode": "0455b370-2ddd-4cd1-ae84-32c046dba7fb"
                        },
                        "premium": false,
                        "shortName": "Ross Valley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Anselmo, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "ross-valley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Hidden Valley Elementary School",
                "tagline": [
                    "Ross Valley Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "hidden-valley-elementary-school-san-anselmo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 331
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "This school is great!  We love it here, we moved from SF and were so pleased when we found Hidden Valley.  It is a hidden little gem of a learning community.  Wonderful teachers who show dedication and commitment to the learning of all students,  a great parent community, it is  place that feels good.  I'm so glad that my kids will grow up here.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-04-16T17:21:54.56238Z",
                "guid": "201b1de9-203e-41ac-8a37-a369fa2b94b4",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.574,38.0056,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.574,38.0056,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94960"
            }
        ]
    },
    {
        "guid": "08a2b1a7-ab2a-40f6-9784-fde4ff5dfd40",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 282,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.70023,
                "lon": -122.092799
            },
            "entity": {
                "abbreviation": "Stanton Elementary School",
                "alternates": {
                    "nces": "060780009525"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "08a2b1a7-ab2a-40f6-9784-fde4ff5dfd40",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Stanton Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "schoolDistrict": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Castro Valley Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3707b90b-92ee-4fe1-b360-2afce14889fb",
                        "location": "Castro Valley, CA",
                        "name": "Castro Valley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                            "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                        },
                        "premium": false,
                        "shortName": "Castro Valley Unified School District",
                        "tagline": [
                            "School District",
                            "Castro Valley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "castro-valley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Stanton Elementary School",
                "tagline": [
                    "Castro Valley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "stanton-elementary-school-castro-valley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 493
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.093,37.7006,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.093,37.7006,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94546"
            }
        ]
    },
    {
        "guid": "cefe8523-d75f-4f86-9df5-e50ec3a0f40f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 283,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.523707,
                "lon": -122.349446
            },
            "entity": {
                "abbreviation": "Highlands Elementary School",
                "alternates": {
                    "nces": "063492005907"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "cefe8523-d75f-4f86-9df5-e50ec3a0f40f",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Highlands Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "062f4806-af97-4d46-8d31-2a26304d0e2f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Highlands Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "highlands-elementary-school-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 527
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.35,37.5231,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.35,37.5231,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94402"
            }
        ]
    },
    {
        "guid": "ed1a9504-fb4e-42d2-87f8-54c11ccc9fe8",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 284,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.562256,
                "lon": -122.030939
            },
            "entity": {
                "abbreviation": "Patterson Elementary School",
                "alternates": {
                    "nces": "061440001685"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ed1a9504-fb4e-42d2-87f8-54c11ccc9fe8",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Patterson Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Patterson Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "patterson-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 786
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.032,37.5615,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.032,37.5615,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "290a3faa-dc62-4c54-8391-fb5d503f978a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 285,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.000678,
                "lon": -122.10072
            },
            "entity": {
                "abbreviation": "Morello Park Elementary School",
                "alternates": {
                    "nces": "062403000178"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "290a3faa-dc62-4c54-8391-fb5d503f978a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Martinez, CA",
                "name": "Morello Park Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb309258-c416-4193-9d2e-072248ea2ba6",
                    "schoolDistrict": "bb309258-c416-4193-9d2e-072248ea2ba6",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                    "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Martinez Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb309258-c416-4193-9d2e-072248ea2ba6",
                        "location": "Martinez, CA",
                        "name": "Martinez Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                            "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                        },
                        "premium": false,
                        "shortName": "Martinez Unified School District",
                        "tagline": [
                            "School District",
                            "Martinez, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "martinez-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Morello Park Elementary School",
                "tagline": [
                    "Martinez Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "morello-park-elementary-school-martinez-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 514
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.099,38.0015,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.099,38.0015,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94553"
            }
        ]
    },
    {
        "guid": "5fe8e717-a048-4848-bb1e-470a8aaed346",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 286,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.613641,
                "lon": -122.485801
            },
            "entity": {
                "abbreviation": "Vallemar Elementary School",
                "alternates": {
                    "nces": "062046002470"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5fe8e717-a048-4848-bb1e-470a8aaed346",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pacifica, CA",
                "name": "Vallemar Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "schoolDistrict": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                    "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Pacifica School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                        "location": "Pacifica, CA",
                        "name": "Pacifica School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                            "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                        },
                        "premium": false,
                        "shortName": "Pacifica School District",
                        "tagline": [
                            "School District",
                            "Pacifica, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pacifica-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Vallemar Elementary School",
                "tagline": [
                    "Pacifica School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "vallemar-elementary-school-pacifica-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 514
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Vallemar Elementary School provides a high standard of education in english, social studies, math and science to their students, preparing them sufficiently for any college level courses they may take in high school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-14T06:52:31.88166Z",
                "guid": "fd8456e6-765a-4215-8a72-5db8b9e8ae03",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.484,37.6143,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.484,37.6143,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.6666666666666665,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94044"
            }
        ]
    },
    {
        "guid": "d25fed67-088e-4361-ae4d-7fd58dab7a72",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 287,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.343429,
                "lon": -121.964667
            },
            "entity": {
                "abbreviation": "C. W. Haman Elementary School",
                "alternates": {
                    "nces": "063543006040"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "d25fed67-088e-4361-ae4d-7fd58dab7a72",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "C.W. Haman Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "91f8ca89-1d96-436a-8a3f-5e45a8940ad1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "C. W. Haman Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cw-haman-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 381
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.964,37.3433,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.964,37.3433,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95050"
            }
        ]
    },
    {
        "guid": "4e21ce07-2d31-45f1-924d-b518d9d8ce86",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 288,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.303761,
                "lon": -121.887095
            },
            "entity": {
                "abbreviation": "Hammer Montessori at Galarza Elementary School",
                "alternates": {
                    "nces": "063459007429"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "4e21ce07-2d31-45f1-924d-b518d9d8ce86",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Hammer Montessori at Galarza Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9bed7cde-76c6-4ed3-9076-f48cade709d3",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "0f5f9ca7-0382-4b2d-8697-36994f2fddf7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Hammer Montessori at Galarza Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "hammer-montessori-at-galarza-elementary-school-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 326
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "featuredReview": {
                "author": "Junior",
                "body": "While the teacher to student ratio is very good, the classes do not teach students time management skills, since they are allowed to choose what they wish to do.  In addition, the Parent Teacher Association at Hammer Montessori has experienced turmoil, especially with the PTA of Galarza Elementary, the school residing on the same campus.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-08-26T04:11:30.956667Z",
                "guid": "658801ac-2eed-4bfb-9cd5-6df2cacd1c98",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.885,37.3029,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.885,37.3029,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95125"
            }
        ]
    },
    {
        "guid": "bb397db5-70d6-40d4-8058-0d1595b08ac3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 289,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.544996,
                "lon": -121.997616
            },
            "entity": {
                "abbreviation": "Tom Maloney Elementary School",
                "alternates": {
                    "nces": "061440001674"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bb397db5-70d6-40d4-8058-0d1595b08ac3",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Tom Maloney Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Tom Maloney Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "tom-maloney-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 589
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.998,37.5463,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.998,37.5463,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "d0d47477-646e-4b50-b27e-6904f9350026",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 290,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.260299,
                "lon": -121.942967
            },
            "entity": {
                "abbreviation": "Farnham Charter School",
                "alternates": {
                    "nces": "060714000653"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "d0d47477-646e-4b50-b27e-6904f9350026",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Farnham Charter School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "schoolDistrict": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cambrian School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "aec99b83-c70f-442f-b30c-4c79582989d1",
                        "location": "San Jose, CA",
                        "name": "Cambrian School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Cambrian School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cambrian-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Farnham Charter School",
                "tagline": [
                    "Cambrian School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "farnham-charter-school-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 528
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.944,37.2607,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.944,37.2607,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "917a38bb-54f1-40b2-bf36-917d4d88a66f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 291,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.407003,
                "lon": -121.905183
            },
            "entity": {
                "abbreviation": "Pearl Zanker Elementary School",
                "alternates": {
                    "nces": "062450003677"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "917a38bb-54f1-40b2-bf36-917d4d88a66f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "Pearl Zanker Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Pearl Zanker Elementary School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "pearl-zanker-elementary-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 635
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I like the vast variety of events children can do to be involved in the school. Many assemblies with many subjects and guest speakers to teach kids. The academics were challenging but easy to grasp. Kids loved to play sports and there were even mini contests teachers created for them. Overall fun experience.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-07-26T21:06:04.806053Z",
                "guid": "44536f5e-554a-4926-9d71-8030de50c747",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.906,37.4077,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.906,37.4077,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "5b16fef8-8677-47f3-a2d9-0614bded1243",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 292,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.716113,
                "lon": -122.46622
            },
            "entity": {
                "abbreviation": "Jose Ortega Elementary",
                "alternates": {
                    "nces": "063441005635"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5b16fef8-8677-47f3-a2d9-0614bded1243",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Jose Ortega Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5bc2c068-17f6-4acf-850a-4d0ba012f9d3",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "0d98457f-33f6-4c34-ba1e-34f6f778475c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Jose Ortega Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "jose-ortega-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 399
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.467,37.7163,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.467,37.7163,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94132"
            }
        ]
    },
    {
        "guid": "51176a1b-8fc5-4f79-a84f-d93fbda15e2c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 293,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.296655,
                "lon": -121.788995
            },
            "entity": {
                "abbreviation": "James Franklin Smith Elementary School",
                "alternates": {
                    "nces": "061314008631"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "51176a1b-8fc5-4f79-a84f-d93fbda15e2c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "James Franklin Smith Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "d45dfd8d-1c4a-4d14-adf5-73af66adef2a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "James Franklin Smith Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "james-franklin-smith-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 642
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "I have two kids that go to James Franklin Smith and I couldn't be happier. We have really experienced teachers that care for the children and do their very best to give them a well-rounded education. I feel the JFS has created a safe environment for my kids. I like the way the school has included in enrichment programs during the pandemic. Overall I can recommend this school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-10-14T03:02:39.851141Z",
                "guid": "c0774678-a0da-4bbb-8843-df7f0f5516ac",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.787,37.2972,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.787,37.2972,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95121"
            }
        ]
    },
    {
        "guid": "dc7dd9c7-31bf-4704-80df-2ac035ffa559",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 294,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.575235,
                "lon": -121.96022
            },
            "entity": {
                "abbreviation": "Vallejo Mill Elementary School",
                "alternates": {
                    "nces": "061440001688"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "dc7dd9c7-31bf-4704-80df-2ac035ffa559",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Vallejo Mill Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Vallejo Mill Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "vallejo-mill-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 519
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.961,37.5749,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.961,37.5749,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "e02e54e8-ca2b-4bcd-995e-7af1605500b3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 295,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.433119,
                "lon": -121.877482
            },
            "entity": {
                "abbreviation": "Alexander Rose Elementary School",
                "alternates": {
                    "nces": "062450003672"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e02e54e8-ca2b-4bcd-995e-7af1605500b3",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "Alexander Rose Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alexander Rose Elementary School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "alexander-rose-elementary-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 479
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.876,37.4325,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.876,37.4325,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "89d29494-65df-4ca4-a9b8-a544764dd49d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 296,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.366926,
                "lon": -122.055853
            },
            "entity": {
                "abbreviation": "Cherry Chase Elementary School",
                "alternates": {
                    "nces": "063846006458"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "89d29494-65df-4ca4-a9b8-a544764dd49d",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Cherry Chase Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "schoolDistrict": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "12ede258-9fa8-4ba5-9e1e-4d80c11aa22e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Sunnyvale School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                        "location": "Sunnyvale, CA",
                        "name": "Sunnyvale School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                        },
                        "premium": false,
                        "shortName": "Sunnyvale School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "sunnyvale-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Cherry Chase Elementary School",
                "tagline": [
                    "Sunnyvale School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cherry-chase-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 835
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "DescriptionCherry Chase Elementary School is located in the Sunnyvale Elementary School District in Sunnyvale, California. It is one of the 8 grade K-5 elementary schools located in the ten-school district.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-03-25T14:02:00.303675Z",
                "guid": "bb41da17-76fe-4f86-aa24-df989a1986d8",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.057,37.366,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.057,37.366,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.3333333333333335,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94087"
            }
        ]
    },
    {
        "guid": "88315ba3-6e11-42c1-a029-c02d648e0aee",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 297,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.079872,
                "lon": -122.172082
            },
            "entity": {
                "abbreviation": "Joe Henderson Elementary School",
                "alternates": {
                    "nces": "060462009116"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "88315ba3-6e11-42c1-a029-c02d648e0aee",
                "isClaimed": true,
                "isPremium": false,
                "location": "Benicia, CA",
                "name": "Joe Henderson Elementary School",
                "parentGUIDs": {
                    "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "schoolDistrict": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                    "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Benicia Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7c21191c-eac4-441c-86d9-aee0b22405db",
                        "location": "Benicia, CA",
                        "name": "Benicia Unified School District",
                        "parentGUIDs": {
                            "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                            "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                        },
                        "premium": false,
                        "shortName": "Benicia Unified School District",
                        "tagline": [
                            "School District",
                            "Benicia, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "benicia-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Joe Henderson Elementary School",
                "tagline": [
                    "Benicia Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "joe-henderson-elementary-school-benicia-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 548
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "I am now a senior in high school, but I still remember how much I loved going to Joe Henderson. I used to tell everybody about how much I loved going to school. Its a super safe environment with very friendly families and teachers. 10/10 would reccomend. Go Henderson Hounds!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-09-05T01:20:01.833654Z",
                "guid": "7cc3d743-6bdb-47cb-9603-1b8e3015288d",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.17,38.0817,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.17,38.0817,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94510"
            }
        ]
    },
    {
        "guid": "daa533e5-e0b1-435b-bdeb-18ecb63a5fc1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 298,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.561453,
                "lon": -122.259506
            },
            "entity": {
                "abbreviation": "Brewer Island Elementary School",
                "alternates": {
                    "nces": "063492005404"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "daa533e5-e0b1-435b-bdeb-18ecb63a5fc1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Foster City, CA",
                "name": "Brewer Island Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                    "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Brewer Island Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "brewer-island-elementary-school-foster-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 567
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "I’m very satisfied with everything that the school offer to the students. The teachers are very creativities and dedicated.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-02-28T05:23:42.805064Z",
                "guid": "f2fa10e4-7e98-48e1-ba06-6d32f57d8248",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.261,37.5616,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.261,37.5616,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94404"
            }
        ]
    },
    {
        "guid": "930e02c4-1985-44f3-b52a-63f7982cc210",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 299,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.602291,
                "lon": -122.418815
            },
            "entity": {
                "abbreviation": "Meadows Elementary School",
                "alternates": {
                    "nces": "062490003719"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "930e02c4-1985-44f3-b52a-63f7982cc210",
                "isClaimed": false,
                "isPremium": false,
                "location": "Millbrae, CA",
                "name": "Meadows Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "schoolDistrict": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a22149ca-7510-485b-b4ab-2f8db3bd4a65",
                    "zipCode": "4fba468a-9eb7-49d5-b87f-036348d35412"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Millbrae Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                        "location": "Millbrae, CA",
                        "name": "Millbrae Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a22149ca-7510-485b-b4ab-2f8db3bd4a65",
                            "zipCode": "4fba468a-9eb7-49d5-b87f-036348d35412"
                        },
                        "premium": false,
                        "shortName": "Millbrae Elementary School District",
                        "tagline": [
                            "School District",
                            "Millbrae, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "millbrae-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Meadows Elementary School",
                "tagline": [
                    "Millbrae Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "meadows-elementary-school-millbrae-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 438
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.419,37.6016,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.419,37.6016,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94030"
            }
        ]
    },
    {
        "guid": "7a9f19a7-8e83-4ddc-8a48-3326ac1026c2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 300,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.966576,
                "lon": -122.080535
            },
            "entity": {
                "abbreviation": "Valhalla Elementary School",
                "alternates": {
                    "nces": "062637003977"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7a9f19a7-8e83-4ddc-8a48-3326ac1026c2",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Valhalla Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "454e8dd6-f26e-4493-a281-1f2250e8bae9",
                    "zipCode": "c02f3379-b7e4-421d-8daf-40132890b3fd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Valhalla Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "valhalla-elementary-school-pleasant-hill-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 569
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Valhalla is a great community. The teachers are dedicated, the parents are involved and the principal is amazing.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-03-02T20:53:49.344633Z",
                "guid": "42ad2427-387a-4edf-8f98-3745d76479a1",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.081,37.9677,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.081,37.9677,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94523"
            }
        ]
    },
    {
        "guid": "37a6fe99-05ef-4615-85ca-599ac7482558",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 301,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.27865,
                "lon": -121.922106
            },
            "entity": {
                "abbreviation": "Bagby Elementary School",
                "alternates": {
                    "nces": "060714000650"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "37a6fe99-05ef-4615-85ca-599ac7482558",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Bagby Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "schoolDistrict": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cambrian School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "aec99b83-c70f-442f-b30c-4c79582989d1",
                        "location": "San Jose, CA",
                        "name": "Cambrian School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Cambrian School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cambrian-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Bagby Elementary School",
                "tagline": [
                    "Cambrian School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "bagby-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 511
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.922,37.2774,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.922,37.2774,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "d8617771-ea01-4b57-b7ff-a5ab689d6993",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 302,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.345371,
                "lon": -121.976084
            },
            "entity": {
                "abbreviation": "Central Park Elementary School",
                "alternates": {
                    "nces": "063543013982"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "d8617771-ea01-4b57-b7ff-a5ab689d6993",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Central Park Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Central Park Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "central-park-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 399
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 45
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {},
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95051"
            }
        ]
    },
    {
        "guid": "d5199c9a-bea4-45e1-a3c6-d606f96f1fbc",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 303,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.943405,
                "lon": -122.067607
            },
            "entity": {
                "abbreviation": "Sequoia Elementary School",
                "alternates": {
                    "nces": "062637007319"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "d5199c9a-bea4-45e1-a3c6-d606f96f1fbc",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Sequoia Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "454e8dd6-f26e-4493-a281-1f2250e8bae9",
                    "zipCode": "c02f3379-b7e4-421d-8daf-40132890b3fd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sequoia Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sequoia-elementary-school-pleasant-hill-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 571
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.069,37.9441,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.069,37.9441,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94523"
            }
        ]
    },
    {
        "guid": "7d3fa680-92e4-4d4e-8e0f-e57dfa83d95c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 304,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.748845,
                "lon": -122.492308
            },
            "entity": {
                "abbreviation": "Robert Louis Stevenson Elementary",
                "alternates": {
                    "nces": "063441005660"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7d3fa680-92e4-4d4e-8e0f-e57dfa83d95c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Robert Louis Stevenson Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "47575dee-023f-4a2f-8c52-61e4471bda57",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "dfff3cae-3a03-46a7-b060-62db0e2af199"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Robert Louis Stevenson Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "robert-louis-stevenson-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 489
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Incredibly warm community. Teachers are well trained and friendly. Nice facilities and a huge variety of resources that are accessed.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-24T18:18:42.664139Z",
                "guid": "f2380f1b-c815-4ecc-b318-d7ecd17a9f0c",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.492,37.7487,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.492,37.7487,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94116"
            }
        ]
    },
    {
        "guid": "e8d058c2-bc64-4d7e-9bd0-a6000a63bf36",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 305,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.278863,
                "lon": -121.972258
            },
            "entity": {
                "abbreviation": "Southside Elementary School",
                "alternates": {
                    "nces": "063765006379"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e8d058c2-bc64-4d7e-9bd0-a6000a63bf36",
                "isClaimed": false,
                "isPremium": false,
                "location": "Hollister, CA",
                "name": "Southside Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                },
                "premium": false,
                "shortName": "Southside Elementary School",
                "tagline": [
                    "Public School",
                    "Hollister, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "southside-elementary-school-hollister-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 229
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.365,36.8007,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.365,36.8007,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95023"
            }
        ]
    },
    {
        "guid": "8f6d1039-cab1-4f14-9062-ee96c98a7e74",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 306,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.333035,
                "lon": -121.961603
            },
            "entity": {
                "abbreviation": "Westwood Elementary School",
                "alternates": {
                    "nces": "063543006054"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "8f6d1039-cab1-4f14-9062-ee96c98a7e74",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Westwood Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "91f8ca89-1d96-436a-8a3f-5e45a8940ad1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Westwood Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "westwood-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 392
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.96,37.333,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.96,37.333,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95050"
            }
        ]
    },
    {
        "guid": "ca3b4420-08ee-4a07-8f99-b9148e9d5929",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 307,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.935376,
                "lon": -122.070431
            },
            "entity": {
                "abbreviation": "Horizons Alternative School",
                "alternates": {
                    "nces": "062637009946"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ca3b4420-08ee-4a07-8f99-b9148e9d5929",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Horizons School: Independent Study",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "454e8dd6-f26e-4493-a281-1f2250e8bae9",
                    "zipCode": "c02f3379-b7e4-421d-8daf-40132890b3fd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Horizons Alternative School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "horizons-school--independent-study-pleasant-hill-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 155
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 11
                }
            ],
            "featuredReview": {
                "author": "Freshman",
                "body": "Horizons Independent Study School provides outstanding opportunities for students who (1) can't go to regular school because of other commitments (e.g., sports); (2) want to finish school faster; or (3) don't like going to regular school. Each student at Horizons gets an individual advisor who helps you through the year. Advisors are wonderful: very knowledgeable and caring! You get free help for math! Plus you can complete courses at the local community college earning college and high school credits at the same time.  Love it.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-01-25T16:41:20.608285Z",
                "guid": "5c7b17ee-c9df-42c6-8c91-d49882e6fa12",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.068,37.9336,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.068,37.9336,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.388888888888889,
                "count": 18
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94523"
            }
        ]
    },
    {
        "guid": "c1cde998-bcce-4dc4-9312-ffa3093101bd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 308,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.31064,
                "lon": -121.778243
            },
            "entity": {
                "abbreviation": "Evergreen Elementary School",
                "alternates": {
                    "nces": "061314001492"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c1cde998-bcce-4dc4-9312-ffa3093101bd",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Evergreen Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "7b9128e4-a557-4f99-aa1e-3d1bcd32a36a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Evergreen Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "evergreen-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 738
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.779,37.3097,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.779,37.3097,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95135"
            }
        ]
    },
    {
        "guid": "7b740bac-31bf-4bef-b226-6ab397e364eb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 309,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.985787,
                "lon": -122.549976
            },
            "entity": {
                "abbreviation": "Sun Valley Elementary School",
                "alternates": {
                    "nces": "063509005940"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7b740bac-31bf-4bef-b226-6ab397e364eb",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Rafael, CA",
                "name": "Sun Valley Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "dc35c977-7c80-4331-8c54-73b06934204e",
                    "parent": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                    "schoolDistrict": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                    "zipCode": "218cd2f0-f326-4a8e-a957-02117d51d241"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Rafael City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                        "location": "San Rafael, CA",
                        "name": "San Rafael City Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                            "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                        },
                        "premium": false,
                        "shortName": "San Rafael City Elementary School District",
                        "tagline": [
                            "School District",
                            "San Rafael, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-rafael-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Sun Valley Elementary School",
                "tagline": [
                    "San Rafael City Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sun-valley-elementary-school-san-rafael-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 501
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great school my daughter loves it the teachers are amazing and super hands on with a lot of patience.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-09-28T15:23:47.11376Z",
                "guid": "02774c67-92f6-4732-9263-56cd8ee58a38",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.551,37.9852,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.551,37.9852,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94901"
            }
        ]
    },
    {
        "guid": "fceecfca-d5c8-4146-a553-f3b32965e70a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 310,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.857342,
                "lon": -122.26254
            },
            "entity": {
                "abbreviation": "LeConte Elementary School",
                "alternates": {
                    "nces": "060474000445"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "fceecfca-d5c8-4146-a553-f3b32965e70a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Leconte Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "86697417-5daa-4db2-b58d-8dc8ab427d15",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "b9dd7946-c2a7-4ae6-8e94-d5fea0a9aa3d"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "LeConte Elementary School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "leconte-elementary-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 398
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.262,37.8578,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.262,37.8578,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94705"
            }
        ]
    },
    {
        "guid": "92083bb6-4579-411b-ac95-0aed9686755a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 311,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.801886,
                "lon": -122.416275
            },
            "entity": {
                "abbreviation": "Yick Wo Elementary School",
                "alternates": {
                    "nces": "063441008967"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "92083bb6-4579-411b-ac95-0aed9686755a",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Yick Wo Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "963a82de-5229-4167-b80d-0b231bb94570",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a9ba46dd-c464-469c-b61f-0bb664188fda"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Yick Wo Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "yick-wo-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 239
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "This is our third year at Yick Wo.  Our son is finishing 2nd grade and our daughter is finishing kindergarten.  The experience has been overwhelmingly positive. The teaching, the community and administration are excellent.  There are great before and after care options.   They have lots of fun enrichment programs such as art, gardening, and music.  It’s a small school mostly made up of children from the neighborhood and surrounding area so feels like a neighborhood school. The parent community is awesome too! It’s really easy to get involved in whatever way you can. We really like the school and feel like our children are being set up for a great future.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-04-19T04:48:05.979871Z",
                "guid": "79f5efec-1904-4295-b6b1-2fbede69e636",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.417,37.8019,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.417,37.8019,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94133"
            }
        ]
    },
    {
        "guid": "7c963813-2bf9-4f46-9a7b-55f01bb2c6b1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 312,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.6900763,
                "lon": -121.7706111
            },
            "entity": {
                "abbreviation": "Lawrence Elementary",
                "alternates": {
                    "nces": "062211014134"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "7c963813-2bf9-4f46-9a7b-55f01bb2c6b1",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Lawrence Elementary",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lawrence Elementary",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lawrence-elementary-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 357
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.7706111,37.6900763,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.7706111,37.6900763,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94551"
            }
        ]
    },
    {
        "guid": "bc56bf05-0fea-4772-b27b-e27d2668dec2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 313,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.38722,
                "lon": -121.903159
            },
            "entity": {
                "abbreviation": "Orchard Elementary School",
                "alternates": {
                    "nces": "062868004460"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bc56bf05-0fea-4772-b27b-e27d2668dec2",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Orchard Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bc007bff-f267-494c-b4bc-92ce588a6db6",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "782e20d7-90eb-4994-919f-95fc800744f4"
                },
                "premium": false,
                "shortName": "Orchard Elementary School",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "orchard-elementary-school-san-jose-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 843
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "the teachers and staff were really nice and helped my student feel better about the school online. when they moved to in lass sessions it was a good transition.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-05-31T21:30:23.451988Z",
                "guid": "c5108551-149a-4f09-97a7-01f7404dae2d",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.903,37.3875,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.903,37.3875,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95131"
            }
        ]
    },
    {
        "guid": "88dbd9c4-e2a7-4bb6-a913-e61c4bf023b1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 314,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.482126,
                "lon": -122.236002
            },
            "entity": {
                "abbreviation": "North Star Academy",
                "alternates": {
                    "nces": "063213007422"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "88dbd9c4-e2a7-4bb6-a913-e61c4bf023b1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "North Star Academy",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a5dafe95-b00a-4d53-8912-9559cbb65dd9",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "7d773137-7242-4cc9-91c9-d3770c1b9f33"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "North Star Academy",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "3-8"
                ],
                "type": "School",
                "url": "north-star-academy-redwood-city-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 533
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "North Star is a great school! The material being taught isn't perfect, but its better than a lot of other schools, as it offers more of a challenge and sometimes we are being taught in interesting and engaging ways. The school isn't especially diverse, nor are all the teachers very good (most of them are, but some aren't very fair or nice). There is lots of parent involvement, and the principle and most of the staff is very nice. I have a gluten allergy, and the people working at the cafeteria make special (delicious) meals, which is very nice of them. Overall, this school is great!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-04-02T18:37:07.162219Z",
                "guid": "78563a33-92c6-4efe-b861-4da0c327fa07",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.4808,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.235,37.4808,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.888888888888889,
                "count": 9
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94062"
            }
        ]
    },
    {
        "guid": "8a9e6814-6b6d-4ecd-a7a0-f03e799dea12",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 315,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.79825,
                "lon": -122.403028
            },
            "entity": {
                "abbreviation": "John Yehall Chin Elementary",
                "alternates": {
                    "nces": "063441005369"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8a9e6814-6b6d-4ecd-a7a0-f03e799dea12",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "John Yehall Chin Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a6d5c610-2f7d-4aa9-a385-9f3be8d8ba89",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a9ba46dd-c464-469c-b61f-0bb664188fda"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John Yehall Chin Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "john-yehall-chin-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 266
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.403,37.7989,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.403,37.7989,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 1,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94133"
            }
        ]
    },
    {
        "guid": "907b79b9-227a-450e-ab66-45313ce18d1f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 316,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.397249,
                "lon": -121.94383
            },
            "entity": {
                "abbreviation": "Don Callejon",
                "alternates": {
                    "nces": "063543011797"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "907b79b9-227a-450e-ab66-45313ce18d1f",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Don Callejon",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "5e59747f-8fae-4054-a7a1-457bbce26323"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Don Callejon",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "don-callejon-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 912
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "It a  great school. our son is going for 2 years. he loves the school and teachers. its a racially diversified school and we love the way teachers and students interact with each other. Wish it had more land and space so that children could have better play areas and more rooms for classes too. We wish this school upgrades to a high school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-01-26T19:06:35.440258Z",
                "guid": "93b609fc-8e58-4437-a73d-4f5490b18dcd",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.947,37.3986,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.947,37.3986,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95054"
            }
        ]
    },
    {
        "guid": "f5efcd8a-37a5-4cbd-9a84-edac03539329",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 317,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.540397,
                "lon": -121.984127
            },
            "entity": {
                "abbreviation": "Brier Elementary School",
                "alternates": {
                    "nces": "061440001657"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f5efcd8a-37a5-4cbd-9a84-edac03539329",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Brier Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Brier Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "brier-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 717
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.5402,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.986,37.5402,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "41f6f5b6-17f4-4e77-b623-92c8aecffe37",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 318,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.962919,
                "lon": -121.971296
            },
            "entity": {
                "abbreviation": "Silverwood Elementary School",
                "alternates": {
                    "nces": "062637003973"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "41f6f5b6-17f4-4e77-b623-92c8aecffe37",
                "isClaimed": false,
                "isPremium": false,
                "location": "Concord, CA",
                "name": "Silverwood Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "187f89d7-6963-49c2-b924-426631dae88f",
                    "zipCode": "64ec8361-307f-4c60-8518-5e08e7cc3cb0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Silverwood Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "silverwood-elementary-school-concord-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 505
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.9635,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.973,37.9635,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94521"
            }
        ]
    },
    {
        "guid": "d0be56df-9f9b-4ab8-8da9-213f10c1a3fa",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 319,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.32952,
                "lon": -121.855984
            },
            "entity": {
                "abbreviation": "Rocketship Mosaic Elementary School",
                "alternates": {
                    "nces": "060244412645"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "d0be56df-9f9b-4ab8-8da9-213f10c1a3fa",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Rocketship Mosaic Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "schoolNetwork": "159c1d35-78c2-4e2f-8837-1d24cf94af66",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "premium": false,
                "shortName": "Rocketship Mosaic Elementary School",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rocketship-mosaic-elementary-school-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 579
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 32
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.856,37.3296,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.856,37.3296,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "3e1b9680-c7c3-4c38-b09c-55308689ec11",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 320,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.774282,
                "lon": -122.450142
            },
            "entity": {
                "abbreviation": "New Traditions Elementary School",
                "alternates": {
                    "nces": "063441005652"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3e1b9680-c7c3-4c38-b09c-55308689ec11",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "New Traditions Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "4c744a5c-4dd2-4d84-87e3-d72ba57df81f",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "7db16a60-8255-4b6d-ac82-4ba69c978bcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "New Traditions Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "new-traditions-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 246
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.45,37.7742,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.45,37.7742,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94117"
            }
        ]
    },
    {
        "guid": "754fa4e7-6ae5-499b-8220-a0b2da9dabd4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 321,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.264526,
                "lon": -121.894573
            },
            "entity": {
                "abbreviation": "Reed Elementary School",
                "alternates": {
                    "nces": "063459005734"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "754fa4e7-6ae5-499b-8220-a0b2da9dabd4",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Reed Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "cfe32ecb-646c-4ac4-8146-97a3f348c00e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Reed Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "reed-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 445
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.894,37.2632,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.894,37.2632,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95118"
            }
        ]
    },
    {
        "guid": "54a7d109-65ae-4c0e-aacf-5d9986965e89",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 322,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.98554,
                "lon": -122.477059
            },
            "entity": {
                "abbreviation": "Glenwood Elementary School",
                "alternates": {
                    "nces": "063509005935"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "54a7d109-65ae-4c0e-aacf-5d9986965e89",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Rafael, CA",
                "name": "Glenwood Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bf9afc9f-add7-4181-85e3-c735429b9ae0",
                    "parent": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                    "schoolDistrict": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                    "zipCode": "218cd2f0-f326-4a8e-a957-02117d51d241"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Rafael City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                        "location": "San Rafael, CA",
                        "name": "San Rafael City Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                            "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                        },
                        "premium": false,
                        "shortName": "San Rafael City Elementary School District",
                        "tagline": [
                            "School District",
                            "San Rafael, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-rafael-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Glenwood Elementary School",
                "tagline": [
                    "San Rafael City Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "glenwood-elementary-school-san-rafael-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 383
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Glenwood has a fabulous staff! They have a fun, yet rigorous academic program and an inclusive community!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-06-26T06:48:50.811986Z",
                "guid": "a8665fb6-79fe-4ca6-b0bc-47b37d9a75a1",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.478,37.9833,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.478,37.9833,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94901"
            }
        ]
    },
    {
        "guid": "7db8deb3-20ab-4db0-9c77-9f93b3da36e9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 323,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.464128,
                "lon": -122.434262
            },
            "entity": {
                "abbreviation": "Kings Mountain Elementary School",
                "alternates": {
                    "nces": "060678000600"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7db8deb3-20ab-4db0-9c77-9f93b3da36e9",
                "isClaimed": false,
                "isPremium": false,
                "location": "Woodside, CA",
                "name": "Kings Mountain Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                    "schoolDistrict": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6f401739-fe70-4434-8602-b2cdb57c2074",
                    "zipCode": "cfe7994d-8a48-4529-b312-78d0deacfe27"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cabrillo Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                        "location": "Half Moon Bay, CA",
                        "name": "Cabrillo Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6f401739-fe70-4434-8602-b2cdb57c2074",
                            "zipCode": "cfe7994d-8a48-4529-b312-78d0deacfe27"
                        },
                        "premium": false,
                        "shortName": "Cabrillo Unified School District",
                        "tagline": [
                            "School District",
                            "Half Moon Bay, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cabrillo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Kings Mountain Elementary School",
                "tagline": [
                    "Cabrillo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "kings-mountain-elementary-school-woodside-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 57
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great school.  Small class size.  EXCELLENT teachers.  Great parent support.  1-1 instruction.  Students get teachers for 2 years.  Every student knows every other student.  Great community support.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-10-08T15:18:05.130593Z",
                "guid": "709dde14-aadb-43e9-8943-7f0830f5ec1e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.4624,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.4624,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94062"
            }
        ]
    },
    {
        "guid": "ead96416-dcf0-4d99-8a6a-0aeeb5166510",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 324,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.771692,
                "lon": -122.28372
            },
            "entity": {
                "abbreviation": "William G. Paden Elementary School",
                "alternates": {
                    "nces": "060177000003"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ead96416-dcf0-4d99-8a6a-0aeeb5166510",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "William G. Paden Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "William G. Paden Elementary School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "william-g-paden-elementary-school-alameda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 397
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.285,37.7717,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.285,37.7717,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "0b0aaaa8-ac01-4b7c-8c96-a876e7f6c67b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 325,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.269642,
                "lon": -121.883885
            },
            "entity": {
                "abbreviation": "Hacienda Science/Environmental Magnet",
                "alternates": {
                    "nces": "063459005742"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "0b0aaaa8-ac01-4b7c-8c96-a876e7f6c67b",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Hacienda Science/Environmental Magnet",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "cfe32ecb-646c-4ac4-8146-97a3f348c00e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Hacienda Science/Environmental Magnet",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "hacienda-science-environmental-magnet-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 706
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "My son started kindergarten in school year 2018-2019. Not only does my son like the school but so do I. The school offers certain things that other schools in the area don't . I've had friends ask me if my son attends a private school based on some of the attention details the school offers. The school is public however it does feel private with school logo wear, hardcover year book, great music and science class and more.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-06-07T03:52:38.465041Z",
                "guid": "105ddc53-77fb-4e5b-aa24-a2b4329b2f51",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.883,37.2691,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.883,37.2691,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95118"
            }
        ]
    },
    {
        "guid": "e372fbd0-7aab-477c-ad7c-df83cc3679e7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 326,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.919036,
                "lon": -121.738863
            },
            "entity": {
                "abbreviation": "R. Paul Krey Elementary School",
                "alternates": {
                    "nces": "060591010474"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e372fbd0-7aab-477c-ad7c-df83cc3679e7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "R. Paul Krey Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "R. Paul Krey Elementary School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "r-paul-krey-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 859
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.738,37.92,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.738,37.92,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "f61778db-167f-4654-80b7-9dd7e813b039",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 327,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.291216,
                "lon": -121.977975
            },
            "entity": {
                "abbreviation": "Latimer School",
                "alternates": {
                    "nces": "062577013806"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f61778db-167f-4654-80b7-9dd7e813b039",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Latimer Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "a8938731-bf81-4725-9991-fd2727841f28",
                    "schoolDistrict": "a8938731-bf81-4725-9991-fd2727841f28",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moreland Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a8938731-bf81-4725-9991-fd2727841f28",
                        "location": "San Jose, CA",
                        "name": "Moreland",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                        },
                        "premium": false,
                        "shortName": "Moreland Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moreland-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Latimer School",
                "tagline": [
                    "Moreland, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "latimer-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 554
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.978,37.2924,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.978,37.2924,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95130"
            }
        ]
    },
    {
        "guid": "ad0b093f-f66e-41d1-b7af-9482e7211b7b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 328,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.23109,
                "lon": -121.807994
            },
            "entity": {
                "abbreviation": "Taylor (Bertha) Elementary School",
                "alternates": {
                    "nces": "062781004224"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ad0b093f-f66e-41d1-b7af-9482e7211b7b",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Bertha Taylor Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Taylor (Bertha) Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "bertha-taylor-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 683
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.808,37.2302,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.808,37.2302,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95123"
            }
        ]
    },
    {
        "guid": "840b8871-cc54-4006-bc60-a76e5fe323eb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 329,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.320847,
                "lon": -121.770056
            },
            "entity": {
                "abbreviation": "Carolyn A. Clark Elementary School",
                "alternates": {
                    "nces": "061314011451"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "840b8871-cc54-4006-bc60-a76e5fe323eb",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Carolyn A. Clark Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Carolyn A. Clark Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "carolyn-a-clark-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 581
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great programs, knowledgable teachers, and a welcoming staff. There is an emphasis on deeper learning and the arts. Many after school opportunities and lots of Project Based Learning going on in classrooms.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-04-13T22:34:51.778795Z",
                "guid": "7f777320-df3a-485f-966d-19707ddc32c3",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.769,37.3217,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.769,37.3217,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95148"
            }
        ]
    },
    {
        "guid": "73c86be0-15fa-429d-a72d-91963c2ada77",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 330,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.435867,
                "lon": -122.204352
            },
            "entity": {
                "abbreviation": "Monte Verde Elementary School",
                "alternates": {
                    "nces": "063753006347"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "73c86be0-15fa-429d-a72d-91963c2ada77",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Bruno, CA",
                "name": "Monte Verde Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "schoolDistrict": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "b1ffcba5-34ff-41c4-8dee-13d6f1b49d01",
                    "zipCode": "190d1411-e5a3-4f6d-a604-70b9e1fe71a3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "South San Francisco Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "41a0969a-227f-49e4-989c-cce558a6963c",
                        "location": "South San Francisco, CA",
                        "name": "South San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                            "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                        },
                        "premium": false,
                        "shortName": "South San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "South San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "south-san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Monte Verde Elementary School",
                "tagline": [
                    "South San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "monte-verde-elementary-school-san-bruno-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 530
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.452,37.6366,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.452,37.6366,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94066"
            }
        ]
    },
    {
        "guid": "c76f14d7-28f3-41b9-b9da-f440495a9f96",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 331,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.942491,
                "lon": -121.940324
            },
            "entity": {
                "abbreviation": "Mt. Diablo Elementary School",
                "alternates": {
                    "nces": "062637003959"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c76f14d7-28f3-41b9-b9da-f440495a9f96",
                "isClaimed": false,
                "isPremium": false,
                "location": "Clayton, CA",
                "name": "Mt. Diablo Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "f0b6dd7a-adbc-460d-a370-82088cf801be",
                    "zipCode": "e1047bb3-fb68-4a8e-b4c7-769c73094f23"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Mt. Diablo Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "mt-diablo-elementary-school-clayton-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 798
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.939,37.9418,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.939,37.9418,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94517"
            }
        ]
    },
    {
        "guid": "02b61130-167a-4583-be09-d378d8ac21ee",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 332,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.36341,
                "lon": -122.017956
            },
            "entity": {
                "abbreviation": "Braly Elementary School",
                "alternates": {
                    "nces": "063543001588"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "02b61130-167a-4583-be09-d378d8ac21ee",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Braly Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Braly Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "braly-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 391
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.018,37.3642,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.018,37.3642,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94086"
            }
        ]
    },
    {
        "guid": "d27124ec-235b-4532-a18f-e81bcc67dbc2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 333,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.917623,
                "lon": -122.019473
            },
            "entity": {
                "abbreviation": "Walnut Acres Elementary School",
                "alternates": {
                    "nces": "062637003980"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d27124ec-235b-4532-a18f-e81bcc67dbc2",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Walnut Acres Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Walnut Acres Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "walnut-acres-elementary-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 634
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.02,37.9186,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.02,37.9186,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94598"
            }
        ]
    },
    {
        "guid": "5c4dbaf5-722e-4942-b619-a7a470c9a1ba",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 334,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.651605,
                "lon": -122.489855
            },
            "entity": {
                "abbreviation": "Ocean Shore Elementary School",
                "alternates": {
                    "nces": "062046002469"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5c4dbaf5-722e-4942-b619-a7a470c9a1ba",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pacifica, CA",
                "name": "Ocean Shore Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "schoolDistrict": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                    "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Pacifica School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                        "location": "Pacifica, CA",
                        "name": "Pacifica School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                            "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                        },
                        "premium": false,
                        "shortName": "Pacifica School District",
                        "tagline": [
                            "School District",
                            "Pacifica, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pacifica-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ocean Shore Elementary School",
                "tagline": [
                    "Pacifica School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "ocean-shore-elementary-school-pacifica-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 432
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.49,37.6509,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.49,37.6509,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94044"
            }
        ]
    },
    {
        "guid": "38b32565-f34a-4b49-9bc3-43a942388795",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 335,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.7436,
                "lon": -122.464667
            },
            "entity": {
                "abbreviation": "West Portal Elementary School",
                "alternates": {
                    "nces": "063441005679"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "38b32565-f34a-4b49-9bc3-43a942388795",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "West Portal Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "4eef47cc-3daa-4e48-b9da-f446e9e81af3",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "50a82c65-04cd-4b00-bb26-c5f015b369e3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "West Portal Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "west-portal-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 590
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 30
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.464,37.7429,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.464,37.7429,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94127"
            }
        ]
    },
    {
        "guid": "93089bc9-1781-493f-ac71-fbf472e8ea70",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 336,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.408279,
                "lon": -122.09796
            },
            "entity": {
                "abbreviation": "Monta Loma Elementary School",
                "alternates": {
                    "nces": "062628006932"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "93089bc9-1781-493f-ac71-fbf472e8ea70",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Monta Loma Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "schoolDistrict": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mountain View Whisman School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "55669afd-2c14-4350-8f57-42ebb04aa320",
                        "location": "Mountain View, CA",
                        "name": "Mountain View Whisman School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                            "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                        },
                        "premium": false,
                        "shortName": "Mountain View Whisman School District",
                        "tagline": [
                            "School District",
                            "Mountain View, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mountain-view-whisman-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Monta Loma Elementary School",
                "tagline": [
                    "Mountain View Whisman School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "monta-loma-elementary-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 425
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.099,37.4091,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.099,37.4091,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94043"
            }
        ]
    },
    {
        "guid": "cf24d4bd-6d5e-44ca-82b9-f223063152f2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 337,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.222609,
                "lon": -121.83913
            },
            "entity": {
                "abbreviation": "Ledesma (Rita) Elementary School",
                "alternates": {
                    "nces": "062781008358"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "cf24d4bd-6d5e-44ca-82b9-f223063152f2",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Rita Ledesma Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ledesma (Rita) Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "rita-ledesma-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 494
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.761,37.2428,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.761,37.2428,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95138"
            }
        ]
    },
    {
        "guid": "f9fe4b75-94f0-4d47-b404-75d5358ed4d9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 338,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.758133,
                "lon": -122.502475
            },
            "entity": {
                "abbreviation": "Francis Scott Key Elementary",
                "alternates": {
                    "nces": "063441005609"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f9fe4b75-94f0-4d47-b404-75d5358ed4d9",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Francis Scott Key Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "47575dee-023f-4a2f-8c52-61e4471bda57",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "b4e89c98-14e0-415a-81a3-67446f01feef"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Francis Scott Key Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "francis-scott-key-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 551
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.502,37.7582,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.502,37.7582,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94122"
            }
        ]
    },
    {
        "guid": "6db0dc18-7b82-42dc-8376-70b0b2683855",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 339,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.410525,
                "lon": -121.857148
            },
            "entity": {
                "abbreviation": "Majestic Way Elementary School",
                "alternates": {
                    "nces": "060480000460"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6db0dc18-7b82-42dc-8376-70b0b2683855",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Majestic Way Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Majestic Way Elementary School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "majestic-way-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 518
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.858,37.4103,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.858,37.4103,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "44dbe5fc-68cb-45ee-874b-9d3384bf5b55",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 340,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.857244,
                "lon": -122.246074
            },
            "entity": {
                "abbreviation": "John Muir Elementary School",
                "alternates": {
                    "nces": "060474008119"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "44dbe5fc-68cb-45ee-874b-9d3384bf5b55",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "John Muir Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8882898c-a030-44a2-9ef4-acc3141fb6f0",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "b9dd7946-c2a7-4ae6-8e94-d5fea0a9aa3d"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John Muir Elementary School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "john-muir-elementary-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 305
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.246,37.8572,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.246,37.8572,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94705"
            }
        ]
    },
    {
        "guid": "ab144cb0-5ace-417f-b533-94ac2d6bc094",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 341,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.381247,
                "lon": -121.872791
            },
            "entity": {
                "abbreviation": "Vinci Park Elementary School",
                "alternates": {
                    "nces": "060480000469"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ab144cb0-5ace-417f-b533-94ac2d6bc094",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Vinci Park Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bc007bff-f267-494c-b4bc-92ce588a6db6",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "782e20d7-90eb-4994-919f-95fc800744f4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Vinci Park Elementary School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "vinci-park-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 564
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.873,37.38,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.873,37.38,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95131"
            }
        ]
    },
    {
        "guid": "3f53c762-2aa6-48de-b007-2f8685b6306e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 342,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.457484,
                "lon": -121.905594
            },
            "entity": {
                "abbreviation": "Joseph Weller Elementary School",
                "alternates": {
                    "nces": "062450003676"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3f53c762-2aa6-48de-b007-2f8685b6306e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "Joseph Weller Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Joseph Weller Elementary School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "joseph-weller-elementary-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 454
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.906,37.4585,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.906,37.4585,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "28228332-2691-49a6-b7cb-e6f416a32ab6",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 343,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.685225,
                "lon": -121.742559
            },
            "entity": {
                "abbreviation": "Jackson Avenue Elementary School",
                "alternates": {
                    "nces": "062211002627"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "28228332-2691-49a6-b7cb-e6f416a32ab6",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Jackson Avenue Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "0a020eaf-ab20-4a50-8308-d60ca74bd07f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Jackson Avenue Elementary School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "jackson-avenue-elementary-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 526
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.741,37.685,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.741,37.685,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94550"
            }
        ]
    },
    {
        "guid": "6cbf48c6-beef-424c-ab9f-902c1b06d1ce",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 344,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.934129,
                "lon": -122.015055
            },
            "entity": {
                "abbreviation": "Valle Verde Elementary School",
                "alternates": {
                    "nces": "062637003978"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6cbf48c6-beef-424c-ab9f-902c1b06d1ce",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Valle Verde Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Valle Verde Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "valle-verde-elementary-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 472
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.014,37.9331,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.014,37.9331,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94598"
            }
        ]
    },
    {
        "guid": "e23118ee-0d69-45c5-aa08-b3355a060600",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 345,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.801637,
                "lon": -122.406655
            },
            "entity": {
                "abbreviation": "Garfield Elementary School",
                "alternates": {
                    "nces": "063441005613"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e23118ee-0d69-45c5-aa08-b3355a060600",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Garfield Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "be407e1a-54e9-47fa-b861-c5eec1ff3838",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a9ba46dd-c464-469c-b61f-0bb664188fda"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Garfield Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "garfield-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 228
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "This is our kids second year at Garfield Elementary.  What a gem of a school.  We commute from the other side of the city just to be in the school and in its Cantonese immersion program.  Our kids fit in well and as did we as parents. We felt welcome and supported from day 1 in this school by the loving teachers, staff, kids and other parents in the community.   I love that our community is diverse and supportive.  I also love the school’s focus on the whole person in academic, social and emotional development.   I also really admire the teachers’ enthusiasm and creativity in getting the kids to engage and explore in writers and readers workshop and publishing books.  All these experiences have really contributed to how well my kids have adapted to being first graders now.  All in all, as first time parents sending kids to kinder, I feel that I could fully trust the teachers and the staff with my kids.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-10T07:10:11.620884Z",
                "guid": "24272ee8-da8c-4abe-9e4d-2934e843bb4d",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.407,37.8022,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.407,37.8022,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94133"
            }
        ]
    },
    {
        "guid": "1f89ba0f-7e97-4535-942d-19337272ee21",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 346,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.754174,
                "lon": -122.444146
            },
            "entity": {
                "abbreviation": "Rooftop Elementary School",
                "alternates": {
                    "nces": "063441005661"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1f89ba0f-7e97-4535-942d-19337272ee21",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Rooftop Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bffbbdb0-b26c-4342-b1b0-6ff6fe27c76d",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "ee205ed4-1f3f-448a-8f17-065ffce55d35"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Rooftop Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "rooftop-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 568
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Rooftop offers a smaller educational environment that's safe and nurturing to students’ academic and emotional needs. I like that the school’s emphasis on the inclusion of art to teach various subject matters. My child has attended this school since kindergarten and now  is in the eighth grade.  Rooftop has provided  several, various learning opportunities which have positively contributed to my child’s growth/development. The administration has always been helpful and receptive to our family.  We’ve always viewed the school as a PARTNERSHIP in the endeavor of educating a child. The teachers have well prepared my child for high school. Rooftop is a beautiful and an excellent PUBLIC school in San Francisco.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-16T21:22:50.616968Z",
                "guid": "58f8cb42-bdd8-4f5b-9390-1fd9510e72b1",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.444,37.7549,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.444,37.7549,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 4
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94131"
            }
        ]
    },
    {
        "guid": "c209c4d7-c476-4155-8e36-5302d07db1e8",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 347,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.739956,
                "lon": -122.481862
            },
            "entity": {
                "abbreviation": "Dianne Feinstein Elementary",
                "alternates": {
                    "nces": "063441011772"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c209c4d7-c476-4155-8e36-5302d07db1e8",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Dianne Feinstein Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "45ae15e7-7755-498f-b7ee-f6f7ee80674c",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "dfff3cae-3a03-46a7-b060-62db0e2af199"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Dianne Feinstein Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "dianne-feinstein-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 502
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.482,37.7397,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.482,37.7397,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94116"
            }
        ]
    },
    {
        "guid": "33e22ed4-f702-4113-9bcf-598e96bf0300",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 348,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.061715,
                "lon": -122.171483
            },
            "entity": {
                "abbreviation": "Mary Farmar Elementary School",
                "alternates": {
                    "nces": "060462000424"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "33e22ed4-f702-4113-9bcf-598e96bf0300",
                "isClaimed": true,
                "isPremium": false,
                "location": "Benicia, CA",
                "name": "Mary Farmar Elementary School",
                "parentGUIDs": {
                    "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "schoolDistrict": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                    "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Benicia Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7c21191c-eac4-441c-86d9-aee0b22405db",
                        "location": "Benicia, CA",
                        "name": "Benicia Unified School District",
                        "parentGUIDs": {
                            "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                            "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                        },
                        "premium": false,
                        "shortName": "Benicia Unified School District",
                        "tagline": [
                            "School District",
                            "Benicia, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "benicia-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Mary Farmar Elementary School",
                "tagline": [
                    "Benicia Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "mary-farmar-elementary-school-benicia-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 443
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.171,38.0625,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.171,38.0625,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94510"
            }
        ]
    },
    {
        "guid": "2f19009d-389f-4d05-9828-d77ac5ab5a88",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 349,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.863422,
                "lon": -122.500895
            },
            "entity": {
                "abbreviation": "Willow Creek Academy",
                "alternates": {
                    "nces": "060160808732"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "2f19009d-389f-4d05-9828-d77ac5ab5a88",
                "isClaimed": false,
                "isPremium": false,
                "location": "Sausalito, CA",
                "name": "Willow Creek Academy",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e6ebb77a-f04a-4ff1-9349-1d65c07852a2",
                    "zipCode": "98b8b9f2-6679-4526-81fb-59677c56d1b9"
                },
                "premium": false,
                "shortName": "Willow Creek Academy",
                "tagline": [
                    "Public School",
                    "Sausalito, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "willow-creek-academy-sausalito-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 409
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 31
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "There are so many reasons why our family feels remarkably lucky to be at WCA. We were most drawn to the mix of income levels, racial and cultural backgrounds and languages, over any other school in Marin. The beautiful campus overlooks the Bay and has 4 gardens. WCA’s greatest strength is undoubtedly the teachers - a passionate, fun-spirited group who use hands-on, project-based learning methods and are innovative and creative in their approaches. We have found the administration to be personally responsive and accessible and encouraging of parent engagement. It is a joy to see kids playing ukelele, xylophone and percussion in our dynamic music program, as well as the murals, sculptures and paintings from our vibrant art classes. And did I mention the freshly cooked school lunches, with all organic food? It's SO many things!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-11-30T19:49:46.284798Z",
                "guid": "5123fed3-479a-498b-9c5d-45ad3fb61813",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.503,37.8632,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.503,37.8632,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94965"
            }
        ]
    },
    {
        "guid": "c9ef8304-e5b9-4f9d-8bef-4652c442402c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 350,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.396095,
                "lon": -121.991229
            },
            "entity": {
                "abbreviation": "Fairwood Elementary School",
                "alternates": {
                    "nces": "063846007433"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "c9ef8304-e5b9-4f9d-8bef-4652c442402c",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Fairwood Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "schoolDistrict": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "dfa40734-09f2-436c-874c-ac28a0c26bdd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Sunnyvale School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                        "location": "Sunnyvale, CA",
                        "name": "Sunnyvale School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                        },
                        "premium": false,
                        "shortName": "Sunnyvale School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "sunnyvale-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Fairwood Elementary School",
                "tagline": [
                    "Sunnyvale School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "fairwood-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 435
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.99,37.3963,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.99,37.3963,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94089"
            }
        ]
    },
    {
        "guid": "921ff790-74c8-4626-be5c-b86f674231c7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 351,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.730294,
                "lon": -122.448782
            },
            "entity": {
                "abbreviation": "Sunnyside Elementary School",
                "alternates": {
                    "nces": "063441005672"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "921ff790-74c8-4626-be5c-b86f674231c7",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Sunnyside Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "99e99ecc-958d-41c7-9f98-d78249b29a98",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "61413703-2cce-42b9-a718-b28b79a1680f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sunnyside Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sunnyside-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 383
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "I have had two children at this school, and can state that this elementary has gone from strength to strength. Teachers are all excellent, and there is very active PTA who fundraise to provide additional academic support, art programs etc. Less diverse than it used to be, but still good for a public elementary school in SF.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-04-23T02:59:25.63773Z",
                "guid": "111b9ffc-48ee-468e-ad04-d896b09e7eb5",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.449,37.7303,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.449,37.7303,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94112"
            }
        ]
    },
    {
        "guid": "e68ce846-3d4a-4f10-8867-7f43a0293ec2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 352,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.059066,
                "lon": -122.146524
            },
            "entity": {
                "abbreviation": "Robert Semple Elementary School",
                "alternates": {
                    "nces": "060462000427"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "e68ce846-3d4a-4f10-8867-7f43a0293ec2",
                "isClaimed": true,
                "isPremium": false,
                "location": "Benicia, CA",
                "name": "Robert Semple Elementary School",
                "parentGUIDs": {
                    "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "schoolDistrict": "7c21191c-eac4-441c-86d9-aee0b22405db",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                    "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Benicia Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7c21191c-eac4-441c-86d9-aee0b22405db",
                        "location": "Benicia, CA",
                        "name": "Benicia Unified School District",
                        "parentGUIDs": {
                            "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "1f8c93ce-b789-4186-992a-b1f87cc64d9f",
                            "zipCode": "6e4e6d26-8c6c-4784-a6d0-339cc1370fcb"
                        },
                        "premium": false,
                        "shortName": "Benicia Unified School District",
                        "tagline": [
                            "School District",
                            "Benicia, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "benicia-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Robert Semple Elementary School",
                "tagline": [
                    "Benicia Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "robert-semple-elementary-school-benicia-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 472
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great staff, great admin , very diverse and all are accepted here!! The parent group maybe small but works very hard to plan and sponsor many fun family events. Teachers work many extra hours after their duty day to make sure everything is ready for their students. The place may need some paint - but the heart of the school is the people that work there to help each and every student  succeed !",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-08-10T05:48:37.105466Z",
                "guid": "e2cd457b-1d95-4339-9fe9-701602eaa757",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.148,38.059,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.148,38.059,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94510"
            }
        ]
    },
    {
        "guid": "0f11f2aa-d5b3-4564-a3ca-8f02c048e568",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 353,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.395777,
                "lon": -121.838742
            },
            "entity": {
                "abbreviation": "Noble Elementary School",
                "alternates": {
                    "nces": "060480000462"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0f11f2aa-d5b3-4564-a3ca-8f02c048e568",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Noble Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Noble Elementary School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "noble-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 456
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.839,37.3949,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.839,37.3949,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "82152d53-2400-4b2a-9fd2-67a2338835f9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 354,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.767204,
                "lon": -122.436241
            },
            "entity": {
                "abbreviation": "McKinley Elementary School",
                "alternates": {
                    "nces": "063441005647"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "82152d53-2400-4b2a-9fd2-67a2338835f9",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "McKinley Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "1f3e87a1-9663-45d7-9767-bc60670c4389",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a3ca5eac-7152-427f-be6e-9e3727c508ed"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "McKinley Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "mckinley-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 383
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 29
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.437,37.7669,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.437,37.7669,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94114"
            }
        ]
    },
    {
        "guid": "5d050ba9-ac47-4a56-9736-54dc0af97d2b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 355,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.688978,
                "lon": -121.796891
            },
            "entity": {
                "abbreviation": "Rancho Las Positas Elementary School",
                "alternates": {
                    "nces": "062211002632"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "5d050ba9-ac47-4a56-9736-54dc0af97d2b",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Rancho Las Positas Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Rancho Las Positas Elementary School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rancho-las-positas-elementary-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 599
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.797,37.6876,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.797,37.6876,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94551"
            }
        ]
    },
    {
        "guid": "92b2b93e-70b8-49d1-8244-739864912b6a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 356,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.560891,
                "lon": -122.297596
            },
            "entity": {
                "abbreviation": "Parkside Elementary School",
                "alternates": {
                    "nces": "063492005916"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "92b2b93e-70b8-49d1-8244-739864912b6a",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Parkside Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "2ab8d452-5509-4d81-a858-86685b2bc951"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Parkside Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "parkside-elementary-school-san-mateo-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 228
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.297,37.5616,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.297,37.5616,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94402"
            }
        ]
    },
    {
        "guid": "1944cb21-b9ec-44e4-91df-ce5149fd1e01",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 357,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.441659,
                "lon": -121.882162
            },
            "entity": {
                "abbreviation": "William Burnett Elementary School",
                "alternates": {
                    "nces": "062450003663"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1944cb21-b9ec-44e4-91df-ce5149fd1e01",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "William Burnett Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "William Burnett Elementary School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "william-burnett-elementary-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 539
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.881,37.442,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.881,37.442,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "e4028f65-b8fb-4f71-bbcf-b59f011e55a5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 358,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.753331,
                "lon": -122.399406
            },
            "entity": {
                "abbreviation": "Starr King Elementary",
                "alternates": {
                    "nces": "063441005671"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e4028f65-b8fb-4f71-bbcf-b59f011e55a5",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Starr King Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0e7feade-1234-4a78-b9a6-f0a08b901e7c",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "781604ff-cae4-4ff7-8bc5-30889e25ac1f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Starr King Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "starr-king-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 331
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "The top of Potrero Hill is a nice sunny spot to go to elementary school. We have talented administrators and teachers, generous and involved families. Our PTA just raised $285,000 for next year's budget (enrollment is roughly 340 kids). The Mandarin program is solid and Starr King is a feeder for one of the district's better middle schools, Aptos MS. There's even a school bus that picks kids up from Starr King to go to Aptos and also brings them back to Potrero, which makes it very convenient for those with siblings to still do one drop off.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-03-22T03:29:20.785267Z",
                "guid": "05b0f402-b3d2-49d0-9c29-5cf67ac3086f",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.399,37.7531,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.399,37.7531,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.8333333333333335,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94107"
            }
        ]
    },
    {
        "guid": "55458918-f47d-4a92-8b7f-7d3de62a9cda",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 359,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.933554,
                "lon": -122.081617
            },
            "entity": {
                "abbreviation": "Pleasant Hill Elementary School",
                "alternates": {
                    "nces": "062637003967"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "55458918-f47d-4a92-8b7f-7d3de62a9cda",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Pleasant Hill Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "454e8dd6-f26e-4493-a281-1f2250e8bae9",
                    "zipCode": "c02f3379-b7e4-421d-8daf-40132890b3fd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Pleasant Hill Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "pleasant-hill-elementary-school-pleasant-hill-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 618
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.083,37.9328,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.083,37.9328,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94523"
            }
        ]
    },
    {
        "guid": "95d819a9-7ecb-4b25-90f2-def2c6a461d2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 360,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.235807,
                "lon": -121.796172
            },
            "entity": {
                "abbreviation": "Santa Teresa Elementary School",
                "alternates": {
                    "nces": "062781004223"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "95d819a9-7ecb-4b25-90f2-def2c6a461d2",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Santa Teresa Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Santa Teresa Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "santa-teresa-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 623
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "My daughter goes to this school. \nShe is so happy with friends and teachers. \nAlways hearing that how wonderful teachers and friends she has, it makes me so delight daily.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-05-09T17:56:33.974076Z",
                "guid": "00f53b11-626c-4148-8888-f4ccbacb755f",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.795,37.2347,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.795,37.2347,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95119"
            }
        ]
    },
    {
        "guid": "1d6c3c2b-ffe2-4f8c-a0d5-d1e5f20063cb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 361,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.266537,
                "lon": -121.964076
            },
            "entity": {
                "abbreviation": "Capri Elementary School",
                "alternates": {
                    "nces": "060720000663"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "1d6c3c2b-ffe2-4f8c-a0d5-d1e5f20063cb",
                "isClaimed": false,
                "isPremium": false,
                "location": "Campbell, CA",
                "name": "Capri Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                    "parent": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "schoolDistrict": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                    "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Campbell Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                        "location": "Campbell, CA",
                        "name": "Campbell Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                            "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                        },
                        "premium": false,
                        "shortName": "Campbell Union School District",
                        "tagline": [
                            "School District",
                            "Campbell, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "campbell-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Capri Elementary School",
                "tagline": [
                    "Campbell Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "capri-elementary-school-campbell-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 589
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.965,37.2653,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.965,37.2653,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95008"
            }
        ]
    },
    {
        "guid": "f52dc221-9a8e-4df1-ab07-355667fa453b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 362,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.600971,
                "lon": -122.049827
            },
            "entity": {
                "abbreviation": "Tom Kitayama Elementary School",
                "alternates": {
                    "nces": "062691007480"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "f52dc221-9a8e-4df1-ab07-355667fa453b",
                "isClaimed": true,
                "isPremium": false,
                "location": "Union City, CA",
                "name": "Tom Kitayama Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "schoolDistrict": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                    "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "New Haven Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "305b3896-e539-4976-95d2-a90396c3f1cf",
                        "location": "Union City, CA",
                        "name": "New Haven Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                            "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                        },
                        "premium": false,
                        "shortName": "New Haven Unified School District",
                        "tagline": [
                            "School District",
                            "Union City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "new-haven-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Tom Kitayama Elementary School",
                "tagline": [
                    "New Haven Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "tom-kitayama-elementary-school-union-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 776
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.051,37.5984,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.051,37.5984,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94587"
            }
        ]
    },
    {
        "guid": "fed85cb8-b899-45f6-8cf2-15b911f4ea7c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 363,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.595808,
                "lon": -122.077075
            },
            "entity": {
                "abbreviation": "Alvarado Elementary School",
                "alternates": {
                    "nces": "062691004065"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "fed85cb8-b899-45f6-8cf2-15b911f4ea7c",
                "isClaimed": true,
                "isPremium": false,
                "location": "Union City, CA",
                "name": "Alvarado Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "schoolDistrict": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                    "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "New Haven Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "305b3896-e539-4976-95d2-a90396c3f1cf",
                        "location": "Union City, CA",
                        "name": "New Haven Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                            "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                        },
                        "premium": false,
                        "shortName": "New Haven Unified School District",
                        "tagline": [
                            "School District",
                            "Union City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "new-haven-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alvarado Elementary School",
                "tagline": [
                    "New Haven Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "alvarado-elementary-school-union-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 726
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.075,37.5943,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.075,37.5943,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94587"
            }
        ]
    },
    {
        "guid": "ccd550f0-fd13-4b6d-a254-e98773aa7500",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 364,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.988357,
                "lon": -122.00499
            },
            "entity": {
                "abbreviation": "Monte Gardens Elementary School",
                "alternates": {
                    "nces": "062637003957"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "ccd550f0-fd13-4b6d-a254-e98773aa7500",
                "isClaimed": false,
                "isPremium": false,
                "location": "Concord, CA",
                "name": "Monte Gardens Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "187f89d7-6963-49c2-b924-426631dae88f",
                    "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Monte Gardens Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "monte-gardens-elementary-school-concord-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 518
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.005,37.9872,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.005,37.9872,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94519"
            }
        ]
    },
    {
        "guid": "90146217-f53b-47bc-98cf-a94588da1efe",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 365,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.537846,
                "lon": -122.290981
            },
            "entity": {
                "abbreviation": "George Hall Elementary School",
                "alternates": {
                    "nces": "063492005906"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "90146217-f53b-47bc-98cf-a94588da1efe",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "George Hall Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "2ab8d452-5509-4d81-a858-86685b2bc951"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "George Hall Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "george-hall-elementary-school-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 432
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "George Hall is one of the cleanest schools I’ve been to! The parents, teachers and staff are all very friendly and ready to help! Everyone one of the teachers I’ve had the pleasure to interact with have left me with a positive attitude and give me comfort to know my child stays there for most of his day if not all! Fantastic school all around. The Art in Action program is fantastic!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-03-16T11:46:04.201908Z",
                "guid": "93d06dde-42df-4bc5-a475-a52282cbb5de",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.291,37.5372,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.291,37.5372,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.666666666666667,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94403"
            }
        ]
    },
    {
        "guid": "b039d73d-821f-4bcb-974e-e4e3f478116c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 366,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.458568,
                "lon": -122.256245
            },
            "entity": {
                "abbreviation": "Roy Cloud Elementary School",
                "alternates": {
                    "nces": "063213004963"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b039d73d-821f-4bcb-974e-e4e3f478116c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "Roy Cloud Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "f16f48a5-122d-4be7-8d77-b5fe1a5cbce3",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "711f94d7-a522-4b45-a751-5a884e5670a6"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Roy Cloud Elementary School",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "roy-cloud-elementary-school-redwood-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 751
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Roy Cloud is by far the best school for miles for education. The teachers are odd, and they could use improvement in disability care, but the quality of education, at least in my opinion far out weighed it's flaws. I went to Roy Cloud for K-8. I didn't necessarily enjoy my time there, which I think is true for many, though I account that more to the faults of schools themselves. The teachers and staff were very nice. I still go back and talk to them. \nThe brilliance of Roy Cloud is difficult to see until you leave. When I went to Woodside High School, I found that many Cloud kids were leagues above students who had been to other schools simply by being at Roy Cloud. Some of the work advanced classes had to stop and review over for other students had been taught to Cloud kids in 3rd or 4th grade. My AS (advanced standing) English class was made up of more than a third of kids from Roy Cloud. One of the smaller middle school feeders out of 26.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-04-13T09:45:13.018105Z",
                "guid": "d5fb4c8c-d00a-4bf1-9886-f945554d8f8e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.256,37.4597,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.256,37.4597,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94061"
            }
        ]
    },
    {
        "guid": "2a6a23a0-ea9d-48fa-ad93-788d86052037",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 367,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.928139,
                "lon": -122.038388
            },
            "entity": {
                "abbreviation": "Bancroft Elementary School",
                "alternates": {
                    "nces": "062637003935"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2a6a23a0-ea9d-48fa-ad93-788d86052037",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Bancroft Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Bancroft Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "bancroft-elementary-school-walnut-creek-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 645
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.038,37.9294,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.038,37.9294,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94598"
            }
        ]
    },
    {
        "guid": "b7ecda27-874f-4cf2-a13d-1da6298f1a29",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 368,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.40934,
                "lon": -121.881525
            },
            "entity": {
                "abbreviation": "Northwood Elementary School",
                "alternates": {
                    "nces": "060480000463"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b7ecda27-874f-4cf2-a13d-1da6298f1a29",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Northwood Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bc007bff-f267-494c-b4bc-92ce588a6db6",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Northwood Elementary School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "northwood-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 574
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.881,37.4088,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.881,37.4088,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "24d4c36a-fb18-4a49-867c-6c36b22ac618",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 369,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.997405,
                "lon": -122.594411
            },
            "entity": {
                "abbreviation": "Manor Elementary School",
                "alternates": {
                    "nces": "060000608773"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "24d4c36a-fb18-4a49-867c-6c36b22ac618",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fairfax, CA",
                "name": "Manor Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "schoolDistrict": "e787e270-2623-42bc-917f-95d6d7ff2026",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "d8aac2d4-6266-4e7a-a585-8758fbf27804",
                    "zipCode": "98575946-123b-48d5-8cc4-beebd45ad068"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Ross Valley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e787e270-2623-42bc-917f-95d6d7ff2026",
                        "location": "San Anselmo, CA",
                        "name": "Ross Valley Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "0db5806d-7d26-41a5-8300-2d00fc0dfd2c",
                            "zipCode": "0455b370-2ddd-4cd1-ae84-32c046dba7fb"
                        },
                        "premium": false,
                        "shortName": "Ross Valley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Anselmo, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "ross-valley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Manor Elementary School",
                "tagline": [
                    "Ross Valley Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "manor-elementary-school-fairfax-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 275
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.594,37.9967,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.594,37.9967,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94930"
            }
        ]
    },
    {
        "guid": "f8d4d9d6-2abf-4ee9-8061-13ac66ecd0b9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 370,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.932583,
                "lon": -121.719582
            },
            "entity": {
                "abbreviation": "Ron Nunn Elementary School",
                "alternates": {
                    "nces": "060591010194"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f8d4d9d6-2abf-4ee9-8061-13ac66ecd0b9",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Ron Nunn Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ron Nunn Elementary School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ron-nunn-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 650
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.73,37.9304,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.73,37.9304,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "9fb4edd6-2f38-48a7-bba4-4949c9cc6b9c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 371,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.753837,
                "lon": -122.438675
            },
            "entity": {
                "abbreviation": "Alvarado Elementary School",
                "alternates": {
                    "nces": "063441005583"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9fb4edd6-2f38-48a7-bba4-4949c9cc6b9c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Alvarado Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5aeb771b-e08e-4386-92eb-c56905c5912c",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a3ca5eac-7152-427f-be6e-9e3727c508ed"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alvarado Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "alvarado-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 515
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 29
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.439,37.7537,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.439,37.7537,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94114"
            }
        ]
    },
    {
        "guid": "f15b4449-66fb-4c6e-af61-e1d801e4d8a5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 372,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.260632,
                "lon": -121.911149
            },
            "entity": {
                "abbreviation": "Sartorette Charter School",
                "alternates": {
                    "nces": "060714000655"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "f15b4449-66fb-4c6e-af61-e1d801e4d8a5",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Sartorette Charter School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "schoolDistrict": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cambrian School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "aec99b83-c70f-442f-b30c-4c79582989d1",
                        "location": "San Jose, CA",
                        "name": "Cambrian School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Cambrian School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cambrian-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Sartorette Charter School",
                "tagline": [
                    "Cambrian School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sartorette-charter-school-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 431
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.911,37.2608,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.911,37.2608,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "478220e1-d348-4c60-be12-4893055456b2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 373,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.669681,
                "lon": -121.783599
            },
            "entity": {
                "abbreviation": "Joe Michell",
                "alternates": {
                    "nces": "062211002628"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "478220e1-d348-4c60-be12-4893055456b2",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Joe Michell",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "0a020eaf-ab20-4a50-8308-d60ca74bd07f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Joe Michell",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "joe-michell-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 819
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.783,37.6685,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.783,37.6685,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94550"
            }
        ]
    },
    {
        "guid": "6344f28d-54e6-4596-b043-06c45005a976",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 374,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.980388,
                "lon": -122.124722
            },
            "entity": {
                "abbreviation": "John Swett Elementary School",
                "alternates": {
                    "nces": "062403003614"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6344f28d-54e6-4596-b043-06c45005a976",
                "isClaimed": false,
                "isPremium": false,
                "location": "Martinez, CA",
                "name": "John Swett Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb309258-c416-4193-9d2e-072248ea2ba6",
                    "schoolDistrict": "bb309258-c416-4193-9d2e-072248ea2ba6",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                    "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Martinez Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb309258-c416-4193-9d2e-072248ea2ba6",
                        "location": "Martinez, CA",
                        "name": "Martinez Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                            "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                        },
                        "premium": false,
                        "shortName": "Martinez Unified School District",
                        "tagline": [
                            "School District",
                            "Martinez, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "martinez-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John Swett Elementary School",
                "tagline": [
                    "Martinez Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "john-swett-elementary-school-martinez-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 512
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.124,37.9818,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.124,37.9818,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94553"
            }
        ]
    },
    {
        "guid": "17a64bd0-b417-4a29-b562-dbb42ba64ced",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 375,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.681508,
                "lon": -122.399572
            },
            "entity": {
                "abbreviation": "Brisbane Elementary School",
                "alternates": {
                    "nces": "060606000541"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "17a64bd0-b417-4a29-b562-dbb42ba64ced",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brisbane, CA",
                "name": "Brisbane Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f1111177-2a01-4f9f-ab49-2387b39175bf",
                    "schoolDistrict": "f1111177-2a01-4f9f-ab49-2387b39175bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "9bc6d672-7a23-47c9-8ec7-b0899a2d1a74",
                    "zipCode": "a3b9a792-eb0b-4455-bc46-9ccde2b7c60e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brisbane Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f1111177-2a01-4f9f-ab49-2387b39175bf",
                        "location": "Brisbane, CA",
                        "name": "Brisbane Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9bc6d672-7a23-47c9-8ec7-b0899a2d1a74",
                            "zipCode": "a3b9a792-eb0b-4455-bc46-9ccde2b7c60e"
                        },
                        "premium": false,
                        "shortName": "Brisbane Elementary School District",
                        "tagline": [
                            "School District",
                            "Brisbane, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brisbane-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Brisbane Elementary School",
                "tagline": [
                    "Brisbane Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "brisbane-elementary-school-brisbane-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 193
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4,37.6806,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4,37.6806,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94005"
            }
        ]
    },
    {
        "guid": "dc9a45e3-65e9-457c-8eeb-e0bc3580c2ef",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 376,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.271003,
                "lon": -121.925828
            },
            "entity": {
                "abbreviation": "Fammatre Elementary School",
                "alternates": {
                    "nces": "060714000652"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "dc9a45e3-65e9-457c-8eeb-e0bc3580c2ef",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Fammatre Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                    "parent": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "schoolDistrict": "aec99b83-c70f-442f-b30c-4c79582989d1",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cambrian School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "aec99b83-c70f-442f-b30c-4c79582989d1",
                        "location": "San Jose, CA",
                        "name": "Cambrian School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "5750507d-5a3f-47db-a00c-b8ac64163449",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "c051b239-374c-4f96-b426-d3be11c6ef67"
                        },
                        "premium": false,
                        "shortName": "Cambrian School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cambrian-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Fammatre Elementary School",
                "tagline": [
                    "Cambrian School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "fammatre-elementary-school-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 526
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.925,37.2714,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.925,37.2714,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95124"
            }
        ]
    },
    {
        "guid": "f333fe9f-5479-407d-ad86-16ac22fbe5cb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 377,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.539105,
                "lon": -122.309101
            },
            "entity": {
                "abbreviation": "Beresford Elementary School",
                "alternates": {
                    "nces": "063492007781"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f333fe9f-5479-407d-ad86-16ac22fbe5cb",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Beresford Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "2ab8d452-5509-4d81-a858-86685b2bc951"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Beresford Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "beresford-elementary-school-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 271
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Need to find out about Sadie Azevedo\nMy 5th grade teacher from the 1960’s\n\nCan you rsvp\n\nStephen A Jove\n(831) 207-0411",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-12-16T05:04:16.396967Z",
                "guid": "76780fcf-bef6-4bee-b6da-9e7f90c76812",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.309,37.5394,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.309,37.5394,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94403"
            }
        ]
    },
    {
        "guid": "833e6f5f-0dc2-4704-a1e7-e792bd43229e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 378,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.776279,
                "lon": -122.464031
            },
            "entity": {
                "abbreviation": "Frank McCoppin Elementary",
                "alternates": {
                    "nces": "063441005611"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "833e6f5f-0dc2-4704-a1e7-e792bd43229e",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Frank McCoppin Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "4c5b2024-bead-4cc8-b4bc-873b3b375d10",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "1b2bdc40-30b1-4227-bccb-fd8c4930354e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Frank McCoppin Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "frank-mccoppin-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 220
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.464,37.7763,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.464,37.7763,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94118"
            }
        ]
    },
    {
        "guid": "b3f9ec7b-1260-4e1f-b8b0-a050560901d7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 379,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.357646,
                "lon": -121.986628
            },
            "entity": {
                "abbreviation": "Briarwood Elementary School",
                "alternates": {
                    "nces": "063543006036"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "b3f9ec7b-1260-4e1f-b8b0-a050560901d7",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Briarwood Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Briarwood Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "briarwood-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 319
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.988,37.3581,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.988,37.3581,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95051"
            }
        ]
    },
    {
        "guid": "771c8619-5f51-4761-8504-a69a00996e0e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 380,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.95426,
                "lon": -121.699955
            },
            "entity": {
                "abbreviation": "Marsh Creek Elementary School",
                "alternates": {
                    "nces": "060591010754"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "771c8619-5f51-4761-8504-a69a00996e0e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Marsh Creek Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Marsh Creek Elementary School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "marsh-creek-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 732
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.707,37.9531,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.707,37.9531,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "a605ba19-ba42-4b1a-87ec-881f62f73c2b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 381,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.95615,
                "lon": -121.737134
            },
            "entity": {
                "abbreviation": "Pioneer Elementary School",
                "alternates": {
                    "nces": "060591010753"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a605ba19-ba42-4b1a-87ec-881f62f73c2b",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Pioneer Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Pioneer Elementary School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "pioneer-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 875
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Pioneer has great teachers and a very friendly staff. The school is very diverse, and my children all love it there.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-16T19:19:51.315436Z",
                "guid": "d663b9ad-ff84-4a3b-b95a-489cd14b667f",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.736,37.955,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.736,37.955,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "8fd9120a-445e-4143-8b39-caaa61da3be3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 382,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.319713,
                "lon": -121.788275
            },
            "entity": {
                "abbreviation": "Millbrook Elementary School",
                "alternates": {
                    "nces": "061314008836"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8fd9120a-445e-4143-8b39-caaa61da3be3",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Millbrook Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Millbrook Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "millbrook-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 618
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 30
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.787,37.3191,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.787,37.3191,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95148"
            }
        ]
    },
    {
        "guid": "2564dc9c-b0f0-42b9-8c12-f43e90cedab0",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 383,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.929245,
                "lon": -121.706888
            },
            "entity": {
                "abbreviation": "Brentwood Elementary School",
                "alternates": {
                    "nces": "060591000534"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2564dc9c-b0f0-42b9-8c12-f43e90cedab0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Brentwood Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Brentwood Elementary School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "brentwood-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 764
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.708,37.9283,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.708,37.9283,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "49196440-8c90-49a6-9911-2a02a3ee3cc8",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 384,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.939844,
                "lon": -121.739606
            },
            "entity": {
                "abbreviation": "Loma Vista Elementary School",
                "alternates": {
                    "nces": "060591008455"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "49196440-8c90-49a6-9911-2a02a3ee3cc8",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Loma Vista Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Loma Vista Elementary School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "loma-vista-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 613
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.736,37.9405,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.736,37.9405,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "5d5620a5-6cc1-4901-a8fe-de2f5aac3774",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 385,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.241139,
                "lon": -121.821158
            },
            "entity": {
                "abbreviation": "Oak Ridge Elementary School",
                "alternates": {
                    "nces": "062781004218"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5d5620a5-6cc1-4901-a8fe-de2f5aac3774",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Oak Ridge Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Oak Ridge Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "oak-ridge-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 551
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.82,37.2407,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.82,37.2407,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95123"
            }
        ]
    },
    {
        "guid": "1e4644f5-7fad-49c6-ac71-7435201edb3f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 386,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.550785,
                "lon": -122.023525
            },
            "entity": {
                "abbreviation": "Cabrillo Elementary School",
                "alternates": {
                    "nces": "061440001659"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "1e4644f5-7fad-49c6-ac71-7435201edb3f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "Cabrillo Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "4dc14d8f-c3ed-4d52-abca-3f8a107886e5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Cabrillo Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "cabrillo-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 418
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.024,37.5517,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.024,37.5517,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94536"
            }
        ]
    },
    {
        "guid": "f441bd93-6645-462c-bca3-bb34cc478e40",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 387,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.971466,
                "lon": -121.714118
            },
            "entity": {
                "abbreviation": "Garin Elementary School",
                "alternates": {
                    "nces": "060591000536"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f441bd93-6645-462c-bca3-bb34cc478e40",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Garin Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6ee6365f-3e69-4aad-a8df-5cd72ae38991",
                    "zipCode": "4e62bbb5-5683-41cb-a3ca-4f5f14325035"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Garin Elementary School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "garin-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 653
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.69,37.928,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.69,37.928,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "b7289aae-2cc6-4349-b691-0d927456ff31",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 388,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.405866,
                "lon": -122.084992
            },
            "entity": {
                "abbreviation": "Theuerkauf Elementary School",
                "alternates": {
                    "nces": "062628006933"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b7289aae-2cc6-4349-b691-0d927456ff31",
                "isClaimed": false,
                "isPremium": false,
                "location": "Mountain View, CA",
                "name": "Theuerkauf Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "schoolDistrict": "55669afd-2c14-4350-8f57-42ebb04aa320",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                    "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Mountain View Whisman School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "55669afd-2c14-4350-8f57-42ebb04aa320",
                        "location": "Mountain View, CA",
                        "name": "Mountain View Whisman School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "829d17a3-f1df-4505-8ff2-57bda4794f1c",
                            "zipCode": "b06f5a38-eb84-4d23-9399-05105d3f59ce"
                        },
                        "premium": false,
                        "shortName": "Mountain View Whisman School District",
                        "tagline": [
                            "School District",
                            "Mountain View, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mountain-view-whisman-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Theuerkauf Elementary School",
                "tagline": [
                    "Mountain View Whisman School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "theuerkauf-elementary-school-mountain-view-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 355
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.084,37.405,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.084,37.405,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94043"
            }
        ]
    },
    {
        "guid": "10cd6a7c-98ad-4fbc-98f9-81a377fcf804",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 389,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.596838,
                "lon": -122.496867
            },
            "entity": {
                "abbreviation": "Cabrillo Elementary School",
                "alternates": {
                    "nces": "062046002458"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "10cd6a7c-98ad-4fbc-98f9-81a377fcf804",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pacifica, CA",
                "name": "Cabrillo Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "schoolDistrict": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                    "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Pacifica School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                        "location": "Pacifica, CA",
                        "name": "Pacifica School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                            "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                        },
                        "premium": false,
                        "shortName": "Pacifica School District",
                        "tagline": [
                            "School District",
                            "Pacifica, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pacifica-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Cabrillo Elementary School",
                "tagline": [
                    "Pacifica School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "cabrillo-elementary-school-pacifica-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 562
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.496,37.598,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.496,37.598,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94044"
            }
        ]
    },
    {
        "guid": "1eb8fb69-58ed-4807-8d50-db50c3d6b5c3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 390,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.683983,
                "lon": -121.729025
            },
            "entity": {
                "abbreviation": "Arroyo Seco Elementary School",
                "alternates": {
                    "nces": "062211002620"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "1eb8fb69-58ed-4807-8d50-db50c3d6b5c3",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Arroyo Seco Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "0a020eaf-ab20-4a50-8308-d60ca74bd07f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Arroyo Seco Elementary School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "arroyo-seco-elementary-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 678
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.728,37.6845,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.728,37.6845,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94550"
            }
        ]
    },
    {
        "guid": "393ec551-a8db-4ab7-9682-b417a2c57caf",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 391,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.623987,
                "lon": -122.452456
            },
            "entity": {
                "abbreviation": "Portola Elementary School",
                "alternates": {
                    "nces": "063423008626"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "393ec551-a8db-4ab7-9682-b417a2c57caf",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Bruno, CA",
                "name": "Portola Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "af247174-605a-4c45-96da-8e4b1aad03f4",
                    "schoolDistrict": "af247174-605a-4c45-96da-8e4b1aad03f4",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "501fa4d3-c95b-4be7-8f45-a278b79ec201",
                    "zipCode": "2747e85f-3e2c-4bb8-a767-725ae14708a4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Bruno Park Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "af247174-605a-4c45-96da-8e4b1aad03f4",
                        "location": "San Bruno, CA",
                        "name": "San Bruno Park Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "501fa4d3-c95b-4be7-8f45-a278b79ec201",
                            "zipCode": "2747e85f-3e2c-4bb8-a767-725ae14708a4"
                        },
                        "premium": false,
                        "shortName": "San Bruno Park Elementary School District",
                        "tagline": [
                            "School District",
                            "San Bruno, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-bruno-park-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Portola Elementary School",
                "tagline": [
                    "San Bruno Park Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "portola-elementary-school-san-bruno-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 337
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.452,37.6233,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.452,37.6233,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94066"
            }
        ]
    },
    {
        "guid": "f61c52c0-263b-419a-bcf3-040016d1ea8c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 392,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.231403,
                "lon": -121.83908
            },
            "entity": {
                "abbreviation": "Sakamoto Elementary School",
                "alternates": {
                    "nces": "062781004220"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f61c52c0-263b-419a-bcf3-040016d1ea8c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Sakamoto Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Sakamoto Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "sakamoto-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 639
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Sakamoto had teachers that were very dedicated for their students. For example, I had a teach who dedicated a lot of after school time to me because I did not understand the material and could not read very well, and she made sure to take time out of her day to ensure I knew the material.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-11-14T16:13:55.220693Z",
                "guid": "0e193e55-b869-42f1-bf52-27a02a186131",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.838,37.2311,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.838,37.2311,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95123"
            }
        ]
    },
    {
        "guid": "e36ab80b-e641-4333-ab7b-3e74dc8c95cc",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 393,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.528069,
                "lon": -121.977685
            },
            "entity": {
                "abbreviation": "John Blacow Elementary School",
                "alternates": {
                    "nces": "061440001656"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e36ab80b-e641-4333-ab7b-3e74dc8c95cc",
                "isClaimed": false,
                "isPremium": false,
                "location": "Fremont, CA",
                "name": "John Blacow Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "schoolDistrict": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "36041593-134d-436a-b83b-4c4244f6a447",
                    "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "FUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "409b0789-8963-4ffa-9fdd-ca61850d3ef0",
                        "location": "Fremont, CA",
                        "name": "Fremont Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "36041593-134d-436a-b83b-4c4244f6a447",
                            "zipCode": "132ece3d-4f68-42cf-badb-1c281d1efddd"
                        },
                        "premium": false,
                        "shortName": "Fremont Unified School District",
                        "tagline": [
                            "School District",
                            "Fremont, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "fremont-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John Blacow Elementary School",
                "tagline": [
                    "Fremont Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "john-blacow-elementary-school-fremont-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 447
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.976,37.5279,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.976,37.5279,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94538"
            }
        ]
    },
    {
        "guid": "f651b817-fc6d-4d3d-b015-1ece9241009f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 394,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.893155,
                "lon": -122.280519
            },
            "entity": {
                "abbreviation": "Thousand Oaks Elementary School",
                "alternates": {
                    "nces": "060474000450"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f651b817-fc6d-4d3d-b015-1ece9241009f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Berkeley, CA",
                "name": "Thousand Oaks Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "93e55122-d6d5-419b-88c9-93565823c556",
                    "parent": "a80d0954-768b-4a61-af13-29460f542800",
                    "schoolDistrict": "a80d0954-768b-4a61-af13-29460f542800",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                    "zipCode": "59111173-6ea4-44ab-b974-b2b1bf1af8fb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "BUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a80d0954-768b-4a61-af13-29460f542800",
                        "location": "Berkeley, CA",
                        "name": "Berkeley Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "62e9b88d-c1c2-4def-a030-dd9fa0106676",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4eb8fec9-8de0-46a9-b898-75cc913b8cef",
                            "zipCode": "40657545-f7b8-4dad-aab4-1f71e29d13b4"
                        },
                        "premium": false,
                        "shortName": "Berkley Public Schools",
                        "tagline": [
                            "School District",
                            "Berkeley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berkeley-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Thousand Oaks Elementary School",
                "tagline": [
                    "Berkeley Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "thousand-oaks-elementary-school-berkeley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 403
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.28,37.8931,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.28,37.8931,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94707"
            }
        ]
    },
    {
        "guid": "fb2fe867-fdcb-42cc-a0c9-13233390d050",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 395,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.72793,
                "lon": -122.407214
            },
            "entity": {
                "abbreviation": "Edward R. Taylor Elementary",
                "alternates": {
                    "nces": "063441005604"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "fb2fe867-fdcb-42cc-a0c9-13233390d050",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Edward R. Taylor Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "f03b3dc2-87ff-47a3-a436-170bc3a6609a",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "67339067-3b07-488a-a8f9-c097bd4fcad2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Edward R. Taylor Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "edward-r-taylor-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 646
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.407,37.7274,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.407,37.7274,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94134"
            }
        ]
    },
    {
        "guid": "35d7e4ca-84c3-490b-a435-4d5a4d1b483b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 396,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.271589,
                "lon": -121.845826
            },
            "entity": {
                "abbreviation": "Parkview Elementary School",
                "alternates": {
                    "nces": "062781004219"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "35d7e4ca-84c3-490b-a435-4d5a4d1b483b",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Parkview Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "bcda0e04-4247-440c-ae7f-42454876c867"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Parkview Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "parkview-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 579
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.845,37.2706,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.845,37.2706,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95136"
            }
        ]
    },
    {
        "guid": "193c15d7-1d38-4b44-a6a6-6cfa8f06d4af",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 397,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.414883,
                "lon": -121.871111
            },
            "entity": {
                "abbreviation": "Laneview Elementary School",
                "alternates": {
                    "nces": "060480000459"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "193c15d7-1d38-4b44-a6a6-6cfa8f06d4af",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Laneview Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Laneview Elementary School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "laneview-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 373
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.872,37.4138,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.872,37.4138,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "ed183d37-69d6-498a-9ef7-2c4b893c8360",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 398,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.30735,
                "lon": -121.787935
            },
            "entity": {
                "abbreviation": "Cadwallader Elementary School",
                "alternates": {
                    "nces": "061314001490"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ed183d37-69d6-498a-9ef7-2c4b893c8360",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Cadwallader Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "d45dfd8d-1c4a-4d14-adf5-73af66adef2a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Cadwallader Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "cadwallader-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 341
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.789,37.3066,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.789,37.3066,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95121"
            }
        ]
    },
    {
        "guid": "6c2952ed-205c-4a6b-badd-43bbd40cf0a9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 399,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.950315,
                "lon": -122.075431
            },
            "entity": {
                "abbreviation": "Strandwood Elementary School",
                "alternates": {
                    "nces": "062637003974"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6c2952ed-205c-4a6b-badd-43bbd40cf0a9",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Strandwood Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "454e8dd6-f26e-4493-a281-1f2250e8bae9",
                    "zipCode": "c02f3379-b7e4-421d-8daf-40132890b3fd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Strandwood Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "strandwood-elementary-school-pleasant-hill-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 622
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.077,37.9504,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.077,37.9504,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94523"
            }
        ]
    },
    {
        "guid": "648ee54f-dca9-4454-a8a4-a25357b32ebb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 400,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.482028,
                "lon": -122.262936
            },
            "entity": {
                "abbreviation": "Clifford School",
                "alternates": {
                    "nces": "063213004962"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "648ee54f-dca9-4454-a8a4-a25357b32ebb",
                "isClaimed": true,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "Clifford School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "43bebd40-0f9e-4801-b879-ef6adab50ceb",
                    "zipCode": "69cda34a-cfdb-4ce8-92cc-935e98812d50"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Clifford ",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "clifford-school-redwood-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 554
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "We're new to the Clifford community, but after being in \"top-rated\" schools in other parts of the country, I can safely say that Clifford is the best school our children have attended. The teachers are phenomenal, the administration truly care about student development, and the parents are active, especially through the PTO. Very impressed so far!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-12-05T05:48:59.511053Z",
                "guid": "3bdd4f1e-63f7-4979-b8ce-a12b936d8f1f",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.264,37.482,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.264,37.482,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 3
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94062"
            }
        ]
    },
    {
        "guid": "b27f0a05-e4ee-46c5-94eb-df66d09cbb7f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 401,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.588572,
                "lon": -122.477123
            },
            "entity": {
                "abbreviation": "Ortega Elementary School",
                "alternates": {
                    "nces": "062046011430"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b27f0a05-e4ee-46c5-94eb-df66d09cbb7f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pacifica, CA",
                "name": "Ortega Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "schoolDistrict": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                    "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Pacifica School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb7f05c5-2704-4a55-809d-5eaf72841a4a",
                        "location": "Pacifica, CA",
                        "name": "Pacifica School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "31f36d31-d402-416c-a8c8-c1050bc23388",
                            "zipCode": "4296d11e-c508-4cdd-9609-6ede3d3e659e"
                        },
                        "premium": false,
                        "shortName": "Pacifica School District",
                        "tagline": [
                            "School District",
                            "Pacifica, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "pacifica-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ortega Elementary School",
                "tagline": [
                    "Pacifica School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ortega-elementary-school-pacifica-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 481
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.478,37.5878,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.478,37.5878,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94044"
            }
        ]
    },
    {
        "guid": "5158ad76-a82f-4105-8c83-a92098fe0da5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 402,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.389553,
                "lon": -121.85201
            },
            "entity": {
                "abbreviation": "Summerdale Elementary School",
                "alternates": {
                    "nces": "060480000467"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5158ad76-a82f-4105-8c83-a92098fe0da5",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Summerdale Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Summerdale Elementary School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "summerdale-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 403
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "Overall it was a fun school to attend. Really sweet teachers whom I still keep in contact with today. I had a lot of great memories from this school which still impact me today. Even as a 5th grader I had a lot of opportunities to get involved with my community at a young age, such as doing yearbook, recycling club, and serving lunch to other students.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-02-14T18:29:49.645616Z",
                "guid": "32786fc5-5c48-438d-90e2-6bab0cec7e8f",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.851,37.3901,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.851,37.3901,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "531c4a5e-b62e-457b-a098-d59cf87ca744",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 403,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.78387,
                "lon": -122.471019
            },
            "entity": {
                "abbreviation": "Sutro Elementary School",
                "alternates": {
                    "nces": "063441005674"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "531c4a5e-b62e-457b-a098-d59cf87ca744",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Sutro Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "4c5b2024-bead-4cc8-b4bc-873b3b375d10",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "1b2bdc40-30b1-4227-bccb-fd8c4930354e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sutro Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sutro-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 262
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.471,37.7836,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.471,37.7836,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94118"
            }
        ]
    },
    {
        "guid": "02cae65a-d202-4cdb-a106-90b889c9657e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 404,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.119644,
                "lon": -122.604085
            },
            "entity": {
                "abbreviation": "San Ramon Elementary School",
                "alternates": {
                    "nces": "062772004197"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "02cae65a-d202-4cdb-a106-90b889c9657e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "San Ramon Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "San Ramon Elementary School",
                "tagline": [
                    "Novato Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "san-ramon-elementary-school-novato-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 467
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.605,38.1193,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.605,38.1193,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94945"
            }
        ]
    },
    {
        "guid": "2c396f80-d7d9-40fa-a36a-712d2fe15e79",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 405,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.657388,
                "lon": -122.445443
            },
            "entity": {
                "abbreviation": "Buri Buri Elementary School",
                "alternates": {
                    "nces": "063753006341"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2c396f80-d7d9-40fa-a36a-712d2fe15e79",
                "isClaimed": false,
                "isPremium": false,
                "location": "South San Francisco, CA",
                "name": "Buri Buri Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "schoolDistrict": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                    "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "South San Francisco Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "41a0969a-227f-49e4-989c-cce558a6963c",
                        "location": "South San Francisco, CA",
                        "name": "South San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                            "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                        },
                        "premium": false,
                        "shortName": "South San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "South San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "south-san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Buri Buri Elementary School",
                "tagline": [
                    "South San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "buri-buri-elementary-school-south-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 601
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.446,37.6565,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.446,37.6565,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94080"
            }
        ]
    },
    {
        "guid": "80337fdf-a34f-4f10-a456-56545fba1e05",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 406,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.572026,
                "lon": -122.071621
            },
            "entity": {
                "abbreviation": "Pioneer Elementary School",
                "alternates": {
                    "nces": "062691007795"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "80337fdf-a34f-4f10-a456-56545fba1e05",
                "isClaimed": true,
                "isPremium": false,
                "location": "Union City, CA",
                "name": "Pioneer Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "schoolDistrict": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                    "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "New Haven Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "305b3896-e539-4976-95d2-a90396c3f1cf",
                        "location": "Union City, CA",
                        "name": "New Haven Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                            "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                        },
                        "premium": false,
                        "shortName": "New Haven Unified School District",
                        "tagline": [
                            "School District",
                            "Union City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "new-haven-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Pioneer Elementary School",
                "tagline": [
                    "New Haven Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "pioneer-elementary-school-union-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 750
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Teachers are invested and very involved in the advancement of the students. My daughter really appreciates her teachers. However, substitute teachers are a different story.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-01-09T23:02:47.286313Z",
                "guid": "db90546c-66f7-4327-895c-be99eb012a36",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.071,37.5728,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.071,37.5728,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94587"
            }
        ]
    },
    {
        "guid": "9c62e091-818c-4d2c-afaf-9c03677f8856",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 407,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.792669,
                "lon": -122.433459
            },
            "entity": {
                "abbreviation": "San Francisco Public Montessori",
                "alternates": {
                    "nces": "063441012788"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9c62e091-818c-4d2c-afaf-9c03677f8856",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "San Francisco Public Montessori",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "7e186115-03c1-40e9-b1a3-5a5d0295f400",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "9fbdd391-c59a-4ee3-a774-a928ff2f0905"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "San Francisco Public Montessori",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "san-francisco-public-montessori-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 173
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 72
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.434,37.7927,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.434,37.7927,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94115"
            }
        ]
    },
    {
        "guid": "f6999c81-a50b-4f87-9ec5-4f966fc7cfca",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 408,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.425256,
                "lon": -121.972505
            },
            "entity": {
                "abbreviation": "George Mayne Elementary School",
                "alternates": {
                    "nces": "063543006043"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "f6999c81-a50b-4f87-9ec5-4f966fc7cfca",
                "isClaimed": true,
                "isPremium": false,
                "location": "Alviso, CA",
                "name": "George Mayne Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "cac9d554-659e-44ea-8589-5e37d77dc37e",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "02fbceb7-af61-4ec0-9ebe-25b6f2ba8e4d"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "George Mayne Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "george-mayne-elementary-school-alviso-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 493
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.967,37.4244,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.967,37.4244,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95002"
            }
        ]
    },
    {
        "guid": "7dff0eb9-3bba-45ed-85e0-5d226c953b0a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 409,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.987564,
                "lon": -121.826837
            },
            "entity": {
                "abbreviation": "Antioch Charter Academy",
                "alternates": {
                    "nces": "060202707493"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "7dff0eb9-3bba-45ed-85e0-5d226c953b0a",
                "isClaimed": false,
                "isPremium": false,
                "location": "Antioch, CA",
                "name": "Antioch Charter Academy",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "775a411e-1de8-46d4-aa54-28cb004cae6f",
                    "zipCode": "2026ed1b-602c-43a5-859e-4d5b3ed7410b"
                },
                "premium": false,
                "shortName": "Antioch Charter Academy",
                "tagline": [
                    "Public School",
                    "Antioch, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "antioch-charter-academy-antioch-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 197
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.827,37.9883,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.827,37.9883,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94509"
            }
        ]
    },
    {
        "guid": "8a5dcc44-3519-4d30-a2f8-d508523a7081",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 410,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.328533,
                "lon": -121.788931
            },
            "entity": {
                "abbreviation": "Norwood Creek Elementary School",
                "alternates": {
                    "nces": "061314007708"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8a5dcc44-3519-4d30-a2f8-d508523a7081",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Norwood Creek Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Norwood Creek Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "norwood-creek-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 625
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "elementary school was a great time and mostly because of this elementary school! they have shown great improvements since i was there including reconstruction, new teachers, and a better playground.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-06-13T21:21:06.577737Z",
                "guid": "3bc2c01e-4669-4bcf-838f-203a662cbb25",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.79,37.3279,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.79,37.3279,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95148"
            }
        ]
    },
    {
        "guid": "e31efd84-3c33-4869-8b90-046b5c3d77ed",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 411,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.357548,
                "lon": -121.842475
            },
            "entity": {
                "abbreviation": "Rocketship Fuerza Community Prep",
                "alternates": {
                    "nces": "060237913747"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "e31efd84-3c33-4869-8b90-046b5c3d77ed",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Rocketship Fuerza Community Prep",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bc007bff-f267-494c-b4bc-92ce588a6db6",
                    "schoolNetwork": "159c1d35-78c2-4e2f-8837-1d24cf94af66",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "953f02c6-35fb-44cc-a96d-584c48aceaab"
                },
                "premium": false,
                "shortName": "Rocketship Fuerza Community Prep",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rocketship-fuerza-community-prep-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 628
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 36
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.842,37.3576,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.842,37.3576,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95116"
            }
        ]
    },
    {
        "guid": "aae41ab8-9724-493a-bf70-105f1ab84dec",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 412,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.999076,
                "lon": -122.119452
            },
            "entity": {
                "abbreviation": "John Muir Elementary School",
                "alternates": {
                    "nces": "062403009579"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "aae41ab8-9724-493a-bf70-105f1ab84dec",
                "isClaimed": false,
                "isPremium": false,
                "location": "Martinez, CA",
                "name": "John Muir Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "bb309258-c416-4193-9d2e-072248ea2ba6",
                    "schoolDistrict": "bb309258-c416-4193-9d2e-072248ea2ba6",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                    "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Martinez Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "bb309258-c416-4193-9d2e-072248ea2ba6",
                        "location": "Martinez, CA",
                        "name": "Martinez Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                            "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                        },
                        "premium": false,
                        "shortName": "Martinez Unified School District",
                        "tagline": [
                            "School District",
                            "Martinez, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "martinez-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John Muir Elementary School",
                "tagline": [
                    "Martinez Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "john-muir-elementary-school-martinez-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 434
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.12,38.0001,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.12,38.0001,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94553"
            }
        ]
    },
    {
        "guid": "59044315-e5cf-4864-9b58-7289d649b751",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 413,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.011154,
                "lon": -121.82086
            },
            "entity": {
                "abbreviation": "Antioch Charter Academy II",
                "alternates": {
                    "nces": "060182211984"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "59044315-e5cf-4864-9b58-7289d649b751",
                "isClaimed": false,
                "isPremium": false,
                "location": "Antioch, CA",
                "name": "Antioch Charter Academy II",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "775a411e-1de8-46d4-aa54-28cb004cae6f",
                    "zipCode": "2026ed1b-602c-43a5-859e-4d5b3ed7410b"
                },
                "premium": false,
                "shortName": "Antioch Charter Academy II",
                "tagline": [
                    "Public School",
                    "Antioch, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "antioch-charter-academy-ii-antioch-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 202
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.825,38.0108,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.825,38.0108,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94509"
            }
        ]
    },
    {
        "guid": "72646464-cebd-4b8f-81ee-fd3cf13d39a7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 414,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.3673,
                "lon": -121.9762
            },
            "entity": {
                "abbreviation": "Bracher Elementary School",
                "alternates": {
                    "nces": "063543006034"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "72646464-cebd-4b8f-81ee-fd3cf13d39a7",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Bracher Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Bracher Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "bracher-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 344
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 30
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Very good school. My daughter is just completing her fifth grade here and been here since first grade. Really good teachers, who take a lot of time to personalize teaching and very dedicated.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-05-15T17:27:27.999106Z",
                "guid": "da55efc5-a22a-4f1c-beda-5cb9c9ad9d5c",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.975,37.3669,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.975,37.3669,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95051"
            }
        ]
    },
    {
        "guid": "e7c2980b-d2f2-46e9-8e37-065695b776ad",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 415,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.394782,
                "lon": -121.865306
            },
            "entity": {
                "abbreviation": "Cherrywood Elementary School",
                "alternates": {
                    "nces": "060480000458"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e7c2980b-d2f2-46e9-8e37-065695b776ad",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Cherrywood Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Cherrywood Elementary School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cherrywood-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 391
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.865,37.3941,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.865,37.3941,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95132"
            }
        ]
    },
    {
        "guid": "9c01bfc4-e51d-40c2-9215-c49e76e4bc73",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 416,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.840502,
                "lon": -122.2324
            },
            "entity": {
                "abbreviation": "Hillcrest Elementary School",
                "alternates": {
                    "nces": "062805004275"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9c01bfc4-e51d-40c2-9215-c49e76e4bc73",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Hillcrest Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "f80b92b8-46fc-430c-9898-99e764ec21f3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Hillcrest Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "hillcrest-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 388
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I have been here for almost nine years, and it has been a very good experience. For a public school, it has a lot of resources, comparable to some private schools. Highly recommend for people looking for a small school with only ~50 students per grade.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-04-20T20:08:21.652758Z",
                "guid": "17bc0300-4d3d-4840-80f6-7e7c79f9fd00",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.233,37.8412,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.233,37.8412,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.8,
                "count": 5
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94618"
            }
        ]
    },
    {
        "guid": "5b451266-9d3f-4cd8-b763-c548698688d1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 417,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.613025,
                "lon": -122.435325
            },
            "entity": {
                "abbreviation": "John Muir Elementary School",
                "alternates": {
                    "nces": "063423005397"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5b451266-9d3f-4cd8-b763-c548698688d1",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Bruno, CA",
                "name": "John Muir Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "af247174-605a-4c45-96da-8e4b1aad03f4",
                    "schoolDistrict": "af247174-605a-4c45-96da-8e4b1aad03f4",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "501fa4d3-c95b-4be7-8f45-a278b79ec201",
                    "zipCode": "2747e85f-3e2c-4bb8-a767-725ae14708a4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Bruno Park Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "af247174-605a-4c45-96da-8e4b1aad03f4",
                        "location": "San Bruno, CA",
                        "name": "San Bruno Park Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "501fa4d3-c95b-4be7-8f45-a278b79ec201",
                            "zipCode": "2747e85f-3e2c-4bb8-a767-725ae14708a4"
                        },
                        "premium": false,
                        "shortName": "San Bruno Park Elementary School District",
                        "tagline": [
                            "School District",
                            "San Bruno, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-bruno-park-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "John Muir Elementary School",
                "tagline": [
                    "San Bruno Park Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "john-muir-elementary-school-san-bruno-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 437
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 35
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.6138,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.6138,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94066"
            }
        ]
    },
    {
        "guid": "aa31e044-d77d-43fa-8502-ac3d8da9651e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 418,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.849031,
                "lon": -122.258784
            },
            "entity": {
                "abbreviation": "Peralta Elementary School",
                "alternates": {
                    "nces": "062805004307"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "aa31e044-d77d-43fa-8502-ac3d8da9651e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Peralta Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "60b359f6-6160-41a8-9d11-3415c3143417"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Peralta Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "peralta-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 331
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great teachers and community. Lots of parent participation and involvement. Community is inclusive with a culturally diverse student body.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-27T12:42:20.062615Z",
                "guid": "4dece1e4-d46b-4844-a881-deaea029d24a",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.259,37.8495,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.259,37.8495,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94609"
            }
        ]
    },
    {
        "guid": "bf63e2b9-6ae8-4eb2-8179-33cff5dfef96",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 419,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.398565,
                "lon": -121.880253
            },
            "entity": {
                "abbreviation": "Brooktree Elementary School",
                "alternates": {
                    "nces": "060480000457"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "bf63e2b9-6ae8-4eb2-8179-33cff5dfef96",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Brooktree Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "bc007bff-f267-494c-b4bc-92ce588a6db6",
                    "parent": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "schoolDistrict": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "782e20d7-90eb-4994-919f-95fc800744f4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Berryessa Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "05982ad0-949d-4406-ade5-eb14ec477b5f",
                        "location": "San Jose, CA",
                        "name": "Berryessa Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "73dbbbce-3862-46d6-b375-4abe156d1210",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "f9b10c5e-36dc-4824-9185-338678030da4"
                        },
                        "premium": false,
                        "shortName": "Berryessa Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "berryessa-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Brooktree Elementary School",
                "tagline": [
                    "Berryessa Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "brooktree-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 461
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.88,37.3978,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.88,37.3978,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95131"
            }
        ]
    },
    {
        "guid": "025bf3a6-b535-4171-9ee0-246ffc593624",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 420,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.836271,
                "lon": -122.21153
            },
            "entity": {
                "abbreviation": "Thornhill Elementary School",
                "alternates": {
                    "nces": "062805004318"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "025bf3a6-b535-4171-9ee0-246ffc593624",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Thornhill Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Thornhill Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "thornhill-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 410
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I've had such wonderful experience here letting me explore all aspects of school as well as helping grow strong connections with various friends. The small school setting allowed a lot of one v one time with the teacher and helped me thrive.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2016-10-30T01:05:48.337239Z",
                "guid": "b42ffcfe-6a79-4c0b-99bc-8a5dd275d218",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.211,37.8357,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.211,37.8357,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94611"
            }
        ]
    },
    {
        "guid": "951aba82-d6d0-4e0a-8e69-677b32718481",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 421,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.830325,
                "lon": -122.211817
            },
            "entity": {
                "abbreviation": "Montclair Elementary School",
                "alternates": {
                    "nces": "062805004302"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "951aba82-d6d0-4e0a-8e69-677b32718481",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Montclair Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Montclair Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "montclair-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 640
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Middle School Student",
                "body": "I LOVE MONTCLAIR\nLOVE\nLOVE\ni recommend this school to everyone, the teachers are amazing, the campus is clean, they have an amazing library with an amazing librarian, they music and art, and clubs and sports and an AWESOME garden, there are lots of plants and food in the garden.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-04-29T17:15:22.95726Z",
                "guid": "0eadf238-cd95-44d5-93ef-13cfbb78ced5",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.212,37.8302,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.212,37.8302,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94611"
            }
        ]
    },
    {
        "guid": "d63f2f6b-35ba-42c0-acec-40ad98695f43",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 422,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.900929,
                "lon": -121.596768
            },
            "entity": {
                "abbreviation": "Discovery Bay Elementary School",
                "alternates": {
                    "nces": "060675007653"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d63f2f6b-35ba-42c0-acec-40ad98695f43",
                "isClaimed": false,
                "isPremium": false,
                "location": "Discovery Bay, CA",
                "name": "Discovery Bay Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "77c39175-7d61-4abc-ba26-79ea992e5079",
                    "schoolDistrict": "77c39175-7d61-4abc-ba26-79ea992e5079",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "82335b37-1b0e-4d8b-914a-287ac9d5d5d5",
                    "zipCode": "37f2e295-8e86-44bb-b3c4-e75a55124a7d"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Byron Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "77c39175-7d61-4abc-ba26-79ea992e5079",
                        "location": "Byron, CA",
                        "name": "Byron Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3f2c06a3-6dc5-4b8b-ae42-25d409d4ae34",
                            "zipCode": "4c4ae928-08ae-4b39-a92f-d9350bc71e2f"
                        },
                        "premium": false,
                        "shortName": "Byron Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Byron, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "byron-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Discovery Bay Elementary School",
                "tagline": [
                    "Byron Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "discovery-bay-elementary-school-discovery-bay-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 418
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.599,37.9011,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.599,37.9011,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94505"
            }
        ]
    },
    {
        "guid": "00c95bc2-ec1f-48a7-b089-5ad7e21c1619",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 423,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.280574,
                "lon": -121.888278
            },
            "entity": {
                "abbreviation": "Schallenberger Elementary School",
                "alternates": {
                    "nces": "063459005737"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "00c95bc2-ec1f-48a7-b089-5ad7e21c1619",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Schallenberger Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9bed7cde-76c6-4ed3-9076-f48cade709d3",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "0f5f9ca7-0382-4b2d-8697-36994f2fddf7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Schallenberger Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "schallenberger-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 570
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.887,37.2798,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.887,37.2798,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95125"
            }
        ]
    },
    {
        "guid": "8979dd2c-5853-4042-b2d3-94455bcf79e1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 424,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.850453,
                "lon": -122.243623
            },
            "entity": {
                "abbreviation": "Chabot Elementary School",
                "alternates": {
                    "nces": "062805004239"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8979dd2c-5853-4042-b2d3-94455bcf79e1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Chabot Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "f80b92b8-46fc-430c-9898-99e764ec21f3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Chabot Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "chabot-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 580
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.243,37.8504,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.243,37.8504,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94618"
            }
        ]
    },
    {
        "guid": "54751dbb-dca9-46f5-a853-fc0e624f1ef3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 425,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.793956,
                "lon": -122.40872
            },
            "entity": {
                "abbreviation": "Gordon J. Lau Elementary",
                "alternates": {
                    "nces": "063441005599"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "54751dbb-dca9-46f5-a853-fc0e624f1ef3",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Gordon J. Lau Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "ce1de80c-3bf7-4c9b-a728-454ef6ec3f34",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "78ecd0d9-e579-4d73-bbc3-ba39ef4c5d7c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Gordon J. Lau Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "gordon-j-lau-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 695
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.409,37.7944,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.409,37.7944,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94108"
            }
        ]
    },
    {
        "guid": "659737d9-9149-45d4-9ae2-36fc9970fb7d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 426,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.482135,
                "lon": -122.210823
            },
            "entity": {
                "abbreviation": "Marjorie H. Tobias Elementary School",
                "alternates": {
                    "nces": "061887002289"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "659737d9-9149-45d4-9ae2-36fc9970fb7d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Daly City, CA",
                "name": "Marjorie H. Tobias Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "844b235c-05df-4456-8ac0-5f90a4c285b8",
                    "parent": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "schoolDistrict": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Jefferson Elementary School District (Daly City)",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                        "location": "Daly City, CA",
                        "name": "Jefferson Elementary School District (Daly City)",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "41a99124-6b7c-496d-9635-39a33c683883",
                            "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                        },
                        "premium": false,
                        "shortName": "Jefferson Elementary School District (Daly City)",
                        "tagline": [
                            "School District",
                            "Daly City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "jefferson-elementary-school-district-daly-city-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Marjorie H. Tobias Elementary School",
                "tagline": [
                    "Jefferson Elementary School District (Daly City), CA",
                    "K-5"
                ],
                "type": "School",
                "url": "marjorie-h-tobias-elementary-school-daly-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 378
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.49,37.6911,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.49,37.6911,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94015"
            }
        ]
    },
    {
        "guid": "6dac6879-34b7-4665-a24e-c8983782cd22",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 427,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.301551,
                "lon": -121.947201
            },
            "entity": {
                "abbreviation": "Castlemont Elementary School",
                "alternates": {
                    "nces": "060720000664"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "6dac6879-34b7-4665-a24e-c8983782cd22",
                "isClaimed": false,
                "isPremium": false,
                "location": "Campbell, CA",
                "name": "Castlemont Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "schoolDistrict": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "bf537caa-7876-4936-b0a8-98add2dd3610"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Campbell Union School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "dc02e654-b7be-4c5e-8765-d6879d9d99cf",
                        "location": "Campbell, CA",
                        "name": "Campbell Union School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3b98262-15ef-44a8-815a-27134567fadc",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "4df422c6-0638-49ba-8066-ba47e02b585b",
                            "zipCode": "ca297213-7ddc-46ed-b83c-f4704a492b61"
                        },
                        "premium": false,
                        "shortName": "Campbell Union School District",
                        "tagline": [
                            "School District",
                            "Campbell, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "campbell-union-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Castlemont Elementary School",
                "tagline": [
                    "Campbell Union School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "castlemont-elementary-school-campbell-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 626
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.947,37.3007,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.947,37.3007,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95008"
            }
        ]
    },
    {
        "guid": "c255946e-ac81-471c-b16f-252c18e99b15",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 428,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.912486,
                "lon": -122.012242
            },
            "entity": {
                "abbreviation": "Eagle Peak Montessori",
                "alternates": {
                    "nces": "062637008688"
                },
                "character": "Public, Magnet, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "c255946e-ac81-471c-b16f-252c18e99b15",
                "isClaimed": false,
                "isPremium": false,
                "location": "Walnut Creek, CA",
                "name": "Eagle Peak Montessori",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e3c4436c-7dc9-4c55-8ef7-d47f7a932ba9",
                    "zipCode": "047384f0-7981-4f9a-bed7-6c8444ca2013"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Eagle Peak Montessori",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "1-8"
                ],
                "type": "School",
                "url": "eagle-peak-montessori-walnut-creek-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 286
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.011,37.9117,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.011,37.9117,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94598"
            }
        ]
    },
    {
        "guid": "c6fe58ce-0ed4-4734-80c2-715bfb1ac96c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 429,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.303222,
                "lon": -121.896963
            },
            "entity": {
                "abbreviation": "Willow Glen Elementary School",
                "alternates": {
                    "nces": "063459005745"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c6fe58ce-0ed4-4734-80c2-715bfb1ac96c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Willow Glen Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9bed7cde-76c6-4ed3-9076-f48cade709d3",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "0f5f9ca7-0382-4b2d-8697-36994f2fddf7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Willow Glen Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "willow-glen-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 756
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.897,37.3032,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.897,37.3032,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95125"
            }
        ]
    },
    {
        "guid": "198a4438-6ddb-4585-8bdd-8cd39f580635",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 430,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.716955,
                "lon": -121.728316
            },
            "entity": {
                "abbreviation": "Leo R. Croce Elementary School",
                "alternates": {
                    "nces": "062211000052"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "198a4438-6ddb-4585-8bdd-8cd39f580635",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Leo R. Croce Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Leo R. Croce Elementary School",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "leo-r-croce-elementary-school-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 601
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Very good school! Teachers care about the students education and help provide a safe and fun environment. They provide lots of programs for kids to help them succeed.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-13T18:40:31.238146Z",
                "guid": "4a428309-5080-4375-9ccc-763a736c49a4",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.728,37.7184,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.728,37.7184,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94551"
            }
        ]
    },
    {
        "guid": "dd1bef72-5e01-4310-b587-77e732511f7a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 431,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.565613,
                "lon": -122.296983
            },
            "entity": {
                "abbreviation": "Bayside STEM Academy",
                "alternates": {
                    "nces": "063492014143"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "dd1bef72-5e01-4310-b587-77e732511f7a",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Bayside Academy",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "2ab8d452-5509-4d81-a858-86685b2bc951"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Bayside STEM Academy",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "bayside-academy-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 924
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Collaboration with Harvard University to design the new STEM program has built a solid foundation and greatly excelled student performance. Bayside STEM also won the distinguished California School Board Association (CSBA) Golden Bell Award for 2014, which recognizes exemplary programs that support teaching and learning. A $200,000 grant from Gilead Sciences is going towards building a STEM-based science exploration area. Keep up the great work, and keep those awards/recognitions coming!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-10-10T18:05:58.915125Z",
                "guid": "21ac5c8b-c470-4998-bd2b-da4983e43e13",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.296,37.5663,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.296,37.5663,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94403"
            }
        ]
    },
    {
        "guid": "1eb20ba9-f05f-4514-a905-78403bc66f9a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 432,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.404008,
                "lon": -121.963664
            },
            "entity": {
                "abbreviation": "Kathryn Hughes Elementary School",
                "alternates": {
                    "nces": "063543006041"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "1eb20ba9-f05f-4514-a905-78403bc66f9a",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Kathryn Hughes Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "5e59747f-8fae-4054-a7a1-457bbce26323"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Kathryn Hughes Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "kathryn-hughes-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 407
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 41
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.963,37.4059,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.963,37.4059,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95054"
            }
        ]
    },
    {
        "guid": "155e691f-8990-4eef-8419-29a4ec806f93",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 433,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.108649,
                "lon": -122.613062
            },
            "entity": {
                "abbreviation": "Pleasant Valley Elementary School",
                "alternates": {
                    "nces": "062772004193"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "155e691f-8990-4eef-8419-29a4ec806f93",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Pleasant Valley Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "7cf6b53c-a89f-46d8-a63f-d6f897c0dd65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Pleasant Valley Elementary School",
                "tagline": [
                    "Novato Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "pleasant-valley-elementary-school-novato-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 446
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.614,38.1087,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.614,38.1087,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94947"
            }
        ]
    },
    {
        "guid": "752b4ee2-6eb6-4eff-8951-0b26bc1ff15f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 434,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.321319,
                "lon": -121.886703
            },
            "entity": {
                "abbreviation": "Rocketship Mateo Sheedy Elementary School",
                "alternates": {
                    "nces": "060178211864"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "752b4ee2-6eb6-4eff-8951-0b26bc1ff15f",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Rocketship Mateo Sheedy Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                    "schoolNetwork": "159c1d35-78c2-4e2f-8837-1d24cf94af66",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "e055b5f7-d1e6-491d-9e73-e54d691a3326"
                },
                "premium": false,
                "shortName": "Rocketship Mateo Sheedy Elementary School",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rocketship-mateo-sheedy-elementary-school-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 554
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 33
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.887,37.3217,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.887,37.3217,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95110"
            }
        ]
    },
    {
        "guid": "65837342-0455-4c4e-898b-263d8a6b691d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 435,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.983754,
                "lon": -122.088355
            },
            "entity": {
                "abbreviation": "Hidden Valley Elementary School",
                "alternates": {
                    "nces": "062637003950"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "65837342-0455-4c4e-898b-263d8a6b691d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Martinez, CA",
                "name": "Hidden Valley Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                    "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Hidden Valley Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "hidden-valley-elementary-school-martinez-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 835
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.089,37.9824,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.089,37.9824,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94553"
            }
        ]
    },
    {
        "guid": "26366317-53c9-46e8-8a79-5d737967506e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 436,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.811532,
                "lon": -122.22771
            },
            "entity": {
                "abbreviation": "Crocker Highlands Elementary School",
                "alternates": {
                    "nces": "062805004253"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "26366317-53c9-46e8-8a79-5d737967506e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Crocker Highlands Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "151103a6-ab35-4483-a39b-6a34c254b9bd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Crocker Highlands Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "crocker-highlands-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 466
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Wonderful school with amazing teachers and an infinite amount of resources.  However, over the years, the school has lost the wonderful cultural exchange that came from its diversity.  Many students at Crocker are now very privileged, entitled, and white.  The school finds itself now at the will of the crazy parents who are at one another's necks to ensure that their child gains more advantages.  Crazy parent dynamic, but amazing classroom dynamic.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-08-08T02:26:54.939973Z",
                "guid": "36699086-4094-4152-8c27-861fabcd16c2",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.228,37.8111,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.228,37.8111,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94610"
            }
        ]
    },
    {
        "guid": "284977e6-a0d7-4090-a95d-1b4e5f17344b",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 437,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.779598,
                "lon": -122.28794
            },
            "entity": {
                "abbreviation": "Nea",
                "alternates": {
                    "ceeb": "054529",
                    "nces": "060188712399"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "284977e6-a0d7-4090-a95d-1b4e5f17344b",
                "isClaimed": true,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Nea Community Learning Center",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "premium": false,
                "shortName": "Nea",
                "tagline": [
                    "Public School",
                    "Alameda, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "nea-community-learning-center-alameda-ca",
                "variation": 56
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 579
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "Nea Community Learning Center, is a good school. I love this school and it is diverse. It does not matter where you come from, the school's main goal is to ensure that you feel welcome. I have been in this school for five years and I have meet new friends and teachers. There are no hate crimes that happen in the school. Although it is not a big school as everyone's dream high school is, I can guarantee you that it is the best school you could dream off. There are no AP classes or sports but the school gives you an opportunity to branch out to colleges to complete the requirements.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2021-02-23T23:35:17.527134Z",
                "guid": "8a173f1d-ac7e-4f50-9194-7061df9b2c94",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.287,37.7792,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.287,37.7792,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.75,
                "count": 40
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "56896d34-4bf4-4e14-9ec9-3555b8aaa91a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 438,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.794379,
                "lon": -122.418965
            },
            "entity": {
                "abbreviation": "Spring Valley Elementary School",
                "alternates": {
                    "nces": "063441005670"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "56896d34-4bf4-4e14-9ec9-3555b8aaa91a",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Spring Valley Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "ce1de80c-3bf7-4c9b-a728-454ef6ec3f34",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a2266d23-e1e6-4459-a631-2eeb3e801498"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Spring Valley Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "spring-valley-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 327
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 19
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.419,37.7941,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.419,37.7941,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94109"
            }
        ]
    },
    {
        "guid": "709fcd7d-dba1-4fa2-8bd1-0228abfcb444",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 439,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.2918896,
                "lon": -121.8332644
            },
            "entity": {
                "abbreviation": "Rocketship Franklin McKinley",
                "alternates": {
                    "nces": "060172913979"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "709fcd7d-dba1-4fa2-8bd1-0228abfcb444",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Rocketship Rising Stars",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8db627d4-9ef7-4051-b4f7-cc15a0037356",
                    "schoolNetwork": "159c1d35-78c2-4e2f-8837-1d24cf94af66",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "22009871-64c9-41cb-9a56-3fd200b76810"
                },
                "premium": false,
                "shortName": "Rocketship Franklin McKinley",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rocketship-rising-stars-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 631
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 37
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8332644,37.2918896,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.8332644,37.2918896,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95111"
            }
        ]
    },
    {
        "guid": "c42561bf-ce6d-4b6a-a904-81003bb29b40",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 440,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.093703,
                "lon": -122.570927
            },
            "entity": {
                "abbreviation": "Rancho Elementary School",
                "alternates": {
                    "nces": "062772004194"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c42561bf-ce6d-4b6a-a904-81003bb29b40",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Rancho Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "7cf6b53c-a89f-46d8-a63f-d6f897c0dd65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Rancho Elementary School",
                "tagline": [
                    "Novato Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rancho-elementary-school-novato-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 331
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "An incredible elementary experience with top notch educators who care for your child’s education. Kids learn not only a well rounded curriculum they gain self control and are motivated daily. We love Rancho and love that our kids were educated here.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-24T05:22:28.283086Z",
                "guid": "7cab2e3d-47ea-4fd6-bc72-6e0b966668f7",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.571,38.0954,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.571,38.0954,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 3.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94947"
            }
        ]
    },
    {
        "guid": "8660e448-e007-4181-9d47-2ad8279828c5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 441,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.538674,
                "lon": -122.31683
            },
            "entity": {
                "abbreviation": "Meadow Heights Elementary School",
                "alternates": {
                    "nces": "063492005913"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "8660e448-e007-4181-9d47-2ad8279828c5",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Meadow Heights Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "2ab8d452-5509-4d81-a858-86685b2bc951"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Meadow Heights Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "meadow-heights-elementary-school-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 339
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.316,37.5392,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.316,37.5392,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94403"
            }
        ]
    },
    {
        "guid": "393ad687-b9ba-4b4b-a736-c614703b6d98",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 442,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.602312,
                "lon": -122.015637
            },
            "entity": {
                "abbreviation": "Guy Jr. Emanuele Elementary School",
                "alternates": {
                    "nces": "062691007481"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "393ad687-b9ba-4b4b-a736-c614703b6d98",
                "isClaimed": true,
                "isPremium": false,
                "location": "Union City, CA",
                "name": "Guy Jr. Emanuele Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "schoolDistrict": "305b3896-e539-4976-95d2-a90396c3f1cf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                    "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "New Haven Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "305b3896-e539-4976-95d2-a90396c3f1cf",
                        "location": "Union City, CA",
                        "name": "New Haven Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a1a37120-c207-41c6-9193-6d1b754eb2ec",
                            "zipCode": "f865aa9f-3f3c-41d7-80c3-1df06f3db370"
                        },
                        "premium": false,
                        "shortName": "New Haven Unified School District",
                        "tagline": [
                            "School District",
                            "Union City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "new-haven-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Guy Jr. Emanuele Elementary School",
                "tagline": [
                    "New Haven Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "guy-jr-emanuele-elementary-school-union-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 569
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.016,37.6005,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.016,37.6005,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94587"
            }
        ]
    },
    {
        "guid": "35abb458-abdf-4b6e-9e05-84b7650f8919",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 443,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.369983,
                "lon": -122.023896
            },
            "entity": {
                "abbreviation": "Ellis Elementary School",
                "alternates": {
                    "nces": "063846006460"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "35abb458-abdf-4b6e-9e05-84b7650f8919",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Ellis Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "schoolDistrict": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Sunnyvale School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                        "location": "Sunnyvale, CA",
                        "name": "Sunnyvale School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                        },
                        "premium": false,
                        "shortName": "Sunnyvale School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "sunnyvale-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ellis Elementary School",
                "tagline": [
                    "Sunnyvale School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ellis-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 787
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3.33
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.024,37.3692,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.024,37.3692,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94086"
            }
        ]
    },
    {
        "guid": "4b3bc1ec-7e7d-4b23-aa92-92e7ee8cb635",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 444,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.528998,
                "lon": -122.01511
            },
            "entity": {
                "abbreviation": "James L. Bunker Elementary School",
                "alternates": {
                    "nces": "062706004079"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "4b3bc1ec-7e7d-4b23-aa92-92e7ee8cb635",
                "isClaimed": false,
                "isPremium": false,
                "location": "Newark, CA",
                "name": "Birch Grove Primary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                    "schoolDistrict": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                    "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Newark Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                        "location": "Newark, CA",
                        "name": "Newark Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                            "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                        },
                        "premium": false,
                        "shortName": "Newark Unified School District",
                        "tagline": [
                            "School District",
                            "Newark, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "newark-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "James L. Bunker Elementary School",
                "tagline": [
                    "Newark Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "birch-grove-primary-school-newark-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 418
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 32
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.016,37.5294,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.016,37.5294,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94560"
            }
        ]
    },
    {
        "guid": "724516bf-9b8a-48cb-89c4-101dda57c822",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 445,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.302671,
                "lon": -121.780222
            },
            "entity": {
                "abbreviation": "Laurelwood Elementary School",
                "alternates": {
                    "nces": "061314001497"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "724516bf-9b8a-48cb-89c4-101dda57c822",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Laurelwood Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "d45dfd8d-1c4a-4d14-adf5-73af66adef2a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Laurelwood Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "laurelwood-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 316
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.779,37.3021,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.779,37.3021,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95121"
            }
        ]
    },
    {
        "guid": "e6f15682-0019-4408-87b3-870a0cb15f68",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 446,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.94391,
                "lon": -121.954912
            },
            "entity": {
                "abbreviation": "Highlands Elementary School",
                "alternates": {
                    "nces": "062637003951"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "e6f15682-0019-4408-87b3-870a0cb15f68",
                "isClaimed": false,
                "isPremium": false,
                "location": "Concord, CA",
                "name": "Highlands Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "187f89d7-6963-49c2-b924-426631dae88f",
                    "zipCode": "64ec8361-307f-4c60-8518-5e08e7cc3cb0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Highlands Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "highlands-elementary-school-concord-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 542
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.957,37.9422,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.957,37.9422,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94521"
            }
        ]
    },
    {
        "guid": "6c2c1ca5-bf79-4c60-8a04-fd383222272f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 447,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.261787,
                "lon": -121.826379
            },
            "entity": {
                "abbreviation": "Hayes Elementary School",
                "alternates": {
                    "nces": "062781004215"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6c2c1ca5-bf79-4c60-8a04-fd383222272f",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Hayes Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "bcda0e04-4247-440c-ae7f-42454876c867"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Hayes Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "hayes-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 592
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.827,37.2625,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.827,37.2625,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95136"
            }
        ]
    },
    {
        "guid": "5da3b1e9-f4f0-48fc-b242-94c16cdf5523",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 448,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.929293,
                "lon": -122.358993
            },
            "entity": {
                "abbreviation": "Richmond College Preparatory",
                "alternates": {
                    "nces": "060172211576"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "5da3b1e9-f4f0-48fc-b242-94c16cdf5523",
                "isClaimed": false,
                "isPremium": false,
                "location": "Richmond, CA",
                "name": "Richmond College Preparatory",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                    "zipCode": "ce8bebad-32ac-45ac-bd87-076119b2c0fa"
                },
                "premium": false,
                "shortName": "Richmond College Preparatory",
                "tagline": [
                    "Public School",
                    "Richmond, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "richmond-college-preparatory-richmond-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 542
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 29
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.359,37.9292,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.359,37.9292,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94804"
            }
        ]
    },
    {
        "guid": "02a60ff8-454e-4316-b87b-e2cf63e4cc5a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 449,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.927991,
                "lon": -122.299286
            },
            "entity": {
                "abbreviation": "Madera Elementary School",
                "alternates": {
                    "nces": "063255005043"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "02a60ff8-454e-4316-b87b-e2cf63e4cc5a",
                "isClaimed": true,
                "isPremium": false,
                "location": "El Cerrito, CA",
                "name": "Madera Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "schoolDistrict": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "f343dc53-cb24-4991-838e-fd06781600a8",
                    "zipCode": "5c0d439b-7e68-4d4e-ac7b-5c3008345353"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "WCCUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a053a637-da7b-48d7-a67d-fba014c2d452",
                        "location": "Richmond, CA",
                        "name": "West Contra Costa Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                            "zipCode": "9e563e34-37ab-4fc4-b373-ed793d877060"
                        },
                        "premium": false,
                        "shortName": "West Contra Costa Unified School District",
                        "tagline": [
                            "School District",
                            "Richmond, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "west-contra-costa-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Madera Elementary School",
                "tagline": [
                    "West Contra Costa Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "madera-elementary-school-el-cerrito-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 474
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Mostly great teachers, better academics than many of the private schools around the area, and a loving and supportive environment for the kids.  The front office could be better but otherwise the administration is pretty good.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-04-17T15:15:05.45394Z",
                "guid": "1a37b063-b798-4835-96bb-854427eb9bd4",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.298,37.928,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.298,37.928,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94530"
            }
        ]
    },
    {
        "guid": "81672b3a-f747-417b-a7a3-b06f5b355799",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 450,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.706718,
                "lon": -122.437238
            },
            "entity": {
                "abbreviation": "Panorama Elementary School",
                "alternates": {
                    "nces": "060606000543"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "81672b3a-f747-417b-a7a3-b06f5b355799",
                "isClaimed": false,
                "isPremium": false,
                "location": "Daly City, CA",
                "name": "Panorama Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f1111177-2a01-4f9f-ab49-2387b39175bf",
                    "schoolDistrict": "f1111177-2a01-4f9f-ab49-2387b39175bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "41a99124-6b7c-496d-9635-39a33c683883",
                    "zipCode": "73a39e4a-bcc0-4688-99ae-a5dc425c236e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brisbane Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f1111177-2a01-4f9f-ab49-2387b39175bf",
                        "location": "Brisbane, CA",
                        "name": "Brisbane Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "9bc6d672-7a23-47c9-8ec7-b0899a2d1a74",
                            "zipCode": "a3b9a792-eb0b-4455-bc46-9ccde2b7c60e"
                        },
                        "premium": false,
                        "shortName": "Brisbane Elementary School District",
                        "tagline": [
                            "School District",
                            "Brisbane, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brisbane-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Panorama Elementary School",
                "tagline": [
                    "Brisbane Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "panorama-elementary-school-daly-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 136
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "I am very happy with Panorama School!   It feels like my child is in a private school as the philosophy of education seems very progressive.  Panorama is a caring school community.    The principal and teachers are positive and welcoming.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2015-01-20T19:31:09Z",
                "guid": "296f7b46-c49d-499c-8de8-b438cbf5d040",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.439,37.7067,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.439,37.7067,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.222222222222222,
                "count": 9
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94014"
            }
        ]
    },
    {
        "guid": "25dc7565-fad1-44f0-aa4c-03f39e423461",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 451,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.736518,
                "lon": -122.146881
            },
            "entity": {
                "abbreviation": "Roosevelt Elementary School",
                "alternates": {
                    "nces": "063468005841"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "25dc7565-fad1-44f0-aa4c-03f39e423461",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Leandro, CA",
                "name": "Roosevelt Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "d746ca26-c18b-483f-90c2-1e6ed64ff40e",
                    "parent": "703b106b-ed91-4085-98d4-5867e5f04ecb",
                    "schoolDistrict": "703b106b-ed91-4085-98d4-5867e5f04ecb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "97147736-91ca-4fcf-8a1e-c9419d2d2593",
                    "zipCode": "0418db05-538d-429e-a8b8-8b6441797a1d"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Leandro Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "703b106b-ed91-4085-98d4-5867e5f04ecb",
                        "location": "San Leandro, CA",
                        "name": "San Leandro Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a2d1b6e7-6b04-4cd6-a4bf-7eadb1067cb7",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "97147736-91ca-4fcf-8a1e-c9419d2d2593",
                            "zipCode": "0418db05-538d-429e-a8b8-8b6441797a1d"
                        },
                        "premium": false,
                        "shortName": "San Leandro Unified School District",
                        "tagline": [
                            "School District",
                            "San Leandro, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-leandro-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Roosevelt Elementary School",
                "tagline": [
                    "San Leandro Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "roosevelt-elementary-school-san-leandro-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 541
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.147,37.7358,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.147,37.7358,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94577"
            }
        ]
    },
    {
        "guid": "300c2b38-6f78-44ce-abf1-baa8d13b4824",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 452,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.105,
                "lon": -122.585857
            },
            "entity": {
                "abbreviation": "Lu Sutton Elementary School",
                "alternates": {
                    "nces": "062772004188"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "300c2b38-6f78-44ce-abf1-baa8d13b4824",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Lu Sutton Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "7cf6b53c-a89f-46d8-a63f-d6f897c0dd65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lu Sutton Elementary School",
                "tagline": [
                    "Novato Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lu-sutton-elementary-school-novato-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 375
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.585,38.1055,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.585,38.1055,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94947"
            }
        ]
    },
    {
        "guid": "58f39368-0896-49d6-bc36-144f55bf068e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 453,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.223553,
                "lon": -121.784074
            },
            "entity": {
                "abbreviation": "Baldwin (Julia) Elementary School",
                "alternates": {
                    "nces": "062781004204"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "58f39368-0896-49d6-bc36-144f55bf068e",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Julia Baldwin Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Baldwin (Julia) Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "julia-baldwin-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 485
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.783,37.2237,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.783,37.2237,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95119"
            }
        ]
    },
    {
        "guid": "d9b29a21-23b4-43b9-9c29-8111992d92e0",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 454,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.53232,
                "lon": -122.012526
            },
            "entity": {
                "abbreviation": "Almond Grove School",
                "alternates": {
                    "nces": "062808013786"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d9b29a21-23b4-43b9-9c29-8111992d92e0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakley, CA",
                "name": "Almond Grove Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "2464769a-1f69-41a3-b882-e208bbd5a2e1",
                    "schoolDistrict": "2464769a-1f69-41a3-b882-e208bbd5a2e1",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                    "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oakley Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "2464769a-1f69-41a3-b882-e208bbd5a2e1",
                        "location": "Oakley, CA",
                        "name": "Oakley Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6ee6365f-3e69-4aad-a8df-5cd72ae38991",
                            "zipCode": "4e62bbb5-5683-41cb-a3ca-4f5f14325035"
                        },
                        "premium": false,
                        "shortName": "Oakley Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Oakley, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakley-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Almond Grove School",
                "tagline": [
                    "Oakley Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "almond-grove-elementary-school-oakley-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 514
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.725,37.9754,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.725,37.9754,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94561"
            }
        ]
    },
    {
        "guid": "65716c45-0fdd-4b9c-9ed7-d0e054f63ea4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 455,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.247532,
                "lon": -121.83682
            },
            "entity": {
                "abbreviation": "Frost (Earl) Elementary School",
                "alternates": {
                    "nces": "062781004213"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "65716c45-0fdd-4b9c-9ed7-d0e054f63ea4",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Earl Frost Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Frost (Earl) Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "earl-frost-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 638
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.836,37.2465,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.836,37.2465,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95123"
            }
        ]
    },
    {
        "guid": "95af8d7d-e2a4-4276-8d20-17ca5c3e88da",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 456,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.355234,
                "lon": -121.871213
            },
            "entity": {
                "abbreviation": "Rocketship Discovery Prep",
                "alternates": {
                    "nces": "060162112668"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "95af8d7d-e2a4-4276-8d20-17ca5c3e88da",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Rocketship Discovery Prep",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                    "schoolNetwork": "159c1d35-78c2-4e2f-8837-1d24cf94af66",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "953f02c6-35fb-44cc-a96d-584c48aceaab"
                },
                "premium": false,
                "shortName": "Rocketship Discovery Prep",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rocketship-discovery-prep-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 524
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 33
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.87,37.3548,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.87,37.3548,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95116"
            }
        ]
    },
    {
        "guid": "70d89edf-cef6-4ed5-96ae-331b2aeb55d5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 457,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.646584,
                "lon": -122.433661
            },
            "entity": {
                "abbreviation": "Ponderosa Elementary School",
                "alternates": {
                    "nces": "063753006349"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "70d89edf-cef6-4ed5-96ae-331b2aeb55d5",
                "isClaimed": false,
                "isPremium": false,
                "location": "South San Francisco, CA",
                "name": "Ponderosa Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "schoolDistrict": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                    "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "South San Francisco Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "41a0969a-227f-49e4-989c-cce558a6963c",
                        "location": "South San Francisco, CA",
                        "name": "South San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                            "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                        },
                        "premium": false,
                        "shortName": "South San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "South San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "south-san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Ponderosa Elementary School",
                "tagline": [
                    "South San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ponderosa-elementary-school-south-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 411
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.433,37.6462,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.433,37.6462,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94080"
            }
        ]
    },
    {
        "guid": "07f7870d-5936-4975-9feb-ce8e83da4d83",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 458,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.797419,
                "lon": -122.410264
            },
            "entity": {
                "abbreviation": "Jean Parker Elementary",
                "alternates": {
                    "nces": "063441005630"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "07f7870d-5936-4975-9feb-ce8e83da4d83",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Jean Parker Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "963a82de-5229-4167-b80d-0b231bb94570",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a9ba46dd-c464-469c-b61f-0bb664188fda"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Jean Parker Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "jean-parker-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 227
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.411,37.7977,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.411,37.7977,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.2857142857142856,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94133"
            }
        ]
    },
    {
        "guid": "48f49857-ffee-4e5f-8030-21eb19a5a913",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 459,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.912472,
                "lon": -122.278335
            },
            "entity": {
                "abbreviation": "Kensington Elementary School",
                "alternates": {
                    "nces": "063255005038"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "48f49857-ffee-4e5f-8030-21eb19a5a913",
                "isClaimed": true,
                "isPremium": false,
                "location": "Kensington, CA",
                "name": "Kensington Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "schoolDistrict": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "8392c887-13fb-4f30-9d73-bdc3c71cefab",
                    "zipCode": "0ce57911-0bf8-45b6-a9aa-72972eb07e24"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "WCCUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a053a637-da7b-48d7-a67d-fba014c2d452",
                        "location": "Richmond, CA",
                        "name": "West Contra Costa Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                            "zipCode": "9e563e34-37ab-4fc4-b373-ed793d877060"
                        },
                        "premium": false,
                        "shortName": "West Contra Costa Unified School District",
                        "tagline": [
                            "School District",
                            "Richmond, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "west-contra-costa-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Kensington Elementary School",
                "tagline": [
                    "West Contra Costa Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "kensington-elementary-school-kensington-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 475
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Good school, I had a good experience here. The administration was helpful and the teachers were pretty good. This elementary school is one of the best in the area. School lunch is terrible but not the school's fault since it is the district's food. Kids are driven to learn creatively and teachers have control over the classrooms.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-01-18T09:17:37.59962Z",
                "guid": "ad5d7f32-0e77-48ab-a040-6dcc7231140d",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.279,37.9143,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.279,37.9143,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94708"
            }
        ]
    },
    {
        "guid": "c88ea40e-3544-4ee8-b010-d5a0ee639853",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 460,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.5359,
                "lon": -122.052
            },
            "entity": {
                "abbreviation": "Lincoln Elementary School",
                "alternates": {
                    "nces": "062706004083"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c88ea40e-3544-4ee8-b010-d5a0ee639853",
                "isClaimed": false,
                "isPremium": false,
                "location": "Newark, CA",
                "name": "Lincoln Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                    "schoolDistrict": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                    "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Newark Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                        "location": "Newark, CA",
                        "name": "Newark Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                            "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                        },
                        "premium": false,
                        "shortName": "Newark Unified School District",
                        "tagline": [
                            "School District",
                            "Newark, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "newark-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lincoln Elementary School",
                "tagline": [
                    "Newark Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "lincoln-elementary-school-newark-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 401
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 31
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.052,37.5359,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.052,37.5359,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94560"
            }
        ]
    },
    {
        "guid": "897ebb61-35d1-4bc5-a212-38c297449001",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 461,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.695759,
                "lon": -122.160362
            },
            "entity": {
                "abbreviation": "Madison Elementary School",
                "alternates": {
                    "nces": "063468007482"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "897ebb61-35d1-4bc5-a212-38c297449001",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Leandro, CA",
                "name": "Madison Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "fccafdc2-7336-4cd2-8bcf-d88b5d730132",
                    "parent": "703b106b-ed91-4085-98d4-5867e5f04ecb",
                    "schoolDistrict": "703b106b-ed91-4085-98d4-5867e5f04ecb",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "97147736-91ca-4fcf-8a1e-c9419d2d2593",
                    "zipCode": "2f1b031c-d987-4e47-a043-a587b9a42bee"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Leandro Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "703b106b-ed91-4085-98d4-5867e5f04ecb",
                        "location": "San Leandro, CA",
                        "name": "San Leandro Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a2d1b6e7-6b04-4cd6-a4bf-7eadb1067cb7",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "97147736-91ca-4fcf-8a1e-c9419d2d2593",
                            "zipCode": "0418db05-538d-429e-a8b8-8b6441797a1d"
                        },
                        "premium": false,
                        "shortName": "San Leandro Unified School District",
                        "tagline": [
                            "School District",
                            "San Leandro, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-leandro-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Madison Elementary School",
                "tagline": [
                    "San Leandro Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "madison-elementary-school-san-leandro-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 479
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.16,37.6954,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.16,37.6954,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94579"
            }
        ]
    },
    {
        "guid": "083fded5-d341-4c97-bf2d-c7f6926de47a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 462,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.387752,
                "lon": -121.947824
            },
            "entity": {
                "abbreviation": "Montague Elementary School",
                "alternates": {
                    "nces": "063543006045"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "083fded5-d341-4c97-bf2d-c7f6926de47a",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Montague Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "5e59747f-8fae-4054-a7a1-457bbce26323"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Montague Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "montague-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 426
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.946,37.3894,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.946,37.3894,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95054"
            }
        ]
    },
    {
        "guid": "0052e113-aa58-4b44-a7b8-df6849724dde",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 463,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.270699,
                "lon": -121.85516
            },
            "entity": {
                "abbreviation": "Rachel Carson Elementary School",
                "alternates": {
                    "nces": "063459005706"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0052e113-aa58-4b44-a7b8-df6849724dde",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Rachel Carson Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "bcda0e04-4247-440c-ae7f-42454876c867"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Rachel Carson Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rachel-carson-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 291
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "featuredReview": {
                "author": "Senior",
                "body": "Looking for a small school in a safe neighborhood? Looking for a supportive staff? Looking for a premium education for all facets of your child's life? Carson is the school you want! This school is my academic foundation. I am starting my senior year and maintaining a 4.0 gpa, all because of Carson.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-09-09T03:06:21.266142Z",
                "guid": "efc91bf1-5fc1-452c-9fb6-41c01a66967b",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.855,37.2697,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.855,37.2697,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95136"
            }
        ]
    },
    {
        "guid": "6c3ac996-2970-449e-8c2f-df06aff85fe7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 464,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.065564,
                "lon": -122.546162
            },
            "entity": {
                "abbreviation": "Loma Verde Elementary School",
                "alternates": {
                    "nces": "062772004187"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "6c3ac996-2970-449e-8c2f-df06aff85fe7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Loma Verde Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Loma Verde Elementary School",
                "tagline": [
                    "Novato Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "loma-verde-elementary-school-novato-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 401
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.548,38.0645,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.548,38.0645,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94949"
            }
        ]
    },
    {
        "guid": "b3d0aebb-8ed8-4ece-bc38-ee379279d34e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 465,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.756185,
                "lon": -122.412331
            },
            "entity": {
                "abbreviation": "George R. Moscone Elementary",
                "alternates": {
                    "nces": "063441005615"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b3d0aebb-8ed8-4ece-bc38-ee379279d34e",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "George R. Moscone Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "0cac67cc-9c02-4a4c-8cd6-5714b7fda8b7",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "c568e3a2-4c27-4503-a9f7-85276a6ec461"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "George R. Moscone Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "george-r-moscone-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 381
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.413,37.7567,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.413,37.7567,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94110"
            }
        ]
    },
    {
        "guid": "d27c098d-b7e3-4242-bd9f-59d08a32cce5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 466,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.312122,
                "lon": -121.899298
            },
            "entity": {
                "abbreviation": "River Glen",
                "alternates": {
                    "nces": "063459004084"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "d27c098d-b7e3-4242-bd9f-59d08a32cce5",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "River Glen",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9bed7cde-76c6-4ed3-9076-f48cade709d3",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "0f5f9ca7-0382-4b2d-8697-36994f2fddf7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "River Glen",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "river-glen-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 520
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I graduated River Glen in 2018 and had gone there for all 9 years, K-8. I loved my time at River Glen. It may have been a little different then many other schools around the areas, however that's what I loved about it. We were a bilingual school that taught kids English and Spanish in an area were that was a bit unusual. At the time I went there, I did not appreciate the gift that it had given me. If I had not gone to this school I would not be able to speak two languages fluently. I owe my greatest skill to this small, strange, amazing, quirky school. I do admit it has had its problems, but what school doesn't. This may not be the school for everyone, it may be just another public school but to me and all my classmates I had, I can say that I would never have chosen to spend my time of education in any other place. Additionally, the community is incredible. My family and I have made life long friends that have become family. River Glen was my home and I will cherish it forever.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-06-03T16:23:45.105821Z",
                "guid": "e36294c8-0b37-46fd-9e9a-3bf7c70445f9",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.902,37.3107,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.902,37.3107,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95125"
            }
        ]
    },
    {
        "guid": "96c53c6e-efad-4d7a-99f3-ab9293bd7ba6",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 467,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.651563,
                "lon": -122.466593
            },
            "entity": {
                "abbreviation": "Skyline Elementary School",
                "alternates": {
                    "nces": "063753006351"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "96c53c6e-efad-4d7a-99f3-ab9293bd7ba6",
                "isClaimed": false,
                "isPremium": false,
                "location": "Daly City, CA",
                "name": "Skyline Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "schoolDistrict": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "41a99124-6b7c-496d-9635-39a33c683883",
                    "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "South San Francisco Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "41a0969a-227f-49e4-989c-cce558a6963c",
                        "location": "South San Francisco, CA",
                        "name": "South San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                            "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                        },
                        "premium": false,
                        "shortName": "South San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "South San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "south-san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Skyline Elementary School",
                "tagline": [
                    "South San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "skyline-elementary-school-daly-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 402
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.465,37.6517,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.465,37.6517,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94015"
            }
        ]
    },
    {
        "guid": "9034fdc1-29bc-4256-86cc-6b707308c8b1",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 468,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.941409,
                "lon": -122.017781
            },
            "entity": {
                "abbreviation": "Woodside Elementary School",
                "alternates": {
                    "nces": "062637003983"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "9034fdc1-29bc-4256-86cc-6b707308c8b1",
                "isClaimed": false,
                "isPremium": false,
                "location": "Concord, CA",
                "name": "Woodside Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "187f89d7-6963-49c2-b924-426631dae88f",
                    "zipCode": "6b58b2cc-6f28-4ab5-9cae-5d07eb3c2450"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Woodside Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "woodside-elementary-school-concord-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 354
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.019,37.9397,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.019,37.9397,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94518"
            }
        ]
    },
    {
        "guid": "24e37482-8f1b-4356-aca8-59e48b6c27d5",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 469,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.294916,
                "lon": -121.812664
            },
            "entity": {
                "abbreviation": "Ramblewood Elementary School",
                "alternates": {
                    "nces": "061437011452"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "24e37482-8f1b-4356-aca8-59e48b6c27d5",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Ramblewood Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "37987e01-5343-4216-b264-0535f5366805",
                    "schoolDistrict": "37987e01-5343-4216-b264-0535f5366805",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "d45dfd8d-1c4a-4d14-adf5-73af66adef2a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Franklin-McKinley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "37987e01-5343-4216-b264-0535f5366805",
                        "location": "San Jose, CA",
                        "name": "Franklin-McKinley Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                        },
                        "premium": false,
                        "shortName": "Franklin-McKinley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "franklin-mckinley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Ramblewood Elementary School",
                "tagline": [
                    "Franklin-McKinley Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "ramblewood-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 351
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.812,37.2961,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.812,37.2961,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95121"
            }
        ]
    },
    {
        "guid": "82433d79-b592-4f99-ab9b-a81e9f48a8a4",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 470,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.661005,
                "lon": -122.469685
            },
            "entity": {
                "abbreviation": "Junipero Serra Elementary School",
                "alternates": {
                    "nces": "063753006345"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "82433d79-b592-4f99-ab9b-a81e9f48a8a4",
                "isClaimed": false,
                "isPremium": false,
                "location": "Daly City, CA",
                "name": "Junipero Serra Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "schoolDistrict": "41a0969a-227f-49e4-989c-cce558a6963c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "41a99124-6b7c-496d-9635-39a33c683883",
                    "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "South San Francisco Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "41a0969a-227f-49e4-989c-cce558a6963c",
                        "location": "South San Francisco, CA",
                        "name": "South San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "64832fba-4ed7-403d-a9bb-3c8c5210d64c",
                            "zipCode": "8c664451-0242-437f-a6d9-d577f9b17d9e"
                        },
                        "premium": false,
                        "shortName": "South San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "South San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "south-san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Junipero Serra Elementary School",
                "tagline": [
                    "South San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "junipero-serra-elementary-school-daly-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 314
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.469,37.6605,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.469,37.6605,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94015"
            }
        ]
    },
    {
        "guid": "def2c89e-f2b4-4ee3-9eee-92c924ced539",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 471,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.49998,
                "lon": -122.46457
            },
            "entity": {
                "abbreviation": "El Granada Elementary School",
                "alternates": {
                    "nces": "060678000597"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "def2c89e-f2b4-4ee3-9eee-92c924ced539",
                "isClaimed": false,
                "isPremium": false,
                "location": "Half Moon Bay, CA",
                "name": "El Granada Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                    "schoolDistrict": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "e083ca91-ec8b-4dad-97da-64408f4f37bb",
                    "zipCode": "cfe7994d-8a48-4529-b312-78d0deacfe27"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cabrillo Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                        "location": "Half Moon Bay, CA",
                        "name": "Cabrillo Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6f401739-fe70-4434-8602-b2cdb57c2074",
                            "zipCode": "cfe7994d-8a48-4529-b312-78d0deacfe27"
                        },
                        "premium": false,
                        "shortName": "Cabrillo Unified School District",
                        "tagline": [
                            "School District",
                            "Half Moon Bay, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cabrillo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "El Granada Elementary School",
                "tagline": [
                    "Cabrillo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "el-granada-elementary-school-half-moon-bay-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 409
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.464,37.4995,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.464,37.4995,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94019"
            }
        ]
    },
    {
        "guid": "3b9fc2a4-8a91-4df2-9310-0df1a1244739",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 472,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.687969,
                "lon": -122.069646
            },
            "entity": {
                "abbreviation": "Golden Oak Montessori of Hayward",
                "alternates": {
                    "nces": "060165512426"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "3b9fc2a4-8a91-4df2-9310-0df1a1244739",
                "isClaimed": false,
                "isPremium": false,
                "location": "Castro Valley, CA",
                "name": "Golden Oak Montessori of Hayward",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "3b63d4f5-8c5d-4908-9952-1529e9a9dcee",
                    "zipCode": "ca87fa67-ecae-4bbb-a27b-22324f3483bc"
                },
                "premium": false,
                "shortName": "Golden Oak Montessori of Hayward",
                "tagline": [
                    "Public School",
                    "Castro Valley, CA",
                    "1-8"
                ],
                "type": "School",
                "url": "golden-oak-montessori-of-hayward-castro-valley-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 249
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 249
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "I graduated from here in 2017. The community and staff are great, but since it's Montessori, only the students whom have an interest in doing well, benefit from this kind of teaching. Also, since it is a charter school, we did not have many extracurricular or other non-academic programs.\nThe middle school is 7th and 8th grade. In the small community, I was able to make connections and build strong bonds with my peers and teachers. The middle school is very good at preparing their students for high school. So well, actually, that the students generally have less of a struggle in high school.  Unfortunately, the work load is a lot, which causes a decent amount of stress among the students, particularly when concerning group work. There is a group assignment every week, which gets stressful. Fortunately the teachers and staff are willing to aid the students in developing healthy stress relief habits and developing a strong work ethic.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-15T20:05:39.522233Z",
                "guid": "1866a9ca-43e7-41ba-8b31-554a049ddbba",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.07,37.6878,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.07,37.6878,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94546"
            }
        ]
    },
    {
        "guid": "b1ac917b-c25d-4a10-8cb9-d52433bbf1ce",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 473,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.725367,
                "lon": -122.429912
            },
            "entity": {
                "abbreviation": "Monroe Elementary School",
                "alternates": {
                    "nces": "063441005651"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b1ac917b-c25d-4a10-8cb9-d52433bbf1ce",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Monroe Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "a36158f8-7bdf-419a-9e51-680d947f62d9",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "61413703-2cce-42b9-a718-b28b79a1680f"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Monroe Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "monroe-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 528
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 40
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.431,37.7254,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.431,37.7254,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94112"
            }
        ]
    },
    {
        "guid": "af276081-ffab-4655-aedf-c289e58340ba",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 474,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.818714,
                "lon": -122.201594
            },
            "entity": {
                "abbreviation": "Joaquin Miller Elementary School",
                "alternates": {
                    "nces": "062805004280"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "af276081-ffab-4655-aedf-c289e58340ba",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Joaquin Miller Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "c2e42d78-53d8-4353-a489-378eab873ee1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Joaquin Miller Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "joaquin-miller-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 443
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.202,37.819,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.202,37.819,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94611"
            }
        ]
    },
    {
        "guid": "7f3cae80-1024-4187-a7c3-d7f64969cd77",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 475,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.633077,
                "lon": -122.045059
            },
            "entity": {
                "abbreviation": "Timber Point Elementary School",
                "alternates": {
                    "nces": "060675010475"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7f3cae80-1024-4187-a7c3-d7f64969cd77",
                "isClaimed": false,
                "isPremium": false,
                "location": "Discovery Bay, CA",
                "name": "Timber Point Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "e5a197a9-8dd0-4252-b39b-63f31ae9d3d7",
                    "parent": "77c39175-7d61-4abc-ba26-79ea992e5079",
                    "schoolDistrict": "77c39175-7d61-4abc-ba26-79ea992e5079",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                    "zipCode": "72f14b46-7398-463c-a7b1-84d94dfe3a97"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Byron Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "77c39175-7d61-4abc-ba26-79ea992e5079",
                        "location": "Byron, CA",
                        "name": "Byron Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "3f2c06a3-6dc5-4b8b-ae42-25d409d4ae34",
                            "zipCode": "4c4ae928-08ae-4b39-a92f-d9350bc71e2f"
                        },
                        "premium": false,
                        "shortName": "Byron Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Byron, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "byron-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Timber Point Elementary School",
                "tagline": [
                    "Byron Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "timber-point-elementary-school-discovery-bay-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 488
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.619,37.9074,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.619,37.9074,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94505"
            }
        ]
    },
    {
        "guid": "a1a5d18a-b343-4fd5-8323-ac3f040f7787",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 476,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.31868,
                "lon": -121.797672
            },
            "entity": {
                "abbreviation": "Holly Oak Elementary School",
                "alternates": {
                    "nces": "061314001494"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a1a5d18a-b343-4fd5-8323-ac3f040f7787",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Holly Oak Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Holly Oak Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "holly-oak-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 562
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Had a lot of help from administrators at the school. They were very helpful when I needed help with my daughter. They are very helpful when you need help or a student asks for help.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-28T06:54:01.301Z",
                "guid": "e6afacde-cb0b-4452-a176-426fd5b8f568",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.799,37.3195,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.799,37.3195,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95148"
            }
        ]
    },
    {
        "guid": "3287198c-e45c-4fda-9b27-511b8e78d98d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 477,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.357104,
                "lon": -121.976502
            },
            "entity": {
                "abbreviation": "Bowers Elementary School",
                "alternates": {
                    "nces": "063543006033"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "3287198c-e45c-4fda-9b27-511b8e78d98d",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Bowers Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Bowers Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "bowers-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 282
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.976,37.358,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.976,37.358,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95051"
            }
        ]
    },
    {
        "guid": "42e898c6-8884-4b11-a430-7c2e3f408c32",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 478,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.284462,
                "lon": -121.819654
            },
            "entity": {
                "abbreviation": "Voices College-Bound Language Academy",
                "alternates": {
                    "nces": "060199312031"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "42e898c6-8884-4b11-a430-7c2e3f408c32",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Voices College-Bound Language Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8db627d4-9ef7-4051-b4f7-cc15a0037356",
                    "schoolNetwork": "d277ce97-405c-42a8-ae1e-e8069eea6a1c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "22009871-64c9-41cb-9a56-3fd200b76810"
                },
                "premium": false,
                "shortName": "Voices College-Bound Language Academy",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "voices-college-bound-language-academy-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 481
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.82,37.2845,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.82,37.2845,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95111"
            }
        ]
    },
    {
        "guid": "3960ef56-d14f-4d41-b703-8e6ab763212c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 479,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.246564,
                "lon": -121.811934
            },
            "entity": {
                "abbreviation": "Anderson (Alex) Elementary School",
                "alternates": {
                    "nces": "062781009413"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3960ef56-d14f-4d41-b703-8e6ab763212c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Alex Anderson Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                    "parent": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "schoolDistrict": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Oak Grove Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "62805854-2de1-4e7f-95ef-70aa4cf2b688",
                        "location": "San Jose, CA",
                        "name": "Oak Grove Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "82659f7c-b7d4-4d17-b5fb-080c2a7a859c",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "e49229ab-b2d8-4541-893c-8c022a3b0c57"
                        },
                        "premium": false,
                        "shortName": "Oak Grove Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oak-grove-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Anderson (Alex) Elementary School",
                "tagline": [
                    "Oak Grove Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "alex-anderson-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 514
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.811,37.2472,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.811,37.2472,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95123"
            }
        ]
    },
    {
        "guid": "4ed4de0f-4b8a-4eff-8176-ebabfeaac5cb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 480,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.386913,
                "lon": -122.025296
            },
            "entity": {
                "abbreviation": "Bishop Elementary School",
                "alternates": {
                    "nces": "063846006456"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "4ed4de0f-4b8a-4eff-8176-ebabfeaac5cb",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Bishop Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "schoolDistrict": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "7a81297b-02c5-4674-a68b-e516dd895b65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Sunnyvale School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                        "location": "Sunnyvale, CA",
                        "name": "Sunnyvale School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                        },
                        "premium": false,
                        "shortName": "Sunnyvale School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "sunnyvale-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Bishop Elementary School",
                "tagline": [
                    "Sunnyvale School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "bishop-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 475
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.024,37.3865,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.024,37.3865,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94085"
            }
        ]
    },
    {
        "guid": "b3f59705-150f-403d-8af3-a6b5129f1350",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 481,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.935671,
                "lon": -121.620505
            },
            "entity": {
                "abbreviation": "Old River Elementary School",
                "alternates": {
                    "nces": "062004014006"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b3f59705-150f-403d-8af3-a6b5129f1350",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Old River Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "d3d23169-05fb-43d3-9163-91eb61f9770e",
                    "schoolDistrict": "d3d23169-05fb-43d3-9163-91eb61f9770e",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Knightsen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "d3d23169-05fb-43d3-9163-91eb61f9770e",
                        "location": "Knightsen, CA",
                        "name": "Knightsen Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "dd92bf0e-5067-4c84-a7d4-9d4d873c429b",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Knightsen Elementary School District",
                        "tagline": [
                            "School District",
                            "Knightsen, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "knightsen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Old River Elementary School",
                "tagline": [
                    "Knightsen Elementary School District, CA",
                    "K-7"
                ],
                "type": "School",
                "url": "old-river-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 268
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.620505,37.935671,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.620505,37.935671,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "fc52cf08-72c0-4be2-a91d-829354b64180",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 482,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.639144,
                "lon": -122.094459
            },
            "entity": {
                "abbreviation": "Southgate Elementary School",
                "alternates": {
                    "nces": "061674002132"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "fc52cf08-72c0-4be2-a91d-829354b64180",
                "isClaimed": false,
                "isPremium": false,
                "location": "Hayward, CA",
                "name": "Southgate Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "f12097a1-3ac1-4f2b-b578-9852dbf14c95",
                    "parent": "0cc8e0c8-9c30-4a76-a806-7e6c803dbcba",
                    "schoolDistrict": "0cc8e0c8-9c30-4a76-a806-7e6c803dbcba",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                    "zipCode": "e8c32215-6d14-4b33-82bd-2d41b6b32206"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "HUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "0cc8e0c8-9c30-4a76-a806-7e6c803dbcba",
                        "location": "Hayward, CA",
                        "name": "Hayward Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "3ee6b10e-9c9c-43c9-bee6-1755097895e1",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "846df0d5-65fc-4f5e-95de-2bd95424c1ff",
                            "zipCode": "72f14b46-7398-463c-a7b1-84d94dfe3a97"
                        },
                        "premium": false,
                        "shortName": "Hayward Unified School District",
                        "tagline": [
                            "School District",
                            "Hayward, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "hayward-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Southgate Elementary School",
                "tagline": [
                    "Hayward Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "southgate-elementary-school-hayward-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 677
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.094,37.6383,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.094,37.6383,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94545"
            }
        ]
    },
    {
        "guid": "7cc2cfce-7098-4630-bcab-800e9c857b1c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 483,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.94133,
                "lon": -122.084915
            },
            "entity": {
                "abbreviation": "Gregory Gardens Elementary School",
                "alternates": {
                    "nces": "062637002956"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7cc2cfce-7098-4630-bcab-800e9c857b1c",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pleasant Hill, CA",
                "name": "Gregory Gardens Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "454e8dd6-f26e-4493-a281-1f2250e8bae9",
                    "zipCode": "c02f3379-b7e4-421d-8daf-40132890b3fd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Gregory Gardens Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "gregory-gardens-elementary-school-pleasant-hill-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 415
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great teachers and staff so professional staff. and great teaching method. The teachers are so nice and professional. The principle so professional and kind.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-02-21T00:38:00.342958Z",
                "guid": "8e6f3f49-89d8-4274-9902-bcd8f435c22a",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.068,37.9605,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.068,37.9605,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94523"
            }
        ]
    },
    {
        "guid": "b9a64e27-825b-4764-987e-c1eb8f508540",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 484,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.630143,
                "lon": -122.444558
            },
            "entity": {
                "abbreviation": "Rollingwood Elementary School",
                "alternates": {
                    "nces": "063423005399"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "b9a64e27-825b-4764-987e-c1eb8f508540",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Bruno, CA",
                "name": "Rollingwood Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "af247174-605a-4c45-96da-8e4b1aad03f4",
                    "schoolDistrict": "af247174-605a-4c45-96da-8e4b1aad03f4",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "501fa4d3-c95b-4be7-8f45-a278b79ec201",
                    "zipCode": "2747e85f-3e2c-4bb8-a767-725ae14708a4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Bruno Park Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "af247174-605a-4c45-96da-8e4b1aad03f4",
                        "location": "San Bruno, CA",
                        "name": "San Bruno Park Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "501fa4d3-c95b-4be7-8f45-a278b79ec201",
                            "zipCode": "2747e85f-3e2c-4bb8-a767-725ae14708a4"
                        },
                        "premium": false,
                        "shortName": "San Bruno Park Elementary School District",
                        "tagline": [
                            "School District",
                            "San Bruno, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-bruno-park-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Rollingwood Elementary School",
                "tagline": [
                    "San Bruno Park Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rollingwood-elementary-school-san-bruno-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 262
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.445,37.6313,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.445,37.6313,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94066"
            }
        ]
    },
    {
        "guid": "0957e184-d713-4eab-bfa3-10e8b1b97074",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 485,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.686943,
                "lon": -121.767125
            },
            "entity": {
                "abbreviation": "Junction K-8",
                "alternates": {
                    "nces": "062211002622"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "0957e184-d713-4eab-bfa3-10e8b1b97074",
                "isClaimed": true,
                "isPremium": false,
                "location": "Livermore, CA",
                "name": "Junction K-8",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "schoolDistrict": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                    "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Livermore Valley Joint Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "19cd8fd6-2e3f-4241-bb82-be4e992de8bf",
                        "location": "Livermore, CA",
                        "name": "Livermore Valley Joint Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "fc27d7f3-833b-4d0b-bced-8aa3c3c99758",
                            "zipCode": "c06482ed-5084-4a24-95f9-0ab50e7042b2"
                        },
                        "premium": false,
                        "shortName": "Livermore Valley Joint Unified School District",
                        "tagline": [
                            "School District",
                            "Livermore, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "livermore-valley-joint-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Junction K-8",
                "tagline": [
                    "Livermore Valley Joint Unified School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "junction-k-8-livermore-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 934
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.765,37.6881,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.765,37.6881,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94551"
            }
        ]
    },
    {
        "guid": "a5032681-894c-4e68-8936-b633f9f13b6e",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 486,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.534137,
                "lon": -122.023119
            },
            "entity": {
                "abbreviation": "Louis Milani Elementary School",
                "alternates": {
                    "nces": "062706004086"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a5032681-894c-4e68-8936-b633f9f13b6e",
                "isClaimed": false,
                "isPremium": false,
                "location": "Newark, CA",
                "name": "Birch Grove Intermediate School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                    "schoolDistrict": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                    "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Newark Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                        "location": "Newark, CA",
                        "name": "Newark Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                            "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                        },
                        "premium": false,
                        "shortName": "Newark Unified School District",
                        "tagline": [
                            "School District",
                            "Newark, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "newark-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Louis Milani Elementary School",
                "tagline": [
                    "Newark Unified School District, CA",
                    "3-6"
                ],
                "type": "School",
                "url": "birch-grove-intermediate-school-newark-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 438
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 35
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.022,37.5351,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.022,37.5351,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94560"
            }
        ]
    },
    {
        "guid": "a8ff7d7d-2b37-47ff-a3df-e9cb453ff05a",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 487,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.348482,
                "lon": -121.986815
            },
            "entity": {
                "abbreviation": "Pomeroy Elementary School",
                "alternates": {
                    "nces": "063543006048"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "a8ff7d7d-2b37-47ff-a3df-e9cb453ff05a",
                "isClaimed": true,
                "isPremium": false,
                "location": "Santa Clara, CA",
                "name": "Pomeroy Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "schoolDistrict": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                    "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Santa Clara Unified School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "1e193417-ee2a-496f-942d-2bd1fec225a9",
                        "location": "Santa Clara, CA",
                        "name": "Santa Clara Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "44b1c9d0-0773-4aa7-aaeb-c04dda704fc1",
                            "zipCode": "d1fd4c8f-758c-4371-b58b-7ac5e13d8df8"
                        },
                        "premium": false,
                        "shortName": "Santa Clara Unified School District",
                        "tagline": [
                            "School District",
                            "Santa Clara, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "santa-clara-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Pomeroy Elementary School",
                "tagline": [
                    "Santa Clara Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "pomeroy-elementary-school-santa-clara-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 421
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.987,37.3481,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.987,37.3481,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95051"
            }
        ]
    },
    {
        "guid": "1c0e572b-1ad3-417d-aafd-81d764947bb2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 488,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.490211,
                "lon": -122.233515
            },
            "entity": {
                "abbreviation": "Orion Alternative School",
                "alternates": {
                    "nces": "063213008182"
                },
                "character": "Public, Magnet, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "1c0e572b-1ad3-417d-aafd-81d764947bb2",
                "isClaimed": false,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "Orion Alternative School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "e9b53ea4-d69f-427a-bfce-9b5afaca3303",
                    "parent": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "schoolDistrict": "72537800-d48e-4f66-8b17-a36f53e430ca",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Redwood City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "72537800-d48e-4f66-8b17-a36f53e430ca",
                        "location": "Redwood City, CA",
                        "name": "Redwood City Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0ba341c5-8f8b-4205-97f8-b9f1815c5e4d",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                            "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                        },
                        "premium": false,
                        "shortName": "Redwood City Elementary School District",
                        "tagline": [
                            "School District",
                            "Redwood City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "redwood-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Orion Alternative School",
                "tagline": [
                    "Redwood City Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "orion-alternative-school-redwood-city-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 229
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.234,37.4902,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.234,37.4902,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94063"
            }
        ]
    },
    {
        "guid": "5743fc19-3019-4d06-a972-ca6e5698bcae",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 489,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.478913,
                "lon": -122.206062
            },
            "entity": {
                "abbreviation": "KECP",
                "alternates": {
                    "nces": "060203013874"
                },
                "character": "Public, Charter",
                "claimed": true,
                "displayable": true,
                "genus": "Charter School",
                "guid": "5743fc19-3019-4d06-a972-ca6e5698bcae",
                "isClaimed": true,
                "isPremium": false,
                "location": "Redwood City, CA",
                "name": "KIPP Excelencia Community Preparatory",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "844b235c-05df-4456-8ac0-5f90a4c285b8",
                    "schoolNetwork": "a310ca2b-9585-4429-96b5-bd4924a4c483",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "eb3f69c3-e6db-4239-8979-66e40ebafe12",
                    "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                },
                "premium": false,
                "shortName": "KIPP Excelencia",
                "tagline": [
                    "Public School",
                    "Redwood City, CA",
                    "PK, K-8"
                ],
                "type": "School",
                "url": "kipp-excelencia-community-preparatory-redwood-city-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 711
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.206062,37.478913,13/900x340@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.206062,37.478913,13/400x175@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94063"
            }
        ]
    },
    {
        "guid": "fb50d40b-dd2a-48aa-bf98-12b9d5fa59a0",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 490,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.853489,
                "lon": -122.231926
            },
            "entity": {
                "abbreviation": "Kaiser Elementary School",
                "alternates": {
                    "nces": "062805004273"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "fb50d40b-dd2a-48aa-bf98-12b9d5fa59a0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Kaiser Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "f80b92b8-46fc-430c-9898-99e764ec21f3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Kaiser Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "kaiser-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 268
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.231,37.8531,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.231,37.8531,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94618"
            }
        ]
    },
    {
        "guid": "0fe6ae83-17a3-4f44-b9aa-ede4aef522b9",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 491,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.53176,
                "lon": -122.303533
            },
            "entity": {
                "abbreviation": "Laurel Elementary School",
                "alternates": {
                    "nces": "063492005911"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "0fe6ae83-17a3-4f44-b9aa-ede4aef522b9",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Mateo, CA",
                "name": "Laurel Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "schoolDistrict": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2e248a4c-74bf-4d82-8538-a83219eb322b",
                    "zipCode": "2ab8d452-5509-4d81-a858-86685b2bc951"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Mateo-Foster City School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "f5214e55-73e8-40ec-b8bc-fe6cbb44b577",
                        "location": "Foster City, CA",
                        "name": "San Mateo-Foster City School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7e9c2807-1696-49f2-be77-2c5105e0c0ec",
                            "zipCode": "d05a441e-2455-4315-94e9-9f3c45f95809"
                        },
                        "premium": false,
                        "shortName": "San Mateo-Foster City School District",
                        "tagline": [
                            "School District",
                            "Foster City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-mateo-foster-city-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Laurel Elementary School",
                "tagline": [
                    "San Mateo-Foster City School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "laurel-elementary-school-san-mateo-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 525
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.304,37.5319,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.304,37.5319,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94403"
            }
        ]
    },
    {
        "guid": "ed9419e4-0268-460a-b389-deea11334255",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 492,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.7302,
                "lon": -122.486
            },
            "entity": {
                "abbreviation": "Lakeshore Alternative Elementary School",
                "alternates": {
                    "nces": "063441005638"
                },
                "character": "Public, Alternative",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "ed9419e4-0268-460a-b389-deea11334255",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Lakeshore Alternative Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "5efebd04-a1e2-40d5-8e4a-7d27d149ec16",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "0d98457f-33f6-4c34-ba1e-34f6f778475c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lakeshore Alternative Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lakeshore-alternative-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 484
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "featuredReview": {
                "author": "Works Here",
                "body": "Most diverse elementary school in SF! Teachers go above and beyond. Parents and community do lots to augment education of whole child. Administrators and support staff all working to improve all the time. Respect for children as individuals.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-07-21T23:30:38.95799Z",
                "guid": "c1c91a06-b5d2-4972-b3c4-bbeba0da9d7b",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.486,37.7302,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.486,37.7302,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94132"
            }
        ]
    },
    {
        "guid": "01a254e9-d7ec-453b-9212-110f34e0abaa",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 493,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.085804,
                "lon": -122.561364
            },
            "entity": {
                "abbreviation": "Lynwood Elementary School",
                "alternates": {
                    "nces": "062772004189"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "01a254e9-d7ec-453b-9212-110f34e0abaa",
                "isClaimed": false,
                "isPremium": false,
                "location": "Novato, CA",
                "name": "Lynwood Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "schoolDistrict": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                    "zipCode": "7cf6b53c-a89f-46d8-a63f-d6f897c0dd65"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Novato Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b9ce499b-3724-477a-89d8-5d6372cb9484",
                        "location": "Novato, CA",
                        "name": "Novato Unified School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "2122e23f-f26c-4604-9180-d896f31c483d",
                            "zipCode": "bff8a774-e3f4-4cfd-8ffa-8c5dfbf869ab"
                        },
                        "premium": false,
                        "shortName": "Novato Unified School District",
                        "tagline": [
                            "School District",
                            "Novato, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "novato-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Lynwood Elementary School",
                "tagline": [
                    "Novato Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lynwood-elementary-school-novato-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 278
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.562,38.087,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.562,38.087,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94947"
            }
        ]
    },
    {
        "guid": "401e33a6-b1e9-48f1-8dfe-f0fee62896ec",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 494,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.551685,
                "lon": -122.042486
            },
            "entity": {
                "abbreviation": "John F. Kennedy Elementary School",
                "alternates": {
                    "nces": "062706004082"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "401e33a6-b1e9-48f1-8dfe-f0fee62896ec",
                "isClaimed": false,
                "isPremium": false,
                "location": "Newark, CA",
                "name": "John F. Kennedy Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                    "schoolDistrict": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                    "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Newark Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "76fb7aec-f438-42a3-82f2-6ad1f6bf15f5",
                        "location": "Newark, CA",
                        "name": "Newark Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "ed699caa-41e0-4b17-9bf6-cddf0a782bac",
                            "zipCode": "27c6b5a2-f312-47d3-873e-e798e3f00196"
                        },
                        "premium": false,
                        "shortName": "Newark Unified School District",
                        "tagline": [
                            "School District",
                            "Newark, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "newark-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "John F. Kennedy Elementary School",
                "tagline": [
                    "Newark Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "john-f-kennedy-elementary-school-newark-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 404
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 37
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.042,37.5516,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.042,37.5516,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94560"
            }
        ]
    },
    {
        "guid": "84b01c98-9a62-49dc-899e-655e07ff5c02",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 495,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.430444,
                "lon": -121.881113
            },
            "entity": {
                "abbreviation": "Robert Randall Elementary School",
                "alternates": {
                    "nces": "062450003671"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "84b01c98-9a62-49dc-899e-655e07ff5c02",
                "isClaimed": false,
                "isPremium": false,
                "location": "Milpitas, CA",
                "name": "Robert Randall Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "schoolDistrict": "b8ccd5a3-a309-4782-a213-14c30671603f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                    "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Milpitas Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "b8ccd5a3-a309-4782-a213-14c30671603f",
                        "location": "Milpitas, CA",
                        "name": "Milpitas Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "aa75a24b-3877-4c8e-9029-e2332e3f1c46",
                            "zipCode": "d68bb1dd-2a50-4ac9-af5d-a8b78944d236"
                        },
                        "premium": false,
                        "shortName": "Milpitas Unified School District",
                        "tagline": [
                            "School District",
                            "Milpitas, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "milpitas-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Robert Randall Elementary School",
                "tagline": [
                    "Milpitas Unified School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "robert-randall-elementary-school-milpitas-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 348
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 20
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.882,37.4299,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.882,37.4299,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95035"
            }
        ]
    },
    {
        "guid": "881e0d55-fc5e-4fcb-abda-fc31cc49c7f2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 496,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.482808,
                "lon": -122.210395
            },
            "entity": {
                "abbreviation": "Thomas Edison Elementary School",
                "alternates": {
                    "nces": "061887002290"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "881e0d55-fc5e-4fcb-abda-fc31cc49c7f2",
                "isClaimed": false,
                "isPremium": false,
                "location": "Daly City, CA",
                "name": "Thomas Edison Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "844b235c-05df-4456-8ac0-5f90a4c285b8",
                    "parent": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "schoolDistrict": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5ca486a6-52a6-4b83-aeda-db4b437b4d7f",
                    "zipCode": "ddb2570c-2e28-493c-939e-61390b422836"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Jefferson Elementary School District (Daly City)",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a3a94d40-ae93-447d-a94c-7eed6f6c7fe9",
                        "location": "Daly City, CA",
                        "name": "Jefferson Elementary School District (Daly City)",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "41a99124-6b7c-496d-9635-39a33c683883",
                            "zipCode": "6e76147a-b88b-4659-93c4-fe720003e7e7"
                        },
                        "premium": false,
                        "shortName": "Jefferson Elementary School District (Daly City)",
                        "tagline": [
                            "School District",
                            "Daly City, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "jefferson-elementary-school-district-daly-city-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Thomas Edison Elementary School",
                "tagline": [
                    "Jefferson Elementary School District (Daly City), CA",
                    "K-5"
                ],
                "type": "School",
                "url": "thomas-edison-elementary-school-daly-city-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 389
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.485,37.6765,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.485,37.6765,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94015"
            }
        ]
    },
    {
        "guid": "fd3757ba-3b8a-45ad-b78e-1904b35f27bd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 497,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.999353,
                "lon": -122.085242
            },
            "entity": {
                "abbreviation": "Mary Casey Black Elementary School",
                "alternates": {
                    "nces": "060591013420"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "fd3757ba-3b8a-45ad-b78e-1904b35f27bd",
                "isClaimed": false,
                "isPremium": false,
                "location": "Brentwood, CA",
                "name": "Mary Casey Black Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "schoolDistrict": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "5aa2a0dd-3537-4cd9-817a-24409c06ce5d",
                    "zipCode": "2c9a56d6-f5d7-43ae-90db-4fab4497e4a2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Brentwood Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5f43e266-d055-4b35-85bd-5f074c64f85c",
                        "location": "Brentwood, CA",
                        "name": "Brentwood Union Elementary School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "8c697687-c0ec-469c-98e8-e0a06e72a35a",
                            "zipCode": "fea44aef-6d39-4c1c-9267-b5b70ebd1cf5"
                        },
                        "premium": false,
                        "shortName": "Brentwood Union Elementary School District",
                        "tagline": [
                            "School District",
                            "Brentwood, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "brentwood-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Mary Casey Black Elementary School",
                "tagline": [
                    "Brentwood Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "mary-casey-black-elementary-school-brentwood-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 721
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.688,37.948,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.688,37.948,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94513"
            }
        ]
    },
    {
        "guid": "95ff96b8-8a1c-4c4f-b105-af7d5a99ec52",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 498,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.315614,
                "lon": -121.853473
            },
            "entity": {
                "abbreviation": "George Shirakawa Sr. Elementary School",
                "alternates": {
                    "nces": "061437008352"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "95ff96b8-8a1c-4c4f-b105-af7d5a99ec52",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "George Shirakawa Sr. Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                    "parent": "37987e01-5343-4216-b264-0535f5366805",
                    "schoolDistrict": "37987e01-5343-4216-b264-0535f5366805",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Franklin-McKinley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "37987e01-5343-4216-b264-0535f5366805",
                        "location": "San Jose, CA",
                        "name": "Franklin-McKinley Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                        },
                        "premium": false,
                        "shortName": "Franklin-McKinley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "franklin-mckinley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "George Shirakawa Sr. Elementary School",
                "tagline": [
                    "Franklin-McKinley Elementary School District, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "george-shirakawa-sr-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 759
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "It is fine. Not much better than other schools, but still decent enough for children to be enrolled in.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2017-06-06T02:54:22.074114Z",
                "guid": "4777946b-3ca0-4d6a-a8f9-b54e833f5973",
                "rating": 4
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.849,37.3151,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.849,37.3151,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 2.3333333333333335,
                "count": 6
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95112"
            }
        ]
    },
    {
        "guid": "f2d9f3dc-7e99-4fff-912b-cb37209c88a2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 499,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.964379,
                "lon": -121.95606
            },
            "entity": {
                "abbreviation": "Ayers Elementary School",
                "alternates": {
                    "nces": "062637003934"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f2d9f3dc-7e99-4fff-912b-cb37209c88a2",
                "isClaimed": false,
                "isPremium": false,
                "location": "Concord, CA",
                "name": "Ayers Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "187f89d7-6963-49c2-b924-426631dae88f",
                    "zipCode": "64ec8361-307f-4c60-8518-5e08e7cc3cb0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Ayers Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ayers-elementary-school-concord-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 422
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.961,37.9657,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.961,37.9657,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94521"
            }
        ]
    },
    {
        "guid": "888e81fc-24b8-4d2f-b1a7-4a5158ec70ca",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 500,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.804341,
                "lon": -122.244034
            },
            "entity": {
                "abbreviation": "Cleveland Elementary School",
                "alternates": {
                    "nces": "062805004251"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "888e81fc-24b8-4d2f-b1a7-4a5158ec70ca",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Cleveland Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "13143c54-a9b4-4958-8aec-fb1609695702"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Cleveland Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "cleveland-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 404
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.244,37.8037,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.244,37.8037,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94606"
            }
        ]
    },
    {
        "guid": "c302b5ac-4001-4b45-a706-397a85bb33cd",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 501,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.381445,
                "lon": -122.047381
            },
            "entity": {
                "abbreviation": "Vargas Elementary School",
                "alternates": {
                    "nces": "063846002796"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "c302b5ac-4001-4b45-a706-397a85bb33cd",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Vargas Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "schoolDistrict": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Sunnyvale School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                        "location": "Sunnyvale, CA",
                        "name": "Sunnyvale School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                        },
                        "premium": false,
                        "shortName": "Sunnyvale School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "sunnyvale-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Vargas Elementary School",
                "tagline": [
                    "Sunnyvale School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "vargas-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 484
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.048,37.3813,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.048,37.3813,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94086"
            }
        ]
    },
    {
        "guid": "d932156a-9d36-4cd5-8053-2bf034f6f365",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 502,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.781691,
                "lon": -122.286023
            },
            "entity": {
                "abbreviation": "Ruby Bridges Elementary School",
                "alternates": {
                    "nces": "060177011549"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d932156a-9d36-4cd5-8053-2bf034f6f365",
                "isClaimed": false,
                "isPremium": false,
                "location": "Alameda, CA",
                "name": "Ruby Bridges Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "schoolDistrict": "3bfe75ba-c072-458a-a742-96bca1544408",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                    "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "AUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "3bfe75ba-c072-458a-a742-96bca1544408",
                        "location": "Alameda, CA",
                        "name": "Alameda Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "54a953dd-3bdc-47b1-b465-0e4d3705637e",
                            "zipCode": "a2b1830b-98f1-4ebd-83e1-b3e0b4fdffcb"
                        },
                        "premium": false,
                        "shortName": "Alameda City Unified School District",
                        "tagline": [
                            "School District",
                            "Alameda, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alameda-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Ruby Bridges Elementary School",
                "tagline": [
                    "Alameda Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "ruby-bridges-elementary-school-alameda-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 484
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.285,37.7825,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.285,37.7825,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94501"
            }
        ]
    },
    {
        "guid": "dfe1daf8-c41a-49ba-9af1-bce85cabddc2",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 503,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.720051,
                "lon": -122.47611
            },
            "entity": {
                "abbreviation": "Rosa Parks Elementary",
                "alternates": {
                    "nces": "063441005658"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "dfe1daf8-c41a-49ba-9af1-bce85cabddc2",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Rosa Parks Elementary",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "0d98457f-33f6-4c34-ba1e-34f6f778475c"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Rosa Parks Elementary",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rosa-parks-elementary-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 476
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.43,37.7833,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.43,37.7833,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94115"
            }
        ]
    },
    {
        "guid": "594462eb-9168-4e6c-8e83-7878c2cdd5e8",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 504,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.398424,
                "lon": -122.003586
            },
            "entity": {
                "abbreviation": "Lakewood Elementary School",
                "alternates": {
                    "nces": "063846006463"
                },
                "character": "Public",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "594462eb-9168-4e6c-8e83-7878c2cdd5e8",
                "isClaimed": true,
                "isPremium": false,
                "location": "Sunnyvale, CA",
                "name": "Lakewood Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "schoolDistrict": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                    "zipCode": "dfa40734-09f2-436c-874c-ac28a0c26bdd"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Sunnyvale School District",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6181b9b5-96cc-4e35-809a-1fa4db00feef",
                        "location": "Sunnyvale, CA",
                        "name": "Sunnyvale School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "cd0684fb-f68d-419b-8bf5-6500062b6af5",
                            "zipCode": "21c1a4c3-3343-47b1-80ef-d7c0b79a9815"
                        },
                        "premium": false,
                        "shortName": "Sunnyvale School District",
                        "tagline": [
                            "School District",
                            "Sunnyvale, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "sunnyvale-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Lakewood Elementary School",
                "tagline": [
                    "Sunnyvale School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lakewood-elementary-school-sunnyvale-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 437
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.002,37.3976,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.002,37.3976,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94089"
            }
        ]
    },
    {
        "guid": "f0122f67-0d46-4742-a0c9-9af7e2f7c2d7",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 505,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.1117869,
                "lon": -122.2446967
            },
            "entity": {
                "abbreviation": "Caliber: Changemakers Academy",
                "alternates": {
                    "nces": "060158214030"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "f0122f67-0d46-4742-a0c9-9af7e2f7c2d7",
                "isClaimed": false,
                "isPremium": false,
                "location": "Vallejo, CA",
                "name": "Caliber: Changemakers Academy",
                "parentGUIDs": {
                    "county": "0584ee6e-08a1-46b2-9361-4d0339bae0ef",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6"
                },
                "premium": false,
                "shortName": "Caliber: Changemakers Academy",
                "tagline": [
                    "Public School",
                    "Vallejo, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "caliber--changemakers-academy-vallejo-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 708
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {},
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94590"
            }
        ]
    },
    {
        "guid": "f66414aa-8666-4042-b201-81eeec6721cb",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 506,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.916509,
                "lon": -122.692127
            },
            "entity": {
                "abbreviation": "Bolinas-Stinson Elementary School",
                "alternates": {
                    "nces": "060552000501"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "f66414aa-8666-4042-b201-81eeec6721cb",
                "isClaimed": false,
                "isPremium": false,
                "location": "Bolinas, CA",
                "name": "Bolinas-Stinson Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "c8181d71-636f-4aaa-9164-d53eedaf19b9",
                    "zipCode": "28b5376b-b6a0-4e79-9c18-f2513fd69f3e"
                },
                "premium": false,
                "shortName": "Bolinas-Stinson Elementary School",
                "tagline": [
                    "Public School",
                    "Bolinas, CA",
                    "K-8"
                ],
                "type": "School",
                "url": "bolinas-stinson-elementary-school-bolinas-ca",
                "variation": 49
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 90
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 13
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.693,37.9173,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.693,37.9173,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94924"
            }
        ]
    },
    {
        "guid": "7348c379-fb5f-4a8f-a35a-75c5504a1900",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 507,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.80566,
                "lon": -122.218685
            },
            "entity": {
                "abbreviation": "Glenview Elementary School",
                "alternates": {
                    "nces": "062805004266"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "7348c379-fb5f-4a8f-a35a-75c5504a1900",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Glenview Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "df3ed847-1c33-48b8-8a62-93505fc01c94"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Glenview Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "glenview-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 465
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.22,37.8059,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.22,37.8059,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94602"
            }
        ]
    },
    {
        "guid": "02e7558d-6315-4370-b7b2-3f2c4b7a20a3",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 508,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.321285,
                "lon": -121.841401
            },
            "entity": {
                "abbreviation": "Jeanne R. Meadows Elementary School",
                "alternates": {
                    "nces": "061437009827"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "02e7558d-6315-4370-b7b2-3f2c4b7a20a3",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Jeanne R. Meadows Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "259502ce-bdc7-4b71-89e6-a525fa78a771",
                    "parent": "37987e01-5343-4216-b264-0535f5366805",
                    "schoolDistrict": "37987e01-5343-4216-b264-0535f5366805",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "ac9aac5c-c77f-4b0d-8bc0-ae6dfa42a701"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Franklin-McKinley Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "37987e01-5343-4216-b264-0535f5366805",
                        "location": "San Jose, CA",
                        "name": "Franklin-McKinley Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "4f21b3d8-c120-45e6-9be7-52738c1baade"
                        },
                        "premium": false,
                        "shortName": "Franklin-McKinley Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "franklin-mckinley-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Jeanne R. Meadows Elementary School",
                "tagline": [
                    "Franklin-McKinley Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "jeanne-r-meadows-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 501
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.84,37.3213,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.84,37.3213,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95122"
            }
        ]
    },
    {
        "guid": "19e55f5b-b5f4-4384-af93-db56fcce0138",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 509,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.38117,
                "lon": -121.83175
            },
            "entity": {
                "abbreviation": "Millard McCollam Elementary School",
                "alternates": {
                    "nces": "060231000112"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "19e55f5b-b5f4-4384-af93-db56fcce0138",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Millard McCollam Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b030291a-fe78-43a8-9531-fb4e469f97c5",
                    "parent": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "schoolDistrict": "7be9062e-b348-4a13-93fa-470342dbb551",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Alum Rock Union Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "7be9062e-b348-4a13-93fa-470342dbb551",
                        "location": "San Jose, CA",
                        "name": "Alum Rock Union Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "b030291a-fe78-43a8-9531-fb4e469f97c5",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "5be5c65e-b5cd-43bf-91dc-4e9af8c6c1f0"
                        },
                        "premium": false,
                        "shortName": "Alum Rock Union Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "alum-rock-union-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Millard McCollam Elementary School",
                "tagline": [
                    "Alum Rock Union Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "millard-mccollam-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 502
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "I came to this school when I was younger and now a days when I have free time I go and help out my previous teachers.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-09-17T17:15:42.917331Z",
                "guid": "8646df8f-fd86-4b04-ab1f-f9e45730e193",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.833,37.381,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.833,37.381,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95127"
            }
        ]
    },
    {
        "guid": "2d973b3f-b7fd-4e92-b25c-78c35c407e4d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 510,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.733099,
                "lon": -122.435955
            },
            "entity": {
                "abbreviation": "Glen Park Elementary School",
                "alternates": {
                    "nces": "063441005618"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "2d973b3f-b7fd-4e92-b25c-78c35c407e4d",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Glen Park Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "554d2930-0795-4eee-a305-65351bba79b6",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "ee205ed4-1f3f-448a-8f17-065ffce55d35"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Glen Park Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "glen-park-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 363
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.7332,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.7332,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94131"
            }
        ]
    },
    {
        "guid": "559e1097-e6b6-4157-9e25-f4bdc8536423",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 511,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.968592,
                "lon": -122.325832
            },
            "entity": {
                "abbreviation": "Vista High (Alternative)",
                "alternates": {
                    "ceeb": "052632",
                    "nces": "063255010198"
                },
                "character": "Public, Alternative",
                "claimed": true,
                "displayable": true,
                "genus": "Public School",
                "guid": "559e1097-e6b6-4157-9e25-f4bdc8536423",
                "isClaimed": true,
                "isPremium": false,
                "location": "San Pablo, CA",
                "name": "Vista High (Alternative)",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "schoolDistrict": "a053a637-da7b-48d7-a67d-fba014c2d452",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                    "zipCode": "a08c6674-b67b-4d2b-976e-ac88b0619d80"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "WCCUSD",
                        "claimed": true,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a053a637-da7b-48d7-a67d-fba014c2d452",
                        "location": "Richmond, CA",
                        "name": "West Contra Costa Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                            "zipCode": "9e563e34-37ab-4fc4-b373-ed793d877060"
                        },
                        "premium": false,
                        "shortName": "West Contra Costa Unified School District",
                        "tagline": [
                            "School District",
                            "Richmond, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "west-contra-costa-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Vista High (Alternative)",
                "tagline": [
                    "West Contra Costa Unified School District, CA",
                    "K-12"
                ],
                "type": "School",
                "url": "vista-high-alternative-san-pablo-ca",
                "variation": 44
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 253
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 14
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "My son starting attending in his sophomore year because he was having health issues and was missing too much school and falling behind at his regular high school.  Vista's one on one program was great for him.  He thrived at Vista and was even able to do concurrent classes at the local community college.  He enjoyed his supervising teacher who was the same for all three years.  He graduated ahead of schedule and was just accepted to UC Berkeley!",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-05-22T03:21:15.105996Z",
                "guid": "036baa71-5b61-41fb-9ec6-822742576312",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.325,37.9686,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.325,37.9686,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 4.285714285714286,
                "count": 7
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94806"
            }
        ]
    },
    {
        "guid": "5ee806f5-e885-428a-a67e-2c1f80fed32c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 512,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.789712,
                "lon": -122.419097
            },
            "entity": {
                "abbreviation": "Redding Elementary School",
                "alternates": {
                    "nces": "063441005659"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "5ee806f5-e885-428a-a67e-2c1f80fed32c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Redding Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "e9ad5c07-7b31-49de-8c61-4c709f507acf",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "a2266d23-e1e6-4459-a631-2eeb3e801498"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Redding Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "redding-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 240
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.419,37.7896,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.419,37.7896,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94109"
            }
        ]
    },
    {
        "guid": "8267533d-0996-42d2-8633-4e07ee24ed79",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 513,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.247561,
                "lon": -121.857489
            },
            "entity": {
                "abbreviation": "Allen at Steinbeck",
                "alternates": {
                    "nces": "063459005697"
                },
                "character": "Public, Magnet",
                "claimed": false,
                "displayable": true,
                "genus": "Magnet School",
                "guid": "8267533d-0996-42d2-8633-4e07ee24ed79",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Allen at Steinbeck",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "078c8917-e52e-4332-af2e-2091c6f2b825",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "656c3aa8-650d-4f5e-97c4-2c4879faa9d3"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Allen at Steinbeck",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "allen-at-steinbeck-san-jose-ca",
                "variation": 53
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 520
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 25
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.857,37.2465,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.857,37.2465,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95123"
            }
        ]
    },
    {
        "guid": "c933d75d-f75f-40c7-86bd-315b46521ffe",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 514,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.612181,
                "lon": -122.40108
            },
            "entity": {
                "abbreviation": "Lomita Park Elementary School",
                "alternates": {
                    "nces": "062490003718"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "c933d75d-f75f-40c7-86bd-315b46521ffe",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Bruno, CA",
                "name": "Lomita Park Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "schoolDistrict": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "501fa4d3-c95b-4be7-8f45-a278b79ec201",
                    "zipCode": "2747e85f-3e2c-4bb8-a767-725ae14708a4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Millbrae Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "cb14e125-fb3c-4fdd-b180-a824d665da40",
                        "location": "Millbrae, CA",
                        "name": "Millbrae Elementary School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "a22149ca-7510-485b-b4ab-2f8db3bd4a65",
                            "zipCode": "4fba468a-9eb7-49d5-b87f-036348d35412"
                        },
                        "premium": false,
                        "shortName": "Millbrae Elementary School District",
                        "tagline": [
                            "School District",
                            "Millbrae, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "millbrae-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Lomita Park Elementary School",
                "tagline": [
                    "Millbrae Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "lomita-park-elementary-school-san-bruno-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 309
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 22
                }
            ],
            "featuredReview": {
                "author": "Alum",
                "body": "Very good school and amazing teachers! However, lacks funds for STEAM programs and after school activities. Many of the students do not learn the same material as other students in the same elementary school district.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2020-01-28T04:00:43.29016Z",
                "guid": "62d09c4c-35b9-4cb6-a800-7f15db1dc3cf",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4,37.6119,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.4,37.6119,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94066"
            }
        ]
    },
    {
        "guid": "a8f64ec5-dae8-4dad-81ce-dedf9971f900",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 515,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.712673,
                "lon": -122.410293
            },
            "entity": {
                "abbreviation": "Visitacion Valley Elementary School",
                "alternates": {
                    "nces": "063441005677"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "a8f64ec5-dae8-4dad-81ce-dedf9971f900",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Francisco, CA",
                "name": "Visitacion Valley Elementary School",
                "parentGUIDs": {
                    "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "d1c25e25-b8b7-4979-bd7a-096ab6b532cd",
                    "parent": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "schoolDistrict": "6739ad45-3827-4000-998c-fdd4f5029e17",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                    "zipCode": "67339067-3b07-488a-a8f9-c097bd4fcad2"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SFUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "6739ad45-3827-4000-998c-fdd4f5029e17",
                        "location": "San Francisco, CA",
                        "name": "San Francisco Unified School District",
                        "parentGUIDs": {
                            "county": "506117e3-01cc-459e-ba28-9730208c9e5d",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "a71ff0b7-9aab-4061-8668-29c022d4c89e",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "7fd0342c-7bda-410e-9b78-296b4fa3472b",
                            "zipCode": "a181afc3-605d-4817-a50e-5d1a3d969668"
                        },
                        "premium": false,
                        "shortName": "San Francisco Unified School District",
                        "tagline": [
                            "School District",
                            "San Francisco, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-francisco-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Visitacion Valley Elementary School",
                "tagline": [
                    "San Francisco Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "visitacion-valley-elementary-school-san-francisco-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 338
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 31
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.41,37.7124,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.41,37.7124,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94134"
            }
        ]
    },
    {
        "guid": "d8c88833-9d11-4ca0-a73b-f5639ff29164",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 516,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.30474,
                "lon": -121.971433
            },
            "entity": {
                "abbreviation": "Leroy Anderson Elementary School",
                "alternates": {
                    "nces": "062577003860"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d8c88833-9d11-4ca0-a73b-f5639ff29164",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Leroy Anderson Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                    "parent": "a8938731-bf81-4725-9991-fd2727841f28",
                    "schoolDistrict": "a8938731-bf81-4725-9991-fd2727841f28",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "326a03e0-bc81-42e5-87ef-3cd92b018bc4"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Moreland Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "a8938731-bf81-4725-9991-fd2727841f28",
                        "location": "San Jose, CA",
                        "name": "Moreland",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "2af26fb6-2239-44b4-be58-b0e8e46b2a61",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "71337ce0-143f-41cf-b6af-d8bf357de7bf"
                        },
                        "premium": false,
                        "shortName": "Moreland Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "moreland-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Leroy Anderson Elementary School",
                "tagline": [
                    "Moreland, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "leroy-anderson-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 386
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.972,37.3037,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.972,37.3037,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95117"
            }
        ]
    },
    {
        "guid": "d5d2f174-3861-48ce-b58c-067c43a4f71c",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 517,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.331609,
                "lon": -121.794061
            },
            "entity": {
                "abbreviation": "Cedar Grove Elementary School",
                "alternates": {
                    "nces": "061314001491"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "d5d2f174-3861-48ce-b58c-067c43a4f71c",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Cedar Grove Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Cedar Grove Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "cedar-grove-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 590
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 27
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.793,37.3323,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.793,37.3323,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95148"
            }
        ]
    },
    {
        "guid": "91342c6b-13ae-4e00-834d-a43e47623436",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 518,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.300827,
                "lon": -121.810574
            },
            "entity": {
                "abbreviation": "Dove Hill Elementary School",
                "alternates": {
                    "nces": "061314009366"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "91342c6b-13ae-4e00-834d-a43e47623436",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Dove Hill Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                    "parent": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "schoolDistrict": "e3ef367d-111b-45eb-8431-a113ea707571",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "d45dfd8d-1c4a-4d14-adf5-73af66adef2a"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Evergreen Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "e3ef367d-111b-45eb-8431-a113ea707571",
                        "location": "San Jose, CA",
                        "name": "Evergreen Elementary School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "8809e41c-e338-454f-bcbb-c2a7fc64460b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "a1b0b88c-ce08-4016-8f40-ad4d3f830ea0"
                        },
                        "premium": false,
                        "shortName": "Evergreen Elementary School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "evergreen-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Dove Hill Elementary School",
                "tagline": [
                    "Evergreen Elementary School District, CA",
                    "K-6"
                ],
                "type": "School",
                "url": "dove-hill-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 420
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Niche User",
                "body": "This school is a great school with lots of nice educators who care about your child. The secratary Veronica Sepulveda was so nice and helpful. I knew that after i'd receive such a warm greeting from Veronica that my children would be in good hands and i wouldn't have to worry about them. Veronica is such a wonder lady. The educators are great. My son's teacher Jan Bertron really helped with my son's education. I have never had such a good teacher i was so happy to have had her as a teacher. All and all what a great school and staff. Thanks Veronica Sepulveda and Jan Bertron for making a difference in our school so that parents like myself can truly feel that our children are in good hands",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2018-02-02T04:28:47.222863Z",
                "guid": "993b49af-67c9-4680-aca8-1af952435d8e",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.81,37.3004,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.81,37.3004,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 1
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95121"
            }
        ]
    },
    {
        "guid": "55a12a14-2fd0-462d-b52a-e87eeb92b883",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 519,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.977554,
                "lon": -122.520183
            },
            "entity": {
                "abbreviation": "Coleman Elementary School",
                "alternates": {
                    "nces": "063509005933"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "55a12a14-2fd0-462d-b52a-e87eeb92b883",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Rafael, CA",
                "name": "Coleman Elementary School",
                "parentGUIDs": {
                    "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "3d3c08b3-e0b1-4f28-8d5c-794f64fc2280",
                    "parent": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                    "schoolDistrict": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                    "zipCode": "218cd2f0-f326-4a8e-a957-02117d51d241"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "San Rafael City Elementary School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "be5a5cf8-82d1-47c0-9d42-67ade27a3f32",
                        "location": "San Rafael, CA",
                        "name": "San Rafael City Elementary School District",
                        "parentGUIDs": {
                            "county": "1ab3b2c8-3335-4bba-900f-776c4fd0f935",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "dc30ba2a-37d6-4766-b90c-517b11b7cdde",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "03b49160-d1e2-4a70-8c58-319b241306a8",
                            "zipCode": "a84b9cf7-0228-401b-85bd-1497a6685ebb"
                        },
                        "premium": false,
                        "shortName": "San Rafael City Elementary School District",
                        "tagline": [
                            "School District",
                            "San Rafael, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-rafael-city-elementary-school-district-ca",
                        "variation": 63
                    }
                },
                "premium": false,
                "shortName": "Coleman Elementary School",
                "tagline": [
                    "San Rafael City Elementary School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "coleman-elementary-school-san-rafael-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 405
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 24
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.528,37.9746,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.528,37.9746,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94901"
            }
        ]
    },
    {
        "guid": "3abb332a-2b5f-4c3a-9cea-3a2287c81a3d",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 520,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 38.011043,
                "lon": -121.966714
            },
            "entity": {
                "abbreviation": "Delta View Elementary School",
                "alternates": {
                    "nces": "062637010756"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "3abb332a-2b5f-4c3a-9cea-3a2287c81a3d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Pittsburg, CA",
                "name": "Delta View Elementary School",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "schoolDistrict": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "0f3ccf9b-3550-4b3b-bc4e-0553b58c6d69",
                    "zipCode": "3da768f4-2aba-4f43-8fa3-a9f7e92a82d1"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "MDUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "80e3741c-fddd-4dc8-a675-bbfc31ff630c",
                        "location": "Concord, CA",
                        "name": "Mt. Diablo Unified School District",
                        "parentGUIDs": {
                            "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "187f89d7-6963-49c2-b924-426631dae88f",
                            "zipCode": "fca2caaa-dbcc-472c-be69-c07f58b2a359"
                        },
                        "premium": false,
                        "shortName": "Mt. Diablo Unified School District",
                        "tagline": [
                            "School District",
                            "Concord, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "mt-diablo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Delta View Elementary School",
                "tagline": [
                    "Mt. Diablo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "delta-view-elementary-school-pittsburg-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 657
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "featuredReview": {
                "author": "Parent",
                "body": "Great teachers, program , school equipment.\nSince is a new school everything its pretty up to date. You can see how great they are doing on every student smile in their face that they love An are happy with Their school.",
                "categories": [
                    "Overall Experience"
                ],
                "created": "2019-08-16T20:33:54.909377Z",
                "guid": "8cc9ce67-375f-4076-9043-2b7c7d5cfa3f",
                "rating": 5
            },
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.967,38.0117,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.967,38.0117,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 5,
                "count": 2
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94565"
            }
        ]
    },
    {
        "guid": "06642757-13a4-494e-92bf-37800e85b46f",
        "badge": {
            "display": "Best Public Elementary Schools in San Francisco Bay Area",
            "ordinal": 521,
            "total": 872,
            "vanityURL": "best-public-elementary-schools/m/san-francisco-metro-area",
            "photoURLs": {
                "desktop": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_2048.png",
                "mobile": "https://d33a4decm84gsn.cloudfront.net/search/2021/k12/k12-public-schools_640.png"
            }
        },
        "content": {
            "centroid": {
                "lat": 37.802281,
                "lon": -122.211605
            },
            "entity": {
                "abbreviation": "Sequoia Elementary School",
                "alternates": {
                    "nces": "062805004314"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "06642757-13a4-494e-92bf-37800e85b46f",
                "isClaimed": false,
                "isPremium": false,
                "location": "Oakland, CA",
                "name": "Sequoia Elementary School",
                "parentGUIDs": {
                    "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "schoolDistrict": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                    "zipCode": "df3ed847-1c33-48b8-8a62-93505fc01c94"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "OUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5aaa7c32-c827-4643-b21a-b4473bb067ef",
                        "location": "Oakland, CA",
                        "name": "Oakland Unified School District",
                        "parentGUIDs": {
                            "county": "9b5c7e66-a434-4dcc-ae52-40a6a254e757",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "fa4528ca-02a8-4cce-8e37-9eee2f1d949b",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "23bac116-2d1e-45f1-83be-a674bca9da64",
                            "zipCode": "cd2934bf-5aad-4119-93ca-694736677a7a"
                        },
                        "premium": false,
                        "shortName": "Oakland Unified School District",
                        "tagline": [
                            "School District",
                            "Oakland, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "oakland-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Sequoia Elementary School",
                "tagline": [
                    "Oakland Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "sequoia-elementary-school-oakland-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 436
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 23
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 3
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.211,37.8021,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.211,37.8021,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94602"
            }
        ]
    },
    {
        "guid": "c88335c8-9af8-428e-a6b5-4c7469d5394d",
        "content": {
            "centroid": {
                "lat": 37.990124,
                "lon": -122.330351
            },
            "entity": {
                "abbreviation": "Aspire Richmond Technology Academy",
                "alternates": {
                    "nces": "060154713891"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "c88335c8-9af8-428e-a6b5-4c7469d5394d",
                "isClaimed": false,
                "isPremium": false,
                "location": "Richmond, CA",
                "name": "Aspire Richmond Technology Academy",
                "parentGUIDs": {
                    "county": "249f98b3-c049-4987-8321-944e69ef7f01",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "schoolNetwork": "6d55fb3e-0771-4fa6-bff7-2753f0a42065",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "7208b7a9-edaf-4196-afb5-f951fad37422",
                    "zipCode": "a08c6674-b67b-4d2b-976e-ac88b0619d80"
                },
                "premium": false,
                "shortName": "Aspire Richmond Technology Academy",
                "tagline": [
                    "Public School",
                    "Richmond, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "aspire-richmond-technology-academy-richmond-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 345
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 28
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.327,37.9782,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.327,37.9782,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94806"
            }
        ]
    },
    {
        "guid": "dd2f9c81-c3f7-4e4e-a605-4ddab5f8cbf0",
        "content": {
            "centroid": {
                "lat": 37.463271,
                "lon": -122.436543
            },
            "entity": {
                "abbreviation": "Alvin S. Hatch Elementary School",
                "alternates": {
                    "nces": "060678000596"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "dd2f9c81-c3f7-4e4e-a605-4ddab5f8cbf0",
                "isClaimed": false,
                "isPremium": false,
                "location": "Half Moon Bay, CA",
                "name": "Alvin S. Hatch Elementary School",
                "parentGUIDs": {
                    "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "parent": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                    "schoolDistrict": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "6f401739-fe70-4434-8602-b2cdb57c2074",
                    "zipCode": "cfe7994d-8a48-4529-b312-78d0deacfe27"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "Cabrillo Unified School District",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "d9dbf94b-adf8-4619-931e-ebcff1d6649f",
                        "location": "Half Moon Bay, CA",
                        "name": "Cabrillo Unified School District",
                        "parentGUIDs": {
                            "county": "c112eefc-a8d3-4aec-8cb3-4aafdf868567",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "6f401739-fe70-4434-8602-b2cdb57c2074",
                            "zipCode": "cfe7994d-8a48-4529-b312-78d0deacfe27"
                        },
                        "premium": false,
                        "shortName": "Cabrillo Unified School District",
                        "tagline": [
                            "School District",
                            "Half Moon Bay, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "cabrillo-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Alvin S. Hatch Elementary School",
                "tagline": [
                    "Cabrillo Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "alvin-s-hatch-elementary-school-half-moon-bay-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 563
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 26
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.4618,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-122.436,37.4618,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/94019"
            }
        ]
    },
    {
        "guid": "64da288e-6ffc-4c24-b043-24d64b7894fa",
        "content": {
            "centroid": {
                "lat": 37.313555,
                "lon": -121.878025
            },
            "entity": {
                "abbreviation": "Rocketship Alma Academy",
                "alternates": {
                    "nces": "060163713048"
                },
                "character": "Public, Charter",
                "claimed": false,
                "displayable": true,
                "genus": "Charter School",
                "guid": "64da288e-6ffc-4c24-b043-24d64b7894fa",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Rocketship Alma Academy",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "b3810c50-1d1b-4a15-8c27-36f244515bfd",
                    "schoolNetwork": "159c1d35-78c2-4e2f-8837-1d24cf94af66",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "e055b5f7-d1e6-491d-9e73-e54d691a3326"
                },
                "premium": false,
                "shortName": "Rocketship Alma Academy",
                "tagline": [
                    "Public School",
                    "San Jose, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "rocketship-alma-academy-san-jose-ca",
                "variation": 57
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 522
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 31
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.878,37.3134,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.878,37.3134,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95110"
            }
        ]
    },
    {
        "guid": "96fa0c67-6530-4b98-b3fd-9e0dc224365b",
        "content": {
            "centroid": {
                "lat": 37.28467,
                "lon": -121.876437
            },
            "entity": {
                "abbreviation": "Canoas Elementary School",
                "alternates": {
                    "nces": "063459008634"
                },
                "character": "Public",
                "claimed": false,
                "displayable": true,
                "genus": "Public School",
                "guid": "96fa0c67-6530-4b98-b3fd-9e0dc224365b",
                "isClaimed": false,
                "isPremium": false,
                "location": "San Jose, CA",
                "name": "Canoas Elementary School",
                "parentGUIDs": {
                    "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                    "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                    "neighborhood": "9bed7cde-76c6-4ed3-9076-f48cade709d3",
                    "parent": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "schoolDistrict": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                    "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                    "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                    "zipCode": "0f5f9ca7-0382-4b2d-8697-36994f2fddf7"
                },
                "parents": {
                    "parent": {
                        "abbreviation": "SJUSD",
                        "claimed": false,
                        "displayable": true,
                        "genus": "School District",
                        "guid": "5443587e-2779-4f77-878e-9ddcb0ce3422",
                        "location": "San Jose, CA",
                        "name": "San Jose Unified School District",
                        "parentGUIDs": {
                            "county": "52fc30ae-f66a-467e-a244-bd6ad255388a",
                            "metroArea": "d8ccf170-f072-4180-977c-a91f914ebcfe",
                            "neighborhood": "0a781906-f3cb-42ec-b08b-9acfcaf63aaa",
                            "state": "b56d7c2d-d07e-4aa2-bcf6-925ecb0890f6",
                            "town": "24bc23a3-5a80-4c8d-9929-5eb10c2bfa6b",
                            "zipCode": "763f10c1-8ad0-4832-90b4-5bad39b6e93f"
                        },
                        "premium": false,
                        "shortName": "San Jose Unified School District",
                        "tagline": [
                            "School District",
                            "San Jose, CA"
                        ],
                        "type": "SchoolDistrict",
                        "url": "san-jose-unified-school-district-ca",
                        "variation": 61
                    }
                },
                "premium": false,
                "shortName": "Canoas Elementary School",
                "tagline": [
                    "San Jose Unified School District, CA",
                    "K-5"
                ],
                "type": "School",
                "url": "canoas-elementary-school-san-jose-ca",
                "variation": 45
            },
            "facts": [
                {
                    "config": {
                        "format": [
                            "comma"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Students",
                    "value": 261
                },
                {
                    "config": {
                        "format": [
                            "ratio"
                        ],
                        "rounding": [
                            "whole"
                        ]
                    },
                    "label": "Student-Teacher Ratio",
                    "tooltip": "Student-Teacher Ratio may not be indicative of class size. It is calculated using the reported number of students and full-time equivalent teachers.",
                    "value": 21
                }
            ],
            "grades": [
                {
                    "description": "Based on quality of academics, teachers, student life, and outcomes.",
                    "guid": "54945c65-04ee-445d-9310-3090bd1f1f0a",
                    "label": "Overall Niche Grade",
                    "value": 2.66
                }
            ],
            "photos": {
                "mapbox_header": {
                    "author": "© Mapbox",
                    "crops": {
                        "DesktopHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.877,37.2844,13/900x471@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg",
                        "MobileHeader": "https://api.mapbox.com/styles/v1/niche-admin/citg1y6rj00312inu917d7y1o/static/-121.877,37.2844,13/400x250@2x?access_token=pk.eyJ1IjoibmljaGUtYWRtaW4iLCJhIjoiY2lyODF1YnR6MDB3bGcybTNhdDQxY3pkZCJ9.NQ0kRtM21uVhwbzjSAridg"
                    },
                    "licenseName": "© OpenStreetMap",
                    "licenseUrl": "http://www.openstreetmap.org/copyright",
                    "sourceUrl": "https://www.mapbox.com/about/maps/"
                }
            },
            "reviewAverage": {
                "average": 0,
                "count": 0
            },
            "virtualTour": [
                {
                    "label": "Virtual Tour"
                }
            ]
        },
        "ctas": [
            {
                "label": "View Nearby Homes",
                "type": "realEstateListings",
                "url": "https://www.realtor.com/realestateandhomes-search/95125"
            }
        ]
    }
]

export default ELEMENTARY_SCHOOL_RANKS