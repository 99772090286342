exports.MILLBRAE_LISTINGS = [
		{
			"closePrice": 1200000,
			"daysOnMovoto": 106,
			"id": "0734523a-43f5-4402-9ef4-b853bddf371f",
			"tnImgPath": "https://pi.movoto.com/p/110/421604261_0_3RV7Qz_p.jpeg",
			"listDate": "2021-09-21 00:00:00",
			"listingAgent": "Grace Ieong",
			"listPrice": 1199000,
			"lotSize": 20981,
			"sqftTotal": 1414,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421604261",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 49,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "94030",
			"path": "millbrae-ca/88-s-broadway-3211-millbrae-ca-94030/pid_l598w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "188a7077-cf1c-487c-b8d2-4e2e04d2cd32",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-10-15 17:21:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=p3NFutB3vic&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.597469,
				"lng": -122.387528,
				"zipcode": "94030",
				"subPremise": "APT 3211",
				"address": "88 S Broadway #3211"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=p3NFutB3vic&brand=0"
		},
		{
			"closePrice": 3100000,
			"daysOnMovoto": 20,
			"id": "44c1d9ee-5b29-4185-9867-a1446d872807",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872179_0_rNZFYA_p.jpeg",
			"listDate": "2021-12-10 00:00:00",
			"listingAgent": "Sue Mahlstedt",
			"listPrice": 2488000,
			"lotSize": 11378,
			"sqftTotal": 2010,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872179",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 46,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1963,
			"zipCode": "94030",
			"path": "millbrae-ca/15-manzanita-ct-millbrae-ca-94030/pid_g6c6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e323038a-45cb-42d7-b8f1-b2c8d23e0f69",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-12-10 22:27:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-10 00:00:00",
			"virtualTourLink": "https://demo.ohpadmin.com/welcome/preview/35335",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.588064,
				"lng": -122.400756,
				"zipcode": "94030",
				"subPremise": "",
				"address": "15 Manzanita Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://demo.ohpadmin.com/welcome/preview/35335"
		},
		{
			"closePrice": 3018000,
			"daysOnMovoto": 152,
			"id": "18be8e78-226c-44ba-85a3-ea2cee592957",
			"tnImgPath": "https://pi.movoto.com/p/110/421612640_0_6BmnbF_p.jpeg",
			"listDate": "2021-07-12 00:00:00",
			"listingAgent": "Winnie Fok",
			"listPrice": 2668000,
			"lotSize": 18408,
			"sqftTotal": 2320,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421612640",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sotheby's International Realty",
			"photoCount": 76,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1960,
			"zipCode": "94030",
			"path": "millbrae-ca/1205-murchison-dr-millbrae-ca-94030-100_81310871/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "36988956-6cb3-4386-9caf-e60a9befa4f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-11 00:00:00",
			"createdAt": "2021-11-23 18:46:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-12 00:00:00",
			"virtualTourLink": "http://www.1205MurchisonDrive.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.590443,
				"lng": -122.399526,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1205 Murchison Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3018000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.1205MurchisonDrive.com"
		},
		{
			"closePrice": 744000,
			"daysOnMovoto": 169,
			"id": "7d72edd4-a2a6-4435-9f09-cd1af8fea86e",
			"tnImgPath": "https://pi.movoto.com/p/12/40956013_0_VmRBEQ_p.jpeg",
			"listDate": "2021-06-24 00:00:00",
			"listingAgent": "Fergus Ma",
			"listPrice": 749000,
			"lotSize": 37089,
			"sqftTotal": 926,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956013",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Infiniti",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1980,
			"zipCode": "94030",
			"path": "millbrae-ca/1396-el-camino-real-110-millbrae-ca-94030-100_81126116/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -31000,
			"propertyId": "74ed2c37-5481-4714-bd03-6acd3a287775",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-06-25 01:21:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.610734,
				"lng": -122.401093,
				"zipcode": "94030",
				"subPremise": "APT 110",
				"address": "1396 El Camino Real #110"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 744000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1218000,
			"daysOnMovoto": 0,
			"id": "b74f1435-c31e-48b6-b963-253c6802179b",
			"tnImgPath": "https://pi.movoto.com/p/102/421579876_0_rIjrUE_p.jpeg",
			"listDate": null,
			"listingAgent": "Brandon Yager",
			"listPrice": 1298000,
			"lotSize": 20981,
			"sqftTotal": 1419,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421579876",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Yager Realty of California",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "94030",
			"path": "millbrae-ca/88-s-broadway-ave-apt-3311-millbrae-ca-94030-110_421579876/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -30888,
			"propertyId": "3a635958-64fa-47c1-ae7b-f8e2fab6e87b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-08-18 19:29:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 19:29:41",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.597236,
				"lng": -122.387523,
				"zipcode": "94030",
				"subPremise": "APT 3311",
				"address": "88 S Broadway Ave #3311"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1218000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 60,
			"id": "e98a4108-6d1a-4d6f-b10d-c559bdfbe114",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861705_0_baz7Jz_p.jpeg",
			"listDate": "2021-09-08 12:57:03",
			"listingAgent": "Russell Beckwith",
			"listPrice": 2100000,
			"lotSize": 3999,
			"sqftTotal": 2736,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861705",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Panda Realty",
			"photoCount": 12,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1953,
			"zipCode": "94030",
			"path": "millbrae-ca/30-laurel-ave-millbrae-ca-94030/pid_3695w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5d035e4d-baf8-4e75-a9f8-5473990f4a87",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-08 00:00:00",
			"createdAt": "2021-09-08 20:02:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 12:57:03",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.594967,
				"lng": -122.395562,
				"zipcode": "94030",
				"subPremise": "",
				"address": "30 Laurel Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2358888,
			"daysOnMovoto": 35,
			"id": "efd126d9-5d87-4320-beca-063703f2bdc1",
			"tnImgPath": "https://pi.movoto.com/p/110/421607356_0_UfyQ7M_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Paige Bocci Brennan",
			"listPrice": 2195000,
			"lotSize": 8400,
			"sqftTotal": 2020,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421607356",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Bocci Realty, Richard J.",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1955,
			"zipCode": "94030",
			"path": "millbrae-ca/835-pinon-ave-millbrae-ca-94030/pid_z5b6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "edd09eb4-92c9-4994-b343-2d23bea123d1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-10-28 19:46:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": "https://www.835pinon.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.589935,
				"lng": -122.38936,
				"zipcode": "94030",
				"subPremise": "",
				"address": "835 Pinon Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2358888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.835pinon.com"
		},
		{
			"closePrice": 2556000,
			"daysOnMovoto": 13,
			"id": "e03f08f5-d902-43f0-ba1b-0b7af60ac298",
			"tnImgPath": "https://pi.movoto.com/p/110/421597092_0_QJMMR7_p.jpeg",
			"listDate": "2021-09-19 00:00:00",
			"listingAgent": "Raymond Scarabosio",
			"listPrice": 2295000,
			"lotSize": 6900,
			"sqftTotal": 1930,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421597092",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "PR Realty Co.",
			"photoCount": 48,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1963,
			"zipCode": "94030",
			"path": "millbrae-ca/1368-frontera-way-millbrae-ca-94030/pid_9qe6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8d02fa06-79b8-4b0f-84f6-a7c221f1e98b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-02 00:00:00",
			"createdAt": "2021-09-23 01:46:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-19 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=S44BttBposz&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.584058,
				"lng": -122.400247,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1368 Frontera Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2556000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=S44BttBposz&mls=1"
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 78,
			"id": "970fccf4-bb6e-4a9e-88ed-304fb587d972",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81853143_0_FAuJEz_p.jpeg",
			"listDate": "2021-07-14 00:00:00",
			"listingAgent": "Bing Pan-Hattori",
			"listPrice": 2075000,
			"lotSize": 5502,
			"sqftTotal": 2665,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81853143",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 27,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1960,
			"zipCode": "94030",
			"path": "millbrae-ca/410-420-millbrae-ave-millbrae-ca-94030-100_ml81853143/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -25000,
			"propertyId": "d74a1286-7ceb-440e-b139-172cb40561a1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-07-14 14:07:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-14 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/574109665",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.596541,
				"lng": -122.391451,
				"zipcode": "94030",
				"subPremise": "",
				"address": "410-420 Millbrae Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/574109665"
		},
		{
			"closePrice": 1880000,
			"daysOnMovoto": 13,
			"id": "8ed1bb33-ba4c-4c23-8701-c5a01ae2ecf9",
			"tnImgPath": "https://pi.movoto.com/p/110/421589430_0_63avbf_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Phoebe Chan",
			"listPrice": 1688000,
			"lotSize": 5300,
			"sqftTotal": 1340,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421589430",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Century 21 Realty Alliance Fine Homes and Estates",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1953,
			"zipCode": "94030",
			"path": "millbrae-ca/1421-hillcrest-blvd-millbrae-ca-94030/pid_mic6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6294d054-8bb6-4645-afb5-32220ccb40ca",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-09 00:00:00",
			"createdAt": "2021-08-27 21:56:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": "https://focusedaerial.gofullframe.com/ut/1421_Hillcrest_Blvd.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.59009,
				"lng": -122.410513,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1421 Hillcrest Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://focusedaerial.gofullframe.com/ut/1421_Hillcrest_Blvd.html"
		},
		{
			"closePrice": 1550000,
			"daysOnMovoto": 24,
			"id": "b43d60ad-899a-432d-9d16-8af9b8f30347",
			"tnImgPath": "https://pi.movoto.com/p/110/421584552_0_baByfB_p.jpeg",
			"listDate": "2021-08-12 00:00:00",
			"listingAgent": "Patrick Shannon",
			"listPrice": 1598888,
			"lotSize": 6930,
			"sqftTotal": 1330,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421584552",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Trinity Realty, Inc.",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1956,
			"zipCode": "94030",
			"path": "millbrae-ca/842-vista-grande-millbrae-ca-94030/pid_8895w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -89112,
			"propertyId": "8ed2b43f-81ed-4e8d-b3fb-073cd1092fe2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-05 00:00:00",
			"createdAt": "2021-08-12 23:26:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-12 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=7ZpyxV86jrA&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.591896,
				"lng": -122.408522,
				"zipcode": "94030",
				"subPremise": "",
				"address": "842 Vista Grande"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=7ZpyxV86jrA&brand=0"
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 13,
			"id": "ed9c7316-efc8-4816-96e2-4d9aca0812b1",
			"tnImgPath": "https://pi.movoto.com/p/110/421583748_0_qIAAVE_p.jpeg",
			"listDate": "2021-08-13 00:00:00",
			"listingAgent": "Korosh Sahami",
			"listPrice": 1698888,
			"lotSize": 8280,
			"sqftTotal": 1380,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421583748",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty World Bay Area Real Estate",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1956,
			"zipCode": "94030",
			"path": "millbrae-ca/1015-pinehurst-ct-millbrae-ca-94030/pid_yv25w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ee0dd9b1-4384-44fa-be52-f7b0abea18a2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-26 00:00:00",
			"createdAt": "2021-08-13 21:46:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.600062,
				"lng": -122.418389,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1015 Pinehurst Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1575000,
			"daysOnMovoto": 42,
			"id": "00c84ab4-5ebc-4575-b1ef-a54ba711aa1d",
			"tnImgPath": "https://pi.movoto.com/p/12/40958981_0_2mnefN_p.jpeg",
			"listDate": "2021-07-15 00:00:00",
			"listingAgent": "Ashley Rice",
			"listPrice": 1500000,
			"lotSize": 6072,
			"sqftTotal": 1380,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40958981",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Twin Oaks Real Estate Inc.",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94030",
			"path": "millbrae-ca/330-ludeman-ln-millbrae-ca-94030/pid_l335w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d7f1e535-1d4e-418e-b015-2c58e9ce2b7b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-26 00:00:00",
			"createdAt": "2021-07-16 00:26:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.606961,
				"lng": -122.403145,
				"zipcode": "94030",
				"subPremise": "",
				"address": "330 Ludeman Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1575000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2300000,
			"daysOnMovoto": 66,
			"id": "db000ca4-f3a0-4c48-aa29-7e5074d86d55",
			"tnImgPath": "https://pi.movoto.com/p/110/421561694_0_B7NvRA_p.jpeg",
			"listDate": "2021-06-12 00:00:00",
			"listingAgent": "Jorge Wong",
			"listPrice": 2200000,
			"lotSize": 5664,
			"sqftTotal": 2660,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421561694",
			"bath": 5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Far East Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1954,
			"zipCode": "94030",
			"path": "millbrae-ca/520-hillcrest-blvd-millbrae-ca-94030/pid_3485w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d42613b5-2ccc-4c08-97fb-efb63bf026bf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-17 00:00:00",
			"createdAt": "2021-06-14 22:01:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.59796,
				"lng": -122.396662,
				"zipcode": "94030",
				"subPremise": "",
				"address": "520 Hillcrest Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1075000,
			"daysOnMovoto": 27,
			"id": "088b9743-bb46-4634-8f5d-68e03fbc80a3",
			"tnImgPath": "https://pi.movoto.com/p/110/421566383_0_YUEM6Y_p.jpeg",
			"listDate": "2021-06-22 00:00:00",
			"listingAgent": "Peter Fong",
			"listPrice": 1059000,
			"lotSize": 68800,
			"sqftTotal": 1147,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421566383",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2009,
			"zipCode": "94030",
			"path": "millbrae-ca/1388-broadway-408-millbrae-ca-94030/pid_e498w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5714b07f-6e4a-4eed-8ee3-cbcce62a5f95",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-19 00:00:00",
			"createdAt": "2021-07-07 21:21:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-22 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=P65bjMRZQjm&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.608744,
				"lng": -122.400743,
				"zipcode": "94030",
				"subPremise": "APT 408",
				"address": "1388 Broadway #408"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1075000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=P65bjMRZQjm&mls=1"
		},
		{
			"closePrice": 1588000,
			"daysOnMovoto": 15,
			"id": "c04a1bce-9c08-4851-9f37-554debfae19f",
			"tnImgPath": "https://pi.movoto.com/p/110/421563486_0_IiEm6M_p.jpeg",
			"listDate": "2021-06-22 00:00:00",
			"listingAgent": "Victor Makras",
			"listPrice": 1500000,
			"lotSize": 5000,
			"sqftTotal": 1610,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421563486",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Makras Real Estate",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1949,
			"zipCode": "94030",
			"path": "millbrae-ca/923-palmito-dr-millbrae-ca-94030/pid_6o25w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "15b14a41-b86c-4fd7-989e-48da716e92f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-07 00:00:00",
			"createdAt": "2021-06-22 17:31:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.611527,
				"lng": -122.403588,
				"zipcode": "94030",
				"subPremise": "",
				"address": "923 Palmito Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1588000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1072500,
			"daysOnMovoto": 16,
			"id": "b6d76671-f50e-4441-820d-32beb8421cf1",
			"tnImgPath": "https://pi.movoto.com/p/110/421564968_0_nYe6A7_p.jpeg",
			"listDate": "2021-06-01 00:00:00",
			"listingAgent": "Juanjuan Huang",
			"listPrice": 1050000,
			"lotSize": 68800,
			"sqftTotal": 1147,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421564968",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 8,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2009,
			"zipCode": "94030",
			"path": "millbrae-ca/1388-broadway-185-millbrae-ca-94030-100_81206614/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6b1eaa98-e4eb-4a2c-b066-af1e0df59426",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-17 00:00:00",
			"createdAt": "2021-06-18 02:05:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.608744,
				"lng": -122.400743,
				"zipcode": "94030",
				"subPremise": "APT 185",
				"address": "1388 Broadway #185"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1072500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2300000,
			"daysOnMovoto": 12,
			"id": "540c4b2d-746d-4f1d-b0ef-04e7141bd796",
			"tnImgPath": "https://pi.movoto.com/p/110/421559613_0_VINjNR_p.jpeg",
			"listDate": "2021-06-03 00:00:00",
			"listingAgent": "Leah Chen",
			"listPrice": 1990000,
			"lotSize": 6262,
			"sqftTotal": 2310,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421559613",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sterling Real Estate",
			"photoCount": 48,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1971,
			"zipCode": "94030",
			"path": "millbrae-ca/865-morningside-dr-millbrae-ca-94030-100_80931316/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4dac479d-05ee-45bc-8ac7-04c24e2d57c7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-15 00:00:00",
			"createdAt": "2021-06-03 23:36:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.59788,
				"lng": -122.409983,
				"zipcode": "94030",
				"subPremise": "",
				"address": "865 Morningside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1880000,
			"daysOnMovoto": 26,
			"id": "d23a7617-1c4d-49dc-bd38-832eb37478bf",
			"tnImgPath": "https://pi.movoto.com/p/110/421543167_0_VYVNAU_p.jpeg",
			"listDate": "2021-05-07 00:00:00",
			"listingAgent": "Joanne Xiang",
			"listPrice": 1695000,
			"lotSize": 5928,
			"sqftTotal": 1794,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421543167",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Century 21 Realty Alliance Fine Homes and Estates",
			"photoCount": 43,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1956,
			"zipCode": "94030",
			"path": "millbrae-ca/864-vista-grande-millbrae-ca-94030-100_81228218/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eac49818-b621-4161-a1ba-529daba770fb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-02 00:00:00",
			"createdAt": "2021-05-07 17:56:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-07 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=mp7L1UF7duL&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.59254,
				"lng": -122.40861,
				"zipcode": "94030",
				"subPremise": "",
				"address": "864 Vista Grande"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=mp7L1UF7duL&mls=1"
		},
		{
			"closePrice": 2738792,
			"daysOnMovoto": 39,
			"id": "359c62c6-7d85-4305-ad3f-3c078d09ed25",
			"tnImgPath": "https://pi.movoto.com/p/110/421540017_0_YJeUYI_p.jpeg",
			"listDate": "2021-04-19 00:00:00",
			"listingAgent": "Ken Kwok",
			"listPrice": 2890000,
			"lotSize": 6000,
			"sqftTotal": 3977,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421540017",
			"bath": 6,
			"bed": 8,
			"openHouses": [],
			"officeListName": "MaxReal",
			"photoCount": 24,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1937,
			"zipCode": "94030",
			"path": "millbrae-ca/11-hazel-ave-apt-10-millbrae-ca-94030-110_421540017/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9f9ffbf1-2c0a-4905-86a5-5940c1751670",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-28 00:00:00",
			"createdAt": "2021-04-19 19:36:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.594947,
				"lng": -122.394862,
				"zipcode": "94030",
				"subPremise": "APT 10",
				"address": "11 Hazel Ave #10"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2738792,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1510000,
			"daysOnMovoto": 134,
			"id": "eac3722e-8dfa-4225-a5c1-f5e0737d5d39",
			"tnImgPath": "https://pi.movoto.com/p/12/40933153_0_jyZUZV_p.jpeg",
			"listDate": "2021-01-13 00:00:00",
			"listingAgent": "Sofyan Karim",
			"listPrice": 1699000,
			"lotSize": 5184,
			"sqftTotal": 1678,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40933153",
			"bath": 1,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty ONE Group TODAY",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1946,
			"zipCode": "94030",
			"path": "millbrae-ca/511-santa-teresa-way-millbrae-ca-94030-12_40931392/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -999,
			"propertyId": "6254a9ae-b0b9-4fed-a822-c369f7f3b92f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-27 00:00:00",
			"createdAt": "2021-01-13 20:16:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.613214,
				"lng": -122.408024,
				"zipcode": "94030",
				"subPremise": "",
				"address": "511 Santa Teresa Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1510000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2025000,
			"daysOnMovoto": 33,
			"id": "033e6f21-21e4-4090-b92d-995f53af5d15",
			"tnImgPath": "https://pi.movoto.com/p/12/40946889_0_aQzvfU_p.jpeg",
			"listDate": "2021-04-23 00:00:00",
			"listingAgent": "Albert Garibaldi",
			"listPrice": 1749950,
			"lotSize": 5088,
			"sqftTotal": 1820,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40946889",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "The Agency",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1948,
			"zipCode": "94030",
			"path": "millbrae-ca/8-michael-ln-millbrae-ca-94030/pid_wu45w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fee8f1d2-af63-4a56-ba61-f0df171d93f7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-26 00:00:00",
			"createdAt": "2021-04-23 18:01:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-23 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=V9qdMMQ8DV1&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.601918,
				"lng": -122.404318,
				"zipcode": "94030",
				"subPremise": "",
				"address": "8 Michael Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2025000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=V9qdMMQ8DV1&mls=1"
		},
		{
			"closePrice": 2047500,
			"daysOnMovoto": 95,
			"id": "5a26a198-0057-4b39-b1bd-ee5780552902",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830217_0_VuFUeQ_p.jpeg",
			"listDate": "2021-02-17 15:47:00",
			"listingAgent": "Scott Brody",
			"listPrice": 2200000,
			"lotSize": 5001,
			"sqftTotal": 4270,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830217",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Multisource",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1984,
			"zipCode": "94030",
			"path": "millbrae-ca/33-35-silva-ave-millbrae-ca-94030-100_ml81830217/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cc9f40d4-60e9-42e4-9a46-21fef4b3448c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-24 00:00:00",
			"createdAt": "2021-02-17 23:52:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-17 15:47:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.604734,
				"lng": -122.393727,
				"zipcode": "94030",
				"subPremise": "",
				"address": "33-35 Silva Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2047500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 81,
			"id": "558134fd-baad-48a4-995f-4072d434ab58",
			"tnImgPath": "https://pi.movoto.com/p/110/421545348_0_jQ7z6e_p.jpeg",
			"listDate": "2021-02-21 00:00:00",
			"listingAgent": "Kalok Law",
			"listPrice": 1749000,
			"lotSize": 8950,
			"sqftTotal": 1880,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421545348",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Sequoia Real Estate",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1963,
			"zipCode": "94030",
			"path": "millbrae-ca/815-larkspur-dr-millbrae-ca-94030/pid_jo35w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "087a11d5-fed7-4490-8f64-d817b20f0ebd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-13 00:00:00",
			"createdAt": "2021-05-07 23:36:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-21 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=cWrPG9hHqq8",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.60176,
				"lng": -122.414318,
				"zipcode": "94030",
				"subPremise": "",
				"address": "815 Larkspur Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=cWrPG9hHqq8"
		},
		{
			"closePrice": 2470819,
			"daysOnMovoto": 11,
			"id": "d012ea01-1c3f-46a0-850a-851702614874",
			"tnImgPath": "https://pi.movoto.com/p/110/421541874_0_ENiYNM_p.jpeg",
			"listDate": "2021-04-23 00:00:00",
			"listingAgent": "Marlon Austria",
			"listPrice": 1998888,
			"lotSize": 5520,
			"sqftTotal": 2060,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421541874",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty World Bay Area Real Estate",
			"photoCount": 47,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1966,
			"zipCode": "94030",
			"path": "millbrae-ca/927-clearfield-dr-millbrae-ca-94030/pid_fp35w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "17022678-d9ec-48ff-9136-5c67f100dc62",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-04 00:00:00",
			"createdAt": "2021-04-24 01:16:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-23 00:00:00",
			"virtualTourLink": "Http://www.927clearfielddr.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.594996,
				"lng": -122.410822,
				"zipcode": "94030",
				"subPremise": "",
				"address": "927 Clearfield Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2470819,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.927clearfielddr.com/"
		},
		{
			"closePrice": 1275000,
			"daysOnMovoto": 12,
			"id": "727a819e-d06e-4a75-9055-7138a973083d",
			"tnImgPath": "https://pi.movoto.com/p/110/421535080_0_enRbnR_p.jpeg",
			"listDate": "2021-04-04 00:00:00",
			"listingAgent": "Victoria Lam",
			"listPrice": 1168000,
			"lotSize": 4745,
			"sqftTotal": 1140,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421535080",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1945,
			"zipCode": "94030",
			"path": "millbrae-ca/13-corte-ana-millbrae-ca-94030/pid_1fc6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a9b87cb9-6147-4187-82ab-8de56a8a2b3a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-04-04 07:46:09",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.605788,
				"lng": -122.388298,
				"zipcode": "94030",
				"subPremise": "",
				"address": "13 Corte Ana"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2218000,
			"daysOnMovoto": 17,
			"id": "c989d820-51cf-4151-8557-dcc2296c000b",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-03-30 00:00:00",
			"listingAgent": "Matthew Rogers",
			"listPrice": 1999000,
			"lotSize": 5998,
			"sqftTotal": 2460,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831108",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Rogers & Associates",
			"photoCount": 0,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Duplex",
			"yearBuilt": 1929,
			"zipCode": "94030",
			"path": "millbrae-ca/111-palm-ave-millbrae-ca-94030-100_ml81831108/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3097d63b-368b-45f3-a695-0e630f8616c1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-04-16 23:31:35",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-03-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.596328,
				"lng": -122.394803,
				"zipcode": "94030",
				"subPremise": "",
				"address": "111 Palm Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2218000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1065000,
			"daysOnMovoto": 18,
			"id": "bed114de-c59f-4caf-bed8-f7f3b5027e27",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81836297_0_zJAUea_p.jpeg",
			"listDate": "2021-03-28 09:36:31",
			"listingAgent": "Vicky Le",
			"listPrice": 998000,
			"lotSize": null,
			"sqftTotal": 1212,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81836297",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Province Real Estate",
			"photoCount": 20,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2010,
			"zipCode": "94030",
			"path": "millbrae-ca/151-el-camino-real-229-millbrae-ca-94030-100_81126034/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "56cbb2ed-7e3c-4530-863f-4de44fa46ee5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-03-28 16:42:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-28 09:36:31",
			"virtualTourLink": "Https://mediaonere.hd.pics/151-El-Camino-Real/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.599933,
				"lng": -122.389444,
				"zipcode": "94030",
				"subPremise": "APT 229",
				"address": "151 El Camino Real #229"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1065000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://mediaonere.hd.pics/151-El-Camino-Real/idx"
		},
		{
			"closePrice": 2025000,
			"daysOnMovoto": 32,
			"id": "6b6292a3-9f7a-45e3-923a-4716159911cc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832725_0_zV2uME_p.jpeg",
			"listDate": "2021-03-05 13:18:58",
			"listingAgent": "Robert R. Bredel",
			"listPrice": 1675000,
			"lotSize": 5201,
			"sqftTotal": 1600,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832725",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Dwell Realtors, Inc.",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1964,
			"zipCode": "94030",
			"path": "millbrae-ca/916-evergreen-way-millbrae-ca-94030-100_ml81516787/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1968eb07-1d24-4e93-9c65-082c0a18630e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-07 00:00:00",
			"createdAt": "2021-03-05 21:27:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-05 13:18:58",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.600215,
				"lng": -122.413342,
				"zipcode": "94030",
				"subPremise": "",
				"address": "916 Evergreen Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2025000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 10,
			"id": "a732ea17-459e-4495-b40b-3bf59f88db15",
			"tnImgPath": "https://pi.movoto.com/p/110/421532836_0_eIqeR7_p.jpeg",
			"listDate": "2021-03-27 00:00:00",
			"listingAgent": "Patrick Shannon",
			"listPrice": 1688888,
			"lotSize": 14500,
			"sqftTotal": 1290,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421532836",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Trinity Realty, Inc.",
			"photoCount": 62,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1947,
			"zipCode": "94030",
			"path": "millbrae-ca/10-mio-corte-millbrae-ca-94030/pid_nx85w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c50c3b7d-df74-47b7-b6d1-821159dcb979",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2021-03-27 20:51:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-27 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=Ak7ntfcUcQp&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.593444,
				"lng": -122.402503,
				"zipcode": "94030",
				"subPremise": "",
				"address": "10 Mio Corte"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=Ak7ntfcUcQp&mls=1"
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 13,
			"id": "1ca0c14e-a8b9-4e36-af05-5e7cb9670d2e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834930_0_yrjV7a_p.jpeg",
			"listDate": "2021-03-19 10:46:21",
			"listingAgent": "Alison Williams",
			"listPrice": 1849000,
			"lotSize": 5600,
			"sqftTotal": 1580,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834930",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Dwell Realtors, Inc.",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1956,
			"zipCode": "94030",
			"path": "millbrae-ca/815-banbury-ln-millbrae-ca-94030/pid_l825w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7facb09e-6de0-4d08-9217-d90cead5aa08",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-03-19 17:52:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-19 10:46:21",
			"virtualTourLink": "Http://www.815banburylane.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.602855,
				"lng": -122.416707,
				"zipcode": "94030",
				"subPremise": "",
				"address": "815 Banbury Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.815banburylane.com/?mls"
		},
		{
			"closePrice": 1550000,
			"daysOnMovoto": 59,
			"id": "7bb5460b-37dc-4938-af89-8992a0b4b9d1",
			"tnImgPath": "https://pi.movoto.com/p/110/421515935_0_7nEbQj_p.jpeg",
			"listDate": "2021-02-01 00:00:00",
			"listingAgent": "Matthew Ma",
			"listPrice": 1295000,
			"lotSize": 9225,
			"sqftTotal": 1610,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421515935",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1948,
			"zipCode": "94030",
			"path": "millbrae-ca/79-la-prenda-millbrae-ca-94030/pid_5ac6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "186d19f0-0622-4145-acad-4681312e2ac0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-01 00:00:00",
			"createdAt": "2021-02-08 08:36:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-01 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=7w8MA2UQMcR",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.588757,
				"lng": -122.410601,
				"zipcode": "94030",
				"subPremise": "",
				"address": "79 La Prenda"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=7w8MA2UQMcR"
		},
		{
			"closePrice": 740000,
			"daysOnMovoto": 23,
			"id": "7cf2da5e-a123-4fdf-966e-3813cf63e415",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831532_0_EMIYei_p.jpeg",
			"listDate": "2021-02-26 08:59:25",
			"listingAgent": "Brandon Denman",
			"listPrice": 680000,
			"lotSize": null,
			"sqftTotal": 734,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831532",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Denman Real Estate Group, Inc.",
			"photoCount": 16,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1989,
			"zipCode": "94030",
			"path": "millbrae-ca/300-murchison-dr-223-millbrae-ca-94030/pid_fua9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77029fe2-3292-408a-b2ed-8731be7a254f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-02-26 17:02:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-26 08:59:25",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2840503",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.594823,
				"lng": -122.388285,
				"zipcode": "94030",
				"subPremise": "APT 223",
				"address": "300 Murchison Dr #223"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 740000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2840503"
		},
		{
			"closePrice": 1830000,
			"daysOnMovoto": 34,
			"id": "846acb62-0309-4017-90f1-84b31bc3f292",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829412_0_brNN3n_p.jpeg",
			"listDate": "2021-02-11 12:02:37",
			"listingAgent": "Deanna Reudy",
			"listPrice": 1695000,
			"lotSize": 8102,
			"sqftTotal": 1610,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829412",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1950,
			"zipCode": "94030",
			"path": "millbrae-ca/1019-pinehurst-ct-millbrae-ca-94030/pid_3v25w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "07730256-2d19-4102-9d32-392c1dddb71d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-18 00:00:00",
			"createdAt": "2021-02-11 20:07:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-11 12:02:37",
			"virtualTourLink": "Http://www.1019pinehurstct.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.600134,
				"lng": -122.418521,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1019 Pinehurst Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1830000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.1019pinehurstct.com/?mls"
		},
		{
			"closePrice": 650000,
			"daysOnMovoto": 63,
			"id": "9b3d884d-c6c9-4a8f-bd73-d93b718e34b8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824829_0_BfjY26_p.jpeg",
			"listDate": "2021-01-06 17:26:22",
			"listingAgent": "Lucy Goldenshteyn",
			"listPrice": 648000,
			"lotSize": null,
			"sqftTotal": 764,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824829",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1973,
			"zipCode": "94030",
			"path": "millbrae-ca/1550-frontera-way-318-millbrae-ca-94030-100_80549781/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0469dbe9-5701-49c0-89b3-978080994116",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-11 00:00:00",
			"createdAt": "2021-01-07 01:32:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-06 17:26:22",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.582753,
				"lng": -122.402997,
				"zipcode": "94030",
				"subPremise": "APT 318",
				"address": "1550 Frontera Way #318"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2001888,
			"daysOnMovoto": 24,
			"id": "12602d9f-d2cd-4dd3-9c41-d1660f8b5d38",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829444_0_VMYYnm_p.jpeg",
			"listDate": "2021-02-11 13:48:08",
			"listingAgent": "Mary Bee",
			"listPrice": 1899000,
			"lotSize": 6425,
			"sqftTotal": 1900,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829444",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1938,
			"zipCode": "94030",
			"path": "millbrae-ca/220-laurel-ave-millbrae-ca-94030-100_81447915/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e6070a5e-7cf4-48fc-8041-fd6e892391ef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-08 00:00:00",
			"createdAt": "2021-02-11 21:52:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-11 13:48:08",
			"virtualTourLink": "Https://220laurel.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.596335,
				"lng": -122.396638,
				"zipcode": "94030",
				"subPremise": "",
				"address": "220 Laurel Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2001888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://220laurel.com"
		},
		{
			"closePrice": 925000,
			"daysOnMovoto": 48,
			"id": "c38f7de0-9148-4b71-8c62-92186a599e15",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825888_0_VjVR2V_p.jpeg",
			"listDate": "2021-01-14 19:57:12",
			"listingAgent": "Vivienne Kelvin",
			"listPrice": 895000,
			"lotSize": null,
			"sqftTotal": 1249,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825888",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Vabrato",
			"photoCount": 24,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94030",
			"path": "millbrae-ca/360-vallejo-dr-117-millbrae-ca-94030-100_81332995/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2d73a44c-6527-49ed-94ca-b46d4ff1f1d3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-04 00:00:00",
			"createdAt": "2021-02-13 02:57:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-14 19:57:12",
			"virtualTourLink": "Http://www.360vallejodr.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.583644,
				"lng": -122.403998,
				"zipcode": "94030",
				"subPremise": "APT 117",
				"address": "360 Vallejo Dr #117"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 925000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.360vallejodr.com/?mls"
		},
		{
			"closePrice": 1048000,
			"daysOnMovoto": 42,
			"id": "e397a5e2-c54d-4070-b726-ea53a376cfc4",
			"tnImgPath": "https://pi.movoto.com/p/110/512079_0_qnrJQB_p.jpeg",
			"listDate": "2021-01-15 00:00:00",
			"listingAgent": "Ken Kwok",
			"listPrice": 1048000,
			"lotSize": 0,
			"sqftTotal": 1075,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "512079",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "MaxReal",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2010,
			"zipCode": "94030",
			"path": "millbrae-ca/151-el-camino-real-239-millbrae-ca-94030-100_81125446/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "becee7fd-45e9-45c8-9653-3fd771904de8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-26 00:00:00",
			"createdAt": "2021-01-15 20:56:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.599933,
				"lng": -122.389444,
				"zipcode": "94030",
				"subPremise": "APT 239",
				"address": "151 El Camino Real #239"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1048000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1755000,
			"daysOnMovoto": 49,
			"id": "e22ae612-2110-400a-a840-c06c40d4545d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824877_0_qvEM7Y_p.jpeg",
			"listDate": "2021-01-07 10:39:59",
			"listingAgent": "Jeff Lamont",
			"listPrice": 1450000,
			"lotSize": 5040,
			"sqftTotal": 1410,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824877",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1956,
			"zipCode": "94030",
			"path": "millbrae-ca/1175-ridgewood-dr-millbrae-ca-94030/pid_af15w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5248dfbd-cf3e-4311-90ed-ad6fbdb8906b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-26 00:00:00",
			"createdAt": "2021-01-23 03:12:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-07 10:39:59",
			"virtualTourLink": "Https://www.cbhometour.com/1175-Ridgewood-Drive-MIllbrae-CA-94030/mlsindex.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.606111,
				"lng": -122.417769,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1175 Ridgewood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1755000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.cbhometour.com/1175-Ridgewood-Drive-MIllbrae-CA-94030/mlsindex.html"
		},
		{
			"closePrice": 1675000,
			"daysOnMovoto": 65,
			"id": "7818c6a6-afbd-49a8-bba2-ad0c3fe4f5d3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81823322_0_MqaI6N_p.jpeg",
			"listDate": "2020-12-15 17:48:40",
			"listingAgent": "Phil Chen",
			"listPrice": 1700000,
			"lotSize": 5641,
			"sqftTotal": 1500,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81823322",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1956,
			"zipCode": "94030",
			"path": "millbrae-ca/1179-ridgewood-dr-millbrae-ca-94030-100_932047/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77a4cfbc-88f5-42d7-bc98-9fd55d94654a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-19 00:00:00",
			"createdAt": "2020-12-16 01:52:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-15 17:48:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.60619,
				"lng": -122.417931,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1179 Ridgewood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 785000,
			"daysOnMovoto": 157,
			"id": "8fb5d94b-4e77-4d34-be19-cea8f1cb88c0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81810506_0_ZVqI7I_p.jpeg",
			"listDate": "2020-09-13 11:42:30",
			"listingAgent": "Paul Huang",
			"listPrice": 799888,
			"lotSize": null,
			"sqftTotal": 1150,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81810506",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Jc Investments, Inc.",
			"photoCount": 9,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1973,
			"zipCode": "94030",
			"path": "millbrae-ca/1550-frontera-way-213-millbrae-ca-94030/pid_1t38w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -18112,
			"propertyId": "fef64288-8cac-4dd6-a5c6-599638c07f38",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-18 00:00:00",
			"createdAt": "2020-09-13 18:47:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-09-13 11:42:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.582753,
				"lng": -122.402997,
				"zipcode": "94030",
				"subPremise": "APT 213",
				"address": "1550 Frontera Way #213"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 785000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 197,
			"id": "2261958a-75d2-4541-8bd7-947859f0968f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81804378_0_i32jRq_p.jpeg",
			"listDate": "2020-08-04 16:11:18",
			"listingAgent": "Celia Dourado Leao",
			"listPrice": 999000,
			"lotSize": null,
			"sqftTotal": 1043,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81804378",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "AGH Realty Group Corp.",
			"photoCount": 12,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2010,
			"zipCode": "94030",
			"path": "millbrae-ca/151-el-camino-real-225-millbrae-ca-94030-100_81125442/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -101000,
			"propertyId": "76beca9f-df69-4da3-b09e-35ed9ee9b437",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-18 00:00:00",
			"createdAt": "2020-08-04 23:17:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-08-04 16:11:18",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.599933,
				"lng": -122.389444,
				"zipcode": "94030",
				"subPremise": "APT 225",
				"address": "151 El Camino Real #225"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1060000,
			"daysOnMovoto": 41,
			"id": "ee83172d-ec5d-4696-952b-efc9b53dda93",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81816563_0_3B2zUB_p.jpeg",
			"listDate": "2021-01-07 00:00:00",
			"listingAgent": "Serena Lei-ng",
			"listPrice": 998000,
			"lotSize": null,
			"sqftTotal": 1461,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81816563",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1975,
			"zipCode": "94030",
			"path": "millbrae-ca/555-palm-ave-302-millbrae-ca-94030-100_80916555/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "682faef2-8208-416d-b555-47714879e36d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-17 00:00:00",
			"createdAt": "2021-01-07 16:02:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.601293,
				"lng": -122.399747,
				"zipcode": "94030",
				"subPremise": "APT 302",
				"address": "555 Palm Ave #302"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1060000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1820000,
			"daysOnMovoto": 77,
			"id": "adf8b13c-f746-475f-8ca3-eaf7fbf4f8f6",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81816312_0_uQZYVQ_p.jpeg",
			"listDate": "2020-12-01 00:00:00",
			"listingAgent": "Jean Joh",
			"listPrice": 1900000,
			"lotSize": 9448,
			"sqftTotal": 1832,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81816312",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Perisson Real Estate, Inc.",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1956,
			"zipCode": "94030",
			"path": "millbrae-ca/1050-pinehurst-ct-millbrae-ca-94030/pid_sx25w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "70dcbdad-476d-493d-bcd2-fe239fbf4c05",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-16 00:00:00",
			"createdAt": "2021-01-13 23:52:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.60112,
				"lng": -122.419318,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1050 Pinehurst Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1820000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 685000,
			"daysOnMovoto": 11,
			"id": "f4ca877b-6845-41d6-a906-aa7c75bb7508",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827904_0_meUmVy_p.jpeg",
			"listDate": "2021-01-31 22:50:37",
			"listingAgent": "Fay Du",
			"listPrice": 639000,
			"lotSize": null,
			"sqftTotal": 734,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827904",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Bay1st Realty & Mortgage Inc",
			"photoCount": 13,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1989,
			"zipCode": "94030",
			"path": "millbrae-ca/300-murchison-dr-308-millbrae-ca-94030/pid_ixa9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "39e414a2-0a4f-4db1-a251-ad50c32b4f44",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-12 00:00:00",
			"createdAt": "2021-02-01 06:52:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-31 22:50:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.594823,
				"lng": -122.388285,
				"zipcode": "94030",
				"subPremise": "APT 308",
				"address": "300 Murchison Dr #308"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 685000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1580000,
			"daysOnMovoto": 174,
			"id": "2761e9bd-e4dd-40a2-8ab1-3ef0085c656a",
			"tnImgPath": "https://pi.movoto.com/p/110/514420_0_6vjRr2_p.jpeg",
			"listDate": "2020-08-18 00:00:00",
			"listingAgent": "Jade Kwan",
			"listPrice": 1599000,
			"lotSize": 5000,
			"sqftTotal": 1620,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "514420",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "JODI Group",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1946,
			"zipCode": "94030",
			"path": "millbrae-ca/939-palmito-dr-millbrae-ca-94030/pid_ao25w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "91487d12-18fb-4798-9529-9cad9ca47207",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-08 00:00:00",
			"createdAt": "2021-01-28 12:16:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-08-18 00:00:00",
			"virtualTourLink": "Https://www.zillow.com/view-3d-home/df78fe8d-28c1-4273-8f01-909e02aaf7b9",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.611095,
				"lng": -122.403136,
				"zipcode": "94030",
				"subPremise": "",
				"address": "939 Palmito Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1580000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.zillow.com/view-3d-home/df78fe8d-28c1-4273-8f01-909e02aaf7b9"
		},
		{
			"closePrice": 2388000,
			"daysOnMovoto": 13,
			"id": "cd9af0f3-38fc-45fb-ad81-fd3a06e01f23",
			"tnImgPath": "https://pi.movoto.com/p/110/512297_0_723NIu_p.jpeg",
			"listDate": "2021-01-15 00:00:00",
			"listingAgent": "Lynnette Giusti",
			"listPrice": 2198000,
			"lotSize": 6700,
			"sqftTotal": 1980,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "512297",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 59,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1963,
			"zipCode": "94030",
			"path": "millbrae-ca/1465-gavilan-way-millbrae-ca-94030/pid_5se6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2e4db72f-8398-4b57-8d96-81d08d899620",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-01-28 00:00:00",
			"createdAt": "2021-01-15 20:16:54",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-15 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=cJYiiM2ug61",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.583804,
				"lng": -122.400955,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1465 Gavilan Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2388000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=cJYiiM2ug61"
		},
		{
			"closePrice": 2300000,
			"daysOnMovoto": 130,
			"id": "f095c195-0f09-4de5-9fc0-85abfbfedd29",
			"tnImgPath": "https://pi.movoto.com/p/101/22025751_0_mNB7qY_p.jpeg",
			"listDate": "2020-09-17 00:00:00",
			"listingAgent": "Vince Sheehan",
			"listPrice": 2388000,
			"lotSize": 7405,
			"sqftTotal": 2600,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "22025751",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Gold",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1956,
			"zipCode": "94030",
			"path": "millbrae-ca/helen-dr-millbrae-ca-94030-100_80397664/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -110000,
			"propertyId": "80d289ec-e819-49c3-9a80-ac10b33744fc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-01-25 00:00:00",
			"createdAt": "2020-10-22 01:48:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-09-17 00:00:00",
			"virtualTourLink": "Https://player.vimeo.com/video/490591019",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Millbrae",
				"lat": 37.602879,
				"lng": -122.419915,
				"zipcode": "94030",
				"subPremise": "",
				"address": "1125 Helen Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://player.vimeo.com/video/490591019"
		}
	]