exports.MILPITAS_SCHOOLS = [{'address': {'city': 'Milpitas',
                                 'street1': '1285 Escuela Parkway',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '78%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 3177,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 31,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 49, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 19,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 5, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'Filipino',
                                        'percentage': 19,
                                        'rating': 8},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5549,
                     'lat': 37.450802,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/milpitas/5549-Milpitas-High-School/#College_success',
                               'profile': '/california/milpitas/5549-Milpitas-High-School/',
                               'reviews': '/california/milpitas/5549-Milpitas-High-School/#Reviews'},
                     'lon': -121.899811,
                     'name': 'Milpitas High School',
                     'numReviews': 39,
                     'parentRating': 4,
                     'percentLowIncome': '31%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'College Readiness Rating': 10,
                                    'Equity Overview Rating': 7,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1500 Escuela Parkway',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 825,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 29,
                                        'rating': 9},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 55, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 16,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 4, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'Filipino',
                                        'percentage': 19,
                                        'rating': 10},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 5554,
                     'lat': 37.453899,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5554-Thomas-Russell-Middle-School/#College_success',
                         'profile': '/california/milpitas/5554-Thomas-Russell-Middle-School/',
                         'reviews': '/california/milpitas/5554-Thomas-Russell-Middle-School/#Reviews'},
                     'lon': -121.901741,
                     'name': 'Thomas Russell Middle School',
                     'numReviews': 39,
                     'parentRating': 3,
                     'percentLowIncome': '29%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Equity Overview Rating': 9, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '275 Redwood',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 730,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 19,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 67, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 11,
                                        'rating': 6},
                                       {'label': 'Filipino',
                                        'percentage': 11,
                                        'rating': 8},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5548,
                     'lat': 37.440083,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/milpitas/5548-Curtner-Elementary-School/#College_success',
                               'profile': '/california/milpitas/5548-Curtner-Elementary-School/',
                               'reviews': '/california/milpitas/5548-Curtner-Elementary-School/#Reviews'},
                     'lon': -121.912651,
                     'name': 'Curtner Elementary School',
                     'numReviews': 77,
                     'parentRating': 5,
                     'percentLowIncome': '19%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 8,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '2025 Yellowstone Avenue',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 738,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 22,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 67, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 11,
                                        'rating': 7},
                                       {'label': 'Filipino',
                                        'percentage': 9,
                                        'rating': 9},
                                       {'label': 'Two or more races', 'percentage': 6},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5555,
                     'lat': 37.42432,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5555-John-Sinnott-Elementary-School/#College_success',
                         'profile': '/california/milpitas/5555-John-Sinnott-Elementary-School/',
                         'reviews': '/california/milpitas/5555-John-Sinnott-Elementary-School/#Reviews'},
                     'lon': -121.86953,
                     'name': 'John Sinnott Elementary School',
                     'numReviews': 98,
                     'parentRating': 5,
                     'percentLowIncome': '22%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 7,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1505 Escuela Parkway',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 722,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 21,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 65, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 11,
                                        'rating': 6},
                                       {'label': 'Filipino',
                                        'percentage': 11,
                                        'rating': 5},
                                       {'label': 'Two or more races', 'percentage': 6},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5550,
                     'lat': 37.453953,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5550-Marshall-Pomeroy-Elementary-School/#College_success',
                         'profile': '/california/milpitas/5550-Marshall-Pomeroy-Elementary-School/',
                         'reviews': '/california/milpitas/5550-Marshall-Pomeroy-Elementary-School/#Reviews'},
                     'lon': -121.902138,
                     'name': 'Marshall Pomeroy Elementary School',
                     'numReviews': 105,
                     'parentRating': 4,
                     'percentLowIncome': '21%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '400 Fanyon',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 539,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 34,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 48, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 17,
                                        'rating': 3},
                                       {'label': 'Filipino',
                                        'percentage': 21,
                                        'rating': 7},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5546,
                     'lat': 37.441689,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5546-William-Burnett-Elementary-School/#College_success',
                         'profile': '/california/milpitas/5546-William-Burnett-Elementary-School/',
                         'reviews': '/california/milpitas/5546-William-Burnett-Elementary-School/#Reviews'},
                     'lon': -121.882271,
                     'name': 'William Burnett Elementary School',
                     'numReviews': 45,
                     'parentRating': 4,
                     'percentLowIncome': '34%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1915 Yellowstone Avenue',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 717,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 33,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 44, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 26,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 4, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 3,
                                        'rating': 8},
                                       {'label': 'Filipino',
                                        'percentage': 18,
                                        'rating': 9},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 5551,
                     'lat': 37.42487,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5551-Rancho-Milpitas-Middle-School/#College_success',
                         'profile': '/california/milpitas/5551-Rancho-Milpitas-Middle-School/',
                         'reviews': '/california/milpitas/5551-Rancho-Milpitas-Middle-School/#Reviews'},
                     'lon': -121.870018,
                     'name': 'Rancho Milpitas Middle School',
                     'numReviews': 32,
                     'parentRating': 5,
                     'percentLowIncome': '33%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Equity Overview Rating': 5, 'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '140 North Abbott',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 589,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 28,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 41, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 21,
                                        'rating': 5},
                                       {'label': 'Filipino',
                                        'percentage': 21,
                                        'rating': 8},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'Two or more races', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5556,
                     'lat': 37.430325,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5556-Anthony-Spangler-Elementary-School/#College_success',
                         'profile': '/california/milpitas/5556-Anthony-Spangler-Elementary-School/',
                         'reviews': '/california/milpitas/5556-Anthony-Spangler-Elementary-School/#Reviews'},
                     'lon': -121.914558,
                     'name': 'Anthony Spangler Elementary School',
                     'numReviews': 47,
                     'parentRating': 4,
                     'percentLowIncome': '28%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '345 Boulder Drive',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 454,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 39,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 42, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 23,
                                        'rating': 5},
                                       {'label': 'Filipino',
                                        'percentage': 24,
                                        'rating': 9},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5557,
                     'lat': 37.457485,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5557-Joseph-Weller-Elementary-School/#College_success',
                         'profile': '/california/milpitas/5557-Joseph-Weller-Elementary-School/',
                         'reviews': '/california/milpitas/5557-Joseph-Weller-Elementary-School/#Reviews'},
                     'lon': -121.905411,
                     'name': 'Joseph Weller Elementary School',
                     'numReviews': 19,
                     'parentRating': 4,
                     'percentLowIncome': '39%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1585 Fallen Leaf Drive',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 635,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 26,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 52, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 17,
                                        'rating': 5},
                                       {'label': 'Two or more races',
                                        'percentage': 7,
                                        'rating': 7},
                                       {'label': 'Filipino',
                                        'percentage': 14,
                                        'rating': 8},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 3}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5558,
                     'lat': 37.407665,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5558-Pearl-Zanker-Elementary-School/#College_success',
                         'profile': '/california/milpitas/5558-Pearl-Zanker-Elementary-School/',
                         'reviews': '/california/milpitas/5558-Pearl-Zanker-Elementary-School/#Reviews'},
                     'lon': -121.904861,
                     'name': 'Pearl Zanker Elementary School',
                     'numReviews': 44,
                     'parentRating': 4,
                     'percentLowIncome': '26%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '250 Roswell Drive',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 479,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 57,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 34, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 33,
                                        'rating': 4},
                                       {'label': 'Filipino',
                                        'percentage': 17,
                                        'rating': 7},
                                       {'label': 'Two or more races', 'percentage': 8},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5553,
                     'lat': 37.43222,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5553-Alexander-Rose-Elementary-School/#College_success',
                         'profile': '/california/milpitas/5553-Alexander-Rose-Elementary-School/',
                         'reviews': '/california/milpitas/5553-Alexander-Rose-Elementary-School/#Reviews'},
                     'lon': -121.877068,
                     'name': 'Alexander Rose Elementary School',
                     'numReviews': 20,
                     'parentRating': 5,
                     'percentLowIncome': '57%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1331 East Calaveras Boulevard',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '44%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 106,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 55,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 53,
                                        'rating': 2},
                                       {'label': 'African American', 'percentage': 14},
                                       {'label': 'Filipino', 'percentage': 11},
                                       {'label': 'Asian', 'percentage': 8},
                                       {'label': 'White', 'percentage': 8},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'Pacific Islander', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5547,
                     'lat': 37.43589,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/milpitas/5547-Calaveras-Hills-School/#College_success',
                               'profile': '/california/milpitas/5547-Calaveras-Hills-School/',
                               'reviews': '/california/milpitas/5547-Calaveras-Hills-School/#Reviews'},
                     'lon': -121.883507,
                     'name': 'Calaveras Hills School',
                     'numReviews': 9,
                     'parentRating': 4,
                     'percentLowIncome': '55%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 8,
                     'subratings': {'College Readiness Rating': 4,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1300 Edsel Drive',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 348,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 65,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 62,
                                        'rating': 1},
                                       {'label': 'Filipino',
                                        'percentage': 17,
                                        'rating': 3},
                                       {'label': 'Asian', 'percentage': 15},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5552,
                     'lat': 37.430347,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/5552-Robert-Randall-Elementary-School/#College_success',
                         'profile': '/california/milpitas/5552-Robert-Randall-Elementary-School/',
                         'reviews': '/california/milpitas/5552-Robert-Randall-Elementary-School/#Reviews'},
                     'lon': -121.880737,
                     'name': 'Robert Randall Elementary School',
                     'numReviews': 35,
                     'parentRating': 4,
                     'percentLowIncome': '65%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1128 Jacklin Road',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29805,
                     'lat': 37.44688,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/29805-Wing-Education-Child-Care-Center/#College_success',
                         'profile': '/california/milpitas/29805-Wing-Education-Child-Care-Center/',
                         'reviews': '/california/milpitas/29805-Wing-Education-Child-Care-Center/#Reviews'},
                     'lon': -121.889755,
                     'name': 'Wing Education Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '2343 Cascade Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29808,
                     'lat': 37.423603,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/29808-Tian-Tian-Chinese-Pre-School/#College_success',
                         'profile': '/california/milpitas/29808-Tian-Tian-Chinese-Pre-School/',
                         'reviews': '/california/milpitas/29808-Tian-Tian-Chinese-Pre-School/#Reviews'},
                     'lon': -121.863045,
                     'name': 'Tian Tian Chinese Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '451 Los Coches Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29847,
                     'lat': 37.431282,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/29847-Omei-Chinese-School-Child-Care-Center/#College_success',
                         'profile': '/california/milpitas/29847-Omei-Chinese-School-Child-Care-Center/',
                         'reviews': '/california/milpitas/29847-Omei-Chinese-School-Child-Care-Center/#Reviews'},
                     'lon': -121.897758,
                     'name': 'Omei Chinese School Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '301 S ABBOTT AVE',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 30012,
                     'lat': 37.42551,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/milpitas/30012-Achieving-Stars-Academy/#College_success',
                               'profile': '/california/milpitas/30012-Achieving-Stars-Academy/',
                               'reviews': '/california/milpitas/30012-Achieving-Stars-Academy/#Reviews'},
                     'lon': -121.913559,
                     'name': 'Achieving Stars Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '630 South Main Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-3',
                     'highlighted': false,
                     'id': 30185,
                     'lat': 37.421467,
                     'levelCode': 'p,e',
                     'links': {'collegeSuccess': '/california/milpitas/30185-Main-Street-Montessori/#College_success',
                               'profile': '/california/milpitas/30185-Main-Street-Montessori/',
                               'reviews': '/california/milpitas/30185-Main-Street-Montessori/#Reviews'},
                     'lon': -121.90419,
                     'name': 'Main Street Montessori',
                     'numReviews': 7,
                     'parentRating': 1,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1402 Dempsey Road',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30234,
                     'lat': 37.416111,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/milpitas/30234-Safari-Kid-Day-Care/#College_success',
                               'profile': '/california/milpitas/30234-Safari-Kid-Day-Care/',
                               'reviews': '/california/milpitas/30234-Safari-Kid-Day-Care/#Reviews'},
                     'lon': -121.879143,
                     'name': 'Safari Kid Day Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1146 Jacklin Road',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30500,
                     'lat': 37.446827,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/30500-Global-Village-Mont-School/#College_success',
                         'profile': '/california/milpitas/30500-Global-Village-Mont-School/',
                         'reviews': '/california/milpitas/30500-Global-Village-Mont-School/#Reviews'},
                     'lon': -121.889893,
                     'name': 'Global Village Mont School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '200 Serra Way #50',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 31031,
                     'lat': 37.426826,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/31031-Little-Flowers-Montessori/#College_success',
                         'profile': '/california/milpitas/31031-Little-Flowers-Montessori/',
                         'reviews': '/california/milpitas/31031-Little-Flowers-Montessori/#Reviews'},
                     'lon': -121.908989,
                     'name': 'Little Flowers Montessori',
                     'numReviews': 1,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '227 S. Main Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 31636,
                     'lat': 37.427273,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/31636-Rainbow-Childhood-Development-Center/#College_success',
                         'profile': '/california/milpitas/31636-Rainbow-Childhood-Development-Center/',
                         'reviews': '/california/milpitas/31636-Rainbow-Childhood-Development-Center/#Reviews'},
                     'lon': -121.906502,
                     'name': 'Rainbow Childhood Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '123 Corning Ave',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 31645,
                     'lat': 37.424713,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/31645-Merryhill-School-Milpitas-Preschool/#College_success',
                         'profile': '/california/milpitas/31645-Merryhill-School-Milpitas-Preschool/',
                         'reviews': '/california/milpitas/31645-Merryhill-School-Milpitas-Preschool/#Reviews'},
                     'lon': -121.907898,
                     'name': 'Merryhill School Milpitas Preschool',
                     'numReviews': 20,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1500 Yosemite Drive',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 27,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 75},
                                       {'label': 'White', 'percentage': 19},
                                       {'label': 'Two or more races', 'percentage': 6}],
                     'gradeLevels': 'PK-2',
                     'highlighted': false,
                     'id': 12989,
                     'lat': 37.428062,
                     'levelCode': 'p,e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/12989-Milpitas-Montessori-School/#College_success',
                         'profile': '/california/milpitas/12989-Milpitas-Montessori-School/',
                         'reviews': '/california/milpitas/12989-Milpitas-Montessori-School/#Reviews'},
                     'lon': -121.876831,
                     'name': 'Milpitas Montessori School',
                     'numReviews': 76,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1991 Landess Avenue',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 121,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 38},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 38},
                                       {'label': 'Two or more races', 'percentage': 10},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 5},
                                       {'label': 'Pacific Islander', 'percentage': 3},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 9777,
                     'lat': 37.419327,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/milpitas/9777-Foothill-Adventist-Elementary-School/#College_success',
                         'profile': '/california/milpitas/9777-Foothill-Adventist-Elementary-School/',
                         'reviews': '/california/milpitas/9777-Foothill-Adventist-Elementary-School/#Reviews'},
                     'lon': -121.86853,
                     'name': 'Foothill Adventist Elementary School',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '360 South Abel Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 218,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 92},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Hispanic', 'percentage': 1}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 9894,
                     'lat': 37.426205,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/milpitas/9894-St.-John-The-Baptist-Catholic-School/#College_success',
                         'profile': '/california/milpitas/9894-St.-John-The-Baptist-Catholic-School/',
                         'reviews': '/california/milpitas/9894-St.-John-The-Baptist-Catholic-School/#Reviews'},
                     'lon': -121.905899,
                     'name': 'St. John the Baptist Catholic School',
                     'numReviews': 4,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '400 South Abel Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 32,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Pacific Islander', 'percentage': 58},
                                       {'label': 'Asian', 'percentage': 42}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 12667,
                     'lat': 37.424438,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/12667-Kindercare-Learning-Center/#College_success',
                         'profile': '/california/milpitas/12667-Kindercare-Learning-Center/',
                         'reviews': '/california/milpitas/12667-Kindercare-Learning-Center/#Reviews'},
                     'lon': -121.906639,
                     'name': 'Kindercare Learning Center',
                     'numReviews': 1,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1500 Yosemite Drive',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 525,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 68},
                                       {'label': 'White', 'percentage': 30},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 10632,
                     'lat': 37.428013,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/milpitas/10632-Merryhill-School/#College_success',
                               'profile': '/california/milpitas/10632-Merryhill-School/',
                               'reviews': '/california/milpitas/10632-Merryhill-School/#Reviews'},
                     'lon': -121.876831,
                     'name': 'Merryhill School',
                     'numReviews': 40,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '860 North Hillview Drive',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 36,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 42},
                                       {'label': 'Two or more races', 'percentage': 25},
                                       {'label': 'White', 'percentage': 17},
                                       {'label': 'African American', 'percentage': 8},
                                       {'label': 'Hispanic', 'percentage': 8}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 10833,
                     'lat': 37.445847,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/10833-Kindercare-Learning-Center/#College_success',
                         'profile': '/california/milpitas/10833-Kindercare-Learning-Center/',
                         'reviews': '/california/milpitas/10833-Kindercare-Learning-Center/#Reviews'},
                     'lon': -121.893036,
                     'name': 'Kindercare Learning Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1715 East Calaveras Boulevard',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 173,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 64},
                                       {'label': 'Two or more races', 'percentage': 29},
                                       {'label': 'Hispanic', 'percentage': 4},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'PK-4',
                     'highlighted': false,
                     'id': 12896,
                     'lat': 37.437965,
                     'levelCode': 'p,e',
                     'links': {'collegeSuccess': '/california/milpitas/12896-Monarch-Christian-School/#College_success',
                               'profile': '/california/milpitas/12896-Monarch-Christian-School/',
                               'reviews': '/california/milpitas/12896-Monarch-Christian-School/#Reviews'},
                     'lon': -121.875961,
                     'name': 'Monarch Christian School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1331 East Calaveras Boulevard',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Milpitas',
                     'districtId': 644,
                     'districtName': 'Milpitas Unified School District',
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'Ungraded',
                     'highlighted': false,
                     'id': 14503,
                     'lat': 37.436886,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/milpitas/14503-Milpitas-Adult/#College_success',
                               'profile': '/california/milpitas/14503-Milpitas-Adult/',
                               'reviews': '/california/milpitas/14503-Milpitas-Adult/#Reviews'},
                     'lon': -121.882477,
                     'name': 'Milpitas Adult',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '856 Kizer Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '2-12',
                     'highlighted': false,
                     'id': 16670,
                     'lat': 37.4505,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/milpitas/16670-Lang-Learning-Center/#College_success',
                               'profile': '/california/milpitas/16670-Lang-Learning-Center/',
                               'reviews': '/california/milpitas/16670-Lang-Learning-Center/#Reviews'},
                     'lon': -121.895599,
                     'name': 'Lang Learning Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '697 Lexington Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 24,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '1-12',
                     'highlighted': false,
                     'id': 17380,
                     'lat': 37.437317,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/milpitas/17380-Plantation-Christian/#College_success',
                               'profile': '/california/milpitas/17380-Plantation-Christian/',
                               'reviews': '/california/milpitas/17380-Plantation-Christian/#Reviews'},
                     'lon': -121.913338,
                     'name': 'Plantation Christian',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '10000 S. Park Victoria Ave',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20501,
                     'lat': 37.420116,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/20501-Milpitas-Christian-Pre-School/#College_success',
                         'profile': '/california/milpitas/20501-Milpitas-Christian-Pre-School/',
                         'reviews': '/california/milpitas/20501-Milpitas-Christian-Pre-School/#Reviews'},
                     'lon': -121.875778,
                     'name': 'Milpitas Christian Pre-School',
                     'numReviews': 5,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1400 South Main Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 20502,
                     'lat': 37.410446,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/milpitas/20502-First-Years/#College_success',
                               'profile': '/california/milpitas/20502-First-Years/',
                               'reviews': '/california/milpitas/20502-First-Years/#Reviews'},
                     'lon': -121.901711,
                     'name': 'First Years',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1651 North Milpitas Blvd',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20504,
                     'lat': 37.454765,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/milpitas/20504-Footprints-Pre-School/#College_success',
                               'profile': '/california/milpitas/20504-Footprints-Pre-School/',
                               'reviews': '/california/milpitas/20504-Footprints-Pre-School/#Reviews'},
                     'lon': -121.910965,
                     'name': 'Footprints Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1905 North Milpitas Boulevard',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 65,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 80},
                                       {'label': 'African American', 'percentage': 10},
                                       {'label': 'White', 'percentage': 10}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 20505,
                     'lat': 37.458282,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/20505-Golden-Harvest-Montessori-School/#College_success',
                         'profile': '/california/milpitas/20505-Golden-Harvest-Montessori-School/',
                         'reviews': '/california/milpitas/20505-Golden-Harvest-Montessori-School/#Reviews'},
                     'lon': -121.912086,
                     'name': 'Golden Harvest Montessori School',
                     'numReviews': 31,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '195 North Main Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20506,
                     'lat': 37.433052,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/20506-Wonderland-Day-Care-Center/#College_success',
                         'profile': '/california/milpitas/20506-Wonderland-Day-Care-Center/',
                         'reviews': '/california/milpitas/20506-Wonderland-Day-Care-Center/#Reviews'},
                     'lon': -121.908112,
                     'name': 'Wonderland Day Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1991 Landess Avenue',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20507,
                     'lat': 37.419468,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/milpitas/20507-Milpitas-Discoveryland/#College_success',
                               'profile': '/california/milpitas/20507-Milpitas-Discoveryland/',
                               'reviews': '/california/milpitas/20507-Milpitas-Discoveryland/#Reviews'},
                     'lon': -121.868614,
                     'name': 'Milpitas Discoveryland',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '215 Dempsey Road',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 62,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 90},
                                       {'label': 'Two or more races', 'percentage': 10}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 20508,
                     'lat': 37.430981,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/20508-Day-Star-Montessori-School/#College_success',
                         'profile': '/california/milpitas/20508-Day-Star-Montessori-School/',
                         'reviews': '/california/milpitas/20508-Day-Star-Montessori-School/#Reviews'},
                     'lon': -121.885651,
                     'name': 'Day Star Montessori School',
                     'numReviews': 3,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '250a Roswell Dr',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20509,
                     'lat': 37.432217,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/20509-Rose-Child-Development-Center/#College_success',
                         'profile': '/california/milpitas/20509-Rose-Child-Development-Center/',
                         'reviews': '/california/milpitas/20509-Rose-Child-Development-Center/#Reviews'},
                     'lon': -121.877068,
                     'name': 'Rose Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': 'Po Box 361436',
                                 'street2': '',
                                 'zip': '95036'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20510,
                     'lat': 37.43,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/20510-Milpitas-Parents-Pre-School/#College_success',
                         'profile': '/california/milpitas/20510-Milpitas-Parents-Pre-School/',
                         'reviews': '/california/milpitas/20510-Milpitas-Parents-Pre-School/#Reviews'},
                     'lon': -121.910004,
                     'name': 'Milpitas Parents Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '356 Dixon Rd',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20511,
                     'lat': 37.459934,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/20511-Sunnyhills-Child-Development-Center/#College_success',
                         'profile': '/california/milpitas/20511-Sunnyhills-Child-Development-Center/',
                         'reviews': '/california/milpitas/20511-Sunnyhills-Child-Development-Center/#Reviews'},
                     'lon': -121.904259,
                     'name': 'Sunnyhills Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '40 East Carlo Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 20512,
                     'lat': 37.430458,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/milpitas/20512-Elan-Esprit-Pre-School/#College_success',
                               'profile': '/california/milpitas/20512-Elan-Esprit-Pre-School/',
                               'reviews': '/california/milpitas/20512-Elan-Esprit-Pre-School/#Reviews'},
                     'lon': -121.906006,
                     'name': 'Elan Esprit Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '637 South Main Street',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 20513,
                     'lat': 37.420967,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/milpitas/20513-Hands-On-Learning/#College_success',
                               'profile': '/california/milpitas/20513-Hands-On-Learning/',
                               'reviews': '/california/milpitas/20513-Hands-On-Learning/#Reviews'},
                     'lon': -121.904442,
                     'name': 'Hands On Learning',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '757 East Capitol Avenue',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20514,
                     'lat': 37.408211,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/20514-Crossing-Early-Child-Care-Center/#College_success',
                         'profile': '/california/milpitas/20514-Crossing-Early-Child-Care-Center/',
                         'reviews': '/california/milpitas/20514-Crossing-Early-Child-Care-Center/#Reviews'},
                     'lon': -121.889618,
                     'name': 'Crossing Early Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '800 Barber Ln',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20515,
                     'lat': 37.415405,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/milpitas/20515-Cisco-Family-Center-DC/#College_success',
                               'profile': '/california/milpitas/20515-Cisco-Family-Center-DC/',
                               'reviews': '/california/milpitas/20515-Cisco-Family-Center-DC/#Reviews'},
                     'lon': -121.914162,
                     'name': 'Cisco Family Center DC',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1331 e Calaveras Blvd',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 10,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 40},
                                       {'label': 'White', 'percentage': 30},
                                       {'label': 'Hispanic', 'percentage': 30}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 24176,
                     'lat': 37.436886,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/milpitas/24176-Calaveras-Montessori/#College_success',
                               'profile': '/california/milpitas/24176-Calaveras-Montessori/',
                               'reviews': '/california/milpitas/24176-Calaveras-Montessori/#Reviews'},
                     'lon': -121.882484,
                     'name': 'Calaveras Montessori',
                     'numReviews': 1,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1715 E Calaveras',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K',
                     'highlighted': false,
                     'id': 24388,
                     'lat': 37.438271,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/milpitas/24388-Monarch-Christian-Schools/#College_success',
                         'profile': '/california/milpitas/24388-Monarch-Christian-Schools/',
                         'reviews': '/california/milpitas/24388-Monarch-Christian-Schools/#Reviews'},
                     'lon': -121.875961,
                     'name': 'Monarch Christian Schools',
                     'numReviews': 3,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '310 Monmouth Drive',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 6,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '2-9',
                     'highlighted': false,
                     'id': 26339,
                     'lat': 37.432056,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/milpitas/26339-C.H.I.N.G.-Academy/#College_success',
                               'profile': '/california/milpitas/26339-C.H.I.N.G.-Academy/',
                               'reviews': '/california/milpitas/26339-C.H.I.N.G.-Academy/#Reviews'},
                     'lon': -121.877754,
                     'name': 'C.H.I.N.G. Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1146 Jacklin Rd',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 26932,
                     'lat': 37.447353,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/26932-Global-Village-Montessori/#College_success',
                         'profile': '/california/milpitas/26932-Global-Village-Montessori/',
                         'reviews': '/california/milpitas/26932-Global-Village-Montessori/#Reviews'},
                     'lon': -121.889244,
                     'name': 'Global Village Montessori',
                     'numReviews': 15,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '1651 North Milpitas Blvd',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 32321,
                     'lat': 37.454365,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/milpitas/32321-Crescent-Montessori-School-Of-Silicon-Valley/#College_success',
                         'profile': '/california/milpitas/32321-Crescent-Montessori-School-Of-Silicon-Valley/',
                         'reviews': '/california/milpitas/32321-Crescent-Montessori-School-Of-Silicon-Valley/#Reviews'},
                     'lon': -121.911255,
                     'name': 'Crescent Montessori School of Silicon Valley',
                     'numReviews': 11,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'Milpitas',
                                 'street1': '341 Great Mall Parkway',
                                 'street2': '',
                                 'zip': '95035'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 32386,
                     'lat': 37.4109,
                     'levelCode': 'p,e,m',
                     'links': {'collegeSuccess': '/california/milpitas/32386-Stratford-School/#College_success',
                               'profile': '/california/milpitas/32386-Stratford-School/',
                               'reviews': '/california/milpitas/32386-Stratford-School/#Reviews'},
                     'lon': -121.894028,
                     'name': 'Stratford School',
                     'numReviews': 20,
                     'parentRating': 1,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'}]
