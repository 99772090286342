const las_vegas = {
    "geography": {
       "census_release": "ACS 2019 1-year",
       "parents": {
          "county": {
             "full_name": "Clark County, NV",
             "short_name": "Clark County",
             "sumlevel": "050",
             "land_area": 20439276974,
             "full_geoid": "05000US32003",
             "total_population": 2266715
          },
          "CBSA": {
             "full_name": "Las Vegas-Henderson-Paradise, NV Metro Area",
             "short_name": "Las Vegas-Henderson-Paradise, NV",
             "sumlevel": "310",
             "land_area": 20439276974,
             "full_geoid": "31000US29820",
             "total_population": 2266715
          },
          "state": {
             "full_name": "Nevada",
             "short_name": "Nevada",
             "sumlevel": "040",
             "land_area": 284537049864,
             "full_geoid": "04000US32",
             "total_population": 3080156
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 328239523
          }
       },
       "this": {
          "full_name": "Las Vegas, NV",
          "short_name": "Las Vegas",
          "sumlevel": "160",
          "land_area": 367175351,
          "full_geoid": "16000US3240000",
          "total_population": 651297,
          "sumlevel_name": "Place",
          "short_geoid": "3240000"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "1"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 24.38,
                   "CBSA": 22.98,
                   "state": 22.43
                },
                "error": {
                   "this": 1.06,
                   "CBSA": 0.33,
                   "state": 0.3
                },
                "numerators": {
                   "this": 158764,
                   "CBSA": 520797,
                   "state": 690737
                },
                "numerator_errors": {
                   "this": 6912,
                   "CBSA": 7432.8,
                   "state": 9228.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 109
                },
                "error_ratio": {
                   "this": 4.3,
                   "CBSA": 1.4,
                   "state": 1.3
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 60.77,
                   "CBSA": 61.91,
                   "state": 61.4
                },
                "error": {
                   "this": 1.52,
                   "CBSA": 0.5,
                   "state": 0.46
                },
                "numerators": {
                   "this": 395797,
                   "CBSA": 1403259,
                   "state": 1891200
                },
                "numerator_errors": {
                   "this": 9919.5,
                   "CBSA": 11445.5,
                   "state": 14073.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 99
                },
                "error_ratio": {
                   "this": 2.5,
                   "CBSA": 0.8,
                   "state": 0.7
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 14.85,
                   "CBSA": 15.12,
                   "state": 16.18
                },
                "error": {
                   "this": 0.69,
                   "CBSA": 0.34,
                   "state": 0.3
                },
                "numerators": {
                   "this": 96736,
                   "CBSA": 342659,
                   "state": 498219
                },
                "numerator_errors": {
                   "this": 4470.7,
                   "CBSA": 7741.2,
                   "state": 9367.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 92
                },
                "error_ratio": {
                   "this": 4.6,
                   "CBSA": 2.2,
                   "state": 1.9
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 13,
                      "CBSA": 12.28,
                      "state": 12
                   },
                   "error": {
                      "this": 0.79,
                      "CBSA": 0.23,
                      "state": 0.21
                   },
                   "numerators": {
                      "this": 84669,
                      "CBSA": 278421,
                      "state": 369492
                   },
                   "numerator_errors": {
                      "this": 5131,
                      "CBSA": 5258.1,
                      "state": 6541
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 106,
                      "state": 108
                   },
                   "error_ratio": {
                      "this": 6.1,
                      "CBSA": 1.9,
                      "state": 1.7
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 13.58,
                      "CBSA": 12.93,
                      "state": 12.71
                   },
                   "error": {
                      "this": 0.76,
                      "CBSA": 0.23,
                      "state": 0.22
                   },
                   "numerators": {
                      "this": 88420,
                      "CBSA": 293195,
                      "state": 391347
                   },
                   "numerator_errors": {
                      "this": 4956,
                      "CBSA": 5284.1,
                      "state": 6774.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 105,
                      "state": 107
                   },
                   "error_ratio": {
                      "this": 5.6,
                      "CBSA": 1.8,
                      "state": 1.7
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 13.3,
                      "CBSA": 13.45,
                      "state": 13.22
                   },
                   "error": {
                      "this": 0.74,
                      "CBSA": 0.23,
                      "state": 0.21
                   },
                   "numerators": {
                      "this": 86621,
                      "CBSA": 304819,
                      "state": 407233
                   },
                   "numerator_errors": {
                      "this": 4799.2,
                      "CBSA": 5199.9,
                      "state": 6473.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 99,
                      "state": 101
                   },
                   "error_ratio": {
                      "this": 5.6,
                      "CBSA": 1.7,
                      "state": 1.6
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 14.51,
                      "CBSA": 14.65,
                      "state": 14.34
                   },
                   "error": {
                      "this": 0.72,
                      "CBSA": 0.23,
                      "state": 0.21
                   },
                   "numerators": {
                      "this": 94491,
                      "CBSA": 332094,
                      "state": 441679
                   },
                   "numerator_errors": {
                      "this": 4696,
                      "CBSA": 5121,
                      "state": 6467.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 99,
                      "state": 101
                   },
                   "error_ratio": {
                      "this": 5,
                      "CBSA": 1.6,
                      "state": 1.5
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 12.14,
                      "CBSA": 13.29,
                      "state": 12.81
                   },
                   "error": {
                      "this": 0.7,
                      "CBSA": 0.22,
                      "state": 0.2
                   },
                   "numerators": {
                      "this": 79067,
                      "CBSA": 301182,
                      "state": 394503
                   },
                   "numerator_errors": {
                      "this": 4585.9,
                      "CBSA": 5035.9,
                      "state": 6023.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 91,
                      "state": 95
                   },
                   "error_ratio": {
                      "this": 5.8,
                      "CBSA": 1.7,
                      "state": 1.6
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 12.62,
                      "CBSA": 12.59,
                      "state": 12.75
                   },
                   "error": {
                      "this": 0.67,
                      "CBSA": 0.21,
                      "state": 0.19
                   },
                   "numerators": {
                      "this": 82203,
                      "CBSA": 285297,
                      "state": 392830
                   },
                   "numerator_errors": {
                      "this": 4351,
                      "CBSA": 4714.7,
                      "state": 5765.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 5.3,
                      "CBSA": 1.7,
                      "state": 1.5
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 10.96,
                      "CBSA": 10.74,
                      "state": 11.41
                   },
                   "error": {
                      "this": 0.63,
                      "CBSA": 0.32,
                      "state": 0.28
                   },
                   "numerators": {
                      "this": 71368,
                      "CBSA": 243558,
                      "state": 351577
                   },
                   "numerator_errors": {
                      "this": 4084.3,
                      "CBSA": 7302.5,
                      "state": 8560.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 96
                   },
                   "error_ratio": {
                      "this": 5.7,
                      "CBSA": 3,
                      "state": 2.5
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 6.75,
                      "CBSA": 7.04,
                      "state": 7.63
                   },
                   "error": {
                      "this": 0.45,
                      "CBSA": 0.21,
                      "state": 0.19
                   },
                   "numerators": {
                      "this": 43985,
                      "CBSA": 159562,
                      "state": 235090
                   },
                   "numerator_errors": {
                      "this": 2903.5,
                      "CBSA": 4729.2,
                      "state": 5899.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 96,
                      "state": 88
                   },
                   "error_ratio": {
                      "this": 6.7,
                      "CBSA": 3,
                      "state": 2.5
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 3.14,
                      "CBSA": 3.03,
                      "state": 3.13
                   },
                   "error": {
                      "this": 0.35,
                      "CBSA": 0.17,
                      "state": 0.15
                   },
                   "numerators": {
                      "this": 20473,
                      "CBSA": 68587,
                      "state": 96405
                   },
                   "numerator_errors": {
                      "this": 2260.9,
                      "CBSA": 3749.6,
                      "state": 4579.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 104,
                      "state": 100
                   },
                   "error_ratio": {
                      "this": 11.1,
                      "CBSA": 5.6,
                      "state": 4.8
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 13.19,
                      "CBSA": 12.48,
                      "state": 12.13
                   },
                   "error": {
                      "this": 1.1,
                      "CBSA": 0.36,
                      "state": 0.31
                   },
                   "numerators": {
                      "this": 43452,
                      "CBSA": 141057,
                      "state": 187388
                   },
                   "numerator_errors": {
                      "this": 3674,
                      "CBSA": 4104.9,
                      "state": 4737.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 106,
                      "state": 109
                   },
                   "error_ratio": {
                      "this": 8.3,
                      "CBSA": 2.9,
                      "state": 2.6
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 14.09,
                      "CBSA": 13.4,
                      "state": 13.08
                   },
                   "error": {
                      "this": 0.99,
                      "CBSA": 0.36,
                      "state": 0.32
                   },
                   "numerators": {
                      "this": 46439,
                      "CBSA": 151455,
                      "state": 202082
                   },
                   "numerator_errors": {
                      "this": 3356.6,
                      "CBSA": 4124,
                      "state": 5031
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 105,
                      "state": 108
                   },
                   "error_ratio": {
                      "this": 7,
                      "CBSA": 2.7,
                      "state": 2.4
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 13.35,
                      "CBSA": 13.67,
                      "state": 13.45
                   },
                   "error": {
                      "this": 1.05,
                      "CBSA": 0.31,
                      "state": 0.29
                   },
                   "numerators": {
                      "this": 43987,
                      "CBSA": 154504,
                      "state": 207824
                   },
                   "numerator_errors": {
                      "this": 3515.1,
                      "CBSA": 3478.9,
                      "state": 4506.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 7.9,
                      "CBSA": 2.3,
                      "state": 2.2
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 14.53,
                      "CBSA": 14.85,
                      "state": 14.57
                   },
                   "error": {
                      "this": 1.02,
                      "CBSA": 0.33,
                      "state": 0.31
                   },
                   "numerators": {
                      "this": 47862,
                      "CBSA": 167903,
                      "state": 225072
                   },
                   "numerator_errors": {
                      "this": 3455.8,
                      "CBSA": 3720.2,
                      "state": 4749.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 100
                   },
                   "error_ratio": {
                      "this": 7,
                      "CBSA": 2.2,
                      "state": 2.1
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 12.01,
                      "CBSA": 13.33,
                      "state": 12.95
                   },
                   "error": {
                      "this": 0.99,
                      "CBSA": 0.32,
                      "state": 0.29
                   },
                   "numerators": {
                      "this": 39567,
                      "CBSA": 150675,
                      "state": 199979
                   },
                   "numerator_errors": {
                      "this": 3313.9,
                      "CBSA": 3591.4,
                      "state": 4549.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 90,
                      "state": 93
                   },
                   "error_ratio": {
                      "this": 8.2,
                      "CBSA": 2.4,
                      "state": 2.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 12.82,
                      "CBSA": 12.52,
                      "state": 12.76
                   },
                   "error": {
                      "this": 0.88,
                      "CBSA": 0.28,
                      "state": 0.25
                   },
                   "numerators": {
                      "this": 42229,
                      "CBSA": 141530,
                      "state": 197133
                   },
                   "numerator_errors": {
                      "this": 2960.8,
                      "CBSA": 3209.3,
                      "state": 3944.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 102,
                      "state": 100
                   },
                   "error_ratio": {
                      "this": 6.9,
                      "CBSA": 2.2,
                      "state": 2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 10.97,
                      "CBSA": 10.5,
                      "state": 11.11
                   },
                   "error": {
                      "this": 0.89,
                      "CBSA": 0.45,
                      "state": 0.37
                   },
                   "numerators": {
                      "this": 36138,
                      "CBSA": 118717,
                      "state": 171669
                   },
                   "numerator_errors": {
                      "this": 2982.4,
                      "CBSA": 5046,
                      "state": 5786.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 104,
                      "state": 99
                   },
                   "error_ratio": {
                      "this": 8.1,
                      "CBSA": 4.3,
                      "state": 3.3
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 6.37,
                      "CBSA": 6.6,
                      "state": 7.17
                   },
                   "error": {
                      "this": 0.56,
                      "CBSA": 0.28,
                      "state": 0.26
                   },
                   "numerators": {
                      "this": 20977,
                      "CBSA": 74572,
                      "state": 110702
                   },
                   "numerator_errors": {
                      "this": 1860.2,
                      "CBSA": 3146.9,
                      "state": 4030.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 97,
                      "state": 89
                   },
                   "error_ratio": {
                      "this": 8.8,
                      "CBSA": 4.2,
                      "state": 3.6
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 2.68,
                      "CBSA": 2.67,
                      "state": 2.78
                   },
                   "error": {
                      "this": 0.39,
                      "CBSA": 0.21,
                      "state": 0.19
                   },
                   "numerators": {
                      "this": 8831,
                      "CBSA": 30210,
                      "state": 42930
                   },
                   "numerator_errors": {
                      "this": 1288.4,
                      "CBSA": 2373.8,
                      "state": 2877.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 96
                   },
                   "error_ratio": {
                      "this": 14.6,
                      "CBSA": 7.9,
                      "state": 6.8
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 12.81,
                      "CBSA": 12.09,
                      "state": 11.86
                   },
                   "error": {
                      "this": 1.09,
                      "CBSA": 0.29,
                      "state": 0.29
                   },
                   "numerators": {
                      "this": 41217,
                      "CBSA": 137364,
                      "state": 182104
                   },
                   "numerator_errors": {
                      "this": 3581.7,
                      "CBSA": 3285.8,
                      "state": 4510
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 106,
                      "state": 108
                   },
                   "error_ratio": {
                      "this": 8.5,
                      "CBSA": 2.4,
                      "state": 2.4
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 13.05,
                      "CBSA": 12.48,
                      "state": 12.33
                   },
                   "error": {
                      "this": 1.11,
                      "CBSA": 0.29,
                      "state": 0.29
                   },
                   "numerators": {
                      "this": 41981,
                      "CBSA": 141740,
                      "state": 189265
                   },
                   "numerator_errors": {
                      "this": 3646.2,
                      "CBSA": 3303.6,
                      "state": 4536.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 105,
                      "state": 106
                   },
                   "error_ratio": {
                      "this": 8.5,
                      "CBSA": 2.3,
                      "state": 2.4
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 13.25,
                      "CBSA": 13.23,
                      "state": 12.99
                   },
                   "error": {
                      "this": 0.99,
                      "CBSA": 0.34,
                      "state": 0.3
                   },
                   "numerators": {
                      "this": 42634,
                      "CBSA": 150315,
                      "state": 199409
                   },
                   "numerator_errors": {
                      "this": 3267.5,
                      "CBSA": 3864.8,
                      "state": 4647
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 102
                   },
                   "error_ratio": {
                      "this": 7.5,
                      "CBSA": 2.6,
                      "state": 2.3
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 14.49,
                      "CBSA": 14.45,
                      "state": 14.11
                   },
                   "error": {
                      "this": 0.96,
                      "CBSA": 0.31,
                      "state": 0.28
                   },
                   "numerators": {
                      "this": 46629,
                      "CBSA": 164191,
                      "state": 216607
                   },
                   "numerator_errors": {
                      "this": 3179.6,
                      "CBSA": 3519.3,
                      "state": 4389.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 103
                   },
                   "error_ratio": {
                      "this": 6.6,
                      "CBSA": 2.1,
                      "state": 2
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 12.27,
                      "CBSA": 13.25,
                      "state": 12.67
                   },
                   "error": {
                      "this": 0.97,
                      "CBSA": 0.31,
                      "state": 0.26
                   },
                   "numerators": {
                      "this": 39500,
                      "CBSA": 150507,
                      "state": 194524
                   },
                   "numerator_errors": {
                      "this": 3169.9,
                      "CBSA": 3530.2,
                      "state": 3947.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 93,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 7.9,
                      "CBSA": 2.3,
                      "state": 2.1
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 12.42,
                      "CBSA": 12.65,
                      "state": 12.75
                   },
                   "error": {
                      "this": 0.97,
                      "CBSA": 0.3,
                      "state": 0.27
                   },
                   "numerators": {
                      "this": 39974,
                      "CBSA": 143767,
                      "state": 195697
                   },
                   "numerator_errors": {
                      "this": 3188.2,
                      "CBSA": 3453.8,
                      "state": 4204.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 98,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 7.8,
                      "CBSA": 2.4,
                      "state": 2.1
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 10.95,
                      "CBSA": 10.99,
                      "state": 11.72
                   },
                   "error": {
                      "this": 0.85,
                      "CBSA": 0.46,
                      "state": 0.41
                   },
                   "numerators": {
                      "this": 35230,
                      "CBSA": 124841,
                      "state": 179908
                   },
                   "numerator_errors": {
                      "this": 2790.5,
                      "CBSA": 5278.6,
                      "state": 6308.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 100,
                      "state": 93
                   },
                   "error_ratio": {
                      "this": 7.8,
                      "CBSA": 4.2,
                      "state": 3.5
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 7.15,
                      "CBSA": 7.48,
                      "state": 8.1
                   },
                   "error": {
                      "this": 0.68,
                      "CBSA": 0.31,
                      "state": 0.28
                   },
                   "numerators": {
                      "this": 23008,
                      "CBSA": 84990,
                      "state": 124388
                   },
                   "numerator_errors": {
                      "this": 2229.4,
                      "CBSA": 3530.2,
                      "state": 4308.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 96,
                      "state": 88
                   },
                   "error_ratio": {
                      "this": 9.5,
                      "CBSA": 4.1,
                      "state": 3.5
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 3.62,
                      "CBSA": 3.38,
                      "state": 3.48
                   },
                   "error": {
                      "this": 0.57,
                      "CBSA": 0.26,
                      "state": 0.23
                   },
                   "numerators": {
                      "this": 11642,
                      "CBSA": 38377,
                      "state": 53475
                   },
                   "numerator_errors": {
                      "this": 1857.9,
                      "CBSA": 2902.5,
                      "state": 3562.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 107,
                      "state": 104
                   },
                   "error_ratio": {
                      "this": 15.7,
                      "CBSA": 7.7,
                      "state": 6.6
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 36.8,
                   "CBSA": 37.7,
                   "state": 38.4
                },
                "error": {
                   "this": 1,
                   "CBSA": 0.2,
                   "state": 0.2
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 96
                },
                "error_ratio": {
                   "this": 2.7,
                   "CBSA": 0.5,
                   "state": 0.5
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 36.1,
                   "CBSA": 36.9,
                   "state": 37.6
                },
                "error": {
                   "this": 1,
                   "CBSA": 0.2,
                   "state": 0.3
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 96
                },
                "error_ratio": {
                   "this": 2.8,
                   "CBSA": 0.5,
                   "state": 0.8
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 37.5,
                   "CBSA": 38.4,
                   "state": 39
                },
                "error": {
                   "this": 1.1,
                   "CBSA": 0.3,
                   "state": 0.2
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 96
                },
                "error_ratio": {
                   "this": 2.9,
                   "CBSA": 0.8,
                   "state": 0.5
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 50.59,
                "CBSA": 49.88,
                "state": 50.15
             },
             "error": {
                "this": 0.79,
                "CBSA": 0.03,
                "state": 0.09
             },
             "numerators": {
                "this": 329482,
                "CBSA": 1130623,
                "state": 1544779
             },
             "numerator_errors": {
                "this": 5157,
                "CBSA": 747,
                "state": 2632
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 101
             },
             "error_ratio": {
                "this": 1.6,
                "CBSA": 0.1,
                "state": 0.2
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 49.41,
                "CBSA": 50.12,
                "state": 49.85
             },
             "error": {
                "this": 0.79,
                "CBSA": 0.03,
                "state": 0.09
             },
             "numerators": {
                "this": 321815,
                "CBSA": 1136092,
                "state": 1535377
             },
             "numerator_errors": {
                "this": 5175,
                "CBSA": 747,
                "state": 2632
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 99
             },
             "error_ratio": {
                "this": 1.6,
                "CBSA": 0.1,
                "state": 0.2
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 40.98,
                "CBSA": 41.25,
                "state": 47.84
             },
             "error": {
                "this": 1.65,
                "CBSA": 0.14,
                "state": 0.11
             },
             "numerators": {
                "this": 266899,
                "CBSA": 935092,
                "state": 1473587
             },
             "numerator_errors": {
                "this": 10747,
                "CBSA": 3180,
                "state": 3292
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 86
             },
             "error_ratio": {
                "this": 4,
                "CBSA": 0.3,
                "state": 0.2
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 11.59,
                "CBSA": 11.91,
                "state": 9.26
             },
             "error": {
                "this": 1.07,
                "CBSA": 0.19,
                "state": 0.16
             },
             "numerators": {
                "this": 75473,
                "CBSA": 269990,
                "state": 285369
             },
             "numerator_errors": {
                "this": 6969,
                "CBSA": 4412,
                "state": 4808
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 125
             },
             "error_ratio": {
                "this": 9.2,
                "CBSA": 1.6,
                "state": 1.7
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.29,
                "CBSA": 0.48,
                "state": 0.9
             },
             "error": {
                "this": 0.11,
                "CBSA": 0.08,
                "state": 0.07
             },
             "numerators": {
                "this": 1864,
                "CBSA": 10944,
                "state": 27771
             },
             "numerator_errors": {
                "this": 690,
                "CBSA": 1807,
                "state": 2104
             },
             "index": {
                "this": 100,
                "CBSA": 60,
                "state": 32
             },
             "error_ratio": {
                "this": 37.9,
                "CBSA": 16.7,
                "state": 7.8
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 6.89,
                "CBSA": 9.82,
                "state": 8.33
             },
             "error": {
                "this": 0.87,
                "CBSA": 0.22,
                "state": 0.17
             },
             "numerators": {
                "this": 44900,
                "CBSA": 222607,
                "state": 256708
             },
             "numerator_errors": {
                "this": 5697,
                "CBSA": 5079,
                "state": 5363
             },
             "index": {
                "this": 100,
                "CBSA": 70,
                "state": 83
             },
             "error_ratio": {
                "this": 12.6,
                "CBSA": 2.2,
                "state": 2
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.8,
                "CBSA": 0.71,
                "state": 0.65
             },
             "error": {
                "this": 0.27,
                "CBSA": 0.06,
                "state": 0.05
             },
             "numerators": {
                "this": 5214,
                "CBSA": 16202,
                "state": 20153
             },
             "numerator_errors": {
                "this": 1763,
                "CBSA": 1336,
                "state": 1584
             },
             "index": {
                "this": 100,
                "CBSA": 113,
                "state": 123
             },
             "error_ratio": {
                "this": 33.8,
                "CBSA": 8.5,
                "state": 7.7
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.35,
                "CBSA": 0.48,
                "state": 0.39
             },
             "error": {
                "this": 0.31,
                "CBSA": 0.16,
                "state": 0.12
             },
             "numerators": {
                "this": 2263,
                "CBSA": 10831,
                "state": 11965
             },
             "numerator_errors": {
                "this": 2045,
                "CBSA": 3689,
                "state": 3754
             },
             "index": {
                "this": 100,
                "CBSA": 73,
                "state": 90
             },
             "error_ratio": {
                "this": 88.6,
                "CBSA": 33.3,
                "state": 30.8
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 3.6,
                "CBSA": 3.7,
                "state": 3.38
             },
             "error": {
                "this": 0.58,
                "CBSA": 0.32,
                "state": 0.24
             },
             "numerators": {
                "this": 23459,
                "CBSA": 83771,
                "state": 104004
             },
             "numerator_errors": {
                "this": 3795,
                "CBSA": 7205,
                "state": 7503
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 107
             },
             "error_ratio": {
                "this": 16.1,
                "CBSA": 8.6,
                "state": 7.1
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 35.5,
                "CBSA": 31.64,
                "state": 29.24
             },
             "error": {
                "this": 1.75,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 231225,
                "CBSA": 717278,
                "state": 900599
             },
             "numerator_errors": {
                "this": 11422,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 112,
                "state": 121
             },
             "error_ratio": {
                "this": 4.9,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 31292,
                "CBSA": 32511,
                "state": 33575
             },
             "error": {
                "this": 1314,
                "CBSA": 614,
                "state": 531
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 93
             },
             "error_ratio": {
                "this": 4.2,
                "CBSA": 1.9,
                "state": 1.6
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 58713,
                "CBSA": 62107,
                "state": 63276
             },
             "error": {
                "this": 2558,
                "CBSA": 863,
                "state": 1021
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 95,
                "state": 93
             },
             "error_ratio": {
                "this": 4.4,
                "CBSA": 1.4,
                "state": 1.6
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 42.21,
                   "CBSA": 39.69,
                   "state": 39.04
                },
                "error": {
                   "this": 2.03,
                   "CBSA": 1.19,
                   "state": 0.98
                },
                "numerators": {
                   "this": 99467,
                   "CBSA": 322916,
                   "state": 446441
                },
                "numerator_errors": {
                   "this": 5354,
                   "CBSA": 10104.5,
                   "state": 11646.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 108
                },
                "error_ratio": {
                   "this": 4.8,
                   "CBSA": 3,
                   "state": 2.5
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 32.33,
                   "CBSA": 32.56,
                   "state": 32.61
                },
                "error": {
                   "this": 1.74,
                   "CBSA": 1.08,
                   "state": 0.87
                },
                "numerators": {
                   "this": 76173,
                   "CBSA": 264904,
                   "state": 372880
                },
                "numerator_errors": {
                   "this": 4508.5,
                   "CBSA": 9075.7,
                   "state": 10292
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 99
                },
                "error_ratio": {
                   "this": 5.4,
                   "CBSA": 3.3,
                   "state": 2.7
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 19.56,
                   "CBSA": 21.27,
                   "state": 21.92
                },
                "error": {
                   "this": 1.26,
                   "CBSA": 0.82,
                   "state": 0.69
                },
                "numerators": {
                   "this": 46084,
                   "CBSA": 173015,
                   "state": 250709
                },
                "numerator_errors": {
                   "this": 3169.8,
                   "CBSA": 6875.8,
                   "state": 8052
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 89
                },
                "error_ratio": {
                   "this": 6.4,
                   "CBSA": 3.9,
                   "state": 3.1
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 5.9,
                   "CBSA": 6.49,
                   "state": 6.43
                },
                "error": {
                   "this": 0.82,
                   "CBSA": 0.4,
                   "state": 0.35
                },
                "numerators": {
                   "this": 13904,
                   "CBSA": 52772,
                   "state": 73527
                },
                "numerator_errors": {
                   "this": 1968,
                   "CBSA": 3308,
                   "state": 4035
                },
                "index": {
                   "this": 100,
                   "CBSA": 91,
                   "state": 92
                },
                "error_ratio": {
                   "this": 13.9,
                   "CBSA": 6.2,
                   "state": 5.4
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 14.86,
                "CBSA": 12.83,
                "state": 12.5
             },
             "error": {
                "this": 1.32,
                "CBSA": 0.76,
                "state": 0.63
             },
             "numerators": {
                "this": 95279,
                "CBSA": 287386,
                "state": 379564
             },
             "numerator_errors": {
                "this": 8485,
                "CBSA": 16941,
                "state": 19019
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 116,
                "state": 119
             },
             "error_ratio": {
                "this": 8.9,
                "CBSA": 5.9,
                "state": 5
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 18.97,
                   "CBSA": 17.66,
                   "state": 16.93
                },
                "error": {
                   "this": 2.04,
                   "CBSA": 1.1,
                   "state": 0.95
                },
                "numerators": {
                   "this": 29301,
                   "CBSA": 90302,
                   "state": 114886
                },
                "numerator_errors": {
                   "this": 3437.9,
                   "CBSA": 5926,
                   "state": 6806.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 112
                },
                "error_ratio": {
                   "this": 10.8,
                   "CBSA": 6.2,
                   "state": 5.6
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 81.03,
                   "CBSA": 82.34,
                   "state": 83.07
                },
                "error": {
                   "this": 1.58,
                   "CBSA": 0.26,
                   "state": 0.32
                },
                "numerators": {
                   "this": 125130,
                   "CBSA": 421037,
                   "state": 563623
                },
                "numerator_errors": {
                   "this": 6324,
                   "CBSA": 8922,
                   "state": 10885.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 98
                },
                "error_ratio": {
                   "this": 1.9,
                   "CBSA": 0.3,
                   "state": 0.4
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 12.53,
                   "CBSA": 10.2,
                   "state": 9.55
                },
                "error": {
                   "this": 1.79,
                   "CBSA": 0.86,
                   "state": 0.69
                },
                "numerators": {
                   "this": 11951,
                   "CBSA": 34678,
                   "state": 47148
                },
                "numerator_errors": {
                   "this": 1793.4,
                   "CBSA": 2962.7,
                   "state": 3443.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 131
                },
                "error_ratio": {
                   "this": 14.3,
                   "CBSA": 8.4,
                   "state": 7.2
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 87.47,
                   "CBSA": 89.8,
                   "state": 90.45
                },
                "error": {
                   "this": 1.14,
                   "CBSA": 1.41,
                   "state": 1.21
                },
                "numerators": {
                   "this": 83443,
                   "CBSA": 305205,
                   "state": 446620
                },
                "numerator_errors": {
                   "this": 3941.5,
                   "CBSA": 2957.6,
                   "state": 3780.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 97
                },
                "error_ratio": {
                   "this": 1.3,
                   "CBSA": 1.6,
                   "state": 1.3
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 26.76,
                "CBSA": 25.95,
                "state": 25.55
             },
             "error": {
                "this": 0.73,
                "CBSA": 0.42,
                "state": 0.33
             },
             "numerators": {
                "this": 7583800,
                "CBSA": 2.658716E7,
                "state": 3.5509204E7
             },
             "numerator_errors": {
                "this": 269546,
                "CBSA": 516339,
                "state": 581941
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 103,
                "state": 105
             },
             "error_ratio": {
                "this": 2.7,
                "CBSA": 1.6,
                "state": 1.3
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 77.56,
                   "CBSA": 78.18,
                   "state": 76.97
                },
                "error": {
                   "this": 1.07,
                   "CBSA": 0.92,
                   "state": 0.78
                },
                "numerators": {
                   "this": 230436,
                   "CBSA": 840598,
                   "state": 1124622
                },
                "numerator_errors": {
                   "this": 5778,
                   "CBSA": 12768,
                   "state": 15348
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 101
                },
                "error_ratio": {
                   "this": 1.4,
                   "CBSA": 1.2,
                   "state": 1
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 9.83,
                   "CBSA": 9.69,
                   "state": 10.62
                },
                "error": {
                   "this": 1.08,
                   "CBSA": 0.64,
                   "state": 0.54
                },
                "numerators": {
                   "this": 29202,
                   "CBSA": 104175,
                   "state": 155163
                },
                "numerator_errors": {
                   "this": 3268,
                   "CBSA": 6942,
                   "state": 7985
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 93
                },
                "error_ratio": {
                   "this": 11,
                   "CBSA": 6.6,
                   "state": 5.1
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 2.52,
                   "CBSA": 2.9,
                   "state": 3.06
                },
                "error": {
                   "this": 0.63,
                   "CBSA": 0.35,
                   "state": 0.33
                },
                "numerators": {
                   "this": 7473,
                   "CBSA": 31231,
                   "state": 44780
                },
                "numerator_errors": {
                   "this": 1878,
                   "CBSA": 3814,
                   "state": 4781
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 82
                },
                "error_ratio": {
                   "this": 25,
                   "CBSA": 12.1,
                   "state": 10.8
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 0.24,
                   "CBSA": 0.21,
                   "state": 0.25
                },
                "error": {
                   "this": 0.15,
                   "CBSA": 0.09,
                   "state": 0.07
                },
                "numerators": {
                   "this": 704,
                   "CBSA": 2292,
                   "state": 3634
                },
                "numerator_errors": {
                   "this": 436,
                   "CBSA": 917,
                   "state": 1053
                },
                "index": {
                   "this": 100,
                   "CBSA": 114,
                   "state": 96
                },
                "error_ratio": {
                   "this": 62.5,
                   "CBSA": 42.9,
                   "state": 28
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 1.08,
                   "CBSA": 1.16,
                   "state": 1.59
                },
                "error": {
                   "this": 0.33,
                   "CBSA": 0.21,
                   "state": 0.2
                },
                "numerators": {
                   "this": 3195,
                   "CBSA": 12519,
                   "state": 23281
                },
                "numerator_errors": {
                   "this": 987,
                   "CBSA": 2252,
                   "state": 2871
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 68
                },
                "error_ratio": {
                   "this": 30.6,
                   "CBSA": 18.1,
                   "state": 12.6
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 4.17,
                   "CBSA": 3.15,
                   "state": 2.62
                },
                "error": {
                   "this": 0.75,
                   "CBSA": 0.4,
                   "state": 0.33
                },
                "numerators": {
                   "this": 12376,
                   "CBSA": 33914,
                   "state": 38306
                },
                "numerator_errors": {
                   "this": 2253,
                   "CBSA": 4285,
                   "state": 4860
                },
                "index": {
                   "this": 100,
                   "CBSA": 132,
                   "state": 159
                },
                "error_ratio": {
                   "this": 18,
                   "CBSA": 12.7,
                   "state": 12.6
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 4.62,
                   "CBSA": 4.69,
                   "state": 4.88
                },
                "error": {
                   "this": 0.63,
                   "CBSA": 0.37,
                   "state": 0.32
                },
                "numerators": {
                   "this": 13717,
                   "CBSA": 50479,
                   "state": 71251
                },
                "numerator_errors": {
                   "this": 1883,
                   "CBSA": 4045,
                   "state": 4777
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 95
                },
                "error_ratio": {
                   "this": 13.6,
                   "CBSA": 7.9,
                   "state": 6.6
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 45.55,
                "CBSA": 46.22,
                "state": 47.72
             },
             "error": {
                "this": 1,
                "CBSA": 0.66,
                "state": 0.55
             },
             "numerators": {
                "this": 236645,
                "CBSA": 847003,
                "state": 1194890
             },
             "numerator_errors": {
                "this": 5853.2,
                "CBSA": 12047.1,
                "state": 13773.3
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 95
             },
             "error_ratio": {
                "this": 2.2,
                "CBSA": 1.4,
                "state": 1.2
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 54.45,
                "CBSA": 53.78,
                "state": 52.28
             },
             "error": {
                "this": 1.5,
                "CBSA": 0.75,
                "state": 0.62
             },
             "numerators": {
                "this": 282930,
                "CBSA": 985522,
                "state": 1309093
             },
             "numerator_errors": {
                "this": 8418.2,
                "CBSA": 13783.9,
                "state": 15595.2
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 104
             },
             "error_ratio": {
                "this": 2.8,
                "CBSA": 1.4,
                "state": 1.2
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 39.98,
                   "CBSA": 39.08,
                   "state": 37.19
                },
                "error": {
                   "this": 1.83,
                   "CBSA": 0.8,
                   "state": 0.64
                },
                "numerators": {
                   "this": 104106,
                   "CBSA": 355012,
                   "state": 464646
                },
                "numerator_errors": {
                   "this": 5244,
                   "CBSA": 7293,
                   "state": 8079
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 108
                },
                "error_ratio": {
                   "this": 4.6,
                   "CBSA": 2,
                   "state": 1.7
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 31.3,
                   "CBSA": 31.05,
                   "state": 29.44
                },
                "error": {
                   "this": 1.59,
                   "CBSA": 0.79,
                   "state": 0.63
                },
                "numerators": {
                   "this": 81142,
                   "CBSA": 286911,
                   "state": 369395
                },
                "numerator_errors": {
                   "this": 4329,
                   "CBSA": 7275,
                   "state": 7955
                },
                "index": {
                   "this": 100,
                   "CBSA": 101,
                   "state": 106
                },
                "error_ratio": {
                   "this": 5.1,
                   "CBSA": 2.5,
                   "state": 2.1
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 45.34,
                   "CBSA": 46.38,
                   "state": 48.03
                },
                "error": {
                   "this": 1.44,
                   "CBSA": 0.9,
                   "state": 0.76
                },
                "numerators": {
                   "this": 118050,
                   "CBSA": 421285,
                   "state": 600106
                },
                "numerator_errors": {
                   "this": 4500,
                   "CBSA": 8191,
                   "state": 9586
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 94
                },
                "error_ratio": {
                   "this": 3.2,
                   "CBSA": 1.9,
                   "state": 1.6
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 45.75,
                   "CBSA": 46.07,
                   "state": 47.41
                },
                "error": {
                   "this": 1.23,
                   "CBSA": 0.96,
                   "state": 0.79
                },
                "numerators": {
                   "this": 118595,
                   "CBSA": 425718,
                   "state": 594784
                },
                "numerator_errors": {
                   "this": 3743,
                   "CBSA": 8834,
                   "state": 9890
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 96
                },
                "error_ratio": {
                   "this": 2.7,
                   "CBSA": 2.1,
                   "state": 1.7
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 12.36,
                   "CBSA": 12.14,
                   "state": 12.21
                },
                "error": {
                   "this": 1.01,
                   "CBSA": 0.57,
                   "state": 0.49
                },
                "numerators": {
                   "this": 32190,
                   "CBSA": 110316,
                   "state": 152509
                },
                "numerator_errors": {
                   "this": 2717,
                   "CBSA": 5162,
                   "state": 6173
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 101
                },
                "error_ratio": {
                   "this": 8.2,
                   "CBSA": 4.7,
                   "state": 4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 14.4,
                   "CBSA": 14.96,
                   "state": 14.93
                },
                "error": {
                   "this": 1.2,
                   "CBSA": 0.64,
                   "state": 0.52
                },
                "numerators": {
                   "this": 37314,
                   "CBSA": 138228,
                   "state": 187272
                },
                "numerator_errors": {
                   "this": 3162,
                   "CBSA": 5907,
                   "state": 6488
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 96
                },
                "error_ratio": {
                   "this": 8.3,
                   "CBSA": 4.3,
                   "state": 3.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 2.32,
                   "CBSA": 2.4,
                   "state": 2.57
                },
                "error": {
                   "this": 0.43,
                   "CBSA": 0.25,
                   "state": 0.23
                },
                "numerators": {
                   "this": 6030,
                   "CBSA": 21756,
                   "state": 32163
                },
                "numerator_errors": {
                   "this": 1122,
                   "CBSA": 2283,
                   "state": 2846
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 90
                },
                "error_ratio": {
                   "this": 18.5,
                   "CBSA": 10.4,
                   "state": 8.9
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 8.54,
                   "CBSA": 7.93,
                   "state": 8.22
                },
                "error": {
                   "this": 0.93,
                   "CBSA": 0.45,
                   "state": 0.41
                },
                "numerators": {
                   "this": 22148,
                   "CBSA": 73299,
                   "state": 103108
                },
                "numerator_errors": {
                   "this": 2447,
                   "CBSA": 4139,
                   "state": 5134
                },
                "index": {
                   "this": 100,
                   "CBSA": 108,
                   "state": 104
                },
                "error_ratio": {
                   "this": 10.9,
                   "CBSA": 5.7,
                   "state": 5
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 62.16,
                   "CBSA": 63.69,
                   "state": 65.4
                },
                "error": {
                   "this": 3.45,
                   "CBSA": 2.04,
                   "state": 1.75
                },
                "numerators": {
                   "this": 83109,
                   "CBSA": 282379,
                   "state": 385836
                },
                "numerator_errors": {
                   "this": 6255,
                   "CBSA": 10442,
                   "state": 11739
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 95
                },
                "error_ratio": {
                   "this": 5.6,
                   "CBSA": 3.2,
                   "state": 2.7
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 12.35,
                   "CBSA": 9.89,
                   "state": 9.57
                },
                "error": {
                   "this": 2.66,
                   "CBSA": 1.32,
                   "state": 1.12
                },
                "numerators": {
                   "this": 16517,
                   "CBSA": 43847,
                   "state": 56465
                },
                "numerator_errors": {
                   "this": 3653,
                   "CBSA": 5924,
                   "state": 6630
                },
                "index": {
                   "this": 100,
                   "CBSA": 125,
                   "state": 129
                },
                "error_ratio": {
                   "this": 21.5,
                   "CBSA": 13.3,
                   "state": 11.7
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 25.49,
                   "CBSA": 26.42,
                   "state": 25.03
                },
                "error": {
                   "this": 3.76,
                   "CBSA": 2.04,
                   "state": 1.69
                },
                "numerators": {
                   "this": 34076,
                   "CBSA": 117164,
                   "state": 147705
                },
                "numerator_errors": {
                   "this": 5321,
                   "CBSA": 9313,
                   "state": 10188
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 102
                },
                "error_ratio": {
                   "this": 14.8,
                   "CBSA": 7.7,
                   "state": 6.8
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 5.37,
                "CBSA": 4.94,
                "state": 5.18
             },
             "error": {
                "this": 1.05,
                "CBSA": 0.6,
                "state": 0.52
             },
             "numerators": {
                "this": 8231,
                "CBSA": 27110,
                "state": 37443
             },
             "numerator_errors": {
                "this": 1635,
                "CBSA": 3285,
                "state": 3762
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 109,
                "state": 104
             },
             "error_ratio": {
                "this": 19.6,
                "CBSA": 12.1,
                "state": 10
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 1.06,
                   "CBSA": 0.81,
                   "state": 1.07
                },
                "error": {
                   "this": 1.84,
                   "CBSA": 0.78,
                   "state": 0.84
                },
                "numerators": {
                   "this": 219,
                   "CBSA": 546,
                   "state": 967
                },
                "numerator_errors": {
                   "this": 380,
                   "CBSA": 525,
                   "state": 763
                },
                "index": {
                   "this": 100,
                   "CBSA": 131,
                   "state": 99
                },
                "error_ratio": {
                   "this": 173.6,
                   "CBSA": 96.3,
                   "state": 78.5
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 8.27,
                   "CBSA": 7.41,
                   "state": 7.87
                },
                "error": {
                   "this": 4.03,
                   "CBSA": 2.1,
                   "state": 1.81
                },
                "numerators": {
                   "this": 1516,
                   "CBSA": 4890,
                   "state": 7019
                },
                "numerator_errors": {
                   "this": 757,
                   "CBSA": 1394,
                   "state": 1631
                },
                "index": {
                   "this": 100,
                   "CBSA": 112,
                   "state": 105
                },
                "error_ratio": {
                   "this": 48.7,
                   "CBSA": 28.3,
                   "state": 23
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 7.66,
                   "CBSA": 8.56,
                   "state": 8.8
                },
                "error": {
                   "this": 2.7,
                   "CBSA": 1.79,
                   "state": 1.74
                },
                "numerators": {
                   "this": 1863,
                   "CBSA": 7222,
                   "state": 9702
                },
                "numerator_errors": {
                   "this": 684,
                   "CBSA": 1518,
                   "state": 1942
                },
                "index": {
                   "this": 100,
                   "CBSA": 89,
                   "state": 87
                },
                "error_ratio": {
                   "this": 35.2,
                   "CBSA": 20.9,
                   "state": 19.8
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 13.9,
                   "CBSA": 10.38,
                   "state": 10.52
                },
                "error": {
                   "this": 4.21,
                   "CBSA": 2,
                   "state": 1.79
                },
                "numerators": {
                   "this": 3249,
                   "CBSA": 8525,
                   "state": 11501
                },
                "numerator_errors": {
                   "this": 1035,
                   "CBSA": 1662,
                   "state": 1989
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 132
                },
                "error_ratio": {
                   "this": 30.3,
                   "CBSA": 19.3,
                   "state": 17
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 3.91,
                   "CBSA": 4.51,
                   "state": 4.95
                },
                "error": {
                   "this": 2.16,
                   "CBSA": 1.24,
                   "state": 1.17
                },
                "numerators": {
                   "this": 909,
                   "CBSA": 3700,
                   "state": 5311
                },
                "numerator_errors": {
                   "this": 509,
                   "CBSA": 1033,
                   "state": 1273
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 79
                },
                "error_ratio": {
                   "this": 55.2,
                   "CBSA": 27.5,
                   "state": 23.6
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 1.53,
                   "CBSA": 2.12,
                   "state": 2.17
                },
                "error": {
                   "this": 1.61,
                   "CBSA": 0.92,
                   "state": 0.91
                },
                "numerators": {
                   "this": 291,
                   "CBSA": 1556,
                   "state": 2067
                },
                "numerator_errors": {
                   "this": 308,
                   "CBSA": 683,
                   "state": 872
                },
                "index": {
                   "this": 100,
                   "CBSA": 72,
                   "state": 71
                },
                "error_ratio": {
                   "this": 105.2,
                   "CBSA": 43.4,
                   "state": 41.9
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0.75,
                   "CBSA": 0.71,
                   "state": 0.72
                },
                "error": {
                   "this": 0.76,
                   "CBSA": 0.6,
                   "state": 0.51
                },
                "numerators": {
                   "this": 184,
                   "CBSA": 671,
                   "state": 876
                },
                "numerator_errors": {
                   "this": 186,
                   "CBSA": 566,
                   "state": 623
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 104
                },
                "error_ratio": {
                   "this": 101.3,
                   "CBSA": 84.5,
                   "state": 70.8
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 235628,
                "CBSA": 813607,
                "state": 1143557
             },
             "error": {
                "this": 5729,
                "CBSA": 7174,
                "state": 8281
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 29,
                "state": 21
             },
             "error_ratio": {
                "this": 2.4,
                "CBSA": 0.9,
                "state": 0.7
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 2.73,
                "CBSA": 2.76,
                "state": 2.66
             },
             "error": {
                "this": 0.07,
                "CBSA": 0.02,
                "state": 0.02
             },
             "numerators": {
                "this": 643023,
                "CBSA": 2244412,
                "state": 3042493
             },
             "numerator_errors": {
                "this": 999,
                "CBSA": 1478,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 103
             },
             "error_ratio": {
                "this": 2.6,
                "CBSA": 0.7,
                "state": 0.8
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 52.95,
                   "CBSA": 53.97,
                   "state": 55.35
                },
                "error": {
                   "this": 2.08,
                   "CBSA": 1.11,
                   "state": 0.95
                },
                "numerators": {
                   "this": 340497,
                   "CBSA": 1211219,
                   "state": 1684158
                },
                "numerator_errors": {
                   "this": 13385,
                   "CBSA": 24843,
                   "state": 28976
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 96
                },
                "error_ratio": {
                   "this": 3.9,
                   "CBSA": 2.1,
                   "state": 1.7
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 9.97,
                   "CBSA": 9.16,
                   "state": 8.47
                },
                "error": {
                   "this": 1.69,
                   "CBSA": 0.76,
                   "state": 0.62
                },
                "numerators": {
                   "this": 64114,
                   "CBSA": 205663,
                   "state": 257612
                },
                "numerator_errors": {
                   "this": 10848,
                   "CBSA": 16974,
                   "state": 18858
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 118
                },
                "error_ratio": {
                   "this": 17,
                   "CBSA": 8.3,
                   "state": 7.3
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 18.86,
                   "CBSA": 19.17,
                   "state": 17.79
                },
                "error": {
                   "this": 1.83,
                   "CBSA": 0.98,
                   "state": 0.79
                },
                "numerators": {
                   "this": 121291,
                   "CBSA": 430269,
                   "state": 541370
                },
                "numerator_errors": {
                   "this": 11778,
                   "CBSA": 21967,
                   "state": 24122
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 106
                },
                "error_ratio": {
                   "this": 9.7,
                   "CBSA": 5.1,
                   "state": 4.4
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 18.21,
                   "CBSA": 17.7,
                   "state": 18.38
                },
                "error": {
                   "this": 1.2,
                   "CBSA": 0.59,
                   "state": 0.52
                },
                "numerators": {
                   "this": 117121,
                   "CBSA": 397261,
                   "state": 559353
                },
                "numerator_errors": {
                   "this": 7729,
                   "CBSA": 13238,
                   "state": 15745
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 99
                },
                "error_ratio": {
                   "this": 6.6,
                   "CBSA": 3.3,
                   "state": 2.8
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 258593,
                "CBSA": 924533,
                "state": 1285681
             },
             "error": {
                "this": 5987,
                "CBSA": 584,
                "state": 221
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 28,
                "state": 20
             },
             "error_ratio": {
                "this": 2.3,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 91.12,
                   "CBSA": 88,
                   "state": 88.95
                },
                "error": {
                   "this": 0.68,
                   "CBSA": 0.77,
                   "state": 0.64
                },
                "numerators": {
                   "this": 235628,
                   "CBSA": 813607,
                   "state": 1143557
                },
                "numerator_errors": {
                   "this": 5729,
                   "CBSA": 7174,
                   "state": 8281
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 102
                },
                "error_ratio": {
                   "this": 0.7,
                   "CBSA": 0.9,
                   "state": 0.7
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 8.88,
                   "CBSA": 12,
                   "state": 11.05
                },
                "error": {
                   "this": 1.06,
                   "CBSA": 0.79,
                   "state": 0.64
                },
                "numerators": {
                   "this": 22965,
                   "CBSA": 110926,
                   "state": 142124
                },
                "numerator_errors": {
                   "this": 2790,
                   "CBSA": 7266,
                   "state": 8259
                },
                "index": {
                   "this": 100,
                   "CBSA": 74,
                   "state": 80
                },
                "error_ratio": {
                   "this": 11.9,
                   "CBSA": 6.6,
                   "state": 5.8
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 66.34,
                   "CBSA": 63.39,
                   "state": 63.58
                },
                "error": {
                   "this": 1.04,
                   "CBSA": 0.8,
                   "state": 0.69
                },
                "numerators": {
                   "this": 171552,
                   "CBSA": 586100,
                   "state": 817375
                },
                "numerator_errors": {
                   "this": 4803.7,
                   "CBSA": 7398.3,
                   "state": 8859
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 104
                },
                "error_ratio": {
                   "this": 1.6,
                   "CBSA": 1.3,
                   "state": 1.1
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 32.27,
                   "CBSA": 33.52,
                   "state": 30.87
                },
                "error": {
                   "this": 1.57,
                   "CBSA": 1.05,
                   "state": 0.83
                },
                "numerators": {
                   "this": 83437,
                   "CBSA": 309895,
                   "state": 396855
                },
                "numerator_errors": {
                   "this": 4492.6,
                   "CBSA": 9684.3,
                   "state": 10659.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 105
                },
                "error_ratio": {
                   "this": 4.9,
                   "CBSA": 3.1,
                   "state": 2.7
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 1.34,
                   "CBSA": 2.97,
                   "state": 5.4
                },
                "error": {
                   "this": 0.31,
                   "CBSA": 0.28,
                   "state": 0.29
                },
                "numerators": {
                   "this": 3461,
                   "CBSA": 27475,
                   "state": 69366
                },
                "numerator_errors": {
                   "this": 811,
                   "CBSA": 2547,
                   "state": 3716
                },
                "index": {
                   "this": 100,
                   "CBSA": 45,
                   "state": 25
                },
                "error_ratio": {
                   "this": 23.1,
                   "CBSA": 9.4,
                   "state": 5.4
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0.06,
                   "CBSA": 0.11,
                   "state": 0.16
                },
                "error": {
                   "this": 0.06,
                   "CBSA": 0.05,
                   "state": 0.05
                },
                "numerators": {
                   "this": 143,
                   "CBSA": 1063,
                   "state": 2085
                },
                "numerator_errors": {
                   "this": 145,
                   "CBSA": 429,
                   "state": 671
                },
                "index": {
                   "this": 100,
                   "CBSA": 55,
                   "state": 38
                },
                "error_ratio": {
                   "this": 100,
                   "CBSA": 45.5,
                   "state": 31.2
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 52.54,
                   "CBSA": 54.25,
                   "state": 56.62
                },
                "error": {
                   "this": 1.34,
                   "CBSA": 0.92,
                   "state": 0.72
                },
                "numerators": {
                   "this": 123788,
                   "CBSA": 441385,
                   "state": 647518
                },
                "numerator_errors": {
                   "this": 4364,
                   "CBSA": 8408,
                   "state": 9457
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 93
                },
                "error_ratio": {
                   "this": 2.6,
                   "CBSA": 1.7,
                   "state": 1.3
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 47.46,
                   "CBSA": 45.75,
                   "state": 43.38
                },
                "error": {
                   "this": 1.8,
                   "CBSA": 0.88,
                   "state": 0.73
                },
                "numerators": {
                   "this": 111840,
                   "CBSA": 372222,
                   "state": 496039
                },
                "numerator_errors": {
                   "this": 5031,
                   "CBSA": 7896,
                   "state": 9108
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 109
                },
                "error_ratio": {
                   "this": 3.8,
                   "CBSA": 1.9,
                   "state": 1.7
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 305900,
                "CBSA": 313100,
                "state": 317800
             },
             "error": {
                "this": 7100,
                "CBSA": 3811,
                "state": 3094
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 96
             },
             "error_ratio": {
                "this": 2.3,
                "CBSA": 1.2,
                "state": 1
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 4.59,
                   "CBSA": 5.08,
                   "state": 6.09
                },
                "error": {
                   "this": 0.87,
                   "CBSA": 0.49,
                   "state": 0.46
                },
                "numerators": {
                   "this": 5685,
                   "CBSA": 22413,
                   "state": 39453
                },
                "numerator_errors": {
                   "this": 1100,
                   "CBSA": 2202.8,
                   "state": 3024.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 75
                },
                "error_ratio": {
                   "this": 19,
                   "CBSA": 9.6,
                   "state": 7.6
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 10.41,
                   "CBSA": 10.74,
                   "state": 11.69
                },
                "error": {
                   "this": 1.34,
                   "CBSA": 0.71,
                   "state": 0.68
                },
                "numerators": {
                   "this": 12890,
                   "CBSA": 47409,
                   "state": 75712
                },
                "numerator_errors": {
                   "this": 1721.1,
                   "CBSA": 3280.8,
                   "state": 4533.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 89
                },
                "error_ratio": {
                   "this": 12.9,
                   "CBSA": 6.6,
                   "state": 5.8
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 33.43,
                   "CBSA": 30.63,
                   "state": 27.62
                },
                "error": {
                   "this": 1.81,
                   "CBSA": 0.96,
                   "state": 0.79
                },
                "numerators": {
                   "this": 41379,
                   "CBSA": 135200,
                   "state": 178815
                },
                "numerator_errors": {
                   "this": 2671.6,
                   "CBSA": 4963.7,
                   "state": 5747.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 121
                },
                "error_ratio": {
                   "this": 5.4,
                   "CBSA": 3.1,
                   "state": 2.9
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 26.4,
                   "CBSA": 27.15,
                   "state": 25.85
                },
                "error": {
                   "this": 1.61,
                   "CBSA": 0.91,
                   "state": 0.75
                },
                "numerators": {
                   "this": 32678,
                   "CBSA": 119816,
                   "state": 167381
                },
                "numerator_errors": {
                   "this": 2303,
                   "CBSA": 4609,
                   "state": 5411
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 102
                },
                "error_ratio": {
                   "this": 6.1,
                   "CBSA": 3.4,
                   "state": 2.9
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 9.36,
                   "CBSA": 11.74,
                   "state": 12.38
                },
                "error": {
                   "this": 1.25,
                   "CBSA": 0.83,
                   "state": 0.67
                },
                "numerators": {
                   "this": 11591,
                   "CBSA": 51840,
                   "state": 80156
                },
                "numerator_errors": {
                   "this": 1596,
                   "CBSA": 3792,
                   "state": 4513
                },
                "index": {
                   "this": 100,
                   "CBSA": 80,
                   "state": 76
                },
                "error_ratio": {
                   "this": 13.4,
                   "CBSA": 7.1,
                   "state": 5.4
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 13.32,
                   "CBSA": 12.65,
                   "state": 13.73
                },
                "error": {
                   "this": 1.29,
                   "CBSA": 0.72,
                   "state": 0.61
                },
                "numerators": {
                   "this": 16494,
                   "CBSA": 55847,
                   "state": 88872
                },
                "numerator_errors": {
                   "this": 1700.8,
                   "CBSA": 3348.5,
                   "state": 4184.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 97
                },
                "error_ratio": {
                   "this": 9.7,
                   "CBSA": 5.7,
                   "state": 4.4
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 1.4,
                   "CBSA": 0.94,
                   "state": 1.27
                },
                "error": {
                   "this": 0.63,
                   "CBSA": 0.21,
                   "state": 0.19
                },
                "numerators": {
                   "this": 1739,
                   "CBSA": 4158,
                   "state": 8238
                },
                "numerator_errors": {
                   "this": 779,
                   "CBSA": 924,
                   "state": 1265
                },
                "index": {
                   "this": 100,
                   "CBSA": 149,
                   "state": 110
                },
                "error_ratio": {
                   "this": 45,
                   "CBSA": 22.3,
                   "state": 15
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 123788,
                "CBSA": 441385,
                "state": 647518
             },
             "error": {
                "this": 4364,
                "CBSA": 8408,
                "state": 9457
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 28,
                "state": 19
             },
             "error_ratio": {
                "this": 3.5,
                "CBSA": 1.9,
                "state": 1.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 1-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 2.65,
                "CBSA": 2.28,
                "state": 2.84
             },
             "error": {
                "this": 0.5,
                "CBSA": 0.28,
                "state": 0.27
             },
             "numerators": {
                "this": 17018,
                "CBSA": 51264,
                "state": 86310
             },
             "numerator_errors": {
                "this": 3211.8,
                "CBSA": 6357.6,
                "state": 8181.3
             },
             "index": {
                "this": 100,
                "CBSA": 116,
                "state": 93
             },
             "error_ratio": {
                "this": 18.9,
                "CBSA": 12.3,
                "state": 9.5
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 7.07,
                "CBSA": 6.15,
                "state": 6.81
             },
             "error": {
                "this": 0.78,
                "CBSA": 0.42,
                "state": 0.34
             },
             "numerators": {
                "this": 45468,
                "CBSA": 137926,
                "state": 207163
             },
             "numerator_errors": {
                "this": 5037.8,
                "CBSA": 9473.6,
                "state": 10269.2
             },
             "index": {
                "this": 100,
                "CBSA": 115,
                "state": 104
             },
             "error_ratio": {
                "this": 11,
                "CBSA": 6.8,
                "state": 5
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 16.94,
                "CBSA": 17.05,
                "state": 17.43
             },
             "error": {
                "this": 1.52,
                "CBSA": 0.86,
                "state": 0.73
             },
             "numerators": {
                "this": 108904,
                "CBSA": 382575,
                "state": 530210
             },
             "numerator_errors": {
                "this": 9787.6,
                "CBSA": 19336.4,
                "state": 22354
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 97
             },
             "error_ratio": {
                "this": 9,
                "CBSA": 5,
                "state": 4.2
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 19.03,
                "CBSA": 19.48,
                "state": 19.4
             },
             "error": {
                "this": 1.74,
                "CBSA": 0.95,
                "state": 0.83
             },
             "numerators": {
                "this": 122388,
                "CBSA": 437239,
                "state": 590321
             },
             "numerator_errors": {
                "this": 11197.3,
                "CBSA": 21390.4,
                "state": 25280.8
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 98
             },
             "error_ratio": {
                "this": 9.1,
                "CBSA": 4.9,
                "state": 4.3
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 17.06,
                "CBSA": 17.12,
                "state": 16.81
             },
             "error": {
                "this": 1.96,
                "CBSA": 0.92,
                "state": 0.79
             },
             "numerators": {
                "this": 109711,
                "CBSA": 384347,
                "state": 511316
             },
             "numerator_errors": {
                "this": 12597.9,
                "CBSA": 20742.5,
                "state": 23950.5
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 101
             },
             "error_ratio": {
                "this": 11.5,
                "CBSA": 5.4,
                "state": 4.7
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 37.25,
                "CBSA": 37.92,
                "state": 36.72
             },
             "error": {
                "this": 2.27,
                "CBSA": 1.28,
                "state": 1.09
             },
             "numerators": {
                "this": 239534,
                "CBSA": 851061,
                "state": 1117173
             },
             "numerator_errors": {
                "this": 14574.4,
                "CBSA": 28730.8,
                "state": 33090.3
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 101
             },
             "error_ratio": {
                "this": 6.1,
                "CBSA": 3.4,
                "state": 3
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 18.32,
                "CBSA": 17.94,
                "state": 17.65
             },
             "error": {
                "this": 1.58,
                "CBSA": 0.85,
                "state": 0.68
             },
             "numerators": {
                "this": 118059,
                "CBSA": 402562,
                "state": 538011
             },
             "numerator_errors": {
                "this": 10197.3,
                "CBSA": 19186.3,
                "state": 20782.9
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 102,
                "state": 104
             },
             "error_ratio": {
                "this": 8.6,
                "CBSA": 4.7,
                "state": 3.9
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 1-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 81.68,
                "CBSA": 82.06,
                "state": 82.35
             },
             "error": {
                "this": 1.58,
                "CBSA": 0.87,
                "state": 0.68
             },
             "numerators": {
                "this": 526511,
                "CBSA": 1841664,
                "state": 2510591
             },
             "numerator_errors": {
                "this": 10267,
                "CBSA": 19709,
                "state": 20990
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 99
             },
             "error_ratio": {
                "this": 1.9,
                "CBSA": 1.1,
                "state": 0.8
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 13.39,
                "CBSA": 12.64,
                "state": 11.59
             },
             "error": {
                "this": 1.4,
                "CBSA": 0.74,
                "state": 0.57
             },
             "numerators": {
                "this": 86297,
                "CBSA": 283729,
                "state": 353223
             },
             "numerator_errors": {
                "this": 9009,
                "CBSA": 16658,
                "state": 17342
             },
             "index": {
                "this": 100,
                "CBSA": 106,
                "state": 116
             },
             "error_ratio": {
                "this": 10.5,
                "CBSA": 5.9,
                "state": 4.9
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 0.14,
                "CBSA": 0.17,
                "state": 1.03
             },
             "error": {
                "this": 0.09,
                "CBSA": 0.05,
                "state": 0.16
             },
             "numerators": {
                "this": 933,
                "CBSA": 3710,
                "state": 31453
             },
             "numerator_errors": {
                "this": 577,
                "CBSA": 1063,
                "state": 4762
             },
             "index": {
                "this": 100,
                "CBSA": 82,
                "state": 14
             },
             "error_ratio": {
                "this": 64.3,
                "CBSA": 29.4,
                "state": 15.5
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 4.14,
                "CBSA": 4.44,
                "state": 4.36
             },
             "error": {
                "this": 0.69,
                "CBSA": 0.37,
                "state": 0.3
             },
             "numerators": {
                "this": 26711,
                "CBSA": 99723,
                "state": 132950
             },
             "numerator_errors": {
                "this": 4461,
                "CBSA": 8391,
                "state": 9071
             },
             "index": {
                "this": 100,
                "CBSA": 93,
                "state": 95
             },
             "error_ratio": {
                "this": 16.7,
                "CBSA": 8.3,
                "state": 6.9
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 0.64,
                "CBSA": 0.69,
                "state": 0.67
             },
             "error": {
                "this": 0.25,
                "CBSA": 0.19,
                "state": 0.17
             },
             "numerators": {
                "this": 4118,
                "CBSA": 15400,
                "state": 20385
             },
             "numerator_errors": {
                "this": 1609,
                "CBSA": 4369,
                "state": 5121
             },
             "index": {
                "this": 100,
                "CBSA": 93,
                "state": 96
             },
             "error_ratio": {
                "this": 39.1,
                "CBSA": 27.5,
                "state": 25.4
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 83.82,
                "CBSA": 86.33,
                "state": 86.85
             },
             "error": {
                "this": 1.82,
                "CBSA": 1.17,
                "state": 0.99
             },
             "numerators": {
                "this": 369219,
                "CBSA": 1347588,
                "state": 1855551
             },
             "numerator_errors": {
                "this": 9682.2,
                "CBSA": 18234.2,
                "state": 21279.5
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 97
             },
             "error_ratio": {
                "this": 2.2,
                "CBSA": 1.4,
                "state": 1.1
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 25.12,
                "CBSA": 25.63,
                "state": 25.69
             },
             "error": {
                "this": 1.12,
                "CBSA": 0.63,
                "state": 0.53
             },
             "numerators": {
                "this": 110669,
                "CBSA": 400107,
                "state": 548919
             },
             "numerator_errors": {
                "this": 5202.8,
                "CBSA": 9762.2,
                "state": 11376.6
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 98
             },
             "error_ratio": {
                "this": 4.5,
                "CBSA": 2.5,
                "state": 2.1
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 16.18,
                "CBSA": 13.67,
                "state": 13.15
             },
             "error": {
                "this": 1.06,
                "CBSA": 0.55,
                "state": 0.46
             },
             "numerators": {
                "this": 71289,
                "CBSA": 213359,
                "state": 280915
             },
             "numerator_errors": {
                "this": 4764.8,
                "CBSA": 8547.8,
                "state": 9837.8
             },
             "index": {
                "this": 100,
                "CBSA": 118,
                "state": 123
             },
             "error_ratio": {
                "this": 6.6,
                "CBSA": 4,
                "state": 3.5
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 27.71,
                "CBSA": 28.19,
                "state": 27.78
             },
             "error": {
                "this": 1.22,
                "CBSA": 0.62,
                "state": 0.56
             },
             "numerators": {
                "this": 122046,
                "CBSA": 440002,
                "state": 593417
             },
             "numerator_errors": {
                "this": 5675.4,
                "CBSA": 9716.4,
                "state": 11910.6
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 100
             },
             "error_ratio": {
                "this": 4.4,
                "CBSA": 2.2,
                "state": 2
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 30.99,
                "CBSA": 32.51,
                "state": 33.38
             },
             "error": {
                "this": 1.25,
                "CBSA": 0.77,
                "state": 0.63
             },
             "numerators": {
                "this": 136504,
                "CBSA": 507479,
                "state": 713215
             },
             "numerator_errors": {
                "this": 5870.8,
                "CBSA": 11948.9,
                "state": 13473.2
             },
             "index": {
                "this": 100,
                "CBSA": 95,
                "state": 93
             },
             "error_ratio": {
                "this": 4,
                "CBSA": 2.4,
                "state": 1.9
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 16.19,
                "CBSA": 16.83,
                "state": 16.66
             },
             "error": {
                "this": 0.9,
                "CBSA": 0.49,
                "state": 0.41
             },
             "numerators": {
                "this": 71326,
                "CBSA": 262662,
                "state": 355837
             },
             "numerator_errors": {
                "this": 4094.2,
                "CBSA": 7685.2,
                "state": 8837.2
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 97
             },
             "error_ratio": {
                "this": 5.6,
                "CBSA": 2.9,
                "state": 2.5
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 8.93,
                "CBSA": 8.81,
                "state": 9.04
             },
             "error": {
                "this": 0.72,
                "CBSA": 0.39,
                "state": 0.34
             },
             "numerators": {
                "this": 39343,
                "CBSA": 137445,
                "state": 193082
             },
             "numerator_errors": {
                "this": 3210.4,
                "CBSA": 6019.9,
                "state": 7164.6
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 99
             },
             "error_ratio": {
                "this": 8.1,
                "CBSA": 4.4,
                "state": 3.8
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 22.64,
                "CBSA": 22.64,
                "state": 19.83
             },
             "error": {
                "this": 1.29,
                "CBSA": 0.55,
                "state": 0.46
             },
             "numerators": {
                "this": 147441,
                "CBSA": 513230,
                "state": 610652
             },
             "numerator_errors": {
                "this": 8426,
                "CBSA": 12557,
                "state": 14149
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 114
             },
             "error_ratio": {
                "this": 5.7,
                "CBSA": 2.4,
                "state": 2.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 7.39,
                   "CBSA": 7.1,
                   "state": 7.34
                },
                "error": {
                   "this": 0.5,
                   "CBSA": 0.29,
                   "state": 0.26
                },
                "numerators": {
                   "this": 9844,
                   "CBSA": 34398,
                   "state": 42323
                },
                "numerator_errors": {
                   "this": 690,
                   "CBSA": 1477,
                   "state": 1570
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 101
                },
                "error_ratio": {
                   "this": 6.8,
                   "CBSA": 4.1,
                   "state": 3.5
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 25.47,
                   "CBSA": 32.44,
                   "state": 31.07
                },
                "error": {
                   "this": 1.18,
                   "CBSA": 0.36,
                   "state": 0.37
                },
                "numerators": {
                   "this": 33915,
                   "CBSA": 157207,
                   "state": 179197
                },
                "numerator_errors": {
                   "this": 1687,
                   "CBSA": 2499,
                   "state": 2734
                },
                "index": {
                   "this": 100,
                   "CBSA": 79,
                   "state": 82
                },
                "error_ratio": {
                   "this": 4.6,
                   "CBSA": 1.1,
                   "state": 1.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 1.81,
                   "CBSA": 3.77,
                   "state": 3.47
                },
                "error": {
                   "this": 0.4,
                   "CBSA": 0.29,
                   "state": 0.26
                },
                "numerators": {
                   "this": 2409,
                   "CBSA": 18267,
                   "state": 20020
                },
                "numerator_errors": {
                   "this": 530,
                   "CBSA": 1420,
                   "state": 1502
                },
                "index": {
                   "this": 100,
                   "CBSA": 48,
                   "state": 52
                },
                "error_ratio": {
                   "this": 22.1,
                   "CBSA": 7.7,
                   "state": 7.5
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 0.45,
                   "CBSA": 0.47,
                   "state": 0.54
                },
                "error": {
                   "this": 0.13,
                   "CBSA": 0.09,
                   "state": 0.09
                },
                "numerators": {
                   "this": 594,
                   "CBSA": 2271,
                   "state": 3142
                },
                "numerator_errors": {
                   "this": 177,
                   "CBSA": 415,
                   "state": 531
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 83
                },
                "error_ratio": {
                   "this": 28.9,
                   "CBSA": 19.1,
                   "state": 16.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 63.18,
                   "CBSA": 54.58,
                   "state": 55.71
                },
                "error": {
                   "this": 1.18,
                   "CBSA": 0.51,
                   "state": 0.46
                },
                "numerators": {
                   "this": 84119,
                   "CBSA": 264552,
                   "state": 321285
                },
                "numerator_errors": {
                   "this": 2180,
                   "CBSA": 3854,
                   "state": 4004
                },
                "index": {
                   "this": 100,
                   "CBSA": 116,
                   "state": 113
                },
                "error_ratio": {
                   "this": 1.9,
                   "CBSA": 0.9,
                   "state": 0.8
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 1.69,
                   "CBSA": 1.65,
                   "state": 1.86
                },
                "error": {
                   "this": 0.26,
                   "CBSA": 0.13,
                   "state": 0.12
                },
                "numerators": {
                   "this": 2252,
                   "CBSA": 7981,
                   "state": 10752
                },
                "numerator_errors": {
                   "this": 342,
                   "CBSA": 614,
                   "state": 696
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 91
                },
                "error_ratio": {
                   "this": 15.4,
                   "CBSA": 7.9,
                   "state": 6.5
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 30.41
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.23
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 848177
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 6370.6
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.8
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 63.2,
                   "CBSA": 65.13,
                   "state": 67.24
                },
                "error": {
                   "this": 2.75,
                   "CBSA": 1.72,
                   "state": 1.43
                },
                "numerators": {
                   "this": 72394,
                   "CBSA": 248650,
                   "state": 340619
                },
                "numerator_errors": {
                   "this": 4897,
                   "CBSA": 6587,
                   "state": 7306
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 94
                },
                "error_ratio": {
                   "this": 4.4,
                   "CBSA": 2.6,
                   "state": 2.1
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 32.3,
                   "CBSA": 28.2,
                   "state": 26.78
                },
                "error": {
                   "this": 3.07,
                   "CBSA": 1.47,
                   "state": 1.19
                },
                "numerators": {
                   "this": 37003,
                   "CBSA": 107668,
                   "state": 135637
                },
                "numerator_errors": {
                   "this": 4003,
                   "CBSA": 5617,
                   "state": 6053
                },
                "index": {
                   "this": 100,
                   "CBSA": 115,
                   "state": 121
                },
                "error_ratio": {
                   "this": 9.5,
                   "CBSA": 5.2,
                   "state": 4.4
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 1.58,
                   "CBSA": 1.48,
                   "state": 1.47
                },
                "error": {
                   "this": 0.78,
                   "CBSA": 0.45,
                   "state": 0.46
                },
                "numerators": {
                   "this": 1812,
                   "CBSA": 5649,
                   "state": 7427
                },
                "numerator_errors": {
                   "this": 895,
                   "CBSA": 1709,
                   "state": 2305
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 107
                },
                "error_ratio": {
                   "this": 49.4,
                   "CBSA": 30.4,
                   "state": 31.3
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 1.85,
                   "CBSA": 3.49,
                   "state": 3.03
                },
                "error": {
                   "this": 0.85,
                   "CBSA": 0.66,
                   "state": 0.55
                },
                "numerators": {
                   "this": 2120,
                   "CBSA": 13322,
                   "state": 15342
                },
                "numerator_errors": {
                   "this": 976,
                   "CBSA": 2518,
                   "state": 2778
                },
                "index": {
                   "this": 100,
                   "CBSA": 53,
                   "state": 61
                },
                "error_ratio": {
                   "this": 45.9,
                   "CBSA": 18.9,
                   "state": 18.2
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.06,
                   "CBSA": 1.71,
                   "state": 1.49
                },
                "error": {
                   "this": 0.64,
                   "CBSA": 0.68,
                   "state": 0.5
                },
                "numerators": {
                   "this": 1217,
                   "CBSA": 6515,
                   "state": 7544
                },
                "numerator_errors": {
                   "this": 738,
                   "CBSA": 2591,
                   "state": 2529
                },
                "index": {
                   "this": 100,
                   "CBSA": 62,
                   "state": 71
                },
                "error_ratio": {
                   "this": 60.4,
                   "CBSA": 39.8,
                   "state": 33.6
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 64.83,
                   "CBSA": 65.28,
                   "state": 69.1
                },
                "error": {
                   "this": 1.71,
                   "CBSA": 0.62,
                   "state": 0.47
                },
                "numerators": {
                   "this": 319326,
                   "CBSA": 1139650,
                   "state": 1651099
                },
                "numerator_errors": {
                   "this": 9918.5,
                   "CBSA": 10867.6,
                   "state": 11330.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 94
                },
                "error_ratio": {
                   "this": 2.6,
                   "CBSA": 0.9,
                   "state": 0.7
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 25.7,
                   "CBSA": 22.48,
                   "state": 20.49
                },
                "error": {
                   "this": 1.61,
                   "CBSA": 0.46,
                   "state": 0.35
                },
                "numerators": {
                   "this": 126593,
                   "CBSA": 392495,
                   "state": 489537
                },
                "numerator_errors": {
                   "this": 8192.5,
                   "CBSA": 8102.7,
                   "state": 8471.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 114,
                   "state": 125
                },
                "error_ratio": {
                   "this": 6.3,
                   "CBSA": 2,
                   "state": 1.7
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 3.14,
                   "CBSA": 3.02,
                   "state": 2.75
                },
                "error": {
                   "this": 0.58,
                   "CBSA": 0.31,
                   "state": 0.24
                },
                "numerators": {
                   "this": 15476,
                   "CBSA": 52687,
                   "state": 65644
                },
                "numerator_errors": {
                   "this": 2871.9,
                   "CBSA": 5360.8,
                   "state": 5660.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 104,
                   "state": 114
                },
                "error_ratio": {
                   "this": 18.5,
                   "CBSA": 10.3,
                   "state": 8.7
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 5.55,
                   "CBSA": 7.99,
                   "state": 6.62
                },
                "error": {
                   "this": 0.73,
                   "CBSA": 0.31,
                   "state": 0.25
                },
                "numerators": {
                   "this": 27331,
                   "CBSA": 139487,
                   "state": 158109
                },
                "numerator_errors": {
                   "this": 3607.1,
                   "CBSA": 5499,
                   "state": 6036.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 69,
                   "state": 84
                },
                "error_ratio": {
                   "this": 13.2,
                   "CBSA": 3.9,
                   "state": 3.8
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.77,
                   "CBSA": 1.24,
                   "state": 1.05
                },
                "error": {
                   "this": 0.32,
                   "CBSA": 0.22,
                   "state": 0.17
                },
                "numerators": {
                   "this": 3807,
                   "CBSA": 21599,
                   "state": 25030
                },
                "numerator_errors": {
                   "this": 1563.3,
                   "CBSA": 3785.2,
                   "state": 3967.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 62,
                   "state": 73
                },
                "error_ratio": {
                   "this": 41.6,
                   "CBSA": 17.7,
                   "state": 16.2
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 711,
                   "CBSA": 1847,
                   "state": 3445
                },
                "error": {
                   "this": 439.33,
                   "CBSA": 622.09,
                   "state": 929.35
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 38,
                   "state": 21
                },
                "error_ratio": {
                   "this": 61.8,
                   "CBSA": 33.7,
                   "state": 27
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 2257,
                   "CBSA": 8116,
                   "state": 12362
                },
                "error": {
                   "this": 636.75,
                   "CBSA": 1065.88,
                   "state": 1362.87
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 28,
                   "state": 18
                },
                "error_ratio": {
                   "this": 28.2,
                   "CBSA": 13.1,
                   "state": 11
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 13208,
                   "CBSA": 47217,
                   "state": 73359
                },
                "error": {
                   "this": 1770.57,
                   "CBSA": 3061.45,
                   "state": 3637.98
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 28,
                   "state": 18
                },
                "error_ratio": {
                   "this": 13.4,
                   "CBSA": 6.5,
                   "state": 5
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 9723,
                   "CBSA": 37297,
                   "state": 50602
                },
                "error": {
                   "this": 1680.7,
                   "CBSA": 3257.34,
                   "state": 3578.06
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 26,
                   "state": 19
                },
                "error_ratio": {
                   "this": 17.3,
                   "CBSA": 8.7,
                   "state": 7.1
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 9068,
                   "CBSA": 35123,
                   "state": 49443
                },
                "error": {
                   "this": 1813.82,
                   "CBSA": 3419.4,
                   "state": 4191.17
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 26,
                   "state": 18
                },
                "error_ratio": {
                   "this": 20,
                   "CBSA": 9.7,
                   "state": 8.5
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 36935,
                   "CBSA": 129104,
                   "state": 189240
                },
                "error": {
                   "this": 3336,
                   "CBSA": 5300,
                   "state": 6425
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 29,
                   "state": 20
                },
                "error_ratio": {
                   "this": 9,
                   "CBSA": 4.1,
                   "state": 3.4
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 3899,
                   "CBSA": 16342,
                   "state": 23267
                },
                "error": {
                   "this": 1046,
                   "CBSA": 2524,
                   "state": 3321
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 24,
                   "state": 17
                },
                "error_ratio": {
                   "this": 26.8,
                   "CBSA": 15.4,
                   "state": 14.3
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 40834,
                "CBSA": 145446,
                "state": 212507
             },
             "error": {
                "this": 3501,
                "CBSA": 6022,
                "state": 7047
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 28,
                "state": 19
             },
             "error_ratio": {
                "this": 8.6,
                "CBSA": 4.1,
                "state": 3.3
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 8.33,
                "CBSA": 8.37,
                "state": 8.93
             },
             "error": {
                "this": 0.71,
                "CBSA": 0.35,
                "state": 0.3
             },
             "numerators": {
                "this": 40834,
                "CBSA": 145446,
                "state": 212507
             },
             "numerator_errors": {
                "this": 3501,
                "CBSA": 6022,
                "state": 7047
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 93
             },
             "error_ratio": {
                "this": 8.5,
                "CBSA": 4.2,
                "state": 3.4
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 367175351,
       "awater": 138524,
       "display_name": "Las Vegas, NV",
       "full_geoid": "16000US3240000",
       "population": 626637,
       "simple_name": "Las Vegas",
       "sumlevel": "160",
       "square_miles": 141.8,
       "population_density": 4594.1
    }
 }

 export default las_vegas