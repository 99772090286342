import React from 'react';
import {Table} from 'reactstrap';

const School = (school) => (
    <tr>
        <td className="align-middle text-nowrap">
            <a href={"https://www.greatschools.org/" + school.links.profile} target="_blank" className="text-decoration-none">{school.name} <i className="fa fa-angle-right"></i></a>
        </td>
        <td className="align-middle text-nowrap">
            {school.gradeLevels }
        </td>
        <td className="align-middle text-nowrap">
            {school['ranking'] && school['ranking'] != 'N/A' ? "第" + school['ranking'] : 'N/A' }
        </td>
        <td className="align-middle text-nowrap">
            {school.studentsPerTeacher}
        </td>
        <td className="align-middle text-nowrap">
            {school.rating ? school.rating + ' / 10' : 'N/A' }
        </td>
        <td className="align-middle text-nowrap">
            {school.subratings['Test Scores Rating'] ? school.subratings['Test Scores Rating'] + ' / 10' : 'N/A' }
        </td>
        <td className="align-middle text-nowrap">
            {school.subratings['Academic Progress Rating'] ? school.subratings['Academic Progress Rating'] + ' / 10' : 'N/A' }
        </td>
        <td className="align-middle text-nowrap">
            {school.subratings['Equity Overview Rating'] ? school.subratings['Equity Overview Rating'] + ' / 10' : 'N/A' }
        </td>
        <td className="align-middle text-nowrap">
            {school.subratings['College Readiness Rating'] ? school.subratings['College Readiness Rating'] + ' / 10' : 'N/A' }
        </td>
        <td className="align-middle text-nowrap">
            {school['ranking'] && <a href={"/" + school.districtName + "-" + school.name}>点击查看</a>}
        </td>
    </tr>
  )

const SchoolList = ({ schools }) => (
    <Table responsive striped className="mb-0">
        <thead>
            <tr>
                <th className="bt-0">学校名称</th>
                <th className="bt-0">年级</th>
                <th className="bt-0">排名</th>
                <th className="bt-0">师生比</th>
                <th className="bt-0">greatschool评分</th>
                <th className="bt-0">Test Scores Rating</th>
                <th className="bt-0">Academic Progress Rating</th>
                <th className="bt-0">Equity Overview Rating</th>
                <th className="bt-0">College Readiness Rating</th>
                <th className="bt-0">学校详情</th>
            </tr>
        </thead>
        <tbody>
            {schools.map(school => (
                <School key={school.name} {...school} />
            ))}
        </tbody>
    </Table>
)

export { SchoolList };
