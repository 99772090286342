exports.SAN_MATEO_LISTINGS = [
		{
			"closePrice": 1720000,
			"daysOnMovoto": 43,
			"id": "968e1c8a-11cc-44f3-ad84-57c48f3ed50c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871857_0_AIQbqe_p.jpeg",
			"listDate": "2021-12-06 07:34:47",
			"listingAgent": "Anthony Galli",
			"listPrice": 1595000,
			"lotSize": 8120,
			"sqftTotal": 1240,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871857",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1902,
			"zipCode": "94401",
			"path": "san-mateo-ca/339-n-claremont-st-san-mateo-ca-94401-100_80416248/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "56040a91-e248-4513-8938-a620efbea1ef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2021-12-06 15:37:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-06 07:34:47",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.573643,
				"lng": -122.329195,
				"zipcode": "94401",
				"subPremise": "",
				"address": "339 N Claremont St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1720000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1052000,
			"daysOnMovoto": 139,
			"id": "b5cb09f4-e117-419c-8f1d-f55f261ac138",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860111_0_nvMFY3_p.jpeg",
			"listDate": "2021-08-26 13:48:04",
			"listingAgent": "Gloria Heffran",
			"listPrice": 1098000,
			"lotSize": null,
			"sqftTotal": 1574,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860111",
			"bath": 2,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Estates Company",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94402",
			"path": "san-mateo-ca/111-w-3rd-ave-105-san-mateo-ca-94402/pid_hff9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -2000,
			"propertyId": "a480b8aa-717d-4923-ae3e-e7232187ce03",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-08-26 20:52:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 13:48:04",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.561248,
				"lng": -122.3277,
				"zipcode": "94402",
				"subPremise": "APT 105",
				"address": "111 W 3rd Ave #105"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1052000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 743850,
			"daysOnMovoto": 53,
			"id": "85e31ca3-b29f-45b5-b9aa-5b64cab15293",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870654_0_FZMAZ3_p.jpeg",
			"listDate": "2021-11-18 21:27:43",
			"listingAgent": "James Sherman",
			"listPrice": 738500,
			"lotSize": 1114918,
			"sqftTotal": 974,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870654",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94401",
			"path": "san-mateo-ca/835-n-humboldt-st-411-san-mateo-ca-94401/pid_nv49w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fb5573ef-6673-4f4c-a9a3-6550872b286d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-11-19 05:32:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 21:27:43",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.581461,
				"lng": -122.330543,
				"zipcode": "94401",
				"subPremise": "APT 411",
				"address": "835 N Humboldt St #411"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 743850,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 21,
			"id": "eb69c643-0f7b-4021-8ac4-94ac40289470",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872344_0_EJMRmf_p.jpeg",
			"listDate": "2021-12-17 00:00:00",
			"listingAgent": "Lin Storer",
			"listPrice": 999000,
			"lotSize": null,
			"sqftTotal": 1318,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872344",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 45,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1982,
			"zipCode": "94403",
			"path": "san-mateo-ca/55-w-20th-ave-apt-109-san-mateo-ca-94403-100_ml81872344/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e64cdfd2-5c4e-409d-a635-511391492529",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-12-17 19:42:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-17 00:00:00",
			"virtualTourLink": "http://55w20thaveunit109.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.546972,
				"lng": -122.31114,
				"zipcode": "94403",
				"subPremise": "APT 109",
				"address": "55 W 20th Ave #109"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://55w20thaveunit109.com"
		},
		{
			"closePrice": 505000,
			"daysOnMovoto": 81,
			"id": "9241b914-9c8c-414f-9aee-db21000aded6",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867155_0_frRIqb_p.jpeg",
			"listDate": "2021-10-17 15:09:04",
			"listingAgent": "Corina Colby",
			"listPrice": 525000,
			"lotSize": 107197,
			"sqftTotal": 710,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867155",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1949,
			"zipCode": "94401",
			"path": "san-mateo-ca/177-n-el-camino-real-12-san-mateo-ca-94401-100_80005634/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c4492aaf-277f-4fea-8213-53d552210ac2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-10-17 22:12:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-17 15:09:04",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.56794,
				"lng": -122.332328,
				"zipcode": "94401",
				"subPremise": "APT 12",
				"address": "177 N El Camino Real #12"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 505000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3300000,
			"daysOnMovoto": 100,
			"id": "edfcd726-9aee-477d-8be0-a6ef93ef5879",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81864626_0_QVu7Vr_p.jpeg",
			"listDate": "2021-09-28 22:00:22",
			"listingAgent": "Robert Reyes",
			"listPrice": 3388888,
			"lotSize": 7248,
			"sqftTotal": 2982,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81864626",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Pinnacle Real Estate",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2016,
			"zipCode": "94401",
			"path": "san-mateo-ca/812-prospect-row-san-mateo-ca-94401/pid_41m6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "29b95389-f126-4355-8542-5d6d7e4335f3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-09-29 05:02:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-28 22:00:22",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.57434,
				"lng": -122.339957,
				"zipcode": "94401",
				"subPremise": "",
				"address": "812 Prospect Row"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 800000,
			"daysOnMovoto": 175,
			"id": "a55ad622-86b5-4170-b7af-28be735cad8a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81853645_0_7NqiY7_p.jpeg",
			"listDate": "2021-07-15 07:59:04",
			"listingAgent": "John Marshall",
			"listPrice": 799900,
			"lotSize": null,
			"sqftTotal": 990,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81853645",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 20,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1974,
			"zipCode": "94401",
			"path": "san-mateo-ca/316-n-el-camino-real-304-san-mateo-ca-94401-100_81032945/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 900,
			"propertyId": "1fd2a089-20f7-408a-b5a5-bb4c8db6467a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-07 00:00:00",
			"createdAt": "2021-07-15 15:02:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-15 07:59:04",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2893823",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.569651,
				"lng": -122.333944,
				"zipcode": "94401",
				"subPremise": "APT 304",
				"address": "316 N El Camino Real #304"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2893823"
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 69,
			"id": "effcbc1f-b5f3-455d-b27b-e12489ccd05b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868467_0_Bm3Vmn_p.jpeg",
			"listDate": "2021-10-28 18:04:05",
			"listingAgent": "John Shroyer",
			"listPrice": 1288000,
			"lotSize": 4748,
			"sqftTotal": 1160,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868467",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1947,
			"zipCode": "94401",
			"path": "san-mateo-ca/11-north-quebec-street-san-mateo-ca-94401-100_ml80432033/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a6dcf8d1-49e1-4703-808c-5861d6526f91",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-10-29 01:07:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 18:04:05",
			"virtualTourLink": "http://www.11nquebec.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.577908,
				"lng": -122.312694,
				"zipcode": "94401",
				"subPremise": "",
				"address": "11 N Quebec St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.11nquebec.com"
		},
		{
			"closePrice": 1275000,
			"daysOnMovoto": 50,
			"id": "508ed72b-6f48-46d4-8724-b34196abb96c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870194_0_QyVNbq_p.jpeg",
			"listDate": "2021-11-16 00:00:00",
			"listingAgent": "Team Pete & Christine",
			"listPrice": 1098888,
			"lotSize": 5820,
			"sqftTotal": 1218,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870194",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "RE/MAX Star Properties",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2013,
			"zipCode": "94402",
			"path": "san-mateo-ca/901-n-hummingbird-ln-san-mateo-ca-94402/pid_ym79w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "82b0f104-812c-429e-a010-35602bc65bb6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-11-16 22:07:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-16 00:00:00",
			"virtualTourLink": "http://www.901northhummingbird.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.564744,
				"lng": -122.308805,
				"zipcode": "94402",
				"subPremise": "",
				"address": "901 N Hummingbird Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.901northhummingbird.com"
		},
		{
			"closePrice": 1800000,
			"daysOnMovoto": 86,
			"id": "35150ce8-592c-4d09-a2e9-862f93a4e8cb",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81865322_0_6ZAaVR_p.jpeg",
			"listDate": "2021-10-04 08:03:19",
			"listingAgent": "Ken Constantino",
			"listPrice": 1598000,
			"lotSize": 5223,
			"sqftTotal": 1280,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81865322",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1939,
			"zipCode": "94402",
			"path": "san-mateo-ca/817-w-grant-pl-san-mateo-ca-94402-100_ml81865322/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "18fb1073-73a4-4ff1-bc97-8c0c6698ebbd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-10-04 15:07:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-04 08:03:19",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2921523",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.56271,
				"lng": -122.31166,
				"zipcode": "94402",
				"subPremise": "",
				"address": "817 W Grant Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2921523"
		},
		{
			"closePrice": 1420000,
			"daysOnMovoto": 15,
			"id": "d3250b04-7b66-4cd9-8de2-b6b3a95e95f4",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81872420_0_bAnjrz_p.jpeg",
			"listDate": "2021-12-13 11:03:16",
			"listingAgent": "Melissa Moore",
			"listPrice": 990000,
			"lotSize": 5001,
			"sqftTotal": 1120,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81872420",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Vilmont Investments Prop.",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1950,
			"zipCode": "94403",
			"path": "san-mateo-ca/3908-pasadena-dr-san-mateo-ca-94403/pid_pr16w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1775c0c7-e007-467a-b7bf-3837a5c4428e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-12-13 19:07:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-13 11:03:16",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.536936,
				"lng": -122.285437,
				"zipcode": "94403",
				"subPremise": "",
				"address": "3908 Pasadena Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1420000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1668888,
			"daysOnMovoto": 65,
			"id": "f9ad6cc5-66bd-4e8b-901f-501463c76bc3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867867_0_EaIAYq_p.jpeg",
			"listDate": "2021-10-22 16:14:10",
			"listingAgent": "Julie Gilmartin",
			"listPrice": 1680000,
			"lotSize": 7749,
			"sqftTotal": 1420,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867867",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Gilmartin Group",
			"photoCount": 17,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1925,
			"zipCode": "94401",
			"path": "san-mateo-ca/664-n-el-camino-real-san-mateo-ca-94401/pid_jgq6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4bfa4f39-1d88-44c8-833a-7581688edbad",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-10-22 23:17:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 16:14:10",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.572723,
				"lng": -122.339381,
				"zipcode": "94401",
				"subPremise": "",
				"address": "664 N El Camino Real"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1668888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2230893,
			"daysOnMovoto": 70,
			"id": "ea0bbe11-7cb9-4c25-9d32-0b26f3df74db",
			"tnImgPath": "https://pi.movoto.com/p/12/40970947_0_BVf7iv_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 2288893,
			"lotSize": 2769,
			"sqftTotal": 1917,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970947",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/426-waters-park-cir-san-mateo-ca-94403-12_40970947/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d6856ff3-0aff-4d82-9554-2bc665e62743",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-10-14 21:11:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=iATYoNo9hqn",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.550969,
				"lng": -122.290205,
				"zipcode": "94403",
				"subPremise": "",
				"address": "426 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2230893,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=iATYoNo9hqn"
		},
		{
			"closePrice": 1887117,
			"daysOnMovoto": 229,
			"id": "71f76512-0a33-4412-9bb9-b5308969a8f3",
			"tnImgPath": "https://pi.movoto.com/p/12/40949097_0_zeba2E_p.jpeg",
			"listDate": "2021-05-08 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 1863590,
			"lotSize": 0,
			"sqftTotal": 2088,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40949097",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/171-waters-park-cir-san-mateo-ca-94403-12_40949097/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c4daedb3-0a30-4432-84de-f9d04e8109c9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-05-08 19:11:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.551988,
				"lng": -122.290694,
				"zipcode": "94403",
				"subPremise": "",
				"address": "171 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1887117,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1372373,
			"daysOnMovoto": 130,
			"id": "fb82fba2-680b-44c3-9880-6ef1896c150c",
			"tnImgPath": "https://pi.movoto.com/p/12/40963236_0_qMu2iQ_p.jpeg",
			"listDate": "2021-08-14 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 1372373,
			"lotSize": 0,
			"sqftTotal": 1602,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963236",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/172-waters-park-cir-san-mateo-ca-94403-12_40963236/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "de3c33f6-3ebd-4255-acce-8ca407b56328",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-08-14 17:41:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.551988,
				"lng": -122.290694,
				"zipcode": "94403",
				"subPremise": "",
				"address": "172 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1372373,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1739087,
			"daysOnMovoto": 228,
			"id": "ecc07821-15b7-496e-96af-6bcc5c57fa21",
			"tnImgPath": "https://pi.movoto.com/p/12/40949108_0_qMu2iQ_p.jpeg",
			"listDate": "2021-05-08 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 1763190,
			"lotSize": 0,
			"sqftTotal": 2065,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40949108",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/177-waters-park-cir-san-mateo-ca-94403-12_40949108/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8f5ff96e-fab8-4663-b2c0-79bafca54342",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-05-08 20:26:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.551988,
				"lng": -122.290694,
				"zipcode": "94403",
				"subPremise": "",
				"address": "177 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1739087,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 37,
			"id": "7d745b2a-c945-4fb0-86a0-776bafb1a65e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870187_0_UuFv22_p.jpeg",
			"listDate": "2021-11-13 17:43:05",
			"listingAgent": "Alfred Chacon",
			"listPrice": 1272000,
			"lotSize": 5001,
			"sqftTotal": 1100,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870187",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "AC Realty Inc.",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1958,
			"zipCode": "94403",
			"path": "san-mateo-ca/2450-s-norfolk-st-san-mateo-ca-94403-100_ml81870187/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1534b63f-a691-408c-8a34-d6c3e290bb4a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-14 01:47:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-13 17:43:05",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.549339,
				"lng": -122.288377,
				"zipcode": "94403",
				"subPremise": "",
				"address": "2450 S Norfolk St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1334195,
			"daysOnMovoto": 68,
			"id": "8b1e35ba-bce2-42f5-9951-aef92ee7ddc7",
			"tnImgPath": "https://pi.movoto.com/p/12/40970979_0_qMu2iQ_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 1347995,
			"lotSize": 0,
			"sqftTotal": 1546,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970979",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/173-waters-park-cir-san-mateo-ca-94403-2001_4o6mrpcgfdb/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b553affd-e383-47ef-b91a-429bab194893",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-10-14 21:56:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=oTHVSDUFr7V",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.550874,
				"lng": -122.290364,
				"zipcode": "94403",
				"subPremise": "",
				"address": "173 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1334195,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=oTHVSDUFr7V"
		},
		{
			"closePrice": 1135000,
			"daysOnMovoto": 70,
			"id": "cea936eb-b254-452f-90c1-6fa958934b34",
			"tnImgPath": "https://pi.movoto.com/p/12/40970405_0_nQuRaA_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Tracy Tang",
			"listPrice": 1098000,
			"lotSize": 1077,
			"sqftTotal": 1507,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970405",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1997,
			"zipCode": "94403",
			"path": "san-mateo-ca/1981-ofarrell-st-san-mateo-ca-94403-100_81308873/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -30000,
			"propertyId": "d3452f33-9dc6-43b7-9108-e6d32d147b1e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-10-12 19:21:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=zNZ8irUzTgN",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.547842,
				"lng": -122.314051,
				"zipcode": "94403",
				"subPremise": "",
				"address": "1981 Ofarrell St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1135000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=zNZ8irUzTgN"
		},
		{
			"closePrice": 1120000,
			"daysOnMovoto": 117,
			"id": "e5558d76-ed85-472b-81f6-e1ad9de3b722",
			"tnImgPath": "https://pi.movoto.com/p/12/40964802_0_A3MZJn_p.jpeg",
			"listDate": "2021-08-26 00:00:00",
			"listingAgent": "Daryll Canlas",
			"listPrice": 1149000,
			"lotSize": 5000,
			"sqftTotal": 1000,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964802",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "The Canlas Brothers, Inc",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1949,
			"zipCode": "94401",
			"path": "san-mateo-ca/724-ada-st-san-mateo-ca-94401/pid_myr6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -39000,
			"propertyId": "69139c44-9aa9-4cbc-8fe6-781ed581b01f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-08-26 22:01:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.570445,
				"lng": -122.307496,
				"zipcode": "94401",
				"subPremise": "",
				"address": "724 Ada St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1120000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1475000,
			"daysOnMovoto": 38,
			"id": "b6018e66-04b1-4cb6-b35b-1fcc24ad41cf",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869885_0_6MJruJ_p.jpeg",
			"listDate": "2021-11-11 09:06:28",
			"listingAgent": "Joanne Jordan",
			"listPrice": 1435000,
			"lotSize": 4600,
			"sqftTotal": 1905,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869885",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 19,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1940,
			"zipCode": "94401",
			"path": "san-mateo-ca/8-10-n-idaho-st-san-mateo-ca-94401-100_ml81869885/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eb7b6447-29b0-4fa2-b3bf-daeb3ee8a75a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-11-11 17:12:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 09:06:28",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"zipcode": "94401",
				"subPremise": "",
				"address": "8-10 N Idaho St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 778000,
			"daysOnMovoto": 48,
			"id": "1b294468-ce20-4203-b39b-05ae5c2143ba",
			"tnImgPath": "https://pi.movoto.com/p/12/40972567_0_M6anZR_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Tim S. Wang",
			"listPrice": 688000,
			"lotSize": 897,
			"sqftTotal": 889,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972567",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1963,
			"zipCode": "94403",
			"path": "san-mateo-ca/2977-s-norfolk-st-110-san-mateo-ca-94403/pid_cif9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "62fcf72b-5003-453b-a5c0-876479537f34",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-10-29 18:01:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.545267,
				"lng": -122.283773,
				"zipcode": "94403",
				"subPremise": "APT 110",
				"address": "2977 S Norfolk St #110"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 778000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 845000,
			"daysOnMovoto": 56,
			"id": "84ed5400-72c4-4c33-b0fe-38dc782b1588",
			"tnImgPath": "https://pi.movoto.com/p/12/40969819_0_Rb67FI_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Jayanta Samanta",
			"listPrice": 849950,
			"lotSize": 568,
			"sqftTotal": 1150,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969819",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1961,
			"zipCode": "94403",
			"path": "san-mateo-ca/1547-day-ave-139-san-mateo-ca-94403/pid_sp88w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d6ec614c-b2f2-4d04-af87-799fcf656de2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-10-20 21:31:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=AeMDbKBGr7m&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.55164,
				"lng": -122.287314,
				"zipcode": "94403",
				"subPremise": "APT 139",
				"address": "1547 Day Ave #139"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 845000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=AeMDbKBGr7m&mls=1"
		},
		{
			"closePrice": 1332960,
			"daysOnMovoto": 69,
			"id": "e06e204f-f21d-4337-b019-ab61cc6c9716",
			"tnImgPath": "https://pi.movoto.com/p/12/40970076_0_qMu2iQ_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 1362960,
			"lotSize": 0,
			"sqftTotal": 1602,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970076",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/195-waters-park-cir-san-mateo-ca-94403-2001_jkcphmf7c1/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b574eab9-eb57-4045-a79d-8a364310fd0b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-10-07 20:56:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=oTHVSDUFr7V",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.551988,
				"lng": -122.290694,
				"zipcode": "94403",
				"subPremise": "",
				"address": "195 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1332960,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=oTHVSDUFr7V"
		},
		{
			"closePrice": 615000,
			"daysOnMovoto": 6,
			"id": "8e6b7f9c-ebb4-43b1-902b-71839debe905",
			"tnImgPath": "https://pi.movoto.com/p/110/421615713_0_bZ32nn_p.jpeg",
			"listDate": "2021-12-07 00:00:00",
			"listingAgent": "Jian Ying Lin",
			"listPrice": 598000,
			"lotSize": 1114920,
			"sqftTotal": 693,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421615713",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94401",
			"path": "san-mateo-ca/800-n-delaware-st-207-san-mateo-ca-94401-100_81318086/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c06880bb-4512-4d37-ab4a-a728806ee008",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-12-08 08:16:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.580688,
				"lng": -122.331245,
				"zipcode": "94401",
				"subPremise": "APT 207",
				"address": "800 N Delaware St #207"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 615000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 54,
			"id": "11dc4d14-17c8-42f4-afaa-2b8aac9848c7",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867327_0_aAjrUR_p.jpeg",
			"listDate": "2021-10-19 11:51:06",
			"listingAgent": "Steve Zirelli",
			"listPrice": 2549000,
			"lotSize": 9374,
			"sqftTotal": 4110,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867327",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Cornish & Carey Commercial",
			"photoCount": 26,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1975,
			"zipCode": "94402",
			"path": "san-mateo-ca/600-9th-ave-san-mateo-ca-94402/pid_j2o6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d9cf6877-450a-4edf-89af-7346d649cd11",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-19 18:57:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-19 11:51:06",
			"virtualTourLink": "https://urldefense.proofpoint.com/v2/url?u=https-3A__youtu.be_kWnedBINcI4&d=DwICAg&c=3v6EBbtpnn9A7jIZYjOw6KN7Pe17WoimzcinOq2Xztg&r=b_LU0R0JfdZBAGGr8lltZEMI0ct-6lF2HQx0Xs7DLks&m=MF88z6SbmEI7DmZraHF4QdbG3fs-Vwg9BOP2rECdTTs&s=asqrI3tQoW_anLjN9coyEHJq5gq7vlgA",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.563075,
				"lng": -122.315314,
				"zipcode": "94402",
				"subPremise": "",
				"address": "600 9th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://urldefense.proofpoint.com/v2/url?u=https-3A__youtu.be_kWnedBINcI4&d=DwICAg&c=3v6EBbtpnn9A7jIZYjOw6KN7Pe17WoimzcinOq2Xztg&r=b_LU0R0JfdZBAGGr8lltZEMI0ct-6lF2HQx0Xs7DLks&m=MF88z6SbmEI7DmZraHF4QdbG3fs-Vwg9BOP2rECdTTs&s=asqrI3tQoW_anLjN9coyEHJq5gq7vlgA"
		},
		{
			"closePrice": 2436303,
			"daysOnMovoto": 66,
			"id": "2d7f034d-2cb4-45b1-a9e9-9a3f47f8dc7f",
			"tnImgPath": "https://pi.movoto.com/p/12/40970313_0_3afv3e_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 2494503,
			"lotSize": 2789,
			"sqftTotal": 2244,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970313",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/442-waters-park-cir-san-mateo-ca-94403-2001_qcipp0c495/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "af454bce-909a-43c7-8086-03073028d7be",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-08 22:11:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=NKctqq6M8ge",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.550934,
				"lng": -122.290263,
				"zipcode": "94403",
				"subPremise": "",
				"address": "442 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2436303,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=NKctqq6M8ge"
		},
		{
			"closePrice": 1372868,
			"daysOnMovoto": 201,
			"id": "cb360a3c-15f8-4047-a820-2a2fb6b33fdc",
			"tnImgPath": "https://pi.movoto.com/p/12/40951566_0_YJBaJI_p.jpeg",
			"listDate": "2021-05-26 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 1486690,
			"lotSize": 0,
			"sqftTotal": 1602,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40951566",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/182-waters-park-cir-san-mateo-ca-94403-12_40951566/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bbf674ec-f5bf-435e-b9c3-ab0b82f60ee3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-05-26 19:56:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.551988,
				"lng": -122.290694,
				"zipcode": "94403",
				"subPremise": "",
				"address": "182 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1372868,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 975000,
			"daysOnMovoto": 23,
			"id": "aefaa55b-ef7e-4048-8068-09650e318335",
			"tnImgPath": "https://pi.movoto.com/p/110/421612383_0_fuNzBr_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Roger Heffner",
			"listPrice": 1095000,
			"lotSize": 13551,
			"sqftTotal": 1100,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421612383",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Vanguard Properties",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1929,
			"zipCode": "94402",
			"path": "san-mateo-ca/w-3rd-ave-11-san-mateo-ca-94402-100_80031281/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2d3e1110-3134-4adc-9a32-256d289958fc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-11 00:00:00",
			"createdAt": "2021-11-19 02:26:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.561835,
				"lng": -122.328075,
				"zipcode": "94402",
				"subPremise": "APT 11",
				"address": "110 W 3rd Ave #11"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 975000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 42,
			"id": "69f7f501-b817-4929-82fb-e5838c6c5668",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868071_0_U33Ae2_p.jpeg",
			"listDate": "2021-10-26 00:16:38",
			"listingAgent": "Martina Wong",
			"listPrice": 1950000,
			"lotSize": 9940,
			"sqftTotal": 1960,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868071",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Gentry Real Estate",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1956,
			"zipCode": "94403",
			"path": "san-mateo-ca/2375-david-ct-san-mateo-ca-94403/pid_olx6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f730265a-6eb0-4415-9b77-37a67ddc99c5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-10-26 07:22:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 00:16:38",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.548981,
				"lng": -122.289689,
				"zipcode": "94403",
				"subPremise": "",
				"address": "2375 David Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1687500,
			"daysOnMovoto": 46,
			"id": "6bb52445-4a77-4651-b78c-723f2b65cb3f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81867842_0_AMjajJ_p.jpeg",
			"listDate": "2021-10-22 14:54:10",
			"listingAgent": "Stanley Lo",
			"listPrice": 1599000,
			"lotSize": 6251,
			"sqftTotal": 1455,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81867842",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Green Banker Realty",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1939,
			"zipCode": "94402",
			"path": "san-mateo-ca/10-14th-ave-san-mateo-ca-94402-100_ml81867842/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "be885167-e921-4152-a40e-1445a2a2f3f0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-10-22 21:57:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 14:54:10",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.554674,
				"lng": -122.316284,
				"zipcode": "94402",
				"subPremise": "",
				"address": "10 14th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1687500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2850000,
			"daysOnMovoto": 33,
			"id": "85057f35-5aac-49ef-8a1f-e0ea5793f792",
			"tnImgPath": "https://pi.movoto.com/p/12/40972702_0_2YIJjF_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Michael Curia",
			"listPrice": 2486000,
			"lotSize": 7000,
			"sqftTotal": 2530,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972702",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Community Realty & Investment",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1976,
			"zipCode": "94402",
			"path": "san-mateo-ca/624-caribbean-way-san-mateo-ca-94402/pid_igt6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f994f439-e033-4b42-a3f8-3d43b674385d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-10-29 22:22:00",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.547668,
				"lng": -122.325787,
				"zipcode": "94402",
				"subPremise": "",
				"address": "624 Caribbean Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1145000,
			"daysOnMovoto": 84,
			"id": "6adf5f2f-70c4-459c-8190-532b13c617ae",
			"tnImgPath": "https://pi.movoto.com/p/101/421592332_0_ZVFu2z_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Michael C Williams",
			"listPrice": 1150000,
			"lotSize": 52514,
			"sqftTotal": 1320,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421592332",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Aalto, Inc",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1993,
			"zipCode": "94403",
			"path": "san-mateo-ca/2074-s-delaware-st-san-mateo-ca-94403-100_ml81804569/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -25000,
			"propertyId": "9410525a-fc49-4ec4-9c29-360c7c325eee",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-09-08 21:43:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=mjZinE9sSuy",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.549803,
				"lng": -122.305785,
				"zipcode": "94403",
				"subPremise": "",
				"address": "2074 S Delaware St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1145000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=mjZinE9sSuy"
		},
		{
			"closePrice": 1350568,
			"daysOnMovoto": 54,
			"id": "83c34bde-1006-4208-9aae-a7571eb105e3",
			"tnImgPath": "https://pi.movoto.com/p/12/40970063_0_qMu2iQ_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 1360568,
			"lotSize": 0,
			"sqftTotal": 1602,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970063",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/185-waters-park-cir-san-mateo-ca-94403-12_40939488/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "445774e6-b06e-4e9e-ad3a-69db3fd337d6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-07 20:22:12",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=oTHVSDUFr7V",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.551988,
				"lng": -122.290694,
				"zipcode": "94403",
				"subPremise": "",
				"address": "185 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350568,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=oTHVSDUFr7V"
		},
		{
			"closePrice": 515000,
			"daysOnMovoto": 130,
			"id": "946b34e5-6f82-44bd-83e6-fb07c97105d2",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81854821_0_bArru2_p.jpeg",
			"listDate": "2021-07-22 11:41:35",
			"listingAgent": "Cam Thompson",
			"listPrice": 539000,
			"lotSize": null,
			"sqftTotal": 473,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81854821",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "KW Peninsula Estates",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94401",
			"path": "san-mateo-ca/833-n-humboldt-st-319-san-mateo-ca-94401-100_81421287/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a1f5dc64-d3eb-45ef-a0c3-abbcd916fab4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-07-22 18:47:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-22 11:41:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.580688,
				"lng": -122.331245,
				"zipcode": "94401",
				"subPremise": "APT 319",
				"address": "833 N Humboldt St #319"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 515000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 649000,
			"daysOnMovoto": 77,
			"id": "323ee679-7cc4-46ee-a675-97d501b58a56",
			"tnImgPath": "https://pi.movoto.com/p/110/421594520_0_rB2Fej_p.jpeg",
			"listDate": "2021-09-13 00:00:00",
			"listingAgent": "Joseph Kong",
			"listPrice": 670000,
			"lotSize": 28484,
			"sqftTotal": 746,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421594520",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Asia Pacific Groups",
			"photoCount": 6,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1982,
			"zipCode": "94404",
			"path": "san-mateo-ca/651-port-dr-apt-306-san-mateo-ca-94404-12_ml80608075/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -17000,
			"propertyId": "0b3e18d8-8105-49a5-a9ce-2794345d77a0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-09-14 05:11:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.569072,
				"lng": -122.288345,
				"zipcode": "94404",
				"subPremise": "APT 306",
				"address": "651 Port Dr #306"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 649000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1933293,
			"daysOnMovoto": 275,
			"id": "3757ad83-3fc8-4619-ae35-77878f7438d1",
			"tnImgPath": "https://pi.movoto.com/p/12/40938578_0_Mz3eeV_p.jpeg",
			"listDate": "2021-02-20 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 1862890,
			"lotSize": 0,
			"sqftTotal": 2088,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938578",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/181-waters-park-cir-san-mateo-ca-94403-12_40938578/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "52748d92-f9be-4980-86c0-7276b9f34227",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-02-20 23:51:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.551988,
				"lng": -122.290694,
				"zipcode": "94403",
				"subPremise": "",
				"address": "181 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1933293,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 549000,
			"daysOnMovoto": 46,
			"id": "f94e53a7-424d-40b0-bb79-04f4bdf3218c",
			"tnImgPath": "https://pi.movoto.com/p/110/421601135_0_v3MvB7_p.jpeg",
			"listDate": "2021-10-04 00:00:00",
			"listingAgent": "Maria Sagullo",
			"listPrice": 549000,
			"lotSize": 220318,
			"sqftTotal": 590,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421601135",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Hatch Realty Group, Inc.",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1961,
			"zipCode": "94403",
			"path": "san-mateo-ca/1548-day-ave-a-san-mateo-ca-94403/pid_t8c9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f238b8b5-bbb1-40ce-87b5-c0bf5a595838",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-10-05 07:16:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.550843,
				"lng": -122.28769,
				"zipcode": "94403",
				"subPremise": "APT A",
				"address": "1548 Day Ave #A"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 549000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 51,
			"id": "7773d419-71f4-4ed1-8bbd-ff65d910f653",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81864632_0_RN7zRI_p.jpeg",
			"listDate": "2021-09-28 23:25:07",
			"listingAgent": "Haneen Hayder",
			"listPrice": 1298000,
			"lotSize": 5998,
			"sqftTotal": 2130,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81864632",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 47,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1959,
			"zipCode": "94401",
			"path": "san-mateo-ca/141-n-eldorado-st-san-mateo-ca-94401-100_ml81864632/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77f961a3-1c23-4f10-8959-81de8e190837",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-09-29 06:27:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-28 23:25:07",
			"virtualTourLink": "https://my.matterport.com/show/?m=nTAc6ddPXJ3",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.572278,
				"lng": -122.324739,
				"zipcode": "94401",
				"subPremise": "",
				"address": "141 N Eldorado St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=nTAc6ddPXJ3"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 43,
			"id": "a835ffaa-afa8-4e07-a61e-a2afd0b185ae",
			"tnImgPath": "https://pi.movoto.com/p/12/40969921_0_raNjAv_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Kristen Barker",
			"listPrice": 1500000,
			"lotSize": 5252,
			"sqftTotal": 1530,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969921",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1956,
			"zipCode": "94403",
			"path": "san-mateo-ca/2250-bermuda-dr-san-mateo-ca-94403/pid_6806w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a85b2afc-17bc-454d-b514-07457119a6cf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-10-07 00:11:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.547913,
				"lng": -122.295758,
				"zipcode": "94403",
				"subPremise": "",
				"address": "2250 Bermuda Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1804725,
			"daysOnMovoto": 272,
			"id": "0b8f88eb-eea8-44df-92db-b95c80f03bf6",
			"tnImgPath": "https://pi.movoto.com/p/12/40938477_0_qMu2iQ_p.jpeg",
			"listDate": "2021-02-19 00:00:00",
			"listingAgent": "Suzie Gibbons",
			"listPrice": 1763390,
			"lotSize": 0,
			"sqftTotal": 2065,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938477",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Pulte Group",
			"photoCount": 14,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2021,
			"zipCode": "94403",
			"path": "san-mateo-ca/187-waters-park-cir-san-mateo-ca-94403-12_40938477/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -600,
			"propertyId": "9459b457-3bef-40e3-a831-bc715317970b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-02-20 00:41:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.551988,
				"lng": -122.290694,
				"zipcode": "94403",
				"subPremise": "",
				"address": "187 Waters Park Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1804725,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 68,
			"id": "2113a5f6-aba6-47d2-ba3b-c4d0ce5e6618",
			"tnImgPath": "https://pi.movoto.com/p/12/40966602_0_33rarU_p.jpeg",
			"listDate": "2021-09-10 00:00:00",
			"listingAgent": "Suzanne Rocha",
			"listPrice": 1048000,
			"lotSize": 4750,
			"sqftTotal": 930,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966602",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Cal Home",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "94401",
			"path": "san-mateo-ca/132-prague-st-san-mateo-ca-94401/pid_kem6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b96f754f-ea5c-4e43-8be4-d799689e63f6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-09-10 16:51:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/585008155",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.57933,
				"lng": -122.314848,
				"zipcode": "94401",
				"subPremise": "",
				"address": "132 Prague St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/585008155"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 33,
			"id": "cd453bb3-1c89-4c7a-a775-a350a1ffa017",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866553_0_vvAQfR_p.jpeg",
			"listDate": "2021-10-13 05:18:17",
			"listingAgent": "Jim Balestieri",
			"listPrice": 350000,
			"lotSize": 3001,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866553",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 7,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94403",
			"path": "san-mateo-ca/0000-alameda-san-mateo-ca-94403-100_ml81866553/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "260fb3c2-c0fa-420e-a4d7-aba977323daa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-10-13 12:22:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 05:18:17",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"zipcode": "94403",
				"subPremise": "",
				"address": "0000 Alameda"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2250000,
			"daysOnMovoto": 29,
			"id": "fe982fd4-de52-4904-a37f-09067389472e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866007_0_J2Vqaq_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Cheri Cerna",
			"listPrice": 2188000,
			"lotSize": 5410,
			"sqftTotal": 2500,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866007",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 38,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1936,
			"zipCode": "94402",
			"path": "san-mateo-ca/9th-ave-san-mateo-ca-94402-100_912459/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "22893545-aefa-4e2c-83cc-216f8f66250c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-12 16:42:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": "https://listing.ewalk.com/bt/718_720_9th_Ave.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.563875,
				"lng": -122.314156,
				"zipcode": "94402",
				"subPremise": "",
				"address": "718 9th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listing.ewalk.com/bt/718_720_9th_Ave.html"
		},
		{
			"closePrice": 2175000,
			"daysOnMovoto": 82,
			"id": "a9879dd1-c168-4e18-9ea5-23fb5586fddb",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859071_0_3bZNzi_p.jpeg",
			"listDate": "2021-08-19 09:06:29",
			"listingAgent": "Chad Krakow",
			"listPrice": 2250000,
			"lotSize": 12088,
			"sqftTotal": 3319,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859071",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Krakow Company",
			"photoCount": 33,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1964,
			"zipCode": "94403",
			"path": "san-mateo-ca/3218-countryside-dr-san-mateo-ca-94403/pid_z916w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1780eb08-92cd-4961-b6d9-ba2020f6fe29",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-08-19 16:12:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 09:06:29",
			"virtualTourLink": "https://www.tourfactory.com/2905356",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.532128,
				"lng": -122.325554,
				"zipcode": "94403",
				"subPremise": "",
				"address": "3218 Countryside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2905356"
		},
		{
			"closePrice": 660000,
			"daysOnMovoto": 82,
			"id": "94a1b79f-67fc-4538-b9df-fa9a46eeda59",
			"tnImgPath": "https://pi.movoto.com/p/12/40963746_0_Nemfzy_p.jpeg",
			"listDate": "2021-08-19 00:00:00",
			"listingAgent": "Andy Chan",
			"listPrice": 648000,
			"lotSize": 1460,
			"sqftTotal": 950,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963746",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1963,
			"zipCode": "94403",
			"path": "san-mateo-ca/los-prados-st-113-san-mateo-ca-94403-100_89950190/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -47000,
			"propertyId": "ad001292-a70e-43f1-a742-aa0b4a8720c0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-08-19 16:21:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.54526,
				"lng": -122.282971,
				"zipcode": "94403",
				"subPremise": "APT 113",
				"address": "3015 Los Prados St #113"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 660000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 14,
			"id": "456e9136-895a-4561-a181-dfe66241cc3e",
			"tnImgPath": "https://pi.movoto.com/p/110/421606007_0_e6EEjA_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Maria Sagullo",
			"listPrice": 1388000,
			"lotSize": 5000,
			"sqftTotal": 1630,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421606007",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Hatch Realty Group, Inc.",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1948,
			"zipCode": "94401",
			"path": "san-mateo-ca/30-s-rochester-st-san-mateo-ca-94401-100_80007906/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "349f1ce3-9924-4c1c-901a-9987ba897732",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-10-22 01:26:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.576635,
				"lng": -122.310247,
				"zipcode": "94401",
				"subPremise": "",
				"address": "30 S Rochester St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 595000,
			"daysOnMovoto": 128,
			"id": "f7aaf56b-432f-474f-b071-3d666b302aa0",
			"tnImgPath": "https://pi.movoto.com/p/101/421567763_0_EjF3Aj_p.jpeg",
			"listDate": "2021-06-26 00:00:00",
			"listingAgent": "Summer Stubblefield",
			"listPrice": 610000,
			"lotSize": 1114920,
			"sqftTotal": 758,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421567763",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass California  II, Inc",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1965,
			"zipCode": "94401",
			"path": "san-mateo-ca/817-n-humboldt-st-201-san-mateo-ca-94401/pid_oow9w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -15000,
			"propertyId": "a0bb05c8-b34b-405c-ad92-289f42b75d99",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-06-26 15:58:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.580786,
				"lng": -122.330323,
				"zipcode": "94401",
				"subPremise": "APT 201",
				"address": "817 N Humboldt St #201"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 595000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2275000,
			"daysOnMovoto": 14,
			"id": "7146a575-44d1-4f44-95f4-0c8f61276c29",
			"tnImgPath": "https://pi.movoto.com/p/12/40971093_0_YBQ2JF_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Maya Arechiga",
			"listPrice": 1925000,
			"lotSize": 5432,
			"sqftTotal": 1740,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971093",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RED OAK REALTY",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1949,
			"zipCode": "94402",
			"path": "san-mateo-ca/609-maple-st-san-mateo-ca-94402/pid_19r6w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2f2c5e5e-0bc5-433a-8bad-9c44c9420c09",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-10-15 16:36:50",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": "https://www.609maple.com/mls/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.558078,
				"lng": -122.325344,
				"zipcode": "94402",
				"subPremise": "",
				"address": "609 Maple St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.609maple.com/mls/unbranded"
		},
		{
			"closePrice": 768000,
			"daysOnMovoto": 30,
			"id": "7a772368-33d4-4c75-bc20-273f30c2e68a",
			"tnImgPath": "https://pi.movoto.com/p/110/421595722_0_zZZYaN_p.jpeg",
			"listDate": "2021-09-21 00:00:00",
			"listingAgent": "Frank Torres",
			"listPrice": 768000,
			"lotSize": 131328,
			"sqftTotal": 918,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421595722",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Mutual Realty",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1974,
			"zipCode": "94403",
			"path": "san-mateo-ca/1919-alameda-de-las-pulgas-apt-80-san-mateo-ca-94403-100_ml81720456/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "40148fd6-98a9-4707-8894-b758ef83e2aa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-09-22 07:16:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Mateo",
				"lat": 37.546647,
				"lng": -122.320076,
				"zipcode": "94403",
				"subPremise": "APT 80",
				"address": "1919 Alameda De Las Pulgas #80"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 768000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]