const los_altos = {
    "geography":{
       "census_release":"ACS 2019 5-year",
       "parents":{
          "county":{
             "full_name":"Santa Clara County, CA",
             "short_name":"Santa Clara County",
             "sumlevel":"050",
             "land_area":3343890934,
             "full_geoid":"05000US06085",
             "total_population":1927470
          },
          "CBSA":{
             "full_name":"San Jose-Sunnyvale-Santa Clara, CA Metro Area",
             "short_name":"San Jose-Sunnyvale-Santa Clara, CA",
             "sumlevel":"310",
             "land_area":6940482016,
             "full_geoid":"31000US41940",
             "total_population":1987846
          },
          "state":{
             "full_name":"California",
             "short_name":"California",
             "sumlevel":"040",
             "land_area":403660088482,
             "full_geoid":"04000US06",
             "total_population":39283497
          },
          "nation":{
             "full_name":"United States",
             "short_name":"United States",
             "sumlevel":"010",
             "land_area":9160971212108,
             "full_geoid":"01000US",
             "total_population":324697795
          }
       },
       "this":{
          "full_name":"Los Altos, CA",
          "short_name":"Los Altos",
          "sumlevel":"160",
          "land_area":16784655,
          "full_geoid":"16000US0643280",
          "total_population":30504,
          "sumlevel_name":"Place",
          "short_geoid":"0643280"
       },
       "comparatives":[
          "CBSA",
          "state"
       ],
       "census_release_year":"19",
       "census_release_level":"5"
    },
    "demographics":{
       "age":{
          "distribution_by_category":{
             "metadata":{
                "table_id":"B01001",
                "universe":"Total population",
                "acs_release":"ACS 2019 5-year"
             },
             "percent_under_18":{
                "name":"Under 18",
                "values":{
                   "this":25.8,
                   "CBSA":22.34,
                   "state":22.97
                },
                "error":{
                   "this":1.61,
                   "CBSA":0.12,
                   "state":0.03
                },
                "numerators":{
                   "this":7869.0,
                   "CBSA":444060.0,
                   "state":9022146.0
                },
                "numerator_errors":{
                   "this":492.5,
                   "CBSA":2398.4,
                   "state":12886.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":115.0,
                   "state":112.0
                },
                "error_ratio":{
                   "this":6.2,
                   "CBSA":0.5,
                   "state":0.1
                }
             },
             "percent_18_to_64":{
                "name":"18 to 64",
                "values":{
                   "this":54.2,
                   "CBSA":64.5,
                   "state":63.07
                },
                "error":{
                   "this":2.32,
                   "CBSA":0.22,
                   "state":0.05
                },
                "numerators":{
                   "this":16533.0,
                   "CBSA":1282083.0,
                   "state":24775310.0
                },
                "numerator_errors":{
                   "this":709.6,
                   "CBSA":4353.9,
                   "state":20108.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":84.0,
                   "state":86.0
                },
                "error_ratio":{
                   "this":4.3,
                   "CBSA":0.3,
                   "state":0.1
                }
             },
             "percent_over_65":{
                "name":"65 and over",
                "values":{
                   "this":20.0,
                   "CBSA":13.17,
                   "state":13.97
                },
                "error":{
                   "this":1.29,
                   "CBSA":0.12,
                   "state":0.03
                },
                "numerators":{
                   "this":6102.0,
                   "CBSA":261703.0,
                   "state":5486041.0
                },
                "numerator_errors":{
                   "this":394.3,
                   "CBSA":2466.5,
                   "state":12678.7
                },
                "index":{
                   "this":100.0,
                   "CBSA":152.0,
                   "state":143.0
                },
                "error_ratio":{
                   "this":6.5,
                   "CBSA":0.9,
                   "state":0.2
                }
             }
          },
          "distribution_by_decade":{
             "total":{
                "metadata":{
                   "table_id":"B01001",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "0-9":{
                   "name":"0-9",
                   "values":{
                      "this":11.92,
                      "CBSA":12.17,
                      "state":12.52
                   },
                   "error":{
                      "this":1.17,
                      "CBSA":0.09,
                      "state":0.02
                   },
                   "numerators":{
                      "this":3636.0,
                      "CBSA":241951.0,
                      "state":4919754.0
                   },
                   "numerator_errors":{
                      "this":355.5,
                      "CBSA":1695.1,
                      "state":9132.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":98.0,
                      "state":95.0
                   },
                   "error_ratio":{
                      "this":9.8,
                      "CBSA":0.7,
                      "state":0.2
                   }
                },
                "10-19":{
                   "name":"10-19",
                   "values":{
                      "this":14.96,
                      "CBSA":12.46,
                      "state":13.09
                   },
                   "error":{
                      "this":1.16,
                      "CBSA":0.09,
                      "state":0.02
                   },
                   "numerators":{
                      "this":4562.0,
                      "CBSA":247684.0,
                      "state":5140633.0
                   },
                   "numerator_errors":{
                      "this":354.0,
                      "CBSA":1701.3,
                      "state":9167.2
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":120.0,
                      "state":114.0
                   },
                   "error_ratio":{
                      "this":7.8,
                      "CBSA":0.7,
                      "state":0.2
                   }
                },
                "20-29":{
                   "name":"20-29",
                   "values":{
                      "this":6.09,
                      "CBSA":14.22,
                      "state":14.84
                   },
                   "error":{
                      "this":1.29,
                      "CBSA":0.11,
                      "state":0.03
                   },
                   "numerators":{
                      "this":1859.0,
                      "CBSA":282618.0,
                      "state":5830059.0
                   },
                   "numerator_errors":{
                      "this":394.6,
                      "CBSA":2161.1,
                      "state":10871.5
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":43.0,
                      "state":41.0
                   },
                   "error_ratio":{
                      "this":21.2,
                      "CBSA":0.8,
                      "state":0.2
                   }
                },
                "30-39":{
                   "name":"30-39",
                   "values":{
                      "this":8.18,
                      "CBSA":15.4,
                      "state":14.18
                   },
                   "error":{
                      "this":0.9,
                      "CBSA":0.1,
                      "state":0.02
                   },
                   "numerators":{
                      "this":2496.0,
                      "CBSA":306040.0,
                      "state":5570748.0
                   },
                   "numerator_errors":{
                      "this":274.5,
                      "CBSA":2049.5,
                      "state":8798.2
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":53.0,
                      "state":58.0
                   },
                   "error_ratio":{
                      "this":11.0,
                      "CBSA":0.6,
                      "state":0.1
                   }
                },
                "40-49":{
                   "name":"40-49",
                   "values":{
                      "this":16.58,
                      "CBSA":14.14,
                      "state":12.95
                   },
                   "error":{
                      "this":1.11,
                      "CBSA":0.1,
                      "state":0.02
                   },
                   "numerators":{
                      "this":5057.0,
                      "CBSA":281103.0,
                      "state":5088145.0
                   },
                   "numerator_errors":{
                      "this":338.0,
                      "CBSA":2043.9,
                      "state":8746.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":117.0,
                      "state":128.0
                   },
                   "error_ratio":{
                      "this":6.7,
                      "CBSA":0.7,
                      "state":0.2
                   }
                },
                "50-59":{
                   "name":"50-59",
                   "values":{
                      "this":15.55,
                      "CBSA":13.05,
                      "state":12.76
                   },
                   "error":{
                      "this":1.03,
                      "CBSA":0.08,
                      "state":0.02
                   },
                   "numerators":{
                      "this":4743.0,
                      "CBSA":259378.0,
                      "state":5011016.0
                   },
                   "numerator_errors":{
                      "this":315.0,
                      "CBSA":1613.0,
                      "state":6867.0
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":119.0,
                      "state":122.0
                   },
                   "error_ratio":{
                      "this":6.6,
                      "CBSA":0.6,
                      "state":0.2
                   }
                },
                "60-69":{
                   "name":"60-69",
                   "values":{
                      "this":11.94,
                      "CBSA":9.62,
                      "state":10.34
                   },
                   "error":{
                      "this":0.97,
                      "CBSA":0.12,
                      "state":0.03
                   },
                   "numerators":{
                      "this":3643.0,
                      "CBSA":191214.0,
                      "state":4062419.0
                   },
                   "numerator_errors":{
                      "this":295.9,
                      "CBSA":2352.5,
                      "state":11748.6
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":124.0,
                      "state":115.0
                   },
                   "error_ratio":{
                      "this":8.1,
                      "CBSA":1.2,
                      "state":0.3
                   }
                },
                "70-79":{
                   "name":"70-79",
                   "values":{
                      "this":8.63,
                      "CBSA":5.51,
                      "state":5.82
                   },
                   "error":{
                      "this":0.87,
                      "CBSA":0.08,
                      "state":0.02
                   },
                   "numerators":{
                      "this":2633.0,
                      "CBSA":109560.0,
                      "state":2285972.0
                   },
                   "numerator_errors":{
                      "this":264.4,
                      "CBSA":1543.2,
                      "state":7838.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":157.0,
                      "state":148.0
                   },
                   "error_ratio":{
                      "this":10.1,
                      "CBSA":1.5,
                      "state":0.3
                   }
                },
                "80+":{
                   "name":"80+",
                   "values":{
                      "this":6.15,
                      "CBSA":3.44,
                      "state":3.5
                   },
                   "error":{
                      "this":0.72,
                      "CBSA":0.06,
                      "state":0.02
                   },
                   "numerators":{
                      "this":1875.0,
                      "CBSA":68298.0,
                      "state":1374751.0
                   },
                   "numerator_errors":{
                      "this":218.6,
                      "CBSA":1207.9,
                      "state":6707.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":179.0,
                      "state":176.0
                   },
                   "error_ratio":{
                      "this":11.7,
                      "CBSA":1.7,
                      "state":0.6
                   }
                }
             },
             "male":{
                "metadata":{
                   "table_id":"B01001",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "0-9":{
                   "name":"0-9",
                   "values":{
                      "this":12.05,
                      "CBSA":12.39,
                      "state":12.87
                   },
                   "error":{
                      "this":1.57,
                      "CBSA":0.12,
                      "state":0.03
                   },
                   "numerators":{
                      "this":1771.0,
                      "CBSA":124430.0,
                      "state":2512581.0
                   },
                   "numerator_errors":{
                      "this":233.7,
                      "CBSA":1182.8,
                      "state":6146.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":97.0,
                      "state":94.0
                   },
                   "error_ratio":{
                      "this":13.0,
                      "CBSA":1.0,
                      "state":0.2
                   }
                },
                "10-19":{
                   "name":"10-19",
                   "values":{
                      "this":15.27,
                      "CBSA":12.58,
                      "state":13.46
                   },
                   "error":{
                      "this":1.63,
                      "CBSA":0.12,
                      "state":0.03
                   },
                   "numerators":{
                      "this":2243.0,
                      "CBSA":126419.0,
                      "state":2628152.0
                   },
                   "numerator_errors":{
                      "this":244.9,
                      "CBSA":1188.6,
                      "state":6239.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":121.0,
                      "state":113.0
                   },
                   "error_ratio":{
                      "this":10.7,
                      "CBSA":1.0,
                      "state":0.2
                   }
                },
                "20-29":{
                   "name":"20-29",
                   "values":{
                      "this":6.49,
                      "CBSA":15.01,
                      "state":15.42
                   },
                   "error":{
                      "this":2.39,
                      "CBSA":0.16,
                      "state":0.04
                   },
                   "numerators":{
                      "this":953.0,
                      "CBSA":150743.0,
                      "state":3011788.0
                   },
                   "numerator_errors":{
                      "this":352.2,
                      "CBSA":1615.1,
                      "state":8042.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":43.0,
                      "state":42.0
                   },
                   "error_ratio":{
                      "this":36.8,
                      "CBSA":1.1,
                      "state":0.3
                   }
                },
                "30-39":{
                   "name":"30-39",
                   "values":{
                      "this":7.49,
                      "CBSA":15.96,
                      "state":14.57
                   },
                   "error":{
                      "this":1.22,
                      "CBSA":0.16,
                      "state":0.03
                   },
                   "numerators":{
                      "this":1101.0,
                      "CBSA":160356.0,
                      "state":2844329.0
                   },
                   "numerator_errors":{
                      "this":180.4,
                      "CBSA":1611.2,
                      "state":6637.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":47.0,
                      "state":51.0
                   },
                   "error_ratio":{
                      "this":16.3,
                      "CBSA":1.0,
                      "state":0.2
                   }
                },
                "40-49":{
                   "name":"40-49",
                   "values":{
                      "this":16.17,
                      "CBSA":14.13,
                      "state":13.02
                   },
                   "error":{
                      "this":1.41,
                      "CBSA":0.16,
                      "state":0.03
                   },
                   "numerators":{
                      "this":2376.0,
                      "CBSA":141926.0,
                      "state":2541977.0
                   },
                   "numerator_errors":{
                      "this":215.0,
                      "CBSA":1605.7,
                      "state":6536.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":114.0,
                      "state":124.0
                   },
                   "error_ratio":{
                      "this":8.7,
                      "CBSA":1.1,
                      "state":0.2
                   }
                },
                "50-59":{
                   "name":"50-59",
                   "values":{
                      "this":15.81,
                      "CBSA":13.01,
                      "state":12.65
                   },
                   "error":{
                      "this":1.47,
                      "CBSA":0.1,
                      "state":0.02
                   },
                   "numerators":{
                      "this":2323.0,
                      "CBSA":130685.0,
                      "state":2470900.0
                   },
                   "numerator_errors":{
                      "this":222.7,
                      "CBSA":1033.0,
                      "state":4877.9
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":122.0,
                      "state":125.0
                   },
                   "error_ratio":{
                      "this":9.3,
                      "CBSA":0.8,
                      "state":0.2
                   }
                },
                "60-69":{
                   "name":"60-69",
                   "values":{
                      "this":12.39,
                      "CBSA":9.28,
                      "state":9.92
                   },
                   "error":{
                      "this":1.38,
                      "CBSA":0.17,
                      "state":0.04
                   },
                   "numerators":{
                      "this":1820.0,
                      "CBSA":93182.0,
                      "state":1937575.0
                   },
                   "numerator_errors":{
                      "this":207.1,
                      "CBSA":1660.0,
                      "state":8018.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":134.0,
                      "state":125.0
                   },
                   "error_ratio":{
                      "this":11.1,
                      "CBSA":1.8,
                      "state":0.4
                   }
                },
                "70-79":{
                   "name":"70-79",
                   "values":{
                      "this":7.96,
                      "CBSA":4.96,
                      "state":5.32
                   },
                   "error":{
                      "this":1.06,
                      "CBSA":0.1,
                      "state":0.03
                   },
                   "numerators":{
                      "this":1169.0,
                      "CBSA":49877.0,
                      "state":1038517.0
                   },
                   "numerator_errors":{
                      "this":158.7,
                      "CBSA":993.8,
                      "state":5256.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":160.0,
                      "state":150.0
                   },
                   "error_ratio":{
                      "this":13.3,
                      "CBSA":2.0,
                      "state":0.6
                   }
                },
                "80+":{
                   "name":"80+",
                   "values":{
                      "this":6.37,
                      "CBSA":2.68,
                      "state":2.77
                   },
                   "error":{
                      "this":1.04,
                      "CBSA":0.07,
                      "state":0.02
                   },
                   "numerators":{
                      "this":936.0,
                      "CBSA":26955.0,
                      "state":540479.0
                   },
                   "numerator_errors":{
                      "this":153.9,
                      "CBSA":748.1,
                      "state":4503.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":238.0,
                      "state":230.0
                   },
                   "error_ratio":{
                      "this":16.3,
                      "CBSA":2.6,
                      "state":0.7
                   }
                }
             },
             "female":{
                "metadata":{
                   "table_id":"B01001",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "0-9":{
                   "name":"0-9",
                   "values":{
                      "this":11.79,
                      "CBSA":11.95,
                      "state":12.18
                   },
                   "error":{
                      "this":1.68,
                      "CBSA":0.12,
                      "state":0.03
                   },
                   "numerators":{
                      "this":1865.0,
                      "CBSA":117521.0,
                      "state":2407173.0
                   },
                   "numerator_errors":{
                      "this":267.9,
                      "CBSA":1214.2,
                      "state":6754.4
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":99.0,
                      "state":97.0
                   },
                   "error_ratio":{
                      "this":14.2,
                      "CBSA":1.0,
                      "state":0.2
                   }
                },
                "10-19":{
                   "name":"10-19",
                   "values":{
                      "this":14.67,
                      "CBSA":12.33,
                      "state":12.72
                   },
                   "error":{
                      "this":1.59,
                      "CBSA":0.12,
                      "state":0.03
                   },
                   "numerators":{
                      "this":2319.0,
                      "CBSA":121265.0,
                      "state":2512481.0
                   },
                   "numerator_errors":{
                      "this":255.6,
                      "CBSA":1217.2,
                      "state":6716.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":119.0,
                      "state":115.0
                   },
                   "error_ratio":{
                      "this":10.8,
                      "CBSA":1.0,
                      "state":0.2
                   }
                },
                "20-29":{
                   "name":"20-29",
                   "values":{
                      "this":5.73,
                      "CBSA":13.41,
                      "state":14.26
                   },
                   "error":{
                      "this":1.12,
                      "CBSA":0.15,
                      "state":0.04
                   },
                   "numerators":{
                      "this":906.0,
                      "CBSA":131875.0,
                      "state":2818271.0
                   },
                   "numerator_errors":{
                      "this":178.0,
                      "CBSA":1436.0,
                      "state":7314.7
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":43.0,
                      "state":40.0
                   },
                   "error_ratio":{
                      "this":19.5,
                      "CBSA":1.1,
                      "state":0.3
                   }
                },
                "30-39":{
                   "name":"30-39",
                   "values":{
                      "this":8.82,
                      "CBSA":14.82,
                      "state":13.8
                   },
                   "error":{
                      "this":1.29,
                      "CBSA":0.13,
                      "state":0.03
                   },
                   "numerators":{
                      "this":1395.0,
                      "CBSA":145684.0,
                      "state":2726419.0
                   },
                   "numerator_errors":{
                      "this":206.9,
                      "CBSA":1266.6,
                      "state":5775.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":60.0,
                      "state":64.0
                   },
                   "error_ratio":{
                      "this":14.6,
                      "CBSA":0.9,
                      "state":0.2
                   }
                },
                "40-49":{
                   "name":"40-49",
                   "values":{
                      "this":16.96,
                      "CBSA":14.15,
                      "state":12.89
                   },
                   "error":{
                      "this":1.61,
                      "CBSA":0.13,
                      "state":0.03
                   },
                   "numerators":{
                      "this":2681.0,
                      "CBSA":139177.0,
                      "state":2546168.0
                   },
                   "numerator_errors":{
                      "this":260.9,
                      "CBSA":1264.5,
                      "state":5811.3
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":120.0,
                      "state":132.0
                   },
                   "error_ratio":{
                      "this":9.5,
                      "CBSA":0.9,
                      "state":0.2
                   }
                },
                "50-59":{
                   "name":"50-59",
                   "values":{
                      "this":15.3,
                      "CBSA":13.09,
                      "state":12.86
                   },
                   "error":{
                      "this":1.37,
                      "CBSA":0.13,
                      "state":0.02
                   },
                   "numerators":{
                      "this":2420.0,
                      "CBSA":128693.0,
                      "state":2540116.0
                   },
                   "numerator_errors":{
                      "this":222.7,
                      "CBSA":1238.8,
                      "state":4833.5
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":117.0,
                      "state":119.0
                   },
                   "error_ratio":{
                      "this":9.0,
                      "CBSA":1.0,
                      "state":0.2
                   }
                },
                "60-69":{
                   "name":"60-69",
                   "values":{
                      "this":11.53,
                      "CBSA":9.97,
                      "state":10.75
                   },
                   "error":{
                      "this":1.31,
                      "CBSA":0.17,
                      "state":0.04
                   },
                   "numerators":{
                      "this":1823.0,
                      "CBSA":98032.0,
                      "state":2124844.0
                   },
                   "numerator_errors":{
                      "this":211.3,
                      "CBSA":1666.9,
                      "state":8586.6
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":116.0,
                      "state":107.0
                   },
                   "error_ratio":{
                      "this":11.4,
                      "CBSA":1.7,
                      "state":0.4
                   }
                },
                "70-79":{
                   "name":"70-79",
                   "values":{
                      "this":9.26,
                      "CBSA":6.07,
                      "state":6.31
                   },
                   "error":{
                      "this":1.32,
                      "CBSA":0.12,
                      "state":0.03
                   },
                   "numerators":{
                      "this":1464.0,
                      "CBSA":59683.0,
                      "state":1247455.0
                   },
                   "numerator_errors":{
                      "this":211.5,
                      "CBSA":1180.6,
                      "state":5815.2
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":153.0,
                      "state":147.0
                   },
                   "error_ratio":{
                      "this":14.3,
                      "CBSA":2.0,
                      "state":0.5
                   }
                },
                "80+":{
                   "name":"80+",
                   "values":{
                      "this":5.94,
                      "CBSA":4.2,
                      "state":4.22
                   },
                   "error":{
                      "this":0.97,
                      "CBSA":0.1,
                      "state":0.03
                   },
                   "numerators":{
                      "this":939.0,
                      "CBSA":41343.0,
                      "state":834272.0
                   },
                   "numerator_errors":{
                      "this":155.2,
                      "CBSA":948.3,
                      "state":4970.5
                   },
                   "index":{
                      "this":100.0,
                      "CBSA":141.0,
                      "state":141.0
                   },
                   "error_ratio":{
                      "this":16.3,
                      "CBSA":2.4,
                      "state":0.7
                   }
                }
             }
          },
          "median_age":{
             "total":{
                "name":"Median age",
                "values":{
                   "this":46.1,
                   "CBSA":37.1,
                   "state":36.5
                },
                "error":{
                   "this":0.8,
                   "CBSA":0.1,
                   "state":0.1
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B01002",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":124.0,
                   "state":126.0
                },
                "error_ratio":{
                   "this":1.7,
                   "CBSA":0.3,
                   "state":0.3
                }
             },
             "male":{
                "name":"Median age male",
                "values":{
                   "this":46.4,
                   "CBSA":36.0,
                   "state":35.4
                },
                "error":{
                   "this":0.8,
                   "CBSA":0.1,
                   "state":0.1
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B01002",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":129.0,
                   "state":131.0
                },
                "error_ratio":{
                   "this":1.7,
                   "CBSA":0.3,
                   "state":0.3
                }
             },
             "female":{
                "name":"Median age female",
                "values":{
                   "this":45.7,
                   "CBSA":38.2,
                   "state":37.7
                },
                "error":{
                   "this":1.2,
                   "CBSA":0.1,
                   "state":0.1
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B01002",
                   "universe":"Total population",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":120.0,
                   "state":121.0
                },
                "error_ratio":{
                   "this":2.6,
                   "CBSA":0.3,
                   "state":0.3
                }
             }
          }
       },
       "sex":{
          "metadata":{
             "table_id":"B01001",
             "universe":"Total population",
             "acs_release":"ACS 2019 5-year"
          },
          "percent_male":{
             "name":"Male",
             "values":{
                "this":48.16,
                "CBSA":50.54,
                "state":49.71
             },
             "error":{
                "this":1.13,
                "CBSA":0.01,
                "state":0.0
             },
             "numerators":{
                "this":14692.0,
                "CBSA":1004573.0,
                "state":19526298.0
             },
             "numerator_errors":{
                "this":346.0,
                "CBSA":251.0,
                "state":1141.0
             },
             "index":{
                "this":100.0,
                "CBSA":95.0,
                "state":97.0
             },
             "error_ratio":{
                "this":2.3,
                "CBSA":null,
                "state":null
             }
          },
          "percent_female":{
             "name":"Female",
             "values":{
                "this":51.84,
                "CBSA":49.46,
                "state":50.29
             },
             "error":{
                "this":1.11,
                "CBSA":0.01,
                "state":0.0
             },
             "numerators":{
                "this":15812.0,
                "CBSA":983273.0,
                "state":19757199.0
             },
             "numerator_errors":{
                "this":341.0,
                "CBSA":251.0,
                "state":1141.0
             },
             "index":{
                "this":100.0,
                "CBSA":105.0,
                "state":103.0
             },
             "error_ratio":{
                "this":2.1,
                "CBSA":null,
                "state":null
             }
          }
       },
       "race":{
          "metadata":{
             "table_id":"B03002",
             "universe":"Total population",
             "acs_release":"ACS 2019 5-year"
          },
          "percent_white":{
             "name":"White",
             "values":{
                "this":58.14,
                "CBSA":31.62,
                "state":37.18
             },
             "error":{
                "this":2.09,
                "CBSA":0.04,
                "state":0.01
             },
             "numerators":{
                "this":17735.0,
                "CBSA":628606.0,
                "state":14605312.0
             },
             "numerator_errors":{
                "this":639.0,
                "CBSA":844.0,
                "state":3168.0
             },
             "index":{
                "this":100.0,
                "CBSA":184.0,
                "state":156.0
             },
             "error_ratio":{
                "this":3.6,
                "CBSA":0.1,
                "state":null
             }
          },
          "percent_black":{
             "name":"Black",
             "values":{
                "this":0.48,
                "CBSA":2.3,
                "state":5.52
             },
             "error":{
                "this":0.34,
                "CBSA":0.05,
                "state":0.02
             },
             "numerators":{
                "this":145.0,
                "CBSA":45713.0,
                "state":2169155.0
             },
             "numerator_errors":{
                "this":105.0,
                "CBSA":926.0,
                "state":6936.0
             },
             "index":{
                "this":100.0,
                "CBSA":21.0,
                "state":9.0
             },
             "error_ratio":{
                "this":70.8,
                "CBSA":2.2,
                "state":0.4
             }
          },
          "percent_native":{
             "name":"Native",
             "values":{
                "this":0.08,
                "CBSA":0.18,
                "state":0.36
             },
             "error":{
                "this":0.12,
                "CBSA":0.02,
                "state":0.01
             },
             "numerators":{
                "this":24.0,
                "CBSA":3552.0,
                "state":140831.0
             },
             "numerator_errors":{
                "this":37.0,
                "CBSA":409.0,
                "state":2516.0
             },
             "index":{
                "this":100.0,
                "CBSA":44.0,
                "state":22.0
             },
             "error_ratio":{
                "this":150.0,
                "CBSA":11.1,
                "state":2.8
             }
          },
          "percent_asian":{
             "name":"Asian",
             "values":{
                "this":31.43,
                "CBSA":35.27,
                "state":14.28
             },
             "error":{
                "this":1.59,
                "CBSA":0.1,
                "state":0.02
             },
             "numerators":{
                "this":9586.0,
                "CBSA":701044.0,
                "state":5610931.0
             },
             "numerator_errors":{
                "this":486.0,
                "CBSA":2065.0,
                "state":9199.0
             },
             "index":{
                "this":100.0,
                "CBSA":89.0,
                "state":220.0
             },
             "error_ratio":{
                "this":5.1,
                "CBSA":0.3,
                "state":0.1
             }
          },
          "percent_islander":{
             "name":"Islander",
             "values":{
                "this":0,
                "CBSA":0.32,
                "state":0.36
             },
             "error":{
                "this":0,
                "CBSA":0.03,
                "state":0.01
             },
             "numerators":{
                "this":0.0,
                "CBSA":6265.0,
                "state":140788.0
             },
             "numerator_errors":{
                "this":26.0,
                "CBSA":499.0,
                "state":2224.0
             },
             "index":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":null,
                "CBSA":9.4,
                "state":2.8
             }
          },
          "percent_other":{
             "name":"Other",
             "values":{
                "this":0.33,
                "CBSA":0.28,
                "state":0.25
             },
             "error":{
                "this":0.27,
                "CBSA":0.05,
                "state":0.01
             },
             "numerators":{
                "this":100.0,
                "CBSA":5606.0,
                "state":100119.0
             },
             "numerator_errors":{
                "this":83.0,
                "CBSA":920.0,
                "state":3715.0
             },
             "index":{
                "this":100.0,
                "CBSA":118.0,
                "state":132.0
             },
             "error_ratio":{
                "this":81.8,
                "CBSA":17.9,
                "state":4.0
             }
          },
          "percent_two_or_more":{
             "name":"Two+",
             "values":{
                "this":5.14,
                "CBSA":3.52,
                "state":3.03
             },
             "error":{
                "this":1.22,
                "CBSA":0.11,
                "state":0.04
             },
             "numerators":{
                "this":1567.0,
                "CBSA":70001.0,
                "state":1188673.0
             },
             "numerator_errors":{
                "this":371.0,
                "CBSA":2135.0,
                "state":14463.0
             },
             "index":{
                "this":100.0,
                "CBSA":146.0,
                "state":170.0
             },
             "error_ratio":{
                "this":23.7,
                "CBSA":3.1,
                "state":1.3
             }
          },
          "percent_hispanic":{
             "name":"Hispanic",
             "values":{
                "this":4.42,
                "CBSA":26.51,
                "state":39.02
             },
             "error":{
                "this":1.08,
                "CBSA":0,
                "state":0
             },
             "numerators":{
                "this":1347.0,
                "CBSA":527059.0,
                "state":15327688.0
             },
             "numerator_errors":{
                "this":330.0,
                "CBSA":0.0,
                "state":0.0
             },
             "index":{
                "this":100.0,
                "CBSA":17.0,
                "state":11.0
             },
             "error_ratio":{
                "this":24.4,
                "CBSA":null,
                "state":null
             }
          }
       }
    },
    "economics":{
       "income":{
          "per_capita_income_in_the_last_12_months":{
             "name":"Per capita income",
             "values":{
                "this":115456.0,
                "CBSA":55547.0,
                "state":36955.0
             },
             "error":{
                "this":5689.0,
                "CBSA":380.0,
                "state":129.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B19301",
                "universe":"Total population",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":208.0,
                "state":312.0
             },
             "error_ratio":{
                "this":4.9,
                "CBSA":0.7,
                "state":0.3
             }
          },
          "median_household_income":{
             "name":"Median household income",
             "values":{
                "this":235278.0,
                "CBSA":122478.0,
                "state":75235.0
             },
             "error":{
                "this":14739.0,
                "CBSA":1085.0,
                "state":232.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B19013",
                "universe":"Households",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":192.0,
                "state":313.0
             },
             "error_ratio":{
                "this":6.3,
                "CBSA":0.9,
                "state":0.3
             }
          },
          "household_distribution":{
             "metadata":{
                "table_id":"B19001",
                "universe":"Households",
                "acs_release":"ACS 2019 5-year"
             },
             "under_50":{
                "name":"Under $50K",
                "values":{
                   "this":10.27,
                   "CBSA":20.8,
                   "state":34.35
                },
                "error":{
                   "this":1.73,
                   "CBSA":0.38,
                   "state":0.1
                },
                "numerators":{
                   "this":1094.0,
                   "CBSA":136936.0,
                   "state":4481083.0
                },
                "numerator_errors":{
                   "this":186.3,
                   "CBSA":2507.3,
                   "state":15330.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":49.0,
                   "state":30.0
                },
                "error_ratio":{
                   "this":16.8,
                   "CBSA":1.8,
                   "state":0.3
                }
             },
             "50_to_100":{
                "name":"$50K - $100K",
                "values":{
                   "this":9.75,
                   "CBSA":20.81,
                   "state":27.93
                },
                "error":{
                   "this":1.55,
                   "CBSA":0.34,
                   "state":0.09
                },
                "numerators":{
                   "this":1039.0,
                   "CBSA":136978.0,
                   "state":3643284.0
                },
                "numerator_errors":{
                   "this":167.1,
                   "CBSA":2294.6,
                   "state":12935.8
                },
                "index":{
                   "this":100.0,
                   "CBSA":47.0,
                   "state":35.0
                },
                "error_ratio":{
                   "this":15.9,
                   "CBSA":1.6,
                   "state":0.3
                }
             },
             "100_to_200":{
                "name":"$100K - $200K",
                "values":{
                   "this":22.38,
                   "CBSA":30.92,
                   "state":25.56
                },
                "error":{
                   "this":2.24,
                   "CBSA":0.4,
                   "state":0.1
                },
                "numerators":{
                   "this":2384.0,
                   "CBSA":203545.0,
                   "state":3333848.0
                },
                "numerator_errors":{
                   "this":244.5,
                   "CBSA":2677.2,
                   "state":14018.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":72.0,
                   "state":88.0
                },
                "error_ratio":{
                   "this":10.0,
                   "CBSA":1.3,
                   "state":0.4
                }
             },
             "over_200":{
                "name":"Over $200K",
                "values":{
                   "this":57.59,
                   "CBSA":27.48,
                   "state":12.16
                },
                "error":{
                   "this":2.5,
                   "CBSA":0.35,
                   "state":0.08
                },
                "numerators":{
                   "this":6135.0,
                   "CBSA":180891.0,
                   "state":1586051.0
                },
                "numerator_errors":{
                   "this":297.0,
                   "CBSA":2316.0,
                   "state":10523.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":210.0,
                   "state":474.0
                },
                "error_ratio":{
                   "this":4.3,
                   "CBSA":1.3,
                   "state":0.7
                }
             }
          }
       },
       "poverty":{
          "percent_below_poverty_line":{
             "name":"Persons below poverty line",
             "values":{
                "this":2.83,
                "CBSA":7.54,
                "state":13.36
             },
             "error":{
                "this":0.78,
                "CBSA":0.23,
                "state":0.1
             },
             "numerators":{
                "this":856.0,
                "CBSA":147595.0,
                "state":5149742.0
             },
             "numerator_errors":{
                "this":236.0,
                "CBSA":4438.0,
                "state":38666.0
             },
             "metadata":{
                "table_id":"B17001",
                "universe":"Population for whom poverty status is determined",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":38.0,
                "state":21.0
             },
             "error_ratio":{
                "this":27.6,
                "CBSA":3.1,
                "state":0.7
             }
          },
          "children":{
             "metadata":{
                "table_id":"B17001",
                "universe":"Population for whom poverty status is determined",
                "acs_release":"ACS 2019 5-year"
             },
             "Below":{
                "name":"Poverty",
                "values":{
                   "this":2.66,
                   "CBSA":7.92,
                   "state":18.13
                },
                "error":{
                   "this":1.5,
                   "CBSA":0.35,
                   "state":0.12
                },
                "numerators":{
                   "this":209.0,
                   "CBSA":34773.0,
                   "state":1610923.0
                },
                "numerator_errors":{
                   "this":118.3,
                   "CBSA":1570.3,
                   "state":10973.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":34.0,
                   "state":15.0
                },
                "error_ratio":{
                   "this":56.4,
                   "CBSA":4.4,
                   "state":0.7
                }
             },
             "above":{
                "name":"Non-poverty",
                "values":{
                   "this":97.34,
                   "CBSA":92.08,
                   "state":81.87
                },
                "error":{
                   "this":8.51,
                   "CBSA":0.96,
                   "state":0.0
                },
                "numerators":{
                   "this":7638.0,
                   "CBSA":404295.0,
                   "state":7275254.0
                },
                "numerator_errors":{
                   "this":471.4,
                   "CBSA":2898.9,
                   "state":15647.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":106.0,
                   "state":119.0
                },
                "error_ratio":{
                   "this":8.7,
                   "CBSA":1.0,
                   "state":null
                }
             }
          },
          "seniors":{
             "metadata":{
                "table_id":"B17001",
                "universe":"Population for whom poverty status is determined",
                "acs_release":"ACS 2019 5-year"
             },
             "Below":{
                "name":"Poverty",
                "values":{
                   "this":3.29,
                   "CBSA":8.01,
                   "state":10.23
                },
                "error":{
                   "this":1.26,
                   "CBSA":0.37,
                   "state":0.1
                },
                "numerators":{
                   "this":195.0,
                   "CBSA":20603.0,
                   "state":551219.0
                },
                "numerator_errors":{
                   "this":75.5,
                   "CBSA":953.5,
                   "state":5245.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":41.0,
                   "state":32.0
                },
                "error_ratio":{
                   "this":38.3,
                   "CBSA":4.6,
                   "state":1.0
                }
             },
             "above":{
                "name":"Non-poverty",
                "values":{
                   "this":96.71,
                   "CBSA":91.99,
                   "state":89.77
                },
                "error":{
                   "this":1.0,
                   "CBSA":0.63,
                   "state":0.16
                },
                "numerators":{
                   "this":5726.0,
                   "CBSA":236657.0,
                   "state":4839112.0
                },
                "numerator_errors":{
                   "this":370.1,
                   "CBSA":998.3,
                   "state":5421.1
                },
                "index":{
                   "this":100.0,
                   "CBSA":105.0,
                   "state":108.0
                },
                "error_ratio":{
                   "this":1.0,
                   "CBSA":0.7,
                   "state":0.2
                }
             }
          }
       },
       "employment":{
          "mean_travel_time":{
             "name":"Mean travel time to work",
             "values":{
                "this":24.2,
                "CBSA":29.51,
                "state":29.79
             },
             "error":{
                "this":0.68,
                "CBSA":0.11,
                "state":0.07
             },
             "numerators":{
                "this":289530.0,
                "CBSA":27906095.0,
                "state":509970415.0
             },
             "numerator_errors":{
                "this":15615.0,
                "CBSA":162628.0,
                "state":1354876.0
             },
             "metadata":{
                "table_id":"B08006, B08013",
                "universe":"Workers 16 years and over who did not work at home",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":82.0,
                "state":81.0
             },
             "error_ratio":{
                "this":2.8,
                "CBSA":0.4,
                "state":0.2
             }
          },
          "transportation_distribution":{
             "metadata":{
                "table_id":"B08006",
                "universe":"Workers 16 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "drove_alone":{
                "name":"Drove alone",
                "values":{
                   "this":76.85,
                   "CBSA":74.88,
                   "state":73.72
                },
                "error":{
                   "this":2.61,
                   "CBSA":0.37,
                   "state":0.04
                },
                "numerators":{
                   "this":10275.0,
                   "CBSA":745233.0,
                   "state":13411041.0
                },
                "numerator_errors":{
                   "this":527.0,
                   "CBSA":4630.0,
                   "state":17348.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":103.0,
                   "state":104.0
                },
                "error_ratio":{
                   "this":3.4,
                   "CBSA":0.5,
                   "state":0.1
                }
             },
             "carpooled":{
                "name":"Carpooled",
                "values":{
                   "this":6.39,
                   "CBSA":10.63,
                   "state":10.12
                },
                "error":{
                   "this":1.42,
                   "CBSA":0.27,
                   "state":0.09
                },
                "numerators":{
                   "this":854.0,
                   "CBSA":105809.0,
                   "state":1841632.0
                },
                "numerator_errors":{
                   "this":193.0,
                   "CBSA":2737.0,
                   "state":16674.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":60.0,
                   "state":63.0
                },
                "error_ratio":{
                   "this":22.2,
                   "CBSA":2.5,
                   "state":0.9
                }
             },
             "public_transit":{
                "name":"Public transit",
                "values":{
                   "this":3.02,
                   "CBSA":4.33,
                   "state":5.08
                },
                "error":{
                   "this":1.0,
                   "CBSA":0.15,
                   "state":0.04
                },
                "numerators":{
                   "this":404.0,
                   "CBSA":43142.0,
                   "state":923834.0
                },
                "numerator_errors":{
                   "this":134.0,
                   "CBSA":1491.0,
                   "state":7563.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":70.0,
                   "state":59.0
                },
                "error_ratio":{
                   "this":33.1,
                   "CBSA":3.5,
                   "state":0.8
                }
             },
             "Bicycle":{
                "name":"Bicycle",
                "values":{
                   "this":1.67,
                   "CBSA":1.73,
                   "state":0.95
                },
                "error":{
                   "this":0.57,
                   "CBSA":0.09,
                   "state":0.02
                },
                "numerators":{
                   "this":223.0,
                   "CBSA":17241.0,
                   "state":173081.0
                },
                "numerator_errors":{
                   "this":77.0,
                   "CBSA":877.0,
                   "state":3177.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":97.0,
                   "state":176.0
                },
                "error_ratio":{
                   "this":34.1,
                   "CBSA":5.2,
                   "state":2.1
                }
             },
             "walked":{
                "name":"Walked",
                "values":{
                   "this":0.76,
                   "CBSA":2.12,
                   "state":2.62
                },
                "error":{
                   "this":0.37,
                   "CBSA":0.13,
                   "state":0.03
                },
                "numerators":{
                   "this":102.0,
                   "CBSA":21077.0,
                   "state":476291.0
                },
                "numerator_errors":{
                   "this":50.0,
                   "CBSA":1264.0,
                   "state":5234.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":36.0,
                   "state":29.0
                },
                "error_ratio":{
                   "this":48.7,
                   "CBSA":6.1,
                   "state":1.1
                }
             },
             "other":{
                "name":"Other",
                "values":{
                   "this":0.81,
                   "CBSA":1.32,
                   "state":1.6
                },
                "error":{
                   "this":0.46,
                   "CBSA":0.08,
                   "state":0.02
                },
                "numerators":{
                   "this":108.0,
                   "CBSA":13160.0,
                   "state":291710.0
                },
                "numerator_errors":{
                   "this":61.0,
                   "CBSA":846.0,
                   "state":4364.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":61.0,
                   "state":51.0
                },
                "error_ratio":{
                   "this":56.8,
                   "CBSA":6.1,
                   "state":1.2
                }
             },
             "worked_at_home":{
                "name":"Worked at home",
                "values":{
                   "this":10.5,
                   "CBSA":4.98,
                   "state":5.9
                },
                "error":{
                   "this":1.41,
                   "CBSA":0.15,
                   "state":0.05
                },
                "numerators":{
                   "this":1404.0,
                   "CBSA":49567.0,
                   "state":1073966.0
                },
                "numerator_errors":{
                   "this":196.0,
                   "CBSA":1546.0,
                   "state":8330.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":211.0,
                   "state":178.0
                },
                "error_ratio":{
                   "this":13.4,
                   "CBSA":3.0,
                   "state":0.8
                }
             }
          }
       }
    },
    "families":{
       "marital_status":{
          "metadata":{
             "table_id":"B12001",
             "universe":"Population 15 years and over",
             "acs_release":"ACS 2019 5-year"
          },
          "married":{
             "name":"Married",
             "values":{
                "this":68.51,
                "CBSA":54.67,
                "state":48.86
             },
             "error":{
                "this":1.03,
                "CBSA":0.28,
                "state":0.11
             },
             "numerators":{
                "this":16536.0,
                "CBSA":884034.0,
                "state":15532639.0
             },
             "numerator_errors":{
                "this":328.1,
                "CBSA":4460.5,
                "state":35143.9
             },
             "index":{
                "this":100.0,
                "CBSA":125.0,
                "state":140.0
             },
             "error_ratio":{
                "this":1.5,
                "CBSA":0.5,
                "state":0.2
             }
          },
          "single":{
             "name":"Single",
             "values":{
                "this":31.49,
                "CBSA":45.33,
                "state":51.14
             },
             "error":{
                "this":2.28,
                "CBSA":0.29,
                "state":0.1
             },
             "numerators":{
                "this":7600.0,
                "CBSA":732911.0,
                "state":16255641.0
             },
             "numerator_errors":{
                "this":559.7,
                "CBSA":4762.0,
                "state":32012.1
             },
             "index":{
                "this":100.0,
                "CBSA":69.0,
                "state":62.0
             },
             "error_ratio":{
                "this":7.2,
                "CBSA":0.6,
                "state":0.2
             }
          }
       },
       "marital_status_grouped":{
          "metadata":{
             "table_id":"B12001",
             "universe":"Population 15 years and over",
             "acs_release":"ACS 2019 5-year"
          },
          "never_married":{
             "acs_release":"ACS 2019 5-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Never married"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":22.46,
                   "CBSA":37.44,
                   "state":40.42
                },
                "error":{
                   "this":3.37,
                   "CBSA":0.35,
                   "state":0.14
                },
                "numerators":{
                   "this":2616.0,
                   "CBSA":305004.0,
                   "state":6343459.0
                },
                "numerator_errors":{
                   "this":399.0,
                   "CBSA":2839.0,
                   "state":22441.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":60.0,
                   "state":56.0
                },
                "error_ratio":{
                   "this":15.0,
                   "CBSA":0.9,
                   "state":0.3
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":18.35,
                   "CBSA":29.76,
                   "state":33.49
                },
                "error":{
                   "this":1.89,
                   "CBSA":0.32,
                   "state":0.11
                },
                "numerators":{
                   "this":2292.0,
                   "CBSA":238780.0,
                   "state":5389443.0
                },
                "numerator_errors":{
                   "this":240.0,
                   "CBSA":2599.0,
                   "state":17597.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":62.0,
                   "state":55.0
                },
                "error_ratio":{
                   "this":10.3,
                   "CBSA":1.1,
                   "state":0.3
                }
             }
          },
          "married":{
             "acs_release":"ACS 2019 5-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Now married"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":71.07,
                   "CBSA":54.94,
                   "state":49.8
                },
                "error":{
                   "this":0.21,
                   "CBSA":0.39,
                   "state":0.17
                },
                "numerators":{
                   "this":8277.0,
                   "CBSA":447525.0,
                   "state":7816684.0
                },
                "numerator_errors":{
                   "this":233.0,
                   "CBSA":3214.0,
                   "state":27451.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":129.0,
                   "state":143.0
                },
                "error_ratio":{
                   "this":0.3,
                   "CBSA":0.7,
                   "state":0.3
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":66.13,
                   "CBSA":54.4,
                   "state":47.95
                },
                "error":{
                   "this":1.31,
                   "CBSA":0.39,
                   "state":0.14
                },
                "numerators":{
                   "this":8259.0,
                   "CBSA":436509.0,
                   "state":7715955.0
                },
                "numerator_errors":{
                   "this":231.0,
                   "CBSA":3093.0,
                   "state":21944.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":122.0,
                   "state":138.0
                },
                "error_ratio":{
                   "this":2.0,
                   "CBSA":0.7,
                   "state":0.3
                }
             }
          },
          "divorced":{
             "acs_release":"ACS 2019 5-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Divorced"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":4.22,
                   "CBSA":5.95,
                   "state":7.65
                },
                "error":{
                   "this":0.91,
                   "CBSA":0.19,
                   "state":0.06
                },
                "numerators":{
                   "this":491.0,
                   "CBSA":48487.0,
                   "state":1200982.0
                },
                "numerator_errors":{
                   "this":107.0,
                   "CBSA":1518.0,
                   "state":9397.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":71.0,
                   "state":55.0
                },
                "error_ratio":{
                   "this":21.6,
                   "CBSA":3.2,
                   "state":0.8
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":7.78,
                   "CBSA":9.05,
                   "state":10.85
                },
                "error":{
                   "this":1.55,
                   "CBSA":0.23,
                   "state":0.05
                },
                "numerators":{
                   "this":972.0,
                   "CBSA":72589.0,
                   "state":1746282.0
                },
                "numerator_errors":{
                   "this":195.0,
                   "CBSA":1835.0,
                   "state":8613.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":86.0,
                   "state":72.0
                },
                "error_ratio":{
                   "this":19.9,
                   "CBSA":2.5,
                   "state":0.5
                }
             }
          },
          "widowed":{
             "acs_release":"ACS 2019 5-year",
             "metadata":{
                "universe":"Population 15 years and over",
                "table_id":"B12001",
                "name":"Widowed"
             },
             "male":{
                "name":"Male",
                "values":{
                   "this":2.26,
                   "CBSA":1.67,
                   "state":2.13
                },
                "error":{
                   "this":0.75,
                   "CBSA":0.1,
                   "state":0.02
                },
                "numerators":{
                   "this":263.0,
                   "CBSA":13581.0,
                   "state":334237.0
                },
                "numerator_errors":{
                   "this":88.0,
                   "CBSA":820.0,
                   "state":3638.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":135.0,
                   "state":106.0
                },
                "error_ratio":{
                   "this":33.2,
                   "CBSA":6.0,
                   "state":0.9
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":7.73,
                   "CBSA":6.79,
                   "state":7.71
                },
                "error":{
                   "this":1.58,
                   "CBSA":0.15,
                   "state":0.04
                },
                "numerators":{
                   "this":966.0,
                   "CBSA":54470.0,
                   "state":1241238.0
                },
                "numerator_errors":{
                   "this":198.0,
                   "CBSA":1232.0,
                   "state":5983.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":114.0,
                   "state":100.0
                },
                "error_ratio":{
                   "this":20.4,
                   "CBSA":2.2,
                   "state":0.5
                }
             }
          }
       },
       "family_types":{
          "children":{
             "metadata":{
                "table_id":"B09002",
                "universe":"Own children under 18 years",
                "acs_release":"ACS 2019 5-year"
             },
             "married_couple":{
                "name":"Married couple",
                "values":{
                   "this":93.81,
                   "CBSA":81.4,
                   "state":72.23
                },
                "error":{
                   "this":1.96,
                   "CBSA":0.63,
                   "state":0.27
                },
                "numerators":{
                   "this":7053.0,
                   "CBSA":319520.0,
                   "state":5567122.0
                },
                "numerator_errors":{
                   "this":303.0,
                   "CBSA":2844.0,
                   "state":22183.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":115.0,
                   "state":130.0
                },
                "error_ratio":{
                   "this":2.1,
                   "CBSA":0.8,
                   "state":0.4
                }
             },
             "male_householder":{
                "name":"Male householder",
                "values":{
                   "this":1.09,
                   "CBSA":5.63,
                   "state":7.67
                },
                "error":{
                   "this":0.86,
                   "CBSA":0.34,
                   "state":0.11
                },
                "numerators":{
                   "this":82.0,
                   "CBSA":22081.0,
                   "state":591232.0
                },
                "numerator_errors":{
                   "this":65.0,
                   "CBSA":1348.0,
                   "state":8769.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":19.0,
                   "state":14.0
                },
                "error_ratio":{
                   "this":78.9,
                   "CBSA":6.0,
                   "state":1.4
                }
             },
             "female_householder":{
                "name":"Female householder",
                "values":{
                   "this":5.09,
                   "CBSA":12.98,
                   "state":20.1
                },
                "error":{
                   "this":1.65,
                   "CBSA":0.54,
                   "state":0.21
                },
                "numerators":{
                   "this":383.0,
                   "CBSA":50947.0,
                   "state":1549424.0
                },
                "numerator_errors":{
                   "this":125.0,
                   "CBSA":2129.0,
                   "state":16177.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":39.0,
                   "state":25.0
                },
                "error_ratio":{
                   "this":32.4,
                   "CBSA":4.2,
                   "state":1.0
                }
             }
          }
       },
       "fertility":{
          "total":{
             "name":"Women 15-50 who gave birth during past year",
             "values":{
                "this":4.34,
                "CBSA":4.91,
                "state":4.88
             },
             "error":{
                "this":1.75,
                "CBSA":0.2,
                "state":0.06
             },
             "numerators":{
                "this":267.0,
                "CBSA":23987.0,
                "state":469884.0
             },
             "numerator_errors":{
                "this":108.0,
                "CBSA":958.0,
                "state":5597.0
             },
             "metadata":{
                "table_id":"B13016",
                "universe":"Women 15 to 50 years",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":88.0,
                "state":89.0
             },
             "error_ratio":{
                "this":40.3,
                "CBSA":4.1,
                "state":1.2
             }
          },
          "by_age":{
             "metadata":{
                "table_id":"B13016",
                "universe":"Women 15 to 50 years",
                "acs_release":"ACS 2019 5-year"
             },
             "15_to_19":{
                "name":"15-19",
                "values":{
                   "this":0,
                   "CBSA":0.44,
                   "state":1.01
                },
                "error":{
                   "this":0,
                   "CBSA":0.2,
                   "state":0.07
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":252.0,
                   "state":12727.0
                },
                "numerator_errors":{
                   "this":26.0,
                   "CBSA":117.0,
                   "state":844.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":45.5,
                   "state":6.9
                }
             },
             "20_to_24":{
                "name":"20-24",
                "values":{
                   "this":0,
                   "CBSA":2.68,
                   "state":4.98
                },
                "error":{
                   "this":0,
                   "CBSA":0.5,
                   "state":0.17
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":1581.0,
                   "state":66276.0
                },
                "numerator_errors":{
                   "this":26.0,
                   "CBSA":295.0,
                   "state":2263.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":18.7,
                   "state":3.4
                }
             },
             "25_to_29":{
                "name":"25-29",
                "values":{
                   "this":3.11,
                   "CBSA":6.56,
                   "state":7.77
                },
                "error":{
                   "this":3.63,
                   "CBSA":0.65,
                   "state":0.16
                },
                "numerators":{
                   "this":16.0,
                   "CBSA":4781.0,
                   "state":115600.0
                },
                "numerator_errors":{
                   "this":19.0,
                   "CBSA":476.0,
                   "state":2442.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":47.0,
                   "state":40.0
                },
                "error_ratio":{
                   "this":116.7,
                   "CBSA":9.9,
                   "state":2.1
                }
             },
             "30_to_34":{
                "name":"30-35",
                "values":{
                   "this":9.43,
                   "CBSA":11.86,
                   "state":9.88
                },
                "error":{
                   "this":6.85,
                   "CBSA":0.81,
                   "state":0.2
                },
                "numerators":{
                   "this":48.0,
                   "CBSA":8909.0,
                   "state":138843.0
                },
                "numerator_errors":{
                   "this":37.0,
                   "CBSA":617.0,
                   "state":2879.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":80.0,
                   "state":95.0
                },
                "error_ratio":{
                   "this":72.6,
                   "CBSA":6.8,
                   "state":2.0
                }
             },
             "35_to_39":{
                "name":"35-39",
                "values":{
                   "this":10.5,
                   "CBSA":8.52,
                   "state":7.09
                },
                "error":{
                   "this":5.55,
                   "CBSA":0.66,
                   "state":0.16
                },
                "numerators":{
                   "this":93.0,
                   "CBSA":6011.0,
                   "state":93691.0
                },
                "numerator_errors":{
                   "this":52.0,
                   "CBSA":478.0,
                   "state":2137.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":123.0,
                   "state":148.0
                },
                "error_ratio":{
                   "this":52.9,
                   "CBSA":7.7,
                   "state":2.3
                }
             },
             "40_to_44":{
                "name":"40-44",
                "values":{
                   "this":3.76,
                   "CBSA":2.67,
                   "state":2.43
                },
                "error":{
                   "this":3.47,
                   "CBSA":0.37,
                   "state":0.1
                },
                "numerators":{
                   "this":46.0,
                   "CBSA":1855.0,
                   "state":30695.0
                },
                "numerator_errors":{
                   "this":43.0,
                   "CBSA":258.0,
                   "state":1315.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":141.0,
                   "state":155.0
                },
                "error_ratio":{
                   "this":92.3,
                   "CBSA":13.9,
                   "state":4.1
                }
             },
             "45_to_50":{
                "name":"45-50",
                "values":{
                   "this":3.63,
                   "CBSA":0.71,
                   "state":0.77
                },
                "error":{
                   "this":3.44,
                   "CBSA":0.21,
                   "state":0.06
                },
                "numerators":{
                   "this":64.0,
                   "CBSA":598.0,
                   "state":12052.0
                },
                "numerator_errors":{
                   "this":61.0,
                   "CBSA":174.0,
                   "state":861.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":511.0,
                   "state":471.0
                },
                "error_ratio":{
                   "this":94.8,
                   "CBSA":29.6,
                   "state":7.8
                }
             }
          }
       },
       "households":{
          "number_of_households":{
             "name":"Number of households",
             "values":{
                "this":10652.0,
                "CBSA":658350.0,
                "state":13044266.0
             },
             "error":{
                "this":229.0,
                "CBSA":1597.0,
                "state":20333.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B11001",
                "universe":"Households",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":2.0,
                "state":null
             },
             "error_ratio":{
                "this":2.1,
                "CBSA":0.2,
                "state":0.2
             }
          },
          "persons_per_household":{
             "name":"Persons per household",
             "values":{
                "this":2.84,
                "CBSA":2.96,
                "state":2.95
             },
             "error":{
                "this":0.06,
                "CBSA":0.01,
                "state":0.0
             },
             "numerators":{
                "this":30227.0,
                "CBSA":1951101.0,
                "state":38462235.0
             },
             "numerator_errors":{
                "this":131.0,
                "CBSA":1336.0,
                "state":0.0
             },
             "metadata":{
                "table_id":"B11001,b11002",
                "universe":"Households",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":96.0,
                "state":96.0
             },
             "error_ratio":{
                "this":2.1,
                "CBSA":0.3,
                "state":null
             }
          },
          "distribution":{
             "metadata":{
                "table_id":"B11002",
                "universe":"People in Households",
                "acs_release":"ACS 2019 5-year"
             },
             "married_couples":{
                "name":"Married couples",
                "values":{
                   "this":82.96,
                   "CBSA":67.5,
                   "state":61.04
                },
                "error":{
                   "this":2.19,
                   "CBSA":0.43,
                   "state":0.21
                },
                "numerators":{
                   "this":25077.0,
                   "CBSA":1316990.0,
                   "state":23478016.0
                },
                "numerator_errors":{
                   "this":671.0,
                   "CBSA":8371.0,
                   "state":81110.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":123.0,
                   "state":136.0
                },
                "error_ratio":{
                   "this":2.6,
                   "CBSA":0.6,
                   "state":0.3
                }
             },
             "male_householder":{
                "name":"Male householder",
                "values":{
                   "this":1.38,
                   "CBSA":6.18,
                   "state":7.59
                },
                "error":{
                   "this":0.58,
                   "CBSA":0.23,
                   "state":0.09
                },
                "numerators":{
                   "this":417.0,
                   "CBSA":120578.0,
                   "state":2919408.0
                },
                "numerator_errors":{
                   "this":175.0,
                   "CBSA":4560.0,
                   "state":33493.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":22.0,
                   "state":18.0
                },
                "error_ratio":{
                   "this":42.0,
                   "CBSA":3.7,
                   "state":1.2
                }
             },
             "female_householder":{
                "name":"Female householder",
                "values":{
                   "this":7.68,
                   "CBSA":12.3,
                   "state":16.57
                },
                "error":{
                   "this":1.83,
                   "CBSA":0.34,
                   "state":0.12
                },
                "numerators":{
                   "this":2321.0,
                   "CBSA":239896.0,
                   "state":6373007.0
                },
                "numerator_errors":{
                   "this":553.0,
                   "CBSA":6556.0,
                   "state":46993.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":62.0,
                   "state":46.0
                },
                "error_ratio":{
                   "this":23.8,
                   "CBSA":2.8,
                   "state":0.7
                }
             },
             "nonfamily":{
                "name":"Non-family",
                "values":{
                   "this":7.98,
                   "CBSA":14.02,
                   "state":14.8
                },
                "error":{
                   "this":0.96,
                   "CBSA":0.23,
                   "state":0.07
                },
                "numerators":{
                   "this":2412.0,
                   "CBSA":273637.0,
                   "state":5691804.0
                },
                "numerator_errors":{
                   "this":290.0,
                   "CBSA":4552.0,
                   "state":27482.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":57.0,
                   "state":54.0
                },
                "error_ratio":{
                   "this":12.0,
                   "CBSA":1.6,
                   "state":0.5
                }
             }
          }
       }
    },
    "housing":{
       "units":{
          "number":{
             "name":"Number of housing units",
             "values":{
                "this":11057.0,
                "CBSA":691465.0,
                "state":14175976.0
             },
             "error":{
                "this":246.0,
                "CBSA":562.0,
                "state":1184.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B25002",
                "universe":"Housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":2.0,
                "state":null
             },
             "error_ratio":{
                "this":2.2,
                "CBSA":0.1,
                "state":null
             }
          },
          "occupancy_distribution":{
             "metadata":{
                "table_id":"B25002",
                "universe":"Housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "occupied":{
                "name":"Occupied",
                "values":{
                   "this":96.34,
                   "CBSA":95.21,
                   "state":92.02
                },
                "error":{
                   "this":2.98,
                   "CBSA":0.22,
                   "state":0.14
                },
                "numerators":{
                   "this":10652.0,
                   "CBSA":658350.0,
                   "state":13044266.0
                },
                "numerator_errors":{
                   "this":229.0,
                   "CBSA":1597.0,
                   "state":20333.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":101.0,
                   "state":105.0
                },
                "error_ratio":{
                   "this":3.1,
                   "CBSA":0.2,
                   "state":0.2
                }
             },
             "vacant":{
                "name":"Vacant",
                "values":{
                   "this":3.66,
                   "CBSA":4.79,
                   "state":7.98
                },
                "error":{
                   "this":1.29,
                   "CBSA":0.26,
                   "state":0.15
                },
                "numerators":{
                   "this":405.0,
                   "CBSA":33115.0,
                   "state":1131710.0
                },
                "numerator_errors":{
                   "this":143.0,
                   "CBSA":1770.0,
                   "state":20951.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":76.0,
                   "state":46.0
                },
                "error_ratio":{
                   "this":35.2,
                   "CBSA":5.4,
                   "state":1.9
                }
             }
          },
          "structure_distribution":{
             "metadata":{
                "table_id":"B25024",
                "universe":"Housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "single_unit":{
                "name":"Single unit",
                "values":{
                   "this":86.45,
                   "CBSA":63.19,
                   "state":64.78
                },
                "error":{
                   "this":1.57,
                   "CBSA":0.33,
                   "state":0.09
                },
                "numerators":{
                   "this":9559.0,
                   "CBSA":436950.0,
                   "state":9183849.0
                },
                "numerator_errors":{
                   "this":274.7,
                   "CBSA":2296.3,
                   "state":13345.6
                },
                "index":{
                   "this":100.0,
                   "CBSA":137.0,
                   "state":133.0
                },
                "error_ratio":{
                   "this":1.8,
                   "CBSA":0.5,
                   "state":0.1
                }
             },
             "multi_unit":{
                "name":"Multi-unit",
                "values":{
                   "this":13.46,
                   "CBSA":33.87,
                   "state":31.43
                },
                "error":{
                   "this":1.69,
                   "CBSA":0.44,
                   "state":0.11
                },
                "numerators":{
                   "this":1488.0,
                   "CBSA":234174.0,
                   "state":4455831.0
                },
                "numerator_errors":{
                   "this":189.9,
                   "CBSA":3074.0,
                   "state":15037.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":40.0,
                   "state":43.0
                },
                "error_ratio":{
                   "this":12.6,
                   "CBSA":1.3,
                   "state":0.3
                }
             },
             "mobile_home":{
                "name":"Mobile home",
                "values":{
                   "this":0.09,
                   "CBSA":2.86,
                   "state":3.68
                },
                "error":{
                   "this":0.15,
                   "CBSA":0.1,
                   "state":0.03
                },
                "numerators":{
                   "this":10.0,
                   "CBSA":19795.0,
                   "state":521135.0
                },
                "numerator_errors":{
                   "this":17.0,
                   "CBSA":718.0,
                   "state":4740.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":3.0,
                   "state":2.0
                },
                "error_ratio":{
                   "this":166.7,
                   "CBSA":3.5,
                   "state":0.8
                }
             },
             "vehicle":{
                "name":"Boat, RV, van, etc.",
                "values":{
                   "this":0,
                   "CBSA":0.08,
                   "state":0.11
                },
                "error":{
                   "this":0,
                   "CBSA":0.03,
                   "state":0.01
                },
                "numerators":{
                   "this":0.0,
                   "CBSA":546.0,
                   "state":15161.0
                },
                "numerator_errors":{
                   "this":26.0,
                   "CBSA":173.0,
                   "state":862.0
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":37.5,
                   "state":9.1
                }
             }
          }
       },
       "ownership":{
          "distribution":{
             "metadata":{
                "table_id":"B25003",
                "universe":"Occupied housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "owner":{
                "name":"Owner occupied",
                "values":{
                   "this":81.01,
                   "CBSA":56.6,
                   "state":54.85
                },
                "error":{
                   "this":1.64,
                   "CBSA":0.4,
                   "state":0.28
                },
                "numerators":{
                   "this":8629.0,
                   "CBSA":372659.0,
                   "state":7154580.0
                },
                "numerator_errors":{
                   "this":255.0,
                   "CBSA":2785.0,
                   "state":37872.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":143.0,
                   "state":148.0
                },
                "error_ratio":{
                   "this":2.0,
                   "CBSA":0.7,
                   "state":0.5
                }
             },
             "renter":{
                "name":"Renter occupied",
                "values":{
                   "this":18.99,
                   "CBSA":43.4,
                   "state":45.15
                },
                "error":{
                   "this":1.61,
                   "CBSA":0.36,
                   "state":0.15
                },
                "numerators":{
                   "this":2023.0,
                   "CBSA":285691.0,
                   "state":5889686.0
                },
                "numerator_errors":{
                   "this":177.0,
                   "CBSA":2449.0,
                   "state":21311.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":44.0,
                   "state":42.0
                },
                "error_ratio":{
                   "this":8.5,
                   "CBSA":0.8,
                   "state":0.3
                }
             }
          },
          "median_value":{
             "name":"Median value of owner-occupied housing units",
             "values":{
                "this":2000001.0,
                "CBSA":968800.0,
                "state":505000.0
             },
             "error":{
                "this":0,
                "CBSA":5412.0,
                "state":1147.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B25077",
                "universe":"Owner-occupied housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":206.0,
                "state":396.0
             },
             "error_ratio":{
                "this":null,
                "CBSA":0.6,
                "state":0.2
             }
          },
          "value_distribution":{
             "metadata":{
                "table_id":"B25075",
                "universe":"Owner-occupied housing units",
                "acs_release":"ACS 2019 5-year"
             },
             "under_100":{
                "name":"Under $100K",
                "values":{
                   "this":0.16,
                   "CBSA":2.37,
                   "state":5.03
                },
                "error":{
                   "this":1.02,
                   "CBSA":0.15,
                   "state":0.05
                },
                "numerators":{
                   "this":14.0,
                   "CBSA":8828.0,
                   "state":359961.0
                },
                "numerator_errors":{
                   "this":87.6,
                   "CBSA":548.4,
                   "state":3940.4
                },
                "index":{
                   "this":100.0,
                   "CBSA":7.0,
                   "state":3.0
                },
                "error_ratio":{
                   "this":637.5,
                   "CBSA":6.3,
                   "state":1.0
                }
             },
             "100_to_200":{
                "name":"$100K - $200K",
                "values":{
                   "this":0.23,
                   "CBSA":2.07,
                   "state":6.63
                },
                "error":{
                   "this":0.5,
                   "CBSA":0.15,
                   "state":0.06
                },
                "numerators":{
                   "this":20.0,
                   "CBSA":7732.0,
                   "state":474527.0
                },
                "numerator_errors":{
                   "this":43.0,
                   "CBSA":568.6,
                   "state":4768.2
                },
                "index":{
                   "this":100.0,
                   "CBSA":11.0,
                   "state":3.0
                },
                "error_ratio":{
                   "this":217.4,
                   "CBSA":7.2,
                   "state":0.9
                }
             },
             "200_to_300":{
                "name":"$200K - $300K",
                "values":{
                   "this":0.44,
                   "CBSA":1.76,
                   "state":11.06
                },
                "error":{
                   "this":0.37,
                   "CBSA":0.13,
                   "state":0.06
                },
                "numerators":{
                   "this":38.0,
                   "CBSA":6570.0,
                   "state":791435.0
                },
                "numerator_errors":{
                   "this":32.3,
                   "CBSA":504.3,
                   "state":6253.7
                },
                "index":{
                   "this":100.0,
                   "CBSA":25.0,
                   "state":4.0
                },
                "error_ratio":{
                   "this":84.1,
                   "CBSA":7.4,
                   "state":0.5
                }
             },
             "300_to_400":{
                "name":"$300K - $400K",
                "values":{
                   "this":0.6,
                   "CBSA":2.37,
                   "state":13.6
                },
                "error":{
                   "this":0.36,
                   "CBSA":0.16,
                   "state":0.09
                },
                "numerators":{
                   "this":52.0,
                   "CBSA":8838.0,
                   "state":973196.0
                },
                "numerator_errors":{
                   "this":31.0,
                   "CBSA":599.0,
                   "state":7992.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":25.0,
                   "state":4.0
                },
                "error_ratio":{
                   "this":60.0,
                   "CBSA":6.8,
                   "state":0.7
                }
             },
             "400_to_500":{
                "name":"$400K - $500K",
                "values":{
                   "this":0.59,
                   "CBSA":3.99,
                   "state":13.21
                },
                "error":{
                   "this":0.44,
                   "CBSA":0.22,
                   "state":0.08
                },
                "numerators":{
                   "this":51.0,
                   "CBSA":14882.0,
                   "state":945276.0
                },
                "numerator_errors":{
                   "this":38.0,
                   "CBSA":831.0,
                   "state":7694.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":15.0,
                   "state":4.0
                },
                "error_ratio":{
                   "this":74.6,
                   "CBSA":5.5,
                   "state":0.6
                }
             },
             "500_to_1000000":{
                "name":"$500K - $1M",
                "values":{
                   "this":2.27,
                   "CBSA":40.13,
                   "state":35.28
                },
                "error":{
                   "this":0.75,
                   "CBSA":0.54,
                   "state":0.26
                },
                "numerators":{
                   "this":196.0,
                   "CBSA":149566.0,
                   "state":2523951.0
                },
                "numerator_errors":{
                   "this":65.2,
                   "CBSA":2309.5,
                   "state":12891.8
                },
                "index":{
                   "this":100.0,
                   "CBSA":6.0,
                   "state":6.0
                },
                "error_ratio":{
                   "this":33.0,
                   "CBSA":1.3,
                   "state":0.7
                }
             },
             "over_1000000":{
                "name":"Over $1M",
                "values":{
                   "this":5.49,
                   "CBSA":22.29,
                   "state":7.93
                },
                "error":{
                   "this":1.25,
                   "CBSA":0.41,
                   "state":0.04
                },
                "numerators":{
                   "this":474.0,
                   "CBSA":83053.0,
                   "state":567462.0
                },
                "numerator_errors":{
                   "this":109.0,
                   "CBSA":1652.0,
                   "state":4302.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":25.0,
                   "state":69.0
                },
                "error_ratio":{
                   "this":22.8,
                   "CBSA":1.8,
                   "state":0.5
                }
             }
          },
          "total_value":{
             "name":"Total value of owner-occupied housing units",
             "values":{
                "this":8629.0,
                "CBSA":372659.0,
                "state":7154580.0
             },
             "error":{
                "this":255.0,
                "CBSA":2785.0,
                "state":37872.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "index":{
                "this":100.0,
                "CBSA":2.0,
                "state":null
             },
             "error_ratio":{
                "this":3.0,
                "CBSA":0.7,
                "state":0.5
             }
          }
       },
       "length_of_tenure":{
          "metadata":{
             "table_id":"B25026",
             "universe":"Total population in occupied housing units",
             "acs_release":"ACS 2019 5-year"
          },
          "Before_1990":{
             "name":"Before 1990",
             "values":{
                "this":16.24,
                "CBSA":10.69,
                "state":9.72
             },
             "error":{
                "this":1.5,
                "CBSA":0.25,
                "state":0.06
             },
             "numerators":{
                "this":4909.0,
                "CBSA":208556.0,
                "state":3739275.0
             },
             "numerator_errors":{
                "this":454.4,
                "CBSA":4934.2,
                "state":21932.9
             },
             "index":{
                "this":100.0,
                "CBSA":152.0,
                "state":167.0
             },
             "error_ratio":{
                "this":9.2,
                "CBSA":2.3,
                "state":0.6
             }
          },
          "1990s":{
             "name":"1990s",
             "values":{
                "this":14.32,
                "CBSA":11.88,
                "state":12.25
             },
             "error":{
                "this":1.75,
                "CBSA":0.24,
                "state":0.08
             },
             "numerators":{
                "this":4330.0,
                "CBSA":231731.0,
                "state":4711644.0
             },
             "numerator_errors":{
                "this":530.0,
                "CBSA":4705.1,
                "state":30793.4
             },
             "index":{
                "this":100.0,
                "CBSA":121.0,
                "state":117.0
             },
             "error_ratio":{
                "this":12.2,
                "CBSA":2.0,
                "state":0.7
             }
          },
          "2000s":{
             "name":"2000s",
             "values":{
                "this":27.41,
                "CBSA":26.42,
                "state":25.7
             },
             "error":{
                "this":2.57,
                "CBSA":0.43,
                "state":0.13
             },
             "numerators":{
                "this":8285.0,
                "CBSA":515473.0,
                "state":9884339.0
             },
             "numerator_errors":{
                "this":777.6,
                "CBSA":8494.2,
                "state":50780.8
             },
             "index":{
                "this":100.0,
                "CBSA":104.0,
                "state":107.0
             },
             "error_ratio":{
                "this":9.4,
                "CBSA":1.6,
                "state":0.5
             }
          },
          "2010_to_2014":{
             "name":"2010-2014",
             "values":{
                "this":25.61,
                "CBSA":27.26,
                "state":28.78
             },
             "error":{
                "this":2.38,
                "CBSA":0.45,
                "state":0.16
             },
             "numerators":{
                "this":7742.0,
                "CBSA":531851.0,
                "state":11067761.0
             },
             "numerator_errors":{
                "this":720.2,
                "CBSA":8744.9,
                "state":60057.7
             },
             "index":{
                "this":100.0,
                "CBSA":94.0,
                "state":89.0
             },
             "error_ratio":{
                "this":9.3,
                "CBSA":1.7,
                "state":0.6
             }
          },
          "2015_to_2016":{
             "name":"2015-2016",
             "values":{
                "this":8.22,
                "CBSA":14.0,
                "state":14.48
             },
             "error":{
                "this":1.54,
                "CBSA":0.36,
                "state":0.11
             },
             "numerators":{
                "this":2484.0,
                "CBSA":273196.0,
                "state":5568721.0
             },
             "numerator_errors":{
                "this":464.6,
                "CBSA":6977.5,
                "state":43280.9
             },
             "index":{
                "this":100.0,
                "CBSA":59.0,
                "state":57.0
             },
             "error_ratio":{
                "this":18.7,
                "CBSA":2.6,
                "state":0.8
             }
          },
          "since_2017":{
             "name":"Since 2017",
             "values":{
                "this":8.19,
                "CBSA":9.75,
                "state":9.08
             },
             "error":{
                "this":1.59,
                "CBSA":0.29,
                "state":0.09
             },
             "numerators":{
                "this":2477.0,
                "CBSA":190294.0,
                "state":3490495.0
             },
             "numerator_errors":{
                "this":479.6,
                "CBSA":5754.6,
                "state":33565.8
             },
             "index":{
                "this":100.0,
                "CBSA":84.0,
                "state":90.0
             },
             "error_ratio":{
                "this":19.4,
                "CBSA":3.0,
                "state":1.0
             }
          }
       },
       "migration":{
          "moved_since_previous_year":{
             "name":"Moved since previous year",
             "values":{
                "this":10.21,
                "CBSA":14.41,
                "state":12.86
             },
             "error":{
                "this":1.64,
                "CBSA":0.28,
                "state":0.08
             },
             "numerators":{
                "this":3090.0,
                "CBSA":283223.0,
                "state":4993305.0
             },
             "numerator_errors":{
                "this":497.3,
                "CBSA":5465.0,
                "state":29689.5
             },
             "metadata":{
                "table_id":"B07003",
                "universe":"Population 1 year and over in the United States",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":71.0,
                "state":79.0
             },
             "error_ratio":{
                "this":16.1,
                "CBSA":1.9,
                "state":0.6
             }
          }
       },
       "migration_distribution":{
          "metadata":{
             "table_id":"B07003",
             "universe":"Population 1 year and over in the United States",
             "acs_release":"ACS 2019 5-year"
          },
          "same_house_year_ago":{
             "name":"Same house year ago",
             "values":{
                "this":89.79,
                "CBSA":85.59,
                "state":87.14
             },
             "error":{
                "this":1.6,
                "CBSA":0.27,
                "state":0.07
             },
             "numerators":{
                "this":27165.0,
                "CBSA":1682642.0,
                "state":33839689.0
             },
             "numerator_errors":{
                "this":500.0,
                "CBSA":5276.0,
                "state":27490.0
             },
             "index":{
                "this":100.0,
                "CBSA":105.0,
                "state":103.0
             },
             "error_ratio":{
                "this":1.8,
                "CBSA":0.3,
                "state":0.1
             }
          },
          "moved_same_county":{
             "name":"From same county",
             "values":{
                "this":4.88,
                "CBSA":8.33,
                "state":8.01
             },
             "error":{
                "this":1.18,
                "CBSA":0.22,
                "state":0.07
             },
             "numerators":{
                "this":1475.0,
                "CBSA":163762.0,
                "state":3108823.0
             },
             "numerator_errors":{
                "this":357.0,
                "CBSA":4270.0,
                "state":25604.0
             },
             "index":{
                "this":100.0,
                "CBSA":59.0,
                "state":61.0
             },
             "error_ratio":{
                "this":24.2,
                "CBSA":2.6,
                "state":0.9
             }
          },
          "moved_different_county":{
             "name":"From different county",
             "values":{
                "this":1.69,
                "CBSA":2.5,
                "state":2.75
             },
             "error":{
                "this":0.56,
                "CBSA":0.11,
                "state":0.03
             },
             "numerators":{
                "this":511.0,
                "CBSA":49162.0,
                "state":1069429.0
             },
             "numerator_errors":{
                "this":168.0,
                "CBSA":2113.0,
                "state":11456.0
             },
             "index":{
                "this":100.0,
                "CBSA":68.0,
                "state":61.0
             },
             "error_ratio":{
                "this":33.1,
                "CBSA":4.4,
                "state":1.1
             }
          },
          "moved_different_state":{
             "name":"From different state",
             "values":{
                "this":1.96,
                "CBSA":1.81,
                "state":1.3
             },
             "error":{
                "this":0.69,
                "CBSA":0.1,
                "state":0.02
             },
             "numerators":{
                "this":592.0,
                "CBSA":35635.0,
                "state":503560.0
             },
             "numerator_errors":{
                "this":209.0,
                "CBSA":1923.0,
                "state":8001.0
             },
             "index":{
                "this":100.0,
                "CBSA":108.0,
                "state":151.0
             },
             "error_ratio":{
                "this":35.2,
                "CBSA":5.5,
                "state":1.5
             }
          },
          "moved_from_abroad":{
             "name":"From abroad",
             "values":{
                "this":1.69,
                "CBSA":1.76,
                "state":0.8
             },
             "error":{
                "this":0.72,
                "CBSA":0.09,
                "state":0.01
             },
             "numerators":{
                "this":512.0,
                "CBSA":34664.0,
                "state":311493.0
             },
             "numerator_errors":{
                "this":219.0,
                "CBSA":1863.0,
                "state":5536.0
             },
             "index":{
                "this":100.0,
                "CBSA":96.0,
                "state":211.0
             },
             "error_ratio":{
                "this":42.6,
                "CBSA":5.1,
                "state":1.2
             }
          }
       }
    },
    "social":{
       "educational_attainment":{
          "percent_high_school_grad_or_higher":{
             "name":"High school grad or higher",
             "values":{
                "this":98.45,
                "CBSA":88.15,
                "state":83.31
             },
             "error":{
                "this":2.95,
                "CBSA":0.5,
                "state":0.15
             },
             "numerators":{
                "this":21160.0,
                "CBSA":1211097.0,
                "state":22052868.0
             },
             "numerator_errors":{
                "this":697.7,
                "CBSA":6911.2,
                "state":39734.1
             },
             "metadata":{
                "table_id":"B15002",
                "universe":"Population 25 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":112.0,
                "state":118.0
             },
             "error_ratio":{
                "this":3.0,
                "CBSA":0.6,
                "state":0.2
             }
          },
          "percent_bachelor_degree_or_higher":{
             "name":"Bachelor's degree or higher",
             "values":{
                "this":84.25,
                "CBSA":51.47,
                "state":33.93
             },
             "error":{
                "this":2.66,
                "CBSA":0.36,
                "state":0.09
             },
             "numerators":{
                "this":18108.0,
                "CBSA":707206.0,
                "state":8980726.0
             },
             "numerator_errors":{
                "this":623.6,
                "CBSA":4914.3,
                "state":23065.1
             },
             "metadata":{
                "table_id":"B15002",
                "universe":"Population 25 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":164.0,
                "state":248.0
             },
             "error_ratio":{
                "this":3.2,
                "CBSA":0.7,
                "state":0.3
             }
          }
       },
       "educational_attainment_distribution":{
          "metadata":{
             "table_id":"B15002",
             "universe":"Population 25 years and over",
             "acs_release":"ACS 2019 5-year"
          },
          "non_high_school_grad":{
             "name":"No degree",
             "values":{
                "this":1.55,
                "CBSA":11.85,
                "state":16.69
             },
             "error":{
                "this":0.56,
                "CBSA":0.21,
                "state":0.07
             },
             "numerators":{
                "this":334.0,
                "CBSA":162819.0,
                "state":4418675.0
             },
             "numerator_errors":{
                "this":120.6,
                "CBSA":2905.1,
                "state":18934.1
             },
             "index":{
                "this":100.0,
                "CBSA":13.0,
                "state":9.0
             },
             "error_ratio":{
                "this":36.1,
                "CBSA":1.8,
                "state":0.4
             }
          },
          "high_school_grad":{
             "name":"High school",
             "values":{
                "this":3.27,
                "CBSA":14.4,
                "state":20.49
             },
             "error":{
                "this":0.67,
                "CBSA":0.24,
                "state":0.08
             },
             "numerators":{
                "this":703.0,
                "CBSA":197824.0,
                "state":5423462.0
             },
             "numerator_errors":{
                "this":145.1,
                "CBSA":3326.3,
                "state":22237.1
             },
             "index":{
                "this":100.0,
                "CBSA":23.0,
                "state":16.0
             },
             "error_ratio":{
                "this":20.5,
                "CBSA":1.7,
                "state":0.4
             }
          },
          "some_college":{
             "name":"Some college",
             "values":{
                "this":10.93,
                "CBSA":22.28,
                "state":28.89
             },
             "error":{
                "this":1.28,
                "CBSA":0.26,
                "state":0.09
             },
             "numerators":{
                "this":2349.0,
                "CBSA":306067.0,
                "state":7648680.0
             },
             "numerator_errors":{
                "this":277.1,
                "CBSA":3542.7,
                "state":23501.3
             },
             "index":{
                "this":100.0,
                "CBSA":49.0,
                "state":38.0
             },
             "error_ratio":{
                "this":11.7,
                "CBSA":1.2,
                "state":0.3
             }
          },
          "Bachelor_degree":{
             "name":"Bachelor's",
             "values":{
                "this":32.46,
                "CBSA":27.29,
                "state":21.17
             },
             "error":{
                "this":1.6,
                "CBSA":0.25,
                "state":0.07
             },
             "numerators":{
                "this":6976.0,
                "CBSA":374919.0,
                "state":5603047.0
             },
             "numerator_errors":{
                "this":358.2,
                "CBSA":3457.6,
                "state":17216.6
             },
             "index":{
                "this":100.0,
                "CBSA":119.0,
                "state":153.0
             },
             "error_ratio":{
                "this":4.9,
                "CBSA":0.9,
                "state":0.3
             }
          },
          "post_grad_degree":{
             "name":"Post-grad",
             "values":{
                "this":51.79,
                "CBSA":24.19,
                "state":12.76
             },
             "error":{
                "this":2.26,
                "CBSA":0.25,
                "state":0.06
             },
             "numerators":{
                "this":11132.0,
                "CBSA":332287.0,
                "state":3377679.0
             },
             "numerator_errors":{
                "this":510.5,
                "CBSA":3492.1,
                "state":15348.8
             },
             "index":{
                "this":100.0,
                "CBSA":214.0,
                "state":406.0
             },
             "error_ratio":{
                "this":4.4,
                "CBSA":1.0,
                "state":0.5
             }
          }
       },
       "place_of_birth":{
          "percent_foreign_born":{
             "name":"Foreign-born population",
             "values":{
                "this":31.32,
                "CBSA":38.58,
                "state":26.85
             },
             "error":{
                "this":2.05,
                "CBSA":0.26,
                "state":0.09
             },
             "numerators":{
                "this":9555.0,
                "CBSA":767006.0,
                "state":10547210.0
             },
             "numerator_errors":{
                "this":627.0,
                "CBSA":5248.0,
                "state":36592.0
             },
             "metadata":{
                "table_id":"B05002",
                "universe":"Total population",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":81.0,
                "state":117.0
             },
             "error_ratio":{
                "this":6.5,
                "CBSA":0.7,
                "state":0.3
             }
          },
          "distribution":{
             "metadata":{
                "table_id":"B05006",
                "universe":"Foreign-born population",
                "acs_release":"ACS 2019 5-year"
             },
             "europe":{
                "name":"Europe",
                "values":{
                   "this":21.22,
                   "CBSA":7.91,
                   "state":6.42
                },
                "error":{
                   "this":3.41,
                   "CBSA":0.3,
                   "state":0.07
                },
                "numerators":{
                   "this":2028.0,
                   "CBSA":60639.0,
                   "state":677261.0
                },
                "numerator_errors":{
                   "this":352.0,
                   "CBSA":2354.0,
                   "state":7344.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":268.0,
                   "state":331.0
                },
                "error_ratio":{
                   "this":16.1,
                   "CBSA":3.8,
                   "state":1.1
                }
             },
             "asia":{
                "name":"Asia",
                "values":{
                   "this":68.16,
                   "CBSA":65.91,
                   "state":39.42
                },
                "error":{
                   "this":2.35,
                   "CBSA":0.62,
                   "state":0.09
                },
                "numerators":{
                   "this":6513.0,
                   "CBSA":505503.0,
                   "state":4157181.0
                },
                "numerator_errors":{
                   "this":483.0,
                   "CBSA":3277.0,
                   "state":17026.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":103.0,
                   "state":173.0
                },
                "error_ratio":{
                   "this":3.4,
                   "CBSA":0.9,
                   "state":0.2
                }
             },
             "africa":{
                "name":"Africa",
                "values":{
                   "this":1.48,
                   "CBSA":1.7,
                   "state":1.84
                },
                "error":{
                   "this":0.61,
                   "CBSA":0.14,
                   "state":0.05
                },
                "numerators":{
                   "this":141.0,
                   "CBSA":13034.0,
                   "state":193567.0
                },
                "numerator_errors":{
                   "this":59.0,
                   "CBSA":1055.0,
                   "state":4869.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":87.0,
                   "state":80.0
                },
                "error_ratio":{
                   "this":41.2,
                   "CBSA":8.2,
                   "state":2.7
                }
             },
             "oceania":{
                "name":"Oceania",
                "values":{
                   "this":0.9,
                   "CBSA":0.53,
                   "state":0.82
                },
                "error":{
                   "this":0.78,
                   "CBSA":0.09,
                   "state":0.03
                },
                "numerators":{
                   "this":86.0,
                   "CBSA":4060.0,
                   "state":86276.0
                },
                "numerator_errors":{
                   "this":75.0,
                   "CBSA":662.0,
                   "state":2767.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":170.0,
                   "state":110.0
                },
                "error_ratio":{
                   "this":86.7,
                   "CBSA":17.0,
                   "state":3.7
                }
             },
             "latin_america":{
                "name":"Latin America",
                "values":{
                   "this":4.8,
                   "CBSA":22.67,
                   "state":50.28
                },
                "error":{
                   "this":1.63,
                   "CBSA":0.42,
                   "state":0.15
                },
                "numerators":{
                   "this":459.0,
                   "CBSA":173901.0,
                   "state":5302761.0
                },
                "numerator_errors":{
                   "this":159.0,
                   "CBSA":3417.0,
                   "state":24213.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":21.0,
                   "state":10.0
                },
                "error_ratio":{
                   "this":34.0,
                   "CBSA":1.9,
                   "state":0.3
                }
             },
             "north_america":{
                "name":"North America",
                "values":{
                   "this":3.43,
                   "CBSA":1.29,
                   "state":1.23
                },
                "error":{
                   "this":1.24,
                   "CBSA":0.09,
                   "state":0.03
                },
                "numerators":{
                   "this":328.0,
                   "CBSA":9858.0,
                   "state":130093.0
                },
                "numerator_errors":{
                   "this":120.0,
                   "CBSA":722.0,
                   "state":3038.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":266.0,
                   "state":279.0
                },
                "error_ratio":{
                   "this":36.2,
                   "CBSA":7.0,
                   "state":2.4
                }
             }
          }
       },
       "language":{
          "percent_non_english_at_home":{
             "name":"Persons with language other than English spoken at home",
             "values":{
                "this":null,
                "CBSA":null,
                "state":44.23
             },
             "error":{
                "this":null,
                "CBSA":null,
                "state":0.14
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":16292017.0
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":52781.5
             },
             "metadata":{
                "table_id":"B16001",
                "universe":"Population 5 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "error_ratio":{
                "this":null,
                "CBSA":null,
                "state":0.3
             }
          },
          "children":{
             "metadata":{
                "table_id":"B16007",
                "universe":"Population 5 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "english":{
                "name":"English only",
                "values":{
                   "this":70.48,
                   "CBSA":52.99,
                   "state":56.46
                },
                "error":{
                   "this":4.35,
                   "CBSA":0.67,
                   "state":0.24
                },
                "numerators":{
                   "this":4632.0,
                   "CBSA":171505.0,
                   "state":3709800.0
                },
                "numerator_errors":{
                   "this":371.0,
                   "CBSA":2186.0,
                   "state":15797.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":133.0,
                   "state":125.0
                },
                "error_ratio":{
                   "this":6.2,
                   "CBSA":1.3,
                   "state":0.4
                }
             },
             "spanish":{
                "name":"Spanish",
                "values":{
                   "this":2.24,
                   "CBSA":22.53,
                   "state":33.98
                },
                "error":{
                   "this":1.85,
                   "CBSA":0.54,
                   "state":0.2
                },
                "numerators":{
                   "this":147.0,
                   "CBSA":72935.0,
                   "state":2232947.0
                },
                "numerator_errors":{
                   "this":122.0,
                   "CBSA":1746.0,
                   "state":13243.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":10.0,
                   "state":7.0
                },
                "error_ratio":{
                   "this":82.6,
                   "CBSA":2.4,
                   "state":0.6
                }
             },
             "indoeuropean":{
                "name":"Indo-European",
                "values":{
                   "this":10.5,
                   "CBSA":6.21,
                   "state":2.92
                },
                "error":{
                   "this":3.09,
                   "CBSA":0.29,
                   "state":0.07
                },
                "numerators":{
                   "this":690.0,
                   "CBSA":20097.0,
                   "state":191982.0
                },
                "numerator_errors":{
                   "this":206.0,
                   "CBSA":935.0,
                   "state":4345.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":169.0,
                   "state":360.0
                },
                "error_ratio":{
                   "this":29.4,
                   "CBSA":4.7,
                   "state":2.4
                }
             },
             "asian_islander":{
                "name":"Asian/Islander",
                "values":{
                   "this":13.66,
                   "CBSA":17.0,
                   "state":5.73
                },
                "error":{
                   "this":2.79,
                   "CBSA":0.46,
                   "state":0.06
                },
                "numerators":{
                   "this":898.0,
                   "CBSA":55026.0,
                   "state":376241.0
                },
                "numerator_errors":{
                   "this":189.0,
                   "CBSA":1483.0,
                   "state":4139.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":80.0,
                   "state":238.0
                },
                "error_ratio":{
                   "this":20.4,
                   "CBSA":2.7,
                   "state":1.0
                }
             },
             "other":{
                "name":"Other",
                "values":{
                   "this":3.12,
                   "CBSA":1.27,
                   "state":0.91
                },
                "error":{
                   "this":1.62,
                   "CBSA":0.18,
                   "state":0.04
                },
                "numerators":{
                   "this":205.0,
                   "CBSA":4106.0,
                   "state":59648.0
                },
                "numerator_errors":{
                   "this":107.0,
                   "CBSA":595.0,
                   "state":2823.0
                },
                "index":{
                   "this":100.0,
                   "CBSA":246.0,
                   "state":343.0
                },
                "error_ratio":{
                   "this":51.9,
                   "CBSA":14.2,
                   "state":4.4
                }
             }
          },
          "adults":{
             "metadata":{
                "table_id":"B16007",
                "universe":"Population 5 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "english":{
                "name":"English only",
                "values":{
                   "this":65.43,
                   "CBSA":46.23,
                   "state":55.62
                },
                "error":{
                   "this":2.37,
                   "CBSA":0.27,
                   "state":0.11
                },
                "numerators":{
                   "this":14810.0,
                   "CBSA":713748.0,
                   "state":16830152.0
                },
                "numerator_errors":{
                   "this":616.9,
                   "CBSA":4210.1,
                   "state":34792.2
                },
                "index":{
                   "this":100.0,
                   "CBSA":142.0,
                   "state":118.0
                },
                "error_ratio":{
                   "this":3.6,
                   "CBSA":0.6,
                   "state":0.2
                }
             },
             "spanish":{
                "name":"Spanish",
                "values":{
                   "this":3.45,
                   "CBSA":17.25,
                   "state":27.58
                },
                "error":{
                   "this":0.86,
                   "CBSA":0.21,
                   "state":0.08
                },
                "numerators":{
                   "this":781.0,
                   "CBSA":266293.0,
                   "state":8345569.0
                },
                "numerator_errors":{
                   "this":196.3,
                   "CBSA":3165.9,
                   "state":23054.2
                },
                "index":{
                   "this":100.0,
                   "CBSA":20.0,
                   "state":13.0
                },
                "error_ratio":{
                   "this":24.9,
                   "CBSA":1.2,
                   "state":0.3
                }
             },
             "indoeuropean":{
                "name":"Indo-European",
                "values":{
                   "this":13.24,
                   "CBSA":9.53,
                   "state":4.85
                },
                "error":{
                   "this":1.79,
                   "CBSA":0.24,
                   "state":0.04
                },
                "numerators":{
                   "this":2997.0,
                   "CBSA":147182.0,
                   "state":1468932.0
                },
                "numerator_errors":{
                   "this":410.1,
                   "CBSA":3659.2,
                   "state":12705.6
                },
                "index":{
                   "this":100.0,
                   "CBSA":139.0,
                   "state":273.0
                },
                "error_ratio":{
                   "this":13.5,
                   "CBSA":2.5,
                   "state":0.8
                }
             },
             "asian_islander":{
                "name":"Asian/Islander",
                "values":{
                   "this":15.98,
                   "CBSA":25.62,
                   "state":10.88
                },
                "error":{
                   "this":1.49,
                   "CBSA":0.23,
                   "state":0.03
                },
                "numerators":{
                   "this":3617.0,
                   "CBSA":395544.0,
                   "state":3293073.0
                },
                "numerator_errors":{
                   "this":345.6,
                   "CBSA":3565.1,
                   "state":10307.3
                },
                "index":{
                   "this":100.0,
                   "CBSA":62.0,
                   "state":147.0
                },
                "error_ratio":{
                   "this":9.3,
                   "CBSA":0.9,
                   "state":0.3
                }
             },
             "other":{
                "name":"Other",
                "values":{
                   "this":1.9,
                   "CBSA":1.36,
                   "state":1.07
                },
                "error":{
                   "this":0.61,
                   "CBSA":0.09,
                   "state":0.02
                },
                "numerators":{
                   "this":430.0,
                   "CBSA":21019.0,
                   "state":323625.0
                },
                "numerator_errors":{
                   "this":139.5,
                   "CBSA":1339.9,
                   "state":7069.6
                },
                "index":{
                   "this":100.0,
                   "CBSA":140.0,
                   "state":178.0
                },
                "error_ratio":{
                   "this":32.1,
                   "CBSA":6.6,
                   "state":1.9
                }
             }
          }
       },
       "veterans":{
          "wartime_service":{
             "metadata":{
                "table_id":"B21002",
                "universe":"Civilian veterans 18 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "wwii":{
                "name":"WWII",
                "values":{
                   "this":96.0,
                   "CBSA":2442.0,
                   "state":66574.0
                },
                "error":{
                   "this":55.82,
                   "CBSA":319.93,
                   "state":1628.6
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":4.0,
                   "state":null
                },
                "error_ratio":{
                   "this":58.1,
                   "CBSA":13.1,
                   "state":2.4
                }
             },
             "korea":{
                "name":"Korea",
                "values":{
                   "this":196.0,
                   "CBSA":6382.0,
                   "state":145536.0
                },
                "error":{
                   "this":73.46,
                   "CBSA":405.23,
                   "state":2399.66
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":3.0,
                   "state":null
                },
                "error_ratio":{
                   "this":37.5,
                   "CBSA":6.3,
                   "state":1.6
                }
             },
             "vietnam":{
                "name":"Vietnam",
                "values":{
                   "this":270.0,
                   "CBSA":19651.0,
                   "state":561083.0
                },
                "error":{
                   "this":103.13,
                   "CBSA":863.14,
                   "state":4350.77
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":1.0,
                   "state":null
                },
                "error_ratio":{
                   "this":38.2,
                   "CBSA":4.4,
                   "state":0.8
                }
             },
             "gulf_1990s":{
                "name":"Gulf (1990s)",
                "values":{
                   "this":81.0,
                   "CBSA":7981.0,
                   "state":284290.0
                },
                "error":{
                   "this":87.06,
                   "CBSA":586.46,
                   "state":3865.66
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":100.0,
                   "CBSA":1.0,
                   "state":null
                },
                "error_ratio":{
                   "this":107.5,
                   "CBSA":7.3,
                   "state":1.4
                }
             },
             "gulf_2001":{
                "name":"Gulf (2001-)",
                "values":{
                   "this":0.0,
                   "CBSA":7720.0,
                   "state":309021.0
                },
                "error":{
                   "this":45.03,
                   "CBSA":544.05,
                   "state":4425.46
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "index":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "error_ratio":{
                   "this":null,
                   "CBSA":7.0,
                   "state":1.4
                }
             }
          },
          "sex":{
             "male":{
                "name":"Male",
                "values":{
                   "this":839.0,
                   "CBSA":50028.0,
                   "state":1446385.0
                },
                "error":{
                   "this":138.0,
                   "CBSA":1263.0,
                   "state":8117.0
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B21001",
                   "universe":"Civilian population 18 years and over",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":2.0,
                   "state":null
                },
                "error_ratio":{
                   "this":16.4,
                   "CBSA":2.5,
                   "state":0.6
                }
             },
             "female":{
                "name":"Female",
                "values":{
                   "this":104.0,
                   "CBSA":4063.0,
                   "state":128146.0
                },
                "error":{
                   "this":83.0,
                   "CBSA":388.0,
                   "state":2994.0
                },
                "numerators":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "numerator_errors":{
                   "this":null,
                   "CBSA":null,
                   "state":null
                },
                "metadata":{
                   "table_id":"B21001",
                   "universe":"Civilian population 18 years and over",
                   "acs_release":"ACS 2019 5-year"
                },
                "index":{
                   "this":100.0,
                   "CBSA":3.0,
                   "state":null
                },
                "error_ratio":{
                   "this":79.8,
                   "CBSA":9.5,
                   "state":2.3
                }
             }
          },
          "number":{
             "name":"Total veterans",
             "values":{
                "this":943.0,
                "CBSA":54091.0,
                "state":1574531.0
             },
             "error":{
                "this":163.0,
                "CBSA":1285.0,
                "state":8842.0
             },
             "numerators":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "numerator_errors":{
                "this":null,
                "CBSA":null,
                "state":null
             },
             "metadata":{
                "table_id":"B21001",
                "universe":"Civilian population 18 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":2.0,
                "state":null
             },
             "error_ratio":{
                "this":17.3,
                "CBSA":2.4,
                "state":0.6
             }
          },
          "percentage":{
             "name":"Population with veteran status",
             "values":{
                "this":4.17,
                "CBSA":3.51,
                "state":5.23
             },
             "error":{
                "this":0.72,
                "CBSA":0.08,
                "state":0.03
             },
             "numerators":{
                "this":943.0,
                "CBSA":54091.0,
                "state":1574531.0
             },
             "numerator_errors":{
                "this":163.0,
                "CBSA":1285.0,
                "state":8842.0
             },
             "metadata":{
                "table_id":"B21001",
                "universe":"Civilian population 18 years and over",
                "acs_release":"ACS 2019 5-year"
             },
             "index":{
                "this":100.0,
                "CBSA":119.0,
                "state":80.0
             },
             "error_ratio":{
                "this":17.3,
                "CBSA":2.3,
                "state":0.6
             }
          }
       }
    },
    "geo_metadata":{
       "aland":16784655,
       "awater":0,
       "display_name":"Los Altos, CA",
       "full_geoid":"16000US0643280",
       "population":30588,
       "simple_name":"Los Altos",
       "sumlevel":"160",
       "square_miles":6.5,
       "population_density":4707.0
    }
 }

 export default los_altos