exports.SAN_BRUNO_LISTINGS = [
		{
			"closePrice": 2190000,
			"daysOnMovoto": 70,
			"id": "64d7fa47-6c68-46f4-acde-3afdd46cb290",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869669_0_yazmry_p.jpeg",
			"listDate": "2021-11-09 13:49:05",
			"listingAgent": "Carlos Barba",
			"listPrice": 1899000,
			"lotSize": 3419,
			"sqftTotal": 4081,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869669",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Bayshore Realtors",
			"photoCount": 25,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "94066",
			"path": "san-bruno-ca/584-hensley-ave-san-bruno-ca-94066/pid_jmy5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eb8cf8df-c242-4ed5-af9d-6c59c8ce1b28",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-19 00:00:00",
			"createdAt": "2021-11-09 21:52:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-09 13:49:05",
			"virtualTourLink": "https://584hensley46595.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.6252,
				"lng": -122.413433,
				"zipcode": "94066",
				"subPremise": "",
				"address": "584 Hensley Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2190000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://584hensley46595.f8re.com/"
		},
		{
			"closePrice": 1433000,
			"daysOnMovoto": 172,
			"id": "6840908f-49ac-471c-b3f7-0c9c9c0c543f",
			"tnImgPath": "https://pi.movoto.com/p/110/421576821_0_3fQjuE_p.jpeg",
			"listDate": "2021-07-15 00:00:00",
			"listingAgent": "Patricia Hontalas",
			"listPrice": 1550000,
			"lotSize": 5000,
			"sqftTotal": 4336,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421576821",
			"bath": 4,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 5,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1941,
			"zipCode": "94066",
			"path": "san-bruno-ca/770-e-san-bruno-ave-san-bruno-ca-94066-110_421576821/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -150000,
			"propertyId": "60df01c3-3d3a-4345-9901-25caccc01046",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-07-22 21:46:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.630741,
				"lng": -122.405955,
				"zipcode": "94066",
				"subPremise": "",
				"address": "770 E San Bruno Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1433000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 980000,
			"daysOnMovoto": 49,
			"id": "3fe66e0c-30ab-46ec-8094-8ce34d512835",
			"tnImgPath": "https://pi.movoto.com/p/12/40973103_0_nqaQR7_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Timothy Eng",
			"listPrice": 925000,
			"lotSize": 3700,
			"sqftTotal": 920,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973103",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Gentry Real Estate",
			"photoCount": 4,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1946,
			"zipCode": "94066",
			"path": "san-bruno-ca/724-4th-ave-san-bruno-ca-94066/pid_vhz5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3a31b591-8053-4fdf-b4ad-5e6ad1c42f90",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-03 19:26:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.629747,
				"lng": -122.407858,
				"zipcode": "94066",
				"subPremise": "",
				"address": "724 4th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 980000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 55,
			"id": "627eb91c-aaef-49ad-8a23-5160e7ccc4dc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868067_0_uJUVZA_p.jpeg",
			"listDate": "2021-10-25 22:06:08",
			"listingAgent": "Nathan Gustavson",
			"listPrice": 1475000,
			"lotSize": 3136,
			"sqftTotal": 2843,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868067",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass SF",
			"photoCount": 21,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1948,
			"zipCode": "94066",
			"path": "san-bruno-ca/772-6th-ave-san-bruno-ca-94066/pid_m9y5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e82f8f2b-5b76-4644-be84-181d8aad6f6d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-10-26 05:12:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-25 22:06:08",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.630483,
				"lng": -122.406309,
				"zipcode": "94066",
				"subPremise": "",
				"address": "772 6th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1610000,
			"daysOnMovoto": 41,
			"id": "11973ece-d04b-4d4e-84cb-503534b85079",
			"tnImgPath": "https://pi.movoto.com/p/12/40973092_0_ZuIZMF_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Barbara Reynolds",
			"listPrice": 1295000,
			"lotSize": 8736,
			"sqftTotal": 1510,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973092",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sothebys Intl Rlty",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "94066",
			"path": "san-bruno-ca/2801-saint-cloud-dr-san-bruno-ca-94066/pid_49s5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ffdd4b0d-afc8-4608-be6f-6e9b04eb261f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-03 18:11:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": "https://www.2801stcloud.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.634854,
				"lng": -122.454354,
				"zipcode": "94066",
				"subPremise": "",
				"address": "2801 Saint Cloud Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1610000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.2801stcloud.com/mls"
		},
		{
			"closePrice": 1268000,
			"daysOnMovoto": 0,
			"id": "2da10bcb-67b9-46ef-94a7-cb92603ca6c9",
			"tnImgPath": "https://pi.movoto.com/p/102/421611971_0_JQmjmB_p.jpeg",
			"listDate": null,
			"listingAgent": "Kenneth Mori",
			"listPrice": 1268000,
			"lotSize": 3700,
			"sqftTotal": 1550,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421611971",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Gold Fair Oaks",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1948,
			"zipCode": "94066",
			"path": "san-bruno-ca/470-beech-ave-apt-san-bruno-ca-94066-102_421611971/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 80000,
			"propertyId": "449bf173-3c72-4251-8420-b4ae65117599",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-11-14 21:09:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-14 21:09:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619402,
				"lng": -122.417858,
				"zipcode": "94066",
				"subPremise": "APT .",
				"address": "470 Beech Ave #."
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1268000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 369000,
			"daysOnMovoto": 46,
			"id": "b2a20535-637b-4d29-a538-c040d8b359ee",
			"tnImgPath": "https://pi.movoto.com/p/110/421607748_0_IZUQ6A_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Eric Castongia",
			"listPrice": 369000,
			"lotSize": 1679615,
			"sqftTotal": 490,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421607748",
			"bath": 1,
			"bed": null,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/5224-shelter-creek-ln-san-bruno-ca-94066/pid_6h58w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d2f454ba-7d54-4f24-8fff-ef3a4654ade3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-12 00:00:00",
			"createdAt": "2021-10-29 04:16:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/hDQpl3o8VNk",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619843,
				"lng": -122.428366,
				"zipcode": "94066",
				"subPremise": "",
				"address": "5224 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 369000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/hDQpl3o8VNk"
		},
		{
			"closePrice": 950000,
			"daysOnMovoto": 1,
			"id": "6814811c-1767-4312-affe-b0698ecc15b1",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-12-09 00:00:00",
			"listingAgent": "AiLing Ye",
			"listPrice": 950000,
			"lotSize": 5000,
			"sqftTotal": 1260,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421616382",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Surpass Real Estate & Finance",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1949,
			"zipCode": "94066",
			"path": "san-bruno-ca/846-masson-ave-san-bruno-ca-94066/pid_r6x5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "40387d7e-f8ef-4bfa-9329-2f9952f856ea",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-12-10 07:31:13",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-12-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.631029,
				"lng": -122.414008,
				"zipcode": "94066",
				"subPremise": "",
				"address": "846 Masson Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1475000,
			"daysOnMovoto": 1,
			"id": "08e0049e-e01b-4d08-9254-7e6edd43b647",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871940_0_7bEa73_p.jpeg",
			"listDate": "2021-12-06 18:58:39",
			"listingAgent": "Adolfo Sequeira, Jr.",
			"listPrice": 1475000,
			"lotSize": 6199,
			"sqftTotal": 2177,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871940",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "ASJ Real Estate Investments",
			"photoCount": 1,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1925,
			"zipCode": "94066",
			"path": "san-bruno-ca/110-san-antonio-ave-san-bruno-ca-94066-202_ml81483589/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8f5552c2-abc0-4b8c-b95c-ec4cf143245c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-12-07 03:02:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-06 18:58:39",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619057,
				"lng": -122.405309,
				"zipcode": "94066",
				"subPremise": "",
				"address": "110 San Antonio Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 941200,
			"daysOnMovoto": 0,
			"id": "87870a4b-0730-4cf5-b548-0c778d5f1650",
			"tnImgPath": "https://pi.movoto.com/p/102/20076207_0_qqqAYN_p.jpeg",
			"listDate": null,
			"listingAgent": "Michael Miller",
			"listPrice": 1199000,
			"lotSize": 10350,
			"sqftTotal": 2140,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "20076207",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "ERA Carlile Realty Group",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1955,
			"zipCode": "94066",
			"path": "san-bruno-ca/2796-fleetwood-dr-san-bruno-ca-94066-100_ml81774535/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2d211c59-56de-432b-ac2d-bf2e3cd6b732",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-01-13 20:03:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-13 20:03:33",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.626022,
				"lng": -122.446751,
				"zipcode": "94066",
				"subPremise": "",
				"address": "2796 Fleetwood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 941200,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1525000,
			"daysOnMovoto": 6,
			"id": "1df402b0-48d5-4357-bc0f-8cb082112732",
			"tnImgPath": "https://pi.movoto.com/p/110/421612834_0_6yi6v6_p.jpeg",
			"listDate": "2021-11-19 00:00:00",
			"listingAgent": "Maria Sagullo",
			"listPrice": 1198000,
			"lotSize": 5050,
			"sqftTotal": 1548,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421612834",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Hatch Realty Group, Inc.",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1960,
			"zipCode": "94066",
			"path": "san-bruno-ca/3671-elston-dr-san-bruno-ca-94066-100_81437637/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e9be3cb0-0ef8-4f97-8c0b-aea3389a2e62",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-25 00:00:00",
			"createdAt": "2021-11-20 05:26:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.635638,
				"lng": -122.461997,
				"zipcode": "94066",
				"subPremise": "",
				"address": "3671 Elston Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1525000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 63,
			"id": "de2ba986-da82-4a12-8a7f-e43851733abe",
			"tnImgPath": "https://pi.movoto.com/p/110/421610283_0_fjuVvb_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Ric Rocchiccioli",
			"listPrice": 925000,
			"lotSize": 5000,
			"sqftTotal": 759,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421610283",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1943,
			"zipCode": "94066",
			"path": "san-bruno-ca/668-5th-ave-san-bruno-ca-94066-100_80501917/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -25000,
			"propertyId": "0eda3e66-bf23-4254-a94a-bc6eafbc5e2c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-11-06 16:51:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.628742,
				"lng": -122.40632,
				"zipcode": "94066",
				"subPremise": "",
				"address": "668 5th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 34,
			"id": "4f9930f5-7594-4455-8a7b-ca9e1f9afd25",
			"tnImgPath": "https://pi.movoto.com/p/12/40970634_0_mJBq2e_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Brad Slabaugh",
			"listPrice": 1099000,
			"lotSize": 4500,
			"sqftTotal": 850,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970634",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1917,
			"zipCode": "94066",
			"path": "san-bruno-ca/145-cypress-ave-san-bruno-ca-94066/pid_iwz5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "58b64293-844d-4219-acb8-9df395f54fd0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-10-12 22:26:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.614586,
				"lng": -122.411309,
				"zipcode": "94066",
				"subPremise": "",
				"address": "145 Cypress Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 405000,
			"daysOnMovoto": 24,
			"id": "25ff68d4-8b3c-46c8-82c2-a213790b6ea5",
			"tnImgPath": "https://pi.movoto.com/p/101/421603281_0_qIiFvj_p.jpeg",
			"listDate": "2021-10-11 00:00:00",
			"listingAgent": "Marty Bautista",
			"listPrice": 399000,
			"lotSize": 159124,
			"sqftTotal": 450,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421603281",
			"bath": 1,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94066",
			"path": "san-bruno-ca/406-boardwalk-ave-15-san-bruno-ca-94066/pid_ql78w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7c0169f6-e6a2-4688-bc62-482dee6a347c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-10-12 06:28:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-11 00:00:00",
			"virtualTourLink": "https://www.cbhometour.com/406-Boardwalk-Avenue-Apt-15-San-Bruno-CA-94066/index.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.630333,
				"lng": -122.430466,
				"zipcode": "94066",
				"subPremise": "APT 15",
				"address": "406 Boardwalk Ave #15"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 405000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.cbhometour.com/406-Boardwalk-Avenue-Apt-15-San-Bruno-CA-94066/index.html"
		},
		{
			"closePrice": 915000,
			"daysOnMovoto": 42,
			"id": "19fc8545-cf07-443d-b6b2-d31362949123",
			"tnImgPath": "https://pi.movoto.com/p/12/40966526_0_yiYYbi_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Michael Rubio",
			"listPrice": 899000,
			"lotSize": 5000,
			"sqftTotal": 1000,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966526",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1940,
			"zipCode": "94066",
			"path": "san-bruno-ca/40-atlantic-san-bruno-ca-94066-12_40966526/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "761af3b7-375b-4c54-b410-1fb0d76b7123",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-09-18 01:11:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.638742,
				"lng": -122.41476,
				"zipcode": "94066",
				"subPremise": "",
				"address": "40 Atlantic"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 915000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1105000,
			"daysOnMovoto": 13,
			"id": "ac37ce3a-81f8-463a-ac68-35a4e079c31b",
			"tnImgPath": "https://pi.movoto.com/p/110/421604002_0_ium2yN_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Wendy Tam",
			"listPrice": 995000,
			"lotSize": 3800,
			"sqftTotal": 1720,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421604002",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "W Real Estate",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1909,
			"zipCode": "94066",
			"path": "san-bruno-ca/535-e-angus-ave-san-bruno-ca-94066-110_421604002/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "97b143d9-093f-4d9a-8f2e-22a02dfc0ff3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-10-15 16:16:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=tmRL9RFU6YP&brand=0&back=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.627533,
				"lng": -122.406442,
				"zipcode": "94066",
				"subPremise": "",
				"address": "535 E Angus Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1105000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=tmRL9RFU6YP&brand=0&back=1"
		},
		{
			"closePrice": 1875000,
			"daysOnMovoto": 40,
			"id": "e855ddee-5aa4-4d70-aaf1-b63d4eef90f2",
			"tnImgPath": "https://pi.movoto.com/p/12/40967011_0_vIJmaI_p.jpeg",
			"listDate": "2021-09-16 00:00:00",
			"listingAgent": "Kevin Magna",
			"listPrice": 1688000,
			"lotSize": 10995,
			"sqftTotal": 2040,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967011",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1965,
			"zipCode": "94066",
			"path": "san-bruno-ca/100-merced-san-bruno-ca-94066-12_40967011/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7063f847-9a45-454f-8026-2f583462951d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-16 16:11:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=5c4o2kFpN3U&brand=0&mls=1&",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619749,
				"lng": -122.453027,
				"zipcode": "94066",
				"subPremise": "",
				"address": "100 Merced"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=5c4o2kFpN3U&brand=0&mls=1&"
		},
		{
			"closePrice": 510000,
			"daysOnMovoto": 37,
			"id": "c6e528ee-23db-4079-ab57-5ac4d8787c70",
			"tnImgPath": "https://pi.movoto.com/p/110/421597571_0_7IzerM_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Steven Cervantes",
			"listPrice": 485000,
			"lotSize": 1679615,
			"sqftTotal": 680,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421597571",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/6204-shelter-creek-ln-san-bruno-ca-94066/pid_j368w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "944ca014-f413-4edb-8238-e5e2805835bb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-24 00:00:00",
			"createdAt": "2021-09-24 05:06:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619825,
				"lng": -122.428378,
				"zipcode": "94066",
				"subPremise": "",
				"address": "6204 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 510000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 417000,
			"daysOnMovoto": 74,
			"id": "3b786685-384e-4f5a-8028-a865929e2801",
			"tnImgPath": "https://pi.movoto.com/p/110/421583036_0_i3jain_p.jpeg",
			"listDate": "2021-08-02 00:00:00",
			"listingAgent": "Sabrina Kwok",
			"listPrice": 399000,
			"lotSize": 1679615,
			"sqftTotal": 549,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421583036",
			"bath": 1,
			"bed": null,
			"openHouses": [],
			"officeListName": "Sequoia Real Estate",
			"photoCount": 19,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/shelter-creek-ln-san-bruno-ca-94066-100_80713507/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8dc9df28-a9d1-4fc0-ac47-03dc3293f8ae",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-08-12 07:21:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619843,
				"lng": -122.428366,
				"zipcode": "94066",
				"subPremise": "",
				"address": "3154 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 417000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1457625,
			"daysOnMovoto": 14,
			"id": "98c374c0-a416-4ece-b157-319dcb29b74c",
			"tnImgPath": "https://pi.movoto.com/p/110/421600129_0_ARUEjf_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Tanya Dzhibrailova",
			"listPrice": 1295000,
			"lotSize": 5000,
			"sqftTotal": 1190,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421600129",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1960,
			"zipCode": "94066",
			"path": "san-bruno-ca/3260-geoffrey-dr-san-bruno-ca-94066/pid_h0t5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77a4fcf3-52bf-4f62-8f71-3e64c4d72d65",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-10-01 17:46:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": "https://www.3260geoffreydrive.com/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.635122,
				"lng": -122.460634,
				"zipcode": "94066",
				"subPremise": "",
				"address": "3260 Geoffrey Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1457625,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.3260geoffreydrive.com/mls"
		},
		{
			"closePrice": 1275000,
			"daysOnMovoto": 50,
			"id": "08d4da0b-3b51-40bd-96c9-5934e0f04f52",
			"tnImgPath": "https://pi.movoto.com/p/110/421591912_0_Eiq3nV_p.jpeg",
			"listDate": "2021-08-18 00:00:00",
			"listingAgent": "Florence Whitney",
			"listPrice": 1189000,
			"lotSize": 6188,
			"sqftTotal": 1080,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421591912",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1957,
			"zipCode": "94066",
			"path": "san-bruno-ca/2561-maywood-dr-san-bruno-ca-94066-100_81120205/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f8dadef0-ef85-4f22-92e6-705c83f1745f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-07 00:00:00",
			"createdAt": "2021-09-13 07:21:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 00:00:00",
			"virtualTourLink": "http://www.2561maywooddr.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.632641,
				"lng": -122.447041,
				"zipcode": "94066",
				"subPremise": "",
				"address": "2561 Maywood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.2561maywooddr.com/"
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 56,
			"id": "b93657db-7575-49c2-bfbb-067304e960ef",
			"tnImgPath": "https://pi.movoto.com/p/110/421599732_0_3E7nar_p.jpeg",
			"listDate": "2021-08-12 00:00:00",
			"listingAgent": "Shannon Cronan",
			"listPrice": 1095000,
			"lotSize": 5000,
			"sqftTotal": 1000,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421599732",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass SF",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1945,
			"zipCode": "94066",
			"path": "san-bruno-ca/740-linden-ave-san-bruno-ca-94066-100_80346704/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c24a7f92-bb23-4c9f-af69-9f00e5974bbc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-07 00:00:00",
			"createdAt": "2021-09-30 18:06:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-12 00:00:00",
			"virtualTourLink": "http://www.740Linden.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.627328,
				"lng": -122.416939,
				"zipcode": "94066",
				"subPremise": "",
				"address": "740 Linden Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.740Linden.com"
		},
		{
			"closePrice": 1801000,
			"daysOnMovoto": 81,
			"id": "87503bd4-c05b-4c7f-bc1f-6c07f6ce1c86",
			"tnImgPath": "https://pi.movoto.com/p/12/40959277_0_RNbrQZ_p.jpeg",
			"listDate": "2021-07-16 00:00:00",
			"listingAgent": "Dashna Mahmood",
			"listPrice": 1599888,
			"lotSize": 6664,
			"sqftTotal": 3210,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959277",
			"bath": 4.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Tri-Valley RE Group, Inc",
			"photoCount": 30,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1990,
			"zipCode": "94066",
			"path": "san-bruno-ca/240-san-felipe-ave-san-bruno-ca-94066/pid_7u25w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b0bcbbdd-eae9-4971-a1da-1f6cd624a3d9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-05 00:00:00",
			"createdAt": "2021-07-17 04:06:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-16 00:00:00",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.618888,
				"lng": -122.405761,
				"zipcode": "94066",
				"subPremise": "",
				"address": "240 San Felipe Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1801000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 1525000,
			"daysOnMovoto": 0,
			"id": "6d2e6505-969f-473e-b1ee-2cb1a2b54a19",
			"tnImgPath": "https://pi.movoto.com/p/102/421587249_0_qMjbyI_p.jpeg",
			"listDate": null,
			"listingAgent": "Willie Haidari",
			"listPrice": 1488000,
			"lotSize": 5194,
			"sqftTotal": 2460,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421587249",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Abella Organization",
			"photoCount": 15,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1963,
			"zipCode": "94066",
			"path": "san-bruno-ca/tulare-dr-san-bruno-ca-94066-100_950714/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8adf87ee-190a-403f-808e-d575ffb6d7c1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-01 00:00:00",
			"createdAt": "2021-08-20 19:49:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 19:49:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.622311,
				"lng": -122.450078,
				"zipcode": "94066",
				"subPremise": "",
				"address": "211 Tulare Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1525000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 35,
			"id": "aaf007fc-1f51-405b-b055-342f2b46b783",
			"tnImgPath": "https://pi.movoto.com/p/110/421587796_0_ZIQ2IR_p.jpeg",
			"listDate": "2021-08-25 00:00:00",
			"listingAgent": "Michael Soon",
			"listPrice": 900000,
			"lotSize": 176288,
			"sqftTotal": 1342,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421587796",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1979,
			"zipCode": "94066",
			"path": "san-bruno-ca/1031-cherry-ave-6-san-bruno-ca-94066/pid_0h48w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1e800b0e-1a76-4cee-8cab-455723a54acf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-29 00:00:00",
			"createdAt": "2021-08-26 20:41:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-25 00:00:00",
			"virtualTourLink": "http://www.1031CherryAve6.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.630209,
				"lng": -122.428338,
				"zipcode": "94066",
				"subPremise": "APT 6",
				"address": "1031 Cherry Ave #6"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.1031CherryAve6.com"
		},
		{
			"closePrice": 1310000,
			"daysOnMovoto": 47,
			"id": "a88d64d0-a153-4e90-8479-f79e94807858",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81857795_0_fF6zJ6_p.jpeg",
			"listDate": "2021-08-11 00:00:37",
			"listingAgent": "Vic Aenlle,CCIM",
			"listPrice": 1308000,
			"lotSize": 3772,
			"sqftTotal": 3600,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81857795",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 9,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1958,
			"zipCode": "94066",
			"path": "san-bruno-ca/510512-san-antonio-ave-san-bruno-ca-94066-100_ml81857795/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "46d01fd5-05cf-449a-9c66-b4a96e9de15d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-28 00:00:00",
			"createdAt": "2021-08-11 07:02:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-11 00:00:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"zipcode": "94066",
				"subPremise": "",
				"address": "510512 San Antonio Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1310000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 73,
			"id": "4be60b6b-02eb-4055-85c0-79f03c466ae5",
			"tnImgPath": "https://pi.movoto.com/p/110/421572324_0_IJrrYz_p.jpeg",
			"listDate": "2021-07-12 00:00:00",
			"listingAgent": "Ken Kwok",
			"listPrice": 1095000,
			"lotSize": 5133,
			"sqftTotal": 1160,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421572324",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "MaxReal",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1943,
			"zipCode": "94066",
			"path": "san-bruno-ca/632-6th-ave-san-bruno-ca-94066/pid_4mx5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 107000,
			"propertyId": "a230610c-ad21-4add-8b64-72f409df92c9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-23 00:00:00",
			"createdAt": "2021-07-12 18:01:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.628815,
				"lng": -122.405424,
				"zipcode": "94066",
				"subPremise": "",
				"address": "632 6th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1025000,
			"daysOnMovoto": 48,
			"id": "3333f66b-0b7c-4982-8c2a-c94b75b2cd62",
			"tnImgPath": "https://pi.movoto.com/p/12/40961761_0_RmmEza_p.jpeg",
			"listDate": "2021-08-04 00:00:00",
			"listingAgent": "Maya Hiersoux",
			"listPrice": 929000,
			"lotSize": 3800,
			"sqftTotal": 1110,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40961761",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHG RE RELIANCE PARTNERS",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1948,
			"zipCode": "94066",
			"path": "san-bruno-ca/645-6th-ave-san-bruno-ca-94066/pid_iox5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4e8fdfa9-e83e-4538-9f62-15c9790fcf91",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-21 00:00:00",
			"createdAt": "2021-08-05 01:21:52",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-04 00:00:00",
			"virtualTourLink": "http://www.645-6thave.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.628571,
				"lng": -122.405734,
				"zipcode": "94066",
				"subPremise": "",
				"address": "645 6th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1025000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.645-6thave.com/?mls"
		},
		{
			"closePrice": 930000,
			"daysOnMovoto": 64,
			"id": "4662d89b-14d4-4c8f-b2de-c880827f1dff",
			"tnImgPath": "https://pi.movoto.com/p/110/421573691_0_ve623N_p.jpeg",
			"listDate": "2021-07-14 00:00:00",
			"listingAgent": "Tyson Li",
			"listPrice": 980000,
			"lotSize": 3700,
			"sqftTotal": 780,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421573691",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1940,
			"zipCode": "94066",
			"path": "san-bruno-ca/714-3rd-ave-san-bruno-ca-94066-100_815948/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 81000,
			"propertyId": "a8bc1161-a5b0-470a-93ce-924b60904d3d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-16 00:00:00",
			"createdAt": "2021-07-15 14:26:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-14 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=unN1H6Ut6HE",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.629216,
				"lng": -122.408481,
				"zipcode": "94066",
				"subPremise": "",
				"address": "714 3rd Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=unN1H6Ut6HE"
		},
		{
			"closePrice": 670000,
			"daysOnMovoto": 18,
			"id": "0bc2a49d-2c56-4294-aa20-8c0d6c328091",
			"tnImgPath": "https://pi.movoto.com/p/110/421575538_0_Ffmyyi_p.jpeg",
			"listDate": "2021-08-26 00:00:00",
			"listingAgent": "Wayne Ho",
			"listPrice": 649000,
			"lotSize": 177208,
			"sqftTotal": 816,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421575538",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Trust Real Estate",
			"photoCount": 3,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94066",
			"path": "san-bruno-ca/413-piccadilly-pl-4-san-bruno-ca-94066-100_81203148/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "56e506d7-4f7b-4d27-a5c4-e2fa3333fb9d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-13 00:00:00",
			"createdAt": "2021-08-26 14:11:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.630781,
				"lng": -122.431959,
				"zipcode": "94066",
				"subPremise": "APT 4",
				"address": "413 Piccadilly Pl #4"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 670000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 735000,
			"daysOnMovoto": 88,
			"id": "bd6bce16-d6af-4985-b1fb-c6fde8de9a41",
			"tnImgPath": "https://pi.movoto.com/p/12/40954178_0_ny32Eq_p.jpeg",
			"listDate": "2021-06-13 00:00:00",
			"listingAgent": "Jessica Medina",
			"listPrice": 740000,
			"lotSize": 2500,
			"sqftTotal": 730,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954178",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Village Properties & Assoc.",
			"photoCount": 13,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1940,
			"zipCode": "94066",
			"path": "san-bruno-ca/620-4th-ave-san-bruno-ca-94066/pid_5yx5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 22000,
			"propertyId": "b63badf7-58e2-446b-8ae5-88c518375052",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-09 00:00:00",
			"createdAt": "2021-06-14 00:16:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.62775,
				"lng": -122.406707,
				"zipcode": "94066",
				"subPremise": "",
				"address": "620 4th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 735000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 522000,
			"daysOnMovoto": 7,
			"id": "ba9b5f2a-b346-4a0e-9ca2-416b6a778d1a",
			"tnImgPath": "https://pi.movoto.com/p/110/421589444_0_jebANI_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Elena Wyman",
			"listPrice": 510000,
			"lotSize": 1679615,
			"sqftTotal": 680,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421589444",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Emerald Key Realty",
			"photoCount": 20,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/3138-shelter-creek-ln-san-bruno-ca-94066/pid_yc68w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "79aceb83-df53-4430-83c8-d2acc0280fa6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-03 00:00:00",
			"createdAt": "2021-08-27 21:51:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619811,
				"lng": -122.428382,
				"zipcode": "94066",
				"subPremise": "",
				"address": "3138 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 522000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 400000,
			"daysOnMovoto": 16,
			"id": "a49621e9-a9ad-4ba4-b148-64e4d0cdcd31",
			"tnImgPath": "https://pi.movoto.com/p/110/421585055_0_UjMMbq_p.jpeg",
			"listDate": "2021-08-13 00:00:00",
			"listingAgent": "Patrick Shannon",
			"listPrice": 388888,
			"lotSize": 0,
			"sqftTotal": 490,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421585055",
			"bath": 1,
			"bed": null,
			"openHouses": [],
			"officeListName": "Trinity Realty, Inc.",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": null,
			"zipCode": "94066",
			"path": "san-bruno-ca/7222-shelter-creek-ln-san-bruno-ca-94066/pid_bw88w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5e2b9bb8-6b8b-4086-9152-2091cf8aecfe",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-29 00:00:00",
			"createdAt": "2021-08-14 00:01:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619843,
				"lng": -122.428366,
				"zipcode": "94066",
				"subPremise": "",
				"address": "7222 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 6,
			"id": "b7f45345-1d64-475d-849b-d993a7f40123",
			"tnImgPath": "https://pi.movoto.com/p/110/421587130_0_vv6qFM_p.jpeg",
			"listDate": "2021-08-20 00:00:00",
			"listingAgent": "Wan-Jen Chen",
			"listPrice": 1288000,
			"lotSize": 8850,
			"sqftTotal": 1540,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421587130",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Comax Realty, Inc.",
			"photoCount": 17,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1959,
			"zipCode": "94066",
			"path": "san-bruno-ca/2000-evergreen-dr-san-bruno-ca-94066/pid_y7r5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7887e438-ce75-4d1d-a13b-4a02011cf4d8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-26 00:00:00",
			"createdAt": "2021-08-20 20:11:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.633398,
				"lng": -122.445021,
				"zipcode": "94066",
				"subPremise": "",
				"address": "2000 Evergreen Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 480000,
			"daysOnMovoto": 5,
			"id": "c79c1caa-ce01-430f-8a46-abb92fd0a4bf",
			"tnImgPath": "https://pi.movoto.com/p/101/421583426_0_zNV2m7_p.jpeg",
			"listDate": "2021-08-16 00:00:00",
			"listingAgent": "Shirley Barklow",
			"listPrice": 495000,
			"lotSize": 147962,
			"sqftTotal": 650,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "421583426",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Century 21 Alliance",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94066",
			"path": "san-bruno-ca/105-piccadilly-pl-a-san-bruno-ca-94066/pid_mv48w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "71ba786c-b125-4935-8ae2-3150398bc2a2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-21 00:00:00",
			"createdAt": "2021-08-16 08:33:09",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.632235,
				"lng": -122.429778,
				"zipcode": "94066",
				"subPremise": "APT A",
				"address": "105 Piccadilly Pl #A"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 480000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 15,
			"id": "68acd4c3-1233-48e6-96a8-d5aab9dac6f0",
			"tnImgPath": "https://pi.movoto.com/p/110/421580691_0_mMB7AQ_p.jpeg",
			"listDate": "2021-08-02 00:00:00",
			"listingAgent": "Debbie Lamica",
			"listPrice": 1100000,
			"lotSize": 5000,
			"sqftTotal": 2080,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421580691",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Star Properties",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1928,
			"zipCode": "94066",
			"path": "san-bruno-ca/552-4th-ave-san-bruno-ca-94066-100_80510083/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "965bae33-a26a-402d-906e-51f39bfb58a5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-17 00:00:00",
			"createdAt": "2021-08-04 05:31:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-02 00:00:00",
			"virtualTourLink": "http://www.552-4thAve.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.626286,
				"lng": -122.405808,
				"zipcode": "94066",
				"subPremise": "",
				"address": "552 4th Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.552-4thAve.com"
		},
		{
			"closePrice": 1280000,
			"daysOnMovoto": 40,
			"id": "ae0c0720-a762-4aa4-ab2a-3a54663a3060",
			"tnImgPath": "https://pi.movoto.com/p/12/40957758_0_Rr7RnJ_p.jpeg",
			"listDate": "2021-07-08 00:00:00",
			"listingAgent": "Albert Garibaldi",
			"listPrice": 1099950,
			"lotSize": 5700,
			"sqftTotal": 1190,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40957758",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "The Agency",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "94066",
			"path": "san-bruno-ca/3311-fasman-dr-san-bruno-ca-94066/pid_juu5w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4cc326a1-e1f1-4a57-add5-f2851a94c743",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-17 00:00:00",
			"createdAt": "2021-07-08 17:31:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-08 00:00:00",
			"virtualTourLink": "https://listing.mercadovisualz.com/ut/3311_Fasman_Dr.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.63466,
				"lng": -122.462138,
				"zipcode": "94066",
				"subPremise": "",
				"address": "3311 Fasman Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1280000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listing.mercadovisualz.com/ut/3311_Fasman_Dr.html"
		},
		{
			"closePrice": 690000,
			"daysOnMovoto": 103,
			"id": "074f2ca2-2e6c-430f-b3a4-f43b3ff013a8",
			"tnImgPath": "https://pi.movoto.com/p/110/421545513_0_zebvQU_p.jpeg",
			"listDate": "2021-05-05 00:00:00",
			"listingAgent": "Sam Chong",
			"listPrice": 699000,
			"lotSize": 1679615,
			"sqftTotal": 1090,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421545513",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Global Realty",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/2141-shelter-creek-ln-apt-2141-san-bruno-ca-94066-110_421545513/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "edd9c6be-e9e9-4340-824c-2c258fbad434",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-16 00:00:00",
			"createdAt": "2021-05-06 16:26:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619843,
				"lng": -122.428366,
				"zipcode": "94066",
				"subPremise": "APT 2141",
				"address": "2141 Shelter Creek Ln #2141"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 690000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 415000,
			"daysOnMovoto": 68,
			"id": "bd8c8900-2c56-47b1-9bbf-a95589d07344",
			"tnImgPath": "https://pi.movoto.com/p/110/421571268_0_q6JJMV_p.jpeg",
			"listDate": "2021-06-04 00:00:00",
			"listingAgent": "Ellie Kravets",
			"listPrice": 398000,
			"lotSize": 1679615,
			"sqftTotal": 490,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421571268",
			"bath": 1,
			"bed": null,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/3211-shelter-creek-ln-san-bruno-ca-94066-100_81301910/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f5d6b207-a40b-444a-a573-72338aaba345",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-11 00:00:00",
			"createdAt": "2021-07-15 16:46:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-04 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=XqzZfutXhFY",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619843,
				"lng": -122.428366,
				"zipcode": "94066",
				"subPremise": "",
				"address": "3211 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 415000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=XqzZfutXhFY"
		},
		{
			"closePrice": 562000,
			"daysOnMovoto": 100,
			"id": "a529c56b-e1ff-4b47-a463-4600e44b03b4",
			"tnImgPath": "https://pi.movoto.com/p/110/421579168_0_N6ayYB_p.jpeg",
			"listDate": "2021-04-28 00:00:00",
			"listingAgent": "Kenneth Go",
			"listPrice": 520000,
			"lotSize": 1679615,
			"sqftTotal": 680,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421579168",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/8329-shelter-creek-ln-san-bruno-ca-94066-100_80535765/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b1026d28-5c9e-4a08-bbe3-712522cadbef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-06 00:00:00",
			"createdAt": "2021-07-28 19:16:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619843,
				"lng": -122.428366,
				"zipcode": "94066",
				"subPremise": "",
				"address": "8329 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 562000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 530000,
			"daysOnMovoto": 43,
			"id": "1ed5dee6-a346-4568-82b2-da2a8a81f27c",
			"tnImgPath": "https://pi.movoto.com/p/12/40952159_0_6ifAVZ_p.jpeg",
			"listDate": "2021-06-10 00:00:00",
			"listingAgent": "Helen Zang",
			"listPrice": 514000,
			"lotSize": 0,
			"sqftTotal": 650,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40952159",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94066",
			"path": "san-bruno-ca/243-boardwalk-ave-b-san-bruno-ca-94066-100_81318708/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a25590a3-0f85-41e1-85ed-00262fb932a0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-23 00:00:00",
			"createdAt": "2021-06-11 03:51:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.6313,
				"lng": -122.429704,
				"zipcode": "94066",
				"subPremise": "APT B",
				"address": "243 Boardwalk Ave #B"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 530000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 400000,
			"daysOnMovoto": 56,
			"id": "61470fba-6d98-4e32-94bd-02d11a869c14",
			"tnImgPath": "https://pi.movoto.com/p/110/421556248_0_ezMZNV_p.jpeg",
			"listDate": "2021-05-25 00:00:00",
			"listingAgent": "Thomas Nguyen",
			"listPrice": 388000,
			"lotSize": 0,
			"sqftTotal": 490,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421556248",
			"bath": 1,
			"bed": null,
			"openHouses": [],
			"officeListName": "RE/MAX UP Real Estate",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/2110-shelter-creek-ln-apt-110-san-bruno-ca-94066-110_421556248/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "978c5f50-9062-44ba-bd1e-8782804e475d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-20 00:00:00",
			"createdAt": "2021-06-15 19:01:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619843,
				"lng": -122.428366,
				"zipcode": "94066",
				"subPremise": "APT 110",
				"address": "2110 Shelter Creek Ln #110"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 525000,
			"daysOnMovoto": 4,
			"id": "eeee6810-f21f-4d3d-b354-c7ac39c928b6",
			"tnImgPath": "https://pi.movoto.com/p/110/421574053_0_ZR7ZJj_p.jpeg",
			"listDate": "2021-07-15 00:00:00",
			"listingAgent": "Richard Lee",
			"listPrice": 505000,
			"lotSize": 1679615,
			"sqftTotal": 680,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421574053",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "DLT Happie Realty",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/2255-shelter-creek-ln-san-bruno-ca-94066/pid_bd58w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "50ca315b-380c-4add-aded-5744d87b507c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-19 00:00:00",
			"createdAt": "2021-07-15 11:26:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619843,
				"lng": -122.428366,
				"zipcode": "94066",
				"subPremise": "",
				"address": "2255 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 525000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 558168,
			"daysOnMovoto": 7,
			"id": "955a5610-50bc-4423-be90-5ac93c05762f",
			"tnImgPath": "https://pi.movoto.com/p/110/421570466_0_z76E3q_p.jpeg",
			"listDate": "2021-07-06 00:00:00",
			"listingAgent": "Sabrina Kwok",
			"listPrice": 515000,
			"lotSize": 1679615,
			"sqftTotal": 680,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421570466",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Sequoia Real Estate",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": null,
			"zipCode": "94066",
			"path": "san-bruno-ca/6306-shelter-creek-ln-san-bruno-ca-94066/pid_sn78w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "845b5b1c-90be-4c6e-b505-323a5f6267fb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-13 00:00:00",
			"createdAt": "2021-07-08 07:26:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619843,
				"lng": -122.428366,
				"zipcode": "94066",
				"subPremise": "",
				"address": "6306 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 558168,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 675000,
			"daysOnMovoto": 41,
			"id": "41a32fd6-515d-4ec4-b388-2430ba9426b7",
			"tnImgPath": "https://pi.movoto.com/p/110/421570217_0_VjNRY2_p.jpeg",
			"listDate": "2021-06-01 00:00:00",
			"listingAgent": "Arthur Zendarski",
			"listPrice": 619000,
			"lotSize": 177208,
			"sqftTotal": 816,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421570217",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Corcoran Global Living",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94066",
			"path": "san-bruno-ca/415-piccadilly-pl-1-san-bruno-ca-94066/pid_qe98w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9a942a1f-03c8-4805-a566-dd82beef0b36",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-12 00:00:00",
			"createdAt": "2021-07-09 14:36:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-01 00:00:00",
			"virtualTourLink": "http://www.415picadillyplaceunit1.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.630313,
				"lng": -122.431735,
				"zipcode": "94066",
				"subPremise": "APT 1",
				"address": "415 Piccadilly Pl #1"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.415picadillyplaceunit1.com/"
		},
		{
			"closePrice": 530000,
			"daysOnMovoto": 24,
			"id": "e5a7b6b7-5d93-47ab-a723-d18692ce9045",
			"tnImgPath": "https://pi.movoto.com/p/110/421567267_0_rNnieI_p.jpeg",
			"listDate": "2021-06-13 00:00:00",
			"listingAgent": "Karen Wong-Dunlap",
			"listPrice": 469000,
			"lotSize": 147962,
			"sqftTotal": 540,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421567267",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Keller Williams SF Monterey",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94066",
			"path": "san-bruno-ca/107-piccadilly-pl-b-san-bruno-ca-94066/pid_l048w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eb3dfdc6-e35b-443f-b75d-dad91e6ab33d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-07 00:00:00",
			"createdAt": "2021-06-25 08:51:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-13 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=43GJfA4KdZe&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.632235,
				"lng": -122.429778,
				"zipcode": "94066",
				"subPremise": "APT B",
				"address": "107 Piccadilly Pl #B"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 530000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=43GJfA4KdZe&brand=0"
		},
		{
			"closePrice": 410000,
			"daysOnMovoto": 103,
			"id": "f3c623df-0bd8-4f26-9870-29c3a4c3597e",
			"tnImgPath": "https://pi.movoto.com/p/110/421555253_0_Q6jVBI_p.jpeg",
			"listDate": "2021-03-26 00:00:00",
			"listingAgent": "Toby Jackson",
			"listPrice": 399000,
			"lotSize": 122730,
			"sqftTotal": 450,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421555253",
			"bath": 1,
			"bed": null,
			"openHouses": [],
			"officeListName": "BarbCo",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94066",
			"path": "san-bruno-ca/217-boardwalk-dr-apt-b-san-bruno-ca-94066-110_421555253/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7520a692-df1a-418d-a71a-c6ef9f9bbfed",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-07 00:00:00",
			"createdAt": "2021-05-21 22:56:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.630798,
				"lng": -122.428736,
				"zipcode": "94066",
				"subPremise": "APT B",
				"address": "217 Boardwalk Dr #B"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 410000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1125000,
			"daysOnMovoto": 14,
			"id": "beb58e96-cd4b-4ef4-8d30-92f7ad0cf06f",
			"tnImgPath": "https://pi.movoto.com/p/110/421565167_0_BqjRef_p.jpeg",
			"listDate": "2021-06-18 00:00:00",
			"listingAgent": "Andrew de Vries",
			"listPrice": 849000,
			"lotSize": 6750,
			"sqftTotal": 1760,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421565167",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway-Franciscan",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1931,
			"zipCode": "94066",
			"path": "san-bruno-ca/172-san-felipe-ave-san-bruno-ca-94066/pid_oy25w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5984bba0-98e8-45c5-a862-679be26ac639",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-02 00:00:00",
			"createdAt": "2021-06-18 16:31:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-18 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=JdMY4Bgcj5H&",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.618376,
				"lng": -122.407106,
				"zipcode": "94066",
				"subPremise": "",
				"address": "172 San Felipe Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1125000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=JdMY4Bgcj5H&"
		},
		{
			"closePrice": 705000,
			"daysOnMovoto": 29,
			"id": "c32eb63a-c18c-48a1-bca5-05980319db1b",
			"tnImgPath": "https://pi.movoto.com/p/110/421561616_0_bbU6YI_p.jpeg",
			"listDate": "2021-06-02 00:00:00",
			"listingAgent": "Angelo Cosentino",
			"listPrice": 649000,
			"lotSize": 177208,
			"sqftTotal": 816,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421561616",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass SF",
			"photoCount": 42,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1971,
			"zipCode": "94066",
			"path": "san-bruno-ca/413-piccadilly-pl-14-san-bruno-ca-94066-100_89821849/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6d3fa551-9a23-4050-b2a4-997928845df2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-01 00:00:00",
			"createdAt": "2021-06-11 16:56:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-02 00:00:00",
			"virtualTourLink": "Http://www.413piccadilly.com/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.630781,
				"lng": -122.431959,
				"zipcode": "94066",
				"subPremise": "APT 14",
				"address": "413 Piccadilly Pl #14"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 705000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.413piccadilly.com/unbranded"
		},
		{
			"closePrice": 405000,
			"daysOnMovoto": 42,
			"id": "d9043491-3729-4f85-acbe-73ed6ac39c61",
			"tnImgPath": "https://pi.movoto.com/p/110/421548833_0_mQ2zEv_p.jpeg",
			"listDate": "2021-05-14 00:00:00",
			"listingAgent": "Bing Ting Ren",
			"listPrice": 408000,
			"lotSize": 1679615,
			"sqftTotal": 490,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421548833",
			"bath": 1,
			"bed": null,
			"openHouses": [],
			"officeListName": "Norcal Realty",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94066",
			"path": "san-bruno-ca/6146-shelter-creek-ln-san-bruno-ca-94066/pid_vv68w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "d6c355b0-c2ff-4f3c-8f00-74c4c2167a67",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-25 00:00:00",
			"createdAt": "2021-05-14 17:53:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "San Bruno",
				"lat": 37.619779,
				"lng": -122.428399,
				"zipcode": "94066",
				"subPremise": "",
				"address": "6146 Shelter Creek Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 405000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]