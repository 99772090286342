exports.UNION_CITY_SCHOOLS = [
    {
        "id": 15377,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.587746,
        "lon": -122.066971,
        "name": "Adventure Montessori Academy",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "4101 Pleiades Place",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/15377-Adventure-Montessori-Academy/",
            "reviews": "/california/union-city/15377-Adventure-Montessori-Academy/#Reviews",
            "collegeSuccess": "/california/union-city/15377-Adventure-Montessori-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 48,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 20
            },
            {
                "label": "Hispanic",
                "percentage": 20
            },
            {
                "label": "White",
                "percentage": 20
            },
            {
                "label": "African American",
                "percentage": 20
            },
            {
                "label": "Asian",
                "percentage": 20
            }
        ],
        "remediationData": []
    },
    {
        "id": 23360,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.587746,
        "lon": -122.066971,
        "name": "Adventure Montessori Academy",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "4101 Pleiades Place",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/23360-Adventure-Montessori-Academy/",
            "reviews": "/california/union-city/23360-Adventure-Montessori-Academy/#Reviews",
            "collegeSuccess": "/california/union-city/23360-Adventure-Montessori-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 3,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 161,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "e",
        "lat": 37.595623,
        "lon": -122.076927,
        "name": "Alvarado Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "31100 Fredi Street",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/161-Alvarado-Elementary-School/",
            "reviews": "/california/union-city/161-Alvarado-Elementary-School/#Reviews",
            "collegeSuccess": "/california/union-city/161-Alvarado-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "49%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 726,
        "parentRating": 3,
        "numReviews": 18,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 49
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 23
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 22
            },
            {
                "label": "White",
                "rating": 7,
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 6
            },
            {
                "label": "Filipino",
                "rating": 8,
                "percentage": 36
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 162,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "m",
        "lat": 37.591747,
        "lon": -122.08036,
        "name": "Alvarado Middle School",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "31604 Alvarado Boulevard",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/162-Alvarado-Middle-School/",
            "reviews": "/california/union-city/162-Alvarado-Middle-School/#Reviews",
            "collegeSuccess": "/california/union-city/162-Alvarado-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "39%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1396,
        "parentRating": 3,
        "numReviews": 38,
        "studentsPerTeacher": 26,
        "subratings": {
            "Test Scores Rating": 6,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 39
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Asian",
                "rating": 8,
                "percentage": 31
            },
            {
                "label": "African American",
                "rating": 2,
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 22
            },
            {
                "label": "Pacific Islander",
                "rating": 3,
                "percentage": 2
            },
            {
                "label": "White",
                "rating": 7,
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "rating": 7,
                "percentage": 6
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 26
            }
        ],
        "remediationData": []
    },
    {
        "id": 165,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "m",
        "lat": 37.590191,
        "lon": -122.047348,
        "name": "Cesar Chavez Middle School",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "2801 Hop Ranch Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/165-Cesar-Chavez-Middle-School/",
            "reviews": "/california/union-city/165-Cesar-Chavez-Middle-School/#Reviews",
            "collegeSuccess": "/california/union-city/165-Cesar-Chavez-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "59%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1210,
        "parentRating": 3,
        "numReviews": 10,
        "studentsPerTeacher": 34,
        "subratings": {
            "Test Scores Rating": 4,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 2,
                "percentage": 59
            },
            {
                "label": "All students",
                "rating": 4
            },
            {
                "label": "Asian",
                "rating": 7,
                "percentage": 12
            },
            {
                "label": "African American",
                "rating": 1,
                "percentage": 5
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 59
            },
            {
                "label": "Pacific Islander",
                "rating": 2,
                "percentage": 3
            },
            {
                "label": "White",
                "rating": 5,
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "rating": 5,
                "percentage": 4
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 10
            }
        ],
        "remediationData": []
    },
    {
        "id": 30200,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.59367,
        "lon": -122.044495,
        "name": "Cfcs Head Start-Glanker",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "32980 Alvarado-Niles Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/30200-Cfcs-Head-Start-Glanker/",
            "reviews": "/california/union-city/30200-Cfcs-Head-Start-Glanker/#Reviews",
            "collegeSuccess": "/california/union-city/30200-Cfcs-Head-Start-Glanker/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 30467,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.59367,
        "lon": -122.044495,
        "name": "Cfcs Head Start-Madison",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "32980 Alvarado-Niles Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/30467-Cfcs-Head-Start-Madison/",
            "reviews": "/california/union-city/30467-Cfcs-Head-Start-Madison/#Reviews",
            "collegeSuccess": "/california/union-city/30467-Cfcs-Head-Start-Madison/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 29316,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.59367,
        "lon": -122.044495,
        "name": "Decoto Head Start",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "32980 Alvarado-Niles Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/29316-Decoto-Head-Start/",
            "reviews": "/california/union-city/29316-Decoto-Head-Start/#Reviews",
            "collegeSuccess": "/california/union-city/29316-Decoto-Head-Start/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 26956,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "e,m,h",
        "lat": 37.606995,
        "lon": -122.0271,
        "name": "Decoto School For Independent Study",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "725 Whipple Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/26956-Decoto-School-For-Independent-Study/",
            "reviews": "/california/union-city/26956-Decoto-School-For-Independent-Study/#Reviews",
            "collegeSuccess": "/california/union-city/26956-Decoto-School-For-Independent-Study/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "47%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "52%",
            "state_average": "64%"
        },
        "enrollment": 121,
        "parentRating": 0,
        "numReviews": 0,
        "studentsPerTeacher": 19,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 47
            },
            {
                "label": "Hispanic",
                "percentage": 63
            },
            {
                "label": "White",
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Asian",
                "percentage": 7
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": []
    },
    {
        "id": 11904,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "e",
        "lat": 37.566837,
        "lon": -122.074829,
        "name": "Delaine Eastin Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "34901 Eastin Drive",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/11904-Delaine-Eastin-Elementary-School/",
            "reviews": "/california/union-city/11904-Delaine-Eastin-Elementary-School/#Reviews",
            "collegeSuccess": "/california/union-city/11904-Delaine-Eastin-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "21%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 765,
        "parentRating": 4,
        "numReviews": 76,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 21
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 49
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 15
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 7
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 17
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 23358,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.592548,
        "lon": -122.002373,
        "name": "Free To Be Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "188 Appian Way",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/23358-Free-To-Be-Pre-School/",
            "reviews": "/california/union-city/23358-Free-To-Be-Pre-School/#Reviews",
            "collegeSuccess": "/california/union-city/23358-Free-To-Be-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28726,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.59367,
        "lon": -122.044495,
        "name": "Glassbrook Center Head Start",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "32980 Alvarado-Niles Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/28726-Glassbrook-Center-Head-Start/",
            "reviews": "/california/union-city/28726-Glassbrook-Center-Head-Start/#Reviews",
            "collegeSuccess": "/california/union-city/28726-Glassbrook-Center-Head-Start/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 11683,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "e",
        "lat": 37.602016,
        "lon": -122.015953,
        "name": "Guy Jr. Emanuele Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "100 Decoto Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": 5,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/11683-Guy-Jr.-Emanuele-Elementary-School/",
            "reviews": "/california/union-city/11683-Guy-Jr.-Emanuele-Elementary-School/#Reviews",
            "collegeSuccess": "/california/union-city/11683-Guy-Jr.-Emanuele-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "59%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 569,
        "parentRating": 4,
        "numReviews": 27,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 6,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 59
            },
            {
                "label": "All students",
                "rating": 6
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 19
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 51
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 12
            },
            {
                "label": "White",
                "percentage": 8
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 28940,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.58725,
        "lon": -122.027039,
        "name": "Holly Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "34009 Alvarado-Niles Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/28940-Holly-Pre-School/",
            "reviews": "/california/union-city/28940-Holly-Pre-School/#Reviews",
            "collegeSuccess": "/california/union-city/28940-Holly-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 170,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "h",
        "lat": 37.592495,
        "lon": -122.026039,
        "name": "James Logan high school",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "1800 H Street",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/170-James-Logan-High-School/",
            "reviews": "/california/union-city/170-James-Logan-High-School/#Reviews",
            "collegeSuccess": "/california/union-city/170-James-Logan-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "44%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "79%",
            "state_average": "64%"
        },
        "enrollment": 3635,
        "parentRating": 4,
        "numReviews": 62,
        "studentsPerTeacher": 25,
        "subratings": {
            "Test Scores Rating": 9,
            "College Readiness Rating": 8,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 44
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 25
            },
            {
                "label": "African American",
                "rating": 5,
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 34
            },
            {
                "label": "Pacific Islander",
                "rating": 8,
                "percentage": 2
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 5
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 5
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 23
            }
        ],
        "remediationData": []
    },
    {
        "id": 29317,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.59367,
        "lon": -122.044495,
        "name": "Jefferson Head Start",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "32980 Alvarado-Niles Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/29317-Jefferson-Head-Start/",
            "reviews": "/california/union-city/29317-Jefferson-Head-Start/#Reviews",
            "collegeSuccess": "/california/union-city/29317-Jefferson-Head-Start/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 28636,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.591068,
        "lon": -122.029198,
        "name": "Kidango Cdc-Logan",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "33821 Syracuse Avenue",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/28636-Kidango-Cdc-Logan/",
            "reviews": "/california/union-city/28636-Kidango-Cdc-Logan/#Reviews",
            "collegeSuccess": "/california/union-city/28636-Kidango-Cdc-Logan/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 23359,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.591373,
        "lon": -122.034569,
        "name": "Little People Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "33700 Alvarado-Niles Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/23359-Little-People-Pre-School/",
            "reviews": "/california/union-city/23359-Little-People-Pre-School/#Reviews",
            "collegeSuccess": "/california/union-city/23359-Little-People-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 25512,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e,m",
        "lat": 37.607975,
        "lon": -122.027077,
        "name": "Mission Hills Middle School",
        "gradeLevels": "PK-8",
        "assigned": null,
        "address": {
            "street1": "250 Tamarack Drive",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/25512-Mission-Hills-Middle-School/",
            "reviews": "/california/union-city/25512-Mission-Hills-Middle-School/#Reviews",
            "collegeSuccess": "/california/union-city/25512-Mission-Hills-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 116,
        "parentRating": 4,
        "numReviews": 31,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 14286,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "e,m,h",
        "lat": 37.589931,
        "lon": -122.026863,
        "name": "New Haven Adult",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "1800 H Street",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/14286-New-Haven-Adult/",
            "reviews": "/california/union-city/14286-New-Haven-Adult/#Reviews",
            "collegeSuccess": "/california/union-city/14286-New-Haven-Adult/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 25851,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.607185,
        "lon": -122.027077,
        "name": "Northstar School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "725 Whipple Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/25851-Northstar-School/",
            "reviews": "/california/union-city/25851-Northstar-School/#Reviews",
            "collegeSuccess": "/california/union-city/25851-Northstar-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 126,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 8221,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.604591,
        "lon": -122.025185,
        "name": "Our Lady Of The Rosary",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "678 B Street",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/8221-Our-Lady-Of-The-Rosary/",
            "reviews": "/california/union-city/8221-Our-Lady-Of-The-Rosary/#Reviews",
            "collegeSuccess": "/california/union-city/8221-Our-Lady-Of-The-Rosary/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 129,
        "parentRating": 5,
        "numReviews": 14,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 66
            },
            {
                "label": "Asian",
                "percentage": 23
            },
            {
                "label": "Two or more races",
                "percentage": 8
            },
            {
                "label": "White",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 171,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "e",
        "lat": 37.572075,
        "lon": -122.071671,
        "name": "Pioneer Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "32737 Bel Aire Street",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/171-Pioneer-Elementary-School/",
            "reviews": "/california/union-city/171-Pioneer-Elementary-School/#Reviews",
            "collegeSuccess": "/california/union-city/171-Pioneer-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "35%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 750,
        "parentRating": 4,
        "numReviews": 41,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 7,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 35
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 36
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 15
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 9
            },
            {
                "label": "Two or more races",
                "rating": 8,
                "percentage": 7
            },
            {
                "label": "Filipino",
                "rating": 7,
                "percentage": 27
            },
            {
                "label": "African American",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 11481,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.59959,
        "lon": -122.022667,
        "name": "Purple Lotus Buddhist School",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "33615 9th Street",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/11481-Purple-Lotus-Buddhist-School/",
            "reviews": "/california/union-city/11481-Purple-Lotus-Buddhist-School/#Reviews",
            "collegeSuccess": "/california/union-city/11481-Purple-Lotus-Buddhist-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 25,
        "parentRating": 4,
        "numReviews": 11,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 96
            },
            {
                "label": "Two or more races",
                "percentage": 4
            }
        ],
        "remediationData": []
    },
    {
        "id": 29965,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.577778,
        "lon": -122.063293,
        "name": "Safari Kid Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4497 Niland Street",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/29965-Safari-Kid-Day-Care/",
            "reviews": "/california/union-city/29965-Safari-Kid-Day-Care/#Reviews",
            "collegeSuccess": "/california/union-city/29965-Safari-Kid-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 172,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "e",
        "lat": 37.596062,
        "lon": -122.027168,
        "name": "Searles Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "33629 15th Street",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": 4,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/172-Searles-Elementary-School/",
            "reviews": "/california/union-city/172-Searles-Elementary-School/#Reviews",
            "collegeSuccess": "/california/union-city/172-Searles-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "50%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 662,
        "parentRating": 4,
        "numReviews": 18,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 4,
            "Academic Progress Rating": 5,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 3,
                "percentage": 50
            },
            {
                "label": "All students",
                "rating": 4
            },
            {
                "label": "Hispanic",
                "rating": 3,
                "percentage": 65
            },
            {
                "label": "Asian",
                "percentage": 11
            },
            {
                "label": "Filipino",
                "percentage": 8
            },
            {
                "label": "White",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 26456,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.580189,
        "lon": -122.082901,
        "name": "Smiling Faces Home Childcare/Preschool",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "5641 Pacific Grove Way",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/26456-Smiling-Faces-Home-Childcare-Preschool/",
            "reviews": "/california/union-city/26456-Smiling-Faces-Home-Childcare-Preschool/#Reviews",
            "collegeSuccess": "/california/union-city/26456-Smiling-Faces-Home-Childcare-Preschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 23362,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.587555,
        "lon": -122.080727,
        "name": "So Alameda County HS-Decoto Plaza",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "East 500 St.",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/23362-So-Alameda-County-HS-Decoto-Plaza/",
            "reviews": "/california/union-city/23362-So-Alameda-County-HS-Decoto-Plaza/#Reviews",
            "collegeSuccess": "/california/union-city/23362-So-Alameda-County-HS-Decoto-Plaza/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 23361,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.602837,
        "lon": -122.021057,
        "name": "So Alameda County HS-Soto Plaza",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "500 E St",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/23361-So-Alameda-County-HS-Soto-Plaza/",
            "reviews": "/california/union-city/23361-So-Alameda-County-HS-Soto-Plaza/#Reviews",
            "collegeSuccess": "/california/union-city/23361-So-Alameda-County-HS-Soto-Plaza/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 15835,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.606098,
        "lon": -122.027298,
        "name": "Spectrum Center-Mission Valley Campus",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "725 Whipple Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/15835-Spectrum-Center-Mission-Valley-Campus/",
            "reviews": "/california/union-city/15835-Spectrum-Center-Mission-Valley-Campus/#Reviews",
            "collegeSuccess": "/california/union-city/15835-Spectrum-Center-Mission-Valley-Campus/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    },
    {
        "id": 169,
        "districtId": 12,
        "districtName": "New Haven Unified School District",
        "districtCity": "Union City",
        "levelCode": "e",
        "lat": 37.601467,
        "lon": -122.049789,
        "name": "Tom Kitayama Elementary School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "1959 Sunsprite Drive",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/169-Tom-Kitayama-Elementary-School/",
            "reviews": "/california/union-city/169-Tom-Kitayama-Elementary-School/#Reviews",
            "collegeSuccess": "/california/union-city/169-Tom-Kitayama-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "43%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 776,
        "parentRating": 5,
        "numReviews": 34,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 43
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 26
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 36
            },
            {
                "label": "White",
                "rating": 7,
                "percentage": 8
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 18
            },
            {
                "label": "Two or more races",
                "percentage": 7
            },
            {
                "label": "African American",
                "percentage": 3
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": []
    },
    {
        "id": 10548,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.591373,
        "lon": -122.034569,
        "name": "Union City Christian School",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "33700 Alvarado-Niles Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/10548-Union-City-Christian-School/",
            "reviews": "/california/union-city/10548-Union-City-Christian-School/#Reviews",
            "collegeSuccess": "/california/union-city/10548-Union-City-Christian-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 71,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 69
            },
            {
                "label": "African American",
                "percentage": 17
            },
            {
                "label": "Two or more races",
                "percentage": 9
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 6
            }
        ],
        "remediationData": []
    },
    {
        "id": 28725,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.59367,
        "lon": -122.044495,
        "name": "Whiteford Center Head Start",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "32980 Alvarado-Niles Road",
            "street2": "",
            "zip": "94587",
            "city": "Union City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94587",
        "type": "school",
        "links": {
            "profile": "/california/union-city/28725-Whiteford-Center-Head-Start/",
            "reviews": "/california/union-city/28725-Whiteford-Center-Head-Start/#Reviews",
            "collegeSuccess": "/california/union-city/28725-Whiteford-Center-Head-Start/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": []
    }
]