exports.CASTRO_VALLEY_SCHOOLS = [
    {
        "id": 18365,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.710037,
        "lon": -122.073692,
        "name": "A Kids Kingdom",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "18550 Redwood Road",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/18365-A-Kids-Kingdom/",
            "reviews": "/california/castro-valley/18365-A-Kids-Kingdom/#Reviews",
            "collegeSuccess": "/california/castro-valley/18365-A-Kids-Kingdom/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18368,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.698631,
        "lon": -122.081207,
        "name": "Abc Pre-School",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "20135 San Miguel Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/18368-Abc-Pre-School/",
            "reviews": "/california/castro-valley/18368-Abc-Pre-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/18368-Abc-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 24801,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e,m,h",
        "lat": 37.708199,
        "lon": -122.071983,
        "name": "Alma Preschool",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "4400 Alma Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/24801-Alma-Preschool/",
            "reviews": "/california/castro-valley/24801-Alma-Preschool/#Reviews",
            "collegeSuccess": "/california/castro-valley/24801-Alma-Preschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 52,
        "parentRating": 0,
        "numReviews": 0,
        "studentsPerTeacher": 10,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 31222,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.705799,
        "lon": -122.089264,
        "name": "Anchor Education, Inc.",
        "gradeLevels": "3-12",
        "assigned": null,
        "address": {
            "street1": "19234 Lake Chabot Road",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/31222-Anchor-Education-Inc./",
            "reviews": "/california/castro-valley/31222-Anchor-Education-Inc./#Reviews",
            "collegeSuccess": "/california/castro-valley/31222-Anchor-Education-Inc./#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 18,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 23779,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.705685,
        "lon": -122.089676,
        "name": "Anchor Education, Inc. School",
        "gradeLevels": "3-12",
        "assigned": null,
        "address": {
            "street1": "19234 Lake Chabot Road",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/23779-Anchor-Education-Inc.-School/",
            "reviews": "/california/castro-valley/23779-Anchor-Education-Inc.-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/23779-Anchor-Education-Inc.-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15777,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.692955,
        "lon": -122.08976,
        "name": "Bright World Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20613 Stanton Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/15777-Bright-World-Pre-School/",
            "reviews": "/california/castro-valley/15777-Bright-World-Pre-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/15777-Bright-World-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 23781,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.696964,
        "lon": -122.097298,
        "name": "Camelot School",
        "gradeLevels": "K",
        "assigned": null,
        "address": {
            "street1": "2330 Pomar Vista Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/23781-Camelot-School/",
            "reviews": "/california/castro-valley/23781-Camelot-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/23781-Camelot-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 14,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 11259,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.696964,
        "lon": -122.097298,
        "name": "Camelot School",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "2330 Pomar Vista Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/11259-Camelot-School/",
            "reviews": "/california/castro-valley/11259-Camelot-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/11259-Camelot-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 70,
        "parentRating": 4,
        "numReviews": 9,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "African American",
                "percentage": 25
            },
            {
                "label": "White",
                "percentage": 25
            },
            {
                "label": "Hispanic",
                "percentage": 20
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 20
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 5
            }
        ],
        "remediationData": {}
    },
    {
        "id": 41,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "m",
        "lat": 37.705917,
        "lon": -122.051781,
        "name": "Canyon Middle",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "19600 Cull Canyon Road",
            "street2": null,
            "zip": "94552",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94552",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/41-Canyon-Middle-School/",
            "reviews": "/california/castro-valley/41-Canyon-Middle-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/41-Canyon-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "24%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1391,
        "parentRating": 4,
        "numReviews": 8,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 24
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 28
            },
            {
                "label": "African American",
                "rating": 4,
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 25
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 25
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 9
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 13854,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e,m,h",
        "lat": 37.708008,
        "lon": -122.071182,
        "name": "Castro Valley Adult And Career Education",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "4430 Alma Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/13854-Castro-Valley-Adult-And-Career-Education/",
            "reviews": "/california/castro-valley/13854-Castro-Valley-Adult-And-Career-Education/#Reviews",
            "collegeSuccess": "/california/castro-valley/13854-Castro-Valley-Adult-And-Career-Education/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 42,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e",
        "lat": 37.698425,
        "lon": -122.081856,
        "name": "Castro Valley Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "20185 San Miguel Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/42-Castro-Valley-Elementary-School/",
            "reviews": "/california/castro-valley/42-Castro-Valley-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/42-Castro-Valley-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "46%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 506,
        "parentRating": 5,
        "numReviews": 9,
        "studentsPerTeacher": 20,
        "subratings": {
            "Test Scores Rating": 8,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 46
            },
            {
                "label": "All students",
                "rating": 8
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 20
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 37
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 18
            },
            {
                "label": "Two or more races",
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 7
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 43,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "h",
        "lat": 37.704498,
        "lon": -122.076302,
        "name": "Castro Valley High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "19400 Santa Maria Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/43-Castro-Valley-High-School/",
            "reviews": "/california/castro-valley/43-Castro-Valley-High-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/43-Castro-Valley-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "22%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "80%",
            "state_average": "64%"
        },
        "enrollment": 2834,
        "parentRating": 3,
        "numReviews": 9,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 9,
            "College Readiness Rating": 9,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 22
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 27
            },
            {
                "label": "African American",
                "rating": 5,
                "percentage": 6
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 24
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 28
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 9
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 4
            }
        ],
        "remediationData": {}
    },
    {
        "id": 32566,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e,m,h",
        "lat": 37.7132,
        "lon": -122.09169,
        "name": "Castro Valley Virtual Academy",
        "gradeLevels": "K-12",
        "assigned": null,
        "address": {
            "street1": "18400 Clifton Way",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/32566-Castro-Valley-Virtual-Academy/",
            "reviews": "/california/castro-valley/32566-Castro-Valley-Virtual-Academy/#Reviews",
            "collegeSuccess": "/california/castro-valley/32566-Castro-Valley-Virtual-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "14%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 14,
        "parentRating": 0,
        "numReviews": 0,
        "studentsPerTeacher": 22,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 14
            },
            {
                "label": "White",
                "percentage": 50
            },
            {
                "label": "Asian",
                "percentage": 21
            },
            {
                "label": "Two or more races",
                "percentage": 21
            },
            {
                "label": "Hispanic",
                "percentage": 7
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27372,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.698303,
        "lon": -122.082008,
        "name": "Castro Valley Ymca Kids Ca",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20185 San Miguel Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/27372-Castro-Valley-Ymca-Kids-Ca/",
            "reviews": "/california/castro-valley/27372-Castro-Valley-Ymca-Kids-Ca/#Reviews",
            "collegeSuccess": "/california/castro-valley/27372-Castro-Valley-Ymca-Kids-Ca/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 44,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e",
        "lat": 37.707161,
        "lon": -122.089249,
        "name": "Chabot Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "19104 Lake Chabot Road",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/44-Chabot-Elementary-School/",
            "reviews": "/california/castro-valley/44-Chabot-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/44-Chabot-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "21%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 464,
        "parentRating": 5,
        "numReviews": 9,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 21
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 27
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 17
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 32
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 13
            },
            {
                "label": "African American",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 29323,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.694855,
        "lon": -122.077606,
        "name": "Creative Beginnings Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20861 Wilbeam Avenue #5",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/29323-Creative-Beginnings-Pre-School/",
            "reviews": "/california/castro-valley/29323-Creative-Beginnings-Pre-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/29323-Creative-Beginnings-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 45,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "m",
        "lat": 37.699615,
        "lon": -122.061111,
        "name": "Creekside Middle",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "19722 Center Street",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/45-Creekside-Middle-School/",
            "reviews": "/california/castro-valley/45-Creekside-Middle-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/45-Creekside-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "24%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 781,
        "parentRating": 4,
        "numReviews": 15,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 24
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 26
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 22
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 34
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 8
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 11484,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.692139,
        "lon": -122.092857,
        "name": "Crossroads Christian Elementary School",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "20600 John Dr",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/11484-Crossroads-Christian-Elementary-School/",
            "reviews": "/california/castro-valley/11484-Crossroads-Christian-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/11484-Crossroads-Christian-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 26463,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.703491,
        "lon": -122.062622,
        "name": "Early Bird Montessori Preschool",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "4779 Heyer Ave",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/26463-Early-Bird-Montessori-Preschool/",
            "reviews": "/california/castro-valley/26463-Early-Bird-Montessori-Preschool/#Reviews",
            "collegeSuccess": "/california/castro-valley/26463-Early-Bird-Montessori-Preschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 13257,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.703686,
        "lon": -122.062599,
        "name": "Early Bird Montessori School",
        "gradeLevels": "PK-1",
        "assigned": null,
        "address": {
            "street1": "4779 Heyer Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/13257-Early-Bird-Montessori-School/",
            "reviews": "/california/castro-valley/13257-Early-Bird-Montessori-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/13257-Early-Bird-Montessori-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 30,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 40
            },
            {
                "label": "White",
                "percentage": 30
            },
            {
                "label": "Hispanic",
                "percentage": 10
            },
            {
                "label": "Two or more races",
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 10
            }
        ],
        "remediationData": {}
    },
    {
        "id": 25125,
        "districtId": 2199,
        "districtName": "Golden Oak Montessori of Hayward District",
        "districtCity": "Castro Valley",
        "levelCode": "e,m",
        "lat": 37.687241,
        "lon": -122.070381,
        "name": "Golden Oak Montessori of Hayward",
        "gradeLevels": "1-8",
        "assigned": null,
        "address": {
            "street1": "2652 Vergil Court",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "charter",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/25125-Golden-Oak-Montessori-Of-Hayward-School/",
            "reviews": "/california/castro-valley/25125-Golden-Oak-Montessori-Of-Hayward-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/25125-Golden-Oak-Montessori-Of-Hayward-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "20%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 249,
        "parentRating": 4,
        "numReviews": 18,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 7,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 3
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 20
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 37
            },
            {
                "label": "White",
                "rating": 7,
                "percentage": 24
            },
            {
                "label": "Filipino",
                "percentage": 12
            },
            {
                "label": "African American",
                "percentage": 10
            },
            {
                "label": "Asian",
                "percentage": 8
            },
            {
                "label": "Two or more races",
                "percentage": 7
            },
            {
                "label": "Pacific Islander",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18369,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.718864,
        "lon": -122.078064,
        "name": "Growing Years Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "Po Box 2085",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/18369-Growing-Years-Pre-School/",
            "reviews": "/california/castro-valley/18369-Growing-Years-Pre-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/18369-Growing-Years-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 1,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18370,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.6931,
        "lon": -122.093224,
        "name": "Happiness Hill Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20600 John Drive",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/18370-Happiness-Hill-Pre-School/",
            "reviews": "/california/castro-valley/18370-Happiness-Hill-Pre-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/18370-Happiness-Hill-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 13576,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.68528,
        "lon": -122.072105,
        "name": "His Growing Grove",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "2490 Grove Way",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/13576-His-Growing-Grove/",
            "reviews": "/california/castro-valley/13576-His-Growing-Grove/#Reviews",
            "collegeSuccess": "/california/castro-valley/13576-His-Growing-Grove/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 46,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e",
        "lat": 37.698769,
        "lon": -122.05072,
        "name": "Independent Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "21201 Independent School Road",
            "street2": null,
            "zip": "94552",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94552",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/46-Independent-Elementary-School/",
            "reviews": "/california/castro-valley/46-Independent-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/46-Independent-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "16%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 626,
        "parentRating": 4,
        "numReviews": 10,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 7
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 16
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 38
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 13
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 23
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 10
            },
            {
                "label": "African American",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 5
            }
        ],
        "remediationData": {}
    },
    {
        "id": 47,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e",
        "lat": 37.712593,
        "lon": -122.030602,
        "name": "Jensen Ranch Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "20001 Carson Lane",
            "street2": null,
            "zip": "94552",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94552",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/47-Jensen-Ranch-Elementary-School/",
            "reviews": "/california/castro-valley/47-Jensen-Ranch-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/47-Jensen-Ranch-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "11%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 424,
        "parentRating": 5,
        "numReviews": 5,
        "studentsPerTeacher": 22,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 10
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 9,
                "percentage": 11
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 46
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 24
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 8
            },
            {
                "label": "Hispanic",
                "percentage": 11
            },
            {
                "label": "Filipino",
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 28973,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.707165,
        "lon": -122.088898,
        "name": "Kids Care Plus Day Care",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "3432 Christensen Lane",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/28973-Kids-Care-Plus-Day-Care/",
            "reviews": "/california/castro-valley/28973-Kids-Care-Plus-Day-Care/#Reviews",
            "collegeSuccess": "/california/castro-valley/28973-Kids-Care-Plus-Day-Care/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 15825,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m,h",
        "lat": 37.716599,
        "lon": -122.045197,
        "name": "Liberty School",
        "gradeLevels": "1-12",
        "assigned": null,
        "address": {
            "street1": "5991 Greenridge Road",
            "street2": null,
            "zip": "94552",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94552",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/15825-Liberty-School/",
            "reviews": "/california/castro-valley/15825-Liberty-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/15825-Liberty-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18371,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.694019,
        "lon": -122.080498,
        "name": "Lil Sunflowers Child Development Pgrm",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20875 Chester Street",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/18371-Lil-Sunflowers-Child-Development-Pgrm/",
            "reviews": "/california/castro-valley/18371-Lil-Sunflowers-Child-Development-Pgrm/#Reviews",
            "collegeSuccess": "/california/castro-valley/18371-Lil-Sunflowers-Child-Development-Pgrm/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 48,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e",
        "lat": 37.698601,
        "lon": -122.067055,
        "name": "Marshall Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "20111 Marshall Street",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/48-Marshall-Elementary-School/",
            "reviews": "/california/castro-valley/48-Marshall-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/48-Marshall-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "34%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 490,
        "parentRating": 5,
        "numReviews": 8,
        "studentsPerTeacher": 20,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 8
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 7,
                "percentage": 34
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 24
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 32
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 21
            },
            {
                "label": "Two or more races",
                "percentage": 9
            },
            {
                "label": "African American",
                "percentage": 5
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 29067,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.706165,
        "lon": -122.064438,
        "name": "Mikies Crtv Kids Child Care Center",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "19081 Madison Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/29067-Mikies-Crtv-Kids-Child-Care-Center/",
            "reviews": "/california/castro-valley/29067-Mikies-Crtv-Kids-Child-Care-Center/#Reviews",
            "collegeSuccess": "/california/castro-valley/29067-Mikies-Crtv-Kids-Child-Care-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 26763,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.69133,
        "lon": -122.040527,
        "name": "Montessori School At Five Canyons",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "22781 Canyon Drive",
            "street2": null,
            "zip": "94552",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94552",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/26763-Montessori-School-At-Five-Canyons/",
            "reviews": "/california/castro-valley/26763-Montessori-School-At-Five-Canyons/#Reviews",
            "collegeSuccess": "/california/castro-valley/26763-Montessori-School-At-Five-Canyons/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 100,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 55
            },
            {
                "label": "African American",
                "percentage": 15
            },
            {
                "label": "Asian or Pacific Islander",
                "percentage": 15
            },
            {
                "label": "Hispanic",
                "percentage": 10
            },
            {
                "label": "Two or more races",
                "percentage": 5
            }
        ],
        "remediationData": {}
    },
    {
        "id": 12929,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.69133,
        "lon": -122.040527,
        "name": "Montessori School-Five Cany",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "22781 Canyon Ct",
            "street2": null,
            "zip": "94552",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94552",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/12929-Montessori-School-Five-Cany/",
            "reviews": "/california/castro-valley/12929-Montessori-School-Five-Cany/#Reviews",
            "collegeSuccess": "/california/castro-valley/12929-Montessori-School-Five-Cany/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 8251,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.700588,
        "lon": -122.083282,
        "name": "Our Lady Of Grace School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "19920 Anita Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/8251-Our-Lady-Of-Grace-School/",
            "reviews": "/california/castro-valley/8251-Our-Lady-Of-Grace-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/8251-Our-Lady-Of-Grace-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 238,
        "parentRating": 4,
        "numReviews": 10,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Two or more races",
                "percentage": 45
            },
            {
                "label": "White",
                "percentage": 24
            },
            {
                "label": "Asian",
                "percentage": 12
            },
            {
                "label": "African American",
                "percentage": 10
            },
            {
                "label": "Hispanic",
                "percentage": 5
            },
            {
                "label": "Pacific Islander",
                "percentage": 4
            }
        ],
        "remediationData": {}
    },
    {
        "id": 49,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e",
        "lat": 37.694756,
        "lon": -122.025818,
        "name": "Palomares Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "6395 Palo Verde Road",
            "street2": null,
            "zip": "94552",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94552",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/49-Palomares-Elementary-School/",
            "reviews": "/california/castro-valley/49-Palomares-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/49-Palomares-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "22%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 143,
        "parentRating": 4,
        "numReviews": 4,
        "studentsPerTeacher": 18,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 8,
            "Equity Overview Rating": 10
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 22
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Hispanic",
                "rating": 10,
                "percentage": 36
            },
            {
                "label": "White",
                "percentage": 31
            },
            {
                "label": "Asian",
                "percentage": 12
            },
            {
                "label": "Two or more races",
                "percentage": 12
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 50,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e",
        "lat": 37.716213,
        "lon": -122.072029,
        "name": "Proctor Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "17520 Redwood Road",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/50-Proctor-Elementary-School/",
            "reviews": "/california/castro-valley/50-Proctor-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/50-Proctor-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "18%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 511,
        "parentRating": 4,
        "numReviews": 12,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 9
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 8,
                "percentage": 18
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 23
            },
            {
                "label": "Hispanic",
                "rating": 8,
                "percentage": 23
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 31
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 13
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 51,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "h",
        "lat": 37.7132,
        "lon": -122.09169,
        "name": "Redwood Continuation High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "18400 Clifton Way",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/51-Redwood-Continuation-High-School/",
            "reviews": "/california/castro-valley/51-Redwood-Continuation-High-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/51-Redwood-Continuation-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "40%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {
            "school_value": "32%",
            "state_average": "64%"
        },
        "enrollment": 125,
        "parentRating": 4,
        "numReviews": 1,
        "studentsPerTeacher": 12,
        "subratings": {
            "College Readiness Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 40
            },
            {
                "label": "Hispanic",
                "percentage": 41
            },
            {
                "label": "White",
                "percentage": 26
            },
            {
                "label": "Two or more races",
                "percentage": 11
            },
            {
                "label": "African American",
                "percentage": 11
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 2
            },
            {
                "label": "Asian",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18366,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.707043,
        "lon": -122.073296,
        "name": "Redwood Forest Pre-School",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "19200 Redwood Road",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/18366-Redwood-Forest-Pre-School/",
            "reviews": "/california/castro-valley/18366-Redwood-Forest-Pre-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/18366-Redwood-Forest-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 18367,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.697075,
        "lon": -122.060799,
        "name": "Rise 'N Shine Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20104 Center St",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/18367-Rise-N-Shine-Pre-School/",
            "reviews": "/california/castro-valley/18367-Rise-N-Shine-Pre-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/18367-Rise-N-Shine-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 12352,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "h",
        "lat": 37.7132,
        "lon": -122.09169,
        "name": "Roy A. Johnson High",
        "gradeLevels": "9-12",
        "assigned": null,
        "address": {
            "street1": "18400 Clifton Way",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/12352-Roy-A.-Johnson-High-School/",
            "reviews": "/california/castro-valley/12352-Roy-A.-Johnson-High-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/12352-Roy-A.-Johnson-High-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "35%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 17,
        "parentRating": 5,
        "numReviews": 1,
        "studentsPerTeacher": 7,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 35
            },
            {
                "label": "Hispanic",
                "percentage": 29
            },
            {
                "label": "White",
                "percentage": 24
            },
            {
                "label": "Asian",
                "percentage": 18
            },
            {
                "label": "African American",
                "percentage": 12
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 6
            }
        ],
        "remediationData": {}
    },
    {
        "id": 11676,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.696899,
        "lon": -122.085503,
        "name": "Saybrook Learning Center",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "20375 Wisteria St",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/11676-Saybrook-Learning-Center/",
            "reviews": "/california/castro-valley/11676-Saybrook-Learning-Center/#Reviews",
            "collegeSuccess": "/california/castro-valley/11676-Saybrook-Learning-Center/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 25073,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e,m,h",
        "lat": 37.687241,
        "lon": -122.070381,
        "name": "Special Education-Preschool-Laurel",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "2652 Vergil Court",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/25073-Special-Education-Preschool-Laurel/",
            "reviews": "/california/castro-valley/25073-Special-Education-Preschool-Laurel/#Reviews",
            "collegeSuccess": "/california/castro-valley/25073-Special-Education-Preschool-Laurel/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "0%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Hispanic",
                "percentage": 100
            }
        ],
        "remediationData": {}
    },
    {
        "id": 52,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e",
        "lat": 37.700573,
        "lon": -122.09404,
        "name": "Stanton Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "2644 Somerset Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/52-Stanton-Elementary-School/",
            "reviews": "/california/castro-valley/52-Stanton-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/52-Stanton-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "36%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 493,
        "parentRating": 4,
        "numReviews": 14,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 7,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 36
            },
            {
                "label": "All students",
                "rating": 7
            },
            {
                "label": "Asian",
                "rating": 9,
                "percentage": 24
            },
            {
                "label": "Hispanic",
                "rating": 4,
                "percentage": 26
            },
            {
                "label": "White",
                "rating": 8,
                "percentage": 20
            },
            {
                "label": "Two or more races",
                "rating": 8,
                "percentage": 11
            },
            {
                "label": "African American",
                "percentage": 9
            },
            {
                "label": "Filipino",
                "percentage": 8
            }
        ],
        "remediationData": {}
    },
    {
        "id": 136,
        "districtId": 9,
        "districtName": "Hayward Unified",
        "districtCity": "Hayward",
        "levelCode": "e",
        "lat": 37.687794,
        "lon": -122.088409,
        "name": "Strobridge Elementary",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "21400 Bedford Dr.",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 2,
        "ratingScale": "Below average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/136-Strobridge-Elementary-School/",
            "reviews": "/california/castro-valley/136-Strobridge-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/136-Strobridge-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "71%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 492,
        "parentRating": 3,
        "numReviews": 5,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 1,
            "Academic Progress Rating": 3,
            "Equity Overview Rating": 2
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 1,
                "percentage": 71
            },
            {
                "label": "All students",
                "rating": 1
            },
            {
                "label": "African American",
                "rating": 1,
                "percentage": 12
            },
            {
                "label": "Hispanic",
                "rating": 1,
                "percentage": 68
            },
            {
                "label": "White",
                "percentage": 6
            },
            {
                "label": "Two or more races",
                "percentage": 5
            },
            {
                "label": "Asian",
                "percentage": 4
            },
            {
                "label": "Filipino",
                "percentage": 2
            },
            {
                "label": "Pacific Islander",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 31231,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.684513,
        "lon": -122.081352,
        "name": "The Montessori Giving Tree",
        "gradeLevels": "K-6",
        "assigned": null,
        "address": {
            "street1": "1914 Grove Way",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/31231-The-Montessori-Giving-Tree/",
            "reviews": "/california/castro-valley/31231-The-Montessori-Giving-Tree/#Reviews",
            "collegeSuccess": "/california/castro-valley/31231-The-Montessori-Giving-Tree/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 12,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 53,
        "districtId": 4,
        "districtName": "Castro Valley Unified",
        "districtCity": "Castro Valley",
        "levelCode": "e",
        "lat": 37.70713,
        "lon": -122.058754,
        "name": "Vannoy Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "5100 Vannoy Avenue",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/53-Vannoy-Elementary-School/",
            "reviews": "/california/castro-valley/53-Vannoy-Elementary-School/#Reviews",
            "collegeSuccess": "/california/castro-valley/53-Vannoy-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "14%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 436,
        "parentRating": 4,
        "numReviews": 11,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 6,
                "percentage": 14
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 29
            },
            {
                "label": "Hispanic",
                "rating": 7,
                "percentage": 21
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 30
            },
            {
                "label": "Two or more races",
                "percentage": 10
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 3
            }
        ],
        "remediationData": {}
    },
    {
        "id": 25860,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.68515,
        "lon": -122.072327,
        "name": "Vita Academy",
        "gradeLevels": "1-6",
        "assigned": null,
        "address": {
            "street1": "2490 Grove Way",
            "street2": null,
            "zip": "94546",
            "city": "Castro Valley"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94546",
        "type": "school",
        "links": {
            "profile": "/california/castro-valley/25860-Vita-Academy/",
            "reviews": "/california/castro-valley/25860-Vita-Academy/#Reviews",
            "collegeSuccess": "/california/castro-valley/25860-Vita-Academy/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    }
]