exports.PALO_ALTO_SCHOOLS = [
		{
			"id": 8851,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.421936,
			"lon": -122.112144,
			"name": "Achieve, Inc. School",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "3860 Middlefield Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/8851-Achieve-Inc.-School/",
				"reviews": "/california/palo-alto/8851-Achieve-Inc.-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/8851-Achieve-Inc.-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 63,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 45
				},
				{
					"label": "Hispanic",
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 15
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 5603,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.446175,
			"lon": -122.149971,
			"name": "Addison Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "650 Addison Avenue",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5603-Addison-Elementary-School/",
				"reviews": "/california/palo-alto/5603-Addison-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5603-Addison-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "16%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 402,
			"parentRating": 5,
			"numReviews": 2,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 16
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 16
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 47
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 28055,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.4459,
			"lon": -122.150497,
			"name": "Addison Kids Corner",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "650 Addison Avenue",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/28055-Addison-Kids-Corner/",
				"reviews": "/california/palo-alto/28055-Addison-Kids-Corner/#Reviews",
				"collegeSuccess": "/california/palo-alto/28055-Addison-Kids-Corner/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31717,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.440872,
			"lon": -122.157524,
			"name": "AltSchool Palo Alto",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "930 Emerson Street",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/31717-AltSchool-Palo-Alto/",
				"reviews": "/california/palo-alto/31717-AltSchool-Palo-Alto/#Reviews",
				"collegeSuccess": "/california/palo-alto/31717-AltSchool-Palo-Alto/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31576,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.414158,
			"lon": -122.104538,
			"name": "Athena Academy",
			"gradeLevels": "1-8",
			"assigned": null,
			"address": {
				"street1": "525 San Antonio Avenue",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/31576-Athena-Academy/",
				"reviews": "/california/palo-alto/31576-Athena-Academy/#Reviews",
				"collegeSuccess": "/california/palo-alto/31576-Athena-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 50,
			"parentRating": 5,
			"numReviews": 6,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 78
				},
				{
					"label": "Asian",
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 5604,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.412487,
			"lon": -122.137856,
			"name": "Barron Park Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "800 Barron Avenue",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5604-Barron-Park-Elementary-School/",
				"reviews": "/california/palo-alto/5604-Barron-Park-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5604-Barron-Park-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "31%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 244,
			"parentRating": 5,
			"numReviews": 25,
			"studentsPerTeacher": 11,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 31
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 18
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 33
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 29119,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.417992,
			"lon": -122.127754,
			"name": "Barron Park Kids Club",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3990 Ventura Court",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/29119-Barron-Park-Kids-Club/",
				"reviews": "/california/palo-alto/29119-Barron-Park-Kids-Club/#Reviews",
				"collegeSuccess": "/california/palo-alto/29119-Barron-Park-Kids-Club/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21126,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.415455,
			"lon": -122.135429,
			"name": "Barron Park Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3650 La Donna Avenue",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21126-Barron-Park-Pre-School/",
				"reviews": "/california/palo-alto/21126-Barron-Park-Pre-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21126-Barron-Park-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24015,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.417568,
			"lon": -122.131683,
			"name": "Bear Hollow School",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "3790 El Camino Real",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/24015-Bear-Hollow-School/",
				"reviews": "/california/palo-alto/24015-Bear-Hollow-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/24015-Bear-Hollow-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10430,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.402737,
			"lon": -122.127869,
			"name": "Bowman International School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "4000 Terman Drive",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/10430-Bowman-International-School/",
				"reviews": "/california/palo-alto/10430-Bowman-International-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/10430-Bowman-International-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 243,
			"parentRating": 4,
			"numReviews": 20,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 56
				},
				{
					"label": "Asian",
					"percentage": 38
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 21121,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.415726,
			"lon": -122.127998,
			"name": "Bright Horizons Family Solutions",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3000 El Camino Way #3",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21121-Bright-Horizons-Family-Solutions/",
				"reviews": "/california/palo-alto/21121-Bright-Horizons-Family-Solutions/#Reviews",
				"collegeSuccess": "/california/palo-alto/21121-Bright-Horizons-Family-Solutions/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21138,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.426701,
			"lon": -122.146683,
			"name": "Casa Dei Bambini Pre-School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "457 College Avenue",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21138-Casa-Dei-Bambini-Pre-School/",
				"reviews": "/california/palo-alto/21138-Casa-Dei-Bambini-Pre-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21138-Casa-Dei-Bambini-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 11,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26342,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.42675,
			"lon": -122.146729,
			"name": "Casa Dei Bambini School",
			"gradeLevels": "K-1",
			"assigned": null,
			"address": {
				"street1": "457 College Avenue",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/26342-Casa-Dei-Bambini-School/",
				"reviews": "/california/palo-alto/26342-Casa-Dei-Bambini-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/26342-Casa-Dei-Bambini-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 93,
			"parentRating": 5,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 86
				},
				{
					"label": "African American",
					"percentage": 14
				}
			],
			"remediationData": {}
		},
		{
			"id": 8687,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.439259,
			"lon": -122.151329,
			"name": "Castilleja School",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "1310 Bryant Street",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/8687-Castilleja-School/",
				"reviews": "/california/palo-alto/8687-Castilleja-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/8687-Castilleja-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 448,
			"parentRating": 4,
			"numReviews": 14,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 61
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				},
				{
					"label": "Hispanic",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31770,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.440807,
			"lon": -122.174736,
			"name": "CCLC at Stanford West",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "625 Clark Way",
				"street2": null,
				"zip": "94304",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94304",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/31770-CCLC-At-Stanford-West/",
				"reviews": "/california/palo-alto/31770-CCLC-At-Stanford-West/#Reviews",
				"collegeSuccess": "/california/palo-alto/31770-CCLC-At-Stanford-West/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13073,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.420624,
			"lon": -122.111,
			"name": "Challenger - Middlefield",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "3880 Middlefield Rd",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/13073-Challenger---Middlefield/",
				"reviews": "/california/palo-alto/13073-Challenger---Middlefield/#Reviews",
				"collegeSuccess": "/california/palo-alto/13073-Challenger---Middlefield/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 328,
			"parentRating": 3,
			"numReviews": 2,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21132,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.418079,
			"lon": -122.108047,
			"name": "Children's Pre-School Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4000 Middlefield Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21132-Childrens-Pre-School-Center/",
				"reviews": "/california/palo-alto/21132-Childrens-Pre-School-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21132-Childrens-Pre-School-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27559,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.442101,
			"lon": -122.157616,
			"name": "Childrens Creative Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "848 Ramona Street",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/27559-Childrens-Creative-Learning-Center/",
				"reviews": "/california/palo-alto/27559-Childrens-Creative-Learning-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/27559-Childrens-Creative-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21114,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.422901,
			"lon": -122.14888,
			"name": "College Terrace Pre-School Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2300 Wellesley St",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21114-College-Terrace-Pre-School-Center/",
				"reviews": "/california/palo-alto/21114-College-Terrace-Pre-School-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21114-College-Terrace-Pre-School-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26344,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.396679,
			"lon": -122.132858,
			"name": "Cornerstone Chinese Immersion School",
			"gradeLevels": "K-1",
			"assigned": null,
			"address": {
				"street1": "4175 Manuela Avenue",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/26344-Cornerstone-Chinese-Immersion-School/",
				"reviews": "/california/palo-alto/26344-Cornerstone-Chinese-Immersion-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/26344-Cornerstone-Chinese-Immersion-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21130,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.417992,
			"lon": -122.127754,
			"name": "Country Day Little School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3990 Ventura Court",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21130-Country-Day-Little-School/",
				"reviews": "/california/palo-alto/21130-Country-Day-Little-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21130-Country-Day-Little-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21142,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.42326,
			"lon": -122.115143,
			"name": "Covenant Presbyterian Chldrn's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "670 East Meadow Drive",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21142-Covenant-Presbyterian-Chldrns-Center/",
				"reviews": "/california/palo-alto/21142-Covenant-Presbyterian-Chldrns-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21142-Covenant-Presbyterian-Chldrns-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21146,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.411301,
			"lon": -122.112068,
			"name": "Crescent Park Community Cc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4161 Alma Street",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21146-Crescent-Park-Community-Cc/",
				"reviews": "/california/palo-alto/21146-Crescent-Park-Community-Cc/#Reviews",
				"collegeSuccess": "/california/palo-alto/21146-Crescent-Park-Community-Cc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29755,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.448559,
			"lon": -122.136749,
			"name": "Crescent Park Community Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1610 Channing Avenue",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/29755-Crescent-Park-Community-Child-Care-Center/",
				"reviews": "/california/palo-alto/29755-Crescent-Park-Community-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/29755-Crescent-Park-Community-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13349,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.449894,
			"lon": -122.158394,
			"name": "Discovery Children's House Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "437 Webster Street",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/13349-Discovery-Childrens-House-Montessori-School/",
				"reviews": "/california/palo-alto/13349-Discovery-Childrens-House-Montessori-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/13349-Discovery-Childrens-House-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 49,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 50
				},
				{
					"label": "White",
					"percentage": 50
				}
			],
			"remediationData": {}
		},
		{
			"id": 21141,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.446819,
			"lon": -122.159531,
			"name": "Downtown Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "555 Waverley Street",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21141-Downtown-Childrens-Center/",
				"reviews": "/california/palo-alto/21141-Downtown-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21141-Downtown-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5606,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.449772,
			"lon": -122.13446,
			"name": "Duveneck Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "705 Alester Avenue",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5606-Duveneck-Elementary-School/",
				"reviews": "/california/palo-alto/5606-Duveneck-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5606-Duveneck-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 374,
			"parentRating": 5,
			"numReviews": 7,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 16
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 14
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 27960,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.450066,
			"lon": -122.134476,
			"name": "Duveneck Kids Club",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "705 Alester Ave",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/27960-Duveneck-Kids-Club/",
				"reviews": "/california/palo-alto/27960-Duveneck-Kids-Club/#Reviews",
				"collegeSuccess": "/california/palo-alto/27960-Duveneck-Kids-Club/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5607,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.425579,
			"lon": -122.130402,
			"name": "El Carmelo Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "3024 Bryant Street",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5607-El-Carmelo-Elementary-School/",
				"reviews": "/california/palo-alto/5607-El-Carmelo-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5607-El-Carmelo-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "19%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 360,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 19
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 38
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 16
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 27907,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.425823,
			"lon": -122.130348,
			"name": "El Carmelo Kids Corner",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3024 Bryant Street",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/27907-El-Carmelo-Kids-Corner/",
				"reviews": "/california/palo-alto/27907-El-Carmelo-Kids-Corner/#Reviews",
				"collegeSuccess": "/california/palo-alto/27907-El-Carmelo-Kids-Corner/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 14387,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "m",
			"lat": 37.403236,
			"lon": -122.126396,
			"name": "Ellen Fletcher Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "655 Arastradero Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/14387-Terman-Middle-School/",
				"reviews": "/california/palo-alto/14387-Terman-Middle-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/14387-Terman-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "12%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 668,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 12
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 37
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 16
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 30
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21139,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.419575,
			"lon": -122.112495,
			"name": "Ellen Thacher Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "505 East Charleston Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21139-Ellen-Thacher-Childrens-Center/",
				"reviews": "/california/palo-alto/21139-Ellen-Thacher-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21139-Ellen-Thacher-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10249,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.444397,
			"lon": -122.118973,
			"name": "Emerson",
			"gradeLevels": "1-5",
			"assigned": null,
			"address": {
				"street1": "2800 West Bayshore Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/10249-Emerson/",
				"reviews": "/california/palo-alto/10249-Emerson/#Reviews",
				"collegeSuccess": "/california/palo-alto/10249-Emerson/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 15,
			"parentRating": 5,
			"numReviews": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 47
				},
				{
					"label": "White",
					"percentage": 47
				},
				{
					"label": "Hispanic",
					"percentage": 7
				}
			],
			"remediationData": {}
		},
		{
			"id": 8727,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.440002,
			"lon": -122.178513,
			"name": "Esther B. Clark School",
			"gradeLevels": "2-10",
			"assigned": null,
			"address": {
				"street1": "650 Clark Way",
				"street2": null,
				"zip": "94304",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94304",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/8727-Esther-B.-Clark-School/",
				"reviews": "/california/palo-alto/8727-Esther-B.-Clark-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/8727-Esther-B.-Clark-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 82,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 71
				},
				{
					"label": "Hispanic",
					"percentage": 12
				},
				{
					"label": "Asian",
					"percentage": 11
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 5609,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.422314,
			"lon": -122.117538,
			"name": "Fairmeadow Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "500 East Meadow Drive",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5609-Fairmeadow-Elementary-School/",
				"reviews": "/california/palo-alto/5609-Fairmeadow-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5609-Fairmeadow-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "9%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 445,
			"parentRating": 5,
			"numReviews": 8,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 9
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 42
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21113,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.44413,
			"lon": -122.133804,
			"name": "First Congregational Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1985 Louis Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21113-First-Congregational-Nursery-School/",
				"reviews": "/california/palo-alto/21113-First-Congregational-Nursery-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21113-First-Congregational-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 29331,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.452461,
			"lon": -122.151596,
			"name": "Footsteps Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "945 Forest Avenue",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/29331-Footsteps-Pre-School/",
				"reviews": "/california/palo-alto/29331-Footsteps-Pre-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/29331-Footsteps-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5614,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "m",
			"lat": 37.438499,
			"lon": -122.134705,
			"name": "Frank S. Greene Jr. Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "750 North California Avenue",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5614-Frank-S.-Greene-Jr.-Middle/",
				"reviews": "/california/palo-alto/5614-Frank-S.-Greene-Jr.-Middle/#Reviews",
				"collegeSuccess": "/california/palo-alto/5614-Frank-S.-Greene-Jr.-Middle/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "10%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1050,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 10
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 30
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 14
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 41
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32381,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.450962,
			"lon": -122.124176,
			"name": "Fusion Academy Palo Alto",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "2191 East Bayshore Road",
				"street2": "Suite 100",
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/32381-Fusion-Academy-Palo-Alto/",
				"reviews": "/california/palo-alto/32381-Fusion-Academy-Palo-Alto/#Reviews",
				"collegeSuccess": "/california/palo-alto/32381-Fusion-Academy-Palo-Alto/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9774,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.41275,
			"lon": -122.104507,
			"name": "Gideon Hausner Jewish Day School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "450 San Antonio Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/9774-Gideon-Hausner-Jewish-Day-School/",
				"reviews": "/california/palo-alto/9774-Gideon-Hausner-Jewish-Day-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/9774-Gideon-Hausner-Jewish-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 385,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 92
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 26348,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m",
			"lat": 37.435383,
			"lon": -122.109894,
			"name": "Girls' Middle School, The",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "3400 West Bayshore Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/26348-Girls-Middle-School-The/",
				"reviews": "/california/palo-alto/26348-Girls-Middle-School-The/#Reviews",
				"collegeSuccess": "/california/palo-alto/26348-Girls-Middle-School-The/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 199,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21133,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.418079,
			"lon": -122.108047,
			"name": "Good Neighbor Montessori",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "4000 Middlefield Rd, Suite K4",
				"street2": "Suite K4",
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21133-Good-Neighbor-Montessori/",
				"reviews": "/california/palo-alto/21133-Good-Neighbor-Montessori/#Reviews",
				"collegeSuccess": "/california/palo-alto/21133-Good-Neighbor-Montessori/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21124,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.426899,
			"lon": -122.127304,
			"name": "Grace Lutheran Church Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3149 Waverley St",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21124-Grace-Lutheran-Church-Pre-School/",
				"reviews": "/california/palo-alto/21124-Grace-Lutheran-Church-Pre-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21124-Grace-Lutheran-Church-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5610,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.416492,
			"lon": -122.107147,
			"name": "Greendell",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "4120 Middlefield Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5610-Greendell-School/",
				"reviews": "/california/palo-alto/5610-Greendell-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5610-Greendell-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "7%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 110,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 14,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 7
				},
				{
					"label": "White",
					"percentage": 39
				},
				{
					"label": "Asian",
					"percentage": 38
				},
				{
					"label": "Hispanic",
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21136,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.412331,
			"lon": -122.12159,
			"name": "Growing Tree Montessouri",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "450 West Charleston Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21136-Growing-Tree-Montessouri/",
				"reviews": "/california/palo-alto/21136-Growing-Tree-Montessouri/#Reviews",
				"collegeSuccess": "/california/palo-alto/21136-Growing-Tree-Montessouri/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26648,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.444397,
			"lon": -122.118973,
			"name": "Headsup Child Development Center Palo Alto",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "2800 West Bayshore Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/26648-Headsup-Child-Development-Center-Palo-Alto/",
				"reviews": "/california/palo-alto/26648-Headsup-Child-Development-Center-Palo-Alto/#Reviews",
				"collegeSuccess": "/california/palo-alto/26648-Headsup-Child-Development-Center-Palo-Alto/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 92,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5611,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "h",
			"lat": 37.400814,
			"lon": -122.132721,
			"name": "Henry M. Gunn High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "780 Arastradero Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5611-Henry-M.-Gunn-High-School/",
				"reviews": "/california/palo-alto/5611-Henry-M.-Gunn-High-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5611-Henry-M.-Gunn-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "7%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "86%",
				"state_average": "64%"
			},
			"enrollment": 2006,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 7
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 43
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 9
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 37
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5613,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.418762,
			"lon": -122.115677,
			"name": "Herbert Hoover Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "445 East Charleston Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5613-Herbert-Hoover-Elementary-School/",
				"reviews": "/california/palo-alto/5613-Herbert-Hoover-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5613-Herbert-Hoover-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "7%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 395,
			"parentRating": 4,
			"numReviews": 19,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 7
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 70
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "White",
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21120,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.447327,
			"lon": -122.118088,
			"name": "Hope Technology Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2525 East Bayshore Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21120-Hope-Technology-Pre-School/",
				"reviews": "/california/palo-alto/21120-Hope-Technology-Pre-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21120-Hope-Technology-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 15227,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.447403,
			"lon": -122.118065,
			"name": "Hope Technology School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2525 East Bayshore Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/15227-Hope-Technology-School/",
				"reviews": "/california/palo-alto/15227-Hope-Technology-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/15227-Hope-Technology-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 124,
			"parentRating": 4,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Two or more races",
					"percentage": 46
				},
				{
					"label": "White",
					"percentage": 27
				},
				{
					"label": "Asian",
					"percentage": 15
				},
				{
					"label": "African American",
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 31736,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.417393,
			"lon": -122.108376,
			"name": "Imagination School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "4000 Middlefield Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/31736-Imagination-School/",
				"reviews": "/california/palo-alto/31736-Imagination-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/31736-Imagination-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 10,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31582,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.452564,
			"lon": -122.125504,
			"name": "International School Of The Peninsula",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "151 Laura Ln",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/31582-International-School-Of-The-Peninsula/",
				"reviews": "/california/palo-alto/31582-International-School-Of-The-Peninsula/#Reviews",
				"collegeSuccess": "/california/palo-alto/31582-International-School-Of-The-Peninsula/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 75,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5619,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "m",
			"lat": 37.421253,
			"lon": -122.118546,
			"name": "Jane Lathrop Stanford Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "480 East Meadow Drive",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5619-Jane-Lathrop-Stanford-Middle-School/",
				"reviews": "/california/palo-alto/5619-Jane-Lathrop-Stanford-Middle-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5619-Jane-Lathrop-Stanford-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1137,
			"parentRating": 5,
			"numReviews": 5,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 44
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 12
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 32
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 5605,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.407627,
			"lon": -122.130386,
			"name": "Juana Briones Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "4100 Orme Street",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5605-Juana-Briones-Elementary-School/",
				"reviews": "/california/palo-alto/5605-Juana-Briones-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5605-Juana-Briones-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "15%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 307,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 9,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 15
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 34
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 17
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 32
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 28056,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.407944,
			"lon": -122.130608,
			"name": "Juana Briones Kid's Club",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4100 Orme Street",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/28056-Juana-Briones-Kids-Club/",
				"reviews": "/california/palo-alto/28056-Juana-Briones-Kids-Club/#Reviews",
				"collegeSuccess": "/california/palo-alto/28056-Juana-Briones-Kids-Club/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17374,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.424786,
			"lon": -122.104568,
			"name": "Kehillah Jewish High School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "3900 Fabian Way",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/17374-Kehillah-Jewish-High-School/",
				"reviews": "/california/palo-alto/17374-Kehillah-Jewish-High-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/17374-Kehillah-Jewish-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 185,
			"parentRating": 4,
			"numReviews": 10,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 94
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 26352,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.431194,
			"lon": -122.127045,
			"name": "Keys Family Day School",
			"gradeLevels": "K-4",
			"assigned": null,
			"address": {
				"street1": "2890 Middlefield Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/26352-Keys-Family-Day-School/",
				"reviews": "/california/palo-alto/26352-Keys-Family-Day-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/26352-Keys-Family-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 177,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 26353,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.416325,
			"lon": -122.128227,
			"name": "Keys Family Day School",
			"gradeLevels": "5-8",
			"assigned": null,
			"address": {
				"street1": "3981 El Camino Real",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/26353-Keys-Family-Day-School/",
				"reviews": "/california/palo-alto/26353-Keys-Family-Day-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/26353-Keys-Family-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 138,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8790,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.431194,
			"lon": -122.127045,
			"name": "Keys School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2890 Middlefield Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/8790-Keys-School/",
				"reviews": "/california/palo-alto/8790-Keys-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/8790-Keys-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 289,
			"parentRating": 4,
			"numReviews": 19,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 60
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 28
				},
				{
					"label": "Two or more races",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Hispanic",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21137,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.442318,
			"lon": -122.151016,
			"name": "Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "459 Kingsley Avenue",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21137-Learning-Center/",
				"reviews": "/california/palo-alto/21137-Learning-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21137-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21127,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.420982,
			"lon": -122.129364,
			"name": "Lilliput Infant-Toddler Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3789 Park Boulevard",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21127-Lilliput-Infant-Toddler-Center/",
				"reviews": "/california/palo-alto/21127-Lilliput-Infant-Toddler-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21127-Lilliput-Infant-Toddler-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 10173,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 37.426868,
			"lon": -122.147224,
			"name": "Living Wisdom School Of Palo Alto",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "456 College Avenue",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/10173-Living-Wisdom-School-Of-Palo-Alto/",
				"reviews": "/california/palo-alto/10173-Living-Wisdom-School-Of-Palo-Alto/#Reviews",
				"collegeSuccess": "/california/palo-alto/10173-Living-Wisdom-School-Of-Palo-Alto/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 65,
			"parentRating": 5,
			"numReviews": 26,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 41
				},
				{
					"label": "White",
					"percentage": 38
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 21119,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.435165,
			"lon": -122.132797,
			"name": "Love N Care Christian Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2490 Middlefield Rd",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21119-Love-N-Care-Christian-Pre-School/",
				"reviews": "/california/palo-alto/21119-Love-N-Care-Christian-Pre-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21119-Love-N-Care-Christian-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 31586,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "h",
			"lat": 37.430393,
			"lon": -122.104202,
			"name": "Meira Academy",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "3291 Fabian Way",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/31586-Meira-Academy/",
				"reviews": "/california/palo-alto/31586-Meira-Academy/#Reviews",
				"collegeSuccess": "/california/palo-alto/31586-Meira-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 23,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 100
				}
			],
			"remediationData": {}
		},
		{
			"id": 21144,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.437214,
			"lon": -122.125259,
			"name": "Midtown Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "855 Bruce Dr",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21144-Midtown-Nursery-School/",
				"reviews": "/california/palo-alto/21144-Midtown-Nursery-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21144-Midtown-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24081,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.420898,
			"lon": -122.11087,
			"name": "Milestones Preschool",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3864 Middlefield Rd",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/24081-Milestones-Preschool/",
				"reviews": "/california/palo-alto/24081-Milestones-Preschool/#Reviews",
				"collegeSuccess": "/california/palo-alto/24081-Milestones-Preschool/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21125,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.426632,
			"lon": -122.127403,
			"name": "Mini Infant Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3149 Waverley Street",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21125-Mini-Infant-Center/",
				"reviews": "/california/palo-alto/21125-Mini-Infant-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21125-Mini-Infant-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21135,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.414604,
			"lon": -122.112663,
			"name": "Montessori School Of Los Altos",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "303 Parkside Drive",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21135-Montessori-School-Of-Los-Altos/",
				"reviews": "/california/palo-alto/21135-Montessori-School-Of-Los-Altos/#Reviews",
				"collegeSuccess": "/california/palo-alto/21135-Montessori-School-Of-Los-Altos/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 22733,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.414677,
			"lon": -122.113457,
			"name": "Morningstar Chinese School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "272 Creekside Drive",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/22733-Morningstar-Chinese-School/",
				"reviews": "/california/palo-alto/22733-Morningstar-Chinese-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/22733-Morningstar-Chinese-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30123,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.446484,
			"lon": -122.117805,
			"name": "Mustard Seed Learning Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2585 East Bayshore Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/30123-Mustard-Seed-Learning-Center/",
				"reviews": "/california/palo-alto/30123-Mustard-Seed-Learning-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/30123-Mustard-Seed-Learning-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21123,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.432892,
			"lon": -122.140015,
			"name": "Neighborhood Infant-Toddler Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "305 North California Avenue",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21123-Neighborhood-Infant-Toddler-Center/",
				"reviews": "/california/palo-alto/21123-Neighborhood-Infant-Toddler-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21123-Neighborhood-Infant-Toddler-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5616,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.439213,
			"lon": -122.123322,
			"name": "Ohlone Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "950 Amarillo Avenue",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5616-Ohlone-Elementary-School/",
				"reviews": "/california/palo-alto/5616-Ohlone-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5616-Ohlone-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "4%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 560,
			"parentRating": 5,
			"numReviews": 5,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 4
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 39
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 36
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21147,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.439213,
			"lon": -122.123322,
			"name": "Ohlone Kids Club",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "950 Amarillo Avenue",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21147-Ohlone-Kids-Club/",
				"reviews": "/california/palo-alto/21147-Ohlone-Kids-Club/#Reviews",
				"collegeSuccess": "/california/palo-alto/21147-Ohlone-Kids-Club/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 18830,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.464878,
			"lon": -122.130539,
			"name": "Oicw Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1070 Beech St",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/18830-Oicw-Child-Development-Center/",
				"reviews": "/california/palo-alto/18830-Oicw-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/18830-Oicw-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12604,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e,m,h",
			"lat": 37.436081,
			"lon": -122.173874,
			"name": "Packard Children's Hospital/Stanford",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "725 Welch Road",
				"street2": null,
				"zip": "94304",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94304",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/12604-Packard-Childrens-Hospital-Stanford/",
				"reviews": "/california/palo-alto/12604-Packard-Childrens-Hospital-Stanford/#Reviews",
				"collegeSuccess": "/california/palo-alto/12604-Packard-Childrens-Hospital-Stanford/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "57%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 23,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 57
				},
				{
					"label": "Hispanic",
					"percentage": 43
				},
				{
					"label": "Asian",
					"percentage": 17
				},
				{
					"label": "Pacific Islander",
					"percentage": 17
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "White",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 13650,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e,m,h",
			"lat": 37.436386,
			"lon": -122.157303,
			"name": "Palo Alto Adult Education",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "50 Embarcadero Road",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/13650-Palo-Alto-Adult-Education/",
				"reviews": "/california/palo-alto/13650-Palo-Alto-Adult-Education/#Reviews",
				"collegeSuccess": "/california/palo-alto/13650-Palo-Alto-Adult-Education/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30238,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.408188,
			"lon": -122.122154,
			"name": "Palo Alto Bilingual Mont Ps",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4232 El Camino Real",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/30238-Palo-Alto-Bilingual-Mont-Ps/",
				"reviews": "/california/palo-alto/30238-Palo-Alto-Bilingual-Mont-Ps/#Reviews",
				"collegeSuccess": "/california/palo-alto/30238-Palo-Alto-Bilingual-Mont-Ps/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21148,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.438042,
			"lon": -122.120872,
			"name": "Palo Alto Friends Nursery School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "957 Colorado Avenue",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21148-Palo-Alto-Friends-Nursery-School/",
				"reviews": "/california/palo-alto/21148-Palo-Alto-Friends-Nursery-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21148-Palo-Alto-Friends-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5617,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "h",
			"lat": 37.436253,
			"lon": -122.155441,
			"name": "Palo Alto High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "50 Embarcadero Road",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5617-Palo-Alto-High-School/",
				"reviews": "/california/palo-alto/5617-Palo-Alto-High-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5617-Palo-Alto-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "83%",
				"state_average": "64%"
			},
			"enrollment": 2124,
			"parentRating": 4,
			"numReviews": 12,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 10,
				"College Readiness Rating": 10,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 33
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 12
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 45
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21134,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.412144,
			"lon": -122.113274,
			"name": "Palo Alto Infant-Toddler Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4111 Alma Street",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21134-Palo-Alto-Infant-Toddler-Center/",
				"reviews": "/california/palo-alto/21134-Palo-Alto-Infant-Toddler-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21134-Palo-Alto-Infant-Toddler-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13621,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.408283,
			"lon": -122.124519,
			"name": "Palo Alto Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "575 Arastradero Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/13621-Palo-Alto-Montessori-School/",
				"reviews": "/california/palo-alto/13621-Palo-Alto-Montessori-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/13621-Palo-Alto-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5618,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.430969,
			"lon": -122.11441,
			"name": "Palo Verde Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "3450 Louis Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 9,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5618-Palo-Verde-Elementary-School/",
				"reviews": "/california/palo-alto/5618-Palo-Verde-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5618-Palo-Verde-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "6%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 393,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 6
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 42
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 38
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 29133,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.417992,
			"lon": -122.127754,
			"name": "Palo Verde Kids Club Dc",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "3990 Ventura Court",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/29133-Palo-Verde-Kids-Club-Dc/",
				"reviews": "/california/palo-alto/29133-Palo-Verde-Kids-Club-Dc/#Reviews",
				"collegeSuccess": "/california/palo-alto/29133-Palo-Verde-Kids-Club-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21115,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.441109,
			"lon": -122.129791,
			"name": "Parents Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2328 Louis Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21115-Parents-Nursery-School/",
				"reviews": "/california/palo-alto/21115-Parents-Nursery-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21115-Parents-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21140,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.41473,
			"lon": -122.104919,
			"name": "Peninsula Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "525 San Antonio Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21140-Peninsula-Day-Care-Center/",
				"reviews": "/california/palo-alto/21140-Peninsula-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21140-Peninsula-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 32201,
			"districtId": 794,
			"districtName": "Ravenswood City Elementary",
			"districtCity": "East Palo Alto",
			"levelCode": "p",
			"lat": 37.461044,
			"lon": -122.133347,
			"name": "Ravenswood Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "951 O'connor Street East",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/32201-Ravenswood-Child-Development-Center/",
				"reviews": "/california/palo-alto/32201-Ravenswood-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/32201-Ravenswood-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 17,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21116,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.473637,
			"lon": -122.145035,
			"name": "Ravenswood Child Development Program",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2450 Ralmar St",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21116-Ravenswood-Child-Development-Program/",
				"reviews": "/california/palo-alto/21116-Ravenswood-Child-Development-Program/#Reviews",
				"collegeSuccess": "/california/palo-alto/21116-Ravenswood-Child-Development-Program/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21117,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.460999,
			"lon": -122.134178,
			"name": "Ravenwood Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "951 O' Connor Street",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21117-Ravenwood-Child-Development-Center/",
				"reviews": "/california/palo-alto/21117-Ravenwood-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21117-Ravenwood-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 25560,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.440002,
			"lon": -122.178513,
			"name": "Sand Hill School at Children's Health Council",
			"gradeLevels": "K-4, 6-8",
			"assigned": null,
			"address": {
				"street1": "650 Clark Way",
				"street2": null,
				"zip": "94304",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94304",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/25560-Sand-Hill-School-At-Childrens-Health-Council/",
				"reviews": "/california/palo-alto/25560-Sand-Hill-School-At-Childrens-Health-Council/#Reviews",
				"collegeSuccess": "/california/palo-alto/25560-Sand-Hill-School-At-Childrens-Health-Council/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9447,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.452564,
			"lon": -122.125504,
			"name": "Silicon Valley International School",
			"gradeLevels": "1-8",
			"assigned": null,
			"address": {
				"street1": "151 Laura Lane",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/9447-International-School-Of-The-Peninsula/",
				"reviews": "/california/palo-alto/9447-International-School-Of-The-Peninsula/#Reviews",
				"collegeSuccess": "/california/palo-alto/9447-International-School-Of-The-Peninsula/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 574,
			"parentRating": 4,
			"numReviews": 34,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 56
				},
				{
					"label": "Asian",
					"percentage": 35
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Hispanic",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 21129,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.417992,
			"lon": -122.127754,
			"name": "Sojourner Truth Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "3990 Ventura Court",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21129-Sojourner-Truth-Child-Development-Center/",
				"reviews": "/california/palo-alto/21129-Sojourner-Truth-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21129-Sojourner-Truth-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30214,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.424755,
			"lon": -122.11467,
			"name": "Sora International Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "701 East Meadow Drive",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/30214-Sora-International-Pre-School/",
				"reviews": "/california/palo-alto/30214-Sora-International-Pre-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/30214-Sora-International-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 9099,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.449039,
			"lon": -122.144928,
			"name": "St. Elizabeth Seton",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1095 Channing Avenue",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/9099-St.-Elizabeth-Seton/",
				"reviews": "/california/palo-alto/9099-St.-Elizabeth-Seton/#Reviews",
				"collegeSuccess": "/california/palo-alto/9099-St.-Elizabeth-Seton/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 274,
			"parentRating": 3,
			"numReviews": 5,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 86
				},
				{
					"label": "Pacific Islander",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "White",
					"percentage": 2
				},
				{
					"label": "Two or more races",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 16698,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.441154,
			"lon": -122.132042,
			"name": "Stratford School",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "870 North California Avenue",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/16698-Stratford-School/",
				"reviews": "/california/palo-alto/16698-Stratford-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/16698-Stratford-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 202,
			"parentRating": 4,
			"numReviews": 10,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21131,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.416458,
			"lon": -122.107323,
			"name": "Ta'Enna Nursery School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4000 Middlefield Rd",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21131-TaEnna-Nursery-School/",
				"reviews": "/california/palo-alto/21131-TaEnna-Nursery-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21131-TaEnna-Nursery-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21122,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.435177,
			"lon": -122.1203,
			"name": "Torah Academy",
			"gradeLevels": "4-5",
			"assigned": null,
			"address": {
				"street1": "3070 Louis Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21122-Torah-Academy/",
				"reviews": "/california/palo-alto/21122-Torah-Academy/#Reviews",
				"collegeSuccess": "/california/palo-alto/21122-Torah-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 6,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 13510,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m",
			"lat": 37.435177,
			"lon": -122.1203,
			"name": "Torah Academy",
			"gradeLevels": "6-7",
			"assigned": null,
			"address": {
				"street1": "3070 Louis Road",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/13510-Torah-Academy/",
				"reviews": "/california/palo-alto/13510-Torah-Academy/#Reviews",
				"collegeSuccess": "/california/palo-alto/13510-Torah-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 9,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 25491,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.444969,
			"lon": -122.146881,
			"name": "Tru",
			"gradeLevels": "K-6",
			"assigned": null,
			"address": {
				"street1": "1295 Middlefield Rd",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/25491-Tru/",
				"reviews": "/california/palo-alto/25491-Tru/#Reviews",
				"collegeSuccess": "/california/palo-alto/25491-Tru/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 24,
			"parentRating": 4,
			"numReviews": 17,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27962,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.443089,
			"lon": -122.143181,
			"name": "Walter Hayes Kids Club",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1525 Middlefield Road",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/27962-Walter-Hayes-Kids-Club/",
				"reviews": "/california/palo-alto/27962-Walter-Hayes-Kids-Club/#Reviews",
				"collegeSuccess": "/california/palo-alto/27962-Walter-Hayes-Kids-Club/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 5612,
			"districtId": 652,
			"districtName": "Palo Alto Unified",
			"districtCity": "Palo Alto",
			"levelCode": "e",
			"lat": 37.443089,
			"lon": -122.143181,
			"name": "Walter Hays Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1525 Middlefield Road",
				"street2": null,
				"zip": "94301",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94301",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/5612-Walter-Hays-Elementary-School/",
				"reviews": "/california/palo-alto/5612-Walter-Hays-Elementary-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/5612-Walter-Hays-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "9%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 371,
			"parentRating": 4,
			"numReviews": 2,
			"studentsPerTeacher": 15,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 9
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 26
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 15
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 41
				},
				{
					"label": "Two or more races",
					"percentage": 12
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21149,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.457794,
			"lon": -122.122566,
			"name": "Way We Grow Infant Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "P.O. Box 52003",
				"street2": null,
				"zip": "94303",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94303",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21149-Way-We-Grow-Infant-Child-Care-Center/",
				"reviews": "/california/palo-alto/21149-Way-We-Grow-Infant-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21149-Way-We-Grow-Infant-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24958,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.419548,
			"lon": -122.11248,
			"name": "Wellspring International School",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "505 E. Charleston Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/24958-Wellspring-International-School/",
				"reviews": "/california/palo-alto/24958-Wellspring-International-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/24958-Wellspring-International-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 7,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21128,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.381344,
			"lon": -122.180283,
			"name": "Whistle Stop Child Develop Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 51",
				"street2": null,
				"zip": "94304",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94304",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21128-Whistle-Stop-Child-Develop-Center/",
				"reviews": "/california/palo-alto/21128-Whistle-Stop-Child-Develop-Center/#Reviews",
				"collegeSuccess": "/california/palo-alto/21128-Whistle-Stop-Child-Develop-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 21143,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.401466,
			"lon": -122.128822,
			"name": "Young Life Christian Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "687 Arastradero Road",
				"street2": null,
				"zip": "94306",
				"city": "Palo Alto"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94306",
			"type": "school",
			"links": {
				"profile": "/california/palo-alto/21143-Young-Life-Christian-Pre-School/",
				"reviews": "/california/palo-alto/21143-Young-Life-Christian-Pre-School/#Reviews",
				"collegeSuccess": "/california/palo-alto/21143-Young-Life-Christian-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]