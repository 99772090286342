exports.NEWARK_LISTINGS = [
		{
			"closePrice": 1245000,
			"daysOnMovoto": 0,
			"id": "16497831-1ee0-4ae7-8f6d-0de338bbd8de",
			"tnImgPath": "https://pi.movoto.com/p/102/221151184_0_q6aJV3_p.jpeg",
			"listDate": null,
			"listingAgent": "Carmela Fratarcangeli",
			"listPrice": 999888,
			"lotSize": 1951,
			"sqftTotal": 1752,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221151184",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty EDH",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1991,
			"zipCode": "94560",
			"path": "newark-ca/6149-potrero-dr-newark-ca-94560-100_81415851/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e9fa4b53-4e86-4c21-b7a3-e908753ff267",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-14 00:00:00",
			"createdAt": "2021-12-08 23:54:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-08 23:54:22",
			"virtualTourLink": "https://www.tourfactory.com/idxr2939186",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.518626,
				"lng": -121.996163,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6149 Potrero Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1245000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2939186"
		},
		{
			"closePrice": 632500,
			"daysOnMovoto": 39,
			"id": "cac1f733-f98b-4987-bda1-b46adb592ed9",
			"tnImgPath": "https://pi.movoto.com/p/12/40974753_0_2AFVrM_p.jpeg",
			"listDate": "2021-11-20 00:00:00",
			"listingAgent": "Patricia Ratto",
			"listPrice": 609000,
			"lotSize": 0,
			"sqftTotal": 1060,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974753",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "94560",
			"path": "newark-ca/35088-lido-apt-j-newark-ca-94560-12_40974753/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "124f6c28-83c8-43f2-8de4-cd4907d2bd6f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-20 22:06:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.54708,
				"lng": -122.048662,
				"zipcode": "94560",
				"subPremise": "APT J",
				"address": "35088 Lido #J"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 632500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 560000,
			"daysOnMovoto": 46,
			"id": "f3fed5ec-6162-44b3-b80c-ca8272e98b18",
			"tnImgPath": "https://pi.movoto.com/p/12/40974038_0_NUFQEe_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Joseph O'Leary",
			"listPrice": 537000,
			"lotSize": 250000,
			"sqftTotal": 905,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974038",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty ONE Group Future",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1982,
			"zipCode": "94560",
			"path": "newark-ca/6378-joaquin-murieta-av-f-newark-ca-94560-12_28522062/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0b887c87-68ba-419c-aef5-b8b74e4e142d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-12 16:06:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.517189,
				"lng": -121.999652,
				"zipcode": "94560",
				"subPremise": "APT F",
				"address": "6378 Joaquin Murieta Ave #F"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 560000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1399970,
			"daysOnMovoto": 62,
			"id": "4040274f-f841-4409-b146-ea3038706d4a",
			"tnImgPath": "https://pi.movoto.com/p/12/40972414_0_mfvyQy_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Sue Hanna",
			"listPrice": 1298000,
			"lotSize": 13112,
			"sqftTotal": 1500,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972414",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Venture Sotheby's Intl Rlty",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1964,
			"zipCode": "94560",
			"path": "newark-ca/35861-spruce-st-newark-ca-94560/pid_w88bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "df40061a-c261-4504-80bf-2c542ebb3d89",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-10-27 23:36:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2929866",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.537726,
				"lng": -122.059175,
				"zipcode": "94560",
				"subPremise": "",
				"address": "35861 Spruce St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1399970,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2929866"
		},
		{
			"closePrice": 570000,
			"daysOnMovoto": 59,
			"id": "5e2411ee-f34d-4de1-9afa-dbd7bb316deb",
			"tnImgPath": "https://pi.movoto.com/p/12/40972642_0_2nuuI2_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Karunasagar Kotha",
			"listPrice": 575000,
			"lotSize": 9148,
			"sqftTotal": 890,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972642",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Windemere Realty Inc",
			"photoCount": 2,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1985,
			"zipCode": "94560",
			"path": "newark-ca/6266-civic-terrace-ave-apt-a-newark-ca-94560-12_40972642/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6289d07b-b99c-4809-a396-9d1cf3575a1b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-10-29 17:31:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.534345,
				"lng": -122.028185,
				"zipcode": "94560",
				"subPremise": "APT A",
				"address": "6266 Civic Terrace Ave #A"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 570000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1465000,
			"daysOnMovoto": 0,
			"id": "166a44c5-80f2-4562-8565-1c87589938ef",
			"tnImgPath": "https://pi.movoto.com/p/102/221145890_0_auJAMa_p.jpeg",
			"listDate": null,
			"listingAgent": "Jose Martinez",
			"listPrice": 1149000,
			"lotSize": 7723,
			"sqftTotal": 1512,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221145890",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1973,
			"zipCode": "94560",
			"path": "newark-ca/5823-sunrose-ave-newark-ca-94560/pid_tdgcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9b657918-ebb7-4b6f-9152-967aa433183f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-18 03:19:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 03:19:27",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.534934,
				"lng": -122.020234,
				"zipcode": "94560",
				"subPremise": "",
				"address": "5823 Sunrose Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1465000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 845000,
			"daysOnMovoto": 41,
			"id": "23f9a5c8-0eda-46c3-8098-1cca26dca20e",
			"tnImgPath": "https://pi.movoto.com/p/12/40973696_0_vqmrJb_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Patty Tovar",
			"listPrice": 799999,
			"lotSize": 5000,
			"sqftTotal": 1050,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973696",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1953,
			"zipCode": "94560",
			"path": "newark-ca/6279-dairy-ave-newark-ca-94560/pid_6tecvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b3b04727-9b8f-4185-89d8-473229ae0db8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-12 04:56:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.536455,
				"lng": -122.034533,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6279 Dairy Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 845000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1490000,
			"daysOnMovoto": 37,
			"id": "5cb098fe-c1e8-4e2a-9a49-c8029ad78e4a",
			"tnImgPath": "https://pi.movoto.com/p/12/40973004_0_3YUQaJ_p.jpeg",
			"listDate": "2021-11-09 00:00:00",
			"listingAgent": "Shikha Kapur",
			"listPrice": 1399999,
			"lotSize": 3080,
			"sqftTotal": 2192,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973004",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2019,
			"zipCode": "94560",
			"path": "newark-ca/37955-harbor-light-rd-newark-ca-94560-4001_38579811/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9ac49cba-d7aa-4deb-b1b7-c1b91c1b4a1f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-09 19:56:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.515699,
				"lng": -122.050396,
				"zipcode": "94560",
				"subPremise": "",
				"address": "37955 Harbor Light Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1490000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1245000,
			"daysOnMovoto": 45,
			"id": "a41f67d0-d4ff-4d8b-a388-3141274076c3",
			"tnImgPath": "https://pi.movoto.com/p/12/40972829_0_ffrirJ_p.jpeg",
			"listDate": "2021-10-31 00:00:00",
			"listingAgent": "Raeesa Rasheed",
			"listPrice": 1148000,
			"lotSize": 6300,
			"sqftTotal": 1480,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972829",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1961,
			"zipCode": "94560",
			"path": "newark-ca/8339-juniper-ave-newark-ca-94560-12_40972829/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9ebdf88b-399b-4a68-8abd-7989ed31dc36",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-10-31 22:41:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-31 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.525134,
				"lng": -122.049186,
				"zipcode": "94560",
				"subPremise": "",
				"address": "8339 Juniper Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1245000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1262800,
			"daysOnMovoto": 0,
			"id": "339f66f6-fffc-4bc6-a192-2773b96cd094",
			"tnImgPath": "https://pi.movoto.com/p/102/221141547_0_BjfUQz_p.jpeg",
			"listDate": null,
			"listingAgent": "Antonio Gallegos",
			"listPrice": 1260000,
			"lotSize": 7500,
			"sqftTotal": 2102,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221141547",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1960,
			"zipCode": "94560",
			"path": "newark-ca/36857-locust-st-newark-ca-94560/pid_q97bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 310000,
			"propertyId": "dff71d2a-2c8b-43ae-bd66-8d3f876eea01",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-11-05 21:14:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 21:14:29",
			"virtualTourLink": "https://player.vimeo.com/video/641670311",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.529397,
				"lng": -122.044191,
				"zipcode": "94560",
				"subPremise": "",
				"address": "36857 Locust St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1262800,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/641670311"
		},
		{
			"closePrice": 1130000,
			"daysOnMovoto": 59,
			"id": "134029c6-2bc9-4990-a29e-eda6272d82e6",
			"tnImgPath": "https://pi.movoto.com/p/12/40970675_0_FYIBYI_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Edward Kennedy",
			"listPrice": 1098000,
			"lotSize": 14645,
			"sqftTotal": 1829,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970675",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Allison James Estates & Homes",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "94560",
			"path": "newark-ca/37634-cape-cod-rd-newark-ca-94560-12_40970675/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "599a3367-b489-48ad-90cd-4b02ef0ee1ed",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-13 03:51:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2924696",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.518028,
				"lng": -122.051589,
				"zipcode": "94560",
				"subPremise": "",
				"address": "37634 Cape Cod Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1130000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2924696"
		},
		{
			"closePrice": 950000,
			"daysOnMovoto": 43,
			"id": "611947cf-0625-40e3-9c11-79d7edc70e3d",
			"tnImgPath": "https://pi.movoto.com/p/12/40972368_0_fYYQ3v_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Imran (Ron) Nasir",
			"listPrice": 848888,
			"lotSize": 7023,
			"sqftTotal": 1022,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972368",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Benchmark",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1948,
			"zipCode": "94560",
			"path": "newark-ca/36663-magnolia-st-newark-ca-94560-12_11070298/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "87a23d93-c681-4e69-b3b5-68e2c837e0cb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-10-27 19:16:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://36663magnoliast314862mls.f8re.com/Website/Index",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.532494,
				"lng": -122.041194,
				"zipcode": "94560",
				"subPremise": "",
				"address": "36663 Magnolia St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://36663magnoliast314862mls.f8re.com/Website/Index"
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 0,
			"id": "63bffc23-677f-4e4f-a1bf-cc919e752f33",
			"tnImgPath": "https://pi.movoto.com/p/102/221083295_0_rnBaVq_p.jpeg",
			"listDate": null,
			"listingAgent": "Osiel Luna",
			"listPrice": 1060000,
			"lotSize": 5249,
			"sqftTotal": 1462,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221083295",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "West Coast Marketing Group of Modesto, Inc.",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1953,
			"zipCode": "94560",
			"path": "newark-ca/6405-market-ave-newark-ca-94560-102_221083295/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "d5281063-27f3-4aa4-b23f-2735cc06ef1b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-07-19 07:29:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-19 07:29:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.530204,
				"lng": -122.026096,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6405 Market Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1563000,
			"daysOnMovoto": 29,
			"id": "b28ab1cb-fd9c-4317-9c79-f0b83fa4e8ba",
			"tnImgPath": "https://pi.movoto.com/p/12/40972965_0_iFfUri_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Helen Du",
			"listPrice": 1428000,
			"lotSize": 3080,
			"sqftTotal": 2192,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972965",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2018,
			"zipCode": "94560",
			"path": "newark-ca/37736-hickory-st-newark-ca-94560-12_40972965/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e5d5d338-4a74-4e3c-b91f-6698447f4c87",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-11-04 22:41:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=Lbe92z1iaf4&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.517013,
				"lng": -122.051178,
				"zipcode": "94560",
				"subPremise": "",
				"address": "37736 Hickory St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1563000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=Lbe92z1iaf4&brand=0"
		},
		{
			"closePrice": 725000,
			"daysOnMovoto": 34,
			"id": "68180780-fde6-4e6f-ab93-6e82e5bc0b06",
			"tnImgPath": "https://pi.movoto.com/p/12/40972536_0_6beBr6_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Renee Vargas",
			"listPrice": 700000,
			"lotSize": 1368,
			"sqftTotal": 1164,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972536",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1971,
			"zipCode": "94560",
			"path": "newark-ca/35175-lido-blvd-newark-ca-94560/pid_a2bcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "29198b2e-126e-41ab-bf0c-bf4dacb43fb0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-10-28 21:26:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": "https://www.cbhometour.com/35175-Lido-Blvd-Newark-CA-94560/mlsindex.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.546372,
				"lng": -122.049181,
				"zipcode": "94560",
				"subPremise": "",
				"address": "35175 Lido Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.cbhometour.com/35175-Lido-Blvd-Newark-CA-94560/mlsindex.html"
		},
		{
			"closePrice": 569000,
			"daysOnMovoto": 70,
			"id": "636b5fac-f810-47c9-a133-d61ad0352a5e",
			"tnImgPath": "https://pi.movoto.com/p/12/40967602_0_iUJJIA_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Larry Rapiz",
			"listPrice": 529888,
			"lotSize": 108413,
			"sqftTotal": 903,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967602",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1982,
			"zipCode": "94560",
			"path": "newark-ca/6714-jarvis-ave-newark-ca-94560-12_11152886/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f065c656-fcda-42f4-a8a0-879b56dffdd9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-09-22 16:36:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.542826,
				"lng": -122.054518,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6714 Jarvis Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 569000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1120000,
			"daysOnMovoto": 90,
			"id": "4c041531-9f7e-4aec-99c2-906954657b43",
			"tnImgPath": "https://pi.movoto.com/p/12/40965709_0_ZRFBmj_p.jpeg",
			"listDate": "2021-09-02 00:00:00",
			"listingAgent": "Fernando Roman",
			"listPrice": 1136000,
			"lotSize": 1950,
			"sqftTotal": 1627,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965709",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Bay Cities Rlty & Home Loan",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1996,
			"zipCode": "94560",
			"path": "newark-ca/39888-potrero-dr-newark-ca-94560/pid_m6tfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "351ddb0d-7c3f-47a2-ae62-3fefa8fe6c91",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-01 00:00:00",
			"createdAt": "2021-09-03 04:16:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-02 00:00:00",
			"virtualTourLink": "https://www.dropbox.com/s/xd3dayq0nosc60j/39888%20Potrero%20Final.mov?dl=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.516433,
				"lng": -121.993674,
				"zipcode": "94560",
				"subPremise": "",
				"address": "39888 Potrero Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1120000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.dropbox.com/s/xd3dayq0nosc60j/39888%20Potrero%20Final.mov?dl=0"
		},
		{
			"closePrice": 1151655,
			"daysOnMovoto": 33,
			"id": "72b349ca-b23f-4d46-a610-6096b70eaafc",
			"tnImgPath": "https://pi.movoto.com/p/12/40971743_0_zbnZ3R_p.jpeg",
			"listDate": "2021-10-28 00:00:00",
			"listingAgent": "Paul Padgett",
			"listPrice": 998950,
			"lotSize": 4005,
			"sqftTotal": 1450,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971743",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Bishop & Assoc. RE Sales",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1975,
			"zipCode": "94560",
			"path": "newark-ca/36909-port-tidewood-st-newark-ca-94560-12_28510507/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2674ac37-62e4-4a51-99d8-27d659d649a1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-28 20:36:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.54527,
				"lng": -122.025564,
				"zipcode": "94560",
				"subPremise": "",
				"address": "36909 Port Tidewood St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1151655,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1235000,
			"daysOnMovoto": 35,
			"id": "fd8bb0c5-f237-47d0-89bd-8a298951d859",
			"tnImgPath": "https://pi.movoto.com/p/12/40972065_0_f2U6YM_p.jpeg",
			"listDate": "2021-10-26 00:00:00",
			"listingAgent": "Raymond Liu",
			"listPrice": 1120000,
			"lotSize": 14238,
			"sqftTotal": 2018,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972065",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "MAXREAL",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2018,
			"zipCode": "94560",
			"path": "newark-ca/8575-leeward-way-newark-ca-94560-12_40972065/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "dfd53b73-9c71-4049-8e7f-1b678523fb97",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-26 21:06:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/634253584",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.520341,
				"lng": -122.050678,
				"zipcode": "94560",
				"subPremise": "",
				"address": "8575 Leeward Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1235000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/634253584"
		},
		{
			"closePrice": 945000,
			"daysOnMovoto": 34,
			"id": "95f98561-79f5-41bb-bf3e-6f94bca7442c",
			"tnImgPath": "https://pi.movoto.com/p/12/40971136_0_NbfqMi_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Helen Du",
			"listPrice": 899000,
			"lotSize": 0,
			"sqftTotal": 1424,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971136",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2019,
			"zipCode": "94560",
			"path": "newark-ca/8547-leeward-way-newark-ca-94560-12_40971136/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bc3f62e6-d8c2-4d65-8c79-7c0e59f86ca3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-21 23:16:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=11QdVLbV3Lv&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.52039,
				"lng": -122.050415,
				"zipcode": "94560",
				"subPremise": "",
				"address": "8547 Leeward Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 945000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=11QdVLbV3Lv&brand=0"
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 29,
			"id": "03380b06-2c28-4a20-a84a-c7c5a849419c",
			"tnImgPath": "https://pi.movoto.com/p/12/40971538_0_uii2bZ_p.jpeg",
			"listDate": "2021-10-19 00:00:00",
			"listingAgent": "Patrick MacCartee",
			"listPrice": 1499000,
			"lotSize": 3460,
			"sqftTotal": 2293,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971538",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Nova Real Estate",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2018,
			"zipCode": "94560",
			"path": "newark-ca/8439-bluetide-way-newark-ca-94560-12_40971538/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2a1b2d7e-18a5-4e5b-b286-7485bd8e2dfd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-10-20 04:56:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-19 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=PAguoSXa3Da&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.519713,
				"lng": -122.047555,
				"zipcode": "94560",
				"subPremise": "",
				"address": "8439 Bluetide Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=PAguoSXa3Da&brand=0"
		},
		{
			"closePrice": 1800000,
			"daysOnMovoto": 35,
			"id": "b99eb3e4-d2aa-4dbe-a301-cbff1dac5549",
			"tnImgPath": "https://pi.movoto.com/p/12/40970702_0_EuavBE_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Brooke Crossland",
			"listPrice": 1788000,
			"lotSize": 9431,
			"sqftTotal": 2184,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970702",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1978,
			"zipCode": "94560",
			"path": "newark-ca/35641-lundy-dr-newark-ca-94560/pid_flgcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ddbb7ae2-288f-44c3-b84e-281b0f492d97",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-10-13 16:26:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/629259216",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.553792,
				"lng": -122.03682,
				"zipcode": "94560",
				"subPremise": "",
				"address": "35641 Lundy Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/629259216"
		},
		{
			"closePrice": 1978000,
			"daysOnMovoto": 38,
			"id": "9b6c04b1-8fef-4f45-960b-443a164b73af",
			"tnImgPath": "https://pi.movoto.com/p/12/40969281_0_UzvMeN_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Kelly Hopkins",
			"listPrice": 1750000,
			"lotSize": 4284,
			"sqftTotal": 2299,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969281",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2019,
			"zipCode": "94560",
			"path": "newark-ca/6705-tule-elk-way-newark-ca-94560-12_40969281/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "719ef173-b7b2-4396-a852-c8f7f55507c6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-08 00:00:00",
			"createdAt": "2021-10-01 14:41:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=nMpQathHXbs&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.513467,
				"lng": -121.998563,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6705 Tule Elk Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1978000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=nMpQathHXbs&brand=0"
		},
		{
			"closePrice": 1035000,
			"daysOnMovoto": 42,
			"id": "c8955b3b-b8ef-4ce6-9aac-97de25250f98",
			"tnImgPath": "https://pi.movoto.com/p/12/40968820_0_QBY7rR_p.jpeg",
			"listDate": "2021-09-27 00:00:00",
			"listingAgent": "Helen Du",
			"listPrice": 1009000,
			"lotSize": 0,
			"sqftTotal": 1566,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968820",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 7,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2016,
			"zipCode": "94560",
			"path": "newark-ca/37659-breakers-rd-newark-ca-94560-12_40883488/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6045513a-eb47-45de-b5e5-cac6c8aa4af5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-08 00:00:00",
			"createdAt": "2021-09-28 05:06:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.518642,
				"lng": -122.048892,
				"zipcode": "94560",
				"subPremise": "",
				"address": "37659 Breakers Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1035000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1215000,
			"daysOnMovoto": 36,
			"id": "b58187ca-b521-4b28-97ae-68944f7b9821",
			"tnImgPath": "https://pi.movoto.com/p/12/40968577_0_niEvB6_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Melia Kwee",
			"listPrice": 1085000,
			"lotSize": 7000,
			"sqftTotal": 1196,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968577",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94560",
			"path": "newark-ca/35960-brandywine-st-newark-ca-94560/pid_jb6bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3532160e-d896-4718-aac2-54e24cbbb793",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-30 23:31:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.538845,
				"lng": -122.053004,
				"zipcode": "94560",
				"subPremise": "",
				"address": "35960 Brandywine St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1215000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1400000,
			"daysOnMovoto": 39,
			"id": "b5899967-9833-4fad-98a5-6def614a83fb",
			"tnImgPath": "https://pi.movoto.com/p/12/40968803_0_jyUMfv_p.jpeg",
			"listDate": "2021-09-27 00:00:00",
			"listingAgent": "A.J. Cohen",
			"listPrice": 1139900,
			"lotSize": 6500,
			"sqftTotal": 1707,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968803",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1967,
			"zipCode": "94560",
			"path": "newark-ca/5050-winsford-ct-newark-ca-94560/pid_phbcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d17256d1-b994-4367-8eab-3e686ea3d714",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-28 00:36:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-27 00:00:00",
			"virtualTourLink": "http://www.5050winsford.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.553642,
				"lng": -122.031558,
				"zipcode": "94560",
				"subPremise": "",
				"address": "5050 Winsford Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.5050winsford.com/?mls"
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 24,
			"id": "1fb98d0e-2e6b-46d3-ad6a-31f4f725875e",
			"tnImgPath": "https://pi.movoto.com/p/12/40970466_0_eB633y_p.jpeg",
			"listDate": "2021-10-11 00:00:00",
			"listingAgent": "Gagan Singh",
			"listPrice": 1300000,
			"lotSize": 7735,
			"sqftTotal": 1724,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970466",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "94560",
			"path": "newark-ca/38850-bluegrass-ct-newark-ca-94560/pid_90bcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "658accae-b768-4e9d-9895-8c73eaf7e28a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-10-11 14:41:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.525488,
				"lng": -122.00909,
				"zipcode": "94560",
				"subPremise": "",
				"address": "38850 Bluegrass Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 693000,
			"daysOnMovoto": 34,
			"id": "d634c137-d145-4c51-92b5-9a3d2d78b822",
			"tnImgPath": "https://pi.movoto.com/p/12/40969253_0_z2zRNJ_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Joe Stevenson",
			"listPrice": 669000,
			"lotSize": 108413,
			"sqftTotal": 1131,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969253",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Radius Agent Realty",
			"photoCount": 10,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1982,
			"zipCode": "94560",
			"path": "newark-ca/6822-jarvis-ave-newark-ca-94560-12_11088156/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "39516867-5c53-42f4-84c5-d79820775e26",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-10-01 03:11:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.543339,
				"lng": -122.054369,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6822 Jarvis Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 693000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 43,
			"id": "5f65ec4e-38c4-4e8a-aa28-7f01ab431e22",
			"tnImgPath": "https://pi.movoto.com/p/12/40967968_0_vmv6fi_p.jpeg",
			"listDate": "2021-09-21 00:00:00",
			"listingAgent": "Lynn Chin",
			"listPrice": 1159800,
			"lotSize": 10783,
			"sqftTotal": 2191,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967968",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2017,
			"zipCode": "94560",
			"path": "newark-ca/8565-pierview-way-newark-ca-94560-12_40967968/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c4dde503-e152-4656-a6c1-fa4b0c25c141",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-09-21 21:26:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=fSPMuMfBrGr&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.518826,
				"lng": -122.050878,
				"zipcode": "94560",
				"subPremise": "",
				"address": "8565 Pierview Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=fSPMuMfBrGr&brand=0"
		},
		{
			"closePrice": 595000,
			"daysOnMovoto": 0,
			"id": "b635092a-43e2-455e-ad3d-aee5fb280d7d",
			"tnImgPath": "https://pi.movoto.com/p/102/221122265_0_uyeaUV_p.jpeg",
			"listDate": null,
			"listingAgent": "Tamim Omary",
			"listPrice": 585000,
			"lotSize": 0,
			"sqftTotal": 1071,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221122265",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Go2Realty Pros, Inc.",
			"photoCount": 20,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1985,
			"zipCode": "94560",
			"path": "newark-ca/39997-cedar-blvd-154-newark-ca-94560/pid_blufvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b7a47a33-a7a6-4f8a-aeaa-1fe6c3052e0d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-09-24 16:34:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 16:34:36",
			"virtualTourLink": "https://my.matterport.com/show/?m=Tp78vb2PXtd",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.518124,
				"lng": -121.993145,
				"zipcode": "94560",
				"subPremise": "APT 154",
				"address": "39997 Cedar Blvd #154"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 595000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=Tp78vb2PXtd"
		},
		{
			"closePrice": 810000,
			"daysOnMovoto": 33,
			"id": "2a82abcb-6257-459a-aaa2-e62d7334a63c",
			"tnImgPath": "https://pi.movoto.com/p/12/40969079_0_qUBN72_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Carol Zhang",
			"listPrice": 759000,
			"lotSize": 1171,
			"sqftTotal": 1456,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969079",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1981,
			"zipCode": "94560",
			"path": "newark-ca/6237-joaquin-murieta-ave-apt-a-apt-a-newark-ca-94560-12_40969079/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c5d2e918-deb1-4244-abc3-9074a7aa7681",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-30 11:31:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=VFSNpZuKyxb",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.519024,
				"lng": -121.999704,
				"zipcode": "94560",
				"subPremise": "APT A",
				"address": "6237 Joaquin Murieta Ave Apt A #A"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=VFSNpZuKyxb"
		},
		{
			"closePrice": 1400000,
			"daysOnMovoto": 53,
			"id": "e43217f1-a640-4b2b-937b-b3ad2e4f6ef8",
			"tnImgPath": "https://pi.movoto.com/p/12/40966742_0_emAANb_p.jpeg",
			"listDate": "2021-09-10 00:00:00",
			"listingAgent": "Dorothy Broderson",
			"listPrice": 1375000,
			"lotSize": 10240,
			"sqftTotal": 1489,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966742",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1976,
			"zipCode": "94560",
			"path": "newark-ca/38124-guava-dr-newark-ca-94560/pid_qjfcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "34d7b3c7-7ea3-4a67-a962-8eac05775af9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-10 23:41:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/9886994",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.529242,
				"lng": -122.018436,
				"zipcode": "94560",
				"subPremise": "",
				"address": "38124 Guava Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/9886994"
		},
		{
			"closePrice": 1075000,
			"daysOnMovoto": 92,
			"id": "cf89b4f7-c689-4105-bf88-fdb726c38d31",
			"tnImgPath": "https://pi.movoto.com/p/12/40961407_0_EjbQMA_p.jpeg",
			"listDate": "2021-08-02 00:00:00",
			"listingAgent": "Imran (Ron) Nasir",
			"listPrice": 1150000,
			"lotSize": 16574,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40961407",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Keller Williams Benchmark",
			"photoCount": 1,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Lots / Land",
			"yearBuilt": null,
			"zipCode": "94560",
			"path": "newark-ca/37501-cherry-st-newark-ca-94560/pid_8l7bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9f294724-3bd5-4279-b555-f923695f1f26",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-08-02 16:01:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.528987,
				"lng": -122.0269,
				"zipcode": "94560",
				"subPremise": "",
				"address": "37501 Cherry St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1075000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 520000,
			"daysOnMovoto": 0,
			"id": "801fe7ce-ccd8-40cc-a2b0-637bd4252989",
			"tnImgPath": "https://pi.movoto.com/p/102/221126464_0_EmQFIE_p.jpeg",
			"listDate": null,
			"listingAgent": "Deena Brown",
			"listPrice": 545000,
			"lotSize": 800,
			"sqftTotal": 1132,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221126464",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Century 21 Select Real Estate",
			"photoCount": 42,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1981,
			"zipCode": "94560",
			"path": "newark-ca/6371-joaquin-murieta-ave-b-newark-ca-94560/pid_xotfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "43d31d7c-f86a-4aa9-9006-a0854711dcd3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-01 00:00:00",
			"createdAt": "2021-10-02 20:19:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-02 20:19:25",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.51787,
				"lng": -122.001096,
				"zipcode": "94560",
				"subPremise": "APT B",
				"address": "6371 Joaquin Murieta Ave #B"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 520000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1425000,
			"daysOnMovoto": 42,
			"id": "4536d602-6bac-488b-99bd-27f02b2e87fc",
			"tnImgPath": "https://pi.movoto.com/p/12/40967505_0_IR22Jy_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Helen Du",
			"listPrice": 1399000,
			"lotSize": 2222,
			"sqftTotal": 2364,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967505",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2018,
			"zipCode": "94560",
			"path": "newark-ca/37717-bay-crest-rd-newark-ca-94560-12_40967505/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "880961ce-c519-4672-8b2f-ddf6cdedb11b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-09-17 08:11:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=1j9LcXSMkj1&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.519008,
				"lng": -122.047625,
				"zipcode": "94560",
				"subPremise": "",
				"address": "37717 Bay Crest Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=1j9LcXSMkj1&brand=0"
		},
		{
			"closePrice": 1425000,
			"daysOnMovoto": 31,
			"id": "4b6b8b2a-1ef8-430b-b535-b1e212413af4",
			"tnImgPath": "https://pi.movoto.com/p/12/40968808_0_FBvMei_p.jpeg",
			"listDate": "2021-09-27 00:00:00",
			"listingAgent": "Chris Hotaki",
			"listPrice": 1175000,
			"lotSize": 7107,
			"sqftTotal": 1315,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968808",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94560",
			"path": "newark-ca/6257-rockrose-newark-ca-94560-12_40968808/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d7fa06f0-d03f-424a-8320-3a065ae90b2b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-28 01:11:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.525894,
				"lng": -122.013791,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6257 Rockrose"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1160777,
			"daysOnMovoto": 35,
			"id": "f979c666-5db2-4dd1-930d-4fb8ec562543",
			"tnImgPath": "https://pi.movoto.com/p/12/40968205_0_Nv3aey_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Nancy Salonga",
			"listPrice": 980000,
			"lotSize": 5456,
			"sqftTotal": 1519,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968205",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Your Family Realty Center",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1979,
			"zipCode": "94560",
			"path": "newark-ca/6372-truckee-ct-newark-ca-94560/pid_e7wfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bea3d682-9448-4c5e-bb92-94d8075ca56e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-23 14:31:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.520153,
				"lng": -122.0058,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6372 Truckee Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1160777,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1220000,
			"daysOnMovoto": 36,
			"id": "e195a815-dab9-412b-89c6-176bce5a39ed",
			"tnImgPath": "https://pi.movoto.com/p/12/40968143_0_Ba6JbE_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Helen Du",
			"listPrice": 1159800,
			"lotSize": 0,
			"sqftTotal": 2191,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968143",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2017,
			"zipCode": "94560",
			"path": "newark-ca/8552-seawind-way-newark-ca-94560-12_40968143/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1f6feaaf-6689-4266-a127-6420c3fe659d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-23 00:41:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=r2demSnQVoY&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.519299,
				"lng": -122.050761,
				"zipcode": "94560",
				"subPremise": "",
				"address": "8552 Seawind Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1220000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=r2demSnQVoY&brand=0"
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 48,
			"id": "f1407571-583e-4a8a-beb7-e3941764258f",
			"tnImgPath": "https://pi.movoto.com/p/12/40966691_0_ayn6yb_p.jpeg",
			"listDate": "2021-09-10 00:00:00",
			"listingAgent": "Rick Geha",
			"listPrice": 1350000,
			"lotSize": 4081,
			"sqftTotal": 2168,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966691",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2003,
			"zipCode": "94560",
			"path": "newark-ca/36403-dijon-dr-newark-ca-94560/pid_g88bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "47fb3e1a-08cb-43cf-942d-456cd9caee8c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-10 20:56:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 00:00:00",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.540502,
				"lng": -122.0344,
				"zipcode": "94560",
				"subPremise": "",
				"address": "36403 Dijon Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 1420000,
			"daysOnMovoto": 39,
			"id": "0267716a-3b0d-4793-918a-5f214c59fc1f",
			"tnImgPath": "https://pi.movoto.com/p/12/40967581_0_nI3MVz_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Avonne Suen",
			"listPrice": 1299000,
			"lotSize": 7200,
			"sqftTotal": 1776,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967581",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94560",
			"path": "newark-ca/6378-moores-ave-newark-ca-94560/pid_waecvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "867dd6ad-af08-43f1-9326-b389d122b80c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-17 18:21:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": "https://6378MooresAve46802mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.524297,
				"lng": -122.013662,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6378 Moores Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1420000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://6378MooresAve46802mls.f8re.com/"
		},
		{
			"closePrice": 1698000,
			"daysOnMovoto": 59,
			"id": "56b20760-980b-4ec1-ae8b-f0d4d7464250",
			"tnImgPath": "https://pi.movoto.com/p/12/40964426_0_v2bzuZ_p.jpeg",
			"listDate": "2021-08-27 00:00:00",
			"listingAgent": "Joe Schembri",
			"listPrice": 1698000,
			"lotSize": 12056,
			"sqftTotal": 2123,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964426",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 6,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1978,
			"zipCode": "94560",
			"path": "newark-ca/5202-channel-dr-newark-ca-94560/pid_6dccvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7e7016b7-8133-4456-8552-4ab645b2f8ad",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-08-27 15:56:50",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.553254,
				"lng": -122.035688,
				"zipcode": "94560",
				"subPremise": "",
				"address": "5202 Channel Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1698000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 29,
			"id": "3ae3ab45-1f68-4b90-b80e-5e3ffb87ae85",
			"tnImgPath": "https://pi.movoto.com/p/12/40968426_0_rQbfaZ_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Helen Du",
			"listPrice": 1399000,
			"lotSize": 2735,
			"sqftTotal": 2426,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968426",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2018,
			"zipCode": "94560",
			"path": "newark-ca/37693-bay-crest-rd-newark-ca-94560-12_40968426/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bccdc3f6-1276-4e8e-b7d2-263ec49e3b77",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-24 06:36:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=sQwPqGo4cvN&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.519271,
				"lng": -122.047733,
				"zipcode": "94560",
				"subPremise": "",
				"address": "37693 Bay Crest Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=sQwPqGo4cvN&brand=0"
		},
		{
			"closePrice": 1475000,
			"daysOnMovoto": 35,
			"id": "8b0a3b3e-c64e-4c29-accd-2a4d8bfde958",
			"tnImgPath": "https://pi.movoto.com/p/12/40967555_0_i7Q6eM_p.jpeg",
			"listDate": "2021-09-17 00:00:00",
			"listingAgent": "Nicole Causey",
			"listPrice": 1395000,
			"lotSize": 8730,
			"sqftTotal": 1953,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967555",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1976,
			"zipCode": "94560",
			"path": "newark-ca/8026-par-ct-newark-ca-94560-12_40022102/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "44a9f54a-19e8-4554-9975-e12ea840ad72",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-17 17:26:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-17 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=6ic2cj518fD&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.533814,
				"lng": -122.053049,
				"zipcode": "94560",
				"subPremise": "",
				"address": "8026 Par Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=6ic2cj518fD&brand=0"
		},
		{
			"closePrice": 1520000,
			"daysOnMovoto": 37,
			"id": "fe4cf56e-1142-4a3f-9a60-eace9c593254",
			"tnImgPath": "https://pi.movoto.com/p/12/40967175_0_F63mVj_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Brooke Crossland",
			"listPrice": 1398000,
			"lotSize": 8733,
			"sqftTotal": 1944,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967175",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1969,
			"zipCode": "94560",
			"path": "newark-ca/35295-farnham-dr-newark-ca-94560/pid_mzbcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "063d6da9-9bca-4dcd-abcc-fafb908ecdec",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-15 19:01:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/605414841",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.559588,
				"lng": -122.037708,
				"zipcode": "94560",
				"subPremise": "",
				"address": "35295 Farnham Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1520000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/605414841"
		},
		{
			"closePrice": 1320000,
			"daysOnMovoto": 41,
			"id": "79170b18-8508-4a75-9e26-f9401ac3cbfa",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81862144_0_UujQ7M_p.jpeg",
			"listDate": "2021-09-10 11:27:39",
			"listingAgent": "Lana Hien Nguyen",
			"listPrice": 1289000,
			"lotSize": 10184,
			"sqftTotal": 2268,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81862144",
			"bath": null,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Century 21 Real Estate Alliance",
			"photoCount": 10,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1962,
			"zipCode": "94560",
			"path": "newark-ca/37124-oak-st-newark-ca-94560/pid_jk6bvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "30765355-3448-4d84-83d3-4955b07cda9f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-10 18:32:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-10 11:27:39",
			"virtualTourLink": "https://www.youtube.com/embed/mSbAYro1ISw",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.527417,
				"lng": -122.038663,
				"zipcode": "94560",
				"subPremise": "",
				"address": "37124 Oak St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1320000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/mSbAYro1ISw"
		},
		{
			"closePrice": 1068000,
			"daysOnMovoto": 44,
			"id": "713c40e5-fa00-4cbc-b55a-bf675d0c2043",
			"tnImgPath": "https://pi.movoto.com/p/12/40966282_0_ZFZYIz_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Kelly Hopkins",
			"listPrice": 959000,
			"lotSize": 2950,
			"sqftTotal": 1672,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966282",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1987,
			"zipCode": "94560",
			"path": "newark-ca/6244-jarvis-ave-newark-ca-94560/pid_ubgcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0a152bb4-634d-4fc7-baf1-fed275ab9c3e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-22 00:00:00",
			"createdAt": "2021-09-08 22:11:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.547731,
				"lng": -122.049774,
				"zipcode": "94560",
				"subPremise": "",
				"address": "6244 Jarvis Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1068000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 990000,
			"daysOnMovoto": 35,
			"id": "7143513a-0196-411a-af49-0cbbc817def6",
			"tnImgPath": "https://pi.movoto.com/p/12/40967393_0_6MyARB_p.jpeg",
			"listDate": "2021-09-16 00:00:00",
			"listingAgent": "William Aboumrad",
			"listPrice": 869000,
			"lotSize": 6300,
			"sqftTotal": 1118,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967393",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1959,
			"zipCode": "94560",
			"path": "newark-ca/35973-cherry-st-newark-ca-94560/pid_nfbcvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 10000,
			"propertyId": "684679b8-44c6-491f-8269-fd4bf860876c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-09-16 22:01:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.541838,
				"lng": -122.044901,
				"zipcode": "94560",
				"subPremise": "",
				"address": "35973 Cherry St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 990000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"address": "Undisclosed",
			"closePrice": 1300000,
			"daysOnMovoto": 66,
			"id": "3ddfbd58-b561-4923-87a7-a7456729fa0f",
			"tnImgPath": "https://pi.movoto.com/p/12/40963207_0_AvRmBb_p.jpeg",
			"listDate": "2021-08-13 00:00:00",
			"listingAgent": "Viola Manago",
			"listPrice": 1250000,
			"lotSize": 6300,
			"sqftTotal": 1642,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963207",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Bravo Realty",
			"photoCount": 10,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1976,
			"zipCode": "94560",
			"path": "newark-ca/no-address-newark-ca-94560-12_40963207/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0b9261c2-7ed2-49b2-a37a-a217a030a0c9",
			"visibility": "ALL_ADDRESS_SIGNED_IN",
			"soldDate": "2021-10-18 00:00:00",
			"createdAt": "2021-08-14 03:11:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 0,
				"lng": 0,
				"zipcode": "94560",
				"subPremise": "",
				"address": "Undisclosed",
				"address2": "6036 Campanula Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"longitude": 0,
			"latitude": 0,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1620000,
			"daysOnMovoto": 37,
			"id": "d3240860-87e2-4343-be25-1f523d3b1c93",
			"tnImgPath": "https://pi.movoto.com/p/12/40965455_0_j72Qyi_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Gowthaman Gurusamy",
			"listPrice": 1620000,
			"lotSize": 4800,
			"sqftTotal": 2327,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965455",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty Champion Inc",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2003,
			"zipCode": "94560",
			"path": "newark-ca/38381-amaryllis-pl-newark-ca-94560-12_40461613/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 121112,
			"propertyId": "75c00641-459f-45e8-a881-a51700f6f145",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-09-08 21:36:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.531813,
				"lng": -122.01073,
				"zipcode": "94560",
				"subPremise": "",
				"address": "38381 Amaryllis Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1620000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1210000,
			"daysOnMovoto": 0,
			"id": "65f588cb-8da9-4e55-b031-ebc8499c43b5",
			"tnImgPath": "https://pi.movoto.com/p/102/221084252_0_qRVY3I_p.jpeg",
			"listDate": null,
			"listingAgent": "Carlos Cantu",
			"listPrice": 1214900,
			"lotSize": 4312,
			"sqftTotal": 1468,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221084252",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1986,
			"zipCode": "94560",
			"path": "newark-ca/smith-ave-newark-ca-94560-100_80134032/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -60000,
			"propertyId": "e8a3efef-d7d3-4269-ab26-a2d7518b950b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-07-14 14:19:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-14 14:19:25",
			"virtualTourLink": "https:////my.matterport.com/show/?m=gwFuQETSsDH&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Newark",
				"lat": 37.53095,
				"lng": -122.013298,
				"zipcode": "94560",
				"subPremise": "",
				"address": "5896 Smith Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1210000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https:////my.matterport.com/show/?m=gwFuQETSsDH&mls=1"
		}
	]