exports.FREMONT_LISTINGS = [
		{
			"closePrice": 587000,
			"daysOnMovoto": 39,
			"id": "a0633c04-2530-452f-bc47-28b3912ddf81",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871729_0_nNj7Av_p.jpeg",
			"listDate": "2021-12-03 14:35:47",
			"listingAgent": "Aman Ghotra",
			"listPrice": 549000,
			"lotSize": null,
			"sqftTotal": 882,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871729",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Pacific Home Brokers",
			"photoCount": 41,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1982,
			"zipCode": "94538",
			"path": "fremont-ca/1975-barrymore-common-apt-t-fremont-ca-94538-100_ml81871729/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "40db1f77-e3f3-4213-a235-c791a188c89c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-12-03 22:42:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-03 14:35:47",
			"virtualTourLink": "https://player.vimeo.com/video/652839650",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.553925,
				"lng": -121.970568,
				"zipcode": "94538",
				"subPremise": "APT T",
				"address": "1975 Barrymore Common #T"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 587000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/652839650"
		},
		{
			"closePrice": 1205000,
			"daysOnMovoto": 55,
			"id": "ac846536-72f7-419e-a7e8-bf5519bdffc3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81870484_0_vyNffq_p.jpeg",
			"listDate": "2021-11-17 15:53:52",
			"listingAgent": "Laxmi Penupothula",
			"listPrice": 1099900,
			"lotSize": 1137,
			"sqftTotal": 1448,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81870484",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1997,
			"zipCode": "94538",
			"path": "fremont-ca/3669-dickenson-common-fremont-ca-94538-100_ml81870484/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "85d19af8-4b1e-41b1-81e1-238730595a8a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-11-17 23:57:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 15:53:52",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.544613,
				"lng": -121.973447,
				"zipcode": "94538",
				"subPremise": "",
				"address": "3669 Dickenson Common"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1205000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1190000,
			"daysOnMovoto": 0,
			"id": "dfc9df56-bc2c-4933-9cdd-f3cc8682020d",
			"tnImgPath": "https://pi.movoto.com/p/102/221142464_0_ufiIQZ_p.jpeg",
			"listDate": null,
			"listingAgent": "Johnny Formoso",
			"listPrice": 968000,
			"lotSize": 24394,
			"sqftTotal": 1387,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221142464",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Gold Laguna",
			"photoCount": 50,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2007,
			"zipCode": "94555",
			"path": "fremont-ca/34120-spezia-ter-apt-213-fremont-ca-94555-102_221142464/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1acbd39a-61ce-4547-a682-2cfea2ec5a18",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-11-29 08:24:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-29 08:24:15",
			"virtualTourLink": "https://www.youtube.com/embed/iBHs-DkRgfo",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.561137,
				"lng": -122.061487,
				"zipcode": "94555",
				"subPremise": "APT 213",
				"address": "34120 Spezia Ter #213"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1190000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/iBHs-DkRgfo"
		},
		{
			"closePrice": 2080000,
			"daysOnMovoto": 34,
			"id": "7c2903d6-9bf6-470b-a786-8402e5cfbd33",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871951_0_bIRVJ3_p.jpeg",
			"listDate": "2021-12-07 00:00:00",
			"listingAgent": "Gary Yip",
			"listPrice": 1698000,
			"lotSize": 3494,
			"sqftTotal": 1685,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871951",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Bay One Realty & Financial Service Inc",
			"photoCount": 46,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1989,
			"zipCode": "94539",
			"path": "fremont-ca/670-perry-common-fremont-ca-94539-100_ml81871951/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "82a288ff-2bef-4184-9383-70cf5a2fa836",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-10 00:00:00",
			"createdAt": "2021-12-07 15:47:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.531034,
				"lng": -121.927823,
				"zipcode": "94539",
				"subPremise": "",
				"address": "670 Perry Common"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2080000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1876000,
			"daysOnMovoto": 63,
			"id": "3c015e1c-4ef7-464c-83c7-dd87a911effc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81868881_0_UjQUAf_p.jpeg",
			"listDate": "2021-11-02 10:18:02",
			"listingAgent": "Laxmi Penupothula",
			"listPrice": 1749800,
			"lotSize": 7275,
			"sqftTotal": 2213,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81868881",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1988,
			"zipCode": "94539",
			"path": "fremont-ca/952-corbel-common-fremont-ca-94539-100_ml89917692/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1336fd70-9921-471a-814e-b5d4a9a91787",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-11-02 17:22:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 10:18:02",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.482482,
				"lng": -121.915159,
				"zipcode": "94539",
				"subPremise": "",
				"address": "952 Corbel Common"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1876000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 199800,
			"daysOnMovoto": 113,
			"id": "23e76408-dba2-473b-85df-5a25b5389cb9",
			"tnImgPath": "https://pi.movoto.com/p/12/40967076_0_VMnjvj_p.jpeg",
			"listDate": "2021-09-14 00:00:00",
			"listingAgent": "Lisa Benavides",
			"listPrice": 199800,
			"lotSize": 784,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967076",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 36,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94555",
			"path": "fremont-ca/4141-deep-creek-rd-apt-80-fremont-ca-94555-12_40967076/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "79fdabac-9919-4325-bd42-35b2f9b4036c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-05 00:00:00",
			"createdAt": "2021-09-14 21:51:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.577296,
				"lng": -122.053463,
				"zipcode": "94555",
				"subPremise": "APT 80",
				"address": "4141 Deep Creek Rd #80"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 199800,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 840000,
			"daysOnMovoto": 48,
			"id": "6f8d4e91-5d3b-4db9-9ec2-59e59da083b0",
			"tnImgPath": "https://pi.movoto.com/p/12/40974047_0_fvaRei_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 840000,
			"lotSize": 0,
			"sqftTotal": 1496,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974047",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94539",
			"path": "fremont-ca/47402-mission-falls-ct-fremont-ca-94539-12_40974047/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 1120,
			"propertyId": "fd09bc35-1cc6-4d6f-8585-4a7a3770ee01",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-12 16:36:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.483339,
				"lng": -121.92763,
				"zipcode": "94539",
				"subPremise": "",
				"address": "47402 Mission Falls Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 840000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 810000,
			"daysOnMovoto": 48,
			"id": "6dcace1f-9293-4452-810c-4fbaf20439b9",
			"tnImgPath": "https://pi.movoto.com/p/12/40974042_0_fvaRei_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 818880,
			"lotSize": 0,
			"sqftTotal": 1496,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974042",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94539",
			"path": "fremont-ca/47452-mission-falls-ct-fremont-ca-94539-12_40974042/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9e0c213c-e295-460f-ab23-35b5bbead8e5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-12 16:32:09",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.482928,
				"lng": -121.927642,
				"zipcode": "94539",
				"subPremise": "",
				"address": "47452 Mission Falls Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 878880,
			"daysOnMovoto": 75,
			"id": "8d4a7a48-01ea-49ee-a5fd-d2cb5ee6fce0",
			"tnImgPath": "https://pi.movoto.com/p/12/40971248_0_NufZvE_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 888880,
			"lotSize": 0,
			"sqftTotal": 1515,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971248",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94539",
			"path": "fremont-ca/185-carson-falls-ter-fremont-ca-94539-12_40971248/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77805ce3-7808-4cef-b712-74baf788435d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-10-16 04:46:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.483976,
				"lng": -121.927459,
				"zipcode": "94539",
				"subPremise": "",
				"address": "185 Carson Falls Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 878880,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1300000,
			"daysOnMovoto": 43,
			"id": "075f5562-0529-426e-ad24-d8e91371bc8c",
			"tnImgPath": "https://pi.movoto.com/p/12/40973455_0_mImqMM_p.jpeg",
			"listDate": "2021-11-15 00:00:00",
			"listingAgent": "Cliff Andrade",
			"listPrice": 1099000,
			"lotSize": 5600,
			"sqftTotal": 1148,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973455",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "94538",
			"path": "fremont-ca/4591-piper-st-fremont-ca-94538-12_28507765/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fe7dd852-db17-4e30-a77e-f56e1996bb3d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-15 17:31:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-15 00:00:00",
			"virtualTourLink": "https://www.cbhometour.com/4591-Piper-Street-Fremont-CA-94538/index.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.530683,
				"lng": -121.976615,
				"zipcode": "94538",
				"subPremise": "",
				"address": "4591 Piper St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.cbhometour.com/4591-Piper-Street-Fremont-CA-94538/index.html"
		},
		{
			"closePrice": 908000,
			"daysOnMovoto": 46,
			"id": "c6a059c3-4871-461a-a13f-39100117456f",
			"tnImgPath": "https://pi.movoto.com/p/12/40974046_0_fvaRei_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 908000,
			"lotSize": 0,
			"sqftTotal": 1515,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974046",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 4,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94539",
			"path": "fremont-ca/47412-mission-falls-ct-fremont-ca-94539-12_40974046/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 9120,
			"propertyId": "4bfbd9a7-bac3-472c-8aa8-db43b3601459",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-11-12 16:32:12",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.483305,
				"lng": -121.92761,
				"zipcode": "94539",
				"subPremise": "",
				"address": "47412 Mission Falls Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 908000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 278000,
			"daysOnMovoto": 84,
			"id": "c7a44df3-5075-4aaf-bf6c-8bafa576da59",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863080_0_A6VZmI_p.jpeg",
			"listDate": "2021-10-05 00:00:00",
			"listingAgent": "Douglas Yi",
			"listPrice": 278000,
			"lotSize": null,
			"sqftTotal": 1213,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863080",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Realty One Group Infinity",
			"photoCount": 16,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 2016,
			"zipCode": "94538",
			"path": "fremont-ca/79-delta-grn-fremont-ca-94538-100_ml81863080/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20000,
			"propertyId": "0654af2b-ba8c-483a-b9e1-f3a5a5b74ad5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-10-05 07:22:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.510932,
				"lng": -121.959927,
				"zipcode": "94538",
				"subPremise": "",
				"address": "79 Delta Grn"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 278000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 119000,
			"daysOnMovoto": 132,
			"id": "310a86d2-666c-4932-a27e-e028e2d7aa67",
			"tnImgPath": "https://pi.movoto.com/p/12/40963661_0_BrfzQj_p.jpeg",
			"listDate": "2021-08-18 00:00:00",
			"listingAgent": "Robert Costa",
			"listPrice": 119900,
			"lotSize": 1152,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40963661",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Advantage Homes",
			"photoCount": 40,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94555",
			"path": "fremont-ca/4141-deep-creek-rd-apt-115-apt-115-fremont-ca-94555-12_40963661/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -13600,
			"propertyId": "0c1ee222-ae69-4233-aeff-16e39f105812",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-08-18 23:46:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.577627,
				"lng": -122.053783,
				"zipcode": "94555",
				"subPremise": "APT 115",
				"address": "4141 Deep Creek Rd Apt 115 #115"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 119000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1165000,
			"daysOnMovoto": 165,
			"id": "6392b581-fedf-49b4-aea5-7da0f7d4a777",
			"tnImgPath": "https://pi.movoto.com/p/12/40959258_0_z3u7Qy_p.jpeg",
			"listDate": "2021-07-16 00:00:00",
			"listingAgent": "Anita Dosanjh",
			"listPrice": 1150000,
			"lotSize": 6048,
			"sqftTotal": 950,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40959258",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 9,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1959,
			"zipCode": "94538",
			"path": "fremont-ca/4274-hardwood-fremont-ca-94538-12_40959258/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "13764200-fefa-4592-936d-8b66b1bde35d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-07-17 01:51:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-16 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.516575,
				"lng": -121.959154,
				"zipcode": "94538",
				"subPremise": "",
				"address": "4274 Hardwood"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1165000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 34,
			"id": "87aa1ad4-63b0-442a-85da-6e101942e3c6",
			"tnImgPath": "https://pi.movoto.com/p/12/40974844_0_mJYIvN_p.jpeg",
			"listDate": "2021-11-23 00:00:00",
			"listingAgent": "Sid Ajaz",
			"listPrice": 1388000,
			"lotSize": 6300,
			"sqftTotal": 1584,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974844",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "94536",
			"path": "fremont-ca/4196-eggers-dr-fremont-ca-94536-12_40973940/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3828728d-6510-485b-9c3e-4398d3d816f9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-23 18:16:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.550525,
				"lng": -121.997627,
				"zipcode": "94536",
				"subPremise": "",
				"address": "4196 Eggers Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1652000,
			"daysOnMovoto": 39,
			"id": "8f020845-c657-49e9-80cc-33fc1bc95099",
			"tnImgPath": "https://pi.movoto.com/p/12/40974604_0_AYYjaN_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Surinder Gill",
			"listPrice": 1500000,
			"lotSize": 5022,
			"sqftTotal": 1266,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974604",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1953,
			"zipCode": "94539",
			"path": "fremont-ca/406-ohlones-st-fremont-ca-94539/pid_o1tevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "001bb5ff-f9b5-498d-ab0c-95a4c8715702",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-30 07:00:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.531234,
				"lng": -121.924667,
				"zipcode": "94539",
				"subPremise": "",
				"address": "406 Ohlones St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1652000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 95,
			"id": "91d0de48-c851-484c-8927-f21a5781c9ba",
			"tnImgPath": "https://pi.movoto.com/p/12/40968365_0_nrujVU_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Timothy Blood",
			"listPrice": 1750000,
			"lotSize": 10688,
			"sqftTotal": 2296,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968365",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Excel Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94538",
			"path": "fremont-ca/5781-butano-park-dr-fremont-ca-94538/pid_u3hfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b8750fef-ae1e-4e0f-ae34-64b7f985998c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-09-24 01:26:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://www.asteroom.com/pviewer?hideleadgen=1&token=F37ZCE_Z7UiH57gXXvfNqw&autorotation=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.512913,
				"lng": -121.973416,
				"zipcode": "94538",
				"subPremise": "",
				"address": "5781 Butano Park Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.asteroom.com/pviewer?hideleadgen=1&token=F37ZCE_Z7UiH57gXXvfNqw&autorotation=1"
		},
		{
			"closePrice": 1315000,
			"daysOnMovoto": 15,
			"id": "162a116a-f95d-4c33-bd92-431707d98e8b",
			"tnImgPath": "https://pi.movoto.com/p/12/40975918_0_uQBFnb_p.jpeg",
			"listDate": "2021-12-08 00:00:00",
			"listingAgent": "John Lambiase",
			"listPrice": 1300000,
			"lotSize": 5148,
			"sqftTotal": 1337,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975918",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94539",
			"path": "fremont-ca/2991-anderson-ave-fremont-ca-94539/pid_sm7evaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6cef9163-08ed-425b-9443-545093cad2d8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-12-09 08:01:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-08 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.535326,
				"lng": -121.951022,
				"zipcode": "94539",
				"subPremise": "",
				"address": "2991 Anderson Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1315000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 175000,
			"daysOnMovoto": 18,
			"id": "bd56b02f-95f4-4c49-8033-863de6a6c7c5",
			"tnImgPath": "https://pi.movoto.com/p/12/40975659_0_MiQQRi_p.jpeg",
			"listDate": "2021-12-05 00:00:00",
			"listingAgent": "Lisa Benavides",
			"listPrice": 174000,
			"lotSize": 1358,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975659",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 30,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94536",
			"path": "fremont-ca/711-old-canyon-rd-apt-32-fremont-ca-94536-12_40975659/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "12f707b8-3176-49b1-b947-3c4847948daf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-12-05 23:11:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.577117,
				"lng": -121.966152,
				"zipcode": "94536",
				"subPremise": "APT 32",
				"address": "711 Old Canyon Rd #32"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2725000,
			"daysOnMovoto": 27,
			"id": "83ce5cdc-ab72-426c-a3a4-3a593cbb5a77",
			"tnImgPath": "https://pi.movoto.com/p/12/40974980_0_nFbEEJ_p.jpeg",
			"listDate": "2021-11-26 00:00:00",
			"listingAgent": "Sandhya Paramel",
			"listPrice": 2399888,
			"lotSize": 9380,
			"sqftTotal": 2372,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974980",
			"bath": 2.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 8,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1975,
			"zipCode": "94539",
			"path": "fremont-ca/45556-kiowa-ct-fremont-ca-94539-100_80331218/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "46f18ea0-1c38-4c74-addc-13bbe0452b94",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-26 19:51:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-26 00:00:00",
			"virtualTourLink": "https://mls.homejab.com/property/view/45556-kiowa-ct-fremont-ca-94539-usa",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.502681,
				"lng": -121.923284,
				"zipcode": "94539",
				"subPremise": "",
				"address": "45556 Kiowa Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://mls.homejab.com/property/view/45556-kiowa-ct-fremont-ca-94539-usa"
		},
		{
			"closePrice": 1451000,
			"daysOnMovoto": 36,
			"id": "04a9e62f-26f7-4655-b4bf-03daa9c60131",
			"tnImgPath": "https://pi.movoto.com/p/12/40974500_0_ZQeaQa_p.jpeg",
			"listDate": "2021-11-17 00:00:00",
			"listingAgent": "Rachna Bhatnagar",
			"listPrice": 1369000,
			"lotSize": 3200,
			"sqftTotal": 1723,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974500",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "VXL Real Estate & Mortgage",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1986,
			"zipCode": "94555",
			"path": "fremont-ca/4804-touchstone-ter-fremont-ca-94555-100_80771002/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a03d5a60-87f2-4616-a3cb-84d0ded1ba23",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-18 01:51:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.568326,
				"lng": -122.050121,
				"zipcode": "94555",
				"subPremise": "",
				"address": "4804 Touchstone Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1451000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 820000,
			"daysOnMovoto": 41,
			"id": "3cb65760-864e-4ef6-9e52-13e5384966e1",
			"tnImgPath": "https://pi.movoto.com/p/12/40973927_0_aIeZeZ_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Gagan Singh",
			"listPrice": 799000,
			"lotSize": 901,
			"sqftTotal": 1166,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973927",
			"bath": 1.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 21,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1970,
			"zipCode": "94555",
			"path": "fremont-ca/34531-nantucket-cmn-fremont-ca-94555/pid_aukfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a731407f-5ee8-456c-a8af-215a166901de",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-12 21:41:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.572951,
				"lng": -122.038545,
				"zipcode": "94555",
				"subPremise": "",
				"address": "34531 Nantucket Cmn"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 820000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3342000,
			"daysOnMovoto": 42,
			"id": "c7fb6ab5-9347-494a-9c3d-49c2c332844b",
			"tnImgPath": "https://pi.movoto.com/p/12/40973317_0_EIEIQQ_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Joe Schembri",
			"listPrice": 2880000,
			"lotSize": 6110,
			"sqftTotal": 2836,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973317",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1999,
			"zipCode": "94539",
			"path": "fremont-ca/175-leal-way-fremont-ca-94539/pid_rcffvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d1bf59d2-98d2-4505-8b94-844cd5b700b4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-10 16:56:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=4kynEAV46cv&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.549475,
				"lng": -121.936267,
				"zipcode": "94539",
				"subPremise": "",
				"address": "175 Leal Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3342000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=4kynEAV46cv&mls=1"
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 77,
			"id": "bb32ad0b-4d3a-43b0-954e-367afce64cd4",
			"tnImgPath": "https://pi.movoto.com/p/12/40969820_0_VeMVvJ_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Basil Yaqub",
			"listPrice": 995000,
			"lotSize": 6000,
			"sqftTotal": 1391,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969820",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94538",
			"path": "fremont-ca/40166-michelle-st-fremont-ca-94538/pid_ur9evaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2a7a9154-dfa8-48cf-abd4-3b19ad87c626",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-10-06 17:21:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.544634,
				"lng": -121.967374,
				"zipcode": "94538",
				"subPremise": "",
				"address": "40166 Michelle St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 571000,
			"daysOnMovoto": 14,
			"id": "4187f01a-d636-4f37-bb4d-f432a7549ab7",
			"tnImgPath": "https://pi.movoto.com/p/12/40975812_0_Mnf7Aj_p.jpeg",
			"listDate": "2021-12-07 00:00:00",
			"listingAgent": "Stephen Lin",
			"listPrice": 568000,
			"lotSize": 0,
			"sqftTotal": 988,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975812",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Everhome",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1984,
			"zipCode": "94536",
			"path": "fremont-ca/3501-birchwood-ter-112-fremont-ca-94536/pid_5prevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2b29a417-bd29-414f-8dfe-91ece3f7736e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-12-08 02:21:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.561809,
				"lng": -122.006363,
				"zipcode": "94536",
				"subPremise": "APT 112",
				"address": "3501 Birchwood Ter #112"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 571000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 810000,
			"daysOnMovoto": 39,
			"id": "89644c44-a233-47f5-a1b5-3feab9f9efd3",
			"tnImgPath": "https://pi.movoto.com/p/12/40974045_0_fvaRei_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 818880,
			"lotSize": 0,
			"sqftTotal": 1496,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974045",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94539",
			"path": "fremont-ca/47422-mission-falls-ct-fremont-ca-94539-12_40974045/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a52a1d0d-a842-4769-aeaa-ae204e61aa39",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-12 16:32:11",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.48327,
				"lng": -121.92759,
				"zipcode": "94539",
				"subPremise": "",
				"address": "47422 Mission Falls Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 860000,
			"daysOnMovoto": 39,
			"id": "d19dcb17-0854-4515-8390-71b13a6477a2",
			"tnImgPath": "https://pi.movoto.com/p/12/40974044_0_fvaRei_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 878880,
			"lotSize": 0,
			"sqftTotal": 1515,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974044",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 4,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94539",
			"path": "fremont-ca/47432-mission-falls-ct-fremont-ca-94539-12_40974044/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "386786d8-fb4b-4e86-93d3-d709cba88909",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-12 16:32:10",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.48325,
				"lng": -121.927579,
				"zipcode": "94539",
				"subPremise": "",
				"address": "47432 Mission Falls Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 860000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1360000,
			"daysOnMovoto": 42,
			"id": "d7b12d6a-9470-490e-a66d-c74ece3809a7",
			"tnImgPath": "https://pi.movoto.com/p/12/40973071_0_jEZBEv_p.jpeg",
			"listDate": "2021-11-09 00:00:00",
			"listingAgent": "Nila Wong",
			"listPrice": 1098000,
			"lotSize": 1538,
			"sqftTotal": 1610,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973071",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1994,
			"zipCode": "94536",
			"path": "fremont-ca/1207-albacore-ter-fremont-ca-94536/pid_87oevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3d9e97a7-b9e8-40c3-9839-ea72f4b6129d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-09 08:56:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-09 00:00:00",
			"virtualTourLink": "https://s3.amazonaws.com/video.creativeedge.tv/643129-4.mp4",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.559637,
				"lng": -121.973272,
				"zipcode": "94536",
				"subPremise": "",
				"address": "1207 Albacore Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1360000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://s3.amazonaws.com/video.creativeedge.tv/643129-4.mp4"
		},
		{
			"closePrice": 691000,
			"daysOnMovoto": 75,
			"id": "2d99172a-969e-424b-a1a9-f49a57cc85d4",
			"tnImgPath": "https://pi.movoto.com/p/12/40969941_0_iazua6_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Moe Yousofi",
			"listPrice": 689888,
			"lotSize": 14947,
			"sqftTotal": 1140,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969941",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1988,
			"zipCode": "94536",
			"path": "fremont-ca/4635-rothbury-cmn-66-fremont-ca-94536/pid_sfhevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "c33e14b7-b747-4e16-b56a-1fb7d7d33257",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-10-07 02:01:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=5zB47NQEPeC&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.552201,
				"lng": -122.016301,
				"zipcode": "94536",
				"subPremise": "APT 66",
				"address": "4635 Rothbury Cmn #66"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 691000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=5zB47NQEPeC&mls=1"
		},
		{
			"closePrice": 675000,
			"daysOnMovoto": 98,
			"id": "1b648dcb-74cb-407a-8361-b8974ce00c54",
			"tnImgPath": "https://pi.movoto.com/p/12/40966984_0_vzymmR_p.jpeg",
			"listDate": "2021-09-13 00:00:00",
			"listingAgent": "Jennifer Krause",
			"listPrice": 698950,
			"lotSize": 0,
			"sqftTotal": 1325,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966984",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Bishop & Assoc. RE Sales",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1980,
			"zipCode": "94536",
			"path": "fremont-ca/38726-aurora-ter-fremont-ca-94536-12_10578331/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -16050,
			"propertyId": "e97976df-db03-4516-b575-c956f999b7d0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-09-14 00:36:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.568617,
				"lng": -121.965346,
				"zipcode": "94536",
				"subPremise": "",
				"address": "38726 Aurora Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 269000,
			"daysOnMovoto": 105,
			"id": "ad3745a1-19f8-4ccf-8661-d9fb9944026a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861372_0_2UuR2I_p.jpeg",
			"listDate": "2021-09-05 10:42:45",
			"listingAgent": "Ben Beveridge",
			"listPrice": 269000,
			"lotSize": null,
			"sqftTotal": 1368,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861372",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Benjamin Alan Properties",
			"photoCount": 25,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": 1969,
			"zipCode": "94536",
			"path": "fremont-ca/711-old-canyon-rd-fremont-ca-94536-12_29023534/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b993a30b-4a5b-4e09-a939-078ae1aa8d09",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-09-05 17:47:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-05 10:42:45",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.578262,
				"lng": -121.96669,
				"zipcode": "94536",
				"subPremise": "",
				"address": "711 Old Canyon Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 269000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 115000,
			"daysOnMovoto": 35,
			"id": "6ecbe742-d187-4204-b287-ef6d9b617b94",
			"tnImgPath": "https://pi.movoto.com/p/12/40974188_0_aiZuQY_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Lisa Benavides",
			"listPrice": 99000,
			"lotSize": 720,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974188",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "REALTY EXPERTS",
			"photoCount": 22,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94536",
			"path": "fremont-ca/711-old-canyon-rd-apt-28-fremont-ca-94536-12_40888004/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "faef3347-e508-48b0-beb3-eaa56ab686eb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-13 03:36:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.577117,
				"lng": -121.966152,
				"zipcode": "94536",
				"subPremise": "APT 28",
				"address": "711 Old Canyon Rd #28"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 115000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 35,
			"id": "42083298-17d4-43b7-9b79-cb09260ac573",
			"tnImgPath": "https://pi.movoto.com/p/12/40974120_0_EjFAMy_p.jpeg",
			"listDate": "2021-11-12 00:00:00",
			"listingAgent": "Barry Ripp",
			"listPrice": 1378000,
			"lotSize": 2818,
			"sqftTotal": 1477,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974120",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1988,
			"zipCode": "94555",
			"path": "fremont-ca/34711-teal-cmn-fremont-ca-94555/pid_glofvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e0ce598c-49d6-48a9-9a0d-945f9ceabbf1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-12 21:56:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.565652,
				"lng": -122.045331,
				"zipcode": "94555",
				"subPremise": "",
				"address": "34711 Teal Cmn"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1666000,
			"daysOnMovoto": 36,
			"id": "fffcfb3e-b8e1-4b64-95f1-dc0d0b2e4180",
			"tnImgPath": "https://pi.movoto.com/p/12/40973996_0_YJEuuv_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Lester Belliveau",
			"listPrice": 1399900,
			"lotSize": 6675,
			"sqftTotal": 1441,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973996",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "94536",
			"path": "fremont-ca/4228-patricia-st-fremont-ca-94536-100_81030008/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b6c00fac-3ea7-4982-9e7e-a76f5db3b6d5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-11 23:56:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=CnWpzDtjvmF&brand=0\"",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.549047,
				"lng": -121.996695,
				"zipcode": "94536",
				"subPremise": "",
				"address": "4228 Patricia St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1666000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=CnWpzDtjvmF&brand=0\""
		},
		{
			"closePrice": 925000,
			"daysOnMovoto": 37,
			"id": "c4dc0467-75f3-48d8-97bb-bc5de95c10b6",
			"tnImgPath": "https://pi.movoto.com/p/12/40973901_0_2yyQIR_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Bryan VanHeusen",
			"listPrice": 899000,
			"lotSize": 80581,
			"sqftTotal": 1436,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973901",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2020,
			"zipCode": "94536",
			"path": "fremont-ca/37303-maple-st-fremont-ca-94536-12_40879359/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "30eed046-db44-44a6-ab3a-1b667ea282cc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-11 03:51:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": "https://37303MapleSt497814mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.557322,
				"lng": -122.008222,
				"zipcode": "94536",
				"subPremise": "",
				"address": "37303 Maple St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 925000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://37303MapleSt497814mls.f8re.com/"
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 37,
			"id": "d0b9f51e-86b9-4b40-a7d2-6bf6b7ab3fe5",
			"tnImgPath": "https://pi.movoto.com/p/12/40973827_0_yYIznu_p.jpeg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Lester Belliveau",
			"listPrice": 1599900,
			"lotSize": 9375,
			"sqftTotal": 1642,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973827",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1953,
			"zipCode": "94536",
			"path": "fremont-ca/4609-mattos-dr-fremont-ca-94536-12_40201188/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ca5bc553-8abc-4629-8ac0-a26cbad91216",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-10 19:56:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=wtnFDPqNweN&brand=0\"",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.545797,
				"lng": -122.005223,
				"zipcode": "94536",
				"subPremise": "",
				"address": "4609 Mattos Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=wtnFDPqNweN&brand=0\""
		},
		{
			"closePrice": 550000,
			"daysOnMovoto": 38,
			"id": "5a537d2a-c464-421b-a761-92b4a0ff4aae",
			"tnImgPath": "https://pi.movoto.com/p/12/40973732_0_yMJYEb_p.jpeg",
			"listDate": "2021-11-09 00:00:00",
			"listingAgent": "Sam Chiu",
			"listPrice": 538000,
			"lotSize": 649200,
			"sqftTotal": 799,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973732",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway Home Services",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1974,
			"zipCode": "94536",
			"path": "fremont-ca/38627-cherry-ln-72-fremont-ca-94536-12_40025650/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6af8acc8-427a-4580-90f1-59edeee46fa1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-09 20:31:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.565504,
				"lng": -121.972366,
				"zipcode": "94536",
				"subPremise": "APT 72",
				"address": "38627 Cherry Ln #72"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1275000,
			"daysOnMovoto": 43,
			"id": "c1235081-6781-42a0-89b5-4640e4a87ccb",
			"tnImgPath": "https://pi.movoto.com/p/12/40973306_0_JZaQI7_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Hemalatha Shankar",
			"listPrice": 1300000,
			"lotSize": 3305,
			"sqftTotal": 1523,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973306",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Attached Single Family Home",
			"yearBuilt": 1994,
			"zipCode": "94536",
			"path": "fremont-ca/37536-chauntry-cmn-fremont-ca-94536-100_ml80511401/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8d31d3db-b4de-4e9c-9e5c-e879404f424b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-04 23:01:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": "https://37536ChauntryCmn44543mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.558792,
				"lng": -122.001714,
				"zipcode": "94536",
				"subPremise": "",
				"address": "37536 Chauntry Cmn"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://37536ChauntryCmn44543mls.f8re.com/"
		},
		{
			"closePrice": 3400000,
			"daysOnMovoto": 0,
			"id": "1ddb5c30-e24e-4a20-8dbc-23cc0fee24c3",
			"tnImgPath": "https://pi.movoto.com/p/102/221142173_0_rfbQve_p.jpeg",
			"listDate": null,
			"listingAgent": "Tammy Phillips",
			"listPrice": 3275000,
			"lotSize": 28575,
			"sqftTotal": 4760,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221142173",
			"bath": 4,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Phillips Real Estate",
			"photoCount": 90,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1989,
			"zipCode": "94539",
			"path": "fremont-ca/2449-olive-ave-fremont-ca-94539/pid_fa8evaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "abc0c4a9-5bb6-4b5d-83c0-05e513faec8d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-11-08 05:14:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-08 05:14:16",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.53463,
				"lng": -121.944689,
				"zipcode": "94539",
				"subPremise": "",
				"address": "2449 Olive Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 1170000,
			"daysOnMovoto": 15,
			"id": "1d3a224f-707b-41ae-b6e3-963f1f39e989",
			"tnImgPath": "https://pi.movoto.com/p/12/40975137_0_eJ2ujB_p.jpeg",
			"listDate": "2021-11-30 00:00:00",
			"listingAgent": "Edward Gomes",
			"listPrice": 1079000,
			"lotSize": 6400,
			"sqftTotal": 1036,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40975137",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "PRIDE PROPERTIES",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1960,
			"zipCode": "94538",
			"path": "fremont-ca/40344-sundale-dr-fremont-ca-94538/pid_rt8evaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "67d54dac-6ed6-4077-8b0a-020a6db5ab62",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-11-30 18:06:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-30 00:00:00",
			"virtualTourLink": "http://eagent360.com/vt/app/?tour=55581#/version/mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.528635,
				"lng": -121.978437,
				"zipcode": "94538",
				"subPremise": "",
				"address": "40344 Sundale Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1170000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://eagent360.com/vt/app/?tour=55581#/version/mls"
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 72,
			"id": "366bda5f-5244-4e9c-a102-ab6cd7600a62",
			"tnImgPath": "https://pi.movoto.com/p/12/40966929_0_b6iUez_p.jpeg",
			"listDate": "2021-10-04 00:00:00",
			"listingAgent": "Laura Lane",
			"listPrice": 1600000,
			"lotSize": 6719,
			"sqftTotal": 1939,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966929",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Lane & Associates",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1977,
			"zipCode": "94536",
			"path": "fremont-ca/1520-skelton-ct-fremont-ca-94536/pid_l4nevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2b45d7ec-dc65-44e5-8017-a18fb6542ba7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-10-05 03:26:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.562948,
				"lng": -121.982524,
				"zipcode": "94536",
				"subPremise": "",
				"address": "1520 Skelton Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1358895,
			"daysOnMovoto": 34,
			"id": "9d0234d2-90c5-4203-b12c-1ac176dc02c9",
			"tnImgPath": "https://pi.movoto.com/p/12/40973727_0_2Mu6Jz_p.jpeg",
			"listDate": "2021-11-09 00:00:00",
			"listingAgent": "Minji Lee",
			"listPrice": 1358895,
			"lotSize": 0,
			"sqftTotal": 2109,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973727",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 20,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2021,
			"zipCode": "94539",
			"path": "fremont-ca/45378-tom-blalock-street-apt-305-g5-fremont-ca-94539-12_40973727/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d7b6bf5b-cf9d-4625-a94f-d4c4b1083384",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-11-09 20:01:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-09 00:00:00",
			"virtualTourLink": "https://www.tollbrothers.com/luxury-homes-for-sale/California/Metro-Crossing/Chancery-Lane#view=gallery",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.503805,
				"lng": -121.933461,
				"zipcode": "94539",
				"subPremise": "APT 305 G5",
				"address": "45378 Tom Blalock Street #305 G5"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1358895,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tollbrothers.com/luxury-homes-for-sale/California/Metro-Crossing/Chancery-Lane#view=gallery"
		},
		{
			"closePrice": 1650500,
			"daysOnMovoto": 41,
			"id": "8848c7a6-62c9-46f3-a75a-a6e4f6a6832b",
			"tnImgPath": "https://pi.movoto.com/p/12/40972711_0_UeJFIJ_p.jpeg",
			"listDate": "2021-11-02 00:00:00",
			"listingAgent": "Everett Eslinger",
			"listPrice": 1569950,
			"lotSize": 8025,
			"sqftTotal": 1635,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972711",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94536",
			"path": "fremont-ca/york-dr-fremont-ca-94536-12_40136999/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8f2c78e5-2702-458a-8dc1-1bd349dde721",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-11-02 21:31:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 00:00:00",
			"virtualTourLink": "https://5353YorkDr429429mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.533478,
				"lng": -122.005711,
				"zipcode": "94536",
				"subPremise": "",
				"address": "5353 York Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://5353YorkDr429429mls.f8re.com/"
		},
		{
			"closePrice": 281250,
			"daysOnMovoto": 47,
			"id": "a871c3a7-a3d7-4fee-9557-0215e90648f4",
			"tnImgPath": "https://pi.movoto.com/p/12/40972429_0_eeqRYj_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Ernest Costa",
			"listPrice": 289900,
			"lotSize": 1542,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972429",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Advantage Homes",
			"photoCount": 33,
			"propertyType": "MOBILE",
			"propertyTypeDisplayName": "Manufactured",
			"yearBuilt": null,
			"zipCode": "94555",
			"path": "fremont-ca/4141-deep-creek-rd-apt-234-fremont-ca-94555-12_40972429/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fa2bdbf3-6fa2-4690-af73-52e2afbaaed5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-28 00:46:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.577296,
				"lng": -122.053463,
				"zipcode": "94555",
				"subPremise": "APT 234",
				"address": "4141 Deep Creek Rd #234"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 281250,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 780000,
			"daysOnMovoto": 11,
			"id": "ef9679a7-f4f1-4b59-b9e7-0d1ce7450052",
			"tnImgPath": "https://pi.movoto.com/p/110/421614237_0_vnEJfJ_p.jpeg",
			"listDate": "2021-11-29 00:00:00",
			"listingAgent": "Yu Huang",
			"listPrice": 728000,
			"lotSize": 901,
			"sqftTotal": 1166,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421614237",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Norcal Realty",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1970,
			"zipCode": "94555",
			"path": "fremont-ca/4004-caribbean-common-fremont-ca-94555-100_ml81677783/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "df32e95c-de97-4a4a-ba9b-aa8fd2f4963a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-30 04:41:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-29 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=53F1Y6Y6Vrz",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.572976,
				"lng": -122.03743,
				"zipcode": "94555",
				"subPremise": "",
				"address": "4004 Caribbean Common"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 780000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=53F1Y6Y6Vrz"
		},
		{
			"closePrice": 1856000,
			"daysOnMovoto": 39,
			"id": "9ac7155d-64db-44c6-878c-f328f396794d",
			"tnImgPath": "https://pi.movoto.com/p/12/40972848_0_AVrEer_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Harry Grewal",
			"listPrice": 1682000,
			"lotSize": 5300,
			"sqftTotal": 1583,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972848",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Magnum Realty & Investment",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1970,
			"zipCode": "94539",
			"path": "fremont-ca/40861-valero-fremont-ca-94539-12_40972848/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "268d02dc-a4b9-4feb-9b28-37c1e9bb7fab",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-01 14:46:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/3625748",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.540341,
				"lng": -121.953777,
				"zipcode": "94539",
				"subPremise": "",
				"address": "40861 Valero"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1856000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/3625748"
		},
		{
			"closePrice": 2990000,
			"daysOnMovoto": 69,
			"id": "bf9630ea-27ec-4e41-bdd1-771cd00120d0",
			"tnImgPath": "https://pi.movoto.com/p/12/40969490_0_YBBN7F_p.jpeg",
			"listDate": "2021-10-02 00:00:00",
			"listingAgent": "Kaajal Shahani",
			"listPrice": 2990000,
			"lotSize": 13550,
			"sqftTotal": 3596,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969490",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1987,
			"zipCode": "94539",
			"path": "fremont-ca/43733-greenhills-way-fremont-ca-94539/pid_0axevaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -195000,
			"propertyId": "92e21ead-67e7-4f2f-9983-d568ec24b18a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-02 19:01:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-02 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=aEPTdkfHJF6&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.518303,
				"lng": -121.936623,
				"zipcode": "94539",
				"subPremise": "",
				"address": "43733 Greenhills Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2990000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=aEPTdkfHJF6&mls=1"
		},
		{
			"closePrice": 1682000,
			"daysOnMovoto": 79,
			"id": "8d28dd61-b310-40a3-9ed8-b6ae7d04ced9",
			"tnImgPath": "https://pi.movoto.com/p/12/40968145_0_uEUEYQ_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Drew Eggum",
			"listPrice": 1650000,
			"lotSize": 3752,
			"sqftTotal": 1793,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968145",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Blue Sapphire Homes",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1990,
			"zipCode": "94555",
			"path": "fremont-ca/5037-xavier-cmn-fremont-ca-94555/pid_sxrfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 110000,
			"propertyId": "003b6543-baaa-4561-81fa-4c5f19e2daaf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-09-23 00:46:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.564559,
				"lng": -122.051406,
				"zipcode": "94555",
				"subPremise": "",
				"address": "5037 Xavier Cmn"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1682000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1740000,
			"daysOnMovoto": 37,
			"id": "ab017bdc-6774-46ca-9bd1-5da8c913c1b4",
			"tnImgPath": "https://pi.movoto.com/p/12/40972997_0_2nbeJQ_p.jpeg",
			"listDate": "2021-11-02 00:00:00",
			"listingAgent": "Mustafa Ebrahimi",
			"listPrice": 1588888,
			"lotSize": 6592,
			"sqftTotal": 2149,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972997",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Paragon Estates & Lending",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1991,
			"zipCode": "94536",
			"path": "fremont-ca/overacker-ter-fremont-ca-94538-12_11017960/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2f85beec-ab67-475c-92ff-0bf098d6f9fa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-02 20:21:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.569906,
				"lng": -121.969018,
				"zipcode": "94536",
				"subPremise": "",
				"address": "102 Overacker Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1740000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 43,
			"id": "74c6b7c4-7ef1-4161-bcae-dd7e96ba39c7",
			"tnImgPath": "https://pi.movoto.com/p/12/40972443_0_MEqZb2_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Jing Xue",
			"listPrice": 1488000,
			"lotSize": 5400,
			"sqftTotal": 1960,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972443",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1955,
			"zipCode": "94536",
			"path": "fremont-ca/661-wasatch-dr-fremont-ca-94536-100_81115597/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4b4d70d8-0351-432d-9cf8-db7c3a615f34",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-10-28 02:21:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/eIawW-n0JYY",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Fremont",
				"lat": 37.576797,
				"lng": -121.963553,
				"zipcode": "94536",
				"subPremise": "",
				"address": "661 Wasatch Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/eIawW-n0JYY"
		}
	]