const cupertino = {
    "geography": {
       "census_release": "ACS 2019 5-year",
       "parents": {
          "county": {
             "full_name": "Santa Clara County, CA",
             "short_name": "Santa Clara County",
             "sumlevel": "050",
             "land_area": 3343890934,
             "full_geoid": "05000US06085",
             "total_population": 1927470
          },
          "CBSA": {
             "full_name": "San Jose-Sunnyvale-Santa Clara, CA Metro Area",
             "short_name": "San Jose-Sunnyvale-Santa Clara, CA",
             "sumlevel": "310",
             "land_area": 6940482016,
             "full_geoid": "31000US41940",
             "total_population": 1987846
          },
          "state": {
             "full_name": "California",
             "short_name": "California",
             "sumlevel": "040",
             "land_area": 403660088482,
             "full_geoid": "04000US06",
             "total_population": 39283497
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 324697795
          }
       },
       "this": {
          "full_name": "Cupertino, CA",
          "short_name": "Cupertino",
          "sumlevel": "160",
          "land_area": 29295948,
          "full_geoid": "16000US0617610",
          "total_population": 60257,
          "sumlevel_name": "Place",
          "short_geoid": "0617610"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "5"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 25.69,
                   "CBSA": 22.34,
                   "state": 22.97
                },
                "error": {
                   "this": 1.27,
                   "CBSA": 0.12,
                   "state": 0.03
                },
                "numerators": {
                   "this": 15480,
                   "CBSA": 444060,
                   "state": 9022146
                },
                "numerator_errors": {
                   "this": 763,
                   "CBSA": 2398.4,
                   "state": 12886.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 115,
                   "state": 112
                },
                "error_ratio": {
                   "this": 4.9,
                   "CBSA": 0.5,
                   "state": 0.1
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 59.63,
                   "CBSA": 64.5,
                   "state": 63.07
                },
                "error": {
                   "this": 1.97,
                   "CBSA": 0.22,
                   "state": 0.05
                },
                "numerators": {
                   "this": 35930,
                   "CBSA": 1282083,
                   "state": 2.477531E7
                },
                "numerator_errors": {
                   "this": 1186.8,
                   "CBSA": 4353.9,
                   "state": 20108.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 95
                },
                "error_ratio": {
                   "this": 3.3,
                   "CBSA": 0.3,
                   "state": 0.1
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 14.68,
                   "CBSA": 13.17,
                   "state": 13.97
                },
                "error": {
                   "this": 0.93,
                   "CBSA": 0.12,
                   "state": 0.03
                },
                "numerators": {
                   "this": 8847,
                   "CBSA": 261703,
                   "state": 5486041
                },
                "numerator_errors": {
                   "this": 561.5,
                   "CBSA": 2466.5,
                   "state": 12678.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 111,
                   "state": 105
                },
                "error_ratio": {
                   "this": 6.3,
                   "CBSA": 0.9,
                   "state": 0.2
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.47,
                      "CBSA": 12.17,
                      "state": 12.52
                   },
                   "error": {
                      "this": 0.91,
                      "CBSA": 0.09,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 6913,
                      "CBSA": 241951,
                      "state": 4919754
                   },
                   "numerator_errors": {
                      "this": 546.2,
                      "CBSA": 1695.1,
                      "state": 9132.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 94,
                      "state": 92
                   },
                   "error_ratio": {
                      "this": 7.9,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 16.17,
                      "CBSA": 12.46,
                      "state": 13.09
                   },
                   "error": {
                      "this": 0.97,
                      "CBSA": 0.09,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 9743,
                      "CBSA": 247684,
                      "state": 5140633
                   },
                   "numerator_errors": {
                      "this": 582.1,
                      "CBSA": 1701.3,
                      "state": 9167.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 130,
                      "state": 124
                   },
                   "error_ratio": {
                      "this": 6,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 7.75,
                      "CBSA": 14.22,
                      "state": 14.84
                   },
                   "error": {
                      "this": 0.94,
                      "CBSA": 0.11,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 4667,
                      "CBSA": 282618,
                      "state": 5830059
                   },
                   "numerator_errors": {
                      "this": 564.9,
                      "CBSA": 2161.1,
                      "state": 10871.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 55,
                      "state": 52
                   },
                   "error_ratio": {
                      "this": 12.1,
                      "CBSA": 0.8,
                      "state": 0.2
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 11.3,
                      "CBSA": 15.4,
                      "state": 14.18
                   },
                   "error": {
                      "this": 0.86,
                      "CBSA": 0.1,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 6809,
                      "CBSA": 306040,
                      "state": 5570748
                   },
                   "numerator_errors": {
                      "this": 516.1,
                      "CBSA": 2049.5,
                      "state": 8798.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 73,
                      "state": 80
                   },
                   "error_ratio": {
                      "this": 7.6,
                      "CBSA": 0.6,
                      "state": 0.1
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 20.05,
                      "CBSA": 14.14,
                      "state": 12.95
                   },
                   "error": {
                      "this": 1.01,
                      "CBSA": 0.1,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 12080,
                      "CBSA": 281103,
                      "state": 5088145
                   },
                   "numerator_errors": {
                      "this": 606.7,
                      "CBSA": 2043.9,
                      "state": 8746.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 142,
                      "state": 155
                   },
                   "error_ratio": {
                      "this": 5,
                      "CBSA": 0.7,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 13.6,
                      "CBSA": 13.05,
                      "state": 12.76
                   },
                   "error": {
                      "this": 0.9,
                      "CBSA": 0.08,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 8195,
                      "CBSA": 259378,
                      "state": 5011016
                   },
                   "numerator_errors": {
                      "this": 545.3,
                      "CBSA": 1613,
                      "state": 6867
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 104,
                      "state": 107
                   },
                   "error_ratio": {
                      "this": 6.6,
                      "CBSA": 0.6,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 9.22,
                      "CBSA": 9.62,
                      "state": 10.34
                   },
                   "error": {
                      "this": 0.74,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 5558,
                      "CBSA": 191214,
                      "state": 4062419
                   },
                   "numerator_errors": {
                      "this": 443.8,
                      "CBSA": 2352.5,
                      "state": 11748.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 96,
                      "state": 89
                   },
                   "error_ratio": {
                      "this": 8,
                      "CBSA": 1.2,
                      "state": 0.3
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 5.91,
                      "CBSA": 5.51,
                      "state": 5.82
                   },
                   "error": {
                      "this": 0.55,
                      "CBSA": 0.08,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 3562,
                      "CBSA": 109560,
                      "state": 2285972
                   },
                   "numerator_errors": {
                      "this": 330.2,
                      "CBSA": 1543.2,
                      "state": 7838.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 107,
                      "state": 102
                   },
                   "error_ratio": {
                      "this": 9.3,
                      "CBSA": 1.5,
                      "state": 0.3
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 4.53,
                      "CBSA": 3.44,
                      "state": 3.5
                   },
                   "error": {
                      "this": 0.55,
                      "CBSA": 0.06,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 2730,
                      "CBSA": 68298,
                      "state": 1374751
                   },
                   "numerator_errors": {
                      "this": 334.4,
                      "CBSA": 1207.9,
                      "state": 6707.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 132,
                      "state": 129
                   },
                   "error_ratio": {
                      "this": 12.1,
                      "CBSA": 1.7,
                      "state": 0.6
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.13,
                      "CBSA": 12.39,
                      "state": 12.87
                   },
                   "error": {
                      "this": 1.29,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 3343,
                      "CBSA": 124430,
                      "state": 2512581
                   },
                   "numerator_errors": {
                      "this": 392.1,
                      "CBSA": 1182.8,
                      "state": 6146.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 90,
                      "state": 86
                   },
                   "error_ratio": {
                      "this": 11.6,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 17,
                      "CBSA": 12.58,
                      "state": 13.46
                   },
                   "error": {
                      "this": 1.44,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 5106,
                      "CBSA": 126419,
                      "state": 2628152
                   },
                   "numerator_errors": {
                      "this": 441.3,
                      "CBSA": 1188.6,
                      "state": 6239.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 135,
                      "state": 126
                   },
                   "error_ratio": {
                      "this": 8.5,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 9.3,
                      "CBSA": 15.01,
                      "state": 15.42
                   },
                   "error": {
                      "this": 1.6,
                      "CBSA": 0.16,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 2794,
                      "CBSA": 150743,
                      "state": 3011788
                   },
                   "numerator_errors": {
                      "this": 482.9,
                      "CBSA": 1615.1,
                      "state": 8042.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 62,
                      "state": 60
                   },
                   "error_ratio": {
                      "this": 17.2,
                      "CBSA": 1.1,
                      "state": 0.3
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 10.48,
                      "CBSA": 15.96,
                      "state": 14.57
                   },
                   "error": {
                      "this": 1.13,
                      "CBSA": 0.16,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 3148,
                      "CBSA": 160356,
                      "state": 2844329
                   },
                   "numerator_errors": {
                      "this": 344,
                      "CBSA": 1611.2,
                      "state": 6637.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 66,
                      "state": 72
                   },
                   "error_ratio": {
                      "this": 10.8,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 20.05,
                      "CBSA": 14.13,
                      "state": 13.02
                   },
                   "error": {
                      "this": 1.45,
                      "CBSA": 0.16,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 6022,
                      "CBSA": 141926,
                      "state": 2541977
                   },
                   "numerator_errors": {
                      "this": 449,
                      "CBSA": 1605.7,
                      "state": 6536.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 142,
                      "state": 154
                   },
                   "error_ratio": {
                      "this": 7.2,
                      "CBSA": 1.1,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 13.98,
                      "CBSA": 13.01,
                      "state": 12.65
                   },
                   "error": {
                      "this": 1.29,
                      "CBSA": 0.1,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 4198,
                      "CBSA": 130685,
                      "state": 2470900
                   },
                   "numerator_errors": {
                      "this": 395.8,
                      "CBSA": 1033,
                      "state": 4877.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 107,
                      "state": 111
                   },
                   "error_ratio": {
                      "this": 9.2,
                      "CBSA": 0.8,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 9.37,
                      "CBSA": 9.28,
                      "state": 9.92
                   },
                   "error": {
                      "this": 1.08,
                      "CBSA": 0.17,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 2815,
                      "CBSA": 93182,
                      "state": 1937575
                   },
                   "numerator_errors": {
                      "this": 326.9,
                      "CBSA": 1660,
                      "state": 8018.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 101,
                      "state": 94
                   },
                   "error_ratio": {
                      "this": 11.5,
                      "CBSA": 1.8,
                      "state": 0.4
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 4.89,
                      "CBSA": 4.96,
                      "state": 5.32
                   },
                   "error": {
                      "this": 0.69,
                      "CBSA": 0.1,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1469,
                      "CBSA": 49877,
                      "state": 1038517
                   },
                   "numerator_errors": {
                      "this": 208.1,
                      "CBSA": 993.8,
                      "state": 5256.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 99,
                      "state": 92
                   },
                   "error_ratio": {
                      "this": 14.1,
                      "CBSA": 2,
                      "state": 0.6
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 3.8,
                      "CBSA": 2.68,
                      "state": 2.77
                   },
                   "error": {
                      "this": 0.68,
                      "CBSA": 0.07,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 1141,
                      "CBSA": 26955,
                      "state": 540479
                   },
                   "numerator_errors": {
                      "this": 205.3,
                      "CBSA": 748.1,
                      "state": 4503.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 142,
                      "state": 137
                   },
                   "error_ratio": {
                      "this": 17.9,
                      "CBSA": 2.6,
                      "state": 0.7
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 11.81,
                      "CBSA": 11.95,
                      "state": 12.18
                   },
                   "error": {
                      "this": 1.24,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 3570,
                      "CBSA": 117521,
                      "state": 2407173
                   },
                   "numerator_errors": {
                      "this": 380.3,
                      "CBSA": 1214.2,
                      "state": 6754.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 99,
                      "state": 97
                   },
                   "error_ratio": {
                      "this": 10.5,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 15.34,
                      "CBSA": 12.33,
                      "state": 12.72
                   },
                   "error": {
                      "this": 1.22,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 4637,
                      "CBSA": 121265,
                      "state": 2512481
                   },
                   "numerator_errors": {
                      "this": 379.6,
                      "CBSA": 1217.2,
                      "state": 6716.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 124,
                      "state": 121
                   },
                   "error_ratio": {
                      "this": 8,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 6.2,
                      "CBSA": 13.41,
                      "state": 14.26
                   },
                   "error": {
                      "this": 0.96,
                      "CBSA": 0.15,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 1873,
                      "CBSA": 131875,
                      "state": 2818271
                   },
                   "numerator_errors": {
                      "this": 293.1,
                      "CBSA": 1436,
                      "state": 7314.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 46,
                      "state": 43
                   },
                   "error_ratio": {
                      "this": 15.5,
                      "CBSA": 1.1,
                      "state": 0.3
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 12.11,
                      "CBSA": 14.82,
                      "state": 13.8
                   },
                   "error": {
                      "this": 1.25,
                      "CBSA": 0.13,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 3661,
                      "CBSA": 145684,
                      "state": 2726419
                   },
                   "numerator_errors": {
                      "this": 384.7,
                      "CBSA": 1266.6,
                      "state": 5775.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 82,
                      "state": 88
                   },
                   "error_ratio": {
                      "this": 10.3,
                      "CBSA": 0.9,
                      "state": 0.2
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 20.05,
                      "CBSA": 14.15,
                      "state": 12.89
                   },
                   "error": {
                      "this": 1.3,
                      "CBSA": 0.13,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 6058,
                      "CBSA": 139177,
                      "state": 2546168
                   },
                   "numerator_errors": {
                      "this": 407.9,
                      "CBSA": 1264.5,
                      "state": 5811.3
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 142,
                      "state": 156
                   },
                   "error_ratio": {
                      "this": 6.5,
                      "CBSA": 0.9,
                      "state": 0.2
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 13.23,
                      "CBSA": 13.09,
                      "state": 12.86
                   },
                   "error": {
                      "this": 1.22,
                      "CBSA": 0.13,
                      "state": 0.02
                   },
                   "numerators": {
                      "this": 3997,
                      "CBSA": 128693,
                      "state": 2540116
                   },
                   "numerator_errors": {
                      "this": 375.1,
                      "CBSA": 1238.8,
                      "state": 4833.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 101,
                      "state": 103
                   },
                   "error_ratio": {
                      "this": 9.2,
                      "CBSA": 1,
                      "state": 0.2
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 9.08,
                      "CBSA": 9.97,
                      "state": 10.75
                   },
                   "error": {
                      "this": 0.98,
                      "CBSA": 0.17,
                      "state": 0.04
                   },
                   "numerators": {
                      "this": 2743,
                      "CBSA": 98032,
                      "state": 2124844
                   },
                   "numerator_errors": {
                      "this": 300.2,
                      "CBSA": 1666.9,
                      "state": 8586.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 91,
                      "state": 84
                   },
                   "error_ratio": {
                      "this": 10.8,
                      "CBSA": 1.7,
                      "state": 0.4
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 6.93,
                      "CBSA": 6.07,
                      "state": 6.31
                   },
                   "error": {
                      "this": 0.84,
                      "CBSA": 0.12,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 2093,
                      "CBSA": 59683,
                      "state": 1247455
                   },
                   "numerator_errors": {
                      "this": 256.3,
                      "CBSA": 1180.6,
                      "state": 5815.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 114,
                      "state": 110
                   },
                   "error_ratio": {
                      "this": 12.1,
                      "CBSA": 2,
                      "state": 0.5
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 5.26,
                      "CBSA": 4.2,
                      "state": 4.22
                   },
                   "error": {
                      "this": 0.87,
                      "CBSA": 0.1,
                      "state": 0.03
                   },
                   "numerators": {
                      "this": 1589,
                      "CBSA": 41343,
                      "state": 834272
                   },
                   "numerator_errors": {
                      "this": 264,
                      "CBSA": 948.3,
                      "state": 4970.5
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 125,
                      "state": 125
                   },
                   "error_ratio": {
                      "this": 16.5,
                      "CBSA": 2.4,
                      "state": 0.7
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 41.6,
                   "CBSA": 37.1,
                   "state": 36.5
                },
                "error": {
                   "this": 0.7,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 112,
                   "state": 114
                },
                "error_ratio": {
                   "this": 1.7,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 40.8,
                   "CBSA": 36,
                   "state": 35.4
                },
                "error": {
                   "this": 0.9,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 113,
                   "state": 115
                },
                "error_ratio": {
                   "this": 2.2,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 42.2,
                   "CBSA": 38.2,
                   "state": 37.7
                },
                "error": {
                   "this": 0.7,
                   "CBSA": 0.1,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 112
                },
                "error_ratio": {
                   "this": 1.7,
                   "CBSA": 0.3,
                   "state": 0.3
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 49.85,
                "CBSA": 50.54,
                "state": 49.71
             },
             "error": {
                "this": 0.91,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 30036,
                "CBSA": 1004573,
                "state": 1.9526298E7
             },
             "numerator_errors": {
                "this": 546,
                "CBSA": 251,
                "state": 1141
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 100
             },
             "error_ratio": {
                "this": 1.8,
                "CBSA": null,
                "state": null
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 50.15,
                "CBSA": 49.46,
                "state": 50.29
             },
             "error": {
                "this": 0.91,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 30221,
                "CBSA": 983273,
                "state": 1.97572E7
             },
             "numerator_errors": {
                "this": 548,
                "CBSA": 251,
                "state": 1141
             },
             "index": {
                "this": 100,
                "CBSA": 101,
                "state": 100
             },
             "error_ratio": {
                "this": 1.8,
                "CBSA": null,
                "state": null
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 5-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 25.17,
                "CBSA": 31.62,
                "state": 37.18
             },
             "error": {
                "this": 1.39,
                "CBSA": 0.04,
                "state": 0.01
             },
             "numerators": {
                "this": 15168,
                "CBSA": 628606,
                "state": 1.4605312E7
             },
             "numerator_errors": {
                "this": 840,
                "CBSA": 844,
                "state": 3168
             },
             "index": {
                "this": 100,
                "CBSA": 80,
                "state": 68
             },
             "error_ratio": {
                "this": 5.5,
                "CBSA": 0.1,
                "state": null
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 0.8,
                "CBSA": 2.3,
                "state": 5.52
             },
             "error": {
                "this": 0.61,
                "CBSA": 0.05,
                "state": 0.02
             },
             "numerators": {
                "this": 482,
                "CBSA": 45713,
                "state": 2169155
             },
             "numerator_errors": {
                "this": 368,
                "CBSA": 926,
                "state": 6936
             },
             "index": {
                "this": 100,
                "CBSA": 35,
                "state": 14
             },
             "error_ratio": {
                "this": 76.2,
                "CBSA": 2.2,
                "state": 0.4
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.06,
                "CBSA": 0.18,
                "state": 0.36
             },
             "error": {
                "this": 0.05,
                "CBSA": 0.02,
                "state": 0.01
             },
             "numerators": {
                "this": 38,
                "CBSA": 3552,
                "state": 140831
             },
             "numerator_errors": {
                "this": 30,
                "CBSA": 409,
                "state": 2516
             },
             "index": {
                "this": 100,
                "CBSA": 33,
                "state": 17
             },
             "error_ratio": {
                "this": 83.3,
                "CBSA": 11.1,
                "state": 2.8
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 67.35,
                "CBSA": 35.27,
                "state": 14.28
             },
             "error": {
                "this": 1.55,
                "CBSA": 0.1,
                "state": 0.02
             },
             "numerators": {
                "this": 40586,
                "CBSA": 701044,
                "state": 5610931
             },
             "numerator_errors": {
                "this": 936,
                "CBSA": 2065,
                "state": 9199
             },
             "index": {
                "this": 100,
                "CBSA": 191,
                "state": 472
             },
             "error_ratio": {
                "this": 2.3,
                "CBSA": 0.3,
                "state": 0.1
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0.32,
                "CBSA": 0.32,
                "state": 0.36
             },
             "error": {
                "this": 0.35,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 193,
                "CBSA": 6265,
                "state": 140788
             },
             "numerator_errors": {
                "this": 210,
                "CBSA": 499,
                "state": 2224
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 89
             },
             "error_ratio": {
                "this": 109.4,
                "CBSA": 9.4,
                "state": 2.8
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.07,
                "CBSA": 0.28,
                "state": 0.25
             },
             "error": {
                "this": 0.08,
                "CBSA": 0.05,
                "state": 0.01
             },
             "numerators": {
                "this": 42,
                "CBSA": 5606,
                "state": 100119
             },
             "numerator_errors": {
                "this": 49,
                "CBSA": 920,
                "state": 3715
             },
             "index": {
                "this": 100,
                "CBSA": 25,
                "state": 28
             },
             "error_ratio": {
                "this": 114.3,
                "CBSA": 17.9,
                "state": 4
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 2.89,
                "CBSA": 3.52,
                "state": 3.03
             },
             "error": {
                "this": 0.64,
                "CBSA": 0.11,
                "state": 0.04
             },
             "numerators": {
                "this": 1741,
                "CBSA": 70001,
                "state": 1188673
             },
             "numerator_errors": {
                "this": 387,
                "CBSA": 2135,
                "state": 14463
             },
             "index": {
                "this": 100,
                "CBSA": 82,
                "state": 95
             },
             "error_ratio": {
                "this": 22.1,
                "CBSA": 3.1,
                "state": 1.3
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 3.33,
                "CBSA": 26.51,
                "state": 39.02
             },
             "error": {
                "this": 0.67,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 2007,
                "CBSA": 527059,
                "state": 1.5327688E7
             },
             "numerator_errors": {
                "this": 406,
                "CBSA": 0,
                "state": 0
             },
             "index": {
                "this": 100,
                "CBSA": 13,
                "state": 9
             },
             "error_ratio": {
                "this": 20.1,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 72507,
                "CBSA": 55547,
                "state": 36955
             },
             "error": {
                "this": 2668,
                "CBSA": 380,
                "state": 129
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 131,
                "state": 196
             },
             "error_ratio": {
                "this": 3.7,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 171917,
                "CBSA": 122478,
                "state": 75235
             },
             "error": {
                "this": 9141,
                "CBSA": 1085,
                "state": 232
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 140,
                "state": 229
             },
             "error_ratio": {
                "this": 5.3,
                "CBSA": 0.9,
                "state": 0.3
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 15.62,
                   "CBSA": 20.8,
                   "state": 34.35
                },
                "error": {
                   "this": 2.04,
                   "CBSA": 0.38,
                   "state": 0.1
                },
                "numerators": {
                   "this": 3278,
                   "CBSA": 136936,
                   "state": 4481083
                },
                "numerator_errors": {
                   "this": 432,
                   "CBSA": 2507.3,
                   "state": 15330.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 75,
                   "state": 45
                },
                "error_ratio": {
                   "this": 13.1,
                   "CBSA": 1.8,
                   "state": 0.3
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 12.74,
                   "CBSA": 20.81,
                   "state": 27.93
                },
                "error": {
                   "this": 1.56,
                   "CBSA": 0.34,
                   "state": 0.09
                },
                "numerators": {
                   "this": 2674,
                   "CBSA": 136978,
                   "state": 3643284
                },
                "numerator_errors": {
                   "this": 330.5,
                   "CBSA": 2294.6,
                   "state": 12935.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 61,
                   "state": 46
                },
                "error_ratio": {
                   "this": 12.2,
                   "CBSA": 1.6,
                   "state": 0.3
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 29.51,
                   "CBSA": 30.92,
                   "state": 25.56
                },
                "error": {
                   "this": 2.57,
                   "CBSA": 0.4,
                   "state": 0.1
                },
                "numerators": {
                   "this": 6191,
                   "CBSA": 203545,
                   "state": 3333848
                },
                "numerator_errors": {
                   "this": 548.4,
                   "CBSA": 2677.2,
                   "state": 14018.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 95,
                   "state": 115
                },
                "error_ratio": {
                   "this": 8.7,
                   "CBSA": 1.3,
                   "state": 0.4
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 42.12,
                   "CBSA": 27.48,
                   "state": 12.16
                },
                "error": {
                   "this": 1.86,
                   "CBSA": 0.35,
                   "state": 0.08
                },
                "numerators": {
                   "this": 8838,
                   "CBSA": 180891,
                   "state": 1586051
                },
                "numerator_errors": {
                   "this": 419,
                   "CBSA": 2316,
                   "state": 10523
                },
                "index": {
                   "this": 100,
                   "CBSA": 153,
                   "state": 346
                },
                "error_ratio": {
                   "this": 4.4,
                   "CBSA": 1.3,
                   "state": 0.7
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 5.97,
                "CBSA": 7.54,
                "state": 13.36
             },
             "error": {
                "this": 1.06,
                "CBSA": 0.23,
                "state": 0.1
             },
             "numerators": {
                "this": 3577,
                "CBSA": 147595,
                "state": 5149742
             },
             "numerator_errors": {
                "this": 638,
                "CBSA": 4438,
                "state": 38666
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 79,
                "state": 45
             },
             "error_ratio": {
                "this": 17.8,
                "CBSA": 3.1,
                "state": 0.7
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 3.61,
                   "CBSA": 7.92,
                   "state": 18.13
                },
                "error": {
                   "this": 0.95,
                   "CBSA": 0.35,
                   "state": 0.12
                },
                "numerators": {
                   "this": 557,
                   "CBSA": 34773,
                   "state": 1610923
                },
                "numerator_errors": {
                   "this": 148.9,
                   "CBSA": 1570.3,
                   "state": 10973.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 46,
                   "state": 20
                },
                "error_ratio": {
                   "this": 26.3,
                   "CBSA": 4.4,
                   "state": 0.7
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 96.39,
                   "CBSA": 92.08,
                   "state": 81.87
                },
                "error": {
                   "this": 0.83,
                   "CBSA": 0.96,
                   "state": 0
                },
                "numerators": {
                   "this": 14875,
                   "CBSA": 404295,
                   "state": 7275254
                },
                "numerator_errors": {
                   "this": 722.8,
                   "CBSA": 2898.9,
                   "state": 15647.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 118
                },
                "error_ratio": {
                   "this": 0.9,
                   "CBSA": 1,
                   "state": null
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 5-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 7.68,
                   "CBSA": 8.01,
                   "state": 10.23
                },
                "error": {
                   "this": 2.49,
                   "CBSA": 0.37,
                   "state": 0.1
                },
                "numerators": {
                   "this": 664,
                   "CBSA": 20603,
                   "state": 551219
                },
                "numerator_errors": {
                   "this": 219.4,
                   "CBSA": 953.5,
                   "state": 5245
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 75
                },
                "error_ratio": {
                   "this": 32.4,
                   "CBSA": 4.6,
                   "state": 1
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 92.32,
                   "CBSA": 91.99,
                   "state": 89.77
                },
                "error": {
                   "this": 8.05,
                   "CBSA": 0.63,
                   "state": 0.16
                },
                "numerators": {
                   "this": 7981,
                   "CBSA": 236657,
                   "state": 4839112
                },
                "numerator_errors": {
                   "this": 489.4,
                   "CBSA": 998.3,
                   "state": 5421.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 100,
                   "state": 103
                },
                "error_ratio": {
                   "this": 8.7,
                   "CBSA": 0.7,
                   "state": 0.2
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 28.94,
                "CBSA": 29.51,
                "state": 29.79
             },
             "error": {
                "this": 0.75,
                "CBSA": 0.11,
                "state": 0.07
             },
             "numerators": {
                "this": 731545,
                "CBSA": 2.7906096E7,
                "state": 5.099704E8
             },
             "numerator_errors": {
                "this": 28087,
                "CBSA": 162628,
                "state": 1354876
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 97
             },
             "error_ratio": {
                "this": 2.6,
                "CBSA": 0.4,
                "state": 0.2
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 79.1,
                   "CBSA": 74.88,
                   "state": 73.72
                },
                "error": {
                   "this": 1.83,
                   "CBSA": 0.37,
                   "state": 0.04
                },
                "numerators": {
                   "this": 21128,
                   "CBSA": 745233,
                   "state": 1.3411041E7
                },
                "numerator_errors": {
                   "this": 715,
                   "CBSA": 4630,
                   "state": 17348
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 107
                },
                "error_ratio": {
                   "this": 2.3,
                   "CBSA": 0.5,
                   "state": 0.1
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 7.88,
                   "CBSA": 10.63,
                   "state": 10.12
                },
                "error": {
                   "this": 1.17,
                   "CBSA": 0.27,
                   "state": 0.09
                },
                "numerators": {
                   "this": 2106,
                   "CBSA": 105809,
                   "state": 1841632
                },
                "numerator_errors": {
                   "this": 318,
                   "CBSA": 2737,
                   "state": 16674
                },
                "index": {
                   "this": 100,
                   "CBSA": 74,
                   "state": 78
                },
                "error_ratio": {
                   "this": 14.8,
                   "CBSA": 2.5,
                   "state": 0.9
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 3.46,
                   "CBSA": 4.33,
                   "state": 5.08
                },
                "error": {
                   "this": 0.85,
                   "CBSA": 0.15,
                   "state": 0.04
                },
                "numerators": {
                   "this": 925,
                   "CBSA": 43142,
                   "state": 923834
                },
                "numerator_errors": {
                   "this": 229,
                   "CBSA": 1491,
                   "state": 7563
                },
                "index": {
                   "this": 100,
                   "CBSA": 80,
                   "state": 68
                },
                "error_ratio": {
                   "this": 24.6,
                   "CBSA": 3.5,
                   "state": 0.8
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 0.75,
                   "CBSA": 1.73,
                   "state": 0.95
                },
                "error": {
                   "this": 0.31,
                   "CBSA": 0.09,
                   "state": 0.02
                },
                "numerators": {
                   "this": 199,
                   "CBSA": 17241,
                   "state": 173081
                },
                "numerator_errors": {
                   "this": 84,
                   "CBSA": 877,
                   "state": 3177
                },
                "index": {
                   "this": 100,
                   "CBSA": 43,
                   "state": 79
                },
                "error_ratio": {
                   "this": 41.3,
                   "CBSA": 5.2,
                   "state": 2.1
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 2.17,
                   "CBSA": 2.12,
                   "state": 2.62
                },
                "error": {
                   "this": 0.57,
                   "CBSA": 0.13,
                   "state": 0.03
                },
                "numerators": {
                   "this": 579,
                   "CBSA": 21077,
                   "state": 476291
                },
                "numerator_errors": {
                   "this": 152,
                   "CBSA": 1264,
                   "state": 5234
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 83
                },
                "error_ratio": {
                   "this": 26.3,
                   "CBSA": 6.1,
                   "state": 1.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.29,
                   "CBSA": 1.32,
                   "state": 1.6
                },
                "error": {
                   "this": 0.46,
                   "CBSA": 0.08,
                   "state": 0.02
                },
                "numerators": {
                   "this": 345,
                   "CBSA": 13160,
                   "state": 291710
                },
                "numerator_errors": {
                   "this": 122,
                   "CBSA": 846,
                   "state": 4364
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 81
                },
                "error_ratio": {
                   "this": 35.7,
                   "CBSA": 6.1,
                   "state": 1.2
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 5.35,
                   "CBSA": 4.98,
                   "state": 5.9
                },
                "error": {
                   "this": 1.06,
                   "CBSA": 0.15,
                   "state": 0.05
                },
                "numerators": {
                   "this": 1429,
                   "CBSA": 49567,
                   "state": 1073966
                },
                "numerator_errors": {
                   "this": 284,
                   "CBSA": 1546,
                   "state": 8330
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 91
                },
                "error_ratio": {
                   "this": 19.8,
                   "CBSA": 3,
                   "state": 0.8
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 65.86,
                "CBSA": 54.67,
                "state": 48.86
             },
             "error": {
                "this": 1,
                "CBSA": 0.28,
                "state": 0.11
             },
             "numerators": {
                "this": 31460,
                "CBSA": 884034,
                "state": 1.5532639E7
             },
             "numerator_errors": {
                "this": 583.4,
                "CBSA": 4460.5,
                "state": 35143.9
             },
             "index": {
                "this": 100,
                "CBSA": 120,
                "state": 135
             },
             "error_ratio": {
                "this": 1.5,
                "CBSA": 0.5,
                "state": 0.2
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 34.14,
                "CBSA": 45.33,
                "state": 51.14
             },
             "error": {
                "this": 1.9,
                "CBSA": 0.29,
                "state": 0.1
             },
             "numerators": {
                "this": 16306,
                "CBSA": 732911,
                "state": 1.6255641E7
             },
             "numerator_errors": {
                "this": 922.3,
                "CBSA": 4762,
                "state": 32012.1
             },
             "index": {
                "this": 100,
                "CBSA": 75,
                "state": 67
             },
             "error_ratio": {
                "this": 5.6,
                "CBSA": 0.6,
                "state": 0.2
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 29.39,
                   "CBSA": 37.44,
                   "state": 40.42
                },
                "error": {
                   "this": 2.89,
                   "CBSA": 0.35,
                   "state": 0.14
                },
                "numerators": {
                   "this": 6959,
                   "CBSA": 305004,
                   "state": 6343459
                },
                "numerator_errors": {
                   "this": 704,
                   "CBSA": 2839,
                   "state": 22441
                },
                "index": {
                   "this": 100,
                   "CBSA": 78,
                   "state": 73
                },
                "error_ratio": {
                   "this": 9.8,
                   "CBSA": 0.9,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 19.79,
                   "CBSA": 29.76,
                   "state": 33.49
                },
                "error": {
                   "this": 1.62,
                   "CBSA": 0.32,
                   "state": 0.11
                },
                "numerators": {
                   "this": 4766,
                   "CBSA": 238780,
                   "state": 5389443
                },
                "numerator_errors": {
                   "this": 402,
                   "CBSA": 2599,
                   "state": 17597
                },
                "index": {
                   "this": 100,
                   "CBSA": 66,
                   "state": 59
                },
                "error_ratio": {
                   "this": 8.2,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 65.76,
                   "CBSA": 54.94,
                   "state": 49.8
                },
                "error": {
                   "this": 0.72,
                   "CBSA": 0.39,
                   "state": 0.17
                },
                "numerators": {
                   "this": 15573,
                   "CBSA": 447525,
                   "state": 7816684
                },
                "numerator_errors": {
                   "this": 407,
                   "CBSA": 3214,
                   "state": 27451
                },
                "index": {
                   "this": 100,
                   "CBSA": 120,
                   "state": 132
                },
                "error_ratio": {
                   "this": 1.1,
                   "CBSA": 0.7,
                   "state": 0.3
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 65.96,
                   "CBSA": 54.4,
                   "state": 47.95
                },
                "error": {
                   "this": 1.06,
                   "CBSA": 0.39,
                   "state": 0.14
                },
                "numerators": {
                   "this": 15887,
                   "CBSA": 436509,
                   "state": 7715955
                },
                "numerator_errors": {
                   "this": 418,
                   "CBSA": 3093,
                   "state": 21944
                },
                "index": {
                   "this": 100,
                   "CBSA": 121,
                   "state": 138
                },
                "error_ratio": {
                   "this": 1.6,
                   "CBSA": 0.7,
                   "state": 0.3
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 3.2,
                   "CBSA": 5.95,
                   "state": 7.65
                },
                "error": {
                   "this": 0.75,
                   "CBSA": 0.19,
                   "state": 0.06
                },
                "numerators": {
                   "this": 758,
                   "CBSA": 48487,
                   "state": 1200982
                },
                "numerator_errors": {
                   "this": 179,
                   "CBSA": 1518,
                   "state": 9397
                },
                "index": {
                   "this": 100,
                   "CBSA": 54,
                   "state": 42
                },
                "error_ratio": {
                   "this": 23.4,
                   "CBSA": 3.2,
                   "state": 0.8
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 5.87,
                   "CBSA": 9.05,
                   "state": 10.85
                },
                "error": {
                   "this": 0.92,
                   "CBSA": 0.23,
                   "state": 0.05
                },
                "numerators": {
                   "this": 1414,
                   "CBSA": 72589,
                   "state": 1746282
                },
                "numerator_errors": {
                   "this": 223,
                   "CBSA": 1835,
                   "state": 8613
                },
                "index": {
                   "this": 100,
                   "CBSA": 65,
                   "state": 54
                },
                "error_ratio": {
                   "this": 15.7,
                   "CBSA": 2.5,
                   "state": 0.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 5-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 1.66,
                   "CBSA": 1.67,
                   "state": 2.13
                },
                "error": {
                   "this": 0.51,
                   "CBSA": 0.1,
                   "state": 0.02
                },
                "numerators": {
                   "this": 392,
                   "CBSA": 13581,
                   "state": 334237
                },
                "numerator_errors": {
                   "this": 122,
                   "CBSA": 820,
                   "state": 3638
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 78
                },
                "error_ratio": {
                   "this": 30.7,
                   "CBSA": 6,
                   "state": 0.9
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 8.37,
                   "CBSA": 6.79,
                   "state": 7.71
                },
                "error": {
                   "this": 1.28,
                   "CBSA": 0.15,
                   "state": 0.04
                },
                "numerators": {
                   "this": 2017,
                   "CBSA": 54470,
                   "state": 1241238
                },
                "numerator_errors": {
                   "this": 311,
                   "CBSA": 1232,
                   "state": 5983
                },
                "index": {
                   "this": 100,
                   "CBSA": 123,
                   "state": 109
                },
                "error_ratio": {
                   "this": 15.3,
                   "CBSA": 2.2,
                   "state": 0.5
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 92.14,
                   "CBSA": 81.4,
                   "state": 72.23
                },
                "error": {
                   "this": 0.53,
                   "CBSA": 0.63,
                   "state": 0.27
                },
                "numerators": {
                   "this": 13783,
                   "CBSA": 319520,
                   "state": 5567122
                },
                "numerator_errors": {
                   "this": 434,
                   "CBSA": 2844,
                   "state": 22183
                },
                "index": {
                   "this": 100,
                   "CBSA": 113,
                   "state": 128
                },
                "error_ratio": {
                   "this": 0.6,
                   "CBSA": 0.8,
                   "state": 0.4
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 1.66,
                   "CBSA": 5.63,
                   "state": 7.67
                },
                "error": {
                   "this": 0.87,
                   "CBSA": 0.34,
                   "state": 0.11
                },
                "numerators": {
                   "this": 248,
                   "CBSA": 22081,
                   "state": 591232
                },
                "numerator_errors": {
                   "this": 130,
                   "CBSA": 1348,
                   "state": 8769
                },
                "index": {
                   "this": 100,
                   "CBSA": 29,
                   "state": 22
                },
                "error_ratio": {
                   "this": 52.4,
                   "CBSA": 6,
                   "state": 1.4
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 6.2,
                   "CBSA": 12.98,
                   "state": 20.1
                },
                "error": {
                   "this": 1.88,
                   "CBSA": 0.54,
                   "state": 0.21
                },
                "numerators": {
                   "this": 927,
                   "CBSA": 50947,
                   "state": 1549424
                },
                "numerator_errors": {
                   "this": 283,
                   "CBSA": 2129,
                   "state": 16177
                },
                "index": {
                   "this": 100,
                   "CBSA": 48,
                   "state": 31
                },
                "error_ratio": {
                   "this": 30.3,
                   "CBSA": 4.2,
                   "state": 1
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 3.4,
                "CBSA": 4.91,
                "state": 4.88
             },
             "error": {
                "this": 0.98,
                "CBSA": 0.2,
                "state": 0.06
             },
             "numerators": {
                "this": 485,
                "CBSA": 23987,
                "state": 469884
             },
             "numerator_errors": {
                "this": 140,
                "CBSA": 958,
                "state": 5597
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 69,
                "state": 70
             },
             "error_ratio": {
                "this": 28.8,
                "CBSA": 4.1,
                "state": 1.2
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 5-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0,
                   "CBSA": 0.44,
                   "state": 1.01
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.2,
                   "state": 0.07
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 252,
                   "state": 12727
                },
                "numerator_errors": {
                   "this": 29,
                   "CBSA": 117,
                   "state": 844
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 45.5,
                   "state": 6.9
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 0,
                   "CBSA": 2.68,
                   "state": 4.98
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.5,
                   "state": 0.17
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 1581,
                   "state": 66276
                },
                "numerator_errors": {
                   "this": 29,
                   "CBSA": 295,
                   "state": 2263
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 18.7,
                   "state": 3.4
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 16.55,
                   "CBSA": 6.56,
                   "state": 7.77
                },
                "error": {
                   "this": 9.1,
                   "CBSA": 0.65,
                   "state": 0.16
                },
                "numerators": {
                   "this": 121,
                   "CBSA": 4781,
                   "state": 115600
                },
                "numerator_errors": {
                   "this": 73,
                   "CBSA": 476,
                   "state": 2442
                },
                "index": {
                   "this": 100,
                   "CBSA": 252,
                   "state": 213
                },
                "error_ratio": {
                   "this": 55,
                   "CBSA": 9.9,
                   "state": 2.1
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 9.6,
                   "CBSA": 11.86,
                   "state": 9.88
                },
                "error": {
                   "this": 4.04,
                   "CBSA": 0.81,
                   "state": 0.2
                },
                "numerators": {
                   "this": 131,
                   "CBSA": 8909,
                   "state": 138843
                },
                "numerator_errors": {
                   "this": 61,
                   "CBSA": 617,
                   "state": 2879
                },
                "index": {
                   "this": 100,
                   "CBSA": 81,
                   "state": 97
                },
                "error_ratio": {
                   "this": 42.1,
                   "CBSA": 6.8,
                   "state": 2
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 6.79,
                   "CBSA": 8.52,
                   "state": 7.09
                },
                "error": {
                   "this": 3.21,
                   "CBSA": 0.66,
                   "state": 0.16
                },
                "numerators": {
                   "this": 156,
                   "CBSA": 6011,
                   "state": 93691
                },
                "numerator_errors": {
                   "this": 76,
                   "CBSA": 478,
                   "state": 2137
                },
                "index": {
                   "this": 100,
                   "CBSA": 80,
                   "state": 96
                },
                "error_ratio": {
                   "this": 47.3,
                   "CBSA": 7.7,
                   "state": 2.3
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 2.09,
                   "CBSA": 2.67,
                   "state": 2.43
                },
                "error": {
                   "this": 1.71,
                   "CBSA": 0.37,
                   "state": 0.1
                },
                "numerators": {
                   "this": 64,
                   "CBSA": 1855,
                   "state": 30695
                },
                "numerator_errors": {
                   "this": 53,
                   "CBSA": 258,
                   "state": 1315
                },
                "index": {
                   "this": 100,
                   "CBSA": 78,
                   "state": 86
                },
                "error_ratio": {
                   "this": 81.8,
                   "CBSA": 13.9,
                   "state": 4.1
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0.36,
                   "CBSA": 0.71,
                   "state": 0.77
                },
                "error": {
                   "this": 0.58,
                   "CBSA": 0.21,
                   "state": 0.06
                },
                "numerators": {
                   "this": 13,
                   "CBSA": 598,
                   "state": 12052
                },
                "numerator_errors": {
                   "this": 21,
                   "CBSA": 174,
                   "state": 861
                },
                "index": {
                   "this": 100,
                   "CBSA": 51,
                   "state": 47
                },
                "error_ratio": {
                   "this": 161.1,
                   "CBSA": 29.6,
                   "state": 7.8
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 20981,
                "CBSA": 658350,
                "state": 1.3044266E7
             },
             "error": {
                "this": 357,
                "CBSA": 1597,
                "state": 20333
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 3,
                "state": null
             },
             "error_ratio": {
                "this": 1.7,
                "CBSA": 0.2,
                "state": 0.2
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 2.85,
                "CBSA": 2.96,
                "state": 2.95
             },
             "error": {
                "this": 0.05,
                "CBSA": 0.01,
                "state": 0
             },
             "numerators": {
                "this": 59833,
                "CBSA": 1951101,
                "state": 3.8462236E7
             },
             "numerator_errors": {
                "this": 137,
                "CBSA": 1336,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 97
             },
             "error_ratio": {
                "this": 1.8,
                "CBSA": 0.3,
                "state": null
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 5-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 80.11,
                   "CBSA": 67.5,
                   "state": 61.04
                },
                "error": {
                   "this": 1.87,
                   "CBSA": 0.43,
                   "state": 0.21
                },
                "numerators": {
                   "this": 47935,
                   "CBSA": 1316990,
                   "state": 2.3478016E7
                },
                "numerator_errors": {
                   "this": 1122,
                   "CBSA": 8371,
                   "state": 81110
                },
                "index": {
                   "this": 100,
                   "CBSA": 119,
                   "state": 131
                },
                "error_ratio": {
                   "this": 2.3,
                   "CBSA": 0.6,
                   "state": 0.3
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 2.77,
                   "CBSA": 6.18,
                   "state": 7.59
                },
                "error": {
                   "this": 0.97,
                   "CBSA": 0.23,
                   "state": 0.09
                },
                "numerators": {
                   "this": 1655,
                   "CBSA": 120578,
                   "state": 2919408
                },
                "numerator_errors": {
                   "this": 583,
                   "CBSA": 4560,
                   "state": 33493
                },
                "index": {
                   "this": 100,
                   "CBSA": 45,
                   "state": 36
                },
                "error_ratio": {
                   "this": 35,
                   "CBSA": 3.7,
                   "state": 1.2
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 6.26,
                   "CBSA": 12.3,
                   "state": 16.57
                },
                "error": {
                   "this": 1.25,
                   "CBSA": 0.34,
                   "state": 0.12
                },
                "numerators": {
                   "this": 3744,
                   "CBSA": 239896,
                   "state": 6373007
                },
                "numerator_errors": {
                   "this": 746,
                   "CBSA": 6556,
                   "state": 46993
                },
                "index": {
                   "this": 100,
                   "CBSA": 51,
                   "state": 38
                },
                "error_ratio": {
                   "this": 20,
                   "CBSA": 2.8,
                   "state": 0.7
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 10.86,
                   "CBSA": 14.02,
                   "state": 14.8
                },
                "error": {
                   "this": 1.21,
                   "CBSA": 0.23,
                   "state": 0.07
                },
                "numerators": {
                   "this": 6499,
                   "CBSA": 273637,
                   "state": 5691804
                },
                "numerator_errors": {
                   "this": 726,
                   "CBSA": 4552,
                   "state": 27482
                },
                "index": {
                   "this": 100,
                   "CBSA": 77,
                   "state": 73
                },
                "error_ratio": {
                   "this": 11.1,
                   "CBSA": 1.6,
                   "state": 0.5
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 22267,
                "CBSA": 691465,
                "state": 1.4175976E7
             },
             "error": {
                "this": 429,
                "CBSA": 562,
                "state": 1184
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 3,
                "state": null
             },
             "error_ratio": {
                "this": 1.9,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 94.22,
                   "CBSA": 95.21,
                   "state": 92.02
                },
                "error": {
                   "this": 2.42,
                   "CBSA": 0.22,
                   "state": 0.14
                },
                "numerators": {
                   "this": 20981,
                   "CBSA": 658350,
                   "state": 1.3044266E7
                },
                "numerator_errors": {
                   "this": 357,
                   "CBSA": 1597,
                   "state": 20333
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 102
                },
                "error_ratio": {
                   "this": 2.6,
                   "CBSA": 0.2,
                   "state": 0.2
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 5.78,
                   "CBSA": 4.79,
                   "state": 7.98
                },
                "error": {
                   "this": 1.49,
                   "CBSA": 0.26,
                   "state": 0.15
                },
                "numerators": {
                   "this": 1286,
                   "CBSA": 33115,
                   "state": 1131710
                },
                "numerator_errors": {
                   "this": 333,
                   "CBSA": 1770,
                   "state": 20951
                },
                "index": {
                   "this": 100,
                   "CBSA": 121,
                   "state": 72
                },
                "error_ratio": {
                   "this": 25.8,
                   "CBSA": 5.4,
                   "state": 1.9
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 67.4,
                   "CBSA": 63.19,
                   "state": 64.78
                },
                "error": {
                   "this": 1.43,
                   "CBSA": 0.33,
                   "state": 0.09
                },
                "numerators": {
                   "this": 15009,
                   "CBSA": 436950,
                   "state": 9183849
                },
                "numerator_errors": {
                   "this": 429.8,
                   "CBSA": 2296.3,
                   "state": 13345.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 104
                },
                "error_ratio": {
                   "this": 2.1,
                   "CBSA": 0.5,
                   "state": 0.1
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 32.46,
                   "CBSA": 33.87,
                   "state": 31.43
                },
                "error": {
                   "this": 2.38,
                   "CBSA": 0.44,
                   "state": 0.11
                },
                "numerators": {
                   "this": 7227,
                   "CBSA": 234174,
                   "state": 4455831
                },
                "numerator_errors": {
                   "this": 547.5,
                   "CBSA": 3074,
                   "state": 15037
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 103
                },
                "error_ratio": {
                   "this": 7.3,
                   "CBSA": 1.3,
                   "state": 0.3
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 0.14,
                   "CBSA": 2.86,
                   "state": 3.68
                },
                "error": {
                   "this": 0.15,
                   "CBSA": 0.1,
                   "state": 0.03
                },
                "numerators": {
                   "this": 31,
                   "CBSA": 19795,
                   "state": 521135
                },
                "numerator_errors": {
                   "this": 34,
                   "CBSA": 718,
                   "state": 4740
                },
                "index": {
                   "this": 100,
                   "CBSA": 5,
                   "state": 4
                },
                "error_ratio": {
                   "this": 107.1,
                   "CBSA": 3.5,
                   "state": 0.8
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0,
                   "CBSA": 0.08,
                   "state": 0.11
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.03,
                   "state": 0.01
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 546,
                   "state": 15161
                },
                "numerator_errors": {
                   "this": 29,
                   "CBSA": 173,
                   "state": 862
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 37.5,
                   "state": 9.1
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 60.23,
                   "CBSA": 56.6,
                   "state": 54.85
                },
                "error": {
                   "this": 1.57,
                   "CBSA": 0.4,
                   "state": 0.28
                },
                "numerators": {
                   "this": 12636,
                   "CBSA": 372659,
                   "state": 7154580
                },
                "numerator_errors": {
                   "this": 394,
                   "CBSA": 2785,
                   "state": 37872
                },
                "index": {
                   "this": 100,
                   "CBSA": 106,
                   "state": 110
                },
                "error_ratio": {
                   "this": 2.6,
                   "CBSA": 0.7,
                   "state": 0.5
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 39.77,
                   "CBSA": 43.4,
                   "state": 45.15
                },
                "error": {
                   "this": 2.01,
                   "CBSA": 0.36,
                   "state": 0.15
                },
                "numerators": {
                   "this": 8345,
                   "CBSA": 285691,
                   "state": 5889686
                },
                "numerator_errors": {
                   "this": 445,
                   "CBSA": 2449,
                   "state": 21311
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 88
                },
                "error_ratio": {
                   "this": 5.1,
                   "CBSA": 0.8,
                   "state": 0.3
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 1711300,
                "CBSA": 968800,
                "state": 505000
             },
             "error": {
                "this": 31419,
                "CBSA": 5412,
                "state": 1147
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 177,
                "state": 339
             },
             "error_ratio": {
                "this": 1.8,
                "CBSA": 0.6,
                "state": 0.2
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 5-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 1.72,
                   "CBSA": 2.37,
                   "state": 5.03
                },
                "error": {
                   "this": 1.12,
                   "CBSA": 0.15,
                   "state": 0.05
                },
                "numerators": {
                   "this": 217,
                   "CBSA": 8828,
                   "state": 359961
                },
                "numerator_errors": {
                   "this": 141.8,
                   "CBSA": 548.4,
                   "state": 3940.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 73,
                   "state": 34
                },
                "error_ratio": {
                   "this": 65.1,
                   "CBSA": 6.3,
                   "state": 1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 0.66,
                   "CBSA": 2.07,
                   "state": 6.63
                },
                "error": {
                   "this": 0.47,
                   "CBSA": 0.15,
                   "state": 0.06
                },
                "numerators": {
                   "this": 83,
                   "CBSA": 7732,
                   "state": 474527
                },
                "numerator_errors": {
                   "this": 59.5,
                   "CBSA": 568.6,
                   "state": 4768.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 32,
                   "state": 10
                },
                "error_ratio": {
                   "this": 71.2,
                   "CBSA": 7.2,
                   "state": 0.9
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 0.74,
                   "CBSA": 1.76,
                   "state": 11.06
                },
                "error": {
                   "this": 0.39,
                   "CBSA": 0.13,
                   "state": 0.06
                },
                "numerators": {
                   "this": 93,
                   "CBSA": 6570,
                   "state": 791435
                },
                "numerator_errors": {
                   "this": 49.4,
                   "CBSA": 504.3,
                   "state": 6253.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 42,
                   "state": 7
                },
                "error_ratio": {
                   "this": 52.7,
                   "CBSA": 7.4,
                   "state": 0.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 0.47,
                   "CBSA": 2.37,
                   "state": 13.6
                },
                "error": {
                   "this": 0.41,
                   "CBSA": 0.16,
                   "state": 0.09
                },
                "numerators": {
                   "this": 60,
                   "CBSA": 8838,
                   "state": 973196
                },
                "numerator_errors": {
                   "this": 52,
                   "CBSA": 599,
                   "state": 7992
                },
                "index": {
                   "this": 100,
                   "CBSA": 20,
                   "state": 3
                },
                "error_ratio": {
                   "this": 87.2,
                   "CBSA": 6.8,
                   "state": 0.7
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 1.19,
                   "CBSA": 3.99,
                   "state": 13.21
                },
                "error": {
                   "this": 0.66,
                   "CBSA": 0.22,
                   "state": 0.08
                },
                "numerators": {
                   "this": 151,
                   "CBSA": 14882,
                   "state": 945276
                },
                "numerator_errors": {
                   "this": 84,
                   "CBSA": 831,
                   "state": 7694
                },
                "index": {
                   "this": 100,
                   "CBSA": 30,
                   "state": 9
                },
                "error_ratio": {
                   "this": 55.5,
                   "CBSA": 5.5,
                   "state": 0.6
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 12.99,
                   "CBSA": 40.13,
                   "state": 35.28
                },
                "error": {
                   "this": 1.64,
                   "CBSA": 0.54,
                   "state": 0.26
                },
                "numerators": {
                   "this": 1641,
                   "CBSA": 149566,
                   "state": 2523951
                },
                "numerator_errors": {
                   "this": 213.7,
                   "CBSA": 2309.5,
                   "state": 12891.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 32,
                   "state": 37
                },
                "error_ratio": {
                   "this": 12.6,
                   "CBSA": 1.3,
                   "state": 0.7
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 18.04,
                   "CBSA": 22.29,
                   "state": 7.93
                },
                "error": {
                   "this": 1.84,
                   "CBSA": 0.41,
                   "state": 0.04
                },
                "numerators": {
                   "this": 2280,
                   "CBSA": 83053,
                   "state": 567462
                },
                "numerator_errors": {
                   "this": 243,
                   "CBSA": 1652,
                   "state": 4302
                },
                "index": {
                   "this": 100,
                   "CBSA": 81,
                   "state": 227
                },
                "error_ratio": {
                   "this": 10.2,
                   "CBSA": 1.8,
                   "state": 0.5
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 12636,
                "CBSA": 372659,
                "state": 7154580
             },
             "error": {
                "this": 394,
                "CBSA": 2785,
                "state": 37872
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 3,
                "state": null
             },
             "error_ratio": {
                "this": 3.1,
                "CBSA": 0.7,
                "state": 0.5
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 5-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 10.07,
                "CBSA": 10.69,
                "state": 9.72
             },
             "error": {
                "this": 1.09,
                "CBSA": 0.25,
                "state": 0.06
             },
             "numerators": {
                "this": 6025,
                "CBSA": 208556,
                "state": 3739275
             },
             "numerator_errors": {
                "this": 651.1,
                "CBSA": 4934.2,
                "state": 21932.9
             },
             "index": {
                "this": 100,
                "CBSA": 94,
                "state": 104
             },
             "error_ratio": {
                "this": 10.8,
                "CBSA": 2.3,
                "state": 0.6
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 11.8,
                "CBSA": 11.88,
                "state": 12.25
             },
             "error": {
                "this": 1.25,
                "CBSA": 0.24,
                "state": 0.08
             },
             "numerators": {
                "this": 7060,
                "CBSA": 231731,
                "state": 4711644
             },
             "numerator_errors": {
                "this": 745.8,
                "CBSA": 4705.1,
                "state": 30793.4
             },
             "index": {
                "this": 100,
                "CBSA": 99,
                "state": 96
             },
             "error_ratio": {
                "this": 10.6,
                "CBSA": 2,
                "state": 0.7
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 25.68,
                "CBSA": 26.42,
                "state": 25.7
             },
             "error": {
                "this": 2.12,
                "CBSA": 0.43,
                "state": 0.13
             },
             "numerators": {
                "this": 15367,
                "CBSA": 515473,
                "state": 9884339
             },
             "numerator_errors": {
                "this": 1268.3,
                "CBSA": 8494.2,
                "state": 50780.8
             },
             "index": {
                "this": 100,
                "CBSA": 97,
                "state": 100
             },
             "error_ratio": {
                "this": 8.3,
                "CBSA": 1.6,
                "state": 0.5
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 28.64,
                "CBSA": 27.26,
                "state": 28.78
             },
             "error": {
                "this": 2.26,
                "CBSA": 0.45,
                "state": 0.16
             },
             "numerators": {
                "this": 17139,
                "CBSA": 531851,
                "state": 1.1067761E7
             },
             "numerator_errors": {
                "this": 1353.4,
                "CBSA": 8744.9,
                "state": 60057.7
             },
             "index": {
                "this": 100,
                "CBSA": 105,
                "state": 100
             },
             "error_ratio": {
                "this": 7.9,
                "CBSA": 1.7,
                "state": 0.6
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 14.22,
                "CBSA": 14,
                "state": 14.48
             },
             "error": {
                "this": 1.85,
                "CBSA": 0.36,
                "state": 0.11
             },
             "numerators": {
                "this": 8509,
                "CBSA": 273196,
                "state": 5568721
             },
             "numerator_errors": {
                "this": 1104.4,
                "CBSA": 6977.5,
                "state": 43280.9
             },
             "index": {
                "this": 100,
                "CBSA": 102,
                "state": 98
             },
             "error_ratio": {
                "this": 13,
                "CBSA": 2.6,
                "state": 0.8
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 9.58,
                "CBSA": 9.75,
                "state": 9.08
             },
             "error": {
                "this": 1.53,
                "CBSA": 0.29,
                "state": 0.09
             },
             "numerators": {
                "this": 5733,
                "CBSA": 190294,
                "state": 3490495
             },
             "numerator_errors": {
                "this": 915.1,
                "CBSA": 5754.6,
                "state": 33565.8
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 106
             },
             "error_ratio": {
                "this": 16,
                "CBSA": 3,
                "state": 1
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 14.34,
                "CBSA": 14.41,
                "state": 12.86
             },
             "error": {
                "this": 1.92,
                "CBSA": 0.28,
                "state": 0.08
             },
             "numerators": {
                "this": 8584,
                "CBSA": 283223,
                "state": 4993305
             },
             "numerator_errors": {
                "this": 1148,
                "CBSA": 5465,
                "state": 29689.5
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 112
             },
             "error_ratio": {
                "this": 13.4,
                "CBSA": 1.9,
                "state": 0.6
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 5-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 85.66,
                "CBSA": 85.59,
                "state": 87.14
             },
             "error": {
                "this": 1.76,
                "CBSA": 0.27,
                "state": 0.07
             },
             "numerators": {
                "this": 51275,
                "CBSA": 1682642,
                "state": 3.3839688E7
             },
             "numerator_errors": {
                "this": 1060,
                "CBSA": 5276,
                "state": 27490
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 98
             },
             "error_ratio": {
                "this": 2.1,
                "CBSA": 0.3,
                "state": 0.1
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 7.27,
                "CBSA": 8.33,
                "state": 8.01
             },
             "error": {
                "this": 1.45,
                "CBSA": 0.22,
                "state": 0.07
             },
             "numerators": {
                "this": 4350,
                "CBSA": 163762,
                "state": 3108823
             },
             "numerator_errors": {
                "this": 869,
                "CBSA": 4270,
                "state": 25604
             },
             "index": {
                "this": 100,
                "CBSA": 87,
                "state": 91
             },
             "error_ratio": {
                "this": 19.9,
                "CBSA": 2.6,
                "state": 0.9
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 2.06,
                "CBSA": 2.5,
                "state": 2.75
             },
             "error": {
                "this": 0.64,
                "CBSA": 0.11,
                "state": 0.03
             },
             "numerators": {
                "this": 1232,
                "CBSA": 49162,
                "state": 1069429
             },
             "numerator_errors": {
                "this": 381,
                "CBSA": 2113,
                "state": 11456
             },
             "index": {
                "this": 100,
                "CBSA": 82,
                "state": 75
             },
             "error_ratio": {
                "this": 31.1,
                "CBSA": 4.4,
                "state": 1.1
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 1.93,
                "CBSA": 1.81,
                "state": 1.3
             },
             "error": {
                "this": 0.8,
                "CBSA": 0.1,
                "state": 0.02
             },
             "numerators": {
                "this": 1153,
                "CBSA": 35635,
                "state": 503560
             },
             "numerator_errors": {
                "this": 476,
                "CBSA": 1923,
                "state": 8001
             },
             "index": {
                "this": 100,
                "CBSA": 107,
                "state": 148
             },
             "error_ratio": {
                "this": 41.5,
                "CBSA": 5.5,
                "state": 1.5
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 3.09,
                "CBSA": 1.76,
                "state": 0.8
             },
             "error": {
                "this": 0.73,
                "CBSA": 0.09,
                "state": 0.01
             },
             "numerators": {
                "this": 1849,
                "CBSA": 34664,
                "state": 311493
             },
             "numerator_errors": {
                "this": 437,
                "CBSA": 1863,
                "state": 5536
             },
             "index": {
                "this": 100,
                "CBSA": 176,
                "state": 386
             },
             "error_ratio": {
                "this": 23.6,
                "CBSA": 5.1,
                "state": 1.2
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 97.23,
                "CBSA": 88.15,
                "state": 83.31
             },
             "error": {
                "this": 2.6,
                "CBSA": 0.5,
                "state": 0.15
             },
             "numerators": {
                "this": 39835,
                "CBSA": 1211097,
                "state": 2.2052868E7
             },
             "numerator_errors": {
                "this": 1163.3,
                "CBSA": 6911.2,
                "state": 39734.1
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 110,
                "state": 117
             },
             "error_ratio": {
                "this": 2.7,
                "CBSA": 0.6,
                "state": 0.2
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 78.77,
                "CBSA": 51.47,
                "state": 33.93
             },
             "error": {
                "this": 2.22,
                "CBSA": 0.36,
                "state": 0.09
             },
             "numerators": {
                "this": 32270,
                "CBSA": 707206,
                "state": 8980726
             },
             "numerator_errors": {
                "this": 985.5,
                "CBSA": 4914.3,
                "state": 23065.1
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 153,
                "state": 232
             },
             "error_ratio": {
                "this": 2.8,
                "CBSA": 0.7,
                "state": 0.3
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 5-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 2.77,
                "CBSA": 11.85,
                "state": 16.69
             },
             "error": {
                "this": 0.51,
                "CBSA": 0.21,
                "state": 0.07
             },
             "numerators": {
                "this": 1134,
                "CBSA": 162819,
                "state": 4418675
             },
             "numerator_errors": {
                "this": 209.4,
                "CBSA": 2905.1,
                "state": 18934.1
             },
             "index": {
                "this": 100,
                "CBSA": 23,
                "state": 17
             },
             "error_ratio": {
                "this": 18.4,
                "CBSA": 1.8,
                "state": 0.4
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 5.5,
                "CBSA": 14.4,
                "state": 20.49
             },
             "error": {
                "this": 0.95,
                "CBSA": 0.24,
                "state": 0.08
             },
             "numerators": {
                "this": 2252,
                "CBSA": 197824,
                "state": 5423462
             },
             "numerator_errors": {
                "this": 388.3,
                "CBSA": 3326.3,
                "state": 22237.1
             },
             "index": {
                "this": 100,
                "CBSA": 38,
                "state": 27
             },
             "error_ratio": {
                "this": 17.3,
                "CBSA": 1.7,
                "state": 0.4
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 12.97,
                "CBSA": 22.28,
                "state": 28.89
             },
             "error": {
                "this": 1.16,
                "CBSA": 0.26,
                "state": 0.09
             },
             "numerators": {
                "this": 5313,
                "CBSA": 306067,
                "state": 7648680
             },
             "numerator_errors": {
                "this": 481,
                "CBSA": 3542.7,
                "state": 23501.3
             },
             "index": {
                "this": 100,
                "CBSA": 58,
                "state": 45
             },
             "error_ratio": {
                "this": 8.9,
                "CBSA": 1.2,
                "state": 0.3
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 34.38,
                "CBSA": 27.29,
                "state": 21.17
             },
             "error": {
                "this": 1.4,
                "CBSA": 0.25,
                "state": 0.07
             },
             "numerators": {
                "this": 14086,
                "CBSA": 374919,
                "state": 5603047
             },
             "numerator_errors": {
                "this": 597.5,
                "CBSA": 3457.6,
                "state": 17216.6
             },
             "index": {
                "this": 100,
                "CBSA": 126,
                "state": 162
             },
             "error_ratio": {
                "this": 4.1,
                "CBSA": 0.9,
                "state": 0.3
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 44.38,
                "CBSA": 24.19,
                "state": 12.76
             },
             "error": {
                "this": 1.84,
                "CBSA": 0.25,
                "state": 0.06
             },
             "numerators": {
                "this": 18184,
                "CBSA": 332287,
                "state": 3377679
             },
             "numerator_errors": {
                "this": 783.6,
                "CBSA": 3492.1,
                "state": 15348.8
             },
             "index": {
                "this": 100,
                "CBSA": 183,
                "state": 348
             },
             "error_ratio": {
                "this": 4.1,
                "CBSA": 1,
                "state": 0.5
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 53.09,
                "CBSA": 38.58,
                "state": 26.85
             },
             "error": {
                "this": 1.54,
                "CBSA": 0.26,
                "state": 0.09
             },
             "numerators": {
                "this": 31989,
                "CBSA": 767006,
                "state": 1.054721E7
             },
             "numerator_errors": {
                "this": 928,
                "CBSA": 5248,
                "state": 36592
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 138,
                "state": 198
             },
             "error_ratio": {
                "this": 2.9,
                "CBSA": 0.7,
                "state": 0.3
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 6.9,
                   "CBSA": 7.91,
                   "state": 6.42
                },
                "error": {
                   "this": 1.14,
                   "CBSA": 0.3,
                   "state": 0.07
                },
                "numerators": {
                   "this": 2207,
                   "CBSA": 60639,
                   "state": 677261
                },
                "numerator_errors": {
                   "this": 369,
                   "CBSA": 2354,
                   "state": 7344
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 107
                },
                "error_ratio": {
                   "this": 16.5,
                   "CBSA": 3.8,
                   "state": 1.1
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 88.18,
                   "CBSA": 65.91,
                   "state": 39.42
                },
                "error": {
                   "this": 1.2,
                   "CBSA": 0.62,
                   "state": 0.09
                },
                "numerators": {
                   "this": 28207,
                   "CBSA": 505503,
                   "state": 4157181
                },
                "numerator_errors": {
                   "this": 904,
                   "CBSA": 3277,
                   "state": 17026
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 224
                },
                "error_ratio": {
                   "this": 1.4,
                   "CBSA": 0.9,
                   "state": 0.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 0.83,
                   "CBSA": 1.7,
                   "state": 1.84
                },
                "error": {
                   "this": 0.39,
                   "CBSA": 0.14,
                   "state": 0.05
                },
                "numerators": {
                   "this": 267,
                   "CBSA": 13034,
                   "state": 193567
                },
                "numerator_errors": {
                   "this": 124,
                   "CBSA": 1055,
                   "state": 4869
                },
                "index": {
                   "this": 100,
                   "CBSA": 49,
                   "state": 45
                },
                "error_ratio": {
                   "this": 47,
                   "CBSA": 8.2,
                   "state": 2.7
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 0.29,
                   "CBSA": 0.53,
                   "state": 0.82
                },
                "error": {
                   "this": 0.26,
                   "CBSA": 0.09,
                   "state": 0.03
                },
                "numerators": {
                   "this": 94,
                   "CBSA": 4060,
                   "state": 86276
                },
                "numerator_errors": {
                   "this": 84,
                   "CBSA": 662,
                   "state": 2767
                },
                "index": {
                   "this": 100,
                   "CBSA": 55,
                   "state": 35
                },
                "error_ratio": {
                   "this": 89.7,
                   "CBSA": 17,
                   "state": 3.7
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 2.23,
                   "CBSA": 22.67,
                   "state": 50.28
                },
                "error": {
                   "this": 0.62,
                   "CBSA": 0.42,
                   "state": 0.15
                },
                "numerators": {
                   "this": 713,
                   "CBSA": 173901,
                   "state": 5302761
                },
                "numerator_errors": {
                   "this": 198,
                   "CBSA": 3417,
                   "state": 24213
                },
                "index": {
                   "this": 100,
                   "CBSA": 10,
                   "state": 4
                },
                "error_ratio": {
                   "this": 27.8,
                   "CBSA": 1.9,
                   "state": 0.3
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 1.57,
                   "CBSA": 1.29,
                   "state": 1.23
                },
                "error": {
                   "this": 0.61,
                   "CBSA": 0.09,
                   "state": 0.03
                },
                "numerators": {
                   "this": 501,
                   "CBSA": 9858,
                   "state": 130093
                },
                "numerator_errors": {
                   "this": 196,
                   "CBSA": 722,
                   "state": 3038
                },
                "index": {
                   "this": 100,
                   "CBSA": 122,
                   "state": 128
                },
                "error_ratio": {
                   "this": 38.9,
                   "CBSA": 7,
                   "state": 2.4
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 44.23
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.14
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 1.6292017E7
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 52781.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.3
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 42.92,
                   "CBSA": 52.99,
                   "state": 56.46
                },
                "error": {
                   "this": 3.43,
                   "CBSA": 0.67,
                   "state": 0.24
                },
                "numerators": {
                   "this": 5518,
                   "CBSA": 171505,
                   "state": 3709800
                },
                "numerator_errors": {
                   "this": 492,
                   "CBSA": 2186,
                   "state": 15797
                },
                "index": {
                   "this": 100,
                   "CBSA": 81,
                   "state": 76
                },
                "error_ratio": {
                   "this": 8,
                   "CBSA": 1.3,
                   "state": 0.4
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 2.28,
                   "CBSA": 22.53,
                   "state": 33.98
                },
                "error": {
                   "this": 1.33,
                   "CBSA": 0.54,
                   "state": 0.2
                },
                "numerators": {
                   "this": 293,
                   "CBSA": 72935,
                   "state": 2232947
                },
                "numerator_errors": {
                   "this": 172,
                   "CBSA": 1746,
                   "state": 13243
                },
                "index": {
                   "this": 100,
                   "CBSA": 10,
                   "state": 7
                },
                "error_ratio": {
                   "this": 58.3,
                   "CBSA": 2.4,
                   "state": 0.6
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 14.9,
                   "CBSA": 6.21,
                   "state": 2.92
                },
                "error": {
                   "this": 2.55,
                   "CBSA": 0.29,
                   "state": 0.07
                },
                "numerators": {
                   "this": 1915,
                   "CBSA": 20097,
                   "state": 191982
                },
                "numerator_errors": {
                   "this": 337,
                   "CBSA": 935,
                   "state": 4345
                },
                "index": {
                   "this": 100,
                   "CBSA": 240,
                   "state": 510
                },
                "error_ratio": {
                   "this": 17.1,
                   "CBSA": 4.7,
                   "state": 2.4
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 39.11,
                   "CBSA": 17,
                   "state": 5.73
                },
                "error": {
                   "this": 3.33,
                   "CBSA": 0.46,
                   "state": 0.06
                },
                "numerators": {
                   "this": 5028,
                   "CBSA": 55026,
                   "state": 376241
                },
                "numerator_errors": {
                   "this": 472,
                   "CBSA": 1483,
                   "state": 4139
                },
                "index": {
                   "this": 100,
                   "CBSA": 230,
                   "state": 683
                },
                "error_ratio": {
                   "this": 8.5,
                   "CBSA": 2.7,
                   "state": 1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.79,
                   "CBSA": 1.27,
                   "state": 0.91
                },
                "error": {
                   "this": 0.68,
                   "CBSA": 0.18,
                   "state": 0.04
                },
                "numerators": {
                   "this": 101,
                   "CBSA": 4106,
                   "state": 59648
                },
                "numerator_errors": {
                   "this": 87,
                   "CBSA": 595,
                   "state": 2823
                },
                "index": {
                   "this": 100,
                   "CBSA": 62,
                   "state": 87
                },
                "error_ratio": {
                   "this": 86.1,
                   "CBSA": 14.2,
                   "state": 4.4
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 36.08,
                   "CBSA": 46.23,
                   "state": 55.62
                },
                "error": {
                   "this": 1.79,
                   "CBSA": 0.27,
                   "state": 0.11
                },
                "numerators": {
                   "this": 16155,
                   "CBSA": 713748,
                   "state": 1.6830152E7
                },
                "numerator_errors": {
                   "this": 851.2,
                   "CBSA": 4210.1,
                   "state": 34792.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 78,
                   "state": 65
                },
                "error_ratio": {
                   "this": 5,
                   "CBSA": 0.6,
                   "state": 0.2
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 2.46,
                   "CBSA": 17.25,
                   "state": 27.58
                },
                "error": {
                   "this": 0.63,
                   "CBSA": 0.21,
                   "state": 0.08
                },
                "numerators": {
                   "this": 1103,
                   "CBSA": 266293,
                   "state": 8345569
                },
                "numerator_errors": {
                   "this": 281.3,
                   "CBSA": 3165.9,
                   "state": 23054.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 14,
                   "state": 9
                },
                "error_ratio": {
                   "this": 25.6,
                   "CBSA": 1.2,
                   "state": 0.3
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 13.7,
                   "CBSA": 9.53,
                   "state": 4.85
                },
                "error": {
                   "this": 1.56,
                   "CBSA": 0.24,
                   "state": 0.04
                },
                "numerators": {
                   "this": 6135,
                   "CBSA": 147182,
                   "state": 1468932
                },
                "numerator_errors": {
                   "this": 706.4,
                   "CBSA": 3659.2,
                   "state": 12705.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 144,
                   "state": 282
                },
                "error_ratio": {
                   "this": 11.4,
                   "CBSA": 2.5,
                   "state": 0.8
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 45.54,
                   "CBSA": 25.62,
                   "state": 10.88
                },
                "error": {
                   "this": 1.54,
                   "CBSA": 0.23,
                   "state": 0.03
                },
                "numerators": {
                   "this": 20391,
                   "CBSA": 395544,
                   "state": 3293073
                },
                "numerator_errors": {
                   "this": 781.7,
                   "CBSA": 3565.1,
                   "state": 10307.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 178,
                   "state": 419
                },
                "error_ratio": {
                   "this": 3.4,
                   "CBSA": 0.9,
                   "state": 0.3
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 2.22,
                   "CBSA": 1.36,
                   "state": 1.07
                },
                "error": {
                   "this": 0.64,
                   "CBSA": 0.09,
                   "state": 0.02
                },
                "numerators": {
                   "this": 993,
                   "CBSA": 21019,
                   "state": 323625
                },
                "numerator_errors": {
                   "this": 289,
                   "CBSA": 1339.9,
                   "state": 7069.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 163,
                   "state": 207
                },
                "error_ratio": {
                   "this": 28.8,
                   "CBSA": 6.6,
                   "state": 1.9
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 84,
                   "CBSA": 2442,
                   "state": 66574
                },
                "error": {
                   "this": 63.64,
                   "CBSA": 319.93,
                   "state": 1628.6
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 3,
                   "state": null
                },
                "error_ratio": {
                   "this": 75.8,
                   "CBSA": 13.1,
                   "state": 2.4
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 352,
                   "CBSA": 6382,
                   "state": 145536
                },
                "error": {
                   "this": 141.09,
                   "CBSA": 405.23,
                   "state": 2399.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 6,
                   "state": null
                },
                "error_ratio": {
                   "this": 40.1,
                   "CBSA": 6.3,
                   "state": 1.6
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 373,
                   "CBSA": 19651,
                   "state": 561083
                },
                "error": {
                   "this": 127.24,
                   "CBSA": 863.14,
                   "state": 4350.77
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 34.1,
                   "CBSA": 4.4,
                   "state": 0.8
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 95,
                   "CBSA": 7981,
                   "state": 284290
                },
                "error": {
                   "this": 69.07,
                   "CBSA": 586.46,
                   "state": 3865.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 72.7,
                   "CBSA": 7.3,
                   "state": 1.4
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 50,
                   "CBSA": 7720,
                   "state": 309021
                },
                "error": {
                   "this": 55.4,
                   "CBSA": 544.05,
                   "state": 4425.46
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 1,
                   "state": null
                },
                "error_ratio": {
                   "this": 110.8,
                   "CBSA": 7,
                   "state": 1.4
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 1149,
                   "CBSA": 50028,
                   "state": 1446385
                },
                "error": {
                   "this": 213,
                   "CBSA": 1263,
                   "state": 8117
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 18.5,
                   "CBSA": 2.5,
                   "state": 0.6
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 89,
                   "CBSA": 4063,
                   "state": 128146
                },
                "error": {
                   "this": 55,
                   "CBSA": 388,
                   "state": 2994
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 5-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 2,
                   "state": null
                },
                "error_ratio": {
                   "this": 61.8,
                   "CBSA": 9.5,
                   "state": 2.3
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 1238,
                "CBSA": 54091,
                "state": 1574531
             },
             "error": {
                "this": 221,
                "CBSA": 1285,
                "state": 8842
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 2,
                "state": null
             },
             "error_ratio": {
                "this": 17.9,
                "CBSA": 2.4,
                "state": 0.6
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 2.77,
                "CBSA": 3.51,
                "state": 5.23
             },
             "error": {
                "this": 0.49,
                "CBSA": 0.08,
                "state": 0.03
             },
             "numerators": {
                "this": 1238,
                "CBSA": 54091,
                "state": 1574531
             },
             "numerator_errors": {
                "this": 221,
                "CBSA": 1285,
                "state": 8842
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": 100,
                "CBSA": 79,
                "state": 53
             },
             "error_ratio": {
                "this": 17.7,
                "CBSA": 2.3,
                "state": 0.6
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 29295948,
       "awater": 2520,
       "display_name": "Cupertino, CA",
       "full_geoid": "16000US0617610",
       "population": 60614,
       "simple_name": "Cupertino",
       "sumlevel": "160",
       "square_miles": 11.3,
       "population_density": 5327.2
    }
 }

 export default cupertino