exports.PORTOLA_VALLEY_SCHOOLS = [
		{
			"id": 30355,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.387619,
			"lon": -122.237755,
			"name": "Carillon Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "815 Portola Road",
				"street2": null,
				"zip": "94028",
				"city": "Portola Valley"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94028",
			"type": "school",
			"links": {
				"profile": "/california/portola-valley/30355-Carillon-Pre-School/",
				"reviews": "/california/portola-valley/30355-Carillon-Pre-School/#Reviews",
				"collegeSuccess": "/california/portola-valley/30355-Carillon-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6909,
			"districtId": 793,
			"districtName": "Portola Valley Elementary",
			"districtCity": "Portola Valley",
			"levelCode": "e,m",
			"lat": 37.367977,
			"lon": -122.212074,
			"name": "Corte Madera",
			"gradeLevels": "4-8",
			"assigned": null,
			"address": {
				"street1": "4575 Alpine Road",
				"street2": null,
				"zip": "94028",
				"city": "Portola Valley"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94028",
			"type": "school",
			"links": {
				"profile": "/california/portola-valley/6909-Corte-Madera-School/",
				"reviews": "/california/portola-valley/6909-Corte-Madera-School/#Reviews",
				"collegeSuccess": "/california/portola-valley/6909-Corte-Madera-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 309,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 12,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 15
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 66
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31560,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e",
			"lat": 37.383686,
			"lon": -122.231781,
			"name": "Creekside 21st Century Learning Lab",
			"gradeLevels": "4-5",
			"assigned": null,
			"address": {
				"street1": "884 Portola Road B-1",
				"street2": null,
				"zip": "94028",
				"city": "Portola Valley"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94028",
			"type": "school",
			"links": {
				"profile": "/california/portola-valley/31560-Creekside-21st-Century-Learning-Lab/",
				"reviews": "/california/portola-valley/31560-Creekside-21st-Century-Learning-Lab/#Reviews",
				"collegeSuccess": "/california/portola-valley/31560-Creekside-21st-Century-Learning-Lab/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 14,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 79
				},
				{
					"label": "Two or more races",
					"percentage": 21
				}
			],
			"remediationData": {}
		},
		{
			"id": 6910,
			"districtId": 793,
			"districtName": "Portola Valley Elementary",
			"districtCity": "Portola Valley",
			"levelCode": "e",
			"lat": 37.380604,
			"lon": -122.217392,
			"name": "Ormondale Elementary",
			"gradeLevels": "K-3",
			"assigned": null,
			"address": {
				"street1": "200 Shawnee Pass",
				"street2": null,
				"zip": "94028",
				"city": "Portola Valley"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94028",
			"type": "school",
			"links": {
				"profile": "/california/portola-valley/6910-Ormondale-Elementary-School/",
				"reviews": "/california/portola-valley/6910-Ormondale-Elementary-School/#Reviews",
				"collegeSuccess": "/california/portola-valley/6910-Ormondale-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "8%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 266,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 13,
			"subratings": {
				"Test Scores Rating": 10
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 8
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 65
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"percentage": 14
				},
				{
					"label": "Asian",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 21381,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.376808,
			"lon": -122.198586,
			"name": "Windmill School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "4141 Alpine Road",
				"street2": null,
				"zip": "94028",
				"city": "Portola Valley"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94028",
			"type": "school",
			"links": {
				"profile": "/california/portola-valley/21381-Windmill-School/",
				"reviews": "/california/portola-valley/21381-Windmill-School/#Reviews",
				"collegeSuccess": "/california/portola-valley/21381-Windmill-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 16642,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.402561,
			"lon": -122.196236,
			"name": "Woodland School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "360 La Cuesta Drive",
				"street2": null,
				"zip": "94028",
				"city": "Portola Valley"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94028",
			"type": "school",
			"links": {
				"profile": "/california/portola-valley/16642-Woodland-School/",
				"reviews": "/california/portola-valley/16642-Woodland-School/#Reviews",
				"collegeSuccess": "/california/portola-valley/16642-Woodland-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 264,
			"parentRating": 4,
			"numReviews": 43,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 70
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "Asian",
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 8092,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.375908,
			"lon": -122.213112,
			"name": "Woodside Priory",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "302 Portola Road",
				"street2": null,
				"zip": "94028",
				"city": "Portola Valley"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94028",
			"type": "school",
			"links": {
				"profile": "/california/portola-valley/8092-Woodside-Priory/",
				"reviews": "/california/portola-valley/8092-Woodside-Priory/#Reviews",
				"collegeSuccess": "/california/portola-valley/8092-Woodside-Priory/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 385,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 99
				},
				{
					"label": "African American",
					"percentage": 1
				}
			],
			"remediationData": {}
		}
	]