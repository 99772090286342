exports.SAN_JOSE_SCHOOLS = [{'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28066,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28066-Anne-Darling-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28066-Anne-Darling-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28066-Anne-Darling-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Anne Darling School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28067,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28067-Barbara-Comstock-Morse-Cdc/#College_success',
                         'profile': '/california/san-jose/28067-Barbara-Comstock-Morse-Cdc/',
                         'reviews': '/california/san-jose/28067-Barbara-Comstock-Morse-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Barbara Comstock Morse Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28069,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28069-Del-Mar-Licoln-Day-Care/#College_success',
                               'profile': '/california/san-jose/28069-Del-Mar-Licoln-Day-Care/',
                               'reviews': '/california/san-jose/28069-Del-Mar-Licoln-Day-Care/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Del Mar Licoln Day Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28071,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28071-Foulks-Ranch-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28071-Foulks-Ranch-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28071-Foulks-Ranch-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Foulks Ranch School-Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28072,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28072-Franklin-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/28072-Franklin-Child-Development-Center/',
                         'reviews': '/california/san-jose/28072-Franklin-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Franklin Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28074,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28074-Maeola-Beitzel-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28074-Maeola-Beitzel-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28074-Maeola-Beitzel-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Maeola Beitzel School-Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28075,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28075-Mary-Tsukamoto-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28075-Mary-Tsukamoto-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28075-Mary-Tsukamoto-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Mary Tsukamoto School-Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28077,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28077-Plaza-Vista-Cdc/#College_success',
                               'profile': '/california/san-jose/28077-Plaza-Vista-Cdc/',
                               'reviews': '/california/san-jose/28077-Plaza-Vista-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Plaza Vista Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #26',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28080,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28080-Reith-School-Age-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/28080-Reith-School-Age-Child-Development-Center/',
                         'reviews': '/california/san-jose/28080-Reith-School-Age-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Reith School Age Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28081,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28081-Springbrook-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28081-Springbrook-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28081-Springbrook-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Springbrook School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28082,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28082-Stone-Lake-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28082-Stone-Lake-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28082-Stone-Lake-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Stone Lake School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28083,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28083-Turtle-Rock-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28083-Turtle-Rock-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28083-Turtle-Rock-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Turtle Rock School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28084,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28084-Tustin-Memorial-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28084-Tustin-Memorial-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28084-Tustin-Memorial-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Tustin Memorial School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28085,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28085-University-Park-Cdc/#College_success',
                               'profile': '/california/san-jose/28085-University-Park-Cdc/',
                               'reviews': '/california/san-jose/28085-University-Park-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'University Park Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '660 Sinclair Drive',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28088,
                     'lat': 37.349064,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28088-Macsa-Youth-Center-Latchkey-Dc/#College_success',
                         'profile': '/california/san-jose/28088-Macsa-Youth-Center-Latchkey-Dc/',
                         'reviews': '/california/san-jose/28088-Macsa-Youth-Center-Latchkey-Dc/#Reviews'},
                     'lon': -121.841309,
                     'name': 'Macsa Youth Center Latchkey Dc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28089,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28089-Santa-Teresa-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/28089-Santa-Teresa-Child-Development-Center/',
                         'reviews': '/california/san-jose/28089-Santa-Teresa-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Santa Teresa Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 28101,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28101-Ramon-Tayfoya-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28101-Ramon-Tayfoya-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28101-Ramon-Tayfoya-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Ramon Tayfoya School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28154,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28154-Lake-Mathews-Cdc/#College_success',
                               'profile': '/california/san-jose/28154-Lake-Mathews-Cdc/',
                               'reviews': '/california/san-jose/28154-Lake-Mathews-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Lake Mathews Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28176,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28176-John-F-Kennedy-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/28176-John-F-Kennedy-Child-Development-Center/',
                         'reviews': '/california/san-jose/28176-John-F-Kennedy-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'John F Kennedy Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28177,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28177-Newport-Heights-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/28177-Newport-Heights-School-Age-Cdc/',
                         'reviews': '/california/san-jose/28177-Newport-Heights-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Newport Heights School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28183,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28183-Anderson-School-Age-Cdc/#College_success',
                               'profile': '/california/san-jose/28183-Anderson-School-Age-Cdc/',
                               'reviews': '/california/san-jose/28183-Anderson-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Anderson School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1051 West San Fernando Street',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28202,
                     'lat': 37.329063,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28202-Little-Lions-Pre-School/#College_success',
                               'profile': '/california/san-jose/28202-Little-Lions-Pre-School/',
                               'reviews': '/california/san-jose/28202-Little-Lions-Pre-School/#Reviews'},
                     'lon': -121.910973,
                     'name': 'Little Lions Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4250 Kirk Road',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28215,
                     'lat': 37.258755,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28215-Running-Start-Pre-School/#College_success',
                               'profile': '/california/san-jose/28215-Running-Start-Pre-School/',
                               'reviews': '/california/san-jose/28215-Running-Start-Pre-School/#Reviews'},
                     'lon': -121.904259,
                     'name': 'Running Start Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28315,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28315-Ramon-Tafoya-Cdc/#College_success',
                               'profile': '/california/san-jose/28315-Ramon-Tafoya-Cdc/',
                               'reviews': '/california/san-jose/28315-Ramon-Tafoya-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Ramon Tafoya Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2515 North 1st Street',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28332,
                     'lat': 37.38287,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28332-4cs-Child-Development-Center-I/#College_success',
                         'profile': '/california/san-jose/28332-4cs-Child-Development-Center-I/',
                         'reviews': '/california/san-jose/28332-4cs-Child-Development-Center-I/#Reviews'},
                     'lon': -121.926018,
                     'name': "4c's Child Development Center I",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1596 Davis Street',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28442,
                     'lat': 37.336636,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28442-A-Special-Place-Dc-Trinity/#College_success',
                         'profile': '/california/san-jose/28442-A-Special-Place-Dc-Trinity/',
                         'reviews': '/california/san-jose/28442-A-Special-Place-Dc-Trinity/#Reviews'},
                     'lon': -121.932632,
                     'name': 'A Special Place Dc-Trinity',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1680 Foley Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28446,
                     'lat': 37.33033,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28446-Kidango-Cdc-Hubbard/#College_success',
                               'profile': '/california/san-jose/28446-Kidango-Cdc-Hubbard/',
                               'reviews': '/california/san-jose/28446-Kidango-Cdc-Hubbard/#Reviews'},
                     'lon': -121.839676,
                     'name': 'Kidango Cdc-Hubbard',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28489,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28489-Cesar-Chavez-Pre-School/#College_success',
                               'profile': '/california/san-jose/28489-Cesar-Chavez-Pre-School/',
                               'reviews': '/california/san-jose/28489-Cesar-Chavez-Pre-School/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Cesar Chavez Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1945 Terilyn Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28524,
                     'lat': 37.327354,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28524-Sjb-Cdc-Grove-Head-Start/#College_success',
                               'profile': '/california/san-jose/28524-Sjb-Cdc-Grove-Head-Start/',
                               'reviews': '/california/san-jose/28524-Sjb-Cdc-Grove-Head-Start/#Reviews'},
                     'lon': -121.837944,
                     'name': 'Sjb Cdc-Grove Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28569,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28569-King-City-Migrant-Cdc/#College_success',
                               'profile': '/california/san-jose/28569-King-City-Migrant-Cdc/',
                               'reviews': '/california/san-jose/28569-King-City-Migrant-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'King City Migrant Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28584,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28584-Freedom-Crest-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/28584-Freedom-Crest-Child-Development-Center/',
                         'reviews': '/california/san-jose/28584-Freedom-Crest-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Freedom Crest Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2575 Coit Drive',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28640,
                     'lat': 37.2747,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28640-Promise-Land-Pre-School/#College_success',
                               'profile': '/california/san-jose/28640-Promise-Land-Pre-School/',
                               'reviews': '/california/san-jose/28640-Promise-Land-Pre-School/#Reviews'},
                     'lon': -121.924133,
                     'name': 'Promise Land Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1670 Moorpark Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28644,
                     'lat': 37.315739,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28644-Neighborhood-Christian-Ps/#College_success',
                         'profile': '/california/san-jose/28644-Neighborhood-Christian-Ps/',
                         'reviews': '/california/san-jose/28644-Neighborhood-Christian-Ps/#Reviews'},
                     'lon': -121.922249,
                     'name': 'Neighborhood Christian Ps',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2920 Fowler Road',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28676,
                     'lat': 37.309631,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28676-Kids-Korner-Day-Care/#College_success',
                               'profile': '/california/san-jose/28676-Kids-Korner-Day-Care/',
                               'reviews': '/california/san-jose/28676-Kids-Korner-Day-Care/#Reviews'},
                     'lon': -121.782814,
                     'name': 'Kids Korner Day Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1835 Cunningham Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28721,
                     'lat': 37.332645,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28721-Overfelt-High-School-Head-Start/#College_success',
                         'profile': '/california/san-jose/28721-Overfelt-High-School-Head-Start/',
                         'reviews': '/california/san-jose/28721-Overfelt-High-School-Head-Start/#Reviews'},
                     'lon': -121.831566,
                     'name': 'Overfelt High School Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28809,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28809-Chandler-Tripp-Ampm-Head-Start/#College_success',
                         'profile': '/california/san-jose/28809-Chandler-Tripp-Ampm-Head-Start/',
                         'reviews': '/california/san-jose/28809-Chandler-Tripp-Ampm-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Chandler Tripp Ampm Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28810,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28810-Alum-Rock-Ampm-Head-Start/#College_success',
                         'profile': '/california/san-jose/28810-Alum-Rock-Ampm-Head-Start/',
                         'reviews': '/california/san-jose/28810-Alum-Rock-Ampm-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Alum Rock Ampm Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28811,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28811-Job-Corps-2-And-3-Head-Start/#College_success',
                         'profile': '/california/san-jose/28811-Job-Corps-2-And-3-Head-Start/',
                         'reviews': '/california/san-jose/28811-Job-Corps-2-And-3-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Job Corps 2 And 3 Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1990 Kammerer Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28813,
                     'lat': 37.34901,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28813-Presley-1--2-Am-Pm-Head-Start/#College_success',
                         'profile': '/california/san-jose/28813-Presley-1--2-Am-Pm-Head-Start/',
                         'reviews': '/california/san-jose/28813-Presley-1--2-Am-Pm-Head-Start/#Reviews'},
                     'lon': -121.84581,
                     'name': 'Presley 1 & 2 Am Pm Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28814,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28814-Los-Arboles-1-2-Head-Start/#College_success',
                         'profile': '/california/san-jose/28814-Los-Arboles-1-2-Head-Start/',
                         'reviews': '/california/san-jose/28814-Los-Arboles-1-2-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Los Arboles 1 2 Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28916,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28916-Rosemary-St-Pre-School/#College_success',
                               'profile': '/california/san-jose/28916-Rosemary-St-Pre-School/',
                               'reviews': '/california/san-jose/28916-Rosemary-St-Pre-School/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Rosemary St Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3810 San Felipe Road',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28931,
                     'lat': 37.309212,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/28931-Good-Neighbor-Education-Center/#College_success',
                         'profile': '/california/san-jose/28931-Good-Neighbor-Education-Center/',
                         'reviews': '/california/san-jose/28931-Good-Neighbor-Education-Center/#Reviews'},
                     'lon': -121.781387,
                     'name': 'Good Neighbor Education Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2146 Lincoln Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28975,
                     'lat': 37.292,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28975-Lincoln-Infant-Center/#College_success',
                               'profile': '/california/san-jose/28975-Lincoln-Infant-Center/',
                               'reviews': '/california/san-jose/28975-Lincoln-Infant-Center/#Reviews'},
                     'lon': -121.888756,
                     'name': 'Lincoln Infant Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3005 Linburn Court',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29007,
                     'lat': 37.314377,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29007-Bertuccelli-Child-Care-Center/#College_success',
                         'profile': '/california/san-jose/29007-Bertuccelli-Child-Care-Center/',
                         'reviews': '/california/san-jose/29007-Bertuccelli-Child-Care-Center/#Reviews'},
                     'lon': -121.805,
                     'name': 'Bertuccelli Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6804 Rainbow Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29008,
                     'lat': 37.300877,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29008-Marias-Day-Care/#College_success',
                               'profile': '/california/san-jose/29008-Marias-Day-Care/',
                               'reviews': '/california/san-jose/29008-Marias-Day-Care/#Reviews'},
                     'lon': -122.023926,
                     'name': 'Marias Day Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '247 Vineyard Drive',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29009,
                     'lat': 37.228161,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29009-Blossom-Vly-International-Ps/#College_success',
                         'profile': '/california/san-jose/29009-Blossom-Vly-International-Ps/',
                         'reviews': '/california/san-jose/29009-Blossom-Vly-International-Ps/#Reviews'},
                     'lon': -121.786354,
                     'name': 'Blossom Vly International Ps',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4660 Eastus Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29024,
                     'lat': 37.305847,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29024-Moreland-Area-Community-Center/#College_success',
                         'profile': '/california/san-jose/29024-Moreland-Area-Community-Center/',
                         'reviews': '/california/san-jose/29024-Moreland-Area-Community-Center/#Reviews'},
                     'lon': -121.986671,
                     'name': 'Moreland Area Community Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4237 Monet Circle',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29065,
                     'lat': 37.271217,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29065-Keiki-Company-Fcc/#College_success',
                               'profile': '/california/san-jose/29065-Keiki-Company-Fcc/',
                               'reviews': '/california/san-jose/29065-Keiki-Company-Fcc/#Reviews'},
                     'lon': -121.837715,
                     'name': 'Keiki Company Fcc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda Fl 3',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 29080,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29080-Ymca-Day-Care-Sakamoto/#College_success',
                               'profile': '/california/san-jose/29080-Ymca-Day-Care-Sakamoto/',
                               'reviews': '/california/san-jose/29080-Ymca-Day-Care-Sakamoto/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Day Care-Sakamoto',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29086,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29086-Ymca-Day-Care-Carolyn-Clark/#College_success',
                         'profile': '/california/san-jose/29086-Ymca-Day-Care-Carolyn-Clark/',
                         'reviews': '/california/san-jose/29086-Ymca-Day-Care-Carolyn-Clark/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Day Care-Carolyn Clark',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29090,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29090-Ymca-Kids-Place-Day-Care-Oak/#College_success',
                         'profile': '/california/san-jose/29090-Ymca-Kids-Place-Day-Care-Oak/',
                         'reviews': '/california/san-jose/29090-Ymca-Kids-Place-Day-Care-Oak/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Kids Place Day Care-Oak',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29093,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29093-Ladera-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/29093-Ladera-Child-Development-Center/',
                         'reviews': '/california/san-jose/29093-Ladera-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Ladera Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29105,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29105-Ymca-Day-Care-Silver-Oak/#College_success',
                               'profile': '/california/san-jose/29105-Ymca-Day-Care-Silver-Oak/',
                               'reviews': '/california/san-jose/29105-Ymca-Day-Care-Silver-Oak/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Day Care-Silver Oak',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29117,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29117-Birch-Ln-School-Age-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/29117-Birch-Ln-School-Age-Child-Development-Center/',
                         'reviews': '/california/san-jose/29117-Birch-Ln-School-Age-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Birch Ln School Age Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 29127,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29127-Ymca-John-Muir/#College_success',
                               'profile': '/california/san-jose/29127-Ymca-John-Muir/',
                               'reviews': '/california/san-jose/29127-Ymca-John-Muir/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca John Muir',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29134,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29134-Ymca-Day-Care-Meyerholz/#College_success',
                               'profile': '/california/san-jose/29134-Ymca-Day-Care-Meyerholz/',
                               'reviews': '/california/san-jose/29134-Ymca-Day-Care-Meyerholz/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Day Care-Meyerholz',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29156,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29156-Ymca-Child-Care-Center-Barrett/#College_success',
                         'profile': '/california/san-jose/29156-Ymca-Child-Care-Center-Barrett/',
                         'reviews': '/california/san-jose/29156-Ymca-Child-Care-Center-Barrett/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Child Care Center-Barrett',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29171,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29171-Ymca-Kids-Place-Dc-Almond/#College_success',
                         'profile': '/california/san-jose/29171-Ymca-Kids-Place-Dc-Almond/',
                         'reviews': '/california/san-jose/29171-Ymca-Kids-Place-Dc-Almond/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Kids Place Dc-Almond',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29184,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29184-Woodland-Cdc/#College_success',
                               'profile': '/california/san-jose/29184-Woodland-Cdc/',
                               'reviews': '/california/san-jose/29184-Woodland-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Woodland Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '801 Hibiscus Lane',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29235,
                     'lat': 37.312675,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29235-Macc-Phelan-Ps-W-Valley-Mid/#College_success',
                         'profile': '/california/san-jose/29235-Macc-Phelan-Ps-W-Valley-Mid/',
                         'reviews': '/california/san-jose/29235-Macc-Phelan-Ps-W-Valley-Mid/#Reviews'},
                     'lon': -121.97316,
                     'name': 'Macc Phelan Ps W Valley Mid',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29281,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29281-Elitha-Donner-Sch-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/29281-Elitha-Donner-Sch-Age-Cdc/',
                         'reviews': '/california/san-jose/29281-Elitha-Donner-Sch-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Elitha Donner Sch/Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29305,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29305-Nordstrom-Cdc-School-Age/#College_success',
                               'profile': '/california/san-jose/29305-Nordstrom-Cdc-School-Age/',
                               'reviews': '/california/san-jose/29305-Nordstrom-Cdc-School-Age/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Nordstrom Cdc School Age',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29306,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29306-Christopher-Rnch-Head-Start/#College_success',
                         'profile': '/california/san-jose/29306-Christopher-Rnch-Head-Start/',
                         'reviews': '/california/san-jose/29306-Christopher-Rnch-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Christopher Rnch Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1200 Blossom Hill Road',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29469,
                     'lat': 37.248993,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29469-Almaden-Head-Start/#College_success',
                               'profile': '/california/san-jose/29469-Almaden-Head-Start/',
                               'reviews': '/california/san-jose/29469-Almaden-Head-Start/#Reviews'},
                     'lon': -121.879562,
                     'name': 'Almaden Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29559,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29559-Villa-San-Pedro-Head-Start/#College_success',
                         'profile': '/california/san-jose/29559-Villa-San-Pedro-Head-Start/',
                         'reviews': '/california/san-jose/29559-Villa-San-Pedro-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Villa San Pedro Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5526 Bigoak Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29716,
                     'lat': 37.306824,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29716-Empire-Montessori-Pre-School/#College_success',
                         'profile': '/california/san-jose/29716-Empire-Montessori-Pre-School/',
                         'reviews': '/california/san-jose/29716-Empire-Montessori-Pre-School/#Reviews'},
                     'lon': -121.999603,
                     'name': 'Empire Montessori Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '947 Willowleaf Drive #1304',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29726,
                     'lat': 37.308697,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29726-Nellys-Childcare--Pre-School/#College_success',
                         'profile': '/california/san-jose/29726-Nellys-Childcare--Pre-School/',
                         'reviews': '/california/san-jose/29726-Nellys-Childcare--Pre-School/#Reviews'},
                     'lon': -121.92495,
                     'name': "Nelly's Childcare & Pre-School",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '383 Spar Avenue',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29750,
                     'lat': 37.320854,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29750-Shalom-Christian-Academy/#College_success',
                               'profile': '/california/san-jose/29750-Shalom-Christian-Academy/',
                               'reviews': '/california/san-jose/29750-Shalom-Christian-Academy/#Reviews'},
                     'lon': -121.951706,
                     'name': 'Shalom Christian Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1400 Parkmoor Avenue #220',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29786,
                     'lat': 37.316017,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29786-Vine-Street-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/29786-Vine-Street-Child-Development-Center/',
                         'reviews': '/california/san-jose/29786-Vine-Street-Child-Development-Center/#Reviews'},
                     'lon': -121.912704,
                     'name': 'Vine Street Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5116 Forest View Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29803,
                     'lat': 37.312778,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29803-Acme-Education-Child-Care-Center/#College_success',
                         'profile': '/california/san-jose/29803-Acme-Education-Child-Care-Center/',
                         'reviews': '/california/san-jose/29803-Acme-Education-Child-Care-Center/#Reviews'},
                     'lon': -121.99295,
                     'name': 'Acme Education Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4927 Bela Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29807,
                     'lat': 37.299644,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29807-Creative-Minds-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/29807-Creative-Minds-Child-Development-Center/',
                         'reviews': '/california/san-jose/29807-Creative-Minds-Child-Development-Center/#Reviews'},
                     'lon': -121.987801,
                     'name': 'Creative Minds Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '440 Colony Crest Drive',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29813,
                     'lat': 37.257908,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29813-Champions-Child-Care-Center-Luigi-Aprea/#College_success',
                         'profile': '/california/san-jose/29813-Champions-Child-Care-Center-Luigi-Aprea/',
                         'reviews': '/california/san-jose/29813-Champions-Child-Care-Center-Luigi-Aprea/#Reviews'},
                     'lon': -121.841873,
                     'name': 'Champions Child Care Center-Luigi Aprea',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '440 Colony Crest Drive',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29814,
                     'lat': 37.257908,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29814-Champions-Child-Care-Center-Rod-Kelley/#College_success',
                         'profile': '/california/san-jose/29814-Champions-Child-Care-Center-Rod-Kelley/',
                         'reviews': '/california/san-jose/29814-Champions-Child-Care-Center-Rod-Kelley/#Reviews'},
                     'lon': -121.841873,
                     'name': 'Champions Child Care Center-Rod Kelley',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29880,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29880-Darling-Head-Start/#College_success',
                               'profile': '/california/san-jose/29880-Darling-Head-Start/',
                               'reviews': '/california/san-jose/29880-Darling-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Darling Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29896,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29896-San-Antonio-Head-Start/#College_success',
                               'profile': '/california/san-jose/29896-San-Antonio-Head-Start/',
                               'reviews': '/california/san-jose/29896-San-Antonio-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'San Antonio Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1400 Parkmoor Avenue #220',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29933,
                     'lat': 37.316017,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29933-Sjb-Head-Start-Cassell/#College_success',
                               'profile': '/california/san-jose/29933-Sjb-Head-Start-Cassell/',
                               'reviews': '/california/san-jose/29933-Sjb-Head-Start-Cassell/#Reviews'},
                     'lon': -121.912704,
                     'name': 'Sjb Head Start-Cassell',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29934,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29934-Story-Roadhead-Start/#College_success',
                               'profile': '/california/san-jose/29934-Story-Roadhead-Start/',
                               'reviews': '/california/san-jose/29934-Story-Roadhead-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Story Roadhead Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29937,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29937-Edenvale-Head-Start/#College_success',
                               'profile': '/california/san-jose/29937-Edenvale-Head-Start/',
                               'reviews': '/california/san-jose/29937-Edenvale-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Edenvale Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29951,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29951-Del-Buono-Head-Start/#College_success',
                               'profile': '/california/san-jose/29951-Del-Buono-Head-Start/',
                               'reviews': '/california/san-jose/29951-Del-Buono-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Del Buono Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4075 Evergreen Village Square',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 29964,
                     'lat': 37.314587,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29964-Achieve-Learning-Center/#College_success',
                               'profile': '/california/san-jose/29964-Achieve-Learning-Center/',
                               'reviews': '/california/san-jose/29964-Achieve-Learning-Center/#Reviews'},
                     'lon': -121.772934,
                     'name': 'Achieve Learning Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6453 Little Falls Drive',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29977,
                     'lat': 37.221802,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/29977-Montessori-Way-School/#College_success',
                               'profile': '/california/san-jose/29977-Montessori-Way-School/',
                               'reviews': '/california/san-jose/29977-Montessori-Way-School/#Reviews'},
                     'lon': -121.87188,
                     'name': 'Montessori Way School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4089 Lakemont Court',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 29996,
                     'lat': 37.317375,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/29996-Evergreen-Montessori-School-Yb/#College_success',
                         'profile': '/california/san-jose/29996-Evergreen-Montessori-School-Yb/',
                         'reviews': '/california/san-jose/29996-Evergreen-Montessori-School-Yb/#Reviews'},
                     'lon': -121.773605,
                     'name': 'Evergreen Montessori School-Yb',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '80 Descanso Drive #1201',
                                 'street2': '',
                                 'zip': '95134'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30007,
                     'lat': 37.406784,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30007-Monarch-Christian-Pre-School/#College_success',
                         'profile': '/california/san-jose/30007-Monarch-Christian-Pre-School/',
                         'reviews': '/california/san-jose/30007-Monarch-Christian-Pre-School/#Reviews'},
                     'lon': -121.938782,
                     'name': 'Monarch Christian Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': 'Po Box 5123',
                                 'street2': '',
                                 'zip': '95150'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30011,
                     'lat': 37.389999,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30011-Premier-International-Lang-Cdc/#College_success',
                         'profile': '/california/san-jose/30011-Premier-International-Lang-Cdc/',
                         'reviews': '/california/san-jose/30011-Premier-International-Lang-Cdc/#Reviews'},
                     'lon': -121.900002,
                     'name': 'Premier International Lang Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30039,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30039-Patwin-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/30039-Patwin-Child-Development-Center/',
                         'reviews': '/california/san-jose/30039-Patwin-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Patwin Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30052,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30052-Gibson-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/30052-Gibson-Child-Development-Center/',
                         'reviews': '/california/san-jose/30052-Gibson-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Gibson Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '668 North 1st Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30160,
                     'lat': 37.348488,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30160-San-Jose-Bilingual-Mont-Ps/#College_success',
                         'profile': '/california/san-jose/30160-San-Jose-Bilingual-Mont-Ps/',
                         'reviews': '/california/san-jose/30160-San-Jose-Bilingual-Mont-Ps/#Reviews'},
                     'lon': -121.899338,
                     'name': 'San Jose Bilingual Mont Ps',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1149 Minnesota Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30161,
                     'lat': 37.303055,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/30161-Willows-Nursery-School/#College_success',
                               'profile': '/california/san-jose/30161-Willows-Nursery-School/',
                               'reviews': '/california/san-jose/30161-Willows-Nursery-School/#Reviews'},
                     'lon': -121.899437,
                     'name': 'Willows Nursery School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2360 McLaughlin Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30216,
                     'lat': 37.316425,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30216-Gateway-Pre-School-Academy/#College_success',
                         'profile': '/california/san-jose/30216-Gateway-Pre-School-Academy/',
                         'reviews': '/california/san-jose/30216-Gateway-Pre-School-Academy/#Reviews'},
                     'lon': -121.836365,
                     'name': 'Gateway Pre-School Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1569 Queens Crossing Drive',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30231,
                     'lat': 37.402096,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30231-Happy-Childhood-Pre-School/#College_success',
                         'profile': '/california/san-jose/30231-Happy-Childhood-Pre-School/',
                         'reviews': '/california/san-jose/30231-Happy-Childhood-Pre-School/#Reviews'},
                     'lon': -121.860611,
                     'name': 'Happy Childhood Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '196 Martinvale Lane',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30267,
                     'lat': 37.229237,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30267-De-Colores-Child-Care-Center/#College_success',
                         'profile': '/california/san-jose/30267-De-Colores-Child-Care-Center/',
                         'reviews': '/california/san-jose/30267-De-Colores-Child-Care-Center/#Reviews'},
                     'lon': -121.779343,
                     'name': 'De Colores Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1155 Meridian Avenue #110',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30299,
                     'lat': 37.303303,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30299-Paseo-Senter-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/30299-Paseo-Senter-Child-Development-Center/',
                         'reviews': '/california/san-jose/30299-Paseo-Senter-Child-Development-Center/#Reviews'},
                     'lon': -121.91407,
                     'name': 'Paseo Senter Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2177 Cottle Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30314,
                     'lat': 37.289165,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30314-First-Discoveries-Christian-Ps/#College_success',
                         'profile': '/california/san-jose/30314-First-Discoveries-Christian-Ps/',
                         'reviews': '/california/san-jose/30314-First-Discoveries-Christian-Ps/#Reviews'},
                     'lon': -121.894722,
                     'name': 'First Discoveries Christian Ps',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1395 East Santa Clara Street',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30448,
                     'lat': 37.34927,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30448-Dom-Dinis-Day-Care--Pre-School/#College_success',
                         'profile': '/california/san-jose/30448-Dom-Dinis-Day-Care--Pre-School/',
                         'reviews': '/california/san-jose/30448-Dom-Dinis-Day-Care--Pre-School/#Reviews'},
                     'lon': -121.863113,
                     'name': 'Dom Dinis Day Care & Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '405 South 10th Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 30516,
                     'lat': 37.334038,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30516-Nuestra-Escuelita-Pre-School/#College_success',
                         'profile': '/california/san-jose/30516-Nuestra-Escuelita-Pre-School/',
                         'reviews': '/california/san-jose/30516-Nuestra-Escuelita-Pre-School/#Reviews'},
                     'lon': -121.87674,
                     'name': 'Nuestra Escuelita Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5845 Allen Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 83,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 63},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 16},
                                       {'label': 'Two or more races', 'percentage': 11},
                                       {'label': 'Hispanic', 'percentage': 11}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 30551,
                     'lat': 37.244099,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30551-Action-Day-Primary-Plus-Allen-School/#College_success',
                         'profile': '/california/san-jose/30551-Action-Day-Primary-Plus-Allen-School/',
                         'reviews': '/california/san-jose/30551-Action-Day-Primary-Plus-Allen-School/#Reviews'},
                     'lon': -121.857086,
                     'name': 'Action Day Primary Plus Allen School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5200 Dent Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 168,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 72},
                                       {'label': 'Asian', 'percentage': 11},
                                       {'label': 'Hispanic', 'percentage': 11},
                                       {'label': 'African American', 'percentage': 6}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 30570,
                     'lat': 37.24905,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30570-Appleseed-Almaden-Montessori-School/#College_success',
                         'profile': '/california/san-jose/30570-Appleseed-Almaden-Montessori-School/',
                         'reviews': '/california/san-jose/30570-Appleseed-Almaden-Montessori-School/#Reviews'},
                     'lon': -121.897247,
                     'name': 'Appleseed Almaden Montessori School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1631 Peregrino Way',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 24,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 83},
                                       {'label': 'White', 'percentage': 13},
                                       {'label': 'African American', 'percentage': 4}],
                     'gradeLevels': 'K-10',
                     'highlighted': false,
                     'id': 30572,
                     'lat': 37.28997,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30572-As-Safa-Institute-As-Safa-Academy/#College_success',
                         'profile': '/california/san-jose/30572-As-Safa-Institute-As-Safa-Academy/',
                         'reviews': '/california/san-jose/30572-As-Safa-Institute-As-Safa-Academy/#Reviews'},
                     'lon': -121.90937,
                     'name': 'As-Safa Institute/As-Safa Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6601 Camden Avenue',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 76,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 67},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 33}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 30608,
                     'lat': 37.222172,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/30608-Cornerstone-Kindergarten/#College_success',
                               'profile': '/california/san-jose/30608-Cornerstone-Kindergarten/',
                               'reviews': '/california/san-jose/30608-Cornerstone-Kindergarten/#Reviews'},
                     'lon': -121.855721,
                     'name': 'Cornerstone Kindergarten',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4250 Latimer Avenue',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 355,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 96},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-9',
                     'highlighted': false,
                     'id': 30748,
                     'lat': 37.292393,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/30748-San-Iku-Gakuin/#College_success',
                               'profile': '/california/san-jose/30748-San-Iku-Gakuin/',
                               'reviews': '/california/san-jose/30748-San-Iku-Gakuin/#Reviews'},
                     'lon': -121.978035,
                     'name': 'San-Iku Gakuin',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '420 Calero Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '39%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 366,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 52},
                                       {'label': 'Hispanic', 'percentage': 61},
                                       {'label': 'Asian', 'percentage': 20},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '10-12',
                     'highlighted': false,
                     'id': 31076,
                     'lat': 37.241322,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/31076-Calero-High/#College_success',
                               'profile': '/california/san-jose/31076-Calero-High/',
                               'reviews': '/california/san-jose/31076-Calero-High/#Reviews'},
                     'lon': -121.82663,
                     'name': 'Calero High',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '52%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 10,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1855 Lucretia Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 210,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 29,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 63, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 30,
                                        'rating': 6},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 31093,
                     'lat': 37.320114,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/31093-College-Connection-Academy/#College_success',
                         'profile': '/california/san-jose/31093-College-Connection-Academy/',
                         'reviews': '/california/san-jose/31093-College-Connection-Academy/#Reviews'},
                     'lon': -121.847511,
                     'name': 'College Connection Academy',
                     'numReviews': 37,
                     'parentRating': 3,
                     'percentLowIncome': '29%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Equity Overview Rating': 7, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1601 Cunningham Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 277,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 92,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 71,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 22, 'rating': 4},
                                       {'label': 'Asian', 'percentage': 4},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 31196,
                     'lat': 37.326569,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/31196-Alpha-Jose-Hernandez-Middle/#College_success',
                         'profile': '/california/san-jose/31196-Alpha-Jose-Hernandez-Middle/',
                         'reviews': '/california/san-jose/31196-Alpha-Jose-Hernandez-Middle/#Reviews'},
                     'lon': -121.83506,
                     'name': 'Alpha: Jose Hernandez Middle',
                     'numReviews': 18,
                     'parentRating': 4,
                     'percentLowIncome': '92%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 29,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1045 Mazzone Drive',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 7,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '1-12',
                     'highlighted': false,
                     'id': 31575,
                     'lat': 37.233803,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/31575-Almaden-Hills-Academy/#College_success',
                               'profile': '/california/san-jose/31575-Almaden-Hills-Academy/',
                               'reviews': '/california/san-jose/31575-Almaden-Hills-Academy/#Reviews'},
                     'lon': -121.86615,
                     'name': 'Almaden Hills Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1250 Redoaks Drive',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 6,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '1-12',
                     'highlighted': false,
                     'id': 31581,
                     'lat': 37.302917,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/31581-Heritage-Academy/#College_success',
                               'profile': '/california/san-jose/31581-Heritage-Academy/',
                               'reviews': '/california/san-jose/31581-Heritage-Academy/#Reviews'},
                     'lon': -121.94873,
                     'name': 'Heritage Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3001 Ross Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 12,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K',
                     'highlighted': false,
                     'id': 31583,
                     'lat': 37.266651,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/31583-Learning-Pathways-Kindergarten/#College_success',
                         'profile': '/california/san-jose/31583-Learning-Pathways-Kindergarten/',
                         'reviews': '/california/san-jose/31583-Learning-Pathways-Kindergarten/#Reviews'},
                     'lon': -121.920532,
                     'name': 'Learning Pathways Kindergarten',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3635 Pearl Avenue',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 50,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '1-6',
                     'highlighted': false,
                     'id': 31588,
                     'lat': 37.273113,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/31588-One-World-Montessori-School/#College_success',
                         'profile': '/california/san-jose/31588-One-World-Montessori-School/',
                         'reviews': '/california/san-jose/31588-One-World-Montessori-School/#Reviews'},
                     'lon': -121.870705,
                     'name': 'One World Montessori School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3001 Ross Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 627,
                     'districtName': 'Cambrian School District',
                     'enrollment': 502,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 3},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 30, 'rating': 10},
                                       {'label': 'White', 'percentage': 46, 'rating': 10},
                                       {'label': 'Hispanic', 'percentage': 12},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 32423,
                     'lat': 37.266651,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/32423-Steindorf-STEAM-School/#College_success',
                               'profile': '/california/san-jose/32423-Steindorf-STEAM-School/',
                               'reviews': '/california/san-jose/32423-Steindorf-STEAM-School/#Reviews'},
                     'lon': -121.920532,
                     'name': 'Steindorf STEAM School',
                     'numReviews': 73,
                     'parentRating': 4,
                     'percentLowIncome': '3%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4250 Latimer Avenue',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 646,
                     'districtName': 'Moreland School District',
                     'enrollment': 554,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 35,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 16, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 39,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 27, 'rating': 9},
                                       {'label': 'Two or more races', 'percentage': 6},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 31680,
                     'lat': 37.292393,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/31680-Latimer-Elementary-School/#College_success',
                         'profile': '/california/san-jose/31680-Latimer-Elementary-School/',
                         'reviews': '/california/san-jose/31680-Latimer-Elementary-School/#Reviews'},
                     'lon': -121.978027,
                     'name': 'Latimer Elementary School',
                     'numReviews': 47,
                     'parentRating': 4,
                     'percentLowIncome': '35%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3124 Williamsburg Drive',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 31719,
                     'lat': 37.297523,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/31719-Community-First-School/#College_success',
                               'profile': '/california/san-jose/31719-Community-First-School/',
                               'reviews': '/california/san-jose/31719-Community-First-School/#Reviews'},
                     'lon': -121.950851,
                     'name': 'Community First School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1402 Monterey Highway',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 655,
                     'districtName': 'San Jose Unified School District',
                     'enrollment': 607,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 82,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Asian', 'percentage': 3, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 89,
                                        'rating': 2},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 31777,
                     'lat': 37.31633,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/31777-Downtown-College-Preparatory-Middle/#College_success',
                         'profile': '/california/san-jose/31777-Downtown-College-Preparatory-Middle/',
                         'reviews': '/california/san-jose/31777-Downtown-College-Preparatory-Middle/#Reviews'},
                     'lon': -121.872879,
                     'name': 'Downtown College Preparatory Middle',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '82%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3173 Senter Road',
                                 'street2': null,
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 631,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 71,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 28, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 65,
                                        'rating': 7},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 32439,
                     'lat': 37.292561,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/32439-Rocketship-Rising-Stars/#College_success',
                               'profile': '/california/san-jose/32439-Rocketship-Rising-Stars/',
                               'reviews': '/california/san-jose/32439-Rocketship-Rising-Stars/#Reviews'},
                     'lon': -121.832428,
                     'name': 'Rocketship Rising Stars',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '71%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Equity Overview Rating': 8, 'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '70 South Jackson Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 628,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 78,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 79,
                                        'rating': 7},
                                       {'label': 'Asian', 'percentage': 13},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 32212,
                     'lat': 37.357609,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/32212-Rocketship-Fuerza-Community-Prep/#College_success',
                         'profile': '/california/san-jose/32212-Rocketship-Fuerza-Community-Prep/',
                         'reviews': '/california/san-jose/32212-Rocketship-Fuerza-Community-Prep/#Reviews'},
                     'lon': -121.842323,
                     'name': 'Rocketship Fuerza Community Prep',
                     'numReviews': 8,
                     'parentRating': 5,
                     'percentLowIncome': '78%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 36,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 7,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '14271 Story Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 261,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 77,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 95,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 32215,
                     'lat': 37.359211,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/32215-Voices-College-Bound-Language-Academy-At-Mt.-Pleasant/#College_success',
                         'profile': '/california/san-jose/32215-Voices-College-Bound-Language-Academy-At-Mt.-Pleasant/',
                         'reviews': '/california/san-jose/32215-Voices-College-Bound-Language-Academy-At-Mt.-Pleasant/#Reviews'},
                     'lon': -121.816101,
                     'name': 'Voices College-Bound Language Academy At Mt. Pleasant',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '77%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Equity Overview Rating': 5, 'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1250 South King Road',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 416,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 80,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 17, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 79,
                                        'rating': 6},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 32217,
                     'lat': 37.337944,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/32217-KIPP-Prize-Preparatory-Academy/#College_success',
                         'profile': '/california/san-jose/32217-KIPP-Prize-Preparatory-Academy/',
                         'reviews': '/california/san-jose/32217-KIPP-Prize-Preparatory-Academy/#Reviews'},
                     'lon': -121.840172,
                     'name': 'KIPP Prize Preparatory Academy',
                     'numReviews': 14,
                     'parentRating': 4,
                     'percentLowIncome': '80%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1855 Lucretia Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '81%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 363,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 76,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 95,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 32218,
                     'lat': 37.3204,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/32218-Luis-Valdez-Leadership-Academy/#College_success',
                         'profile': '/california/san-jose/32218-Luis-Valdez-Leadership-Academy/',
                         'reviews': '/california/san-jose/32218-Luis-Valdez-Leadership-Academy/#Reviews'},
                     'lon': -121.848122,
                     'name': 'Luis Valdez Leadership Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '76%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'College Readiness Rating': 3,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '14265 Story Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 270,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 83,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 98,
                                        'rating': 2},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 32219,
                     'lat': 37.365883,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/32219-B.-Roberto-Cruz-Leadership-Academy/#College_success',
                         'profile': '/california/san-jose/32219-B.-Roberto-Cruz-Leadership-Academy/',
                         'reviews': '/california/san-jose/32219-B.-Roberto-Cruz-Leadership-Academy/#Reviews'},
                     'lon': -121.805717,
                     'name': 'B. Roberto Cruz Leadership Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '83%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'College Readiness Rating': 1,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1881 Cunningham Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 438,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 88,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 82,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 8},
                                       {'label': 'Asian', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 32220,
                     'lat': 37.330196,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/32220-Alpha-Cindy-Avitia-High/#College_success',
                               'profile': '/california/san-jose/32220-Alpha-Cindy-Avitia-High/',
                               'reviews': '/california/san-jose/32220-Alpha-Cindy-Avitia-High/#Reviews'},
                     'lon': -121.829117,
                     'name': 'Alpha: Cindy Avitia High',
                     'numReviews': 34,
                     'parentRating': 4,
                     'percentLowIncome': '88%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'College Readiness Rating': 4,
                                    'Equity Overview Rating': 9,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1665 Santee Drive',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 288,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 90,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 97,
                                        'rating': 3},
                                       {'label': 'Asian', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 32222,
                     'lat': 37.328014,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/32222-Ace-Franklin-Mckinley/#College_success',
                               'profile': '/california/san-jose/32222-Ace-Franklin-Mckinley/',
                               'reviews': '/california/san-jose/32222-Ace-Franklin-Mckinley/#Reviews'},
                     'lon': -121.845306,
                     'name': 'Ace Franklin Mckinley',
                     'numReviews': 7,
                     'parentRating': 5,
                     'percentLowIncome': '90%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1155 East Julian Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 655,
                     'districtName': 'San Jose Unified School District',
                     'enrollment': 257,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 91,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 95,
                                        'rating': 3},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Asian', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 32226,
                     'lat': 37.350983,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/32226-Ace-Inspire-Academy/#College_success',
                               'profile': '/california/san-jose/32226-Ace-Inspire-Academy/',
                               'reviews': '/california/san-jose/32226-Ace-Inspire-Academy/#Reviews'},
                     'lon': -121.872047,
                     'name': 'Ace Inspire Academy',
                     'numReviews': 7,
                     'parentRating': 5,
                     'percentLowIncome': '91%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1390 Five Wounds Ln',
                                 'street2': null,
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 250,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 90},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'Asian', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': '9-10',
                     'highlighted': false,
                     'id': 32547,
                     'lat': 37.350246,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/32547-Cristo-Rey-San-Jose-High-School/#College_success',
                         'profile': '/california/san-jose/32547-Cristo-Rey-San-Jose-High-School/',
                         'reviews': '/california/san-jose/32547-Cristo-Rey-San-Jose-High-School/#Reviews'},
                     'lon': -121.864014,
                     'name': 'Cristo Rey San Jose High School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '484 East San Fernando Street',
                                 'street2': null,
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 13,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 92},
                                       {'label': 'Hispanic', 'percentage': 85},
                                       {'label': 'Asian', 'percentage': 8}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 32731,
                     'lat': 37.338879,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/32731-Legacy-Academy/#College_success',
                               'profile': '/california/san-jose/32731-Legacy-Academy/',
                               'reviews': '/california/san-jose/32731-Legacy-Academy/#Reviews'},
                     'lon': -121.879013,
                     'name': 'Legacy Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '92%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1970 Cinerella Lane',
                                 'street2': null,
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 172,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 80,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 97,
                                        'rating': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-3',
                     'highlighted': false,
                     'id': 32732,
                     'lat': 37.344196,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/32732-Adelante-Dual-Language-Academy-Ii/#College_success',
                         'profile': '/california/san-jose/32732-Adelante-Dual-Language-Academy-Ii/',
                         'reviews': '/california/san-jose/32732-Adelante-Dual-Language-Academy-Ii/#Reviews'},
                     'lon': -121.840393,
                     'name': 'Adelante Dual Language Academy Ii',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '80%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {'Equity Overview Rating': 1, 'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '500 Saratoga Avenue',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 1853,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 69},
                                       {'label': 'White', 'percentage': 25},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'Hispanic', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 8457,
                     'lat': 37.317413,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/8457-The-Harker-School/#College_success',
                               'profile': '/california/san-jose/8457-The-Harker-School/',
                               'reviews': '/california/san-jose/8457-The-Harker-School/#Reviews'},
                     'lon': -121.971642,
                     'name': 'The Harker School',
                     'numReviews': 74,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1450 Leigh Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 457,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 58},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 13},
                                       {'label': 'Two or more races', 'percentage': 11},
                                       {'label': 'Hispanic', 'percentage': 10},
                                       {'label': 'African American', 'percentage': 8},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 8458,
                     'lat': 37.296806,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/8458-Valley-Christian-Elementary-School/#College_success',
                         'profile': '/california/san-jose/8458-Valley-Christian-Elementary-School/',
                         'reviews': '/california/san-jose/8458-Valley-Christian-Elementary-School/#Reviews'},
                     'lon': -121.922173,
                     'name': 'Valley Christian Elementary School',
                     'numReviews': 21,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '14381 Bercaw Ln',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 24946,
                     'lat': 37.259708,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/24946-Early-Discoveries-CDC/#College_success',
                               'profile': '/california/san-jose/24946-Early-Discoveries-CDC/',
                               'reviews': '/california/san-jose/24946-Early-Discoveries-CDC/#Reviews'},
                     'lon': -121.92868,
                     'name': 'Early Discoveries CDC',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5670 Camden Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '6-12',
                     'highlighted': false,
                     'id': 8703,
                     'lat': 37.235401,
                     'levelCode': 'm,h',
                     'links': {'collegeSuccess': '/california/san-jose/8703-Beacon/#College_success',
                               'profile': '/california/san-jose/8703-Beacon/',
                               'reviews': '/california/san-jose/8703-Beacon/#Reviews'},
                     'lon': -121.903297,
                     'name': 'Beacon',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2790 S Kind Rd',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 103,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 59},
                                       {'label': 'Hispanic', 'percentage': 19},
                                       {'label': 'White', 'percentage': 15},
                                       {'label': 'African American', 'percentage': 8}],
                     'gradeLevels': 'PK-12',
                     'highlighted': false,
                     'id': 8798,
                     'lat': 37.314781,
                     'levelCode': 'p,e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/8798-Liberty-Baptist-School/#College_success',
                               'profile': '/california/san-jose/8798-Liberty-Baptist-School/',
                               'reviews': '/california/san-jose/8798-Liberty-Baptist-School/#Reviews'},
                     'lon': -121.81768,
                     'name': 'Liberty Baptist School',
                     'numReviews': 12,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2280 Kenwood Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 67,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 63},
                                       {'label': 'Asian', 'percentage': 21},
                                       {'label': 'African American', 'percentage': 9},
                                       {'label': 'Hispanic', 'percentage': 4},
                                       {'label': 'Pacific Islander', 'percentage': 3}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 8828,
                     'lat': 37.332699,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/8828-Moran-Autism-Center/#College_success',
                               'profile': '/california/san-jose/8828-Moran-Autism-Center/',
                               'reviews': '/california/san-jose/8828-Moran-Autism-Center/#Reviews'},
                     'lon': -121.943199,
                     'name': 'Moran Autism Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '100 Skyway Drive',
                                 'street2': 'Suite 110',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 1421,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 39},
                                       {'label': 'Two or more races', 'percentage': 28},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 17},
                                       {'label': 'Hispanic', 'percentage': 11},
                                       {'label': 'African American', 'percentage': 5}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 8904,
                     'lat': 37.275749,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/8904-Valley-Christian-High-School/#College_success',
                         'profile': '/california/san-jose/8904-Valley-Christian-High-School/',
                         'reviews': '/california/san-jose/8904-Valley-Christian-High-School/#Reviews'},
                     'lon': -121.826744,
                     'name': 'Valley Christian High School',
                     'numReviews': 49,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5200 Dent Ave',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 126,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 52},
                                       {'label': 'White', 'percentage': 40},
                                       {'label': 'African American', 'percentage': 6},
                                       {'label': 'Hispanic', 'percentage': 2}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 8937,
                     'lat': 37.24892,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/8937-Carden-Academy-Of-Almaden/#College_success',
                               'profile': '/california/san-jose/8937-Carden-Academy-Of-Almaden/',
                               'reviews': '/california/san-jose/8937-Carden-Academy-Of-Almaden/#Reviews'},
                     'lon': -121.897812,
                     'name': 'Carden Academy of Almaden',
                     'numReviews': 39,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3150 Sierra Road',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 240,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 87},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'Hispanic', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 9082,
                     'lat': 37.397327,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/9082-St.-Victor-School/#College_success',
                               'profile': '/california/san-jose/9082-St.-Victor-School/',
                               'reviews': '/california/san-jose/9082-St.-Victor-School/#Reviews'},
                     'lon': -121.851761,
                     'name': 'St. Victor School',
                     'numReviews': 38,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '51 North 9th Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 251,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 56},
                                       {'label': 'Asian', 'percentage': 31},
                                       {'label': 'Pacific Islander', 'percentage': 6},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'White', 'percentage': 2}],
                     'gradeLevels': 'PK-12',
                     'highlighted': false,
                     'id': 9084,
                     'lat': 37.340351,
                     'levelCode': 'p,e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9084-St.-Patrick-Elementary-School/#College_success',
                         'profile': '/california/san-jose/9084-St.-Patrick-Elementary-School/',
                         'reviews': '/california/san-jose/9084-St.-Patrick-Elementary-School/#Reviews'},
                     'lon': -121.883224,
                     'name': 'St. Patrick Elementary School',
                     'numReviews': 99,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': "300 O'Connor Drive",
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 347,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 84},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'Hispanic', 'percentage': 5},
                                       {'label': 'Asian', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 9089,
                     'lat': 37.325382,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9089-St.-Martin-Of-Tours-School/#College_success',
                         'profile': '/california/san-jose/9089-St.-Martin-Of-Tours-School/',
                         'reviews': '/california/san-jose/9089-St.-Martin-Of-Tours-School/#Reviews'},
                     'lon': -121.939308,
                     'name': 'St. Martin Of Tours School',
                     'numReviews': 9,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1051 West San Fernando Street',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 275,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 47},
                                       {'label': 'White', 'percentage': 44},
                                       {'label': 'Asian', 'percentage': 8},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 9091,
                     'lat': 37.328835,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9091-St.-Leo-The-Great-Catholic-School/#College_success',
                         'profile': '/california/san-jose/9091-St.-Leo-The-Great-Catholic-School/',
                         'reviews': '/california/san-jose/9091-St.-Leo-The-Great-Catholic-School/#Reviews'},
                     'lon': -121.911041,
                     'name': 'St. Leo the Great Catholic School',
                     'numReviews': 40,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4601 Hyland Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 456,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 47},
                                       {'label': 'White', 'percentage': 25},
                                       {'label': 'Asian', 'percentage': 25},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 9096,
                     'lat': 37.372108,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/9096-St.-John-Vianney/#College_success',
                               'profile': '/california/san-jose/9096-St.-John-Vianney/',
                               'reviews': '/california/san-jose/9096-St.-John-Vianney/#Reviews'},
                     'lon': -121.827362,
                     'name': 'St. John Vianney',
                     'numReviews': 26,
                     'parentRating': 2,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '15325 Woodard Road',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 628,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 54},
                                       {'label': 'Asian', 'percentage': 19},
                                       {'label': 'Two or more races', 'percentage': 14},
                                       {'label': 'Hispanic', 'percentage': 11},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 9098,
                     'lat': 37.260525,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9098-St.-Frances-Cabrini-Elementary-School/#College_success',
                         'profile': '/california/san-jose/9098-St.-Frances-Cabrini-Elementary-School/',
                         'reviews': '/california/san-jose/9098-St.-Frances-Cabrini-Elementary-School/#Reviews'},
                     'lon': -121.936218,
                     'name': 'St. Frances Cabrini Elementary School',
                     'numReviews': 44,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2278 Booksin Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 627,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 88},
                                       {'label': 'Hispanic', 'percentage': 5},
                                       {'label': 'Asian', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 9102,
                     'lat': 37.283199,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9102-St.-Christopher-Elementary-School/#College_success',
                         'profile': '/california/san-jose/9102-St.-Christopher-Elementary-School/',
                         'reviews': '/california/san-jose/9102-St.-Christopher-Elementary-School/#Reviews'},
                     'lon': -121.903069,
                     'name': 'St. Christopher Elementary School',
                     'numReviews': 20,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4950 Mitty Way',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 283,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 53},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 25},
                                       {'label': 'Hispanic', 'percentage': 16},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 9106,
                     'lat': 37.31485,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/9106-Queen-Of-Apostles-School/#College_success',
                               'profile': '/california/san-jose/9106-Queen-Of-Apostles-School/',
                               'reviews': '/california/san-jose/9106-Queen-Of-Apostles-School/#Reviews'},
                     'lon': -121.99144,
                     'name': 'Queen Of Apostles School',
                     'numReviews': 46,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2281 Plummer Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 750,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 54},
                                       {'label': 'Asian', 'percentage': 17},
                                       {'label': 'Two or more races', 'percentage': 15},
                                       {'label': 'Hispanic', 'percentage': 14}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 9107,
                     'lat': 37.283295,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/9107-Presentation-High-School/#College_success',
                               'profile': '/california/san-jose/9107-Presentation-High-School/',
                               'reviews': '/california/san-jose/9107-Presentation-High-School/#Reviews'},
                     'lon': -121.900055,
                     'name': 'Presentation High School',
                     'numReviews': 57,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '596 South Second Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 630,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 44},
                                       {'label': 'White', 'percentage': 32},
                                       {'label': 'Hispanic', 'percentage': 16},
                                       {'label': 'Two or more races', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 9108,
                     'lat': 37.32819,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9108-Notre-Dame-High-School-San-Jose/#College_success',
                         'profile': '/california/san-jose/9108-Notre-Dame-High-School-San-Jose/',
                         'reviews': '/california/san-jose/9108-Notre-Dame-High-School-San-Jose/#Reviews'},
                     'lon': -121.882278,
                     'name': 'Notre Dame High School San Jose',
                     'numReviews': 17,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1940 Cunningham Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 126,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 42},
                                       {'label': 'Asian', 'percentage': 29},
                                       {'label': 'Two or more races', 'percentage': 18},
                                       {'label': 'White', 'percentage': 9},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 9109,
                     'lat': 37.331089,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/9109-Most-Holy-Trinity-School/#College_success',
                               'profile': '/california/san-jose/9109-Most-Holy-Trinity-School/',
                               'reviews': '/california/san-jose/9109-Most-Holy-Trinity-School/#Reviews'},
                     'lon': -121.8274,
                     'name': 'Most Holy Trinity School',
                     'numReviews': 34,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1390 Five Wounds Ln',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 9111,
                     'lat': 37.350399,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/9111-Five-Wounds-School/#College_success',
                               'profile': '/california/san-jose/9111-Five-Wounds-School/',
                               'reviews': '/california/san-jose/9111-Five-Wounds-School/#Reviews'},
                     'lon': -121.863876,
                     'name': 'Five Wounds School',
                     'numReviews': 1,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '960 West Hedding Street',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 1640,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 55},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 26},
                                       {'label': 'Hispanic', 'percentage': 13},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 3}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 9112,
                     'lat': 37.342484,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9112-Bellarmine-College-Preparatory-School/#College_success',
                         'profile': '/california/san-jose/9112-Bellarmine-College-Preparatory-School/',
                         'reviews': '/california/san-jose/9112-Bellarmine-College-Preparatory-School/#Reviews'},
                     'lon': -121.917908,
                     'name': 'Bellarmine College Preparatory School',
                     'numReviews': 16,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '750 N Capitol Ave',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 15802,
                     'lat': 37.378689,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15802-Merryhill-School-San-Jose/#College_success',
                         'profile': '/california/san-jose/15802-Merryhill-School-San-Jose/',
                         'reviews': '/california/san-jose/15802-Merryhill-School-San-Jose/#Reviews'},
                     'lon': -121.851288,
                     'name': 'Merryhill School San Jose',
                     'numReviews': 4,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1201 South Winchester Boulevard',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 35,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 30580,
                     'lat': 37.304043,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30580-Bethel-Preschool--Kindergarten/#College_success',
                         'profile': '/california/san-jose/30580-Bethel-Preschool--Kindergarten/',
                         'reviews': '/california/san-jose/30580-Bethel-Preschool--Kindergarten/#Reviews'},
                     'lon': -121.95137,
                     'name': 'Bethel Preschool & Kindergarten',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '540 Sands Drive',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 236,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 30},
                                       {'label': 'Hispanic', 'percentage': 30},
                                       {'label': 'Asian', 'percentage': 20},
                                       {'label': 'African American', 'percentage': 15},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 6}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 9276,
                     'lat': 37.288601,
                     'levelCode': 'p,e,m',
                     'links': {'collegeSuccess': '/california/san-jose/9276-Achiever-Christian-School/#College_success',
                               'profile': '/california/san-jose/9276-Achiever-Christian-School/',
                               'reviews': '/california/san-jose/9276-Achiever-Christian-School/#Reviews'},
                     'lon': -121.873199,
                     'name': 'Achiever Christian School',
                     'numReviews': 23,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3435 Birchwood Ln',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 524,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 66},
                                       {'label': 'White', 'percentage': 13},
                                       {'label': 'Two or more races', 'percentage': 12},
                                       {'label': 'Hispanic', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 9280,
                     'lat': 37.417728,
                     'levelCode': 'p,e,m',
                     'links': {'collegeSuccess': '/california/san-jose/9280-Milpitas-Christian-School/#College_success',
                               'profile': '/california/san-jose/9280-Milpitas-Christian-School/',
                               'reviews': '/california/san-jose/9280-Milpitas-Christian-School/#Reviews'},
                     'lon': -121.863579,
                     'name': 'Milpitas Christian School',
                     'numReviews': 37,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3500 Amber Drive',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 494,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 29},
                                       {'label': 'Hispanic', 'percentage': 28},
                                       {'label': 'White', 'percentage': 26},
                                       {'label': 'African American', 'percentage': 9},
                                       {'label': 'Pacific Islander', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 2}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 9281,
                     'lat': 37.304119,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9281-Primary-Plus-Elementary-School/#College_success',
                         'profile': '/california/san-jose/9281-Primary-Plus-Elementary-School/',
                         'reviews': '/california/san-jose/9281-Primary-Plus-Elementary-School/#Reviews'},
                     'lon': -121.959503,
                     'name': 'Primary Plus Elementary School',
                     'numReviews': 9,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5100 Camden Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 140,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 75},
                                       {'label': 'Two or more races', 'percentage': 19},
                                       {'label': 'Hispanic', 'percentage': 4},
                                       {'label': 'Asian', 'percentage': 3}],
                     'gradeLevels': 'PK-5',
                     'highlighted': false,
                     'id': 9449,
                     'lat': 37.246063,
                     'levelCode': 'p,e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9449-St.-Timothys-Christian-Academy/#College_success',
                         'profile': '/california/san-jose/9449-St.-Timothys-Christian-Academy/',
                         'reviews': '/california/san-jose/9449-St.-Timothys-Christian-Academy/#Reviews'},
                     'lon': -121.906799,
                     'name': "St. Timothy's Christian Academy",
                     'numReviews': 20,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6835 Trinidad Drive',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 406,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 74},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 18},
                                       {'label': 'Pacific Islander', 'percentage': 3},
                                       {'label': 'Hispanic', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 9452,
                     'lat': 37.219288,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9452-Almaden-Country-Day-School/#College_success',
                         'profile': '/california/san-jose/9452-Almaden-Country-Day-School/',
                         'reviews': '/california/san-jose/9452-Almaden-Country-Day-School/#Reviews'},
                     'lon': -121.851494,
                     'name': 'Almaden Country Day School',
                     'numReviews': 69,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1170 Foxworthy Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 22,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 73},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 15},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'Hispanic', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 9487,
                     'lat': 37.277199,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9487-One-World-Montessori-School/#College_success',
                         'profile': '/california/san-jose/9487-One-World-Montessori-School/',
                         'reviews': '/california/san-jose/9487-One-World-Montessori-School/#Reviews'},
                     'lon': -121.88166,
                     'name': 'One World Montessori School',
                     'numReviews': 7,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5670 Camden Ave',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 9543,
                     'lat': 37.2346,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9543-Almaden-Preparatory-School/#College_success',
                         'profile': '/california/san-jose/9543-Almaden-Preparatory-School/',
                         'reviews': '/california/san-jose/9543-Almaden-Preparatory-School/#Reviews'},
                     'lon': -121.903397,
                     'name': 'Almaden Preparatory School',
                     'numReviews': 1,
                     'parentRating': 1,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3320 San Felipe Road',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 110,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 30},
                                       {'label': 'Asian', 'percentage': 20},
                                       {'label': 'Two or more races', 'percentage': 20},
                                       {'label': 'Hispanic', 'percentage': 20},
                                       {'label': 'African American', 'percentage': 10}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 13130,
                     'lat': 37.313396,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/13130-Kindercare-Learning-Center/#College_success',
                         'profile': '/california/san-jose/13130-Kindercare-Learning-Center/',
                         'reviews': '/california/san-jose/13130-Kindercare-Learning-Center/#Reviews'},
                     'lon': -121.789513,
                     'name': 'Kindercare Learning Center',
                     'numReviews': 1,
                     'parentRating': 1,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2715 South White Road',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 215,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 38},
                                       {'label': 'Hispanic', 'percentage': 28},
                                       {'label': 'Two or more races', 'percentage': 13},
                                       {'label': 'Pacific Islander', 'percentage': 9},
                                       {'label': 'White', 'percentage': 8},
                                       {'label': 'African American', 'percentage': 4}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 9667,
                     'lat': 37.327091,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/9667-East-Valley-Christian-School/#College_success',
                         'profile': '/california/san-jose/9667-East-Valley-Christian-School/',
                         'reviews': '/california/san-jose/9667-East-Valley-Christian-School/#Reviews'},
                     'lon': -121.798843,
                     'name': 'East Valley Christian School',
                     'numReviews': 10,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2350 Leigh Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 11,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 55},
                                       {'label': 'Asian', 'percentage': 36},
                                       {'label': 'Hispanic', 'percentage': 9}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 9699,
                     'lat': 37.279701,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/9699-Grace-Christian-School/#College_success',
                               'profile': '/california/san-jose/9699-Grace-Christian-School/',
                               'reviews': '/california/san-jose/9699-Grace-Christian-School/#Reviews'},
                     'lon': -121.923103,
                     'name': 'Grace Christian School',
                     'numReviews': 15,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2151 Oakland Road',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 7,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '4-7',
                     'highlighted': false,
                     'id': 26747,
                     'lat': 37.396233,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/26747-Lamb-O-Academy/#College_success',
                               'profile': '/california/san-jose/26747-Lamb-O-Academy/',
                               'reviews': '/california/san-jose/26747-Lamb-O-Academy/#Reviews'},
                     'lon': -121.90535,
                     'name': 'Lamb-O Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2887 McLaughlin Avenue',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 95,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 48},
                                       {'label': 'Hispanic', 'percentage': 25},
                                       {'label': 'Pacific Islander', 'percentage': 12},
                                       {'label': 'White', 'percentage': 8},
                                       {'label': 'African American', 'percentage': 8}],
                     'gradeLevels': 'PK-5',
                     'highlighted': false,
                     'id': 9920,
                     'lat': 37.304714,
                     'levelCode': 'p,e',
                     'links': {'collegeSuccess': '/california/san-jose/9920-Tower-Academy/#College_success',
                               'profile': '/california/san-jose/9920-Tower-Academy/',
                               'reviews': '/california/san-jose/9920-Tower-Academy/#Reviews'},
                     'lon': -121.826851,
                     'name': 'Tower Academy',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3001 Ross Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 37,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 60},
                                       {'label': 'Hispanic', 'percentage': 20},
                                       {'label': 'White', 'percentage': 20}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 12934,
                     'lat': 37.266651,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/12934-Over-The-Rainbow-Montessori-School/#College_success',
                         'profile': '/california/san-jose/12934-Over-The-Rainbow-Montessori-School/',
                         'reviews': '/california/san-jose/12934-Over-The-Rainbow-Montessori-School/#Reviews'},
                     'lon': -121.920532,
                     'name': 'Over The Rainbow Montessori School',
                     'numReviews': 5,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4850 Pearl Avenue',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 328,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 33},
                                       {'label': 'White', 'percentage': 33},
                                       {'label': 'Hispanic', 'percentage': 19},
                                       {'label': 'Two or more races', 'percentage': 13},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 10008,
                     'lat': 37.2621,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/10008-Holy-Family-School/#College_success',
                               'profile': '/california/san-jose/10008-Holy-Family-School/',
                               'reviews': '/california/san-jose/10008-Holy-Family-School/#Reviews'},
                     'lon': -121.863373,
                     'name': 'Holy Family School',
                     'numReviews': 35,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '480 South White Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 10,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 95},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 5}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 10101,
                     'lat': 37.36182,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/10101-White-Road-Baptist-Academy/#College_success',
                         'profile': '/california/san-jose/10101-White-Road-Baptist-Academy/',
                         'reviews': '/california/san-jose/10101-White-Road-Baptist-Academy/#Reviews'},
                     'lon': -121.823761,
                     'name': 'White Road Baptist Academy',
                     'numReviews': 1,
                     'parentRating': 1,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3703 Silver Creek Road',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 92,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 70},
                                       {'label': 'African American', 'percentage': 19},
                                       {'label': 'Hispanic', 'percentage': 11}],
                     'gradeLevels': 'K-4',
                     'highlighted': false,
                     'id': 10273,
                     'lat': 37.302002,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/10273-Scholars-Academy/#College_success',
                               'profile': '/california/san-jose/10273-Scholars-Academy/',
                               'reviews': '/california/san-jose/10273-Scholars-Academy/#Reviews'},
                     'lon': -121.806625,
                     'name': 'Scholars Academy',
                     'numReviews': 33,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '750 North Capitol Avenue',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 138,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 40},
                                       {'label': 'White', 'percentage': 16},
                                       {'label': 'Two or more races', 'percentage': 14},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 12},
                                       {'label': 'Hispanic', 'percentage': 12},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Pacific Islander', 'percentage': 2}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 10329,
                     'lat': 37.378689,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/10329-Merryhill-Elementary-School/#College_success',
                         'profile': '/california/san-jose/10329-Merryhill-Elementary-School/',
                         'reviews': '/california/san-jose/10329-Merryhill-Elementary-School/#Reviews'},
                     'lon': -121.851288,
                     'name': 'Merryhill Elementary School',
                     'numReviews': 25,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5828 Santa Teresa Boulevard',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 209,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 40},
                                       {'label': 'African American', 'percentage': 18},
                                       {'label': 'Asian', 'percentage': 17},
                                       {'label': 'Two or more races', 'percentage': 13},
                                       {'label': 'Hispanic', 'percentage': 12}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 10644,
                     'lat': 37.237385,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/10644-Apostles-Lutheran/#College_success',
                               'profile': '/california/san-jose/10644-Apostles-Lutheran/',
                               'reviews': '/california/san-jose/10644-Apostles-Lutheran/#Reviews'},
                     'lon': -121.840393,
                     'name': 'Apostles Lutheran',
                     'numReviews': 23,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1198 Redmond Avenue',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 556,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 56},
                                       {'label': 'Hispanic', 'percentage': 22},
                                       {'label': 'Asian', 'percentage': 13},
                                       {'label': 'Two or more races', 'percentage': 8},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 12745,
                     'lat': 37.228371,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/12745-Holy-Spirit/#College_success',
                               'profile': '/california/san-jose/12745-Holy-Spirit/',
                               'reviews': '/california/san-jose/12745-Holy-Spirit/#Reviews'},
                     'lon': -121.872963,
                     'name': 'Holy Spirit',
                     'numReviews': 79,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1570 Branham Lane',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '85%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 630,
                     'districtName': 'Campbell Union High School District',
                     'enrollment': 1802,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 17,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 17, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 28,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 47, 'rating': 9},
                                       {'label': 'Filipino',
                                        'percentage': 3,
                                        'rating': 8},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 11149,
                     'lat': 37.254578,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/11149-Branham-High-School/#College_success',
                               'profile': '/california/san-jose/11149-Branham-High-School/',
                               'reviews': '/california/san-jose/11149-Branham-High-School/#Reviews'},
                     'lon': -121.899567,
                     'name': 'Branham High School',
                     'numReviews': 44,
                     'parentRating': 4,
                     'percentLowIncome': '17%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'College Readiness Rating': 10,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '665 Wool Creek Drive',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 759,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 68,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 35, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 49,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 11151,
                     'lat': 37.316086,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/11151-Shirakawa-George-Sr.-Elementary-School/#College_success',
                         'profile': '/california/san-jose/11151-Shirakawa-George-Sr.-Elementary-School/',
                         'reviews': '/california/san-jose/11151-Shirakawa-George-Sr.-Elementary-School/#Reviews'},
                     'lon': -121.849739,
                     'name': 'Shirakawa (George, Sr.) Elementary School',
                     'numReviews': 52,
                     'parentRating': 4,
                     'percentLowIncome': '68%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1001 Schoolhouse Road',
                                 'street2': '',
                                 'zip': '95138'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 494,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 26,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 33, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 27,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 17, 'rating': 9},
                                       {'label': 'Two or more races', 'percentage': 10},
                                       {'label': 'Filipino', 'percentage': 9},
                                       {'label': 'African American', 'percentage': 4}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 11155,
                     'lat': 37.242641,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/11155-Ledesma-Rita-Elementary-School/#College_success',
                         'profile': '/california/san-jose/11155-Ledesma-Rita-Elementary-School/',
                         'reviews': '/california/san-jose/11155-Ledesma-Rita-Elementary-School/#Reviews'},
                     'lon': -121.762672,
                     'name': 'Ledesma (Rita) Elementary School',
                     'numReviews': 39,
                     'parentRating': 4,
                     'percentLowIncome': '26%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4849 Pearl Avenue',
                                 'street2': 'Suite 2',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '25%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 655,
                     'districtName': 'San Jose Unified School District',
                     'enrollment': 334,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 48,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 72,
                                        'rating': 2},
                                       {'label': 'White', 'percentage': 17, 'rating': 1},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'Asian', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': '6-12',
                     'highlighted': false,
                     'id': 11156,
                     'lat': 37.261578,
                     'levelCode': 'm,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/11156-Liberty-High-Alternative-School/#College_success',
                         'profile': '/california/san-jose/11156-Liberty-High-Alternative-School/',
                         'reviews': '/california/san-jose/11156-Liberty-High-Alternative-School/#Reviews'},
                     'lon': -121.865372,
                     'name': 'Liberty High (Alternative) School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '48%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'College Readiness Rating': 1,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1610 Bird Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 655,
                     'districtName': 'San Jose Unified School District',
                     'enrollment': 326,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 24,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 36,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 29, 'rating': 9},
                                       {'label': 'Two or more races', 'percentage': 11},
                                       {'label': 'Asian', 'percentage': 11},
                                       {'label': 'African American', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 6}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 11157,
                     'lat': 37.303524,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/11157-Hammer-Montessori-At-Galarza-Elementary-School/#College_success',
                         'profile': '/california/san-jose/11157-Hammer-Montessori-At-Galarza-Elementary-School/',
                         'reviews': '/california/san-jose/11157-Hammer-Montessori-At-Galarza-Elementary-School/#Reviews'},
                     'lon': -121.886864,
                     'name': 'Hammer Montessori At Galarza Elementary School',
                     'numReviews': 40,
                     'parentRating': 4,
                     'percentLowIncome': '24%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 30,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '495 Massar Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 48,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 59},
                                       {'label': 'White', 'percentage': 18},
                                       {'label': 'African American', 'percentage': 12},
                                       {'label': 'Asian', 'percentage': 12}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 11311,
                     'lat': 37.354546,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/11311-Montessori-Academy/#College_success',
                               'profile': '/california/san-jose/11311-Montessori-Academy/',
                               'reviews': '/california/san-jose/11311-Montessori-Academy/#Reviews'},
                     'lon': -121.83419,
                     'name': 'Montessori Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1730 Curtner Ave',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-12',
                     'highlighted': false,
                     'id': 11338,
                     'lat': 37.280495,
                     'levelCode': 'p,e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/11338-Willow-Vale-Christian-Children/#College_success',
                         'profile': '/california/san-jose/11338-Willow-Vale-Christian-Children/',
                         'reviews': '/california/san-jose/11338-Willow-Vale-Christian-Children/#Reviews'},
                     'lon': -121.910965,
                     'name': 'Willow Vale Christian Children',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1480 Luning Dr',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '2, 4-5, 7, 9-11',
                     'highlighted': false,
                     'id': 11468,
                     'lat': 37.253662,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/11468-Our-Shepherds-Academy/#College_success',
                               'profile': '/california/san-jose/11468-Our-Shepherds-Academy/',
                               'reviews': '/california/san-jose/11468-Our-Shepherds-Academy/#Reviews'},
                     'lon': -121.895126,
                     'name': "Our Shepherd's Academy",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '19950 McKean Road',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 209,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 13076,
                     'lat': 37.200783,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/13076-Challenger-School---Almaden/#College_success',
                         'profile': '/california/san-jose/13076-Challenger-School---Almaden/',
                         'reviews': '/california/san-jose/13076-Challenger-School---Almaden/#Reviews'},
                     'lon': -121.825706,
                     'name': 'Challenger School - Almaden',
                     'numReviews': 40,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive, Mc273',
                                 'street2': 'MC271',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 1190,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 41,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 1},
                                       {'label': 'Hispanic', 'percentage': 43},
                                       {'label': 'Asian', 'percentage': 29},
                                       {'label': 'White', 'percentage': 17},
                                       {'label': 'Filipino', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'PK-12',
                     'highlighted': false,
                     'id': 12420,
                     'lat': 37.383717,
                     'levelCode': 'p,e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/12420-Santa-Clara-County-Special-Education-School/#College_success',
                         'profile': '/california/san-jose/12420-Santa-Clara-County-Special-Education-School/',
                         'reviews': '/california/san-jose/12420-Santa-Clara-County-Special-Education-School/#Reviews'},
                     'lon': -121.900261,
                     'name': 'Santa Clara County Special Education School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '41%',
                     'pinned': null,
                     'rating': 1,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 7,
                     'subratings': {'College Readiness Rating': 1,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 1},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2220 Woodbury Lane',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 642,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 11,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 73, 'rating': 10},
                                       {'label': 'White', 'percentage': 10, 'rating': 10},
                                       {'label': 'Hispanic', 'percentage': 6},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 11864,
                     'lat': 37.297367,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/11864-James-Franklin-Smith-Elementary-School/#College_success',
                         'profile': '/california/san-jose/11864-James-Franklin-Smith-Elementary-School/',
                         'reviews': '/california/san-jose/11864-James-Franklin-Smith-Elementary-School/#Reviews'},
                     'lon': -121.787987,
                     'name': 'James Franklin Smith Elementary School',
                     'numReviews': 16,
                     'parentRating': 4,
                     'percentLowIncome': '11%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 9,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1402 Monterey Highway',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '81%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 655,
                     'districtName': 'San Jose Unified School District',
                     'enrollment': 488,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 83,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 91,
                                        'rating': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 3},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Asian', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 11869,
                     'lat': 37.31633,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/11869-Downtown-College-Preparatory-School/#College_success',
                         'profile': '/california/san-jose/11869-Downtown-College-Preparatory-School/',
                         'reviews': '/california/san-jose/11869-Downtown-College-Preparatory-School/#Reviews'},
                     'lon': -121.872879,
                     'name': 'Downtown College Preparatory School',
                     'numReviews': 10,
                     'parentRating': 3,
                     'percentLowIncome': '83%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'College Readiness Rating': 1,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2101 Moorpark Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '76%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 655,
                     'districtName': 'San Jose Unified School District',
                     'enrollment': 43,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 49},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Hispanic', 'percentage': 44},
                                       {'label': 'White', 'percentage': 26},
                                       {'label': 'Asian', 'percentage': 14},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 2}],
                     'gradeLevels': '11-12',
                     'highlighted': false,
                     'id': 11870,
                     'lat': 37.316364,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/11870-Middle-College-High-School/#College_success',
                         'profile': '/california/san-jose/11870-Middle-College-High-School/',
                         'reviews': '/california/san-jose/11870-Middle-College-High-School/#Reviews'},
                     'lon': -121.929848,
                     'name': 'Middle College High School',
                     'numReviews': 1,
                     'parentRating': 4,
                     'percentLowIncome': '49%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 26,
                     'subratings': {'College Readiness Rating': 7, 'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '880 Wren Drive',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 655,
                     'districtName': 'San Jose Unified School District',
                     'enrollment': 261,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 75,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 69,
                                        'rating': 4},
                                       {'label': 'Asian', 'percentage': 11},
                                       {'label': 'African American', 'percentage': 7},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 3}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 11871,
                     'lat': 37.284721,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/11871-Canoas-Elementary-School/#College_success',
                               'profile': '/california/san-jose/11871-Canoas-Elementary-School/',
                               'reviews': '/california/san-jose/11871-Canoas-Elementary-School/#Reviews'},
                     'lon': -121.876541,
                     'name': 'Canoas Elementary School',
                     'numReviews': 15,
                     'parentRating': 2,
                     'percentLowIncome': '75%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1376 Piedmont Road',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 1,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Filipino', 'percentage': 100}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 12065,
                     'lat': 37.403828,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/12065-Berryessa-Union-Elementary-School/#College_success',
                         'profile': '/california/san-jose/12065-Berryessa-Union-Elementary-School/',
                         'reviews': '/california/san-jose/12065-Berryessa-Union-Elementary-School/#Reviews'},
                     'lon': -121.848862,
                     'name': 'Berryessa Union Elementary School',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '0%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '14271 Story Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '70%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 410,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 81,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 97,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 12068,
                     'lat': 37.359051,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/12068-Latino-College-Preparatory-Academy/#College_success',
                         'profile': '/california/san-jose/12068-Latino-College-Preparatory-Academy/',
                         'reviews': '/california/san-jose/12068-Latino-College-Preparatory-Academy/#Reviews'},
                     'lon': -121.816002,
                     'name': 'Latino College Preparatory Academy',
                     'numReviews': 39,
                     'parentRating': 3,
                     'percentLowIncome': '81%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 18,
                     'subratings': {'College Readiness Rating': 1,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1610 Bird Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 655,
                     'districtName': 'San Jose Unified School District',
                     'enrollment': 397,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 69,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 82,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 12074,
                     'lat': 37.303524,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/12074-Ernesto-Galarza-Elementary-School/#College_success',
                         'profile': '/california/san-jose/12074-Ernesto-Galarza-Elementary-School/',
                         'reviews': '/california/san-jose/12074-Ernesto-Galarza-Elementary-School/#Reviews'},
                     'lon': -121.886864,
                     'name': 'Ernesto Galarza Elementary School',
                     'numReviews': 21,
                     'parentRating': 4,
                     'percentLowIncome': '69%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '467 North White Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 369,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 76,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 1},
                                       {'label': 'Hispanic',
                                        'percentage': 97,
                                        'rating': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 12238,
                     'lat': 37.378475,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/12238-Escuela-Popular-Accelerated-Family-Learning-School/#College_success',
                         'profile': '/california/san-jose/12238-Escuela-Popular-Accelerated-Family-Learning-School/',
                         'reviews': '/california/san-jose/12238-Escuela-Popular-Accelerated-Family-Learning-School/#Reviews'},
                     'lon': -121.838181,
                     'name': 'Escuela Popular Accelerated Family Learning School',
                     'numReviews': 23,
                     'parentRating': 4,
                     'percentLowIncome': '76%',
                     'pinned': null,
                     'rating': 1,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 18,
                     'subratings': {'Academic Progress Rating': 2,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 1},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3300 Quimby Road',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '87%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 2961,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 16,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 71, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 14,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 4, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 3,
                                        'rating': 10},
                                       {'label': 'Filipino',
                                        'percentage': 7,
                                        'rating': 8},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 12237,
                     'lat': 37.325199,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/12237-Evergreen-Valley-High-School/#College_success',
                         'profile': '/california/san-jose/12237-Evergreen-Valley-High-School/',
                         'reviews': '/california/san-jose/12237-Evergreen-Valley-High-School/#Reviews'},
                     'lon': -121.778664,
                     'name': 'Evergreen Valley High School',
                     'numReviews': 91,
                     'parentRating': 4,
                     'percentLowIncome': '16%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 26,
                     'subratings': {'College Readiness Rating': 10,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1560 Berger Drive',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '16%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 214,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 86},
                                       {'label': 'Hispanic', 'percentage': 87},
                                       {'label': 'Asian', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': '12',
                     'highlighted': false,
                     'id': 12236,
                     'lat': 37.372688,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/12236-San-Jose-Conservation-Corps-Charter-School/#College_success',
                         'profile': '/california/san-jose/12236-San-Jose-Conservation-Corps-Charter-School/',
                         'reviews': '/california/san-jose/12236-San-Jose-Conservation-Corps-Charter-School/#Reviews'},
                     'lon': -121.897072,
                     'name': 'San Jose Conservation Corps Charter School',
                     'numReviews': 1,
                     'parentRating': 3,
                     'percentLowIncome': '86%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '950 Saint Elizabeth Drive',
                                 'street2': 'Room 9 and 10',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 48,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 88},
                                       {'label': 'African American', 'percentage': 13}],
                     'gradeLevels': 'K',
                     'highlighted': false,
                     'id': 13432,
                     'lat': 37.306461,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/13432-San-Jose-Montessori-School/#College_success',
                         'profile': '/california/san-jose/13432-San-Jose-Montessori-School/',
                         'reviews': '/california/san-jose/13432-San-Jose-Montessori-School/#Reviews'},
                     'lon': -121.915901,
                     'name': 'San Jose Montessori School',
                     'numReviews': 6,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '100 Skyway Drive',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 618,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 43},
                                       {'label': 'Two or more races', 'percentage': 33},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 13},
                                       {'label': 'Hispanic', 'percentage': 7},
                                       {'label': 'African American', 'percentage': 4}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 13522,
                     'lat': 37.275749,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/13522-Valley-Christian-Junior-High-School/#College_success',
                         'profile': '/california/san-jose/13522-Valley-Christian-Junior-High-School/',
                         'reviews': '/california/san-jose/13522-Valley-Christian-Junior-High-School/#Reviews'},
                     'lon': -121.826744,
                     'name': 'Valley Christian Junior High School',
                     'numReviews': 10,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2841 Junction Avenue',
                                 'street2': '',
                                 'zip': '95134'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 35,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 60},
                                       {'label': 'Two or more races', 'percentage': 20},
                                       {'label': 'White', 'percentage': 20}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 13573,
                     'lat': 37.39592,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/13573-Headsup-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/13573-Headsup-Child-Development-Center/',
                         'reviews': '/california/san-jose/13573-Headsup-Child-Development-Center/#Reviews'},
                     'lon': -121.92556,
                     'name': 'Headsup Child Development Center',
                     'numReviews': 2,
                     'parentRating': 2,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '625 Educational Park Drive',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'Ungraded',
                     'highlighted': false,
                     'id': 13655,
                     'lat': 37.365639,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/13655-Adult-Education-Program/#College_success',
                               'profile': '/california/san-jose/13655-Adult-Education-Program/',
                               'reviews': '/california/san-jose/13655-Adult-Education-Program/#Reviews'},
                     'lon': -121.859062,
                     'name': 'Adult Education Program',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3701 Rue Mirassou Drive',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 581,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 9,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 73, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 9,
                                        'rating': 7},
                                       {'label': 'Filipino', 'percentage': 7},
                                       {'label': 'Two or more races', 'percentage': 6},
                                       {'label': 'White', 'percentage': 3}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 13990,
                     'lat': 37.321712,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/13990-Carolyn-A.-Clark-Elementary-School/#College_success',
                         'profile': '/california/san-jose/13990-Carolyn-A.-Clark-Elementary-School/',
                         'reviews': '/california/san-jose/13990-Carolyn-A.-Clark-Elementary-School/#Reviews'},
                     'lon': -121.769371,
                     'name': 'Carolyn A. Clark Elementary School',
                     'numReviews': 27,
                     'parentRating': 4,
                     'percentLowIncome': '9%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 8,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1149 East Julian Street',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 243,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 89,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 90,
                                        'rating': 4},
                                       {'label': 'Asian', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 30972,
                     'lat': 37.351173,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/30972-Sunrise-Middle/#College_success',
                               'profile': '/california/san-jose/30972-Sunrise-Middle/',
                               'reviews': '/california/san-jose/30972-Sunrise-Middle/#Reviews'},
                     'lon': -121.872841,
                     'name': 'Sunrise Middle',
                     'numReviews': 1,
                     'parentRating': 4,
                     'percentLowIncome': '89%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1351 Lightland Road',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 351,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 59,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 51, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 29,
                                        'rating': 6},
                                       {'label': 'Filipino', 'percentage': 9},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 14076,
                     'lat': 37.29512,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/14076-Ramblewood-Elementary-School/#College_success',
                         'profile': '/california/san-jose/14076-Ramblewood-Elementary-School/',
                         'reviews': '/california/san-jose/14076-Ramblewood-Elementary-School/#Reviews'},
                     'lon': -121.812019,
                     'name': 'Ramblewood Elementary School',
                     'numReviews': 15,
                     'parentRating': 4,
                     'percentLowIncome': '59%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1250 South King Road',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 415,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 84,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 19, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 76,
                                        'rating': 8},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 14113,
                     'lat': 37.337685,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/14113-KIPP-Heartwood-Academy/#College_success',
                               'profile': '/california/san-jose/14113-KIPP-Heartwood-Academy/',
                               'reviews': '/california/san-jose/14113-KIPP-Heartwood-Academy/#Reviews'},
                     'lon': -121.84095,
                     'name': 'KIPP Heartwood Academy',
                     'numReviews': 25,
                     'parentRating': 4,
                     'percentLowIncome': '84%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 28,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 9,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive, Mc213',
                                 'street2': 'MC213',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '25%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 39,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 69},
                                       {'label': 'All students', 'rating': 1},
                                       {'label': 'Hispanic',
                                        'percentage': 72,
                                        'rating': 1},
                                       {'label': 'White', 'percentage': 10},
                                       {'label': 'African American', 'percentage': 5},
                                       {'label': 'Asian', 'percentage': 5},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'Pacific Islander', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 3}],
                     'gradeLevels': '7-12',
                     'highlighted': false,
                     'id': 14162,
                     'lat': 37.383701,
                     'levelCode': 'm,h',
                     'links': {'collegeSuccess': '/california/san-jose/14162-County-Community-School/#College_success',
                               'profile': '/california/san-jose/14162-County-Community-School/',
                               'reviews': '/california/san-jose/14162-County-Community-School/#Reviews'},
                     'lon': -121.901337,
                     'name': 'County Community School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '69%',
                     'pinned': null,
                     'rating': 1,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 6,
                     'subratings': {'College Readiness Rating': 2,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 1},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1224 Del Mar Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 630,
                     'districtName': 'Campbell Union High School District',
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'Ungraded',
                     'highlighted': false,
                     'id': 14183,
                     'lat': 37.303921,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/14183-Campbell-Adult-And-Community-Education/#College_success',
                         'profile': '/california/san-jose/14183-Campbell-Adult-And-Community-Education/',
                         'reviews': '/california/san-jose/14183-Campbell-Adult-And-Community-Education/#Reviews'},
                     'lon': -121.928497,
                     'name': 'Campbell Adult And Community Education',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2999 Ridgemont Drive',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 528,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 57,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 95,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Asian', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 14207,
                     'lat': 37.34457,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/14207-Adelante-Dual-Language-Academy/#College_success',
                         'profile': '/california/san-jose/14207-Adelante-Dual-Language-Academy/',
                         'reviews': '/california/san-jose/14207-Adelante-Dual-Language-Academy/#Reviews'},
                     'lon': -121.812714,
                     'name': 'Adelante Dual Language Academy',
                     'numReviews': 11,
                     'parentRating': 4,
                     'percentLowIncome': '57%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2223 Camden Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 630,
                     'districtName': 'Campbell Union High School District',
                     'enrollment': 17,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 29},
                                       {'label': 'Hispanic', 'percentage': 59},
                                       {'label': 'White', 'percentage': 24},
                                       {'label': 'African American', 'percentage': 6},
                                       {'label': 'Two or more races', 'percentage': 6},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 6}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 14376,
                     'lat': 37.265034,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/14376-Camden-Community-Day-School/#College_success',
                         'profile': '/california/san-jose/14376-Camden-Community-Day-School/',
                         'reviews': '/california/san-jose/14376-Camden-Community-Day-School/#Reviews'},
                     'lon': -121.939346,
                     'name': 'Camden Community Day School',
                     'numReviews': 1,
                     'parentRating': 1,
                     'percentLowIncome': '29%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 7,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1711 East San Antonio St',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 283,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 80,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 92,
                                        'rating': 5},
                                       {'label': 'Asian', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 14481,
                     'lat': 37.349968,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/14481-Learning-In-An-Urban-Community-With-High-Achievement-L.U.C.H.A./#College_success',
                         'profile': '/california/san-jose/14481-Learning-In-An-Urban-Community-With-High-Achievement-L.U.C.H.A./',
                         'reviews': '/california/san-jose/14481-Learning-In-An-Urban-Community-With-High-Achievement-L.U.C.H.A./#Reviews'},
                     'lon': -121.852463,
                     'name': 'Learning In An Urban Community With High Achievement (L.U.C.H.A.)',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '80%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1720 Hopkins Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 308,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 81,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 17, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 76,
                                        'rating': 5},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 14486,
                     'lat': 37.336681,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/14486-Renaissance-Academy/#College_success',
                               'profile': '/california/san-jose/14486-Renaissance-Academy/',
                               'reviews': '/california/san-jose/14486-Renaissance-Academy/#Reviews'},
                     'lon': -121.830833,
                     'name': 'Renaissance Academy',
                     'numReviews': 29,
                     'parentRating': 5,
                     'percentLowIncome': '81%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4955 Edenview Drive',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 6,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 67},
                                       {'label': 'Hispanic', 'percentage': 67},
                                       {'label': 'Asian', 'percentage': 17},
                                       {'label': 'White', 'percentage': 17}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 14534,
                     'lat': 37.267685,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/14534-The-Academy/#College_success',
                               'profile': '/california/san-jose/14534-The-Academy/',
                               'reviews': '/california/san-jose/14534-The-Academy/#Reviews'},
                     'lon': -121.811661,
                     'name': 'The Academy',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '67%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 4,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '149 North White Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '21%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 643,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 59,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 1},
                                       {'label': 'Hispanic',
                                        'percentage': 99,
                                        'rating': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 14688,
                     'lat': 37.36998,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/14688-Escuela-Popular-Center-For-Training-And-Careers-Family-Learning/#College_success',
                         'profile': '/california/san-jose/14688-Escuela-Popular-Center-For-Training-And-Careers-Family-Learning/',
                         'reviews': '/california/san-jose/14688-Escuela-Popular-Center-For-Training-And-Careers-Family-Learning/#Reviews'},
                     'lon': -121.83165,
                     'name': 'Escuela Popular/Center For Training And Careers, Family Learning',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '59%',
                     'pinned': null,
                     'rating': 1,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Equity Overview Rating': 1, 'Test Scores Rating': 1},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1901 Cunningham Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'Ungraded',
                     'highlighted': false,
                     'id': 14693,
                     'lat': 37.331844,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/14693-Overfelt-Adult/#College_success',
                               'profile': '/california/san-jose/14693-Overfelt-Adult/',
                               'reviews': '/california/san-jose/14693-Overfelt-Adult/#Reviews'},
                     'lon': -121.827263,
                     'name': 'Overfelt Adult',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '760 Hillsdale Avenue',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 655,
                     'districtName': 'San Jose Unified School District',
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'Ungraded',
                     'highlighted': false,
                     'id': 14839,
                     'lat': 37.277885,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/14839-Silicon-Valley-Adult-Education-Program/#College_success',
                         'profile': '/california/san-jose/14839-Silicon-Valley-Adult-Education-Program/',
                         'reviews': '/california/san-jose/14839-Silicon-Valley-Adult-Education-Program/#Reviews'},
                     'lon': -121.865959,
                     'name': 'Silicon Valley Adult Education Program',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': null,
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '19%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 291,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 81,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 1},
                                       {'label': 'Hispanic',
                                        'percentage': 87,
                                        'rating': 1},
                                       {'label': 'African American', 'percentage': 5},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'Asian', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 32438,
                     'lat': 37.383907,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/32438-Opportunity-Youth-Academy/#College_success',
                         'profile': '/california/san-jose/32438-Opportunity-Youth-Academy/',
                         'reviews': '/california/san-jose/32438-Opportunity-Youth-Academy/#Reviews'},
                     'lon': -121.90023,
                     'name': 'Opportunity Youth Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '81%',
                     'pinned': null,
                     'rating': 1,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Equity Overview Rating': 1, 'Test Scores Rating': 1},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5000 Mitty Way',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 1710,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 14929,
                     'lat': 37.313763,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/14929-Archbishop-Mitty-High-School/#College_success',
                         'profile': '/california/san-jose/14929-Archbishop-Mitty-High-School/',
                         'reviews': '/california/san-jose/14929-Archbishop-Mitty-High-School/#Reviews'},
                     'lon': -121.991409,
                     'name': 'Archbishop Mitty High School',
                     'numReviews': 69,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '760 Hillsdale Avenue',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1265,
                     'districtName': 'Metro Education School District',
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '11-12',
                     'highlighted': false,
                     'id': 15009,
                     'lat': 37.279255,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15009-Metro-Education-District-School/#College_success',
                         'profile': '/california/san-jose/15009-Metro-Education-District-School/',
                         'reviews': '/california/san-jose/15009-Metro-Education-District-School/#Reviews'},
                     'lon': -121.866547,
                     'name': 'Metro Education District School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 60,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1212 McGinness Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 51,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 35},
                                       {'label': 'Hispanic', 'percentage': 31},
                                       {'label': 'Asian', 'percentage': 20},
                                       {'label': 'Pacific Islander', 'percentage': 8},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 2}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 15116,
                     'lat': 37.351105,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/15116-Achievekids-School/#College_success',
                               'profile': '/california/san-jose/15116-Achievekids-School/',
                               'reviews': '/california/san-jose/15116-Achievekids-School/#Reviews'},
                     'lon': -121.822701,
                     'name': 'Achievekids School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '730 Camina Escuela',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 504,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 15171,
                     'lat': 37.314114,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15171-Challenger---Strawberry-Park/#College_success',
                         'profile': '/california/san-jose/15171-Challenger---Strawberry-Park/',
                         'reviews': '/california/san-jose/15171-Challenger---Strawberry-Park/#Reviews'},
                     'lon': -121.983932,
                     'name': 'Challenger - Strawberry Park',
                     'numReviews': 45,
                     'parentRating': 2,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3520 San Felipe Road',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 15288,
                     'lat': 37.311749,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15288-Pioneer-Montessori-School/#College_success',
                         'profile': '/california/san-jose/15288-Pioneer-Montessori-School/',
                         'reviews': '/california/san-jose/15288-Pioneer-Montessori-School/#Reviews'},
                     'lon': -121.785599,
                     'name': 'Pioneer Montessori School',
                     'numReviews': 9,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4645 Albany Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 96,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 88},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 13}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 15315,
                     'lat': 37.321621,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/15315-Starbright-School/#College_success',
                               'profile': '/california/san-jose/15315-Starbright-School/',
                               'reviews': '/california/san-jose/15315-Starbright-School/#Reviews'},
                     'lon': -121.984566,
                     'name': 'Starbright School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '455 Silicon Valley Blvd',
                                 'street2': '',
                                 'zip': '95138'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '8-12',
                     'highlighted': false,
                     'id': 15316,
                     'lat': 37.248882,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/15316-Starlight-High-School/#College_success',
                               'profile': '/california/san-jose/15316-Starlight-High-School/',
                               'reviews': '/california/san-jose/15316-Starlight-High-School/#Reviews'},
                     'lon': -121.772011,
                     'name': 'Starlight High School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2555 Moorpark Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 15358,
                     'lat': 37.316429,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15358-Ballard-Montessori-School/#College_success',
                         'profile': '/california/san-jose/15358-Ballard-Montessori-School/',
                         'reviews': '/california/san-jose/15358-Ballard-Montessori-School/#Reviews'},
                     'lon': -121.938484,
                     'name': 'Ballard Montessori School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4300 Bucknall Rd',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 15363,
                     'lat': 37.2836,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15363-The-Harker-School-Lower-Division/#College_success',
                         'profile': '/california/san-jose/15363-The-Harker-School-Lower-Division/',
                         'reviews': '/california/san-jose/15363-The-Harker-School-Lower-Division/#Reviews'},
                     'lon': -121.977898,
                     'name': 'The Harker School Lower Division',
                     'numReviews': 24,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '310 Edwards Avenue',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 63,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 100}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 15368,
                     'lat': 37.318558,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15368-Sacred-Heart-Nativity-School/#College_success',
                         'profile': '/california/san-jose/15368-Sacred-Heart-Nativity-School/',
                         'reviews': '/california/san-jose/15368-Sacred-Heart-Nativity-School/#Reviews'},
                     'lon': -121.884987,
                     'name': 'Sacred Heart Nativity School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6120 Liska Lane',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 26,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 50},
                                       {'label': 'White', 'percentage': 33},
                                       {'label': 'Two or more races', 'percentage': 17}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 15388,
                     'lat': 37.240086,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15388-Bright-Horizons-At-San-Jose/#College_success',
                         'profile': '/california/san-jose/15388-Bright-Horizons-At-San-Jose/',
                         'reviews': '/california/san-jose/15388-Bright-Horizons-At-San-Jose/#Reviews'},
                     'lon': -121.797607,
                     'name': 'Bright Horizons At San Jose',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5670 Camden Ave',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 141,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 82},
                                       {'label': 'White', 'percentage': 14},
                                       {'label': 'African American', 'percentage': 4}],
                     'gradeLevels': 'PK-11',
                     'highlighted': false,
                     'id': 26701,
                     'lat': 37.235077,
                     'levelCode': 'p,e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/26701-Champion-School/#College_success',
                               'profile': '/california/san-jose/26701-Champion-School/',
                               'reviews': '/california/san-jose/26701-Champion-School/#Reviews'},
                     'lon': -121.90197,
                     'name': 'Champion School',
                     'numReviews': 7,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1425 Old Piedmont Road',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 18,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 50},
                                       {'label': 'White', 'percentage': 17},
                                       {'label': 'African American', 'percentage': 17},
                                       {'label': 'Hispanic', 'percentage': 17}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 15541,
                     'lat': 37.406609,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15541-Piedmont-Hills-Montessori-Academy/#College_success',
                         'profile': '/california/san-jose/15541-Piedmont-Hills-Montessori-Academy/',
                         'reviews': '/california/san-jose/15541-Piedmont-Hills-Montessori-Academy/#Reviews'},
                     'lon': -121.847939,
                     'name': 'Piedmont Hills Montessori Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': 'Po Box 18248',
                                 'street2': '',
                                 'zip': '95158'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 15556,
                     'lat': 37.259998,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/15556-Clement-Pre-School/#College_success',
                               'profile': '/california/san-jose/15556-Clement-Pre-School/',
                               'reviews': '/california/san-jose/15556-Clement-Pre-School/#Reviews'},
                     'lon': -121.879997,
                     'name': 'Clement Pre-School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '7245 Sharon Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 15558,
                     'lat': 37.297447,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/15558-Future-Assets-Cdc/#College_success',
                               'profile': '/california/san-jose/15558-Future-Assets-Cdc/',
                               'reviews': '/california/san-jose/15558-Future-Assets-Cdc/#Reviews'},
                     'lon': -122.031242,
                     'name': 'Future Assets Cdc',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1175 Hillsdale Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 137,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 66},
                                       {'label': 'Asian', 'percentage': 17},
                                       {'label': 'Hispanic', 'percentage': 10},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 15571,
                     'lat': 37.275219,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/15571-Calvary-Christian-Academy/#College_success',
                         'profile': '/california/san-jose/15571-Calvary-Christian-Academy/',
                         'reviews': '/california/san-jose/15571-Calvary-Christian-Academy/#Reviews'},
                     'lon': -121.881035,
                     'name': 'Calvary Christian Academy',
                     'numReviews': 10,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '7269 Santa Teresa Blvd',
                                 'street2': '',
                                 'zip': '95139'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 24989,
                     'lat': 37.22374,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/24989-St.-Stephens-In-The-Field-Parish-Pre-School/#College_success',
                         'profile': '/california/san-jose/24989-St.-Stephens-In-The-Field-Parish-Pre-School/',
                         'reviews': '/california/san-jose/24989-St.-Stephens-In-The-Field-Parish-Pre-School/#Reviews'},
                     'lon': -121.769157,
                     'name': "St. Stephen's in-the-Field Parish Pre-School",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1040 the Alameda',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '1-12',
                     'highlighted': false,
                     'id': 16656,
                     'lat': 37.331123,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/16656-Alternative-Private-Schooling/#College_success',
                         'profile': '/california/san-jose/16656-Alternative-Private-Schooling/',
                         'reviews': '/california/san-jose/16656-Alternative-Private-Schooling/#Reviews'},
                     'lon': -121.9104,
                     'name': 'Alternative Private Schooling',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1980 Hamilton Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 164,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 16659,
                     'lat': 37.293346,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/16659-Carden-Day-School-Of-San-Jose/#College_success',
                         'profile': '/california/san-jose/16659-Carden-Day-School-Of-San-Jose/',
                         'reviews': '/california/san-jose/16659-Carden-Day-School-Of-San-Jose/#Reviews'},
                     'lon': -121.921684,
                     'name': 'Carden Day School Of San Jose',
                     'numReviews': 43,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '500 Shawnee Lane',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 347,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 16660,
                     'lat': 37.240948,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/16660-Challenger-School---Shawnee/#College_success',
                         'profile': '/california/san-jose/16660-Challenger-School---Shawnee/',
                         'reviews': '/california/san-jose/16660-Challenger-School---Shawnee/#Reviews'},
                     'lon': -121.833061,
                     'name': 'Challenger School - Shawnee',
                     'numReviews': 35,
                     'parentRating': 2,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '711 E Gish Rd',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 764,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 16661,
                     'lat': 37.371078,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/16661-Challenger---Berryessa/#College_success',
                               'profile': '/california/san-jose/16661-Challenger---Berryessa/',
                               'reviews': '/california/san-jose/16661-Challenger---Berryessa/#Reviews'},
                     'lon': -121.895432,
                     'name': 'Challenger - Berryessa',
                     'numReviews': 43,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4949 Harwood Rd',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 198,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-4',
                     'highlighted': false,
                     'id': 16662,
                     'lat': 37.24799,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/16662-Challenger---Harwood/#College_success',
                               'profile': '/california/san-jose/16662-Challenger---Harwood/',
                               'reviews': '/california/san-jose/16662-Challenger---Harwood/#Reviews'},
                     'lon': -121.913132,
                     'name': 'Challenger - Harwood',
                     'numReviews': 48,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2530 Berryessa Road',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 11,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 100}],
                     'gradeLevels': '1-12',
                     'highlighted': false,
                     'id': 16663,
                     'lat': 37.387333,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/16663-Christ-The-King-Academy/#College_success',
                               'profile': '/california/san-jose/16663-Christ-The-King-Academy/',
                               'reviews': '/california/san-jose/16663-Christ-The-King-Academy/#Reviews'},
                     'lon': -121.859474,
                     'name': 'Christ The King Academy',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3206 Irlanda Way',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '4-9',
                     'highlighted': false,
                     'id': 16666,
                     'lat': 37.266899,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/16666-Glory-Of-Learning/#College_success',
                               'profile': '/california/san-jose/16666-Glory-Of-Learning/',
                               'reviews': '/california/san-jose/16666-Glory-Of-Learning/#Reviews'},
                     'lon': -121.910202,
                     'name': 'Glory of Learning',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '650 Davenport Drive',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 21,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 16669,
                     'lat': 37.367165,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/16669-Heart-Academy/#College_success',
                               'profile': '/california/san-jose/16669-Heart-Academy/',
                               'reviews': '/california/san-jose/16669-Heart-Academy/#Reviews'},
                     'lon': -121.810196,
                     'name': 'Heart Academy',
                     'numReviews': 1,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1200 Blossom Hill Road',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 5,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K',
                     'highlighted': false,
                     'id': 16672,
                     'lat': 37.248993,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/16672-The-Learning-Company--Academy/#College_success',
                         'profile': '/california/san-jose/16672-The-Learning-Company--Academy/',
                         'reviews': '/california/san-jose/16672-The-Learning-Company--Academy/#Reviews'},
                     'lon': -121.879562,
                     'name': 'The Learning Company & Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2315 Canoas Garden Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-1',
                     'highlighted': false,
                     'id': 16677,
                     'lat': 37.292088,
                     'levelCode': 'p,e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/16677-My-School-At-Cathedral-Of-Faith/#College_success',
                         'profile': '/california/san-jose/16677-My-School-At-Cathedral-Of-Faith/',
                         'reviews': '/california/san-jose/16677-My-School-At-Cathedral-Of-Faith/#Reviews'},
                     'lon': -121.874718,
                     'name': 'My School at Cathedral of Faith',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1325 Bouret Drive',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 70,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 51},
                                       {'label': 'Hispanic', 'percentage': 31},
                                       {'label': 'African American', 'percentage': 11},
                                       {'label': 'Two or more races', 'percentage': 6}],
                     'gradeLevels': '1-12',
                     'highlighted': false,
                     'id': 16681,
                     'lat': 37.256771,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/16681-Pine-Hill-School-Second-Start-Learning-D/#College_success',
                         'profile': '/california/san-jose/16681-Pine-Hill-School-Second-Start-Learning-D/',
                         'reviews': '/california/san-jose/16681-Pine-Hill-School-Second-Start-Learning-D/#Reviews'},
                     'lon': -121.887337,
                     'name': 'Pine Hill School Second Start Learning D',
                     'numReviews': 13,
                     'parentRating': 2,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '342 Shadow Run Drive',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 16686,
                     'lat': 37.311069,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/16686-Private-Educational-Network-School/#College_success',
                         'profile': '/california/san-jose/16686-Private-Educational-Network-School/',
                         'reviews': '/california/san-jose/16686-Private-Educational-Network-School/#Reviews'},
                     'lon': -121.880257,
                     'name': 'Private Educational Network School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1700 Cleveland Avenue',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '3-12',
                     'highlighted': false,
                     'id': 16687,
                     'lat': 37.329102,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/16687-Rose-Garden-Academy/#College_success',
                               'profile': '/california/san-jose/16687-Rose-Garden-Academy/',
                               'reviews': '/california/san-jose/16687-Rose-Garden-Academy/#Reviews'},
                     'lon': -121.927299,
                     'name': 'Rose Garden Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1565 South White Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 200,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 88},
                                       {'label': 'Hispanic', 'percentage': 7},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 5},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 16694,
                     'lat': 37.3484,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/16694-St.-Thomas-More/#College_success',
                               'profile': '/california/san-jose/16694-St.-Thomas-More/',
                               'reviews': '/california/san-jose/16694-St.-Thomas-More/#Reviews'},
                     'lon': -121.812103,
                     'name': 'St. Thomas More',
                     'numReviews': 5,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6670 San Anselmo Way',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 301,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 16695,
                     'lat': 37.230061,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/16695-Stratford-School/#College_success',
                               'profile': '/california/san-jose/16695-Stratford-School/',
                               'reviews': '/california/san-jose/16695-Stratford-School/#Reviews'},
                     'lon': -121.789284,
                     'name': 'Stratford School',
                     'numReviews': 17,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1718 Andover Lane',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 164,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 72},
                                       {'label': 'White', 'percentage': 25},
                                       {'label': 'Hispanic', 'percentage': 3}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 16699,
                     'lat': 37.25552,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/16699-Stratford-Middle-School/#College_success',
                               'profile': '/california/san-jose/16699-Stratford-Middle-School/',
                               'reviews': '/california/san-jose/16699-Stratford-Middle-School/#Reviews'},
                     'lon': -121.910271,
                     'name': 'Stratford Middle School',
                     'numReviews': 26,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1333 Lefont Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 27,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '1-12',
                     'highlighted': false,
                     'id': 16700,
                     'lat': 37.384239,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/16700-Trinity-Christian-School/#College_success',
                               'profile': '/california/san-jose/16700-Trinity-Christian-School/',
                               'reviews': '/california/san-jose/16700-Trinity-Christian-School/#Reviews'},
                     'lon': -121.889778,
                     'name': 'Trinity Christian School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1887 Blossom Hill Road',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 16701,
                     'lat': 37.236198,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/16701-Two-Hearts-Academy/#College_success',
                               'profile': '/california/san-jose/16701-Two-Hearts-Academy/',
                               'reviews': '/california/san-jose/16701-Two-Hearts-Academy/#Reviews'},
                     'lon': -121.924301,
                     'name': 'Two Hearts Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '801 Hibiscus Lane',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 149,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 54},
                                       {'label': 'Two or more races', 'percentage': 13},
                                       {'label': 'Asian', 'percentage': 11},
                                       {'label': 'Hispanic', 'percentage': 9},
                                       {'label': 'Pacific Islander', 'percentage': 7},
                                       {'label': 'African American', 'percentage': 6}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 16703,
                     'lat': 37.3125,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/16703-West-Valley-Middle-School/#College_success',
                         'profile': '/california/san-jose/16703-West-Valley-Middle-School/',
                         'reviews': '/california/san-jose/16703-West-Valley-Middle-School/#Reviews'},
                     'lon': -121.972099,
                     'name': 'West Valley Middle School',
                     'numReviews': 2,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '762 Sunset Glen Drive',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 52,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 37},
                                       {'label': 'Hispanic', 'percentage': 23},
                                       {'label': 'African American', 'percentage': 15},
                                       {'label': 'Two or more races', 'percentage': 13},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 10},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 2}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 17749,
                     'lat': 37.236156,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/17749-Spectrum-Center-Inc---San-Jose/#College_success',
                         'profile': '/california/san-jose/17749-Spectrum-Center-Inc---San-Jose/',
                         'reviews': '/california/san-jose/17749-Spectrum-Center-Inc---San-Jose/#Reviews'},
                     'lon': -121.848427,
                     'name': 'Spectrum Center Inc - San Jose',
                     'numReviews': 8,
                     'parentRating': 1,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4021 Teale Avenue',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 566,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 7},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 33, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 15,
                                        'rating': 8},
                                       {'label': 'White', 'percentage': 35, 'rating': 9},
                                       {'label': 'Two or more races', 'percentage': 9},
                                       {'label': 'African American', 'percentage': 5},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 17001,
                     'lat': 37.302746,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/17001-Discovery-Charter-School/#College_success',
                               'profile': '/california/san-jose/17001-Discovery-Charter-School/',
                               'reviews': '/california/san-jose/17001-Discovery-Charter-School/#Reviews'},
                     'lon': -121.97364,
                     'name': 'Discovery Charter School',
                     'numReviews': 72,
                     'parentRating': 4,
                     'percentLowIncome': '7%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 8,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '310 Edwards Avenue',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 64,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 100}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 17379,
                     'lat': 37.318558,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/17379-Our-Lady-Of-Grace/#College_success',
                               'profile': '/california/san-jose/17379-Our-Lady-Of-Grace/',
                               'reviews': '/california/san-jose/17379-Our-Lady-Of-Grace/#Reviews'},
                     'lon': -121.884987,
                     'name': 'Our Lady Of Grace',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2720 South Bascom Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 9,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 78},
                                       {'label': 'Hispanic', 'percentage': 22}],
                     'gradeLevels': '2-8',
                     'highlighted': false,
                     'id': 17383,
                     'lat': 37.272202,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/17383-Skylar-Hadden-School/#College_success',
                               'profile': '/california/san-jose/17383-Skylar-Hadden-School/',
                               'reviews': '/california/san-jose/17383-Skylar-Hadden-School/#Reviews'},
                     'lon': -121.935799,
                     'name': 'Skylar Hadden School',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2315 Canoas Garden Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '88%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 684,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 16,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 41, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 10,
                                        'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 27,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 14, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 3,
                                        'rating': 8},
                                       {'label': 'Filipino',
                                        'percentage': 5,
                                        'rating': 10}],
                     'gradeLevels': '7-12',
                     'highlighted': false,
                     'id': 17533,
                     'lat': 37.292255,
                     'levelCode': 'm,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/17533-University-Preparatory-Academy-Charter/#College_success',
                         'profile': '/california/san-jose/17533-University-Preparatory-Academy-Charter/',
                         'reviews': '/california/san-jose/17533-University-Preparatory-Academy-Charter/#Reviews'},
                     'lon': -121.873917,
                     'name': 'University Preparatory Academy Charter',
                     'numReviews': 74,
                     'parentRating': 4,
                     'percentLowIncome': '16%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'College Readiness Rating': 10,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2851 Gay Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 446,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 76,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 74,
                                        'rating': 3},
                                       {'label': 'Filipino',
                                        'percentage': 11,
                                        'rating': 6},
                                       {'label': 'Asian', 'percentage': 8},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'White', 'percentage': 2}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 17559,
                     'lat': 37.368759,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/17559-Anthony-P.-Russo-Academy/#College_success',
                               'profile': '/california/san-jose/17559-Anthony-P.-Russo-Academy/',
                               'reviews': '/california/san-jose/17559-Anthony-P.-Russo-Academy/#Reviews'},
                     'lon': -121.835678,
                     'name': 'Anthony P. Russo Academy',
                     'numReviews': 10,
                     'parentRating': 4,
                     'percentLowIncome': '76%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '788 Locust Street',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 554,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 84,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'African American',
                                        'percentage': 7,
                                        'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 84,
                                        'rating': 8},
                                       {'label': 'Asian', 'percentage': 4},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 17648,
                     'lat': 37.321396,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/17648-Rocketship-Mateo-Sheedy-Elementary-School/#College_success',
                         'profile': '/california/san-jose/17648-Rocketship-Mateo-Sheedy-Elementary-School/',
                         'reviews': '/california/san-jose/17648-Rocketship-Mateo-Sheedy-Elementary-School/#Reviews'},
                     'lon': -121.886673,
                     'name': 'Rocketship Mateo Sheedy Elementary School',
                     'numReviews': 19,
                     'parentRating': 4,
                     'percentLowIncome': '84%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 36,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 9,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '715 Hellyer Avenue',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 481,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 69,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 93,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Asian', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 17663,
                     'lat': 37.28474,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/17663-Voices-College-Bound-Language-Academy/#College_success',
                         'profile': '/california/san-jose/17663-Voices-College-Bound-Language-Academy/',
                         'reviews': '/california/san-jose/17663-Voices-College-Bound-Language-Academy/#Reviews'},
                     'lon': -121.821808,
                     'name': 'Voices College-Bound Language Academy',
                     'numReviews': 44,
                     'parentRating': 4,
                     'percentLowIncome': '69%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1532 Willowbrae Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22496,
                     'lat': 37.297398,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22496-Sinai-Nursery-School/#College_success',
                               'profile': '/california/san-jose/22496-Sinai-Nursery-School/',
                               'reviews': '/california/san-jose/22496-Sinai-Nursery-School/#Reviews'},
                     'lon': -121.910599,
                     'name': 'Sinai Nursery School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '370 Wooster Street',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 524,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 77,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 80,
                                        'rating': 5},
                                       {'label': 'Asian', 'percentage': 12},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 25489,
                     'lat': 37.354202,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/25489-Rocketship-Discovery-Prep/#College_success',
                         'profile': '/california/san-jose/25489-Rocketship-Discovery-Prep/',
                         'reviews': '/california/san-jose/25489-Rocketship-Discovery-Prep/#Reviews'},
                     'lon': -121.870049,
                     'name': 'Rocketship Discovery Prep',
                     'numReviews': 11,
                     'parentRating': 4,
                     'percentLowIncome': '77%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 30,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18207,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/18207-Knott-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/18207-Knott-Child-Development-Center/',
                         'reviews': '/california/san-jose/18207-Knott-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Knott Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1596 Davis Street',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18282,
                     'lat': 37.336636,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/18282-A-Special-Place-Camden/#College_success',
                               'profile': '/california/san-jose/18282-A-Special-Place-Camden/',
                               'reviews': '/california/san-jose/18282-A-Special-Place-Camden/#Reviews'},
                     'lon': -121.932632,
                     'name': 'A Special Place-Camden',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 18699,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/18699-Sedgwick-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/18699-Sedgwick-Child-Development-Center/',
                         'reviews': '/california/san-jose/18699-Sedgwick-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Sedgwick Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 18801,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/18801-Dixon-Migrant-Cdc-School-Age/#College_success',
                         'profile': '/california/san-jose/18801-Dixon-Migrant-Cdc-School-Age/',
                         'reviews': '/california/san-jose/18801-Dixon-Migrant-Cdc-School-Age/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Dixon Migrant Cdc-School Age',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 19588,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/19588-Canyon-View-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/19588-Canyon-View-School-Age-Cdc/',
                         'reviews': '/california/san-jose/19588-Canyon-View-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Canyon View School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 20370,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/20370-Madison-Migrant-Cdc/#College_success',
                               'profile': '/california/san-jose/20370-Madison-Migrant-Cdc/',
                               'reviews': '/california/san-jose/20370-Madison-Migrant-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Madison Migrant Cdc',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1590 Minnesota Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 25,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 40},
                                       {'label': 'White', 'percentage': 40},
                                       {'label': 'Hispanic', 'percentage': 20}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 26654,
                     'lat': 37.300793,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/26654-Willow-Glen-Academy/#College_success',
                               'profile': '/california/san-jose/26654-Willow-Glen-Academy/',
                               'reviews': '/california/san-jose/26654-Willow-Glen-Academy/#Reviews'},
                     'lon': -121.913147,
                     'name': 'Willow Glen Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 20645,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/20645-El-Toro-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/20645-El-Toro-Child-Development-Center/',
                         'reviews': '/california/san-jose/20645-El-Toro-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'El Toro Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #26',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 21725,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/21725-Al-Tahoe-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/21725-Al-Tahoe-Child-Development-Center/',
                         'reviews': '/california/san-jose/21725-Al-Tahoe-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Al Tahoe Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 21729,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/21729-Lake-Tahoe-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/21729-Lake-Tahoe-Child-Development-Center/',
                         'reviews': '/california/san-jose/21729-Lake-Tahoe-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Lake Tahoe Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1080 The Alameda',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22366,
                     'lat': 37.331093,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22366-Park-Avenue-Pre-School/#College_success',
                               'profile': '/california/san-jose/22366-Park-Avenue-Pre-School/',
                               'reviews': '/california/san-jose/22366-Park-Avenue-Pre-School/#Reviews'},
                     'lon': -121.911346,
                     'name': 'Park Avenue Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1081 Foxworthy Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 12,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 42},
                                       {'label': 'African American', 'percentage': 25},
                                       {'label': 'Two or more races', 'percentage': 17},
                                       {'label': 'White', 'percentage': 8},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 8}],
                     'gradeLevels': 'K',
                     'highlighted': false,
                     'id': 22367,
                     'lat': 37.278645,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22367-Kindercare-Learning-Center/#College_success',
                         'profile': '/california/san-jose/22367-Kindercare-Learning-Center/',
                         'reviews': '/california/san-jose/22367-Kindercare-Learning-Center/#Reviews'},
                     'lon': -121.878799,
                     'name': 'Kindercare Learning Center',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1133 Piedmont Rd',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22368,
                     'lat': 37.39539,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22368-Tinytown/#College_success',
                               'profile': '/california/san-jose/22368-Tinytown/',
                               'reviews': '/california/san-jose/22368-Tinytown/#Reviews'},
                     'lon': -121.845612,
                     'name': 'Tinytown',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1149 East Julian Street',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22369,
                     'lat': 37.351124,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22369-Family-Early-Learning-Center/#College_success',
                         'profile': '/california/san-jose/22369-Family-Early-Learning-Center/',
                         'reviews': '/california/san-jose/22369-Family-Early-Learning-Center/#Reviews'},
                     'lon': -121.873055,
                     'name': 'Family Early Learning Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1175 Hillsdale Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22370,
                     'lat': 37.275219,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22370-Calvary-Chapel-Christian-School/#College_success',
                         'profile': '/california/san-jose/22370-Calvary-Chapel-Christian-School/',
                         'reviews': '/california/san-jose/22370-Calvary-Chapel-Christian-School/#Reviews'},
                     'lon': -121.881035,
                     'name': 'Calvary Chapel Christian School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1177 Naglee Avenue',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22371,
                     'lat': 37.337067,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22371-Central-Nursery-School/#College_success',
                               'profile': '/california/san-jose/22371-Central-Nursery-School/',
                               'reviews': '/california/san-jose/22371-Central-Nursery-School/#Reviews'},
                     'lon': -121.92057,
                     'name': 'Central Nursery School',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '11843 Redmond Ave',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22372,
                     'lat': 37.229607,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22372-Redmond-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22372-Redmond-Child-Development-Center/',
                         'reviews': '/california/san-jose/22372-Redmond-Child-Development-Center/#Reviews'},
                     'lon': -121.874359,
                     'name': 'Redmond Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1474 Vía Codorniz',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22373,
                     'lat': 37.296474,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22373-Discovery-Years-Pre-School/#College_success',
                         'profile': '/california/san-jose/22373-Discovery-Years-Pre-School/',
                         'reviews': '/california/san-jose/22373-Discovery-Years-Pre-School/#Reviews'},
                     'lon': -121.938042,
                     'name': 'Discovery Years Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1191 South De Anza Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22374,
                     'lat': 37.302799,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22374-Alphabet-Soup-Pre-School/#College_success',
                               'profile': '/california/san-jose/22374-Alphabet-Soup-Pre-School/',
                               'reviews': '/california/san-jose/22374-Alphabet-Soup-Pre-School/#Reviews'},
                     'lon': -122.032593,
                     'name': 'Alphabet Soup Pre-School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1201 South Winchester Boulevard',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22375,
                     'lat': 37.304043,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22375-Bethel-Pre-School/#College_success',
                               'profile': '/california/san-jose/22375-Bethel-Pre-School/',
                               'reviews': '/california/san-jose/22375-Bethel-Pre-School/#Reviews'},
                     'lon': -121.95137,
                     'name': 'Bethel Pre-School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1229 Naglee Avenue',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22376,
                     'lat': 37.336472,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22376-Joyful-Noise-Pre-School/#College_success',
                               'profile': '/california/san-jose/22376-Joyful-Noise-Pre-School/',
                               'reviews': '/california/san-jose/22376-Joyful-Noise-Pre-School/#Reviews'},
                     'lon': -121.921677,
                     'name': 'Joyful Noise Pre-School',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': 'Po Box 20145',
                                 'street2': '',
                                 'zip': '95160'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22377,
                     'lat': 37.220001,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22377-Precious-Pre-School/#College_success',
                               'profile': '/california/san-jose/22377-Precious-Pre-School/',
                               'reviews': '/california/san-jose/22377-Precious-Pre-School/#Reviews'},
                     'lon': -121.860001,
                     'name': 'Precious Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1281 Redmond Avenue',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22378,
                     'lat': 37.228985,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22378-Shepherd-Of-Vly-Luth-Pre-School/#College_success',
                         'profile': '/california/san-jose/22378-Shepherd-Of-Vly-Luth-Pre-School/',
                         'reviews': '/california/san-jose/22378-Shepherd-Of-Vly-Luth-Pre-School/#Reviews'},
                     'lon': -121.881302,
                     'name': 'Shepherd Of Vly Luth Pre-School',
                     'numReviews': 5,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Dr',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22379,
                     'lat': 37.383762,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22379-Almaden-Head-Start-Pre-School/#College_success',
                         'profile': '/california/san-jose/22379-Almaden-Head-Start-Pre-School/',
                         'reviews': '/california/san-jose/22379-Almaden-Head-Start-Pre-School/#Reviews'},
                     'lon': -121.9002,
                     'name': 'Almaden Head Start Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1313 Audobon Dr',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22380,
                     'lat': 37.328026,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22380-Santee-Child-Development-Center--Day-Care-Center/#College_success',
                         'profile': '/california/san-jose/22380-Santee-Child-Development-Center--Day-Care-Center/',
                         'reviews': '/california/san-jose/22380-Santee-Child-Development-Center--Day-Care-Center/#Reviews'},
                     'lon': -121.846657,
                     'name': 'Santee Child Development Center & Day Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1315 McLaughlin Ave',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22382,
                     'lat': 37.330101,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22382-Lindsay-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22382-Lindsay-Child-Development-Center/',
                         'reviews': '/california/san-jose/22382-Lindsay-Child-Development-Center/#Reviews'},
                     'lon': -121.851868,
                     'name': 'Lindsay Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1321 Miller Avenue',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22384,
                     'lat': 37.300262,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22384-Sunshine-Montessori-School/#College_success',
                         'profile': '/california/san-jose/22384-Sunshine-Montessori-School/',
                         'reviews': '/california/san-jose/22384-Sunshine-Montessori-School/#Reviews'},
                     'lon': -122.015472,
                     'name': 'Sunshine Montessori School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1321 Miller Ave',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22386,
                     'lat': 37.300278,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22386-Winnie-The-Pooh/#College_success',
                               'profile': '/california/san-jose/22386-Winnie-The-Pooh/',
                               'reviews': '/california/san-jose/22386-Winnie-The-Pooh/#Reviews'},
                     'lon': -122.015472,
                     'name': 'Winnie the Pooh',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1322 Santee Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22387,
                     'lat': 37.330517,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22387-Monte-Alban-Head-Start/#College_success',
                               'profile': '/california/san-jose/22387-Monte-Alban-Head-Start/',
                               'reviews': '/california/san-jose/22387-Monte-Alban-Head-Start/#Reviews'},
                     'lon': -121.847916,
                     'name': 'Monte Alban Head Start',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1929 Baker Place',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22388,
                     'lat': 37.384537,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22388-Sunshine-School/#College_success',
                               'profile': '/california/san-jose/22388-Sunshine-School/',
                               'reviews': '/california/san-jose/22388-Sunshine-School/#Reviews'},
                     'lon': -121.878334,
                     'name': 'Sunshine School',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '13901 Nordyke Drive',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22389,
                     'lat': 37.359772,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22389-Easthills-Lyndale-Head-Start/#College_success',
                         'profile': '/california/san-jose/22389-Easthills-Lyndale-Head-Start/',
                         'reviews': '/california/san-jose/22389-Easthills-Lyndale-Head-Start/#Reviews'},
                     'lon': -121.825005,
                     'name': 'Easthills Lyndale Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1411 Piedmont Rd',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22390,
                     'lat': 37.405556,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22390-Wonder-Years-Day-Care/#College_success',
                               'profile': '/california/san-jose/22390-Wonder-Years-Day-Care/',
                               'reviews': '/california/san-jose/22390-Wonder-Years-Day-Care/#Reviews'},
                     'lon': -121.850044,
                     'name': 'Wonder Years Day Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1474 Vía Codorniz',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22391,
                     'lat': 37.296474,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22391-Discovery-Years/#College_success',
                               'profile': '/california/san-jose/22391-Discovery-Years/',
                               'reviews': '/california/san-jose/22391-Discovery-Years/#Reviews'},
                     'lon': -121.938042,
                     'name': 'Discovery Years',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1420 Newport Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22392,
                     'lat': 37.301563,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22392-Willow-Glen-Methodist-Ch-Pre-School/#College_success',
                         'profile': '/california/san-jose/22392-Willow-Glen-Methodist-Ch-Pre-School/',
                         'reviews': '/california/san-jose/22392-Willow-Glen-Methodist-Ch-Pre-School/#Reviews'},
                     'lon': -121.90065,
                     'name': 'Willow Glen Methodist Ch Pre-School',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1425 Old Piedmont Road',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22393,
                     'lat': 37.406609,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22393-Piedmont-Hills-Montessori/#College_success',
                         'profile': '/california/san-jose/22393-Piedmont-Hills-Montessori/',
                         'reviews': '/california/san-jose/22393-Piedmont-Hills-Montessori/#Reviews'},
                     'lon': -121.847939,
                     'name': 'Piedmont Hills Montessori',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1447 Bryan Ave',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22394,
                     'lat': 37.266117,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22394-Church-Of-The-Chimes-Child-Care-Center/#College_success',
                         'profile': '/california/san-jose/22394-Church-Of-The-Chimes-Child-Care-Center/',
                         'reviews': '/california/san-jose/22394-Church-Of-The-Chimes-Child-Care-Center/#Reviews'},
                     'lon': -121.890007,
                     'name': 'Church of the Chimes Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '14834 Leigh Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22395,
                     'lat': 37.25325,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22395-Creative-Beginnings/#College_success',
                               'profile': '/california/san-jose/22395-Creative-Beginnings/',
                               'reviews': '/california/san-jose/22395-Creative-Beginnings/#Reviews'},
                     'lon': -121.921432,
                     'name': 'Creative Beginnings',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '14845 Story Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22396,
                     'lat': 37.364307,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22396-East-Hills-Pre-School/#College_success',
                               'profile': '/california/san-jose/22396-East-Hills-Pre-School/',
                               'reviews': '/california/san-jose/22396-East-Hills-Pre-School/#Reviews'},
                     'lon': -121.807816,
                     'name': 'East Hills Pre-School',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '15040 Union Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22397,
                     'lat': 37.24992,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22397-St-Edwards-Pre-School/#College_success',
                               'profile': '/california/san-jose/22397-St-Edwards-Pre-School/',
                               'reviews': '/california/san-jose/22397-St-Edwards-Pre-School/#Reviews'},
                     'lon': -121.930786,
                     'name': "St Edward's Pre-School",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1550 Meridian Ave',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22399,
                     'lat': 37.296314,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22399-Mariposa-Montessori-School/#College_success',
                         'profile': '/california/san-jose/22399-Mariposa-Montessori-School/',
                         'reviews': '/california/san-jose/22399-Mariposa-Montessori-School/#Reviews'},
                     'lon': -121.912338,
                     'name': 'Mariposa Montessori School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1590 Las Plumas Avenue',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22400,
                     'lat': 37.359142,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22400-Voyagers-Child-Care-Center/#College_success',
                         'profile': '/california/san-jose/22400-Voyagers-Child-Care-Center/',
                         'reviews': '/california/san-jose/22400-Voyagers-Child-Care-Center/#Reviews'},
                     'lon': -121.86953,
                     'name': "Voyager's Child Care Center",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': 'Po Box 6425',
                                 'street2': '',
                                 'zip': '95150'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22401,
                     'lat': 37.389999,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22401-Willow-Glen-Academy-Dc/#College_success',
                               'profile': '/california/san-jose/22401-Willow-Glen-Academy-Dc/',
                               'reviews': '/california/san-jose/22401-Willow-Glen-Academy-Dc/#Reviews'},
                     'lon': -121.900002,
                     'name': 'Willow Glen Academy Dc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1601 Cunningham Ave',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22402,
                     'lat': 37.326447,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22402-Slonaker-Extended-Day-Care-1/#College_success',
                         'profile': '/california/san-jose/22402-Slonaker-Extended-Day-Care-1/',
                         'reviews': '/california/san-jose/22402-Slonaker-Extended-Day-Care-1/#Reviews'},
                     'lon': -121.834572,
                     'name': 'Slonaker Extended Day Care 1',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '611 Willis Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22403,
                     'lat': 37.322216,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22403-Estrella-Family-Services/#College_success',
                               'profile': '/california/san-jose/22403-Estrella-Family-Services/',
                               'reviews': '/california/san-jose/22403-Estrella-Family-Services/#Reviews'},
                     'lon': -121.895271,
                     'name': 'Estrella Family Services',
                     'numReviews': 4,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1650 South White Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22404,
                     'lat': 37.346558,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22404-Mt-Pleasant-Childrens-Center/#College_success',
                         'profile': '/california/san-jose/22404-Mt-Pleasant-Childrens-Center/',
                         'reviews': '/california/san-jose/22404-Mt-Pleasant-Childrens-Center/#Reviews'},
                     'lon': -121.810005,
                     'name': "Mt Pleasant Children's Center",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1717 the Alameda',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22405,
                     'lat': 37.337681,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22405-Kids-Korner/#College_success',
                               'profile': '/california/san-jose/22405-Kids-Korner/',
                               'reviews': '/california/san-jose/22405-Kids-Korner/#Reviews'},
                     'lon': -121.920479,
                     'name': "Kid's Korner",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1718 Andover Lane',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22406,
                     'lat': 37.25552,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22406-Learning-Springs-Academy/#College_success',
                               'profile': '/california/san-jose/22406-Learning-Springs-Academy/',
                               'reviews': '/california/san-jose/22406-Learning-Springs-Academy/#Reviews'},
                     'lon': -121.910271,
                     'name': 'Learning Springs Academy',
                     'numReviews': 15,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1776 Educational Park Drive',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22407,
                     'lat': 37.367844,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22407-Independence-High-Chldrns-Center/#College_success',
                         'profile': '/california/san-jose/22407-Independence-High-Chldrns-Center/',
                         'reviews': '/california/san-jose/22407-Independence-High-Chldrns-Center/#Reviews'},
                     'lon': -121.85965,
                     'name': "Independence High Chldrn's Center",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive #325',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22408,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22408-Parkway-Head-Start/#College_success',
                               'profile': '/california/san-jose/22408-Parkway-Head-Start/',
                               'reviews': '/california/san-jose/22408-Parkway-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Parkway Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1835 Cunningham Ave',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22409,
                     'lat': 37.329697,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22409-WC-Overfelt-Childrens-Center/#College_success',
                         'profile': '/california/san-jose/22409-WC-Overfelt-Childrens-Center/',
                         'reviews': '/california/san-jose/22409-WC-Overfelt-Childrens-Center/#Reviews'},
                     'lon': -121.829712,
                     'name': "WC Overfelt Children's Center",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '830 North Capitol Avenue',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22411,
                     'lat': 37.380646,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22411-Yerba-Buena-Childrens-Center/#College_success',
                         'profile': '/california/san-jose/22411-Yerba-Buena-Childrens-Center/',
                         'reviews': '/california/san-jose/22411-Yerba-Buena-Childrens-Center/#Reviews'},
                     'lon': -121.853836,
                     'name': "Yerba Buena Children's Center",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1879 Meridian Ave',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22412,
                     'lat': 37.289577,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22412-Children-Under-The-Rainbow-DC/#College_success',
                         'profile': '/california/san-jose/22412-Children-Under-The-Rainbow-DC/',
                         'reviews': '/california/san-jose/22412-Children-Under-The-Rainbow-DC/#Reviews'},
                     'lon': -121.913795,
                     'name': 'Children Under the Rainbow DC',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1919 Gunston Way',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22413,
                     'lat': 37.255379,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22413-Discovery-Parent-Child-Pre-School/#College_success',
                         'profile': '/california/san-jose/22413-Discovery-Parent-Child-Pre-School/',
                         'reviews': '/california/san-jose/22413-Discovery-Parent-Child-Pre-School/#Reviews'},
                     'lon': -121.923088,
                     'name': 'Discovery Parent Child Pre-School',
                     'numReviews': 3,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1921 Clarinda Way',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22414,
                     'lat': 37.245819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22414-Little-Oak-Pre-School/#College_success',
                               'profile': '/california/san-jose/22414-Little-Oak-Pre-School/',
                               'reviews': '/california/san-jose/22414-Little-Oak-Pre-School/#Reviews'},
                     'lon': -121.925446,
                     'name': 'Little Oak Pre-School',
                     'numReviews': 4,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1400 Parkmoor Avenue #220',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22415,
                     'lat': 37.316017,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22415-San-Juan-Gautista-Cdc/#College_success',
                               'profile': '/california/san-jose/22415-San-Juan-Gautista-Cdc/',
                               'reviews': '/california/san-jose/22415-San-Juan-Gautista-Cdc/#Reviews'},
                     'lon': -121.912704,
                     'name': 'San Juan Gautista Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1945 Terilyn Ave',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22416,
                     'lat': 37.326618,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22416-Santa-Clara-Water-District-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22416-Santa-Clara-Water-District-Child-Development-Center/',
                         'reviews': '/california/san-jose/22416-Santa-Clara-Water-District-Child-Development-Center/#Reviews'},
                     'lon': -121.83725,
                     'name': 'Santa Clara Water District Child Development Center',
                     'numReviews': 5,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1975 Cambrianna Drive',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22417,
                     'lat': 37.265141,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22417-Atlc-Pre-School/#College_success',
                               'profile': '/california/san-jose/22417-Atlc-Pre-School/',
                               'reviews': '/california/san-jose/22417-Atlc-Pre-School/#Reviews'},
                     'lon': -121.930511,
                     'name': 'Atlc Pre-School',
                     'numReviews': 1,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1975 South White Road',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22419,
                     'lat': 37.342972,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22419-East-Vly-Ymca-Family-Dc/#College_success',
                               'profile': '/california/san-jose/22419-East-Vly-Ymca-Family-Dc/',
                               'reviews': '/california/san-jose/22419-East-Vly-Ymca-Family-Dc/#Reviews'},
                     'lon': -121.807945,
                     'name': 'East Vly Ymca Family Dc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1980 Fruitdale Ave',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22420,
                     'lat': 37.310444,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22420-New-Frontier-Pre-School/#College_success',
                               'profile': '/california/san-jose/22420-New-Frontier-Pre-School/',
                               'reviews': '/california/san-jose/22420-New-Frontier-Pre-School/#Reviews'},
                     'lon': -121.927925,
                     'name': 'New Frontier Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2021 Lincoln Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22421,
                     'lat': 37.293655,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22421-Regard-Child-Care-Development-Center/#College_success',
                         'profile': '/california/san-jose/22421-Regard-Child-Care-Development-Center/',
                         'reviews': '/california/san-jose/22421-Regard-Child-Care-Development-Center/#Reviews'},
                     'lon': -121.890839,
                     'name': 'Regard Child Care Development Center',
                     'numReviews': 10,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2055 Summerside Dr',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22422,
                     'lat': 37.318085,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22422-Eso-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22422-Eso-Child-Development-Center/',
                         'reviews': '/california/san-jose/22422-Eso-Child-Development-Center/#Reviews'},
                     'lon': -121.842659,
                     'name': 'Eso Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2100 Moorpark Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22423,
                     'lat': 37.315884,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22423-San-Jose-City-Collge-Child-Development/#College_success',
                         'profile': '/california/san-jose/22423-San-Jose-City-Collge-Child-Development/',
                         'reviews': '/california/san-jose/22423-San-Jose-City-Collge-Child-Development/#Reviews'},
                     'lon': -121.9319,
                     'name': 'San Jose City Collge Child Development',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3030 Moorpark Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22424,
                     'lat': 37.315353,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22424-Action-Day-Primary-Plus/#College_success',
                               'profile': '/california/san-jose/22424-Action-Day-Primary-Plus/',
                               'reviews': '/california/san-jose/22424-Action-Day-Primary-Plus/#Reviews'},
                     'lon': -121.948494,
                     'name': 'Action Day Primary Plus',
                     'numReviews': 9,
                     'parentRating': 2,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2180 Radio Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22425,
                     'lat': 37.290524,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22425-San-Jose-Parents-Participating-Nursery-School/#College_success',
                         'profile': '/california/san-jose/22425-San-Jose-Parents-Participating-Nursery-School/',
                         'reviews': '/california/san-jose/22425-San-Jose-Parents-Participating-Nursery-School/#Reviews'},
                     'lon': -121.890114,
                     'name': 'San Jose Parents Participating Nursery School',
                     'numReviews': 7,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2380 Enborg Lane',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22427,
                     'lat': 37.311012,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22427-Rosa-Elena-Childcare-Center/#College_success',
                         'profile': '/california/san-jose/22427-Rosa-Elena-Childcare-Center/',
                         'reviews': '/california/san-jose/22427-Rosa-Elena-Childcare-Center/#Reviews'},
                     'lon': -121.935219,
                     'name': 'Rosa Elena Childcare Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2425 Samaritan Dr',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22428,
                     'lat': 37.252144,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22428-Good-Samaritan-Child-Care-Center/#College_success',
                         'profile': '/california/san-jose/22428-Good-Samaritan-Child-Care-Center/',
                         'reviews': '/california/san-jose/22428-Good-Samaritan-Child-Care-Center/#Reviews'},
                     'lon': -121.946594,
                     'name': 'Good Samaritan Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3016 Allenwood Drive',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22429,
                     'lat': 37.326649,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22429-Genesis-Pre-School-Child-Care/#College_success',
                         'profile': '/california/san-jose/22429-Genesis-Pre-School-Child-Care/',
                         'reviews': '/california/san-jose/22429-Genesis-Pre-School-Child-Care/#Reviews'},
                     'lon': -121.796547,
                     'name': 'Genesis Pre-School-Child Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2605 Gassman Dr',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22432,
                     'lat': 37.308556,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22432-Stonegate-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22432-Stonegate-Child-Development-Center/',
                         'reviews': '/california/san-jose/22432-Stonegate-Child-Development-Center/#Reviews'},
                     'lon': -121.836426,
                     'name': 'Stonegate Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': 'Po Box 24786',
                                 'street2': '',
                                 'zip': '95154'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22433,
                     'lat': 37.259998,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22433-Explorer-Parent-Pre-School/#College_success',
                         'profile': '/california/san-jose/22433-Explorer-Parent-Pre-School/',
                         'reviews': '/california/san-jose/22433-Explorer-Parent-Pre-School/#Reviews'},
                     'lon': -121.910004,
                     'name': 'Explorer Parent Pre-School',
                     'numReviews': 25,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2798 S Bascom Ave',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22435,
                     'lat': 37.270744,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22435-Jia-Ray-Learning-Center/#College_success',
                               'profile': '/california/san-jose/22435-Jia-Ray-Learning-Center/',
                               'reviews': '/california/san-jose/22435-Jia-Ray-Learning-Center/#Reviews'},
                     'lon': -121.936577,
                     'name': 'Jia-Ray Learning Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6986 El Marcero Court',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22436,
                     'lat': 37.222988,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22436-Building-Kids-Care/#College_success',
                               'profile': '/california/san-jose/22436-Building-Kids-Care/',
                               'reviews': '/california/san-jose/22436-Building-Kids-Care/#Reviews'},
                     'lon': -121.783173,
                     'name': 'Building Kids Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2858 Stevens Creek Boulevard #10',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22437,
                     'lat': 37.323086,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22437-Kidspark/#College_success',
                               'profile': '/california/san-jose/22437-Kidspark/',
                               'reviews': '/california/san-jose/22437-Kidspark/#Reviews'},
                     'lon': -121.944801,
                     'name': 'Kidspark',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '286 Sorrento Way',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22438,
                     'lat': 37.235306,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22438-Little-Kiddles-Swing-Set-Grow/#College_success',
                         'profile': '/california/san-jose/22438-Little-Kiddles-Swing-Set-Grow/',
                         'reviews': '/california/san-jose/22438-Little-Kiddles-Swing-Set-Grow/#Reviews'},
                     'lon': -121.79985,
                     'name': 'Little Kiddles-Swing Set Grow',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2955 Alum Rock Ave',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22440,
                     'lat': 37.364571,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22440-James-Lick-Childrens-Center/#College_success',
                         'profile': '/california/san-jose/22440-James-Lick-Childrens-Center/',
                         'reviews': '/california/san-jose/22440-James-Lick-Childrens-Center/#Reviews'},
                     'lon': -121.830765,
                     'name': "James Lick Children's Center",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '7221 Bark Lane #8',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22441,
                     'lat': 37.303043,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22441-Parkside-Chinese-School/#College_success',
                               'profile': '/california/san-jose/22441-Parkside-Chinese-School/',
                               'reviews': '/california/san-jose/22441-Parkside-Chinese-School/#Reviews'},
                     'lon': -122.030869,
                     'name': 'Parkside Chinese School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3001 Ross Avenue #1',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22442,
                     'lat': 37.267197,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22442-After-School-Adventures/#College_success',
                               'profile': '/california/san-jose/22442-After-School-Adventures/',
                               'reviews': '/california/san-jose/22442-After-School-Adventures/#Reviews'},
                     'lon': -121.919312,
                     'name': 'After School Adventures',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '301 Cottle Rd',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22443,
                     'lat': 37.239021,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22443-Familiar-Footsteps/#College_success',
                               'profile': '/california/san-jose/22443-Familiar-Footsteps/',
                               'reviews': '/california/san-jose/22443-Familiar-Footsteps/#Reviews'},
                     'lon': -121.803902,
                     'name': 'Familiar Footsteps',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3030 Moorpark Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22445,
                     'lat': 37.315353,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22445-Action-Day-Nursery-On-Moorpark/#College_success',
                         'profile': '/california/san-jose/22445-Action-Day-Nursery-On-Moorpark/',
                         'reviews': '/california/san-jose/22445-Action-Day-Nursery-On-Moorpark/#Reviews'},
                     'lon': -121.948494,
                     'name': 'Action Day Nursery On Moorpark',
                     'numReviews': 4,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3095 Yerba Buena Road',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22446,
                     'lat': 37.299999,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22446-Evergreen-Valley-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22446-Evergreen-Valley-Child-Development-Center/',
                         'reviews': '/california/san-jose/22446-Evergreen-Valley-Child-Development-Center/#Reviews'},
                     'lon': -121.761162,
                     'name': 'Evergreen Valley Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3122 Solwer Rd',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22447,
                     'lat': 37.310349,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22447-CHLDRNs-Pre-School-Center-Evergreen/#College_success',
                         'profile': '/california/san-jose/22447-CHLDRNs-Pre-School-Center-Evergreen/',
                         'reviews': '/california/san-jose/22447-CHLDRNs-Pre-School-Center-Evergreen/#Reviews'},
                     'lon': -121.777634,
                     'name': "CHLDRN's Pre-School Center-Evergreen",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3151 Union Ave',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22448,
                     'lat': 37.265957,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22448-A-Special-Place/#College_success',
                               'profile': '/california/san-jose/22448-A-Special-Place/',
                               'reviews': '/california/san-jose/22448-A-Special-Place/#Reviews'},
                     'lon': -121.932381,
                     'name': 'A Special Place',
                     'numReviews': 2,
                     'parentRating': 2,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #26',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22449,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22449-El-Rancho-Verde-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22449-El-Rancho-Verde-Child-Development-Center/',
                         'reviews': '/california/san-jose/22449-El-Rancho-Verde-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'El Rancho Verde Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3200 Senter Road',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22450,
                     'lat': 37.292488,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22450-Andrew-Hill-Childrens-Center/#College_success',
                         'profile': '/california/san-jose/22450-Andrew-Hill-Childrens-Center/',
                         'reviews': '/california/san-jose/22450-Andrew-Hill-Childrens-Center/#Reviews'},
                     'lon': -121.831383,
                     'name': "Andrew Hill Children's Center",
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '33 North 8th Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22452,
                     'lat': 37.339989,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22452-San-Jose-Day-Nursery/#College_success',
                               'profile': '/california/san-jose/22452-San-Jose-Day-Nursery/',
                               'reviews': '/california/san-jose/22452-San-Jose-Day-Nursery/#Reviews'},
                     'lon': -121.884056,
                     'name': 'San Jose Day Nursery',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3320 San Felipe Road',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22453,
                     'lat': 37.313396,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22453-Kinder-Care-Learning-Center-1187/#College_success',
                         'profile': '/california/san-jose/22453-Kinder-Care-Learning-Center-1187/',
                         'reviews': '/california/san-jose/22453-Kinder-Care-Learning-Center-1187/#Reviews'},
                     'lon': -121.789513,
                     'name': 'Kinder Care Learning Center #1187',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3485 East Hills Drive',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22454,
                     'lat': 37.366962,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22454-San-Jose-Job-Corps-Head-Start/#College_success',
                         'profile': '/california/san-jose/22454-San-Jose-Job-Corps-Head-Start/',
                         'reviews': '/california/san-jose/22454-San-Jose-Job-Corps-Head-Start/#Reviews'},
                     'lon': -121.820534,
                     'name': 'San Jose Job Corps Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4711 West Campbell Avenue',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22455,
                     'lat': 37.287693,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22455-Moreland-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22455-Moreland-Child-Development-Center/',
                         'reviews': '/california/san-jose/22455-Moreland-Child-Development-Center/#Reviews'},
                     'lon': -121.985268,
                     'name': 'Moreland Child Development Center',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3800 Narvaez Avenue',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22456,
                     'lat': 37.275188,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22456-Play-N-Learn-Pre-School/#College_success',
                               'profile': '/california/san-jose/22456-Play-N-Learn-Pre-School/',
                               'reviews': '/california/san-jose/22456-Play-N-Learn-Pre-School/#Reviews'},
                     'lon': -121.860352,
                     'name': 'Play-N-Learn Pre-School',
                     'numReviews': 4,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '383 Spar Ave',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'Ungraded',
                     'highlighted': false,
                     'id': 22457,
                     'lat': 37.320866,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22457-Beginning-Steps-To-Independence/#College_success',
                         'profile': '/california/san-jose/22457-Beginning-Steps-To-Independence/',
                         'reviews': '/california/san-jose/22457-Beginning-Steps-To-Independence/#Reviews'},
                     'lon': -121.951706,
                     'name': 'Beginning Steps To Independence',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3975 Mira Loma',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22458,
                     'lat': 37.282345,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22458-Seven-Trees-Head-Start/#College_success',
                               'profile': '/california/san-jose/22458-Seven-Trees-Head-Start/',
                               'reviews': '/california/san-jose/22458-Seven-Trees-Head-Start/#Reviews'},
                     'lon': -121.830269,
                     'name': 'Seven Trees Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4 Wabash Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22459,
                     'lat': 37.323532,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22459-Luther-Burbank-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22459-Luther-Burbank-Child-Development-Center/',
                         'reviews': '/california/san-jose/22459-Luther-Burbank-Child-Development-Center/#Reviews'},
                     'lon': -121.92675,
                     'name': 'Luther Burbank Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22462,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22462-Franklin-House-Head-Start/#College_success',
                         'profile': '/california/san-jose/22462-Franklin-House-Head-Start/',
                         'reviews': '/california/san-jose/22462-Franklin-House-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Franklin House Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '460 South 8th Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22463,
                     'lat': 37.33289,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22463-Associated-Students-Cdc/#College_success',
                               'profile': '/california/san-jose/22463-Associated-Students-Cdc/',
                               'reviews': '/california/san-jose/22463-Associated-Students-Cdc/#Reviews'},
                     'lon': -121.877647,
                     'name': 'Associated Students Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5670 Camden Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 139,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 22464,
                     'lat': 37.235077,
                     'levelCode': 'p,e,m',
                     'links': {'collegeSuccess': '/california/san-jose/22464-Champion-School/#College_success',
                               'profile': '/california/san-jose/22464-Champion-School/',
                               'reviews': '/california/san-jose/22464-Champion-School/#Reviews'},
                     'lon': -121.90197,
                     'name': 'Champion School',
                     'numReviews': 126,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2421 Carlton Ave',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 22465,
                     'lat': 37.247433,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22465-South-Valley-Childrens-Center-A/#College_success',
                         'profile': '/california/san-jose/22465-South-Valley-Childrens-Center-A/',
                         'reviews': '/california/san-jose/22465-South-Valley-Childrens-Center-A/#Reviews'},
                     'lon': -121.945747,
                     'name': 'South Valley Childrens Center A',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5035 Poston Dr',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22467,
                     'lat': 37.261784,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22467-Hayes-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22467-Hayes-Child-Development-Center/',
                         'reviews': '/california/san-jose/22467-Hayes-Child-Development-Center/#Reviews'},
                     'lon': -121.826447,
                     'name': 'Hayes Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22468,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22468-Hayes-State-Pre-School-Cdc/#College_success',
                         'profile': '/california/san-jose/22468-Hayes-State-Pre-School-Cdc/',
                         'reviews': '/california/san-jose/22468-Hayes-State-Pre-School-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Hayes State Pre-School Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5038 Hyland Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22469,
                     'lat': 37.375805,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22469-Mandala-Childrens-House/#College_success',
                               'profile': '/california/san-jose/22469-Mandala-Childrens-House/',
                               'reviews': '/california/san-jose/22469-Mandala-Childrens-House/#Reviews'},
                     'lon': -121.823654,
                     'name': "Mandala Children's House",
                     'numReviews': 19,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '505 Massar Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22470,
                     'lat': 37.354427,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22470-Play-And-Learn-Pre-School/#College_success',
                         'profile': '/california/san-jose/22470-Play-And-Learn-Pre-School/',
                         'reviews': '/california/san-jose/22470-Play-And-Learn-Pre-School/#Reviews'},
                     'lon': -121.834068,
                     'name': 'Play And Learn Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '505 W Julian St',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22471,
                     'lat': 37.335773,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22471-Autumn-Wonderland-YMCA-Child-Care-Center/#College_success',
                         'profile': '/california/san-jose/22471-Autumn-Wonderland-YMCA-Child-Care-Center/',
                         'reviews': '/california/san-jose/22471-Autumn-Wonderland-YMCA-Child-Care-Center/#Reviews'},
                     'lon': -121.902664,
                     'name': 'Autumn Wonderland YMCA Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5050 Moorpark Ave',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22472,
                     'lat': 37.310787,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22472-De-Vargas-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22472-De-Vargas-Child-Development-Center/',
                         'reviews': '/california/san-jose/22472-De-Vargas-Child-Development-Center/#Reviews'},
                     'lon': -121.990936,
                     'name': 'De Vargas Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5102 Alum Rock Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22473,
                     'lat': 37.37484,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22473-Jordans-Pre-School/#College_success',
                               'profile': '/california/san-jose/22473-Jordans-Pre-School/',
                               'reviews': '/california/san-jose/22473-Jordans-Pre-School/#Reviews'},
                     'lon': -121.820938,
                     'name': "Jordan's Pre-School",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '521 West Capitol Expressway',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22474,
                     'lat': 37.275913,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22474-Kiddie-Academy-San-Jose/#College_success',
                               'profile': '/california/san-jose/22474-Kiddie-Academy-San-Jose/',
                               'reviews': '/california/san-jose/22474-Kiddie-Academy-San-Jose/#Reviews'},
                     'lon': -121.851898,
                     'name': 'Kiddie Academy-San Jose',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '525 Giuffrida Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22475,
                     'lat': 37.253468,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22475-Green-Valley-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22475-Green-Valley-Child-Development-Center/',
                         'reviews': '/california/san-jose/22475-Green-Valley-Child-Development-Center/#Reviews'},
                     'lon': -121.832344,
                     'name': 'Green Valley Child Development Center',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5303 Carter Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22476,
                     'lat': 37.242222,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22476-Carter-Avenue-Nursery-School/#College_success',
                         'profile': '/california/san-jose/22476-Carter-Avenue-Nursery-School/',
                         'reviews': '/california/san-jose/22476-Carter-Avenue-Nursery-School/#Reviews'},
                     'lon': -121.903267,
                     'name': 'Carter Avenue Nursery School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5345 Avenida Almendros',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22477,
                     'lat': 37.257332,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22477-Del-Roble-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22477-Del-Roble-Child-Development-Center/',
                         'reviews': '/california/san-jose/22477-Del-Roble-Child-Development-Center/#Reviews'},
                     'lon': -121.836113,
                     'name': 'Del Roble Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5370 Snell Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22478,
                     'lat': 37.25626,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22478-3d-Pre-School/#College_success',
                               'profile': '/california/san-jose/22478-3d-Pre-School/',
                               'reviews': '/california/san-jose/22478-3d-Pre-School/#Reviews'},
                     'lon': -121.830162,
                     'name': '3d Pre-School',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5386 Alum Rock Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22479,
                     'lat': 37.379147,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22479-Kiddie-Kollege/#College_success',
                               'profile': '/california/san-jose/22479-Kiddie-Kollege/',
                               'reviews': '/california/san-jose/22479-Kiddie-Kollege/#Reviews'},
                     'lon': -121.81842,
                     'name': 'Kiddie Kollege',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '555 Meridian Ave Suite 3',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22480,
                     'lat': 37.318432,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22480-Community-Family-Services/#College_success',
                         'profile': '/california/san-jose/22480-Community-Family-Services/',
                         'reviews': '/california/san-jose/22480-Community-Family-Services/#Reviews'},
                     'lon': -121.914436,
                     'name': 'Community Family Services',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5560 Entrada Cedros',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22481,
                     'lat': 37.252029,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22481-Kinderwood-Childrens-Center/#College_success',
                         'profile': '/california/san-jose/22481-Kinderwood-Childrens-Center/',
                         'reviews': '/california/san-jose/22481-Kinderwood-Childrens-Center/#Reviews'},
                     'lon': -121.835396,
                     'name': "Kinderwood Children's Center",
                     'numReviews': 1,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5805 Cahalan Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22482,
                     'lat': 37.246571,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22482-Almaden-Parents-Pre-School/#College_success',
                         'profile': '/california/san-jose/22482-Almaden-Parents-Pre-School/',
                         'reviews': '/california/san-jose/22482-Almaden-Parents-Pre-School/#Reviews'},
                     'lon': -121.848541,
                     'name': 'Almaden Parents Pre-School',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5845 Allen Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22483,
                     'lat': 37.244099,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22483-Action-Day-Primary-Plus-Dc/#College_success',
                         'profile': '/california/san-jose/22483-Action-Day-Primary-Plus-Dc/',
                         'reviews': '/california/san-jose/22483-Action-Day-Primary-Plus-Dc/#Reviews'},
                     'lon': -121.857086,
                     'name': 'Action Day Primary Plus Dc',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5860 Blossom Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22484,
                     'lat': 37.24247,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22484-Sunrise-Kiddie-Korral/#College_success',
                               'profile': '/california/san-jose/22484-Sunrise-Kiddie-Korral/',
                               'reviews': '/california/san-jose/22484-Sunrise-Kiddie-Korral/#Reviews'},
                     'lon': -121.838432,
                     'name': 'Sunrise Kiddie Korral',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6044 Vera Cruz Dr',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22485,
                     'lat': 37.228756,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22485-Adventure-Club-Guadalupe/#College_success',
                               'profile': '/california/san-jose/22485-Adventure-Club-Guadalupe/',
                               'reviews': '/california/san-jose/22485-Adventure-Club-Guadalupe/#Reviews'},
                     'lon': -121.89666,
                     'name': 'Adventure Club-Guadalupe',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6097 Cottle Road',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22486,
                     'lat': 37.237167,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22486-Familiar-Footsteps-Child-Care/#College_success',
                         'profile': '/california/san-jose/22486-Familiar-Footsteps-Child-Care/',
                         'reviews': '/california/san-jose/22486-Familiar-Footsteps-Child-Care/#Reviews'},
                     'lon': -121.804321,
                     'name': 'Familiar Footsteps Child Care',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6150 Snell Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22487,
                     'lat': 37.235138,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22487-Santa-Teresa-Childrens-Center/#College_success',
                         'profile': '/california/san-jose/22487-Santa-Teresa-Childrens-Center/',
                         'reviews': '/california/san-jose/22487-Santa-Teresa-Childrens-Center/#Reviews'},
                     'lon': -121.82888,
                     'name': "Santa Teresa Children's Center",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6200 Encinal Dr',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22488,
                     'lat': 37.234734,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22488-Santa-Teresa-State-Pre-School/#College_success',
                         'profile': '/california/san-jose/22488-Santa-Teresa-State-Pre-School/',
                         'reviews': '/california/san-jose/22488-Santa-Teresa-State-Pre-School/#Reviews'},
                     'lon': -121.795975,
                     'name': 'Santa Teresa State Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6350 Rainbow Dr',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22489,
                     'lat': 37.300571,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22489-Building-Block/#College_success',
                               'profile': '/california/san-jose/22489-Building-Block/',
                               'reviews': '/california/san-jose/22489-Building-Block/#Reviews'},
                     'lon': -122.016716,
                     'name': 'Building Block',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '637 Calero Ave',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22490,
                     'lat': 37.242455,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22490-Bright-Beginnings-Preschool--Day-Care-Center/#College_success',
                         'profile': '/california/san-jose/22490-Bright-Beginnings-Preschool--Day-Care-Center/',
                         'reviews': '/california/san-jose/22490-Bright-Beginnings-Preschool--Day-Care-Center/#Reviews'},
                     'lon': -121.837708,
                     'name': 'Bright Beginnings Preschool & Day Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '639 North 5th Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22491,
                     'lat': 37.349598,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22491-Lotus-Pre-School/#College_success',
                               'profile': '/california/san-jose/22491-Lotus-Pre-School/',
                               'reviews': '/california/san-jose/22491-Lotus-Pre-School/#Reviews'},
                     'lon': -121.895287,
                     'name': 'Lotus Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '65 Gordon Ave',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22492,
                     'lat': 37.379387,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22492-Linda-Vista-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22492-Linda-Vista-Child-Development-Center/',
                         'reviews': '/california/san-jose/22492-Linda-Vista-Child-Development-Center/#Reviews'},
                     'lon': -121.821442,
                     'name': 'Linda Vista Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive #325',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22493,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22493-Mckinley-Head-Start/#College_success',
                               'profile': '/california/san-jose/22493-Mckinley-Head-Start/',
                               'reviews': '/california/san-jose/22493-Mckinley-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Mckinley Head Start',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6601 Camden Avenue',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 22494,
                     'lat': 37.222172,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22494-Cornerstone-Pre-School/#College_success',
                               'profile': '/california/san-jose/22494-Cornerstone-Pre-School/',
                               'reviews': '/california/san-jose/22494-Cornerstone-Pre-School/#Reviews'},
                     'lon': -121.855721,
                     'name': 'Cornerstone Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '701 Vine St',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22495,
                     'lat': 37.321789,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22495-Head-Start-Center/#College_success',
                               'profile': '/california/san-jose/22495-Head-Start-Center/',
                               'reviews': '/california/san-jose/22495-Head-Start-Center/#Reviews'},
                     'lon': -121.886414,
                     'name': 'Head Start Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22497,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22497-Ymca-Day-Care-Allen/#College_success',
                               'profile': '/california/san-jose/22497-Ymca-Day-Care-Allen/',
                               'reviews': '/california/san-jose/22497-Ymca-Day-Care-Allen/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Day Care-Allen',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '897 Broadleaf Ln',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22498,
                     'lat': 37.332085,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22498-Cory-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22498-Cory-Child-Development-Center/',
                         'reviews': '/california/san-jose/22498-Cory-Child-Development-Center/#Reviews'},
                     'lon': -121.941444,
                     'name': 'Cory Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '935 Piedmont Rd',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22499,
                     'lat': 37.390675,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22499-Catapillar-To-Butterflies-DC/#College_success',
                         'profile': '/california/san-jose/22499-Catapillar-To-Butterflies-DC/',
                         'reviews': '/california/san-jose/22499-Catapillar-To-Butterflies-DC/#Reviews'},
                     'lon': -121.842552,
                     'name': 'Catapillar To Butterflies DC',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #26',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22500,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22500-Toyon-Partners-Cdc/#College_success',
                               'profile': '/california/san-jose/22500-Toyon-Partners-Cdc/',
                               'reviews': '/california/san-jose/22500-Toyon-Partners-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Toyon Partners Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22655,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/22655-Guin-Foss-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/22655-Guin-Foss-Child-Development-Center/',
                         'reviews': '/california/san-jose/22655-Guin-Foss-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Guin Foss Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4977 Dent Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 22895,
                     'lat': 37.252312,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/22895-Challenger-Pre-School-2/#College_success',
                               'profile': '/california/san-jose/22895-Challenger-Pre-School-2/',
                               'reviews': '/california/san-jose/22895-Challenger-Pre-School-2/#Reviews'},
                     'lon': -121.90097,
                     'name': 'Challenger Pre-School 2',
                     'numReviews': 5,
                     'parentRating': 2,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 23146,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/23146-Vargas-Child-Development/#College_success',
                               'profile': '/california/san-jose/23146-Vargas-Child-Development/',
                               'reviews': '/california/san-jose/23146-Vargas-Child-Development/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Vargas Child Development',
                     'numReviews': 1,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1175 Johnson Avenue',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 23148,
                     'lat': 37.303787,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/23148-New-Concept-School/#College_success',
                               'profile': '/california/san-jose/23148-New-Concept-School/',
                               'reviews': '/california/san-jose/23148-New-Concept-School/#Reviews'},
                     'lon': -122.005783,
                     'name': 'New Concept School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 23158,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/23158-Bishop-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/23158-Bishop-Child-Development-Center/',
                         'reviews': '/california/san-jose/23158-Bishop-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Bishop Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 23172,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/23172-Cumberland-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/23172-Cumberland-Child-Development-Center/',
                         'reviews': '/california/san-jose/23172-Cumberland-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Cumberland Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 23270,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/23270-Wanda-Hirsch-State-Pre-Sch-Ccc/#College_success',
                         'profile': '/california/san-jose/23270-Wanda-Hirsch-State-Pre-Sch-Ccc/',
                         'reviews': '/california/san-jose/23270-Wanda-Hirsch-State-Pre-Sch-Ccc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Wanda Hirsch State Pre-Sch/Ccc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 23283,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/23283-George-Kelly-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/23283-George-Kelly-Child-Development-Center/',
                         'reviews': '/california/san-jose/23283-George-Kelly-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'George Kelly Child Development Center',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3124 Williamsburg Drive',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 23316,
                     'lat': 37.297523,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/23316-Special-Place/#College_success',
                               'profile': '/california/san-jose/23316-Special-Place/',
                               'reviews': '/california/san-jose/23316-Special-Place/#Reviews'},
                     'lon': -121.950851,
                     'name': 'Special Place',
                     'numReviews': 1,
                     'parentRating': 1,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 23329,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/23329-W-R-Nelson-School-Age-Cdc/#College_success',
                         'profile': '/california/san-jose/23329-W-R-Nelson-School-Age-Cdc/',
                         'reviews': '/california/san-jose/23329-W-R-Nelson-School-Age-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'W R Nelson School Age Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2927 Hostetter Road',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-12',
                     'highlighted': false,
                     'id': 24016,
                     'lat': 37.401806,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/24016-Berryessa-Academy/#College_success',
                               'profile': '/california/san-jose/24016-Berryessa-Academy/',
                               'reviews': '/california/san-jose/24016-Berryessa-Academy/#Reviews'},
                     'lon': -121.863785,
                     'name': 'Berryessa Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1050 Park Ave',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 36,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 51},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 42},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Hispanic', 'percentage': 2}],
                     'gradeLevels': 'PK-5',
                     'highlighted': false,
                     'id': 24023,
                     'lat': 37.325844,
                     'levelCode': 'p,e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/24023-Njeris-Morning-Glory-School-And-Art-Center/#College_success',
                         'profile': '/california/san-jose/24023-Njeris-Morning-Glory-School-And-Art-Center/',
                         'reviews': '/california/san-jose/24023-Njeris-Morning-Glory-School-And-Art-Center/#Reviews'},
                     'lon': -121.909683,
                     'name': "Njeri's Morning Glory School and Art Center",
                     'numReviews': 8,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4660 Eastus Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 35,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-1',
                     'highlighted': false,
                     'id': 24030,
                     'lat': 37.305847,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/24030-Trust-Primary-School/#College_success',
                               'profile': '/california/san-jose/24030-Trust-Primary-School/',
                               'reviews': '/california/san-jose/24030-Trust-Primary-School/#Reviews'},
                     'lon': -121.986671,
                     'name': 'Trust Primary School',
                     'numReviews': 13,
                     'parentRating': 2,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4250 Latimer Avenue',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-5',
                     'highlighted': false,
                     'id': 24064,
                     'lat': 37.292377,
                     'levelCode': 'p,e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/24064-Premier-International-Language-Academy/#College_success',
                         'profile': '/california/san-jose/24064-Premier-International-Language-Academy/',
                         'reviews': '/california/san-jose/24064-Premier-International-Language-Academy/#Reviews'},
                     'lon': -121.977974,
                     'name': 'Premier International Language Academy',
                     'numReviews': 13,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3001 Ross Avenue',
                                 'street2': 'Room 1',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 5,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-4',
                     'highlighted': false,
                     'id': 24213,
                     'lat': 37.266651,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/24213-Chrysallis-Elementary-School/#College_success',
                         'profile': '/california/san-jose/24213-Chrysallis-Elementary-School/',
                         'reviews': '/california/san-jose/24213-Chrysallis-Elementary-School/#Reviews'},
                     'lon': -121.920532,
                     'name': 'Chrysallis Elementary School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3800 Blackford Ave',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 24280,
                     'lat': 37.31229,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/24280-The-Harker-School--Middle-School/#College_success',
                         'profile': '/california/san-jose/24280-The-Harker-School--Middle-School/',
                         'reviews': '/california/san-jose/24280-The-Harker-School--Middle-School/#Reviews'},
                     'lon': -121.968391,
                     'name': 'The Harker School | Middle School',
                     'numReviews': 21,
                     'parentRating': 4,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1816 Tully Rd',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 24336,
                     'lat': 37.323029,
                     'levelCode': 'p,e,m',
                     'links': {'collegeSuccess': '/california/san-jose/24336-Kumon-Of-East-Hills/#College_success',
                               'profile': '/california/san-jose/24336-Kumon-Of-East-Hills/',
                               'reviews': '/california/san-jose/24336-Kumon-Of-East-Hills/#Reviews'},
                     'lon': -121.824783,
                     'name': 'Kumon of East Hills',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '880 Hillsdale Ave',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 23,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 29},
                                       {'label': 'Two or more races', 'percentage': 29},
                                       {'label': 'Hispanic', 'percentage': 29},
                                       {'label': 'Asian', 'percentage': 14}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 24438,
                     'lat': 37.278351,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/24438-Over-The-Rainbow-Montessori-School/#College_success',
                         'profile': '/california/san-jose/24438-Over-The-Rainbow-Montessori-School/',
                         'reviews': '/california/san-jose/24438-Over-The-Rainbow-Montessori-School/#Reviews'},
                     'lon': -121.870628,
                     'name': 'Over the Rainbow Montessori School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': 'Po Box 5123',
                                 'street2': '',
                                 'zip': '95150'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 48,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 50},
                                       {'label': 'Two or more races', 'percentage': 21},
                                       {'label': 'Hispanic', 'percentage': 21},
                                       {'label': 'White', 'percentage': 9}],
                     'gradeLevels': 'PK-4',
                     'highlighted': false,
                     'id': 30735,
                     'lat': 37.389999,
                     'levelCode': 'p,e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/30735-Premier-International-Language-Academy/#College_success',
                         'profile': '/california/san-jose/30735-Premier-International-Language-Academy/',
                         'reviews': '/california/san-jose/30735-Premier-International-Language-Academy/#Reviews'},
                     'lon': -121.900002,
                     'name': 'Premier International Language Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '625 South Sunset Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 301,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 92,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 98,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 24681,
                     'lat': 37.348602,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/24681-Ace-Charter-School/#College_success',
                               'profile': '/california/san-jose/24681-Ace-Charter-School/',
                               'reviews': '/california/san-jose/24681-Ace-Charter-School/#Reviews'},
                     'lon': -121.844597,
                     'name': 'Ace Charter School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '92%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 34,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1790 Educational Park Drive',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '82%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 530,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 69,
                                        'rating': 9},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 22, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 73,
                                        'rating': 7},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 24687,
                     'lat': 37.368244,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/24687-KIPP-San-Jose-Collegiate/#College_success',
                               'profile': '/california/san-jose/24687-KIPP-San-Jose-Collegiate/',
                               'reviews': '/california/san-jose/24687-KIPP-San-Jose-Collegiate/#Reviews'},
                     'lon': -121.860077,
                     'name': 'KIPP San Jose Collegiate',
                     'numReviews': 11,
                     'parentRating': 4,
                     'percentLowIncome': '69%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'College Readiness Rating': 8,
                                    'Equity Overview Rating': 8,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1010 University Ave ',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 24970,
                     'lat': 37.340294,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/24970-Temple-Emanu-El-Preschool-/#College_success',
                         'profile': '/california/san-jose/24970-Temple-Emanu-El-Preschool-/',
                         'reviews': '/california/san-jose/24970-Temple-Emanu-El-Preschool-/#Reviews'},
                     'lon': -121.920425,
                     'name': 'Temple Emanu El Preschool ',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2249 Dobern Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 430,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 86,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 84,
                                        'rating': 5},
                                       {'label': 'Asian', 'percentage': 9},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 25172,
                     'lat': 37.352333,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/25172-Rocketship-Si-Se-Puede-Academy/#College_success',
                         'profile': '/california/san-jose/25172-Rocketship-Si-Se-Puede-Academy/',
                         'reviews': '/california/san-jose/25172-Rocketship-Si-Se-Puede-Academy/#Reviews'},
                     'lon': -121.83979,
                     'name': 'Rocketship Si Se Puede Academy',
                     'numReviews': 16,
                     'parentRating': 3,
                     'percentLowIncome': '86%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 33,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1598 Lucretia Ave',
                                 'street2': 'Portable P-6',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 543,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 69,
                                        'rating': 9},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 54, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 31,
                                        'rating': 7},
                                       {'label': 'Filipino', 'percentage': 6},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 25268,
                     'lat': 37.32563,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/25268-Cornerstone-Academy-Preparatory-School/#College_success',
                         'profile': '/california/san-jose/25268-Cornerstone-Academy-Preparatory-School/',
                         'reviews': '/california/san-jose/25268-Cornerstone-Academy-Preparatory-School/#Reviews'},
                     'lon': -121.853027,
                     'name': 'Cornerstone Academy Preparatory School',
                     'numReviews': 97,
                     'parentRating': 4,
                     'percentLowIncome': '69%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 9,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Almaden Expressway',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 75,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian', 'percentage': 67},
                                       {'label': 'White', 'percentage': 27},
                                       {'label': 'Hispanic', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 3}],
                     'gradeLevels': '6-12',
                     'highlighted': false,
                     'id': 25293,
                     'lat': 37.26469,
                     'levelCode': 'm,h',
                     'links': {'collegeSuccess': '/california/san-jose/25293-Cambrian-Academy/#College_success',
                               'profile': '/california/san-jose/25293-Cambrian-Academy/',
                               'reviews': '/california/san-jose/25293-Cambrian-Academy/#Reviews'},
                     'lon': -121.876854,
                     'name': 'Cambrian Academy',
                     'numReviews': 19,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '331 South 34th Street',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 454,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 90,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 87,
                                        'rating': 5},
                                       {'label': 'Asian', 'percentage': 7},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 25326,
                     'lat': 37.344757,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/25326-Rocketship-Los-Suenos-Academy/#College_success',
                         'profile': '/california/san-jose/25326-Rocketship-Los-Suenos-Academy/',
                         'reviews': '/california/san-jose/25326-Rocketship-Los-Suenos-Academy/#Reviews'},
                     'lon': -121.852119,
                     'name': 'Rocketship Los Suenos Academy',
                     'numReviews': 3,
                     'parentRating': 4,
                     'percentLowIncome': '90%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 36,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6436 Bose Lane',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 25482,
                     'lat': 37.222965,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/25482-Over-The-Rainbow-Montessori-School/#College_success',
                         'profile': '/california/san-jose/25482-Over-The-Rainbow-Montessori-School/',
                         'reviews': '/california/san-jose/25482-Over-The-Rainbow-Montessori-School/#Reviews'},
                     'lon': -121.867332,
                     'name': 'Over the Rainbow Montessori School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1750 South White Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '78%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 362,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 46,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 17, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 59,
                                        'rating': 4},
                                       {'label': 'Two or more races', 'percentage': 11},
                                       {'label': 'White', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 25616,
                     'lat': 37.346161,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/25616-Summit-Public-School-Rainier/#College_success',
                         'profile': '/california/san-jose/25616-Summit-Public-School-Rainier/',
                         'reviews': '/california/san-jose/25616-Summit-Public-School-Rainier/#Reviews'},
                     'lon': -121.808266,
                     'name': 'Summit Public School: Rainier',
                     'numReviews': 76,
                     'parentRating': 4,
                     'percentLowIncome': '46%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'College Readiness Rating': 5,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '285 Blossom Hill Road',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '88%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 379,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 35,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 44,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 22, 'rating': 10},
                                       {'label': 'Two or more races', 'percentage': 13},
                                       {'label': 'Asian', 'percentage': 12},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 25620,
                     'lat': 37.252796,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/25620-Summit-Public-School-Tahoma/#College_success',
                         'profile': '/california/san-jose/25620-Summit-Public-School-Tahoma/',
                         'reviews': '/california/san-jose/25620-Summit-Public-School-Tahoma/#Reviews'},
                     'lon': -121.819572,
                     'name': 'Summit Public School: Tahoma',
                     'numReviews': 145,
                     'parentRating': 4,
                     'percentLowIncome': '35%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 17,
                     'subratings': {'College Readiness Rating': 6,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1776 Educational Park Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '67%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 668,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 75,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 89,
                                        'rating': 3},
                                       {'label': 'Asian', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '6-12',
                     'highlighted': false,
                     'id': 25621,
                     'lat': 37.366749,
                     'levelCode': 'm,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/25621-Downtown-College-Prep---Alum-Rock-School/#College_success',
                         'profile': '/california/san-jose/25621-Downtown-College-Prep---Alum-Rock-School/',
                         'reviews': '/california/san-jose/25621-Downtown-College-Prep---Alum-Rock-School/#Reviews'},
                     'lon': -121.85791,
                     'name': 'Downtown College Prep - Alum Rock School',
                     'numReviews': 22,
                     'parentRating': 3,
                     'percentLowIncome': '75%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 9,
                     'subratings': {'Academic Progress Rating': 6,
                                    'College Readiness Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3924 Picardy Place Court',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 7,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '1-12',
                     'highlighted': false,
                     'id': 26335,
                     'lat': 37.297325,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/26335-Alim-Academy/#College_success',
                               'profile': '/california/san-jose/26335-Alim-Academy/',
                               'reviews': '/california/san-jose/26335-Alim-Academy/#Reviews'},
                     'lon': -121.804337,
                     'name': 'Alim Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4250 Latimer Avenue',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 22,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-10',
                     'highlighted': false,
                     'id': 26337,
                     'lat': 37.292393,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/26337-As-Safa-Institute-As-Safa-Academy/#College_success',
                         'profile': '/california/san-jose/26337-As-Safa-Institute-As-Safa-Academy/',
                         'reviews': '/california/san-jose/26337-As-Safa-Institute-As-Safa-Academy/#Reviews'},
                     'lon': -121.978035,
                     'name': 'As-Safa Institute/As-Safa Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5670 Camden Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 46,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Hispanic', 'percentage': 43},
                                       {'label': 'White', 'percentage': 33},
                                       {'label': 'African American', 'percentage': 22},
                                       {'label': 'Asian', 'percentage': 2}],
                     'gradeLevels': '4-12',
                     'highlighted': false,
                     'id': 26338,
                     'lat': 37.235077,
                     'levelCode': 'e,m,h',
                     'links': {'collegeSuccess': '/california/san-jose/26338-Beacon-School/#College_success',
                               'profile': '/california/san-jose/26338-Beacon-School/',
                               'reviews': '/california/san-jose/26338-Beacon-School/#Reviews'},
                     'lon': -121.90197,
                     'name': 'Beacon School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1175 Hillsdale Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 98,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 26340,
                     'lat': 37.275219,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/26340-Calvary-Christian-Academy/#College_success',
                         'profile': '/california/san-jose/26340-Calvary-Christian-Academy/',
                         'reviews': '/california/san-jose/26340-Calvary-Christian-Academy/#Reviews'},
                     'lon': -121.881035,
                     'name': 'Calvary Christian Academy',
                     'numReviews': 1,
                     'parentRating': 1,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6601 Camden Avenue',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K',
                     'highlighted': false,
                     'id': 26345,
                     'lat': 37.22274,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/26345-Cornerstone-Kindergarten/#College_success',
                               'profile': '/california/san-jose/26345-Cornerstone-Kindergarten/',
                               'reviews': '/california/san-jose/26345-Cornerstone-Kindergarten/#Reviews'},
                     'lon': -121.855461,
                     'name': 'Cornerstone Kindergarten',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '257 South Claremont Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 18,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 26347,
                     'lat': 37.372074,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/26347-Foothills-Christian-Academy/#College_success',
                         'profile': '/california/san-jose/26347-Foothills-Christian-Academy/',
                         'reviews': '/california/san-jose/26347-Foothills-Christian-Academy/#Reviews'},
                     'lon': -121.815819,
                     'name': 'Foothills Christian Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4547 Blackford Avenue',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '1-8',
                     'highlighted': false,
                     'id': 26349,
                     'lat': 37.314644,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/26349-Golden-State-Academy/#College_success',
                               'profile': '/california/san-jose/26349-Golden-State-Academy/',
                               'reviews': '/california/san-jose/26349-Golden-State-Academy/#Reviews'},
                     'lon': -121.981689,
                     'name': 'Golden State Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '420 Allegan Circle',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 187,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'White', 'percentage': 40},
                                       {'label': 'Asian', 'percentage': 28},
                                       {'label': 'Hispanic', 'percentage': 19},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 5},
                                       {'label': 'African American', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 26355,
                     'lat': 37.233238,
                     'levelCode': 'p,e,m',
                     'links': {'collegeSuccess': '/california/san-jose/26355-Legacy-Christian-School/#College_success',
                               'profile': '/california/san-jose/26355-Legacy-Christian-School/',
                               'reviews': '/california/san-jose/26355-Legacy-Christian-School/#Reviews'},
                     'lon': -121.815346,
                     'name': 'Legacy Christian School',
                     'numReviews': 15,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4415 Fortran Court',
                                 'street2': '',
                                 'zip': '95134'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 101,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Two or more races', 'percentage': 86},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Hispanic', 'percentage': 4},
                                       {'label': 'Asian', 'percentage': 3},
                                       {'label': 'White', 'percentage': 3}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 26363,
                     'lat': 37.421047,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/26363-Saba-Academy/#College_success',
                               'profile': '/california/san-jose/26363-Saba-Academy/',
                               'reviews': '/california/san-jose/26363-Saba-Academy/#Reviews'},
                     'lon': -121.958145,
                     'name': 'Saba Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '405 South 10th Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 26451,
                     'lat': 37.334038,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/26451-Nuestra-Escuelita/#College_success',
                               'profile': '/california/san-jose/26451-Nuestra-Escuelita/',
                               'reviews': '/california/san-jose/26451-Nuestra-Escuelita/#Reviews'},
                     'lon': -121.87674,
                     'name': 'Nuestra Escuelita',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '102 Sonora Ave.',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 26462,
                     'lat': 37.363403,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/26462-Pasitos-School/#College_success',
                               'profile': '/california/san-jose/26462-Pasitos-School/',
                               'reviews': '/california/san-jose/26462-Pasitos-School/#Reviews'},
                     'lon': -121.912872,
                     'name': 'Pasitos School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2960 Story Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 586,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 74,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 14, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 79,
                                        'rating': 4},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 27104,
                     'lat': 37.35577,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27104-Rocketship-Brilliant-Minds/#College_success',
                         'profile': '/california/san-jose/27104-Rocketship-Brilliant-Minds/',
                         'reviews': '/california/san-jose/27104-Rocketship-Brilliant-Minds/#Reviews'},
                     'lon': -121.819679,
                     'name': 'Rocketship Brilliant Minds',
                     'numReviews': 31,
                     'parentRating': 4,
                     'percentLowIncome': '74%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 33,
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '198 West Alma Avenue',
                                 'street2': '',
                                 'zip': '95110'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 522,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 84,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 84,
                                        'rating': 8},
                                       {'label': 'African American', 'percentage': 7},
                                       {'label': 'Asian', 'percentage': 5},
                                       {'label': 'White', 'percentage': 3}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 27105,
                     'lat': 37.313431,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/27105-Rocketship-Alma-Academy/#College_success',
                               'profile': '/california/san-jose/27105-Rocketship-Alma-Academy/',
                               'reviews': '/california/san-jose/27105-Rocketship-Alma-Academy/#Reviews'},
                     'lon': -121.87767,
                     'name': 'Rocketship Alma Academy',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '84%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 36,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 9,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1601 Cunningham Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 290,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 88,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 69,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 21, 'rating': 4},
                                       {'label': 'Asian', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 27106,
                     'lat': 37.326569,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27106-Alpha-Blanca-Alvarado-Middle-School/#College_success',
                         'profile': '/california/san-jose/27106-Alpha-Blanca-Alvarado-Middle-School/',
                         'reviews': '/california/san-jose/27106-Alpha-Blanca-Alvarado-Middle-School/#Reviews'},
                     'lon': -121.83506,
                     'name': 'Alpha: Blanca Alvarado',
                     'numReviews': 7,
                     'parentRating': 4,
                     'percentLowIncome': '88%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 7,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2050 Kammerer Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 278,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 86,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 12, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 79,
                                        'rating': 4},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 27107,
                     'lat': 37.350964,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/27107-Renaissance-At-Mathson/#College_success',
                               'profile': '/california/san-jose/27107-Renaissance-At-Mathson/',
                               'reviews': '/california/san-jose/27107-Renaissance-At-Mathson/#Reviews'},
                     'lon': -121.844009,
                     'name': 'Renaissance At Mathson',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '86%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1776 Educational Park Drive',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '71%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 363,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 76,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 97,
                                        'rating': 5},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Asian', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 27108,
                     'lat': 37.366749,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/27108-Ace-Charter-High/#College_success',
                               'profile': '/california/san-jose/27108-Ace-Charter-High/',
                               'reviews': '/california/san-jose/27108-Ace-Charter-High/#Reviews'},
                     'lon': -121.85791,
                     'name': 'Ace Charter High',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '76%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'College Readiness Rating': 1,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '667 North First Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27138,
                     'lat': 37.348202,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27138-Downtown-Scholars-Child-Development-Center-Inc./#College_success',
                         'profile': '/california/san-jose/27138-Downtown-Scholars-Child-Development-Center-Inc./',
                         'reviews': '/california/san-jose/27138-Downtown-Scholars-Child-Development-Center-Inc./#Reviews'},
                     'lon': -121.899773,
                     'name': 'Downtown Scholars Child Development Center, Inc.',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '625 Education Park Drive',
                                 'street2': null,
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'Ungraded',
                     'highlighted': false,
                     'id': 27160,
                     'lat': 37.365639,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27160-Adult-Education-Eastside-Union/#College_success',
                         'profile': '/california/san-jose/27160-Adult-Education-Eastside-Union/',
                         'reviews': '/california/san-jose/27160-Adult-Education-Eastside-Union/#Reviews'},
                     'lon': -121.859062,
                     'name': 'Adult Education Eastside Union',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1115 Kimberly Dr',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27172,
                     'lat': 37.271111,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27172-ABC-Learning-Montessori/#College_success',
                               'profile': '/california/san-jose/27172-ABC-Learning-Montessori/',
                               'reviews': '/california/san-jose/27172-ABC-Learning-Montessori/#Reviews'},
                     'lon': -121.87944,
                     'name': 'ABC Learning Montessori',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '762 Sunset Glen Drive',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 1116,
                     'districtName': 'Santa Clara County Office Of Education School District',
                     'enrollment': 584,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 8},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 18, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 21,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 41, 'rating': 8},
                                       {'label': 'Two or more races',
                                        'percentage': 15,
                                        'rating': 8},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 27183,
                     'lat': 37.236156,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/27183-Discovery-Charter-2/#College_success',
                               'profile': '/california/san-jose/27183-Discovery-Charter-2/',
                               'reviews': '/california/san-jose/27183-Discovery-Charter-2/#Reviews'},
                     'lon': -121.848419,
                     'name': 'Discovery Charter 2',
                     'numReviews': 103,
                     'parentRating': 4,
                     'percentLowIncome': '8%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 7,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1781 Bird Ave',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27196,
                     'lat': 37.301125,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27196-Cielo-Montessori/#College_success',
                               'profile': '/california/san-jose/27196-Cielo-Montessori/',
                               'reviews': '/california/san-jose/27196-Cielo-Montessori/#Reviews'},
                     'lon': -121.88562,
                     'name': 'Cielo Montessori',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4250 Latimer Avenue',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 27218,
                     'lat': 37.292393,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27218-Silicon-Valley-International-School/#College_success',
                         'profile': '/california/san-jose/27218-Silicon-Valley-International-School/',
                         'reviews': '/california/san-jose/27218-Silicon-Valley-International-School/#Reviews'},
                     'lon': -121.978035,
                     'name': 'Silicon Valley International School',
                     'numReviews': 2,
                     'parentRating': 5,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Parkmoor Ave',
                                 'street2': '',
                                 'zip': '95216'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': '5-12',
                     'highlighted': false,
                     'id': 27225,
                     'lat': 37.316143,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27225-BASIS-Independent-Silicon-Valley/#College_success',
                         'profile': '/california/san-jose/27225-BASIS-Independent-Silicon-Valley/',
                         'reviews': '/california/san-jose/27225-BASIS-Independent-Silicon-Valley/#Reviews'},
                     'lon': -121.910065,
                     'name': 'BASIS Independent Silicon Valley',
                     'numReviews': 167,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '683 Sylvandale Avenue',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 608,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 66,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 38, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 51,
                                        'rating': 6},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 27236,
                     'lat': 37.287979,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/27236-Rocketship-Spark-Academy/#College_success',
                               'profile': '/california/san-jose/27236-Rocketship-Spark-Academy/',
                               'reviews': '/california/san-jose/27236-Rocketship-Spark-Academy/#Reviews'},
                     'lon': -121.822144,
                     'name': 'Rocketship Spark Academy',
                     'numReviews': 10,
                     'parentRating': 5,
                     'percentLowIncome': '66%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 34,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 8,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4977 Dent Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27241,
                     'lat': 37.252312,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27241-Creative-Minds-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/27241-Creative-Minds-Child-Development-Center/',
                         'reviews': '/california/san-jose/27241-Creative-Minds-Child-Development-Center/#Reviews'},
                     'lon': -121.90097,
                     'name': 'Creative Minds Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Ridder Park Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 28812,
                     'lat': 37.383732,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/28812-Kr-Smith-Head-Start/#College_success',
                               'profile': '/california/san-jose/28812-Kr-Smith-Head-Start/',
                               'reviews': '/california/san-jose/28812-Kr-Smith-Head-Start/#Reviews'},
                     'lon': -121.900146,
                     'name': 'Kr Smith Head Start',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27345,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27345-North-Country-Cdc/#College_success',
                               'profile': '/california/san-jose/27345-North-Country-Cdc/',
                               'reviews': '/california/san-jose/27345-North-Country-Cdc/#Reviews'},
                     'lon': -121.978966,
                     'name': 'North Country Cdc',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1400 Parkmoor Avenue #220',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27390,
                     'lat': 37.316017,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27390-Slonaker-Extended-Day-Care-2/#College_success',
                         'profile': '/california/san-jose/27390-Slonaker-Extended-Day-Care-2/',
                         'reviews': '/california/san-jose/27390-Slonaker-Extended-Day-Care-2/#Reviews'},
                     'lon': -121.912704,
                     'name': 'Slonaker Extended Day Care 2',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5459 Entrada Cedros',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27395,
                     'lat': 37.254196,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27395-Over-Rainbow-Montessori/#College_success',
                               'profile': '/california/san-jose/27395-Over-Rainbow-Montessori/',
                               'reviews': '/california/san-jose/27395-Over-Rainbow-Montessori/#Reviews'},
                     'lon': -121.836082,
                     'name': 'Over Rainbow Montessori',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1081 Foxworthy Avenue',
                                 'street2': '',
                                 'zip': '95118'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27453,
                     'lat': 37.278683,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27453-Kinder-Care-Learning-Center-462/#College_success',
                         'profile': '/california/san-jose/27453-Kinder-Care-Learning-Center-462/',
                         'reviews': '/california/san-jose/27453-Kinder-Care-Learning-Center-462/#Reviews'},
                     'lon': -121.878746,
                     'name': 'Kinder Care Learning Center #462',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1425 Lincoln Avenue',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27454,
                     'lat': 37.303371,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27454-Willow-Glen-Community-Ext-Day-Prog/#College_success',
                         'profile': '/california/san-jose/27454-Willow-Glen-Community-Ext-Day-Prog/',
                         'reviews': '/california/san-jose/27454-Willow-Glen-Community-Ext-Day-Prog/#Reviews'},
                     'lon': -121.897324,
                     'name': 'Willow Glen Community Ext Day Prog',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '121 South White Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27504,
                     'lat': 37.363899,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27504-Gloria-Dei-Early-Learning-Center/#College_success',
                         'profile': '/california/san-jose/27504-Gloria-Dei-Early-Learning-Center/',
                         'reviews': '/california/san-jose/27504-Gloria-Dei-Early-Learning-Center/#Reviews'},
                     'lon': -121.826866,
                     'name': 'Gloria Dei Early Learning Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4160 Senter Road',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27505,
                     'lat': 37.280861,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27505-Child-Kingdom-Pre-School/#College_success',
                               'profile': '/california/san-jose/27505-Child-Kingdom-Pre-School/',
                               'reviews': '/california/san-jose/27505-Child-Kingdom-Pre-School/#Reviews'},
                     'lon': -121.82296,
                     'name': 'Child Kingdom Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5669 Snell Avenue #284',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27586,
                     'lat': 37.250622,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27586-Champions-Child-Care-Center-Lietz/#College_success',
                         'profile': '/california/san-jose/27586-Champions-Child-Care-Center-Lietz/',
                         'reviews': '/california/san-jose/27586-Champions-Child-Care-Center-Lietz/#Reviews'},
                     'lon': -121.831322,
                     'name': 'Champions Child Care Center-Lietz',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5669 Snell Avenue #284',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27587,
                     'lat': 37.250622,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27587-Champions-Child-Care-Center-Alta-Vista/#College_success',
                         'profile': '/california/san-jose/27587-Champions-Child-Care-Center-Alta-Vista/',
                         'reviews': '/california/san-jose/27587-Champions-Child-Care-Center-Alta-Vista/#Reviews'},
                     'lon': -121.831322,
                     'name': 'Champions Child Care Center-Alta Vista',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27596,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27596-Dan-O-Root-Day-Care-Ii/#College_success',
                               'profile': '/california/san-jose/27596-Dan-O-Root-Day-Care-Ii/',
                               'reviews': '/california/san-jose/27596-Dan-O-Root-Day-Care-Ii/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Dan O Root Day Care Ii',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4603 Pacific Rim Way',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27597,
                     'lat': 37.297482,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27597-Spring-Source-Education-Child-Care-Center/#College_success',
                         'profile': '/california/san-jose/27597-Spring-Source-Education-Child-Care-Center/',
                         'reviews': '/california/san-jose/27597-Spring-Source-Education-Child-Care-Center/#Reviews'},
                     'lon': -121.777405,
                     'name': 'Spring Source Education Child Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '590 Shawnee Lane',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27638,
                     'lat': 37.241238,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27638-Tiny-Treasures-Day-Care/#College_success',
                               'profile': '/california/san-jose/27638-Tiny-Treasures-Day-Care/',
                               'reviews': '/california/san-jose/27638-Tiny-Treasures-Day-Care/#Reviews'},
                     'lon': -121.834763,
                     'name': 'Tiny Treasures Day Care',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2750 Yerba Buena Road',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27683,
                     'lat': 37.297138,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27683-Evergreen-Valley-Christian-Ps/#College_success',
                         'profile': '/california/san-jose/27683-Evergreen-Valley-Christian-Ps/',
                         'reviews': '/california/san-jose/27683-Evergreen-Valley-Christian-Ps/#Reviews'},
                     'lon': -121.772621,
                     'name': 'Evergreen Valley Christian Ps',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5301 McKee Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27740,
                     'lat': 37.382565,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27740-Foothill-Christian-Pre-School/#College_success',
                         'profile': '/california/san-jose/27740-Foothill-Christian-Pre-School/',
                         'reviews': '/california/san-jose/27740-Foothill-Christian-Pre-School/#Reviews'},
                     'lon': -121.825737,
                     'name': 'Foothill Christian Pre-School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '81 North 9th Street',
                                 'street2': '',
                                 'zip': '95112'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27799,
                     'lat': 37.341335,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27799-St-Patricks-Day-Care-Center/#College_success',
                         'profile': '/california/san-jose/27799-St-Patricks-Day-Care-Center/',
                         'reviews': '/california/san-jose/27799-St-Patricks-Day-Care-Center/#Reviews'},
                     'lon': -121.882957,
                     'name': "St Patrick's Day Care Center",
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27857,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27857-Henry-Ford-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/27857-Henry-Ford-Child-Development-Center/',
                         'reviews': '/california/san-jose/27857-Henry-Ford-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Henry Ford Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27863,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27863-Collins-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/27863-Collins-Child-Development-Center/',
                         'reviews': '/california/san-jose/27863-Collins-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Collins Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #26',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27865,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27865-Garden-Gate-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/27865-Garden-Gate-Child-Development-Center/',
                         'reviews': '/california/san-jose/27865-Garden-Gate-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Garden Gate Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27867,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27867-Ymca-School-Age-Cc-Stevens-Creek/#College_success',
                         'profile': '/california/san-jose/27867-Ymca-School-Age-Cc-Stevens-Creek/',
                         'reviews': '/california/san-jose/27867-Ymca-School-Age-Cc-Stevens-Creek/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca School Age Cc-Stevens Creek',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27869,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27869-Ymca-Kids-Place-Bubb/#College_success',
                               'profile': '/california/san-jose/27869-Ymca-Kids-Place-Bubb/',
                               'reviews': '/california/san-jose/27869-Ymca-Kids-Place-Bubb/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Kids Place-Bubb',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27870,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27870-Ymca-Kids-Place-Castro/#College_success',
                               'profile': '/california/san-jose/27870-Ymca-Kids-Place-Castro/',
                               'reviews': '/california/san-jose/27870-Ymca-Kids-Place-Castro/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca Kids Place-Castro',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4450 McCoy Avenue',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27873,
                     'lat': 37.276016,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27873-Forest-Hill-Extended-Day/#College_success',
                               'profile': '/california/san-jose/27873-Forest-Hill-Extended-Day/',
                               'reviews': '/california/san-jose/27873-Forest-Hill-Extended-Day/#Reviews'},
                     'lon': -121.982513,
                     'name': 'Forest Hill Extended Day',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6982 Shearwater Drive',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27874,
                     'lat': 37.211559,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27874-Graystone-Chld-Development-Center/#College_success',
                         'profile': '/california/san-jose/27874-Graystone-Chld-Development-Center/',
                         'reviews': '/california/san-jose/27874-Graystone-Chld-Development-Center/#Reviews'},
                     'lon': -121.837326,
                     'name': 'Graystone Chld Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6130 Silberman Drive',
                                 'street2': '',
                                 'zip': '95120'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27875,
                     'lat': 37.227573,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27875-Los-Alamitos-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/27875-Los-Alamitos-Child-Development-Center/',
                         'reviews': '/california/san-jose/27875-Los-Alamitos-Child-Development-Center/#Reviews'},
                     'lon': -121.881142,
                     'name': 'Los Alamitos Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda Fl 3',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27876,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27876-Los-Paseos-Ymca-Sunshine-Co/#College_success',
                         'profile': '/california/san-jose/27876-Los-Paseos-Ymca-Sunshine-Co/',
                         'reviews': '/california/san-jose/27876-Los-Paseos-Ymca-Sunshine-Co/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Los Paseos Ymca Sunshine Co',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda Fl 3',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27877,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27877-Simonds-Ymca-Sunshine-Company/#College_success',
                         'profile': '/california/san-jose/27877-Simonds-Ymca-Sunshine-Company/',
                         'reviews': '/california/san-jose/27877-Simonds-Ymca-Sunshine-Company/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Simonds Ymca Sunshine Company',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda Fl 3',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27880,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27880-Reed-Ymca/#College_success',
                               'profile': '/california/san-jose/27880-Reed-Ymca/',
                               'reviews': '/california/san-jose/27880-Reed-Ymca/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Reed Ymca',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27881,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27881-Ymca-Hacienda-Valley-View/#College_success',
                         'profile': '/california/san-jose/27881-Ymca-Hacienda-Valley-View/',
                         'reviews': '/california/san-jose/27881-Ymca-Hacienda-Valley-View/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca-Hacienda Valley View',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27887,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27887-Eisenhower-Ymca-Sunshine-Co/#College_success',
                         'profile': '/california/san-jose/27887-Eisenhower-Ymca-Sunshine-Co/',
                         'reviews': '/california/san-jose/27887-Eisenhower-Ymca-Sunshine-Co/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Eisenhower Ymca Sunshine Co',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1922 The Alameda 3rd Fl',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27940,
                     'lat': 37.329014,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27940-Ymca-School-Age-Cc-Terrell/#College_success',
                         'profile': '/california/san-jose/27940-Ymca-School-Age-Cc-Terrell/',
                         'reviews': '/california/san-jose/27940-Ymca-School-Age-Cc-Terrell/#Reviews'},
                     'lon': -121.916023,
                     'name': 'Ymca-School Age Cc-Terrell',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1400 Parkmoor Avenue #220',
                                 'street2': '',
                                 'zip': '95126'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27947,
                     'lat': 37.316017,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27947-Noble-Day-Care-Center/#College_success',
                               'profile': '/california/san-jose/27947-Noble-Day-Care-Center/',
                               'reviews': '/california/san-jose/27947-Noble-Day-Care-Center/#Reviews'},
                     'lon': -121.912704,
                     'name': 'Noble Day Care Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27958,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27958-Cherry-Chase-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/27958-Cherry-Chase-Child-Development-Center/',
                         'reviews': '/california/san-jose/27958-Cherry-Chase-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Cherry Chase Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27963,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27963-John-Gill-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/27963-John-Gill-Child-Development-Center/',
                         'reviews': '/california/san-jose/27963-John-Gill-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'John Gill Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4340 Stevens Creek Boulevard #260',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK-K',
                     'highlighted': false,
                     'id': 27970,
                     'lat': 37.321819,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/27970-Pioneer-Child-Development-Center/#College_success',
                         'profile': '/california/san-jose/27970-Pioneer-Child-Development-Center/',
                         'reviews': '/california/san-jose/27970-Pioneer-Child-Development-Center/#Reviews'},
                     'lon': -121.978966,
                     'name': 'Pioneer Child Development Center',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '950 Owsley Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 579,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 74,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 40, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 51,
                                        'rating': 7},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 25490,
                     'lat': 37.329388,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/25490-Rocketship-Mosaic-Elementary/#College_success',
                         'profile': '/california/san-jose/25490-Rocketship-Mosaic-Elementary/',
                         'reviews': '/california/san-jose/25490-Rocketship-Mosaic-Elementary/#Reviews'},
                     'lon': -121.855957,
                     'name': 'Rocketship Mosaic Elementary',
                     'numReviews': 20,
                     'parentRating': 5,
                     'percentLowIncome': '74%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 35,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 8,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '423 Los Arboles St',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 452,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 73,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 49, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 45,
                                        'rating': 6},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 31948,
                     'lat': 37.285271,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/31948-KIPP-Heritage-Academy/#College_success',
                               'profile': '/california/san-jose/31948-KIPP-Heritage-Academy/',
                               'reviews': '/california/san-jose/31948-KIPP-Heritage-Academy/#Reviews'},
                     'lon': -121.83139,
                     'name': 'KIPP Heritage Academy',
                     'numReviews': 19,
                     'parentRating': 4,
                     'percentLowIncome': '73%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 7,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4525 Union Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 27136,
                     'lat': 37.25489,
                     'levelCode': 'p',
                     'links': {'collegeSuccess': '/california/san-jose/27136-Harker-Preschool/#College_success',
                               'profile': '/california/san-jose/27136-Harker-Preschool/',
                               'reviews': '/california/san-jose/27136-Harker-Preschool/#Reviews'},
                     'lon': -121.93206,
                     'name': 'Harker Preschool',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3122 Fowler Road',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 110,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                       {'label': 'Asian or Pacific Islander',
                                        'percentage': 68},
                                       {'label': 'Hispanic', 'percentage': 18},
                                       {'label': 'White', 'percentage': 9},
                                       {'label': 'African American', 'percentage': 5}],
                     'gradeLevels': 'Ungraded',
                     'highlighted': false,
                     'id': 17125,
                     'lat': 37.310394,
                     'levelCode': 'e,m,h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/17125-Evergreen-Montessori-School/#College_success',
                         'profile': '/california/san-jose/17125-Evergreen-Montessori-School/',
                         'reviews': '/california/san-jose/17125-Evergreen-Montessori-School/#Reviews'},
                     'lon': -121.777611,
                     'name': 'Evergreen Montessori School',
                     'numReviews': 3,
                     'parentRating': 3,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '801 Hibiscus Lane',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': null,
                     'districtId': 0,
                     'districtName': null,
                     'enrollment': 0,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                     'gradeLevels': 'PK',
                     'highlighted': false,
                     'id': 32272,
                     'lat': 37.312675,
                     'levelCode': 'p',
                     'links': {
                         'collegeSuccess': '/california/san-jose/32272-Action-Day--Primary-Plus---Phelan/#College_success',
                         'profile': '/california/san-jose/32272-Action-Day--Primary-Plus---Phelan/',
                         'reviews': '/california/san-jose/32272-Action-Day--Primary-Plus---Phelan/#Reviews'},
                     'lon': -121.97316,
                     'name': 'Action Day | Primary Plus - Phelan',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '?',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'private',
                     'state': 'CA',
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1970 Cinderella Lane',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 223,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 91,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 1},
                                       {'label': 'Hispanic',
                                        'percentage': 83,
                                        'rating': 1},
                                       {'label': 'Asian', 'percentage': 12},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5367,
                     'lat': 37.344776,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5367-Clyde-Arbuckle-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5367-Clyde-Arbuckle-Elementary-School/',
                         'reviews': '/california/san-jose/5367-Clyde-Arbuckle-Elementary-School/#Reviews'},
                     'lon': -121.841011,
                     'name': 'Clyde Arbuckle Elementary School',
                     'numReviews': 7,
                     'parentRating': 3,
                     'percentLowIncome': '91%',
                     'pinned': null,
                     'rating': 1,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 1},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1300 Tallahassee Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 389,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 87,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 83,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 9},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5368,
                     'lat': 37.344383,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5368-Sylvia-Cassell-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5368-Sylvia-Cassell-Elementary-School/',
                         'reviews': '/california/san-jose/5368-Sylvia-Cassell-Elementary-School/#Reviews'},
                     'lon': -121.830383,
                     'name': 'Sylvia Cassell Elementary School',
                     'numReviews': 3,
                     'parentRating': 2,
                     'percentLowIncome': '87%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2000 Kammerer Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 344,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 91,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 84,
                                        'rating': 3},
                                       {'label': 'Asian', 'percentage': 9},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5369,
                     'lat': 37.350239,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5369-Cesar-Chavez-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5369-Cesar-Chavez-Elementary-School/',
                         'reviews': '/california/san-jose/5369-Cesar-Chavez-Elementary-School/#Reviews'},
                     'lon': -121.844971,
                     'name': 'Cesar Chavez Elementary School',
                     'numReviews': 12,
                     'parentRating': 2,
                     'percentLowIncome': '91%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3720 East Hills Drive',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 385,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 87,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 84,
                                        'rating': 3},
                                       {'label': 'Asian', 'percentage': 6},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5370,
                     'lat': 37.369354,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5370-Horace-Cureton-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5370-Horace-Cureton-Elementary-School/',
                         'reviews': '/california/san-jose/5370-Horace-Cureton-Elementary-School/#Reviews'},
                     'lon': -121.814217,
                     'name': 'Horace Cureton Elementary School',
                     'numReviews': 24,
                     'parentRating': 5,
                     'percentLowIncome': '87%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1290 Bal Harbor Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 371,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 87,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 88,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 5},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5371,
                     'lat': 37.3391,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5371-A.-J.-Dorsa-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5371-A.-J.-Dorsa-Elementary-School/',
                         'reviews': '/california/san-jose/5371-A.-J.-Dorsa-Elementary-School/#Reviews'},
                     'lon': -121.837914,
                     'name': 'A. J. Dorsa Elementary School',
                     'numReviews': 18,
                     'parentRating': 5,
                     'percentLowIncome': '87%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1720 Hopkins Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 289,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 93,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Asian', 'percentage': 9, 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 82,
                                        'rating': 1},
                                       {'label': 'African American', 'percentage': 6},
                                       {'label': 'Pacific Islander', 'percentage': 3},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5372,
                     'lat': 37.336681,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5372-Clyde-L.-Fischer-Middle-School/#College_success',
                         'profile': '/california/san-jose/5372-Clyde-L.-Fischer-Middle-School/',
                         'reviews': '/california/san-jose/5372-Clyde-L.-Fischer-Middle-School/#Reviews'},
                     'lon': -121.830833,
                     'name': 'Clyde L. Fischer Middle School',
                     'numReviews': 10,
                     'parentRating': 4,
                     'percentLowIncome': '93%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '277 Mahoney Drive',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 521,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 86,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Asian', 'percentage': 7, 'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 84,
                                        'rating': 3},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5373,
                     'lat': 37.371174,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5373-Joseph-George-Middle-School/#College_success',
                         'profile': '/california/san-jose/5373-Joseph-George-Middle-School/',
                         'reviews': '/california/san-jose/5373-Joseph-George-Middle-School/#Reviews'},
                     'lon': -121.814278,
                     'name': 'Joseph George Middle School',
                     'numReviews': 13,
                     'parentRating': 4,
                     'percentLowIncome': '86%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2475 Van Winkle Lane',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 417,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 93,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 87,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 6},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 5374,
                     'lat': 37.349949,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5374-Aptitud-Community-Academy-At-Goss/#College_success',
                         'profile': '/california/san-jose/5374-Aptitud-Community-Academy-At-Goss/',
                         'reviews': '/california/san-jose/5374-Aptitud-Community-Academy-At-Goss/#Reviews'},
                     'lon': -121.831398,
                     'name': 'Aptitud Community Academy At Goss',
                     'numReviews': 16,
                     'parentRating': 4,
                     'percentLowIncome': '93%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1745 June Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 622,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 91,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 88,
                                        'rating': 3},
                                       {'label': 'Asian', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 5375,
                     'lat': 37.33123,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5375-O.-S.-Hubbard-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5375-O.-S.-Hubbard-Elementary-School/',
                         'reviews': '/california/san-jose/5375-O.-S.-Hubbard-Elementary-School/#Reviews'},
                     'lon': -121.838257,
                     'name': 'O. S. Hubbard Elementary School',
                     'numReviews': 7,
                     'parentRating': 4,
                     'percentLowIncome': '91%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '100 Kirk Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 512,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 72,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 80,
                                        'rating': 4},
                                       {'label': 'Asian', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5376,
                     'lat': 37.376934,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5376-Linda-Vista-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5376-Linda-Vista-Elementary-School/',
                         'reviews': '/california/san-jose/5376-Linda-Vista-Elementary-School/#Reviews'},
                     'lon': -121.823723,
                     'name': 'Linda Vista Elementary School',
                     'numReviews': 18,
                     'parentRating': 3,
                     'percentLowIncome': '72%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '13901 Nordyke Drive',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 334,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 84,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 84,
                                        'rating': 5},
                                       {'label': 'Asian', 'percentage': 9},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5377,
                     'lat': 37.359745,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5377-Lyndale-Elementary-School/#College_success',
                               'profile': '/california/san-jose/5377-Lyndale-Elementary-School/',
                               'reviews': '/california/san-jose/5377-Lyndale-Elementary-School/#Reviews'},
                     'lon': -121.824921,
                     'name': 'Lyndale Elementary School',
                     'numReviews': 11,
                     'parentRating': 3,
                     'percentLowIncome': '84%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 17,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2050 Kammerer Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 293,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 92,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Asian', 'percentage': 13, 'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 73,
                                        'rating': 2},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5378,
                     'lat': 37.350826,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5378-Lee-Mathson-Middle-School/#College_success',
                               'profile': '/california/san-jose/5378-Lee-Mathson-Middle-School/',
                               'reviews': '/california/san-jose/5378-Lee-Mathson-Middle-School/#Reviews'},
                     'lon': -121.844078,
                     'name': 'Lee Mathson Middle School',
                     'numReviews': 10,
                     'parentRating': 4,
                     'percentLowIncome': '92%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3311 Lucian Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 502,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 58,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 31, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 49,
                                        'rating': 5},
                                       {'label': 'Filipino', 'percentage': 8},
                                       {'label': 'White', 'percentage': 7},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5379,
                     'lat': 37.38047,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5379-Millard-Mccollam-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5379-Millard-Mccollam-Elementary-School/',
                         'reviews': '/california/san-jose/5379-Millard-Mccollam-Elementary-School/#Reviews'},
                     'lon': -121.833122,
                     'name': 'Millard Mccollam Elementary School',
                     'numReviews': 36,
                     'parentRating': 5,
                     'percentLowIncome': '58%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1824 Daytona Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 369,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 86,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 79,
                                        'rating': 4},
                                       {'label': 'Asian', 'percentage': 13},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5380,
                     'lat': 37.335186,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5380-Donald-J.-Meyer-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5380-Donald-J.-Meyer-Elementary-School/',
                         'reviews': '/california/san-jose/5380-Donald-J.-Meyer-Elementary-School/#Reviews'},
                     'lon': -121.827667,
                     'name': 'Donald J. Meyer Elementary School',
                     'numReviews': 7,
                     'parentRating': 3,
                     'percentLowIncome': '86%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2800 Ocala Avenue',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 449,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 84,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Asian', 'percentage': 12, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 79,
                                        'rating': 3},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5383,
                     'lat': 37.340385,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5383-Ocala-Middle-School/#College_success',
                               'profile': '/california/san-jose/5383-Ocala-Middle-School/',
                               'reviews': '/california/san-jose/5383-Ocala-Middle-School/#Reviews'},
                     'lon': -121.820457,
                     'name': 'Ocala Middle School',
                     'numReviews': 15,
                     'parentRating': 4,
                     'percentLowIncome': '84%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '500 Rough And Ready Road',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 334,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 80,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 30, 'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 50,
                                        'rating': 3},
                                       {'label': 'Filipino',
                                        'percentage': 17,
                                        'rating': 6},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'PK-8',
                     'highlighted': false,
                     'id': 5384,
                     'lat': 37.37495,
                     'levelCode': 'p,e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5384-Ben-Painter-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5384-Ben-Painter-Elementary-School/',
                         'reviews': '/california/san-jose/5384-Ben-Painter-Elementary-School/#Reviews'},
                     'lon': -121.843307,
                     'name': 'Ben Painter Elementary School',
                     'numReviews': 7,
                     'parentRating': 4,
                     'percentLowIncome': '80%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1241 Mcginness Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 305,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 85,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 83,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 9},
                                       {'label': 'Filipino', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5387,
                     'lat': 37.350063,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5387-Thomas-P.-Ryan-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5387-Thomas-P.-Ryan-Elementary-School/',
                         'reviews': '/california/san-jose/5387-Thomas-P.-Ryan-Elementary-School/#Reviews'},
                     'lon': -121.822571,
                     'name': 'Thomas P. Ryan Elementary School',
                     'numReviews': 35,
                     'parentRating': 4,
                     'percentLowIncome': '85%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1721 East San Antonio Street',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 344,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 88,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 78,
                                        'rating': 4},
                                       {'label': 'Asian', 'percentage': 11},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5388,
                     'lat': 37.350025,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5388-San-Antonio-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5388-San-Antonio-Elementary-School/',
                         'reviews': '/california/san-jose/5388-San-Antonio-Elementary-School/#Reviews'},
                     'lon': -121.852341,
                     'name': 'San Antonio Elementary School',
                     'numReviews': 12,
                     'parentRating': 3,
                     'percentLowIncome': '88%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '480 Rough And Ready Road',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 625,
                     'districtName': 'Alum Rock Union Elementary School District',
                     'enrollment': 601,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 80,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 29, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 58,
                                        'rating': 2},
                                       {'label': 'Filipino',
                                        'percentage': 8,
                                        'rating': 5},
                                       {'label': 'Pacific Islander', 'percentage': 2},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5389,
                     'lat': 37.374512,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5389-William-Sheppard-Middle-School/#College_success',
                         'profile': '/california/san-jose/5389-William-Sheppard-Middle-School/',
                         'reviews': '/california/san-jose/5389-William-Sheppard-Middle-School/#Reviews'},
                     'lon': -121.843002,
                     'name': 'William Sheppard Middle School',
                     'numReviews': 13,
                     'parentRating': 3,
                     'percentLowIncome': '80%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1781 Olivetree Drive',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 461,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 29,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 48, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 22,
                                        'rating': 4},
                                       {'label': 'Filipino',
                                        'percentage': 20,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5392,
                     'lat': 37.398514,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5392-Brooktree-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5392-Brooktree-Elementary-School/',
                         'reviews': '/california/san-jose/5392-Brooktree-Elementary-School/#Reviews'},
                     'lon': -121.880051,
                     'name': 'Brooktree Elementary School',
                     'numReviews': 8,
                     'parentRating': 5,
                     'percentLowIncome': '29%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2550 Greengate Drive',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 391,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 30,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 53, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 19,
                                        'rating': 5},
                                       {'label': 'Filipino', 'percentage': 11},
                                       {'label': 'Two or more races', 'percentage': 8},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'PK-5',
                     'highlighted': false,
                     'id': 5393,
                     'lat': 37.394402,
                     'levelCode': 'p,e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5393-Cherrywood-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5393-Cherrywood-Elementary-School/',
                         'reviews': '/california/san-jose/5393-Cherrywood-Elementary-School/#Reviews'},
                     'lon': -121.865082,
                     'name': 'Cherrywood Elementary School',
                     'numReviews': 40,
                     'parentRating': 4,
                     'percentLowIncome': '30%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2095 Warmwood Lane',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 373,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 35,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 46, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 21,
                                        'rating': 5},
                                       {'label': 'Filipino', 'percentage': 17},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'Pacific Islander', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'PK-5',
                     'highlighted': false,
                     'id': 5394,
                     'lat': 37.414558,
                     'levelCode': 'p,e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5394-Laneview-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5394-Laneview-Elementary-School/',
                         'reviews': '/california/san-jose/5394-Laneview-Elementary-School/#Reviews'},
                     'lon': -121.870689,
                     'name': 'Laneview Elementary School',
                     'numReviews': 12,
                     'parentRating': 4,
                     'percentLowIncome': '35%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1855 Majestic Way',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 518,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 29,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 55, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 24,
                                        'rating': 6},
                                       {'label': 'Two or more races',
                                        'percentage': 6,
                                        'rating': 7},
                                       {'label': 'Filipino', 'percentage': 8},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5395,
                     'lat': 37.410648,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5395-Majestic-Way-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5395-Majestic-Way-Elementary-School/',
                         'reviews': '/california/san-jose/5395-Majestic-Way-Elementary-School/#Reviews'},
                     'lon': -121.857231,
                     'name': 'Majestic Way Elementary School',
                     'numReviews': 21,
                     'parentRating': 4,
                     'percentLowIncome': '29%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1970 Morrill Avenue',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 633,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 37,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 43, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 24,
                                        'rating': 4},
                                       {'label': 'Two or more races',
                                        'percentage': 6,
                                        'rating': 7},
                                       {'label': 'Filipino',
                                        'percentage': 21,
                                        'rating': 8},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5396,
                     'lat': 37.410217,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5396-Morrill-Middle-School/#College_success',
                               'profile': '/california/san-jose/5396-Morrill-Middle-School/',
                               'reviews': '/california/san-jose/5396-Morrill-Middle-School/#Reviews'},
                     'lon': -121.871109,
                     'name': 'Morrill Middle School',
                     'numReviews': 14,
                     'parentRating': 4,
                     'percentLowIncome': '37%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3466 Grossmont Drive',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 456,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 21,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 54, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 21,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 9},
                                       {'label': 'Two or more races', 'percentage': 8},
                                       {'label': 'Filipino', 'percentage': 7},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5397,
                     'lat': 37.396301,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5397-Noble-Elementary-School/#College_success',
                               'profile': '/california/san-jose/5397-Noble-Elementary-School/',
                               'reviews': '/california/san-jose/5397-Noble-Elementary-School/#Reviews'},
                     'lon': -121.839104,
                     'name': 'Noble Elementary School',
                     'numReviews': 34,
                     'parentRating': 4,
                     'percentLowIncome': '21%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2760 East Trimble Road',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 574,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 16,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 77, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 8,
                                        'rating': 8},
                                       {'label': 'Filipino',
                                        'percentage': 7,
                                        'rating': 10},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5398,
                     'lat': 37.409458,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5398-Northwood-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5398-Northwood-Elementary-School/',
                         'reviews': '/california/san-jose/5398-Northwood-Elementary-School/#Reviews'},
                     'lon': -121.88134,
                     'name': 'Northwood Elementary School',
                     'numReviews': 51,
                     'parentRating': 4,
                     'percentLowIncome': '16%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 8,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '955 Piedmont Road',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 819,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 41,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 38, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 34,
                                        'rating': 2},
                                       {'label': 'White', 'percentage': 5, 'rating': 6},
                                       {'label': 'Two or more races',
                                        'percentage': 5,
                                        'rating': 8},
                                       {'label': 'Filipino',
                                        'percentage': 14,
                                        'rating': 7},
                                       {'label': 'African American', 'percentage': 3}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5399,
                     'lat': 37.390728,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5399-Piedmont-Middle-School/#College_success',
                               'profile': '/california/san-jose/5399-Piedmont-Middle-School/',
                               'reviews': '/california/san-jose/5399-Piedmont-Middle-School/#Reviews'},
                     'lon': -121.842644,
                     'name': 'Piedmont Middle School',
                     'numReviews': 15,
                     'parentRating': 4,
                     'percentLowIncome': '41%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1401 Turlock Lane',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 568,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 19,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 68, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 15,
                                        'rating': 3},
                                       {'label': 'Filipino', 'percentage': 7},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5400,
                     'lat': 37.400284,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5400-Ruskin-Elementary-School/#College_success',
                               'profile': '/california/san-jose/5400-Ruskin-Elementary-School/',
                               'reviews': '/california/san-jose/5400-Ruskin-Elementary-School/#Reviews'},
                     'lon': -121.855888,
                     'name': 'Ruskin Elementary School',
                     'numReviews': 60,
                     'parentRating': 5,
                     'percentLowIncome': '19%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 33,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3155 Kimlee Drive',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 930,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 27,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 62, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 19,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 5, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'Filipino',
                                        'percentage': 8,
                                        'rating': 9},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5401,
                     'lat': 37.403133,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5401-Sierramont-Middle-School/#College_success',
                               'profile': '/california/san-jose/5401-Sierramont-Middle-School/',
                               'reviews': '/california/san-jose/5401-Sierramont-Middle-School/#Reviews'},
                     'lon': -121.855927,
                     'name': 'Sierramont Middle School',
                     'numReviews': 29,
                     'parentRating': 5,
                     'percentLowIncome': '27%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 26,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1100 Summerdale Drive',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 403,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 34,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 47, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 27,
                                        'rating': 4},
                                       {'label': 'Filipino',
                                        'percentage': 16,
                                        'rating': 6},
                                       {'label': 'Two or more races', 'percentage': 5},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5402,
                     'lat': 37.389587,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5402-Summerdale-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5402-Summerdale-Elementary-School/',
                         'reviews': '/california/san-jose/5402-Summerdale-Elementary-School/#Reviews'},
                     'lon': -121.851959,
                     'name': 'Summerdale Elementary School',
                     'numReviews': 33,
                     'parentRating': 4,
                     'percentLowIncome': '34%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '995 Bard Street',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 292,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 52,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Asian', 'percentage': 30, 'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 49,
                                        'rating': 1},
                                       {'label': 'Filipino', 'percentage': 7},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5403,
                     'lat': 37.391392,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5403-Toyon-Elementary-School/#College_success',
                               'profile': '/california/san-jose/5403-Toyon-Elementary-School/',
                               'reviews': '/california/san-jose/5403-Toyon-Elementary-School/#Reviews'},
                     'lon': -121.838272,
                     'name': 'Toyon Elementary School',
                     'numReviews': 28,
                     'parentRating': 3,
                     'percentLowIncome': '52%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1311 Vinci Park Way',
                                 'street2': '',
                                 'zip': '95131'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 626,
                     'districtName': 'Berryessa Union Elementary School District',
                     'enrollment': 564,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 40,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 42, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 26,
                                        'rating': 6},
                                       {'label': 'Filipino',
                                        'percentage': 19,
                                        'rating': 8},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5404,
                     'lat': 37.380943,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5404-Vinci-Park-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5404-Vinci-Park-Elementary-School/',
                         'reviews': '/california/san-jose/5404-Vinci-Park-Elementary-School/#Reviews'},
                     'lon': -121.873329,
                     'name': 'Vinci Park Elementary School',
                     'numReviews': 32,
                     'parentRating': 4,
                     'percentLowIncome': '40%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 7,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1840 Harris Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 627,
                     'districtName': 'Cambrian School District',
                     'enrollment': 511,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 19,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 16, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 25,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 39, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 13,
                                        'rating': 10},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5405,
                     'lat': 37.278713,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5405-Bagby-Elementary-School/#College_success',
                               'profile': '/california/san-jose/5405-Bagby-Elementary-School/',
                               'reviews': '/california/san-jose/5405-Bagby-Elementary-School/#Reviews'},
                     'lon': -121.921928,
                     'name': 'Bagby Elementary School',
                     'numReviews': 39,
                     'parentRating': 5,
                     'percentLowIncome': '19%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2800 New Jersey Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 627,
                     'districtName': 'Cambrian School District',
                     'enrollment': 526,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 15,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 16, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 20,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 47, 'rating': 9},
                                       {'label': 'Two or more races', 'percentage': 11},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 2}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5406,
                     'lat': 37.271042,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5406-Fammatre-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5406-Fammatre-Elementary-School/',
                         'reviews': '/california/san-jose/5406-Fammatre-Elementary-School/#Reviews'},
                     'lon': -121.925781,
                     'name': 'Fammatre Elementary School',
                     'numReviews': 50,
                     'parentRating': 5,
                     'percentLowIncome': '15%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '15711 Woodard Road',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 627,
                     'districtName': 'Cambrian School District',
                     'enrollment': 528,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 16,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 22, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 18,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 41, 'rating': 9},
                                       {'label': 'Two or more races', 'percentage': 9},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Filipino', 'percentage': 3}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5407,
                     'lat': 37.260197,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5407-Farnham-Charter-School/#College_success',
                               'profile': '/california/san-jose/5407-Farnham-Charter-School/',
                               'reviews': '/california/san-jose/5407-Farnham-Charter-School/#Reviews'},
                     'lon': -121.943024,
                     'name': 'Farnham Charter School',
                     'numReviews': 69,
                     'parentRating': 4,
                     'percentLowIncome': '16%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2650 New Jersey Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 627,
                     'districtName': 'Cambrian School District',
                     'enrollment': 962,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 18,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 16, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 3,
                                        'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 27,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 43, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 7,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 2}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5408,
                     'lat': 37.273403,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5408-Price-Charter-Middle-School/#College_success',
                         'profile': '/california/san-jose/5408-Price-Charter-Middle-School/',
                         'reviews': '/california/san-jose/5408-Price-Charter-Middle-School/#Reviews'},
                     'lon': -121.926788,
                     'name': 'Price Charter Middle School',
                     'numReviews': 52,
                     'parentRating': 3,
                     'percentLowIncome': '18%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3850 Woodford Drive',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 627,
                     'districtName': 'Cambrian School District',
                     'enrollment': 431,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 25,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 22, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 29,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 31, 'rating': 6},
                                       {'label': 'Two or more races',
                                        'percentage': 14,
                                        'rating': 10},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5409,
                     'lat': 37.260593,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5409-Sartorette-Charter-School/#College_success',
                               'profile': '/california/san-jose/5409-Sartorette-Charter-School/',
                               'reviews': '/california/san-jose/5409-Sartorette-Charter-School/#Reviews'},
                     'lon': -121.911118,
                     'name': 'Sartorette Charter School',
                     'numReviews': 111,
                     'parentRating': 4,
                     'percentLowIncome': '25%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1970 Willow Street',
                                 'street2': '',
                                 'zip': '95125'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Campbell',
                     'districtId': 629,
                     'districtName': 'Campbell Union School District',
                     'enrollment': 524,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 68,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'African American',
                                        'percentage': 7,
                                        'rating': 1},
                                       {'label': 'Hispanic',
                                        'percentage': 68,
                                        'rating': 2},
                                       {'label': 'White', 'percentage': 8},
                                       {'label': 'Two or more races', 'percentage': 6},
                                       {'label': 'Asian', 'percentage': 4},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5410,
                     'lat': 37.299419,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5410-Blackford-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5410-Blackford-Elementary-School/',
                         'reviews': '/california/san-jose/5410-Blackford-Elementary-School/#Reviews'},
                     'lon': -121.922318,
                     'name': 'Blackford Elementary School',
                     'numReviews': 99,
                     'parentRating': 3,
                     'percentLowIncome': '68%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4450 Mccoy Avenue',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Campbell',
                     'districtId': 629,
                     'districtName': 'Campbell Union School District',
                     'enrollment': 657,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 12,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 40, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 13,
                                        'rating': 8},
                                       {'label': 'White', 'percentage': 31, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 9,
                                        'rating': 10},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5414,
                     'lat': 37.276424,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5414-Forest-Hill-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5414-Forest-Hill-Elementary-School/',
                         'reviews': '/california/san-jose/5414-Forest-Hill-Elementary-School/#Reviews'},
                     'lon': -121.982353,
                     'name': 'Forest Hill Elementary School',
                     'numReviews': 20,
                     'parentRating': 5,
                     'percentLowIncome': '12%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 14,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 8,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '881 South Cypress Avenue',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Campbell',
                     'districtId': 629,
                     'districtName': 'Campbell Union School District',
                     'enrollment': 579,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 53,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 13, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 56,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 16, 'rating': 8},
                                       {'label': 'Two or more races', 'percentage': 5},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5416,
                     'lat': 37.31089,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5416-Lynhaven-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5416-Lynhaven-Elementary-School/',
                         'reviews': '/california/san-jose/5416-Lynhaven-Elementary-School/#Reviews'},
                     'lon': -121.959778,
                     'name': 'Lynhaven Elementary School',
                     'numReviews': 39,
                     'parentRating': 4,
                     'percentLowIncome': '53%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 16,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1055 South Monroe Street',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Campbell',
                     'districtId': 629,
                     'districtName': 'Campbell Union School District',
                     'enrollment': 1118,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 67,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 6, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 4,
                                        'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 70,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 12, 'rating': 8},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 5418,
                     'lat': 37.309078,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5418-Monroe-Middle-School/#College_success',
                               'profile': '/california/san-jose/5418-Monroe-Middle-School/',
                               'reviews': '/california/san-jose/5418-Monroe-Middle-School/#Reviews'},
                     'lon': -121.94355,
                     'name': 'Monroe Middle School',
                     'numReviews': 8,
                     'parentRating': 3,
                     'percentLowIncome': '67%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1800 Fruitdale Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Campbell',
                     'districtId': 629,
                     'districtName': 'Campbell Union School District',
                     'enrollment': 538,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 68,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 87,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5421,
                     'lat': 37.310627,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5421-Sherman-Oaks-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5421-Sherman-Oaks-Elementary-School/',
                         'reviews': '/california/san-jose/5421-Sherman-Oaks-Elementary-School/#Reviews'},
                     'lon': -121.924339,
                     'name': 'Sherman Oaks Elementary School',
                     'numReviews': 38,
                     'parentRating': 4,
                     'percentLowIncome': '68%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '901 Boynton Avenue',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '36%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 630,
                     'districtName': 'Campbell Union High School District',
                     'enrollment': 209,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 53,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 1},
                                       {'label': 'Hispanic',
                                        'percentage': 70,
                                        'rating': 1},
                                       {'label': 'White', 'percentage': 22, 'rating': 2},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'Asian', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5423,
                     'lat': 37.312298,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5423-Boynton-High-School/#College_success',
                               'profile': '/california/san-jose/5423-Boynton-High-School/',
                               'reviews': '/california/san-jose/5423-Boynton-High-School/#Reviews'},
                     'lon': -121.966011,
                     'name': 'Boynton High School',
                     'numReviews': 0,
                     'parentRating': 0,
                     'percentLowIncome': '53%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 17,
                     'subratings': {'College Readiness Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 1},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1224 Del Mar Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '68%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 630,
                     'districtName': 'Campbell Union High School District',
                     'enrollment': 1300,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 49,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 6, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 5,
                                        'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 61,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 20, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'Filipino',
                                        'percentage': 3,
                                        'rating': 7},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5424,
                     'lat': 37.303261,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5424-Del-Mar-High-School/#College_success',
                               'profile': '/california/san-jose/5424-Del-Mar-High-School/',
                               'reviews': '/california/san-jose/5424-Del-Mar-High-School/#Reviews'},
                     'lon': -121.930298,
                     'name': 'Del Mar High School',
                     'numReviews': 43,
                     'parentRating': 4,
                     'percentLowIncome': '49%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'College Readiness Rating': 5,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5210 Leigh Avenue',
                                 'street2': '',
                                 'zip': '95124'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '88%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 630,
                     'districtName': 'Campbell Union High School District',
                     'enrollment': 1772,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 9,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 21, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 18,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 53, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 5,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5425,
                     'lat': 37.243309,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5425-Leigh-High-School/#College_success',
                               'profile': '/california/san-jose/5425-Leigh-High-School/',
                               'reviews': '/california/san-jose/5425-Leigh-High-School/#Reviews'},
                     'lon': -121.922577,
                     'name': 'Leigh High School',
                     'numReviews': 56,
                     'parentRating': 4,
                     'percentLowIncome': '9%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'College Readiness Rating': 10,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5050 Moorpark Avenue',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Sunnyvale',
                     'districtId': 631,
                     'districtName': 'Cupertino Union School District',
                     'enrollment': 519,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 18,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 67, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 15,
                                        'rating': 1},
                                       {'label': 'White', 'percentage': 11, 'rating': 10},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5431,
                     'lat': 37.310596,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5431-Manuel-De-Vargas-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5431-Manuel-De-Vargas-Elementary-School/',
                         'reviews': '/california/san-jose/5431-Manuel-De-Vargas-Elementary-School/#Reviews'},
                     'lon': -121.99102,
                     'name': 'Manuel De Vargas Elementary School',
                     'numReviews': 65,
                     'parentRating': 5,
                     'percentLowIncome': '18%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1101 Strayer Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Sunnyvale',
                     'districtId': 631,
                     'districtName': 'Cupertino Union School District',
                     'enrollment': 435,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 3},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 87, 'rating': 10},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'Hispanic', 'percentage': 2}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5432,
                     'lat': 37.30629,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5432-Nelson-S.-Dilworth-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5432-Nelson-S.-Dilworth-Elementary-School/',
                         'reviews': '/california/san-jose/5432-Nelson-S.-Dilworth-Elementary-School/#Reviews'},
                     'lon': -122.006882,
                     'name': 'Nelson S. Dilworth Elementary School',
                     'numReviews': 23,
                     'parentRating': 4,
                     'percentLowIncome': '3%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6990 Melvin Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Sunnyvale',
                     'districtId': 631,
                     'districtName': 'Cupertino Union School District',
                     'enrollment': 771,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 5},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 81, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 7,
                                        'rating': 10},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'Hispanic', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5441,
                     'lat': 37.307095,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5441-R.-I.-Meyerholz-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5441-R.-I.-Meyerholz-Elementary-School/',
                         'reviews': '/california/san-jose/5441-R.-I.-Meyerholz-Elementary-School/#Reviews'},
                     'lon': -122.026581,
                     'name': 'R. I. Meyerholz Elementary School',
                     'numReviews': 19,
                     'parentRating': 4,
                     'percentLowIncome': '5%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 26,
                     'subratings': {'Academic Progress Rating': 7, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6151 Rainbow Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Sunnyvale',
                     'districtId': 631,
                     'districtName': 'Cupertino Union School District',
                     'enrollment': 1191,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 5,
                                        'rating': 9},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 87, 'rating': 10},
                                       {'label': 'White', 'percentage': 6, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 3,
                                        'rating': 10},
                                       {'label': 'Hispanic', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5442,
                     'lat': 37.301254,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5442-Joaquin-Miller-Middle-School/#College_success',
                         'profile': '/california/san-jose/5442-Joaquin-Miller-Middle-School/',
                         'reviews': '/california/san-jose/5442-Joaquin-Miller-Middle-School/#Reviews'},
                     'lon': -122.0121,
                     'name': 'Joaquin Miller Middle School',
                     'numReviews': 54,
                     'parentRating': 4,
                     'percentLowIncome': '5%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6560 Hanover Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Sunnyvale',
                     'districtId': 631,
                     'districtName': 'Cupertino Union School District',
                     'enrollment': 354,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 6},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 79, 'rating': 10},
                                       {'label': 'White', 'percentage': 9},
                                       {'label': 'Hispanic', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5444,
                     'lat': 37.300407,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5444-John-Muir-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5444-John-Muir-Elementary-School/',
                         'reviews': '/california/san-jose/5444-John-Muir-Elementary-School/#Reviews'},
                     'lon': -122.019463,
                     'name': 'John Muir Elementary School',
                     'numReviews': 39,
                     'parentRating': 4,
                     'percentLowIncome': '6%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1188 Wunderlich Drive',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Sunnyvale',
                     'districtId': 631,
                     'districtName': 'Cupertino Union School District',
                     'enrollment': 570,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 2},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 91, 'rating': 10},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5446,
                     'lat': 37.30381,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5446-Murdock-Portal-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5446-Murdock-Portal-Elementary-School/',
                         'reviews': '/california/san-jose/5446-Murdock-Portal-Elementary-School/#Reviews'},
                     'lon': -121.999519,
                     'name': 'Murdock-Portal Elementary School',
                     'numReviews': 48,
                     'parentRating': 3,
                     'percentLowIncome': '2%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 26,
                     'subratings': {'Academic Progress Rating': 7, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1835 Cunningham Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '53%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 151,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 81},
                                       {'label': 'Hispanic', 'percentage': 91},
                                       {'label': 'Asian', 'percentage': 3},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': '11-12',
                     'highlighted': false,
                     'id': 5452,
                     'lat': 37.331921,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5452-Apollo-High-School/#College_success',
                               'profile': '/california/san-jose/5452-Apollo-High-School/',
                               'reviews': '/california/san-jose/5452-Apollo-High-School/#Reviews'},
                     'lon': -121.829903,
                     'name': 'Apollo High School',
                     'numReviews': 5,
                     'parentRating': 5,
                     'percentLowIncome': '81%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '230 Pala Avenue',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '32%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 274,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 74},
                                       {'label': 'Hispanic', 'percentage': 87},
                                       {'label': 'Asian', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '11-12',
                     'highlighted': false,
                     'id': 5453,
                     'lat': 37.368065,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5453-Foothill-High-School/#College_success',
                               'profile': '/california/san-jose/5453-Foothill-High-School/',
                               'reviews': '/california/san-jose/5453-Foothill-High-School/#Reviews'},
                     'lon': -121.838669,
                     'name': 'Foothill High School',
                     'numReviews': 3,
                     'parentRating': 5,
                     'percentLowIncome': '74%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 17,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3200 Senter Road',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '73%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 1867,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 63,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 37, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 55,
                                        'rating': 2},
                                       {'label': 'Filipino',
                                        'percentage': 3,
                                        'rating': 5},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5455,
                     'lat': 37.291924,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5455-Andrew-P.-Hill-High-School/#College_success',
                         'profile': '/california/san-jose/5455-Andrew-P.-Hill-High-School/',
                         'reviews': '/california/san-jose/5455-Andrew-P.-Hill-High-School/#Reviews'},
                     'lon': -121.831734,
                     'name': 'Andrew P. Hill High School',
                     'numReviews': 46,
                     'parentRating': 4,
                     'percentLowIncome': '63%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'College Readiness Rating': 6,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1776 Educational Park Drive',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '76%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 2872,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 48,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 41, 'rating': 9},
                                       {'label': 'African American',
                                        'percentage': 2,
                                        'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 32,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 3, 'rating': 8},
                                       {'label': 'Two or more races',
                                        'percentage': 2,
                                        'rating': 9},
                                       {'label': 'Filipino',
                                        'percentage': 18,
                                        'rating': 8},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5456,
                     'lat': 37.367798,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5456-Independence-High-School/#College_success',
                               'profile': '/california/san-jose/5456-Independence-High-School/',
                               'reviews': '/california/san-jose/5456-Independence-High-School/#Reviews'},
                     'lon': -121.859612,
                     'name': 'Independence High School',
                     'numReviews': 41,
                     'parentRating': 4,
                     'percentLowIncome': '48%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'College Readiness Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '57 North White Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '70%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 1053,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 83,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 6, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 80,
                                        'rating': 4},
                                       {'label': 'Filipino',
                                        'percentage': 6,
                                        'rating': 9},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5457,
                     'lat': 37.368046,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5457-James-Lick-High-School/#College_success',
                               'profile': '/california/san-jose/5457-James-Lick-High-School/',
                               'reviews': '/california/san-jose/5457-James-Lick-High-School/#Reviews'},
                     'lon': -121.830093,
                     'name': 'James Lick High School',
                     'numReviews': 14,
                     'parentRating': 4,
                     'percentLowIncome': '83%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'College Readiness Rating': 3,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1750 South White Road',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '74%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 1271,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 64,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 18, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 68,
                                        'rating': 5},
                                       {'label': 'Filipino',
                                        'percentage': 7,
                                        'rating': 9},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5458,
                     'lat': 37.345646,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5458-Mt.-Pleasant-High-School/#College_success',
                               'profile': '/california/san-jose/5458-Mt.-Pleasant-High-School/',
                               'reviews': '/california/san-jose/5458-Mt.-Pleasant-High-School/#Reviews'},
                     'lon': -121.809479,
                     'name': 'Mt. Pleasant High School',
                     'numReviews': 17,
                     'parentRating': 4,
                     'percentLowIncome': '64%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'College Readiness Rating': 6,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '285 Blossom Hill Road',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '74%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 1766,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 47,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 22, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 5,
                                        'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 56,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 7, 'rating': 7},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 7},
                                       {'label': 'Filipino',
                                        'percentage': 5,
                                        'rating': 6},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5459,
                     'lat': 37.251575,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5459-Oak-Grove-High-School/#College_success',
                               'profile': '/california/san-jose/5459-Oak-Grove-High-School/',
                               'reviews': '/california/san-jose/5459-Oak-Grove-High-School/#Reviews'},
                     'lon': -121.818916,
                     'name': 'Oak Grove High School',
                     'numReviews': 10,
                     'parentRating': 4,
                     'percentLowIncome': '47%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'College Readiness Rating': 6,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1835 Cunningham Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '71%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 1438,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 77,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 9, 'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 80,
                                        'rating': 5},
                                       {'label': 'Filipino',
                                        'percentage': 6,
                                        'rating': 6},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5460,
                     'lat': 37.330593,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5460-William-C.-Overfelt-High-School/#College_success',
                         'profile': '/california/san-jose/5460-William-C.-Overfelt-High-School/',
                         'reviews': '/california/san-jose/5460-William-C.-Overfelt-High-School/#Reviews'},
                     'lon': -121.828377,
                     'name': 'William C. Overfelt High School',
                     'numReviews': 15,
                     'parentRating': 4,
                     'percentLowIncome': '77%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'College Readiness Rating': 2,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1776 Educational Park Drive',
                                 'street2': '',
                                 'zip': '95133'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '38%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 114,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 53},
                                       {'label': 'Hispanic', 'percentage': 66},
                                       {'label': 'Asian', 'percentage': 8},
                                       {'label': 'African American', 'percentage': 8},
                                       {'label': 'White', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'Pacific Islander', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 2}],
                     'gradeLevels': '11-12',
                     'highlighted': false,
                     'id': 5461,
                     'lat': 37.367798,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5461-Pegasus-High-School/#College_success',
                               'profile': '/california/san-jose/5461-Pegasus-High-School/',
                               'reviews': '/california/san-jose/5461-Pegasus-High-School/#Reviews'},
                     'lon': -121.859612,
                     'name': 'Pegasus High School',
                     'numReviews': 1,
                     'parentRating': 5,
                     'percentLowIncome': '53%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6150 Snell Road',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '30%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 78,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 38},
                                       {'label': 'Hispanic', 'percentage': 77},
                                       {'label': 'White', 'percentage': 10},
                                       {'label': 'Asian', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '11-12',
                     'highlighted': false,
                     'id': 5462,
                     'lat': 37.235085,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5462-Phoenix-High-School/#College_success',
                               'profile': '/california/san-jose/5462-Phoenix-High-School/',
                               'reviews': '/california/san-jose/5462-Phoenix-High-School/#Reviews'},
                     'lon': -121.830498,
                     'name': 'Phoenix High School',
                     'numReviews': 1,
                     'parentRating': 4,
                     'percentLowIncome': '38%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 17,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1377 Piedmont Road',
                                 'street2': '',
                                 'zip': '95132'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '83%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 2058,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 26,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 56, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 23,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 5, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 5,
                                        'rating': 9},
                                       {'label': 'Filipino',
                                        'percentage': 9,
                                        'rating': 8},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5463,
                     'lat': 37.40379,
                     'levelCode': 'h',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5463-Piedmont-Hills-High-School/#College_success',
                         'profile': '/california/san-jose/5463-Piedmont-Hills-High-School/',
                         'reviews': '/california/san-jose/5463-Piedmont-Hills-High-School/#Reviews'},
                     'lon': -121.848991,
                     'name': 'Piedmont Hills High School',
                     'numReviews': 37,
                     'parentRating': 4,
                     'percentLowIncome': '26%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'College Readiness Rating': 10,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6150 Snell Road',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '80%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 2145,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 21,
                                        'rating': 8},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 23, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 3,
                                        'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 39,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 25, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 6,
                                        'rating': 10},
                                       {'label': 'Filipino',
                                        'percentage': 4,
                                        'rating': 9}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5464,
                     'lat': 37.235085,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5464-Santa-Teresa-High-School/#College_success',
                               'profile': '/california/san-jose/5464-Santa-Teresa-High-School/',
                               'reviews': '/california/san-jose/5464-Santa-Teresa-High-School/#Reviews'},
                     'lon': -121.830498,
                     'name': 'Santa Teresa High School',
                     'numReviews': 51,
                     'parentRating': 3,
                     'percentLowIncome': '21%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'College Readiness Rating': 9,
                                    'Equity Overview Rating': 7,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3434 Silver Creek Road',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '80%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 2435,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 39,
                                        'rating': 9},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 50, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 33,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 4, 'rating': 10},
                                       {'label': 'Filipino',
                                        'percentage': 10,
                                        'rating': 9},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5465,
                     'lat': 37.304333,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5465-Silver-Creek-High-School/#College_success',
                               'profile': '/california/san-jose/5465-Silver-Creek-High-School/',
                               'reviews': '/california/san-jose/5465-Silver-Creek-High-School/#Reviews'},
                     'lon': -121.808311,
                     'name': 'Silver Creek High School',
                     'numReviews': 41,
                     'parentRating': 3,
                     'percentLowIncome': '39%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'College Readiness Rating': 9,
                                    'Equity Overview Rating': 8,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1855 Lucretia Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '69%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 632,
                     'districtName': 'East Side Union High School District',
                     'enrollment': 1706,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 66,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 43, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 49,
                                        'rating': 3},
                                       {'label': 'Filipino',
                                        'percentage': 6,
                                        'rating': 6},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5466,
                     'lat': 37.320789,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5466-Yerba-Buena-High-School/#College_success',
                               'profile': '/california/san-jose/5466-Yerba-Buena-High-School/',
                               'reviews': '/california/san-jose/5466-Yerba-Buena-High-School/#Reviews'},
                     'lon': -121.848312,
                     'name': 'Yerba Buena High School',
                     'numReviews': 35,
                     'parentRating': 4,
                     'percentLowIncome': '66%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'College Readiness Rating': 5,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3799 Cadwallader Avenue',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 341,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 39,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 47, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 30,
                                        'rating': 5},
                                       {'label': 'Filipino', 'percentage': 10},
                                       {'label': 'White', 'percentage': 6},
                                       {'label': 'Two or more races', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5467,
                     'lat': 37.306698,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5467-Cadwallader-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5467-Cadwallader-Elementary-School/',
                         'reviews': '/california/san-jose/5467-Cadwallader-Elementary-School/#Reviews'},
                     'lon': -121.787628,
                     'name': 'Cadwallader Elementary School',
                     'numReviews': 18,
                     'parentRating': 4,
                     'percentLowIncome': '39%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2702 Sugar Plum Drive',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 590,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 41,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 46, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 28,
                                        'rating': 2},
                                       {'label': 'Filipino',
                                        'percentage': 14,
                                        'rating': 9},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5468,
                     'lat': 37.331165,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5468-Cedar-Grove-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5468-Cedar-Grove-Elementary-School/',
                         'reviews': '/california/san-jose/5468-Cedar-Grove-Elementary-School/#Reviews'},
                     'lon': -121.79361,
                     'name': 'Cedar Grove Elementary School',
                     'numReviews': 25,
                     'parentRating': 4,
                     'percentLowIncome': '41%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3276 Cortona Drive',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 1094,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 12,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 70, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 10,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 8, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 5,
                                        'rating': 10},
                                       {'label': 'Filipino',
                                        'percentage': 6,
                                        'rating': 10}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 5469,
                     'lat': 37.312332,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5469-Chaboya-Middle-School/#College_success',
                               'profile': '/california/san-jose/5469-Chaboya-Middle-School/',
                               'reviews': '/california/san-jose/5469-Chaboya-Middle-School/#Reviews'},
                     'lon': -121.767998,
                     'name': 'Chaboya Middle School',
                     'numReviews': 33,
                     'parentRating': 3,
                     'percentLowIncome': '12%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Equity Overview Rating': 6, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1460 Colt Way',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 420,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 59,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 35, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 49,
                                        'rating': 3},
                                       {'label': 'Filipino', 'percentage': 6},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5470,
                     'lat': 37.301102,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5470-Dove-Hill-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5470-Dove-Hill-Elementary-School/',
                         'reviews': '/california/san-jose/5470-Dove-Hill-Elementary-School/#Reviews'},
                     'lon': -121.80983,
                     'name': 'Dove Hill Elementary School',
                     'numReviews': 22,
                     'parentRating': 4,
                     'percentLowIncome': '59%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3010 Fowler Road',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 738,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 9,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 75, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 8,
                                        'rating': 2},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'White', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5471,
                     'lat': 37.310524,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5471-Evergreen-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5471-Evergreen-Elementary-School/',
                         'reviews': '/california/san-jose/5471-Evergreen-Elementary-School/#Reviews'},
                     'lon': -121.777931,
                     'name': 'Evergreen Elementary School',
                     'numReviews': 42,
                     'parentRating': 5,
                     'percentLowIncome': '9%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2995 Rossmore Way',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 562,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 45,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 47, 'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 36,
                                        'rating': 2},
                                       {'label': 'Filipino', 'percentage': 7},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5472,
                     'lat': 37.318748,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5472-Holly-Oak-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5472-Holly-Oak-Elementary-School/',
                         'reviews': '/california/san-jose/5472-Holly-Oak-Elementary-School/#Reviews'},
                     'lon': -121.797653,
                     'name': 'Holly Oak Elementary School',
                     'numReviews': 15,
                     'parentRating': 3,
                     'percentLowIncome': '45%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4280 Partridge Drive',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 316,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 22,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 41, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 29,
                                        'rating': 2},
                                       {'label': 'Two or more races', 'percentage': 12},
                                       {'label': 'White', 'percentage': 8},
                                       {'label': 'Filipino', 'percentage': 6},
                                       {'label': 'African American', 'percentage': 4}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5473,
                     'lat': 37.302517,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5473-Laurelwood-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5473-Laurelwood-Elementary-School/',
                         'reviews': '/california/san-jose/5473-Laurelwood-Elementary-School/#Reviews'},
                     'lon': -121.780029,
                     'name': 'Laurelwood Elementary School',
                     'numReviews': 20,
                     'parentRating': 4,
                     'percentLowIncome': '22%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 18,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1865 Monrovia Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 733,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 51,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 32, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 49,
                                        'rating': 2},
                                       {'label': 'White', 'percentage': 3, 'rating': 6},
                                       {'label': 'Two or more races',
                                        'percentage': 3,
                                        'rating': 8},
                                       {'label': 'Filipino',
                                        'percentage': 9,
                                        'rating': 6},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 5474,
                     'lat': 37.314281,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5474-George-V.-Leyva-Intermediate-School/#College_success',
                         'profile': '/california/san-jose/5474-George-V.-Leyva-Intermediate-School/',
                         'reviews': '/california/san-jose/5474-George-V.-Leyva-Intermediate-School/#Reviews'},
                     'lon': -121.81263,
                     'name': 'George V. Leyva Intermediate School',
                     'numReviews': 31,
                     'parentRating': 4,
                     'percentLowIncome': '51%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Equity Overview Rating': 3, 'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4121 Mackin Woods Lane',
                                 'street2': '',
                                 'zip': '95135'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 657,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 7},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 82, 'rating': 10},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Hispanic', 'percentage': 5},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 2}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5475,
                     'lat': 37.312016,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5475-Tom-Matsumoto-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5475-Tom-Matsumoto-Elementary-School/',
                         'reviews': '/california/san-jose/5475-Tom-Matsumoto-Elementary-School/#Reviews'},
                     'lon': -121.765022,
                     'name': 'Tom Matsumoto Elementary School',
                     'numReviews': 19,
                     'parentRating': 3,
                     'percentLowIncome': '7%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 7, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3200 Millbrook Drive',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 618,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 33,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 55, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 29,
                                        'rating': 5},
                                       {'label': 'Filipino', 'percentage': 9},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5476,
                     'lat': 37.319489,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5476-Millbrook-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5476-Millbrook-Elementary-School/',
                         'reviews': '/california/san-jose/5476-Millbrook-Elementary-School/#Reviews'},
                     'lon': -121.788048,
                     'name': 'Millbrook Elementary School',
                     'numReviews': 34,
                     'parentRating': 4,
                     'percentLowIncome': '33%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2010 Daniel Maloney Drive',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 423,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 51,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Asian', 'percentage': 38, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 46,
                                        'rating': 1},
                                       {'label': 'Filipino', 'percentage': 10},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5477,
                     'lat': 37.307735,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5477-John-J.-Montgomery-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5477-John-J.-Montgomery-Elementary-School/',
                         'reviews': '/california/san-jose/5477-John-J.-Montgomery-Elementary-School/#Reviews'},
                     'lon': -121.801079,
                     'name': 'John J. Montgomery Elementary School',
                     'numReviews': 15,
                     'parentRating': 4,
                     'percentLowIncome': '51%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3241 Remington Way',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 625,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 22,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 66, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 18,
                                        'rating': 5},
                                       {'label': 'Filipino', 'percentage': 8},
                                       {'label': 'Two or more races', 'percentage': 5},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5478,
                     'lat': 37.328175,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5478-Norwood-Creek-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5478-Norwood-Creek-Elementary-School/',
                         'reviews': '/california/san-jose/5478-Norwood-Creek-Elementary-School/#Reviews'},
                     'lon': -121.78862,
                     'name': 'Norwood Creek Elementary School',
                     'numReviews': 21,
                     'parentRating': 4,
                     'percentLowIncome': '22%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3190 Quimby Road',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 980,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 28,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 59, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 22,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 5, 'rating': 8},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'Filipino',
                                        'percentage': 8,
                                        'rating': 9},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 5479,
                     'lat': 37.324776,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5479-Quimby-Oak-Middle-School/#College_success',
                               'profile': '/california/san-jose/5479-Quimby-Oak-Middle-School/',
                               'reviews': '/california/san-jose/5479-Quimby-Oak-Middle-School/#Reviews'},
                     'lon': -121.787079,
                     'name': 'Quimby Oak Middle School',
                     'numReviews': 26,
                     'parentRating': 4,
                     'percentLowIncome': '28%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Equity Overview Rating': 5, 'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5000 Farnsworth Drive',
                                 'street2': '',
                                 'zip': '95138'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 607,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 5},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 61, 'rating': 10},
                                       {'label': 'White', 'percentage': 22, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 9,
                                        'rating': 10},
                                       {'label': 'Hispanic', 'percentage': 4},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5480,
                     'lat': 37.284241,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5480-Silver-Oak-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5480-Silver-Oak-Elementary-School/',
                         'reviews': '/california/san-jose/5480-Silver-Oak-Elementary-School/#Reviews'},
                     'lon': -121.773689,
                     'name': 'Silver Oak Elementary School',
                     'numReviews': 19,
                     'parentRating': 4,
                     'percentLowIncome': '5%',
                     'pinned': null,
                     'rating': 9,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2025 Clarice Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 525,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 70,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Asian', 'percentage': 22, 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 65,
                                        'rating': 1},
                                       {'label': 'Filipino', 'percentage': 7},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5481,
                     'lat': 37.326252,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5481-Katherine-R.-Smith-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5481-Katherine-R.-Smith-Elementary-School/',
                         'reviews': '/california/san-jose/5481-Katherine-R.-Smith-Elementary-School/#Reviews'},
                     'lon': -121.82206,
                     'name': 'Katherine R. Smith Elementary School',
                     'numReviews': 10,
                     'parentRating': 4,
                     'percentLowIncome': '70%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2655 Alvin Avenue',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 633,
                     'districtName': 'Evergreen Elementary School District',
                     'enrollment': 385,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 61,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Asian', 'percentage': 28, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 56,
                                        'rating': 2},
                                       {'label': 'Filipino', 'percentage': 8},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 2},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5482,
                     'lat': 37.316189,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5482-O.-B.-Whaley-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5482-O.-B.-Whaley-Elementary-School/',
                         'reviews': '/california/san-jose/5482-O.-B.-Whaley-Elementary-School/#Reviews'},
                     'lon': -121.824242,
                     'name': 'O. B. Whaley Elementary School',
                     'numReviews': 22,
                     'parentRating': 5,
                     'percentLowIncome': '61%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1702 Mclaughlin Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 345,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 84,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Asian', 'percentage': 23, 'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 68,
                                        'rating': 2},
                                       {'label': 'Filipino',
                                        'percentage': 6,
                                        'rating': 5},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 5483,
                     'lat': 37.325455,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5483-Bridges-Academy/#College_success',
                               'profile': '/california/san-jose/5483-Bridges-Academy/',
                               'reviews': '/california/san-jose/5483-Bridges-Academy/#Reviews'},
                     'lon': -121.846458,
                     'name': 'Bridges Academy',
                     'numReviews': 30,
                     'parentRating': 3,
                     'percentLowIncome': '84%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 18,
                     'subratings': {'Equity Overview Rating': 2, 'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '420 Tully Road',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 665,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 77,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 41, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 52,
                                        'rating': 2},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5484,
                     'lat': 37.305454,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5484-Franklin-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5484-Franklin-Elementary-School/',
                         'reviews': '/california/san-jose/5484-Franklin-Elementary-School/#Reviews'},
                     'lon': -121.851067,
                     'name': 'Franklin Elementary School',
                     'numReviews': 15,
                     'parentRating': 4,
                     'percentLowIncome': '77%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '725 Hellyer Avenue',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 402,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 73,
                                        'rating': 7},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 30, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 61,
                                        'rating': 4},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5485,
                     'lat': 37.284439,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5485-G.-W.-Hellyer-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5485-G.-W.-Hellyer-Elementary-School/',
                         'reviews': '/california/san-jose/5485-G.-W.-Hellyer-Elementary-School/#Reviews'},
                     'lon': -121.819641,
                     'name': 'G. W. Hellyer Elementary School',
                     'numReviews': 26,
                     'parentRating': 4,
                     'percentLowIncome': '73%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3200 Water Street',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 549,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 80,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Asian', 'percentage': 21, 'rating': 7},
                                       {'label': 'Hispanic',
                                        'percentage': 70,
                                        'rating': 2},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5486,
                     'lat': 37.288155,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5486-Captain-Jason-M.-Dahl-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5486-Captain-Jason-M.-Dahl-Elementary-School/',
                         'reviews': '/california/san-jose/5486-Captain-Jason-M.-Dahl-Elementary-School/#Reviews'},
                     'lon': -121.840027,
                     'name': 'Captain Jason M. Dahl Elementary School',
                     'numReviews': 6,
                     'parentRating': 4,
                     'percentLowIncome': '80%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1602 Lucretia Avenue',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 390,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 75,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Asian', 'percentage': 24, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 66,
                                        'rating': 2},
                                       {'label': 'Filipino', 'percentage': 7},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5487,
                     'lat': 37.324997,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5487-Robert-F.-Kennedy-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5487-Robert-F.-Kennedy-Elementary-School/',
                         'reviews': '/california/san-jose/5487-Robert-F.-Kennedy-Elementary-School/#Reviews'},
                     'lon': -121.852402,
                     'name': 'Robert F. Kennedy Elementary School',
                     'numReviews': 15,
                     'parentRating': 4,
                     'percentLowIncome': '75%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '455 Los Arboles Street',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 353,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 85,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 79,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 15},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-3',
                     'highlighted': false,
                     'id': 5488,
                     'lat': 37.285606,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5488-Los-Arboles-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5488-Los-Arboles-Elementary-School/',
                         'reviews': '/california/san-jose/5488-Los-Arboles-Elementary-School/#Reviews'},
                     'lon': -121.831268,
                     'name': 'Los Arboles Elementary School',
                     'numReviews': 3,
                     'parentRating': 4,
                     'percentLowIncome': '85%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Equity Overview Rating': 2, 'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '651 Macredes Avenue',
                                 'street2': '',
                                 'zip': '95116'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 286,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 86,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 90,
                                        'rating': 3},
                                       {'label': 'Asian', 'percentage': 8},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5489,
                     'lat': 37.337475,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5489-McKinley-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5489-McKinley-Elementary-School/',
                         'reviews': '/california/san-jose/5489-McKinley-Elementary-School/#Reviews'},
                     'lon': -121.860863,
                     'name': 'McKinley Elementary School',
                     'numReviews': 3,
                     'parentRating': 3,
                     'percentLowIncome': '86%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 17,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1250 Taper Lane',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 501,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 77,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 24, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 64,
                                        'rating': 5},
                                       {'label': 'Filipino', 'percentage': 8},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5490,
                     'lat': 37.321842,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5490-Jeanne-R.-Meadows-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5490-Jeanne-R.-Meadows-Elementary-School/',
                         'reviews': '/california/san-jose/5490-Jeanne-R.-Meadows-Elementary-School/#Reviews'},
                     'lon': -121.840103,
                     'name': 'Jeanne R. Meadows Elementary School',
                     'numReviews': 14,
                     'parentRating': 5,
                     'percentLowIncome': '77%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1313 Audubon Drive',
                                 'street2': '',
                                 'zip': '95122'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 423,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 92,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 88,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 9},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5491,
                     'lat': 37.32806,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5491-Santee-Elementary-School/#College_success',
                               'profile': '/california/san-jose/5491-Santee-Elementary-School/',
                               'reviews': '/california/san-jose/5491-Santee-Elementary-School/#Reviews'},
                     'lon': -121.84671,
                     'name': 'Santee Elementary School',
                     'numReviews': 19,
                     'parentRating': 3,
                     'percentLowIncome': '92%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3975 Mira Loma',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 383,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 89,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 87,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 8},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '4-8',
                     'highlighted': false,
                     'id': 5492,
                     'lat': 37.282379,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5492-Daniel-Lairon-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5492-Daniel-Lairon-Elementary-School/',
                         'reviews': '/california/san-jose/5492-Daniel-Lairon-Elementary-School/#Reviews'},
                     'lon': -121.82885,
                     'name': 'Daniel Lairon Elementary School',
                     'numReviews': 4,
                     'parentRating': 5,
                     'percentLowIncome': '89%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2605 Gassmann Drive',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 681,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 67,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 42, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 46,
                                        'rating': 3},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 5493,
                     'lat': 37.308514,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5493-Stonegate-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5493-Stonegate-Elementary-School/',
                         'reviews': '/california/san-jose/5493-Stonegate-Elementary-School/#Reviews'},
                     'lon': -121.836349,
                     'name': 'Stonegate Elementary School',
                     'numReviews': 21,
                     'parentRating': 4,
                     'percentLowIncome': '67%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '653 Sylvandale Avenue',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 574,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 76,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Asian', 'percentage': 33, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 57,
                                        'rating': 1},
                                       {'label': 'Filipino',
                                        'percentage': 6,
                                        'rating': 6},
                                       {'label': 'African American', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 5494,
                     'lat': 37.287865,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5494-Sylvandale-Middle-School/#College_success',
                               'profile': '/california/san-jose/5494-Sylvandale-Middle-School/',
                               'reviews': '/california/san-jose/5494-Sylvandale-Middle-School/#Reviews'},
                     'lon': -121.82531,
                     'name': 'Sylvandale Middle School',
                     'numReviews': 16,
                     'parentRating': 4,
                     'percentLowIncome': '76%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Equity Overview Rating': 3, 'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2880 Aetna Way',
                                 'street2': '',
                                 'zip': '95121'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 634,
                     'districtName': 'Franklin-Mckinley Elementary School District',
                     'enrollment': 440,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 62,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 45, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 44,
                                        'rating': 2},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 5495,
                     'lat': 37.306213,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5495-Windmill-Springs-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5495-Windmill-Springs-Elementary-School/',
                         'reviews': '/california/san-jose/5495-Windmill-Springs-Elementary-School/#Reviews'},
                     'lon': -121.82428,
                     'name': 'Windmill Springs Elementary School',
                     'numReviews': 10,
                     'parentRating': 5,
                     'percentLowIncome': '62%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1280 Johnson Avenue',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {'school_value': '87%',
                                               'state_average': '64%'},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Sunnyvale',
                     'districtId': 635,
                     'districtName': 'Fremont Union High School District',
                     'enrollment': 1880,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 5,
                                        'rating': 10},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 84, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 4,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 7, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '9-12',
                     'highlighted': false,
                     'id': 5499,
                     'lat': 37.300713,
                     'levelCode': 'h',
                     'links': {'collegeSuccess': '/california/san-jose/5499-Lynbrook-High-School/#College_success',
                               'profile': '/california/san-jose/5499-Lynbrook-High-School/',
                               'reviews': '/california/san-jose/5499-Lynbrook-High-School/#Reviews'},
                     'lon': -122.00647,
                     'name': 'Lynbrook High School',
                     'numReviews': 62,
                     'parentRating': 4,
                     'percentLowIncome': '5%',
                     'pinned': null,
                     'rating': 10,
                     'ratingScale': 'Top rated',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'College Readiness Rating': 10,
                                    'Equity Overview Rating': 10,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4 Wabash Avenue',
                                 'street2': '',
                                 'zip': '95128'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 642,
                     'districtName': 'Luther Burbank School District',
                     'enrollment': 516,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 84,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 91,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Two or more races', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'Asian', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 5532,
                     'lat': 37.323555,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5532-Luther-Burbank-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5532-Luther-Burbank-Elementary-School/',
                         'reviews': '/california/san-jose/5532-Luther-Burbank-Elementary-School/#Reviews'},
                     'lon': -121.926697,
                     'name': 'Luther Burbank Elementary School',
                     'numReviews': 7,
                     'parentRating': 3,
                     'percentLowIncome': '84%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '121 Avenida Grande',
                                 'street2': '',
                                 'zip': '95139'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Morgan Hill',
                     'districtId': 643,
                     'districtName': 'Morgan Hill Unified School District',
                     'enrollment': 501,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 39,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 6},
                                       {'label': 'Asian', 'percentage': 12, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 48,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 23, 'rating': 7},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5540,
                     'lat': 37.228985,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5540-Los-Paseos-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5540-Los-Paseos-Elementary-School/',
                         'reviews': '/california/san-jose/5540-Los-Paseos-Elementary-School/#Reviews'},
                     'lon': -121.767387,
                     'name': 'Los Paseos Elementary School',
                     'numReviews': 33,
                     'parentRating': 5,
                     'percentLowIncome': '39%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 31,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 6},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '141 Avenida Espana',
                                 'street2': '',
                                 'zip': '95139'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'Morgan Hill',
                     'districtId': 643,
                     'districtName': 'Morgan Hill Unified School District',
                     'enrollment': 742,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 34,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 16, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 46,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 26, 'rating': 8},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 7},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5541,
                     'lat': 37.225582,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5541-Martin-Murphy-Middle-School/#College_success',
                         'profile': '/california/san-jose/5541-Martin-Murphy-Middle-School/',
                         'reviews': '/california/san-jose/5541-Martin-Murphy-Middle-School/#Reviews'},
                     'lon': -121.76693,
                     'name': 'Martin Murphy Middle School',
                     'numReviews': 18,
                     'parentRating': 4,
                     'percentLowIncome': '34%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4000 Rhoda Drive',
                                 'street2': '',
                                 'zip': '95117'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 646,
                     'districtName': 'Moreland School District',
                     'enrollment': 386,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 75,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 78,
                                        'rating': 4},
                                       {'label': 'African American', 'percentage': 6},
                                       {'label': 'Asian', 'percentage': 6},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Two or more races', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5560,
                     'lat': 37.304661,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5560-Leroy-Anderson-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5560-Leroy-Anderson-Elementary-School/',
                         'reviews': '/california/san-jose/5560-Leroy-Anderson-Elementary-School/#Reviews'},
                     'lon': -121.971497,
                     'name': 'Leroy Anderson Elementary School',
                     'numReviews': 21,
                     'parentRating': 4,
                     'percentLowIncome': '75%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4845 Bucknall Road',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 646,
                     'districtName': 'Moreland School District',
                     'enrollment': 666,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 14,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 40, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 19,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 28, 'rating': 10},
                                       {'label': 'Two or more races', 'percentage': 8},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5561,
                     'lat': 37.283417,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5561-Gussie-M.-Baker-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5561-Gussie-M.-Baker-Elementary-School/',
                         'reviews': '/california/san-jose/5561-Gussie-M.-Baker-Elementary-School/#Reviews'},
                     'lon': -121.987122,
                     'name': 'Gussie M. Baker Elementary School',
                     'numReviews': 66,
                     'parentRating': 4,
                     'percentLowIncome': '14%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 8,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4600 Student Lane',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 646,
                     'districtName': 'Moreland School District',
                     'enrollment': 944,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 34,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 25, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 4,
                                        'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 36,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 23, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 7,
                                        'rating': 10},
                                       {'label': 'Filipino',
                                        'percentage': 3,
                                        'rating': 10},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5562,
                     'lat': 37.290192,
                     'levelCode': 'm',
                     'links': {'collegeSuccess': '/california/san-jose/5562-Moreland-Middle-School/#College_success',
                               'profile': '/california/san-jose/5562-Moreland-Middle-School/',
                               'reviews': '/california/san-jose/5562-Moreland-Middle-School/#Reviews'},
                     'lon': -121.984108,
                     'name': 'Moreland Middle School',
                     'numReviews': 29,
                     'parentRating': 4,
                     'percentLowIncome': '34%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5140 Country Lane',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 646,
                     'districtName': 'Moreland School District',
                     'enrollment': 613,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 10,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 52, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 10,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 26, 'rating': 10},
                                       {'label': 'Two or more races', 'percentage': 6},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5563,
                     'lat': 37.298473,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5563-Country-Lane-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5563-Country-Lane-Elementary-School/',
                         'reviews': '/california/san-jose/5563-Country-Lane-Elementary-School/#Reviews'},
                     'lon': -121.991928,
                     'name': 'Country Lane Elementary School',
                     'numReviews': 125,
                     'parentRating': 4,
                     'percentLowIncome': '10%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '4835 Doyle Road',
                                 'street2': '',
                                 'zip': '95129'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 646,
                     'districtName': 'Moreland School District',
                     'enrollment': 950,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 16,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 10},
                                       {'label': 'Asian', 'percentage': 41, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 21,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 27, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 6,
                                        'rating': 10},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 5565,
                     'lat': 37.303398,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/5565-Easterbrook-Discovery/#College_success',
                               'profile': '/california/san-jose/5565-Easterbrook-Discovery/',
                               'reviews': '/california/san-jose/5565-Easterbrook-Discovery/#Reviews'},
                     'lon': -121.986633,
                     'name': 'Easterbrook Discovery',
                     'numReviews': 47,
                     'parentRating': 5,
                     'percentLowIncome': '16%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 10},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3750 Gleason Avenue',
                                 'street2': '',
                                 'zip': '95130'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 646,
                     'districtName': 'Moreland School District',
                     'enrollment': 581,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 29,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 28, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 32,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 23, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 9,
                                        'rating': 10},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5567,
                     'lat': 37.29744,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5567-George-C.-Payne-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5567-George-C.-Payne-Elementary-School/',
                         'reviews': '/california/san-jose/5567-George-C.-Payne-Elementary-School/#Reviews'},
                     'lon': -121.967422,
                     'name': 'George C. Payne Elementary School',
                     'numReviews': 39,
                     'parentRating': 5,
                     'percentLowIncome': '29%',
                     'pinned': null,
                     'rating': 7,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1944 Flint Avenue',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 647,
                     'districtName': 'Mount Pleasant Elementary School District',
                     'enrollment': 556,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 78,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Asian', 'percentage': 17, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 74,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': '6-8',
                     'highlighted': false,
                     'id': 5569,
                     'lat': 37.347153,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5569-August-Boeger-Middle-School/#College_success',
                         'profile': '/california/san-jose/5569-August-Boeger-Middle-School/',
                         'reviews': '/california/san-jose/5569-August-Boeger-Middle-School/#Reviews'},
                     'lon': -121.80143,
                     'name': 'August Boeger Middle School',
                     'numReviews': 57,
                     'parentRating': 4,
                     'percentLowIncome': '78%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '1966 Flint Avenue',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 647,
                     'districtName': 'Mount Pleasant Elementary School District',
                     'enrollment': 612,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 61,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 83,
                                        'rating': 4},
                                       {'label': 'Asian', 'percentage': 6},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 5570,
                     'lat': 37.34692,
                     'levelCode': 'e,m',
                     'links': {'collegeSuccess': '/california/san-jose/5570-Ida-Jew-Academy/#College_success',
                               'profile': '/california/san-jose/5570-Ida-Jew-Academy/',
                               'reviews': '/california/san-jose/5570-Ida-Jew-Academy/#Reviews'},
                     'lon': -121.801079,
                     'name': 'Ida Jew Academy',
                     'numReviews': 86,
                     'parentRating': 4,
                     'percentLowIncome': '61%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'charter',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 5,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '14275 Candler Drive',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 647,
                     'districtName': 'Mount Pleasant Elementary School District',
                     'enrollment': 293,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 81,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Hispanic',
                                        'percentage': 86,
                                        'rating': 3},
                                       {'label': 'Asian', 'percentage': 9},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'White', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5571,
                     'lat': 37.354084,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5571-Mount-Pleasant-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5571-Mount-Pleasant-Elementary-School/',
                         'reviews': '/california/san-jose/5571-Mount-Pleasant-Elementary-School/#Reviews'},
                     'lon': -121.811043,
                     'name': 'Mount Pleasant Elementary School',
                     'numReviews': 15,
                     'parentRating': 3,
                     'percentLowIncome': '81%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '3411 Rocky Mountain Drive',
                                 'street2': '',
                                 'zip': '95127'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 647,
                     'districtName': 'Mount Pleasant Elementary School District',
                     'enrollment': 419,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 81,
                                        'rating': 1},
                                       {'label': 'All students', 'rating': 2},
                                       {'label': 'Hispanic',
                                        'percentage': 78,
                                        'rating': 1},
                                       {'label': 'Asian', 'percentage': 9},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5572,
                     'lat': 37.351574,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5572-Robert-Sanders-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5572-Robert-Sanders-Elementary-School/',
                         'reviews': '/california/san-jose/5572-Robert-Sanders-Elementary-School/#Reviews'},
                     'lon': -121.801773,
                     'name': 'Robert Sanders Elementary School',
                     'numReviews': 36,
                     'parentRating': 5,
                     'percentLowIncome': '81%',
                     'pinned': null,
                     'rating': 2,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 1,
                                    'Test Scores Rating': 2},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '2400 Flint Avenue',
                                 'street2': '',
                                 'zip': '95148'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 647,
                     'districtName': 'Mount Pleasant Elementary School District',
                     'enrollment': 349,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 67,
                                        'rating': 2},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Asian', 'percentage': 32, 'rating': 6},
                                       {'label': 'Hispanic',
                                        'percentage': 50,
                                        'rating': 2},
                                       {'label': 'White', 'percentage': 5},
                                       {'label': 'Two or more races', 'percentage': 3},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Pacific Islander', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-5',
                     'highlighted': false,
                     'id': 5573,
                     'lat': 37.339031,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5573-Valle-Vista-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5573-Valle-Vista-Elementary-School/',
                         'reviews': '/california/san-jose/5573-Valle-Vista-Elementary-School/#Reviews'},
                     'lon': -121.792007,
                     'name': 'Valle Vista Elementary School',
                     'numReviews': 24,
                     'parentRating': 4,
                     'percentLowIncome': '67%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 19,
                     'subratings': {'Academic Progress Rating': 3,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5800 Calpine Drive',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 514,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 52,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 21, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 51,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 12},
                                       {'label': 'Filipino', 'percentage': 6},
                                       {'label': 'Two or more races', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5582,
                     'lat': 37.246738,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5582-Anderson-Alex-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5582-Anderson-Alex-Elementary-School/',
                         'reviews': '/california/san-jose/5582-Anderson-Alex-Elementary-School/#Reviews'},
                     'lon': -121.811943,
                     'name': 'Anderson (Alex) Elementary School',
                     'numReviews': 32,
                     'parentRating': 4,
                     'percentLowIncome': '52%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '280 Martinvale Lane',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 485,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 42,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 20, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 49,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 13},
                                       {'label': 'Two or more races', 'percentage': 9},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 4},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5583,
                     'lat': 37.224098,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5583-Baldwin-Julia-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5583-Baldwin-Julia-Elementary-School/',
                         'reviews': '/california/san-jose/5583-Baldwin-Julia-Elementary-School/#Reviews'},
                     'lon': -121.784233,
                     'name': 'Baldwin (Julia) Elementary School',
                     'numReviews': 93,
                     'parentRating': 4,
                     'percentLowIncome': '42%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 17,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6610 San Ignacio Avenue',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 742,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 29,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 24, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 4,
                                        'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 42,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 17, 'rating': 8},
                                       {'label': 'Two or more races',
                                        'percentage': 7,
                                        'rating': 8},
                                       {'label': 'Filipino',
                                        'percentage': 5,
                                        'rating': 10},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 5584,
                     'lat': 37.227089,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5584-Bernal-Intermediate-School/#College_success',
                         'profile': '/california/san-jose/5584-Bernal-Intermediate-School/',
                         'reviews': '/california/san-jose/5584-Bernal-Intermediate-School/#Reviews'},
                     'lon': -121.79097,
                     'name': 'Bernal Intermediate School',
                     'numReviews': 24,
                     'parentRating': 4,
                     'percentLowIncome': '29%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Equity Overview Rating': 4, 'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '565 Coyote Road',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 375,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 71,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Hispanic',
                                        'percentage': 82,
                                        'rating': 4},
                                       {'label': 'Asian', 'percentage': 7},
                                       {'label': 'White', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 2},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 2},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 5586,
                     'lat': 37.279388,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5586-Christopher-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5586-Christopher-Elementary-School/',
                         'reviews': '/california/san-jose/5586-Christopher-Elementary-School/#Reviews'},
                     'lon': -121.817612,
                     'name': 'Christopher Elementary School',
                     'numReviews': 9,
                     'parentRating': 2,
                     'percentLowIncome': '71%',
                     'pinned': null,
                     'rating': 5,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5035 Edenview Drive',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 596,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 59,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 21, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 5,
                                        'rating': 1},
                                       {'label': 'Hispanic',
                                        'percentage': 61,
                                        'rating': 3},
                                       {'label': 'White', 'percentage': 7, 'rating': 6},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '7-8',
                     'highlighted': false,
                     'id': 5587,
                     'lat': 37.266422,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5587-Davis-Caroline-Intermediate-School/#College_success',
                         'profile': '/california/san-jose/5587-Davis-Caroline-Intermediate-School/',
                         'reviews': '/california/san-jose/5587-Davis-Caroline-Intermediate-School/#Reviews'},
                     'lon': -121.81192,
                     'name': 'Davis (Caroline) Intermediate School',
                     'numReviews': 21,
                     'parentRating': 4,
                     'percentLowIncome': '59%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Equity Overview Rating': 3, 'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5345 Avenida Almendros',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 556,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 40,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Asian', 'percentage': 11, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 66,
                                        'rating': 2},
                                       {'label': 'White', 'percentage': 10, 'rating': 5},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 2}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5588,
                     'lat': 37.256531,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5588-Del-Roble-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5588-Del-Roble-Elementary-School/',
                         'reviews': '/california/san-jose/5588-Del-Roble-Elementary-School/#Reviews'},
                     'lon': -121.835648,
                     'name': 'Del Roble Elementary School',
                     'numReviews': 28,
                     'parentRating': 4,
                     'percentLowIncome': '40%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '285 Azucar Avenue',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 485,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 81,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 3},
                                       {'label': 'Hispanic',
                                        'percentage': 85,
                                        'rating': 2},
                                       {'label': 'Asian', 'percentage': 10},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'White', 'percentage': 2},
                                       {'label': 'Filipino', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5589,
                     'lat': 37.26157,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5589-Edenvale-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5589-Edenvale-Elementary-School/',
                         'reviews': '/california/san-jose/5589-Edenvale-Elementary-School/#Reviews'},
                     'lon': -121.80265,
                     'name': 'Edenvale Elementary School',
                     'numReviews': 7,
                     'parentRating': 2,
                     'percentLowIncome': '81%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 4,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 3},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '530 Gettysburg Drive',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 638,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 24,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 17, 'rating': 9},
                                       {'label': 'Hispanic',
                                        'percentage': 35,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 32, 'rating': 8},
                                       {'label': 'Two or more races',
                                        'percentage': 10,
                                        'rating': 8},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 2}],
                     'gradeLevels': 'K-8',
                     'highlighted': false,
                     'id': 5590,
                     'lat': 37.247513,
                     'levelCode': 'e,m',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5590-Frost-Earl-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5590-Frost-Earl-Elementary-School/',
                         'reviews': '/california/san-jose/5590-Frost-Earl-Elementary-School/#Reviews'},
                     'lon': -121.837059,
                     'name': 'Frost (Earl) Elementary School',
                     'numReviews': 21,
                     'parentRating': 5,
                     'percentLowIncome': '24%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 27,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '511 Cozy Drive',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 620,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 36},
                                       {'label': 'Hispanic', 'percentage': 40},
                                       {'label': 'Asian', 'percentage': 28},
                                       {'label': 'White', 'percentage': 17},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5591,
                     'lat': 37.232605,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5591-Glider-Elementary-School/#College_success',
                               'profile': '/california/san-jose/5591-Glider-Elementary-School/',
                               'reviews': '/california/san-jose/5591-Glider-Elementary-School/#Reviews'},
                     'lon': -121.823402,
                     'name': 'Glider Elementary School',
                     'numReviews': 18,
                     'parentRating': 4,
                     'percentLowIncome': '36%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 26,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5035 Poston Drive',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 592,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 60,
                                        'rating': 4},
                                       {'label': 'All students', 'rating': 5},
                                       {'label': 'Asian', 'percentage': 16, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 58,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 10, 'rating': 8},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5592,
                     'lat': 37.261806,
                     'levelCode': 'e',
                     'links': {'collegeSuccess': '/california/san-jose/5592-Hayes-Elementary-School/#College_success',
                               'profile': '/california/san-jose/5592-Hayes-Elementary-School/',
                               'reviews': '/california/san-jose/5592-Hayes-Elementary-School/#Reviews'},
                     'lon': -121.826508,
                     'name': 'Hayes Elementary School',
                     'numReviews': 11,
                     'parentRating': 4,
                     'percentLowIncome': '60%',
                     'pinned': null,
                     'rating': 4,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 18,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 3,
                                    'Test Scores Rating': 5},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5955 Blossom Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 871,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 27,
                                        'rating': 6},
                                       {'label': 'All students', 'rating': 9},
                                       {'label': 'Asian', 'percentage': 17, 'rating': 10},
                                       {'label': 'African American',
                                        'percentage': 4,
                                        'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 43,
                                        'rating': 7},
                                       {'label': 'White', 'percentage': 26, 'rating': 10},
                                       {'label': 'Two or more races',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'Filipino',
                                        'percentage': 4,
                                        'rating': 9},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': '5-8',
                     'highlighted': false,
                     'id': 5593,
                     'lat': 37.239738,
                     'levelCode': 'm',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5593-Herman-Leonard-Intermediate-School/#College_success',
                         'profile': '/california/san-jose/5593-Herman-Leonard-Intermediate-School/',
                         'reviews': '/california/san-jose/5593-Herman-Leonard-Intermediate-School/#Reviews'},
                     'lon': -121.839752,
                     'name': 'Herman (Leonard) Intermediate School',
                     'numReviews': 28,
                     'parentRating': 4,
                     'percentLowIncome': '27%',
                     'pinned': null,
                     'rating': 8,
                     'ratingScale': 'Above average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 21,
                     'subratings': {'Academic Progress Rating': 7,
                                    'Equity Overview Rating': 6,
                                    'Test Scores Rating': 9},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5629 Lean Avenue',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 437,
                     'ethnicityInfo': [{'label': 'Low-income', 'percentage': 68},
                                       {'label': 'Hispanic', 'percentage': 69},
                                       {'label': 'Asian', 'percentage': 11},
                                       {'label': 'White', 'percentage': 8},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'Two or more races', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5594,
                     'lat': 37.250664,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5594-Miner-George-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5594-Miner-George-Elementary-School/',
                         'reviews': '/california/san-jose/5594-Miner-George-Elementary-School/#Reviews'},
                     'lon': -121.817558,
                     'name': 'Miner (George) Elementary School',
                     'numReviews': 20,
                     'parentRating': 3,
                     'percentLowIncome': '68%',
                     'pinned': null,
                     'rating': null,
                     'ratingScale': null,
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5920 Bufkin Drive',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 551,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 33,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 18, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 42,
                                        'rating': 4},
                                       {'label': 'White', 'percentage': 25, 'rating': 7},
                                       {'label': 'Two or more races', 'percentage': 9},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 3}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5595,
                     'lat': 37.241058,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5595-Oak-Ridge-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5595-Oak-Ridge-Elementary-School/',
                         'reviews': '/california/san-jose/5595-Oak-Ridge-Elementary-School/#Reviews'},
                     'lon': -121.821152,
                     'name': 'Oak Ridge Elementary School',
                     'numReviews': 25,
                     'parentRating': 4,
                     'percentLowIncome': '33%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 20,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '330 Bluefield Drive',
                                 'street2': '',
                                 'zip': '95136'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 579,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 46,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 7},
                                       {'label': 'Asian', 'percentage': 24, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 49,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 9, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 6,
                                        'rating': 8},
                                       {'label': 'African American', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'American Indian/Alaska Native',
                                        'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5596,
                     'lat': 37.271595,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5596-Parkview-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5596-Parkview-Elementary-School/',
                         'reviews': '/california/san-jose/5596-Parkview-Elementary-School/#Reviews'},
                     'lon': -121.845779,
                     'name': 'Parkview Elementary School',
                     'numReviews': 28,
                     'parentRating': 4,
                     'percentLowIncome': '46%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 23,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 7},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6280 Shadelands Drive',
                                 'street2': '',
                                 'zip': '95123'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 639,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 15,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 21, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 31,
                                        'rating': 6},
                                       {'label': 'White', 'percentage': 30, 'rating': 9},
                                       {'label': 'Two or more races',
                                        'percentage': 11,
                                        'rating': 8},
                                       {'label': 'Filipino', 'percentage': 4},
                                       {'label': 'African American', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5597,
                     'lat': 37.230824,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5597-Sakamoto-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5597-Sakamoto-Elementary-School/',
                         'reviews': '/california/san-jose/5597-Sakamoto-Elementary-School/#Reviews'},
                     'lon': -121.839188,
                     'name': 'Sakamoto Elementary School',
                     'numReviews': 38,
                     'parentRating': 5,
                     'percentLowIncome': '15%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 24,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '6200 Encinal Drive',
                                 'street2': '',
                                 'zip': '95119'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 623,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 29,
                                        'rating': 5},
                                       {'label': 'All students', 'rating': 8},
                                       {'label': 'Asian', 'percentage': 28, 'rating': 10},
                                       {'label': 'Hispanic',
                                        'percentage': 38,
                                        'rating': 5},
                                       {'label': 'White', 'percentage': 19, 'rating': 8},
                                       {'label': 'Two or more races', 'percentage': 7},
                                       {'label': 'Filipino', 'percentage': 5},
                                       {'label': 'African American', 'percentage': 2}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5599,
                     'lat': 37.234753,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5599-Santa-Teresa-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5599-Santa-Teresa-Elementary-School/',
                         'reviews': '/california/san-jose/5599-Santa-Teresa-Elementary-School/#Reviews'},
                     'lon': -121.795898,
                     'name': 'Santa Teresa Elementary School',
                     'numReviews': 35,
                     'parentRating': 5,
                     'percentLowIncome': '29%',
                     'pinned': null,
                     'rating': 6,
                     'ratingScale': 'Average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 25,
                     'subratings': {'Academic Progress Rating': 6,
                                    'Equity Overview Rating': 4,
                                    'Test Scores Rating': 8},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'},
                    {'address': {'city': 'San Jose',
                                 'street1': '5000 Lyng Drive',
                                 'street2': '',
                                 'zip': '95111'},
                     'assigned': null,
                     'collegeEnrollmentData': {},
                     'collegePersistentData': [],
                     'csaAwardYears': [],
                     'districtCity': 'San Jose',
                     'districtId': 650,
                     'districtName': 'Oak Grove Elementary School District',
                     'enrollment': 423,
                     'ethnicityInfo': [{'label': 'Low-income',
                                        'percentage': 71,
                                        'rating': 3},
                                       {'label': 'All students', 'rating': 4},
                                       {'label': 'Asian', 'percentage': 18, 'rating': 8},
                                       {'label': 'Hispanic',
                                        'percentage': 71,
                                        'rating': 2},
                                       {'label': 'African American', 'percentage': 3},
                                       {'label': 'Filipino', 'percentage': 3},
                                       {'label': 'Two or more races', 'percentage': 2},
                                       {'label': 'Pacific Islander', 'percentage': 1},
                                       {'label': 'White', 'percentage': 1}],
                     'gradeLevels': 'K-6',
                     'highlighted': false,
                     'id': 5600,
                     'lat': 37.271339,
                     'levelCode': 'e',
                     'links': {
                         'collegeSuccess': '/california/san-jose/5600-Stipe-Samuel-Elementary-School/#College_success',
                         'profile': '/california/san-jose/5600-Stipe-Samuel-Elementary-School/',
                         'reviews': '/california/san-jose/5600-Stipe-Samuel-Elementary-School/#Reviews'},
                     'lon': -121.806519,
                     'name': 'Stipe (Samuel) Elementary School',
                     'numReviews': 12,
                     'parentRating': 4,
                     'percentLowIncome': '71%',
                     'pinned': null,
                     'rating': 3,
                     'ratingScale': 'Below average',
                     'remediationData': [],
                     'schoolType': 'public',
                     'state': 'CA',
                     'studentsPerTeacher': 22,
                     'subratings': {'Academic Progress Rating': 5,
                                    'Equity Overview Rating': 2,
                                    'Test Scores Rating': 4},
                     'testScoreRatingForEthnicity': null,
                     'type': 'school'}]
