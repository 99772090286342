exports.LOS_ALTOS_LISTINGS = [
		{
			"closePrice": 2498000,
			"daysOnMovoto": 41,
			"id": "bc942af4-c36d-4278-96ee-83f17bfc1c58",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869886_0_q7mEB6_p.jpeg",
			"listDate": "2021-11-11 09:06:29",
			"listingAgent": "Pamela Blackman",
			"listPrice": 2498000,
			"lotSize": 10163,
			"sqftTotal": 2182,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869886",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 30,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1961,
			"zipCode": "94024",
			"path": "los-altos-ca/2100-fallen-leaf-a-b-ln-los-altos-ca-94024-100_ml81869886/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f3274000-3527-4734-aa18-dadb72f0017c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-11-11 17:12:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 09:06:29",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2932935",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.338617,
				"lng": -122.065324,
				"zipcode": "94024",
				"subPremise": "",
				"address": "2100 Fallen Leaf A-b Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2498000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2932935"
		},
		{
			"closePrice": 3500000,
			"daysOnMovoto": 35,
			"id": "4af13489-88f7-482d-9b65-286c0ca904fa",
			"tnImgPath": "https://pi.movoto.com/p/12/40972984_0_bbEbJQ_p.jpeg",
			"listDate": "2021-11-02 00:00:00",
			"listingAgent": "Lynn Chin",
			"listPrice": 3198000,
			"lotSize": 9450,
			"sqftTotal": 1096,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972984",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Legacy Real Estate & Assoc.",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94024",
			"path": "los-altos-ca/1290-golden-way-los-altos-ca-94024/pid_48lo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b26a36e8-fe57-4093-8b4f-b59321f90805",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-11-02 19:16:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-02 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=zfMmmGaP3u7",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.359728,
				"lng": -122.091334,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1290 Golden Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=zfMmmGaP3u7"
		},
		{
			"closePrice": 1800000,
			"daysOnMovoto": 53,
			"id": "3d02f60d-727e-4af1-bbd0-9664ad2f67f4",
			"tnImgPath": "https://pi.movoto.com/p/12/40969633_0_UmVQJb_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Sunil Sethi",
			"listPrice": 1850000,
			"lotSize": 1098,
			"sqftTotal": 1440,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969633",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sunil Sethi Real Estate",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1964,
			"zipCode": "94022",
			"path": "los-altos-ca/29-los-altos-sq-los-altos-ca-94022/pid_etjo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "07448028-e6b0-4224-8cdb-4dfbdc87ff69",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-10-14 20:21:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/3182774",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.398012,
				"lng": -122.111511,
				"zipcode": "94022",
				"subPremise": "",
				"address": "29 Los Altos Sq"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/3182774"
		},
		{
			"closePrice": 2950000,
			"daysOnMovoto": 278,
			"id": "b99f89fb-2783-4ad1-8f48-8f4a7875b4eb",
			"tnImgPath": "https://pi.movoto.com/p/110/421546284_0_ZNJ2YM_p.jpeg",
			"listDate": "2021-01-06 00:00:00",
			"listingAgent": "Ashley Barrington-Mace",
			"listPrice": 2995000,
			"lotSize": 0,
			"sqftTotal": 1318,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421546284",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sotheby's International Realty",
			"photoCount": 49,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1952,
			"zipCode": "94022",
			"path": "los-altos-ca/14-4th-st-los-altos-ca-94022/pid_1dgo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fa51b3a5-7945-4707-b283-248562d93887",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-11 00:00:00",
			"createdAt": "2021-05-13 17:18:54",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-06 00:00:00",
			"virtualTourLink": "http://www.14-4thStreet.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.381163,
				"lng": -122.116384,
				"zipcode": "94022",
				"subPremise": "",
				"address": "14 4th St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.14-4thStreet.com"
		},
		{
			"closePrice": 4100000,
			"daysOnMovoto": 2,
			"id": "d01c064a-21de-40a2-8b5b-e7bd994766e0",
			"tnImgPath": "https://pi.movoto.com/p/110/421597135_0_iRvmy2_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Roh Habibi",
			"listPrice": 3698000,
			"lotSize": 10804,
			"sqftTotal": 2523,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421597135",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 46,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1970,
			"zipCode": "94024",
			"path": "los-altos-ca/1644-fallen-leaf-ln-los-altos-ca-94024-100_81213372/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8617135e-d2e5-4373-b8a0-f9e842a8336b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-24 00:00:00",
			"createdAt": "2021-09-22 16:16:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/610620753",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.351134,
				"lng": -122.066178,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1644 Fallen Leaf Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/610620753"
		},
		{
			"closePrice": 3500000,
			"daysOnMovoto": 49,
			"id": "408e5c4e-e21f-4533-ae86-cae3f86f87c0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81854646_0_yuBM3U_p.jpeg",
			"listDate": "2021-07-21 14:32:11",
			"listingAgent": "David Troyer",
			"listPrice": 2998000,
			"lotSize": 20944,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81854646",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 8,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94024",
			"path": "los-altos-ca/0-joandra-ct-los-altos-ca-94024-100_ml81854646/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "78b93b99-8896-4d5b-8152-e1f436be9563",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-09 00:00:00",
			"createdAt": "2021-07-21 21:37:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-21 14:32:11",
			"virtualTourLink": "https://www.youtube.com/embed/xFACI02mB4Y",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"zipcode": "94024",
				"subPremise": "",
				"address": "0 Joandra Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/xFACI02mB4Y"
		},
		{
			"closePrice": 5200000,
			"daysOnMovoto": 34,
			"id": "77d9eeda-d45b-409b-8c39-d8facd2043b9",
			"tnImgPath": "https://pi.movoto.com/p/110/421585957_0_BeuNyb_p.jpeg",
			"listDate": "2021-08-01 00:00:00",
			"listingAgent": "Christopher Lim",
			"listPrice": 4888888,
			"lotSize": 57203,
			"sqftTotal": 4289,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421585957",
			"bath": 5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1985,
			"zipCode": "94024",
			"path": "los-altos-ca/11337-eastbrook-ave-los-altos-ca-94024/pid_j09q2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "31f6e9d4-c242-4a4d-846f-3f12b30848d4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-04 00:00:00",
			"createdAt": "2021-08-26 16:26:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-01 00:00:00",
			"virtualTourLink": "https://www.cbhometour.com/11337-Eastbrook-Ave-Los-Altos-CA-94024/index.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.348239,
				"lng": -122.105002,
				"zipcode": "94024",
				"subPremise": "",
				"address": "11337 Eastbrook Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.cbhometour.com/11337-Eastbrook-Ave-Los-Altos-CA-94024/index.html"
		},
		{
			"closePrice": 4650000,
			"daysOnMovoto": 55,
			"id": "4b7385f9-6b88-4f4e-97f5-89acef7d557f",
			"tnImgPath": "https://pi.movoto.com/p/12/40954714_0_6u6yZn_p.jpeg",
			"listDate": "2021-06-17 00:00:00",
			"listingAgent": "Mimi Costa",
			"listPrice": 4900000,
			"lotSize": 6650,
			"sqftTotal": 3582,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954714",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2006,
			"zipCode": "94022",
			"path": "los-altos-ca/490-orange-ave-los-altos-ca-94022-100_80918697/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -800000,
			"propertyId": "d60a98f2-7902-499c-acff-125054ac51d9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-11 00:00:00",
			"createdAt": "2021-06-17 16:11:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-17 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.374066,
				"lng": -122.117405,
				"zipcode": "94022",
				"subPremise": "",
				"address": "490 Orange Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4195000,
			"daysOnMovoto": 57,
			"id": "8b0df8f2-633d-4b55-a238-36c3c4529ef0",
			"tnImgPath": "https://pi.movoto.com/p/12/40950209_0_aFRR2y_p.jpeg",
			"listDate": "2021-05-19 00:00:00",
			"listingAgent": "Brenda Vance",
			"listPrice": 3988000,
			"lotSize": 10326,
			"sqftTotal": 2343,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40950209",
			"bath": 2.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94022",
			"path": "los-altos-ca/134-jardin-dr-los-altos-ca-94022-100_81318175/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "080f8e64-7412-4de5-b859-77e198abb0fb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-15 00:00:00",
			"createdAt": "2021-05-20 04:36:54",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-19 00:00:00",
			"virtualTourLink": "Https://134jardin.com/1837651?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.388427,
				"lng": -122.111037,
				"zipcode": "94022",
				"subPremise": "",
				"address": "134 Jardin Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4195000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://134jardin.com/1837651?idx=1"
		},
		{
			"closePrice": 1753000,
			"daysOnMovoto": 34,
			"id": "0ea9d8b4-788a-4413-97e3-86efbd343898",
			"tnImgPath": "https://pi.movoto.com/p/12/40953758_0_fZJQ6F_p.jpeg",
			"listDate": "2021-06-10 00:00:00",
			"listingAgent": "Akiko Lewis",
			"listPrice": 1795000,
			"lotSize": 5400,
			"sqftTotal": 996,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953758",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 17,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1946,
			"zipCode": "94024",
			"path": "los-altos-ca/951-dolores-ave-los-altos-ca-94024-100_80386261/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "92c00c5d-2912-4d65-854d-d63aba37930f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-14 00:00:00",
			"createdAt": "2021-06-11 02:41:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.354486,
				"lng": -122.088382,
				"zipcode": "94024",
				"subPremise": "",
				"address": "951 Dolores Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1753000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 126,
			"id": "1502a6fa-7781-42ba-b098-61ecbe09d45b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828057_0_6NJMAY_p.jpeg",
			"listDate": "2021-02-01 17:17:04",
			"listingAgent": "Mo Bani-Taba",
			"listPrice": 1799888,
			"lotSize": 13190,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828057",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 29,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "94024",
			"path": "los-altos-ca/899-madonna-way-los-altos-ca-94024-100_ml81776557/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50112,
			"propertyId": "424786cb-b14a-4755-9bb5-50827735cc0e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-08 00:00:00",
			"createdAt": "2021-02-02 01:22:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-01 17:17:04",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.36583,
				"lng": -122.110312,
				"zipcode": "94024",
				"subPremise": "",
				"address": "899 Madonna Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3100000,
			"daysOnMovoto": 0,
			"id": "8cc2a228-0cc4-4404-a609-ea02693f2d60",
			"tnImgPath": "https://pi.movoto.com/p/102/221043597_0_V2MFNQ_p.jpeg",
			"listDate": null,
			"listingAgent": "Doreun Ramalia",
			"listPrice": 2795000,
			"lotSize": 12341,
			"sqftTotal": 1770,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221043597",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Ramalia Real Estate",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1957,
			"zipCode": "94024",
			"path": "los-altos-ca/1448-brookmill-rd-los-altos-ca-94024/pid_kpto2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77e3979e-6eb9-42b6-bcae-4b9fa2c0399f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-08 00:00:00",
			"createdAt": "2021-05-05 18:44:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-05 18:44:28",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.353595,
				"lng": -122.064014,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1448 Brookmill Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3810000,
			"daysOnMovoto": 37,
			"id": "0d82199c-40ce-4374-b488-89b8f7183759",
			"tnImgPath": "https://pi.movoto.com/p/12/40947085_0_BZI237_p.jpeg",
			"listDate": "2021-04-25 00:00:00",
			"listingAgent": "Karen Neuer",
			"listPrice": 3100000,
			"lotSize": 11050,
			"sqftTotal": 1514,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40947085",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "The Neuer Group",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1953,
			"zipCode": "94024",
			"path": "los-altos-ca/1118-lisa-ln-los-altos-ca-94024/pid_rfpo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d2e851c2-e821-47dc-8d7a-f38f9d68c87b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-01 00:00:00",
			"createdAt": "2021-04-25 16:51:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-25 00:00:00",
			"virtualTourLink": "Https://virtualtourcafe.com/mls/4689102",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.354445,
				"lng": -122.082435,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1118 Lisa Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://virtualtourcafe.com/mls/4689102"
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 33,
			"id": "73805aa1-1cde-49a5-8911-cc26e2835f29",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-15 00:00:00",
			"listingAgent": "Gregg Fussell",
			"listPrice": 2188000,
			"lotSize": 2953,
			"sqftTotal": 1511,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81839164",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1994,
			"zipCode": "94022",
			"path": "los-altos-ca/79-chester-cir-los-altos-ca-94022/pid_khko2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d215aa97-2fe7-4913-a6db-ac9cc3cd4122",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-18 00:00:00",
			"createdAt": "2021-04-23 07:45:07",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.397987,
				"lng": -122.113258,
				"zipcode": "94022",
				"subPremise": "",
				"address": "79 Chester Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3550000,
			"daysOnMovoto": 42,
			"id": "19c26e1a-c3e2-4c59-b1df-650ae7e77d3a",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-06 00:00:00",
			"listingAgent": "David Troyer",
			"listPrice": 2998000,
			"lotSize": 9468,
			"sqftTotal": 1559,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81837648",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1952,
			"zipCode": "94024",
			"path": "los-altos-ca/927-linda-vista-way-los-altos-ca-94024/pid_q3no2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2fac59a7-63b8-447d-9765-346b699d1d64",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-18 00:00:00",
			"createdAt": "2021-04-23 07:50:38",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-06 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/qcgGqfvNZLI",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.359824,
				"lng": -122.089093,
				"zipcode": "94024",
				"subPremise": "",
				"address": "927 Linda Vista Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/qcgGqfvNZLI"
		},
		{
			"closePrice": 4300000,
			"daysOnMovoto": 42,
			"id": "e6a56733-5594-49ef-bf98-7f61ba9c9c2b",
			"tnImgPath": "https://pi.movoto.com/p/12/ML81837495_0_qzjEAf_p.jpeg",
			"listDate": "2021-04-05 00:00:00",
			"listingAgent": "Eric Fischer-Colbrie",
			"listPrice": 3498000,
			"lotSize": 25700,
			"sqftTotal": 2650,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81837495",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1939,
			"zipCode": "94024",
			"path": "los-altos-ca/705-vista-grande-ave-los-altos-ca-94024/pid_tapo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8c37a062-83c6-49d2-98fd-6284160bf2dc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-05-18 18:30:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-05 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/532408967",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.384398,
				"lng": -122.095661,
				"zipcode": "94024",
				"subPremise": "",
				"address": "705 Vista Grande Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/532408967"
		},
		{
			"closePrice": 8408000,
			"daysOnMovoto": 5,
			"id": "0309d22b-bf91-407f-8237-16769e79321a",
			"tnImgPath": "https://pi.movoto.com/p/110/421537909_0_6ERyEN_p.jpeg",
			"listDate": "2021-04-15 00:00:00",
			"listingAgent": "Lanting Chen",
			"listPrice": 7999000,
			"lotSize": 20467,
			"sqftTotal": 5415,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421537909",
			"bath": 6,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway Drysdale Properties",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2011,
			"zipCode": "94022",
			"path": "los-altos-ca/132-hawthorne-ave-los-altos-ca-94022-100_80827187/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "96ec9335-8f8e-40b1-bf22-85e4fe959e0d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-20 00:00:00",
			"createdAt": "2021-04-15 20:06:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-15 00:00:00",
			"virtualTourLink": "Https://my.matterport.com/show/?m=5dZB1c46mBR",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.378463,
				"lng": -122.111223,
				"zipcode": "94022",
				"subPremise": "",
				"address": "132 Hawthorne Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 8408000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://my.matterport.com/show/?m=5dZB1c46mBR"
		},
		{
			"closePrice": 3250000,
			"daysOnMovoto": 32,
			"id": "6ef11ced-b9f4-4491-837e-27e7ed0b0f6b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834260_0_6N62rY_p.jpeg",
			"listDate": "2021-03-15 00:00:00",
			"listingAgent": "Jennifer Cosgrove",
			"listPrice": 2988000,
			"lotSize": 12206,
			"sqftTotal": 2134,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834260",
			"bath": 2,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1958,
			"zipCode": "94024",
			"path": "los-altos-ca/1030-miramonte-ave-los-altos-ca-94024/pid_trno2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5dd046aa-368f-49c3-9ee8-8387f2067693",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-03-19 06:32:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-15 00:00:00",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2848294",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.364542,
				"lng": -122.087347,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1030 Miramonte Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2848294"
		},
		{
			"closePrice": 3850000,
			"daysOnMovoto": 38,
			"id": "ff48d5f3-058a-4629-b344-1de47a8a8db8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833063_0_jQAvmN_p.jpeg",
			"listDate": "2021-03-08 11:42:46",
			"listingAgent": "Om Talajia",
			"listPrice": 3348000,
			"lotSize": 12985,
			"sqftTotal": 1893,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833063",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1955,
			"zipCode": "94022",
			"path": "los-altos-ca/730-los-altos-ave-los-altos-ca-94022-100_924866/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "46dac25a-e0a1-4ac6-b67b-aaf3a719d5fb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-16 00:00:00",
			"createdAt": "2021-03-08 19:47:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-08 11:42:46",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2845338",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.394845,
				"lng": -122.121685,
				"zipcode": "94022",
				"subPremise": "",
				"address": "730 Los Altos Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2845338"
		},
		{
			"closePrice": 3850000,
			"daysOnMovoto": 35,
			"id": "93f62184-af02-468b-b832-8a679df41f9a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81833353_0_IzYMzV_p.jpeg",
			"listDate": "2021-03-10 06:56:30",
			"listingAgent": "Alexander Wihtol",
			"listPrice": 2988000,
			"lotSize": 10424,
			"sqftTotal": 2027,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81833353",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1952,
			"zipCode": "94022",
			"path": "los-altos-ca/449-panchita-way-los-altos-ca-94022/pid_vljo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3acc5b37-5566-4b88-9e5d-36529f2f3698",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-15 00:00:00",
			"createdAt": "2021-03-10 15:02:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-10 06:56:30",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2845473",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.389495,
				"lng": -122.110172,
				"zipcode": "94022",
				"subPremise": "",
				"address": "449 Panchita Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2845473"
		},
		{
			"closePrice": 2240000,
			"daysOnMovoto": 60,
			"id": "1273a24c-c25c-4870-8f3c-8aadbfc67a73",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829744_0_23Y7MA_p.jpeg",
			"listDate": "2021-02-13 08:54:34",
			"listingAgent": "Kristi Foxgrover",
			"listPrice": 2249000,
			"lotSize": 2705,
			"sqftTotal": 1840,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829744",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1972,
			"zipCode": "94022",
			"path": "los-altos-ca/40-bay-tree-ln-los-altos-ca-94022/pid_tbso2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50000,
			"propertyId": "7f6ccc8e-af8b-43be-8714-a390122cca61",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-15 00:00:00",
			"createdAt": "2021-02-13 16:57:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-13 08:54:34",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.367598,
				"lng": -122.117326,
				"zipcode": "94022",
				"subPremise": "",
				"address": "40 Bay Tree Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2240000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4375000,
			"daysOnMovoto": 39,
			"id": "dc42646d-02e4-472a-94f2-03fef9f4cb86",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832699_0_FyvVaY_p.jpeg",
			"listDate": "2021-03-05 11:57:56",
			"listingAgent": "David Troyer",
			"listPrice": 4269000,
			"lotSize": 11326,
			"sqftTotal": 3779,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832699",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1984,
			"zipCode": "94022",
			"path": "los-altos-ca/345-w-portola-ave-los-altos-ca-94022/pid_mofo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3aed303a-149b-4ac5-b795-4aec7a527531",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-03-05 20:07:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-05 11:57:56",
			"virtualTourLink": "https://www.youtube.com/embed/1hM15s-NnVI",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.397078,
				"lng": -122.121597,
				"zipcode": "94022",
				"subPremise": "",
				"address": "345 W Portola Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4375000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/1hM15s-NnVI"
		},
		{
			"closePrice": 1800000,
			"daysOnMovoto": 40,
			"id": "64108b5a-cbb5-45b4-82f6-e3306e037be8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832431_0_BfbjR3_p.jpeg",
			"listDate": "2021-03-04 08:03:44",
			"listingAgent": "Howard Bloom",
			"listPrice": 1575000,
			"lotSize": null,
			"sqftTotal": 1521,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832431",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1997,
			"zipCode": "94022",
			"path": "los-altos-ca/1-w-edith-ave-d218-los-altos-ca-94022/pid_i0fo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6c4dc35a-1f0e-4854-bb95-412b7b30fab1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-03-04 16:07:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-04 08:03:44",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2808534",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.381807,
				"lng": -122.115059,
				"zipcode": "94022",
				"subPremise": "APT D218",
				"address": "1 W Edith Ave #D218"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2808534"
		},
		{
			"closePrice": 4011000,
			"daysOnMovoto": 49,
			"id": "3c4ebb8b-72cd-4f3c-b9d4-9793f239d0e1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830958_0_MaZRYq_p.jpeg",
			"listDate": "2021-02-23 10:23:08",
			"listingAgent": "David Guercio",
			"listPrice": 4150000,
			"lotSize": 18814,
			"sqftTotal": 3126,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830958",
			"bath": 3,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1925,
			"zipCode": "94022",
			"path": "los-altos-ca/585-cherry-ave-los-altos-ca-94022/pid_l1lo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "439ca428-cf11-453a-b8eb-9112ad800e1c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-02-23 18:27:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 10:23:08",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2840706",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.391953,
				"lng": -122.117225,
				"zipcode": "94022",
				"subPremise": "",
				"address": "585 Cherry Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4011000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2840706"
		},
		{
			"closePrice": 1438000,
			"daysOnMovoto": 80,
			"id": "644167ed-3282-4d73-b616-942e3a7ee7f6",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826842_0_2Zfrvu_p.jpeg",
			"listDate": "2021-01-22 12:18:42",
			"listingAgent": "David Troyer",
			"listPrice": 1498000,
			"lotSize": null,
			"sqftTotal": 1302,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826842",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "94022",
			"path": "los-altos-ca/5100-el-camino-real-303-los-altos-ca-94022-100_81329975/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1e7c803e-c2c1-4ab8-8266-3b73d8571781",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-13 00:00:00",
			"createdAt": "2021-01-22 20:27:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-22 12:18:42",
			"virtualTourLink": "https://www.youtube.com/embed/EhbAvKJsxZc",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.395727,
				"lng": -122.103328,
				"zipcode": "94022",
				"subPremise": "APT 303",
				"address": "5100 El Camino Real #303"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1438000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/EhbAvKJsxZc"
		},
		{
			"closePrice": 3810000,
			"daysOnMovoto": 96,
			"id": "dc1ee983-b603-4466-b217-1a7e61ebae2e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824616_0_IUQIqj_p.jpeg",
			"listDate": "2021-01-05 11:51:35",
			"listingAgent": "Alex H. Wang",
			"listPrice": 3398000,
			"lotSize": 14475,
			"sqftTotal": 2578,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824616",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Rainmaker Real Estate",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1974,
			"zipCode": "94024",
			"path": "los-altos-ca/1171-saint-anthony-ct-los-altos-ca-94024/pid_vwar2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f00e0aa8-3f79-4b18-9dd2-b7c650eaf6d4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-12 00:00:00",
			"createdAt": "2021-03-12 23:17:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-05 11:51:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.342181,
				"lng": -122.078374,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1171 Saint Anthony Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3950000,
			"daysOnMovoto": 21,
			"id": "130b657f-c866-4a24-abd5-8fec472b3127",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81834686_0_iYq6a6_p.jpeg",
			"listDate": "2021-03-18 10:03:15",
			"listingAgent": "Rick Smith",
			"listPrice": 2999950,
			"lotSize": 12637,
			"sqftTotal": 1760,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81834686",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "KW Bay Area Estates",
			"photoCount": 21,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1938,
			"zipCode": "94022",
			"path": "los-altos-ca/120-coronado-ave-los-altos-ca-94022/pid_q9fo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 499950,
			"propertyId": "769f235a-eb67-487b-b57d-2104f37af2e6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-18 17:12:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-18 10:03:15",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2849817",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.385623,
				"lng": -122.116879,
				"zipcode": "94022",
				"subPremise": "",
				"address": "120 Coronado Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3950000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2849817"
		},
		{
			"closePrice": 4100000,
			"daysOnMovoto": 35,
			"id": "8e5f69ab-eb2f-4c73-a476-f0a1fbd50eec",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832459_0_J6qyvN_p.jpeg",
			"listDate": "2021-03-04 11:17:48",
			"listingAgent": "Kirk Mahncke",
			"listPrice": 3488000,
			"lotSize": 10646,
			"sqftTotal": 2374,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832459",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1963,
			"zipCode": "94022",
			"path": "los-altos-ca/360-fontainbleau-terrace-los-altos-ca-94022-100_ml80659444/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "69963a90-c472-4192-9b36-acae000442d9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-09 00:00:00",
			"createdAt": "2021-03-04 19:22:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-04 11:17:48",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2844058",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.388098,
				"lng": -122.112253,
				"zipcode": "94022",
				"subPremise": "",
				"address": "360 Fontainbleau Ter"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2844058"
		},
		{
			"closePrice": 2871000,
			"daysOnMovoto": 40,
			"id": "c87a561e-52f7-4dc8-90de-cee22f3bba33",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831229_0_yV6yzI_p.jpeg",
			"listDate": "2021-02-24 15:53:36",
			"listingAgent": "Fling Yang & Assoc",
			"listPrice": 2498000,
			"lotSize": 10411,
			"sqftTotal": 1836,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831229",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1953,
			"zipCode": "94024",
			"path": "los-altos-ca/1650-austin-ave-los-altos-ca-94024/pid_ezzq2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b9fdf49b-dac1-41f8-a70c-8c9bf14c4a47",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2021-02-24 23:57:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-24 15:53:36",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.351046,
				"lng": -122.074313,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1650 Austin Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2871000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2110000,
			"daysOnMovoto": 66,
			"id": "7385330d-e59b-4902-a97a-0d7d0adfdcae",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827781_0_MmvJFr_p.jpeg",
			"listDate": "2021-01-29 19:15:27",
			"listingAgent": "Radha Rustagi",
			"listPrice": 1999999,
			"lotSize": 8063,
			"sqftTotal": 1082,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827781",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty - Cupertino",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1956,
			"zipCode": "94022",
			"path": "los-altos-ca/566-lincoln-ave-los-altos-ca-94022/pid_9eko2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a6928022-26e0-4d89-9f35-50cd24036d60",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-06 00:00:00",
			"createdAt": "2021-01-30 03:17:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-29 19:15:27",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.372815,
				"lng": -122.114395,
				"zipcode": "94022",
				"subPremise": "",
				"address": "566 Lincoln Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2110000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3881051,
			"daysOnMovoto": 46,
			"id": "71fda25f-c94b-4e47-92c6-c061808f7b48",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830121_0_BYbZNn_p.jpeg",
			"listDate": "2021-02-17 08:46:56",
			"listingAgent": "Jennifer Paulson",
			"listPrice": 3250000,
			"lotSize": 9727,
			"sqftTotal": 2465,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830121",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 17,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1964,
			"zipCode": "94022",
			"path": "los-altos-ca/1051-via-del-pozo-los-altos-ca-94022/pid_yveo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c5a5507a-474e-485f-9292-c06e72777d51",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-05 00:00:00",
			"createdAt": "2021-02-17 16:52:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-17 08:46:56",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2836005",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.401177,
				"lng": -122.119268,
				"zipcode": "94022",
				"subPremise": "",
				"address": "1051 Via Del Pozo"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3881051,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2836005"
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 48,
			"id": "e1f85002-9861-4a50-b0ba-10a7c0f6eeda",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81829593_0_nFRmAV_p.jpeg",
			"listDate": "2021-02-12 10:21:13",
			"listingAgent": "Isaac Malek",
			"listPrice": 1850000,
			"lotSize": 1442,
			"sqftTotal": 1444,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81829593",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Alliance Bay Realty",
			"photoCount": 52,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1964,
			"zipCode": "94022",
			"path": "los-altos-ca/10-los-altos-sq-los-altos-ca-94022-100_80634439/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "438bcf15-228a-4683-8109-3bf5d2c47744",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-02-12 18:27:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-12 10:21:13",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2839408",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.398579,
				"lng": -122.11158,
				"zipcode": "94022",
				"subPremise": "",
				"address": "10 Los Altos Sq"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2839408"
		},
		{
			"closePrice": 989000,
			"daysOnMovoto": 9,
			"id": "9e9c18fc-8ee0-4f2e-a13a-72ad6981aacc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81835377_0_Maaay2_p.jpeg",
			"listDate": "2021-03-22 19:43:18",
			"listingAgent": "Hiep K. Nguyen",
			"listPrice": 995000,
			"lotSize": null,
			"sqftTotal": 846,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81835377",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1973,
			"zipCode": "94022",
			"path": "los-altos-ca/101-2nd-st-apt-5-los-altos-ca-94022-100_ml81835377/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ef4e0d0e-51c4-40de-b538-b12a219fd01a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-01 00:00:00",
			"createdAt": "2021-03-23 02:47:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-22 19:43:18",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.380477,
				"lng": -122.118039,
				"zipcode": "94022",
				"subPremise": "APT 5",
				"address": "101 2nd St #5"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 989000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4375000,
			"daysOnMovoto": 30,
			"id": "fa2be885-988d-4628-8781-777f3260a99a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831749_0_267BEM_p.jpeg",
			"listDate": "2021-02-27 14:13:45",
			"listingAgent": "Eric Fischer-Colbrie",
			"listPrice": 4250000,
			"lotSize": 11343,
			"sqftTotal": 3685,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831749",
			"bath": 6,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 60,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1967,
			"zipCode": "94024",
			"path": "los-altos-ca/695-san-martin-pl-los-altos-ca-94024-100_80317279/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "08d73b8c-003e-432b-8816-3cde4534beeb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-30 00:00:00",
			"createdAt": "2021-02-27 22:17:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-27 14:13:45",
			"virtualTourLink": "https://player.vimeo.com/video/518767223",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.378484,
				"lng": -122.096622,
				"zipcode": "94024",
				"subPremise": "",
				"address": "695 San Martin Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4375000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/518767223"
		},
		{
			"closePrice": 5100000,
			"daysOnMovoto": 223,
			"id": "6b5661a8-edc2-4639-924e-82f3275e019a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81806185_0_2qFyRF_p.jpeg",
			"listDate": "2020-08-18 00:00:00",
			"listingAgent": "Hanna Shacham",
			"listPrice": 5395000,
			"lotSize": 10533,
			"sqftTotal": 3235,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81806185",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 59,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2018,
			"zipCode": "94022",
			"path": "los-altos-ca/772-university-ave-los-altos-ca-94022-100_ml81699081/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -300000,
			"propertyId": "ebd1f114-28dc-44c1-a8d0-6674aecabf6e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-29 00:00:00",
			"createdAt": "2020-08-18 14:47:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-08-18 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/449800583",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.369011,
				"lng": -122.113504,
				"zipcode": "94022",
				"subPremise": "",
				"address": "772 University Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/449800583"
		},
		{
			"closePrice": 3675000,
			"daysOnMovoto": 44,
			"id": "36161be3-bd3a-4950-93ca-177871c9697b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828885_0_euiEUA_p.jpeg",
			"listDate": "2021-02-08 01:45:24",
			"listingAgent": "Kirsten Hagen",
			"listPrice": 3095000,
			"lotSize": 10481,
			"sqftTotal": 2265,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828885",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 46,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1970,
			"zipCode": "94024",
			"path": "los-altos-ca/1407-marinovich-way-los-altos-ca-94024/pid_22ro2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e9c15d5f-438a-49dc-9321-8049b16328d7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-25 00:00:00",
			"createdAt": "2021-02-13 17:47:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-08 01:45:24",
			"virtualTourLink": "Http://1407marinovichway.com/nb",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.356247,
				"lng": -122.07449,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1407 Marinovich Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://1407marinovichway.com/nb"
		},
		{
			"closePrice": 2850000,
			"daysOnMovoto": 45,
			"id": "a66c9da1-80f4-403a-814c-64339689b0b1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828869_0_jvJeee_p.jpeg",
			"listDate": "2021-02-07 18:19:20",
			"listingAgent": "Eric Fischer-Colbrie",
			"listPrice": 2988000,
			"lotSize": 11439,
			"sqftTotal": 2059,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828869",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1963,
			"zipCode": "94022",
			"path": "los-altos-ca/450-mariposa-ave-los-altos-ca-94022/pid_9rio2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -342000,
			"propertyId": "4038ea21-39a2-4f74-9478-2456bda1cc34",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-25 00:00:00",
			"createdAt": "2021-02-08 02:22:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-07 18:19:20",
			"virtualTourLink": "https://player.vimeo.com/video/509493210",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.384814,
				"lng": -122.123461,
				"zipcode": "94022",
				"subPremise": "",
				"address": "450 Mariposa Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/509493210"
		},
		{
			"closePrice": 2998880,
			"daysOnMovoto": 48,
			"id": "eb3e22b9-2b65-45dd-a1d9-8d0ee6413aa3",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828174_0_fjqYzi_p.jpeg",
			"listDate": "2021-02-02 14:06:18",
			"listingAgent": "David Troyer",
			"listPrice": 3089000,
			"lotSize": 10441,
			"sqftTotal": 1820,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828174",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1956,
			"zipCode": "94024",
			"path": "los-altos-ca/1436-brookmill-rd-los-altos-ca-94024/pid_d1wo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 91000,
			"propertyId": "62d1c95a-6577-4943-9032-ed57528804d9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-23 00:00:00",
			"createdAt": "2021-02-02 22:12:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-02 14:06:18",
			"virtualTourLink": "https://www.youtube.com/embed/64hTHx_pbqs",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.354071,
				"lng": -122.06337,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1436 Brookmill Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2998880,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/64hTHx_pbqs"
		},
		{
			"closePrice": 4100000,
			"daysOnMovoto": 28,
			"id": "f551326f-0314-4199-b5ab-be7529b9696d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830295_0_2fNBme_p.jpeg",
			"listDate": "2021-02-18 07:14:34",
			"listingAgent": "John W. King",
			"listPrice": 3498000,
			"lotSize": 18909,
			"sqftTotal": 2214,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830295",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Palo Alto",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1959,
			"zipCode": "94022",
			"path": "los-altos-ca/17-alma-ct-los-altos-ca-94022/pid_m5lo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eba6a7ff-2057-4c99-b478-b47b485df4f8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-02-18 15:17:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 07:14:34",
			"virtualTourLink": "Https://17almacourt36191mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.391274,
				"lng": -122.115319,
				"zipcode": "94022",
				"subPremise": "",
				"address": "17 Alma Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://17almacourt36191mls.f8re.com/"
		},
		{
			"closePrice": 3700000,
			"daysOnMovoto": 41,
			"id": "865a03a3-142f-42e6-a24a-7e4706cec58c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828609_0_M6qimy_p.jpeg",
			"listDate": "2021-02-05 10:02:51",
			"listingAgent": "Susan Sims",
			"listPrice": 3398000,
			"lotSize": 15856,
			"sqftTotal": 1802,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828609",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1952,
			"zipCode": "94022",
			"path": "los-altos-ca/705-casita-way-los-altos-ca-94022-202_or15118703/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "55fc6c10-dc4a-4593-af2d-8e71ab21466f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-19 00:00:00",
			"createdAt": "2021-02-05 18:14:57",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-05 10:02:51",
			"virtualTourLink": "Https://spark.adobe.com/page/7mYcQh2QapUYa/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.394306,
				"lng": -122.102828,
				"zipcode": "94022",
				"subPremise": "",
				"address": "705 Casita Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://spark.adobe.com/page/7mYcQh2QapUYa/"
		},
		{
			"closePrice": 2675000,
			"daysOnMovoto": 74,
			"id": "e6c2ae04-ff83-4b28-b444-ae04f1a0c6fc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824313_0_FQ6ZQ3_p.jpeg",
			"listDate": "2020-12-31 12:24:38",
			"listingAgent": "Gary Campi",
			"listPrice": 2795000,
			"lotSize": 6077,
			"sqftTotal": 1969,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824313",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1935,
			"zipCode": "94022",
			"path": "los-altos-ca/222-pasa-robles-ave-los-altos-ca-94022/pid_klfo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6b44d3b4-836a-4a0c-a09c-98bec1d088f3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-16 00:00:00",
			"createdAt": "2021-01-05 16:17:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-31 12:24:38",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.398188,
				"lng": -122.118736,
				"zipcode": "94022",
				"subPremise": "",
				"address": "222 Pasa Robles Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1402950,
			"daysOnMovoto": 38,
			"id": "d66e1d1a-6a53-4aea-b1ed-135c1944434c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81823623_0_NauM3u_p.jpeg",
			"listDate": "2021-02-02 00:00:00",
			"listingAgent": "Joe Velasco",
			"listPrice": 1399888,
			"lotSize": null,
			"sqftTotal": 1212,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81823623",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 28,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1974,
			"zipCode": "94022",
			"path": "los-altos-ca/457-tyndall-st-2-los-altos-ca-94022/pid_75go2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9062960f-06db-43d1-8869-0c58818ae10c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-12 00:00:00",
			"createdAt": "2021-02-01 18:47:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-01 18:47:28",
			"virtualTourLink": "Http://www.457tyndallstreet2.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.374623,
				"lng": -122.112868,
				"zipcode": "94022",
				"subPremise": "APT 2",
				"address": "457 Tyndall St #2"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1402950,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.457tyndallstreet2.com/?mls"
		},
		{
			"closePrice": 4610000,
			"daysOnMovoto": 44,
			"id": "2a401878-403f-46ce-9883-9b9832105ff8",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827281_0_vUJJ3y_p.jpeg",
			"listDate": "2021-01-26 14:46:07",
			"listingAgent": "Nicholas French",
			"listPrice": 3980000,
			"lotSize": 10607,
			"sqftTotal": 2911,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827281",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2005,
			"zipCode": "94024",
			"path": "los-altos-ca/905-seena-ave-los-altos-ca-94024-100_81115127/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cb6e72b3-f768-4646-973c-b832b2e4c651",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-12 00:00:00",
			"createdAt": "2021-01-26 22:52:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-26 14:46:07",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2828311",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.366767,
				"lng": -122.091727,
				"zipcode": "94024",
				"subPremise": "",
				"address": "905 Seena Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4610000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2828311"
		},
		{
			"closePrice": 2862000,
			"daysOnMovoto": 98,
			"id": "7739e93f-51e1-49cf-80e6-2bcc59775aaa",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81822026_0_vRIJu2_p.jpeg",
			"listDate": "2020-12-02 18:00:32",
			"listingAgent": "Shirley Bailey",
			"listPrice": 2495000,
			"lotSize": 10158,
			"sqftTotal": 2052,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81822026",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 25,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1960,
			"zipCode": "94024",
			"path": "los-altos-ca/1728-hawkins-dr-los-altos-ca-94024/pid_kexo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ef40e389-5ab1-4c3f-9212-3c512cdc0a19",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-11 00:00:00",
			"createdAt": "2020-12-03 02:07:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-12-02 18:00:32",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.358103,
				"lng": -122.064436,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1728 Hawkins Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2862000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2900000,
			"daysOnMovoto": 36,
			"id": "2ba433ab-6945-4c78-b6f4-7c1ca4c046da",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828056_0_QBiNa2_p.jpeg",
			"listDate": "2021-02-01 17:16:56",
			"listingAgent": "David Troyer",
			"listPrice": 2498000,
			"lotSize": 10319,
			"sqftTotal": 1896,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828056",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1953,
			"zipCode": "94024",
			"path": "unincorporated-ca/22420-creston-dr-unincorporated-ca-94024-100_80333116/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "563a1bda-6fe2-4c5d-b71b-ab0b23d358ed",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-10 00:00:00",
			"createdAt": "2021-02-02 01:22:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-01 17:16:56",
			"virtualTourLink": "https://www.youtube.com/embed/S0p6UKAR-WE",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.332829,
				"lng": -122.066899,
				"zipcode": "94024",
				"subPremise": "",
				"address": "22420 Creston Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/S0p6UKAR-WE"
		},
		{
			"closePrice": 3100000,
			"daysOnMovoto": 31,
			"id": "b341b8b9-f752-4f7e-824c-e282177a866a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828577_0_Q2zN3e_p.jpeg",
			"listDate": "2021-02-05 05:04:08",
			"listingAgent": "Lynn Burnett North",
			"listPrice": 3198000,
			"lotSize": 11156,
			"sqftTotal": 2242,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828577",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 41,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1960,
			"zipCode": "94022",
			"path": "los-altos-ca/4-alma-ct-los-altos-ca-94022/pid_yaoo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -100000,
			"propertyId": "d6c01415-d822-4a19-8d60-fe7b0ba7e58c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-09 00:00:00",
			"createdAt": "2021-02-05 13:07:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-05 05:04:08",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.390766,
				"lng": -122.11452,
				"zipcode": "94022",
				"subPremise": "",
				"address": "4 Alma Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4400000,
			"daysOnMovoto": 40,
			"id": "a32213d3-fd79-4a59-a908-ecb8df05351a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827395_0_AuFYuF_p.jpeg",
			"listDate": "2021-01-27 11:07:09",
			"listingAgent": "Louise Dedera",
			"listPrice": 3500000,
			"lotSize": 18539,
			"sqftTotal": 2661,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827395",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1953,
			"zipCode": "94024",
			"path": "los-altos-ca/1550-oakhurst-ave-los-altos-ca-94024/pid_anqo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5967e158-18f8-491f-81bf-c9ab0b28df42",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-09 00:00:00",
			"createdAt": "2021-01-27 19:12:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-27 11:07:09",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?t=2831401&r=https3A2F%2Fwww%2Etourfactory%2Ecom%2F",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.353243,
				"lng": -122.083818,
				"zipcode": "94024",
				"subPremise": "",
				"address": "1550 Oakhurst Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?t=2831401&r=https3A2F%2Fwww%2Etourfactory%2Ecom%2F"
		},
		{
			"closePrice": 3775000,
			"daysOnMovoto": 37,
			"id": "f1ee1cd9-6e7d-46c6-94f2-7baefae6f177",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827205_0_zF6m3E_p.jpeg",
			"listDate": "2021-01-26 00:48:13",
			"listingAgent": "David Lillo",
			"listPrice": 3428000,
			"lotSize": 6678,
			"sqftTotal": 2063,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827205",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "DPL Real Estate",
			"photoCount": 54,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1967,
			"zipCode": "94022",
			"path": "los-altos-ca/569-university-ave-los-altos-ca-94022-100_81116188/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7fee5098-74d5-4a45-bcc1-39a195c50eff",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-05 00:00:00",
			"createdAt": "2021-01-26 08:52:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-26 00:48:13",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2832931",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.372871,
				"lng": -122.117299,
				"zipcode": "94022",
				"subPremise": "",
				"address": "569 University Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3775000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2832931"
		},
		{
			"closePrice": 4100121,
			"daysOnMovoto": 50,
			"id": "67373893-2ac1-4811-99db-6c2380ff232b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825469_0_jrbuyQ_p.jpeg",
			"listDate": "2021-01-12 15:26:09",
			"listingAgent": "The Locals",
			"listPrice": 3498000,
			"lotSize": 25069,
			"sqftTotal": 2531,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825469",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 48,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1948,
			"zipCode": "94022",
			"path": "los-altos-ca/166-n-san-antonio-rd-los-altos-ca-94022/pid_pmgo2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9b0c974b-8e68-4b1d-9a38-9234d2b8e55d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-04 00:00:00",
			"createdAt": "2021-01-12 23:32:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-12 15:26:09",
			"virtualTourLink": "Http://www.localslosaltos.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.384412,
				"lng": -122.114698,
				"zipcode": "94022",
				"subPremise": "",
				"address": "166 N San Antonio Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4100121,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.localslosaltos.com/?mls"
		},
		{
			"closePrice": 1548000,
			"daysOnMovoto": 163,
			"id": "21fd9078-f483-497f-b7c6-dce6b301f225",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81810784_0_3FI3f3_p.jpeg",
			"listDate": "2020-09-22 00:00:00",
			"listingAgent": "Gary Campi",
			"listPrice": 1595000,
			"lotSize": null,
			"sqftTotal": 1502,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81810784",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1979,
			"zipCode": "94022",
			"path": "los-altos-ca/50-w-edith-ave-3-los-altos-ca-94022-100_81109200/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -55000,
			"propertyId": "d220c427-2f1e-457b-8407-f9b0a8dbe45b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-04 00:00:00",
			"createdAt": "2020-09-22 16:57:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-09-22 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/463225067",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Altos",
				"lat": 37.381075,
				"lng": -122.11571,
				"zipcode": "94022",
				"subPremise": "APT 3",
				"address": "50 W Edith Ave #3"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1548000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/463225067"
		}
	]