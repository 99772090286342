exports.ATHERTON_SCHOOLS = [
		{
			"id": 6899,
			"districtId": 790,
			"districtName": "Las Lomitas Elementary",
			"districtCity": "Menlo Park",
			"levelCode": "e",
			"lat": 37.438381,
			"lon": -122.212563,
			"name": "Las Lomitas Elementary",
			"gradeLevels": "K-3",
			"assigned": null,
			"address": {
				"street1": "299 Alameda de las Pulgas",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": 10,
			"ratingScale": "Top rated",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/6899-Las-Lomitas-Elementary-School/",
				"reviews": "/california/atherton/6899-Las-Lomitas-Elementary-School/#Reviews",
				"collegeSuccess": "/california/atherton/6899-Las-Lomitas-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "7%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 501,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 14,
			"subratings": {
				"Test Scores Rating": 10,
				"Equity Overview Rating": 9
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 7
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"rating": 9,
					"percentage": 14
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 14
				}
			],
			"remediationData": {}
		},
		{
			"id": 6900,
			"districtId": 791,
			"districtName": "Menlo Park City Elementary",
			"districtCity": "Atherton",
			"levelCode": "e",
			"lat": 37.464336,
			"lon": -122.18631,
			"name": "Encinal Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "195 Encinal Avenue",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/6900-Encinal-Elementary-School/",
				"reviews": "/california/atherton/6900-Encinal-Elementary-School/#Reviews",
				"collegeSuccess": "/california/atherton/6900-Encinal-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "10%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 601,
			"parentRating": 5,
			"numReviews": 10,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 15
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 18
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 51
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 6902,
			"districtId": 791,
			"districtName": "Menlo Park City Elementary",
			"districtCity": "Atherton",
			"levelCode": "e",
			"lat": 37.467648,
			"lon": -122.172073,
			"name": "Laurel Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "95 Edge Road",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/6902-Laurel-Elementary-School/",
				"reviews": "/california/atherton/6902-Laurel-Elementary-School/#Reviews",
				"collegeSuccess": "/california/atherton/6902-Laurel-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "10%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 709,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 10,
				"Academic Progress Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 10
				},
				{
					"label": "All students",
					"rating": 10
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 21
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 50
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 11
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6951,
			"districtId": 798,
			"districtName": "Sequoia Union High",
			"districtCity": "Redwood City",
			"levelCode": "h",
			"lat": 37.460766,
			"lon": -122.175117,
			"name": "Menlo-Atherton High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "555 Middlefield Road",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/6951-Menlo-Atherton-High-School/",
				"reviews": "/california/atherton/6951-Menlo-Atherton-High-School/#Reviews",
				"collegeSuccess": "/california/atherton/6951-Menlo-Atherton-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "39%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "72%",
				"state_average": "64%"
			},
			"enrollment": 2498,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 9,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 39
				},
				{
					"label": "All students",
					"rating": 9
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 6
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 5
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 41
				},
				{
					"label": "Pacific Islander",
					"rating": 2,
					"percentage": 5
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 39
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6935,
			"districtId": 795,
			"districtName": "Redwood City Elementary",
			"districtCity": "Redwood City",
			"levelCode": "e,m",
			"lat": 37.458103,
			"lon": -122.22023,
			"name": "Selby Lane Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "170 Selby Lane",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/6935-Selby-Lane-Elementary-School/",
				"reviews": "/california/atherton/6935-Selby-Lane-Elementary-School/#Reviews",
				"collegeSuccess": "/california/atherton/6935-Selby-Lane-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "79%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 730,
			"parentRating": 4,
			"numReviews": 25,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 5,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 79
				},
				{
					"label": "All students",
					"rating": 5
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 89
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "African American",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 17952,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.448181,
			"lon": -122.19416,
			"name": "St Joseph's Montessori School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "150 Valparaiso Avenue",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/17952-St-Josephs-Montessori-School/",
				"reviews": "/california/atherton/17952-St-Josephs-Montessori-School/#Reviews",
				"collegeSuccess": "/california/atherton/17952-St-Josephs-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 17954,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.464497,
			"lon": -122.186577,
			"name": "Newton CCC-Encinal",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "195 Encinal Ave",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/17954-Newton-CCC-Encinal/",
				"reviews": "/california/atherton/17954-Newton-CCC-Encinal/#Reviews",
				"collegeSuccess": "/california/atherton/17954-Newton-CCC-Encinal/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24463,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.448181,
			"lon": -122.19416,
			"name": "Sacred Heart Schools, Atherton",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "150 Valparaiso Avenue",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/24463-Sacred-Heart-Schools-Atherton/",
				"reviews": "/california/atherton/24463-Sacred-Heart-Schools-Atherton/#Reviews",
				"collegeSuccess": "/california/atherton/24463-Sacred-Heart-Schools-Atherton/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1109,
			"parentRating": 4,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 76
				},
				{
					"label": "Hispanic",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Asian or Pacific Islander",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 24863,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.469593,
			"lon": -122.187775,
			"name": "LearningU",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "51 Hawthorn Drive",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/24863-LearningU/",
				"reviews": "/california/atherton/24863-LearningU/#Reviews",
				"collegeSuccess": "/california/atherton/24863-LearningU/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8161,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.452324,
			"lon": -122.195435,
			"name": "St. Joseph of The Sacred Heart School",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "50 Emilie Ave",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/8161-St.-Joseph-Of-The-Sacred-Heart-School/",
				"reviews": "/california/atherton/8161-St.-Joseph-Of-The-Sacred-Heart-School/#Reviews",
				"collegeSuccess": "/california/atherton/8161-St.-Joseph-Of-The-Sacred-Heart-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 3,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8162,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m,h",
			"lat": 37.449188,
			"lon": -122.196465,
			"name": "Sacred Heart Schools, Atherton",
			"gradeLevels": "PK-12",
			"assigned": null,
			"address": {
				"street1": "150 Valparaiso Avenue",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/8162-Sacred-Heart-Schools-Atherton/",
				"reviews": "/california/atherton/8162-Sacred-Heart-Schools-Atherton/#Reviews",
				"collegeSuccess": "/california/atherton/8162-Sacred-Heart-Schools-Atherton/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 4,
			"numReviews": 11,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 8548,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.4534,
			"lon": -122.189697,
			"name": "Menlo School",
			"gradeLevels": "6-12",
			"assigned": null,
			"address": {
				"street1": "50 Valparaiso Avenue",
				"street2": null,
				"zip": "94027",
				"city": "Atherton"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "94027",
			"type": "school",
			"links": {
				"profile": "/california/atherton/8548-Menlo-School/",
				"reviews": "/california/atherton/8548-Menlo-School/#Reviews",
				"collegeSuccess": "/california/atherton/8548-Menlo-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 798,
			"parentRating": 4,
			"numReviews": 12,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		}
	]