exports.DANVILLE_LISTINGS = [
		{
			"closePrice": 1675000,
			"daysOnMovoto": 0,
			"id": "977e1ac8-c0ee-4574-ac40-0ac533f63009",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-12-28 00:00:00",
			"listingAgent": "Steve Kehrig",
			"listPrice": 1675000,
			"lotSize": 9200,
			"sqftTotal": 2433,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40976814",
			"bath": 2.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "94526",
			"path": "danville-ca/114-zephyr-pl-danville-ca-94526-12_27237757/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b779a89e-ff27-4ee5-8254-ba3b05d029c4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-12-28 20:00:18",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-12-28 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.80007,
				"lng": -121.980994,
				"zipcode": "94526",
				"subPremise": "",
				"address": "114 Zephyr Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1675000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 715000,
			"daysOnMovoto": 34,
			"id": "15e1ff75-c814-4336-a98e-ec33b51e18fc",
			"tnImgPath": "https://pi.movoto.com/p/12/40974538_0_fVR2b6_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Lucy Ramos",
			"listPrice": 674500,
			"lotSize": 0,
			"sqftTotal": 1145,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974538",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1991,
			"zipCode": "94526",
			"path": "danville-ca/2078-fostoria-cir-danville-ca-94526-12_28255734/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7c56572a-b433-49d5-b755-d673e6c62cfc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-18 18:56:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.782987,
				"lng": -121.97079,
				"zipcode": "94526",
				"subPremise": "",
				"address": "2078 Fostoria Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 715000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 77,
			"id": "db137d75-9fc3-4f77-ab71-f881cf700d55",
			"tnImgPath": "https://pi.movoto.com/p/12/40969410_0_iQYjBE_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Steve Williams",
			"listPrice": 2975000,
			"lotSize": 71003,
			"sqftTotal": 5959,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969410",
			"bath": 5.5,
			"bed": 7,
			"openHouses": [],
			"officeListName": "Diablo View Realtors",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1880,
			"zipCode": "94506",
			"path": "danville-ca/2050-diablo-rd-danville-ca-94506/pid_kd4tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200000,
			"propertyId": "090d1b81-a0df-4de7-bf0f-41c86de951d5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-10-01 23:46:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2919869",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.831214,
				"lng": -121.953932,
				"zipcode": "94506",
				"subPremise": "",
				"address": "2050 Diablo Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2919869"
		},
		{
			"closePrice": 1810000,
			"daysOnMovoto": 0,
			"id": "f05ac07b-7b5a-4b9b-b186-4fd0790ec795",
			"tnImgPath": "https://pi.movoto.com/p/102/221116886_0_6EJ2UN_p.jpeg",
			"listDate": null,
			"listingAgent": "Mukeshbai Patel",
			"listPrice": 1549999,
			"lotSize": 10119,
			"sqftTotal": 2423,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221116886",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1976,
			"zipCode": "94526",
			"path": "danville-ca/312-borica-dr-danville-ca-94526-102_221116886/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -250000,
			"propertyId": "d742ed1e-c28c-4961-89c5-80bec0859e7d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-09-16 15:54:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-16 15:54:31",
			"virtualTourLink": "https://mls.homejab.com/property/view/312-borica-dr-danville-ca-94526-usa-89661",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.797932,
				"lng": -121.964182,
				"zipcode": "94526",
				"subPremise": "",
				"address": "312 Borica Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1810000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://mls.homejab.com/property/view/312-borica-dr-danville-ca-94526-usa-89661"
		},
		{
			"closePrice": 3390000,
			"daysOnMovoto": 23,
			"id": "8fbe9212-d6ff-4b4f-a7fe-685c92d8aea3",
			"tnImgPath": "https://pi.movoto.com/p/12/40973523_0_uUmEqQ_p.jpeg",
			"listDate": "2021-11-22 00:00:00",
			"listingAgent": "Joujou Chawla",
			"listPrice": 2999800,
			"lotSize": 10067,
			"sqftTotal": 4825,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973523",
			"bath": 5.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2015,
			"zipCode": "94506",
			"path": "danville-ca/100-capari-ct-danville-ca-94506-12_40973523/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "898ff494-dc6f-4c44-9840-f3b29086d416",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-11-23 00:30:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-22 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2928017",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.792455,
				"lng": -121.878452,
				"zipcode": "94506",
				"subPremise": "",
				"address": "100 Capari Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3390000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2928017"
		},
		{
			"closePrice": 560000,
			"daysOnMovoto": 36,
			"id": "059f80b4-9468-44ba-ba9d-a1015ca4a6e6",
			"tnImgPath": "https://pi.movoto.com/p/12/40973297_0_37ZjbF_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Savanah Slover",
			"listPrice": 570000,
			"lotSize": 872,
			"sqftTotal": 1012,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973297",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "ACME REAL ESTATE",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1964,
			"zipCode": "94526",
			"path": "danville-ca/394-ilo-apt-302-danville-ca-94526-12_40966708/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2f7c390e-18a6-4489-89bd-9654be7220b7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-04 21:41:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.82705,
				"lng": -121.997692,
				"zipcode": "94526",
				"subPremise": "APT 302",
				"address": "394 Ilo #302"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 560000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1910000,
			"daysOnMovoto": 58,
			"id": "73e4e47d-7c63-43cb-96a3-3f47f1a29045",
			"tnImgPath": "https://pi.movoto.com/p/12/40970546_0_UfyEUv_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Loray Hawkins",
			"listPrice": 1649000,
			"lotSize": 10000,
			"sqftTotal": 2308,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970546",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94526",
			"path": "danville-ca/176-waterman-cir-danville-ca-94526/pid_ih6tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b23c4e2e-d9e3-4938-922a-e919d6045fb5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-13 12:26:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": "https://listings.allaccessphoto.com/v2/176-waterman-cir-danville-ca-94526-1242472/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.792312,
				"lng": -121.97737,
				"zipcode": "94526",
				"subPremise": "",
				"address": "176 Waterman Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1910000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listings.allaccessphoto.com/v2/176-waterman-cir-danville-ca-94526-1242472/unbranded"
		},
		{
			"closePrice": 850000,
			"daysOnMovoto": 34,
			"id": "7566c7d2-f656-492d-b625-0cb406854197",
			"tnImgPath": "https://pi.movoto.com/p/12/40973148_0_3UzJBM_p.jpeg",
			"listDate": "2021-11-05 00:00:00",
			"listingAgent": "Bette Sue Schack",
			"listPrice": 799000,
			"lotSize": 0,
			"sqftTotal": 1002,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973148",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 17,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1992,
			"zipCode": "94526",
			"path": "danville-ca/20-danville-oak-pl-danville-ca-94526/pid_cohtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bb825a34-bac8-4ef5-807b-71260d35cdc2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-05 23:01:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.827207,
				"lng": -122.007354,
				"zipcode": "94526",
				"subPremise": "",
				"address": "20 Danville Oak Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1750000,
			"daysOnMovoto": 35,
			"id": "28727173-ecd0-46d0-bbf2-c8744d98ac76",
			"tnImgPath": "https://pi.movoto.com/p/12/40972985_0_m2mRzf_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Jerry Moriarty",
			"listPrice": 1545000,
			"lotSize": 11000,
			"sqftTotal": 2245,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972985",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1972,
			"zipCode": "94526",
			"path": "danville-ca/48-rosemead-ct-danville-ca-94526/pid_rg3tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "21be8d0d-9126-4b02-87ae-3bc17118d18a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-11-03 20:56:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/8419657",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.818192,
				"lng": -121.978925,
				"zipcode": "94526",
				"subPremise": "",
				"address": "48 Rosemead Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/8419657"
		},
		{
			"closePrice": 2025000,
			"daysOnMovoto": 49,
			"id": "65682114-21ee-4e38-a347-7893f8d4c996",
			"tnImgPath": "https://pi.movoto.com/p/12/40971667_0_JVeYZU_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Yvonne Bruce",
			"listPrice": 1795000,
			"lotSize": 17550,
			"sqftTotal": 3192,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971667",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Better Homes Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1973,
			"zipCode": "94526",
			"path": "danville-ca/1148-greenbrook-dr-danville-ca-94526/pid_jmotbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3264f843-963f-4ba4-81a9-fb03d48134bb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-10-21 01:16:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://tour.tpgtours.com/1915354?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.809345,
				"lng": -121.977393,
				"zipcode": "94526",
				"subPremise": "",
				"address": "1148 Greenbrook Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2025000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.tpgtours.com/1915354?idx=1"
		},
		{
			"closePrice": 2000000,
			"daysOnMovoto": 41,
			"id": "e0ecb747-e750-42f1-a7ff-7e12e8dcabca",
			"tnImgPath": "https://pi.movoto.com/p/12/40972410_0_bqiv6N_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Burnard Myers",
			"listPrice": 1995000,
			"lotSize": 9750,
			"sqftTotal": 2557,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972410",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Gate Sothebys International Realty",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1984,
			"zipCode": "94506",
			"path": "danville-ca/3159-deer-meadow-dr-danville-ca-94506/pid_c9mtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "41a91806-847e-46a1-bd60-ab619063678c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-27 23:16:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/639577298",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.819324,
				"lng": -121.916421,
				"zipcode": "94506",
				"subPremise": "",
				"address": "3159 Deer Meadow Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/639577298"
		},
		{
			"closePrice": 661500,
			"daysOnMovoto": 78,
			"id": "bd51bf34-f406-42cb-b030-6c45107df5f1",
			"tnImgPath": "https://pi.movoto.com/p/12/40967790_0_beEBaz_p.jpeg",
			"listDate": "2021-09-20 00:00:00",
			"listingAgent": "Debbie Gibbs",
			"listPrice": 670000,
			"lotSize": 0,
			"sqftTotal": 886,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967790",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 23,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2009,
			"zipCode": "94526",
			"path": "danville-ca/3402-fostoria-way-112-danville-ca-94526-12_40452805/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fce6a9d4-40e3-4f2d-8b15-0bdf8fd57e8c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-09-20 22:16:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-20 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2913848",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.783288,
				"lng": -121.968256,
				"zipcode": "94526",
				"subPremise": "APT 112",
				"address": "3402 Fostoria Way #112"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 661500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2913848"
		},
		{
			"closePrice": 1240000,
			"daysOnMovoto": 38,
			"id": "c4eb08c9-f636-4b4f-bcc6-6bfc85d2c808",
			"tnImgPath": "https://pi.movoto.com/p/12/40972724_0_EFurEr_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Amir Aliloupour",
			"listPrice": 1189800,
			"lotSize": 6600,
			"sqftTotal": 2162,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972724",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1988,
			"zipCode": "94506",
			"path": "blackhawk-ca/133-kingswood-cir-blackhawk-ca-94506-12_40518079/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d9e5e7f7-f52c-4d83-8875-5aa7f2994bae",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-11-19 00:00:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.800328,
				"lng": -121.880685,
				"zipcode": "94506",
				"subPremise": "",
				"address": "133 Kingswood Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1240000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2205000,
			"daysOnMovoto": 46,
			"id": "a05d6146-70cc-4ad1-a396-5812af9ea5f4",
			"tnImgPath": "https://pi.movoto.com/p/12/40970599_0_eBE6my_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Linda Williams",
			"listPrice": 1899000,
			"lotSize": 9069,
			"sqftTotal": 2891,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970599",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 5,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2011,
			"zipCode": "94506",
			"path": "danville-ca/3109-griffon-st-w-danville-ca-94506/pid_9wrtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c8962062-14c0-4b54-b604-42768b87b6f6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-06 00:00:00",
			"createdAt": "2021-10-21 17:51:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.791567,
				"lng": -121.883322,
				"zipcode": "94506",
				"subPremise": "",
				"address": "3109 Griffon St W"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2205000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1800000,
			"daysOnMovoto": 15,
			"id": "1e51c769-fb23-4410-bb04-56e275f99261",
			"tnImgPath": "https://pi.movoto.com/p/101/321106311_0_JY7aaN_p.jpeg",
			"listDate": "2021-11-15 00:00:00",
			"listingAgent": "Matthew Thomas",
			"listPrice": 1525000,
			"lotSize": 10006,
			"sqftTotal": 2502,
			"mlsDbNumber": 101,
			"mls": {
				"dateHidden": null,
				"id": 101
			},
			"mlsNumber": "321106311",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 42,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1969,
			"zipCode": "94526",
			"path": "danville-ca/226-joaquin-dr-danville-ca-94526/pid_9b5tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7d8df723-ffde-4cb0-9a98-b1d0a6f762a4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-11-15 16:58:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.788773,
				"lng": -121.977037,
				"zipcode": "94526",
				"subPremise": "",
				"address": "226 Joaquin Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1600000,
			"daysOnMovoto": 318,
			"id": "329bc66e-75fa-48ff-90d3-c225f3757b1c",
			"tnImgPath": "https://pi.movoto.com/p/12/40933721_0_33qiYb_p.jpeg",
			"listDate": "2021-01-10 00:00:00",
			"listingAgent": "Brooks Landry",
			"listPrice": 1600000,
			"lotSize": 52000,
			"sqftTotal": 2000,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40933721",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Exp Realty of California Inc.",
			"photoCount": 19,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1986,
			"zipCode": "94506",
			"path": "danville-ca/1475-lawrence-rd-danville-ca-94506/pid_bxktbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "68d90888-22bc-487c-9946-3b1611f39043",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-01-10 22:16:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.786923,
				"lng": -121.895897,
				"zipcode": "94506",
				"subPremise": "",
				"address": "1475 Lawrence Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1880000,
			"daysOnMovoto": 55,
			"id": "511f2730-55df-4201-ba14-45de8b0313b9",
			"tnImgPath": "https://pi.movoto.com/p/12/40969071_0_Vb3yez_p.jpeg",
			"listDate": "2021-09-29 00:00:00",
			"listingAgent": "Angela Fitzgerald",
			"listPrice": 1749000,
			"lotSize": 17017,
			"sqftTotal": 2668,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969071",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1987,
			"zipCode": "94526",
			"path": "danville-ca/484-el-pintado-rd-danville-ca-94526/pid_nagtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c4605b73-8397-4eb4-9565-35b7011ec65f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-09-30 06:46:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-29 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2919940",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.832568,
				"lng": -121.998249,
				"zipcode": "94526",
				"subPremise": "",
				"address": "484 El Pintado Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1880000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2919940"
		},
		{
			"closePrice": 2200837,
			"daysOnMovoto": 34,
			"id": "be265258-2073-428c-8ee7-87f464916758",
			"tnImgPath": "https://pi.movoto.com/p/12/40971513_0_ej7JBN_p.jpeg",
			"listDate": "2021-10-19 00:00:00",
			"listingAgent": "Heba Rayan",
			"listPrice": 2189837,
			"lotSize": 15700,
			"sqftTotal": 2237,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971513",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94526",
			"path": "danville-ca/106-glen-ct-danville-ca-94526/pid_5citbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "653be3c5-d461-40c3-9ccd-1bb1caced706",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-10-19 23:46:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-19 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.sharefile.com/share/view/scd4f2527c20f44e4a55c4af4dfd04c7c",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.83219,
				"lng": -122.015327,
				"zipcode": "94526",
				"subPremise": "",
				"address": "106 Glen Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200837,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.sharefile.com/share/view/scd4f2527c20f44e4a55c4af4dfd04c7c"
		},
		{
			"closePrice": 2450000,
			"daysOnMovoto": 39,
			"id": "96276b4c-33c0-44bd-998c-7d56ff65063b",
			"tnImgPath": "https://pi.movoto.com/p/110/421603935_0_iFnvvi_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Wesley Lee",
			"listPrice": 2295000,
			"lotSize": 6710,
			"sqftTotal": 3542,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421603935",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Earth Realty",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1985,
			"zipCode": "94506",
			"path": "blackhawk-ca/4104-whispering-oaks-ln-blackhawk-ca-94506-12_40610144/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "eec0fa0f-7848-4e65-95f0-915997bc7e4a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-10-15 00:11:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/626001682",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.811116,
				"lng": -121.898473,
				"zipcode": "94506",
				"subPremise": "",
				"address": "4104 Whispering Oaks Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/626001682"
		},
		{
			"closePrice": 4000000,
			"daysOnMovoto": 40,
			"id": "0743f3c2-ffbb-470e-985f-e77c67334544",
			"tnImgPath": "https://pi.movoto.com/p/12/40970770_0_vf6qEZ_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Joujou Chawla",
			"listPrice": 3698800,
			"lotSize": 17800,
			"sqftTotal": 6072,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970770",
			"bath": 6,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1988,
			"zipCode": "94506",
			"path": "blackhawk-ca/3170-blackhawk-meadow-dr-blackhawk-ca-94506-12_40675434/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e0237abb-5314-42a5-a052-912cc43e6b47",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-10-13 21:46:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2915294",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.821216,
				"lng": -121.894548,
				"zipcode": "94506",
				"subPremise": "",
				"address": "3170 Blackhawk Meadow Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2915294"
		},
		{
			"closePrice": 1615000,
			"daysOnMovoto": 41,
			"id": "36a4d17f-9044-43fc-8020-c4928a23575e",
			"tnImgPath": "https://pi.movoto.com/p/12/40970036_0_rFMfaf_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Patrick Lane",
			"listPrice": 1348000,
			"lotSize": 5600,
			"sqftTotal": 1866,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970036",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1996,
			"zipCode": "94506",
			"path": "danville-ca/404-antelope-ridge-way-danville-ca-94506/pid_lgltbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d15b50b9-b9e9-4e68-9add-815066d58b17",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-10-13 00:16:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.798571,
				"lng": -121.90284,
				"zipcode": "94506",
				"subPremise": "",
				"address": "404 Antelope Ridge Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1615000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2180000,
			"daysOnMovoto": 16,
			"id": "3065631e-b1b4-4250-94be-399e07067245",
			"tnImgPath": "https://pi.movoto.com/p/12/40973114_0_rYN2aZ_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Suzy Peck",
			"listPrice": 1899000,
			"lotSize": 10050,
			"sqftTotal": 2782,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973114",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Dudum Real Estate Group",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1984,
			"zipCode": "94506",
			"path": "danville-ca/747-liquidamber-pl-danville-ca-94506-12_40973114/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "58ee8b22-0379-43ab-890a-93b419cceb26",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-11-03 19:51:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": "https://listings.allaccessphoto.com/v2/747-liquidamber-pl-danville-ca-94506-1301580/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.817052,
				"lng": -121.913309,
				"zipcode": "94506",
				"subPremise": "",
				"address": "747 Liquidamber Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2180000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listings.allaccessphoto.com/v2/747-liquidamber-pl-danville-ca-94506-1301580/unbranded"
		},
		{
			"closePrice": 685000,
			"daysOnMovoto": 19,
			"id": "e74680da-a7b6-4f70-8e29-36869658e5d2",
			"tnImgPath": "https://pi.movoto.com/p/12/40972722_0_AmbNYq_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Sussan Rouhafza",
			"listPrice": 599500,
			"lotSize": 1032,
			"sqftTotal": 1114,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972722",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1972,
			"zipCode": "94526",
			"path": "danville-ca/129-midland-way-danville-ca-94526/pid_3jstbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2c10a11b-24c0-474b-a572-5610cdea06d9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-10-29 23:31:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/640458411",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.805067,
				"lng": -121.991027,
				"zipcode": "94526",
				"subPremise": "",
				"address": "129 Midland Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 685000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/640458411"
		},
		{
			"closePrice": 875000,
			"daysOnMovoto": 32,
			"id": "c6e86ea9-430a-41fc-9126-240743bc8434",
			"tnImgPath": "https://pi.movoto.com/p/12/40969965_0_uYynqA_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Annestelle Maes",
			"listPrice": 825000,
			"lotSize": 2280,
			"sqftTotal": 1408,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969965",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1973,
			"zipCode": "94526",
			"path": "danville-ca/152-westfield-cir-danville-ca-94526-12_40609142/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5bfa75b8-c1a3-4ae8-87dc-00adae2fa24c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-16 00:00:00",
			"createdAt": "2021-10-16 02:46:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.806272,
				"lng": -121.993133,
				"zipcode": "94526",
				"subPremise": "",
				"address": "152 Westfield Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2050000,
			"daysOnMovoto": 53,
			"id": "97b018a4-0241-4522-88a8-0533a19a1484",
			"tnImgPath": "https://pi.movoto.com/p/12/40968173_0_ImBbyM_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Kelley Krock",
			"listPrice": 1965000,
			"lotSize": 9243,
			"sqftTotal": 2806,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968173",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1981,
			"zipCode": "94506",
			"path": "blackhawk-ca/244-live-oak-dr-blackhawk-ca-94506-12_11071739/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b5548340-0433-44a5-b729-138b4a5fef8f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-09-23 20:46:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=5qSo5dZW7A4&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.825179,
				"lng": -121.919533,
				"zipcode": "94506",
				"subPremise": "",
				"address": "244 Live Oak Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=5qSo5dZW7A4&mls=1"
		},
		{
			"closePrice": 1650000,
			"daysOnMovoto": 67,
			"id": "3f298ded-b805-46ba-a630-ea9e98f23991",
			"tnImgPath": "https://pi.movoto.com/p/12/40966483_0_IVriBr_p.jpeg",
			"listDate": "2021-09-09 00:00:00",
			"listingAgent": "Norm Stanley",
			"listPrice": 1600000,
			"lotSize": 11200,
			"sqftTotal": 2301,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966483",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's Int'l Re",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1976,
			"zipCode": "94526",
			"path": "danville-ca/710-el-capitan-dr-danville-ca-94526/pid_q18tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "17ed2015-09c6-4caa-8314-06419ca86f5a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-11-02 21:30:50",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.800887,
				"lng": -121.973949,
				"zipcode": "94526",
				"subPremise": "",
				"address": "710 El Capitan Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1858888,
			"daysOnMovoto": 16,
			"id": "bdcd8cbb-bd08-4052-8bb1-d24f9225efac",
			"tnImgPath": "https://pi.movoto.com/p/12/40971742_0_mVa3Yy_p.jpeg",
			"listDate": "2021-10-27 00:00:00",
			"listingAgent": "Diane Gilfether",
			"listPrice": 1685000,
			"lotSize": 9200,
			"sqftTotal": 2375,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971742",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1985,
			"zipCode": "94506",
			"path": "danville-ca/387-live-oak-dr-danville-ca-94506/pid_i7mtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9a71b6b6-7844-438a-8c45-1105f1c18bd1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-27 22:16:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-27 00:00:00",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.827875,
				"lng": -121.923711,
				"zipcode": "94506",
				"subPremise": "",
				"address": "387 Live Oak Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1858888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 1000000,
			"daysOnMovoto": 32,
			"id": "9baca591-ba2c-47cd-8820-7636b3358be6",
			"tnImgPath": "https://pi.movoto.com/p/12/40970473_0_IEzEYZ_p.jpeg",
			"listDate": "2021-10-11 00:00:00",
			"listingAgent": "Jesse Jecmen",
			"listPrice": 895000,
			"lotSize": 1176,
			"sqftTotal": 1671,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970473",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Macan Equities",
			"photoCount": 1,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1995,
			"zipCode": "94506",
			"path": "danville-ca/606-joya-ct-danville-ca-94506/pid_t3mtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e0f5c3e3-acb9-469e-bb1d-a24be178e59c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-10-13 00:00:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.802729,
				"lng": -121.914836,
				"zipcode": "94506",
				"subPremise": "",
				"address": "606 Joya Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 52,
			"id": "8a3ffb92-cca4-410e-a3b9-09826fa5e3e7",
			"tnImgPath": "https://pi.movoto.com/p/12/40965246_0_BqBAFm_p.jpeg",
			"listDate": "2021-09-21 00:00:00",
			"listingAgent": "Brett Caires",
			"listPrice": 1399000,
			"lotSize": 9000,
			"sqftTotal": 1896,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965246",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BoaVentura Rl Est Services",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1993,
			"zipCode": "94506",
			"path": "danville-ca/40-haskins-ranch-cir-danville-ca-94506/pid_n0mtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b2c38a66-ed26-4806-80e8-074b8d7e9aa9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-09-21 09:06:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.795049,
				"lng": -121.913776,
				"zipcode": "94506",
				"subPremise": "",
				"address": "40 Haskins Ranch Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4400000,
			"daysOnMovoto": 0,
			"id": "5cc30977-d83a-409d-bba1-350d69c8b1c2",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-11-10 00:00:00",
			"listingAgent": "Tom Hart",
			"listPrice": 4400000,
			"lotSize": 67518,
			"sqftTotal": 3800,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973883",
			"bath": 4.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "94526",
			"path": "danville-ca/16-hillside-dr-danville-ca-94526-12_40661314/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "813b3ce4-cfbf-4120-b3e3-8ab57a0ec576",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-11-11 00:51:37",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-11-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.812847,
				"lng": -122.013972,
				"zipcode": "94526",
				"subPremise": "",
				"address": "16 Hillside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2750000,
			"daysOnMovoto": 20,
			"id": "e4d611a4-154f-4422-833f-a1bb230fcbde",
			"tnImgPath": "https://pi.movoto.com/p/12/40970390_0_AZI2FM_p.jpeg",
			"listDate": "2021-10-21 00:00:00",
			"listingAgent": "Mike Kehrig",
			"listPrice": 2399000,
			"lotSize": 15200,
			"sqftTotal": 3146,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970390",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1992,
			"zipCode": "94506",
			"path": "danville-ca/10-headland-ct-danville-ca-94506-12_11151692/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b0046e5a-ecc1-4dc4-ab0f-830c9d99c1c8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-21 17:56:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=EcbYBUwV7mA",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.796599,
				"lng": -121.929614,
				"zipcode": "94506",
				"subPremise": "",
				"address": "10 Headland Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=EcbYBUwV7mA"
		},
		{
			"closePrice": 655000,
			"daysOnMovoto": 47,
			"id": "96c94b8b-6b2d-48fa-8b07-fe1e29b2006c",
			"tnImgPath": "https://pi.movoto.com/p/12/40968487_0_JQUNmM_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Lili Falsken",
			"listPrice": 640000,
			"lotSize": 1032,
			"sqftTotal": 1114,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968487",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BCRE",
			"photoCount": 30,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1973,
			"zipCode": "94526",
			"path": "danville-ca/1063-san-ramon-valley-blvd-danville-ca-94526/pid_dpstbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "721c9bb4-cf5c-4c31-86b2-d68c50052a40",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-09-24 17:56:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.804637,
				"lng": -121.991459,
				"zipcode": "94526",
				"subPremise": "",
				"address": "1063 San Ramon Valley Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 655000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1376000,
			"daysOnMovoto": 132,
			"id": "c19c178f-5267-49f6-873e-79799b75ccd9",
			"tnImgPath": "https://pi.movoto.com/p/12/40956469_0_AzyNqi_p.jpeg",
			"listDate": "2021-07-01 00:00:00",
			"listingAgent": "Wilfredo Cuevas",
			"listPrice": 1425000,
			"lotSize": 22085,
			"sqftTotal": 1104,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956469",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Realty One Group Zoom",
			"photoCount": 30,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1958,
			"zipCode": "94526",
			"path": "danville-ca/891-diablo-rd-danville-ca-94526/pid_8zktbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -70000,
			"propertyId": "0f2e1d08-770b-41f8-90bc-3ff51cf0d341",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-07-01 18:11:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.830791,
				"lng": -121.98445,
				"zipcode": "94526",
				"subPremise": "",
				"address": "891 Diablo Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1376000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"address": "Undisclosed",
			"closePrice": 1505000,
			"daysOnMovoto": 0,
			"id": "6da4a665-e4a8-41b3-a552-5c5434654ef3",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-11-09 00:00:00",
			"listingAgent": "Jan Ramsey",
			"listPrice": 1500000,
			"lotSize": 28000,
			"sqftTotal": 2111,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973751",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1965,
			"zipCode": "94526",
			"path": "danville-ca/no-address-danville-ca-94526-12_40973751/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8d64aae0-ba24-4225-af3c-2addfd56dfe4",
			"visibility": "ALL_ADDRESS_SIGNED_IN",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-11-09 23:00:39",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-11-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 0,
				"lng": 0,
				"zipcode": "94526",
				"subPremise": "",
				"address": "Undisclosed",
				"address2": "411 Laurel Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1505000,
			"longitude": 0,
			"latitude": 0,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1908000,
			"daysOnMovoto": 48,
			"id": "8276d91a-fed4-4166-890e-b8f942d0a45a",
			"tnImgPath": "https://pi.movoto.com/p/12/40968122_0_7AAAJz_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Emil Ko",
			"listPrice": 1799000,
			"lotSize": 5981,
			"sqftTotal": 2921,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968122",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Grobecker Holland Int'l",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2013,
			"zipCode": "94506",
			"path": "danville-ca/5268-bengali-st-danville-ca-94506-12_40968122/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2f890d7c-a91d-4d5b-9ab5-b76e270ce868",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-09-22 22:21:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.796282,
				"lng": -121.878208,
				"zipcode": "94506",
				"subPremise": "",
				"address": "5268 Bengali St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1908000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1865000,
			"daysOnMovoto": 33,
			"id": "3fdeabe1-1bd2-45b4-a6e6-35b7384bca5c",
			"tnImgPath": "https://pi.movoto.com/p/12/40969967_0_m6Iaef_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Cynthia Silva",
			"listPrice": 1799000,
			"lotSize": 8980,
			"sqftTotal": 2831,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969967",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "The Agency",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1992,
			"zipCode": "94506",
			"path": "danville-ca/504-norfolk-pl-danville-ca-94506/pid_zfaubtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "42b6f4c9-603c-4e82-9e7e-b1fb413f6da2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-08 00:00:00",
			"createdAt": "2021-10-07 05:11:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": "http://www.504Norfolk.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.801348,
				"lng": -121.910909,
				"zipcode": "94506",
				"subPremise": "",
				"address": "504 Norfolk Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1865000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.504Norfolk.com"
		},
		{
			"closePrice": 2670000,
			"daysOnMovoto": 31,
			"id": "109c74a2-07c6-444c-b23f-b1f67137ed00",
			"tnImgPath": "https://pi.movoto.com/p/12/40969742_0_66bbbM_p.jpeg",
			"listDate": "2021-10-05 00:00:00",
			"listingAgent": "Cristian Hernandez",
			"listPrice": 2495000,
			"lotSize": 56192,
			"sqftTotal": 3118,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969742",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1999,
			"zipCode": "94526",
			"path": "danville-ca/sherburne-hills-rd-danville-ca-94526-12_11122389/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1c195b1f-c4a2-4b7f-b7e3-d4047b4776b0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-10-05 22:21:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-05 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/618167834",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.805168,
				"lng": -121.951999,
				"zipcode": "94526",
				"subPremise": "",
				"address": "23 Sherburne Hills Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2670000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/618167834"
		},
		{
			"address": "Undisclosed",
			"closePrice": 2575000,
			"daysOnMovoto": 40,
			"id": "e80b0383-9ca6-45b1-a474-8d01d80b09e9",
			"tnImgPath": "https://pi.movoto.com/p/12/40968696_0_qIjqfa_p.jpeg",
			"listDate": "2021-09-26 00:00:00",
			"listingAgent": "Jacqueline Horewitz",
			"listPrice": 2250000,
			"lotSize": 11907,
			"sqftTotal": 3551,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968696",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Town Real Estate",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2010,
			"zipCode": "94526",
			"path": "danville-ca/no-address-danville-ca-94526-12_40968696/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "025c9be9-2322-4b06-abc9-4abd5cf022c8",
			"visibility": "ALL_ADDRESS_SIGNED_IN",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-29 17:30:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 0,
				"lng": 0,
				"zipcode": "94526",
				"subPremise": "",
				"address": "Undisclosed",
				"address2": "255 Tamarind Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2575000,
			"longitude": 0,
			"latitude": 0,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2150000,
			"daysOnMovoto": 15,
			"id": "e81e81b8-2f3e-4d2a-8e22-fc51a59b3a29",
			"tnImgPath": "https://pi.movoto.com/p/110/421605613_0_V3uiBU_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Steven Minchen",
			"listPrice": 2198000,
			"lotSize": 14910,
			"sqftTotal": 2160,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421605613",
			"bath": 2,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 70,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": null,
			"zipCode": "94526",
			"path": "danville-ca/68-turrini-cir-danville-ca-94526-12_11179411/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "762c9d9b-2789-4197-9a8d-b3a396878854",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-10-20 16:11:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=QY7LSvCMhqb&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.828973,
				"lng": -121.991142,
				"zipcode": "94526",
				"subPremise": "",
				"address": "68 Turrini Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=QY7LSvCMhqb&mls=1"
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 35,
			"id": "67c6c933-e7fa-4548-b36e-6040f49ae4ed",
			"tnImgPath": "https://pi.movoto.com/p/12/40968814_0_IZr7aY_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Loray Hawkins",
			"listPrice": 1550000,
			"lotSize": 9135,
			"sqftTotal": 2182,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968814",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1969,
			"zipCode": "94526",
			"path": "danville-ca/45-saint-teresa-ct-danville-ca-94526/pid_5y9tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6f6be192-0637-4b7d-a9b4-1d60d921be05",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-30 12:11:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.796946,
				"lng": -121.978836,
				"zipcode": "94526",
				"subPremise": "",
				"address": "45 Saint Teresa Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1602000,
			"daysOnMovoto": 33,
			"id": "82429cfc-e668-414c-ab79-470aeffcfea2",
			"tnImgPath": "https://pi.movoto.com/p/12/40968495_0_NBAuVJ_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Catherine Isham",
			"listPrice": 1497000,
			"lotSize": 9340,
			"sqftTotal": 1896,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968495",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Tucker Assoc Rl Est Srvcs",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1993,
			"zipCode": "94506",
			"path": "danville-ca/44-haskins-ranch-cir-danville-ca-94506/pid_d1mtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aef836e6-4018-4a87-b60c-1788be24c439",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-10-01 15:41:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.795145,
				"lng": -121.913398,
				"zipcode": "94506",
				"subPremise": "",
				"address": "44 Haskins Ranch Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1602000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2075000,
			"daysOnMovoto": 36,
			"id": "a64d3ded-0732-469a-911d-2e2c8ff8ba7a",
			"tnImgPath": "https://pi.movoto.com/p/12/40968689_0_2EVF7n_p.jpeg",
			"listDate": "2021-09-28 00:00:00",
			"listingAgent": "David Weiss",
			"listPrice": 1828000,
			"lotSize": 7300,
			"sqftTotal": 3401,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968689",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1993,
			"zipCode": "94506",
			"path": "danville-ca/29-blue-haven-ct-danville-ca-94506/pid_2hdubtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e287f5aa-5994-406f-81f7-1640a8136938",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-09-29 02:01:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-28 00:00:00",
			"virtualTourLink": "https://www.youtube.com/embed/Xb9eCZauXj0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.804246,
				"lng": -121.904085,
				"zipcode": "94506",
				"subPremise": "",
				"address": "29 Blue Haven Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2075000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/Xb9eCZauXj0"
		},
		{
			"closePrice": 2255777,
			"daysOnMovoto": 42,
			"id": "20d589b1-cd70-4bdf-8260-77788e9a6d70",
			"tnImgPath": "https://pi.movoto.com/p/12/40967887_0_iz6U7a_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Keri Fryer",
			"listPrice": 2199999,
			"lotSize": 146797,
			"sqftTotal": 4640,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967887",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Dudum Real Estate Group",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1985,
			"zipCode": "94526",
			"path": "danville-ca/36-sherburne-hills-rd-danville-ca-94526-12_40044679/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -165001,
			"propertyId": "558d8ac6-4f67-4e6d-852e-47ac8afdc9a8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-09-22 22:46:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.804121,
				"lng": -121.953257,
				"zipcode": "94526",
				"subPremise": "",
				"address": "36 Sherburne Hills Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2255777,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 816000,
			"daysOnMovoto": 27,
			"id": "a1633d6c-a26b-4a06-a117-2a3108db8bb8",
			"tnImgPath": "https://pi.movoto.com/p/12/40969846_0_VqzjZB_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Gina Piper",
			"listPrice": 760000,
			"lotSize": 0,
			"sqftTotal": 1018,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969846",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Elation Real Estate",
			"photoCount": 29,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1990,
			"zipCode": "94526",
			"path": "danville-ca/1-danville-oak-pl-danville-ca-94526/pid_jehtbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1e6155d0-e407-4628-8358-75a21851e975",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-10-06 19:21:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/9228320",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.827267,
				"lng": -122.007133,
				"zipcode": "94526",
				"subPremise": "",
				"address": "1 Danville Oak Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 816000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/9228320"
		},
		{
			"closePrice": 1130000,
			"daysOnMovoto": 30,
			"id": "e2723d84-4ce6-4a9e-b091-d38335db70e5",
			"tnImgPath": "https://pi.movoto.com/p/12/40968299_0_REqRAI_p.jpeg",
			"listDate": "2021-09-29 00:00:00",
			"listingAgent": "Rebecca Loboschefsky",
			"listPrice": 1100000,
			"lotSize": 7797,
			"sqftTotal": 1598,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968299",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 12,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1976,
			"zipCode": "94526",
			"path": "danville-ca/796-contada-cir-danville-ca-94526/pid_8y6tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a5283ce5-0cda-4765-a6c0-4346f6d5f576",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-09-29 22:56:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.82163,
				"lng": -121.986433,
				"zipcode": "94526",
				"subPremise": "",
				"address": "796 Contada Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1130000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2600000,
			"daysOnMovoto": 37,
			"id": "6137a185-2124-45ff-8fff-d6044bb0ad66",
			"tnImgPath": "https://pi.movoto.com/p/12/40967947_0_i2Eyur_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Paul Zuvella",
			"listPrice": 2198000,
			"lotSize": 8320,
			"sqftTotal": 3853,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967947",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2000,
			"zipCode": "94506",
			"path": "danville-ca/135-provence-rd-danville-ca-94506/pid_wjotbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "20e103b3-d261-472d-932c-ed2a70af7bf0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-09-22 18:46:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": "http://135provencerd.com/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.794368,
				"lng": -121.897394,
				"zipcode": "94506",
				"subPremise": "",
				"address": "135 Provence Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://135provencerd.com/idx"
		},
		{
			"closePrice": 4200000,
			"daysOnMovoto": 57,
			"id": "a8b4aedf-096d-4a85-9490-d0d6f5181918",
			"tnImgPath": "https://pi.movoto.com/p/12/40965659_0_jfr2bn_p.jpeg",
			"listDate": "2021-09-02 00:00:00",
			"listingAgent": "Matthew Wallace",
			"listPrice": 3950000,
			"lotSize": 21700,
			"sqftTotal": 6236,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965659",
			"bath": 5.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1990,
			"zipCode": "94506",
			"path": "danville-ca/5483-blackhawk-dr-danville-ca-94506-12_40871825/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "119fb193-2803-42f8-9cdd-0b790d74bad6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-09-02 22:31:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-02 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=KKy8bWNDccT&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.802011,
				"lng": -121.883989,
				"zipcode": "94506",
				"subPremise": "",
				"address": "5483 Blackhawk Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=KKy8bWNDccT&mls=1"
		},
		{
			"closePrice": 3393002,
			"daysOnMovoto": 247,
			"id": "03ff6ae2-0901-461a-9d97-c6e5bd27e5d9",
			"tnImgPath": "https://pi.movoto.com/p/12/40938915_0_ivVRZZ_p.jpeg",
			"listDate": "2021-02-24 00:00:00",
			"listingAgent": "Minji Lee",
			"listPrice": 3381180,
			"lotSize": 15845,
			"sqftTotal": 4723,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40938915",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Toll Brothers Real Estate,Inc.",
			"photoCount": 4,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "94506",
			"path": "danville-ca/6238-massara-st-danville-ca-94506-12_40923408/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ce2310c8-68a9-4d66-8f45-69c9c25547bd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-02-24 18:56:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.788021,
				"lng": -121.888182,
				"zipcode": "94506",
				"subPremise": "",
				"address": "6238 Massara St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3393002,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1275000,
			"daysOnMovoto": 41,
			"id": "5a73c535-e761-4bf8-8611-ddaa119c138e",
			"tnImgPath": "https://pi.movoto.com/p/12/40967254_0_jBnzJU_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Sarah Ridge",
			"listPrice": 1275000,
			"lotSize": 5400,
			"sqftTotal": 1650,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967254",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "District Homes",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1977,
			"zipCode": "94526",
			"path": "danville-ca/642-paradise-valley-ct-danville-ca-94526-12_40967254/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 50000,
			"propertyId": "864d8886-a97e-4139-b2a8-24e06d6635ce",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-15 23:36:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": "https://www.642paradisevalley.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.786048,
				"lng": -121.959419,
				"zipcode": "94526",
				"subPremise": "",
				"address": "642 Paradise Valley Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.642paradisevalley.com/?mls"
		},
		{
			"closePrice": 2250000,
			"daysOnMovoto": 30,
			"id": "38df91c4-6cac-4067-a1d7-5e25c7526bda",
			"tnImgPath": "https://pi.movoto.com/p/12/40968640_0_2NIBBA_p.jpeg",
			"listDate": "2021-09-25 00:00:00",
			"listingAgent": "Roger Pell",
			"listPrice": 1895000,
			"lotSize": 12400,
			"sqftTotal": 2822,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968640",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Better Homes Realty Alamo",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1988,
			"zipCode": "94506",
			"path": "danville-ca/20-grandview-ct-danville-ca-94506/pid_kj4tbtk38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "da72717f-26af-497d-8a07-03364f3390dc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-09-25 16:06:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-25 00:00:00",
			"virtualTourLink": "https://listings.allaccessphoto.com/v2/20-grandview-ct-danville-ca-94506-1189713/unbranded",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Danville",
				"lat": 37.799321,
				"lng": -121.942992,
				"zipcode": "94506",
				"subPremise": "",
				"address": "20 Grandview Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://listings.allaccessphoto.com/v2/20-grandview-ct-danville-ca-94506-1189713/unbranded"
		}
	]