exports.DUBLIN_LISTINGS = [
		{
			"closePrice": 972427,
			"daysOnMovoto": 411,
			"id": "dc67dbc7-36d0-4f23-940c-14166cf69635",
			"tnImgPath": "https://pi.movoto.com/p/12/40929101_0_Bn6FUj_p.jpeg",
			"listDate": "2020-11-12 00:00:00",
			"listingAgent": "Paul Desmet",
			"listPrice": 954990,
			"lotSize": 0,
			"sqftTotal": 1961,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40929101",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Courtesy Brokerage",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2020,
			"zipCode": "95468",
			"path": "dublin-ca/5728-kemper-pl-apt-201-dublin-ca-95468-12_40929101/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "44779853-3c08-4d0e-81d4-b233079859d8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2020-11-13 02:56:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-11-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"zipcode": "95468",
				"subPremise": "APT 201",
				"address": "5728 Kemper Pl #201"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 972427,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1575000,
			"daysOnMovoto": 39,
			"id": "d2a9e349-c455-44b4-b37a-d4f3138e243a",
			"tnImgPath": "https://pi.movoto.com/p/12/40974568_0_rbENMN_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Steve Mohseni",
			"listPrice": 1188000,
			"lotSize": 10001,
			"sqftTotal": 1598,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974568",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1986,
			"zipCode": "94568",
			"path": "dublin-ca/11640-fenwick-pl-dublin-ca-94568-12_27243208/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "196abf32-aa25-4d3e-ba91-e2bb589d4ba1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-11-18 22:01:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": "https://virtualtourcafe.com/mls/9649505",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.719854,
				"lng": -121.948044,
				"zipcode": "94568",
				"subPremise": "",
				"address": "11640 Fenwick Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1575000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://virtualtourcafe.com/mls/9649505"
		},
		{
			"closePrice": 985000,
			"daysOnMovoto": 89,
			"id": "ea343981-b784-43c0-ae61-597be3856027",
			"tnImgPath": "https://pi.movoto.com/p/12/40968976_0_IQFfeN_p.jpeg",
			"listDate": "2021-09-29 00:00:00",
			"listingAgent": "Kyle Tran",
			"listPrice": 995000,
			"lotSize": 0,
			"sqftTotal": 1598,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968976",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Kyle Tran, RE Broker",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2014,
			"zipCode": "94568",
			"path": "dublin-ca/5529-esprit-way-dublin-ca-94568-12_40968976/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -4888,
			"propertyId": "a6137a37-90e1-42cb-98fa-2e1c93f6d42b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-27 00:00:00",
			"createdAt": "2021-09-29 19:06:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.70529,
				"lng": -121.896599,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5529 Esprit Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 985000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 730000,
			"daysOnMovoto": 34,
			"id": "8b820dfd-c6ad-4584-a341-93984348bb4a",
			"tnImgPath": "https://pi.movoto.com/p/12/40973243_0_MMfVy7_p.jpeg",
			"listDate": "2021-11-18 00:00:00",
			"listingAgent": "Romar De Claro",
			"listPrice": 724900,
			"lotSize": 0,
			"sqftTotal": 1066,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973243",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "94568",
			"path": "dublin-ca/3385-dublin-blvd-117-dublin-ca-94568/pid_e1ogvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "49ef29f0-8d7a-41f5-952c-59396fb46302",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-11-18 18:31:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.705457,
				"lng": -121.862552,
				"zipcode": "94568",
				"subPremise": "APT 117",
				"address": "3385 Dublin Blvd #117"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 730000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 25,
			"id": "63287108-fd88-4cb9-b6ce-8a6a35d7315a",
			"tnImgPath": "https://pi.movoto.com/p/12/40974820_0_q6Qaq2_p.jpeg",
			"listDate": "2021-11-25 00:00:00",
			"listingAgent": "Manel Sousou",
			"listPrice": 1249000,
			"lotSize": 3968,
			"sqftTotal": 1719,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974820",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 26,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2006,
			"zipCode": "94568",
			"path": "dublin-ca/6081-hillbrook-pl-dublin-ca-94568/pid_0jrgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ab1d7278-2072-4751-bb29-f2a7011bd391",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-11-25 18:41:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-25 00:00:00",
			"virtualTourLink": "https://6081HillbrookPl.com/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.721758,
				"lng": -121.874739,
				"zipcode": "94568",
				"subPremise": "",
				"address": "6081 Hillbrook Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://6081HillbrookPl.com/idx"
		},
		{
			"closePrice": 665000,
			"daysOnMovoto": 30,
			"id": "15f73803-9445-4099-8d9d-e2b16546b7f6",
			"tnImgPath": "https://pi.movoto.com/p/12/40974417_0_eEv7Z7_p.jpeg",
			"listDate": "2021-11-20 00:00:00",
			"listingAgent": "Heather Kelly",
			"listPrice": 649000,
			"lotSize": 0,
			"sqftTotal": 906,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974417",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 11,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1990,
			"zipCode": "94568",
			"path": "dublin-ca/11872-pollardstown-ct-dublin-ca-94568-12_40974417/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "16a46ec9-4119-4d33-9d5b-73976baee32e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-11-21 01:01:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.704744,
				"lng": -121.938992,
				"zipcode": "94568",
				"subPremise": "",
				"address": "11872 Pollardstown Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 665000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 768000,
			"daysOnMovoto": 61,
			"id": "cbb64ef2-00eb-4934-932e-97343236321f",
			"tnImgPath": "https://pi.movoto.com/p/12/40971668_0_jIjaV7_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Ted Babbes",
			"listPrice": 745000,
			"lotSize": 2025,
			"sqftTotal": 963,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971668",
			"bath": 1.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Monument Realty",
			"photoCount": 31,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1986,
			"zipCode": "94568",
			"path": "dublin-ca/7604-arbor-creek-cir-dublin-ca-94568/pid_h20fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "52a42bb4-f1fa-438e-9e41-a8e6bba50432",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-20 00:00:00",
			"createdAt": "2021-10-21 01:16:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://video214.com/play/pij3j9XUNISqK00RuRF9Cg/s/dark",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.708682,
				"lng": -121.938445,
				"zipcode": "94568",
				"subPremise": "",
				"address": "7604 Arbor Creek Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 768000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://video214.com/play/pij3j9XUNISqK00RuRF9Cg/s/dark"
		},
		{
			"closePrice": 1245000,
			"daysOnMovoto": 21,
			"id": "11781989-0ae5-4a97-8373-09bd792b5cdb",
			"tnImgPath": "https://pi.movoto.com/p/12/40974970_0_qE3V7f_p.jpeg",
			"listDate": "2021-11-26 00:00:00",
			"listingAgent": "Ted Chen",
			"listPrice": 1049000,
			"lotSize": 1827,
			"sqftTotal": 1692,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974970",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2005,
			"zipCode": "94568",
			"path": "dublin-ca/5992-hillbrook-pl-dublin-ca-94568/pid_0yrgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "45c0a1ab-01d3-41ab-9bc8-e655ad283988",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-11-26 08:16:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-26 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=g2FpPqN8bBD&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.720842,
				"lng": -121.875051,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5992 Hillbrook Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1245000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=g2FpPqN8bBD&mls=1"
		},
		{
			"closePrice": 1250000,
			"daysOnMovoto": 49,
			"id": "f9e872e5-1eee-4792-a62e-06c239955c78",
			"tnImgPath": "https://pi.movoto.com/p/12/40972400_0_EZqaQe_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Matthew Castillo",
			"listPrice": 1198989,
			"lotSize": 8546,
			"sqftTotal": 1760,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972400",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94568",
			"path": "dublin-ca/7622-calle-verde-rd-dublin-ca-94568/pid_8izfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "580a532b-70a4-48f3-bb6f-02498e281588",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-10-29 15:36:54",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.708925,
				"lng": -121.940534,
				"zipcode": "94568",
				"subPremise": "",
				"address": "7622 Calle Verde Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 895000,
			"daysOnMovoto": 37,
			"id": "6f427c07-ac13-4c5a-8ab9-3ce2fc4893c8",
			"tnImgPath": "https://pi.movoto.com/p/12/40973161_0_Y22zyN_p.jpeg",
			"listDate": "2021-11-03 00:00:00",
			"listingAgent": "Kim Deol",
			"listPrice": 839999,
			"lotSize": 0,
			"sqftTotal": 1348,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973161",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "94568",
			"path": "dublin-ca/5586-el-dorado-ln-dublin-ca-94568-12_40973161/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d3fc162b-156e-4ebf-be41-13080a7c3430",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-11-04 00:11:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-03 00:00:00",
			"virtualTourLink": "https://tour.jacoballenmedia.com/tours/mkisdu?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.707294,
				"lng": -121.898107,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5586 El Dorado Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 895000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.jacoballenmedia.com/tours/mkisdu?mls"
		},
		{
			"closePrice": 1750000,
			"daysOnMovoto": 35,
			"id": "699a9358-6fea-4716-8c35-c353f337eca2",
			"tnImgPath": "https://pi.movoto.com/p/12/40973248_0_aINfuI_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Steve Lee",
			"listPrice": 1595000,
			"lotSize": 3694,
			"sqftTotal": 2906,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973248",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2017,
			"zipCode": "94568",
			"path": "dublin-ca/7451-yountville-st-dublin-ca-94568-12_40973248/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "074bb117-d0e3-472f-ac12-844abe81ef70",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-11-04 19:11:45",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": "https://tour.jacoballenmedia.com/3d-matterport/9eoqnt?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.737676,
				"lng": -121.87763,
				"zipcode": "94568",
				"subPremise": "",
				"address": "7451 Yountville St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.jacoballenmedia.com/3d-matterport/9eoqnt?mls"
		},
		{
			"closePrice": 1200000,
			"daysOnMovoto": 53,
			"id": "dad9ec71-2f2f-4f93-a3c5-b2095b9740d0",
			"tnImgPath": "https://pi.movoto.com/p/12/40971083_0_RjvJFV_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Joe Frazzano",
			"listPrice": 1198000,
			"lotSize": 0,
			"sqftTotal": 2333,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971083",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2020,
			"zipCode": "94568",
			"path": "dublin-ca/5906-easton-rd-dublin-ca-94568-12_40968446/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "88927683-c6a6-46ed-87e2-32dc3e23afa6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-15 16:11:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2920860",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.711896,
				"lng": -121.893712,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5906 Easton Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2920860"
		},
		{
			"closePrice": 1125000,
			"daysOnMovoto": 62,
			"id": "74742a44-fe13-4d09-881e-ff60e4892c11",
			"tnImgPath": "https://pi.movoto.com/p/12/40969825_0_iVN7E2_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Jeff Colon",
			"listPrice": 1124000,
			"lotSize": 0,
			"sqftTotal": 1883,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969825",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Jeff Colon Group",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2015,
			"zipCode": "94568",
			"path": "dublin-ca/4187-blackrock-dublin-ca-94568-12_40969825/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bfdb45a3-6ed2-4985-92d8-091c1a1413a8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-06 17:41:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": "https://bit.ly/4187VID",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.7105,
				"lng": -121.84681,
				"zipcode": "94568",
				"subPremise": "",
				"address": "4187 Blackrock"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1125000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://bit.ly/4187VID"
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 43,
			"id": "601af412-9206-4ff9-919f-1439718ac4c0",
			"tnImgPath": "https://pi.movoto.com/p/12/40971592_0_bIaYaf_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "James Dye",
			"listPrice": 999000,
			"lotSize": 0,
			"sqftTotal": 1933,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971592",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Heritage Properties",
			"photoCount": 26,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "94568",
			"path": "dublin-ca/5615-dublin-blvd-dublin-ca-94568-12_40971592/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "82d4bb09-3510-4e69-a3be-d75c5d24aaa8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-02 00:00:00",
			"createdAt": "2021-10-20 19:16:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=Z4ZQjPH86eX",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.706591,
				"lng": -121.897655,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5615 Dublin Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=Z4ZQjPH86eX"
		},
		{
			"closePrice": 900000,
			"daysOnMovoto": 37,
			"id": "5fbf6834-31d6-4fc8-9061-4701f2615ac6",
			"tnImgPath": "https://pi.movoto.com/p/12/40972138_0_2JBUmF_p.jpeg",
			"listDate": "2021-10-24 00:00:00",
			"listingAgent": "Mir Said",
			"listPrice": 869000,
			"lotSize": 11555,
			"sqftTotal": 1348,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972138",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2018,
			"zipCode": "94568",
			"path": "dublin-ca/5622-el-dorado-ln-dublin-ca-94568-12_40972138/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0df4a332-5619-468c-8d74-b79e916ccfe2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-24 18:46:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.707069,
				"lng": -121.8983,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5622 El Dorado Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 0,
			"id": "29b1f072-58dd-45a1-b2a4-abb871b5db62",
			"tnImgPath": "https://pi.movoto.com/p/102/221091830_0_UIY3Qq_p.jpeg",
			"listDate": null,
			"listingAgent": "Donny Piwowarski",
			"listPrice": 975950,
			"lotSize": 0,
			"sqftTotal": 1610,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221091830",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Hero Real Estate",
			"photoCount": 15,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1999,
			"zipCode": "94568",
			"path": "dublin-ca/4824-hibernia-dr-110-dublin-ca-94568/pid_ohngvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9ee49b5c-1d57-4494-87e0-710af2f9aaf5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-10-22 21:49:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 21:49:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.706908,
				"lng": -121.885029,
				"zipcode": "94568",
				"subPremise": "APT 110",
				"address": "4824 Hibernia Dr #110"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1035000,
			"daysOnMovoto": 31,
			"id": "6be28a72-d777-4085-b965-a68f5dbe993b",
			"tnImgPath": "https://pi.movoto.com/p/12/40972381_0_EzRNzn_p.jpeg",
			"listDate": "2021-10-29 00:00:00",
			"listingAgent": "Gail Boal",
			"listPrice": 940000,
			"lotSize": 7014,
			"sqftTotal": 1116,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40972381",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 16,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1961,
			"zipCode": "94568",
			"path": "dublin-ca/7842-canterbury-ln-dublin-ca-94568/pid_xmvfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0d6d36c8-3731-46db-9d3d-21bb3c8be3fc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-29 15:46:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-29 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.714613,
				"lng": -121.930912,
				"zipcode": "94568",
				"subPremise": "",
				"address": "7842 Canterbury Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1035000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1210000,
			"daysOnMovoto": 0,
			"id": "6b0f77c3-4cf7-45f7-8818-b138d507fe34",
			"tnImgPath": "https://pi.movoto.com/p/102/221134585_0_uIeiB2_p.jpeg",
			"listDate": null,
			"listingAgent": "Prithvi Johal",
			"listPrice": 1174000,
			"lotSize": 0,
			"sqftTotal": 2129,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221134585",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "GNN Real Estate",
			"photoCount": 59,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2019,
			"zipCode": "94568",
			"path": "dublin-ca/5882-cadence-ave-dublin-ca-94568-102_221089816/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "23846471-c569-48b1-bd18-0427eb41e24d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-20 18:19:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 18:19:35",
			"virtualTourLink": "https://my.matterport.com/show/?m=5qpLpaNUkjT&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.706221,
				"lng": -121.901933,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5882 Cadence Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1210000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=5qpLpaNUkjT&brand=0"
		},
		{
			"closePrice": 1116000,
			"daysOnMovoto": 40,
			"id": "209b6ebb-4a4e-4257-97dc-99dbd82d89c3",
			"tnImgPath": "https://pi.movoto.com/p/12/40971022_0_a7IAnz_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Kim Deol",
			"listPrice": 959000,
			"lotSize": 0,
			"sqftTotal": 1637,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971022",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2019,
			"zipCode": "94568",
			"path": "dublin-ca/7066-stags-leap-ln-dublin-ca-94568-12_40971022/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "63cdf7ee-7087-4b6c-9c4c-4bc2af68122e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-15 00:31:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://7066stagsleap.com?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.734885,
				"lng": -121.872104,
				"zipcode": "94568",
				"subPremise": "",
				"address": "7066 Stags Leap Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1116000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://7066stagsleap.com?mls"
		},
		{
			"closePrice": 520000,
			"daysOnMovoto": 86,
			"id": "bc9c8b18-03ba-469d-88c1-b19bce2be166",
			"tnImgPath": "https://pi.movoto.com/p/12/40964672_0_vi2iNe_p.jpeg",
			"listDate": "2021-08-25 00:00:00",
			"listingAgent": "Mayling Trinh",
			"listPrice": 539000,
			"lotSize": 0,
			"sqftTotal": 732,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964672",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "BHG RE Reliance Partners",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1987,
			"zipCode": "94568",
			"path": "dublin-ca/7635-arbor-creek-cir-112-dublin-ca-94568-12_40560950/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "2a67bf35-60c3-4fc8-80c9-77f62784a1a7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-19 00:00:00",
			"createdAt": "2021-08-26 07:01:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-25 00:00:00",
			"virtualTourLink": "https://7635ArborCreekCir45985mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.707897,
				"lng": -121.938166,
				"zipcode": "94568",
				"subPremise": "APT 112",
				"address": "7635 Arbor Creek Cir #112"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 520000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://7635ArborCreekCir45985mls.f8re.com/"
		},
		{
			"closePrice": 2450000,
			"daysOnMovoto": 35,
			"id": "c2ea0aed-4324-4a00-aa95-a1e83b332dab",
			"tnImgPath": "https://pi.movoto.com/p/12/40970901_0_BaJzqJ_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Flavio Amaral",
			"listPrice": 2225000,
			"lotSize": 6305,
			"sqftTotal": 3631,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970901",
			"bath": 3.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2011,
			"zipCode": "94568",
			"path": "dublin-ca/2500-encanto-way-dublin-ca-94568/pid_nwogvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5a25f848-c697-48c7-a69c-a92d51e23e47",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-18 00:00:00",
			"createdAt": "2021-10-14 18:26:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.725459,
				"lng": -121.849256,
				"zipcode": "94568",
				"subPremise": "",
				"address": "2500 Encanto Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2318888,
			"daysOnMovoto": 16,
			"id": "6d467b4a-2bf2-4801-b498-e475be5f02f0",
			"tnImgPath": "https://pi.movoto.com/p/110/421608713_0_V6NA27_p.jpeg",
			"listDate": "2021-11-01 00:00:00",
			"listingAgent": "Budi Hartono",
			"listPrice": 2082800,
			"lotSize": 7705,
			"sqftTotal": 3386,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421608713",
			"bath": 5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 2,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2011,
			"zipCode": "94568",
			"path": "dublin-ca/7501-marshall-canyon-dr-dublin-ca-94568/pid_5z9fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1c6629ba-5814-4d46-9c53-5bfed54188d5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-11-05 21:16:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-01 00:00:00",
			"virtualTourLink": "https://drive.google.com/file/d/1SUoTYg6yihsaVVzQMOKkwJMdDdjxCKyG/view?usp=sharing",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.70077,
				"lng": -121.975105,
				"zipcode": "94568",
				"subPremise": "",
				"address": "7501 Marshall Canyon Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2318888,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://drive.google.com/file/d/1SUoTYg6yihsaVVzQMOKkwJMdDdjxCKyG/view?usp=sharing"
		},
		{
			"closePrice": 1175000,
			"daysOnMovoto": 33,
			"id": "07cc1ea7-f017-4544-bf05-d1848ff25e9b",
			"tnImgPath": "https://pi.movoto.com/p/12/40970751_0_M2anVn_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Angela Rose",
			"listPrice": 1149000,
			"lotSize": 0,
			"sqftTotal": 2002,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970751",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Rose Team",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2015,
			"zipCode": "94568",
			"path": "dublin-ca/2056-central-pkwy-dublin-ca-94568-12_40970751/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bca89298-ea20-4cd5-851a-0f6edceb5e7b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-10-13 20:16:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.709415,
				"lng": -121.842176,
				"zipcode": "94568",
				"subPremise": "",
				"address": "2056 Central Pkwy"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1175000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 33,
			"id": "15083384-9d61-447a-8edb-d7f87a73ad52",
			"tnImgPath": "https://pi.movoto.com/p/12/40970688_0_VjfV6V_p.jpeg",
			"listDate": "2021-10-13 00:00:00",
			"listingAgent": "Ted Chen",
			"listPrice": 1049000,
			"lotSize": 1827,
			"sqftTotal": 1692,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970688",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2006,
			"zipCode": "94568",
			"path": "dublin-ca/6086-hillbrook-pl-dublin-ca-94568/pid_k5rgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6327a726-1d87-49bb-88df-8cb5777c9114",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-15 00:00:00",
			"createdAt": "2021-10-13 08:01:13",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=sA6f7zLTHSM&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.721688,
				"lng": -121.875095,
				"zipcode": "94568",
				"subPremise": "",
				"address": "6086 Hillbrook Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=sA6f7zLTHSM&mls=1"
		},
		{
			"closePrice": 2475000,
			"daysOnMovoto": 33,
			"id": "6ecd3289-e602-4c8e-adee-83aa8ab95233",
			"tnImgPath": "https://pi.movoto.com/p/12/40969669_0_MeZeVI_p.jpeg",
			"listDate": "2021-10-08 00:00:00",
			"listingAgent": "Azad Aramandla",
			"listPrice": 2199000,
			"lotSize": 5449,
			"sqftTotal": 3467,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969669",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2014,
			"zipCode": "94568",
			"path": "dublin-ca/2220-valentano-dr-dublin-ca-94568/pid_0z4hvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4b622cad-8aea-4850-b30e-bc943c6e7c8a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-08 05:36:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-08 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=Xm7fH5c84fy&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.721723,
				"lng": -121.844604,
				"zipcode": "94568",
				"subPremise": "",
				"address": "2220 Valentano Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2475000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=Xm7fH5c84fy&mls=1"
		},
		{
			"closePrice": 705000,
			"daysOnMovoto": 34,
			"id": "ff55e6d4-a875-4f6c-b99f-cbdddf2cf8e2",
			"tnImgPath": "https://pi.movoto.com/p/12/40970078_0_BaFFVe_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Melissa Pederson",
			"listPrice": 699500,
			"lotSize": 0,
			"sqftTotal": 963,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970078",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Venture Sotheby's Intl Rlty",
			"photoCount": 20,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 1990,
			"zipCode": "94568",
			"path": "dublin-ca/11834-dunstown-ct-dublin-ca-94568-12_27597242/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "652b129c-d0c2-4514-baaa-c54dc8858d1a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-10 00:00:00",
			"createdAt": "2021-10-07 21:01:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idx2916278",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.704189,
				"lng": -121.939324,
				"zipcode": "94568",
				"subPremise": "",
				"address": "11834 Dunstown Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 705000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idx2916278"
		},
		{
			"closePrice": 1310000,
			"daysOnMovoto": 30,
			"id": "4124cbcf-bac0-4db8-a3ae-7dcf215bfac7",
			"tnImgPath": "https://pi.movoto.com/p/12/40969966_0_BBMFqF_p.jpeg",
			"listDate": "2021-10-06 00:00:00",
			"listingAgent": "Lin Shao",
			"listPrice": 1285000,
			"lotSize": 0,
			"sqftTotal": 2447,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969966",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 18,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2015,
			"zipCode": "94568",
			"path": "dublin-ca/4175-blackrock-rd-dublin-ca-94568-12_40969966/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "85c52d73-1487-4307-b8de-8b620a55deec",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-10-07 05:11:17",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.710354,
				"lng": -121.846807,
				"zipcode": "94568",
				"subPremise": "",
				"address": "4175 Blackrock Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1310000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1315000,
			"daysOnMovoto": 35,
			"id": "049ab45b-9e52-4709-852b-5a39718b4c64",
			"tnImgPath": "https://pi.movoto.com/p/12/40969392_0_zm3F2F_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Beverly Herrera",
			"listPrice": 1315000,
			"lotSize": 0,
			"sqftTotal": 2463,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969392",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Elation Real Estate",
			"photoCount": 36,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2012,
			"zipCode": "94568",
			"path": "dublin-ca/3506-capoterra-way-dublin-ca-94568-12_40530778/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 127000,
			"propertyId": "e6d9e6cb-eba8-4715-9075-f822d96ac158",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-10-01 22:41:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": "https://tour.jacoballenmedia.com/tours/c9onyy?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.710399,
				"lng": -121.864735,
				"zipcode": "94568",
				"subPremise": "",
				"address": "3506 Capoterra Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1315000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tour.jacoballenmedia.com/tours/c9onyy?mls"
		},
		{
			"closePrice": 808000,
			"daysOnMovoto": 44,
			"id": "069cf90a-befa-4247-bcc8-6c2581505bde",
			"tnImgPath": "https://pi.movoto.com/p/12/40968046_0_JvqnQi_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Fred Scheberies",
			"listPrice": 798000,
			"lotSize": 0,
			"sqftTotal": 1420,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968046",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Berkshire Hathaway Home Services",
			"photoCount": 22,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2004,
			"zipCode": "94568",
			"path": "dublin-ca/4257-clarinbridge-cir-dublin-ca-94568/pid_r4mgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3d269527-cc09-495f-a5f1-7acfd91c0cf8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-05 00:00:00",
			"createdAt": "2021-09-22 17:36:50",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.706808,
				"lng": -121.866388,
				"zipcode": "94568",
				"subPremise": "",
				"address": "4257 Clarinbridge Cir"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 808000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1120000,
			"daysOnMovoto": 42,
			"id": "2f51aae6-811a-46f5-9b2b-bcd81512b34b",
			"tnImgPath": "https://pi.movoto.com/p/12/40968211_0_3Uj7Iz_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Giri Uppuganti",
			"listPrice": 1095000,
			"lotSize": 0,
			"sqftTotal": 2372,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968211",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2014,
			"zipCode": "94568",
			"path": "dublin-ca/6482-tralee-village-dr-dublin-ca-94568-12_40661411/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d07c6b1b-4f3f-4baa-a5d5-2910f33efee9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-04 00:00:00",
			"createdAt": "2021-09-23 15:36:48",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.705257,
				"lng": -121.910443,
				"zipcode": "94568",
				"subPremise": "",
				"address": "6482 Tralee Village Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1120000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1173200,
			"daysOnMovoto": 15,
			"id": "76af2545-e58d-48b0-91e4-54a91bd31c04",
			"tnImgPath": "https://pi.movoto.com/p/12/40971533_0_MfeRYv_p.jpeg",
			"listDate": "2021-10-19 00:00:00",
			"listingAgent": "Daniel Gamache",
			"listPrice": 1150000,
			"lotSize": 6479,
			"sqftTotal": 1926,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40971533",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 26,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1968,
			"zipCode": "94568",
			"path": "dublin-ca/8640-beverly-ln-dublin-ca-94568/pid_2b1fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b9fa693d-8cd1-48d1-ac9b-615d2052f642",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-10-20 02:46:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-19 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.725063,
				"lng": -121.930467,
				"zipcode": "94568",
				"subPremise": "",
				"address": "8640 Beverly Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1173200,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1480000,
			"daysOnMovoto": 19,
			"id": "1c95ee72-5501-4415-9935-6dfcd60620b5",
			"tnImgPath": "https://pi.movoto.com/p/110/421604118_0_j3eUFA_p.jpeg",
			"listDate": "2021-10-14 00:00:00",
			"listingAgent": "Dora Calantog",
			"listPrice": 1299000,
			"lotSize": 2312,
			"sqftTotal": 2076,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421604118",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Better Homes and Gardens Real Estate Thrive",
			"photoCount": 47,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2017,
			"zipCode": "94568",
			"path": "dublin-ca/2868-stringham-way-dublin-ca-94568-110_421604118/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d0c56942-0d1b-481f-a015-ddfd6bcc2572",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-10-14 19:31:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-14 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/625563482",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.705974,
				"lng": -121.854727,
				"zipcode": "94568",
				"subPremise": "",
				"address": "2868 Stringham Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1480000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/625563482"
		},
		{
			"closePrice": 1290000,
			"daysOnMovoto": 33,
			"id": "e2f25b76-30c8-43fe-a263-00162e598c34",
			"tnImgPath": "https://pi.movoto.com/p/12/40968666_0_BraUfi_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Manel Sousou",
			"listPrice": 1219000,
			"lotSize": 0,
			"sqftTotal": 2208,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968666",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 37,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2017,
			"zipCode": "94568",
			"path": "dublin-ca/3050-threecastles-way-dublin-ca-94568-12_40968666/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8cb488c3-1a3c-42e5-9845-682bc110146f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-30 17:56:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": "https://3050ThreecastlesWay.com/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.706969,
				"lng": -121.856755,
				"zipcode": "94568",
				"subPremise": "",
				"address": "3050 Threecastles Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1290000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://3050ThreecastlesWay.com/idx"
		},
		{
			"closePrice": 877000,
			"daysOnMovoto": 41,
			"id": "fd8a3e66-69c0-42e3-9eca-7884409a5270",
			"tnImgPath": "https://pi.movoto.com/p/12/40968048_0_EN67uU_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Tracy Yang",
			"listPrice": 849999,
			"lotSize": 0,
			"sqftTotal": 1348,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968048",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 34,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2018,
			"zipCode": "94568",
			"path": "dublin-ca/5508-el-dorado-ln-dublin-ca-94568-12_40968048/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "58474e03-e648-4e03-8755-1478eaafffe5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-02 00:00:00",
			"createdAt": "2021-09-22 17:41:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=UWRS9PHTz8m&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.707726,
				"lng": -121.89768,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5508 El Dorado Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 877000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=UWRS9PHTz8m&mls=1"
		},
		{
			"closePrice": 1340000,
			"daysOnMovoto": 41,
			"id": "de06029e-356b-4f44-8945-43dba28f9063",
			"tnImgPath": "https://pi.movoto.com/p/12/40967728_0_q6BQfQ_p.jpeg",
			"listDate": "2021-09-18 00:00:00",
			"listingAgent": "Carol Sawdey",
			"listPrice": 1298000,
			"lotSize": 0,
			"sqftTotal": 2704,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967728",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2014,
			"zipCode": "94568",
			"path": "dublin-ca/3219-central-pkwy-dublin-ca-94568-12_40685874/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ba60916b-63a4-4c12-ae6a-61098f7c1e73",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-09-18 19:01:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-18 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=9AXqGZ3w18X&mls=1hl=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.708796,
				"lng": -121.860324,
				"zipcode": "94568",
				"subPremise": "",
				"address": "3219 Central Pkwy"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1340000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=9AXqGZ3w18X&mls=1hl=1"
		},
		{
			"closePrice": 1015000,
			"daysOnMovoto": 36,
			"id": "f3e34f3f-7fb1-4cb8-912b-ef689b2ae586",
			"tnImgPath": "https://pi.movoto.com/p/12/40967892_0_f2Mb7u_p.jpeg",
			"listDate": "2021-09-22 00:00:00",
			"listingAgent": "Varsha Upadhye",
			"listPrice": 878000,
			"lotSize": 1850,
			"sqftTotal": 1528,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967892",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1988,
			"zipCode": "94568",
			"path": "dublin-ca/11474-winding-trail-ln-dublin-ca-94568/pid_r44fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b0b8b3c2-6533-4b3d-9ab6-89af2d6ef8ec",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-28 00:00:00",
			"createdAt": "2021-09-22 14:41:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-22 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2914614",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.706653,
				"lng": -121.947033,
				"zipcode": "94568",
				"subPremise": "",
				"address": "11474 Winding Trail Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1015000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2914614"
		},
		{
			"closePrice": 1090000,
			"daysOnMovoto": 43,
			"id": "98bfbd0d-c728-4844-89e5-f1b574a837f0",
			"tnImgPath": "https://pi.movoto.com/p/12/40966998_0_QIYEfj_p.jpeg",
			"listDate": "2021-09-14 00:00:00",
			"listingAgent": "Catherine Rabago",
			"listPrice": 970000,
			"lotSize": 0,
			"sqftTotal": 1637,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966998",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "eXp Realty of California, Inc",
			"photoCount": 40,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2018,
			"zipCode": "94568",
			"path": "dublin-ca/6984-geyserville-st-dublin-ca-94568-12_40966998/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bf452b2b-2869-4d71-9a59-036dc359b7d8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-27 00:00:00",
			"createdAt": "2021-09-15 03:01:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 00:00:00",
			"virtualTourLink": "https://6984GeyservilleStreet46724mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.734665,
				"lng": -121.872607,
				"zipcode": "94568",
				"subPremise": "",
				"address": "6984 Geyserville St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1090000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://6984GeyservilleStreet46724mls.f8re.com/"
		},
		{
			"closePrice": 1380000,
			"daysOnMovoto": 31,
			"id": "5aa721d6-e41f-451e-87ff-a022ac37e845",
			"tnImgPath": "https://pi.movoto.com/p/12/40967190_0_jinvif_p.jpeg",
			"listDate": "2021-09-25 00:00:00",
			"listingAgent": "Tony Machado",
			"listPrice": 1225000,
			"lotSize": 3150,
			"sqftTotal": 1655,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967190",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Exp Realty of California Inc.",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2002,
			"zipCode": "94568",
			"path": "dublin-ca/5189-san-viciente-loop-dublin-ca-94568/pid_ioqgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "310b1b3a-1777-47a8-a397-c6d77a4ebcb0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-25 20:11:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-25 00:00:00",
			"virtualTourLink": "https://5189sanvicienteloop47445mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.713281,
				"lng": -121.873583,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5189 San Viciente Loop"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1380000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://5189sanvicienteloop47445mls.f8re.com/"
		},
		{
			"closePrice": 1205000,
			"daysOnMovoto": 42,
			"id": "feb30686-7b8a-44e8-981e-2bfce30f8aa6",
			"tnImgPath": "https://pi.movoto.com/p/12/40967072_0_UzJ6ee_p.jpeg",
			"listDate": "2021-09-14 00:00:00",
			"listingAgent": "Ivan Santacruz",
			"listPrice": 1150000,
			"lotSize": 6999,
			"sqftTotal": 1464,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967072",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 32,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1962,
			"zipCode": "94568",
			"path": "dublin-ca/8738-wicklow-ln-dublin-ca-94568/pid_mtwfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4d683e5c-e358-4f3f-8f00-9001c0fb3ddb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-26 00:00:00",
			"createdAt": "2021-09-14 21:01:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.722693,
				"lng": -121.937645,
				"zipcode": "94568",
				"subPremise": "",
				"address": "8738 Wicklow Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1205000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 42,
			"id": "e56bb433-cea7-4130-9b7b-405486e67b6a",
			"tnImgPath": "https://pi.movoto.com/p/12/40966956_0_2uAeyE_p.jpeg",
			"listDate": "2021-09-13 00:00:00",
			"listingAgent": "Tony Machado",
			"listPrice": 1050000,
			"lotSize": 0,
			"sqftTotal": 1807,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966956",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Exp Realty of California Inc.",
			"photoCount": 19,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2007,
			"zipCode": "94568",
			"path": "dublin-ca/3769-branding-iron-pl-dublin-ca-94568-12_40550575/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c0390823-1310-47d4-9b24-ccd706ce68f8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-09-13 22:26:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-13 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.732651,
				"lng": -121.869092,
				"zipcode": "94568",
				"subPremise": "",
				"address": "3769 Branding Iron Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1120000,
			"daysOnMovoto": 28,
			"id": "94024cdc-06c9-4a32-866f-9ad0edc49802",
			"tnImgPath": "https://pi.movoto.com/p/12/40967772_0_FVfM2V_p.jpeg",
			"listDate": "2021-09-23 00:00:00",
			"listingAgent": "Peter Wardhana",
			"listPrice": 979000,
			"lotSize": 6007,
			"sqftTotal": 1446,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40967772",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Accord",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1966,
			"zipCode": "94568",
			"path": "dublin-ca/6793-maple-dr-dublin-ca-94568/pid_2eyfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "db3164c8-9032-4ed6-a4f9-5c04c57fd4ca",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-09-24 00:16:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-23 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.70771,
				"lng": -121.922622,
				"zipcode": "94568",
				"subPremise": "",
				"address": "6793 Maple Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1120000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 920000,
			"daysOnMovoto": 36,
			"id": "c7a50c48-a34b-49bd-85e8-eda5b1df7e8c",
			"tnImgPath": "https://pi.movoto.com/p/12/40965968_0_zrZAIi_p.jpeg",
			"listDate": "2021-09-15 00:00:00",
			"listingAgent": "Anil Reddy",
			"listPrice": 865000,
			"lotSize": 0,
			"sqftTotal": 1348,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965968",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Allison James Estates&Homes",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2019,
			"zipCode": "94568",
			"path": "dublin-ca/5696-el-dorado-ln-dublin-ca-94568-12_40965968/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2d48df2e-11b4-4601-88fb-76efd2eeab02",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-21 00:00:00",
			"createdAt": "2021-09-15 22:41:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.706621,
				"lng": -121.898875,
				"zipcode": "94568",
				"subPremise": "",
				"address": "5696 El Dorado Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 920000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1150000,
			"daysOnMovoto": 44,
			"id": "df9baf5e-f2a3-41a6-b9f5-f3507f7f6bb4",
			"tnImgPath": "https://pi.movoto.com/p/12/40966025_0_QBi6Z6_p.jpeg",
			"listDate": "2021-09-06 00:00:00",
			"listingAgent": "Shelley Lee Stone",
			"listPrice": 989000,
			"lotSize": 6999,
			"sqftTotal": 2000,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966025",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Golden Hills Brkrs/PB",
			"photoCount": 35,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1961,
			"zipCode": "94568",
			"path": "dublin-ca/8157-tamarack-dr-dublin-ca-94568/pid_o1vfvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d1bfc74e-e150-4502-b665-a88d35348e00",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-09-07 02:16:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-06 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.717264,
				"lng": -121.932778,
				"zipcode": "94568",
				"subPremise": "",
				"address": "8157 Tamarack Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1017000,
			"daysOnMovoto": 112,
			"id": "aeaa8b75-13c5-404e-bdf6-f7d75b9a13b0",
			"tnImgPath": "https://pi.movoto.com/p/12/40956666_0_Faz7Ff_p.jpeg",
			"listDate": "2021-06-30 00:00:00",
			"listingAgent": "Thomas Montano",
			"listPrice": 1049000,
			"lotSize": 6479,
			"sqftTotal": 1926,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956666",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Re/Max Accord",
			"photoCount": 2,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1968,
			"zipCode": "94568",
			"path": "dublin-ca/8648-beverly-ln-dublin-ca-94568/pid_8a1fvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -50999,
			"propertyId": "490b4c52-364a-4390-be09-1566c787725b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-20 00:00:00",
			"createdAt": "2021-06-30 14:46:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.725006,
				"lng": -121.930884,
				"zipcode": "94568",
				"subPremise": "",
				"address": "8648 Beverly Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1017000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1700000,
			"daysOnMovoto": 0,
			"id": "1f5f67ff-33f6-4361-a1a5-19d283e23103",
			"tnImgPath": "https://pi.movoto.com/p/102/221116924_0_MEfFje_p.jpeg",
			"listDate": null,
			"listingAgent": "Mary Margaretich",
			"listPrice": 1450000,
			"lotSize": 15155,
			"sqftTotal": 2039,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221116924",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno Group",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1965,
			"zipCode": "94568",
			"path": "dublin-ca/7751-peppertree-rd-dublin-ca-94568-12_40456856/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "884061e9-0463-4e1b-8c06-5c6888b9c3ba",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-18 00:00:00",
			"createdAt": "2021-09-14 15:29:42",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-14 15:29:42",
			"virtualTourLink": "https://my.matterport.com/show/?m=hCEHF5npkcE",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.710435,
				"lng": -121.9416,
				"zipcode": "94568",
				"subPremise": "",
				"address": "7751 Peppertree Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=hCEHF5npkcE"
		},
		{
			"closePrice": 1110000,
			"daysOnMovoto": 15,
			"id": "825cf654-b7fc-4332-88c6-bdf7dd477595",
			"tnImgPath": "https://pi.movoto.com/p/12/40969013_0_2j2UA3_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Catherine Lau",
			"listPrice": 1060000,
			"lotSize": 0,
			"sqftTotal": 1879,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969013",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "CA Golden Properties",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2018,
			"zipCode": "94568",
			"path": "dublin-ca/3358-dublin-blvd-dublin-ca-94568-12_40969013/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "76f7a1e0-36df-4ca4-8af9-d49597c7b86f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-10-01 00:56:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.704433,
				"lng": -121.862468,
				"zipcode": "94568",
				"subPremise": "",
				"address": "3358 Dublin Blvd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1110000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 743000,
			"daysOnMovoto": 72,
			"id": "2fa9b98e-2a9b-4b2e-8516-2252e0e62e21",
			"tnImgPath": "https://pi.movoto.com/p/12/40961769_0_ZE3Mez_p.jpeg",
			"listDate": "2021-08-04 00:00:00",
			"listingAgent": "Steve Mohseni",
			"listPrice": 748800,
			"lotSize": 0,
			"sqftTotal": 1298,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40961769",
			"bath": 2.5,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 35,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 2007,
			"zipCode": "94568",
			"path": "dublin-ca/3420-finnian-way-402-dublin-ca-94568/pid_zmpgvaf38g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2c00c901-44a7-4131-8fef-6b786a6355d6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-15 00:00:00",
			"createdAt": "2021-08-05 01:46:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-04 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2903314",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.705977,
				"lng": -121.862852,
				"zipcode": "94568",
				"subPremise": "APT 402",
				"address": "3420 Finnian Way #402"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 743000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2903314"
		},
		{
			"closePrice": 1515000,
			"daysOnMovoto": 36,
			"id": "8efa7c42-926a-4d25-a2e0-aad037f73b48",
			"tnImgPath": "https://pi.movoto.com/p/12/40965715_0_EvIZRi_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Sandy Sen Sun",
			"listPrice": 1325000,
			"lotSize": 2678,
			"sqftTotal": 1862,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40965715",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Byerly Properties Inc.",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2016,
			"zipCode": "94568",
			"path": "dublin-ca/2682-randall-loop-dublin-ca-94568-12_40965715/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4b153b69-8248-41b0-ba01-03bbf8644fc8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-09-08 20:41:44",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.708981,
				"lng": -121.854368,
				"zipcode": "94568",
				"subPremise": "",
				"address": "2682 Randall Loop"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1515000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 1355000,
			"daysOnMovoto": 36,
			"id": "a983e2fd-d5dd-49ea-adfe-837430d22d72",
			"tnImgPath": "https://pi.movoto.com/p/12/40966234_0_7JiNnV_p.jpeg",
			"listDate": "2021-09-08 00:00:00",
			"listingAgent": "Manel Sousou",
			"listPrice": 1299000,
			"lotSize": 2550,
			"sqftTotal": 1764,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40966234",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Keller Williams Tri-Valley",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2017,
			"zipCode": "94568",
			"path": "dublin-ca/2933-stringham-way-dublin-ca-94568-12_40966234/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d6281acd-c3e1-43d7-b18d-a38f6d948cd6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-09-08 19:31:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-08 00:00:00",
			"virtualTourLink": "https://2933StringhamWay.com/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.705872,
				"lng": -121.855438,
				"zipcode": "94568",
				"subPremise": "",
				"address": "2933 Stringham Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1355000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://2933StringhamWay.com/idx"
		},
		{
			"closePrice": 1050000,
			"daysOnMovoto": 52,
			"id": "34174ff6-4433-4fc2-ad28-1bcd052bfce0",
			"tnImgPath": "https://pi.movoto.com/p/12/40964331_0_YB7UZq_p.jpeg",
			"listDate": "2021-08-23 00:00:00",
			"listingAgent": "Matt Elmore",
			"listPrice": 1049000,
			"lotSize": 0,
			"sqftTotal": 1807,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40964331",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 33,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2015,
			"zipCode": "94568",
			"path": "dublin-ca/3825-branding-iron-pl-dublin-ca-94568-12_40964331/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ea010ee3-9b12-4037-9d60-2b7f3253062e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-14 00:00:00",
			"createdAt": "2021-08-24 00:26:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-23 00:00:00",
			"virtualTourLink": "https://summer-anderson-photography.seehouseat.com/1891473?idx=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Dublin",
				"lat": 37.732249,
				"lng": -121.869366,
				"zipcode": "94568",
				"subPremise": "",
				"address": "3825 Branding Iron Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://summer-anderson-photography.seehouseat.com/1891473?idx=1"
		}
	]