exports.ANTHERTON_LISTINGS = [
		{
			"closePrice": 5450000,
			"daysOnMovoto": 156,
			"id": "61eb7a77-89a8-422f-920d-d1eddcb1f107",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81857354_0_FfUNzF_p.jpeg",
			"listDate": "2021-08-07 11:51:20",
			"listingAgent": "Mary & Brent Gullixson",
			"listPrice": 5995000,
			"lotSize": 45912,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81857354",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 1,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94027",
			"path": "atherton-ca/38-sargent-ln-atherton-ca-94027-100_ml81797082/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "06904e9a-4799-46d4-af2f-528b7304888b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-08-07 18:57:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-07 11:51:20",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.428571,
				"lng": -122.22555,
				"zipcode": "94027",
				"subPremise": "",
				"address": "38 Sargent Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3960000,
			"daysOnMovoto": 0,
			"id": "8180d390-54af-4544-abe1-75c0102f8e30",
			"tnImgPath": "https://pi.movoto.com/p/102/421563988_0_vYQvE3_p.jpeg",
			"listDate": null,
			"listingAgent": "Abies Tsang",
			"listPrice": 3960000,
			"lotSize": 1,
			"sqftTotal": 2200,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421563988",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Vista Pacific Realty",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1953,
			"zipCode": "94027",
			"path": "atherton-ca/1-nora-atherton-ca-94027-102_421563988/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8ec9781d-9a65-4338-9f7a-5c9f6d90371b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-04 00:00:00",
			"createdAt": "2021-06-17 22:19:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-17 22:19:25",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.46559,
				"lng": -122.207616,
				"zipcode": "94027",
				"subPremise": "",
				"address": "1 Nora"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3960000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 5449000,
			"daysOnMovoto": 31,
			"id": "655e59d9-3d99-44f3-bd7a-3c02eb7e7e01",
			"tnImgPath": "https://pi.movoto.com/p/12/40944600_0_nnUQ3E_p.jpeg",
			"listDate": "2021-04-08 00:00:00",
			"listingAgent": "Leila Santos",
			"listPrice": 5499000,
			"lotSize": 43561,
			"sqftTotal": 5307,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40944600",
			"bath": 4.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1964,
			"zipCode": "94027",
			"path": "atherton-ca/2-heritage-ct-atherton-ca-94027-100_80042205/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "81c7cdac-9821-4a93-977e-7af3150a407d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-09 00:00:00",
			"createdAt": "2021-04-08 05:11:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-08 00:00:00",
			"virtualTourLink": "Http://www.2heritagect.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.469018,
				"lng": -122.191847,
				"zipcode": "94027",
				"subPremise": "",
				"address": "2 Heritage Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5449000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.2heritagect.com/?mls"
		},
		{
			"closePrice": 8300000,
			"daysOnMovoto": 72,
			"id": "dd3746d0-921f-4015-8361-c203ce3d464e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827724_0_6R7YVR_p.jpeg",
			"listDate": "2021-01-29 13:29:22",
			"listingAgent": "Hugh Cornish",
			"listPrice": 8450000,
			"lotSize": 53252,
			"sqftTotal": 4690,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827724",
			"bath": 5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1979,
			"zipCode": "94027",
			"path": "atherton-ca/345-selby-ln-atherton-ca-94027-100_931665/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -545000,
			"propertyId": "30a31360-0af3-4c81-9446-5f83dce172f2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-12 00:00:00",
			"createdAt": "2021-01-29 21:32:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-29 13:29:22",
			"virtualTourLink": "Http://www.345Selby.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.45399,
				"lng": -122.215397,
				"zipcode": "94027",
				"subPremise": "",
				"address": "345 Selby Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 8300000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.345Selby.com"
		},
		{
			"closePrice": 3650000,
			"daysOnMovoto": 83,
			"id": "4e767016-c7ab-4942-99a4-162d35aa79ea",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825815_0_N767ZQ_p.jpeg",
			"listDate": "2021-01-14 00:00:00",
			"listingAgent": "Michael Warren",
			"listPrice": 3980000,
			"lotSize": 8416,
			"sqftTotal": 3745,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825815",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1978,
			"zipCode": "94027",
			"path": "atherton-ca/89-park-dr-atherton-ca-94027-100_80952681/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5d46bafd-48c2-4571-80b2-2df24cbf0a5a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-07 00:00:00",
			"createdAt": "2021-02-10 21:47:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-14 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.461554,
				"lng": -122.205501,
				"zipcode": "94027",
				"subPremise": "",
				"address": "89 Park Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 5400000,
			"daysOnMovoto": 33,
			"id": "4c0fa0d5-4ee0-4791-86eb-14dbba632bf5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830941_0_aiyZUz_p.jpeg",
			"listDate": "2021-02-23 06:00:42",
			"listingAgent": "Lan L. Bowling",
			"listPrice": 5180000,
			"lotSize": 36708,
			"sqftTotal": 3640,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830941",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Palo Alto",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1949,
			"zipCode": "94027",
			"path": "atherton-ca/143-selby-ln-atherton-ca-94027-100_81403129/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "918ff101-13da-4624-9954-53ce0a59a705",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-29 00:00:00",
			"createdAt": "2021-02-23 14:02:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-23 06:00:42",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.459233,
				"lng": -122.217546,
				"zipcode": "94027",
				"subPremise": "",
				"address": "143 Selby Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 6500000,
			"daysOnMovoto": 54,
			"id": "465fd754-6ac3-4d20-8314-05a79380d025",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825174_0_zYBYIy_p.jpeg",
			"listDate": "2021-01-27 00:00:00",
			"listingAgent": "Karin Riley",
			"listPrice": 6650000,
			"lotSize": 41800,
			"sqftTotal": 2680,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825174",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1954,
			"zipCode": "94027",
			"path": "atherton-ca/46-lilac-dr-atherton-ca-94027/pid_ff37w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ed78c442-9c24-4c57-81d5-7190ac65c7d7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-22 00:00:00",
			"createdAt": "2021-01-27 18:17:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-27 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.474372,
				"lng": -122.181745,
				"zipcode": "94027",
				"subPremise": "",
				"address": "46 Lilac Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 9100000,
			"daysOnMovoto": 153,
			"id": "a8dd1011-142b-43a1-94d1-fb07e2eaf46f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81815756_0_BjNvIE_p.jpeg",
			"listDate": "2020-10-15 10:27:43",
			"listingAgent": "Hugh Cornish",
			"listPrice": 9998000,
			"lotSize": 326831,
			"sqftTotal": 5705,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81815756",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 71,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 2007,
			"zipCode": "94027",
			"path": "atherton-ca/60-valley-ct-atherton-ca-94027-100_80606511/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -890000,
			"propertyId": "fab03fd5-c5be-49fe-ae1b-64a948e97383",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-18 00:00:00",
			"createdAt": "2020-10-15 17:32:43",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-10-15 10:27:43",
			"virtualTourLink": "Http://60Valley.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.428581,
				"lng": -122.230886,
				"zipcode": "94027",
				"subPremise": "",
				"address": "60 Valley Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 9100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://60Valley.com"
		},
		{
			"closePrice": 13250000,
			"daysOnMovoto": 12,
			"id": "3128d544-ec57-4906-bb63-5f3a003bc9b1",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832279_0_IJzNrJ_p.jpeg",
			"listDate": "2021-03-03 13:05:04",
			"listingAgent": "Courtney Charney",
			"listPrice": 13250000,
			"lotSize": 33750,
			"sqftTotal": 6720,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832279",
			"bath": 5.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Parc Agency Corporation",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1998,
			"zipCode": "94027",
			"path": "atherton-ca/43-amador-ave-atherton-ca-94027-100_81309333/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3c1cffd8-ab91-4520-a080-320fd579ba9a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-16 00:00:00",
			"createdAt": "2021-03-03 21:07:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-03 13:05:04",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.455221,
				"lng": -122.214491,
				"zipcode": "94027",
				"subPremise": "",
				"address": "43 Amador Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 13250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 6350000,
			"daysOnMovoto": 48,
			"id": "561d7212-2cc6-4651-a7b8-9550a75e2459",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826288_0_yMEFvu_p.jpeg",
			"listDate": "2021-01-19 10:21:42",
			"listingAgent": "Kristin Gray",
			"listPrice": 6495000,
			"lotSize": 45146,
			"sqftTotal": 4200,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826288",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 15,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1958,
			"zipCode": "94027",
			"path": "atherton-ca/269-stockbridge-ave-atherton-ca-94027-100_81466578/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2cc66045-5cf4-49d2-98aa-78137e4ce47e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-09 00:00:00",
			"createdAt": "2021-01-20 03:17:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-19 10:21:42",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.445956,
				"lng": -122.221988,
				"zipcode": "94027",
				"subPremise": "",
				"address": "269 Stockbridge Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4700000,
			"daysOnMovoto": 241,
			"id": "da939534-7ef3-4652-9eb0-bc00c1226190",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81798728_0_7qUBMq_p.jpeg",
			"listDate": "2020-06-29 08:59:25",
			"listingAgent": "Mary & Brent Gullixson",
			"listPrice": 4995000,
			"lotSize": 17550,
			"sqftTotal": 3485,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81798728",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1995,
			"zipCode": "94027",
			"path": "atherton-ca/80-amador-ave-atherton-ca-94027-100_81309357/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -705000,
			"propertyId": "2882fc8f-6175-4845-9e7d-604e78d567c0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-26 00:00:00",
			"createdAt": "2020-06-29 16:07:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-06-29 08:59:25",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.45397,
				"lng": -122.213802,
				"zipcode": "94027",
				"subPremise": "",
				"address": "80 Amador Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 6450000,
			"daysOnMovoto": 190,
			"id": "8ef27e4c-e06a-4f00-8bc9-7a4c5f57834a",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81805797_0_Qv7FB7_p.jpeg",
			"listDate": "2020-08-12 21:14:39",
			"listingAgent": "Mary & Brent Gullixson",
			"listPrice": 6750000,
			"lotSize": 34195,
			"sqftTotal": 4750,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81805797",
			"bath": 5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 13,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1977,
			"zipCode": "94027",
			"path": "atherton-ca/439-walsh-rd-atherton-ca-94027-100_80261333/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -245000,
			"propertyId": "df9fd190-0a57-4f84-adab-7ae938a16e94",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-19 00:00:00",
			"createdAt": "2020-08-13 04:17:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-08-12 21:14:39",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.428385,
				"lng": -122.221655,
				"zipcode": "94027",
				"subPremise": "",
				"address": "439 Walsh Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 7000000,
			"daysOnMovoto": 78,
			"id": "407815c2-062b-4eac-b255-953246f811cb",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81818609_0_6vyAuI_p.jpeg",
			"listDate": "2020-11-03 09:51:19",
			"listingAgent": "Sam Anagnostou",
			"listPrice": 7808000,
			"lotSize": 54877,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81818609",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 8,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "94027",
			"path": "atherton-ca/251-selby-ln-atherton-ca-94027-100_ml81818609/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ea5cc21e-7284-4483-ae5c-79f63035d5cc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-01-21 00:00:00",
			"createdAt": "2020-11-03 17:57:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-11-03 09:51:19",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Atherton",
				"lat": 37.455855,
				"lng": -122.218433,
				"zipcode": "94027",
				"subPremise": "",
				"address": "251 Selby Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 7000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		}
	]