exports.FOSTER_CITY_SCHOOLS = [
    {
        "id": 6956,
        "districtId": 799,
        "districtName": "San Mateo-Foster City",
        "districtCity": "Foster City",
        "levelCode": "e",
        "lat": 37.567574,
        "lon": -122.257858,
        "name": "Audubon Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "841 Gull Avenue",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": 6,
        "ratingScale": "Average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/6956-Audubon-Elementary-School/",
            "reviews": "/california/foster-city/6956-Audubon-Elementary-School/#Reviews",
            "collegeSuccess": "/california/foster-city/6956-Audubon-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "5%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 787,
        "parentRating": 4,
        "numReviews": 15,
        "studentsPerTeacher": 23,
        "subratings": {
            "Test Scores Rating": 9,
            "Academic Progress Rating": 6,
            "Equity Overview Rating": 4
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 5
            },
            {
                "label": "All students",
                "rating": 9
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 54
            },
            {
                "label": "Hispanic",
                "rating": 5,
                "percentage": 9
            },
            {
                "label": "White",
                "rating": 10,
                "percentage": 19
            },
            {
                "label": "Two or more races",
                "rating": 9,
                "percentage": 11
            },
            {
                "label": "Filipino",
                "percentage": 6
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6961,
        "districtId": 799,
        "districtName": "San Mateo-Foster City",
        "districtCity": "Foster City",
        "levelCode": "m",
        "lat": 37.559841,
        "lon": -122.248909,
        "name": "Bowditch Middle",
        "gradeLevels": "6-8",
        "assigned": null,
        "address": {
            "street1": "1450 Tarpon Street",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": 7,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/6961-Bowditch-Middle-School/",
            "reviews": "/california/foster-city/6961-Bowditch-Middle-School/#Reviews",
            "collegeSuccess": "/california/foster-city/6961-Bowditch-Middle-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "6%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1047,
        "parentRating": 4,
        "numReviews": 12,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 5
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "rating": 5,
                "percentage": 6
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 52
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 9
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 26
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 8
            },
            {
                "label": "Filipino",
                "rating": 9,
                "percentage": 5
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6962,
        "districtId": 799,
        "districtName": "San Mateo-Foster City",
        "districtCity": "Foster City",
        "levelCode": "e",
        "lat": 37.561756,
        "lon": -122.26149,
        "name": "Brewer Island Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "1151 Polynesia Drive",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": 8,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/6962-Brewer-Island-Elementary-School/",
            "reviews": "/california/foster-city/6962-Brewer-Island-Elementary-School/#Reviews",
            "collegeSuccess": "/california/foster-city/6962-Brewer-Island-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "8%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 567,
        "parentRating": 3,
        "numReviews": 18,
        "studentsPerTeacher": 21,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 6
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 8
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 59
            },
            {
                "label": "Hispanic",
                "rating": 6,
                "percentage": 12
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 18
            },
            {
                "label": "Two or more races",
                "percentage": 6
            },
            {
                "label": "Filipino",
                "percentage": 4
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 17364,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.556347,
        "lon": -122.268944,
        "name": "Bright Horizon Chinese School",
        "gradeLevels": "K-7",
        "assigned": null,
        "address": {
            "street1": "650 Shell Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/17364-Bright-Horizon-Chinese-School/",
            "reviews": "/california/foster-city/17364-Bright-Horizon-Chinese-School/#Reviews",
            "collegeSuccess": "/california/foster-city/17364-Bright-Horizon-Chinese-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 22561,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.569801,
        "lon": -122.281502,
        "name": "Bright Horizons-Gilead",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "301 Velocity Way",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/22561-Bright-Horizons-Gilead/",
            "reviews": "/california/foster-city/22561-Bright-Horizons-Gilead/#Reviews",
            "collegeSuccess": "/california/foster-city/22561-Bright-Horizons-Gilead/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 21081,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.54097,
        "lon": -122.272392,
        "name": "Chai Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "499 Boothbay Avenue",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/21081-Chai-Pre-School/",
            "reviews": "/california/foster-city/21081-Chai-Pre-School/#Reviews",
            "collegeSuccess": "/california/foster-city/21081-Chai-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 5,
        "numReviews": 2,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 6964,
        "districtId": 799,
        "districtName": "San Mateo-Foster City",
        "districtCity": "Foster City",
        "levelCode": "e",
        "lat": 37.546539,
        "lon": -122.273987,
        "name": "Foster City Elementary",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "461 Beach Park Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": 9,
        "ratingScale": "Above average",
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/6964-Foster-City-Elementary-School/",
            "reviews": "/california/foster-city/6964-Foster-City-Elementary-School/#Reviews",
            "collegeSuccess": "/california/foster-city/6964-Foster-City-Elementary-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "4%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 866,
        "parentRating": 3,
        "numReviews": 15,
        "studentsPerTeacher": 24,
        "subratings": {
            "Test Scores Rating": 10,
            "Academic Progress Rating": 7,
            "Equity Overview Rating": 10
        },
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 4
            },
            {
                "label": "All students",
                "rating": 10
            },
            {
                "label": "Asian",
                "rating": 10,
                "percentage": 55
            },
            {
                "label": "Hispanic",
                "rating": 10,
                "percentage": 8
            },
            {
                "label": "White",
                "rating": 9,
                "percentage": 23
            },
            {
                "label": "Two or more races",
                "rating": 10,
                "percentage": 10
            },
            {
                "label": "Filipino",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19071,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.56068,
        "lon": -122.272362,
        "name": "Foster City Kindercare",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1006 Metro Center Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/19071-Foster-City-Kindercare/",
            "reviews": "/california/foster-city/19071-Foster-City-Kindercare/#Reviews",
            "collegeSuccess": "/california/foster-city/19071-Foster-City-Kindercare/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 22538,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.549671,
        "lon": -122.26445,
        "name": "Foster City Pre-Sch& Dcc",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1064 Shell Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/22538-Foster-City-Pre-Sch-Dcc/",
            "reviews": "/california/foster-city/22538-Foster-City-Pre-Sch-Dcc/#Reviews",
            "collegeSuccess": "/california/foster-city/22538-Foster-City-Pre-Sch-Dcc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 3,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 19072,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.54969,
        "lon": -122.264572,
        "name": "Foster City Pre-School And Dcc",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1064 Shell Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/19072-Foster-City-Pre-School-And-Dcc/",
            "reviews": "/california/foster-city/19072-Foster-City-Pre-School-And-Dcc/#Reviews",
            "collegeSuccess": "/california/foster-city/19072-Foster-City-Pre-School-And-Dcc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 22549,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.569817,
        "lon": -122.265175,
        "name": "Kids Connection",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1998 Beach Park Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/22549-Kids-Connection/",
            "reviews": "/california/foster-city/22549-Kids-Connection/#Reviews",
            "collegeSuccess": "/california/foster-city/22549-Kids-Connection/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 11,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 10802,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e",
        "lat": 37.569817,
        "lon": -122.265175,
        "name": "Kids Connection School",
        "gradeLevels": "K-5",
        "assigned": null,
        "address": {
            "street1": "1998 Beach Park Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/10802-Kids-Connection-School/",
            "reviews": "/california/foster-city/10802-Kids-Connection-School/#Reviews",
            "collegeSuccess": "/california/foster-city/10802-Kids-Connection-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 230,
        "parentRating": 4,
        "numReviews": 29,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "American Indian/Alaska Native",
                "percentage": 40
            },
            {
                "label": "Pacific Islander",
                "percentage": 19
            },
            {
                "label": "White",
                "percentage": 17
            },
            {
                "label": "Asian",
                "percentage": 17
            },
            {
                "label": "Two or more races",
                "percentage": 4
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 1
            }
        ],
        "remediationData": {}
    },
    {
        "id": 24342,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.570797,
        "lon": -122.264084,
        "name": "Lakeview Montessori Llc",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "1950 Beach Park Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/24342-Lakeview-Montessori-Llc/",
            "reviews": "/california/foster-city/24342-Lakeview-Montessori-Llc/#Reviews",
            "collegeSuccess": "/california/foster-city/24342-Lakeview-Montessori-Llc/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 4,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 16638,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.566963,
        "lon": -122.271217,
        "name": "Newton",
        "gradeLevels": "K-7",
        "assigned": null,
        "address": {
            "street1": "1155 Chess Drive",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/16638-Newton/",
            "reviews": "/california/foster-city/16638-Newton/#Reviews",
            "collegeSuccess": "/california/foster-city/16638-Newton/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 29999,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.563202,
        "lon": -122.26976,
        "name": "Odyssey Pre-School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "558 Pilgrim Drive",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/29999-Odyssey-Pre-School/",
            "reviews": "/california/foster-city/29999-Odyssey-Pre-School/#Reviews",
            "collegeSuccess": "/california/foster-city/29999-Odyssey-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 2,
        "numReviews": 5,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 27562,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p,e",
        "lat": 37.548546,
        "lon": -122.267113,
        "name": "Omni Montessori Pre-School",
        "gradeLevels": "PK-K",
        "assigned": null,
        "address": {
            "street1": "831 Peary Lane",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/27562-Omni-Montessori-Pre-School/",
            "reviews": "/california/foster-city/27562-Omni-Montessori-Pre-School/#Reviews",
            "collegeSuccess": "/california/foster-city/27562-Omni-Montessori-Pre-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    },
    {
        "id": 9818,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "e,m",
        "lat": 37.557884,
        "lon": -122.267128,
        "name": "Ronald C. Wornick Jewish Day School",
        "gradeLevels": "K-8",
        "assigned": null,
        "address": {
            "street1": "800 Foster City Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/9818-Ronald-C.-Wornick-Jewish-Day-School/",
            "reviews": "/california/foster-city/9818-Ronald-C.-Wornick-Jewish-Day-School/#Reviews",
            "collegeSuccess": "/california/foster-city/9818-Ronald-C.-Wornick-Jewish-Day-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 213,
        "parentRating": 4,
        "numReviews": 29,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "White",
                "percentage": 91
            },
            {
                "label": "Asian",
                "percentage": 5
            },
            {
                "label": "Hispanic",
                "percentage": 3
            },
            {
                "label": "African American",
                "percentage": 2
            }
        ],
        "remediationData": {}
    },
    {
        "id": 25211,
        "districtId": 799,
        "districtName": "San Mateo-Foster City",
        "districtCity": "Foster City",
        "levelCode": "e,m,h",
        "lat": 37.566048,
        "lon": -122.273628,
        "name": "San Mateo-Foster City Special Education Preschool",
        "gradeLevels": "Ungraded",
        "assigned": null,
        "address": {
            "street1": "1170 Chess Drive",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "public",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/25211-San-Mateo-Foster-City-Special-Education-Preschool/",
            "reviews": "/california/foster-city/25211-San-Mateo-Foster-City-Special-Education-Preschool/#Reviews",
            "collegeSuccess": "/california/foster-city/25211-San-Mateo-Foster-City-Special-Education-Preschool/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "0%",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 1,
        "parentRating": 0,
        "numReviews": 0,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            },
            {
                "label": "Asian",
                "percentage": 100
            }
        ],
        "remediationData": {}
    },
    {
        "id": 13166,
        "districtId": 0,
        "districtName": null,
        "districtCity": null,
        "levelCode": "p",
        "lat": 37.545242,
        "lon": -122.272484,
        "name": "St Ambrose Sea Breeze School",
        "gradeLevels": "PK",
        "assigned": null,
        "address": {
            "street1": "900 Edgewater Boulevard",
            "street2": null,
            "zip": "94404",
            "city": "Foster City"
        },
        "csaAwardYears": [],
        "rating": null,
        "ratingScale": null,
        "schoolType": "private",
        "state": "CA",
        "zipcode": "94404",
        "type": "school",
        "links": {
            "profile": "/california/foster-city/13166-St-Ambrose-Sea-Breeze-School/",
            "reviews": "/california/foster-city/13166-St-Ambrose-Sea-Breeze-School/#Reviews",
            "collegeSuccess": "/california/foster-city/13166-St-Ambrose-Sea-Breeze-School/#College_success"
        },
        "highlighted": false,
        "pinned": null,
        "testScoreRatingForEthnicity": null,
        "percentLowIncome": "?",
        "collegePersistentData": [],
        "collegeEnrollmentData": {},
        "enrollment": 0,
        "parentRating": 4,
        "numReviews": 9,
        "subratings": {},
        "ethnicityInfo": [
            {
                "label": "Low-income",
                "percentage": 0
            }
        ],
        "remediationData": {}
    }
]