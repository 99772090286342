exports.MOUNTAIN_HOUSE_LISTINGS = [
		{
			"closePrice": 1200000,
			"daysOnMovoto": 0,
			"id": "641d8ce1-e542-431c-8ca9-bbbedefa5257",
			"tnImgPath": "https://pi.movoto.com/p/102/221151007_0_rMvFbr_p.jpeg",
			"listDate": null,
			"listingAgent": "Ronald Cedillo",
			"listPrice": 1190000,
			"lotSize": 5550,
			"sqftTotal": 3353,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221151007",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Home Buyers Realty",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2017,
			"zipCode": "95391",
			"path": "mountain-house-ca/505-w-huntington-ave-mountain-house-ca-95391-102_221151007/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1425872e-813e-482c-97b3-4a19e529703a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-18 00:00:00",
			"createdAt": "2021-12-09 17:54:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-09 17:54:28",
			"virtualTourLink": "https://my.matterport.com/show/?m=wMbgKKZ6DWb",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.766464,
				"lng": -121.55447,
				"zipcode": "95391",
				"subPremise": "",
				"address": "505 W Huntington Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=wMbgKKZ6DWb"
		},
		{
			"closePrice": 565000,
			"daysOnMovoto": 114,
			"id": "a355f785-5a8b-48d2-b261-9519f90ea8dd",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81863362_0_arNA3I_p.jpeg",
			"listDate": "2021-09-19 03:06:12",
			"listingAgent": "Angelito Reyes",
			"listPrice": 550000,
			"lotSize": null,
			"sqftTotal": 1275,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81863362",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Angelito Reyes, Broker",
			"photoCount": 16,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2009,
			"zipCode": "95391",
			"path": "mountain-house-ca/296-w-gaspara-dr-mountain-house-ca-95391-102_18079966/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -10000,
			"propertyId": "c8aad1b0-98d7-414e-a399-f608e5d1b10c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-12 00:00:00",
			"createdAt": "2021-09-19 10:12:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-19 03:06:12",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.778234,
				"lng": -121.546756,
				"zipcode": "95391",
				"subPremise": "",
				"address": "296 W Gaspara Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 565000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 580000,
			"daysOnMovoto": 0,
			"id": "b52ea8fc-b090-4a9c-b226-12bf6907a8ef",
			"tnImgPath": "https://pi.movoto.com/p/102/221147948_0_vuN6Mq_p.jpeg",
			"listDate": null,
			"listingAgent": "Monica Abrew",
			"listPrice": 559000,
			"lotSize": 100,
			"sqftTotal": 1460,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221147948",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Executive",
			"photoCount": 48,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2006,
			"zipCode": "95391",
			"path": "mountain-house-ca/280-s-yucatan-pl-mountain-house-ca-95391-102_11009008/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "79218a9f-c85a-487e-aac6-59f1b14556a3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-03 00:00:00",
			"createdAt": "2021-11-26 22:44:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-26 22:44:24",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.776695,
				"lng": -121.545189,
				"zipcode": "95391",
				"subPremise": "",
				"address": "280 S Yucatan Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 580000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 856405,
			"daysOnMovoto": 49,
			"id": "b9b731d0-2bda-4f5e-80d7-40f2ef99c916",
			"tnImgPath": "https://pi.movoto.com/p/12/40974015_0_qBFmIz_p.jpeg",
			"listDate": "2021-11-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 856405,
			"lotSize": 3683,
			"sqftTotal": 1906,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974015",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/294-e-tuso-ranch-rd-mountain-house-ca-95391-2001_wd8dbp71h6b/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e8e0ba03-f3c0-4f45-8131-c28c1e488e12",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-12 03:06:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.74666,
				"lng": -121.543138,
				"zipcode": "95391",
				"subPremise": "",
				"address": "294 E Tuso Ranch Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 856405,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 739375,
			"daysOnMovoto": 174,
			"id": "5df628e3-566e-48d5-b4b8-0bbacefad184",
			"tnImgPath": "https://pi.movoto.com/p/12/40957954_0_mnVjAF_p.jpeg",
			"listDate": "2021-07-09 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 737990,
			"lotSize": 2725,
			"sqftTotal": 1676,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40957954",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/195-e-rodbell-ln-mountain-house-ca-95391-12_40957954/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "50c93295-237f-4932-b0ee-09add8cc9b6f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-07-09 05:26:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "195 E Rodbell Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 739375,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 873730,
			"daysOnMovoto": 188,
			"id": "e31009a3-db5d-4ff1-8924-514f53801c74",
			"tnImgPath": "https://pi.movoto.com/p/12/40956052_0_AuVZa6_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 844990,
			"lotSize": 4037,
			"sqftTotal": 2215,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956052",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/298-e-lawson-ave-mountain-house-ca-95391-12_40956052/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "762972b0-10df-4cb9-b8a3-6fe7f277eb55",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-06-25 05:11:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.746113,
				"lng": -121.542306,
				"zipcode": "95391",
				"subPremise": "",
				"address": "298 E Lawson Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 873730,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1286990,
			"daysOnMovoto": 195,
			"id": "60943b37-2b56-4fb0-9d25-131457a3e00a",
			"tnImgPath": "https://pi.movoto.com/p/12/40954942_0_izjjAB_p.jpeg",
			"listDate": "2021-06-18 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 1269990,
			"lotSize": 9437,
			"sqftTotal": 2903,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954942",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/71-e-boshra-ct-mountain-house-ca-95391-12_40954942/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 131110,
			"propertyId": "82ec943b-c1b2-4435-aaa4-85dfd55d9c10",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-06-18 05:41:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.753917,
				"lng": -121.546241,
				"zipcode": "95391",
				"subPremise": "",
				"address": "71 E Boshra Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1286990,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 670000,
			"daysOnMovoto": 0,
			"id": "4b39d015-fb70-437c-a402-f7d932d7fa50",
			"tnImgPath": "https://pi.movoto.com/p/102/221147787_0_QM3rQA_p.jpeg",
			"listDate": null,
			"listingAgent": "Salah Salah",
			"listPrice": 588500,
			"lotSize": 2975,
			"sqftTotal": 1398,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221147787",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty ONE Group Zoom",
			"photoCount": 41,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2005,
			"zipCode": "95391",
			"path": "mountain-house-ca/56-w-posada-ln-mountain-house-ca-95391-102_221147787/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "81256adf-c55b-40c8-9d5e-9e8a207a3745",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-29 00:00:00",
			"createdAt": "2021-11-25 07:39:14",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-25 07:39:14",
			"virtualTourLink": "https://www.youtube.com/embed/VbU5j5cWk_Y",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.774308,
				"lng": -121.545764,
				"zipcode": "95391",
				"subPremise": "",
				"address": "56 W Posada Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 670000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.youtube.com/embed/VbU5j5cWk_Y"
		},
		{
			"closePrice": 698330,
			"daysOnMovoto": 172,
			"id": "d2d47b21-b9c8-4b99-8029-e6df8353e062",
			"tnImgPath": "https://pi.movoto.com/p/12/40957951_0_BmybIQ_p.jpeg",
			"listDate": "2021-07-09 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 697400,
			"lotSize": 2409,
			"sqftTotal": 1467,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40957951",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/184-e-rodbell-ln-mountain-house-ca-95391-12_40957951/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -19350,
			"propertyId": "c3ba9e04-b497-4350-b82b-e49617e18d5c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-28 00:00:00",
			"createdAt": "2021-07-09 05:26:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "184 E Rodbell Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 698330,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 776865,
			"daysOnMovoto": 167,
			"id": "ee162855-4f0f-4443-91d5-72f5a53a2e37",
			"tnImgPath": "https://pi.movoto.com/p/12/40957953_0_aQjFi7_p.jpeg",
			"listDate": "2021-07-09 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 772865,
			"lotSize": 2725,
			"sqftTotal": 1893,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40957953",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/194-e-rodbell-ln-mountain-house-ca-95391-12_40957953/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -20415,
			"propertyId": "3a9f0bd5-8336-4de4-85f5-91eb1ee25936",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-07-09 05:26:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "194 E Rodbell Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 776865,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 962480,
			"daysOnMovoto": 181,
			"id": "3728f665-107e-45a1-bae7-5bf4df9ce0af",
			"tnImgPath": "https://pi.movoto.com/p/12/40956051_0_QvjVaJ_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 909990,
			"lotSize": 5495,
			"sqftTotal": 2101,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956051",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/297-e-central-pkwy-mountain-house-ca-95391-12_40956051/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "604b42ad-17a1-4307-ac04-5ffbccae58b7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-23 00:00:00",
			"createdAt": "2021-06-25 05:11:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.77568,
				"lng": -121.544295,
				"zipcode": "95391",
				"subPremise": "",
				"address": "297 E Central Pkwy"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 962480,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 897000,
			"daysOnMovoto": 194,
			"id": "c4f0f422-f3dc-42d3-8b59-289f6e7c1bc1",
			"tnImgPath": "https://pi.movoto.com/p/12/40953980_0_UnEzE3_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 859990,
			"lotSize": 5024,
			"sqftTotal": 2215,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953980",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/2281-s-amalfi-gln-mountain-house-ca-95391-12_40953980/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7f839000-4c34-4b17-9661-74e5955129cf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-22 00:00:00",
			"createdAt": "2021-06-11 23:06:47",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "2281 S Amalfi Gln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 897000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1225000,
			"daysOnMovoto": 30,
			"id": "eff1a24e-0f61-4770-8d0b-92c671727d35",
			"tnImgPath": "https://pi.movoto.com/p/12/40974283_0_UqIn3B_p.jpeg",
			"listDate": "2021-11-21 00:00:00",
			"listingAgent": "Richard Kislingbury",
			"listPrice": 1199000,
			"lotSize": 7465,
			"sqftTotal": 3634,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40974283",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Independent Realty",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2006,
			"zipCode": "95391",
			"path": "mountain-house-ca/599-w-sombra-way-mountain-house-ca-95391-12_40748717/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "759f5fe3-9c93-4fe7-9ebb-03bdc4fd3219",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-21 17:21:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-21 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.775173,
				"lng": -121.553626,
				"zipcode": "95391",
				"subPremise": "",
				"address": "599 W Sombra Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1225000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 819135,
			"daysOnMovoto": 179,
			"id": "e1a5cdd6-4927-4190-a05e-336da458543a",
			"tnImgPath": "https://pi.movoto.com/p/12/40956056_0_3N7mbu_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 749990,
			"lotSize": 3017,
			"sqftTotal": 1676,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956056",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/171-e-prusiner-ln-mountain-house-ca-95391-12_40956056/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "61822d31-da3c-400b-9cee-84d91e03b74e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-06-25 05:16:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "171 E Prusiner Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 819135,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 665000,
			"daysOnMovoto": 0,
			"id": "d8603db6-c74e-4e3d-8717-79e2c06697fc",
			"tnImgPath": "https://pi.movoto.com/p/102/221146294_0_eBQQmj_p.jpeg",
			"listDate": null,
			"listingAgent": "Janet Anderson",
			"listPrice": 625000,
			"lotSize": 100,
			"sqftTotal": 1799,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221146294",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Keller Williams Realty",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2006,
			"zipCode": "95391",
			"path": "mountain-house-ca/165-w-del-sol-ln-mountain-house-ca-95391-102_221146294/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ea145fd2-8687-4137-859a-481276a85b91",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-21 00:00:00",
			"createdAt": "2021-11-19 19:59:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-19 19:59:41",
			"virtualTourLink": "https://suncrestdigital.com/3d/165-w-del-sol/fullscreen-nobrand/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.777649,
				"lng": -121.545288,
				"zipcode": "95391",
				"subPremise": "",
				"address": "165 W Del Sol Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 665000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://suncrestdigital.com/3d/165-w-del-sol/fullscreen-nobrand/"
		},
		{
			"closePrice": 985845,
			"daysOnMovoto": 189,
			"id": "c42ed646-3284-4bd1-a715-1e8d4555c1e1",
			"tnImgPath": "https://pi.movoto.com/p/12/40953934_0_QvjVaJ_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 909990,
			"lotSize": 3944,
			"sqftTotal": 2101,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953934",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/2257-s-amalfi-gln-mountain-house-ca-95391-12_40953934/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5c6ae01a-8f97-40f5-95f6-2dcf57648b52",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-17 00:00:00",
			"createdAt": "2021-06-11 20:31:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.746343,
				"lng": -121.542687,
				"zipcode": "95391",
				"subPremise": "",
				"address": "2257 S Amalfi Gln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 985845,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1116120,
			"daysOnMovoto": 174,
			"id": "fdf0e506-7aa8-4f68-82f4-313d3cf52c9a",
			"tnImgPath": "https://pi.movoto.com/p/12/40956066_0_Ameeae_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 964990,
			"lotSize": 6681,
			"sqftTotal": 2509,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956066",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/83-e-nasergholi-ave-mountain-house-ca-95391-12_40956066/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ea29e430-c41a-438e-bc3b-397c4658ebd6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-06-25 05:26:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "83 E Nasergholi Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1116120,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 695000,
			"daysOnMovoto": 174,
			"id": "12a757c8-fc4d-47f4-8a1a-82ac603d1dda",
			"tnImgPath": "https://pi.movoto.com/p/12/40956057_0_ezIn6i_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 695000,
			"lotSize": 2409,
			"sqftTotal": 1476,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956057",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/167-e-prusiner-ln-mountain-house-ca-95391-12_40956057/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -880,
			"propertyId": "02ebe022-13c1-4da5-a0cf-77a908fb2622",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-16 00:00:00",
			"createdAt": "2021-06-25 05:16:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "167 E Prusiner Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 695000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1338381,
			"daysOnMovoto": 180,
			"id": "6dfbd355-36fa-4590-85b6-c71abe7280f4",
			"tnImgPath": "https://pi.movoto.com/p/12/40954944_0_uMymU2_p.jpeg",
			"listDate": "2021-06-18 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 1270880,
			"lotSize": 8716,
			"sqftTotal": 3063,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954944",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/1804-s-ginzburg-st-mountain-house-ca-95391-12_40954944/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "cd8ffb9a-54f0-4c5c-b694-a5874517990f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-06-18 05:46:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "1804 S Ginzburg St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1338381,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1271596,
			"daysOnMovoto": 180,
			"id": "7e3782cf-0ab1-456f-b5cd-21fe7cc8535e",
			"tnImgPath": "https://pi.movoto.com/p/12/40954940_0_uMymU2_p.jpeg",
			"listDate": "2021-06-18 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 1297112,
			"lotSize": 7105,
			"sqftTotal": 3490,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40954940",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/1814-s-ginzburg-st-mountain-house-ca-95391-12_40954940/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 34122,
			"propertyId": "1f458888-4cf9-41d9-9b51-c69310ef3b64",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-06-18 05:41:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-18 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "1814 S Ginzburg St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1271596,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 863625,
			"daysOnMovoto": 187,
			"id": "3afa6566-c4ee-421f-9235-f1aab767f668",
			"tnImgPath": "https://pi.movoto.com/p/12/40953968_0_U3i7Qv_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 863625,
			"lotSize": 4304,
			"sqftTotal": 1906,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953968",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/2273-s-amalfi-gln-mountain-house-ca-95391-12_40953968/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 1635,
			"propertyId": "4946af3a-2445-477c-ae73-edb5d8a40ea9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-06-11 22:16:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.74609,
				"lng": -121.542637,
				"zipcode": "95391",
				"subPremise": "",
				"address": "2273 S Amalfi Gln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 863625,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 833650,
			"daysOnMovoto": 187,
			"id": "4b65ae72-4bf3-46c8-99b1-647ba16bb6a9",
			"tnImgPath": "https://pi.movoto.com/p/12/40953839_0_Q6JUrF_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 859173,
			"lotSize": 3080,
			"sqftTotal": 2020,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953839",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/153-e-central-pkwy-mountain-house-ca-95391-12_40953839/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 50183,
			"propertyId": "b99872b6-5d2f-48bb-bf1e-d489490927df",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-15 00:00:00",
			"createdAt": "2021-06-11 15:46:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "153 E Central Pkwy"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 833650,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1020000,
			"daysOnMovoto": 40,
			"id": "18215225-b756-4228-a6a4-6736748af5d9",
			"tnImgPath": "https://pi.movoto.com/p/12/40973364_0_zf2i7a_p.jpeg",
			"listDate": "2021-11-04 00:00:00",
			"listingAgent": "Sridhar Bokinala",
			"listPrice": 949000,
			"lotSize": 3962,
			"sqftTotal": 2534,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40973364",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Ikon Real Estate",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2020,
			"zipCode": "95391",
			"path": "mountain-house-ca/1424-s-tradition-st-mountain-house-ca-95391-12_40973364/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "511e69bc-755d-44ae-ac54-f48265101393",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-11-05 04:16:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-04 00:00:00",
			"virtualTourLink": "http://vtours.k-robbdesigns.com/1424-traditions-street-mountain-house/nb/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.759865,
				"lng": -121.542821,
				"zipcode": "95391",
				"subPremise": "",
				"address": "1424 S Tradition St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1020000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://vtours.k-robbdesigns.com/1424-traditions-street-mountain-house/nb/"
		},
		{
			"closePrice": 839188,
			"daysOnMovoto": 172,
			"id": "756c85f1-6292-4c45-9e19-4f22aa8d04e5",
			"tnImgPath": "https://pi.movoto.com/p/12/40956058_0_Q3Y2B6_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 773990,
			"lotSize": 2409,
			"sqftTotal": 1893,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956058",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/163-e-prusiner-ln-mountain-house-ca-95391-12_40956058/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a3140b88-c1cc-4f68-bbf1-920d31f563dd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-14 00:00:00",
			"createdAt": "2021-06-25 05:16:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "163 E Prusiner Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 839188,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 784948,
			"daysOnMovoto": 185,
			"id": "56de35d3-0804-4890-969e-7946fe274ec3",
			"tnImgPath": "https://pi.movoto.com/p/12/40953857_0_iJQ6AB_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 728990,
			"lotSize": 2753,
			"sqftTotal": 1676,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953857",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/159-e-prusiner-ln-mountain-house-ca-95391-12_40953857/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ceb513ae-94d0-411c-8972-63798170d02f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-06-11 16:36:55",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "159 E Prusiner Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 784948,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 790000,
			"daysOnMovoto": 0,
			"id": "8fd08922-19a3-4cb5-abfd-7d775f5c9081",
			"tnImgPath": "https://pi.movoto.com/p/102/221134554_0_7J6njY_p.jpeg",
			"listDate": null,
			"listingAgent": "Deborah Monceaux",
			"listPrice": 780000,
			"lotSize": 4822,
			"sqftTotal": 1932,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221134554",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Klemm Real Estate",
			"photoCount": 24,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2004,
			"zipCode": "95391",
			"path": "mountain-house-ca/415-bethell-ave-mountain-house-ca-95391-102_19007232/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "90caaa24-241d-433b-ac87-dc56605058a2",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-22 07:24:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-22 07:24:27",
			"virtualTourLink": "http://vtours.toddsfotos.com/415bethellavenue",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.775363,
				"lng": -121.538182,
				"zipcode": "95391",
				"subPremise": "",
				"address": "415 Bethell Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 790000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://vtours.toddsfotos.com/415bethellavenue"
		},
		{
			"closePrice": 575000,
			"daysOnMovoto": 0,
			"id": "5ca56540-72f8-4175-a400-f330ff9460c5",
			"tnImgPath": "https://pi.movoto.com/p/102/221134324_0_vYMNiI_p.jpeg",
			"listDate": null,
			"listingAgent": "Gevon Polgar",
			"listPrice": 568500,
			"lotSize": 100,
			"sqftTotal": 1454,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221134324",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Go2Realty Pros, Inc.",
			"photoCount": 39,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2008,
			"zipCode": "95391",
			"path": "mountain-house-ca/62-s-rio-rapido-dr-mountain-house-ca-95391-102_16067982/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "49769dc3-3cf8-4833-9e82-0976baa36635",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-13 00:00:00",
			"createdAt": "2021-10-19 20:34:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-19 20:34:27",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.7793,
				"lng": -121.545791,
				"zipcode": "95391",
				"subPremise": "",
				"address": "62 S Rio Rapido Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 575000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 973017,
			"daysOnMovoto": 168,
			"id": "d126ed1d-6e95-4ef5-b75f-620c4d98a062",
			"tnImgPath": "https://pi.movoto.com/p/12/40956065_0_2eEE3N_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 944990,
			"lotSize": 4419,
			"sqftTotal": 2509,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956065",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/43-e-nasergholi-ave-mountain-house-ca-95391-12_40956065/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bbfc9b8b-83da-4659-bb00-3b9c4e0b4a26",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-06-25 05:26:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "43 E Nasergholi Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 973017,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 911625,
			"daysOnMovoto": 182,
			"id": "18e0c084-e2a2-4cf3-8d0b-d2d32d1eab67",
			"tnImgPath": "https://pi.movoto.com/p/12/40953955_0_ryYUuV_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 835990,
			"lotSize": 3393,
			"sqftTotal": 2236,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953955",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/2265-s-amalfi-gln-mountain-house-ca-95391-12_40953955/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77d7e224-b9c5-40a4-bf7e-5962c8409d36",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-06-11 21:26:51",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.746204,
				"lng": -121.542811,
				"zipcode": "95391",
				"subPremise": "",
				"address": "2265 S Amalfi Gln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 911625,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 615500,
			"daysOnMovoto": 0,
			"id": "47aed4c0-2719-4f0c-868c-ae2103f32188",
			"tnImgPath": "https://pi.movoto.com/p/102/221139020_0_qf2AQu_p.jpeg",
			"listDate": null,
			"listingAgent": "Jack Klemm",
			"listPrice": 600000,
			"lotSize": 100,
			"sqftTotal": 1796,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221139020",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Klemm Real Estate",
			"photoCount": 38,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2007,
			"zipCode": "95391",
			"path": "mountain-house-ca/19-s-rio-rapido-dr-mountain-house-ca-95391-102_221102401/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "77c69bce-3435-4fb1-9fd9-1e6230acf858",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-10 00:00:00",
			"createdAt": "2021-10-30 14:49:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-30 14:49:31",
			"virtualTourLink": "http://19southriorapido.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.779941,
				"lng": -121.545812,
				"zipcode": "95391",
				"subPremise": "",
				"address": "19 S Rio Rapido Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 615500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://19southriorapido.com"
		},
		{
			"closePrice": 971072,
			"daysOnMovoto": 167,
			"id": "dd7838b2-1751-49a6-8b2d-307ce398a7a3",
			"tnImgPath": "https://pi.movoto.com/p/12/40956064_0_JNRzyV_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 914990,
			"lotSize": 4282,
			"sqftTotal": 2271,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956064",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/31-e-nasergholi-ave-mountain-house-ca-95391-12_40956064/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "002fb12d-8de3-4dcc-b8f1-cde7903ea4dc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-06-25 05:21:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "31 E Nasergholi Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 971072,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 773985,
			"daysOnMovoto": 167,
			"id": "5e8e93e7-3735-4cab-bb45-051ad447f3c8",
			"tnImgPath": "https://pi.movoto.com/p/12/40956055_0_aQjFi7_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 774850,
			"lotSize": 3080,
			"sqftTotal": 1893,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956055",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/159-e-central-pkwy-mountain-house-ca-95391-12_40956055/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -4030,
			"propertyId": "9762652b-d4bc-46b8-a25f-7debf6339e61",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-06-25 05:11:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.77568,
				"lng": -121.544295,
				"zipcode": "95391",
				"subPremise": "",
				"address": "159 E Central Pkwy"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 773985,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 883720,
			"daysOnMovoto": 167,
			"id": "155996c4-6e7f-47c3-8bf4-c4b5b4a72e9a",
			"tnImgPath": "https://pi.movoto.com/p/12/40956054_0_R2vyNn_p.jpeg",
			"listDate": "2021-06-25 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 824990,
			"lotSize": 4747,
			"sqftTotal": 2020,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40956054",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/165-e-central-pkwy-mountain-house-ca-95391-12_40956054/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2ec4ae3b-247f-4116-bea5-bd927a9c0dd6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-09 00:00:00",
			"createdAt": "2021-06-25 05:11:23",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-25 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.77568,
				"lng": -121.544295,
				"zipcode": "95391",
				"subPremise": "",
				"address": "165 E Central Pkwy"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 883720,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 740000,
			"daysOnMovoto": 68,
			"id": "a8434e17-314b-4044-bcaf-bacca2996b01",
			"tnImgPath": "https://pi.movoto.com/p/12/40969305_0_aIIvbr_p.jpeg",
			"listDate": "2021-10-01 00:00:00",
			"listingAgent": "Jenny Zheng",
			"listPrice": 760000,
			"lotSize": 3953,
			"sqftTotal": 2136,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969305",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "BQ Group",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2006,
			"zipCode": "95391",
			"path": "mountain-house-ca/140-w-brilloso-ln-mountain-house-ca-95391-12_40969305/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -27000,
			"propertyId": "c9b6b3c9-f0c6-40ac-bf4e-e90c604a001f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-10-01 17:11:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-01 00:00:00",
			"virtualTourLink": "https://my.matterport.com/show/?m=moTHpBT6F88",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.772111,
				"lng": -121.547927,
				"zipcode": "95391",
				"subPremise": "",
				"address": "140 W Brilloso Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 740000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=moTHpBT6F88"
		},
		{
			"closePrice": 1356250,
			"daysOnMovoto": 180,
			"id": "6176634c-baee-4ad1-b37f-fb855c625985",
			"tnImgPath": "https://pi.movoto.com/p/12/40953817_0_uMymU2_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 1337530,
			"lotSize": 6929,
			"sqftTotal": 3490,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953817",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/64-e-boshra-ct-mountain-house-ca-95391-12_40953817/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 83540,
			"propertyId": "b6b097de-28c5-4588-93f7-3e20f1393493",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-06-11 07:46:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.753508,
				"lng": -121.5465,
				"zipcode": "95391",
				"subPremise": "",
				"address": "64 E Boshra Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1356250,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 755000,
			"daysOnMovoto": 0,
			"id": "652035f0-ec52-4baf-900a-6ebf9dcb83a6",
			"tnImgPath": "https://pi.movoto.com/p/102/221110421_0_nbiaIZ_p.jpeg",
			"listDate": null,
			"listingAgent": "Kathleen Piazzisi",
			"listPrice": 759000,
			"lotSize": 3633,
			"sqftTotal": 2136,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221110421",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "RE/MAX Gold",
			"photoCount": 28,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2006,
			"zipCode": "95391",
			"path": "mountain-house-ca/222-s-puente-dr-mountain-house-ca-95391-102_16012206/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -16000,
			"propertyId": "5e396286-2a72-4598-be8a-e47966e32ec1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-08 00:00:00",
			"createdAt": "2021-08-31 21:54:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-31 21:54:28",
			"virtualTourLink": "https://my.matterport.com/show/?m=YwPLPMzWq7J&brand=0",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.776264,
				"lng": -121.551027,
				"zipcode": "95391",
				"subPremise": "",
				"address": "222 S Puente Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 755000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=YwPLPMzWq7J&brand=0"
		},
		{
			"closePrice": 1029000,
			"daysOnMovoto": 179,
			"id": "343c0ac0-18fa-4d34-93ce-51a4feee9b06",
			"tnImgPath": "https://pi.movoto.com/p/12/40953814_0_JNRzyV_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 999990,
			"lotSize": 5982,
			"sqftTotal": 2271,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953814",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/1733-s-ginzburg-st-mountain-house-ca-95391-12_40953814/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "985f2766-7473-4010-9b7c-e2bab082cae6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-06-11 07:41:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "1733 S Ginzburg St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1029000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 930000,
			"daysOnMovoto": 0,
			"id": "eaaed227-8b3c-448b-b075-d85eee7fbff7",
			"tnImgPath": "https://pi.movoto.com/p/102/221137236_0_bf3UMR_p.jpeg",
			"listDate": null,
			"listingAgent": "Andrew Jacobsen",
			"listPrice": 900000,
			"lotSize": 4879,
			"sqftTotal": 2782,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221137236",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Go2Realty Pros, Inc.",
			"photoCount": 52,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2009,
			"zipCode": "95391",
			"path": "mountain-house-ca/633-w-esplanade-dr-mountain-house-ca-95391-102_20038179/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d90703fd-cf2e-4fba-919f-9a678fe23840",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-07 00:00:00",
			"createdAt": "2021-10-26 19:49:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-26 19:49:27",
			"virtualTourLink": "https://39pixelsphoto.com/3d-virtual-tour/633-w-esplanade-drive-mountain-house-ca/fullscreen-nobrand/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.790895,
				"lng": -121.55232,
				"zipcode": "95391",
				"subPremise": "",
				"address": "633 W Esplanade Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://39pixelsphoto.com/3d-virtual-tour/633-w-esplanade-drive-mountain-house-ca/fullscreen-nobrand/"
		},
		{
			"closePrice": 720000,
			"daysOnMovoto": 0,
			"id": "0dd57f71-260f-4ae2-8153-6a6e433b1748",
			"tnImgPath": "https://pi.movoto.com/p/102/221116442_0_Jmeumn_p.jpeg",
			"listDate": null,
			"listingAgent": "Jack Klemm",
			"listPrice": 682000,
			"lotSize": 3846,
			"sqftTotal": 1681,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221116442",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Klemm Real Estate",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2013,
			"zipCode": "95391",
			"path": "mountain-house-ca/277-w-saint-francis-ave-mountain-house-ca-95391-102_221116442/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "c47632a9-7025-4164-8896-9d674df6a1ee",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-02 00:00:00",
			"createdAt": "2021-10-13 14:34:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-13 14:34:24",
			"virtualTourLink": "http://277westsaintfrancis.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.783596,
				"lng": -121.547147,
				"zipcode": "95391",
				"subPremise": "",
				"address": "277 W Saint Francis Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 720000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://277westsaintfrancis.com"
		},
		{
			"closePrice": 1162139,
			"daysOnMovoto": 172,
			"id": "246863f4-dca7-4f95-b077-f7a9f7abc793",
			"tnImgPath": "https://pi.movoto.com/p/12/40953813_0_Z2U3qa_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 939990,
			"lotSize": 5111,
			"sqftTotal": 2617,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953813",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/90-e-kamyar-pl-mountain-house-ca-95391-12_40953813/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b1c735c5-ddcd-4407-bab9-5e6bb80fbeaa",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-30 00:00:00",
			"createdAt": "2021-06-11 07:41:21",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"zipcode": "95391",
				"subPremise": "",
				"address": "90 E Kamyar Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1162139,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 750000,
			"daysOnMovoto": 0,
			"id": "9a0711d4-c5c3-4057-a71d-67027fe0aff6",
			"tnImgPath": "https://pi.movoto.com/p/102/221137597_0_riUUvz_p.jpeg",
			"listDate": null,
			"listingAgent": "Himiko Sadiki",
			"listPrice": 699999,
			"lotSize": 3729,
			"sqftTotal": 1870,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221137597",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "NextHome Modern Market",
			"photoCount": 22,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2011,
			"zipCode": "95391",
			"path": "mountain-house-ca/832-n-del-prado-st-mountain-house-ca-95391-102_221137597/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a6e26fc5-4849-428b-8e01-4d5177e0a789",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-29 00:00:00",
			"createdAt": "2021-10-30 00:59:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-30 00:59:35",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.788456,
				"lng": -121.542575,
				"zipcode": "95391",
				"subPremise": "",
				"address": "832 N Del Prado St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 570000,
			"daysOnMovoto": 55,
			"id": "daf9a5e5-5eda-417b-ba77-e516ec269ad9",
			"tnImgPath": "https://pi.movoto.com/p/12/40968825_0_3YiuZF_p.jpeg",
			"listDate": "2021-09-30 00:00:00",
			"listingAgent": "Alex Punsalan",
			"listPrice": 588888,
			"lotSize": 100,
			"sqftTotal": 1452,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968825",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Realty One Group Zoom",
			"photoCount": 32,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Condominium",
			"yearBuilt": 2008,
			"zipCode": "95391",
			"path": "mountain-house-ca/273-w-adoncia-dr-mountain-house-ca-95391-12_40968825/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "d16fc1f9-4b6d-49bd-827d-5161cbdbc45a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-09-30 15:36:51",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-30 00:00:00",
			"virtualTourLink": "http://vtours.k-robbdesigns.com/273-west-adoncia-drive-mountain-house/nb/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.778811,
				"lng": -121.546915,
				"zipcode": "95391",
				"subPremise": "",
				"address": "273 W Adoncia Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 570000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://vtours.k-robbdesigns.com/273-west-adoncia-drive-mountain-house/nb/"
		},
		{
			"closePrice": 772155,
			"daysOnMovoto": 166,
			"id": "36572078-0d95-41ae-b6c7-65ca62c8c6d1",
			"tnImgPath": "https://pi.movoto.com/p/12/40953924_0_eirVbm_p.jpeg",
			"listDate": "2021-06-11 00:00:00",
			"listingAgent": "Catmy Bui",
			"listPrice": 768575,
			"lotSize": 3824,
			"sqftTotal": 1604,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40953924",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Providential Investments",
			"photoCount": 3,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2021,
			"zipCode": "95391",
			"path": "mountain-house-ca/2241-s-amalfi-gln-mountain-house-ca-95391-2001_lp3n1r2mztc/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0186e269-a6e7-4514-8eea-c553f6a6d5d9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-06-11 20:01:54",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.746314,
				"lng": -121.542955,
				"zipcode": "95391",
				"subPremise": "",
				"address": "2241 S Amalfi Gln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 772155,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 990000,
			"daysOnMovoto": 0,
			"id": "f28db80a-648d-4e02-8099-8bfc25dd8c3c",
			"tnImgPath": "https://pi.movoto.com/p/102/221133011_0_zM66M6_p.jpeg",
			"listDate": null,
			"listingAgent": "Ronald Cedillo",
			"listPrice": 950000,
			"lotSize": 8969,
			"sqftTotal": 2207,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221133011",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Home Buyers Realty",
			"photoCount": 50,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2004,
			"zipCode": "95391",
			"path": "mountain-house-ca/359-collins-ct-mountain-house-ca-95391-100_ml80828324/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3de3a6a5-1e7b-48aa-99a8-48bcb90c49a0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-19 20:09:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-19 20:09:35",
			"virtualTourLink": "https://my.matterport.com/show/?m=8hABdBvTDfs",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.77525,
				"lng": -121.539385,
				"zipcode": "95391",
				"subPremise": "",
				"address": "359 Collins Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 990000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=8hABdBvTDfs"
		},
		{
			"closePrice": 1320000,
			"daysOnMovoto": 0,
			"id": "35e3abb8-10cd-4e65-b4a4-0d04550c67c3",
			"tnImgPath": "https://pi.movoto.com/p/102/221133192_0_JBf7zr_p.jpeg",
			"listDate": null,
			"listingAgent": "Mike Samawi",
			"listPrice": 1199900,
			"lotSize": 7915,
			"sqftTotal": 3975,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221133192",
			"bath": 4,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 10,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2004,
			"zipCode": "95391",
			"path": "mountain-house-ca/101-felicia-ave-mountain-house-ca-95391-102_221133192/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e71381a0-d7ca-4cfa-a24b-8348856fc2e4",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-10-17 06:24:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-17 06:24:16",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.768581,
				"lng": -121.544181,
				"zipcode": "95391",
				"subPremise": "",
				"address": "101 Felicia Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1320000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 986000,
			"daysOnMovoto": 0,
			"id": "39768a96-0a14-44f0-81d9-e675756a4a27",
			"tnImgPath": "https://pi.movoto.com/p/102/221111971_0_ze2RQF_p.jpeg",
			"listDate": null,
			"listingAgent": "Lewis Sanders",
			"listPrice": 920000,
			"lotSize": 4487,
			"sqftTotal": 2125,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221111971",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Realty ONE Group Zoom",
			"photoCount": 59,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2019,
			"zipCode": "95391",
			"path": "mountain-house-ca/279-e-phelps-dr-mountain-house-ca-95391-102_221111971/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "319791e6-daa7-46a9-811c-6278380c9f37",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-24 00:00:00",
			"createdAt": "2021-09-03 07:14:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-03 07:14:20",
			"virtualTourLink": "http://vtours.k-robbdesigns.com/cp/279-east-phelps-drive-mountain-house/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.759604,
				"lng": -121.540965,
				"zipcode": "95391",
				"subPremise": "",
				"address": "279 E Phelps Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 986000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://vtours.k-robbdesigns.com/cp/279-east-phelps-drive-mountain-house/"
		},
		{
			"closePrice": 930000,
			"daysOnMovoto": 34,
			"id": "39a56d98-a487-4b72-9c48-945323dbf11c",
			"tnImgPath": "https://pi.movoto.com/p/12/40970332_0_uvrrRz_p.jpeg",
			"listDate": "2021-10-20 00:00:00",
			"listingAgent": "Will Bateson",
			"listPrice": 898000,
			"lotSize": 5541,
			"sqftTotal": 2308,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970332",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Sidewalk Homes",
			"photoCount": 36,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2008,
			"zipCode": "95391",
			"path": "mountain-house-ca/442-w-cupertino-ave-mountain-house-ca-95391-12_40970332/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "1184d677-f1c3-47c0-be0e-b10b785bb983",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-20 19:31:41",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-20 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.785097,
				"lng": -121.550644,
				"zipcode": "95391",
				"subPremise": "",
				"address": "442 W Cupertino Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 930000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 831111,
			"daysOnMovoto": 0,
			"id": "e0f53cf1-efc8-4027-9c68-a9d02d288dc4",
			"tnImgPath": "https://pi.movoto.com/p/102/221130192_0_EYuzQF_p.jpeg",
			"listDate": null,
			"listingAgent": "Jack Klemm",
			"listPrice": 770000,
			"lotSize": 4853,
			"sqftTotal": 1817,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221130192",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Klemm Real Estate",
			"photoCount": 33,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2004,
			"zipCode": "95391",
			"path": "mountain-house-ca/466-s-providence-st-mountain-house-ca-95391-102_221130192/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "07360e63-4856-431d-93ad-97c2a682aebe",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-10-21 16:29:46",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-21 16:29:46",
			"virtualTourLink": "http://466southprovidence.com",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.773472,
				"lng": -121.538561,
				"zipcode": "95391",
				"subPremise": "",
				"address": "466 S Providence St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 831111,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://466southprovidence.com"
		},
		{
			"closePrice": 690000,
			"daysOnMovoto": 0,
			"id": "79cb4192-ec5a-4e36-9abc-840de2c33bef",
			"tnImgPath": "https://pi.movoto.com/p/102/221101811_0_NEjUnY_p.jpeg",
			"listDate": null,
			"listingAgent": "Ryan Harris",
			"listPrice": 699950,
			"lotSize": 3054,
			"sqftTotal": 1671,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221101811",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "RE/MAX Grupe Gold",
			"photoCount": 20,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 2006,
			"zipCode": "95391",
			"path": "mountain-house-ca/76-w-brilloso-ln-mountain-house-ca-95391-102_19028943/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -29950,
			"propertyId": "e3d3b9d6-dedf-45af-9723-f35d007e26ae",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-23 00:00:00",
			"createdAt": "2021-08-13 16:59:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-13 16:59:40",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.771924,
				"lng": -121.547236,
				"zipcode": "95391",
				"subPremise": "",
				"address": "76 W Brilloso Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 690000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 931500,
			"daysOnMovoto": 38,
			"id": "0bd09815-34ef-457c-8406-1a61d449ec75",
			"tnImgPath": "https://pi.movoto.com/p/12/40970850_0_67Ibyz_p.jpeg",
			"listDate": "2021-10-15 00:00:00",
			"listingAgent": "Prasanna Rangaswamy",
			"listPrice": 829251,
			"lotSize": 4687,
			"sqftTotal": 2136,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970850",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Arcus Homes",
			"photoCount": 40,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2007,
			"zipCode": "95391",
			"path": "mountain-house-ca/484-w-felicidad-ave-mountain-house-ca-95391-102_17020977/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "45edf8dc-71ac-40f1-8da5-a88e12b8920c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-22 00:00:00",
			"createdAt": "2021-10-15 05:31:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-15 00:00:00",
			"virtualTourLink": "https://39pixelsphoto.com/3d-virtual-tour/484-w-felicidad-avenue-mountain-house-ca/fullscreen-nobrand/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Mountain House",
				"lat": 37.77637,
				"lng": -121.55067,
				"zipcode": "95391",
				"subPremise": "",
				"address": "484 W Felicidad Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 931500,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://39pixelsphoto.com/3d-virtual-tour/484-w-felicidad-avenue-mountain-house-ca/fullscreen-nobrand/"
		}
	]