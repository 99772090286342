exports.TRACY_SCHOOLS = [
		{
			"id": 14670,
			"districtId": 749,
			"districtName": "Jefferson Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.700542,
			"lon": -121.442314,
			"name": "Anthony C. Traina Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "4256 Windsong Drive",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/14670-Anthony-C.-Traina-Elementary-School/",
				"reviews": "/california/tracy/14670-Anthony-C.-Traina-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/14670-Anthony-C.-Traina-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "39%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 764,
			"parentRating": 4,
			"numReviews": 13,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 39
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 16
				},
				{
					"label": "African American",
					"rating": 4,
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 38
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 24
				},
				{
					"label": "Filipino",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 24004,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.698978,
			"lon": -121.440903,
			"name": "Aquarian Kids Private School",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "4474 Crabapple Court",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/24004-Aquarian-Kids-Private-School/",
				"reviews": "/california/tracy/24004-Aquarian-Kids-Private-School/#Reviews",
				"collegeSuccess": "/california/tracy/24004-Aquarian-Kids-Private-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 27246,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.698971,
			"lon": -121.440796,
			"name": "AquarianKids Montessori School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "4474 Crabapple Court",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/27246-AquarianKids-Montessori-School/",
				"reviews": "/california/tracy/27246-AquarianKids-Montessori-School/#Reviews",
				"collegeSuccess": "/california/tracy/27246-AquarianKids-Montessori-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 11853,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.746948,
			"lon": -121.460312,
			"name": "Art Freiler",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2421 West Lowell Avenue",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/11853-Art-Freiler-School/",
				"reviews": "/california/tracy/11853-Art-Freiler-School/#Reviews",
				"collegeSuccess": "/california/tracy/11853-Art-Freiler-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "54%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 813,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 54
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 6,
					"percentage": 22
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 40
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 21
				},
				{
					"label": "Filipino",
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6524,
			"districtId": 744,
			"districtName": "Banta Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.756176,
			"lon": -121.375046,
			"name": "Banta Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "22345 El Rancho Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6524-Banta-Elementary-School/",
				"reviews": "/california/tracy/6524-Banta-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6524-Banta-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "71%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 336,
			"parentRating": 4,
			"numReviews": 3,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 71
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 69
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 23
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 15163,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.747894,
			"lon": -121.440491,
			"name": "Bella Vista Christian Academy",
			"gradeLevels": "PK-8",
			"assigned": null,
			"address": {
				"street1": "1635 Chester Drive",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/15163-Bella-Vista-Christian-Academy/",
				"reviews": "/california/tracy/15163-Bella-Vista-Christian-Academy/#Reviews",
				"collegeSuccess": "/california/tracy/15163-Bella-Vista-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 308,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 55
				},
				{
					"label": "Asian",
					"percentage": 22
				},
				{
					"label": "Two or more races",
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 30301,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.739471,
			"lon": -121.41745,
			"name": "Bizzy-Beez Child Care Center Academy",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "500 East 11th Street",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/30301-Bizzy-Beez-Child-Care-Center-Academy/",
				"reviews": "/california/tracy/30301-Bizzy-Beez-Child-Care-Center-Academy/#Reviews",
				"collegeSuccess": "/california/tracy/30301-Bizzy-Beez-Child-Care-Center-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6660,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.742725,
			"lon": -121.429413,
			"name": "Central Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1370 Parker Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6660-Central-Elementary-School/",
				"reviews": "/california/tracy/6660-Central-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6660-Central-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "82%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 425,
			"parentRating": 3,
			"numReviews": 7,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 82
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 80
				},
				{
					"label": "Asian",
					"percentage": 6
				},
				{
					"label": "White",
					"percentage": 6
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 23269,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.741272,
			"lon": -121.424149,
			"name": "Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "125 Berverdor Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23269-Childrens-Center/",
				"reviews": "/california/tracy/23269-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/tracy/23269-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23276,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.756176,
			"lon": -121.375046,
			"name": "Creative Cc-Banta Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "22345 El Rancho Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23276-Creative-Cc-Banta-Pre-School/",
				"reviews": "/california/tracy/23276-Creative-Cc-Banta-Pre-School/#Reviews",
				"collegeSuccess": "/california/tracy/23276-Creative-Cc-Banta-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12052,
			"districtId": 756,
			"districtName": "New Jerusalem Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m,h",
			"lat": 37.668327,
			"lon": -121.323784,
			"name": "Delta Charter",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "31400 South Koster Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/12052-Delta-Charter-School/",
				"reviews": "/california/tracy/12052-Delta-Charter-School/#Reviews",
				"collegeSuccess": "/california/tracy/12052-Delta-Charter-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "46%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "54%",
				"state_average": "64%"
			},
			"enrollment": 696,
			"parentRating": 4,
			"numReviews": 8,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 5,
				"College Readiness Rating": 6,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 46
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 38
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 43
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 7
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				},
				{
					"label": "Filipino",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31761,
			"districtId": 756,
			"districtName": "New Jerusalem Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m,h",
			"lat": 37.668327,
			"lon": -121.323784,
			"name": "Delta Charter Online",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "31400 South Koster Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 2,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/31761-Delta-Charter-Online/",
				"reviews": "/california/tracy/31761-Delta-Charter-Online/#Reviews",
				"collegeSuccess": "/california/tracy/31761-Delta-Charter-Online/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "55%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "50%",
				"state_average": "64%"
			},
			"enrollment": 33,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 12,
			"subratings": {
				"College Readiness Rating": 2,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 55
				},
				{
					"label": "White",
					"percentage": 39
				},
				{
					"label": "Hispanic",
					"percentage": 39
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 31763,
			"districtId": 756,
			"districtName": "New Jerusalem Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m,h",
			"lat": 37.667702,
			"lon": -121.32222,
			"name": "Delta Home Charter",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "1301 Durham Ferry Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/31763-Delta-Home-Charter/",
				"reviews": "/california/tracy/31763-Delta-Home-Charter/#Reviews",
				"collegeSuccess": "/california/tracy/31763-Delta-Home-Charter/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "44%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 207,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 16,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 44
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 43
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 35
				},
				{
					"label": "Two or more races",
					"percentage": 13
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Asian",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32194,
			"districtId": 756,
			"districtName": "New Jerusalem Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m,h",
			"lat": 37.668037,
			"lon": -121.32412,
			"name": "Delta Keys Charter",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "31400 S. Koster Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/32194-Delta-Keys-Charter/",
				"reviews": "/california/tracy/32194-Delta-Keys-Charter/#Reviews",
				"collegeSuccess": "/california/tracy/32194-Delta-Keys-Charter/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "54%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "24%",
				"state_average": "64%"
			},
			"enrollment": 105,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 27,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 54
				},
				{
					"label": "Hispanic",
					"percentage": 45
				},
				{
					"label": "White",
					"percentage": 36
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 32197,
			"districtId": 756,
			"districtName": "New Jerusalem Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m,h",
			"lat": 37.668037,
			"lon": -121.32412,
			"name": "Delta Launch Charter",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "31400 S. Koster Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/32197-Delta-Launch-Charter/",
				"reviews": "/california/tracy/32197-Delta-Launch-Charter/#Reviews",
				"collegeSuccess": "/california/tracy/32197-Delta-Launch-Charter/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "73%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 11,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 73
				},
				{
					"label": "White",
					"percentage": 91
				},
				{
					"label": "Two or more races",
					"percentage": 9
				}
			],
			"remediationData": {}
		},
		{
			"id": 12059,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.745255,
			"lon": -121.424561,
			"name": "Discovery Charter School",
			"gradeLevels": "5-8",
			"assigned": null,
			"address": {
				"street1": "51 East Beverly Place",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/12059-Discovery-Charter-School/",
				"reviews": "/california/tracy/12059-Discovery-Charter-School/#Reviews",
				"collegeSuccess": "/california/tracy/12059-Discovery-Charter-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "21%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 370,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 21
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 46
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 28
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6662,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "h",
			"lat": 37.753471,
			"lon": -121.427528,
			"name": "Duncan-Russell Continuation",
			"gradeLevels": "10-12",
			"assigned": null,
			"address": {
				"street1": "164 West Grant Line Road",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6662-Duncan-Russell-Continuation-School/",
				"reviews": "/california/tracy/6662-Duncan-Russell-Continuation-School/#Reviews",
				"collegeSuccess": "/california/tracy/6662-Duncan-Russell-Continuation-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "58%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 19,
			"parentRating": 5,
			"numReviews": 2,
			"studentsPerTeacher": 10,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 58
				},
				{
					"label": "Hispanic",
					"percentage": 74
				},
				{
					"label": "African American",
					"percentage": 11
				},
				{
					"label": "White",
					"percentage": 11
				},
				{
					"label": "Asian",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 6674,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "m",
			"lat": 37.731075,
			"lon": -121.445213,
			"name": "Earle E. Williams Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "1600 Tennis Lane",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 4,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6674-Earle-E.-Williams-Middle-School/",
				"reviews": "/california/tracy/6674-Earle-E.-Williams-Middle-School/#Reviews",
				"collegeSuccess": "/california/tracy/6674-Earle-E.-Williams-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "57%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1039,
			"parentRating": 3,
			"numReviews": 13,
			"studentsPerTeacher": 22,
			"subratings": {
				"Test Scores Rating": 4,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 57
				},
				{
					"label": "All students",
					"rating": 4
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 11
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 55
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 18
				},
				{
					"label": "Two or more races",
					"rating": 4,
					"percentage": 3
				},
				{
					"label": "Filipino",
					"rating": 8,
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 17666,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "h",
			"lat": 37.738106,
			"lon": -121.435532,
			"name": "George and Evelyn Stein Continuation",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "650 West 10th Street",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/17666-George-And-Evelyn-Stein-Continuation-School/",
				"reviews": "/california/tracy/17666-George-And-Evelyn-Stein-Continuation-School/#Reviews",
				"collegeSuccess": "/california/tracy/17666-George-And-Evelyn-Stein-Continuation-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "62%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "21%",
				"state_average": "64%"
			},
			"enrollment": 110,
			"parentRating": 5,
			"numReviews": 3,
			"studentsPerTeacher": 9,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 62
				},
				{
					"label": "Hispanic",
					"percentage": 72
				},
				{
					"label": "White",
					"percentage": 12
				},
				{
					"label": "African American",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "Asian",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13980,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.724834,
			"lon": -121.46328,
			"name": "George Kelly Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "535 Mabel Josephine Drive",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/13980-George-Kelly-Elementary-School/",
				"reviews": "/california/tracy/13980-George-Kelly-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/13980-George-Kelly-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "40%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 1013,
			"parentRating": 3,
			"numReviews": 15,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 6
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 40
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 8,
					"percentage": 23
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 29
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"rating": 9,
					"percentage": 8
				},
				{
					"label": "Filipino",
					"rating": 6,
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 6668,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.718361,
			"lon": -121.428741,
			"name": "Gladys Poet-Christian Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1701 South Central Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6668-Gladys-Poet-Christian-Elementary-School/",
				"reviews": "/california/tracy/6668-Gladys-Poet-Christian-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6668-Gladys-Poet-Christian-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "40%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 521,
			"parentRating": 4,
			"numReviews": 15,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 6,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 40
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 44
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 34
				},
				{
					"label": "Asian",
					"percentage": 10
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31547,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.746479,
			"lon": -121.474525,
			"name": "Golden State Academy",
			"gradeLevels": "K-12",
			"assigned": null,
			"address": {
				"street1": "3195 Milton Jenson Court",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/31547-Golden-State-Academy/",
				"reviews": "/california/tracy/31547-Golden-State-Academy/#Reviews",
				"collegeSuccess": "/california/tracy/31547-Golden-State-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 7,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23279,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.746567,
			"lon": -121.420151,
			"name": "Great Beginnings Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "330 Acacia Street",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23279-Great-Beginnings-Pre-School/",
				"reviews": "/california/tracy/23279-Great-Beginnings-Pre-School/#Reviews",
				"collegeSuccess": "/california/tracy/23279-Great-Beginnings-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23273,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.730732,
			"lon": -121.425064,
			"name": "Hawkins Thompeson-Clover Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "18 East 1st Street",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23273-Hawkins-Thompeson-Clover-Child-Care-Center/",
				"reviews": "/california/tracy/23273-Hawkins-Thompeson-Clover-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/tracy/23273-Hawkins-Thompeson-Clover-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23274,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.730923,
			"lon": -121.425072,
			"name": "Hawkins-Thompson Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "18 East First",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23274-Hawkins-Thompson-Child-Development-Center/",
				"reviews": "/california/tracy/23274-Hawkins-Thompson-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/tracy/23274-Hawkins-Thompson-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 30654,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e,m",
			"lat": 37.703415,
			"lon": -121.41925,
			"name": "Jecoi Adventure Academy",
			"gradeLevels": "PK-7",
			"assigned": null,
			"address": {
				"street1": "3845 Payton Lane",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/30654-Jecoi-Adventure-Academy/",
				"reviews": "/california/tracy/30654-Jecoi-Adventure-Academy/#Reviews",
				"collegeSuccess": "/california/tracy/30654-Jecoi-Adventure-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 7,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6534,
			"districtId": 749,
			"districtName": "Jefferson Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.694893,
			"lon": -121.396683,
			"name": "Jefferson",
			"gradeLevels": "5-8",
			"assigned": null,
			"address": {
				"street1": "7500 West Linne Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6534-Jefferson-School/",
				"reviews": "/california/tracy/6534-Jefferson-School/#Reviews",
				"collegeSuccess": "/california/tracy/6534-Jefferson-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 400,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 23,
			"subratings": {
				"Test Scores Rating": 8,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 26
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 40
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 34
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 25008,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "h",
			"lat": 37.731895,
			"lon": -121.473473,
			"name": "John C. Kimball High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "3200 Jaguar Run",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/25008-John-C.-Kimball-High-School/",
				"reviews": "/california/tracy/25008-John-C.-Kimball-High-School/#Reviews",
				"collegeSuccess": "/california/tracy/25008-John-C.-Kimball-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "37%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "71%",
				"state_average": "64%"
			},
			"enrollment": 1506,
			"parentRating": 3,
			"numReviews": 31,
			"studentsPerTeacher": 25,
			"subratings": {
				"Test Scores Rating": 8,
				"College Readiness Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 37
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 16
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 7,
					"percentage": 35
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 26
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 5
				},
				{
					"label": "Filipino",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 31730,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.746651,
			"lon": -121.436249,
			"name": "kids place 2 explore & learn llc",
			"gradeLevels": "PK-5",
			"assigned": null,
			"address": {
				"street1": "18200 W Grant line Rd",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/31730-Kids-Place-2-Explore--Learn-Llc/",
				"reviews": "/california/tracy/31730-Kids-Place-2-Explore--Learn-Llc/#Reviews",
				"collegeSuccess": "/california/tracy/31730-Kids-Place-2-Explore--Learn-Llc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 12671,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.7547,
			"lon": -121.429115,
			"name": "Kindercare Learning Centers",
			"gradeLevels": "PK-K",
			"assigned": null,
			"address": {
				"street1": "265 West Grant Line Road",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/12671-Kindercare-Learning-Centers/",
				"reviews": "/california/tracy/12671-Kindercare-Learning-Centers/#Reviews",
				"collegeSuccess": "/california/tracy/12671-Kindercare-Learning-Centers/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 55,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "African American",
					"percentage": 71
				},
				{
					"label": "Hispanic",
					"percentage": 14
				},
				{
					"label": "White",
					"percentage": 14
				}
			],
			"remediationData": {}
		},
		{
			"id": 23284,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.746674,
			"lon": -121.436325,
			"name": "King's Kids Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "711 W Carlton",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23284-Kings-Kids-Pre-School/",
				"reviews": "/california/tracy/23284-Kings-Kids-Pre-School/#Reviews",
				"collegeSuccess": "/california/tracy/23284-Kings-Kids-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6536,
			"districtId": 1222,
			"districtName": "Lammersville Joint Unified",
			"districtCity": "Mountain House",
			"levelCode": "e,m",
			"lat": 37.751747,
			"lon": -121.513512,
			"name": "Lammersville Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "16555 Von Sosten Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6536-Lammersville-Elementary-School/",
				"reviews": "/california/tracy/6536-Lammersville-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6536-Lammersville-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "48%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 196,
			"parentRating": 1,
			"numReviews": 1,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 7,
				"Academic Progress Rating": 7,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 6,
					"percentage": 48
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 46
				},
				{
					"label": "White",
					"rating": 6,
					"percentage": 37
				},
				{
					"label": "Asian",
					"percentage": 10
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23275,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.756142,
			"lon": -121.46566,
			"name": "Little Country Child Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2455 Naglee Road #358",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23275-Little-Country-Child-Care/",
				"reviews": "/california/tracy/23275-Little-Country-Child-Care/#Reviews",
				"collegeSuccess": "/california/tracy/23275-Little-Country-Child-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6659,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.7285,
			"lon": -121.419815,
			"name": "Louis A. Bohn Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "350 East Mount Diablo Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6659-Louis-A.-Bohn-Elementary-School/",
				"reviews": "/california/tracy/6659-Louis-A.-Bohn-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6659-Louis-A.-Bohn-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "62%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 420,
			"parentRating": 4,
			"numReviews": 3,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 62
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 53
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 21
				},
				{
					"label": "Asian",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6671,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.732731,
			"lon": -121.446335,
			"name": "Louis J. Villalovoz Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1550 Cypress Drive",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6671-Louis-J.-Villalovoz-Elementary-School/",
				"reviews": "/california/tracy/6671-Louis-J.-Villalovoz-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6671-Louis-J.-Villalovoz-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "72%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 507,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 18,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 4
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 72
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 65
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 16
				},
				{
					"label": "Asian",
					"percentage": 7
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 6665,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.746262,
			"lon": -121.438118,
			"name": "McKinley Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "800 Carlton Way",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6665-McKinley-Elementary-School/",
				"reviews": "/california/tracy/6665-McKinley-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6665-McKinley-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "68%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 434,
			"parentRating": 3,
			"numReviews": 7,
			"studentsPerTeacher": 17,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 68
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 62
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 14
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "Two or more races",
					"percentage": 5
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		},
		{
			"id": 6664,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.756611,
			"lon": -121.447227,
			"name": "Melville S. Jacobson Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1750 West Kavanagh Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6664-Melville-S.-Jacobson-Elementary-School/",
				"reviews": "/california/tracy/6664-Melville-S.-Jacobson-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6664-Melville-S.-Jacobson-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "65%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 652,
			"parentRating": 2,
			"numReviews": 7,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 3,
					"percentage": 65
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Asian",
					"rating": 2,
					"percentage": 13
				},
				{
					"label": "Hispanic",
					"rating": 3,
					"percentage": 50
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 16
				},
				{
					"label": "Filipino",
					"rating": 6,
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 6672,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "h",
			"lat": 37.748718,
			"lon": -121.447044,
			"name": "Merrill F. West High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "1775 West Lowell Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6672-Merrill-F.-West-High-School/",
				"reviews": "/california/tracy/6672-Merrill-F.-West-High-School/#Reviews",
				"collegeSuccess": "/california/tracy/6672-Merrill-F.-West-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "57%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "65%",
				"state_average": "64%"
			},
			"enrollment": 2043,
			"parentRating": 3,
			"numReviews": 16,
			"studentsPerTeacher": 26,
			"subratings": {
				"Test Scores Rating": 7,
				"College Readiness Rating": 4,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 57
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 12
				},
				{
					"label": "African American",
					"rating": 3,
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 55
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 15
				},
				{
					"label": "Two or more races",
					"rating": 10,
					"percentage": 3
				},
				{
					"label": "Filipino",
					"rating": 10,
					"percentage": 7
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13770,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "h",
			"lat": 37.745255,
			"lon": -121.424561,
			"name": "Millennium Charter School",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "51 East Beverly Place",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/13770-Millennium-Charter-School/",
				"reviews": "/california/tracy/13770-Millennium-Charter-School/#Reviews",
				"collegeSuccess": "/california/tracy/13770-Millennium-Charter-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "22%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "72%",
				"state_average": "64%"
			},
			"enrollment": 551,
			"parentRating": 5,
			"numReviews": 6,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 8,
				"College Readiness Rating": 9,
				"Equity Overview Rating": 7
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 7,
					"percentage": 22
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 9
				},
				{
					"label": "African American",
					"rating": 5,
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 52
				},
				{
					"label": "White",
					"rating": 10,
					"percentage": 23
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6666,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "m",
			"lat": 37.748695,
			"lon": -121.435974,
			"name": "Monte Vista Middle",
			"gradeLevels": "6-8",
			"assigned": null,
			"address": {
				"street1": "751 West Lowell Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6666-Monte-Vista-Middle-School/",
				"reviews": "/california/tracy/6666-Monte-Vista-Middle-School/#Reviews",
				"collegeSuccess": "/california/tracy/6666-Monte-Vista-Middle-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "72%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 942,
			"parentRating": 2,
			"numReviews": 8,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 3,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 72
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Asian",
					"rating": 6,
					"percentage": 10
				},
				{
					"label": "African American",
					"rating": 1,
					"percentage": 7
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 64
				},
				{
					"label": "White",
					"rating": 5,
					"percentage": 10
				},
				{
					"label": "Two or more races",
					"rating": 4,
					"percentage": 4
				},
				{
					"label": "Filipino",
					"rating": 8,
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 15263,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p,e",
			"lat": 37.729332,
			"lon": -121.434349,
			"name": "Montessori School Of Tracy",
			"gradeLevels": "PK-6",
			"assigned": null,
			"address": {
				"street1": "100 South Tracy Boulevard",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/15263-Montessori-School-Of-Tracy/",
				"reviews": "/california/tracy/15263-Montessori-School-Of-Tracy/#Reviews",
				"collegeSuccess": "/california/tracy/15263-Montessori-School-Of-Tracy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 223,
			"parentRating": 5,
			"numReviews": 4,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Asian",
					"percentage": 57
				},
				{
					"label": "White",
					"percentage": 25
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Hispanic",
					"percentage": 6
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6535,
			"districtId": 749,
			"districtName": "Jefferson Elementary",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.708145,
			"lon": -121.438919,
			"name": "Monticello Elementary",
			"gradeLevels": "K-4",
			"assigned": null,
			"address": {
				"street1": "1001 Cambridge Place",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 8,
			"ratingScale": "Above average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6535-Monticello-Elementary-School/",
				"reviews": "/california/tracy/6535-Monticello-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6535-Monticello-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "26%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 460,
			"parentRating": 4,
			"numReviews": 6,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 8,
				"Equity Overview Rating": 8
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 9,
					"percentage": 26
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "Hispanic",
					"rating": 8,
					"percentage": 41
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 33
				},
				{
					"label": "Two or more races",
					"percentage": 6
				},
				{
					"label": "Filipino",
					"percentage": 4
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23281,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.729374,
			"lon": -121.431618,
			"name": "Mountain View Townhome High School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "377 West Mount Diablo Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23281-Mountain-View-Townhome-High-School/",
				"reviews": "/california/tracy/23281-Mountain-View-Townhome-High-School/#Reviews",
				"collegeSuccess": "/california/tracy/23281-Mountain-View-Townhome-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 16606,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m,h",
			"lat": 37.709999,
			"lon": -121.441704,
			"name": "New Horizon Academy",
			"gradeLevels": "1-12",
			"assigned": null,
			"address": {
				"street1": "2910 Alfalfa Court",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/16606-New-Horizon-Academy/",
				"reviews": "/california/tracy/16606-New-Horizon-Academy/#Reviews",
				"collegeSuccess": "/california/tracy/16606-New-Horizon-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 24765,
			"districtId": 756,
			"districtName": "New Jerusalem Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.668327,
			"lon": -121.323784,
			"name": "New Jerusalem",
			"gradeLevels": "1-8",
			"assigned": null,
			"address": {
				"street1": "31400 South Koster Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/24765-New-Jerusalem-School/",
				"reviews": "/california/tracy/24765-New-Jerusalem-School/#Reviews",
				"collegeSuccess": "/california/tracy/24765-New-Jerusalem-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "65%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 248,
			"parentRating": 0,
			"numReviews": 0,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 65
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 47
				},
				{
					"label": "White",
					"rating": 3,
					"percentage": 43
				},
				{
					"label": "Two or more races",
					"percentage": 2
				},
				{
					"label": "African American",
					"percentage": 2
				},
				{
					"label": "Asian",
					"percentage": 2
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 6611,
			"districtId": 756,
			"districtName": "New Jerusalem Elementary",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.668327,
			"lon": -121.323784,
			"name": "New Jerusalem Elementary",
			"gradeLevels": "K",
			"assigned": null,
			"address": {
				"street1": "31400 South Koster Road",
				"street2": null,
				"zip": "95304",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95304",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6611-New-Jerusalem-Elementary-School/",
				"reviews": "/california/tracy/6611-New-Jerusalem-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6611-New-Jerusalem-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "50%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 22,
			"parentRating": 5,
			"numReviews": 4,
			"studentsPerTeacher": 11,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 50
				},
				{
					"label": "White",
					"percentage": 50
				},
				{
					"label": "Hispanic",
					"percentage": 41
				},
				{
					"label": "Asian",
					"percentage": 5
				},
				{
					"label": "Two or more races",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 6667,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.75679,
			"lon": -121.426598,
			"name": "North Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "2875 Holly Drive",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6667-North-Elementary-School/",
				"reviews": "/california/tracy/6667-North-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6667-North-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "87%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 743,
			"parentRating": 4,
			"numReviews": 11,
			"studentsPerTeacher": 19,
			"subratings": {
				"Test Scores Rating": 2,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 2
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 2,
					"percentage": 87
				},
				{
					"label": "All students",
					"rating": 2
				},
				{
					"label": "Hispanic",
					"rating": 2,
					"percentage": 78
				},
				{
					"label": "White",
					"rating": 4,
					"percentage": 8
				},
				{
					"label": "Asian",
					"percentage": 6
				},
				{
					"label": "African American",
					"percentage": 3
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "Two or more races",
					"percentage": 1
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 13769,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.745255,
			"lon": -121.424561,
			"name": "Primary Charter School",
			"gradeLevels": "K-4",
			"assigned": null,
			"address": {
				"street1": "51 East Beverly Place",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 7,
			"ratingScale": "Above average",
			"schoolType": "charter",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/13769-Primary-Charter-School/",
				"reviews": "/california/tracy/13769-Primary-Charter-School/#Reviews",
				"collegeSuccess": "/california/tracy/13769-Primary-Charter-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "22%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 364,
			"parentRating": 4,
			"numReviews": 7,
			"studentsPerTeacher": 12,
			"subratings": {
				"Test Scores Rating": 8,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 22
				},
				{
					"label": "All students",
					"rating": 8
				},
				{
					"label": "Hispanic",
					"rating": 6,
					"percentage": 46
				},
				{
					"label": "White",
					"rating": 9,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 9
				},
				{
					"label": "Asian",
					"percentage": 9
				},
				{
					"label": "African American",
					"percentage": 6
				},
				{
					"label": "Filipino",
					"percentage": 4
				}
			],
			"remediationData": {}
		},
		{
			"id": 6669,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.727856,
			"lon": -121.432739,
			"name": "South/West Park Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "500 West Mount Diablo Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 3,
			"ratingScale": "Below average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6669-South-West-Park-Elementary-School/",
				"reviews": "/california/tracy/6669-South-West-Park-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6669-South-West-Park-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "78%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 892,
			"parentRating": 4,
			"numReviews": 10,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 3,
				"Academic Progress Rating": 4,
				"Equity Overview Rating": 1
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 1,
					"percentage": 78
				},
				{
					"label": "All students",
					"rating": 3
				},
				{
					"label": "Asian",
					"rating": 9,
					"percentage": 8
				},
				{
					"label": "Hispanic",
					"rating": 1,
					"percentage": 76
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 7
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 3
				},
				{
					"label": "Two or more races",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 8437,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.743645,
			"lon": -121.428871,
			"name": "St. Bernard's Catholic Elementary School",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "165 West Eaton Avenue",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/8437-St.-Bernards-Catholic-Elementary-School/",
				"reviews": "/california/tracy/8437-St.-Bernards-Catholic-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/8437-St.-Bernards-Catholic-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 255,
			"parentRating": 4,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 56
				},
				{
					"label": "Hispanic",
					"percentage": 19
				},
				{
					"label": "Asian",
					"percentage": 14
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 3
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23286,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.68,
			"lon": -121.43,
			"name": "Tender Loving Care Child Development",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "Po Box 1076",
				"street2": null,
				"zip": "95378",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95378",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23286-Tender-Loving-Care-Child-Development/",
				"reviews": "/california/tracy/23286-Tender-Loving-Care-Child-Development/#Reviews",
				"collegeSuccess": "/california/tracy/23286-Tender-Loving-Care-Child-Development/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23277,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.753433,
			"lon": -121.429688,
			"name": "Tender Loving Care Pre-School",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2324 Parker Ave",
				"street2": null,
				"zip": "95378",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95378",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23277-Tender-Loving-Care-Pre-School/",
				"reviews": "/california/tracy/23277-Tender-Loving-Care-Pre-School/#Reviews",
				"collegeSuccess": "/california/tracy/23277-Tender-Loving-Care-Pre-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23278,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.739712,
			"lon": -121.418411,
			"name": "Tender Loving Care Ps/Dc",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "495 West 11th Street",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23278-Tender-Loving-Care-Ps-Dc/",
				"reviews": "/california/tracy/23278-Tender-Loving-Care-Ps-Dc/#Reviews",
				"collegeSuccess": "/california/tracy/23278-Tender-Loving-Care-Ps-Dc/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23267,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.708225,
			"lon": -121.438919,
			"name": "Tender Loving Care-1",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1001 Cambridge Place",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23267-Tender-Loving-Care-1/",
				"reviews": "/california/tracy/23267-Tender-Loving-Care-1/#Reviews",
				"collegeSuccess": "/california/tracy/23267-Tender-Loving-Care-1/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23280,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.732769,
			"lon": -121.452667,
			"name": "Tiny Treasures Children's Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "350 N Corral Hollow Rd",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23280-Tiny-Treasures-Childrens-Center/",
				"reviews": "/california/tracy/23280-Tiny-Treasures-Childrens-Center/#Reviews",
				"collegeSuccess": "/california/tracy/23280-Tiny-Treasures-Childrens-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 11846,
			"districtId": 749,
			"districtName": "Jefferson Elementary",
			"districtCity": "Tracy",
			"levelCode": "e,m",
			"lat": 37.704971,
			"lon": -121.420967,
			"name": "Tom Hawkins Elementary",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "475 Darlene Lane",
				"street2": null,
				"zip": "95377",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95377",
			"type": "school",
			"links": {
				"profile": "/california/tracy/11846-Tom-Hawkins-Elementary-School/",
				"reviews": "/california/tracy/11846-Tom-Hawkins-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/11846-Tom-Hawkins-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "30%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 734,
			"parentRating": 4,
			"numReviews": 22,
			"studentsPerTeacher": 21,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 30
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 7,
					"percentage": 14
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 38
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 35
				},
				{
					"label": "African American",
					"percentage": 4
				},
				{
					"label": "Two or more races",
					"percentage": 4
				},
				{
					"label": "Filipino",
					"percentage": 2
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 14345,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e,m,h",
			"lat": 37.749786,
			"lon": -121.45298,
			"name": "Tracy Adult",
			"gradeLevels": "Ungraded",
			"assigned": null,
			"address": {
				"street1": "1902 Corral Hollow Road",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/14345-Tracy-Adult/",
				"reviews": "/california/tracy/14345-Tracy-Adult/#Reviews",
				"collegeSuccess": "/california/tracy/14345-Tracy-Adult/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6670,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "h",
			"lat": 37.74139,
			"lon": -121.419144,
			"name": "Tracy High",
			"gradeLevels": "9-12",
			"assigned": null,
			"address": {
				"street1": "315 East 11th Street",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 6,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6670-Tracy-High-School/",
				"reviews": "/california/tracy/6670-Tracy-High-School/#Reviews",
				"collegeSuccess": "/california/tracy/6670-Tracy-High-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "37%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {
				"school_value": "76%",
				"state_average": "64%"
			},
			"enrollment": 1999,
			"parentRating": 4,
			"numReviews": 2,
			"studentsPerTeacher": 24,
			"subratings": {
				"Test Scores Rating": 7,
				"College Readiness Rating": 7,
				"Equity Overview Rating": 3
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 4,
					"percentage": 37
				},
				{
					"label": "All students",
					"rating": 7
				},
				{
					"label": "Asian",
					"rating": 10,
					"percentage": 10
				},
				{
					"label": "African American",
					"rating": 6,
					"percentage": 5
				},
				{
					"label": "Hispanic",
					"rating": 4,
					"percentage": 46
				},
				{
					"label": "White",
					"rating": 8,
					"percentage": 31
				},
				{
					"label": "Two or more races",
					"rating": 8,
					"percentage": 3
				},
				{
					"label": "Filipino",
					"rating": 8,
					"percentage": 5
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 9057,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.75177,
			"lon": -121.424576,
			"name": "Tracy Seventh-Day Adventist Christian",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "126 West 21st Street",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/9057-Tracy-Seventh-Day-Adventist-Christian/",
				"reviews": "/california/tracy/9057-Tracy-Seventh-Day-Adventist-Christian/#Reviews",
				"collegeSuccess": "/california/tracy/9057-Tracy-Seventh-Day-Adventist-Christian/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 19,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "Hispanic",
					"percentage": 47
				},
				{
					"label": "White",
					"percentage": 37
				},
				{
					"label": "African American",
					"percentage": 11
				},
				{
					"label": "Asian",
					"percentage": 5
				}
			],
			"remediationData": {}
		},
		{
			"id": 30331,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.757408,
			"lon": -121.426003,
			"name": "Tracy Usd Pre-Sch-North",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "2875 Holly Drive #1",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/30331-Tracy-Usd-Pre-Sch-North/",
				"reviews": "/california/tracy/30331-Tracy-Usd-Pre-Sch-North/#Reviews",
				"collegeSuccess": "/california/tracy/30331-Tracy-Usd-Pre-Sch-North/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23285,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.736099,
			"lon": -121.428055,
			"name": "Tykeland Community Child Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "89 W 7th St",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23285-Tykeland-Community-Child-Care-Center/",
				"reviews": "/california/tracy/23285-Tykeland-Community-Child-Care-Center/#Reviews",
				"collegeSuccess": "/california/tracy/23285-Tykeland-Community-Child-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 16615,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "m,h",
			"lat": 37.739601,
			"lon": -121.439201,
			"name": "Victory Christian Academy",
			"gradeLevels": "8-12",
			"assigned": null,
			"address": {
				"street1": "1175 West 11th Street",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/16615-Victory-Christian-Academy/",
				"reviews": "/california/tracy/16615-Victory-Christian-Academy/#Reviews",
				"collegeSuccess": "/california/tracy/16615-Victory-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 6663,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "e",
			"lat": 37.714867,
			"lon": -121.440849,
			"name": "Wanda Hirsch Elementary",
			"gradeLevels": "K-5",
			"assigned": null,
			"address": {
				"street1": "1280 Dove Drive",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": 5,
			"ratingScale": "Average",
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/6663-Wanda-Hirsch-Elementary-School/",
				"reviews": "/california/tracy/6663-Wanda-Hirsch-Elementary-School/#Reviews",
				"collegeSuccess": "/california/tracy/6663-Wanda-Hirsch-Elementary-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "45%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 510,
			"parentRating": 5,
			"numReviews": 6,
			"studentsPerTeacher": 20,
			"subratings": {
				"Test Scores Rating": 6,
				"Academic Progress Rating": 5,
				"Equity Overview Rating": 5
			},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"rating": 5,
					"percentage": 45
				},
				{
					"label": "All students",
					"rating": 6
				},
				{
					"label": "Asian",
					"rating": 7,
					"percentage": 12
				},
				{
					"label": "Hispanic",
					"rating": 5,
					"percentage": 41
				},
				{
					"label": "White",
					"rating": 7,
					"percentage": 25
				},
				{
					"label": "Two or more races",
					"percentage": 8
				},
				{
					"label": "African American",
					"percentage": 7
				},
				{
					"label": "Filipino",
					"percentage": 5
				},
				{
					"label": "Pacific Islander",
					"percentage": 1
				},
				{
					"label": "American Indian/Alaska Native",
					"percentage": 1
				}
			],
			"remediationData": {}
		},
		{
			"id": 23271,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.737961,
			"lon": -121.449257,
			"name": "Wee Care Day Care",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1790 Sequoia Boulevard",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23271-Wee-Care-Day-Care/",
				"reviews": "/california/tracy/23271-Wee-Care-Day-Care/#Reviews",
				"collegeSuccess": "/california/tracy/23271-Wee-Care-Day-Care/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23272,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.737801,
			"lon": -121.449265,
			"name": "Wee Care Day Care Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "1790 Sequoia Blvd",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23272-Wee-Care-Day-Care-Center/",
				"reviews": "/california/tracy/23272-Wee-Care-Day-Care-Center/#Reviews",
				"collegeSuccess": "/california/tracy/23272-Wee-Care-Day-Care-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 5,
			"numReviews": 1,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 23282,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "p",
			"lat": 37.733528,
			"lon": -121.452522,
			"name": "West Hills Child Development Center",
			"gradeLevels": "PK",
			"assigned": null,
			"address": {
				"street1": "500 N Corral Hollow Rd",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/23282-West-Hills-Child-Development-Center/",
				"reviews": "/california/tracy/23282-West-Hills-Child-Development-Center/#Reviews",
				"collegeSuccess": "/california/tracy/23282-West-Hills-Child-Development-Center/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 0,
			"parentRating": 0,
			"numReviews": 0,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				}
			],
			"remediationData": {}
		},
		{
			"id": 11532,
			"districtId": 0,
			"districtName": null,
			"districtCity": null,
			"levelCode": "e,m",
			"lat": 37.737961,
			"lon": -121.449257,
			"name": "West Valley Christian Academy",
			"gradeLevels": "K-8",
			"assigned": null,
			"address": {
				"street1": "1790 Sequoia Boulevard",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "private",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/11532-West-Valley-Christian-Academy/",
				"reviews": "/california/tracy/11532-West-Valley-Christian-Academy/#Reviews",
				"collegeSuccess": "/california/tracy/11532-West-Valley-Christian-Academy/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "?",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 136,
			"parentRating": 5,
			"numReviews": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 0
				},
				{
					"label": "White",
					"percentage": 36
				},
				{
					"label": "Asian",
					"percentage": 21
				},
				{
					"label": "Hispanic",
					"percentage": 21
				},
				{
					"label": "Two or more races",
					"percentage": 11
				},
				{
					"label": "African American",
					"percentage": 9
				},
				{
					"label": "Pacific Islander",
					"percentage": 2
				}
			],
			"remediationData": {}
		},
		{
			"id": 14682,
			"districtId": 760,
			"districtName": "Tracy Joint Unified",
			"districtCity": "Tracy",
			"levelCode": "m,h",
			"lat": 37.753471,
			"lon": -121.427528,
			"name": "Willow Community Day",
			"gradeLevels": "7-12",
			"assigned": null,
			"address": {
				"street1": "164 West Grant Line Road",
				"street2": null,
				"zip": "95376",
				"city": "Tracy"
			},
			"csaAwardYears": [],
			"rating": null,
			"ratingScale": null,
			"schoolType": "public",
			"state": "CA",
			"zipcode": "95376",
			"type": "school",
			"links": {
				"profile": "/california/tracy/14682-Willow-Community-Day-School/",
				"reviews": "/california/tracy/14682-Willow-Community-Day-School/#Reviews",
				"collegeSuccess": "/california/tracy/14682-Willow-Community-Day-School/#College_success"
			},
			"highlighted": false,
			"pinned": null,
			"testScoreRatingForEthnicity": null,
			"percentLowIncome": "97%",
			"collegePersistentData": [],
			"collegeEnrollmentData": {},
			"enrollment": 31,
			"parentRating": 1,
			"numReviews": 2,
			"studentsPerTeacher": 8,
			"subratings": {},
			"ethnicityInfo": [
				{
					"label": "Low-income",
					"percentage": 97
				},
				{
					"label": "Hispanic",
					"percentage": 58
				},
				{
					"label": "African American",
					"percentage": 16
				},
				{
					"label": "White",
					"percentage": 16
				},
				{
					"label": "Filipino",
					"percentage": 6
				},
				{
					"label": "Pacific Islander",
					"percentage": 3
				}
			],
			"remediationData": {}
		}
	]
