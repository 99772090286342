exports.DUBLIN_SCHOOLS = [{'address': {'city': 'Dublin',
                               'street1': '7997 Vomac Road',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 878,
                   'ethnicityInfo': [{'label': 'Low-income',
                                      'percentage': 9,
                                      'rating': 3},
                                     {'label': 'All students', 'rating': 9},
                                     {'label': 'Asian', 'percentage': 35, 'rating': 10},
                                     {'label': 'Hispanic',
                                      'percentage': 15,
                                      'rating': 5},
                                     {'label': 'White', 'percentage': 31, 'rating': 9},
                                     {'label': 'Two or more races',
                                      'percentage': 11,
                                      'rating': 9},
                                     {'label': 'Filipino', 'percentage': 4},
                                     {'label': 'African American', 'percentage': 2},
                                     {'label': 'Pacific Islander', 'percentage': 1},
                                     {'label': 'American Indian/Alaska Native',
                                      'percentage': 1}],
                   'gradeLevels': 'K-5',
                   'highlighted': false,
                   'id': 55,
                   'lat': 37.715023,
                   'levelCode': 'e',
                   'links': {'collegeSuccess': '/california/dublin/55-Dublin-Elementary-School/#College_success',
                             'profile': '/california/dublin/55-Dublin-Elementary-School/',
                             'reviews': '/california/dublin/55-Dublin-Elementary-School/#Reviews'},
                   'lon': -121.936501,
                   'name': 'Dublin Elementary School',
                   'numReviews': 23,
                   'parentRating': 4,
                   'percentLowIncome': '9%',
                   'pinned': null,
                   'rating': 7,
                   'ratingScale': 'Above average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 21,
                   'subratings': {'Academic Progress Rating': 8,
                                  'Equity Overview Rating': 4,
                                  'Test Scores Rating': 9},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '8151 Village Parkway',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {'school_value': '85%',
                                             'state_average': '64%'},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 2978,
                   'ethnicityInfo': [{'label': 'Low-income',
                                      'percentage': 10,
                                      'rating': 5},
                                     {'label': 'All students', 'rating': 10},
                                     {'label': 'Asian', 'percentage': 44, 'rating': 10},
                                     {'label': 'African American',
                                      'percentage': 4,
                                      'rating': 3},
                                     {'label': 'Hispanic',
                                      'percentage': 13,
                                      'rating': 7},
                                     {'label': 'White', 'percentage': 24, 'rating': 9},
                                     {'label': 'Two or more races',
                                      'percentage': 9,
                                      'rating': 9},
                                     {'label': 'Filipino',
                                      'percentage': 6,
                                      'rating': 10}],
                   'gradeLevels': '9-12',
                   'highlighted': false,
                   'id': 56,
                   'lat': 37.720997,
                   'levelCode': 'h',
                   'links': {'collegeSuccess': '/california/dublin/56-Dublin-High-School/#College_success',
                             'profile': '/california/dublin/56-Dublin-High-School/',
                             'reviews': '/california/dublin/56-Dublin-High-School/#Reviews'},
                   'lon': -121.926392,
                   'name': 'Dublin High School',
                   'numReviews': 64,
                   'parentRating': 3,
                   'percentLowIncome': '10%',
                   'pinned': null,
                   'rating': 9,
                   'ratingScale': 'Above average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 24,
                   'subratings': {'College Readiness Rating': 10,
                                  'Equity Overview Rating': 5,
                                  'Test Scores Rating': 10},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7243 Tamarack Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 800,
                   'ethnicityInfo': [{'label': 'Low-income',
                                      'percentage': 18,
                                      'rating': 2},
                                     {'label': 'All students', 'rating': 7},
                                     {'label': 'Asian', 'percentage': 27, 'rating': 9},
                                     {'label': 'Hispanic',
                                      'percentage': 21,
                                      'rating': 6},
                                     {'label': 'White', 'percentage': 30, 'rating': 7},
                                     {'label': 'Two or more races',
                                      'percentage': 10,
                                      'rating': 7},
                                     {'label': 'Filipino',
                                      'percentage': 5,
                                      'rating': 9},
                                     {'label': 'African American', 'percentage': 6},
                                     {'label': 'Pacific Islander', 'percentage': 1}],
                   'gradeLevels': 'K-5',
                   'highlighted': false,
                   'id': 57,
                   'lat': 37.715076,
                   'levelCode': 'e',
                   'links': {'collegeSuccess': '/california/dublin/57-Frederiksen-Elementary-School/#College_success',
                             'profile': '/california/dublin/57-Frederiksen-Elementary-School/',
                             'reviews': '/california/dublin/57-Frederiksen-Elementary-School/#Reviews'},
                   'lon': -121.921951,
                   'name': 'Frederiksen Elementary School',
                   'numReviews': 13,
                   'parentRating': 4,
                   'percentLowIncome': '18%',
                   'pinned': null,
                   'rating': 6,
                   'ratingScale': 'Average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 21,
                   'subratings': {'Academic Progress Rating': 7,
                                  'Equity Overview Rating': 3,
                                  'Test Scores Rating': 7},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '8435 Davona Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 615,
                   'ethnicityInfo': [{'label': 'Low-income',
                                      'percentage': 16,
                                      'rating': 6},
                                     {'label': 'All students', 'rating': 8},
                                     {'label': 'Asian', 'percentage': 31, 'rating': 9},
                                     {'label': 'Hispanic',
                                      'percentage': 21,
                                      'rating': 5},
                                     {'label': 'White', 'percentage': 27, 'rating': 9},
                                     {'label': 'Two or more races', 'percentage': 8},
                                     {'label': 'Filipino', 'percentage': 6},
                                     {'label': 'African American', 'percentage': 5},
                                     {'label': 'Pacific Islander', 'percentage': 1},
                                     {'label': 'American Indian/Alaska Native',
                                      'percentage': 1}],
                   'gradeLevels': 'K-5',
                   'highlighted': false,
                   'id': 58,
                   'lat': 37.721539,
                   'levelCode': 'e',
                   'links': {'collegeSuccess': '/california/dublin/58-Murray-Elementary-School/#College_success',
                             'profile': '/california/dublin/58-Murray-Elementary-School/',
                             'reviews': '/california/dublin/58-Murray-Elementary-School/#Reviews'},
                   'lon': -121.932983,
                   'name': 'Murray Elementary School',
                   'numReviews': 21,
                   'parentRating': 4,
                   'percentLowIncome': '16%',
                   'pinned': null,
                   'rating': 7,
                   'ratingScale': 'Above average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 19,
                   'subratings': {'Academic Progress Rating': 6,
                                  'Equity Overview Rating': 5,
                                  'Test Scores Rating': 8},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '6901 York Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {'school_value': '43%',
                                             'state_average': '64%'},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 60,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 35},
                                     {'label': 'All students', 'rating': 2},
                                     {'label': 'Hispanic', 'percentage': 30},
                                     {'label': 'White', 'percentage': 28},
                                     {'label': 'African American', 'percentage': 20},
                                     {'label': 'Asian', 'percentage': 10},
                                     {'label': 'Two or more races', 'percentage': 10},
                                     {'label': 'Filipino', 'percentage': 2}],
                   'gradeLevels': '9-12',
                   'highlighted': false,
                   'id': 60,
                   'lat': 37.709167,
                   'levelCode': 'h',
                   'links': {'collegeSuccess': '/california/dublin/60-Valley-High-Continuation-School/#College_success',
                             'profile': '/california/dublin/60-Valley-High-Continuation-School/',
                             'reviews': '/california/dublin/60-Valley-High-Continuation-School/#Reviews'},
                   'lon': -121.921181,
                   'name': 'Valley High (Continuation) School',
                   'numReviews': 1,
                   'parentRating': 5,
                   'percentLowIncome': '35%',
                   'pinned': null,
                   'rating': 2,
                   'ratingScale': 'Below average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 6,
                   'subratings': {'College Readiness Rating': 2, 'Test Scores Rating': 2},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '6800 Penn Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 996,
                   'ethnicityInfo': [{'label': 'Low-income',
                                      'percentage': 16,
                                      'rating': 5},
                                     {'label': 'All students', 'rating': 8},
                                     {'label': 'Asian', 'percentage': 25, 'rating': 10},
                                     {'label': 'African American',
                                      'percentage': 5,
                                      'rating': 2},
                                     {'label': 'Hispanic',
                                      'percentage': 20,
                                      'rating': 6},
                                     {'label': 'White', 'percentage': 33, 'rating': 8},
                                     {'label': 'Two or more races',
                                      'percentage': 9,
                                      'rating': 9},
                                     {'label': 'Filipino',
                                      'percentage': 7,
                                      'rating': 9},
                                     {'label': 'Pacific Islander', 'percentage': 1}],
                   'gradeLevels': '6-8',
                   'highlighted': false,
                   'id': 61,
                   'lat': 37.712563,
                   'levelCode': 'm',
                   'links': {'collegeSuccess': '/california/dublin/61-Wells-Middle-School/#College_success',
                             'profile': '/california/dublin/61-Wells-Middle-School/',
                             'reviews': '/california/dublin/61-Wells-Middle-School/#Reviews'},
                   'lon': -121.9188,
                   'name': 'Wells Middle School',
                   'numReviews': 24,
                   'parentRating': 4,
                   'percentLowIncome': '16%',
                   'pinned': null,
                   'rating': 6,
                   'ratingScale': 'Average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 20,
                   'subratings': {'Academic Progress Rating': 6,
                                  'Equity Overview Rating': 4,
                                  'Test Scores Rating': 8},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '6400 Village Parkway #207',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 28171,
                   'lat': 37.704571,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/28171-Extended-Day-Child-Care-Green/#College_success',
                       'profile': '/california/dublin/28171-Extended-Day-Child-Care-Green/',
                       'reviews': '/california/dublin/28171-Extended-Day-Child-Care-Green/#Reviews'},
                   'lon': -121.922714,
                   'name': 'Extended Day Child Care-Green',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7243 Tamarack Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 28724,
                   'lat': 37.715076,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/28724-Frederiksen-Head-Start/#College_success',
                             'profile': '/california/dublin/28724-Frederiksen-Head-Start/',
                             'reviews': '/california/dublin/28724-Frederiksen-Head-Start/#Reviews'},
                   'lon': -121.921959,
                   'name': 'Frederiksen Head Start',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '6665 Amador Plaza Road #200',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 28953,
                   'lat': 37.701843,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/28953-Fountainhead-Mont-School-Livermor/#College_success',
                       'profile': '/california/dublin/28953-Fountainhead-Mont-School-Livermor/',
                       'reviews': '/california/dublin/28953-Fountainhead-Mont-School-Livermor/#Reviews'},
                   'lon': -121.925484,
                   'name': 'Fountainhead Mont School Livermor',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '8692 Fenwick Way',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 28970,
                   'lat': 37.721161,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/28970-Annies-Playhouse-Pre-School/#College_success',
                             'profile': '/california/dublin/28970-Annies-Playhouse-Pre-School/',
                             'reviews': '/california/dublin/28970-Annies-Playhouse-Pre-School/#Reviews'},
                   'lon': -121.94635,
                   'name': "Annie's Playhouse Pre-School",
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7031 Prince Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 29311,
                   'lat': 37.714275,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/29311-Busdking-Child-Development-Head-Start/#College_success',
                       'profile': '/california/dublin/29311-Busdking-Child-Development-Head-Start/',
                       'reviews': '/california/dublin/29311-Busdking-Child-Development-Head-Start/#Reviews'},
                   'lon': -121.915825,
                   'name': 'Busdking Child Development Head Start',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '5100 Brannigan Street',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 30022,
                   'lat': 37.714542,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/30022-Springfield-Mont-School-Dublin/#College_success',
                       'profile': '/california/dublin/30022-Springfield-Mont-School-Dublin/',
                       'reviews': '/california/dublin/30022-Springfield-Mont-School-Dublin/#Reviews'},
                   'lon': -121.869286,
                   'name': 'Springfield Mont School-Dublin',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '9801 Dublin Boulevard',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 30024,
                   'lat': 37.701233,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/30024-School-Of-Imagination-Child-Care-Center/#College_success',
                       'profile': '/california/dublin/30024-School-Of-Imagination-Child-Care-Center/',
                       'reviews': '/california/dublin/30024-School-Of-Imagination-Child-Care-Center/#Reviews'},
                   'lon': -121.976746,
                   'name': 'School Of Imagination Child Care Center',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7035 Dublin Boulevard',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 30271,
                   'lat': 37.705776,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/30271-Bright-Horizons-Child-Care-Center-Dublin/#College_success',
                       'profile': '/california/dublin/30271-Bright-Horizons-Child-Care-Center-Dublin/',
                       'reviews': '/california/dublin/30271-Bright-Horizons-Child-Care-Center-Dublin/#Reviews'},
                   'lon': -121.920357,
                   'name': 'Bright Horizons Child Care Center-Dublin',
                   'numReviews': 1,
                   'parentRating': 5,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '6665 Amador Plaza Road',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 43,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                     {'label': 'White', 'percentage': 100}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 30624,
                   'lat': 37.701843,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/30624-Fountainhead-Montessori-School---Danville/#College_success',
                       'profile': '/california/dublin/30624-Fountainhead-Montessori-School---Danville/',
                       'reviews': '/california/dublin/30624-Fountainhead-Montessori-School---Danville/#Reviews'},
                   'lon': -121.925484,
                   'name': 'Fountainhead Montessori School - Danville',
                   'numReviews': 1,
                   'parentRating': 5,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '9801 Dublin Boulevard',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 43,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                     {'label': 'White', 'percentage': 61},
                                     {'label': 'Asian or Pacific Islander',
                                      'percentage': 22},
                                     {'label': 'Hispanic', 'percentage': 17}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 30750,
                   'lat': 37.701233,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/30750-School-Of-Imagination--Happy-Talkers/#College_success',
                       'profile': '/california/dublin/30750-School-Of-Imagination--Happy-Talkers/',
                       'reviews': '/california/dublin/30750-School-Of-Imagination--Happy-Talkers/#Reviews'},
                   'lon': -121.976746,
                   'name': 'School Of Imagination & Happy Talkers',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '8941 San Ramon Rd',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 31991,
                   'lat': 37.722485,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/31991-Glitter-Kids/#College_success',
                             'profile': '/california/dublin/31991-Glitter-Kids/',
                             'reviews': '/california/dublin/31991-Glitter-Kids/#Reviews'},
                   'lon': -121.942131,
                   'name': 'Glitter Kids',
                   'numReviews': 1,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '2100 East Cantara Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 839,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 2},
                                     {'label': 'All students', 'rating': 10},
                                     {'label': 'Asian', 'percentage': 77, 'rating': 10},
                                     {'label': 'White', 'percentage': 7, 'rating': 10},
                                     {'label': 'Two or more races',
                                      'percentage': 6,
                                      'rating': 10},
                                     {'label': 'Hispanic', 'percentage': 3},
                                     {'label': 'Filipino', 'percentage': 3},
                                     {'label': 'African American', 'percentage': 1},
                                     {'label': 'American Indian/Alaska Native',
                                      'percentage': 1}],
                   'gradeLevels': 'K-5',
                   'highlighted': false,
                   'id': 32016,
                   'lat': 37.717297,
                   'levelCode': 'e',
                   'links': {'collegeSuccess': '/california/dublin/32016-J.-M.-Amador-Elementary/#College_success',
                             'profile': '/california/dublin/32016-J.-M.-Amador-Elementary/',
                             'reviews': '/california/dublin/32016-J.-M.-Amador-Elementary/#Reviews'},
                   'lon': -121.84565,
                   'name': 'J. M. Amador Elementary',
                   'numReviews': 23,
                   'parentRating': 5,
                   'percentLowIncome': '2%',
                   'pinned': null,
                   'rating': 9,
                   'ratingScale': 'Above average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 20,
                   'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '2400 Central Parkway',
                               'street2': null,
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 813,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 7},
                                     {'label': 'All students', 'rating': 10},
                                     {'label': 'Asian', 'percentage': 78, 'rating': 10},
                                     {'label': 'Two or more races', 'percentage': 6},
                                     {'label': 'Hispanic', 'percentage': 5},
                                     {'label': 'White', 'percentage': 5},
                                     {'label': 'Filipino', 'percentage': 3},
                                     {'label': 'African American', 'percentage': 2},
                                     {'label': 'American Indian/Alaska Native',
                                      'percentage': 1}],
                   'gradeLevels': 'K-8',
                   'highlighted': false,
                   'id': 32569,
                   'lat': 37.710003,
                   'levelCode': 'e,m',
                   'links': {'collegeSuccess': '/california/dublin/32569-Cottonwood-Creek/#College_success',
                             'profile': '/california/dublin/32569-Cottonwood-Creek/',
                             'reviews': '/california/dublin/32569-Cottonwood-Creek/#Reviews'},
                   'lon': -121.847321,
                   'name': 'Cottonwood Creek',
                   'numReviews': 56,
                   'parentRating': 4,
                   'percentLowIncome': '7%',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'subratings': {'Test Scores Rating': 10},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7500 Inspiration Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 282,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'K-5',
                   'highlighted': false,
                   'id': 8515,
                   'lat': 37.702232,
                   'levelCode': 'e',
                   'links': {
                       'collegeSuccess': '/california/dublin/8515-Valley-Christian-Elementary-School/#College_success',
                       'profile': '/california/dublin/8515-Valley-Christian-Elementary-School/',
                       'reviews': '/california/dublin/8515-Valley-Christian-Elementary-School/#Reviews'},
                   'lon': -121.953728,
                   'name': 'Valley Christian Elementary School',
                   'numReviews': 20,
                   'parentRating': 4,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '11760 Dublin Boulevard',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 13156,
                   'lat': 37.701351,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/13156-Little-Kids-Learning-Center/#College_success',
                             'profile': '/california/dublin/13156-Little-Kids-Learning-Center/',
                             'reviews': '/california/dublin/13156-Little-Kids-Learning-Center/#Reviews'},
                   'lon': -121.939537,
                   'name': 'Little Kids Learning Center',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '11557 Shannon Avenue',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 305,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                     {'label': 'White', 'percentage': 49},
                                     {'label': 'Asian', 'percentage': 21},
                                     {'label': 'Hispanic', 'percentage': 15},
                                     {'label': 'Two or more races', 'percentage': 13},
                                     {'label': 'Pacific Islander', 'percentage': 2}],
                   'gradeLevels': 'K-8',
                   'highlighted': false,
                   'id': 9552,
                   'lat': 37.713833,
                   'levelCode': 'e,m',
                   'links': {'collegeSuccess': '/california/dublin/9552-St.-Raymond/#College_success',
                             'profile': '/california/dublin/9552-St.-Raymond/',
                             'reviews': '/california/dublin/9552-St.-Raymond/#Reviews'},
                   'lon': -121.939766,
                   'name': 'St. Raymond',
                   'numReviews': 67,
                   'parentRating': 3,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '6363 Tassajara Road',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 673,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                     {'label': 'Asian', 'percentage': 48},
                                     {'label': 'Two or more races', 'percentage': 39},
                                     {'label': 'White', 'percentage': 6},
                                     {'label': 'African American', 'percentage': 4},
                                     {'label': 'Hispanic', 'percentage': 3}],
                   'gradeLevels': 'K-12',
                   'highlighted': false,
                   'id': 10327,
                   'lat': 37.727722,
                   'levelCode': 'e,m,h',
                   'links': {'collegeSuccess': '/california/dublin/10327-The-Quarry-Lane-School/#College_success',
                             'profile': '/california/dublin/10327-The-Quarry-Lane-School/',
                             'reviews': '/california/dublin/10327-The-Quarry-Lane-School/#Reviews'},
                   'lon': -121.86985,
                   'name': 'The Quarry Lane School',
                   'numReviews': 63,
                   'parentRating': 4,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7500 Inspiration Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 686,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                     {'label': 'White', 'percentage': 60},
                                     {'label': 'Asian', 'percentage': 16},
                                     {'label': 'African American', 'percentage': 7},
                                     {'label': 'Two or more races', 'percentage': 7},
                                     {'label': 'Hispanic', 'percentage': 7},
                                     {'label': 'Pacific Islander', 'percentage': 3}],
                   'gradeLevels': '6-12',
                   'highlighted': false,
                   'id': 11515,
                   'lat': 37.702232,
                   'levelCode': 'm,h',
                   'links': {
                       'collegeSuccess': '/california/dublin/11515-Valley-Christian-Middle-School--High-School/#College_success',
                       'profile': '/california/dublin/11515-Valley-Christian-Middle-School--High-School/',
                       'reviews': '/california/dublin/11515-Valley-Christian-Middle-School--High-School/#Reviews'},
                   'lon': -121.953728,
                   'name': 'Valley Christian Middle School & High School',
                   'numReviews': 6,
                   'parentRating': 4,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '5301 Hibernia Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 890,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 3},
                                     {'label': 'All students', 'rating': 10},
                                     {'label': 'Asian', 'percentage': 71, 'rating': 10},
                                     {'label': 'White', 'percentage': 11, 'rating': 9},
                                     {'label': 'Hispanic', 'percentage': 6},
                                     {'label': 'Two or more races', 'percentage': 6},
                                     {'label': 'Filipino', 'percentage': 5},
                                     {'label': 'African American', 'percentage': 2}],
                   'gradeLevels': 'K-5',
                   'highlighted': false,
                   'id': 11914,
                   'lat': 37.710388,
                   'levelCode': 'e',
                   'links': {
                       'collegeSuccess': '/california/dublin/11914-James-Dougherty-Elementary-School/#College_success',
                       'profile': '/california/dublin/11914-James-Dougherty-Elementary-School/',
                       'reviews': '/california/dublin/11914-James-Dougherty-Elementary-School/#Reviews'},
                   'lon': -121.885117,
                   'name': 'James Dougherty Elementary School',
                   'numReviews': 25,
                   'parentRating': 4,
                   'percentLowIncome': '3%',
                   'pinned': null,
                   'rating': 9,
                   'ratingScale': 'Above average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 23,
                   'subratings': {'Academic Progress Rating': 7, 'Test Scores Rating': 10},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '6901 York Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'Ungraded',
                   'highlighted': false,
                   'id': 13725,
                   'lat': 37.709541,
                   'levelCode': 'e,m,h',
                   'links': {'collegeSuccess': '/california/dublin/13725-Dublin-Adult-Education/#College_success',
                             'profile': '/california/dublin/13725-Dublin-Adult-Education/',
                             'reviews': '/california/dublin/13725-Dublin-Adult-Education/#Reviews'},
                   'lon': -121.921463,
                   'name': 'Dublin Adult Education',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '3601 Kohnen Way',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 1557,
                   'ethnicityInfo': [{'label': 'Low-income',
                                      'percentage': 5,
                                      'rating': 7},
                                     {'label': 'All students', 'rating': 10},
                                     {'label': 'Asian', 'percentage': 66, 'rating': 10},
                                     {'label': 'African American',
                                      'percentage': 2,
                                      'rating': 6},
                                     {'label': 'Hispanic',
                                      'percentage': 6,
                                      'rating': 7},
                                     {'label': 'White', 'percentage': 14, 'rating': 10},
                                     {'label': 'Two or more races',
                                      'percentage': 8,
                                      'rating': 10},
                                     {'label': 'Filipino',
                                      'percentage': 4,
                                      'rating': 10}],
                   'gradeLevels': '6-8',
                   'highlighted': false,
                   'id': 14244,
                   'lat': 37.71566,
                   'levelCode': 'm',
                   'links': {'collegeSuccess': '/california/dublin/14244-Eleanor-Murray-Fallon-School/#College_success',
                             'profile': '/california/dublin/14244-Eleanor-Murray-Fallon-School/',
                             'reviews': '/california/dublin/14244-Eleanor-Murray-Fallon-School/#Reviews'},
                   'lon': -121.865891,
                   'name': 'Eleanor Murray Fallon School',
                   'numReviews': 50,
                   'parentRating': 4,
                   'percentLowIncome': '5%',
                   'pinned': null,
                   'rating': 9,
                   'ratingScale': 'Above average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 24,
                   'subratings': {'Academic Progress Rating': 8,
                                  'Equity Overview Rating': 7,
                                  'Test Scores Rating': 10},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '6665 Amador Plaza Road',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 106,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                     {'label': 'Hispanic', 'percentage': 63},
                                     {'label': 'White', 'percentage': 25},
                                     {'label': 'Asian', 'percentage': 13}],
                   'gradeLevels': 'PK-1',
                   'highlighted': false,
                   'id': 15215,
                   'lat': 37.701843,
                   'levelCode': 'p,e',
                   'links': {
                       'collegeSuccess': '/california/dublin/15215-Fountainhead-Montessori-School/#College_success',
                       'profile': '/california/dublin/15215-Fountainhead-Montessori-School/',
                       'reviews': '/california/dublin/15215-Fountainhead-Montessori-School/#Reviews'},
                   'lon': -121.925484,
                   'name': 'Fountainhead Montessori School',
                   'numReviews': 16,
                   'parentRating': 4,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '8850 Davona Dr',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 67,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                     {'label': 'White', 'percentage': 45},
                                     {'label': 'Asian', 'percentage': 22},
                                     {'label': 'Hispanic', 'percentage': 15},
                                     {'label': 'Two or more races', 'percentage': 13},
                                     {'label': 'African American', 'percentage': 4}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 15580,
                   'lat': 37.723457,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/15580-St.-Philip-Lutheran-School/#College_success',
                             'profile': '/california/dublin/15580-St.-Philip-Lutheran-School/',
                             'reviews': '/california/dublin/15580-St.-Philip-Lutheran-School/#Reviews'},
                   'lon': -121.937225,
                   'name': 'St. Philip Lutheran School',
                   'numReviews': 39,
                   'parentRating': 5,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '3300 Antone Way',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 859,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 4},
                                     {'label': 'All students', 'rating': 10},
                                     {'label': 'Asian', 'percentage': 69, 'rating': 10},
                                     {'label': 'Hispanic',
                                      'percentage': 5,
                                      'rating': 10},
                                     {'label': 'White', 'percentage': 11, 'rating': 9},
                                     {'label': 'Two or more races',
                                      'percentage': 7,
                                      'rating': 10},
                                     {'label': 'Filipino', 'percentage': 5},
                                     {'label': 'African American', 'percentage': 2}],
                   'gradeLevels': 'K-5',
                   'highlighted': false,
                   'id': 17572,
                   'lat': 37.718102,
                   'levelCode': 'e',
                   'links': {'collegeSuccess': '/california/dublin/17572-John-Green-Elementary-School/#College_success',
                             'profile': '/california/dublin/17572-John-Green-Elementary-School/',
                             'reviews': '/california/dublin/17572-John-Green-Elementary-School/#Reviews'},
                   'lon': -121.861504,
                   'name': 'John Green Elementary School',
                   'numReviews': 20,
                   'parentRating': 4,
                   'percentLowIncome': '4%',
                   'pinned': null,
                   'rating': 10,
                   'ratingScale': 'Top rated',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 24,
                   'subratings': {'Academic Progress Rating': 7,
                                  'Equity Overview Rating': 10,
                                  'Test Scores Rating': 10},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '100 Civic Plaza',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 18818,
                   'lat': 37.704388,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/18818-Shannon-Center-Pre-School/#College_success',
                             'profile': '/california/dublin/18818-Shannon-Center-Pre-School/',
                             'reviews': '/california/dublin/18818-Shannon-Center-Pre-School/#Reviews'},
                   'lon': -121.916168,
                   'name': 'Shannon Center Pre-School',
                   'numReviews': 1,
                   'parentRating': 4,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '11925 Amador Valley Boulevard',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 48,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                     {'label': 'White', 'percentage': 50},
                                     {'label': 'Asian or Pacific Islander',
                                      'percentage': 25},
                                     {'label': 'Hispanic', 'percentage': 17},
                                     {'label': 'African American', 'percentage': 8}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 18819,
                   'lat': 37.705753,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/18819-Kindercare-Learning-Center/#College_success',
                             'profile': '/california/dublin/18819-Kindercare-Learning-Center/',
                             'reviews': '/california/dublin/18819-Kindercare-Learning-Center/#Reviews'},
                   'lon': -121.936356,
                   'name': 'Kindercare Learning Center',
                   'numReviews': 1,
                   'parentRating': 5,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7197 Amador Valley Boulevard',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 18821,
                   'lat': 37.709999,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/18821-My-Space-To-Grow/#College_success',
                             'profile': '/california/dublin/18821-My-Space-To-Grow/',
                             'reviews': '/california/dublin/18821-My-Space-To-Grow/#Reviews'},
                   'lon': -121.926514,
                   'name': 'My Space To Grow',
                   'numReviews': 6,
                   'parentRating': 5,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7421 Amarillo Road',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 18822,
                   'lat': 37.705002,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/18822-John-Knox-Co-Op-Pre-School/#College_success',
                             'profile': '/california/dublin/18822-John-Knox-Co-Op-Pre-School/',
                             'reviews': '/california/dublin/18822-John-Knox-Co-Op-Pre-School/#Reviews'},
                   'lon': -121.941933,
                   'name': 'John Knox Co-Op Pre-School',
                   'numReviews': 1,
                   'parentRating': 5,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7421 Amarillo Road',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 18823,
                   'lat': 37.705002,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/18823-Joy-Pre-School-And-Day-Care-Center/#College_success',
                       'profile': '/california/dublin/18823-Joy-Pre-School-And-Day-Care-Center/',
                       'reviews': '/california/dublin/18823-Joy-Pre-School-And-Day-Care-Center/#Reviews'},
                   'lon': -121.941933,
                   'name': 'Joy Pre-School And Day Care Center',
                   'numReviews': 3,
                   'parentRating': 5,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': 'Po Box 2388',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 18824,
                   'lat': 37.702152,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/18824-Seed2sprout-Learning-Center/#College_success',
                             'profile': '/california/dublin/18824-Seed2sprout-Learning-Center/',
                             'reviews': '/california/dublin/18824-Seed2sprout-Learning-Center/#Reviews'},
                   'lon': -121.935791,
                   'name': 'Seed2sprout Learning Center',
                   'numReviews': 1,
                   'parentRating': 4,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7557 Amador Valley Boulevard',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 18825,
                   'lat': 37.709705,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/18825-Resurrection-Luth-Child-Care/#College_success',
                             'profile': '/california/dublin/18825-Resurrection-Luth-Child-Care/',
                             'reviews': '/california/dublin/18825-Resurrection-Luth-Child-Care/#Reviews'},
                   'lon': -121.929909,
                   'name': 'Resurrection Luth Child Care',
                   'numReviews': 12,
                   'parentRating': 4,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7890 Oxbow Lane',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 18826,
                   'lat': 37.708611,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/18826-Tots-University/#College_success',
                             'profile': '/california/dublin/18826-Tots-University/',
                             'reviews': '/california/dublin/18826-Tots-University/#Reviews'},
                   'lon': -121.935867,
                   'name': 'Tots University',
                   'numReviews': 1,
                   'parentRating': 5,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '8010 Holanda St',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 18827,
                   'lat': 37.714695,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/18827-Love-And-Care-Chrn-Pre-School/#College_success',
                       'profile': '/california/dublin/18827-Love-And-Care-Chrn-Pre-School/',
                       'reviews': '/california/dublin/18827-Love-And-Care-Chrn-Pre-School/#Reviews'},
                   'lon': -121.937386,
                   'name': 'Love and Care Chrn Pre-School',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '8435 Davona Drive',
                               'street2': '',
                               'zip': '94583'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 18828,
                   'lat': 37.721619,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/18828-Extended-Day-Child-Care-Center/#College_success',
                       'profile': '/california/dublin/18828-Extended-Day-Child-Care-Center/',
                       'reviews': '/california/dublin/18828-Extended-Day-Child-Care-Center/#Reviews'},
                   'lon': -121.932816,
                   'name': 'Extended Day Child Care Center',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '6665 Amador Plaza Road #200',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 35,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0},
                                     {'label': 'White', 'percentage': 67},
                                     {'label': 'Asian', 'percentage': 33}],
                   'gradeLevels': 'PK-K',
                   'highlighted': false,
                   'id': 21304,
                   'lat': 37.701843,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/21304-Fountainhead-Montessori/#College_success',
                             'profile': '/california/dublin/21304-Fountainhead-Montessori/',
                             'reviews': '/california/dublin/21304-Fountainhead-Montessori/#Reviews'},
                   'lon': -121.925484,
                   'name': 'Fountainhead Montessori',
                   'numReviews': 3,
                   'parentRating': 4,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '11900 Silvergate Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK-1',
                   'highlighted': false,
                   'id': 22630,
                   'lat': 37.709286,
                   'levelCode': 'p,e',
                   'links': {
                       'collegeSuccess': '/california/dublin/22630-Learn-And-Play-Montessori-School/#College_success',
                       'profile': '/california/dublin/22630-Learn-And-Play-Montessori-School/',
                       'reviews': '/california/dublin/22630-Learn-And-Play-Montessori-School/#Reviews'},
                   'lon': -121.939171,
                   'name': 'Learn And Play Montessori School',
                   'numReviews': 3,
                   'parentRating': 5,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '3150 Palermo Way',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': 'Dublin',
                   'districtId': 6,
                   'districtName': 'Dublin Unified School District',
                   'enrollment': 735,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 4},
                                     {'label': 'All students', 'rating': 10},
                                     {'label': 'Asian', 'percentage': 67, 'rating': 10},
                                     {'label': 'White', 'percentage': 13, 'rating': 10},
                                     {'label': 'Two or more races',
                                      'percentage': 5,
                                      'rating': 10},
                                     {'label': 'Filipino', 'percentage': 7},
                                     {'label': 'Hispanic', 'percentage': 4},
                                     {'label': 'African American', 'percentage': 2},
                                     {'label': 'American Indian/Alaska Native',
                                      'percentage': 1}],
                   'gradeLevels': 'K-5',
                   'highlighted': false,
                   'id': 25805,
                   'lat': 37.711929,
                   'levelCode': 'e',
                   'links': {'collegeSuccess': '/california/dublin/25805-Harold-William-Kolb/#College_success',
                             'profile': '/california/dublin/25805-Harold-William-Kolb/',
                             'reviews': '/california/dublin/25805-Harold-William-Kolb/#Reviews'},
                   'lon': -121.858704,
                   'name': 'Harold William Kolb',
                   'numReviews': 43,
                   'parentRating': 4,
                   'percentLowIncome': '4%',
                   'pinned': null,
                   'rating': 9,
                   'ratingScale': 'Above average',
                   'remediationData': [],
                   'schoolType': 'public',
                   'state': 'CA',
                   'studentsPerTeacher': 23,
                   'subratings': {'Academic Progress Rating': 8, 'Test Scores Rating': 10},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7260 San Ramon Road',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 26928,
                   'lat': 37.704239,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/26928-Beyond-Montessori/#College_success',
                             'profile': '/california/dublin/26928-Beyond-Montessori/',
                             'reviews': '/california/dublin/26928-Beyond-Montessori/#Reviews'},
                   'lon': -121.936516,
                   'name': 'Beyond Montessori',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7997 Vomac Road',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 27610,
                   'lat': 37.714924,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/27610-Extended-Day-Care-Dublin/#College_success',
                             'profile': '/california/dublin/27610-Extended-Day-Care-Dublin/',
                             'reviews': '/california/dublin/27610-Extended-Day-Care-Dublin/#Reviews'},
                   'lon': -121.936707,
                   'name': 'Extended Day Care-Dublin',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '7425 Larkdale Avenue',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 27795,
                   'lat': 37.716522,
                   'levelCode': 'p',
                   'links': {'collegeSuccess': '/california/dublin/27795-Easter-Seals-Kaleidoscope/#College_success',
                             'profile': '/california/dublin/27795-Easter-Seals-Kaleidoscope/',
                             'reviews': '/california/dublin/27795-Easter-Seals-Kaleidoscope/#Reviews'},
                   'lon': -121.930328,
                   'name': 'Easter Seals Kaleidoscope',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'},
                  {'address': {'city': 'Dublin',
                               'street1': '5301 Hibernia Drive',
                               'street2': '',
                               'zip': '94568'},
                   'assigned': null,
                   'collegeEnrollmentData': {},
                   'collegePersistentData': [],
                   'csaAwardYears': [],
                   'districtCity': null,
                   'districtId': 0,
                   'districtName': null,
                   'enrollment': 0,
                   'ethnicityInfo': [{'label': 'Low-income', 'percentage': 0}],
                   'gradeLevels': 'PK',
                   'highlighted': false,
                   'id': 27850,
                   'lat': 37.708721,
                   'levelCode': 'p',
                   'links': {
                       'collegeSuccess': '/california/dublin/27850-Extended-Day-Child-Care-Center/#College_success',
                       'profile': '/california/dublin/27850-Extended-Day-Child-Care-Center/',
                       'reviews': '/california/dublin/27850-Extended-Day-Child-Care-Center/#Reviews'},
                   'lon': -121.885185,
                   'name': 'Extended Day Child Care Center',
                   'numReviews': 0,
                   'parentRating': 0,
                   'percentLowIncome': '?',
                   'pinned': null,
                   'rating': null,
                   'ratingScale': null,
                   'remediationData': [],
                   'schoolType': 'private',
                   'state': 'CA',
                   'subratings': {},
                   'testScoreRatingForEthnicity': null,
                   'type': 'school'}]
