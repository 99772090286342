exports.LOS_GATOS_LISTINGS = [
		{
			"closePrice": 725000,
			"daysOnMovoto": 129,
			"id": "5b81cd4a-9fe2-4169-8546-e2404218bfc0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861420_0_fjjIna_p.jpeg",
			"listDate": "2021-09-06 00:00:00",
			"listingAgent": "Ed Dee",
			"listPrice": 795000,
			"lotSize": 481077,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861420",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Sanctuary Real Estate",
			"photoCount": 8,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/00000-highland-way-los-gatos-ca-95033-100_ml81861420/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2160ebef-fe3b-46f2-a5c2-3f726f34f0ab",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-09-06 21:12:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-06 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/2907511",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"zipcode": "95033",
				"subPremise": "",
				"address": "00000 Highland Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2907511"
		},
		{
			"closePrice": 855000,
			"daysOnMovoto": 251,
			"id": "6e472d31-b617-4746-b316-82b4ffae7e19",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81842196_0_QBViii_p.jpeg",
			"listDate": "2021-05-04 16:58:37",
			"listingAgent": "Edgar Meneses",
			"listPrice": 980000,
			"lotSize": 10528,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81842196",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Exit Realty Keystone",
			"photoCount": 21,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95032",
			"path": "los-gatos-ca/14329-mulberry-dr-los-gatos-ca-95032-100_80622603/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -210000,
			"propertyId": "2d052722-499b-48eb-8095-165580fb4925",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-11 00:00:00",
			"createdAt": "2021-05-05 10:32:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-04 16:58:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.260482,
				"lng": -121.975545,
				"zipcode": "95032",
				"subPremise": "",
				"address": "14329 Mulberry Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 855000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2375000,
			"daysOnMovoto": 42,
			"id": "82da4960-ddd9-4950-af2c-6e568c83f087",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871005_0_3IyRv2_p.jpeg",
			"listDate": "2021-11-24 10:17:03",
			"listingAgent": "Annie F Baker",
			"listPrice": 2300000,
			"lotSize": 8538,
			"sqftTotal": 3681,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871005",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Alta Realty Group CA",
			"photoCount": 44,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "95032",
			"path": "los-gatos-ca/920-castlewood-dr-los-gatos-ca-95032/pid_738r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "89b7182e-3095-4561-bc59-a6d0fc3ae860",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-06 00:00:00",
			"createdAt": "2021-11-24 18:22:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-24 10:17:03",
			"virtualTourLink": "https://player.vimeo.com/video/649621114",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.262527,
				"lng": -121.972505,
				"zipcode": "95032",
				"subPremise": "",
				"address": "920 Castlewood Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2375000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/649621114"
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 49,
			"id": "0f0a08cb-a031-4079-86fa-ceb1c35bee0f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81869463_0_R72INq_p.jpeg",
			"listDate": "2021-11-16 00:00:00",
			"listingAgent": "Jeff Eisenbaum",
			"listPrice": 2449000,
			"lotSize": 9971,
			"sqftTotal": 2632,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81869463",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 45,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1966,
			"zipCode": "95032",
			"path": "los-gatos-ca/244-mary-alice-dr-los-gatos-ca-95032/pid_y3n02vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "149b15df-f272-4676-8821-2b553f929dbc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-04 00:00:00",
			"createdAt": "2021-11-16 19:52:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-16 00:00:00",
			"virtualTourLink": "http://www.304carltonave.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.246901,
				"lng": -121.952358,
				"zipcode": "95032",
				"subPremise": "",
				"address": "244 Mary Alice Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://www.304carltonave.com/"
		},
		{
			"closePrice": 2050000,
			"daysOnMovoto": 29,
			"id": "37a1d6d0-5e2e-49fa-ba06-df04bccb5f63",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871266_0_qqANnz_p.jpeg",
			"listDate": "2021-11-30 10:18:07",
			"listingAgent": "John Faylor",
			"listPrice": 1899000,
			"lotSize": 4060,
			"sqftTotal": 2416,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871266",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 21,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1967,
			"zipCode": "95032",
			"path": "los-gatos-ca/114-dardanelli-ln-los-gatos-ca-95032-202_ml81403597/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f82362ea-a6dd-4fed-8588-bc5ec4f844fb",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-30 00:00:00",
			"createdAt": "2021-11-30 18:22:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-11-30 10:18:07",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.261208,
				"lng": -121.967307,
				"zipcode": "95032",
				"subPremise": "",
				"address": "114 Dardanelli Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2725000,
			"daysOnMovoto": 52,
			"id": "2a6f204c-5b81-412a-91d6-bb3324fee0d9",
			"tnImgPath": "https://pi.movoto.com/p/12/40970565_0_u36e2j_p.jpeg",
			"listDate": "2021-10-12 00:00:00",
			"listingAgent": "Ziad Zeid",
			"listPrice": 2699000,
			"lotSize": 7280,
			"sqftTotal": 2709,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40970565",
			"bath": 2.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Century 21 MM",
			"photoCount": 39,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1946,
			"zipCode": "95032",
			"path": "los-gatos-ca/15700-loma-vista-ave-los-gatos-ca-95032-100_81464571/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6cd1451d-8f39-43b6-b255-ad893c5c9ca1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2021-10-13 03:56:20",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 00:00:00",
			"virtualTourLink": "https://www.zillow.com/view-3d-home/20e4033a-6e30-4411-8512-d805fc93b152?setAttribution=mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.240477,
				"lng": -121.950022,
				"zipcode": "95032",
				"subPremise": "",
				"address": "15700 Loma Vista Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2725000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.zillow.com/view-3d-home/20e4033a-6e30-4411-8512-d805fc93b152?setAttribution=mls"
		},
		{
			"closePrice": 650000,
			"daysOnMovoto": 1649,
			"id": "580198d5-bc4e-414d-b590-5f3723e3e547",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81653498_0_Yav77Q_p.jpeg",
			"listDate": "2017-05-28 17:13:04",
			"listingAgent": "Matthew Bowen",
			"listPrice": 700000,
			"lotSize": 25091,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81653498",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Real Estate Solutions",
			"photoCount": 16,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95030",
			"path": "los-gatos-ca/00-beckwith-rd-los-gatos-ca-95030-100_ml81653498/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 50000,
			"propertyId": "e942126e-b049-4aee-98b3-59c923dd7d63",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-12-03 00:00:00",
			"createdAt": "2017-05-29 00:22:19",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2017-05-28 17:13:04",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"zipcode": "95030",
				"subPremise": "",
				"address": "00 Beckwith Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1750000,
			"daysOnMovoto": 0,
			"id": "e4d43587-f09d-4140-84dd-2ba9d7dddcaa",
			"tnImgPath": "https://pi.movoto.com/p/102/221110517_0_ynrjAu_p.jpeg",
			"listDate": null,
			"listingAgent": "Jared English",
			"listPrice": 1695000,
			"lotSize": 2222,
			"sqftTotal": 2302,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221110517",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Jared English, Broker",
			"photoCount": 25,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1977,
			"zipCode": "95032",
			"path": "los-gatos-ca/110-lancewood-pl-los-gatos-ca-95032-100_80764197/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "7ece1ec6-8bbf-477d-a61c-40cf2b553851",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-17 00:00:00",
			"createdAt": "2021-08-31 21:59:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-31 21:59:29",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.258005,
				"lng": -121.970152,
				"zipcode": "95032",
				"subPremise": "",
				"address": "110 Lancewood Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 91,
			"id": "7e1fd2ef-2aec-4f88-a10e-085ecbb66b1e",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858011_0_BuVIjn_p.jpeg",
			"listDate": "2021-08-12 10:21:30",
			"listingAgent": "Mitchell Zurich",
			"listPrice": 2300000,
			"lotSize": 8281,
			"sqftTotal": 3681,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858011",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Marcus & Millichap",
			"photoCount": 7,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1963,
			"zipCode": "95032",
			"path": "los-gatos-ca/904-teakwood-ct-los-gatos-ca-95032/pid_i58r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "60351cbc-d403-463f-b6d3-ebff5c17a5e0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-12 00:00:00",
			"createdAt": "2021-08-12 17:27:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-12 10:21:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.261952,
				"lng": -121.972417,
				"zipcode": "95032",
				"subPremise": "",
				"address": "904 Teakwood Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2700000,
			"daysOnMovoto": 33,
			"id": "2c206b0d-ebb9-48f2-a81a-3284d0c80295",
			"tnImgPath": "https://pi.movoto.com/p/12/40969995_0_zBMUEZ_p.jpeg",
			"listDate": "2021-10-07 00:00:00",
			"listingAgent": "Albert Garibaldi",
			"listPrice": 2700950,
			"lotSize": 10275,
			"sqftTotal": 1089,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40969995",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "The Agency",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "95032",
			"path": "los-gatos-ca/16635-marchmont-dr-los-gatos-ca-95032/pid_vbdu2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 1000,
			"propertyId": "5b06f7d9-e3b1-4890-a601-0e0f42228308",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-09 00:00:00",
			"createdAt": "2021-10-07 16:01:49",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-07 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.227939,
				"lng": -121.960887,
				"zipcode": "95032",
				"subPremise": "",
				"address": "16635 Marchmont Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2050000,
			"daysOnMovoto": 40,
			"id": "0b1b97bb-d8df-4253-8dc7-1b87207a9b82",
			"tnImgPath": "https://pi.movoto.com/p/12/40968473_0_qmFNFA_p.jpeg",
			"listDate": "2021-09-24 00:00:00",
			"listingAgent": "Adel Shahal",
			"listPrice": 2200000,
			"lotSize": 45467,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40968473",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "People's Choice Brokers",
			"photoCount": 7,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Lots / Land",
			"yearBuilt": null,
			"zipCode": "95032",
			"path": "los-gatos-ca/15415-santella-dr-los-gatos-ca-95032-12_40968473/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "47792c08-d597-4c6b-87b3-14ccc781ea0a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-11-03 00:00:00",
			"createdAt": "2021-09-24 17:11:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-24 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/idxr2914618",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.229711,
				"lng": -121.938032,
				"zipcode": "95032",
				"subPremise": "",
				"address": "15415 Santella Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2050000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/idxr2914618"
		},
		{
			"closePrice": 100000,
			"daysOnMovoto": 70,
			"id": "46916fd9-10a7-48b7-8a11-7cb235699254",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859240_0_IFAur3_p.jpeg",
			"listDate": "2021-08-19 20:45:37",
			"listingAgent": "Brian Beck",
			"listPrice": 100000,
			"lotSize": 491792,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859240",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "John R. Motter Realty",
			"photoCount": 14,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/32000-loma-chiquita-rd-los-gatos-ca-95033-100_ml81859240/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "86fbbd59-c32f-4aea-8397-87b3e9a0ca5d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2021-08-20 04:01:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-19 20:45:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.101509,
				"lng": -121.819177,
				"zipcode": "95033",
				"subPremise": "",
				"address": "32000 Loma Chiquita Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 248000,
			"daysOnMovoto": 352,
			"id": "156401df-2a36-442e-b222-9dd88687b331",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81819775_0_zNvE22_p.jpeg",
			"listDate": "2020-11-10 15:47:26",
			"listingAgent": "Ty Ataei",
			"listPrice": 248000,
			"lotSize": 80508,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81819775",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Realty One Group Infinity",
			"photoCount": 5,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/-mountain-charlie-road-los-gatos-ca-95033-100_ml81819775/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "51f85dde-4a6e-440a-a703-aff6d0f0e2e6",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-29 00:00:00",
			"createdAt": "2020-11-10 23:52:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-11-10 15:47:26",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"zipcode": "95033",
				"subPremise": "",
				"address": "- Mountain Charlie Road"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 248000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1450000,
			"daysOnMovoto": 12,
			"id": "cf9239fa-8b4b-4664-9138-4f3261a5b912",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81866418_0_uryBey_p.jpeg",
			"listDate": "2021-10-12 07:56:36",
			"listingAgent": "Alexander Nichols",
			"listPrice": 1298000,
			"lotSize": 8586,
			"sqftTotal": 2126,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81866418",
			"bath": null,
			"bed": 5,
			"openHouses": [],
			"officeListName": "eXp Realty of California Inc.",
			"photoCount": 29,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1965,
			"zipCode": "95032",
			"path": "los-gatos-ca/644-vasona-ave-los-gatos-ca-95032/pid_w6e02vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "31141632-464c-4bf5-af96-6621c29a55cf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-25 00:00:00",
			"createdAt": "2021-10-12 15:02:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-10-12 07:56:36",
			"virtualTourLink": "https://my.matterport.com/show/?m=EqPcDNz3Yiv&mls=1",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.259907,
				"lng": -121.967168,
				"zipcode": "95032",
				"subPremise": "",
				"address": "644 Vasona Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://my.matterport.com/show/?m=EqPcDNz3Yiv&mls=1"
		},
		{
			"closePrice": 59000,
			"daysOnMovoto": 66,
			"id": "9299c15a-8061-4bf8-8e5e-e2579ffc8fa2",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81856331_0_zujZAz_p.jpeg",
			"listDate": "2021-07-31 14:50:03",
			"listingAgent": "Heidi Robinson",
			"listPrice": 65000,
			"lotSize": 9971,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81856331",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Thunderbird Real Estate",
			"photoCount": 13,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/21444-roaring-water-way-los-gatos-ca-95033-100_ml81856331/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f5d13171-ee8e-4a54-81e1-e1ecf11d8a78",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-06 00:00:00",
			"createdAt": "2021-07-31 21:52:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-31 14:50:03",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.159188,
				"lng": -121.970757,
				"zipcode": "95033",
				"subPremise": "",
				"address": "21444 Roaring Water Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 59000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1850000,
			"daysOnMovoto": 24,
			"id": "b3c7f4d3-00bf-461b-aa41-07a26a8ab5ce",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81861906_0_umEvzz_p.jpeg",
			"listDate": "2021-09-09 11:03:18",
			"listingAgent": "Brian Schwatka",
			"listPrice": 1749000,
			"lotSize": 8002,
			"sqftTotal": 1705,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81861906",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "KW Bay Area Estates",
			"photoCount": 1,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1962,
			"zipCode": "95032",
			"path": "los-gatos-ca/232-danville-dr-los-gatos-ca-95032/pid_4r5t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "79b88210-b3d5-480f-92d3-061620e3500a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-04 00:00:00",
			"createdAt": "2021-09-09 18:07:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-09-09 11:03:18",
			"virtualTourLink": "https://www.tourfactory.com/2909196",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.238763,
				"lng": -121.933934,
				"zipcode": "95032",
				"subPremise": "",
				"address": "232 Danville Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1850000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2909196"
		},
		{
			"closePrice": 2275000,
			"daysOnMovoto": 91,
			"id": "54bf8bf4-c1f9-4658-932e-0550893b8571",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81849007_0_Aayue6_p.jpeg",
			"listDate": "2021-07-02 00:00:00",
			"listingAgent": "Mark Miano",
			"listPrice": 2999000,
			"lotSize": 13098,
			"sqftTotal": 2549,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81849007",
			"bath": null,
			"bed": 6,
			"openHouses": [],
			"officeListName": "KW Bay Area Estates",
			"photoCount": 46,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1899,
			"zipCode": "95032",
			"path": "los-gatos-ca/9-forrest-ave-los-gatos-ca-95032/pid_y99t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "a87706ab-f3fe-4c5b-be6c-4b7a4a2d1573",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-01 00:00:00",
			"createdAt": "2021-07-02 17:52:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-02 00:00:00",
			"virtualTourLink": "https://www.tourfactory.com/2887084",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.23359,
				"lng": -121.97591,
				"zipcode": "95032",
				"subPremise": "",
				"address": "9 Forrest Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2275000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.tourfactory.com/2887084"
		},
		{
			"closePrice": 2425000,
			"daysOnMovoto": 30,
			"id": "7fb734e7-c0f5-4955-9d87-0f66a5bd6ab0",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81860555_0_6aeAYf_p.jpeg",
			"listDate": "2021-08-30 12:13:13",
			"listingAgent": "Charles Holcomb",
			"listPrice": 2425000,
			"lotSize": 8189,
			"sqftTotal": 3224,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81860555",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 15,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1958,
			"zipCode": "95032",
			"path": "los-gatos-ca/152-carlton-ave-los-gatos-ca-95032/pid_2des2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "bf2fe8e7-3298-4f5c-b275-71aa7b712d12",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-30 00:00:00",
			"createdAt": "2021-08-30 19:17:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-30 12:13:13",
			"virtualTourLink": "http://152carlton.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.243952,
				"lng": -121.957421,
				"zipcode": "95032",
				"subPremise": "",
				"address": "152 Carlton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "http://152carlton.com/"
		},
		{
			"closePrice": 400000,
			"daysOnMovoto": 246,
			"id": "75d7f9f3-cc97-4d09-93b3-57c45a9e806c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81826997_0_7nErUU_p.jpeg",
			"listDate": "2021-01-23 15:21:02",
			"listingAgent": "MaryBeth McLaughlin",
			"listPrice": 425000,
			"lotSize": 469011,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81826997",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Room Real Estate",
			"photoCount": 20,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/250-robinridge-ln-los-gatos-ca-95033-202_ml81547150/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b4fd846f-b2be-404a-9347-034a4f2ec448",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-27 00:00:00",
			"createdAt": "2021-01-23 23:27:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-23 15:21:02",
			"virtualTourLink": "https://www.santacruzmountainrealestate.com/properties/250robinridge/250_robinridge_branded.mp4",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.091106,
				"lng": -121.945401,
				"zipcode": "95033",
				"subPremise": "",
				"address": "250 Robinridge Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.santacruzmountainrealestate.com/properties/250robinridge/250_robinridge_branded.mp4"
		},
		{
			"closePrice": 600000,
			"daysOnMovoto": 142,
			"id": "09001ffb-d62c-4e19-8710-e8cf9a471587",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81840797_0_YFqYbY_p.jpeg",
			"listDate": "2021-04-26 09:08:07",
			"listingAgent": "Carol Payne",
			"listPrice": 599000,
			"lotSize": 1733008,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81840797",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 10,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/33001-loma-chiquita-rd-los-gatos-ca-95033-100_ml81840797/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3c266065-b065-473e-82a1-dad238e821db",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-16 00:00:00",
			"createdAt": "2021-04-26 16:12:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-26 09:08:07",
			"virtualTourLink": "https://tours.tourfactory.com/tours/tour.asp?t=2858746",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.105613,
				"lng": -121.825609,
				"zipcode": "95033",
				"subPremise": "",
				"address": "33001 Loma Chiquita Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 600000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://tours.tourfactory.com/tours/tour.asp?t=2858746"
		},
		{
			"closePrice": 250000,
			"daysOnMovoto": 19,
			"id": "14364d43-7d91-462e-b3ae-08cf990bed20",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81859285_0_R3VIyu_p.jpeg",
			"listDate": "2021-08-20 09:06:14",
			"listingAgent": "TSE Group",
			"listPrice": 250000,
			"lotSize": 38834,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81859285",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 12,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/18402-montevina-rd-los-gatos-ca-95033/pid_2xbu2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9da52f2b-4345-475c-a4d5-b79baf6ba9be",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-09 00:00:00",
			"createdAt": "2021-08-20 16:12:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-20 09:06:14",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.197947,
				"lng": -121.995721,
				"zipcode": "95033",
				"subPremise": "",
				"address": "18402 Montevina Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2100000,
			"daysOnMovoto": 173,
			"id": "0529aef3-8b7e-4068-b25e-1b863c3c772b",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81835005_0_iBnrmi_p.jpeg",
			"listDate": "2021-03-19 14:33:25",
			"listingAgent": "Janet Simas",
			"listPrice": 1999000,
			"lotSize": 10886,
			"sqftTotal": 2575,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81835005",
			"bath": null,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Spirit Properties",
			"photoCount": 23,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Multi Family",
			"yearBuilt": 1964,
			"zipCode": "95032",
			"path": "los-gatos-ca/295-penn-way-los-gatos-ca-95032/pid_v2n02vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -999,
			"propertyId": "98ca01b8-a549-40b3-afac-d13601f7c122",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-09 00:00:00",
			"createdAt": "2021-03-19 21:37:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-19 14:33:25",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.247595,
				"lng": -121.952605,
				"zipcode": "95032",
				"subPremise": "",
				"address": "295 Penn Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1900000,
			"daysOnMovoto": 150,
			"id": "a792fb31-bc5c-40f1-a2ba-3f2486128983",
			"tnImgPath": "https://pi.movoto.com/p/12/40945040_0_VYrMi2_p.jpeg",
			"listDate": "2021-04-11 00:00:00",
			"listingAgent": "Steven Leung",
			"listPrice": 1899000,
			"lotSize": 22701,
			"sqftTotal": 2016,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40945040",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Outstanding Investment Co.",
			"photoCount": 11,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1945,
			"zipCode": "95032",
			"path": "los-gatos-ca/15876-shannon-rd-los-gatos-ca-95032-100_81438334/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -51000,
			"propertyId": "fc44b0e0-57d1-43ff-b5ac-e4d12bf3cb71",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-08 00:00:00",
			"createdAt": "2021-04-11 05:21:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-11 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.226997,
				"lng": -121.947314,
				"zipcode": "95032",
				"subPremise": "",
				"address": "15876 Shannon Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1170000,
			"daysOnMovoto": 141,
			"id": "f078de47-426e-4372-a2c8-1d580dfbb3da",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81836464_0_ney7NF_p.jpeg",
			"listDate": "2021-04-15 00:00:00",
			"listingAgent": "Nevis and Ardizzone",
			"listPrice": 1500000,
			"lotSize": 447318,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81836464",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 11,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95030",
			"path": "los-gatos-ca/15435-blackberry-hill-rd-los-gatos-ca-95030-100_81343236/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -300000,
			"propertyId": "0570ef58-3325-4b1c-9675-0aada0235840",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-09-03 00:00:00",
			"createdAt": "2021-04-15 18:57:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-15 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.208319,
				"lng": -121.955701,
				"zipcode": "95030",
				"subPremise": "",
				"address": "15435 Blackberry Hill Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1170000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1367000,
			"daysOnMovoto": 17,
			"id": "318f3c4e-323c-4225-9b71-83c8286f1f8c",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81858045_0_Vz76VQ_p.jpeg",
			"listDate": "2021-08-14 00:00:00",
			"listingAgent": "William Lister",
			"listPrice": 1489000,
			"lotSize": 2614,
			"sqftTotal": 764,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81858045",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 27,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1918,
			"zipCode": "95030",
			"path": "los-gatos-ca/310-tait-ave-los-gatos-ca-95030-100_80319813/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "150ab070-08b0-4015-a438-a2fbe51dce28",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-31 00:00:00",
			"createdAt": "2021-08-14 19:32:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-08-14 00:00:00",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.228607,
				"lng": -121.983227,
				"zipcode": "95030",
				"subPremise": "",
				"address": "310 Tait Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1367000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 3700000,
			"daysOnMovoto": 0,
			"id": "849a73aa-3e65-42ad-b2c7-3abf10ccfc4e",
			"tnImgPath": "https://pi.movoto.com/p/102/221087787_0_EaMQiA_p.jpeg",
			"listDate": null,
			"listingAgent": "Sanja Finister",
			"listPrice": 3500000,
			"lotSize": 9378,
			"sqftTotal": 2884,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221087787",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Regal Realty",
			"photoCount": 29,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1951,
			"zipCode": "95032",
			"path": "los-gatos-ca/15572-corinne-dr-los-gatos-ca-95032-100_81305436/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8a23f286-91cb-4ef9-bbd4-3c844e5d6644",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-27 00:00:00",
			"createdAt": "2021-07-20 17:14:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-07-20 17:14:26",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.242298,
				"lng": -121.962228,
				"zipcode": "95032",
				"subPremise": "",
				"address": "15572 Corinne Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1825000,
			"daysOnMovoto": 351,
			"id": "30ae34f2-1ded-4e7c-b7a2-86f6ba5faf80",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81809449_0_NveFEU_p.jpeg",
			"listDate": "2020-09-05 09:13:30",
			"listingAgent": "Brett Jennings Group",
			"listPrice": 1999000,
			"lotSize": 116606,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81809449",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Real Estate Experts",
			"photoCount": 9,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "95032",
			"path": "los-gatos-ca/0-top-of-the-hill-rd-los-gatos-ca-95032-202_ml81517630/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "59a0844f-526c-4ec2-b04f-562fa128235b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-23 00:00:00",
			"createdAt": "2020-09-05 16:17:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-09-05 09:13:30",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"zipcode": "95032",
				"subPremise": "",
				"address": "0 Top Of The Hill Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1825000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 220000,
			"daysOnMovoto": 133,
			"id": "cbd2528d-c8a2-4955-9f5b-6fe6436fafd7",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81835433_0_UVYnqN_p.jpeg",
			"listDate": "2021-03-23 10:18:21",
			"listingAgent": "George Rabe",
			"listPrice": 249950,
			"lotSize": 455638,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81835433",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 1,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Development Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/0-bell-rd-los-gatos-ca-95033-202_ml81329307/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fa6b4720-3813-4b3c-b36c-275521474acd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-04 00:00:00",
			"createdAt": "2021-03-23 17:22:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-23 10:18:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"zipcode": "95033",
				"subPremise": "",
				"address": "0 Bell Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 220000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 199000,
			"daysOnMovoto": 40,
			"id": "4c7c3ebe-0bd0-4c2b-b2d6-7338863f9226",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81850156_0_FNVmqQ_p.jpeg",
			"listDate": "2021-06-22 10:21:43",
			"listingAgent": "Thomas Rollett",
			"listPrice": 199000,
			"lotSize": 55121,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81850156",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 4,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/0-grove-rd-los-gatos-ca-95032-100_ml81706008/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "23ab7436-ad59-4e10-bbb1-db010e9066b9",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-02 00:00:00",
			"createdAt": "2021-06-22 17:27:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-22 10:21:43",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.218669,
				"lng": -121.976257,
				"zipcode": "95033",
				"subPremise": "",
				"address": "0 Grove Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 199000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3250000,
			"daysOnMovoto": 32,
			"id": "99d0ef05-42e1-4ed1-93a7-c6008a3cea12",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81850639_0_ZajfeN_p.jpeg",
			"listDate": "2021-06-24 15:27:03",
			"listingAgent": "Chad West Myers",
			"listPrice": 3198000,
			"lotSize": 14828,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81850639",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Rinu Real Estate",
			"photoCount": 16,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "95032",
			"path": "los-gatos-ca/17045-pine-ave-los-gatos-ca-95032/pid_3j8t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "981dbd49-cf26-4c4f-b213-b12990eda00f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-27 00:00:00",
			"createdAt": "2021-06-24 22:32:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-24 15:27:03",
			"virtualTourLink": "https://www.17045pine.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.229133,
				"lng": -121.96824,
				"zipcode": "95032",
				"subPremise": "",
				"address": "17045 Pine Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.17045pine.com/"
		},
		{
			"closePrice": 2280000,
			"daysOnMovoto": 60,
			"id": "dac7d9f3-54c4-4c73-846e-ebf33b415060",
			"tnImgPath": "https://pi.movoto.com/p/12/40951880_0_aeifiy_p.jpeg",
			"listDate": "2021-05-28 00:00:00",
			"listingAgent": "June Young",
			"listPrice": 2295000,
			"lotSize": 31363,
			"sqftTotal": 1403,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40951880",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker",
			"photoCount": 38,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1947,
			"zipCode": "95032",
			"path": "los-gatos-ca/17220-pine-ave-los-gatos-ca-95032/pid_808t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9434dcf6-25bf-47ed-9c10-31cefe2f10e0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-27 00:00:00",
			"createdAt": "2021-05-28 09:26:16",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-28 00:00:00",
			"virtualTourLink": "Https://www.cbhometour.com/17220-Pine-Avenue-Los-Gatos-CA-95032/mlsindex.html",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.230303,
				"lng": -121.971417,
				"zipcode": "95032",
				"subPremise": "",
				"address": "17220 Pine Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2280000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.cbhometour.com/17220-Pine-Avenue-Los-Gatos-CA-95032/mlsindex.html"
		},
		{
			"closePrice": 1925000,
			"daysOnMovoto": 503,
			"id": "3a283773-44b5-4b4f-bfac-b22245da58db",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81785463_0_qVnBAe_p.jpeg",
			"listDate": "2020-03-05 00:00:00",
			"listingAgent": "Nevis and Ardizzone",
			"listPrice": 2200000,
			"lotSize": 45494,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81785463",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 17,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Development Land",
			"yearBuilt": null,
			"zipCode": "95030",
			"path": "los-gatos-ca/0-hillview-dr-los-gatos-ca-95032-100_ml81720444/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b3566a87-03fa-4c62-9695-fca72bb0845a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-21 00:00:00",
			"createdAt": "2020-03-05 18:52:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-03-05 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"zipcode": "95030",
				"subPremise": "",
				"address": "0 Hillview Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1925000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 354000,
			"daysOnMovoto": 191,
			"id": "1cfdd6bc-4d4d-4930-826d-aecd790c659f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81824470_0_IamrvE_p.jpeg",
			"listDate": "2021-01-04 07:43:21",
			"listingAgent": "Danielle Scott",
			"listPrice": 354000,
			"lotSize": 200855,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81824470",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Donner Land & Homes, Inc.",
			"photoCount": 6,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Development Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/1640-white-rock-rd-los-gatos-ca-95033-100_ml81716284/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "88b2eb93-b027-4f06-8bed-4a909db9cfc5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-15 00:00:00",
			"createdAt": "2021-01-04 15:47:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-04 07:43:21",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.153009,
				"lng": -122.027102,
				"zipcode": "95033",
				"subPremise": "",
				"address": "1640 White Rock Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 354000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 500000,
			"daysOnMovoto": 39,
			"id": "737b111e-d5ae-4901-bdcf-deef195ddefe",
			"tnImgPath": "https://pi.movoto.com/p/12/40951352_0_ZM7fy6_p.jpeg",
			"listDate": "2021-05-24 00:00:00",
			"listingAgent": "Yasmine Austere",
			"listPrice": 450000,
			"lotSize": 248292,
			"sqftTotal": null,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40951352",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "BHHS Drysdale Properties",
			"photoCount": 23,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Lots / Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/25425-radonich-rd-los-gatos-ca-95033-100_ml81759243/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8bdc7e43-b615-4523-b648-324ac3883273",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-02 00:00:00",
			"createdAt": "2021-05-25 00:26:38",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-05-24 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.101536,
				"lng": -121.904826,
				"zipcode": "95033",
				"subPremise": "",
				"address": "25425 Radonich Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 50000,
			"daysOnMovoto": 92,
			"id": "c8ea8900-5dfc-464a-8ba7-51ae3026ca03",
			"tnImgPath": "https://pi.movoto.com/p/110/421534390_0_Ba3vbj_p.jpeg",
			"listDate": "2021-04-01 00:00:00",
			"listingAgent": "Nick Flageollet",
			"listPrice": 225000,
			"lotSize": 195410,
			"sqftTotal": null,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421534390",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Proof Real Estate",
			"photoCount": 7,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95033",
			"path": "los-gatos-ca/0-mountain-charlie-rd-los-gatos-ca-95033-202_ml81416682/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b4ffb24d-37ab-4d0e-bc2d-7f51b3d07875",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-02 00:00:00",
			"createdAt": "2021-04-02 02:06:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"zipcode": "95033",
				"subPremise": "",
				"address": "0 Mountain Charlie Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 50000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1528000,
			"daysOnMovoto": 300,
			"id": "ed34049a-f843-4a20-af0c-34b68a2fe8eb",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81809291_0_ezy6ZB_p.jpeg",
			"listDate": "2020-09-04 12:06:58",
			"listingAgent": "Amy A. McCafferty",
			"listPrice": 1700000,
			"lotSize": 19693,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81809291",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 15,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "95030",
			"path": "los-gatos-ca/26-alpine-ave-los-gatos-ca-95030-100_ml81809291/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": 102000,
			"propertyId": "add195a1-4c58-4272-b3a3-f7e067a67cb7",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-02 00:00:00",
			"createdAt": "2020-09-04 19:12:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-09-04 12:06:58",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.219768,
				"lng": -121.973834,
				"zipcode": "95030",
				"subPremise": "",
				"address": "26 Alpine Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1528000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1350000,
			"daysOnMovoto": 57,
			"id": "ebacebd2-2639-4e48-94ea-fc521ce3838f",
			"tnImgPath": "https://pi.movoto.com/p/12/40946334_0_vbmjRA_p.jpeg",
			"listDate": "2021-04-21 00:00:00",
			"listingAgent": "Silke Stearns",
			"listPrice": 1160000,
			"lotSize": 7968,
			"sqftTotal": 652,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40946334",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Venture Sotheby's Intl Rlty",
			"photoCount": 14,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1900,
			"zipCode": "95032",
			"path": "los-gatos-ca/592-vasona-ave-los-gatos-ca-95032/pid_xk2r2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "557fba3f-28ec-47b7-a430-737d35ab349a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-17 00:00:00",
			"createdAt": "2021-04-22 01:21:27",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-21 00:00:00",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2860935",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.260569,
				"lng": -121.964574,
				"zipcode": "95032",
				"subPremise": "",
				"address": "592 Vasona Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1350000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2860935"
		},
		{
			"closePrice": 3000000,
			"daysOnMovoto": 976,
			"id": "6a3c6efc-965a-45bf-be31-4f7ae4f9984d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81727429_0_QNeM6q_p.jpeg",
			"listDate": "2018-10-12 11:30:15",
			"listingAgent": "Robert Desimpel",
			"listPrice": 3600000,
			"lotSize": 56105,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81727429",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Bay Residential",
			"photoCount": 6,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "95032",
			"path": "los-gatos-ca/0-tourney-loop-los-gatos-ca-95032-202_ml81517065/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0209b5b5-bf17-4c41-a16b-92bd663258d0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-15 00:00:00",
			"createdAt": "2018-10-12 18:37:22",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2018-10-12 11:30:15",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"zipcode": "95032",
				"subPremise": "",
				"address": "0 Tourney Loop"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2450000,
			"daysOnMovoto": 41,
			"id": "b4cc4f80-26d8-49cf-9edc-3407cca8a474",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81839906_0_vzIQRI_p.jpeg",
			"listDate": "2021-04-20 13:43:17",
			"listingAgent": "Bonafede Team",
			"listPrice": 2400000,
			"lotSize": 8320,
			"sqftTotal": 3264,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81839906",
			"bath": null,
			"bed": 8,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 38,
			"propertyType": "MULTI_FAMILY",
			"propertyTypeDisplayName": "Fourplex",
			"yearBuilt": 1958,
			"zipCode": "95032",
			"path": "los-gatos-ca/160-carlton-ave-los-gatos-ca-95032/pid_5des2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "69454cb0-aa9c-493b-8d73-faabe2fbec3c",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-01 00:00:00",
			"createdAt": "2021-04-20 20:47:33",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-20 13:43:17",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2861953",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.244143,
				"lng": -121.957287,
				"zipcode": "95032",
				"subPremise": "",
				"address": "160 Carlton Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2450000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2861953"
		},
		{
			"closePrice": 1325000,
			"daysOnMovoto": 0,
			"id": "acf27145-3cf3-4fa0-ad4a-0283d34bd33b",
			"tnImgPath": "https://pi.movoto.com/p/102/221029405_0_IZFj2Q_p.jpeg",
			"listDate": null,
			"listingAgent": "Russell Ford",
			"listPrice": 1249888,
			"lotSize": 7802,
			"sqftTotal": 980,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "221029405",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Ellington Properties",
			"photoCount": 18,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1956,
			"zipCode": "95032",
			"path": "los-gatos-ca/14335-capri-dr-los-gatos-ca-95032-100_81334370/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "f675ca04-102c-4640-9d03-e19cf55ae39a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-28 00:00:00",
			"createdAt": "2021-04-03 07:14:15",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-03 07:14:15",
			"virtualTourLink": "Http://www.edigitaltours.com/21/03/27476/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.260097,
				"lng": -121.96396,
				"zipcode": "95032",
				"subPremise": "",
				"address": "14335 Capri Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.edigitaltours.com/21/03/27476/"
		},
		{
			"closePrice": 2630000,
			"daysOnMovoto": 34,
			"id": "52ea95e0-6265-4edf-8f1c-af7e499eaff2",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-15 00:00:00",
			"listingAgent": "Alex McCluskey",
			"listPrice": 2595000,
			"lotSize": 132422,
			"sqftTotal": 3981,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81837573",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2006,
			"zipCode": "95033",
			"path": "los-gatos-ca/21851-bear-creek-rd-los-gatos-ca-95033/pid_blmf5si48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fb8fc801-49fa-49c6-a345-0ca9171091ef",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-05-05 06:21:17",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-15 00:00:00",
			"virtualTourLink": "https://player.vimeo.com/video/533352389",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.172103,
				"lng": -122.052405,
				"zipcode": "95033",
				"subPremise": "",
				"address": "21851 Bear Creek Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2630000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/533352389"
		},
		{
			"closePrice": 5500000,
			"daysOnMovoto": 39,
			"id": "8ee16248-8982-472b-b0f1-5d9e0d9178a8",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-10 00:00:00",
			"listingAgent": "Karen McDermott",
			"listPrice": 5800000,
			"lotSize": 19817,
			"sqftTotal": 4570,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81838432",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2017,
			"zipCode": "95030",
			"path": "los-gatos-ca/170-prospect-ave-los-gatos-ca-95030-100_ml81838432/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5f172ecc-eec8-48a9-803d-5e40af16f8a0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-04-23 07:51:13",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-10 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.216999,
				"lng": -121.981345,
				"zipcode": "95030",
				"subPremise": "",
				"address": "170 Prospect Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1480000,
			"daysOnMovoto": 40,
			"id": "a33c5155-732a-45fb-bb7f-dd2838f04019",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-09 00:00:00",
			"listingAgent": "Tom Yore",
			"listPrice": 1399000,
			"lotSize": 45911,
			"sqftTotal": 1735,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81838269",
			"bath": 1,
			"bed": 3,
			"openHouses": [],
			"officeListName": "KW Bay Area Estates Saratoga",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1956,
			"zipCode": "95033",
			"path": "los-gatos-ca/22111-old-santa-cruz-hwy-los-gatos-ca-95033/pid_y7bu2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "5cc58e0f-a6fb-4e95-ae66-e5a279527965",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-04-23 07:53:59",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-09 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2854419",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.150113,
				"lng": -121.975438,
				"zipcode": "95033",
				"subPremise": "",
				"address": "22111 Old Santa Cruz Hwy"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1480000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2854419"
		},
		{
			"closePrice": 2355000,
			"daysOnMovoto": 42,
			"id": "86dc8c14-8f80-48e0-bcf3-6d5f5798d418",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-07 00:00:00",
			"listingAgent": "Tammie Peters",
			"listPrice": 2398000,
			"lotSize": 7874,
			"sqftTotal": 2805,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81836243",
			"bath": 2.5,
			"bed": 6,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1920,
			"zipCode": "95030",
			"path": "los-gatos-ca/51-whitney-ave-los-gatos-ca-95030/pid_lw9t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "2a3e4ce9-013f-42ef-be62-d99a05b8854e",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-05-20 05:01:26",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-07 00:00:00",
			"virtualTourLink": "Https://www.tourfactory.com/idxr2852443",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.221992,
				"lng": -121.971284,
				"zipcode": "95030",
				"subPremise": "",
				"address": "51 Whitney Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2355000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://www.tourfactory.com/idxr2852443"
		},
		{
			"closePrice": 3080000,
			"daysOnMovoto": 49,
			"id": "d08dd679-6372-4cfb-b9c7-ecb44a9938f0",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-03-31 00:00:00",
			"listingAgent": "Derek Essary",
			"listPrice": 2299000,
			"lotSize": 13255,
			"sqftTotal": 1667,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81836754",
			"bath": 2,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Condo to Castle Prop, Inc.",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1930,
			"zipCode": "95030",
			"path": "los-gatos-ca/208-belmont-ave-los-gatos-ca-95030/pid_cy3t2vh48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "0ba3db8e-a365-4826-9938-b3b699a24f64",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-05-07 21:30:43",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-03-31 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.228838,
				"lng": -121.987544,
				"zipcode": "95030",
				"subPremise": "",
				"address": "208 Belmont Ave"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3080000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2200000,
			"daysOnMovoto": 50,
			"id": "c950b2e3-b315-4569-aa20-f59266ac764d",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-03-30 00:00:00",
			"listingAgent": "Carol Camilli",
			"listPrice": 1675000,
			"lotSize": 6300,
			"sqftTotal": 1733,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81836447",
			"bath": 2,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Camilli Homes",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1961,
			"zipCode": "95032",
			"path": "los-gatos-ca/352-penn-way-los-gatos-ca-95032-100_80700376/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "b6ddb612-d5e5-46ce-8be3-4766f009865f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-05-20 05:30:09",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-03-30 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2854089",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.248727,
				"lng": -121.952213,
				"zipcode": "95032",
				"subPremise": "",
				"address": "352 Penn Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2854089"
		},
		{
			"closePrice": 14200000,
			"daysOnMovoto": 71,
			"id": "6af27d85-bedd-4e4f-951f-aa1e10a5558c",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-03-09 00:00:00",
			"listingAgent": "Dana & Cyndi",
			"listPrice": 14500000,
			"lotSize": 87095,
			"sqftTotal": 11616,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81833179",
			"bath": 9,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2001,
			"zipCode": "95030",
			"path": "saratoga-ca/matilija-dr-saratoga-ca-95030-100_80742938/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "73e60518-6440-456a-be6c-4b4cf615f3cf",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-05-20 05:03:12",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-03-09 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.231186,
				"lng": -122.007421,
				"zipcode": "95030",
				"subPremise": "",
				"address": "16331 Matilija Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 14200000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 6150000,
			"daysOnMovoto": 91,
			"id": "c3096811-6245-48b2-83ca-d8d1aa4b6fb7",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-02-16 00:00:00",
			"listingAgent": "Nevis and Ardizzone",
			"listPrice": 5995000,
			"lotSize": 215186,
			"sqftTotal": 5606,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81829941",
			"bath": 6,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 2006,
			"zipCode": "95032",
			"path": "los-gatos-ca/14725-sky-ln-los-gatos-ca-95032-100_80942931/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4ba8052e-9e05-4cea-982e-99370c6c94bc",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-18 00:00:00",
			"createdAt": "2021-04-23 07:56:01",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-02-16 00:00:00",
			"virtualTourLink": "",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.226807,
				"lng": -121.926019,
				"zipcode": "95032",
				"subPremise": "",
				"address": "14725 Sky Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 6150000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": ""
		},
		{
			"closePrice": 5800000,
			"daysOnMovoto": 31,
			"id": "22145103-3046-4f5e-ba22-b841f0fbe0ae",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-04-16 00:00:00",
			"listingAgent": "Carol Jeans",
			"listPrice": 5749000,
			"lotSize": 45325,
			"sqftTotal": 5136,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81839434",
			"bath": 4.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Sereno",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1993,
			"zipCode": "95030",
			"path": "los-gatos-ca/15806-hidden-hill-rd-los-gatos-ca-95030-100_ml81839434/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "81b6e09a-e5c6-483e-a45f-33cbb7874131",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-04-24 02:00:27",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-04-16 00:00:00",
			"virtualTourLink": "Http://15806hiddenhillroad.com/idx",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.238421,
				"lng": -122.009309,
				"zipcode": "95030",
				"subPremise": "",
				"address": "15806 Hidden Hill Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://15806hiddenhillroad.com/idx"
		},
		{
			"closePrice": 1393000,
			"daysOnMovoto": 32,
			"id": "e62a6314-e2bb-4969-b286-5d463ecc6dd8",
			"tnImgPath": "https://pi.movoto.com/p/12/40945526_0_Fjv6Mz_p.jpeg",
			"listDate": "2021-04-15 00:00:00",
			"listingAgent": "Patrick J. Fracisco",
			"listPrice": 1269000,
			"lotSize": 1680,
			"sqftTotal": 1533,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "40945526",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Fracisco Realty & Invest.",
			"photoCount": 27,
			"propertyType": "CONDO",
			"propertyTypeDisplayName": "Townhouse",
			"yearBuilt": 1971,
			"zipCode": "95032",
			"path": "los-gatos-ca/108-birch-wood-ct-los-gatos-ca-95032-100_81419671/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "6351e79e-d00b-4274-8ce2-2fc9ad5d0d35",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-17 00:00:00",
			"createdAt": "2021-04-15 05:31:18",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-15 00:00:00",
			"virtualTourLink": "Https://virtualtourcafe.com/mls/5240445",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Los Gatos",
				"lat": 37.263937,
				"lng": -121.975796,
				"zipcode": "95032",
				"subPremise": "",
				"address": "108 Birch Wood Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1393000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://virtualtourcafe.com/mls/5240445"
		}
	]