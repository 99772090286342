const orlando = {
    "geography": {
       "census_release": "ACS 2019 1-year",
       "parents": {
          "county": {
             "full_name": "Orange County, FL",
             "short_name": "Orange County",
             "sumlevel": "050",
             "land_area": 2335820971,
             "full_geoid": "05000US12095",
             "total_population": 1393452
          },
          "CBSA": {
             "full_name": "Orlando-Kissimmee-Sanford, FL Metro Area",
             "short_name": "Orlando-Kissimmee-Sanford, FL",
             "sumlevel": "310",
             "land_area": 9037795130,
             "full_geoid": "31000US36740",
             "total_population": 2608147
          },
          "state": {
             "full_name": "Florida",
             "short_name": "Florida",
             "sumlevel": "040",
             "land_area": 138947364717,
             "full_geoid": "04000US12",
             "total_population": 21477737
          },
          "nation": {
             "full_name": "United States",
             "short_name": "United States",
             "sumlevel": "010",
             "land_area": 9160971212108,
             "full_geoid": "01000US",
             "total_population": 328239523
          }
       },
       "this": {
          "full_name": "Orlando, FL",
          "short_name": "Orlando",
          "sumlevel": "160",
          "land_area": 286346230,
          "full_geoid": "16000US1253000",
          "total_population": 287435,
          "sumlevel_name": "Place",
          "short_geoid": "1253000"
       },
       "comparatives": [
          "CBSA",
          "state"
       ],
       "census_release_year": "19",
       "census_release_level": "1"
    },
    "demographics": {
       "age": {
          "distribution_by_category": {
             "metadata": {
                "table_id": "B01001",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "percent_under_18": {
                "name": "Under 18",
                "values": {
                   "this": 22.26,
                   "CBSA": 21.67,
                   "state": 19.7
                },
                "error": {
                   "this": 2.05,
                   "CBSA": 0.41,
                   "state": 0.12
                },
                "numerators": {
                   "this": 63989,
                   "CBSA": 565145,
                   "state": 4231955
                },
                "numerator_errors": {
                   "this": 5892.5,
                   "CBSA": 10592.3,
                   "state": 25984.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 113
                },
                "error_ratio": {
                   "this": 9.2,
                   "CBSA": 1.9,
                   "state": 0.6
                }
             },
             "percent_18_to_64": {
                "name": "18 to 64",
                "values": {
                   "this": 67.74,
                   "CBSA": 63.16,
                   "state": 59.35
                },
                "error": {
                   "this": 3.06,
                   "CBSA": 0.69,
                   "state": 0.2
                },
                "numerators": {
                   "this": 194711,
                   "CBSA": 1647205,
                   "state": 1.2747584E7
                },
                "numerator_errors": {
                   "this": 8782.9,
                   "CBSA": 18063.9,
                   "state": 43919.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 114
                },
                "error_ratio": {
                   "this": 4.5,
                   "CBSA": 1.1,
                   "state": 0.3
                }
             },
             "percent_over_65": {
                "name": "65 and over",
                "values": {
                   "this": 10,
                   "CBSA": 15.18,
                   "state": 20.94
                },
                "error": {
                   "this": 1.07,
                   "CBSA": 0.4,
                   "state": 0.14
                },
                "numerators": {
                   "this": 28735,
                   "CBSA": 395797,
                   "state": 4498198
                },
                "numerator_errors": {
                   "this": 3068.9,
                   "CBSA": 10392.6,
                   "state": 30075.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 66,
                   "state": 48
                },
                "error_ratio": {
                   "this": 10.7,
                   "CBSA": 2.6,
                   "state": 0.7
                }
             }
          },
          "distribution_by_decade": {
             "total": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 13.89,
                      "CBSA": 11.75,
                      "state": 10.5
                   },
                   "error": {
                      "this": 1.65,
                      "CBSA": 0.29,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 39936,
                      "CBSA": 306505,
                      "state": 2254796
                   },
                   "numerator_errors": {
                      "this": 4748.7,
                      "CBSA": 7604.1,
                      "state": 18567.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 118,
                      "state": 132
                   },
                   "error_ratio": {
                      "this": 11.9,
                      "CBSA": 2.5,
                      "state": 0.9
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 9.58,
                      "CBSA": 12.47,
                      "state": 11.51
                   },
                   "error": {
                      "this": 1.27,
                      "CBSA": 0.29,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 27524,
                      "CBSA": 325357,
                      "state": 2472056
                   },
                   "numerator_errors": {
                      "this": 3657.7,
                      "CBSA": 7653.9,
                      "state": 19567.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 77,
                      "state": 83
                   },
                   "error_ratio": {
                      "this": 13.3,
                      "CBSA": 2.3,
                      "state": 0.8
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 16.46,
                      "CBSA": 14.47,
                      "state": 12.4
                   },
                   "error": {
                      "this": 1.51,
                      "CBSA": 0.31,
                      "state": 0.1
                   },
                   "numerators": {
                      "this": 47307,
                      "CBSA": 377269,
                      "state": 2662621
                   },
                   "numerator_errors": {
                      "this": 4348.6,
                      "CBSA": 8110.8,
                      "state": 21447.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 114,
                      "state": 133
                   },
                   "error_ratio": {
                      "this": 9.2,
                      "CBSA": 2.1,
                      "state": 0.8
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 20.6,
                      "CBSA": 14.63,
                      "state": 12.63
                   },
                   "error": {
                      "this": 1.78,
                      "CBSA": 0.36,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 59209,
                      "CBSA": 381507,
                      "state": 2712487
                   },
                   "numerator_errors": {
                      "this": 5114.1,
                      "CBSA": 9374.4,
                      "state": 19438.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 141,
                      "state": 163
                   },
                   "error_ratio": {
                      "this": 8.6,
                      "CBSA": 2.5,
                      "state": 0.7
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.9,
                      "CBSA": 13.28,
                      "state": 12.18
                   },
                   "error": {
                      "this": 1.45,
                      "CBSA": 0.33,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 42818,
                      "CBSA": 346378,
                      "state": 2616237
                   },
                   "numerator_errors": {
                      "this": 4161.8,
                      "CBSA": 8600.2,
                      "state": 19314.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 112,
                      "state": 122
                   },
                   "error_ratio": {
                      "this": 9.7,
                      "CBSA": 2.5,
                      "state": 0.7
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 9.19,
                      "CBSA": 12.35,
                      "state": 13.22
                   },
                   "error": {
                      "this": 1.04,
                      "CBSA": 0.26,
                      "state": 0.08
                   },
                   "numerators": {
                      "this": 26429,
                      "CBSA": 322084,
                      "state": 2839001
                   },
                   "numerator_errors": {
                      "this": 2984.7,
                      "CBSA": 6655.4,
                      "state": 17627.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 74,
                      "state": 70
                   },
                   "error_ratio": {
                      "this": 11.3,
                      "CBSA": 2.1,
                      "state": 0.6
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 9.14,
                      "CBSA": 10.82,
                      "state": 12.73
                   },
                   "error": {
                      "this": 1.03,
                      "CBSA": 0.36,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 26277,
                      "CBSA": 282319,
                      "state": 2733738
                   },
                   "numerator_errors": {
                      "this": 2956.1,
                      "CBSA": 9462,
                      "state": 25640.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 84,
                      "state": 72
                   },
                   "error_ratio": {
                      "this": 11.3,
                      "CBSA": 3.3,
                      "state": 0.9
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 4.11,
                      "CBSA": 6.68,
                      "state": 9.49
                   },
                   "error": {
                      "this": 0.67,
                      "CBSA": 0.25,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 11802,
                      "CBSA": 174321,
                      "state": 2038997
                   },
                   "numerator_errors": {
                      "this": 1930.6,
                      "CBSA": 6424.8,
                      "state": 18601.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 62,
                      "state": 43
                   },
                   "error_ratio": {
                      "this": 16.3,
                      "CBSA": 3.7,
                      "state": 0.9
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 2.13,
                      "CBSA": 3.54,
                      "state": 5.34
                   },
                   "error": {
                      "this": 0.44,
                      "CBSA": 0.2,
                      "state": 0.07
                   },
                   "numerators": {
                      "this": 6133,
                      "CBSA": 92407,
                      "state": 1147804
                   },
                   "numerator_errors": {
                      "this": 1261.5,
                      "CBSA": 5232,
                      "state": 15996.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 60,
                      "state": 40
                   },
                   "error_ratio": {
                      "this": 20.7,
                      "CBSA": 5.6,
                      "state": 1.3
                   }
                }
             },
             "male": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 14.99,
                      "CBSA": 12.13,
                      "state": 10.93
                   },
                   "error": {
                      "this": 2.57,
                      "CBSA": 0.46,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 20602,
                      "CBSA": 154853,
                      "state": 1147180
                   },
                   "numerator_errors": {
                      "this": 3623.8,
                      "CBSA": 5851.6,
                      "state": 12616.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 124,
                      "state": 137
                   },
                   "error_ratio": {
                      "this": 17.1,
                      "CBSA": 3.8,
                      "state": 1.1
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 9.09,
                      "CBSA": 13.14,
                      "state": 12.08
                   },
                   "error": {
                      "this": 1.72,
                      "CBSA": 0.46,
                      "state": 0.13
                   },
                   "numerators": {
                      "this": 12502,
                      "CBSA": 167668,
                      "state": 1268023
                   },
                   "numerator_errors": {
                      "this": 2411.9,
                      "CBSA": 5841.9,
                      "state": 13870.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 69,
                      "state": 75
                   },
                   "error_ratio": {
                      "this": 18.9,
                      "CBSA": 3.5,
                      "state": 1.1
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 16.25,
                      "CBSA": 14.86,
                      "state": 12.93
                   },
                   "error": {
                      "this": 1.95,
                      "CBSA": 0.44,
                      "state": 0.14
                   },
                   "numerators": {
                      "this": 22346,
                      "CBSA": 189664,
                      "state": 1357091
                   },
                   "numerator_errors": {
                      "this": 2817,
                      "CBSA": 5681.3,
                      "state": 15179.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 109,
                      "state": 126
                   },
                   "error_ratio": {
                      "this": 12,
                      "CBSA": 3,
                      "state": 1.1
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 21.7,
                      "CBSA": 14.9,
                      "state": 13.11
                   },
                   "error": {
                      "this": 2.35,
                      "CBSA": 0.53,
                      "state": 0.13
                   },
                   "numerators": {
                      "this": 29837,
                      "CBSA": 190095,
                      "state": 1376795
                   },
                   "numerator_errors": {
                      "this": 3419.7,
                      "CBSA": 6744.7,
                      "state": 14019.8
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 146,
                      "state": 166
                   },
                   "error_ratio": {
                      "this": 10.8,
                      "CBSA": 3.6,
                      "state": 1
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 15.1,
                      "CBSA": 13.3,
                      "state": 12.21
                   },
                   "error": {
                      "this": 2.05,
                      "CBSA": 0.46,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 20758,
                      "CBSA": 169743,
                      "state": 1281934
                   },
                   "numerator_errors": {
                      "this": 2928.5,
                      "CBSA": 5858.6,
                      "state": 12628.4
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 114,
                      "state": 124
                   },
                   "error_ratio": {
                      "this": 13.6,
                      "CBSA": 3.5,
                      "state": 1
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 9.32,
                      "CBSA": 12.21,
                      "state": 13.03
                   },
                   "error": {
                      "this": 1.49,
                      "CBSA": 0.37,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 12816,
                      "CBSA": 155820,
                      "state": 1367773
                   },
                   "numerator_errors": {
                      "this": 2102.4,
                      "CBSA": 4743.3,
                      "state": 12879.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 76,
                      "state": 72
                   },
                   "error_ratio": {
                      "this": 16,
                      "CBSA": 3,
                      "state": 0.9
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 8.14,
                      "CBSA": 10.36,
                      "state": 12.15
                   },
                   "error": {
                      "this": 1.39,
                      "CBSA": 0.48,
                      "state": 0.18
                   },
                   "numerators": {
                      "this": 11189,
                      "CBSA": 132272,
                      "state": 1275103
                   },
                   "numerator_errors": {
                      "this": 1954.2,
                      "CBSA": 6178.1,
                      "state": 18552.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 79,
                      "state": 67
                   },
                   "error_ratio": {
                      "this": 17.1,
                      "CBSA": 4.6,
                      "state": 1.5
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 3.92,
                      "CBSA": 6.34,
                      "state": 9.04
                   },
                   "error": {
                      "this": 0.96,
                      "CBSA": 0.34,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 5384,
                      "CBSA": 80883,
                      "state": 949030
                   },
                   "numerator_errors": {
                      "this": 1335.4,
                      "CBSA": 4288.6,
                      "state": 12068.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 62,
                      "state": 43
                   },
                   "error_ratio": {
                      "this": 24.5,
                      "CBSA": 5.4,
                      "state": 1.2
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 1.49,
                      "CBSA": 2.76,
                      "state": 4.53
                   },
                   "error": {
                      "this": 0.56,
                      "CBSA": 0.27,
                      "state": 0.09
                   },
                   "numerators": {
                      "this": 2045,
                      "CBSA": 35217,
                      "state": 475076
                   },
                   "numerator_errors": {
                      "this": 774.9,
                      "CBSA": 3433.5,
                      "state": 9718.9
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 54,
                      "state": 33
                   },
                   "error_ratio": {
                      "this": 37.6,
                      "CBSA": 9.8,
                      "state": 2
                   }
                }
             },
             "female": {
                "metadata": {
                   "table_id": "B01001",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "0-9": {
                   "name": "0-9",
                   "values": {
                      "this": 12.89,
                      "CBSA": 11.39,
                      "state": 10.09
                   },
                   "error": {
                      "this": 2,
                      "CBSA": 0.36,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 19334,
                      "CBSA": 151652,
                      "state": 1107616
                   },
                   "numerator_errors": {
                      "this": 3068.9,
                      "CBSA": 4856.1,
                      "state": 13622.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 113,
                      "state": 128
                   },
                   "error_ratio": {
                      "this": 15.5,
                      "CBSA": 3.2,
                      "state": 1.2
                   }
                },
                "10-19": {
                   "name": "10-19",
                   "values": {
                      "this": 10.02,
                      "CBSA": 11.84,
                      "state": 10.97
                   },
                   "error": {
                      "this": 1.8,
                      "CBSA": 0.37,
                      "state": 0.13
                   },
                   "numerators": {
                      "this": 15022,
                      "CBSA": 157689,
                      "state": 1204033
                   },
                   "numerator_errors": {
                      "this": 2749.8,
                      "CBSA": 4945.2,
                      "state": 13802.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 85,
                      "state": 91
                   },
                   "error_ratio": {
                      "this": 18,
                      "CBSA": 3.1,
                      "state": 1.2
                   }
                },
                "20-29": {
                   "name": "20-29",
                   "values": {
                      "this": 16.65,
                      "CBSA": 14.09,
                      "state": 11.89
                   },
                   "error": {
                      "this": 2.13,
                      "CBSA": 0.43,
                      "state": 0.14
                   },
                   "numerators": {
                      "this": 24961,
                      "CBSA": 187605,
                      "state": 1305530
                   },
                   "numerator_errors": {
                      "this": 3312.9,
                      "CBSA": 5788.7,
                      "state": 15152.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 118,
                      "state": 140
                   },
                   "error_ratio": {
                      "this": 12.8,
                      "CBSA": 3.1,
                      "state": 1.2
                   }
                },
                "30-39": {
                   "name": "30-39",
                   "values": {
                      "this": 19.59,
                      "CBSA": 14.37,
                      "state": 12.17
                   },
                   "error": {
                      "this": 2.44,
                      "CBSA": 0.49,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 29372,
                      "CBSA": 191412,
                      "state": 1335692
                   },
                   "numerator_errors": {
                      "this": 3802.5,
                      "CBSA": 6510.7,
                      "state": 13465.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 136,
                      "state": 161
                   },
                   "error_ratio": {
                      "this": 12.5,
                      "CBSA": 3.4,
                      "state": 1
                   }
                },
                "40-49": {
                   "name": "40-49",
                   "values": {
                      "this": 14.71,
                      "CBSA": 13.26,
                      "state": 12.15
                   },
                   "error": {
                      "this": 1.9,
                      "CBSA": 0.47,
                      "state": 0.13
                   },
                   "numerators": {
                      "this": 22060,
                      "CBSA": 176635,
                      "state": 1334303
                   },
                   "numerator_errors": {
                      "this": 2957.2,
                      "CBSA": 6296,
                      "state": 14613.7
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 111,
                      "state": 121
                   },
                   "error_ratio": {
                      "this": 12.9,
                      "CBSA": 3.5,
                      "state": 1.1
                   }
                },
                "50-59": {
                   "name": "50-59",
                   "values": {
                      "this": 9.08,
                      "CBSA": 12.48,
                      "state": 13.4
                   },
                   "error": {
                      "this": 1.38,
                      "CBSA": 0.35,
                      "state": 0.11
                   },
                   "numerators": {
                      "this": 13613,
                      "CBSA": 166264,
                      "state": 1471228
                   },
                   "numerator_errors": {
                      "this": 2118.5,
                      "CBSA": 4668.6,
                      "state": 12035.1
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 73,
                      "state": 68
                   },
                   "error_ratio": {
                      "this": 15.2,
                      "CBSA": 2.8,
                      "state": 0.8
                   }
                },
                "60-69": {
                   "name": "60-69",
                   "values": {
                      "this": 10.06,
                      "CBSA": 11.27,
                      "state": 13.28
                   },
                   "error": {
                      "this": 1.44,
                      "CBSA": 0.54,
                      "state": 0.16
                   },
                   "numerators": {
                      "this": 15088,
                      "CBSA": 150047,
                      "state": 1458635
                   },
                   "numerator_errors": {
                      "this": 2218,
                      "CBSA": 7166.6,
                      "state": 17699
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 89,
                      "state": 76
                   },
                   "error_ratio": {
                      "this": 14.3,
                      "CBSA": 4.8,
                      "state": 1.2
                   }
                },
                "70-79": {
                   "name": "70-79",
                   "values": {
                      "this": 4.28,
                      "CBSA": 7.02,
                      "state": 9.93
                   },
                   "error": {
                      "this": 0.92,
                      "CBSA": 0.36,
                      "state": 0.13
                   },
                   "numerators": {
                      "this": 6418,
                      "CBSA": 93438,
                      "state": 1089967
                   },
                   "numerator_errors": {
                      "this": 1394.3,
                      "CBSA": 4783.9,
                      "state": 14154.6
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 61,
                      "state": 43
                   },
                   "error_ratio": {
                      "this": 21.5,
                      "CBSA": 5.1,
                      "state": 1.3
                   }
                },
                "80+": {
                   "name": "80+",
                   "values": {
                      "this": 2.73,
                      "CBSA": 4.29,
                      "state": 6.13
                   },
                   "error": {
                      "this": 0.66,
                      "CBSA": 0.3,
                      "state": 0.12
                   },
                   "numerators": {
                      "this": 4088,
                      "CBSA": 57190,
                      "state": 672728
                   },
                   "numerator_errors": {
                      "this": 995.5,
                      "CBSA": 3947.7,
                      "state": 12705.2
                   },
                   "index": {
                      "this": 100,
                      "CBSA": 64,
                      "state": 45
                   },
                   "error_ratio": {
                      "this": 24.2,
                      "CBSA": 7,
                      "state": 2
                   }
                }
             }
          },
          "median_age": {
             "total": {
                "name": "Median age",
                "values": {
                   "this": 34.6,
                   "CBSA": 37.7,
                   "state": 42.4
                },
                "error": {
                   "this": 1,
                   "CBSA": 0.3,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 82
                },
                "error_ratio": {
                   "this": 2.9,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             },
             "male": {
                "name": "Median age male",
                "values": {
                   "this": 34,
                   "CBSA": 36.3,
                   "state": 40.7
                },
                "error": {
                   "this": 1.5,
                   "CBSA": 0.3,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 94,
                   "state": 84
                },
                "error_ratio": {
                   "this": 4.4,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             },
             "female": {
                "name": "Median age female",
                "values": {
                   "this": 34.9,
                   "CBSA": 38.9,
                   "state": 44.1
                },
                "error": {
                   "this": 1.5,
                   "CBSA": 0.3,
                   "state": 0.1
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B01002",
                   "universe": "Total population",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 90,
                   "state": 79
                },
                "error_ratio": {
                   "this": 4.3,
                   "CBSA": 0.8,
                   "state": 0.2
                }
             }
          }
       },
       "sex": {
          "metadata": {
             "table_id": "B01001",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_male": {
             "name": "Male",
             "values": {
                "this": 47.83,
                "CBSA": 48.93,
                "state": 48.88
             },
             "error": {
                "this": 1.83,
                "CBSA": 0.11,
                "state": 0.04
             },
             "numerators": {
                "this": 137479,
                "CBSA": 1276215,
                "state": 1.0498005E7
             },
             "numerator_errors": {
                "this": 5246,
                "CBSA": 2749,
                "state": 7711
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 98
             },
             "error_ratio": {
                "this": 3.8,
                "CBSA": 0.2,
                "state": 0.1
             }
          },
          "percent_female": {
             "name": "Female",
             "values": {
                "this": 52.17,
                "CBSA": 51.07,
                "state": 51.12
             },
             "error": {
                "this": 1.83,
                "CBSA": 0.11,
                "state": 0.04
             },
             "numerators": {
                "this": 149956,
                "CBSA": 1331932,
                "state": 1.0979732E7
             },
             "numerator_errors": {
                "this": 5249,
                "CBSA": 2749,
                "state": 7711
             },
             "index": {
                "this": 100,
                "CBSA": 102,
                "state": 102
             },
             "error_ratio": {
                "this": 3.5,
                "CBSA": 0.2,
                "state": 0.1
             }
          }
       },
       "race": {
          "metadata": {
             "table_id": "B03002",
             "universe": "Total population",
             "acs_release": "ACS 2019 1-year"
          },
          "percent_white": {
             "name": "White",
             "values": {
                "this": 37.2,
                "CBSA": 45.15,
                "state": 52.98
             },
             "error": {
                "this": 2.32,
                "CBSA": 0.22,
                "state": 0.04
             },
             "numerators": {
                "this": 106928,
                "CBSA": 1177581,
                "state": 1.1378457E7
             },
             "numerator_errors": {
                "this": 6666,
                "CBSA": 5633,
                "state": 9637
             },
             "index": {
                "this": 100,
                "CBSA": 82,
                "state": 70
             },
             "error_ratio": {
                "this": 6.2,
                "CBSA": 0.5,
                "state": 0.1
             }
          },
          "percent_black": {
             "name": "Black",
             "values": {
                "this": 20.16,
                "CBSA": 15.49,
                "state": 15.24
             },
             "error": {
                "this": 2.95,
                "CBSA": 0.25,
                "state": 0.08
             },
             "numerators": {
                "this": 57945,
                "CBSA": 403976,
                "state": 3272904
             },
             "numerator_errors": {
                "this": 8467,
                "CBSA": 6547,
                "state": 17131
             },
             "index": {
                "this": 100,
                "CBSA": 130,
                "state": 132
             },
             "error_ratio": {
                "this": 14.6,
                "CBSA": 1.6,
                "state": 0.5
             }
          },
          "percent_native": {
             "name": "Native",
             "values": {
                "this": 0.29,
                "CBSA": 0.16,
                "state": 0.2
             },
             "error": {
                "this": 0.29,
                "CBSA": 0.06,
                "state": 0.02
             },
             "numerators": {
                "this": 828,
                "CBSA": 4244,
                "state": 42758
             },
             "numerator_errors": {
                "this": 823,
                "CBSA": 1664,
                "state": 4308
             },
             "index": {
                "this": 100,
                "CBSA": 181,
                "state": 145
             },
             "error_ratio": {
                "this": 100,
                "CBSA": 37.5,
                "state": 10
             }
          },
          "percent_asian": {
             "name": "Asian",
             "values": {
                "this": 3.13,
                "CBSA": 4.39,
                "state": 2.73
             },
             "error": {
                "this": 1.04,
                "CBSA": 0.11,
                "state": 0.04
             },
             "numerators": {
                "this": 9003,
                "CBSA": 114511,
                "state": 587358
             },
             "numerator_errors": {
                "this": 2999,
                "CBSA": 2967,
                "state": 8152
             },
             "index": {
                "this": 100,
                "CBSA": 71,
                "state": 115
             },
             "error_ratio": {
                "this": 33.2,
                "CBSA": 2.5,
                "state": 1.5
             }
          },
          "percent_islander": {
             "name": "Islander",
             "values": {
                "this": 0,
                "CBSA": 0.08,
                "state": 0.06
             },
             "error": {
                "this": 0,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 0,
                "CBSA": 1983,
                "state": 13927
             },
             "numerator_errors": {
                "this": 234,
                "CBSA": 666,
                "state": 3005
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": 37.5,
                "state": 16.7
             }
          },
          "percent_other": {
             "name": "Other",
             "values": {
                "this": 0.18,
                "CBSA": 0.69,
                "state": 0.38
             },
             "error": {
                "this": 0.13,
                "CBSA": 0.23,
                "state": 0.05
             },
             "numerators": {
                "this": 506,
                "CBSA": 17986,
                "state": 82214
             },
             "numerator_errors": {
                "this": 382,
                "CBSA": 5952,
                "state": 10506
             },
             "index": {
                "this": 100,
                "CBSA": 26,
                "state": 47
             },
             "error_ratio": {
                "this": 72.2,
                "CBSA": 33.3,
                "state": 13.2
             }
          },
          "percent_two_or_more": {
             "name": "Two+",
             "values": {
                "this": 2.05,
                "CBSA": 2.13,
                "state": 2.03
             },
             "error": {
                "this": 0.87,
                "CBSA": 0.24,
                "state": 0.08
             },
             "numerators": {
                "this": 5879,
                "CBSA": 55440,
                "state": 436490
             },
             "numerator_errors": {
                "this": 2495,
                "CBSA": 6343,
                "state": 17092
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 101
             },
             "error_ratio": {
                "this": 42.4,
                "CBSA": 11.3,
                "state": 3.9
             }
          },
          "percent_hispanic": {
             "name": "Hispanic",
             "values": {
                "this": 37,
                "CBSA": 31.92,
                "state": 26.37
             },
             "error": {
                "this": 3.3,
                "CBSA": 0,
                "state": 0
             },
             "numerators": {
                "this": 106346,
                "CBSA": 832426,
                "state": 5663629
             },
             "numerator_errors": {
                "this": 9472,
                "CBSA": 0,
                "state": 637
             },
             "index": {
                "this": 100,
                "CBSA": 116,
                "state": 140
             },
             "error_ratio": {
                "this": 8.9,
                "CBSA": null,
                "state": null
             }
          }
       }
    },
    "economics": {
       "income": {
          "per_capita_income_in_the_last_12_months": {
             "name": "Per capita income",
             "values": {
                "this": 34656,
                "CBSA": 31186,
                "state": 32887
             },
             "error": {
                "this": 2440,
                "CBSA": 577,
                "state": 191
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19301",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 111,
                "state": 105
             },
             "error_ratio": {
                "this": 7,
                "CBSA": 1.9,
                "state": 0.6
             }
          },
          "median_household_income": {
             "name": "Median household income",
             "values": {
                "this": 58819,
                "CBSA": 61876,
                "state": 59227
             },
             "error": {
                "this": 4309,
                "CBSA": 1173,
                "state": 443
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B19013",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 95,
                "state": 99
             },
             "error_ratio": {
                "this": 7.3,
                "CBSA": 1.9,
                "state": 0.7
             }
          },
          "household_distribution": {
             "metadata": {
                "table_id": "B19001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "under_50": {
                "name": "Under $50K",
                "values": {
                   "this": 43.38,
                   "CBSA": 39.69,
                   "state": 42.25
                },
                "error": {
                   "this": 3.77,
                   "CBSA": 1.34,
                   "state": 0.42
                },
                "numerators": {
                   "this": 47481,
                   "CBSA": 353160,
                   "state": 3340106
                },
                "numerator_errors": {
                   "this": 4536.3,
                   "CBSA": 12497.2,
                   "state": 34235.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 109,
                   "state": 103
                },
                "error_ratio": {
                   "this": 8.7,
                   "CBSA": 3.4,
                   "state": 1
                }
             },
             "50_to_100": {
                "name": "$50K - $100K",
                "values": {
                   "this": 30.6,
                   "CBSA": 33.44,
                   "state": 31.51
                },
                "error": {
                   "this": 3.31,
                   "CBSA": 1.22,
                   "state": 0.35
                },
                "numerators": {
                   "this": 33497,
                   "CBSA": 297554,
                   "state": 2491037
                },
                "numerator_errors": {
                   "this": 3854.9,
                   "CBSA": 11256.1,
                   "state": 28778.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 92,
                   "state": 97
                },
                "error_ratio": {
                   "this": 10.8,
                   "CBSA": 3.6,
                   "state": 1.1
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 19.16,
                   "CBSA": 20.32,
                   "state": 19.54
                },
                "error": {
                   "this": 2.2,
                   "CBSA": 0.91,
                   "state": 0.27
                },
                "numerators": {
                   "this": 20976,
                   "CBSA": 180836,
                   "state": 1544644
                },
                "numerator_errors": {
                   "this": 2541.7,
                   "CBSA": 8336.3,
                   "state": 21460.1
                },
                "index": {
                   "this": 100,
                   "CBSA": 94,
                   "state": 98
                },
                "error_ratio": {
                   "this": 11.5,
                   "CBSA": 4.5,
                   "state": 1.4
                }
             },
             "over_200": {
                "name": "Over $200K",
                "values": {
                   "this": 6.85,
                   "CBSA": 6.55,
                   "state": 6.7
                },
                "error": {
                   "this": 1.26,
                   "CBSA": 0.45,
                   "state": 0.13
                },
                "numerators": {
                   "this": 7500,
                   "CBSA": 58272,
                   "state": 530045
                },
                "numerator_errors": {
                   "this": 1407,
                   "CBSA": 4049,
                   "state": 10064
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 102
                },
                "error_ratio": {
                   "this": 18.4,
                   "CBSA": 6.9,
                   "state": 1.9
                }
             }
          }
       },
       "poverty": {
          "percent_below_poverty_line": {
             "name": "Persons below poverty line",
             "values": {
                "this": 15.99,
                "CBSA": 12.07,
                "state": 12.66
             },
             "error": {
                "this": 3.4,
                "CBSA": 0.88,
                "state": 0.26
             },
             "numerators": {
                "this": 45623,
                "CBSA": 309279,
                "state": 2664772
             },
             "numerator_errors": {
                "this": 9699,
                "CBSA": 22492,
                "state": 55731
             },
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 132,
                "state": 126
             },
             "error_ratio": {
                "this": 21.3,
                "CBSA": 7.3,
                "state": 2.1
             }
          },
          "children": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 29.27,
                   "CBSA": 17.43,
                   "state": 17.75
                },
                "error": {
                   "this": 5.68,
                   "CBSA": 1.36,
                   "state": 0.47
                },
                "numerators": {
                   "this": 18587,
                   "CBSA": 96604,
                   "state": 737415
                },
                "numerator_errors": {
                   "this": 4084.3,
                   "CBSA": 7959.4,
                   "state": 20480.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 168,
                   "state": 165
                },
                "error_ratio": {
                   "this": 19.4,
                   "CBSA": 7.8,
                   "state": 2.6
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 70.73,
                   "CBSA": 82.57,
                   "state": 82.25
                },
                "error": {
                   "this": 3.47,
                   "CBSA": 0.43,
                   "state": 0.15
                },
                "numerators": {
                   "this": 44918,
                   "CBSA": 457717,
                   "state": 3417740
                },
                "numerator_errors": {
                   "this": 5140.6,
                   "CBSA": 12387.6,
                   "state": 31652.6
                },
                "index": {
                   "this": 100,
                   "CBSA": 86,
                   "state": 86
                },
                "error_ratio": {
                   "this": 4.9,
                   "CBSA": 0.5,
                   "state": 0.2
                }
             }
          },
          "seniors": {
             "metadata": {
                "table_id": "B17001",
                "universe": "Population for whom poverty status is determined",
                "acs_release": "ACS 2019 1-year"
             },
             "Below": {
                "name": "Poverty",
                "values": {
                   "this": 11.02,
                   "CBSA": 9.07,
                   "state": 10.73
                },
                "error": {
                   "this": 3.36,
                   "CBSA": 0.94,
                   "state": 0.28
                },
                "numerators": {
                   "this": 3073,
                   "CBSA": 35311,
                   "state": 474898
                },
                "numerator_errors": {
                   "this": 992.1,
                   "CBSA": 3698.5,
                   "state": 12514.7
                },
                "index": {
                   "this": 100,
                   "CBSA": 121,
                   "state": 103
                },
                "error_ratio": {
                   "this": 30.5,
                   "CBSA": 10.4,
                   "state": 2.6
                }
             },
             "above": {
                "name": "Non-poverty",
                "values": {
                   "this": 88.98,
                   "CBSA": 90.93,
                   "state": 89.27
                },
                "error": {
                   "this": 3.23,
                   "CBSA": 1.76,
                   "state": 0.49
                },
                "numerators": {
                   "this": 24823,
                   "CBSA": 354076,
                   "state": 3952902
                },
                "numerator_errors": {
                   "this": 2766.7,
                   "CBSA": 4409.6,
                   "state": 14030
                },
                "index": {
                   "this": 100,
                   "CBSA": 98,
                   "state": 100
                },
                "error_ratio": {
                   "this": 3.6,
                   "CBSA": 1.9,
                   "state": 0.5
                }
             }
          }
       },
       "employment": {
          "mean_travel_time": {
             "name": "Mean travel time to work",
             "values": {
                "this": 28.08,
                "CBSA": 30.16,
                "state": 28.44
             },
             "error": {
                "this": 0.99,
                "CBSA": 0.51,
                "state": 0.13
             },
             "numerators": {
                "this": 4149095,
                "CBSA": 3.6204312E7,
                "state": 2.60781536E8
             },
             "numerator_errors": {
                "this": 228901,
                "CBSA": 770801,
                "state": 1614208
             },
             "metadata": {
                "table_id": "B08006, B08013",
                "universe": "Workers 16 years and over who did not work at home",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 93,
                "state": 99
             },
             "error_ratio": {
                "this": 3.5,
                "CBSA": 1.7,
                "state": 0.5
             }
          },
          "transportation_distribution": {
             "metadata": {
                "table_id": "B08006",
                "universe": "Workers 16 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "drove_alone": {
                "name": "Drove alone",
                "values": {
                   "this": 76.73,
                   "CBSA": 77.41,
                   "state": 78.14
                },
                "error": {
                   "this": 2.3,
                   "CBSA": 0.91,
                   "state": 0.29
                },
                "numerators": {
                   "this": 121673,
                   "CBSA": 999217,
                   "state": 7703087
                },
                "numerator_errors": {
                   "this": 5760,
                   "CBSA": 16051,
                   "state": 39653
                },
                "index": {
                   "this": 100,
                   "CBSA": 99,
                   "state": 98
                },
                "error_ratio": {
                   "this": 3,
                   "CBSA": 1.2,
                   "state": 0.4
                }
             },
             "carpooled": {
                "name": "Carpooled",
                "values": {
                   "this": 10.32,
                   "CBSA": 10.76,
                   "state": 9.14
                },
                "error": {
                   "this": 1.98,
                   "CBSA": 0.88,
                   "state": 0.24
                },
                "numerators": {
                   "this": 16359,
                   "CBSA": 138892,
                   "state": 901161
                },
                "numerator_errors": {
                   "this": 3204,
                   "CBSA": 11404,
                   "state": 23798
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 113
                },
                "error_ratio": {
                   "this": 19.2,
                   "CBSA": 8.2,
                   "state": 2.6
                }
             },
             "public_transit": {
                "name": "Public transit",
                "values": {
                   "this": 1.46,
                   "CBSA": 1.43,
                   "state": 1.59
                },
                "error": {
                   "this": 0.67,
                   "CBSA": 0.26,
                   "state": 0.1
                },
                "numerators": {
                   "this": 2319,
                   "CBSA": 18485,
                   "state": 156860
                },
                "numerator_errors": {
                   "this": 1067,
                   "CBSA": 3356,
                   "state": 9797
                },
                "index": {
                   "this": 100,
                   "CBSA": 102,
                   "state": 92
                },
                "error_ratio": {
                   "this": 45.9,
                   "CBSA": 18.2,
                   "state": 6.3
                }
             },
             "Bicycle": {
                "name": "Bicycle",
                "values": {
                   "this": 0.78,
                   "CBSA": 0.41,
                   "state": 0.6
                },
                "error": {
                   "this": 0.51,
                   "CBSA": 0.13,
                   "state": 0.06
                },
                "numerators": {
                   "this": 1240,
                   "CBSA": 5289,
                   "state": 58657
                },
                "numerator_errors": {
                   "this": 811,
                   "CBSA": 1686,
                   "state": 6172
                },
                "index": {
                   "this": 100,
                   "CBSA": 190,
                   "state": 130
                },
                "error_ratio": {
                   "this": 65.4,
                   "CBSA": 31.7,
                   "state": 10
                }
             },
             "walked": {
                "name": "Walked",
                "values": {
                   "this": 1.48,
                   "CBSA": 1.35,
                   "state": 1.57
                },
                "error": {
                   "this": 0.62,
                   "CBSA": 0.24,
                   "state": 0.08
                },
                "numerators": {
                   "this": 2350,
                   "CBSA": 17489,
                   "state": 154954
                },
                "numerator_errors": {
                   "this": 983,
                   "CBSA": 3052,
                   "state": 8239
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 94
                },
                "error_ratio": {
                   "this": 41.9,
                   "CBSA": 17.8,
                   "state": 5.1
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 2.41,
                   "CBSA": 1.63,
                   "state": 1.97
                },
                "error": {
                   "this": 0.98,
                   "CBSA": 0.29,
                   "state": 0.09
                },
                "numerators": {
                   "this": 3815,
                   "CBSA": 21044,
                   "state": 194532
                },
                "numerator_errors": {
                   "this": 1556,
                   "CBSA": 3724,
                   "state": 9186
                },
                "index": {
                   "this": 100,
                   "CBSA": 148,
                   "state": 122
                },
                "error_ratio": {
                   "this": 40.7,
                   "CBSA": 17.8,
                   "state": 4.6
                }
             },
             "worked_at_home": {
                "name": "Worked at home",
                "values": {
                   "this": 6.82,
                   "CBSA": 7.01,
                   "state": 6.98
                },
                "error": {
                   "this": 1.46,
                   "CBSA": 0.53,
                   "state": 0.17
                },
                "numerators": {
                   "this": 10808,
                   "CBSA": 90464,
                   "state": 688324
                },
                "numerator_errors": {
                   "this": 2342,
                   "CBSA": 6882,
                   "state": 17057
                },
                "index": {
                   "this": 100,
                   "CBSA": 97,
                   "state": 98
                },
                "error_ratio": {
                   "this": 21.4,
                   "CBSA": 7.6,
                   "state": 2.4
                }
             }
          }
       }
    },
    "families": {
       "marital_status": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "married": {
             "name": "Married",
             "values": {
                "this": 39.44,
                "CBSA": 47.19,
                "state": 47.94
             },
             "error": {
                "this": 2.02,
                "CBSA": 0.75,
                "state": 0.26
             },
             "numerators": {
                "this": 92279,
                "CBSA": 1010203,
                "state": 8612149
             },
             "numerator_errors": {
                "this": 5116.9,
                "CBSA": 16044,
                "state": 45912.8
             },
             "index": {
                "this": 100,
                "CBSA": 84,
                "state": 82
             },
             "error_ratio": {
                "this": 5.1,
                "CBSA": 1.6,
                "state": 0.5
             }
          },
          "single": {
             "name": "Single",
             "values": {
                "this": 60.56,
                "CBSA": 52.81,
                "state": 52.06
             },
             "error": {
                "this": 3.18,
                "CBSA": 0.85,
                "state": 0.27
             },
             "numerators": {
                "this": 141704,
                "CBSA": 1130464,
                "state": 9352545
             },
             "numerator_errors": {
                "this": 8033.1,
                "CBSA": 18316.8,
                "state": 48542.2
             },
             "index": {
                "this": 100,
                "CBSA": 115,
                "state": 116
             },
             "error_ratio": {
                "this": 5.3,
                "CBSA": 1.6,
                "state": 0.5
             }
          }
       },
       "marital_status_grouped": {
          "metadata": {
             "table_id": "B12001",
             "universe": "Population 15 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "never_married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Never married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 46.32,
                   "CBSA": 38.56,
                   "state": 35.51
                },
                "error": {
                   "this": 4.52,
                   "CBSA": 0.84,
                   "state": 0.3
                },
                "numerators": {
                   "this": 50985,
                   "CBSA": 398978,
                   "state": 3089210
                },
                "numerator_errors": {
                   "this": 5527,
                   "CBSA": 8726,
                   "state": 25898
                },
                "index": {
                   "this": 100,
                   "CBSA": 120,
                   "state": 130
                },
                "error_ratio": {
                   "this": 9.8,
                   "CBSA": 2.2,
                   "state": 0.8
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 41.98,
                   "CBSA": 32.82,
                   "state": 28.79
                },
                "error": {
                   "this": 2.85,
                   "CBSA": 0.89,
                   "state": 0.25
                },
                "numerators": {
                   "this": 52019,
                   "CBSA": 362992,
                   "state": 2667692
                },
                "numerator_errors": {
                   "this": 4112,
                   "CBSA": 9866,
                   "state": 23242
                },
                "index": {
                   "this": 100,
                   "CBSA": 128,
                   "state": 146
                },
                "error_ratio": {
                   "this": 6.8,
                   "CBSA": 2.7,
                   "state": 0.9
                }
             }
          },
          "married": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Now married"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 41.72,
                   "CBSA": 49.07,
                   "state": 49.87
                },
                "error": {
                   "this": 2.79,
                   "CBSA": 1.11,
                   "state": 0.36
                },
                "numerators": {
                   "this": 45925,
                   "CBSA": 507704,
                   "state": 4338448
                },
                "numerator_errors": {
                   "this": 3755,
                   "CBSA": 11480,
                   "state": 31390
                },
                "index": {
                   "this": 100,
                   "CBSA": 85,
                   "state": 84
                },
                "error_ratio": {
                   "this": 6.7,
                   "CBSA": 2.3,
                   "state": 0.7
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 37.41,
                   "CBSA": 45.43,
                   "state": 46.13
                },
                "error": {
                   "this": 2.36,
                   "CBSA": 1.01,
                   "state": 0.36
                },
                "numerators": {
                   "this": 46354,
                   "CBSA": 502499,
                   "state": 4273701
                },
                "numerator_errors": {
                   "this": 3476,
                   "CBSA": 11208,
                   "state": 33506
                },
                "index": {
                   "this": 100,
                   "CBSA": 82,
                   "state": 81
                },
                "error_ratio": {
                   "this": 6.3,
                   "CBSA": 2.2,
                   "state": 0.8
                }
             }
          },
          "divorced": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Divorced"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 10.75,
                   "CBSA": 10.22,
                   "state": 11.46
                },
                "error": {
                   "this": 2.2,
                   "CBSA": 0.73,
                   "state": 0.21
                },
                "numerators": {
                   "this": 11834,
                   "CBSA": 105765,
                   "state": 997168
                },
                "numerator_errors": {
                   "this": 2481,
                   "CBSA": 7596,
                   "state": 18619
                },
                "index": {
                   "this": 100,
                   "CBSA": 105,
                   "state": 94
                },
                "error_ratio": {
                   "this": 20.5,
                   "CBSA": 7.1,
                   "state": 1.8
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 14.74,
                   "CBSA": 13.78,
                   "state": 14.84
                },
                "error": {
                   "this": 2.09,
                   "CBSA": 0.75,
                   "state": 0.22
                },
                "numerators": {
                   "this": 18267,
                   "CBSA": 152407,
                   "state": 1374598
                },
                "numerator_errors": {
                   "this": 2692,
                   "CBSA": 8248,
                   "state": 20466
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 99
                },
                "error_ratio": {
                   "this": 14.2,
                   "CBSA": 5.4,
                   "state": 1.5
                }
             }
          },
          "widowed": {
             "acs_release": "ACS 2019 1-year",
             "metadata": {
                "universe": "Population 15 years and over",
                "table_id": "B12001",
                "name": "Widowed"
             },
             "male": {
                "name": "Male",
                "values": {
                   "this": 1.2,
                   "CBSA": 2.14,
                   "state": 3.16
                },
                "error": {
                   "this": 0.91,
                   "CBSA": 0.33,
                   "state": 0.11
                },
                "numerators": {
                   "this": 1325,
                   "CBSA": 22125,
                   "state": 274697
                },
                "numerator_errors": {
                   "this": 1001,
                   "CBSA": 3373,
                   "state": 9382
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 38
                },
                "error_ratio": {
                   "this": 75.8,
                   "CBSA": 15.4,
                   "state": 3.5
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 5.87,
                   "CBSA": 7.97,
                   "state": 10.24
                },
                "error": {
                   "this": 1.3,
                   "CBSA": 0.45,
                   "state": 0.18
                },
                "numerators": {
                   "this": 7274,
                   "CBSA": 88197,
                   "state": 949180
                },
                "numerator_errors": {
                   "this": 1634,
                   "CBSA": 4992,
                   "state": 17085
                },
                "index": {
                   "this": 100,
                   "CBSA": 74,
                   "state": 57
                },
                "error_ratio": {
                   "this": 22.1,
                   "CBSA": 5.6,
                   "state": 1.8
                }
             }
          }
       },
       "family_types": {
          "children": {
             "metadata": {
                "table_id": "B09002",
                "universe": "Own children under 18 years",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couple": {
                "name": "Married couple",
                "values": {
                   "this": 58.83,
                   "CBSA": 68.23,
                   "state": 65.12
                },
                "error": {
                   "this": 6.33,
                   "CBSA": 1.75,
                   "state": 0.75
                },
                "numerators": {
                   "this": 32593,
                   "CBSA": 328533,
                   "state": 2336939
                },
                "numerator_errors": {
                   "this": 5030,
                   "CBSA": 10684,
                   "state": 30028
                },
                "index": {
                   "this": 100,
                   "CBSA": 86,
                   "state": 90
                },
                "error_ratio": {
                   "this": 10.8,
                   "CBSA": 2.6,
                   "state": 1.2
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 2.79,
                   "CBSA": 7.08,
                   "state": 8.48
                },
                "error": {
                   "this": 1.27,
                   "CBSA": 1.28,
                   "state": 0.49
                },
                "numerators": {
                   "this": 1544,
                   "CBSA": 34088,
                   "state": 304153
                },
                "numerator_errors": {
                   "this": 724,
                   "CBSA": 6204,
                   "state": 17628
                },
                "index": {
                   "this": 100,
                   "CBSA": 39,
                   "state": 33
                },
                "error_ratio": {
                   "this": 45.5,
                   "CBSA": 18.1,
                   "state": 5.8
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 38.39,
                   "CBSA": 24.69,
                   "state": 26.41
                },
                "error": {
                   "this": 9.62,
                   "CBSA": 2.05,
                   "state": 0.78
                },
                "numerators": {
                   "this": 21269,
                   "CBSA": 118853,
                   "state": 947647
                },
                "numerator_errors": {
                   "this": 5826,
                   "CBSA": 10138,
                   "state": 28591
                },
                "index": {
                   "this": 100,
                   "CBSA": 155,
                   "state": 145
                },
                "error_ratio": {
                   "this": 25.1,
                   "CBSA": 8.3,
                   "state": 3
                }
             }
          }
       },
       "fertility": {
          "total": {
             "name": "Women 15-50 who gave birth during past year",
             "values": {
                "this": 4.82,
                "CBSA": 4.2,
                "state": 4.36
             },
             "error": {
                "this": 1.88,
                "CBSA": 0.58,
                "state": 0.2
             },
             "numerators": {
                "this": 4137,
                "CBSA": 27536,
                "state": 205856
             },
             "numerator_errors": {
                "this": 1625,
                "CBSA": 3776,
                "state": 9243
             },
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 115,
                "state": 111
             },
             "error_ratio": {
                "this": 39,
                "CBSA": 13.8,
                "state": 4.6
             }
          },
          "by_age": {
             "metadata": {
                "table_id": "B13016",
                "universe": "Women 15 to 50 years",
                "acs_release": "ACS 2019 1-year"
             },
             "15_to_19": {
                "name": "15-19",
                "values": {
                   "this": 0.71,
                   "CBSA": 0.29,
                   "state": 0.75
                },
                "error": {
                   "this": 1.18,
                   "CBSA": 0.28,
                   "state": 0.24
                },
                "numerators": {
                   "this": 59,
                   "CBSA": 246,
                   "state": 4478
                },
                "numerator_errors": {
                   "this": 99,
                   "CBSA": 237,
                   "state": 1455
                },
                "index": {
                   "this": 100,
                   "CBSA": 245,
                   "state": 95
                },
                "error_ratio": {
                   "this": 166.2,
                   "CBSA": 96.6,
                   "state": 32
                }
             },
             "20_to_24": {
                "name": "20-24",
                "values": {
                   "this": 11.81,
                   "CBSA": 3.98,
                   "state": 4.38
                },
                "error": {
                   "this": 10.85,
                   "CBSA": 1.68,
                   "state": 0.5
                },
                "numerators": {
                   "this": 1187,
                   "CBSA": 3509,
                   "state": 27105
                },
                "numerator_errors": {
                   "this": 1123,
                   "CBSA": 1484,
                   "state": 3104
                },
                "index": {
                   "this": 100,
                   "CBSA": 297,
                   "state": 270
                },
                "error_ratio": {
                   "this": 91.9,
                   "CBSA": 42.2,
                   "state": 11.4
                }
             },
             "25_to_29": {
                "name": "25-29",
                "values": {
                   "this": 5.13,
                   "CBSA": 8.24,
                   "state": 8.35
                },
                "error": {
                   "this": 4.17,
                   "CBSA": 2.05,
                   "state": 0.8
                },
                "numerators": {
                   "this": 764,
                   "CBSA": 8194,
                   "state": 57284
                },
                "numerator_errors": {
                   "this": 634,
                   "CBSA": 2054,
                   "state": 5558
                },
                "index": {
                   "this": 100,
                   "CBSA": 62,
                   "state": 61
                },
                "error_ratio": {
                   "this": 81.3,
                   "CBSA": 24.9,
                   "state": 9.6
                }
             },
             "30_to_34": {
                "name": "30-35",
                "values": {
                   "this": 7.38,
                   "CBSA": 9.24,
                   "state": 9.02
                },
                "error": {
                   "this": 4.17,
                   "CBSA": 2.12,
                   "state": 0.67
                },
                "numerators": {
                   "this": 1174,
                   "CBSA": 8774,
                   "state": 60768
                },
                "numerator_errors": {
                   "this": 687,
                   "CBSA": 2030,
                   "state": 4530
                },
                "index": {
                   "this": 100,
                   "CBSA": 80,
                   "state": 82
                },
                "error_ratio": {
                   "this": 56.5,
                   "CBSA": 22.9,
                   "state": 7.4
                }
             },
             "35_to_39": {
                "name": "35-39",
                "values": {
                   "this": 3.99,
                   "CBSA": 4.74,
                   "state": 5.99
                },
                "error": {
                   "this": 4.89,
                   "CBSA": 1.48,
                   "state": 0.64
                },
                "numerators": {
                   "this": 537,
                   "CBSA": 4565,
                   "state": 39641
                },
                "numerator_errors": {
                   "this": 669,
                   "CBSA": 1458,
                   "state": 4308
                },
                "index": {
                   "this": 100,
                   "CBSA": 84,
                   "state": 67
                },
                "error_ratio": {
                   "this": 122.6,
                   "CBSA": 31.2,
                   "state": 10.7
                }
             },
             "40_to_44": {
                "name": "40-44",
                "values": {
                   "this": 3.63,
                   "CBSA": 1.89,
                   "state": 1.91
                },
                "error": {
                   "this": 3.15,
                   "CBSA": 0.86,
                   "state": 0.38
                },
                "numerators": {
                   "this": 416,
                   "CBSA": 1688,
                   "state": 12415
                },
                "numerator_errors": {
                   "this": 369,
                   "CBSA": 772,
                   "state": 2508
                },
                "index": {
                   "this": 100,
                   "CBSA": 192,
                   "state": 190
                },
                "error_ratio": {
                   "this": 86.8,
                   "CBSA": 45.5,
                   "state": 19.9
                }
             },
             "45_to_50": {
                "name": "45-50",
                "values": {
                   "this": 0,
                   "CBSA": 0.54,
                   "state": 0.5
                },
                "error": {
                   "this": 0,
                   "CBSA": 0.53,
                   "state": 0.15
                },
                "numerators": {
                   "this": 0,
                   "CBSA": 560,
                   "state": 4165
                },
                "numerator_errors": {
                   "this": 234,
                   "CBSA": 559,
                   "state": 1231
                },
                "index": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "error_ratio": {
                   "this": null,
                   "CBSA": 98.1,
                   "state": 30
                }
             }
          }
       },
       "households": {
          "number_of_households": {
             "name": "Number of households",
             "values": {
                "this": 109454,
                "CBSA": 889822,
                "state": 7905832
             },
             "error": {
                "this": 4328,
                "CBSA": 9137,
                "state": 23042
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B11001",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 12,
                "state": 1
             },
             "error_ratio": {
                "this": 4,
                "CBSA": 1,
                "state": 0.3
             }
          },
          "persons_per_household": {
             "name": "Persons per household",
             "values": {
                "this": 2.61,
                "CBSA": 2.89,
                "state": 2.66
             },
             "error": {
                "this": 0.1,
                "CBSA": 0.03,
                "state": 0.01
             },
             "numerators": {
                "this": 285432,
                "CBSA": 2569149,
                "state": 2.1047762E7
             },
             "numerator_errors": {
                "this": 781,
                "CBSA": 4545,
                "state": 0
             },
             "metadata": {
                "table_id": "B11001,b11002",
                "universe": "Households",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 90,
                "state": 98
             },
             "error_ratio": {
                "this": 3.8,
                "CBSA": 1,
                "state": 0.4
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B11002",
                "universe": "People in Households",
                "acs_release": "ACS 2019 1-year"
             },
             "married_couples": {
                "name": "Married couples",
                "values": {
                   "this": 44.16,
                   "CBSA": 57.39,
                   "state": 56.29
                },
                "error": {
                   "this": 3.87,
                   "CBSA": 1.23,
                   "state": 0.4
                },
                "numerators": {
                   "this": 126053,
                   "CBSA": 1474515,
                   "state": 1.1846877E7
                },
                "numerator_errors": {
                   "this": 11049,
                   "CBSA": 31691,
                   "state": 83985
                },
                "index": {
                   "this": 100,
                   "CBSA": 77,
                   "state": 78
                },
                "error_ratio": {
                   "this": 8.8,
                   "CBSA": 2.1,
                   "state": 0.7
                }
             },
             "male_householder": {
                "name": "Male householder",
                "values": {
                   "this": 4.61,
                   "CBSA": 6.99,
                   "state": 7.27
                },
                "error": {
                   "this": 1.42,
                   "CBSA": 0.64,
                   "state": 0.23
                },
                "numerators": {
                   "this": 13171,
                   "CBSA": 179633,
                   "state": 1530401
                },
                "numerator_errors": {
                   "this": 4052,
                   "CBSA": 16427,
                   "state": 48580
                },
                "index": {
                   "this": 100,
                   "CBSA": 66,
                   "state": 63
                },
                "error_ratio": {
                   "this": 30.8,
                   "CBSA": 9.2,
                   "state": 3.2
                }
             },
             "female_householder": {
                "name": "Female householder",
                "values": {
                   "this": 25.78,
                   "CBSA": 19.21,
                   "state": 18.67
                },
                "error": {
                   "this": 3.77,
                   "CBSA": 0.88,
                   "state": 0.32
                },
                "numerators": {
                   "this": 73575,
                   "CBSA": 493423,
                   "state": 3929266
                },
                "numerator_errors": {
                   "this": 10759,
                   "CBSA": 22718,
                   "state": 68092
                },
                "index": {
                   "this": 100,
                   "CBSA": 134,
                   "state": 138
                },
                "error_ratio": {
                   "this": 14.6,
                   "CBSA": 4.6,
                   "state": 1.7
                }
             },
             "nonfamily": {
                "name": "Non-family",
                "values": {
                   "this": 25.45,
                   "CBSA": 16.41,
                   "state": 17.77
                },
                "error": {
                   "this": 2.58,
                   "CBSA": 0.7,
                   "state": 0.21
                },
                "numerators": {
                   "this": 72633,
                   "CBSA": 421578,
                   "state": 3741218
                },
                "numerator_errors": {
                   "this": 7369,
                   "CBSA": 17949,
                   "state": 43595
                },
                "index": {
                   "this": 100,
                   "CBSA": 155,
                   "state": 143
                },
                "error_ratio": {
                   "this": 10.1,
                   "CBSA": 4.3,
                   "state": 1.2
                }
             }
          }
       }
    },
    "housing": {
       "units": {
          "number": {
             "name": "Number of housing units",
             "values": {
                "this": 138456,
                "CBSA": 1077100,
                "state": 9674053
             },
             "error": {
                "this": 5042,
                "CBSA": 1589,
                "state": 581
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 13,
                "state": 1
             },
             "error_ratio": {
                "this": 3.6,
                "CBSA": 0.1,
                "state": null
             }
          },
          "occupancy_distribution": {
             "metadata": {
                "table_id": "B25002",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "occupied": {
                "name": "Occupied",
                "values": {
                   "this": 79.05,
                   "CBSA": 82.61,
                   "state": 81.72
                },
                "error": {
                   "this": 1.22,
                   "CBSA": 0.84,
                   "state": 0.24
                },
                "numerators": {
                   "this": 109454,
                   "CBSA": 889822,
                   "state": 7905832
                },
                "numerator_errors": {
                   "this": 4328,
                   "CBSA": 9137,
                   "state": 23042
                },
                "index": {
                   "this": 100,
                   "CBSA": 96,
                   "state": 97
                },
                "error_ratio": {
                   "this": 1.5,
                   "CBSA": 1,
                   "state": 0.3
                }
             },
             "vacant": {
                "name": "Vacant",
                "values": {
                   "this": 20.95,
                   "CBSA": 17.39,
                   "state": 18.28
                },
                "error": {
                   "this": 2.26,
                   "CBSA": 0.87,
                   "state": 0.24
                },
                "numerators": {
                   "this": 29002,
                   "CBSA": 187278,
                   "state": 1768221
                },
                "numerator_errors": {
                   "this": 3303,
                   "CBSA": 9368,
                   "state": 23034
                },
                "index": {
                   "this": 100,
                   "CBSA": 120,
                   "state": 115
                },
                "error_ratio": {
                   "this": 10.8,
                   "CBSA": 5,
                   "state": 1.3
                }
             }
          },
          "structure_distribution": {
             "metadata": {
                "table_id": "B25024",
                "universe": "Housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "single_unit": {
                "name": "Single unit",
                "values": {
                   "this": 42.54,
                   "CBSA": 64.65,
                   "state": 60.35
                },
                "error": {
                   "this": 2.11,
                   "CBSA": 1.03,
                   "state": 0.29
                },
                "numerators": {
                   "this": 58900,
                   "CBSA": 696344,
                   "state": 5838385
                },
                "numerator_errors": {
                   "this": 3628.4,
                   "CBSA": 11183.9,
                   "state": 27758.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 66,
                   "state": 70
                },
                "error_ratio": {
                   "this": 5,
                   "CBSA": 1.6,
                   "state": 0.5
                }
             },
             "multi_unit": {
                "name": "Multi-unit",
                "values": {
                   "this": 57.04,
                   "CBSA": 29.07,
                   "state": 30.65
                },
                "error": {
                   "this": 3.66,
                   "CBSA": 1.19,
                   "state": 0.33
                },
                "numerators": {
                   "this": 78971,
                   "CBSA": 313076,
                   "state": 2965237
                },
                "numerator_errors": {
                   "this": 5825.7,
                   "CBSA": 12863.9,
                   "state": 31954.3
                },
                "index": {
                   "this": 100,
                   "CBSA": 196,
                   "state": 186
                },
                "error_ratio": {
                   "this": 6.4,
                   "CBSA": 4.1,
                   "state": 1.1
                }
             },
             "mobile_home": {
                "name": "Mobile home",
                "values": {
                   "this": 0.28,
                   "CBSA": 6.08,
                   "state": 8.83
                },
                "error": {
                   "this": 0.22,
                   "CBSA": 0.48,
                   "state": 0.15
                },
                "numerators": {
                   "this": 394,
                   "CBSA": 65499,
                   "state": 854599
                },
                "numerator_errors": {
                   "this": 303,
                   "CBSA": 5128,
                   "state": 14660
                },
                "index": {
                   "this": 100,
                   "CBSA": 5,
                   "state": 3
                },
                "error_ratio": {
                   "this": 78.6,
                   "CBSA": 7.9,
                   "state": 1.7
                }
             },
             "vehicle": {
                "name": "Boat, RV, van, etc.",
                "values": {
                   "this": 0.14,
                   "CBSA": 0.2,
                   "state": 0.16
                },
                "error": {
                   "this": 0.22,
                   "CBSA": 0.11,
                   "state": 0.02
                },
                "numerators": {
                   "this": 191,
                   "CBSA": 2181,
                   "state": 15832
                },
                "numerator_errors": {
                   "this": 309,
                   "CBSA": 1133,
                   "state": 2088
                },
                "index": {
                   "this": 100,
                   "CBSA": 70,
                   "state": 88
                },
                "error_ratio": {
                   "this": 157.1,
                   "CBSA": 55,
                   "state": 12.5
                }
             }
          }
       },
       "ownership": {
          "distribution": {
             "metadata": {
                "table_id": "B25003",
                "universe": "Occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "owner": {
                "name": "Owner occupied",
                "values": {
                   "this": 37.68,
                   "CBSA": 61.91,
                   "state": 66.25
                },
                "error": {
                   "this": 2.83,
                   "CBSA": 1.01,
                   "state": 0.32
                },
                "numerators": {
                   "this": 41239,
                   "CBSA": 550859,
                   "state": 5237519
                },
                "numerator_errors": {
                   "this": 3496,
                   "CBSA": 10649,
                   "state": 29214
                },
                "index": {
                   "this": 100,
                   "CBSA": 61,
                   "state": 57
                },
                "error_ratio": {
                   "this": 7.5,
                   "CBSA": 1.6,
                   "state": 0.5
                }
             },
             "renter": {
                "name": "Renter occupied",
                "values": {
                   "this": 62.32,
                   "CBSA": 38.09,
                   "state": 33.75
                },
                "error": {
                   "this": 3.09,
                   "CBSA": 1.12,
                   "state": 0.34
                },
                "numerators": {
                   "this": 68215,
                   "CBSA": 338963,
                   "state": 2668313
                },
                "numerator_errors": {
                   "this": 4322,
                   "CBSA": 10538,
                   "state": 27920
                },
                "index": {
                   "this": 100,
                   "CBSA": 164,
                   "state": 185
                },
                "error_ratio": {
                   "this": 5,
                   "CBSA": 2.9,
                   "state": 1
                }
             }
          },
          "median_value": {
             "name": "Median value of owner-occupied housing units",
             "values": {
                "this": 260800,
                "CBSA": 255500,
                "state": 245100
             },
             "error": {
                "this": 18381,
                "CBSA": 3677,
                "state": 1183
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B25077",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 102,
                "state": 106
             },
             "error_ratio": {
                "this": 7,
                "CBSA": 1.4,
                "state": 0.5
             }
          },
          "value_distribution": {
             "metadata": {
                "table_id": "B25075",
                "universe": "Owner-occupied housing units",
                "acs_release": "ACS 2019 1-year"
             },
             "under_100": {
                "name": "Under $100K",
                "values": {
                   "this": 6.57,
                   "CBSA": 10.12,
                   "state": 13.83
                },
                "error": {
                   "this": 2.36,
                   "CBSA": 0.82,
                   "state": 0.27
                },
                "numerators": {
                   "this": 2710,
                   "CBSA": 55771,
                   "state": 724456
                },
                "numerator_errors": {
                   "this": 1000.7,
                   "CBSA": 4657.7,
                   "state": 14677.5
                },
                "index": {
                   "this": 100,
                   "CBSA": 65,
                   "state": 48
                },
                "error_ratio": {
                   "this": 35.9,
                   "CBSA": 8.1,
                   "state": 2
                }
             },
             "100_to_200": {
                "name": "$100K - $200K",
                "values": {
                   "this": 27,
                   "CBSA": 20.97,
                   "state": 23.7
                },
                "error": {
                   "this": 4.18,
                   "CBSA": 1.12,
                   "state": 0.36
                },
                "numerators": {
                   "this": 11136,
                   "CBSA": 115527,
                   "state": 1241487
                },
                "numerator_errors": {
                   "this": 1964,
                   "CBSA": 6564.4,
                   "state": 20255.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 129,
                   "state": 114
                },
                "error_ratio": {
                   "this": 15.5,
                   "CBSA": 5.3,
                   "state": 1.5
                }
             },
             "200_to_300": {
                "name": "$200K - $300K",
                "values": {
                   "this": 25.39,
                   "CBSA": 33.19,
                   "state": 26.43
                },
                "error": {
                   "this": 4.39,
                   "CBSA": 1.27,
                   "state": 0.4
                },
                "numerators": {
                   "this": 10471,
                   "CBSA": 182820,
                   "state": 1384430
                },
                "numerator_errors": {
                   "this": 2016.9,
                   "CBSA": 7846.8,
                   "state": 22091.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 76,
                   "state": 96
                },
                "error_ratio": {
                   "this": 17.3,
                   "CBSA": 3.8,
                   "state": 1.5
                }
             },
             "300_to_400": {
                "name": "$300K - $400K",
                "values": {
                   "this": 19.43,
                   "CBSA": 18.93,
                   "state": 16.11
                },
                "error": {
                   "this": 3.27,
                   "CBSA": 0.92,
                   "state": 0.26
                },
                "numerators": {
                   "this": 8011,
                   "CBSA": 104251,
                   "state": 843691
                },
                "numerator_errors": {
                   "this": 1511,
                   "CBSA": 5447,
                   "state": 14341
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 121
                },
                "error_ratio": {
                   "this": 16.8,
                   "CBSA": 4.9,
                   "state": 1.6
                }
             },
             "400_to_500": {
                "name": "$400K - $500K",
                "values": {
                   "this": 8.75,
                   "CBSA": 7.95,
                   "state": 7.91
                },
                "error": {
                   "this": 3.1,
                   "CBSA": 0.7,
                   "state": 0.18
                },
                "numerators": {
                   "this": 3607,
                   "CBSA": 43773,
                   "state": 414400
                },
                "numerator_errors": {
                   "this": 1313,
                   "CBSA": 3964,
                   "state": 9828
                },
                "index": {
                   "this": 100,
                   "CBSA": 110,
                   "state": 111
                },
                "error_ratio": {
                   "this": 35.4,
                   "CBSA": 8.8,
                   "state": 2.3
                }
             },
             "500_to_1000000": {
                "name": "$500K - $1M",
                "values": {
                   "this": 9.94,
                   "CBSA": 6.98,
                   "state": 9.29
                },
                "error": {
                   "this": 2.2,
                   "CBSA": 0.59,
                   "state": 0.2
                },
                "numerators": {
                   "this": 4101,
                   "CBSA": 38455,
                   "state": 486654
                },
                "numerator_errors": {
                   "this": 973.1,
                   "CBSA": 3342.9,
                   "state": 10879.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 142,
                   "state": 107
                },
                "error_ratio": {
                   "this": 22.1,
                   "CBSA": 8.5,
                   "state": 2.2
                }
             },
             "over_1000000": {
                "name": "Over $1M",
                "values": {
                   "this": 1.73,
                   "CBSA": 0.99,
                   "state": 1.3
                },
                "error": {
                   "this": 1.16,
                   "CBSA": 0.26,
                   "state": 0.08
                },
                "numerators": {
                   "this": 714,
                   "CBSA": 5463,
                   "state": 68322
                },
                "numerator_errors": {
                   "this": 483,
                   "CBSA": 1462,
                   "state": 4130
                },
                "index": {
                   "this": 100,
                   "CBSA": 175,
                   "state": 133
                },
                "error_ratio": {
                   "this": 67.1,
                   "CBSA": 26.3,
                   "state": 6.2
                }
             }
          },
          "total_value": {
             "name": "Total value of owner-occupied housing units",
             "values": {
                "this": 41239,
                "CBSA": 550859,
                "state": 5237519
             },
             "error": {
                "this": 3496,
                "CBSA": 10649,
                "state": 29214
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "index": {
                "this": 100,
                "CBSA": 7,
                "state": 1
             },
             "error_ratio": {
                "this": 8.5,
                "CBSA": 1.9,
                "state": 0.6
             }
          }
       },
       "length_of_tenure": {
          "metadata": {
             "table_id": "B25026",
             "universe": "Total population in occupied housing units",
             "acs_release": "ACS 2019 1-year"
          },
          "Before_1990": {
             "name": "Before 1990",
             "values": {
                "this": 4.7,
                "CBSA": 4.78,
                "state": 6.28
             },
             "error": {
                "this": 1.41,
                "CBSA": 0.4,
                "state": 0.18
             },
             "numerators": {
                "this": 13423,
                "CBSA": 122850,
                "state": 1320786
             },
             "numerator_errors": {
                "this": 4036.3,
                "CBSA": 10247.7,
                "state": 37316
             },
             "index": {
                "this": 100,
                "CBSA": 98,
                "state": 75
             },
             "error_ratio": {
                "this": 30,
                "CBSA": 8.4,
                "state": 2.9
             }
          },
          "1990s": {
             "name": "1990s",
             "values": {
                "this": 4.62,
                "CBSA": 8.37,
                "state": 9.9
             },
             "error": {
                "this": 1.32,
                "CBSA": 0.57,
                "state": 0.22
             },
             "numerators": {
                "this": 13191,
                "CBSA": 215000,
                "state": 2083608
             },
             "numerator_errors": {
                "this": 3755.3,
                "CBSA": 14624,
                "state": 47081.4
             },
             "index": {
                "this": 100,
                "CBSA": 55,
                "state": 47
             },
             "error_ratio": {
                "this": 28.6,
                "CBSA": 6.8,
                "state": 2.2
             }
          },
          "2000s": {
             "name": "2000s",
             "values": {
                "this": 11.87,
                "CBSA": 17.87,
                "state": 19.97
             },
             "error": {
                "this": 2.89,
                "CBSA": 0.92,
                "state": 0.31
             },
             "numerators": {
                "this": 33888,
                "CBSA": 459038,
                "state": 4202997
             },
             "numerator_errors": {
                "this": 8246,
                "CBSA": 23705,
                "state": 66207.4
             },
             "index": {
                "this": 100,
                "CBSA": 66,
                "state": 59
             },
             "error_ratio": {
                "this": 24.3,
                "CBSA": 5.1,
                "state": 1.6
             }
          },
          "2010_to_2014": {
             "name": "2010-2014",
             "values": {
                "this": 18.48,
                "CBSA": 17.82,
                "state": 17.64
             },
             "error": {
                "this": 3.07,
                "CBSA": 1.09,
                "state": 0.33
             },
             "numerators": {
                "this": 52759,
                "CBSA": 457722,
                "state": 3712864
             },
             "numerator_errors": {
                "this": 8752.8,
                "CBSA": 27910.1,
                "state": 69482.8
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 105
             },
             "error_ratio": {
                "this": 16.6,
                "CBSA": 6.1,
                "state": 1.9
             }
          },
          "2015_to_2016": {
             "name": "2015-2016",
             "values": {
                "this": 15.75,
                "CBSA": 15.78,
                "state": 14.95
             },
             "error": {
                "this": 2.8,
                "CBSA": 1.02,
                "state": 0.28
             },
             "numerators": {
                "this": 44967,
                "CBSA": 405522,
                "state": 3146119
             },
             "numerator_errors": {
                "this": 8005.6,
                "CBSA": 26169.2,
                "state": 58783.6
             },
             "index": {
                "this": 100,
                "CBSA": 100,
                "state": 105
             },
             "error_ratio": {
                "this": 17.8,
                "CBSA": 6.5,
                "state": 1.9
             }
          },
          "since_2017": {
             "name": "Since 2017",
             "values": {
                "this": 44.57,
                "CBSA": 35.38,
                "state": 31.27
             },
             "error": {
                "this": 3.72,
                "CBSA": 1.25,
                "state": 0.43
             },
             "numerators": {
                "this": 127204,
                "CBSA": 909017,
                "state": 6581388
             },
             "numerator_errors": {
                "this": 10627.2,
                "CBSA": 32280,
                "state": 89974.5
             },
             "index": {
                "this": 100,
                "CBSA": 126,
                "state": 143
             },
             "error_ratio": {
                "this": 8.3,
                "CBSA": 3.5,
                "state": 1.4
             }
          }
       },
       "migration": {
          "moved_since_previous_year": {
             "name": "Moved since previous year",
             "values": {
                "this": 19.34,
                "CBSA": 16.16,
                "state": 14.98
             },
             "error": {
                "this": 2.52,
                "CBSA": 0.92,
                "state": 0.26
             },
             "numerators": {
                "this": 54847,
                "CBSA": 417165,
                "state": 3186224
             },
             "numerator_errors": {
                "this": 7158.7,
                "CBSA": 23670.5,
                "state": 55673.1
             },
             "metadata": {
                "table_id": "B07003",
                "universe": "Population 1 year and over in the United States",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 120,
                "state": 129
             },
             "error_ratio": {
                "this": 13,
                "CBSA": 5.7,
                "state": 1.7
             }
          }
       },
       "migration_distribution": {
          "metadata": {
             "table_id": "B07003",
             "universe": "Population 1 year and over in the United States",
             "acs_release": "ACS 2019 1-year"
          },
          "same_house_year_ago": {
             "name": "Same house year ago",
             "values": {
                "this": 80.66,
                "CBSA": 83.84,
                "state": 85.02
             },
             "error": {
                "this": 2.64,
                "CBSA": 0.82,
                "state": 0.26
             },
             "numerators": {
                "this": 228817,
                "CBSA": 2163743,
                "state": 1.8083184E7
             },
             "numerator_errors": {
                "this": 7620,
                "CBSA": 21298,
                "state": 55882
             },
             "index": {
                "this": 100,
                "CBSA": 96,
                "state": 95
             },
             "error_ratio": {
                "this": 3.3,
                "CBSA": 1,
                "state": 0.3
             }
          },
          "moved_same_county": {
             "name": "From same county",
             "values": {
                "this": 12.15,
                "CBSA": 7.94,
                "state": 8.14
             },
             "error": {
                "this": 2.07,
                "CBSA": 0.7,
                "state": 0.2
             },
             "numerators": {
                "this": 34465,
                "CBSA": 204836,
                "state": 1731259
             },
             "numerator_errors": {
                "this": 5872,
                "CBSA": 17947,
                "state": 43286
             },
             "index": {
                "this": 100,
                "CBSA": 153,
                "state": 149
             },
             "error_ratio": {
                "this": 17,
                "CBSA": 8.8,
                "state": 2.5
             }
          },
          "moved_different_county": {
             "name": "From different county",
             "values": {
                "this": 2.9,
                "CBSA": 4.07,
                "state": 3.06
             },
             "error": {
                "this": 0.98,
                "CBSA": 0.39,
                "state": 0.1
             },
             "numerators": {
                "this": 8223,
                "CBSA": 105038,
                "state": 649949
             },
             "numerator_errors": {
                "this": 2773,
                "CBSA": 10185,
                "state": 21110
             },
             "index": {
                "this": 100,
                "CBSA": 71,
                "state": 95
             },
             "error_ratio": {
                "this": 33.8,
                "CBSA": 9.6,
                "state": 3.3
             }
          },
          "moved_different_state": {
             "name": "From different state",
             "values": {
                "this": 2.55,
                "CBSA": 2.96,
                "state": 2.83
             },
             "error": {
                "this": 0.74,
                "CBSA": 0.38,
                "state": 0.12
             },
             "numerators": {
                "this": 7221,
                "CBSA": 76402,
                "state": 601611
             },
             "numerator_errors": {
                "this": 2088,
                "CBSA": 9918,
                "state": 24764
             },
             "index": {
                "this": 100,
                "CBSA": 86,
                "state": 90
             },
             "error_ratio": {
                "this": 29,
                "CBSA": 12.8,
                "state": 4.2
             }
          },
          "moved_from_abroad": {
             "name": "From abroad",
             "values": {
                "this": 1.74,
                "CBSA": 1.2,
                "state": 0.96
             },
             "error": {
                "this": 0.77,
                "CBSA": 0.23,
                "state": 0.06
             },
             "numerators": {
                "this": 4938,
                "CBSA": 30889,
                "state": 203405
             },
             "numerator_errors": {
                "this": 2172,
                "CBSA": 6008,
                "state": 12920
             },
             "index": {
                "this": 100,
                "CBSA": 145,
                "state": 181
             },
             "error_ratio": {
                "this": 44.3,
                "CBSA": 19.2,
                "state": 6.2
             }
          }
       }
    },
    "social": {
       "educational_attainment": {
          "percent_high_school_grad_or_higher": {
             "name": "High school grad or higher",
             "values": {
                "this": 91.69,
                "CBSA": 89.07,
                "state": 88.41
             },
             "error": {
                "this": 4.03,
                "CBSA": 1.43,
                "state": 0.45
             },
             "numerators": {
                "this": 186098,
                "CBSA": 1603529,
                "state": 1.3689867E7
             },
             "numerator_errors": {
                "this": 9468.4,
                "CBSA": 25793.4,
                "state": 70596.4
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 103,
                "state": 104
             },
             "error_ratio": {
                "this": 4.4,
                "CBSA": 1.6,
                "state": 0.5
             }
          },
          "percent_bachelor_degree_or_higher": {
             "name": "Bachelor's degree or higher",
             "values": {
                "this": 40.94,
                "CBSA": 33.34,
                "state": 30.7
             },
             "error": {
                "this": 2.64,
                "CBSA": 0.81,
                "state": 0.25
             },
             "numerators": {
                "this": 83103,
                "CBSA": 600229,
                "state": 4753637
             },
             "numerator_errors": {
                "this": 5764.9,
                "CBSA": 14671.5,
                "state": 39064.4
             },
             "metadata": {
                "table_id": "B15002",
                "universe": "Population 25 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 123,
                "state": 133
             },
             "error_ratio": {
                "this": 6.4,
                "CBSA": 2.4,
                "state": 0.8
             }
          }
       },
       "educational_attainment_distribution": {
          "metadata": {
             "table_id": "B15002",
             "universe": "Population 25 years and over",
             "acs_release": "ACS 2019 1-year"
          },
          "non_high_school_grad": {
             "name": "No degree",
             "values": {
                "this": 8.31,
                "CBSA": 10.93,
                "state": 11.59
             },
             "error": {
                "this": 1.52,
                "CBSA": 0.6,
                "state": 0.18
             },
             "numerators": {
                "this": 16866,
                "CBSA": 196771,
                "state": 1794635
             },
             "numerator_errors": {
                "this": 3116.7,
                "CBSA": 10857.1,
                "state": 27913.7
             },
             "index": {
                "this": 100,
                "CBSA": 76,
                "state": 72
             },
             "error_ratio": {
                "this": 18.3,
                "CBSA": 5.5,
                "state": 1.6
             }
          },
          "high_school_grad": {
             "name": "High school",
             "values": {
                "this": 23.86,
                "CBSA": 25.7,
                "state": 28.39
             },
             "error": {
                "this": 2.44,
                "CBSA": 0.83,
                "state": 0.26
             },
             "numerators": {
                "this": 48435,
                "CBSA": 462744,
                "state": 4396122
             },
             "numerator_errors": {
                "this": 5095.1,
                "CBSA": 14915.9,
                "state": 39956.9
             },
             "index": {
                "this": 100,
                "CBSA": 93,
                "state": 84
             },
             "error_ratio": {
                "this": 10.2,
                "CBSA": 3.2,
                "state": 0.9
             }
          },
          "some_college": {
             "name": "Some college",
             "values": {
                "this": 26.88,
                "CBSA": 30.03,
                "state": 29.32
             },
             "error": {
                "this": 2.63,
                "CBSA": 0.84,
                "state": 0.28
             },
             "numerators": {
                "this": 54560,
                "CBSA": 540556,
                "state": 4540108
             },
             "numerator_errors": {
                "this": 5518.7,
                "CBSA": 15085.2,
                "state": 43142.5
             },
             "index": {
                "this": 100,
                "CBSA": 90,
                "state": 92
             },
             "error_ratio": {
                "this": 9.8,
                "CBSA": 2.8,
                "state": 1
             }
          },
          "Bachelor_degree": {
             "name": "Bachelor's",
             "values": {
                "this": 27.84,
                "CBSA": 21.77,
                "state": 19.26
             },
             "error": {
                "this": 2.22,
                "CBSA": 0.64,
                "state": 0.2
             },
             "numerators": {
                "this": 56502,
                "CBSA": 391960,
                "state": 2982643
             },
             "numerator_errors": {
                "this": 4735.8,
                "CBSA": 11477.1,
                "state": 30957.4
             },
             "index": {
                "this": 100,
                "CBSA": 128,
                "state": 145
             },
             "error_ratio": {
                "this": 8,
                "CBSA": 2.9,
                "state": 1
             }
          },
          "post_grad_degree": {
             "name": "Post-grad",
             "values": {
                "this": 13.11,
                "CBSA": 11.57,
                "state": 11.44
             },
             "error": {
                "this": 1.58,
                "CBSA": 0.51,
                "state": 0.15
             },
             "numerators": {
                "this": 26601,
                "CBSA": 208269,
                "state": 1770994
             },
             "numerator_errors": {
                "this": 3287.3,
                "CBSA": 9139.4,
                "state": 23825.8
             },
             "index": {
                "this": 100,
                "CBSA": 113,
                "state": 115
             },
             "error_ratio": {
                "this": 12.1,
                "CBSA": 4.4,
                "state": 1.3
             }
          }
       },
       "place_of_birth": {
          "percent_foreign_born": {
             "name": "Foreign-born population",
             "values": {
                "this": 19.93,
                "CBSA": 19.12,
                "state": 21.07
             },
             "error": {
                "this": 2.53,
                "CBSA": 0.82,
                "state": 0.21
             },
             "numerators": {
                "this": 57273,
                "CBSA": 498789,
                "state": 4526428
             },
             "numerator_errors": {
                "this": 7277,
                "CBSA": 21291,
                "state": 45671
             },
             "metadata": {
                "table_id": "B05002",
                "universe": "Total population",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 104,
                "state": 95
             },
             "error_ratio": {
                "this": 12.7,
                "CBSA": 4.3,
                "state": 1
             }
          },
          "distribution": {
             "metadata": {
                "table_id": "B05006",
                "universe": "Foreign-born population",
                "acs_release": "ACS 2019 5-year"
             },
             "europe": {
                "name": "Europe",
                "values": {
                   "this": 6.75,
                   "CBSA": 8.07,
                   "state": 9.38
                },
                "error": {
                   "this": 1.08,
                   "CBSA": 0.43,
                   "state": 0.14
                },
                "numerators": {
                   "this": 4172,
                   "CBSA": 37420,
                   "state": 405571
                },
                "numerator_errors": {
                   "this": 690,
                   "CBSA": 2110,
                   "state": 6345
                },
                "index": {
                   "this": 100,
                   "CBSA": 84,
                   "state": 72
                },
                "error_ratio": {
                   "this": 16,
                   "CBSA": 5.3,
                   "state": 1.5
                }
             },
             "asia": {
                "name": "Asia",
                "values": {
                   "this": 13.24,
                   "CBSA": 16.18,
                   "state": 10.62
                },
                "error": {
                   "this": 1.37,
                   "CBSA": 0.35,
                   "state": 0.13
                },
                "numerators": {
                   "this": 8176,
                   "CBSA": 75010,
                   "state": 459111
                },
                "numerator_errors": {
                   "this": 907,
                   "CBSA": 2097,
                   "state": 5908
                },
                "index": {
                   "this": 100,
                   "CBSA": 82,
                   "state": 125
                },
                "error_ratio": {
                   "this": 10.3,
                   "CBSA": 2.2,
                   "state": 1.2
                }
             },
             "africa": {
                "name": "Africa",
                "values": {
                   "this": 2.86,
                   "CBSA": 3.29,
                   "state": 1.77
                },
                "error": {
                   "this": 0.63,
                   "CBSA": 0.33,
                   "state": 0.06
                },
                "numerators": {
                   "this": 1765,
                   "CBSA": 15248,
                   "state": 76402
                },
                "numerator_errors": {
                   "this": 397,
                   "CBSA": 1539,
                   "state": 2706
                },
                "index": {
                   "this": 100,
                   "CBSA": 87,
                   "state": 162
                },
                "error_ratio": {
                   "this": 22,
                   "CBSA": 10,
                   "state": 3.4
                }
             },
             "oceania": {
                "name": "Oceania",
                "values": {
                   "this": 0.09,
                   "CBSA": 0.18,
                   "state": 0.19
                },
                "error": {
                   "this": 0.08,
                   "CBSA": 0.07,
                   "state": 0.02
                },
                "numerators": {
                   "this": 57,
                   "CBSA": 845,
                   "state": 8402
                },
                "numerator_errors": {
                   "this": 49,
                   "CBSA": 312,
                   "state": 827
                },
                "index": {
                   "this": 100,
                   "CBSA": 50,
                   "state": 47
                },
                "error_ratio": {
                   "this": 88.9,
                   "CBSA": 38.9,
                   "state": 10.5
                }
             },
             "latin_america": {
                "name": "Latin America",
                "values": {
                   "this": 75.66,
                   "CBSA": 70.5,
                   "state": 75.43
                },
                "error": {
                   "this": 2.54,
                   "CBSA": 1.11,
                   "state": 0.17
                },
                "numerators": {
                   "this": 46732,
                   "CBSA": 326788,
                   "state": 3262273
                },
                "numerator_errors": {
                   "this": 2444,
                   "CBSA": 7702,
                   "state": 16950
                },
                "index": {
                   "this": 100,
                   "CBSA": 107,
                   "state": 100
                },
                "error_ratio": {
                   "this": 3.4,
                   "CBSA": 1.6,
                   "state": 0.2
                }
             },
             "north_america": {
                "name": "North America",
                "values": {
                   "this": 1.39,
                   "CBSA": 1.77,
                   "state": 2.61
                },
                "error": {
                   "this": 0.34,
                   "CBSA": 0.16,
                   "state": 0.07
                },
                "numerators": {
                   "this": 860,
                   "CBSA": 8214,
                   "state": 113041
                },
                "numerator_errors": {
                   "this": 211,
                   "CBSA": 770,
                   "state": 3092
                },
                "index": {
                   "this": 100,
                   "CBSA": 79,
                   "state": 53
                },
                "error_ratio": {
                   "this": 24.5,
                   "CBSA": 9,
                   "state": 2.7
                }
             }
          }
       },
       "language": {
          "percent_non_english_at_home": {
             "name": "Persons with language other than English spoken at home",
             "values": {
                "this": null,
                "CBSA": null,
                "state": 29.42
             },
             "error": {
                "this": null,
                "CBSA": null,
                "state": 0.12
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": 5816550
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": 22773.5
             },
             "metadata": {
                "table_id": "B16001",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "index": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "error_ratio": {
                "this": null,
                "CBSA": null,
                "state": 0.4
             }
          },
          "children": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 52.61,
                   "CBSA": 65.85,
                   "state": 70.2
                },
                "error": {
                   "this": 7.52,
                   "CBSA": 2.05,
                   "state": 0.59
                },
                "numerators": {
                   "this": 23823,
                   "CBSA": 274605,
                   "state": 2176830
                },
                "numerator_errors": {
                   "this": 4449,
                   "CBSA": 8626,
                   "state": 18545
                },
                "index": {
                   "this": 100,
                   "CBSA": 80,
                   "state": 75
                },
                "error_ratio": {
                   "this": 14.3,
                   "CBSA": 3.1,
                   "state": 0.8
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 38.26,
                   "CBSA": 25.55,
                   "state": 22.87
                },
                "error": {
                   "this": 7.02,
                   "CBSA": 1.52,
                   "state": 0.42
                },
                "numerators": {
                   "this": 17324,
                   "CBSA": 106531,
                   "state": 709195
                },
                "numerator_errors": {
                   "this": 3800,
                   "CBSA": 6351,
                   "state": 13153
                },
                "index": {
                   "this": 100,
                   "CBSA": 150,
                   "state": 167
                },
                "error_ratio": {
                   "this": 18.3,
                   "CBSA": 5.9,
                   "state": 1.8
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 8,
                   "CBSA": 6.27,
                   "state": 5.09
                },
                "error": {
                   "this": 4.45,
                   "CBSA": 1.28,
                   "state": 0.35
                },
                "numerators": {
                   "this": 3624,
                   "CBSA": 26147,
                   "state": 157694
                },
                "numerator_errors": {
                   "this": 2060,
                   "CBSA": 5341,
                   "state": 10919
                },
                "index": {
                   "this": 100,
                   "CBSA": 128,
                   "state": 157
                },
                "error_ratio": {
                   "this": 55.6,
                   "CBSA": 20.4,
                   "state": 6.9
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 0.77,
                   "CBSA": 1.38,
                   "state": 1.11
                },
                "error": {
                   "this": 0.71,
                   "CBSA": 0.4,
                   "state": 0.12
                },
                "numerators": {
                   "this": 349,
                   "CBSA": 5738,
                   "state": 34478
                },
                "numerator_errors": {
                   "this": 325,
                   "CBSA": 1676,
                   "state": 3796
                },
                "index": {
                   "this": 100,
                   "CBSA": 56,
                   "state": 69
                },
                "error_ratio": {
                   "this": 92.2,
                   "CBSA": 29,
                   "state": 10.8
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 0.36,
                   "CBSA": 0.95,
                   "state": 0.73
                },
                "error": {
                   "this": 0.6,
                   "CBSA": 0.61,
                   "state": 0.17
                },
                "numerators": {
                   "this": 165,
                   "CBSA": 3967,
                   "state": 22610
                },
                "numerator_errors": {
                   "this": 274,
                   "CBSA": 2523,
                   "state": 5266
                },
                "index": {
                   "this": 100,
                   "CBSA": 38,
                   "state": 49
                },
                "error_ratio": {
                   "this": 166.7,
                   "CBSA": 64.2,
                   "state": 23.3
                }
             }
          },
          "adults": {
             "metadata": {
                "table_id": "B16007",
                "universe": "Population 5 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "english": {
                "name": "English only",
                "values": {
                   "this": 60.69,
                   "CBSA": 65.19,
                   "state": 69.66
                },
                "error": {
                   "this": 2.64,
                   "CBSA": 0.75,
                   "state": 0.19
                },
                "numerators": {
                   "this": 135619,
                   "CBSA": 1331816,
                   "state": 1.201361E7
                },
                "numerator_errors": {
                   "this": 6978.7,
                   "CBSA": 15329.6,
                   "state": 32883.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 93,
                   "state": 87
                },
                "error_ratio": {
                   "this": 4.3,
                   "CBSA": 1.2,
                   "state": 0.3
                }
             },
             "spanish": {
                "name": "Spanish",
                "values": {
                   "this": 30.54,
                   "CBSA": 26.13,
                   "state": 22.39
                },
                "error": {
                   "this": 2.77,
                   "CBSA": 0.47,
                   "state": 0.13
                },
                "numerators": {
                   "this": 68247,
                   "CBSA": 533822,
                   "state": 3862167
                },
                "numerator_errors": {
                   "this": 6471.6,
                   "CBSA": 9510.4,
                   "state": 22204.2
                },
                "index": {
                   "this": 100,
                   "CBSA": 117,
                   "state": 136
                },
                "error_ratio": {
                   "this": 9.1,
                   "CBSA": 1.8,
                   "state": 0.6
                }
             },
             "indoeuropean": {
                "name": "Indo-European",
                "values": {
                   "this": 5.67,
                   "CBSA": 5.53,
                   "state": 5.58
                },
                "error": {
                   "this": 1.57,
                   "CBSA": 0.57,
                   "state": 0.14
                },
                "numerators": {
                   "this": 12678,
                   "CBSA": 113000,
                   "state": 961768
                },
                "numerator_errors": {
                   "this": 3516.3,
                   "CBSA": 11551.1,
                   "state": 24929.4
                },
                "index": {
                   "this": 100,
                   "CBSA": 103,
                   "state": 102
                },
                "error_ratio": {
                   "this": 27.7,
                   "CBSA": 10.3,
                   "state": 2.5
                }
             },
             "asian_islander": {
                "name": "Asian/Islander",
                "values": {
                   "this": 1.97,
                   "CBSA": 2.37,
                   "state": 1.67
                },
                "error": {
                   "this": 0.79,
                   "CBSA": 0.22,
                   "state": 0.07
                },
                "numerators": {
                   "this": 4409,
                   "CBSA": 48442,
                   "state": 287620
                },
                "numerator_errors": {
                   "this": 1758.5,
                   "CBSA": 4549.8,
                   "state": 11233.9
                },
                "index": {
                   "this": 100,
                   "CBSA": 83,
                   "state": 118
                },
                "error_ratio": {
                   "this": 40.1,
                   "CBSA": 9.3,
                   "state": 4.2
                }
             },
             "other": {
                "name": "Other",
                "values": {
                   "this": 1.12,
                   "CBSA": 0.78,
                   "state": 0.7
                },
                "error": {
                   "this": 0.71,
                   "CBSA": 0.21,
                   "state": 0.06
                },
                "numerators": {
                   "this": 2493,
                   "CBSA": 15922,
                   "state": 120617
                },
                "numerator_errors": {
                   "this": 1582.9,
                   "CBSA": 4274.2,
                   "state": 9752.8
                },
                "index": {
                   "this": 100,
                   "CBSA": 144,
                   "state": 160
                },
                "error_ratio": {
                   "this": 63.4,
                   "CBSA": 26.9,
                   "state": 8.6
                }
             }
          }
       },
       "veterans": {
          "wartime_service": {
             "metadata": {
                "table_id": "B21002",
                "universe": "Civilian veterans 18 years and over",
                "acs_release": "ACS 2019 5-year"
             },
             "wwii": {
                "name": "WWII",
                "values": {
                   "this": 303,
                   "CBSA": 3657,
                   "state": 60383
                },
                "error": {
                   "this": 131.14,
                   "CBSA": 439.66,
                   "state": 1787.54
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 8,
                   "state": 1
                },
                "error_ratio": {
                   "this": 43.3,
                   "CBSA": 12,
                   "state": 3
                }
             },
             "korea": {
                "name": "Korea",
                "values": {
                   "this": 599,
                   "CBSA": 11111,
                   "state": 139555
                },
                "error": {
                   "this": 170.87,
                   "CBSA": 635.97,
                   "state": 2617.66
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 5,
                   "state": null
                },
                "error_ratio": {
                   "this": 28.5,
                   "CBSA": 5.7,
                   "state": 1.9
                }
             },
             "vietnam": {
                "name": "Vietnam",
                "values": {
                   "this": 3538,
                   "CBSA": 48780,
                   "state": 519307
                },
                "error": {
                   "this": 468.81,
                   "CBSA": 1558.27,
                   "state": 4878.62
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 7,
                   "state": 1
                },
                "error_ratio": {
                   "this": 13.3,
                   "CBSA": 3.2,
                   "state": 0.9
                }
             },
             "gulf_1990s": {
                "name": "Gulf (1990s)",
                "values": {
                   "this": 3058,
                   "CBSA": 32477,
                   "state": 297325
                },
                "error": {
                   "this": 470.47,
                   "CBSA": 1705.4,
                   "state": 4616.06
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 9,
                   "state": 1
                },
                "error_ratio": {
                   "this": 15.4,
                   "CBSA": 5.3,
                   "state": 1.6
                }
             },
             "gulf_2001": {
                "name": "Gulf (2001-)",
                "values": {
                   "this": 3142,
                   "CBSA": 28626,
                   "state": 253255
                },
                "error": {
                   "this": 504.02,
                   "CBSA": 1852.75,
                   "state": 4424.02
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "index": {
                   "this": 100,
                   "CBSA": 11,
                   "state": 1
                },
                "error_ratio": {
                   "this": 16,
                   "CBSA": 6.5,
                   "state": 1.7
                }
             }
          },
          "sex": {
             "male": {
                "name": "Male",
                "values": {
                   "this": 8515,
                   "CBSA": 122162,
                   "state": 1271464
                },
                "error": {
                   "this": 1687,
                   "CBSA": 6259,
                   "state": 19399
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 7,
                   "state": 1
                },
                "error_ratio": {
                   "this": 19.8,
                   "CBSA": 5.1,
                   "state": 1.5
                }
             },
             "female": {
                "name": "Female",
                "values": {
                   "this": 589,
                   "CBSA": 15762,
                   "state": 130800
                },
                "error": {
                   "this": 394,
                   "CBSA": 2873,
                   "state": 6924
                },
                "numerators": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "numerator_errors": {
                   "this": null,
                   "CBSA": null,
                   "state": null
                },
                "metadata": {
                   "table_id": "B21001",
                   "universe": "Civilian population 18 years and over",
                   "acs_release": "ACS 2019 1-year"
                },
                "index": {
                   "this": 100,
                   "CBSA": 4,
                   "state": null
                },
                "error_ratio": {
                   "this": 66.9,
                   "CBSA": 18.2,
                   "state": 5.3
                }
             }
          },
          "number": {
             "name": "Total veterans",
             "values": {
                "this": 9104,
                "CBSA": 137924,
                "state": 1402264
             },
             "error": {
                "this": 1733,
                "CBSA": 6989,
                "state": 19770
             },
             "numerators": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "numerator_errors": {
                "this": null,
                "CBSA": null,
                "state": null
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 7,
                "state": 1
             },
             "error_ratio": {
                "this": 19,
                "CBSA": 5.1,
                "state": 1.4
             }
          },
          "percentage": {
             "name": "Population with veteran status",
             "values": {
                "this": 4.08,
                "CBSA": 6.76,
                "state": 8.16
             },
             "error": {
                "this": 0.77,
                "CBSA": 0.34,
                "state": 0.12
             },
             "numerators": {
                "this": 9104,
                "CBSA": 137924,
                "state": 1402264
             },
             "numerator_errors": {
                "this": 1733,
                "CBSA": 6989,
                "state": 19770
             },
             "metadata": {
                "table_id": "B21001",
                "universe": "Civilian population 18 years and over",
                "acs_release": "ACS 2019 1-year"
             },
             "index": {
                "this": 100,
                "CBSA": 60,
                "state": 50
             },
             "error_ratio": {
                "this": 18.9,
                "CBSA": 5,
                "state": 1.5
             }
          }
       }
    },
    "geo_metadata": {
       "aland": 286346230,
       "awater": 21332077,
       "display_name": "Orlando, FL",
       "full_geoid": "16000US1253000",
       "population": 275690,
       "simple_name": "Orlando",
       "sumlevel": "160",
       "square_miles": 110.6,
       "population_density": 2599.8
    }
 }

 export default orlando