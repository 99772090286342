exports.PORTOLA_VALLEY_LISTINGS = [
		{
			"closePrice": 1325000,
			"daysOnMovoto": 204,
			"id": "07072766-e5e0-4357-a007-550316755293",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81851227_0_ijqeaZ_p.jpeg",
			"listDate": "2021-06-29 10:05:23",
			"listingAgent": "John Hansen",
			"listPrice": 1495000,
			"lotSize": 10289,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81851227",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Sequoia Realty Services",
			"photoCount": 8,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94028",
			"path": "portola-valley-ca/846-portola-rd-portola-valley-ca-94028-202_ml81591680/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200000,
			"propertyId": "a9b60703-6995-4063-8a7b-bc770e27574b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-20 00:00:00",
			"createdAt": "2021-06-29 17:07:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-29 10:05:23",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.383081,
				"lng": -122.231452,
				"zipcode": "94028",
				"subPremise": "",
				"address": "846 Portola Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1325000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 5000000,
			"daysOnMovoto": 37,
			"id": "bf4247d2-bb50-4499-a649-f83e4d06ddff",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81871871_0_N732bM_p.jpeg",
			"listDate": "2021-12-06 10:51:18",
			"listingAgent": "Shelly Roberson",
			"listPrice": 4495000,
			"lotSize": 20473,
			"sqftTotal": 4030,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81871871",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 48,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1988,
			"zipCode": "94028",
			"path": "portola-valley-ca/3-horseshoe-bnd-portola-valley-ca-94028/pid_qln8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e0056879-1e12-44c6-b2d7-cdbbb4e1d1a3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2022-01-13 00:00:00",
			"createdAt": "2021-12-06 18:57:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-12-06 10:51:18",
			"virtualTourLink": "https://www.3horseshoe.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.36592,
				"lng": -122.206752,
				"zipcode": "94028",
				"subPremise": "",
				"address": "3 Horseshoe Bnd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 5000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://www.3horseshoe.com/"
		},
		{
			"closePrice": 2800000,
			"daysOnMovoto": 108,
			"id": "7d5ecf5a-af7e-489c-aeef-bf91e81a467f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81850043_0_V2RIjv_p.jpeg",
			"listDate": "2021-06-21 14:07:59",
			"listingAgent": "Suzhi Wang",
			"listPrice": 3380000,
			"lotSize": 75794,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81850043",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Goodview Financial & Real Estate",
			"photoCount": 18,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Land",
			"yearBuilt": null,
			"zipCode": "94028",
			"path": "portola-valley-ca/9-buck-meadow-dr-portola-valley-ca-94028-202_ml81412853/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e3e2365a-8939-45da-b06f-d8428b17fd68",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-10-08 00:00:00",
			"createdAt": "2021-06-21 21:12:34",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-21 14:07:59",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.355684,
				"lng": -122.206208,
				"zipcode": "94028",
				"subPremise": "",
				"address": "9 Buck Meadow Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 825000,
			"daysOnMovoto": 538,
			"id": "8f5a118b-b466-43b1-858d-9f3a39dfb89f",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81784543_0_jrIQ7f_p.jpeg",
			"listDate": "2020-02-28 07:13:52",
			"listingAgent": "Michele Morhenn",
			"listPrice": 950000,
			"lotSize": 198198,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81784543",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 38,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "94028",
			"path": "portola-valley-ca/5588-alpine-rd-portola-valley-ca-94028-100_ml81784543/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -45000,
			"propertyId": "e4a2c3cb-6126-4998-abed-9d935809cd85",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-08-20 00:00:00",
			"createdAt": "2020-02-28 15:17:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-02-28 07:13:52",
			"virtualTourLink": "https://player.vimeo.com/video/394074373",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.355072,
				"lng": -122.217483,
				"zipcode": "94028",
				"subPremise": "",
				"address": "5588 Alpine Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 825000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "https://player.vimeo.com/video/394074373"
		},
		{
			"closePrice": 2550000,
			"daysOnMovoto": 41,
			"id": "5d337287-a9d6-450d-985d-6bab620eef52",
			"tnImgPath": "https://pi.movoto.com/p/110/421560646_0_b2VjZq_p.jpeg",
			"listDate": "2021-06-01 00:00:00",
			"listingAgent": "Scott Whelan",
			"listPrice": 2800000,
			"lotSize": 13000,
			"sqftTotal": 2120,
			"mlsDbNumber": 110,
			"mls": {
				"dateHidden": null,
				"id": 110
			},
			"mlsNumber": "421560646",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass SF",
			"photoCount": 51,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1978,
			"zipCode": "94028",
			"path": "portola-valley-ca/227-la-cuesta-dr-portola-valley-ca-94028-100_80521306/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "852a0450-9373-48d9-812f-10ee4afd8e47",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-07-12 00:00:00",
			"createdAt": "2021-06-10 18:46:29",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-06-01 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.403073,
				"lng": -122.194126,
				"zipcode": "94028",
				"subPremise": "",
				"address": "227 La Cuesta Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2550000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 650000,
			"daysOnMovoto": 59,
			"id": "41b7236d-4c18-4862-9e0c-699ed7c35e18",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81839350_0_ruZ7nv_p.jpeg",
			"listDate": "2021-04-16 10:43:15",
			"listingAgent": "Shaler B Barnes",
			"listPrice": 699000,
			"lotSize": 27443,
			"sqftTotal": null,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81839350",
			"bath": null,
			"bed": null,
			"openHouses": [],
			"officeListName": "Cowperthwaite & Company",
			"photoCount": 10,
			"propertyType": "LAND",
			"propertyTypeDisplayName": "Residential Lots/Land",
			"yearBuilt": null,
			"zipCode": "94028",
			"path": "portola-valley-ca/0-foxwoodramona-rd-portola-valley-ca-94028-100_ml81839350/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3b969094-eb68-4d30-8cfb-9e5064e8854b",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-15 00:00:00",
			"createdAt": "2021-04-16 17:47:32",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-04-16 10:43:15",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"zipcode": "94028",
				"subPremise": "",
				"address": "0 Foxwoodramona Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 650000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3000000,
			"daysOnMovoto": 0,
			"id": "45129620-fbd6-4d88-946b-55784a659402",
			"tnImgPath": "https://pi.movoto.com/p/102/421520186_0_RAEZVZ_p.jpeg",
			"listDate": null,
			"listingAgent": "Michelle De Bord-Ridge",
			"listPrice": 3498999,
			"lotSize": 57499,
			"sqftTotal": 2690,
			"mlsDbNumber": 102,
			"mls": {
				"dateHidden": null,
				"id": 102
			},
			"mlsNumber": "421520186",
			"bath": 4,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Chase International",
			"photoCount": 56,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Residential - Single Family",
			"yearBuilt": 1974,
			"zipCode": "94028",
			"path": "portola-valley-ca/277-old-spanish-trl-portola-valley-ca-94028/pid_b3n8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "3d524dbc-7bea-4871-9e50-145746a1f287",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-06-09 00:00:00",
			"createdAt": "2021-02-16 21:19:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-16 21:19:28",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.342926,
				"lng": -122.199395,
				"zipcode": "94028",
				"subPremise": "",
				"address": "277 Old Spanish Trl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3000000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 1500000,
			"daysOnMovoto": 54,
			"id": "18133c2f-282a-4f79-bd2a-16baf011a334",
			"tnImgPath": "https://pi2.movoto.com/1.8.519/novaimgs/images/nophoto_small.svg",
			"listDate": "2021-03-26 00:00:00",
			"listingAgent": "Rebecca Eastman",
			"listPrice": 1600000,
			"lotSize": 12574,
			"sqftTotal": 810,
			"mlsDbNumber": 12,
			"mls": {
				"dateHidden": null,
				"id": 12
			},
			"mlsNumber": "ML81836026",
			"bath": 1,
			"bed": 2,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 0,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Detached",
			"yearBuilt": 1963,
			"zipCode": "94028",
			"path": "portola-valley-ca/167-ramona-rd-portola-valley-ca-94028/pid_dgn8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "9c42315c-b0fe-46cc-8a1d-300cc89c75a1",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-05-19 00:00:00",
			"createdAt": "2021-05-12 03:30:28",
			"imageDownloaderStatus": 0,
			"onMarketDateTime": "2021-03-26 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.348447,
				"lng": -122.196392,
				"zipcode": "94028",
				"subPremise": "",
				"address": "167 Ramona Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 1500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3925000,
			"daysOnMovoto": 54,
			"id": "a41213f4-5bef-4f9e-9949-4373a7b90a85",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81830391_0_jAIE6y_p.jpeg",
			"listDate": "2021-02-18 15:01:06",
			"listingAgent": "Mia Banks",
			"listPrice": 3895000,
			"lotSize": 43560,
			"sqftTotal": 3300,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81830391",
			"bath": 2.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 16,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1987,
			"zipCode": "94028",
			"path": "portola-valley-ca/3-veronica-pl-portola-valley-ca-94028-100_81408377/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4aafe56c-d51e-4dd7-8404-6fd8dd55ccf5",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-14 00:00:00",
			"createdAt": "2021-02-18 23:07:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-18 15:01:06",
			"virtualTourLink": "Http://www.3veronicaplace.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.375281,
				"lng": -122.208912,
				"zipcode": "94028",
				"subPremise": "",
				"address": "3 Veronica Pl"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3925000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.3veronicaplace.com/?mls"
		},
		{
			"closePrice": 2500000,
			"daysOnMovoto": 87,
			"id": "bf5d0a6c-aaf9-4892-91a9-4ab957344beb",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825753_0_f2N3j2_p.jpeg",
			"listDate": "2021-01-14 09:28:03",
			"listingAgent": "Rebecca & Karen",
			"listPrice": 2680000,
			"lotSize": 23649,
			"sqftTotal": 2440,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825753",
			"bath": 3.5,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1973,
			"zipCode": "94028",
			"path": "portola-valley-ca/271-mimosa-way-portola-valley-ca-94028/pid_g9p8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "aab41a5d-2bfe-436e-8524-caf33fce7b7f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-12 00:00:00",
			"createdAt": "2021-01-14 17:32:36",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-14 09:28:03",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2820493",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.399653,
				"lng": -122.204468,
				"zipcode": "94028",
				"subPremise": "",
				"address": "271 Mimosa Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2500000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2820493"
		},
		{
			"closePrice": 2425000,
			"daysOnMovoto": 14,
			"id": "2a633440-d78b-43fb-9737-52171c192261",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81835677_0_ZFuMB7_p.jpeg",
			"listDate": "2021-03-24 14:28:30",
			"listingAgent": "Elaine White",
			"listPrice": 2295000,
			"lotSize": 45738,
			"sqftTotal": 2370,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81835677",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 55,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1979,
			"zipCode": "94028",
			"path": "portola-valley-ca/221-vista-verde-way-portola-valley-ca-94028-100_ml81835677/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4d3b5470-cdde-4177-a3b0-989d065c6f26",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-03-24 21:32:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-24 14:28:30",
			"virtualTourLink": "Http://www.221vistaverde.com/?mls",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.342754,
				"lng": -122.196543,
				"zipcode": "94028",
				"subPremise": "",
				"address": "221 Vista Verde Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2425000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Http://www.221vistaverde.com/?mls"
		},
		{
			"closePrice": 800000,
			"daysOnMovoto": 86,
			"id": "7f6f1d38-1035-49f0-a2e1-6303e82debe5",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81820380_0_7vmyqq_p.jpeg",
			"listDate": "2021-01-12 00:00:00",
			"listingAgent": "Alex H. Wang",
			"listPrice": 798000,
			"lotSize": 7510,
			"sqftTotal": 550,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81820380",
			"bath": 1,
			"bed": 1,
			"openHouses": [],
			"officeListName": "Rainmaker Real Estate",
			"photoCount": 12,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1961,
			"zipCode": "94028",
			"path": "portola-valley-ca/128-carmel-way-portola-valley-ca-94028-100_922001/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -200000,
			"propertyId": "efd3076d-ba30-49f7-820d-c3302072a656",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-08 00:00:00",
			"createdAt": "2021-01-13 04:12:31",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-12 00:00:00",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.350581,
				"lng": -122.196177,
				"zipcode": "94028",
				"subPremise": "",
				"address": "128 Carmel Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 800000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 7400000,
			"daysOnMovoto": 34,
			"id": "bd544c77-a639-4357-a94e-12da98218711",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831549_0_nJA7vn_p.jpeg",
			"listDate": "2021-02-26 10:09:02",
			"listingAgent": "Ginny&John Kavanaugh",
			"listPrice": 8250000,
			"lotSize": 43560,
			"sqftTotal": 3651,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831549",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 34,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1975,
			"zipCode": "94028",
			"path": "portola-valley-ca/67-hillbrook-dr-portola-valley-ca-94028-100_81307428/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "4d708fa8-3c35-4769-9e1b-4be3ef3143d0",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-02 00:00:00",
			"createdAt": "2021-02-26 18:12:40",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-26 10:09:02",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2839296",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.3771,
				"lng": -122.20624,
				"zipcode": "94028",
				"subPremise": "",
				"address": "67 Hillbrook Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 7400000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2839296"
		},
		{
			"closePrice": 3250000,
			"daysOnMovoto": 16,
			"id": "1986bd49-a6fe-4c98-9ff5-4ac8f05f5dd9",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81831852_0_ja2BUi_p.jpeg",
			"listDate": "2021-03-16 00:00:00",
			"listingAgent": "Carrie Davis",
			"listPrice": 3325000,
			"lotSize": 20160,
			"sqftTotal": 2570,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81831852",
			"bath": 2.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 44,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1981,
			"zipCode": "94028",
			"path": "portola-valley-ca/3-fremontia-st-portola-valley-ca-94028-100_81406794/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "34019311-921b-4f63-b16f-d46bdf837cf8",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-04-01 00:00:00",
			"createdAt": "2021-03-17 00:17:25",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-16 00:00:00",
			"virtualTourLink": "Https://3Fremontia37500mls.f8re.com/",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.365045,
				"lng": -122.211281,
				"zipcode": "94028",
				"subPremise": "",
				"address": "3 Fremontia St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3250000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://3Fremontia37500mls.f8re.com/"
		},
		{
			"closePrice": 4875000,
			"daysOnMovoto": 22,
			"id": "75e8e29b-a382-47e7-9a38-c025aba14889",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81832108_0_yu3VjB_p.jpeg",
			"listDate": "2021-03-02 14:23:57",
			"listingAgent": "Dean Asborno",
			"listPrice": 4875000,
			"lotSize": 109031,
			"sqftTotal": 2552,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81832108",
			"bath": 3.5,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 15,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1957,
			"zipCode": "94028",
			"path": "portola-valley-ca/300-alamos-rd-portola-valley-ca-94028/pid_lqn8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "76d7f658-9b37-4679-ac99-f8e5d2212e7f",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-25 00:00:00",
			"createdAt": "2021-03-02 22:27:39",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-03-02 14:23:57",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2841878",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.396029,
				"lng": -122.19549,
				"zipcode": "94028",
				"subPremise": "",
				"address": "300 Alamos Rd"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4875000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2841878"
		},
		{
			"closePrice": 4100000,
			"daysOnMovoto": 130,
			"id": "c8b86942-6987-451a-8268-1697332d142d",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81820065_0_RUmJYF_p.jpeg",
			"listDate": "2020-11-12 14:53:51",
			"listingAgent": "Scott Dancer",
			"listPrice": 4150000,
			"lotSize": 20800,
			"sqftTotal": 3628,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81820065",
			"bath": 3,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 37,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1985,
			"zipCode": "94028",
			"path": "portola-valley-ca/5-pomponio-ct-portola-valley-ca-94028-100_ml81820065/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -125000,
			"propertyId": "e47b9732-fc82-4a81-a778-14998f409955",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-23 00:00:00",
			"createdAt": "2020-11-12 22:57:30",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-11-12 14:53:51",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2817323",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.367958,
				"lng": -122.208436,
				"zipcode": "94028",
				"subPremise": "",
				"address": "5 Pomponio Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4100000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2817323"
		},
		{
			"closePrice": 3750000,
			"daysOnMovoto": 30,
			"id": "5ea75309-9cbf-4806-bd8a-07adaaa362af",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81827847_0_iZIQNN_p.jpeg",
			"listDate": "2021-01-30 18:54:31",
			"listingAgent": "Josh Felder",
			"listPrice": 3599000,
			"lotSize": 45302,
			"sqftTotal": 2250,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81827847",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Redfin",
			"photoCount": 59,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1958,
			"zipCode": "94028",
			"path": "portola-valley-ca/150-fawn-ln-portola-valley-ca-94028-100_80947225/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ce8a63e9-2a0b-4b71-8060-363e08c7e5fd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-03-02 00:00:00",
			"createdAt": "2021-01-31 02:57:28",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-30 18:54:31",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.387524,
				"lng": -122.20729,
				"zipcode": "94028",
				"subPremise": "",
				"address": "150 Fawn Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3750000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 4700000,
			"daysOnMovoto": 141,
			"id": "3c225beb-500a-483b-bd91-7a40c06b45fb",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81814073_0_ynvrAF_p.jpeg",
			"listDate": "2020-10-04 09:52:37",
			"listingAgent": "Ginny&John Kavanaugh",
			"listPrice": 4950000,
			"lotSize": 53143,
			"sqftTotal": 5220,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81814073",
			"bath": 4.5,
			"bed": 5,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 23,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1955,
			"zipCode": "94028",
			"path": "portola-valley-ca/60-golden-oak-dr-portola-valley-ca-94028/pid_k6l8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": -550000,
			"propertyId": "051a1fbd-ec1f-4c28-b5a9-403a6cc540cd",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-23 00:00:00",
			"createdAt": "2020-10-04 16:57:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-10-04 09:52:37",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.378395,
				"lng": -122.200301,
				"zipcode": "94028",
				"subPremise": "",
				"address": "60 Golden Oak Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 4700000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3898000,
			"daysOnMovoto": 14,
			"id": "c4d2de25-8c99-419f-b5fe-b8daea82bbbc",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81828846_0_Nf2yAz_p.jpeg",
			"listDate": "2021-02-07 09:28:58",
			"listingAgent": "Denise Villeneuve",
			"listPrice": 3898000,
			"lotSize": 43996,
			"sqftTotal": 2450,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81828846",
			"bath": 3,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 64,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1957,
			"zipCode": "94028",
			"path": "portola-valley-ca/20-holden-ct-portola-valley-ca-94028-100_81418032/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "8b15df75-b1d3-4039-8c29-2923fe5caaa3",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-22 00:00:00",
			"createdAt": "2021-02-07 17:32:26",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-02-07 09:28:58",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.381602,
				"lng": -122.20392,
				"zipcode": "94028",
				"subPremise": "",
				"address": "20 Holden Ct"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3898000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3708000,
			"daysOnMovoto": 38,
			"id": "65267b11-96df-40fc-b0eb-3c1d270e3106",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825756_0_RFfFRJ_p.jpeg",
			"listDate": "2021-01-14 09:41:15",
			"listingAgent": "Rebecca & Karen",
			"listPrice": 3595000,
			"lotSize": 24002,
			"sqftTotal": 2653,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825756",
			"bath": 3,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Intero Real Estate Services",
			"photoCount": 57,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1960,
			"zipCode": "94028",
			"path": "portola-valley-ca/corona-way-portola-valley-ca-94028-100_949908/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "e10c0ca5-d2d1-40fe-9aea-a2f73df7004d",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-22 00:00:00",
			"createdAt": "2021-01-14 17:47:37",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-14 09:41:15",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2827111",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.39815,
				"lng": -122.19927,
				"zipcode": "94028",
				"subPremise": "",
				"address": "140 Corona Way"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3708000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2827111"
		},
		{
			"closePrice": 2900000,
			"daysOnMovoto": 34,
			"id": "3690b19c-62b6-4e50-bbda-77120a4a1e10",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81825990_0_zf7B7U_p.jpeg",
			"listDate": "2021-01-15 14:29:38",
			"listingAgent": "Ginny&John Kavanaugh",
			"listPrice": 2995000,
			"lotSize": 15939,
			"sqftTotal": 2480,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81825990",
			"bath": 2,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Coldwell Banker Realty",
			"photoCount": 17,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1937,
			"zipCode": "94028",
			"path": "portola-valley-ca/109-brookside-dr-portola-valley-ca-94028/pid_90n8w1f48g/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "37526942-a003-4068-9744-5d2a27045c18",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-19 00:00:00",
			"createdAt": "2021-01-15 22:32:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-15 14:29:38",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.374381,
				"lng": -122.215729,
				"zipcode": "94028",
				"subPremise": "",
				"address": "109 Brookside Dr"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2900000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 3745000,
			"daysOnMovoto": 127,
			"id": "6da94203-57f7-4bab-8f32-b0d6c3b88c60",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81814655_0_yAB7a3_p.jpeg",
			"listDate": "2020-10-07 20:36:44",
			"listingAgent": "Chris Iverson",
			"listPrice": 3795000,
			"lotSize": 29621,
			"sqftTotal": 3400,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81814655",
			"bath": 4,
			"bed": 4,
			"openHouses": [],
			"officeListName": "Golden Gate Sotheby's International Realty",
			"photoCount": 49,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1986,
			"zipCode": "94028",
			"path": "portola-valley-ca/acorn-st-portola-valley-ca-94028-100_80844248/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "fa499625-9fe4-4a39-acee-7aa00abc643a",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-12 00:00:00",
			"createdAt": "2020-10-08 03:42:24",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2020-10-07 20:36:44",
			"virtualTourLink": null,
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.362867,
				"lng": -122.205149,
				"zipcode": "94028",
				"subPremise": "",
				"address": "2 Acorn St"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 3745000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": ""
		},
		{
			"closePrice": 2555000,
			"daysOnMovoto": 38,
			"id": "9a88dcd2-1da0-4483-8630-a449ec488ccf",
			"tnImgPath": "https://pi.movoto.com/p/100/ML81821459_0_RRfqjb_p.jpeg",
			"listDate": "2021-01-02 00:00:00",
			"listingAgent": "Margot Lockwood",
			"listPrice": 2495000,
			"lotSize": 17001,
			"sqftTotal": 1850,
			"mlsDbNumber": 100,
			"mls": {
				"dateHidden": null,
				"id": 100
			},
			"mlsNumber": "ML81821459",
			"bath": 2,
			"bed": 3,
			"openHouses": [],
			"officeListName": "Compass",
			"photoCount": 31,
			"propertyType": "SINGLE_FAMILY_HOUSE",
			"propertyTypeDisplayName": "Single Family",
			"yearBuilt": 1952,
			"zipCode": "94028",
			"path": "portola-valley-ca/186-echo-ln-portola-valley-ca-94028-100_81213828/",
			"status": "Closed",
			"houseRealStatus": "SOLD",
			"priceChange": null,
			"propertyId": "ba675197-7ced-427d-af7f-684a5b0bf918",
			"visibility": "NO_RESTRICTION",
			"soldDate": "2021-02-09 00:00:00",
			"createdAt": "2021-01-02 16:37:35",
			"imageDownloaderStatus": 7,
			"onMarketDateTime": "2021-01-02 00:00:00",
			"virtualTourLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2821280",
			"nhsDetails": null,
			"rentalDetails": null,
			"buildingName": null,
			"geo": {
				"state": "CA",
				"city": "Portola Valley",
				"lat": 37.372089,
				"lng": -122.211863,
				"zipcode": "94028",
				"subPremise": "",
				"address": "186 Echo Ln"
			},
			"isNHS": false,
			"isRentals": false,
			"isSold": true,
			"listingByMovoto": false,
			"priceRaw": 2555000,
			"isVideoTour": false,
			"is3DTour": false,
			"videoTourLink": "",
			"virtualLink": "Https://tours.tourfactory.com/tours/tour.asp?idx=1&t=2821280"
		}
	]